import {call, put, take,} from "redux-saga/effects";
import {fetchAdmins} from "../../../lib/api";
import {failedGetAdmins, setAdmins} from "../../reducers/monitoring/AdminReducer";

export function* getAdminSaga(action) {
    try{
        const res = yield call(
            fetchAdmins,
            action.payload
        )

        yield put(setAdmins(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetAdmins(error))
    }
}