import {call, put, take,} from "redux-saga/effects";
import {fetchMiniGames} from "../../../../lib/api";
import {
    failedGetMiniGames,
    setMiniGames,
} from "../../../reducers/game/manage/miniGame";

export function* getMiniGamesSaga(action) {
    try {
        const res = yield call(
            fetchMiniGames,
            action.payload
        );
        yield put(setMiniGames(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetMiniGames(error));
    }
}


