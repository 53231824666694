import {call, put, take,} from "redux-saga/effects";
import {fetchMoneyList} from "../../../lib/api";
import {failedGetMoneyList, setMoneyList} from "../../reducers/deposit/MoneyListReducer";

export function* getMoneyListSaga(action) {
    try{
        const res = yield call(
            fetchMoneyList,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd, action.payload.userId
        )
        yield put(setMoneyList(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMoneyList(error))
    }
}