import {call, put} from "redux-saga/effects";
import {failedGetMessagePopReducer, setMessagePopReducer} from "../../reducers/user/MessagePopReducer";
import {fetchMessagePop} from "../../../lib/api";

export function* getMessagePopSaga(action) {
    try{
        console.log("##getMessagePopSaga input#",action.payload.input)
        const res = yield call(fetchMessagePop,
            action.payload.input,
        );
        console.log("##getMessagePopSaga output#",res.data)
        yield put(setMessagePopReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMessagePopReducer(error))
    }
}