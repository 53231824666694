import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../redux/reducers/user/BlackMemberAddModalReducer";

const MemberInformationDetailModal = ({userId, setSelectedTab}) => {

    const userInfoId = useSelector((state) => state.userInfo.id)

    const [detailBetInfo01, setDetailBetInfo01] = useState([]);
    const [detailBetInfo02, setDetailBetInfo02] = useState([]);
    const [detailBetInfo03, setDetailBetInfo03] = useState([]);
    const [detailBetInfo04, setDetailBetInfo04] = useState([]);

    const [noArr, setNoArr] = useState([]);
    const [betArr01, setBetArr01] = useState([]);
    const [isEditing01, setIsEditing01] = useState(true);


    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }

    const getDetailInfo = () => {

        axios.post('/admin/member/getDetailBetInfo', {
            userId : userId
        }).then((res) => {
            setDetailBetInfo01(res.data.type01List);
            setDetailBetInfo02(res.data.type02List);
            setDetailBetInfo03(res.data.type03List);
            setDetailBetInfo04(res.data.type04List);
        })

    }
    useEffect(getDetailInfo, [])

    const setBetArr01Handler = (e, index) => {
        betArr01[index] = e.target.value;
        setBetArr01(betArr01);
        noArr[index] = index;
        setNoArr(noArr);
        //alert("betArr01 >>> "+JSON.stringify(betArr01));
    }


    const updateState = (noArr, betArr01) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/member/saveBettingLimit', {
                adminNo: userInfoId,
                userId: userId,
                noArr: noArr,
                betArr01: betArr01
            }).then((res) => {
                alert(res.data.isSaved? "저장완료" : "저장실패")
                setSelectedTab(0)
                setSelectedTab(2)
            })
        }
    }
    const handleClose = () => {
        window.close();
    }

    const handleDisplay = () => {
        setIsEditing01(false);
    }

    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table width={"99%"}>
                    <tr>
                        <td align={"right"}>
                            <button style={{
                                backgroundColor: "#5dc434",
                                border: "none",
                                color: "white",
                                fontSize: "9px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "10px"
                            }}
                                    onClick={()=>(updateState(noArr, betArr01)) }
                            >회원 수정
                            </button>
                            <button style={{
                                backgroundColor: "#3fadf7",
                                border: "none",
                                color: "white",
                                fontSize: "9px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "5px"
                            }}  onClick={handleClose}>닫기
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="commonT table table-bordered table-sm text-center" width={"50%"}>
                    <thead>
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <b>게임 종류별 개인 <font color={"red"}>최대 베팅 금액</font>(미설정시 기본 설정을 따름)</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                    </tr>
                    {detailBetInfo01.map((detailBetInfo01, index) => (
                        <tr>
                            <td style={{width: "40%",backgroundColor: detailBetInfo01.amt > 0 ? "lightskyblue":"white"}} align={"center"}>
                                <font color={detailBetInfo01.type_dtl_cd < 3 ? "red" : "black"}>{detailBetInfo01.type_dtl_nm}</font>
                            </td>
                            <td style={{width: "60%", backgroundColor: detailBetInfo01.amt > 0 ? "lightskyblue":"white"}} align={"center"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "120px"}} placeholder={addComma(detailBetInfo01.amt)}
                                       onChange={(e) => {setBetArr01Handler(e, detailBetInfo01.no)}}
                                />&nbsp;원
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                        </td>
                    </tr>
                    </thead>
                </table>
                <table className="commonT table table-bordered table-sm text-center" width={"50%"}>
                    <thead>
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <b>게임 종류별 <font color={"red"}>단폴더</font> 개인 <font color={"red"}>최대 당첨 금액</font>(미설정시 기본 설정을 따름)</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                    </tr>
                    {detailBetInfo02.map((detailBetInfo02, index) => (
                        <tr>
                            <td style={{width: "40%",backgroundColor: detailBetInfo02.amt > 0 ? "lightskyblue":"white"}} align={"center"}>
                                <font color={detailBetInfo02.type_dtl_cd < 3 ? "red" : "black"}>{detailBetInfo02.type_dtl_nm}</font>
                            </td>
                            <td style={{width: "60%",backgroundColor: detailBetInfo02.amt > 0 ? "lightskyblue":"white"}} align={"center"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "120px"}} placeholder={addComma(detailBetInfo02.amt)}
                                       onChange={(e) => {setBetArr01Handler(e, detailBetInfo02.no)}}
                                />&nbsp;원
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                        </td>
                    </tr>
                    </thead>
                </table>
            </div>




            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="commonT table table-bordered table-sm text-center" width={"50%"}>
                    <thead>
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <b>게임 종류별 개인 <font color={"red"}>최대 당첨 금액</font>(미설정시 기본 설정을 따름)</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                    </tr>
                    {detailBetInfo03.map((detailBetInfo03, index) => (
                        <tr>
                            <td style={{width: "40%",backgroundColor: detailBetInfo03.amt > 0 ? "lightskyblue":"white"}} align={"center"}>
                                <font color={detailBetInfo03.type_dtl_cd < 3 ? "red" : "black"}>{detailBetInfo03.type_dtl_nm}</font>
                            </td>
                            <td style={{width: "60%", backgroundColor: detailBetInfo03.amt > 0 ? "lightskyblue":"white"}} align={"center"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "120px"}} placeholder={addComma(detailBetInfo03.amt)}
                                       onChange={(e) => {setBetArr01Handler(e, detailBetInfo03.no)}}
                                />&nbsp;원
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                        </td>
                    </tr>
                    </thead>
                </table>
                <table className="commonT table table-bordered table-sm text-center" width={"50%"}>
                    <thead>
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <b>게임 종류별 <font color={"red"}>단폴더</font> 개인 <font color={"red"}>최대 당첨 금액</font>(미설정시 기본 설정을 따름)</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                    </tr>
                    {detailBetInfo04.map((detailBetInfo04, index) => (
                        <tr>
                            <td style={{width: "40%",backgroundColor: detailBetInfo04.amt > 0 ? "lightskyblue":"white"}} align={"center"}>
                                <font color={detailBetInfo04.type_dtl_cd < 3 ? "red" : "black"}>{detailBetInfo04.type_dtl_nm}</font>
                            </td>
                            <td style={{width: "60%",backgroundColor: detailBetInfo04.amt > 0 ? "lightskyblue":"white"}} align={"center"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "120px"}} placeholder={addComma(detailBetInfo04.amt)}
                                       onChange={(e) => {setBetArr01Handler(e, detailBetInfo04.no)}}
                                />&nbsp;원
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="2" align={"left"}>
                            <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                        </td>
                    </tr>
                    </thead>
                </table>
            </div>

        </div>



    )
}
export default MemberInformationDetailModal;