import React from 'react';
import TagSettingContainer from "../../containers/setting/TagSettingContainer";


const TagSetting = () => {
    return (
        <div>
            <TagSettingContainer />
        </div>
    );
};

export default TagSetting;