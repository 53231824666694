import React, {useEffect, useState,useRef} from 'react';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";

import {setSelectFullNm,setDisplayYn, setIsHovered, setOrgList, setQuery} from "../../redux/reducers/partner/OrgSelectReducer";
import {setIsOpen3} from "../../redux/reducers/user/MessageReducer";
import event from "../../pages/board/Event";

const OrgSelect = ( props ) => {
    const {setChildParam,id } = props
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.OrgSelectReducer[id]|| {})
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const getList = () =>{
        axios.post('/admin/partner/getOrgAll3', {
        }).then((res) => {
            dispatch(setOrgList({id:id,list : res.data.List}))
        })
    }

    useEffect(getList, [dispatch,id])

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const clickedElement = event.target;
            const modalElement = document.getElementById(`OrgSelect-${id}`);
            console.log('Modal Element:', modalElement);
            if (modalElement && !modalElement.contains(clickedElement)) {
                dispatch(setDisplayYn({id:id,displayYn : false}))
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [dispatch,id]); // 빈 배열을 전달하여 컴포넌트가 마운트될 때만 useEffect 실행

    const listStyle = {
        listStyleType: 'none',
        border: '1px solid #000',
        width: '250px', // 원하는 넓이로 조절
        padding: '0', // ul의 padding을 제거
        margin : 0,
        //height : "17px"
    };

    const listItemStyle0 = {
        display: 'flex',
        alignItems: 'center',
        height: '18px', // Adjust the height as needed
        padding: '4px 4px', // Adjust the padding as needed
        cursor: 'pointer',
    };

    const listItemStyle1 = {
        display: 'flex',
        alignItems: 'center',
        height: '32px', // Adjust the height as needed
        padding: '4px 4px', // Adjust the padding as needed
    };

    const inputStyle = {
        flex: 1,
        //marginRight: '10px', // Add margin if needed
        height : '24px',
    };

    const handleClick = (nm,orgId,fullNm) => {
        dispatch(setDisplayYn({id:id,displayYn : false}))
        dispatch(setSelectFullNm({id:id,fullNm:fullNm}))
        setChildParam(orgId)
        // 더 많은 로직을 추가할 수 있습니다.
    };

    const changeSearchQuery = (val) => {
        dispatch(setQuery({id:id,val:val}))
    };

    const onMounseEnterIsHovered = (idx,gb) =>{
        dispatch(setIsHovered({id:id,idx:idx,gb:gb}))
    }

    const onMounseLeaveIsHovered = (idx,gb) =>{
        dispatch(setIsHovered({id:id,idx:idx,gb:gb}))
    }

    const selectOrgClick = () =>{
        if(initialState.displayYn){
            dispatch(setDisplayYn({id:id,displayYn:false}))
        }else{
            dispatch(setDisplayYn({id:id,displayYn:true}))
        }
    }

    return (
        <div id={`OrgSelect-${id}`} className="OrgSelect"  style={{ position: "relative" }}>
            <div style={{ zIndex:id ,position: "absolute", backgroundColor: 'white', opacity: 1}}>
                <ul style={listStyle}>
                    <li className="OrgSpan" style={listItemStyle0} onClick={()=>selectOrgClick()}>
                        <span style={{ marginRight: 'auto'}}>
                            {initialState.selectFullNm?initialState.selectFullNm:
                                ':: 총판/가맹점 ::'
                            }
                        </span>
                        <span>{initialState.displayYn ? '▲' : '▼'}</span>
                    </li>
                    {initialState.displayYn && (
                        <li style={listItemStyle1}>
                            <input
                                className="inputOrg"
                                value={initialState.query}
                                type="text"
                                onChange={(e) => changeSearchQuery(e.target.value)}
                                style={inputStyle}
                            />
                        </li>
                    )}
                    {initialState.displayYn && initialState.searchList && initialState.searchList.length > 0
                        ? initialState.searchList.map((List, index) => (
                            <li
                                key={index}
                                style={{
                                    height: '27px',
                                    padding: '6px',
                                    cursor: 'pointer',
                                    backgroundColor: List.isHovered ? '#0080FF' : 'inherit',
                                    color: List.isHovered ? 'white' : 'inherit',
                                }}
                                tabIndex="0"
                                role="button"
                                onClick={() => handleClick(List.org_nm, List.id, List.fullNm)}
                                onMouseEnter={() => onMounseEnterIsHovered(index, true)}
                                onMouseLeave={() => onMounseLeaveIsHovered(index, false)}
                            >
                                {/*{List.org_level_cd === '02' && '\u00A0\u00A0'}*/}
                                {/*{List.org_level_cd === '03' && '\u00A0\u00A0\u00A0\u00A0'}*/}
                                {List.fullNm}
                            </li>
                        ))
                        : null}
                </ul>
            </div>
        </div>
    );
};

export default OrgSelect;