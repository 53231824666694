import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {setIsOpen,setIsOpen02,setIsOpen03,setIsOpen04,setIsOpen05,setIsOpen06,setIsOpen07,setIsOpen08,setIsOpen09,setIsOpen10} from "../../../../redux/reducers/userInfoDetail";

import Detail01Modal from "../detail/Detail01Modal";
import Detail02Modal from "../detail/Detail02Modal";
import Detail03Modal from "../detail/Detail03Modal";
import Detail04Modal from "../detail/Detail04Modal";
import Detail05Modal from "../detail/Detail05Modal";
import Detail06Modal from "../detail/Detail06Modal";
import Detail07Modal from "../detail/Detail07Modal";
import Detail08Modal from "../detail/Detail08Modal";
import Detail09Modal from "../detail/Detail09Modal";
import Detail10Modal from "../detail/Detail10Modal";
import DetailModal from "../detail/DetailModal";
import {setIsUserId, setIsUserNickname, setIsUserNo} from "../../../../redux/reducers/user/userInfoCombineReducer";
import {NumericFormat} from "react-number-format";
import {setPayGameMoney_gameMoneyAmt} from "../../../../redux/reducers/member/MemberInformationSimpleModalReducer";


const MemberInformationDetailModal = (props) => {
    const userInfoId = useSelector((state) => state.userInfo.id)
    const userInfoIp = useSelector((state) => state.userInfo.ip)
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.userInfoDetail)
    const {open, onClose, userId} = props

    const userState = useSelector((state) => state.userInfoCombine)
    const [detailInfo, setDetailInfo] = useState({});
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [searchId, setSearchId] = useState('');

    const [payGameMoney_gameMoneyAmt, setPayGameMoney_gameMoneyAmt] = useState(0);
    const [payGameMoney_memo, setPayGameMoney_memo] = useState('');
    const [payPoint_point, setPayPoint_point] = useState(0);
    const [payPoint_memo, setPayPoint_memo] = useState('');
    const [payEatPoint_point, setPayEatPoint_point] = useState(0);
    const [payEatPoint_memo, setPayEatPoint_memo] = useState('');


    const searchIdHandler = (e) => {
        setSearchId(e.currentTarget.value);
    }

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }
    const getDetailInfo = () => {

        axios.post('/admin/member/detailInfo', {
            userId : userState.userId,
            startDate: startDate,
            endDate: endDate
        }).then((res) => {
            setDetailInfo(res.data.cmmnCdList);
        })

    }
    useEffect(getDetailInfo, [])

    const onClickSearch = (e) => {

        axios.post('/admin/member/detailInfo', {
            userId : searchId,
            startDate: startDate,
            endDate: endDate
        }).then((res) => {
            if( res.data.cmmnCdList === null )
            {
                alert("일치하는 회원이 없습니다.");
                return;
            }
            else
            {
                setDetailInfo(res.data.cmmnCdList);
                dispatch(setIsUserId(res.data.cmmnCdList.id));
                dispatch(setIsUserNo(res.data.cmmnCdList.no));
                dispatch(setIsUserNickname(res.data.cmmnCdList.nickname));
                modal02Open();
                modal01Open();
            }


        })


        // dispatch(setIsUserId(searchId));
        // getDetailInfo2(searchId);
    }


    const addDate = () => {
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add0Date = () => {
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add1Date = () => {
        setStartDate(moment().add(-1, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add7Date = () => {
        setStartDate(moment().add(-7, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const addMaxDate = () => {
        setStartDate(moment().add(-3650, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }


    const modal01Open = () => {
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(true))
    }
    const modal02Open = () => {
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(true))
    }
    const modal03Open = () => {
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(true))
    }
    const modal04Open = () => {
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(true))
    }
    const modal05Open = () => {
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(true))
    }
    const modal06Open = () => {
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(true))
    }
    const modal07Open = () => {
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(true))
    }
    const modal08Open = () => {
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(true))
    }
    const modal09Open = () => {
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(true))
    }
    const modal10Open = () => {
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(true))
    }
    const handleCancel = () => {
        onClose()
    }


    const onChangePayGameMoney_gameMoneyAmt = (e) =>{
        setPayGameMoney_gameMoneyAmt(e.currentTarget.value)
    }
    const onChangePayGameMoney_memo = (e) =>{
        setPayGameMoney_memo(e.currentTarget.value)
    }
    const payGameMoney = () =>{
        axios.post('/admin/member/simple/memberInfoSimpleModal/payGameMoney', {
            gameMoneyAmt : payGameMoney_gameMoneyAmt,
            memo :  payGameMoney_memo ,
            userId : userState.userId ,
            regId : userInfoId ,
            regIp : userInfoIp ,
        }).then((res) => {
            alert(res.data.message);
        })
    }


    const onChangePayPoint_point = (e) =>{
        setPayPoint_point(e.currentTarget.value)
    }
    const onChangePayPoint_memo = (e) =>{
        setPayPoint_memo(e.currentTarget.value)
    }
    const payPoint = () =>{
        axios.post('/admin/member/simple/memberInfoSimpleModal/payPoint', {
            pointTypeCd : '11',
            point : payPoint_point,
            memo :  payPoint_memo ,
            userId : userState.userId ,
            regId : userInfoId ,
            regIp : userInfoIp ,

        }).then((res) => {
            alert(res.data.message);
        })
    }

    const onChangePayEatPoint_point = (e) =>{
        setPayEatPoint_point(e.currentTarget.value)
    }
    const onChangePayEatPoint_memo = (e) =>{
        setPayEatPoint_memo(e.currentTarget.value)
    }
    const payEatPoint = () =>{
        axios.post('/admin/member/simple/memberInfoSimpleModal/payPoint', {
            pointTypeCd : '30',
            point : payPoint_point,
            memo :  "식사비 지급" ,
            userId : userState.userId ,
            regId : userInfoId ,
            regIp : userInfoIp ,

        }).then((res) => {
            alert(res.data.message);
        })
    }



    return (
        <div className="user_detail_modal_background">
            <div className="user_detail_modal_box" >
                <div className="user_detail_modal_body">

                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <br></br>

                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex", height:"50px"
                    }}>
                        <button style={{
                            width: "90px"
                        }} type="submit" onClick={() => {modal01Open()}}>회원 정보
                        </button>&nbsp;
                        <button style={{
                            width: "90px"
                        }} type="submit" onClick={() => {modal02Open()}}>수정 기록
                        </button>&nbsp;
                        <button style={{
                            width: "90px"
                        }} type="submit" onClick={() => {modal03Open()}}>베팅 제한
                        </button>&nbsp;
                        <button style={{
                            width: "90px"
                        }} type="submit" onClick={() => {modal04Open()}}>개인 배당
                        </button>&nbsp;
                        <button style={{
                            width: "90px"
                        }} type="submit" onClick={() => {modal05Open()}}>롤링 설정
                        </button>&nbsp;
                        <button style={{
                            width: "90px"
                        }} type="submit" onClick={() => {modal06Open()}}>루징 설정
                        </button>&nbsp;
                        <button style={{
                            width: "90px"
                        }} type="submit" onClick={() => {modal07Open()}}>기타 제한
                        </button>&nbsp;
                        <button style={{
                            width: "90px"
                        }} type="submit" onClick={() => {modal08Open()}}>베팅/쪽지
                        </button>&nbsp;
                        <button style={{
                            width: "90px"
                        }} type="submit" onClick={() => {modal09Open()}}>충환 로그
                        </button>&nbsp;
                        <button style={{
                            width: "90px"
                        }} type="submit" onClick={() => {modal10Open()}}>접속 기록
                        </button>&nbsp;
                        <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleCancel}>X</button>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex", height:"670px", overflowY:"scroll", alignItems:'flex-start'
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" >
                            <tr>
                                <td height={"300px"}>
                                    <div>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex", border: "1px solid lightgray"
                                        }}>
                                            <div className="container-fluid p-0 mx-1" style={{
                                                display: "flex", border: "1px solid lightgray", width: "500px"
                                            }}>
                                                <button style={{
                                                    backgroundColor: "#3fadf7",
                                                    border: "none",
                                                    color: "white",
                                                    fontSize: "9px",
                                                    height: "20px",
                                                    width: "fit-content",
                                                    marginRight: "5px"
                                                }} onClick={addMaxDate}>최대
                                                </button>
                                                <button style={{
                                                    backgroundColor: "#3fadf7",
                                                    border: "none",
                                                    color: "white",
                                                    fontSize: "9px",
                                                    height: "20px",
                                                    width: "fit-content",
                                                    marginRight: "5px"
                                                }} onClick={add7Date}>일주일
                                                </button>
                                                <button style={{
                                                    backgroundColor: "#3fadf7",
                                                    border: "none",
                                                    color: "white",
                                                    fontSize: "9px",
                                                    height: "20px",
                                                    width: "fit-content",
                                                    marginRight: "5px"
                                                }} onClick={add1Date}>어제
                                                </button>
                                                <button style={{
                                                    backgroundColor: "#3fadf7",
                                                    border: "none",
                                                    color: "white",
                                                    fontSize: "9px",
                                                    height: "20px",
                                                    width: "fit-content",
                                                    marginRight: "5px"
                                                }} onClick={add0Date}>오늘
                                                </button>
                                                <button style={{
                                                    backgroundColor: "red",
                                                    border: "none",
                                                    color: "white",
                                                    fontSize: "9px",
                                                    height: "20px",
                                                    width: "fit-content",
                                                    marginRight: "5px"
                                                }} onClick={addDate}>지난24시간
                                                </button>
                                                <input className="form-control sm-date" type="date" value={startDate}
                                                       onChange={startDateHandler} style={{width: "110px"}}/>
                                                &nbsp;~&nbsp;
                                                <input className="form-control sm-date" type="date" value={endDate}
                                                       onChange={endDateHandler} style={{width: "110px"}}/>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <div className="container-fluid p-0 mx-1" style={{
                                                display: "flex", border: "1px solid lightgray", width: "370px"
                                            }}>
                                                <table>
                                                    <tr>
                                                        <td align={"left"}>
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "220px", height: "30px"}}
                                                                   placeholder="유저 전환용 검색어를 입력" value={searchId}
                                                                   onChange={searchIdHandler}/>
                                                            &nbsp;
                                                            <button style={{
                                                                width: "90px", height: "30px"
                                                            }} type="submit" onClick={onClickSearch}><b>검색</b>
                                                            </button>
                                                            &nbsp;
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align={"left"}>
                                                            * 닉네임,아이디,계좌번호,전화번호 순으로 검색하며, 일부만 넣어도 됩니다.
                                                            <br></br>
                                                            * 검색어에 해당하는 유저가 1명일 경우, 이 창이 검색된 유저로 전환됩니다.
                                                            <br></br>
                                                            * 검색과 일치하는 유저가 다수일 경우, 전환되지 않습니다.
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex", border: "1px solid lightgray"
                                        }}>
                                            <table className="table table-bordered table-sm text-center align-middle"
                                                   style={{width: "100%"}}>
                                                <tr>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"}>충전
                                                    </td>
                                                    <td width={"17%"} align={"center"}>0 / 0회</td>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"}>베팅
                                                    </td>
                                                    <td width={"17%"} align={"center"}>
                                                        0 / 0회
                                                        <br></br>
                                                        진행중 일반/미니 : 0 / 0건
                                                    </td>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"}>보유금
                                                    </td>
                                                    <td align={"center"} width={"0.1%"}></td>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"7%"}>게임머니
                                                    </td>
                                                    <td width={"17%"} align={"center"}>
                                                        <NumericFormat value={detailInfo.game_money_amt}
                                                                       displayType={'text'} thousandSeparator={true}/>
                                                    </td>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"}>포인트
                                                    </td>
                                                    <td width={"21%"} align={"center"}>
                                                        <NumericFormat value={detailInfo.point} displayType={'text'}
                                                                       thousandSeparator={true}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"}>환전
                                                    </td>
                                                    <td width={"17%"} align={"center"}>0 / 0회</td>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"}>적중
                                                    </td>
                                                    <td width={"17%"} align={"center"}>0</td>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"}>머니지급
                                                    </td>
                                                    <td align={"center"} width={"0.1%"}></td>
                                                    <td align={"center"} colSpan="4">
                                                        <table style={{width: "100%"}}>
                                                            <tr>
                                                                <td style={{backgroundColor: "lightgray"}}
                                                                    align={"center"} width={"43%"}>지급액
                                                                </td>
                                                                <td style={{backgroundColor: "lightgray"}}
                                                                    align={"center"} width={"44%"}>메모
                                                                </td>
                                                                <td style={{backgroundColor: "lightgray"}}
                                                                    align={"center"} width={"13%"}>지급
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align={"center"}>
                                                                    <input onChange={onChangePayGameMoney_gameMoneyAmt} className="sm-input" type="number" value={payGameMoney_gameMoneyAmt}/>
                                                                </td>
                                                                <td align={"center"}>
                                                                    <textarea name="payGameMoney_memo" id="payGameMoney_memo" cols="25" rows="1" onChange={onChangePayGameMoney_memo} value={payGameMoney_memo}/>
                                                                </td>
                                                                <td align={"center"}>
                                                                    <button style={{
                                                                        backgroundColor: "#3fadf7",
                                                                        border: "none",
                                                                        color: "white",
                                                                        fontSize: "9px",
                                                                        height: "20px",
                                                                        width: "fit-content",
                                                                        marginRight: "5px"
                                                                    }}
                                                                            onClick={payGameMoney}
                                                                    >지급
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"}>마진
                                                    </td>
                                                    <td width={"17%"} align={"center"}>0 / 0회</td>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"}>베팅마진
                                                    </td>
                                                    <td width={"17%"} align={"center"}>0</td>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"} rowSpan="2">포인트지급
                                                    </td>
                                                    <td align={"center"} width={"0.1%"}></td>
                                                    <td align={"center"} colSpan="4">
                                                        <table style={{width: "100%"}}>
                                                            <tr>
                                                                <td style={{backgroundColor: "lightgray"}}
                                                                    align={"center"} width={"43%"}>지급 포인트
                                                                </td>
                                                                <td style={{backgroundColor: "lightgray"}}
                                                                    align={"center"} width={"44%"}>메모
                                                                </td>
                                                                <td style={{backgroundColor: "lightgray"}}
                                                                    align={"center"} width={"13%"}>지급
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align={"center"}>
                                                                    <input onChange={onChangePayPoint_point} value={payPoint_point} className="sm-input" type="number"/>
                                                                </td>
                                                                <td align={"center"}>
                                                                    <textarea name="payPoint_memo" id="payPoint_memo" cols="25" rows="1" onChange={onChangePayPoint_memo} value={payPoint_memo}/>
                                                                </td>
                                                                <td align={"center"}>
                                                                    <button style={{
                                                                        backgroundColor: "#3fadf7",
                                                                        border: "none",
                                                                        color: "white",
                                                                        fontSize: "9px",
                                                                        height: "20px",
                                                                        width: "fit-content",
                                                                        marginRight: "5px"
                                                                    }}
                                                                            onClick={payPoint}
                                                                    >지급
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                        width={"5%"}>식사비 지급
                                                    </td>
                                                    <td width={"17%"} align={"center"} colSpan="3">당일 : 0원 / 당월 : 0원 /
                                                        전체 : 0원
                                                    </td>
                                                    <td align={"center"} width={"0.1%"}></td>
                                                    <td align={"center"} colSpan="4">
                                                        <table style={{width: "100%"}}>
                                                            <tr>
                                                                <td style={{backgroundColor: "lightgray"}}
                                                                    align={"center"} width={"43%"}>지급 식사비(포인트)
                                                                </td>
                                                                <td style={{backgroundColor: "lightgray"}}
                                                                    align={"center"} width={"44%"}>메모
                                                                </td>
                                                                <td style={{backgroundColor: "lightgray"}}
                                                                    align={"center"} width={"13%"}>지급
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align={"center"}>
                                                                    <input onChange={onChangePayEatPoint_point} value={payEatPoint_point} className="sm-input" type="number"/>
                                                                </td>
                                                                <td align={"center"}>
                                                                    식사비 지급시, 포인트로 지급되며, 3번 메모에 자동으로 식사비 지급 메모가 남습니다.
                                                                </td>
                                                                <td align={"center"}>
                                                                    <button style={{
                                                                        backgroundColor: "#3fadf7",
                                                                        border: "none",
                                                                        color: "white",
                                                                        fontSize: "9px",
                                                                        height: "20px",
                                                                        width: "fit-content",
                                                                        marginRight: "5px"
                                                                    }}
                                                                            onClick={payEatPoint}
                                                                    >지급
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                    </td>

                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    {initialState.isOpen && (<Detail01Modal /> )}
                                    {initialState.isOpen02 && (<Detail02Modal /> )}
                                    {initialState.isOpen03 && (<Detail03Modal /> )}
                                    {initialState.isOpen04 && (<Detail04Modal /> )}
                                    {initialState.isOpen05 && (<Detail05Modal /> )}
                                    {initialState.isOpen06 && (<Detail06Modal /> )}
                                    {initialState.isOpen07 && (<Detail07Modal /> )}
                                    {initialState.isOpen08 && (<Detail08Modal /> )}
                                    {initialState.isOpen09 && (<Detail09Modal /> )}
                                    {initialState.isOpen10 && (<Detail10Modal /> )}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>



    )
}
export default MemberInformationDetailModal;