import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useRef, useState, forwardRef, useImperativeHandle} from "react"

import Pagination from "../../components/common/Pagination2";
import LevelSetting from "../../components/setting/LevelSetting";
import {NumericFormat} from "react-number-format";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import {getLevelSetting,
    setBox01Val1array,
    setBox01Val2array,
    setBox01Val3array,
    setBox01Val4array,
    setBox01Val5array,
    setBox01Val6array,
    setBox01Val7array
    ,setBox02Val1array
    ,setBox02Val2array
    ,setBox02Val3array
    ,setBox02Val4array
    ,setBox02Val5array
    ,setBox02Val6array
    ,setBox02Val7array
    ,setBox02Val8array
    ,setBox02Val9array
    ,setBox02Val10array
    ,setBox02Val11array
    ,setBox02Val12array
    ,setBox02Val13array
    ,setBox03Val1array
    ,setBox03Val2array
    ,setBox03Val3array
    ,setBox03Val4array
    ,setBox03Val5array
    ,setBox03Val6array
    ,setBox03Val7array
    ,setBox03Val8array
    ,setBox03Val9array
    ,setBox03Val10array
    ,setBox04Val1array
    ,setBox04Val2array
    ,setBox04Val3array
    ,setBox04Val4array
    ,setBox04Val5array
    ,setBox04Val6array
    ,setBox04Val7array
    ,setBox04Val8array
    ,setBox04Val9array
    ,setBox04Val10array
    ,setBox04Val11array
    ,setBox04Val12array
    ,setBox04Val13array
    ,setBox04Val14array
    ,setBox04Val15array
    ,setBox04Val16array
    ,setBox04Val17array
    ,setBox04Val18array
    ,setBox04Val19array
    ,setBox04Val20array
    ,setBox04Val21array
    ,setBox04Val22array
    ,setBox04Val23array
    ,setBox04Val24array
    ,setBox04Val25array
    ,setBox04Val26array
    ,setBox04Val27array
    ,setBox04Val28array
    ,setBox04Val29array
    ,setBox04Val30array
    ,setBox04Val31array
    ,setBox04Val32array
    ,setBox04Val33array
    ,setBox04Val34array
    ,setBox04Val35array
    ,setBox04Val36array
    ,setBox04Val37array
    ,setBox04Val38array
    ,setBox04Val39array
    ,setBox04Val40array
    ,setBox04Val41array
    ,setBox04Val42array
    ,setBox04Val43array
    ,setBox04Val44array
    ,setBox04Val45array
    ,setBox04Val46array
    ,setBox04Val47array
    ,setBox04Val48array
    ,setBox05Val1array
    ,setBox05Val2array
    ,setBox05Val3array
    ,setBox05Val4array
    ,setBox05Val5array
    ,setBox05Val6array
    ,setBox05Val7array
    ,setBox05Val8array
    ,setBox05Val9array
    ,setBox05Val10array
    ,setBox05Val11array
    ,setBox05Val12array
    ,setBox05Val13array
    ,setBox05Val14array
    ,setBox05Val15array
    ,setBox05Val16array
    ,setBox05Val17array
    ,setBox05Val18array
    ,setBox05Val19array
    ,setBox05Val20array
    ,setBox05Val21array
    ,setBox05Val22array
    ,setBox05Val23array
    ,setBox05Val24array
    ,setBox05Val25array
    ,setBox05Val26array
    ,setBox05Val27array
    ,setBox05Val28array
    ,setBox05Val29array
    ,setBox05Val30array
    ,setBox05Val31array
    ,setBox05Val32array
    ,setBox05Val33array
    ,setBox05Val34array
    ,setBox05Val35array
    ,setBox05Val36array
    ,setBox05Val37array
    ,setBox05Val38array
    ,setBox05Val39array
    ,setBox05Val40array
    ,setBox05Val41array
    ,setBox05Val42array
    ,setBox05Val43array
    ,setBox05Val44array
    ,setBox05Val45array
    ,setBox05Val46array
    ,setBox05Val47array
    ,setBox05Val48array
    ,setBox06Val1array
    ,setBox06Val2array
    ,setBox06Val3array
    ,setBox06Val4array
    ,setBox06Val5array
    ,setBox06Val6array
    ,setBox06Val7array
    ,setBox06Val8array
    ,setBox06Val9array
    ,setBox06Val10array
    ,setBox06Val11array
    ,setBox06Val12array
    ,setBox06Val13array
    ,setBox06Val14array
    ,setBox06Val15array
    ,setBox06Val16array
    ,setBox06Val17array
    ,setBox06Val18array
    ,setBox06Val19array
    ,setBox06Val20array
    ,setBox06Val21array
    ,setBox06Val22array
    ,setBox06Val23array
    ,setBox06Val24array
    ,setBox06Val25array
    ,setBox06Val26array
    ,setBox06Val27array
    ,setBox06Val28array
    ,setBox06Val29array
    ,setBox06Val30array
    ,setBox06Val31array
    ,setBox06Val32array
    ,setBox06Val33array
    ,setBox06Val34array
    ,setBox06Val35array
    ,setBox06Val36array
    ,setBox06Val37array
    ,setBox06Val38array
    ,setBox06Val39array
    ,setBox06Val40array
    ,setBox06Val41array
    ,setBox06Val42array
    ,setBox06Val43array
    ,setBox06Val44array
    ,setBox06Val45array
    ,setBox06Val46array
    ,setBox06Val47array
    ,setBox06Val48array
    ,setBox07Val1array
    ,setBox07Val2array
    ,setBox07Val3array
    ,setBox07Val4array
    ,setBox07Val5array
    ,setBox07Val6array
    ,setBox07Val7array
    ,setBox07Val8array
    ,setBox07Val9array
    ,setBox07Val10array
    ,setBox07Val11array
    ,setBox07Val12array
    ,setBox07Val13array
    ,setBox07Val14array
    ,setBox07Val15array
    ,setBox07Val16array
    ,setBox07Val17array
    ,setBox07Val18array
    ,setBox07Val19array
    ,setBox07Val20array
    ,setBox07Val21array
    ,setBox07Val22array
    ,setBox07Val23array
    ,setBox07Val24array
    ,setBox07Val25array
    ,setBox07Val26array
    ,setBox07Val27array
    ,setBox07Val28array
    ,setBox07Val29array
    ,setBox07Val30array
    ,setBox07Val31array
    ,setBox07Val32array
    ,setBox07Val33array
    ,setBox07Val34array
    ,setBox07Val35array
    ,setBox07Val36array
    ,setBox07Val37array
    ,setBox07Val38array
    ,setBox07Val39array
    ,setBox07Val40array
    ,setBox07Val41array
    ,setBox07Val42array
    ,setBox07Val43array
    ,setBox07Val44array
    ,setBox07Val45array
    ,setBox07Val46array
    ,setBox07Val47array
    ,setBox07Val48array
    ,setBox08Val1array
    ,setBox08Val2array
    ,setBox08Val3array
    ,setBox08Val4array
    ,setBox08Val5array
    ,setBox08Val6array
    ,setBox08Val7array
    ,setBox09Val1array
    ,setBox09Val2array
    ,setBox09Val3array
    ,setBox09Val4array
    ,setBox09Val5array
    ,setBox09Val6array
    ,setBox09Val7array
    ,setBox10Val1array
    ,setBox10Val2array
    ,setBox10Val3array
    ,setBox10Val4array
    ,setBox10Val5array
    ,setBox10Val6array
    ,setBox10Val7array
    ,setBox11Val1array
    ,setBox11Val2array
    ,setBox11Val3array
    ,setBox11Val4array
    ,setBox11Val5array
    ,setBox11Val6array
    ,setBox11Val7array
    ,setBox12Val1array
    ,setBox12Val2array
    ,setBox12Val3array
    ,setBox12Val4array
    ,setBox12Val5array
    ,setBox12Val6array
    ,setBox12Val7array
    ,setBox13Val1array
    ,setBox13Val2array
    ,setBox13Val3array
    ,setBox13Val4array
    ,setBox13Val5array
    ,setBox13Val6array
    ,setBox13Val7array
    ,setBox14Val1array
    ,setBox14Val2array
    ,setBox14Val3array
    ,setBox14Val4array
} from "../../redux/reducers/setting/LevelSettingSingleReducer";
import {setComboGb} from "../../redux/reducers/partner/DistributorListReducer";
import {insertAdminLog} from "../../lib/api";

const LevelSettingContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const childComponentRef = useRef();

    const userInfoId = useSelector((state) => state.userInfo.id)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.levelSetting)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM')+'-01');
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [userGbCd, setUserGbCd] = useState("");



    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getLevelSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "레벨별설정"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getLevelSetting({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getLevelSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getLevelSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getLevelSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }


    const exportExcel = ()=>{

        //alert('initialState.totalList <>>>> '+initialState.totalList);

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {


            let jsonObject = { // JSON 객체를 생성합니다.
                no: initialState.totalList[i].no? initialState.totalList[i].no :'',
                소속: initialState.totalList[i].store_cd? initialState.totalList[i].store_cd :'',
                아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                변경필드명: initialState.totalList[i].change_field? initialState.totalList[i].change_field :'',
                기존값: initialState.totalList[i].asis_value? initialState.totalList[i].asis_value :'',
                수정값: initialState.totalList[i].tobe_value? initialState.totalList[i].tobe_value :'',
                수정아이디: initialState.totalList[i].upd_id? initialState.totalList[i].upd_id :'',
                수정아이피: initialState.totalList[i].update_ip? initialState.totalList[i].update_ip :'',
                수정시각: initialState.totalList[i].updatedAt? initialState.totalList[i].updatedAt :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '회원정보 수정내역');
        XLSX.writeFile(workBook, '회원정보 수정내역.xlsx');
    }

    const userGbCdChangeHandler = (e) => {
        alert('userGbCd000 >>>>>>>>>>>> '+e.currentTarget.value);
        setUserGbCd(e.currentTarget.value);

        alert('userGbCd >>>>>>>>>>>> '+userGbCd);
        onRefresh();
    }





    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const add7Date = () => {
        setStartDate(moment().add(-7, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add1Date = () => {
        setStartDate(moment().add(-1, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add0Date = () => {
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }


    const deleteState = (no) => {
        //alert("no :: "+no)
        if (window.confirm("삭제 하시겠습니까?"))
        {
            axios.post('/admin/setting/deleteTagSetting', {
                adminNo: userInfoId,
                no: no
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }


    const setArrayList = (
        startIdx,
        endIdx,
        box01Val1arr,
        box01Val2arr,
        box01Val3arr,
        box01Val4arr,
        box01Val5arr,
        box01Val6arr,
        box01Val7arr,
        box02Val1arr,
        box02Val2arr,
        box02Val3arr,
        box02Val4arr,
        box02Val5arr,
        box02Val6arr,
        box02Val7arr,
        box02Val8arr,
        box02Val9arr,
        box02Val10arr,
        box02Val11arr,
        box02Val12arr,
        box02Val13arr,
        box03Val1arr,
        box03Val2arr,
        box03Val3arr,
        box03Val4arr,
        box03Val5arr,
        box03Val6arr,
        box03Val7arr,
        box03Val8arr,
        box03Val9arr,
        box03Val10arr,
        box04Val1arr,
        box04Val2arr,
        box04Val3arr,
        box04Val4arr,
        box04Val5arr,
        box04Val6arr,
        box04Val7arr,
        box04Val8arr,
        box04Val9arr,
        box04Val10arr,
        box04Val11arr,
        box04Val12arr,
        box04Val13arr,
        box04Val14arr,
        box04Val15arr,
        box04Val16arr,
        box04Val17arr,
        box04Val18arr,
        box04Val19arr,
        box04Val20arr,
        box04Val21arr,
        box04Val22arr,
        box04Val23arr,
        box04Val24arr,
        box04Val25arr,
        box04Val26arr,
        box04Val27arr,
        box04Val28arr,
        box04Val29arr,
        box04Val30arr,
        box04Val31arr,
        box04Val32arr,
        box04Val33arr,
        box04Val34arr,
        box04Val35arr,
        box04Val36arr,
        box04Val37arr,
        box04Val38arr,
        box04Val39arr,
        box04Val40arr,
        box04Val41arr,
        box04Val42arr,
        box04Val43arr,
        box04Val44arr,
        box04Val45arr,
        box04Val46arr,
        box04Val47arr,
        box04Val48arr,
        box05Val1arr,
        box05Val2arr,
        box05Val3arr,
        box05Val4arr,
        box05Val5arr,
        box05Val6arr,
        box05Val7arr,
        box05Val8arr,
        box05Val9arr,
        box05Val10arr,
        box05Val11arr,
        box05Val12arr,
        box05Val13arr,
        box05Val14arr,
        box05Val15arr,
        box05Val16arr,
        box05Val17arr,
        box05Val18arr,
        box05Val19arr,
        box05Val20arr,
        box05Val21arr,
        box05Val22arr,
        box05Val23arr,
        box05Val24arr,
        box05Val25arr,
        box05Val26arr,
        box05Val27arr,
        box05Val28arr,
        box05Val29arr,
        box05Val30arr,
        box05Val31arr,
        box05Val32arr,
        box05Val33arr,
        box05Val34arr,
        box05Val35arr,
        box05Val36arr,
        box05Val37arr,
        box05Val38arr,
        box05Val39arr,
        box05Val40arr,
        box05Val41arr,
        box05Val42arr,
        box05Val43arr,
        box05Val44arr,
        box05Val45arr,
        box05Val46arr,
        box05Val47arr,
        box05Val48arr,
        box06Val1arr,
        box06Val2arr,
        box06Val3arr,
        box06Val4arr,
        box06Val5arr,
        box06Val6arr,
        box06Val7arr,
        box06Val8arr,
        box06Val9arr,
        box06Val10arr,
        box06Val11arr,
        box06Val12arr,
        box06Val13arr,
        box06Val14arr,
        box06Val15arr,
        box06Val16arr,
        box06Val17arr,
        box06Val18arr,
        box06Val19arr,
        box06Val20arr,
        box06Val21arr,
        box06Val22arr,
        box06Val23arr,
        box06Val24arr,
        box06Val25arr,
        box06Val26arr,
        box06Val27arr,
        box06Val28arr,
        box06Val29arr,
        box06Val30arr,
        box06Val31arr,
        box06Val32arr,
        box06Val33arr,
        box06Val34arr,
        box06Val35arr,
        box06Val36arr,
        box06Val37arr,
        box06Val38arr,
        box06Val39arr,
        box06Val40arr,
        box06Val41arr,
        box06Val42arr,
        box06Val43arr,
        box06Val44arr,
        box06Val45arr,
        box06Val46arr,
        box06Val47arr,
        box06Val48arr,
        box07Val1arr,
        box07Val2arr,
        box07Val3arr,
        box07Val4arr,
        box07Val5arr,
        box07Val6arr,
        box07Val7arr,
        box07Val8arr,
        box07Val9arr,
        box07Val10arr,
        box07Val11arr,
        box07Val12arr,
        box07Val13arr,
        box07Val14arr,
        box07Val15arr,
        box07Val16arr,
        box07Val17arr,
        box07Val18arr,
        box07Val19arr,
        box07Val20arr,
        box07Val21arr,
        box07Val22arr,
        box07Val23arr,
        box07Val24arr,
        box07Val25arr,
        box07Val26arr,
        box07Val27arr,
        box07Val28arr,
        box07Val29arr,
        box07Val30arr,
        box07Val31arr,
        box07Val32arr,
        box07Val33arr,
        box07Val34arr,
        box07Val35arr,
        box07Val36arr,
        box07Val37arr,
        box07Val38arr,
        box07Val39arr,
        box07Val40arr,
        box07Val41arr,
        box07Val42arr,
        box07Val43arr,
        box07Val44arr,
        box07Val45arr,
        box07Val46arr,
        box07Val47arr,
        box07Val48arr,
        box08Val1arr,
        box08Val2arr,
        box08Val3arr,
        box08Val4arr,
        box08Val5arr,
        box08Val6arr,
        box08Val7arr,
        box09Val1arr,
        box09Val2arr,
        box09Val3arr,
        box09Val4arr,
        box09Val5arr,
        box09Val6arr,
        box09Val7arr,
        box10Val1arr,
        box10Val2arr,
        box10Val3arr,
        box10Val4arr,
        box10Val5arr,
        box10Val6arr,
        box10Val7arr,
        box11Val1arr,
        box11Val2arr,
        box11Val3arr,
        box11Val4arr,
        box11Val5arr,
        box11Val6arr,
        box11Val7arr,
        box12Val1arr,
        box12Val2arr,
        box12Val3arr,
        box12Val4arr,
        box12Val5arr,
        box12Val6arr,
        box12Val7arr,
        box13Val1arr,
        box13Val2arr,
        box13Val3arr,
        box13Val4arr,
        box13Val5arr,
        box13Val6arr,
        box13Val7arr,
        box14Val1arr,
        box14Val2arr,
        box14Val3arr,
        box14Val4arr
    ) => {
        //여기서 데이터 가공 할 차례

        let box01Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box01Val1Array[i] = box01Val1arr;
            }
        }
        let box01Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box01Val2Array[i] = box01Val2arr;
            }
        }
        let box01Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box01Val3Array[i] = box01Val3arr;
            }
        }
        let box01Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box01Val4Array[i] = box01Val4arr; }
        }
        let box01Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box01Val5Array[i] = box01Val5arr; }
        }
        let box01Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box01Val6Array[i] = box01Val6arr; }
        }
        let box01Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box01Val7Array[i] = box01Val7arr; }
        }
        let box02Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box02Val1Array[i] = box02Val1arr;
            }
        }
        let box02Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box02Val2Array[i] = box02Val2arr;
            }
        }
        let box02Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box02Val3Array[i] = box02Val3arr;
            }
        }
        let box02Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box02Val4Array[i] = box02Val4arr; }
        }
        let box02Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box02Val5Array[i] = box02Val5arr; }
        }
        let box02Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box02Val6Array[i] = box02Val6arr; }
        }
        let box02Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box02Val7Array[i] = box02Val7arr; }
        }
        let box02Val8Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box02Val8Array[i] = box02Val8arr; }
        }
        let box02Val9Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box02Val9Array[i] = box02Val9arr; }
        }
        let box02Val10Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box02Val10Array[i] = box02Val10arr; }
        }
        let box02Val11Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box02Val11Array[i] = box02Val11arr; }
        }
        let box02Val12Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box02Val12Array[i] = box02Val12arr; }
        }
        let box02Val13Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box02Val13Array[i] = box02Val13arr; }
        }
        let box03Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box03Val1Array[i] = box03Val1arr;
            }
        }
        let box03Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box03Val2Array[i] = box03Val2arr;
            }
        }
        let box03Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box03Val3Array[i] = box03Val3arr;
            }
        }
        let box03Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box03Val4Array[i] = box03Val4arr; }
        }
        let box03Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box03Val5Array[i] = box03Val5arr; }
        }
        let box03Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box03Val6Array[i] = box03Val6arr; }
        }
        let box03Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box03Val7Array[i] = box03Val7arr; }
        }
        let box03Val8Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box03Val8Array[i] = box03Val8arr; }
        }
        let box03Val9Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box03Val9Array[i] = box03Val9arr; }
        }
        let box03Val10Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box03Val10Array[i] = box03Val10arr; }
        }
        let box04Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box04Val1Array[i] = box04Val1arr;
            }
        }
        let box04Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box04Val2Array[i] = box04Val2arr;
            }
        }
        let box04Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box04Val3Array[i] = box04Val3arr;
            }
        }
        let box04Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val4Array[i] = box04Val4arr; }
        }
        let box04Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val5Array[i] = box04Val5arr; }
        }
        let box04Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val6Array[i] = box04Val6arr; }
        }
        let box04Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val7Array[i] = box04Val7arr; }
        }
        let box04Val8Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val8Array[i] = box04Val8arr; }
        }
        let box04Val9Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val9Array[i] = box04Val9arr; }
        }
        let box04Val10Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val10Array[i] = box04Val10arr; }
        }
        let box04Val11Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val11Array[i] = box04Val11arr; }
        }
        let box04Val12Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val12Array[i] = box04Val12arr; }
        }
        let box04Val13Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val13Array[i] = box04Val13arr; }
        }
        let box04Val14Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val14Array[i] = box04Val14arr; }
        }
        let box04Val15Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val15Array[i] = box04Val15arr; }
        }
        let box04Val16Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val16Array[i] = box04Val16arr; }
        }
        let box04Val17Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val17Array[i] = box04Val17arr; }
        }
        let box04Val18Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val18Array[i] = box04Val18arr; }
        }
        let box04Val19Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val19Array[i] = box04Val19arr; }
        }
        let box04Val20Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val20Array[i] = box04Val20arr; }
        }
        let box04Val21Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val21Array[i] = box04Val21arr; }
        }
        let box04Val22Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val22Array[i] = box04Val22arr; }
        }
        let box04Val23Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val23Array[i] = box04Val23arr; }
        }
        let box04Val24Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val24Array[i] = box04Val24arr; }
        }
        let box04Val25Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val25Array[i] = box04Val25arr; }
        }
        let box04Val26Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val26Array[i] = box04Val26arr; }
        }
        let box04Val27Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val27Array[i] = box04Val27arr; }
        }
        let box04Val28Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val28Array[i] = box04Val28arr; }
        }
        let box04Val29Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val29Array[i] = box04Val29arr; }
        }
        let box04Val30Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val30Array[i] = box04Val30arr; }
        }
        let box04Val31Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val31Array[i] = box04Val31arr; }
        }
        let box04Val32Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val32Array[i] = box04Val32arr; }
        }
        let box04Val33Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val33Array[i] = box04Val33arr; }
        }
        let box04Val34Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val34Array[i] = box04Val34arr; }
        }
        let box04Val35Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val35Array[i] = box04Val35arr; }
        }
        let box04Val36Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val36Array[i] = box04Val36arr; }
        }
        let box04Val37Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val37Array[i] = box04Val37arr; }
        }
        let box04Val38Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val38Array[i] = box04Val38arr; }
        }
        let box04Val39Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val39Array[i] = box04Val39arr; }
        }
        let box04Val40Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val40Array[i] = box04Val40arr; }
        }
        let box04Val41Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val41Array[i] = box04Val41arr; }
        }
        let box04Val42Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val42Array[i] = box04Val42arr; }
        }
        let box04Val43Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val43Array[i] = box04Val43arr; }
        }
        let box04Val44Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val44Array[i] = box04Val44arr; }
        }
        let box04Val45Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val45Array[i] = box04Val45arr; }
        }
        let box04Val46Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val46Array[i] = box04Val46arr; }
        }
        let box04Val47Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val47Array[i] = box04Val47arr; }
        }
        let box04Val48Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box04Val48Array[i] = box04Val48arr; }
        }
        let box05Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box05Val1Array[i] = box05Val1arr;
            }
        }
        let box05Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box05Val2Array[i] = box05Val2arr;
            }
        }
        let box05Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box05Val3Array[i] = box05Val3arr;
            }
        }
        let box05Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val4Array[i] = box05Val4arr; }
        }
        let box05Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val5Array[i] = box05Val5arr; }
        }
        let box05Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val6Array[i] = box05Val6arr; }
        }
        let box05Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val7Array[i] = box05Val7arr; }
        }
        let box05Val8Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val8Array[i] = box05Val8arr; }
        }
        let box05Val9Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val9Array[i] = box05Val9arr; }
        }
        let box05Val10Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val10Array[i] = box05Val10arr; }
        }
        let box05Val11Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val11Array[i] = box05Val11arr; }
        }
        let box05Val12Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val12Array[i] = box05Val12arr; }
        }
        let box05Val13Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val13Array[i] = box05Val13arr; }
        }
        let box05Val14Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val14Array[i] = box05Val14arr; }
        }
        let box05Val15Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val15Array[i] = box05Val15arr; }
        }
        let box05Val16Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val16Array[i] = box05Val16arr; }
        }
        let box05Val17Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val17Array[i] = box05Val17arr; }
        }
        let box05Val18Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val18Array[i] = box05Val18arr; }
        }
        let box05Val19Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val19Array[i] = box05Val19arr; }
        }
        let box05Val20Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val20Array[i] = box05Val20arr; }
        }
        let box05Val21Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val21Array[i] = box05Val21arr; }
        }
        let box05Val22Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val22Array[i] = box05Val22arr; }
        }
        let box05Val23Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val23Array[i] = box05Val23arr; }
        }
        let box05Val24Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val24Array[i] = box05Val24arr; }
        }
        let box05Val25Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val25Array[i] = box05Val25arr; }
        }
        let box05Val26Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val26Array[i] = box05Val26arr; }
        }
        let box05Val27Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val27Array[i] = box05Val27arr; }
        }
        let box05Val28Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val28Array[i] = box05Val28arr; }
        }
        let box05Val29Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val29Array[i] = box05Val29arr; }
        }
        let box05Val30Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val30Array[i] = box05Val30arr; }
        }
        let box05Val31Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val31Array[i] = box05Val31arr; }
        }
        let box05Val32Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val32Array[i] = box05Val32arr; }
        }
        let box05Val33Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val33Array[i] = box05Val33arr; }
        }
        let box05Val34Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val34Array[i] = box05Val34arr; }
        }
        let box05Val35Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val35Array[i] = box05Val35arr; }
        }
        let box05Val36Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val36Array[i] = box05Val36arr; }
        }
        let box05Val37Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val37Array[i] = box05Val37arr; }
        }
        let box05Val38Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val38Array[i] = box05Val38arr; }
        }
        let box05Val39Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val39Array[i] = box05Val39arr; }
        }
        let box05Val40Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val40Array[i] = box05Val40arr; }
        }
        let box05Val41Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val41Array[i] = box05Val41arr; }
        }
        let box05Val42Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val42Array[i] = box05Val42arr; }
        }
        let box05Val43Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val43Array[i] = box05Val43arr; }
        }
        let box05Val44Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val44Array[i] = box05Val44arr; }
        }
        let box05Val45Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val45Array[i] = box05Val45arr; }
        }
        let box05Val46Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val46Array[i] = box05Val46arr; }
        }
        let box05Val47Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val47Array[i] = box05Val47arr; }
        }
        let box05Val48Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box05Val48Array[i] = box05Val48arr; }
        }
        let box06Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box06Val1Array[i] = box06Val1arr;
            }
        }
        let box06Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box06Val2Array[i] = box06Val2arr;
            }
        }
        let box06Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box06Val3Array[i] = box06Val3arr;
            }
        }
        let box06Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val4Array[i] = box06Val4arr; }
        }
        let box06Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val5Array[i] = box06Val5arr; }
        }
        let box06Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val6Array[i] = box06Val6arr; }
        }
        let box06Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val7Array[i] = box06Val7arr; }
        }
        let box06Val8Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val8Array[i] = box06Val8arr; }
        }
        let box06Val9Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val9Array[i] = box06Val9arr; }
        }
        let box06Val10Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val10Array[i] = box06Val10arr; }
        }
        let box06Val11Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val11Array[i] = box06Val11arr; }
        }
        let box06Val12Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val12Array[i] = box06Val12arr; }
        }
        let box06Val13Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val13Array[i] = box06Val13arr; }
        }
        let box06Val14Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val14Array[i] = box06Val14arr; }
        }
        let box06Val15Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val15Array[i] = box06Val15arr; }
        }
        let box06Val16Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val16Array[i] = box06Val16arr; }
        }
        let box06Val17Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val17Array[i] = box06Val17arr; }
        }
        let box06Val18Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val18Array[i] = box06Val18arr; }
        }
        let box06Val19Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val19Array[i] = box06Val19arr; }
        }
        let box06Val20Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val20Array[i] = box06Val20arr; }
        }
        let box06Val21Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val21Array[i] = box06Val21arr; }
        }
        let box06Val22Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val22Array[i] = box06Val22arr; }
        }
        let box06Val23Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val23Array[i] = box06Val23arr; }
        }
        let box06Val24Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val24Array[i] = box06Val24arr; }
        }
        let box06Val25Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val25Array[i] = box06Val25arr; }
        }
        let box06Val26Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val26Array[i] = box06Val26arr; }
        }
        let box06Val27Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val27Array[i] = box06Val27arr; }
        }
        let box06Val28Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val28Array[i] = box06Val28arr; }
        }
        let box06Val29Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val29Array[i] = box06Val29arr; }
        }
        let box06Val30Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val30Array[i] = box06Val30arr; }
        }
        let box06Val31Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val31Array[i] = box06Val31arr; }
        }
        let box06Val32Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val32Array[i] = box06Val32arr; }
        }
        let box06Val33Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val33Array[i] = box06Val33arr; }
        }
        let box06Val34Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val34Array[i] = box06Val34arr; }
        }
        let box06Val35Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val35Array[i] = box06Val35arr; }
        }
        let box06Val36Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val36Array[i] = box06Val36arr; }
        }
        let box06Val37Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val37Array[i] = box06Val37arr; }
        }
        let box06Val38Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val38Array[i] = box06Val38arr; }
        }
        let box06Val39Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val39Array[i] = box06Val39arr; }
        }
        let box06Val40Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val40Array[i] = box06Val40arr; }
        }
        let box06Val41Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val41Array[i] = box06Val41arr; }
        }
        let box06Val42Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val42Array[i] = box06Val42arr; }
        }
        let box06Val43Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val43Array[i] = box06Val43arr; }
        }
        let box06Val44Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val44Array[i] = box06Val44arr; }
        }
        let box06Val45Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val45Array[i] = box06Val45arr; }
        }
        let box06Val46Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val46Array[i] = box06Val46arr; }
        }
        let box06Val47Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val47Array[i] = box06Val47arr; }
        }
        let box06Val48Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box06Val48Array[i] = box06Val48arr; }
        }
        let box07Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box07Val1Array[i] = box07Val1arr;
            }
        }
        let box07Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box07Val2Array[i] = box07Val2arr;
            }
        }
        let box07Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box07Val3Array[i] = box07Val3arr;
            }
        }
        let box07Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val4Array[i] = box07Val4arr; }
        }
        let box07Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val5Array[i] = box07Val5arr; }
        }
        let box07Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val6Array[i] = box07Val6arr; }
        }
        let box07Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val7Array[i] = box07Val7arr; }
        }
        let box07Val8Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val8Array[i] = box07Val8arr; }
        }
        let box07Val9Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val9Array[i] = box07Val9arr; }
        }
        let box07Val10Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val10Array[i] = box07Val10arr; }
        }
        let box07Val11Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val11Array[i] = box07Val11arr; }
        }
        let box07Val12Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val12Array[i] = box07Val12arr; }
        }
        let box07Val13Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val13Array[i] = box07Val13arr; }
        }
        let box07Val14Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val14Array[i] = box07Val14arr; }
        }
        let box07Val15Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val15Array[i] = box07Val15arr; }
        }
        let box07Val16Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val16Array[i] = box07Val16arr; }
        }
        let box07Val17Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val17Array[i] = box07Val17arr; }
        }
        let box07Val18Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val18Array[i] = box07Val18arr; }
        }
        let box07Val19Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val19Array[i] = box07Val19arr; }
        }
        let box07Val20Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val20Array[i] = box07Val20arr; }
        }
        let box07Val21Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val21Array[i] = box07Val21arr; }
        }
        let box07Val22Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val22Array[i] = box07Val22arr; }
        }
        let box07Val23Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val23Array[i] = box07Val23arr; }
        }
        let box07Val24Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val24Array[i] = box07Val24arr; }
        }
        let box07Val25Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val25Array[i] = box07Val25arr; }
        }
        let box07Val26Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val26Array[i] = box07Val26arr; }
        }
        let box07Val27Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val27Array[i] = box07Val27arr; }
        }
        let box07Val28Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val28Array[i] = box07Val28arr; }
        }
        let box07Val29Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val29Array[i] = box07Val29arr; }
        }
        let box07Val30Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val30Array[i] = box07Val30arr; }
        }
        let box07Val31Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val31Array[i] = box07Val31arr; }
        }
        let box07Val32Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val32Array[i] = box07Val32arr; }
        }
        let box07Val33Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val33Array[i] = box07Val33arr; }
        }
        let box07Val34Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val34Array[i] = box07Val34arr; }
        }
        let box07Val35Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val35Array[i] = box07Val35arr; }
        }
        let box07Val36Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val36Array[i] = box07Val36arr; }
        }
        let box07Val37Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val37Array[i] = box07Val37arr; }
        }
        let box07Val38Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val38Array[i] = box07Val38arr; }
        }
        let box07Val39Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val39Array[i] = box07Val39arr; }
        }
        let box07Val40Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val40Array[i] = box07Val40arr; }
        }
        let box07Val41Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val41Array[i] = box07Val41arr; }
        }
        let box07Val42Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val42Array[i] = box07Val42arr; }
        }
        let box07Val43Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val43Array[i] = box07Val43arr; }
        }
        let box07Val44Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val44Array[i] = box07Val44arr; }
        }
        let box07Val45Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val45Array[i] = box07Val45arr; }
        }
        let box07Val46Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val46Array[i] = box07Val46arr; }
        }
        let box07Val47Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val47Array[i] = box07Val47arr; }
        }
        let box07Val48Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box07Val48Array[i] = box07Val48arr; }
        }
        let box08Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box08Val1Array[i] = box08Val1arr;
            }
        }
        let box08Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box08Val2Array[i] = box08Val2arr;
            }
        }
        let box08Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box08Val3Array[i] = box08Val3arr;
            }
        }
        let box08Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box08Val4Array[i] = box08Val4arr; }
        }
        let box08Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box08Val5Array[i] = box08Val5arr; }
        }
        let box08Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box08Val6Array[i] = box08Val6arr; }
        }
        let box08Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box08Val7Array[i] = box08Val7arr; }
        }
        let box09Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box09Val1Array[i] = box09Val1arr;
            }
        }
        let box09Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box09Val2Array[i] = box09Val2arr;
            }
        }
        let box09Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box09Val3Array[i] = box09Val3arr;
            }
        }
        let box09Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box09Val4Array[i] = box09Val4arr; }
        }
        let box09Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box09Val5Array[i] = box09Val5arr; }
        }
        let box09Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box09Val6Array[i] = box09Val6arr; }
        }
        let box09Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box09Val7Array[i] = box09Val7arr; }
        }
        let box10Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box10Val1Array[i] = box10Val1arr;
            }
        }
        let box10Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box10Val2Array[i] = box10Val2arr;
            }
        }
        let box10Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box10Val3Array[i] = box10Val3arr;
            }
        }
        let box10Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box10Val4Array[i] = box10Val4arr; }
        }
        let box10Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box10Val5Array[i] = box10Val5arr; }
        }
        let box10Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box10Val6Array[i] = box10Val6arr; }
        }
        let box10Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box10Val7Array[i] = box10Val7arr; }
        }
        let box11Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box11Val1Array[i] = box11Val1arr;
            }
        }
        let box11Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box11Val2Array[i] = box11Val2arr;
            }
        }
        let box11Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box11Val3Array[i] = box11Val3arr;
            }
        }
        let box11Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box11Val4Array[i] = box11Val4arr; }
        }
        let box11Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box11Val5Array[i] = box11Val5arr; }
        }
        let box11Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box11Val6Array[i] = box11Val6arr; }
        }
        let box11Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box11Val7Array[i] = box11Val7arr; }
        }
        let box12Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box12Val1Array[i] = box12Val1arr;
            }
        }
        let box12Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box12Val2Array[i] = box12Val2arr;
            }
        }
        let box12Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box12Val3Array[i] = box12Val3arr;
            }
        }
        let box12Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box12Val4Array[i] = box12Val4arr; }
        }
        let box12Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box12Val5Array[i] = box12Val5arr; }
        }
        let box12Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box12Val6Array[i] = box12Val6arr; }
        }
        let box12Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box12Val7Array[i] = box12Val7arr; }
        }
        let box13Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box13Val1Array[i] = box13Val1arr;
            }
        }
        let box13Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box13Val2Array[i] = box13Val2arr;
            }
        }
        let box13Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box13Val3Array[i] = box13Val3arr;
            }
        }
        let box13Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box13Val4Array[i] = box13Val4arr; }
        }
        let box13Val5Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box13Val5Array[i] = box13Val5arr; }
        }
        let box13Val6Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box13Val6Array[i] = box13Val6arr; }
        }
        let box13Val7Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box13Val7Array[i] = box13Val7arr; }
        }
        let box14Val1Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box14Val1Array[i] = box14Val1arr;
            }
        }
        let box14Val2Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box14Val2Array[i] = box14Val2arr;
            }
        }
        let box14Val3Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){
                box14Val3Array[i] = box14Val3arr;
            }
        }
        let box14Val4Array = [];
        for ( let i = 0; i < endIdx; i++){
            if( i > startIdx ){ box14Val4Array[i] = box14Val4arr; }
        }



        dispatch(setBox01Val1array(box01Val1Array))
        dispatch(setBox01Val2array(box01Val2Array))
        dispatch(setBox01Val3array(box01Val3Array))
        dispatch(setBox01Val4array(box01Val4Array))
        dispatch(setBox01Val5array(box01Val5Array))
        dispatch(setBox01Val6array(box01Val6Array))
        dispatch(setBox01Val7array(box01Val7Array))
        dispatch(setBox02Val1array(box02Val1Array))
        dispatch(setBox02Val2array(box02Val2Array))
        dispatch(setBox02Val3array(box02Val3Array))
        dispatch(setBox02Val4array(box02Val4Array))
        dispatch(setBox02Val5array(box02Val5Array))
        dispatch(setBox02Val6array(box02Val6Array))
        dispatch(setBox02Val7array(box02Val7Array))
        dispatch(setBox02Val8array(box02Val8Array))
        dispatch(setBox02Val9array(box02Val9Array))
        dispatch(setBox02Val10array(box02Val10Array))
        dispatch(setBox02Val11array(box02Val11Array))
        dispatch(setBox02Val12array(box02Val12Array))
        dispatch(setBox02Val13array(box02Val13Array))
        dispatch(setBox03Val1array(box03Val1Array))
        dispatch(setBox03Val2array(box03Val2Array))
        dispatch(setBox03Val3array(box03Val3Array))
        dispatch(setBox03Val4array(box03Val4Array))
        dispatch(setBox03Val5array(box03Val5Array))
        dispatch(setBox03Val6array(box03Val6Array))
        dispatch(setBox03Val7array(box03Val7Array))
        dispatch(setBox03Val8array(box03Val8Array))
        dispatch(setBox03Val9array(box03Val9Array))
        dispatch(setBox03Val10array(box03Val10Array))
        dispatch(setBox04Val1array(box04Val1Array))
        dispatch(setBox04Val2array(box04Val2Array))
        dispatch(setBox04Val3array(box04Val3Array))
        dispatch(setBox04Val4array(box04Val4Array))
        dispatch(setBox04Val5array(box04Val5Array))
        dispatch(setBox04Val6array(box04Val6Array))
        dispatch(setBox04Val7array(box04Val7Array))
        dispatch(setBox04Val8array(box04Val8Array))
        dispatch(setBox04Val9array(box04Val9Array))
        dispatch(setBox04Val10array(box04Val10Array))
        dispatch(setBox04Val11array(box04Val11Array))
        dispatch(setBox04Val12array(box04Val12Array))
        dispatch(setBox04Val13array(box04Val13Array))
        dispatch(setBox04Val14array(box04Val14Array))
        dispatch(setBox04Val15array(box04Val15Array))
        dispatch(setBox04Val16array(box04Val16Array))
        dispatch(setBox04Val17array(box04Val17Array))
        dispatch(setBox04Val18array(box04Val18Array))
        dispatch(setBox04Val19array(box04Val19Array))
        dispatch(setBox04Val20array(box04Val20Array))
        dispatch(setBox04Val21array(box04Val21Array))
        dispatch(setBox04Val22array(box04Val22Array))
        dispatch(setBox04Val23array(box04Val23Array))
        dispatch(setBox04Val24array(box04Val24Array))
        dispatch(setBox04Val25array(box04Val25Array))
        dispatch(setBox04Val26array(box04Val26Array))
        dispatch(setBox04Val27array(box04Val27Array))
        dispatch(setBox04Val28array(box04Val28Array))
        dispatch(setBox04Val29array(box04Val29Array))
        dispatch(setBox04Val30array(box04Val30Array))
        dispatch(setBox04Val31array(box04Val31Array))
        dispatch(setBox04Val32array(box04Val32Array))
        dispatch(setBox04Val33array(box04Val33Array))
        dispatch(setBox04Val34array(box04Val34Array))
        dispatch(setBox04Val35array(box04Val35Array))
        dispatch(setBox04Val36array(box04Val36Array))
        dispatch(setBox04Val37array(box04Val37Array))
        dispatch(setBox04Val38array(box04Val38Array))
        dispatch(setBox04Val39array(box04Val39Array))
        dispatch(setBox04Val40array(box04Val40Array))
        dispatch(setBox04Val41array(box04Val41Array))
        dispatch(setBox04Val42array(box04Val42Array))
        dispatch(setBox04Val43array(box04Val43Array))
        dispatch(setBox04Val44array(box04Val44Array))
        dispatch(setBox04Val45array(box04Val45Array))
        dispatch(setBox04Val46array(box04Val46Array))
        dispatch(setBox04Val47array(box04Val47Array))
        dispatch(setBox04Val48array(box04Val48Array))
        dispatch(setBox05Val1array(box05Val1Array))
        dispatch(setBox05Val2array(box05Val2Array))
        dispatch(setBox05Val3array(box05Val3Array))
        dispatch(setBox05Val4array(box05Val4Array))
        dispatch(setBox05Val5array(box05Val5Array))
        dispatch(setBox05Val6array(box05Val6Array))
        dispatch(setBox05Val7array(box05Val7Array))
        dispatch(setBox05Val8array(box05Val8Array))
        dispatch(setBox05Val9array(box05Val9Array))
        dispatch(setBox05Val10array(box05Val10Array))
        dispatch(setBox05Val11array(box05Val11Array))
        dispatch(setBox05Val12array(box05Val12Array))
        dispatch(setBox05Val13array(box05Val13Array))
        dispatch(setBox05Val14array(box05Val14Array))
        dispatch(setBox05Val15array(box05Val15Array))
        dispatch(setBox05Val16array(box05Val16Array))
        dispatch(setBox05Val17array(box05Val17Array))
        dispatch(setBox05Val18array(box05Val18Array))
        dispatch(setBox05Val19array(box05Val19Array))
        dispatch(setBox05Val20array(box05Val20Array))
        dispatch(setBox05Val21array(box05Val21Array))
        dispatch(setBox05Val22array(box05Val22Array))
        dispatch(setBox05Val23array(box05Val23Array))
        dispatch(setBox05Val24array(box05Val24Array))
        dispatch(setBox05Val25array(box05Val25Array))
        dispatch(setBox05Val26array(box05Val26Array))
        dispatch(setBox05Val27array(box05Val27Array))
        dispatch(setBox05Val28array(box05Val28Array))
        dispatch(setBox05Val29array(box05Val29Array))
        dispatch(setBox05Val30array(box05Val30Array))
        dispatch(setBox05Val31array(box05Val31Array))
        dispatch(setBox05Val32array(box05Val32Array))
        dispatch(setBox05Val33array(box05Val33Array))
        dispatch(setBox05Val34array(box05Val34Array))
        dispatch(setBox05Val35array(box05Val35Array))
        dispatch(setBox05Val36array(box05Val36Array))
        dispatch(setBox05Val37array(box05Val37Array))
        dispatch(setBox05Val38array(box05Val38Array))
        dispatch(setBox05Val39array(box05Val39Array))
        dispatch(setBox05Val40array(box05Val40Array))
        dispatch(setBox05Val41array(box05Val41Array))
        dispatch(setBox05Val42array(box05Val42Array))
        dispatch(setBox05Val43array(box05Val43Array))
        dispatch(setBox05Val44array(box05Val44Array))
        dispatch(setBox05Val45array(box05Val45Array))
        dispatch(setBox05Val46array(box05Val46Array))
        dispatch(setBox05Val47array(box05Val47Array))
        dispatch(setBox05Val48array(box05Val48Array))
        dispatch(setBox06Val1array(box06Val1Array))
        dispatch(setBox06Val2array(box06Val2Array))
        dispatch(setBox06Val3array(box06Val3Array))
        dispatch(setBox06Val4array(box06Val4Array))
        dispatch(setBox06Val5array(box06Val5Array))
        dispatch(setBox06Val6array(box06Val6Array))
        dispatch(setBox06Val7array(box06Val7Array))
        dispatch(setBox06Val8array(box06Val8Array))
        dispatch(setBox06Val9array(box06Val9Array))
        dispatch(setBox06Val10array(box06Val10Array))
        dispatch(setBox06Val11array(box06Val11Array))
        dispatch(setBox06Val12array(box06Val12Array))
        dispatch(setBox06Val13array(box06Val13Array))
        dispatch(setBox06Val14array(box06Val14Array))
        dispatch(setBox06Val15array(box06Val15Array))
        dispatch(setBox06Val16array(box06Val16Array))
        dispatch(setBox06Val17array(box06Val17Array))
        dispatch(setBox06Val18array(box06Val18Array))
        dispatch(setBox06Val19array(box06Val19Array))
        dispatch(setBox06Val20array(box06Val20Array))
        dispatch(setBox06Val21array(box06Val21Array))
        dispatch(setBox06Val22array(box06Val22Array))
        dispatch(setBox06Val23array(box06Val23Array))
        dispatch(setBox06Val24array(box06Val24Array))
        dispatch(setBox06Val25array(box06Val25Array))
        dispatch(setBox06Val26array(box06Val26Array))
        dispatch(setBox06Val27array(box06Val27Array))
        dispatch(setBox06Val28array(box06Val28Array))
        dispatch(setBox06Val29array(box06Val29Array))
        dispatch(setBox06Val30array(box06Val30Array))
        dispatch(setBox06Val31array(box06Val31Array))
        dispatch(setBox06Val32array(box06Val32Array))
        dispatch(setBox06Val33array(box06Val33Array))
        dispatch(setBox06Val34array(box06Val34Array))
        dispatch(setBox06Val35array(box06Val35Array))
        dispatch(setBox06Val36array(box06Val36Array))
        dispatch(setBox06Val37array(box06Val37Array))
        dispatch(setBox06Val38array(box06Val38Array))
        dispatch(setBox06Val39array(box06Val39Array))
        dispatch(setBox06Val40array(box06Val40Array))
        dispatch(setBox06Val41array(box06Val41Array))
        dispatch(setBox06Val42array(box06Val42Array))
        dispatch(setBox06Val43array(box06Val43Array))
        dispatch(setBox06Val44array(box06Val44Array))
        dispatch(setBox06Val45array(box06Val45Array))
        dispatch(setBox06Val46array(box06Val46Array))
        dispatch(setBox06Val47array(box06Val47Array))
        dispatch(setBox06Val48array(box06Val48Array))
        dispatch(setBox07Val1array(box07Val1Array))
        dispatch(setBox07Val2array(box07Val2Array))
        dispatch(setBox07Val3array(box07Val3Array))
        dispatch(setBox07Val4array(box07Val4Array))
        dispatch(setBox07Val5array(box07Val5Array))
        dispatch(setBox07Val6array(box07Val6Array))
        dispatch(setBox07Val7array(box07Val7Array))
        dispatch(setBox07Val8array(box07Val8Array))
        dispatch(setBox07Val9array(box07Val9Array))
        dispatch(setBox07Val10array(box07Val10Array))
        dispatch(setBox07Val11array(box07Val11Array))
        dispatch(setBox07Val12array(box07Val12Array))
        dispatch(setBox07Val13array(box07Val13Array))
        dispatch(setBox07Val14array(box07Val14Array))
        dispatch(setBox07Val15array(box07Val15Array))
        dispatch(setBox07Val16array(box07Val16Array))
        dispatch(setBox07Val17array(box07Val17Array))
        dispatch(setBox07Val18array(box07Val18Array))
        dispatch(setBox07Val19array(box07Val19Array))
        dispatch(setBox07Val20array(box07Val20Array))
        dispatch(setBox07Val21array(box07Val21Array))
        dispatch(setBox07Val22array(box07Val22Array))
        dispatch(setBox07Val23array(box07Val23Array))
        dispatch(setBox07Val24array(box07Val24Array))
        dispatch(setBox07Val25array(box07Val25Array))
        dispatch(setBox07Val26array(box07Val26Array))
        dispatch(setBox07Val27array(box07Val27Array))
        dispatch(setBox07Val28array(box07Val28Array))
        dispatch(setBox07Val29array(box07Val29Array))
        dispatch(setBox07Val30array(box07Val30Array))
        dispatch(setBox07Val31array(box07Val31Array))
        dispatch(setBox07Val32array(box07Val32Array))
        dispatch(setBox07Val33array(box07Val33Array))
        dispatch(setBox07Val34array(box07Val34Array))
        dispatch(setBox07Val35array(box07Val35Array))
        dispatch(setBox07Val36array(box07Val36Array))
        dispatch(setBox07Val37array(box07Val37Array))
        dispatch(setBox07Val38array(box07Val38Array))
        dispatch(setBox07Val39array(box07Val39Array))
        dispatch(setBox07Val40array(box07Val40Array))
        dispatch(setBox07Val41array(box07Val41Array))
        dispatch(setBox07Val42array(box07Val42Array))
        dispatch(setBox07Val43array(box07Val43Array))
        dispatch(setBox07Val44array(box07Val44Array))
        dispatch(setBox07Val45array(box07Val45Array))
        dispatch(setBox07Val46array(box07Val46Array))
        dispatch(setBox07Val47array(box07Val47Array))
        dispatch(setBox07Val48array(box07Val48Array))
        dispatch(setBox08Val1array(box08Val1Array))
        dispatch(setBox08Val2array(box08Val2Array))
        dispatch(setBox08Val3array(box08Val3Array))
        dispatch(setBox08Val4array(box08Val4Array))
        dispatch(setBox08Val5array(box08Val5Array))
        dispatch(setBox08Val6array(box08Val6Array))
        dispatch(setBox08Val7array(box08Val7Array))
        dispatch(setBox09Val1array(box09Val1Array))
        dispatch(setBox09Val2array(box09Val2Array))
        dispatch(setBox09Val3array(box09Val3Array))
        dispatch(setBox09Val4array(box09Val4Array))
        dispatch(setBox09Val5array(box09Val5Array))
        dispatch(setBox09Val6array(box09Val6Array))
        dispatch(setBox09Val7array(box09Val7Array))
        dispatch(setBox10Val1array(box10Val1Array))
        dispatch(setBox10Val2array(box10Val2Array))
        dispatch(setBox10Val3array(box10Val3Array))
        dispatch(setBox10Val4array(box10Val4Array))
        dispatch(setBox10Val5array(box10Val5Array))
        dispatch(setBox10Val6array(box10Val6Array))
        dispatch(setBox10Val7array(box10Val7Array))
        dispatch(setBox11Val1array(box11Val1Array))
        dispatch(setBox11Val2array(box11Val2Array))
        dispatch(setBox11Val3array(box11Val3Array))
        dispatch(setBox11Val4array(box11Val4Array))
        dispatch(setBox11Val5array(box11Val5Array))
        dispatch(setBox11Val6array(box11Val6Array))
        dispatch(setBox11Val7array(box11Val7Array))
        dispatch(setBox12Val1array(box12Val1Array))
        dispatch(setBox12Val2array(box12Val2Array))
        dispatch(setBox12Val3array(box12Val3Array))
        dispatch(setBox12Val4array(box12Val4Array))
        dispatch(setBox12Val5array(box12Val5Array))
        dispatch(setBox12Val6array(box12Val6Array))
        dispatch(setBox12Val7array(box12Val7Array))
        dispatch(setBox13Val1array(box13Val1Array))
        dispatch(setBox13Val2array(box13Val2Array))
        dispatch(setBox13Val3array(box13Val3Array))
        dispatch(setBox13Val4array(box13Val4Array))
        dispatch(setBox13Val5array(box13Val5Array))
        dispatch(setBox13Val6array(box13Val6Array))
        dispatch(setBox13Val7array(box13Val7Array))
        dispatch(setBox14Val1array(box14Val1Array))
        dispatch(setBox14Val2array(box14Val2Array))
        dispatch(setBox14Val3array(box14Val3Array))
        dispatch(setBox14Val4array(box14Val4Array))

        //alert("initialState.arrayList >> "+JSON.stringify(initialState.arrayList));
        //alert("array >> "+JSON.stringify(array));
        //initialState.arrayList = array;
    }


    const insertState = (noArr,etcArr,tagNmArr,colorNmArr,insertNoArr,insertEtcArr,insertTagNmArr,insertColorNmArr) => {
        // alert("noArr:::"+JSON.stringify(noArr));
        // alert("etcArr:::"+JSON.stringify(etcArr));
        // alert("tagNmArr:::"+JSON.stringify(tagNmArr));
        // alert("colorNmArr:::"+JSON.stringify(colorNmArr));
        //
        // alert("insertNoArr:::"+JSON.stringify(insertNoArr));
        // alert("insertEtcArr:::"+JSON.stringify(insertEtcArr));
        // alert("insertTagNmArr:::"+JSON.stringify(insertTagNmArr));
        // alert("insertColorNmArr:::"+JSON.stringify(insertColorNmArr));

        if (window.confirm("저장 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveTagSettingList', {
                adminNo: userInfoId,
                noArr: noArr,
                etcArr: etcArr,
                tagNmArr: tagNmArr,
                colorNmArr: colorNmArr,
                insertNoArr: insertNoArr,
                insertEtcArr: insertEtcArr,
                insertTagNmArr: insertTagNmArr,
                insertColorNmArr: insertColorNmArr
            }).then((res) => {
                alert(res.data.isSaved? "저장완료" : "저장실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }






    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }

    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)





    return <>
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">레벨별설정</font>
                </div>


            </div>
        </div>



        <LevelSetting levelSetting={initialState.levelSetting}  countList={initialState.countList} onRefresh={onRefresh} initialState={initialState} deleteState={deleteState} insertState={insertState} ref={childComponentRef} arrayList={initialState.arrayList} setArrayList={setArrayList}/>

    </>





}
export default LevelSettingContainer