import React from 'react';
import PointStatContainer from "../../containers/deposit/PointStatContainer";


const PointStat = () => {
    return (
        <div>
            <PointStatContainer />
        </div>
    );
};

export default PointStat;