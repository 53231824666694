import React from 'react';
import InplayResultContainer from "../../../containers/game/manage/InplayResultContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const InplayResult = () => {
    return (
        <div className="common">
            <font color={"blue"} size="3">인플레이종료</font>
            <InplayResultContainer />
        </div>
    );
};

export default InplayResult;