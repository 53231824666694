import React from "react";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {setCheckedInputs} from "../../../redux/reducers/game/manage/fixture";

const FixtureList = ({state}) => {
    const dispatch = useDispatch()

    const statusToString = {
        1: "경기시작전",
        2: "경기중",
        3: "경기종료",
        4: "경기취소",
        5: "경기연기",
        6: "경기중단",
        7: "경기폐기",
        8: "경기이상",
        9: "경기곧시작",
    }

    const changeHandler = (checked, id, index) => {
        if(checked) {
            dispatch(setCheckedInputs([...state.checkedInputs, id]));
        }
        else {
            dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== id)));
        }
    };

    const allChangeHandler = (e) => {
        const check_ids = []
        for(let index = 0; index < state.fixtures.length; index++) {
            check_ids.push(state.fixtures[index].id)
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []));
    }


    return (
        <div className="container-fluid">
            {state.isLoading && "로딩중..."}
            {!state.isLoading && state.fixtures && (
                <table className="table table-bordered table-hover text-center">
                <thead>
                <tr>
                    <th>종목</th>
                    <th>경기일정</th>
                    <th>상태</th>
                    <th>국가</th>
                    <th>리그</th>
                    <th>홈팀명</th>
                    <th>원정팀명</th>
                    <th>FixID</th>
                </tr>
                </thead>
                <tbody>
                    {!state.fixtures.length && (
                        <tr>
                            <td colSpan="3">목록이 비어있습니다.</td>
                        </tr>
                    )}
                    {!!state.fixtures.length && state.fixtures.map((fixture, index) => (
                        <tr className={state.checkedInputs.includes(fixture.id)? "info" : ""}>
                            <td>{fixture.home.league.sport.name}</td>
                            <td>{moment(fixture.start_date).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{statusToString[fixture.status]}</td>
                            <td>{fixture.home.league.location.name}</td>
                            <td>{fixture.home.league.name}</td>
                            <td>
                                {fixture.home.name}
                            </td>
                            <td>{fixture.away.name}</td>
                            <td>{fixture.id}</td>

                        </tr>
                    ))}
                </tbody>
            </table>
            )}
        </div>
    );

}

export default FixtureList;