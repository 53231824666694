import React, {useEffect, useState } from 'react';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setBankList,setCmmnCd,setId,setBankCd,setAccountName,setNickname,setMbphStart,setMbphCenter,setMbphEnd,setAccount,setBlackTypeCd,setMessangeInfo,setDamageAmt,setBlackMemo,setMemberYnText,setMemberYnTextColor,setDisableYn,resetState} from "../../../redux/reducers/user/BlackMemberAddModalReducer";
import {useParams} from "react-router-dom";
const BlackMemberAddModal = ({userId}) => {
    const param = useParams()
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.BlackMemberAddModalReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)

    const getCmmnCdList = () => {
        dispatch(setId(param.userId))
        axios.post('/admin/cmmnCdList', {
            cmmnCdId : '0006',
        }).then((res) => {
            if(res.data.cmmnCdList !== null) {
                console.log("res.data.cmmnCdList",res.data.cmmnCdList);
                dispatch(setCmmnCd(res.data.cmmnCdList))
            }
            else {
                alert('공통코드조회실패')
            }
        })

        if(initialState.bankList.length === 0){
            axios.post('/admin/member/getBank', {
                cmmnCdId : '01',
            }).then((res) => {
                dispatch(setBankList(res.data.cmmnCdList));
            })
        }
    }
    useEffect(getCmmnCdList, [dispatch])

    //취소버튼클릭
    const handleCancel = () => {
        dispatch(resetState())
        window.close()
    }

    const handleRegister = () => {
        if (window.confirm("등록하시겠습니까? ")) {
            axios.post('/admin/user/BlackMemberAddModal/Insert', {
                id : initialState.id,
                nickname : initialState.nickname,
                mbphStart : initialState.mbphStart,
                mbphCenter : initialState.mbphCenter,
                mbphEnd : initialState.mbphEnd,
                bankCd : initialState.bankCd,
                accountName : initialState.accountName,
                account : initialState.account,
                blackTypeCd : initialState.blackTypeCd,
                messangeInfo : initialState.messangeInfo,
                damageAmt : initialState.damageAmt,
                blackMemo : initialState.blackMemo,
                regId : userInfoId,
                updId : userInfoId,
            }).then((res) => {
                if(res.data.isInserted) {
                    alert('블랙회원등록완료')
                    handleCancel()
                }
                else {
                    alert('블랙회원등록실패')
                    handleCancel()
                }
            })
        }
    }

    const onIdHandler = (e) => {
        dispatch(setId(e.target.value))
    }
    const onBlackTypeCdHandler = (e) => {
        dispatch(setBlackTypeCd(e.target.value))
    }
    const onMessangeInfoHandler = (e) => {
        dispatch(setMessangeInfo(e.target.value))
    }
    const onDamageAmtHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setDamageAmt(value))
        }
    }
    const onBlackMemoHandler = (e) => {
        dispatch(setBlackMemo(e.target.value))
    }

    const onChangenNickname = (e) => {
        dispatch(setNickname(e.target.value))
    }

    const onChangeMbphStart = (e) => {
        dispatch(setMbphStart(e.target.value))
    }

    const onChangeMbphCenter = (e) => {
        dispatch(setMbphCenter(e.target.value))
    }

    const onChangeMbphEnd = (e) => {
        dispatch(setMbphEnd(e.target.value))
    }

    const onChangeBankCd = (e) => {
        dispatch(setBankCd(e.target.value))
    }

    const onChangeAccountName = (e) => {
        dispatch(setAccountName(e.target.value))
    }

    const onChangeAccount = (e) => {
        dispatch(setAccount(e.target.value))
    }

    const memberYnStyle ={
        color: initialState.memberYnTextColor, // 리덕스 상태에서 가져온 값
        marginLeft: '4px',
    }

    return (
        <div className="common">
            <div className="row" style={{ marginTop: '5px' }}>
                <div className="col-2">
                    <h6>블랙회원등록</h6>
                </div>
            </div>
            <div>
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <th style={{   fontSize: '11px', backgroundColor: '#E6E6E6', border: '1px solid #D7D4CF', verticalAlign: 'middle', textAlign: 'center', height: '30px' }}>블랙등록이란?
                        </th>
                        <td style={{  fontSize: '11px', border: '1px solid #D7D4CF', verticalAlign: 'middle', height: '30px',}}>
                            * 회원 목록에서 삭제되며, 블랙리스트에 등록됩니다. (삭제되었으므로 이용 불가 상태가 됩니다)<br />
                            * 블랙 회원이 사용한 아이디에 대해 회원 목록에 블랙 표시가 나타납니다.<br />
                            * 접속중이었다면 접속이 끊어집니다.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style={{width: '100%',marginTop: '5px' }}>
                <span style={{ color: 'red',width: '120px', marginRight: '510px'}}>* 아래 모든 정보를 입력하실 필요는 없습니다.</span><br />
                <span style={{ color: 'red',width: '120px' ,marginRight: '557px'}}>* 입력 가능한 정보만 입력해주세요.</span>
            </div>
            <div style={{marginTop: '5px' }}>
                <table>
                    <tbody>
                    <tr>
                        <th colSpan='2' style={{  fontSize: '11px', backgroundColor: '#E6E6E6', border: '1px solid #D7D4CF', verticalAlign: 'middle', textAlign: 'center', height: '30px'}}>
                            블랙저장정보
                        </th>
                    </tr>
                    <tr>
                        <th style={{  fontSize: '11px', backgroundColor: '#E6E6E6', border: '1px solid #D7D4CF', verticalAlign: 'middle', textAlign: 'center', height: '30px',width:'12%'}}>아이디</th><td><input disabled={initialState.disableYn} onChange={onIdHandler} value={initialState.id}/>
                        <span style={memberYnStyle}>{initialState.memberYnText}</span>
                    </td>
                    </tr>
                    <tr>
                        <th style={{  fontSize: '11px', backgroundColor: '#E6E6E6', border: '1px solid #D7D4CF', verticalAlign: 'middle', textAlign: 'center', height: '30px',width:'12%'}}>닉네임</th>
                        <td><input onChange={onChangenNickname} value={initialState.nickname}/>
                        </td>
                    </tr>
                    <tr>
                        <th style={{  fontSize: '11px', backgroundColor: '#E6E6E6', border: '1px solid #D7D4CF', verticalAlign: 'middle', textAlign: 'center', height: '30px',width:'12%'}}>핸드폰</th>
                        <td><input onChange={onChangeMbphStart} value={initialState.mbphStart}/>-
                            <input onChange={onChangeMbphCenter} value={initialState.mbphCenter}/>-
                            <input onChange={onChangeMbphEnd} value={initialState.mbphEnd}/>
                        </td>
                    </tr>
                    <tr>
                        <th style={{  fontSize: '11px', backgroundColor: '#E6E6E6', border: '1px solid #D7D4CF', verticalAlign: 'middle', textAlign: 'center', height: '30px',width:'12%'}}>은행</th>
                        <td>
                            <div className="container-fluid p-0 mx-1" style={{display: "flex"}}>
                                <span>은행</span>&nbsp;
                                <select
                                    className="form-select sm-select"
                                    defaultValue={'01'}
                                    style={{
                                        width: "140px"
                                    }}
                                    onChange={onChangeBankCd} value={initialState.bankCd}
                                >
                                    {initialState.bankList?.map((item, index) => (
                                        <option key={item.bank_cd} value={item.bank_cd}>{item.tag_nm}</option>
                                    ))}
                                </select>
                            </div>
                            <span>예금주명</span><input onChange={onChangeAccountName} value={initialState.accountName}/><br></br>
                            <span>계좌번호</span><input onChange={onChangeAccount} value={initialState.account}/><br></br>
                        </td>
                    </tr>
                    <tr>
                        <th style={{  fontSize: '11px', backgroundColor: '#E6E6E6', border: '1px solid #D7D4CF', verticalAlign: 'middle', textAlign: 'center', height: '30px'}}>블랙구분</th>
                        <td style={{alignItems: 'center' }}>
                            {initialState.cmmnCdList?.map(item => (
                                <React.Fragment key={item.cmmn_cd_dtl_id}>
                                    <input
                                        type="radio"
                                        name="flexRadioKMoney"
                                        id={`flexRadioK${item.cmmn_cd_dtl_id}`}
                                        value={item.cmmn_cd_dtl_id}
                                        style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                        onChange={onBlackTypeCdHandler}
                                        checked={item.cmmn_cd_dtl_id === initialState.blackTypeCd}
                                    />
                                    <label
                                        htmlFor={`flexRadioK${item.cmmn_cd_dtl_id}`}
                                        style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                    >
                                        {item.cmmn_cd_dtl_nm}
                                    </label>
                                </React.Fragment>
                            ))}
                        </td>
                    </tr>
                    <tr>
                        <th style={{  fontSize: '11px', backgroundColor: '#E6E6E6', border: '1px solid #D7D4CF', verticalAlign: 'middle', textAlign: 'center', height: '30px'}}>사용 메신저 이름/닉네임/아이디 정보</th>
                        <td><input onChange={onMessangeInfoHandler}/></td>
                    </tr>
                    <tr>
                        <th style={{  fontSize: '11px', backgroundColor: '#E6E6E6', border: '1px solid #D7D4CF', verticalAlign: 'middle', textAlign: 'center', height: '30px'}}>피해금액</th>
                        <td><input style={{textAlign: 'right'}} value={initialState.damageAmt} onChange={onDamageAmtHandler}/></td>
                    </tr>
                    <tr style={{height:'300px'}}>
                        <th style={{  fontSize: '11px', backgroundColor: '#E6E6E6', border: '1px solid #D7D4CF', verticalAlign: 'middle', textAlign: 'center', height: '30px'}}>블랙사유메모</th>
                        <td><textarea style={{height:'300px',width:'650px'}}  placeholder={initialState.blackMemo} onChange={onBlackMemoHandler}/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style={{height:'40px',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                <button style={{width:'70px',height:'25px',color:"white",backgroundColor:"#5DC434",border:"none",alignItems: 'center'}} onClick={handleRegister}>블랙등록</button>
                <button style={{width:'70px',height:'25px',color:"white",backgroundColor:"red",border:"none",marginLeft:'4px'}} onClick={handleCancel} >취소</button>
            </div>
        </div>
    )
}

export default BlackMemberAddModal;