import React from "react";
import AccountContainer from "../../containers/monitoring/AccountContainer";
const Account = () => {
    return (
        <div className="px-2">
            <font color={"blue"} size="3">계좌조회</font>&nbsp;
            <AccountContainer />
        </div>

    )
}
export default Account;