import React, {useEffect} from "react";
import CustomerDetailContainer from "../../containers/board/CustomerDetailContainer";
import {useParams} from "react-router-dom";
import {getCustomerDetail} from "../../redux/reducers/board/CustomerDetailReducer";
import {useDispatch, useSelector} from "react-redux";
import MemberTotalInfoContainer from "../../containers/user/MemberTotalInfoContainer";
const CustomerDetail = () => {
    const param = useParams();
    const dispatch = useDispatch();
    const state = useSelector((state) => state.customerDetail);
    const userInfoId = useSelector((state) => state.userInfo.id);


    useEffect(() => {
        if(param.cs_no > 0) {
            dispatch((getCustomerDetail({
                id: userInfoId,
                csNo: param.cs_no
            })))
        }
    }, [])



    return (
        <div className="sm-font mx-3" style={{display: "flex", marginTop: "30px", height: "fit-content"}}>
            <div className="me-1" style={{width: "30%", backgroundColor: "#D7D4CF"}}>
                <div>
                    <CustomerDetailContainer />
                </div>
            </div>
            <div style={{width: "70%"}}>
                {state.customer?.id && (<MemberTotalInfoContainer userId={state.customer?.id}/>)}
            </div>


        </div>

    )
}
export default CustomerDetail;