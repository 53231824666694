import {call, put, take,} from "redux-saga/effects";
import {
    failedGetCloseFixture, failedGetCloseFixtureDetail,
    getCloseFixtureDetail,
    setCloseFixture, setCurrentFixture
} from "../../../reducers/game/manage/closeMatch";
import {fetchCloseFixture, fetchFixtureDetail} from "../../../../lib/api";

export function* getCloseFixtureSaga(action) {
    try {
        const res = yield call(fetchCloseFixture, action.payload);
        yield put(setCloseFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getCloseFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetCloseFixture(error));
    }
}

export function* getCloseFixtureDetailSaga(action) {
    try {
        const res = yield call(fetchFixtureDetail, action.payload, 3)
        yield put(setCurrentFixture(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetCloseFixtureDetail(error))
    }
}



