import {call, put} from "redux-saga/effects";
import {failedGetTagSetting, setTagSetting} from "../../reducers/setting/TagSettingListReducer";
import {fetchTagSettingList} from "../../../lib/api";

export function* getTagSettingSaga(action) {
    try{
        console.log("##getTagSettingSingleSaga input#",action.payload.input)
        const res = yield call(fetchTagSettingList,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd
        );
        console.log("##getTagSettingSingleSaga output#",res.data)
        yield put(setTagSetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetTagSetting(error))
    }
}