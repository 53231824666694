import React from 'react';
import DepositWithdrawCombineContainer from "../../containers/deposit/DepositWithdrawCombineContainer";


const DepositWithdrawCombine = () => {
    return (
        <div>
            <DepositWithdrawCombineContainer />
        </div>
    );
};

export default DepositWithdrawCombine;