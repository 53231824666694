import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../redux/reducers/user/BlackMemberAddModalReducer";

const BetAndMessageContainer = ({userId}) => {


    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <b>페이지 접속 기록 (일주일 이내 / 최대 1,000건)</b>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                * 베팅내역, 쪽지 확인 기록을 시간 역순으로 보여줍니다.
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
                <table className="commonT table table-bordered table-sm text-center" style={{width: "60%"}}>
                    <thead >
                    <tr>
                        <th style={{width: "20%",backgroundColor: "lightgray"}}>시각</th>
                        <th style={{width: "60%",backgroundColor: "lightgray"}}>페이지 구분</th>
                        <th style={{width: "20%",backgroundColor: "lightgray"}}>IP</th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>



    )
}
export default BetAndMessageContainer;