import {call, put, take,} from "redux-saga/effects";
import {fetchPointStat} from "../../../lib/api";
import {failedGetPointStat, setPointStat} from "../../reducers/deposit/PointStatReducer";

export function* getPointStatSaga(action) {
    try{
        const res = yield call(
            fetchPointStat,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd, action.payload.searchCd01
        )
        yield put(setPointStat(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetPointStat(error))
    }
}