import {call, put, take,} from "redux-saga/effects";
import {fetchAccounts} from "../../../lib/api";
import {failedGetAccounts, setAccounts} from "../../reducers/monitoring/AccountReducer";

export function* getAccountSaga(action) {
    try{
        const res = yield call(
            fetchAccounts,
            action.payload
        )

        yield put(setAccounts(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetAccounts(error))
    }
}