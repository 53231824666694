import React from 'react';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import common from "../../util/common";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";

const CustomerList = ({state, onRefresh}) => {
    const userInfoId = useSelector((state) => state.userInfo.id)
    const dispatch = useDispatch();
    const changeReplyCode = (no, reply_cd) => {
        axios.post('/admin/board/changeReplyCd', {
            no: no,
            reply_cd: reply_cd,
            adminNo: userInfoId
        }).then((res) => {
            if(res.data.isUpdated) {
                onRefresh()
            }
        })
    }

    return (
        <div className="common">
            <table className="table table-bordered table-sm text-center align-middle">
                <thead>
                <tr>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>보류</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>번호</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>제목</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>그룹</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>작성자</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>마지막 충전</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>실제 아이디</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>도메인</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>가맹점</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>등록일</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>조회수</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이피</td>
                </tr>
                </thead>
                <tbody>
                {state?.customer && state.customer.map((item, index) => (
                    <tr style={{backgroundColor: item.cust_delete_yn === "Y"? "#797979" : "#FFFFFF"}}>
                        <td>
                            {item.reply_cd === "답변완료"? (
                                <>
                                    <span style={{color: "blue"}}>{item.upd_id}</span>
                                    <br />
                                    <span style={{color: "gray"}}>{item.reply_cd}</span>
                                </>
                            ) : (
                                <button className={item.reply_cd === "답변대기"? "btn btn-danger" : (item.reply_cd === "열람대기"? "btn btn-primary" : "")} onClick={(e) => {
                                    changeReplyCode(item.no, item.reply_cd === "열람대기"? "01" : "00")
                                }}>
                                    {item.reply_cd === "열람대기"? "보류하기" : "보류중"}
                                </button>
                            )}
                        </td>
                        <td>{item.no}</td>
                        <td><a href={"/board/center_view/" + item.no} target="_blank">{item.title}</a></td>
                        <td>{item.user_group_cd}</td>
                        <td style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                            dispatch(openUserSimpleInfo({
                                isOpen: true,
                                openId: item.reg_id,
                                xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                            }))
                        }}>{item.pnt_gd_cd} {item.reg_id} {item.user_gb_cd}</td>
                        <td>{item.complete_dt? common.getRemainTime(item.complete_dt): "-"}</td>
                        <td style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                            dispatch(openUserSimpleInfo({
                                isOpen: true,
                                openId: item.reg_id,
                                xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                            }))
                        }}>{item.reg_id}</td>
                        <td>{item.domain}</td>
                        <td>{item.mrch_cd}</td>
                        <td>{item.createdAt}</td>
                        <td>{item.read_cnt}</td>
                        <td><span style={{ color: "#1F68EE" }}>{item.use_ip}</span><br/>한국</td>
                    </tr>
                ))}

                </tbody>
            </table>
        </div>
    )

}


export default CustomerList;