import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {setMemberUploadList} from "../../redux/reducers/user/MemberUploadReducer";
import axios from "axios";
import {insertAdminLog} from "../../lib/api";

const Member_upload = () => {

    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.MemberUploadReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)

    const XLSX = require( "xlsx" );
    const [uploadedFile, setUploadedFile] = useState(null);

    useEffect(() => {
        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "엑셀회원등록"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handleDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];

            const reader = new FileReader();
            reader.onload = async (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array", bookVBA: true });

                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet);

                alert("jsonData : "+JSON.stringify(jsonData));

                document.getElementById("textarea_name").value = JSON.stringify(jsonData);

                setUploadedFile({ file, jsonData });

            };

            reader.readAsArrayBuffer(file);
        }
    }, []);


    const handleFileUpload = (e) => {
        try {
            const file = e.target.files[0];

            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const binaryString = event.target.result;
                    const workbook = XLSX.read(binaryString, { type: 'binary' });

                    workbook.SheetNames.forEach((sheetName) => {
                        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        console.log('Sheet:', sheetName);
                        console.log('Data:', data);
                        // 여기서 데이터를 처리하거나 상태로 저장할 수 있습니다.
                        const _memberUploadList = [];
                        for (let i = 0; i <data.length; i++) {
                            _memberUploadList.push({
                                id: data[i].아이디,
                                pw: data[i].패스워드,
                                nickname: data[i].닉네임,
                                point: data[i].포인트,
                                game_money_amt: data[i].머니,
                                phone_no: data[i].전화번호,
                                name: data[i].이름,
                                bank_cd: data[i].은행,
                                account: data[i].계좌번호,
                                withdraw_pw: data[i].환전비번,
                                deposit_amt: data[i].충전액,
                                withdraw_amt: data[i].환전액,
                                up_rcmr_id: data[i].추천인아이디,
                                store_cd: data[i].소속총판가맹아이디,
                                site_cd: data[i].사이트코드,
                                black_reg_cnt: data[i].가라회원여부,
                                mem01: data[i].메모,
                                pnt_gd_cd: data[i].레벨,
                                user_state_cd: data[i].상태,
                                use_yn: data[i].삭제여부,
                                entr_dttm: data[i].가입일시,
                                domain: data[i].가입도메인,
                                entr_ip: data[i].가입아이피,
                                updatedAt: data[i].최근로그인일시,
                                reg_domain_nm: data[i].최근로그인도메인,
                                rcnt_actv_ip: data[i].최근로그인아이피,
                                reg_id:userInfoId,
                                upd_id:userInfoId,
                            });
                        }

                        dispatch(setMemberUploadList(_memberUploadList));
                    });
                } catch (error) {
                    console.error('Error while processing the Excel  file:', error);
                }
            };

            if (file) {
                reader.readAsBinaryString(file);
            }
        } catch (error) {
            console.error('Error while handling the file upload:', error);
        }
    };

    const handleDownload = () => {
        // 엑셀 데이터 예시 (임의로 데이터를 생성하거나 가져오세요)
        // 새 워크북 생성
        const workbook = XLSX.utils.book_new();
        const sheetName = 'Sheet1';

        // 데이터 생성
        const data = [
            ['아이디', '패스워드','닉네임','포인트','머니','전화번호','이름','은행','계좌번호','환전비번','충전액','환전액','추천인아이디','소속총판가맹아이디','사이트코드','가라회원여부','메모','레벨','상태','삭제여부','가입일시','가입도메인','가입아이피','최근로그인일시','최근로그인도메인','최근로그인아이피'],
            ['','','','','','','','','','','','','','','','','','','','','','','','','',''],
        ];


        // 시트 생성
        const worksheet = XLSX.utils.aoa_to_sheet(data);

        // 모든 셀에 텍스트 형식 적용
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellAddress = { c: C, r: R };
                const cellRef = XLSX.utils.encode_cell(cellAddress);
                const cell = worksheet[cellRef];
                if (cell && cell.t === 'n') {
                    cell.t = 's'; // 숫자 타입을 문자열 타입으로 변경
                }
            }
        }

        // 워크북에 시트 추가
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

        // 파일 저장
        XLSX.writeFile(workbook, '회원엑셀업로드포맷.xlsx');
    };


    const save = () => {
        if (window.confirm("회원 엑셀업로드 하시겠습니까?")) {
            axios.post('/admin/member/insertExcel', {
                id : userInfoId,
                memberUploadList:initialState.memberUploadList,
            }).then((res) => {
                if(res.data.isInserted) {
                    alert('업로드완료')
                }
                else {
                    alert('업로드실패')
                }
            })
        }
    };




    return (


        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">엑셀로 회원등록</font>
                </div>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="table table-bordered table-sm text-center align-middle" >
                        <thead>
                        <tr>
                            <th align={"center"} width={"75%"}>파일</th>
                            <th align={"center"} width={"25%"}>올리기</th>
                        </tr>
                        </thead>
                        <tr>
                            <td>
                                <input type="file" onChange={handleFileUpload} />
                            </td>
                            <td>
                                <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}} onClick={save}>올리기</button>
                            </td>
                        </tr>
                        <tr>
                            <td align={"left"} colspan="2">
                                <font color={"red"}>* 많은 양을 한 번에 올리실 경우 시간이 소요됩니다. 충분히 기다려주시기 바랍니다.</font>
                            </td>
                        </tr>
                        <tr>
                            <td align={"left"} colSpan="2">
                                <font color={"red"}>* 업로드 하는 피씨에서는 관리자가 잠시 멈춘 것처럼 보일 수 있습니다. 업로드 중엔 다른 작업을 삼가주세요.</font>
                            </td>
                        </tr>
                    </table>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th align={"center"} width={"75%"}>신규 회원 올리기 포맷 다운로드</th>
                            <th align={"center"} width={"25%"}>다운로드</th>
                        </tr>
                        </thead>
                        <tr>
                            <td>
                                신규 회원 등록용 엑셀 포맷
                            </td>
                            <td>
                                <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleDownload}>다운로드</button>
                            </td>
                        </tr>
                        <tr>
                            <td align={"left"} colSpan="2">
                                <font color={"black"}>* 신규 회원을 등록할 떄 : 신규회원 올리기 포맷 엑셀 파일을 다운로드해서 수정하신 후 업로드 해주세요.</font>
                            </td>
                        </tr>
                    </table>

                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th align={"left"} >
                                <font color={"black"}>* 회원 업로드예정 리스트가 아래에 나옵니다.</font>
                            </th>
                        </tr>
                        </thead>
                    </table>
                </div>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th colSpan="26">업로드예정리스트</th>
                        </tr>
                        <tr>
                            <th>아이디</th>
                            <th>패스워드</th>
                            <th>닉네임</th>
                            <th>포인트</th>
                            <th>머니</th>
                            <th>전화번호</th>
                            <th>이름</th>
                            <th>은행</th>
                            <th>계좌번호</th>
                            <th>환전비번</th>
                            <th>충전액</th>
                            <th>환전액</th>
                            <th>추천인아이디</th>
                            <th>소속총판가맹아이디</th>
                            <th>사이트코드</th>
                            <th>가라회원여부</th>
                            <th>메모</th>
                            <th>레벨</th>
                            <th>상태(정상,정지)</th>
                            <th>삭제여부</th>
                            <th>가입일시</th>
                            <th>가입도메인</th>
                            <th>가입아이피</th>
                            <th>최근로그인일시</th>
                            <th>최근로그인도메인</th>
                            <th>최근로그인아이피</th>
                        </tr>
                        </thead>
                        {initialState.memberUploadList&&initialState.memberUploadList.length >0 ? initialState.memberUploadList.map((List, index) => (
                                <tbody key={List.id}>
                                <tr>
                                    <td>{List.id}</td>
                                    <td>{List.pw}</td>
                                    <td>{List.nickname}</td>
                                    <td>{List.point}</td>
                                    <td>{List.game_money_amt}</td>
                                    <td>{List.phone_no}</td>
                                    <td>{List.name}</td>
                                    <td>{List.bank_cd}</td>
                                    <td>{List.account}</td>
                                    <td>{List.withdraw_pw}</td>
                                    <td>{List.deposit_amt}</td>
                                    <td>{List.withdraw_amt}</td>
                                    <td>{List.up_rcmr_id}</td>
                                    <td>{List.store_cd}</td>
                                    <td>{List.site_cd}</td>
                                    <td>{List.black_reg_cnt}</td>
                                    <td>{List.mem01}</td>
                                    <td>{List.pnt_gd_cd}</td>
                                    <td>{List.user_state_cd}</td>
                                    <td>{List.use_yn}</td>
                                    <td>{List.entr_dttm}</td>
                                    <td>{List.domain}</td>
                                    <td>{List.entr_ip}</td>
                                    <td>{List.updatedAt}</td>
                                    <td>{List.reg_domain_nm}</td>
                                    <td>{List.rcnt_actv_ip}</td>
                                </tr>
                                </tbody>
                            )) :
                            <tbody>
                            <tr>
                                <td colSpan= "26">업로드 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>


            </div>
        </div>


    )
}
export default Member_upload;