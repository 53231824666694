module.exports = {
    OPEN: 1,
    SUSPENDED: 2,
    SETTLED: 3,
    getKorMessage: (settlement) => {
        switch(settlement) {
            case 1 : return "진행"
            case 2 : return "닫힘"
            case 3 : return "완료"
            default : return "오류"
        }
    }
}