import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import LvlSettingComponent from "../../components/modal/setting/LvlSettingComponent";
import {setIsOpen, setSelectedNos1, setHoleGbCdArr, setVipHoleGbCdArr} from "../../redux/reducers/setting/RouletteListReducer";
import {setDangerUseYnArr} from "../../redux/reducers/setting/RegSettingListReducer";

const RouletteSetting = ({rouletteSetting, onRefresh, initialState, onSearch, selectList1, selectList2}) => {

    const dispatch = useDispatch()
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [levels, setLevels] = useState([])

    const [defaultGbCd, setDefaultGbCd] = useState("")
    const [vipGbCd, setVipGbCd] = useState("")

    const [payPointArr, setPayPointArr] = useState([]);
    const [winningPercentArr, setWinningPercentArr] = useState([]);
    const [payPointVipArr, setPayPointVipArr] = useState([]);
    const [winningPercentVipArr, setWinningPercentVipArr] = useState([]);


    const payPointArrHandler = (e, index) => {
        payPointArr[index] = e.target.value;
        setPayPointArr(payPointArr);
    }
    const winningPercentArrHandler = (e, index) => {
        winningPercentArr[index] = e.target.value;
        setWinningPercentArr(winningPercentArr);
    }
    const payPointVipArrHandler = (e, index) => {
        payPointVipArr[index] = e.target.value;
        setPayPointVipArr(payPointVipArr);
    }
    const winningPercentVipArrHandler = (e, index) => {
        winningPercentVipArr[index] = e.target.value;
        setWinningPercentVipArr(winningPercentVipArr);
    }

    const onLevelChange = (setting_level_no) => {
        onSearch(setting_level_no)
    }

    useEffect(() => {
        getGrades()
    }, [])

    const getGrades = () => {
        axios.post('/admin/admin/getPointGrade2', {

        }).then((res) => {
            setLevels(res.data.result)
            const arr = []
            for(const level of res.data.result) {
                arr.push(level.setting_level_no)
            }
        })
    }
    const handleClickOpen = () => {
        dispatch(setIsOpen(true))
    }
    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        window.location.reload();
    }

    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }


    const defaultGbCdHandler = (e) => {
        setDefaultGbCd(e.currentTarget.value);
    }
    const vipGbCdHandler = (e) => {
        setVipGbCd(e.currentTarget.value);
    }

    const checkHandler1 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos1([...initialState.selectedNos1, no]))
        }
        else {
            dispatch(setSelectedNos1(initialState.selectedNos1.filter((id) => id !== no)))
        }
    }

    const holeGbCdArrHandler = (e, index) => {
        //alert("index : "+index+ " || e.target.value : "+e.target.value);
        const rows = [...initialState.holeGbCdArr];
        rows[index] = e.target.value;
        dispatch(setHoleGbCdArr(rows));
    }

    const vipHoleGbCdArrHandler = (e, index) => {
        const rows = [...initialState.vipHoleGbCdArr];
        rows[index] = e.target.value;
        dispatch(setVipHoleGbCdArr(rows));
    }

    const updateState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveRouletteSetting', {
                pntGbCd: rouletteSetting?.pnt_gb_cd,
                defaultGbCd:defaultGbCd?defaultGbCd:rouletteSetting.default_gb_cd,
                vipGbCd: vipGbCd?vipGbCd:rouletteSetting.vip_gb_cd,
                adminNo: userInfoId
            }).then((res) => {

                //기본룰렛
                selectList1?.map((selectList1, index) => (
                    axios.post('/admin/setting/saveRouletteSettingDtl01', {
                        pntGbCd: rouletteSetting?.pnt_gb_cd,
                        rouletteSeq: selectList1?.roulette_seq,
                        holeGbCd: initialState.holeGbCdArr[parseInt(selectList1.roulette_seq)]?initialState.holeGbCdArr[parseInt(selectList1.roulette_seq)]:selectList1.hole_gb_cd,
                        rouletteGbCd: selectList1?.roulette_gb_cd,
                        payPoint: payPointArr[selectList1?.roulette_seq]?payPointArr[selectList1.roulette_seq]:selectList1?.pay_point,
                        winningPercent: winningPercentArr[selectList1?.roulette_seq]?winningPercentArr[selectList1.roulette_seq]:selectList1?.winning_percent,
                        adminNo: userInfoId
                    }).then((res) => {

                    })
                ))

                //VIP룰렛
                selectList2?.map((selectList2, index) => (
                    axios.post('/admin/setting/saveRouletteSettingDtl02', {
                        pntGbCd: rouletteSetting?.pnt_gb_cd,
                        rouletteSeq: selectList2?.roulette_seq,
                        holeGbCd: initialState.vipHoleGbCdArr[parseInt(selectList2.roulette_seq)]?initialState.vipHoleGbCdArr[parseInt(selectList2.roulette_seq)]:selectList2.hole_gb_cd,
                        rouletteGbCd: selectList2?.roulette_gb_cd,
                        payPoint: payPointVipArr[selectList2?.roulette_seq]?payPointVipArr[selectList2.roulette_seq]:selectList2?.pay_point,
                        winningPercent: winningPercentVipArr[selectList2?.roulette_seq]?winningPercentVipArr[selectList2.roulette_seq]:selectList2?.winning_percent,
                        adminNo: userInfoId
                    }).then((res) => {

                    })
                ))

                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }

    const updateMultiState = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos1.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos1.length; i++) {
                axios.post('/admin/setting/saveRouletteSetting', {
                    pntGbCd: initialState.selectedNos1[i],
                    defaultGbCd: defaultGbCd ? defaultGbCd : rouletteSetting.default_gb_cd,
                    vipGbCd: vipGbCd ? vipGbCd : rouletteSetting.vip_gb_cd,
                    adminNo: userInfoId
                }).then((res) => {
                    //기본룰렛
                    selectList1?.map((selectList1, index) => (
                        axios.post('/admin/setting/saveRouletteSettingDtl01', {
                            pntGbCd: initialState.selectedNos1[i],
                            rouletteSeq: selectList1?.roulette_seq,
                            holeGbCd: '01',
                            rouletteGbCd: selectList1?.roulette_gb_cd,
                            payPoint: payPointArr[selectList1?.roulette_seq] ? payPointArr[selectList1.roulette_seq] : selectList1?.pay_point,
                            winningPercent: winningPercentArr[selectList1?.roulette_seq] ? winningPercentArr[selectList1.roulette_seq] : selectList1?.winning_percent,
                            adminNo: userInfoId
                        }).then((res) => {

                        })
                    ))

                    //VIP룰렛
                    selectList2?.map((selectList2, index) => (
                        axios.post('/admin/setting/saveRouletteSettingDtl02', {
                            pntGbCd: initialState.selectedNos1[i],
                            rouletteSeq: selectList2?.roulette_seq,
                            holeGbCd: '01',
                            rouletteGbCd: selectList2?.roulette_gb_cd,
                            payPoint: payPointVipArr[selectList2?.roulette_seq] ? payPointVipArr[selectList2.roulette_seq] : selectList2?.pay_point,
                            winningPercent: winningPercentVipArr[selectList2?.roulette_seq] ? winningPercentVipArr[selectList2.roulette_seq] : selectList2?.winning_percent,
                            adminNo: userInfoId
                        }).then((res) => {
                            window.location.reload();
                        })
                    ))


                })

            }
            alert("수정완료");

        }
    }



    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "green", border: "1", color: "white",fontSize: "10px", height: "22px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                            onClick={handleClickOpen}>레벨관리(추가,삭제)</button>
                    {initialState.isOpen && <LvlSettingComponent open={initialState.isOpen} onClose={handleClickClose} />}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {levels?.map((item, index) => (
                        <>
                            <button style={{ backgroundColor: initialState.query === item.setting_level_key ? "blue" : "white" , border: "1", color: initialState.query === item.setting_level_key ? "white" : "black",fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                    onClick={(e) => {
                                        onLevelChange(item.setting_level_key)
                                    }}
                            >
                                <b>{item.cmmn_cd_dtl_nm} 레벨</b>
                            </button>
                        </>
                    ))}
                </div>
                <br></br>
            </div>
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"100%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> 룰렛</b></font>
                        </div>

                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "44%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 기본 룰렛</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={defaultGbCdHandler} value={defaultGbCd?defaultGbCd:rouletteSetting.default_gb_cd}
                                    >
                                        <option value={'01'}>사용</option>
                                        <option value={'02'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "44%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> VIP 룰렛</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={vipGbCdHandler} value={vipGbCd?vipGbCd:rouletteSetting.vip_gb_cd}
                                    >
                                        <option value={'01'}>사용</option>
                                        <option value={'02'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center" >
                            <tr>
                                <td>
                                    <table className="commonT table table-bordered table-sm text-center" style={{width: "100%"}}>
                                        {selectList1?.map((selectList1, index) => (
                                            <tbody key={selectList1.roulette_seq}>
                                            <tr>
                                                <td style={{width: "17%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                    구멍 {index+1}
                                                    &nbsp;&nbsp;
                                                    <select
                                                        className="form-select sm-select"
                                                        style={{height: "25px", width:"100px"}}
                                                        onChange={(e) => {holeGbCdArrHandler(e, parseInt(selectList1.roulette_seq))}}
                                                        value={initialState.holeGbCdArr[parseInt(selectList1.roulette_seq)]?initialState.holeGbCdArr[parseInt(selectList1.roulette_seq)]:selectList1.hole_gb_cd}
                                                    >
                                                        <option value={""}>:::선택:::</option>
                                                        <option value={"01"}>쿠폰</option>
                                                        <option value={"02"}>복권</option>
                                                    </select>
                                                </td>
                                                <td style={{width: "17%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                    <font color={"black"}><b>지급</b></font>
                                                    &nbsp;&nbsp;
                                                    <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                                           placeholder={selectList1.pay_point}
                                                           onChange={(e) => {payPointArrHandler(e, selectList1.roulette_seq)}}
                                                    />
                                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                    >
                                                        P
                                                    </button>
                                                </td>
                                                <td style={{width: "17%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                    <font color={"black"}><b>당첨 %</b></font>
                                                    &nbsp;&nbsp;
                                                    <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                                           placeholder={selectList1.winning_percent}
                                                           onChange={(e) => {winningPercentArrHandler(e, selectList1.roulette_seq)}}
                                                    />
                                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                    >
                                                        %
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </td>
                                <td></td>
                                <td>
                                    <table className="commonT table table-bordered table-sm text-center" style={{width: "100%"}}>
                                        {selectList2?.map((selectList2, index) => (
                                            <tbody key={selectList2.roulette_seq}>
                                            <tr>
                                                <td style={{width: "17%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                    구멍 {index+1}
                                                    &nbsp;&nbsp;
                                                    <select
                                                        className="form-select sm-select"
                                                        style={{height: "25px", width:"100px"}}
                                                        onChange={(e) => {vipHoleGbCdArrHandler(e, parseInt(selectList2.roulette_seq))}}
                                                        value={initialState.vipHoleGbCdArr[parseInt(selectList2.roulette_seq)]?initialState.vipHoleGbCdArr[parseInt(selectList2.roulette_seq)]:selectList2.hole_gb_cd}
                                                    >
                                                        <option value={""}>:::선택:::</option>
                                                        <option value={"01"}>쿠폰</option>
                                                        <option value={"02"}>복권</option>
                                                    </select>
                                                </td>
                                                <td style={{width: "17%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                    <font color={"black"}><b>지급</b></font>
                                                    &nbsp;&nbsp;
                                                    <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                                           placeholder={selectList2.pay_point}
                                                           onChange={(e) => {payPointVipArrHandler(e, selectList2.roulette_seq)}}
                                                    />
                                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                    >
                                                        P
                                                    </button>
                                                </td>
                                                <td style={{width: "17%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                    <font color={"black"}><b>당첨 %</b></font>
                                                    &nbsp;&nbsp;
                                                    <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                                           placeholder={selectList2.winning_percent}
                                                           onChange={(e) => {winningPercentVipArrHandler(e, selectList2.roulette_seq)}}
                                                    />
                                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                    >
                                                        %
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </td>

                            </tr>
                        </table>

                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td colSpan={6}>
                                    <tabel>
                                        <tr>
                                            <td colspan={2}>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateState()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateMultiState()}}
                                                >
                                                    선택 레벨 일괄적용
                                                </button>
                                            </td>
                                            <td colSpan={4}>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio11"+item.setting_level_no} id={"inlineRadio11"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos1.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler1(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio11' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio11"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </tabel>

                                </td>
                            </tr>
                        </table>
                    </div>
                </div>




            </div>
        </div>

    )
}

export default RouletteSetting;