import {call, put} from "redux-saga/effects";
import {failedGetMemberStateReducer, setMemberStateReducer} from "../../reducers/user/MemberStateReducer";
import {fetchMemberState} from "../../../lib/api";

export function* getMemberStateSaga(action) {
    try{
        console.log("##getMemberStateSaga input#",action.payload.input)
        const res = yield call(fetchMemberState,
            action.payload.input,
        );
        console.log("##getMemberStateSaga output#",res.data)
        yield put(setMemberStateReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMemberStateReducer(error))
    }
}