import React from "react";
import SameContainer from "../../containers/monitoring/SameContainer";
const Same = () => {
    return (
        <div className="sm-font">
            <font color={"blue"} size="3">동일 PC & 동일 브라우저 사용</font>&nbsp;
            <p style={{ fontSize: "9px" }}>
                <span>* 동일 PC와 동일 브라우저를 사용하여 다른 아이디로 로그인한 경우를 확인 할 수 있습니다.</span>
                <br/>
                <span style={{color: "red"}}>* 이 목록에 나오는 경우 100% 같은 PC를 사용하여 다른 아이디로 로그인한 경우이며, 어떤 예외 변수도 있을 수 없습니다.</span>
                <br/>
                <span>* 다만, 기술적 한계로 인해 같은 PC를 사용했다고 해서 모두 기록이 남는 것은 아닙니다.</span>
            </p>
            <SameContainer />
        </div>

    )
}
export default Same;