import {call, put, take,} from "redux-saga/effects";
import {fetchETCs} from "../../../lib/api";
import {failedGetETCs, setETCs} from "../../reducers/board/ETCReducer";

export function* getETCsSaga(action) {
    try{
        const res = yield call(
            fetchETCs,
            action.payload
        )

        yield put(setETCs(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetETCs(error))
    }
}