import {call, put} from "redux-saga/effects";
import {failedGetEtcSettingListReducer, setEtcSettingListReducer} from "../../reducers/setting/EtcSettingListReducer";
import {fetchEtcSettingList} from "../../../lib/api";

export function* getEtcSettingListSaga(action) {
    try{
        console.log("##getEtcSettingListSaga input#",action.payload.input)
        const res = yield call(fetchEtcSettingList,
            action.payload.input,
        );
        console.log("##getEtcSettingListSaga output#",res.data)
        yield put(setEtcSettingListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetEtcSettingListReducer(error))
    }
}