import React, {useEffect, useState } from 'react';
import axios from "axios";

const InterestMemberColorSelect = (props) => {
    const {onClose,userId} = props
    const [List, setList] = useState([])
    const itemsPerRow = 10; // 한 줄에 표시할 아이템 개수
    const onLoad = () => {
        //1.유저색상조회
        axios.post('/admin/userColorList').
        then((res) => {
            if(res.data.userColorList !== null) {
                setList(res.data.userColorList)
            }
            else {
                alert('색상조회실패')
            }
        })
    }
    useEffect(onLoad, [])

    const clickColor = (userColorNo)=>{
        console.log("userColorNo",userColorNo,"##",userId)
        axios.post('/admin/member/simple/memberInfoSimpleModal/insertInterestedMember',{
            userId : userId,
            userColorNo : userColorNo,
        }).
        then((res) => {
            alert(res.data.message)
            onClose()
            //window.location.reload();
        })
    }

    // 아이템을 렌더링하는 함수
    const renderItems = () => {
        return List?.map((item, index) => (
            <React.Fragment key={item.no}>
                <td onClick={()=>clickColor(item.no)} style={{backgroundColor: item.back_ground_color }} align={"center"} height={"20px"} width="5px" >
                    <label
                        htmlFor={`flexColor1${item.no}`}
                        style={{ cursor:"pointer" ,fontSize: '20px' ,display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                    >
                        <font color={item.font_color}>{item.no}</font>
                    </label>
                </td>
            </React.Fragment>
        ));
    };

    // 행을 렌더링하는 함수
    const renderRows = () => {
        const rows = [];
        for (let i = 0; i < List.length; i += itemsPerRow) {
            rows.push(
                <tr key={i}>
                    {renderItems().slice(i, i + itemsPerRow)}
                </tr>
            );
        }
        return rows;
    };

    const handleCancel = () => {
        onClose()
    }

    return (
        <div>
            <div id="select_looking_mode" style={{ margin: "-10px 0px 0px 145px", position: "absolute", border: "3px solid red" }}>
                <div style={{ display: "block", background: "#000000", height: "30px", color: "#fff900", textAlign: "center" }}>
                    <table   width={"99%"}>
                        <tr>
                            <td align={"left"}>
                                관심 회원 색을 선택하세요
                            </td>
                            <td align={"right"}>
                                <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleCancel}>X</button>
                            </td>
                        </tr>
                    </table>
                </div>
                <table>
                    <tbody>
                    {renderRows()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default InterestMemberColorSelect;