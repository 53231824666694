import React, {useEffect, useState } from 'react';
import {resetState,setNo,setQuery,setCont,setTitle,getMessagePopReducer} from "../../../redux/reducers/user/MessagePopReducer";

import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Editor from "../../common/Editor";



const MessageSendRecModal = (props) => {

    const {open, onClose,no} = props
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.MessagePopReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)

    const getList = () => {
        if(initialState.detail !== null ) {
            dispatch(getMessagePopReducer({
                input:{
                    no : no,
                }
            }))
            dispatch(setNo(no))
        }
    }
    useEffect(getList, [dispatch])

    const handleCancel = () => {
        dispatch(resetState())
        onClose()
    }

    const changeTitle = (e) => {
        dispatch(setTitle(e.target.value))
    }
    const changeCont = (cont) => {
        dispatch(setCont(cont))
    }

    const changeQuery = (e) => {
        dispatch(setQuery(e.target.value))
    }

    const search = () => {
        dispatch(getMessagePopReducer({
            input:{
                no : initialState.input.no,
                query : initialState.input.query,
            }
        }))
    }

    const queryKeyDown = (e) => {
        if (e.key === 'Enter') {
            dispatch(getMessagePopReducer({
                input:{
                    no : initialState.input.no,
                    query : initialState.input.query,
                }
            }))
        }
    }

    const handleBlur = (e) =>{
        search()
    }

    const updateMessageSendRec = () => {
        axios.post('/admin/user/MessagePop/updateMessage', {
            input :{
                no : initialState.detail.no,
                title : initialState.detail.title,
                cont : initialState.detail.cont,
                upd_id : userInfoId
            }
        }).then((res) => {
            if(res.data.code === 'suc'){
                alert(res.data.message);
                search()
            }
        })
    }

    const updateMessageSendRec2 = (no,gb,val) => {
        axios.post('/admin/user/MessagePop/updateMessageSendRec', {
                no : no,
                gb : gb,
                useYn : val,
                confirmYn : val
        }).then((res) => {
            if(res.data.code === 'suc'){
                alert(res.data.message);
                search()
            }
        })
    }


    return (
        <div className="big_modal_background">
            <div className="big_modal_box" >
                <div className="big_modal_body">
                    <div className="scrollable-content">
                        <div className="close-modal-button" onClick={handleCancel}>
                            ✖
                        </div>
                        <div className="row" style={{ marginTop: '5px' }}>
                            <div className="col-2">
                                <h6>쪽지상태변경</h6>
                            </div>
                        </div>
                        <div style={{marginTop: '5px' }}>
                            <table className="big_table1">
                                <tbody>
                                    <tr>
                                        <th style={{width : '10%', height :'30px'}}>제목 수정</th>
                                        <td >
                                            <input onChange={(e)=>changeTitle(e)} value={initialState.detail&&initialState.detail.title} style={{width : '100%'}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{width :'10%'}}>내용수정</th>
                                        <td style={{verticalAlign: 'top'}}>
                                            <Editor cont={initialState.detail&&initialState.detail.cont} setCont={changeCont} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{width : '10%', height :'30px'}}></th>
                                        <td>
                                            <div style={{height:'40px',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                                                <button onClick={()=>updateMessageSendRec()} style={{ fontSize : '20px' , width:'500px',height:'30px',color:"white",backgroundColor:"#5DC434",border:"none",alignItems: 'center'}} >저장</button>
                                                <button style={{fontSize : '20px' ,width:'150px',height:'30px',color:"white",backgroundColor:"red",border:"none",marginLeft:'4px'}} onClick={handleCancel}>취소</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{marginTop: '5px' }}>
                            <table style={{height :'30px'}}>
                                <tbody>
                                    <tr>
                                        <th style={{width : '10%', height :'50px'}}>닉네임검색</th>
                                        <td >
                                            <input value={initialState.input.query} onKeyDown={queryKeyDown} onBlur={handleBlur} onChange={(e)=>changeQuery(e)} style={{marginLeft : '5px' ,width : '20%',height :'30px'}}/>
                                            <button onClick={()=>search()} style={{
                                                marginLeft : '5px' ,width: "80px" , height :'35px'
                                            }} type="submit" className="btn btn-primary" >검색</button>
                                            &nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ width: '100%', height :'30px' ,marginLeft: '20px' ,marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                            <span style={{ color: 'red' }}>* 보낸 쪽지 목록은 100건까지만 표시 됩니다. 찾고 싶은 닉네임을 검색해 주세요.</span>
                        </div>
                        <div>
                            <table style={{height: '40px'}}>
                                <thead>
                                <tr>
                                    <th style={{width: "15%" }}>상세번호</th>
                                    <th style={{width: "15%" }}>아이디</th>
                                    <th style={{width: "20%" }}>닉네임</th>
                                    <th style={{width: "30%" }}>읽은 일시 <br/> (클릭 = 읽음 상태 변경)</th>
                                    <th style={{width: "20%" }}>쪽지 삭제 여부(회원) <br/>(클릭 = 삭제 상태 변경)</th>
                                </tr>
                                </thead>
                                {initialState.totalList&&initialState.totalList.length > 0 ? initialState.totalList.map((List, index) => (
                                <tbody key={index}>
                                <tr>
                                    <td align={"center"}>{List.no}</td>
                                    <td align={"center"}>{List.receiveId}</td>
                                    <td align={"center"}>{List.nickname}</td>
                                    <td align={"center"}>
                                        {List.confirmDt !== null ?
                                            <>
                                                {List.confirmDt} <br/>
                                                관리자가 읽음 처리 &nbsp;
                                                <button onClick={()=>updateMessageSendRec2(List.no,'confirmDt','N')}>읽지않음 처리</button>
                                            </>
                                            :
                                            <>
                                                읽지않음 &nbsp;
                                                <button onClick={()=>updateMessageSendRec2(List.no,'confirmDt','Y')}>읽음 처리</button>
                                            </>
                                        }
                                    </td>
                                    <td align={"center"}>
                                        {List.useYn === 'Y' ?
                                            <>
                                                미삭제  &nbsp;
                                                <button onClick={()=>updateMessageSendRec2(List.no,'useYn','N')}>삭제</button>
                                            </>
                                            :
                                            <>
                                                삭제 &nbsp;
                                                <button onClick={()=>updateMessageSendRec2(List.no,'useYn','Y')}>복구</button>
                                            </>
                                        }
                                    </td>
                                </tr>
                                </tbody>
                                )) :
                                <tbody>
                                <tr>
                                    <td align={"center"} colSpan= "5">조회된 데이터가 없습니다.</td>
                                </tr>
                                </tbody>
                            }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessageSendRecModal;