import React, { useState } from "react";

const TimeSelectBox = (props) => {
    const {change, hh} = props
    const [selectedTime, setSelectedTime] = useState("");

    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            const formattedHour = hour.toString().padStart(2, "0");
            times.push(`${formattedHour}`);
        }
        return times;
    };

    const changeSelect = (e) =>{
        change(e)
    }

    return (
        <div>
            <select
                className="form-select sm-select"
                id="time-select"
                value={hh}
                onChange={(e) => changeSelect(e)}
            >
                {generateTimeOptions().map((time, index) => (
                    <option key={index} value={time}>
                        {time}
                    </option>
                ))}
            </select>
            {selectedTime && <p>선택된 시간: {selectedTime}</p>}
        </div>
    );
};

export default TimeSelectBox;