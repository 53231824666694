import {createSlice} from "@reduxjs/toolkit"

export const customerDetailSlice = createSlice({
    name: 'customerDetail',
    initialState: {
        isLoading: false,
        error: null,
        customer: null,
        replies: [],
        betList: [],
        agentList: [],
        totalAgent: 0,
        reply: "",
        csNo: -1,
    },
    reducers: {
        getCustomerDetail(state, action) {
            state.isLoading = true;
            state.csNo = action.payload.csNo;
        },
        failedGetCustomerDetail(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setCustomerDetail(state, action) {
            state.isLoading = false;
            state.customer = action.payload.customer;
            state.replies = action.payload.replies;
            state.betList = action.payload.betList;
            state.agentList = action.payload.agentList;
            state.totalAgent = action.payload.totalAgent;
            state.reply = "안녕하세요, " + action.payload.customer.nickname + " 회원님, 운영진입니다.\n";
        },
        setReply(state, action) {
            state.reply = action.payload
        },
    }
});

export const { getCustomerDetail, failedGetCustomerDetail, setCustomerDetail, setReply } = customerDetailSlice.actions;

export default customerDetailSlice.reducer;