import React, {useEffect, useState } from 'react';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setUpdId,setRegId,setNo,setMessageCont,setMessageTitle,setTemplate,setGb,sgetMessageTemplateReducer,resetState} from "../../../redux/reducers/user/MessageTemplateReducer";
import {setCmmnCd} from "../../../redux/reducers/user/BlackMemberAddModalReducer";
import Editor from "../../common/Editor";

const MessageTemplateModal = (props) => {
    const {open, onClose,param} = props
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.MessageTemplateReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)

    const onLoad = () => {
        dispatch(setGb(param.gb))
        dispatch(setNo(param.no))
        dispatch(setTemplate(param.template))
        dispatch(setMessageTitle(param.messageTitle))
        dispatch(setMessageCont(param.messageCont))
        dispatch(setUpdId(userInfoId))
        if(param.gb ==="등록"){
            dispatch(setRegId(userInfoId))
        }
    }

    useEffect(onLoad, [dispatch])

    const changeMessageCont = (messageCont) =>{
        dispatch(setMessageCont(messageCont))
    }

    const changeMessageTitle = (e) =>{
        dispatch(setMessageTitle(e.target.value))
    }

    const changeTemplate = (e) =>{
        dispatch(setTemplate(e.target.value))
    }
    const handleCancel = () => {
        dispatch(resetState())
        onClose()
    }

    const handleRegister = () => {
            axios.post('/admin/user/MessageTemplate/saveMessageTemplate', initialState.input)
                .then((res) => {
                    handleCancel()
            })
    }

    return (
        <div className="big_modal_background">
            <div className="big_modal_box" >
                <div className="big_modal_body">
                    <div className="close-modal-button" onClick={handleCancel}>
                        ✖
                    </div>
                    <div className="scrollable-content">
                    <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-2">
                            <h6>쪽지템플릿</h6>
                        </div>
                    </div>
                    <div style={{marginTop: '5px' }}>
                        <table>
                            <tbody>
                                <tr>
                                    <th style={{width : '10%', height :'30px'}}>템플릿명 (관리자에 노출)</th>
                                    <td >
                                        <input value={initialState.input.template} onChange={(e)=>changeTemplate(e)} style={{width : '100%'}}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{width : '10%', height :'30px'}}>제목 (사용자에게 노출)	</th>
                                    <td>
                                        <input value={initialState.input.message_title} onChange={(e)=>changeMessageTitle(e)} style={{width : '100%'}}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{width :'10%'}}>내용</th>
                                    <td style={{verticalAlign: 'top'}}>
                                        <Editor cont={initialState.input.message_cont} setCont={changeMessageCont} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{height:'40px',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                        <button style={{width:'70px',height:'25px',color:"white",backgroundColor:"#5DC434",border:"none",alignItems: 'center' }} onClick={handleRegister}>저장</button>
                        <button style={{width:'70px',height:'25px',color:"white",backgroundColor:"red",border:"none",marginLeft:'4px'}} onClick={handleCancel} >취소</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessageTemplateModal;