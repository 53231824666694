import {call, put, take,} from "redux-saga/effects";
import {fetchChecks} from "../../../lib/api";
import {failedGetChecks, setChecks} from "../../reducers/board/CheckReducer";

export function* getCheckSaga(action) {
    try{
        const res = yield call(
            fetchChecks,
            action.payload.month, action.payload.query
        )

        yield put(setChecks(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetChecks(error))
    }
}