import {setLeagues} from "../../../redux/reducers/game/gameSetting";
import Switch from "react-switch";
import {useEffect, useState} from "react";
import axios from "axios";

const MarketByGame = () => {
    const [games, setGames] = useState([])
    const [gameId, setGameId] = useState(-1)
    const [gameName, setGameName] = useState("")
    const [markets, setMarkets] = useState([])

    useEffect(() => {
        getGamesAndMarkets()
    }, [])

    const getGamesAndMarkets = () => {
        axios.post('/admin/game/setting/getUseGames', {

        }).then((res) => {
            setGames(res.data.games)
        })
    }

    const getUseMarkets = (id) => {
        axios.post('/admin/game/setting/getUseMarkets', {
            gameId: id
        }).then((res) => {
            setMarkets(res.data.markets)
        })
    }

    const changeUseMarket = (item) => {
        axios.post('/admin/game/setting/changeUseMarket', {
            item: item,
            gameId: gameId,
        }).then((res) => {
            if(res.data.isChanged) {
                alert("변경완료")
                getUseMarkets(gameId)
            }
            else {
                alert("변경사항없음")
            }
        })
    }
    const changeAllUseMarket = () => {
        if(markets.length > 0) {
            axios.post('/admin/game/setting/changeAllUseMarket', {
                list: markets,
                gameId: gameId,
            }).then((res) => {
                if(res.data.isChanged) {
                    alert("변경완료")
                    getUseMarkets(gameId)
                }
                else {
                    alert("변경사항없음")
                }
            })
        }
        else {
            alert('마켓갯수가 0개입니다.')
        }

    }

    return <div className="common">
        <font color={"blue"} size="3">종목별 마켓사용 설정</font>
        <div className="d-flex p-1">
            <div className="me-2" style={{width: "48%"}}>
                <p>사용중인 종목</p>
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-dark">
                    <tr>
                        <th>코드</th>
                        <th>종목</th>
                        <th>-</th>
                    </tr>
                    </thead>
                    <tbody>
                    {games.map((item,index) => (
                        <tr style={{backgroundColor: gameId === item.id? '#ead980': "transparent"}}>
                            <td>{item.id}</td>
                            <td>{item.name_kor? item.name_kor: item.name}</td>
                            <td>
                                <button className="btn btn-sm btn-primary" onClick={(e) => {
                                    setGameId(item.id)
                                    setGameName(item.name)
                                    getUseMarkets(item.id)
                                }}>선택</button>
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
            <div className="ms-2" style={{width: "48%"}}>
                <p>{gameName} - 사용중인 마켓</p>

                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-dark">
                    <tr>
                        <th>코드</th>
                        <th>마켓명</th>
                        <th>종목적용마켓명</th>
                        <th>종목적용여부</th>
                        <th>
                            <button className="btn btn-sm btn-primary" onClick={(e) => {
                                changeAllUseMarket()
                            }}>일괄변경</button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {markets.map((item, index) => (
                        <tr>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>
                                <input className="form-control-sm" type="text" style={{ width: '100%' }}
                                       value={item.market_by_games?.length > 0? item.market_by_games[0].name : ""}
                                       onChange={(e) => {
                                           if(item.market_by_games?.length > 0) {
                                               const newItem = {...item}
                                               newItem.market_by_games[0].name = e.currentTarget.value
                                               const arr = [...markets]
                                               arr[index] = newItem
                                               setMarkets(arr)
                                           }
                                           else {
                                               alert("종목적용을 먼저 해주세요.")
                                           }

                                       }}

                                />
                            </td>
                            <td>
                                <Switch onChange={(checked) => {
                                    const newItem = {...item}
                                    newItem.market_by_games = checked? [{no:1}]: []
                                    const arr = [...markets]
                                    arr[index] = newItem
                                    setMarkets(arr)
                                }} checked={item.market_by_games?.length > 0} onColor="#2196F3"/>
                            </td>
                            <td>
                                <button className="btn btn-sm btn-primary" onClick={(e) => {
                                    changeUseMarket(item)
                                }}>저장</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

export default MarketByGame