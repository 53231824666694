import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {setSearchIdArr, setSearchIdSort} from "../../../redux/reducers/user/MemberReducer";

const MemberSearchModal01 = (props) => {

    const dispatch = useDispatch()
    const {open, onClose, initialState, onRefresh} = props

    const handleCancel = () => {
        onClose()
    }

    const handleSearch = () => {
        onClose()
        onRefresh()
    }


    const idChangeHandler = (e) => {
        dispatch(setSearchIdArr(e.currentTarget.value));
    }

    const sortChangeHandler = (value) => {
        dispatch(setSearchIdSort(value));
    }


    return (
        <div className="member_search_modal_background">
            <div className="member_search_modal_box" >
                <div className="member_search_modal_body">

                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table   width={"99%"}>
                            <tr>
                                <td style={{backgroundColor:"orange"}} align={"left"} height={"30px"}>
                                    <b><font color={"white"} size={"2"}>&nbsp;&nbsp; 검색할 아이디들을 입력하세요 (엔터로 구분) </font></b>
                                </td>
                                <td style={{backgroundColor:"orange"}} align={"right"}>
                                    <button style={{ backgroundColor: "lightgray", border: "none", color: "black" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleCancel}><b>X</b></button>
                                    &nbsp;&nbsp;
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%", backgroundColor:"lightgray"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray", height:"30px"}} align={"left"}>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={0} onChange={() => sortChangeHandler(0)}
                                           checked={initialState.searchIdSort == 0 ? true:false} />
                                    <b>정렬 필터 선택대로 정렬</b>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={1} onChange={() => sortChangeHandler(1)}
                                           checked={initialState.searchIdSort == 1 ? true:false} />
                                    입력 순서대로 정렬(고정)
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"white"}} >
                                    <textarea name="textarea_name" cols="90" rows="25" defaultValue="입력하세요"
                                              value={initialState.searchIdArr}  onChange={(e)=>idChangeHandler(e)}
                                    ></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"100%"} height={"30px"} onClick={handleSearch}>
                                    <font size={"4"}><b>검색</b></font>
                                </td>
                            </tr>
                        </table>
                    </div>


                </div>
            </div>
        </div>

    )
}
export default MemberSearchModal01;