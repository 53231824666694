import Switch from "react-switch";
import {useDispatch} from "react-redux";
import axios from "axios";
import {setLeagues, setTeams} from "../../../redux/reducers/game/gameSetting";


const TeamList = ({state, onRefresh}) => {
    const dispatch = useDispatch();
    const changeTeam = (item) => {
        axios.post('/admin/game/setting/changeTeam', {
            teamItem: item,
        }).then((res) => {
            if(res.data.isChanged) {
                alert("변경완료")
            }
            else {
                alert("변경실패")
            }
        })
    }
    const changeAllTeam = () => {
        axios.post('/admin/game/setting/changeAllTeam', {
            list: state.teams,
        }).then((res) => {
            if(res.data.isChanged) {
                alert("변경완료")
                onRefresh()
            }
            else {
                alert("변경실패")
            }
        })
    }

    return <div className="card-body" style={{height: "800px", overflowY: "scroll"}}>
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>코드</th>
                    <th>국가</th>
                    <th>종목</th>
                    <th>리그</th>
                    <th>팀</th>
                    <th>파싱명</th>
                    <th>
                        <button className="btn btn-sm btn-primary" onClick={(e) => {
                            changeAllTeam()
                        }}>일괄변경</button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {state.teams && state.teams.map((item, index) => (
                    <tr>
                        <td>{item.id}</td>
                        <td>{item.league?.game_kind.name_kor? item.league.game_kind.name_kor: item.league.game_kind.name}</td>
                        <td>{item.league?.nation.name_kor? item.league.nation.name_kor: item.league.nation.name}</td>
                        <td>{item.league?.name_kor? item.league.name_kor: item.league.name}</td>
                        <td style={{ textAlign: 'center',paddingLeft: '10px' }}>
                            <input className="form-control form-control-sm" type="text" style={{ width: '100%' }} value={item.name_kor}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.name_kor = e.currentTarget.value
                                       const arr = [...state.teams]
                                       arr[index] = newItem
                                       dispatch(setTeams({
                                           result: arr,
                                       }))
                                   }}
                            />
                        </td>
                        <td>{item.name}</td>
                        <td>
                            <div>
                                <button className="btn btn-sm btn-primary" onClick={(e) => {
                                    changeTeam(item)
                                }}>변경</button>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

    </div>
}

export default TeamList