import React, {useEffect, useState} from "react";
import SameList from "../../components/monitoring/SameList";
import {useDispatch, useSelector} from "react-redux";
import {getSameBrowsers} from "../../redux/reducers/monitoring/SameBrowserReducer";
import * as XLSX from "xlsx";
import moment from "moment/moment";
import Pagination from "../../components/common/Pagination2";
import {useParams} from "react-router-dom";

const SameContainer = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.sameBrowser);
    const param = useParams()

    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setSearchQuery(param.userId)
        dispatch(getSameBrowsers({
            query: param.userId,
            page: state.page,
            pageSize: state.pageSize,
        }))
    }, [])

    const onRefresh = () => {
        dispatch(getSameBrowsers({
            page: state.page,
            pageSize: state.pageSize,
        }))
    }

    const changeQuery = (e) => {
        setSearchQuery(e.target.value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }

    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getSameBrowsers({
            query: searchQuery,
            page: state.page,
            pageSize: state.pageSize,
        }))
    }

    const handlePageChange = (page) => {
        dispatch(getSameBrowsers({
            query: searchQuery,
            page: page,
            pageSize: state.pageSize,
        }))
    }

    const exportExcel = () => {
        if(state.sameBrowsers.length > 0) {
            let excelList = [];
            let headerList = [];

            const heading = [
                ["번호", "새로 로그인", "", "", "", "", "이전 로그인"],
            ]
            const heading2 = {
                '번호': "",
                "시간": "시간",
                "아이피": "아이피",
                "소속": "소속",
                "구분": "구분",
                "유저": "유저",
                "유저2": "유저",
                "구분2": "구분",
                "소속2": "소속",
                "시간2": "시간",
            }
            headerList.push(heading2)

            for(let i = 0; i < state.sameBrowsers.length; i++) {
                const row = state.sameBrowsers[i]
                let jsonObject = {
                    '번호': row.no,
                    "시간": row.recent_login_dt,
                    "아이피": row.use_ip,
                    "소속": row.recent_store_cd,
                    "구분": row.recent_user_gb_cd,
                    "유저": row.recent_nickname,
                    "유저2": row.before_nickname,
                    "구분2": row.before_user_gb_cd,
                    "소속2": row.before_store_cd,
                    "시간2": row.before_login_dt,
                }
                excelList.push(jsonObject)
            }


            const workSheet = XLSX.utils.json_to_sheet([]);
            workSheet["!merges"] = [
                {s:{r: 0, c: 0}, e: {r:1, c:0}},
                {s:{r: 0, c: 1}, e: {r:0, c:5}},
                {s:{r: 0, c: 6}, e: {r:0, c:9}},
            ]
            const workBook = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(workSheet, heading)
            XLSX.utils.sheet_add_json(workSheet, headerList, {origin: 'A2', skipHeader: true})
            XLSX.utils.sheet_add_json(workSheet, excelList, {origin: 'A3', skipHeader: true})
            XLSX.utils.book_append_sheet(workBook, workSheet, '동일PC동일브라우저사용');
            XLSX.writeFile(workBook, `동일PC동일브라우저사용_${moment().format("YYYYMMDD")}.xlsx`);
        }
        else {
            alert("다운로드할 내용이 없습니다.")
        }
    }

    return (
        <>
            <div className="common">
            <div style={{display: "flex"}}>
                <button style={{ marginLeft: "5px" ,position: "relative", top: "-10px" , backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px"}} onClick={exportExcel}>엑셀 다운로드</button>

                <div style={{display: "flex"}}>
                    <label>아이디</label>
                    <input className={"form-control"} type={"text"} value={searchQuery} onChange={changeQuery} onKeyDown={inputPress}/>
                </div>
            </div>

            <SameList state={state} onRefresh={onRefresh} />
            <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange}/>
            </div>
        </>
    )
}

export default SameContainer