import {call, put} from "redux-saga/effects";
import {failedGetDistributorListReducer, setDistributorListReducer} from "../../reducers/partner/DistributorListReducer";
import {fetchDistributorList} from "../../../lib/api";

export function* getDistributorListSaga(action) {
    try{
        console.log("##getDistributorListSaga input#",action.payload.input)
        const res = yield call(fetchDistributorList,
            action.payload.input,
        );
        console.log("##getDistributorListSaga output#",res.data)
        yield put(setDistributorListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDistributorListReducer(error))
    }
}