import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {getOrgAuthListReducer,setMenuAuth,setInfoAuth,setLimitAuth} from "../../redux/reducers/partner/OrgAuthListReducer";
import {setMbphEnd} from "../../redux/reducers/partner/DistributorAddReducer";
import axios from "axios";
import {setComboOrgList} from "../../redux/reducers/partner/PartnerAddReducer";
import Pagination from "../../components/common/Pagination2";
import {getRecommendKeyListReducer} from "../../redux/reducers/partner/RecommendKeyListReducer";
import {insertAdminLog} from "../../lib/api";

const Partner_auth = () => {
    const userInfoId = useSelector((state) => state.userInfo.id)
    const dispatch = useDispatch();
    const initialState = useSelector((state) => state.OrgAuthListReducer);

    const getList = () => {
        if(initialState.meneAuthList !== null ) {
            dispatch(getOrgAuthListReducer({
                input:{
                    page: 1,
                    pageSize: initialState.input.pageSize,
                }
            }))
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "조회권한관리"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const checkMenuAuth = (index01,menu_auth_cd,checked) => {
        console.log("##check함수##",index01,"##",menu_auth_cd,"##",checked)

        dispatch(setMenuAuth({index01 : index01,menu_auth_cd:menu_auth_cd, checked : checked}))
    }

    const checkInfoAuth = (index01,info_auth_cd,checked) => {
        console.log("##check함수##",index01,"##",info_auth_cd,"##",checked)

        dispatch(setInfoAuth({index01 : index01,info_auth_cd:info_auth_cd, checked : checked}))
    }

    const changeLimit = (index01,gb,val) => {
        const value = val.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setLimitAuth({index01 : index01,gb:gb, val : value}))
        }
    }

    const saveAuth = () => {
        console.log("그리드확인",initialState.gridList)
        axios.post('/admin/partner/saveOrgAuthList', initialState.gridList).
        then((res) => {
            if(res.data.IsSaved !== null) {
                alert('저장되었습니다.')
            }
            else {
                alert('저장실패')
            }
        })
    }

    const handlePageChange = (page) => {
        dispatch(getOrgAuthListReducer({
            input:{
                page: page,
                pageSize: initialState.input.pageSize,
            }
        }))
    }
    return (

        <div className="common" >
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">조회 권한 관리</font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table   width={"50%"}>
                        <tbody>
                        <tr>
                            <td align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>

                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table   width={"49%"}>
                        <tbody>
                        <tr>
                            <td align={"right"}>
                                <button onClick={saveAuth} style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" >저장</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>

            </div>

            <table className="commonT table table-bordered table-sm text-center align-middle">
                <thead>
                <tr>

                </tr>
                </thead>
            </table>
            <div className="commonT container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="commonT table table-bordered table-sm text-center align-middle">
                    <thead>
                    <tr>
                        <th style={{width: "3%"}} rowSpan="2" >구분</th>
                        <th style={{width: "5%"}} rowSpan="2" >이름</th>
                        <th style={{width: "5%"}} rowSpan="2" >아이디</th>
                        <th style={{width: "10%"}} colSpan="2">제한</th>
                        <th style={{width: "20%"}} colSpan="6">메뉴 권한</th>
                        <th style={{width: "*%"}} colSpan="16">회원목록 정보 노출 여부</th>
                    </tr>
                    <tr>
                        <th>최소환전</th>
                        <th>최소단위</th>
                        {initialState.menuAuthList && initialState.menuAuthList.length > 0 && initialState.menuAuthList.map((menuAuthList, index) => (
                            <th key={menuAuthList.cmmn_cd_dtl_id}>{menuAuthList.cmmn_cd_dtl_nm}</th>
                        ))}
                        {initialState.infoAuthList && initialState.infoAuthList.length > 0 && initialState.infoAuthList.map((infoAuthList, index) => (
                            <th key={infoAuthList.cmmn_cd_dtl_id}>{infoAuthList.cmmn_cd_dtl_nm}</th>
                        ))}
                    </tr>
                    </thead>
                    {initialState.totalList && initialState.totalList.length > 0 ? initialState.gridList.map((List, index01) => (
                            <tbody key={index01}>
                            <tr style={{ backgroundColor : List.org_color}}>
                                <td align={"center"} >{List.org_level_cd_nm}</td>
                                <td align={"center"} >{List.org_nm}</td>
                                <td align={"center"} >{List.org_id}</td>
                                <td align={"center"} >
                                    <input value={List.admin_limit_auths[0] && List.admin_limit_auths[0].min_withdraw_amt} onChange={(e)=>changeLimit(index01,'min_withdraw_amt',e.target.value)} className="form-check-input sm-input" type="text" placeholder="10,000" style={{
                                        width: "100px", backgroundColor:"white",textAlign: 'right'
                                    }}  />
                                </td>
                                <td align={"center"} >
                                    <input value={List.admin_limit_auths[0] && List.admin_limit_auths[0].min_unit_amt} onChange={(e)=>changeLimit(index01,'min_unit_amt',e.target.value)} className="form-check-input sm-input" type="text" placeholder="10,000" style={{
                                        width: "100px", backgroundColor:"white",textAlign: 'right'
                                    }} />
                                </td>

                                {initialState.menuAuthList && initialState.menuAuthList.length > 0 && initialState.menuAuthList.map((menuAuthList, index02) => (
                                    <td key={menuAuthList.cmmn_cd_dtl_id}>
                                        <input
                                            //value={List.admin_menu_auths[index02] &&List.admin_menu_auths[index02].info_auth_cd}
                                            className="form-check-input"
                                            type="checkbox"
                                            name={`checkbox_${menuAuthList.cmmn_cd_dtl_id}`}
                                            id={`checkbox_${menuAuthList.cmmn_cd_dtl_id}`}
                                            style={{ width: "20px", height: "20px" }}
                                            checked={
                                                List.admin_menu_auths &&
                                                List.admin_menu_auths.find(a => a.menu_auth_cd === menuAuthList.cmmn_cd_dtl_id)
                                                    ? menuAuthList.cmmn_cd_dtl_id === List.admin_menu_auths.find(a => a.menu_auth_cd === menuAuthList.cmmn_cd_dtl_id).menu_auth_cd
                                                    : false
                                            }
                                            onChange={(e) => checkMenuAuth(index01,menuAuthList.cmmn_cd_dtl_id,e.currentTarget.checked)}
                                        />
                                    </td>
                                ))}
                                {initialState.infoAuthList && initialState.infoAuthList.length > 0 && initialState.infoAuthList.map((infoAuthList, index03) => (
                                    <td key={infoAuthList.cmmn_cd_dtl_id}>
                                        <input
                                            //value={List.admin_menu_auths[index02] &&List.admin_menu_auths[index02].info_auth_cd}
                                            className="form-check-input"
                                            type="checkbox"
                                            name={`checkbox_${infoAuthList.cmmn_cd_dtl_id}`}
                                            id={`checkbox_${infoAuthList.cmmn_cd_dtl_id}`}
                                            style={{ width: "20px", height: "20px" }}
                                            checked={
                                                List.admin_info_auths &&
                                                List.admin_info_auths.find(a => a.info_auth_cd === infoAuthList.cmmn_cd_dtl_id)
                                                    ? infoAuthList.cmmn_cd_dtl_id === List.admin_info_auths.find(a => a.info_auth_cd === infoAuthList.cmmn_cd_dtl_id).info_auth_cd
                                                    : false
                                            }
                                            onChange={(e) => checkInfoAuth(index01,infoAuthList.cmmn_cd_dtl_id,e.currentTarget.checked)}
                                        />
                                    </td>
                                ))}
                            </tr>
                            </tbody>
                        )) :
                        <tbody>
                        <tr>
                            <td colSpan= "18">조회된 데이터가 없습니다.</td>
                        </tr>
                        </tbody>
                    }
                </table>
            </div>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalCount && initialState.totalCount > 0 ?
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.input.pageSize} currentPage={initialState.input.page} onPageChange={handlePageChange} />
                    :""}
            </div>

        </div>

    )
}
export default Partner_auth;