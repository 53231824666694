import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";

const TagSetting = ({tagSetting, onRefresh, initialState, deleteState, insertState, updateState}) => {

    const [changeColorList, setChangeColorList] = useState([]);
    const [colorCd, setColorCd] = useState('')
    const [insertColorCd, setInsertColorCd] = useState('')
    const [noArr, setNoArr] = useState([]);
    const [etcArr, setEtcNmArr] = useState([]);
    const [tagNmArr, setTagNmArr] = useState([]);
    const [colorNmArr, setColorNmArr] = useState([]);

    const [insertNoArr, setInsertNoArr] = useState([]);
    const [insertEtcArr, setInsertEtcNmArr] = useState([]);
    const [insertTagNmArr, setInsertTagNmArr] = useState([]);
    const [insertColorNmArr, setInsertColorNmArr] = useState([]);

    const insertTagNmArrHandler = (e, index) => {
        insertTagNmArr[index] = e.target.value;
        setInsertTagNmArr(insertTagNmArr);
        insertNoArr[index] = index;
        setInsertNoArr(insertNoArr);

        const {name, value} = e.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;

        //alert("selectedNos >>> "+JSON.stringify(insertTagNmArr));
    }
    const insertEtcArrHandler = (e, index) => {
        //alert("selectedNos0 >>> "+JSON.stringify(noArr));
        insertEtcArr[index] = e.target.value;
        setInsertEtcNmArr(insertEtcArr);
        insertNoArr[index] = index;
        setInsertNoArr(insertNoArr);
        const {name, value} = e.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        //alert("selectedNos >>> "+JSON.stringify(etcArr));
        //alert("selectedNos2 >>> "+JSON.stringify(noArr));
    }
    const insertColorNmArrHandler = (e, index) => {
        insertColorNmArr[index] = e.target.value;
        setInsertColorNmArr(insertColorNmArr);
        setInsertColorCd(e.target.value);

        //alert("insertColorNmArr ::: "+JSON.stringify(insertColorNmArr));

        insertNoArr[index] = index;
        setInsertNoArr(insertNoArr);
        //const {name, value} = e.target;
        const name = "insertColor";
        const value = e.target.value;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;

    }


    const tagNmArrHandler = (e, index) => {
        tagNmArr[index] = e.target.value;
        setTagNmArr(tagNmArr);
        noArr[index] = index;
        setNoArr(noArr);
        //alert("selectedNos >>> "+JSON.stringify(tagNmArr));
    }
    const etcArrHandler = (e, index) => {
        //alert("selectedNos0 >>> "+JSON.stringify(noArr));
        etcArr[index] = e.target.value;
        setEtcNmArr(etcArr);
        noArr[index] = index;
        setNoArr(noArr);
        //alert("selectedNos >>> "+JSON.stringify(etcArr));
        //alert("selectedNos2 >>> "+JSON.stringify(noArr));
    }
    const colorNmArrHandler = (e, index) => {
        colorNmArr[index] = e.target.value;
        setColorCd(e.target.value);
        setColorNmArr(colorNmArr);

        noArr[index] = index;
        setNoArr(noArr);
    }


    const getCmmnCd = () => {
        if(changeColorList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0023',
            }).then((res) => {
                setChangeColorList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])


    const [rowsData, setRowsData] = useState([]);
    const addTableRows = ()=>{

        const rowsInput={
            insertNo:'',
            insertEtc:'',
            insertColor:''
        }
        setRowsData([...rowsData, rowsInput])

    }
    const deleteTableRows = (index)=>{

        const rows = [...rowsData];
        //alert("index :: "+index);
        rows.splice(index, 1);
        alert("rows :: "+JSON.stringify(rows));
        setRowsData(rows);
    }


    function TableRows({rowsData, deleteTableRows, handleChange}) {
        return(

            rowsData.map((data, index)=>{
                const {insertNo, insertEtc, insertColor}= data;
                return(
                    <tr key={index} >
                        <td align={"center"}>추가</td>
                        <td align={"center"}>
                            <input className="form-check-input sm-input" type="text" name="insertNo" style={{width: "300px"}}
                                   placeholder={insertNo}
                                   onChange={(e) => {insertTagNmArrHandler(e, index)}}
                            />
                        </td>
                        <td align={"center"}>
                            <input className="form-check-input sm-input" type="text" name="insertEtc" style={{width: "400px"}}
                                   placeholder={insertEtc}
                                   onChange={(e) => {insertEtcArrHandler(e, index)}}
                            />
                        </td>

                        {changeColorList.map(item => (
                            <React.Fragment key={item.cmmn_cd_dtl_id}>
                                <td align={"center"} height={"20px"} >
                                    <label className={`looking_id_show type${item.cmmn_cd_dtl_id}`}  >
                                        <font color={item.etc}>{item.cmmn_cd_dtl_id}</font>
                                    </label>
                                    <div className={`looking_id_show type${item.cmmn_cd_dtl_id}`} >
                                        <input
                                            type="radio"
                                            name={`insertColor${index}`}
                                            id={`insertColor${index}`}
                                            value={item.cmmn_cd_dtl_id}
                                            style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                            checked={( item.cmmn_cd_dtl_id === insertColor  ? true : false )}
                                            onChange={(e) => {insertColorNmArrHandler(e, index)}}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                </td>
                            </React.Fragment>
                        ))}
                        <td align={"center"}>
                            <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "23px", width: "fit-content", marginRight: "5px"}} onClick={()=>(deleteTableRows(index))}>삭제</button>
                        </td>
                    </tr>
                )
            })

        )

    }



    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"}  >
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "100px", backgroundColor:"green"
                                }} type="submit" className="btn btn-primary" onClick={addTableRows} >신규 등록</button>
                                &nbsp;
                                <button style={{
                                    width: "100px", backgroundColor:"green"
                                }} type="submit" className="btn btn-primary" onClick={()=>(insertState(noArr,etcArr,tagNmArr,colorNmArr,insertNoArr,insertEtcArr,insertTagNmArr,insertColorNmArr)) } >저장</button>
                            </td>
                        </tr>
                    </table>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <th style={{width: "2%"}}>번호</th>
                            <th style={{width: "20%"}}>태그명</th>
                            <th style={{width: "25%"}}>부가설명</th>
                            <th style={{width: "*%"}} colSpan="30">태그색상</th>
                            <th style={{width: "8%"}}>관리</th>
                        </tr>
                        </thead>
                        {!tagSetting.length && (
                            <tbody key={tagSetting.no}>
                            <tr>
                                <td colSpan="5">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {tagSetting.map((tagSetting, index) => (
                            <tbody key={tagSetting.no}>
                            <tr>
                                <td align={"center"}>{tagSetting.no}</td>
                                <td align={"center"}>
                                    <input className="form-check-input sm-input" type="text" style={{width: "300px"}}
                                           placeholder={tagSetting.tag_nm}
                                           onChange={(e) => {tagNmArrHandler(e, tagSetting.no)}}
                                    />
                                </td>
                                <td align={"center"}>
                                    <input className="form-check-input sm-input" type="text" style={{width: "400px"}}
                                           placeholder={tagSetting.etc}
                                           onChange={(e) => {etcArrHandler(e, tagSetting.no)}}
                                    />
                                </td>

                                {changeColorList.map(item => (
                                    <React.Fragment key={item.cmmn_cd_dtl_id}>
                                        <td align={"center"} height={"20px"} >
                                            <label className={`looking_id_show type${item.cmmn_cd_dtl_id}`}  >
                                                <font color={item.font_color}>{item.cmmn_cd_dtl_id}</font>
                                            </label>
                                            <div className={`looking_id_show type${item.cmmn_cd_dtl_id}`} >
                                                <input
                                                    type="radio"
                                                    name={`flexColor${tagSetting.no}`}
                                                    id={`flexColor${tagSetting.no}`}
                                                    value={item.cmmn_cd_dtl_id}
                                                    style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                                    checked={colorNmArr[tagSetting.no] === undefined ? ( item.cmmn_cd_dtl_id === tagSetting.tag_color_cd ? true : false ) : ( item.cmmn_cd_dtl_id === colorNmArr[tagSetting.no] ? true : false ) }
                                                    onChange={(e) => {colorNmArrHandler(e, tagSetting.no)}}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </div>
                                        </td>
                                    </React.Fragment>
                                ))}
                                <td align={"center"}>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "23px", width: "fit-content", marginRight: "5px"}} onClick={() => {deleteState(tagSetting.no)}}>삭제</button>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                        <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows}  />
                    </table>
                </div>


            </div>
        </div>

    )
}

export default TagSetting;