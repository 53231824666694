import React from 'react';
import CloseContainer from "../../../containers/game/manage/CloseContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const Close = () => {
    return (
        <div className="common">
            <font color={"blue"} size="3">경기마감</font>
            <CloseContainer />
        </div>
    );
};

export default Close;