import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getLotteryReducer} from "../../redux/reducers/deposit/LotteryReducer";
import Pagination from "../../components/common/Pagination2";
import axios from "axios";
import {getCouponList} from "../../redux/reducers/deposit/CouponListReducer";
import {insertAdminLog} from "../../lib/api";

const Lottery = ({userId}) => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.LotteryReducer)
    const [searchQuery, setSearchQuery] = useState()


    useEffect(() => {

        dispatch(getLotteryReducer({
            input:{
                page: 1,
                pageSize: initialState.input.pageSize,
                query: userId && userId !== ""? userId : initialState.input.query,
            }
        }))
        setSearchQuery(userId)

    }, [dispatch])


    const getLotteryList = () => {
        if(initialState.totalList !== null ) {
            setSearchQuery(userId)
            dispatch(getLotteryReducer({
                input:{
                    page: 1,
                    pageSize: initialState.input.pageSize,
                    query: userId && userId !== ""? userId : initialState.input.query,
                }
            }))
        }
    }
    useEffect(getLotteryList, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getLotteryReducer({
            input:{
                page: page,
                pageSize: initialState.input.pageSize,
                query : initialState.input.query,
            }
        }))
    }

    const handleDelete = (no) => {
        if (window.confirm("순서("+no+"번)을 복권리스트에서 삭제하시겠습니까?")) {
            axios.post('/admin/deposit/Lottery/delete', {
                no: no,
            }).then((res) => {
                if (res.data.isDeleted) {
                    alert('삭제했습니다.')
                    refresh()
                } else {
                    alert('삭제실패했습니다.')
                }
            })
        }
    }

    const refresh = () =>{
        dispatch(getLotteryReducer({
            input:{
                page: 1,
                pageSize: initialState.input.pageSize,
                query : initialState.input.query,
            }
        }))
    }

    const handleChangeMemberHaveLottery = () =>{

    }

    const handleChangeOrgCd = () =>{

    }

    const handleChangeLotteryStatCd = () =>{

    }

    const handleChangePntGdCd = () =>{

    }

    const handleChangeQueryGb = () =>{

    }

    return (


        <div className="commonNew">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">회원 보유 복권</font>&nbsp;
                    <select
                        className="form-select sm-select"

                        style={{
                            width: "100px"
                        }}
                        value={"999"}
                        onChange={handleChangeMemberHaveLottery}
                    >
                        <option value={50}>50개</option>
                        <option value={100}>100개</option>
                        <option value={200}>200개</option>
                        <option value={999}>30개</option>
                    </select>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>엑셀 다운로드</button>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>일주일</button>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>어제</button>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>오늘</button>

                    <input className="form-control sm-date" type="date"/>
                    ~
                    <input className="form-control sm-date" type="date"/>
                    &nbsp;
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>이번 정산일 기준</button>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>지난 정산일 기준</button>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>1일~10일</button>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>11일~20일</button>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>21일~말일</button>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    검색&nbsp;
                    <select
                        className="form-select sm-select"
                        style={{
                            width: "170px"
                        }}
                        value={"999"}
                        onChange={handleChangeOrgCd}
                    >
                        <option value={999}>::총판/가맹점::</option>
                    </select>
                    &nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" /> 하위 포함 검색
                    &nbsp;&nbsp;
                    <select
                        className="form-select sm-select"
                        style={{
                            width: "170px"
                        }}
                        value={"999"}
                        onChange={handleChangeLotteryStatCd}
                    >
                        <option value={999}>전체</option>
                    </select>
                    &nbsp;
                    <select
                        className="form-select sm-select"
                        style={{
                            width: "170px"
                        }}
                        value={"999"}
                        onChange={handleChangePntGdCd}
                    >
                        <option value={999}>전체 P등급</option>
                    </select>
                    &nbsp;
                    <select
                        className="form-select sm-select"
                        defaultValue={userId && userId !== ""? '2':'1'}
                        style={{
                            width: "170px"
                        }}
                        onChange={handleChangeQueryGb}
                    >
                        <option value={'1'}>닉네임</option>
                        <option value={'2'}>아이디</option>

                    </select>
                    &nbsp;
                    <input className="form-check-input sm-input" type="text" value={searchQuery} onChange={(e) => {
                        setSearchQuery(e.currentTarget.value)
                    }}/>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th style={{width: "33.3%"}}>사용건수 /발급건수</th>
                            <th style={{width: "33.3%"}}>사용액 / 발급액</th>
                            <th style={{width: "33.3%"}}>미사용액 /미사용건수</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td align={"center"}>{initialState.sum.useCnt} / {initialState.sum.payCnt}</td>
                            <td align={"center"}>{initialState.sum.useAmt}/ {initialState.sum.payAmt}</td>
                            <td align={"center"}>{initialState.sum.unUseCnt} / {initialState.sum.unUseCnt}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th style={{width: "2%"}}>번호</th>
                            <th style={{width: "10%"}}>소속</th>
                            <th style={{width: "10%"}}>아이디</th>
                            <th style={{width: "10%"}}>닉네임</th>
                            <th style={{width: "7%"}}>충전액</th>
                            <th style={{width: "7%"}}>복권이름</th>
                            <th style={{width: "7%"}}>지급액</th>
                            <th style={{width: "7%"}}>지급일</th>
                            <th style={{width: "7%"}}>사용일</th>
                            <th style={{width: "*%"}}>관리자<br></br>지급여부</th>
                            <th style={{width: "5%"}}>상태</th>
                            <th style={{width: "5%"}}>삭제</th>
                        </tr>
                        </thead>
                        {initialState.totalList && initialState.totalList.length > 0 ?
                            initialState.lotteryList.map((List, index) => (
                                <tbody key={List.no}>
                                <tr>
                                    <td align={"center"}>{List.no}</td>
                                    <td align={"center"}>{List.storeCdNm}</td>
                                    <td align={"center"} style={{backgroundColor:"black"}} ><font color="yellow">{List.id}</font></td>
                                    <td align={"center"} style={{backgroundColor:"black"}} ><font color="yellow">{List.nickname}</font></td>
                                    <td align={"center"}>{List.depositAmt}</td>
                                    <td align={"center"}>{List.lotteryName}</td>
                                    <td align={"center"}><font color="blue">{List.payAmt}</font></td>
                                    <td align={"center"}>{List.payDt}</td>
                                    <td align={"center"}>{List.useDt}</td>
                                    <td align={"center"}>{List.adminPayYn}</td>
                                    <td align={"center"} ><font color={List.lotteryStateColor}>{List.lotteryStateCdNm}</font></td>
                                    <td align={"center"}>
                                        <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={()=>handleDelete(List.no)}>삭제</button>
                                    </td>
                                </tr>
                                </tbody>
                            )) :
                            <tbody>
                            <tr>
                                <td colSpan= "15">조회된 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
                <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                    {initialState.totalCount && initialState.totalCount > 0 ?
                        <Pagination totalCount={initialState.totalCount} pageSize={initialState.input.pageSize} currentPage={initialState.input.page} onPageChange={handlePageChange} />
                        :""}
                </div>
            </div>
        </div>


    )
}
export default Lottery;