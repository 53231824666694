import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"


export const siteSettingSlice = createSlice({
    name: 'siteSetting',
    initialState: {
        isLoading: false,
        error: null,
        siteSetting: [],
        countList: {},
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
        userGbCd: "",
        searchGbCd: ""
    },
    reducers: {
        getSiteSetting(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userGbCd = action.payload.userGbCd;
            state.searchGbCd = action.payload.searchGbCd;

        },
        setSiteSetting(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.siteSetting = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList;;

        },
        failedGeSiteSetting(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { getSiteSetting, setSiteSetting, failedGeSiteSetting } = siteSettingSlice.actions;

export default siteSettingSlice.reducer;