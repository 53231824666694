import {call, put, take,} from "redux-saga/effects";
import {fetchAdjustment} from "../../../lib/api";
import {failedGetAdjustment, setAdjustment} from "../../reducers/partner/AdjustmentReducer";

export function* getAdjustmentSaga(action) {
    try{
        const res = yield call(
            fetchAdjustment,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userId
        )
        yield put(setAdjustment(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetAdjustment(error))
    }
}