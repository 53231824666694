import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getActiveMembers} from "../../redux/reducers/monitoring/ActiveMemberReducer";
import ActiveMemberList from "../../components/monitoring/ActiveMemberList";
import OrgSelect from "../../components/org/OrgSelect";
import PointGrade from "../../components/common/PointGrade";
import InterestUser from "../../components/common/InterestUserChecked";
import BarChart from "../../components/chart/BarChart";
import LineChart from "../../components/chart/LineChart";


const ActiveMemberContainer = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.activeMember);

    const [searchQuery, setSearchQuery] = useState("");
    const [searchGb, setSearchGb] = useState("nickname");
    const [isOpenChart, setIsOpenChart] = useState(false)

    {/*TODO 셀럭터들 데이터 불러와서 필터링 기능 추가.*/}

    const input = {
        device: state.device,
        searchGb: state.searchGb,
        searchQuery: state.searchQuery,
        sortColum: state.sortColum,
        sortValue: state.sortValue,
        orgId: state.orgId,
        userGbCd: state.userGbCd,
        userStateCd: state.userStateCd,
        pGrade: state.pGrade,
        interestUser: state.interestUser,
    }
    useEffect(() => {
        dispatch(getActiveMembers(input))
    }, [])

    const onRefresh = () => {
        dispatch(getActiveMembers(input))
    }
    const onReload = () => {
        dispatch(getActiveMembers(input))
    }

    //TODO 1분 인터발 새로고침 처리.

    const changeQuery = (e) => {
        setSearchQuery(e.target.value)
    }

    const changeSearchGb = (e) => {
        setSearchGb(e.target.value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }

    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.searchGb = searchGb
        fixInput.searchQuery = searchQuery
        dispatch(getActiveMembers(fixInput))
    }

    const onDeviceChange = (e) => {
        const fixInput = {...input}
        fixInput.device = parseInt(e.target.value)
        fixInput.searchGb = searchGb
        fixInput.searchQuery = searchQuery
        dispatch(getActiveMembers(fixInput))
    }

    const setParentParam = (id) => {
        const fixInput = {...input}
        fixInput.orgId = id
        dispatch(getActiveMembers(fixInput))
    }
    const changeUserGbCd = (e) => {
        const fixInput = {...input}
        fixInput.userGbCd = e.currentTarget.value
        dispatch(getActiveMembers(fixInput))
    }
    const changeUserStateCd = (e) => {
        const fixInput = {...input}
        fixInput.userStateCd = e.currentTarget.value
        dispatch(getActiveMembers(fixInput))
    }
    const onChangePG = (selectedPGNos) => {
        const fixInput = {...input}
        fixInput.pGrade = selectedPGNos
        dispatch(getActiveMembers(fixInput))
    }

    const onCheckedColor = (selectedNos) => {
        const fixInput = {...input}
        fixInput.interestUser = selectedNos
        dispatch(getActiveMembers(fixInput))
    }

    return <div className="commonNew">
        <div style={{display: "flex", marginTop: "1px", marginBottom: "3px", justifyContent: "start", width: "100%", paddingRight: "5px", paddingLeft: "5px"}}>
            <div className="me-1" style={{width: "300px", height: "20px"}}>
                <div style={{zIndex : 998}}>
                    <OrgSelect setChildParam={setParentParam} id={0}/>
                </div>
            </div>
            <select className="form-select sm-select" onChange={changeUserGbCd}>
                <option selected>::회원분류::</option>
                <option value="01">대기회원</option>
                <option value="02">무료회원</option>
                <option value="03">유료회원</option>
                <option value="04">VIP회원</option>
                <option value="05">가라회원</option>
                <option value="06">관리자</option>
                <option value="07">최고관리자</option>
            </select>
            <select className="form-select sm-select" onChange={changeUserStateCd}>
                <option selected>::회원상태::</option>
                <option value="01">정지</option>
                <option value="02">정상</option>
            </select>
            <select className="form-select sm-select" defaultValue={0} value={state.device} onChange={onDeviceChange}>
                <option value={0}>::모든기기::</option>
                <option value={1}>PC</option>
                <option value={2}>모바일</option>
            </select>
            <select className="form-select sm-select"
                    defaultValue={"nickname"} value={searchGb} onChange={changeSearchGb}
            >
                <option value={"nickname"}>닉네임</option>
                <option value={"reg_domain_nm"}>도메인</option>
                <option value={"id"}>아이디</option>
                <option value={"name"}>이름</option>
                <option value={"entr_ip"}>가입아이피</option>
                <option value={"rcnt_actv_ip"}>최근아이피</option>
            </select>
            <input className="form-check-input sm-input" type="text" value={searchQuery} onChange={changeQuery} onKeyDown={inputPress}/>
            <button onClick={(e) => {
                onSearch(searchQuery)
            }}>검색</button>
        </div>
        <div style={{display: "flex", paddingRight: "5px", paddingLeft: "5px"}}>
            <div style={{display: "flex"}}>
                <div className="commonNew">
                    <label className="sm-label">
                        최근활동 :
                    </label>
                    <input className="form-check-input sm-radio" type="radio" name="flexRadioActive" id="radio_active_past" onChange={(e) => {
                        if(e.currentTarget.checked) {
                            const fixInput = {...input}
                            fixInput.sortColum = "rcnt_actv_dttm"
                            fixInput.sortValue = 0
                            dispatch(getActiveMembers(fixInput))
                        }
                    }}/>
                    <label className="form-check-label me-2" htmlFor="radio_active_past">
                        과거 우선
                    </label>
                    <input className="form-check-input sm-radio" type="radio" name="flexRadioActive" id="radio_active_recent" onChange={(e) => {
                        if(e.currentTarget.checked) {
                            const fixInput = {...input}
                            fixInput.sortColum = "rcnt_actv_dttm"
                            fixInput.sortValue = 1
                            dispatch(getActiveMembers(fixInput))
                        }
                    }}/>
                    <label className="form-check-label me-2" htmlFor="radio_active_recent">
                        최근 우선
                    </label>
                </div>
            </div>
            <div style={{marginLeft: "auto"}}></div>
            <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}} onClick={(e) => {
                setIsOpenChart(!isOpenChart)
            }}>차트 보기</button>
        </div>

        <div style={{display: "flex", paddingRight: "5px", paddingLeft: "5px"}}>
            <PointGrade onChangePG={onChangePG}/>
        </div>
        <div style={{display: "flex", paddingRight: "5px", paddingLeft: "5px"}}>
            <InterestUser onCheckedColor={onCheckedColor} />
        </div>
        <div style={{display: "flex", paddingRight: "5px", paddingLeft: "5px", paddingTop: "5px"}}>
            <div>
                <p>
                    <span style={{ color: "#5dc434", fontWeight: "bold" }}>실시간 접속자 현황</span>
                </p>
                <p>
                    <span style={{ color: "red", fontWeight: "bold" }}>1분 단위로 새로고침 됩니다.</span>
                </p>
            </div>
            <div style={{marginLeft: "auto"}}></div>

            <button style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "50px", width: "fit-content"}} onClick={onReload}>현재 페이지<br/>새로고침</button>
        </div>
        {isOpenChart && (
            <>
                <div className="d-flex" style={{height: "500px"}}>
                    <LineChart title={'주간 접속자 (금주 / 지난 4주 평균)'}/>
                    <LineChart title={'주간 페이지뷰 (금주 / 지난 4주 평균)'}/>
                </div>
                <div className="d-flex" style={{height: "500px"}}>
                    <LineChart title={'시간대별 접속자 (오늘 / 지난 일주일 평균)'}/>
                    <LineChart title={'시간대별 페이지뷰 (오늘 / 지난 일주일 평균)'}/>
                </div>
            </>
        )}

        <ActiveMemberList state={state} onRefresh={onRefresh} />
    </div>
}

export default ActiveMemberContainer