import Switch from "react-switch";


const Ladder = () => {

    return (
        <div>
            <p>사다리</p>
            <div>
                <div className="d-flex">
                    <div className="me-5">
                        <div className="d-flex">
                            <label>게임사용 여부</label>
                            <Switch checked={false} onChange={(e) => {}}/>
                        </div>
                    </div>
                    <div>
                        <div className="input-group mb-3">
                            <label>마감시간</label>
                            <input type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
                            <span className="input-group-text">초</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <button>1레벨 변경</button>
                    <button>전체레벨 일괄적용</button>
                </div>
            </div>
        </div>
    )
}

export default Ladder