import {call, put, take,} from "redux-saga/effects";
import {fetchDepositWithdrawLog} from "../../../lib/api";
import {failedGetDepositWithdrawLog, setDepositWithdrawLog} from "../../reducers/deposit/DepositWithdrawLogReducer";

export function* getDepositWithdrawLogSaga(action) {
    try{
        const res = yield call(
            fetchDepositWithdrawLog,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd,
            action.payload.searchCd01, action.payload.searchCd02, action.payload.searchCd03,
            action.payload.searchCd04, action.payload.searchCd05, action.payload.searchCd06,
            action.payload.userId
        )
        yield put(setDepositWithdrawLog(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDepositWithdrawLog(error))
    }
}