import {call, put} from "redux-saga/effects";
import {failedGeSiteSetting, setSiteSetting} from "../../reducers/setting/SiteSettingSingleReducer";
import {fetchSiteSettingSingle} from "../../../lib/api";

export function* getSiteSettingSingleSaga(action) {
    try{
        console.log("##getSiteSettingSingleSaga input#",action.payload.input)
        const res = yield call(fetchSiteSettingSingle,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd
        );
        console.log("##getSiteSettingSingleSaga output#",res.data)
        yield put(setSiteSetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGeSiteSetting(error))
    }
}