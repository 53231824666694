import React from "react";
import {useDispatch} from "react-redux";
import {openUserSimpleInfo, setIsOpen} from "../../redux/reducers/userInfo";


const OverlapList = ({state, onRefresh}) => {
    const dispatch = useDispatch()

    return (
        <div className="common">
            <table className="table table-bordered table-sm text-center align-middle">
                <thead>
                <tr>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>순서</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>번호</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>총판</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>가맹점</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>회원등급</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>회원상태</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>충전</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>환전</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>마진</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>가입일</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>최근활동</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>로그인</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>접속IP</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>삭제여부</td>
                </tr>
                </thead>
                <tbody>
                {state?.overlaps && state.overlaps.map((item, index) => (
                    <tr>
                        <td>{item.no}</td>
                        <td>{item.access_no}</td>
                        <td>{item.id}</td>
                        <td style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                            dispatch(openUserSimpleInfo({
                                isOpen: true,
                                openId: item.id,
                                xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                            }))
                        }}><span style={{ fontWeight: "bold", color: item.font_color }}>{item.nickname}</span></td>
                        <td>{item.store_cd}</td>
                        <td>{item.mrch_cd}</td>
                        <td><span style={{ color: "#5dc434" }}>{item.user_gb_cd}</span></td>
                        <td><span style={{ color: "#5dc434" }}>{item.user_state_cd}</span></td>
                        <td><span style={{ color: "#1F68EE" }}>{item.deposit_amt}</span></td>
                        <td><span style={{ color: "red" }}>{item.withdraw_amt * -1}</span></td>
                        <td><span style={{ color: "#1F68EE" }}>{item.profit_amt}</span></td>
                        <td>{item.reg_dt}</td>
                        <td>{item.login_dt}</td>
                        <td>{item.login_cnt}</td>
                        <td><a href={"https://www.ipaddress.com/ipv4/" + item.use_ip} target="_blank"><span style={{ color: "#1F68EE" }}>{item.use_ip}</span></a></td>
                        <td><span>-</span></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default OverlapList