import {createSlice} from "@reduxjs/toolkit";
import moment from "moment/moment";

export const stopMatchSlice = createSlice({
    name: 'stopMatch',
    initialState: {
        isLoading: false,
        isLoadingDetail: false,
        fixtures: [],
        originFixtures: [],
        error: null,
        isConnect: false,
        tasks: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        sport: 0,
        market: 0,
        provider: 0,
        league: 0,
        sports: [],
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        markets: [],
        providers: [],
        leagues: [],
        query: "",
        checkedInputs: [],
        betInputs: [],
        updateData: [],
        low_ratios: [],
        currentFixture: null,
        currentFixtureId: 0,
    },
    reducers: {
        getStopFixture(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.sport = action.payload.sport;
            state.provider = action.payload.provider;
            state.league = action.payload.league;
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setStopFixture(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.low_ratios = action.payload.result;
            state.totalCount = action.payload.totalCount;
            state.sports = action.payload.sports;
            state.providers = action.payload.providers;
            state.leagues = action.payload.leagues;
            const inputs = [];
            for(const r of action.payload.result) {
                inputs.push({id: r.id, bet1: r.bet1, bet2: r.bet2, bet3: r.bet3, is_edited: 0})
            }
            state.betInputs = inputs;
            state.checkedInputs = [];

        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        setBetInputs(state, action) {
            state.betInputs = action.payload;
        },
        getStopFixtureDetail(state, action) {
            state.isLoadingDetail = true;
            state.currentFixtureId = action.payload;
        },
        setCurrentFixture(state, action) {
            state.isLoadingDetail = false;
            state.currentFixture = action.payload;
        },
        failedGetStopFixture(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        failedGetStopFixtureDetail(state, action) {
            state.isLoadingDetail = false;
            state.error = action.payload;
        },
    },
});

export const {
    getStopFixture,
    setStopFixture,
    getStopFixtureDetail,
    setCurrentFixture,
    setCheckedInputs,
    setUpdateData,
    setBetInputs,
    failedGetStopFixture,
    failedGetStopFixtureDetail,
} = stopMatchSlice.actions;

export default stopMatchSlice.reducer;