import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState ={
    isLoading: false,
    error: null,
    memberUploadList: [],
}
export const statsSlice = createSlice({
    name: 'MemberUploadReducer',
    initialState,
    reducers: {
        getMemberUploadList(state, action) {
            state.isLoading = true;
        },
        setMemberUploadList(state, action) {
            state.memberUploadList = action.payload;
        },
    }
});

export const {
    setMemberUploadList,
} = statsSlice.actions;

export default statsSlice.reducer;