import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
const CouponList = ({couponList, onRefresh, deleteState}) => {

    const couponState = (no, use_yn) => {
        switch(use_yn) {
            //사용
            case "Y" :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>-</td>
                            </tr>
                        </table>
                    </div>
                )
            //미사용
            case "N" :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {deleteState(no)}}>삭제</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            default : return use_yn
        }
    }

    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead >
                        <tr>
                            <th style={{width: "5%"}}>번호</th>
                            <th style={{width: "10%"}}>아이디</th>
                            <th style={{width: "10%"}}>닉네임</th>
                            <th style={{width: "*"}}>쿠폰 내용</th>
                            <th style={{width: "10%"}}>쿠폰 가격</th>
                            <th style={{width: "10%"}}>지급일</th>
                            <th style={{width: "10%"}}>사용일</th>
                            <th style={{width: "7%"}}>사용여부</th>
                            <th style={{width: "7%"}}>삭제</th>
                        </tr>
                        </thead>
                        {!couponList.length && (
                            <tbody key={couponList.no}>
                            <tr>
                                <td colSpan="9">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {couponList?.map((couponList, index) => (
                            <tbody key={couponList.no}>
                            <tr>
                                <td align={"center"}>{couponList.no}</td>
                                <td align={"center"} style={{backgroundColor:couponList.bg_color}}><font color={couponList.font_color}>{couponList.id}</font></td>
                                <td align={"center"} style={{backgroundColor:couponList.bg_color}}><font color={couponList.font_color}>{couponList.nickname}</font></td>
                                <td align={"center"}>{couponList.pay_coupon_etc}</td>
                                <td align={"center"} style={{backgroundColor:"white"}} >
                                    <font color="blue">
                                        <NumericFormat value={couponList.pay_coupon_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"}>{couponList.pay_dt}</td>
                                <td align={"center"}>{couponList.use_dt}</td>
                                <td align={"center"}>{couponList.use_yn}</td>
                                <td align={"center"}>
                                    {couponState(couponList.no, couponList.use_yn)}
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default CouponList;