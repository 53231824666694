import {createSlice} from "@reduxjs/toolkit"

export const sameBrowserSlice = createSlice({
    name: 'sameBrowser',
    initialState: {
        isLoading: false,
        error: null,
        sameBrowsers: [],
        query: "",
        page: 1,
        pageSize: 100,
        totalCount: 0,
    },
    reducers: {
        getSameBrowsers(state, action) {
            state.isLoading = true;
            state.query = action.payload.query
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
        },
        failedGetSameBrowsers(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setSameBrowsers(state, action) {
            state.isLoading = false;
            state.sameBrowsers = action.payload.result;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalCount = action.payload.totalCount;
        },
    }
});

export const { getSameBrowsers, failedGetSameBrowsers, setSameBrowsers } = sameBrowserSlice.actions;

export default sameBrowserSlice.reducer;