import {call, put} from "redux-saga/effects";
import {failedGetPartnerSingleReducer, setPartnerSingleReducer} from "../../reducers/partner/PartnerSingleReducer";
import {fetchPartnerSingle} from "../../../lib/api";

export function* getPartnerSingleSaga(action) {
    try{
        console.log("##getPartnerSingleSaga input#",action.payload.input)
        const res = yield call(fetchPartnerSingle,
            action.payload.input,
        );
        console.log("##getPartnerSingleSaga output#",res.data)
        yield put(setPartnerSingleReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetPartnerSingleReducer(error))
    }
}