import {call, put, take,} from "redux-saga/effects";
import {fetchOverlaps} from "../../../lib/api";
import {failedGetOverlaps, setOverlaps} from "../../reducers/monitoring/OverlapReducer";

export function* getOverlapSaga(action) {
    try{
        const res = yield call(
            fetchOverlaps,
        )

        yield put(setOverlaps(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetOverlaps(error))
    }
}