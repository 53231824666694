import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {useParams} from "react-router-dom";
import {getOrgListReducer} from "../../../redux/reducers/partner/OrgListReducer";
import {insertAdminLog} from "../../../lib/api";

const SetLosing = (props) => {
    const userInfoId = useSelector((state) => state.userInfo.id)
    const {open, onClose, initialState} = props
    const param = useParams()
    const [losingList, setLosingList] = useState([]);

    const [ratioDepositWithdrawDouble, setRatioDepositWithdrawDouble] = useState([]);
    const [ratioSport, setRatioSport] = useState([]);
    const [ratioCasino, setRatioCasino] = useState([]);
    const [ratioSlot, setRatioSlot] = useState([]);
    const [ratioMini, setRatioMini] = useState([]);
    const [ratioLosingTypeCd, setRatioLosingTypeCd] = useState([]);

    const [ratioDepositWithdrawDoubleAll, setRatioDepositWithdrawDoubleAll] = useState([]);
    const [ratioSportAll, setRatioSportAll] = useState([]);
    const [ratioCasinoAll, setRatioCasinoAll] = useState([]);
    const [ratioSlotAll, setRatioSlotAll] = useState([]);
    const [ratioMiniAll, setRatioMiniAll] = useState([]);
    const [ratioLosingTypeCdAll, setRatioLosingTypeCdAll] = useState([]);


    const setRatioDepositWithdrawDoubleHandler = (e, index) => {
        ratioDepositWithdrawDouble[index] = e.target.value;
        setRatioDepositWithdrawDouble(ratioDepositWithdrawDouble);
    }
    const setRatioSportHandler = (e, index) => {
        ratioSport[index] = e.target.value;
        setRatioSport(ratioSport);
    }
    const setRatioCasinoHandler = (e, index) => {
        ratioCasino[index] = e.target.value;
        setRatioCasino(ratioCasino);
    }
    const setRatioSlotHandler = (e, index) => {
        ratioSlot[index] = e.target.value;
        setRatioSlot(ratioSlot);
    }
    const setRatioMiniHandler = (e, index) => {
        ratioMini[index] = e.target.value;
        setRatioMini(ratioSlot);
    }
    const setRatioLosingTypeCdHandler = (e, index) => {
        ratioLosingTypeCd[index] = e.target.value;
        setRatioLosingTypeCd(ratioLosingTypeCd);
    }

    const saveLosing01 = (id, index) => {
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing01', {
                id: id,
                ratioDepositWithdrawDouble: ratioDepositWithdrawDouble[index]
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }
    const saveLosing02 = (id, index) => {
        //alert("id :: "+id + " index : "+index+ " ratioSport[index] : "+ratioSport[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing02', {
                id: id,
                ratioSport: ratioSport[index]
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }
    const saveLosing03 = (id, index) => {
        //alert("id :: "+id + " index : "+index+ " ratioCasino[index] : "+ratioCasino[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing03', {
                id: id,
                ratioCasino: ratioCasino[index]
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }
    const saveLosing04 = (id, index) => {
        //alert("id :: "+id + " index : "+index+ " ratioSlot[index] : "+ratioSlot[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing04', {
                id: id,
                ratioSlot: ratioSlot[index]
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }
    const saveLosing05 = (id, index) => {
        //alert("id :: "+id + " index : "+index+ " ratioMini[index] : "+ratioMini[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing05', {
                id: id,
                ratioMini: ratioMini[index]
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }
    const saveLosing06 = (id, index) => {
        //alert("id :: "+id + " index : "+index+ " ratioLosingTypeCd[index] : "+ratioLosingTypeCd[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing06', {
                id: id,
                ratioLosingTypeCd: ratioLosingTypeCd[index]
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }

    const setRatioDepositWithdrawDoubleHandlerAll = (e) => {
        setRatioDepositWithdrawDoubleAll(e.target.value);
    }
    const setRatioSportHandlerAll = (e) => {
        setRatioSportAll(e.target.value);
    }
    const setRatioCasinoHandlerAll = (e) => {
        setRatioCasinoAll(e.target.value);
    }
    const setRatioSlotHandlerAll = (e) => {
        setRatioSlotAll(e.target.value);
    }
    const setRatioMiniHandlerAll = (e) => {
        setRatioMiniAll(e.target.value);
    }
    const setRatioLosingTypeCdHandlerAll = (e) => {
        setRatioLosingTypeCdAll(e.target.value);
    }


    const saveLosing01All = (id) => {
        //alert("id :: "+id + " index : "+index+ " ratioDepositWithdrawDouble[index] : "+ratioDepositWithdrawDouble[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing01All', {
                id: id,
                ratioDepositWithdrawDouble: ratioDepositWithdrawDoubleAll
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }
    const saveLosing02All = (id) => {
        //alert("id :: "+id + " index : "+index+ " ratioSport[index] : "+ratioSport[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing02All', {
                id: id,
                ratioSport: ratioSportAll
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }
    const saveLosing03All = (id) => {
        //alert("id :: "+id + " index : "+index+ " ratioCasino[index] : "+ratioCasino[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing03All', {
                id: id,
                ratioCasino: ratioCasinoAll
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }
    const saveLosing04All = (id) => {
        //alert("id :: "+id + " index : "+index+ " ratioSlot[index] : "+ratioSlot[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing04All', {
                id: id,
                ratioSlot: ratioSlotAll
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }
    const saveLosing05All = (id) => {
        //alert("id :: "+id + " index : "+index+ " ratioMini[index] : "+ratioMini[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing05All', {
                id: id,
                ratioMini: ratioMiniAll
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }
    }
    const saveLosing06All = (id) => {
        //alert("id :: "+id + " index : "+index+ " ratioLosingTypeCd[index] : "+ratioLosingTypeCd[index]);
        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/saveLosing06All', {
                id: id,
                ratioLosingTypeCd: ratioLosingTypeCdAll
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패")
                popClose()
            })
        }
    }


    const getCmmnCd = () => {
        //alert("param.userId :: "+param.userId);
        if(losingList.length === 0){
            axios.post('/admin/partner/getLosing01', {
                id : param.userId,
            }).then((res) => {
                //alert("res.data.cmmnCdList : "+JSON.stringify(res.data.cmmnCdList));
                setLosingList(res.data.cmmnCdList);
            })
        }
        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "루징설정팝업"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getCmmnCd, [])

    const popClose =() => {
        if (window.opener && typeof window.opener.popupClose === 'function') {
            window.opener.popupClose();
        }
        window.close();
    }
    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <button style={{ backgroundColor: "blue",
                    color: "white", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                >루징설정</button>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/setRolling/"+param.userId;
                        }}
                >롤링설정</button>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="table table-striped mt-2 scroll-horizontal">
                    <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                        <thead>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"25%"}>지점</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"12%"}>입-출-루징(%)</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"12%"}>스포츠(베-당) 루징(%)</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"12%"}>카지노(베-당) 루징(%)</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"12%"}>슬롯(베-당) 루징(%)</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"12%"}>미니게임(베-당) 루징(%)</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>루징방식</td>
                        </tr>
                        </thead>
                        {!losingList.length && (
                            <tbody key={losingList.id}>
                            <tr>
                                <td colSpan="7">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        <tbody>
                        <tr>
                            <td style={{backgroundColor: "lightseagreen"}} align={"left"}></td>
                            <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "50px"}}
                                       onChange={(e) => {setRatioDepositWithdrawDoubleHandlerAll(e)}} />&nbsp;
                                <button style={{
                                    backgroundColor: "lightgray",
                                    border: "none",
                                    color: "black",
                                    fontSize: "9px",
                                    height: "20px",
                                    width: "fit-content",
                                    marginRight: "5px"
                                }}
                                        onClick={() => {saveLosing01All(losingList)}}
                                >일괄변경
                                </button>
                            </td>
                            <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "50px"}}
                                       onChange={(e) => {setRatioSportHandlerAll(e)}} />&nbsp;
                                <button style={{
                                    backgroundColor: "lightgray",
                                    border: "none",
                                    color: "black",
                                    fontSize: "9px",
                                    height: "20px",
                                    width: "fit-content",
                                    marginRight: "5px"
                                }}
                                        onClick={() => {saveLosing02All(losingList)}}
                                >일괄변경
                                </button>
                            </td>
                            <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "50px"}}
                                       onChange={(e) => {setRatioCasinoHandlerAll(e)}} />&nbsp;
                                <button style={{
                                    backgroundColor: "lightgray",
                                    border: "none",
                                    color: "black",
                                    fontSize: "9px",
                                    height: "20px",
                                    width: "fit-content",
                                    marginRight: "5px"
                                }}
                                        onClick={() => {saveLosing03All(losingList)}}
                                >일괄변경
                                </button>
                            </td>
                            <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "50px"}}
                                       onChange={(e) => {setRatioSlotHandlerAll(e)}} />&nbsp;
                                <button style={{
                                    backgroundColor: "lightgray",
                                    border: "none",
                                    color: "black",
                                    fontSize: "9px",
                                    height: "20px",
                                    width: "fit-content",
                                    marginRight: "5px"
                                }}
                                        onClick={() => {saveLosing04All(losingList)}}
                                >일괄변경
                                </button>
                            </td>
                            <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "50px"}}
                                       onChange={(e) => {setRatioMiniHandlerAll(e)}} />&nbsp;
                                <button style={{
                                    backgroundColor: "lightgray",
                                    border: "none",
                                    color: "black",
                                    fontSize: "9px",
                                    height: "20px",
                                    width: "fit-content",
                                    marginRight: "5px"
                                }}
                                        onClick={() => {saveLosing05All(losingList)}}
                                >일괄변경
                                </button>
                            </td>
                            <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                <div className="sm-center-div">
                                    <select
                                        className="form-select sm-select"
                                        defaultValue={'01'}
                                        style={{
                                            width: "100px"
                                        }}
                                        onChange={(e) => setRatioLosingTypeCdHandlerAll(e)}
                                        value={ratioLosingTypeCdAll}
                                    >
                                        <option value="01">(입-출)*요율%</option>
                                        <option value="02">(배-당)*요율%</option>
                                    </select>
                                    <button style={{
                                        backgroundColor: "lightgray",
                                        border: "none",
                                        color: "black",
                                        fontSize: "9px",
                                        height: "20px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {saveLosing06All(losingList)}}
                                    >일괄변경
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                        {losingList?.map((losingList, index) => (
                            <tbody key={losingList.id}>
                            <tr>
                                <td style={{backgroundColor: "white"}} align={"left"}>
                                    {losingList.lev2 ===  '하위조직'? ' ㄴ' : ''}{losingList.lev1}
                                    ({losingList.id})
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "50px"}} placeholder={losingList.ratio_deposit_withdraw}
                                           onChange={(e) => {setRatioDepositWithdrawDoubleHandler(e, index)}} />&nbsp;
                                    <button style={{
                                        backgroundColor: "lightgray",
                                        border: "none",
                                        color: "black",
                                        fontSize: "9px",
                                        height: "20px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {saveLosing01(losingList.id, index)}}
                                    >변경
                                    </button>
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "50px"}} placeholder={losingList.ratio_sport}
                                           onChange={(e) => {setRatioSportHandler(e, index)}} />&nbsp;
                                    <button style={{
                                        backgroundColor: "lightgray",
                                        border: "none",
                                        color: "black",
                                        fontSize: "9px",
                                        height: "20px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {saveLosing02(losingList.id, index)}}
                                    >변경
                                    </button>
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "50px"}} placeholder={losingList.ratio_casino}
                                           onChange={(e) => {setRatioCasinoHandler(e, index)}} />&nbsp;
                                    <button style={{
                                        backgroundColor: "lightgray",
                                        border: "none",
                                        color: "black",
                                        fontSize: "9px",
                                        height: "20px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {saveLosing03(losingList.id, index)}}
                                    >변경
                                    </button>
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "50px"}} placeholder={losingList.ratio_slot}
                                           onChange={(e) => {setRatioSlotHandler(e, index)}} />&nbsp;
                                    <button style={{
                                        backgroundColor: "lightgray",
                                        border: "none",
                                        color: "black",
                                        fontSize: "9px",
                                        height: "20px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {saveLosing04(losingList.id, index)}}
                                    >변경
                                    </button>
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "50px"}} placeholder={losingList.ratio_mini}
                                           onChange={(e) => {setRatioMiniHandler(e, index)}} />&nbsp;
                                    <button style={{
                                        backgroundColor: "lightgray",
                                        border: "none",
                                        color: "black",
                                        fontSize: "9px",
                                        height: "20px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {saveLosing05(losingList.id, index)}}
                                    >변경
                                    </button>
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <div className="sm-center-div">
                                        <select
                                            className="form-select sm-select"
                                            defaultValue={'01'}
                                            style={{
                                                width: "100px"
                                            }}
                                            onChange={(e) => setRatioLosingTypeCdHandler(e,index)}
                                            value={ratioLosingTypeCd[index] ? ratioLosingTypeCd[index] : losingList.losing_type_cd}
                                        >
                                            <option key="01" value="01">(입-출)*요율%</option>
                                            <option key="02" value="02">(배-당)*요율%</option>
                                        </select>
                                        <button style={{
                                            backgroundColor: "lightgray",
                                            border: "none",
                                            color: "black",
                                            fontSize: "9px",
                                            height: "20px",
                                            width: "fit-content",
                                            marginRight: "5px"
                                        }}
                                                onClick={() => {saveLosing06(losingList.id, index)}}
                                        >변경
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>

    )
}
export default SetLosing;