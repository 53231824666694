import {call, put, take,} from "redux-saga/effects";
import {
    failedGetInplayFixture, failedGetInplayFixtureDetail,
    getInplayFixtureDetail,
    setInplayFixture, setCurrentFixture
} from "../../../reducers/game/manage/inplayMatch";
import {fetchInplayFixture, fetchInplayFixtureDetail} from "../../../../lib/api";

export function* getInplayFixtureSaga(action) {
    try {
        const res = yield call(fetchInplayFixture,
            action.payload
        );
        yield put(setInplayFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getInplayFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetInplayFixture(error));
    }
}

export function* getInplayFixtureDetailSaga(action) {
    try {
        const res = yield call(fetchInplayFixtureDetail, action.payload)
        yield put(setCurrentFixture(res.data))
    } catch(error) {
        console.log(error)
        yield put(failedGetInplayFixtureDetail(error))
    }
}

