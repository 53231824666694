import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {useParams} from "react-router-dom";
import {setPaymentWithinAdjAmt,setOrgAdjMenualGbCd,getAdjustment,setOrgAdjTypeCd,setOrgAutoAdjDetailSettings,setOrgAdjExeCyclCd,setAdjustmentResvDt,setAdjustmentResvHh} from "../../../../redux/reducers/partner/AdjustmentReducer";
import {NumericFormat} from "react-number-format";
import {insertAdminLog} from "../../../../lib/api";
import TimeSelectBox from "../../../common/TimeSelectBox";

const Adjustment = (props) => {

    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.adjustment)
    const param = useParams()
    const userInfoId = useSelector((state) => state.userInfo.id);
    const userInfoIp = useSelector((state) => state.userInfo.ip)
    const end_date = moment().subtract(1, 'day').format("YYYY-MM-DD")+" 23:59:59";

    useEffect(() => {
        dispatch(getAdjustment({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            userId: param.userId
        }))

    }, [dispatch])

    const handleApplcAdjAutoSetting = () =>{
        if(initialState.detail.org_adj_menual_gb_cd !== '01'){
            alert('자동/수동구분이 자동으로 되어있지 않으므로 적용불가능합니다.');
            return false;
        }

        if(window.confirm("자동설정 적용 하시겠습니까?"))
        {
            axios.post('/admin/partner/applcAdjAutoSetting', {
                    org_admin_id: param.userId,
                    org_adj_type_cd: initialState.detail.org_adj_type_cd?initialState.detail.org_adj_type_cd:initialState.cmCd0043[0].cmmn_cd_dtl_id,
                    org_adj_exe_cycl_cd: initialState.detail.org_adj_exe_cycl_cd?initialState.detail.org_adj_exe_cycl_cd:initialState.cmCd0045[0].cmmn_cd_dtl_id,
                    adjustment_resv_dttm : initialState.detail.adjustment_resv_dt+ " " + initialState.detail.adjustment_resv_hh + ":00:00",
                    payment_within_adj_amt: initialState.detail.payment_within_adj_amt,
                    reg_id : userInfoId,
                    upd_id: userInfoId,
                    org_auto_adj_detail_settings : initialState.detail.org_auto_adj_detail_settings
            })
            .then((res) => {
                alert(res.data.message)
                window.location.reload()
            })
        }
    }
    const handleRegister = () => {
        if(initialState.detail.org_adj_menual_gb_cd !== '02'){
            alert('자동/수동구분이 수동으로 되어있지 않으므로 즉시정산 불가능합니다.');
            return false;
        }

        if(window.confirm("정산 하시겠습니까?"))
        {
            axios.post('/admin/partner/exeManualAdj', {
                org_admin_id: param.userId,
                org_adj_type_cd: initialState.detail.org_adj_type_cd?initialState.detail.org_adj_type_cd:initialState.cmCd0043[0].cmmn_cd_dtl_id,
                payment_within_adj_amt: initialState.detail.payment_within_adj_amt,
                reg_id : userInfoId,
                reg_ip : userInfoIp,
                upd_id: userInfoId,
                org_auto_adj_detail_settings : initialState.detail.org_auto_adj_detail_settings
            })
            .then((res) => {
                alert(res.data.message)
                window.location.reload()
            })
        }
    }

    const changeOrgAdjMenualGbCd = (e) => {
        dispatch(setOrgAdjMenualGbCd(e.target.value))//조직정산수동구분코드
        if(e.target.value === '01'){
            dispatch(setAdjustmentResvDt(moment().format('YYYY-MM-DD')))//조직자동정산시작일자초기화
            dispatch(setAdjustmentResvHh(moment().format('HH')))//조직자동정산시작시간초기화
            dispatch(setOrgAdjExeCyclCd(initialState.cmCd0045[0].cmmn_cd_dtl_id))//조직자동정산실행주기코드초기화
        }else{
            dispatch(setAdjustmentResvDt(""))//조직자동정산시작일자초기화
            dispatch(setAdjustmentResvHh(""))//조직자동정산시작시간초기화
            dispatch(setOrgAdjExeCyclCd(""))//조직자동정산실행주기코드초기화
        }
    }

    const changeOrgAdjExeCyclCd = (e) => {
        dispatch(setOrgAdjExeCyclCd(e.target.value))
    }

    const changeAdjustmentResvDt = (e) => {
        dispatch(setAdjustmentResvDt(e.currentTarget.value))
    }

    const changeAdjustmentResvHh = (e) => {
        dispatch(setAdjustmentResvHh(e.target.value))
    }

    const changePaymentWithinAdjAmt = (e) => {
        dispatch(setPaymentWithinAdjAmt(e.target.value))
    }

    const changeOrgAdjTypeCd = (e) =>{
        dispatch(setOrgAdjTypeCd(e.target.value))
    }

    const changeOrgAutoAdjDetailSettings = (e) =>{
        dispatch(setOrgAutoAdjDetailSettings(e))
    }

    return (
        <div className="common" style={{width : "99%"}}>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <button style={{ backgroundColor: "white",
                    color: "black", border: "none", fontSize: "12px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                >정산</button>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="" style={{
                    display: "flex"
                }}>
                    <table>
                        <tr>
                            <td align={"left"} colspan="2" bgcolor={"lightpink"}>
                                <font color="Red">
                                &nbsp;* 정산은 어제밤 0시 기준까지 정산이 됩니다.
                                <br></br>
                                &nbsp;* 금일데이터는 해당 정산에 포함되지 않습니다.
                                <br></br>
                                <br></br>
                                    <b>
                                루징정산은 마이너스 금액이 산생될수 있으므로 정산전에 반드시 재확인하신 후에 보유머니에 합산할지, 0으로 초기화할지 결정해주시기 바랍니다.
                                <br></br>
                                [주별] 자동정산 설정인 경우 시작날짜의 요일에 맞추어 자동처리가 실행됩니다.
                                <br></br>
                                [15일] 자동정산 설정인 경우 시작날짜의 시간만 사용하고 날짜는 매월1일과 16일로 정하여 자동실행합니다.
                                <br></br>
                                [월별] 자동정산 설정인 경우 시작날짜부터 매워 해당 일에 자동처리가 실행됩니다.
                                    </b>
                                </font>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>조직정산구분</td>
                            <td align={"center"} width={"550px"}>
                                <span>{initialState.detail.org_adj_gb_cd_nm}</span>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>루징유형</td>
                            <td align={"center"} width={"550px"}>
                                <span>{initialState.detail.losing_type_cd_nm}</span>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>루징금</td>
                            <td align={"center"} width={"550px"}>
                                <NumericFormat className="ms-1" value={initialState.detail.losing_amt} displayType={'text'} thousandSeparator={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>롤링금</td>
                            <td align={"center"} width={"550px"}>
                                <span>{initialState.detail.rolling_amt}</span>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>정산할 금액</td>
                            <td align={"center"} width={"550px"}>
                                <NumericFormat className="ms-1" value={initialState.detail.adj_amt} displayType={'text'} thousandSeparator={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>(정산금 내)관리자 지급/회수</td>
                            <td align={"center"} width={"550px"}>
                                <input onChange={changePaymentWithinAdjAmt} value={initialState.detail.payment_within_adj_amt} type="text" className="form-check-input sm-input" type="text" style={{ width: '540px' }}></input>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>정산후 보유금액</td>
                            <td align={"center"} width={"550px"}>
                                <NumericFormat className="ms-1" value={parseInt(initialState.detail.money_amt) + parseInt(initialState.detail.adj_amt) + parseInt(initialState.detail.payment_within_adj_amt)} displayType={'text'} thousandSeparator={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>정산날짜</td>
                            <td align={"center"} width={"550px"}>
                                {initialState.detail.adjustment_dttm} ~ {end_date}
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>자동/수동 구분</td>
                            <td align={"left"} width={"550px"}>
                                <select
                                    className="form-select sm-select"
                                    defaultValue={'01'}
                                    style={{
                                        width: "100px"
                                    }}
                                    onChange={changeOrgAdjMenualGbCd} value={initialState.detail.org_adj_menual_gb_cd}
                                >
                                    {initialState.cmCd0042?.map((item, index) => (
                                        <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>자동정산 주기</td>
                            <td align={"center"} width={"550px"}>
                                <table>
                                    <tr>
                                        <td align={"left"} width={"150px"}>
                                            <select
                                                className="form-select sm-select"
                                                defaultValue={'01'}
                                                style={{
                                                    width: "100px"
                                                }}
                                                onChange={changeOrgAdjExeCyclCd} value={initialState.detail.org_adj_exe_cycl_cd}
                                            >
                                                {initialState.cmCd0045?.map((item, index) => (
                                                    <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td align={"center"} width={"100px"}>
                                            자동정산 시작일자
                                        </td>
                                        <td align={"left"} width={"100px"}>
                                            <input onChange={changeAdjustmentResvDt} className="form-control sm-date" type="date" value={initialState.detail.adjustment_resv_dt} style={{width:"110px"}}/>
                                        </td>
                                        <td align={"center"} width={"100px"}>
                                            자동정산 시작시간
                                        </td>
                                        <td align={"left"} width={"100px"}>
                                            <TimeSelectBox change = {changeAdjustmentResvHh} hh = {initialState.detail.adjustment_resv_hh}/>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"left"} width={"150px"}>정산 방식</td>
                            <td align={"center"} width={"550px"}>
                                <table>
                                    <tr>
                                        <td align={"left"} width={"275px"}>
                                            {initialState && initialState.cmCd0043.map(item => (
                                                <div key={item.cmmn_cd_dtl_id} style={{ marginBottom: '8px' }}> {/* 블록 요소로 감싸기 */}
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        name="orgAdjTypeCd"  // 동일한 name으로 설정
                                                        id={`flexcmmn0043${item.cmmn_cd_dtl_id}`}
                                                        value={item.cmmn_cd_dtl_id}
                                                        style={{ cursor: "pointer" }}
                                                        onChange={(e) => changeOrgAdjTypeCd(e)}
                                                        checked={initialState.detail.org_adj_type_cd === item.cmmn_cd_dtl_id}
                                                    />
                                                    <label htmlFor={`flexcmmn0043${item.cmmn_cd_dtl_id}`}>
                                                        {item.cmmn_cd_dtl_nm}
                                                    </label>
                                                </div>
                                            ))}
                                        </td>
                                        <td align={"left"} width={"275px"}>
                                            {initialState && initialState.cmCd0044.map(item => (
                                                <div key={item.cmmn_cd_dtl_id} style={{ marginBottom: '8px' }}> {/* 블록 요소로 감싸기 */}
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="aaaa"  // 동일한 name으로 설정
                                                        id={`flexcmmn0044${item.cmmn_cd_dtl_id}`}
                                                        value={item.cmmn_cd_dtl_id}
                                                        onChange={(e)=>changeOrgAutoAdjDetailSettings(e)}
                                                        checked={initialState.detail.org_auto_adj_detail_settings.some(obj => obj.org_adj_type_detail_cd === item.cmmn_cd_dtl_id)}
                                                    />
                                                    <label htmlFor={`flexcmmn0044${item.cmmn_cd_dtl_id}`}>
                                                        {item.cmmn_cd_dtl_nm}
                                                    </label>
                                                </div>
                                            ))}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"center"} colspan="2">
                                <button onClick= {handleApplcAdjAutoSetting} style={{ backgroundColor: "lightgray", border: "none", color: "black" ,fontSize: "11px", height: "23px", width: "fit-content", marginRight: "5px"}}
                                >자동설정 적용</button>
                                &nbsp;
                                <button style={{ backgroundColor: "blue", border: "none", color: "white" ,fontSize: "11px", height: "23px", width: "fit-content", marginRight: "5px"}}
                                        onClick={handleRegister}
                                >정산</button>
                                &nbsp;
                                <button onClick={() => window.close()} style={{ backgroundColor: "lightgray", border: "none", color: "black" ,fontSize: "11px", height: "23px", width: "fit-content", marginRight: "5px"}}
                                >취소</button>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>


    )
}
export default Adjustment;