import {call, put} from "redux-saga/effects";
import {failedGeBettingSetting, setBettingSetting} from "../../reducers/setting/BettingSingleReducer";
import {fetchBettingSingle} from "../../../lib/api";

export function* getBettingSettingSingleSaga(action) {
    try{
        console.log("##getBettingSettingSingleSaga input#",action.payload.input)
        const res = yield call(fetchBettingSingle,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd
        );
        console.log("##getBettingSettingSingleSaga output#",res.data)
        yield put(setBettingSetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGeBettingSetting(error))
    }
}