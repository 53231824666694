import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setIsOpenColor,
    getMemberInformationSimpleModalReducer, setPayGameMoney_memo, setPayGameMoney_gameMoneyAmt, setPayPoint_pointTypeCd,setPayPoint_point, setPayPoint_memo, setRegForceDeposite_depositAmt, setRegForceWithdraw_withdrawAmt, setPayKgCasinoMoney_kgCasinoMoneyAmt,setPayKgCasinoMoney_kgCasinoMoneyMemo, setPayLottery_lotteryTypeCd,setPayLottery_lotteryName, setPayLottery_sheets, setPayCoupon_payCouponAmt,setPayCoupon_payCouponEtc, setPayCoupon_sheet,
} from "../../../../redux/reducers/member/MemberInformationSimpleModalReducer";
import axios from "axios";
import PlusMinusText from "../../../common/PlusMinusText";
import {NumericFormat} from "react-number-format";
import InterestMemberColorSelect from "./InterestMemberColorSelect";
import moment from "moment";
import OrgSelect2 from "../../../org/OrgSelect";
import {closeUserSimpleInfo, setIsOpen} from "../../../../redux/reducers/userInfo";
const MemberInformationSimpleModal = ({isOpen, userId, xPos}) => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.MemberInformationSimpleModalReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const userInfoIp = useSelector((state) => state.userInfo.ip)
    const [user, setUser] = useState()
    const [marginList, setMarginList] = useState([])
    const [userGbList2, setUserGbList2] = useState([]); //태그DB맵핑
    const [gameType, setGameType] = useState("01");

    const getSimpleData = (searchStartDate) => {
        if(initialState.user !== null ) {
            dispatch(getMemberInformationSimpleModalReducer({
                input:{
                    userId: userId,
                    searchStartDate: searchStartDate? searchStartDate : ""
                }
            }))
        }

        if(userGbList2.length === 0){
            axios.post('/admin/member/getTagSetting', {
                cmmnCdId : '0000',
            }).then((res) => {
                setUserGbList2(res.data.cmmnCdList);
            })
        }
    }

    useEffect(() => {
        getSimpleData()
    }, [])

    //[회원정보심플모달]관심회원등록
    const openInterestMemberModal =()=>{
        dispatch(setIsOpenColor(true))
    }
    //[회원정보심플모달]관심회원해제
    const unlockInterestedMember =()=>{
        axios.post('/admin/member/simple/memberInfoSimpleModal/unlockInterestedMember', {
            userId : userId ,
        }).then((res) => {
            alert(res.data.message);
        })
    }
    //[회원정보심플모달]kgon머니조회
    //[회원정보심플모달]kgon Kick(회원튕김)
    //[회원정보심플모달]kgon머니 강제출금(게임머니로)

    const widthdrawForcekgonMoney = () =>{
        axios.post('/admin/member/simple/memberInfoSimpleModal/widthdrawForcekgonMoney', {
            userId : userId ,
            regId : userInfoId,
            regIp : userInfoIp,
        }).then((res) => {
            alert(res.data.message);
        })
    }

    //[회원정보심플모달]게임머니지급
    const payGameMoney = () =>{

        let price = initialState.payGameMoney_gameMoneyAmt;
        // if( price != null && parseInt(price) > 0 )
        // {
        //     price = Number(initialState.payGameMoney_gameMoneyAmt.replaceAll(',', ''));
        // }
        // else
        // {
        //     price = 0;
        // }


        axios.post('/admin/member/simple/memberInfoSimpleModal/payGameMoney', {
            gameMoneyAmt : price,
            memo :  initialState.payGameMoney_memo ,
            userId : userId ,
            regId : userInfoId,
            regIp : userInfoIp,
        }).then((res) => {
            alert(res.data.message);
            //window.location.reload();
            dispatch(setPayGameMoney_gameMoneyAmt(0));
            dispatch(setPayGameMoney_memo(""));
            getSimpleData()
        })
    }

    //[회원정보심플모달]포인트지급
    const payPoint = () =>{

        let price = initialState.payPoint_point;
        // if( price != null && parseInt(price) > 0 )
        // {
        //     price = Number(initialState.payPoint_point.replaceAll(',', ''));
        // }
        // else
        // {
        //     price = 0;
        // }

        axios.post('/admin/member/simple/memberInfoSimpleModal/payPoint', {
            pointTypeCd : initialState.payPoint_pointTypeCd,
            point : price,
            memo :  initialState.payPoint_memo ,
            userId : userId ,
            regId : userInfoId,
            regIp : userInfoIp,

        }).then((res) => {
            alert(res.data.message);
            //window.location.reload();
            dispatch(setPayPoint_point(0));
            dispatch(setPayPoint_memo(""));
            getSimpleData();
        })
    }

    //[회원정보심플모달]충전강제신청
    const regForceDeposite = () =>{


        axios.post('/admin/member/simple/memberInfoSimpleModal/regForceDepositeDuplCheck', {
            userId : userId ,
            useIp : userInfoIp
        }).then((res) => {
            if( res.data.isChecked )
            {
                alert("이미 충전 신청중인 건이 있습니다. 충전 완료시까지 기다려주세요.");
                window.location.reload();
                return;
            }
            else
            {
                axios.post('/admin/member/simple/memberInfoSimpleModal/regForceDeposite', {
                    depositAmt : initialState.regForceDeposite_depositAmt,
                    gameType: gameType,
                    userId : userId ,
                    useIp : userInfoIp
                }).then((res) => {
                    alert(res.data.message);
                    window.location.reload();
                })
            }

        })



    }

    //[회원정보심플모달]환전강제신청
    const regForceWithdraw = () =>{

        axios.post('/admin/member/simple/memberInfoSimpleModal/regForceWithdrawDuplCheck', {
            userId : userId ,
            useIp : userInfoIp
        }).then((res) => {
            if( res.data.isChecked )
            {
                alert("이미 환전 신청중인 건이 있습니다. 환전 완료시까지 기다려주세요.");
                window.location.reload();
                return;
            }
            else
            {
                axios.post('/admin/member/simple/memberInfoSimpleModal/regForceWithdraw', {
                    userId : userId,
                    withdrawAmt : initialState.regForceWithdraw_withdrawAmt,
                    useIp : userInfoIp,
                }).then((res) => {
                    alert(res.data.message);
                    window.location.reload();
                })
            }

        })

    }

    //[회원정보심플모달]kg카지노머니지급
    const payKgCasinoMoney = () =>{
        axios.post('/admin/member/simple/memberInfoSimpleModal/payKgCasinoMoney', {
            userId : userId,
            kgCasinoMoneyAmt : initialState.payKgCasinoMoney_kgCasinoMoneyAmt,
            kgCasinoMoneyMemo : initialState.payKgCasinoMoney_kgCasinoMoneyMemo,
            regId : userInfoId ,
            regIp : userInfoIp ,
        }).then((res) => {
            alert(res.data.message)
            window.location.reload();
        })
    }

    //[회원정보심플모달]복권지급
    const payLottery = () =>{

        if( initialState.payLottery_sheets < 1 )
        {
            alert("지급 장수가 0건 입니다.");
            return;
        }

        axios.post('/admin/member/simple/memberInfoSimpleModal/payLottery', {
            userId : userId,
            lotteryTypeCd : initialState.payLottery_lotteryTypeCd,
            lotteryName : initialState.payLottery_lotteryName,
            payLotterySheets : initialState.payLottery_sheets,
            regId : userInfoId,
            regIp : userInfoIp
        }).then((res) => {
            alert(res.data.message);
            window.location.reload();
        })
    }

    //[회원정보심플모달]쿠폰지급
    const payCoupon = () =>{

        if( initialState.payCoupon_sheet < 1 )
        {
            alert("지급 장수가 0건 입니다.");
            return;
        }

        axios.post('/admin/member/simple/memberInfoSimpleModal/payCoupon', {
            userId : userId,
            payCouponAmt : initialState.payCoupon_payCouponAmt,
            payCouponEtc : initialState.payCoupon_payCouponEtc,
            payCouponSheet : initialState.payCoupon_sheet,
        }).then((res) => {
            alert(res.data.message);
            window.location.reload();
        })
    }
    //[회원정보심플모달]접속종료
    //[회원정보심플모달]접속차단

    //[회원정보심플모달]게임머니회수
    const recoverGameMoney = () =>{
        axios.post('/admin/member/simple/memberInfoSimpleModal/recoverGameMoney', {
            userId : userId,
            regId : userInfoId,
            regIp : userInfoIp
        }).then((res) => {
            alert(res.data.message);
        })
    }

    //[회원정보심플모달]포인트회수
    const recoverPoint = () =>{
        axios.post('/admin/member/simple/memberInfoSimpleModal/recoverPoint', {
            userId : userId,
            regId : userInfoId,
            regIp : userInfoIp
        }).then((res) => {
            alert(res.data.message);
        })
    }

    const resetInit = () =>{
        window.location.reload();
    }

    //[회원정보심플모달]머니/포인트 내역보정
    //[회원정보심플모달]접속종료
    const connectClose = () =>{
        if(window.confirm("접속을 강제로 끊겠습니까?")){
            axios.post('/admin/member/simple/memberInfoSimpleModal/connectClose', {
                id : userId,
            }).then((res) => {
                alert(res.data.message);
            })
        }
    }
    //[회원정보심플모달]접속차단
    const connectBlock = () =>{
        if(window.confirm("접속을 강제로 끊고 차단하겠습니까?")){
            axios.post('/admin/member/simple/memberInfoSimpleModal/connectBlock', {
                id : userId,
            }).then((res) => {
                alert(res.data.message);
            })
        }
    }


    const onChangePayGameMoney_memo = (e) =>{
        dispatch(setPayGameMoney_memo(e.currentTarget.value))
    }
    const onChangePayGameMoney_gameMoneyAmt = (e) =>{
        dispatch(setPayGameMoney_gameMoneyAmt(e.currentTarget.value))
        // let price = e.target.value;
        // price = Number(price.replaceAll(',', ''));
        // if(isNaN(price)) {
        //     //dispatch(setPayGameMoney_gameMoneyAmt(0))
        //     dispatch(setPayGameMoney_gameMoneyAmt(price.toLocaleString('ko-KR')))
        // } else {
        //     dispatch(setPayGameMoney_gameMoneyAmt(price.toLocaleString('ko-KR')))
        // }

    }

    const onChangePayPoint_pointTypeCd = (e) =>{
        dispatch(setPayPoint_pointTypeCd(e.currentTarget.value))
    }

    const onChangePayPoint_point = (e) =>{
        dispatch(setPayPoint_point(e.currentTarget.value))
        // let price = e.target.value;
        // price = Number(price.replaceAll(',', ''));
        // if(isNaN(price)) {
        //     dispatch(setPayPoint_point(0))
        // } else {
        //     dispatch(setPayPoint_point(price.toLocaleString('ko-KR')))
        // }
    }
    const onChangePayPoint_memo = (e) =>{
        dispatch(setPayPoint_memo(e.currentTarget.value))
    }
    const onChangeRegForceDeposite_depositAmt = (e) =>{
        dispatch(setRegForceDeposite_depositAmt(e.currentTarget.value))
    }
    const onChangeRegForceWithdraw_withdrawAmt = (e) =>{
        dispatch(setRegForceWithdraw_withdrawAmt(e.currentTarget.value))
    }
    const onChangePayKgCasinoMoney_kgCasinoMoneyAmt = (e) =>{
        dispatch(setPayKgCasinoMoney_kgCasinoMoneyAmt(e.currentTarget.value))
    }


    const onChangePayKgCasinoMoney_kgCasinoMoneyMemo = (e) =>{
        dispatch(setPayKgCasinoMoney_kgCasinoMoneyMemo(e.currentTarget.value))
    }
    const onChangePayLottery_lotteryTypeCd = (e) =>{
        dispatch(setPayLottery_lotteryTypeCd(e.currentTarget.value))
    }
    const onChangePayLottery_lotteryName = (e) =>{
        dispatch(setPayLottery_lotteryName(e.currentTarget.value))
    }
    const onChangePayLottery_sheets = (e) =>{
        dispatch(setPayLottery_sheets(e.currentTarget.value))
    }
    const onChangePayCoupon_payCouponAmt = (e) =>{
        dispatch(setPayCoupon_payCouponAmt(e.currentTarget.value))
    }
    const onChangePayCoupon_payCouponEtc = (e) =>{
        dispatch(setPayCoupon_payCouponEtc(e.currentTarget.value))
    }
    const onChangePayCoupon_sheet = (e) =>{
        dispatch(setPayCoupon_sheet(e.currentTarget.value))
    }

    const closeColor = ()=>{
        dispatch(setIsOpenColor(false))
        getSimpleData();
    }

    const gameTypeChangeHandler = (e) => {
        setGameType(e.currentTarget.value);
    }

    const content = isOpen && (
        <Fragment>
            <div className="user_simple_modal_background" onClick={(e) => {
                dispatch(closeUserSimpleInfo())
            }}>
            </div>
            <div className="user_simple_modal_box" style={{
                left: xPos
            }}>
                <div className="user_simple_modal_body">

                    <div className="container-fluid p-0 m-0 border" style={{overflowY: "scroll", height: "1080px"}}>

                        <div className="p-1" style={{backgroundColor: "#0a5c98"}}>
                            <div className="d-flex">
                                <div className="d-flex w-50">
                                    <span style={{color: "greenyellow"}}>{initialState.user?.nickname}</span>
                                </div>
                                <div className="text-end w-50">
                                    <button onClick={unlockInterestedMember} className="btn-secondary">관심회원 해제</button>
                                    <button style={{ backgroundColor: initialState.user?.backGroundColor, color: initialState.user?.fontColor }} onClick={openInterestMemberModal} className="btn-secondary">관심회원 등록</button>
                                </div>
                            </div>
                            <div><span style={{color: "white"}}>{`아이디 : ${initialState.user?.id} / 이름 : ${initialState.user?.name}`}</span></div>
                            <div><span style={{color: "greenyellow"}}>추천인 : {initialState.user?.up_rcmr_id? initialState.user?.up_rcmr_id : "()"}</span></div>
                            <div className="d-flex my-1">
                                <button onClick={(e)=> {
                                    /*openModal("Member")*/
                                    window.open(`/monitoring/overlap`,"_blank", "popup")
                                }} className="btn btn-success w-50 me-1">동일 아이피 확인</button>
                                <button onClick={(e)=> {
                                    // window.open(`/partner/recommend_list/${userId}`,"_blank", "popup")
                                    window.open(`/partner/recommendAddComponent`,"_blank", "popup")
                                }} className="btn btn-success w-50 ms-1">추천인 키 만들기</button>
                            </div>
                            <div className="d-flex mt-2">
                                <button onClick={(e) => {
                                    window.open(`/user/add_black/${userId}`,"_blank", "width=800,height=750,left=20,top=200")
                                }} className="btn btn-dark w-50 me-1">블랙 등록하기</button>
                                <button onClick={(e)=> {
                                    window.open(`/monitoring/same/${userId}`,"_blank", "popup")
                                }}  className="btn btn-dark w-50 ms-1">동일PC사용 확인</button>
                            </div>
                        </div>

                        <div style={{backgroundColor: "#CDEDEE"}}>
                            <div className="d-flex p-1 border-bottom border-secondary" style={{backgroundColor: "black"}}>
                                <div style={{zIndex : 998}}>
                                    {userGbList2?.map(item => (
                                        <React.Fragment key={item.numkey}>
                                            { item.numkey == initialState.user.addn_info_tag || (initialState.user.addn_info_tag !=null && initialState.user.addn_info_tag.indexOf(item.numkey) > -1 && (
                                                <font className={`looking_id_show type${item.no}`}>
                                                    { item.numkey == initialState.user.addn_info_tag || (initialState.user.addn_info_tag !=null && initialState.user.addn_info_tag.indexOf(item.numkey) > -1)  ? item.tag_nm : ""}
                                                </font>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                    {initialState.isOpenColor&&<InterestMemberColorSelect onClose={closeColor} userId={userId}/>}
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary" style={{backgroundColor: "lemonchiffon"}}>
                                <span style={{color: "black", fontWeight: "bold"}}>부본사명 : {initialState.orgNm1}</span>
                            </div>
                            <div className="p-1 border-bottom border-secondary" style={{backgroundColor: "lemonchiffon"}}>
                                <span style={{color: "black", fontWeight: "bold"}}>총판명 : {initialState.orgNm2}</span>
                            </div>
                            <div className="p-1 border-bottom border-secondary" style={{backgroundColor: "lemonchiffon"}}>
                                <span style={{color: "black", fontWeight: "bold"}}>가맹점명 : {initialState.orgNm3}</span>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <span style={{color: "#0a5c98", fontWeight: "bold"}}>최종 도메인 : <span style={{color: "black"}}>{initialState.user?.reg_domain_nm}</span></span>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <span style={{color: "#0a5c98", fontWeight: "bold"}}>가입일시 : {initialState.user?.entr_dttm}</span>
                            </div>
                            <div className="p-1 border-bottom border-secondary" style={{backgroundColor: "yellow"}}>
                                <span style={{color: "#0a5c98", fontWeight: "bold"}}>최종 활동 일시 : {initialState.user?.rcnt_actv_dttm} ({initialState.user?.diffDay}일 {initialState.user?.diffHH}시간)</span>
                            </div>
                            <div className="p-1 border-bottom border-secondary" style={{backgroundColor: "yellow"}}>
                                <span style={{color: "#0a5c98", fontWeight: "bold"}}>최종 충전 일시 : {initialState.user?.lately_deposit? initialState.user?.lately_deposit : "- (-)"}</span>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div className="d-flex">
                                    <span style={{color: "#0a5c98", fontWeight: "bold"}}>Kgon머니 : </span>
                                    <button className="btn-secondary">머니 조회 클릭</button>
                                </div>
                                <div className="d-flex">
                                    <button className="btn-secondary">Kgon Kick(회원튕김)</button>
                                    <div style={{marginLeft: "auto", marginRight: "auto"}}></div>
                                    <button onClick={(e)=> widthdrawForcekgonMoney()} className="btn-secondary text-danger">kgon머니 강제출금(게임머니로)</button>
                                </div>
                                <div className="d-flex">
                                    <div className="w-50">
                                        <span style={{color: initialState.user?.game_money_amt > -1 ? "blue" : "red" , fontWeight: "bold"}}>게임머니 : <PlusMinusText number={initialState.user?.game_money_amt} /> 원</span>
                                    </div>
                                </div>
                                <div>
                                    <span style={{color: initialState.user?.point > -1 ? "blue" : "red" , fontWeight: "bold"}}>포인트 : <span style={{color: initialState.user?.point > -1 ? "blue" : "red"}}>
                                        <NumericFormat value={initialState.user?.point} thousandSeparator={true} displayType="text" />
                                    </span>점
                                        < a style={{cursor:"pointer"}} onClick={()=>{
                                            window.open(`/deposit/Point_list/${userId}`,"_blank", "popup")
                                        }}>
                                            <span style={{color: "red"}}>[내역]</span>
                                        </a>
                                    </span>
                                </div>
                                <div>
                                    <span style={{color: "blue", fontWeight: "bold"}}>일반쿠폰 : <span style={{color: "blue"}}>
                                        <NumericFormat value={initialState.user?.coupon01Count} thousandSeparator={true} displayType="text" />
                                    </span>장</span>
                                    &nbsp;/&nbsp;
                                    <span style={{color: "blue", fontWeight: "bold"}}>프리미엄쿠폰 : <span style={{color: "blue"}}>
                                        <NumericFormat value={initialState.user?.coupon02Count} thousandSeparator={true} displayType="text" />
                                    </span>장</span>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary d-flex bg-light text-center w-100">
                                <span className="w-100">일반쿠폰 : <span className="text-primary">{initialState.user?.coupon01Count}</span>장  프리미엄쿠폰 : <span className="text-primary">{initialState.user?.coupon02Count}</span>장  VIP쿠폰 : <span className="text-primary">{initialState.user?.coupon03Count}</span>장  쿠폰 : <span className="text-primary">{initialState.user?.couponAllCount}</span>장</span>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div>
                                    <span style={{color: "#0a5c98", fontWeight: "bold"}}>충전 : <PlusMinusText number={initialState.user?.depositAmtTotal} />원 / 평균 : <PlusMinusText number={Math.floor(initialState.user?.depositAmtAvg)} />원</span>
                                </div>
                                <div>
                                    <span style={{color: "#0a5c98", fontWeight: "bold"}}>환전 : <PlusMinusText number={initialState.user?.withdrawAmtTotal} />원 / 평균 : <PlusMinusText number={Math.floor(initialState.user?.withdrawAmtAvg)} />원</span>
                                </div>
                                <div>
                                    <span style={{color: "#0a5c98", fontWeight: "bold"}}>마진 : <PlusMinusText number={initialState.user?.profit_amt} />원</span>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div>
                                    <span style={{color: "#0a5c98", fontWeight: "bold"}}>식사비지급 / 전체 : <PlusMinusText number={initialState.user?.mealPointAll} />원</span>
                                </div>
                                <div>
                                    <span style={{color: "#0a5c98", fontWeight: "bold"}}>당월 : <PlusMinusText number={initialState.user?.mealPointMM} />원 / 당일 : <PlusMinusText number={initialState.user?.mealPointDD} />원</span>
                                </div>
                            </div>

                            <div className="p-1 border-bottom border-secondary">
                                <table className="table table-sm text-center align-middle m-0">
                                    <thead>
                                    <tr style={{backgroundColor: "#0a5c98"}}>
                                        <td colSpan="6">
                                            <span style={{color: "white", fontWeight: "bold"}}>마지막 환전 이후 롤링%</span>
                                        </td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style={{backgroundColor: "#DEDEDE", fontWeight: "bold"}}>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>전체</span></td>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>스포츠</span></td>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>미니게임</span></td>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>토큰</span></td>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>인플레이</span></td>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>Kg카지노</span></td>
                                    </tr>
                                    <tr style={{backgroundColor: "white"}}>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                    </tr>
                                    </tbody>
                                </table>

                                <table className="table table-sm text-center align-middle m-0 mt-1">
                                    <thead>
                                    <tr style={{backgroundColor: "#0a5c98"}}>
                                        <td colSpan="6">
                                            <span style={{color: "white", fontWeight: "bold"}}>마지막 충전 이후 롤링%</span>
                                        </td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style={{backgroundColor: "#DEDEDE", fontWeight: "bold"}}>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>전체</span></td>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>스포츠</span></td>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>미니게임</span></td>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>토큰</span></td>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>인플레이</span></td>
                                        <td><span style={{color: "#0a5c98", fontWeight: "bold"}}>Kg카지노</span></td>
                                    </tr>
                                    <tr style={{backgroundColor: "white"}}>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                        <td><span style={{color: "#A6A6A6", fontWeight: "bold"}}>충전x</span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div className="p-1 border-bottom border-secondary" style={{backgroundColor: "lemonchiffon"}}>
                                <span className="fw-bold text-dark">지인 정산 정보 (2023-10-01 ~ 2023-11-01)</span>
                                <br />
                                <span className="text-dark">이월금 : <PlusMinusText number={0} /> </span>
                                <br />
                                <span className="text-dark">총 마진 : <PlusMinusText number={0} /> / 정산액(0%) : <PlusMinusText number={0} /></span>
                            </div>

                            <div className="p-1 border-bottom border-secondary">
                                <textarea className="w-100" style={{height: "100px", fontWeight: "bold"}} value={initialState.user?.memo1+"\r\n"+initialState.user.memo2+"\r\n"+initialState.user.memo3} readOnly={true}/>
                            </div>

                        </div>

                        <div style={{backgroundColor: "white"}}>
                            <div className="p-1 border-bottom border-secondary">
                                <button className="btn btn-danger w-100" onClick={() => {
                                    //handleClickOpen5(initialState.user?.id, initialState.user?.no, initialState.user?.nickname)
                                    window.open(`/user/member_total_info/${userId}`,"_blank", "popup")
                                }}  >회원 통합 정보</button>
                                <div className="d-flex mt-1">
                                    <button onClick={()=> {
                                        dispatch(closeUserSimpleInfo())
                                        window.open(`/deposit/Money_list/${userId}`,"_blank", "popup")
                                    }} className="btn btn-secondary w-50 me-1">머니거래내역</button>
                                    <button onClick={()=>{
                                        window.open(`/deposit/Point_list/${userId}`,"_blank", "popup")
                                    }} className="btn btn-secondary w-50 ms-1">포인트내역</button>
                                </div>
                            </div>

                            <div className="p-1 border-bottom border-secondary">
                                <div className="d-flex">
                                    <button className="btn btn-success w-50 me-1" onClick={() => {
                                        //handleClickOpen2(initialState.user?.id)
                                        window.open(`/user/member_info/${userId}`,"_blank", "width=1100,height=800,left=20,top=200")
                                    }} >회원정보</button>
                                    <button onClick={()=> {
                                        window.open(`/board/center/${userId}`,"_blank", "popup")
                                    }} className="btn btn-secondary w-50 ms-1">고객센터</button>
                                </div>
                                <div className="d-flex mt-1">
                                    <button className="btn btn-secondary w-50 me-1" onClick={()=>{
                                        window.open(`/betting/betting_list/${userId}`,"_blank", "popup")
                                    }}>베팅내역</button>
                                    <button onClick={()=> {
                                        window.open(`/user/Message/${userId}`,"_blank", "popup")
                                    }} className="btn btn-secondary w-50 ms-1">쪽지보내기</button>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div>
                                    <span style={{fontWeight: "bold"}}>* 게임머니</span>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">금액 :</label>
                                    <input onChange={onChangePayGameMoney_gameMoneyAmt} className="sm-input" type="number" value={initialState.payGameMoney_gameMoneyAmt}/>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">내용 :</label>
                                    <textarea onChange={onChangePayGameMoney_memo} value={initialState.payGameMoney_memo}/>
                                    <button onClick={payGameMoney} className="btn btn-info ms-1">지급</button>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div>
                                    <span style={{fontWeight: "bold"}}>* 포인트</span>
                                    <select onChange={onChangePayPoint_pointTypeCd} className="sm-select ms-1" style={{width: "50px"}}>
                                        {initialState.cmCd0029&&initialState.cmCd0029.map((option) => (
                                            <option key={option.cmmn_cd_dtl_id} value={option.cmmn_cd_dtl_id}>
                                                {option.cmmn_cd_dtl_nm}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">금액 :</label>
                                    <input onChange={onChangePayPoint_point} value={initialState.payPoint_point} className="sm-input" type="number" />
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">내용 :</label>
                                    <textarea onChange={onChangePayPoint_memo} value={initialState.payPoint_memo}/>
                                    <button onClick={payPoint} className="btn btn-info ms-1">지급</button>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div>
                                    <span style={{fontWeight: "bold"}}>* 충전 강제 신청</span>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">금액 :</label>
                                    <input onChange={onChangeRegForceDeposite_depositAmt} value={initialState.regForceDeposite_depositAmt} className="sm-input" type="number"/>
                                </div>
                                <div className="d-flex mt-1">
                                    <input type="radio" value="01" checked={(gameType === "01" ? true : false)}
                                           onChange={gameTypeChangeHandler} style={{cursor: "pointer"}}/>
                                    &nbsp;
                                    <font size={1}><b>스포츠 충전 5%(롤링 100%)</b></font>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">
                                        <input type="radio" value="02" checked={(gameType === "02" ? true : false)}
                                               onChange={gameTypeChangeHandler} style={{cursor: "pointer"}}/>
                                        &nbsp;
                                        <font size={1}><b>카지노 충전 5%(롤링 100%)</b></font>
                                    </label>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">
                                        <input type="radio" value="03" checked={(gameType === "03" ? true : false)}
                                               onChange={gameTypeChangeHandler} style={{cursor: "pointer"}}/>
                                        &nbsp;
                                        <font size={1}><b>슬롯 첫충전 15%, 매충전 10%(롤링 100%)</b></font>
                                    </label>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">
                                        <input type="radio" value="04" checked={(gameType === "04" ? true : false)}
                                               onChange={gameTypeChangeHandler} style={{cursor: "pointer"}}/>
                                        &nbsp;
                                        <font size={1}><b>미니게임 충전 5%(롤링 100%)</b></font>
                                    </label>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">
                                        <input type="radio" value="09" checked={(gameType === "09" ? true : false)}
                                               onChange={gameTypeChangeHandler} style={{cursor: "pointer"}}/>
                                        &nbsp;
                                        <font size={1}><b>관리자강제신청</b></font>
                                    </label>
                                    <button onClick={regForceDeposite} className="btn btn-info ms-1">신청</button>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div>
                                    <span style={{fontWeight: "bold", color: "red"}}>* 환전 강제 신청</span>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">금액 :</label>
                                    <input onChange={onChangeRegForceWithdraw_withdrawAmt} value={initialState.regForceWithdraw_withdrawAmt} className="sm-input" type="number" style={{backgroundColor: "yellow"}}/>
                                    <button onClick={regForceWithdraw} className="btn btn-info ms-1">신청</button>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div>
                                    <span style={{fontWeight: "bold", color: "red"}}>* kg카지노머니</span>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">금액 :</label>
                                    <input onChange={onChangePayKgCasinoMoney_kgCasinoMoneyAmt} value={initialState.payKgCasinoMoney_kgCasinoMoneyAmt} className="sm-input" type="number" style={{backgroundColor: "yellow"}}/>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">내용 :</label>
                                    <textarea onChange={onChangePayKgCasinoMoney_kgCasinoMoneyMemo} value={initialState.payKgCasinoMoney_kgCasinoMoneyMemo}/>
                                    <button onClick={payKgCasinoMoney} className="btn btn-info ms-1">지급</button>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div>
                                    <span style={{fontWeight: "bold"}}>* 복권</span>
                                </div>
                                <div className="d-flex mt-1">
                                    <select onChange={onChangePayLottery_lotteryTypeCd} className="sm-select ms-1">
                                        {initialState.cmCd0033&&initialState.cmCd0033.map((option) => (
                                            <option key={option.cmmn_cd_dtl_id} value={option.cmmn_cd_dtl_id}>
                                                {option.cmmn_cd_dtl_nm}
                                            </option>
                                        ))}
                                    </select>

                                    <span className="mx-1">/ 지급 : </span>
                                    <input onChange={onChangePayLottery_sheets} value={initialState.payLottery_sheets} className="sm-input" type="number" style={{width: "30px"}} />
                                    <span>장</span>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">내용 :</label>
                                    <textarea onChange={onChangePayLottery_lotteryName} value={initialState.payLottery_lotteryName}/>
                                    <button onClick={payLottery} className="btn btn-info ms-1">지급</button>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div>
                                    <span style={{fontWeight: "bold"}}>* 쿠폰 지급</span>
                                </div>
                                <div className="d-flex mt-1">
                                    <span className="me-1">금액 : </span>
                                    <input onChange={onChangePayCoupon_payCouponAmt} value={initialState.payCoupon_payCouponAmt} className="sm-input me-1" type="number" />
                                    <span className="mx-1">/ 지급 : </span>
                                    <input onChange={onChangePayCoupon_sheet}  value={initialState.payCoupon_sheet} className="sm-input" type="number" style={{width: "30px"}} />
                                    <span>장</span>
                                </div>
                                <div className="d-flex mt-1">
                                    <label className="me-1">내용 :</label>
                                    <textarea onChange={onChangePayCoupon_payCouponEtc} value={initialState.payCoupon_payCouponEtc}/>
                                    <button onClick={payCoupon} className="btn btn-info ms-1">지급</button>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <div className="d-flex">
                                    <button onClick={connectClose} className="btn btn-secondary w-50 me-1">접속 종료</button>
                                    <button onClick={connectBlock} className="btn btn-secondary w-50 ms-1">접속 차단</button>
                                </div>
                                <div className="d-flex mt-1">
                                    <button onClick={recoverGameMoney} className="btn btn-secondary w-50 me-1">게임머니회수</button>
                                    <button onClick={recoverPoint} className="btn btn-secondary w-50 ms-1">포인트회수</button>
                                </div>
                            </div>
                            <div className="p-1 border-bottom border-secondary">
                                <button onClick={resetInit} className="btn btn-secondary w-100 text-danger">머니/포인트 내역보정</button>
                            </div>
                        </div>


                    </div>
                    <div className="container-fluid p-0 m-0 border bg-light" style={{height: "fit-content"}}>
                        <div className="row m-0 p-0 my-1">
                            <div className="col m-0 p-0 mx-1 form-check">
                                <input type="radio" className="btn-check" name="btnradio" id="bet_radio_all" defaultChecked={true}  onChange={(e) => {
                                    if(e.currentTarget.checked) {
                                        getSimpleData()
                                    }
                                }}/>
                                <label className="btn btn-outline-primary w-100" htmlFor="bet_radio_all">전체</label>
                            </div>
                            <div className="col m-0 p-0 mx-1 form-check">
                                <input type="radio" className="btn-check" name="btnradio" id="bet_radio_60"  onChange={(e) => {
                                    if(e.currentTarget.checked) {
                                        getSimpleData(moment().add(-60, "days").format("YYYY-MM-DD"))
                                    }
                                }}/>
                                <label className="btn btn-outline-primary w-100" htmlFor="bet_radio_60">지난 60일</label>
                            </div>
                            <div className="col m-0 p-0 mx-1 form-check">
                                <input type="radio" className="btn-check" name="btnradio" id="bet_radio_30" onChange={(e) => {
                                    if(e.currentTarget.checked) {
                                        getSimpleData(moment().add(-30, "days").format("YYYY-MM-DD"))
                                    }
                                }}/>
                                <label className="btn btn-outline-primary w-100" htmlFor="bet_radio_30">지난 30일</label>
                            </div>
                        </div>
                        <div className="row m-0 p-0 mb-1">
                            <div className="col m-0 p-0 mx-1 form-check">
                                <input type="radio" className="btn-check" name="btnradio" id="bet_radio_15" onChange={(e) => {
                                    if(e.currentTarget.checked) {
                                        getSimpleData(moment().add(-15, "days").format("YYYY-MM-DD"))
                                    }
                                }}/>
                                <label className="btn btn-outline-primary w-100" htmlFor="bet_radio_15">지난 15일</label>
                            </div>
                            <div className="col m-0 p-0 mx-1 form-check">
                                <input type="radio" className="btn-check" name="btnradio" id="bet_radio_7" onChange={(e) => {
                                    if(e.currentTarget.checked) {
                                        getSimpleData(moment().add(-1, "week").format("YYYY-MM-DD"))
                                    }
                                }}/>
                                <label className="btn btn-outline-primary w-100" htmlFor="bet_radio_7">지난 7일</label>
                            </div>
                            <div className="col m-0 p-0 mx-1 form-check">
                                <input type="radio" className="btn-check" name="btnradio" id="bet_radio_1" onChange={(e) => {
                                    if(e.currentTarget.checked) {
                                        getSimpleData(moment().add(-1, "days").format("YYYY-MM-DD"))
                                    }
                                }}/>
                                <label className="btn btn-outline-primary w-100" htmlFor="bet_radio_1">어제~오늘</label>
                            </div>
                        </div>
                        <div className="text-bg-secondary text-center w-100 text-info fw-bold fs-6" style={{height: "40px"}}>
                            베팅한 게임만 나열 됩니다
                        </div>

                        <div className="d-flex bg-warning p-2">
                            <div className="w-50">
                                <span className="text-danger fw-bold fs-6">총 단순 마진</span>
                            </div>
                            <div className="w-50 text-end fs-6">
                                <PlusMinusText number={initialState.marginList[0]?.totalMoney - initialState.marginList[0]?.totalResultMoney} />
                            </div>
                        </div>
                        {initialState.marginList.length > 0? <div>
                            <p className="px-1" style={{
                                backgroundColor: "#AAAAAA",
                                color: "white"
                            }}>수동일반</p>
                            <div className="d-flex px-1">
                                <span style={{marginRight: "auto"}}>평균 베팅금</span>
                                <PlusMinusText number={initialState.marginList[0]?.totalMoney / initialState.marginList[0]?.betCount}/>
                                <span>(<span style={{color:"blue"}}>{initialState.marginList[0]?.betCount}</span>회)</span>
                            </div>
                            <div className="d-flex px-1">
                                <span style={{marginRight: "auto"}}>베팅금</span>
                                <PlusMinusText number={initialState.marginList[0]?.totalMoney}/>
                            </div>
                            <div className="d-flex px-1">
                                <span style={{marginRight: "auto"}}>당첨금</span>
                                <PlusMinusText number={initialState.marginList[0]?.totalResultMoney}/>
                            </div>
                            <div className="d-flex px-1">
                                <span style={{marginRight: "auto"}}>실패금</span>
                                <PlusMinusText number={initialState.marginList[0]?.totalFailMoney}/>
                            </div>
                            <div className="d-flex px-1" style={{
                                backgroundColor: "#FAED7D"
                            }}>
                                <span style={{marginRight: "auto", color: "red"}}>단순 마진(베팅금-당첨금)</span>
                                <PlusMinusText number={initialState.marginList[0]?.totalMoney - initialState.marginList[0]?.totalResultMoney}/>
                            </div>
                        </div> : <></>}

                    </div>
                </div>
            </div>

        </Fragment>
    )
    return content
}
export default MemberInformationSimpleModal;