module.exports = {
    NOT_STARTED_YET: 1,
    IN_PROGRESS: 2,
    FINISHED: 3,
    CANCELLED: 4,
    POSTPONED: 5,
    INTERRUPTED: 6,
    ABANDONED: 7,
    COVERAGE_LOST: 8,
    ABOUT_TO_START: 9,
    getKorMessage: (settlement) => {
        switch(settlement) {
            case 1 : return "시작전"
            case 2 : return "진행중"
            case 3 : return "완료"
            case 4 : return "취소"
            case 5 : return "연기"
            case 6 : return "중단"
            case 7 : return "폐기"
            case 8 : return "신호잃음"
            case 9 : return "곧시작"
            default : return "오류"
        }
    }
}