import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getIpBlockListReducer,setBlockIp,setEtc,setChk,setChkAll} from "../../redux/reducers/setting/IpBlockListReducer";
import axios from "axios";
import {insertAdminLog} from "../../lib/api";

const IpSetting = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.IpBlockListReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const getList = () => {
        if(initialState.gridList !== null ) {
            dispatch(getIpBlockListReducer({input:{}}))
        }
        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "IP차단"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const reload = () =>{
        dispatch(getIpBlockListReducer({input:{}}))
    }

    const changeBlockIp = (idx,val) =>{
        dispatch(setBlockIp({idx : idx , val : val }))
    }

    const changeEtc = (idx,val) =>{
        dispatch(setEtc({idx : idx , val : val }))
    }
    const addRow = () =>{
        axios.post('/admin/setting/insertIpBlock').
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }
    const updateList = ()=> {

        axios.post('/admin/setting/updateIpBlock', initialState.gridList).
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }

    const deleteRow = (no) => {
        axios.post('/admin/setting/deleteIpBlock', {no : no}).
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }

    const deleteListRow = () => {
        axios.post('/admin/setting/deleteListIpBlock', initialState.gridList).
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }

    const changeChk =(idx,val) => {
        dispatch(setChk({idx : idx , val : val }))
    }

    const changeChkAll =(val) => {
        dispatch(setChkAll({val : val}))
    }

    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">IP차단</font>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"}  >
                        <tbody>
                        <tr>
                            <td align={"left"}>
                                <button onClick={deleteListRow} style={{
                                    width: "100px", backgroundColor:"red"
                                }} type="submit" className="btn btn-primary" >삭제하기</button>
                            </td>
                            <td align={"right"}>
                                <button onClick={updateList} style={{
                                    width: "100px"
                                }} type="submit" className="btn btn-primary" >저장하기</button>
                                &nbsp;
                                <button onClick={addRow} style={{
                                    width: "100px"
                                }} type="submit" className="btn btn-primary" >추가하기</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th style={{width: "3%"}}>
                                <input onChange={(e)=>changeChkAll(e.target.checked)} className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                            </th>
                            <th style={{width: "15%"}}>차단 아이피</th>
                            <th style={{width: "*%"}}>메모</th>
                            <th style={{width: "10%"}}>일시</th>
                            <th style={{width: "5%"}}>삭제</th>
                        </tr>
                        </thead>
                        {initialState.gridList && initialState.gridList.length > 0 ? initialState.gridList.map((List, index) => (
                                <tbody key={List.no}>
                                <tr>
                                    <td align={"center"}>
                                        <input checked ={List._chkYn === "Y"} onChange={(e) =>changeChk(index,e.target.checked)} className="form-check-input" type="checkbox" name="radioNm" id="radioId" />
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input value={List.blockIp?List.blockIp:''} onChange={(e)=>changeBlockIp(index,e.target.value)} className="form-check-input sm-input" type="text" style={{ width: "300px" }} placeholder="183.105.54.116"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input value={List.etc?List.etc:''} onChange={(e)=>changeEtc(index,e.target.value)} className="form-check-input sm-input" type="text" style={{ width: "1000px" }} placeholder="로그인 시도, 180분내 5개 이상 아이디 시도 실패"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>23/11/11 16:25:28</td>
                                    <td align={"center"}>
                                        <button onClick={()=>deleteRow(List.no)} style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "24px", width: "fit-content", marginRight: "5px"}}>삭제</button>
                                    </td>
                                </tr>
                                </tbody>
                            )) :
                            <tbody>
                            <tr>
                                <td colSpan= "5">조회된 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>


    )
}
export default IpSetting;