import React, {useEffect, useState} from "react";
import axios from "axios";


const PointGrade = ({onChangePG}) => {

    const [levels, setLevels] = useState([])
    const [selectedLevels, setSelectedLevels] = useState([])
    useEffect(() => {
        getGrades()
    }, [])

    const getGrades = () => {
        axios.post('/admin/admin/getPointGrade', {

        }).then((res) => {
            setLevels(res.data.result)
            const arr = []
            for(const level of res.data.result) {
                arr.push(level.setting_level_no)
            }
            setSelectedLevels(arr)
        })
    }
    const selectAll = (e) => {
        const arr = []
        for(const level of levels) {
            arr.push(level.setting_level_no)
        }
        setSelectedLevels(arr)
        onChangePG("")
    }
    const unSelectAll = (e) => {
        setSelectedLevels([])
        onChangePG([])
    }

    return <div className="sm-center-div" style={{display: "flex", border: "1px solid lightgray"}}>
        <label>
            P등급 :
        </label>

        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}} onClick={selectAll}>전체 P등급</button>
        <button className="ms-1 me-1" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}} onClick={unSelectAll}>전체 P해제</button>

        {levels?.map((item, index) => (
            <>
                <input className="form-check-input sm-radio" type="checkbox" id={"pgd_" + item.setting_level_no}
                       checked={selectedLevels.filter((el) => el === item.setting_level_no).length > 0}
                       onChange={(e) => {
                           if(e.currentTarget.checked) {
                               if(!selectedLevels.includes(item.setting_level_no)) {
                                   const arr = [...selectedLevels]
                                   arr.push(item.setting_level_no)
                                   setSelectedLevels(arr)
                                   onChangePG(arr)
                               }
                           }
                           else {
                               if(selectedLevels.includes(item.setting_level_no)) {
                                   const arr = [...selectedLevels]
                                   const arr2 = arr.filter((el) => el !== item.setting_level_no)
                                   setSelectedLevels(arr2)
                                   onChangePG(arr2)
                               }
                           }

                       }}/>
                <label className="form-check-label sm-label" htmlFor={"pgd_" + item.setting_level_no}>
                    {`${item.admin_level} [${item.user_level}]`}
                </label>
            </>
        ))}

    </div>
}

export default PointGrade