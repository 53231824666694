import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import axios from "axios";

import {useParams} from "react-router-dom"
import {
    setSettingSportDomesticYn,
    setSettingSportForeignYn, setSettingSportInplayYn, setSettingSportSpecialYn
} from "../../../redux/reducers/setting/LvlSettingListReducer";

const LvlSettingSportDtl = (props) => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.lvlSetting)
    const userInfoId = useSelector((state) => state.userInfo.id)

    const param = useParams()
    const id = param && param.userId;
    const setting_sport_domestic_yn = param && param.yn01;
    const setting_sport_foreign_yn = param && param.yn02;
    const setting_sport_inplay_yn = param && param.yn03;
    const setting_sport_special_yn = param && param.yn04;


    const popClose =() => {
        if (window.opener && typeof window.opener.popupClose === 'function') {
            window.opener.popupClose();
        }
        window.close();
    }

    const changeSettingSportDomesticYn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingSportDomesticYn('N')); }
            else{ dispatch(setSettingSportDomesticYn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingSportDomesticYn('N')); }
            else{ dispatch(setSettingSportDomesticYn('Y')); }
        }
    }

    const changeSettingSportForeignYn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingSportForeignYn('N')); }
            else{ dispatch(setSettingSportForeignYn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingSportForeignYn('N')); }
            else{ dispatch(setSettingSportForeignYn('Y')); }
        }
    }

    const changeSettingSportInplayYn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingSportInplayYn('N')); }
            else{ dispatch(setSettingSportInplayYn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingSportInplayYn('N')); }
            else{ dispatch(setSettingSportInplayYn('Y')); }
        }
    }

    const changeSettingSportSpecialYn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingSportSpecialYn('N')); }
            else{ dispatch(setSettingSportSpecialYn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingSportSpecialYn('N')); }
            else{ dispatch(setSettingSportSpecialYn('Y')); }
        }
    }

    const updateMainBtnState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlMainDtlBtnSetting', {
                pntGbCd: id,
                settingSportDomesticYn: initialState.settingSportDomesticYn == '' ? setting_sport_domestic_yn : initialState.settingSportDomesticYn,
                settingSportForeignYn: initialState.settingSportForeignYn == '' ? setting_sport_foreign_yn : initialState.settingSportForeignYn,
                settingSportInplayYn: initialState.settingSportInplayYn == '' ? setting_sport_inplay_yn : initialState.settingSportInplayYn,
                settingSportSpecialYn: initialState.settingSportSpecialYn == '' ? setting_sport_special_yn : initialState.settingSportSpecialYn,
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                popClose();
            })

        }
    }


    return (
        <div className="common" >
            <div style={{
                paddingLeft: "1px"
            }}>

                <div style={{
                    position:"relative",
                    background:"#3c3c3d",
                    height:"30px",
                    alignItems:"center",
                    textAlign:"left",
                    alignContent:"center"
                }}>
                    &nbsp;&nbsp;&nbsp;
                    <font color={"white"} size={1.5}><b> 스포츠 상세 설정</b></font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                        <button style={{ backgroundColor: initialState.settingSportDomesticYn == '' ? (setting_sport_domestic_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingSportDomesticYn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingSportDomesticYn == '' ? (setting_sport_domestic_yn == 'Y' ? "white" : "black") : (initialState.settingSportDomesticYn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "80px", marginRight: "5px", marginTop:"10px"}}
                                onClick={(e) => {
                                    changeSettingSportDomesticYn(initialState.settingSportDomesticYn, setting_sport_domestic_yn)
                                }}
                        >
                            <b>국내형</b>
                        </button>

                        <button style={{ backgroundColor: initialState.settingSportForeignYn == '' ? (setting_sport_foreign_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingSportForeignYn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingSportForeignYn == '' ? (setting_sport_foreign_yn == 'Y' ? "white" : "black") : (initialState.settingSportForeignYn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "80px", marginRight: "5px", marginTop:"10px"}}
                                onClick={(e) => {
                                    changeSettingSportForeignYn(initialState.settingSportForeignYn, setting_sport_foreign_yn)
                                }}
                        >
                            <b>해외형</b>
                        </button>
                        <button style={{ backgroundColor: initialState.settingSportInplayYn == '' ? (setting_sport_inplay_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingSportInplayYn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingSportInplayYn == '' ? (setting_sport_inplay_yn == 'Y' ? "white" : "black") : (initialState.settingSportInplayYn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "80px", marginRight: "5px", marginTop:"10px"}}
                                onClick={(e) => {
                                    changeSettingSportInplayYn(initialState.settingSportInplayYn, setting_sport_inplay_yn)
                                }}
                        >
                            <b>인플레이</b>
                        </button>
                        <button style={{ backgroundColor: initialState.settingSportSpecialYn == '' ? (setting_sport_special_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingSportSpecialYn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingSportSpecialYn == '' ? (setting_sport_special_yn == 'Y' ? "white" : "black") : (initialState.settingSportSpecialYn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "80px", marginRight: "5px", marginTop:"10px"}}
                                onClick={(e) => {
                                    changeSettingSportSpecialYn(initialState.settingSportSpecialYn, setting_sport_special_yn)
                                }}
                        >
                            <b>스폐셜</b>
                        </button>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                            onClick={() => {updateMainBtnState()}}
                    >
                        변경
                    </button>
                </div>
            </div>
        </div>
    )
}
export default LvlSettingSportDtl;