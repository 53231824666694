import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';

const SetLosingContainer = ({userId, setSelectedTab}) => {

    const userInfoId = useSelector((state) => state.userInfo.id)

    const [detailBetInfo01, setDetailBetInfo01] = useState([]);
    const [detailBetInfo02, setDetailBetInfo02] = useState([]);
    const [detailBetInfo03, setDetailBetInfo03] = useState([]);
    const [detailBetInfo04, setDetailBetInfo04] = useState([]);

    const [noArr, setNoArr] = useState([]);
    const [noArr02, setNoArr02] = useState([]);
    const [betArr01, setBetArr01] = useState([]);
    const [betArr02, setBetArr02] = useState([]);


    const getDetailLosingInfo = () => {

        axios.post('/admin/member/getDetailLosingInfo', {
            userId : userId
        }).then((res) => {
            setDetailBetInfo01(res.data.type01List);
            setDetailBetInfo02(res.data.type02List);
            setDetailBetInfo03(res.data.type03List);
            setDetailBetInfo04(res.data.type04List);
        })

    }
    useEffect(getDetailLosingInfo, [])


    const setBetArr01Handler = (e, index) => {
        betArr01[index] = e.target.value;
        setBetArr01(betArr01);
        noArr[index] = index;
        setNoArr(noArr);
        //alert("betArr01 >>> "+JSON.stringify(betArr01));
    }
    const setBetArr02Handler = (e, index) => {
        betArr02[index] = e.target.value;
        setBetArr02(betArr02);
        noArr02[index] = index;
        setNoArr02(noArr02);
        //alert("betArr01 >>> "+JSON.stringify(betArr01));
    }


    const updateState = (noArr, betArr01, noArr02, betArr02) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/member/saveLosingInfo', {
                adminNo: userInfoId,
                userId: userId,
                noArr: noArr,
                betArr01: betArr01,
                noArr02: noArr02,
                betArr02: betArr02
            }).then((res) => {
                alert(res.data.isSaved? "저장완료" : "저장실패")
                setSelectedTab(0)
                setSelectedTab(5)
            })
        }
    }
    const handleClose = () => {
        window.close();
    }

    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table width={"99%"}>
                    <tr>
                        <td align={"right"}>
                            <button style={{
                                backgroundColor: "#5dc434",
                                border: "none",
                                color: "white",
                                fontSize: "9px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "10px"
                            }}
                                    onClick={()=>(updateState(noArr, betArr01, noArr02, betArr02)) }
                            >회원 수정
                            </button>
                            <button style={{
                                backgroundColor: "#3fadf7",
                                border: "none",
                                color: "white",
                                fontSize: "9px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "5px"
                            }}  onClick={handleClose}>닫기
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="commonT table table-bordered table-sm text-center" width={"50%"}>
                    <thead>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <b>게임 종류별 개인 <font color={"red"}>1폴더</font> 루징 설정</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "20%",backgroundColor: "lightgray"}}>종류</td>
                        <td style={{width: "40%",backgroundColor: "lightgray"}}>1폴더 루징 비율(본인)</td>
                        <td style={{width: "40%",backgroundColor: "lightgray"}}>1폴더 루징 비율(하부 추천인)
                            <br></br>* 하부 추천인이 베팅할 때 본인에게 쌓일 루징 비율
                        </td>
                    </tr>
                    {detailBetInfo01.map((detailBetInfo01, index) => (
                        <tr>
                            <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>
                                <font color={detailBetInfo01.type_dtl_cd < 3 ? "red" : "black"}>{detailBetInfo01.type_dtl_nm}</font>
                            </td>
                            <td style={{width: "15%",backgroundColor: detailBetInfo01.ratio_me > 0 ? "lightskyblue":"white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder={detailBetInfo01.ratio_me}
                                       onChange={(e) => {setBetArr01Handler(e, detailBetInfo01.no, detailBetInfo01.type_cd)}} /> %
                            </td>
                            <td style={{width: "25%",backgroundColor: detailBetInfo01.ratio_other > 0 ? "lightskyblue":"white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder={detailBetInfo01.ratio_other}
                                       onChange={(e) => {setBetArr02Handler(e, detailBetInfo01.no, detailBetInfo01.type_cd)}}  /> %
                            </td>
                        </tr>
                    ))}
                    </thead>
                </table>
                <table className="commonT table table-bordered table-sm text-center" width={"50%"}>
                    <thead>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <b>게임 종류별 개인 <font color={"red"}>2폴더</font> 루징 설정</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                    </tr>
                    <tr>

                        <td style={{width: "20%",backgroundColor: "lightgray"}}>종류</td>
                        <td style={{width: "40%",backgroundColor: "lightgray"}}>2폴더 루징 비율(본인)</td>
                        <td style={{width: "40%",backgroundColor: "lightgray"}}>2폴더 루징 비율(하부 추천인)
                            <br></br>* 하부 추천인이 베팅할 때 본인에게 쌓일 루징 비율
                        </td>
                    </tr>
                    {detailBetInfo02.map((detailBetInfo02, index) => (
                        <tr>
                            <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>
                                <font color={detailBetInfo02.type_dtl_cd < 3 ? "red" : "black"}>{detailBetInfo02.type_dtl_nm}</font>
                            </td>
                            <td style={{width: "15%",backgroundColor: detailBetInfo02.ratio_me > 0 ? "lightskyblue":"white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder={detailBetInfo02.ratio_me}
                                       onChange={(e) => {setBetArr01Handler(e, detailBetInfo02.no, detailBetInfo02.type_cd)}} /> %
                            </td>
                            <td style={{width: "25%",backgroundColor: detailBetInfo02.ratio_other > 0 ? "lightskyblue":"white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder={detailBetInfo02.ratio_other}
                                       onChange={(e) => {setBetArr02Handler(e, detailBetInfo02.no, detailBetInfo02.type_cd)}}  /> %
                            </td>
                        </tr>
                    ))}
                    </thead>
                </table>
            </div>




            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="commonT table table-bordered table-sm text-center" width={"50%"}>
                    <thead>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <b>게임 종류별 개인 <font color={"red"}>3폴더</font> 루징 설정</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                    </tr>
                    <tr>

                        <td style={{width: "20%",backgroundColor: "lightgray"}}>종류</td>
                        <td style={{width: "40%",backgroundColor: "lightgray"}}>3폴더 루징 비율(본인)</td>
                        <td style={{width: "40%",backgroundColor: "lightgray"}}>3폴더 루징 비율(하부 추천인)
                            <br></br>* 하부 추천인이 베팅할 때 본인에게 쌓일 루징 비율
                        </td>
                    </tr>
                    {detailBetInfo03.map((detailBetInfo03, index) => (
                        <tr>
                            <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>
                                <font color={detailBetInfo03.type_dtl_cd < 3 ? "red" : "black"}>{detailBetInfo03.type_dtl_nm}</font>
                            </td>
                            <td style={{width: "15%",backgroundColor: detailBetInfo03.ratio_me > 0 ? "lightskyblue":"white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder={detailBetInfo03.ratio_me}
                                       onChange={(e) => {setBetArr01Handler(e, detailBetInfo03.no, detailBetInfo03.type_cd)}} /> %
                            </td>
                            <td style={{width: "25%",backgroundColor: detailBetInfo03.ratio_other > 0 ? "lightskyblue":"white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder={detailBetInfo03.ratio_other}
                                       onChange={(e) => {setBetArr02Handler(e, detailBetInfo03.no, detailBetInfo03.type_cd)}}  /> %
                            </td>
                        </tr>
                    ))}
                    </thead>
                </table>
                <table className="commonT table table-bordered table-sm text-center" width={"50%"}>
                    <thead>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <b>게임 종류별 개인 <font color={"red"}>4폴더</font> 루징 설정</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                    </tr>
                    <tr>

                        <td style={{width: "20%",backgroundColor: "lightgray"}}>종류</td>
                        <td style={{width: "40%",backgroundColor: "lightgray"}}>4폴더 루징 비율(본인)</td>
                        <td style={{width: "40%",backgroundColor: "lightgray"}}>4폴더 루징 비율(하부 추천인)
                            <br></br>* 하부 추천인이 베팅할 때 본인에게 쌓일 루징 비율
                        </td>
                    </tr>
                    {detailBetInfo04.map((detailBetInfo04, index) => (
                        <tr>
                            <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>
                                <font color={detailBetInfo04.type_dtl_cd < 3 ? "red" : "black"}>{detailBetInfo04.type_dtl_nm}</font>
                            </td>
                            <td style={{width: "15%",backgroundColor: detailBetInfo04.ratio_me > 0 ? "lightskyblue":"white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder={detailBetInfo04.ratio_me}
                                       onChange={(e) => {setBetArr01Handler(e, detailBetInfo04.no, detailBetInfo04.type_cd)}} /> %
                            </td>
                            <td style={{width: "25%",backgroundColor: detailBetInfo04.ratio_other > 0 ? "lightskyblue":"white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder={detailBetInfo04.ratio_other}
                                       onChange={(e) => {setBetArr02Handler(e, detailBetInfo04.no, detailBetInfo04.type_cd)}}  /> %
                            </td>
                        </tr>
                    ))}
                    </thead>
                </table>
            </div>
        </div>
    )
}
export default SetLosingContainer;