import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

const initialState = {
    isLoading: false,
    error: null,
    totalList : [],
    totalCount : 0,
    gridList : [],
    gridCount : 0,
    page: 1,
    pageSize: 20,
}
export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        getEvents(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
        },
        failedGetEvents(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setEvents(state, action) {
            state.isLoading = false;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.gridCount = action.payload.gridCount;
            state.gridList = action.payload.gridList;
        },
    }
});

export const { getEvents, failedGetEvents, setEvents } = eventSlice.actions;

export default eventSlice.reducer;