import React, {useEffect, useState} from 'react';
import {NumericFormat} from "react-number-format";
const RegBankSetting = ({regBankSetting, onRefresh, updateState}) => {


    const bankState = (bank_cd, use_yn) => {
        switch(use_yn) {
            //사용
            case "Y" :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td>
                                    <a onClick={() => {updateState(bank_cd,"N")}} style={{cursor:"pointer"}}><font color={"darkgreen"}>사용</font></a>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //미사용
            case "N" :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td>
                                    <a onClick={() => {updateState(bank_cd,"Y")}} style={{cursor:"pointer"}}><font color={"red"}>미사용</font></a>
                            </td>
                        </tr>
                    </table>
            </div>
                )
            default : return use_yn
        }
    }


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="table table-bordered table-sm text-center align-middle" style={{width : "70%"}}>
                        <thead className="table-dark">
                        <tr>
                            <th style={{width: "5%"}}>은행코드</th>
                            <th style={{width: "*%"}}>은행명</th>
                            <th style={{width: "10%"}}>사용유저수(정상유저)</th>
                            <th style={{width: "10%"}}>사용유저수(전체유저)</th>
                            <th style={{width: "10%"}}>사용여부</th>
                        </tr>
                        </thead>
                        {!regBankSetting.length && (
                            <tbody key={regBankSetting.bank_cd}>
                            <tr>
                                <td colSpan="5">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {regBankSetting.map((regBankSetting, index) => (
                            <tbody key={(regBankSetting.bank_cd)}>
                            <tr bgcolor={(regBankSetting.use_yn === 'Y' ? "lightyellow" : "white")  }>
                                <td align={"center"}>{regBankSetting.bank_cd}</td>
                                <td align={"center"}>{regBankSetting.tag_nm}</td>
                                <td align={"center"}>
                                    <NumericFormat value={regBankSetting.state_normal_cnt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td align={"center"}>
                                    <NumericFormat value={regBankSetting.state_all_cnt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td align={"center"}>
                                    {bankState(regBankSetting.bank_cd, regBankSetting.use_yn)}
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>


            </div>
        </div>

    )
}

export default RegBankSetting;