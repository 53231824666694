
import React from "react";
import moment from "moment/moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";

const CheckList = ({state, onRefresh, month}) => {
    const userInfoId = useSelector((state) => state.userInfo.id);
    const dispatch = useDispatch();
    const exportExcel = (id, details) => {
        if(details.length > 0) {
            let excelList = [];
            for(let i = 0; i < details.length; i++) {
                const row = details[i]
                let jsonObject = {
                    수정: "",
                    일자: `${row.date}${row.isChecked? "✅" : "🚫"}`,
                }
                if(row.isChecked) {
                    jsonObject["연속일 수"] = row.data.consec_att_day > 0? row.consec_att_day : 0
                    jsonObject["이달 연속일 수"] = row.data.consec_att_day_month > 0? row.consec_att_day_month : 0
                    jsonObject["이달 출석일 수"] = row.data.att_day_total_month > 0? row.att_day_total_month : 0
                    jsonObject["출석당시 충전액"] = row.data.deposit_amt > 0? row.data.deposit_amt : 0
                    jsonObject["당일 충전액"] = row.data.standard_deposit_amt > 0? row.data.standard_deposit_amt : 0
                    jsonObject["당일 환전액"] = row.data.deposit_amt > 0? row.data.deposit_amt : 0
                    jsonObject["출석 지급 포인트"] = row.data.result_point > 0? row.data.result_point : 0
                    jsonObject["수행아이디"] = row.data.reg_id? row.data.reg_id : ""
                    jsonObject["수행일시"] = row.data.complete_dt? row.data.complete_dt : ""
                    jsonObject["아이피"] = row.data.use_ip? row.data.use_ip : ""
                }
                else {
                    jsonObject["연속일 수"] = "-"
                    jsonObject["이달 연속일 수"] = "-"
                    jsonObject["이달 출석일 수"] = "-"
                    jsonObject["출석당시 충전액"] = "-"
                    jsonObject["당일 충전액"] = "-"
                    jsonObject["당일 환전액"] = "-"
                    jsonObject["출석 지급 포인트"] = "-"
                    jsonObject["수행아이디"] = "-"
                    jsonObject["수행일시"] = "-"
                    jsonObject["아이피"] = "-"
                }
                excelList.push(jsonObject)
            }

            const workSheet = XLSX.utils.json_to_sheet(excelList);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, '출석체크상세');
            XLSX.writeFile(workBook, `출석체크상세_${id}_${moment().format("YYYYMMDD")}.xlsx`);
        }
        else {
            alert("다운로드할 내용이 없습니다.")
        }
    }

    const forceAttend = (id, attend_date) => {
        axios.post('/admin/board/forceAttend', {
            attend_date: attend_date,
            id: id,
            reg_id: userInfoId,
        }).then((res) => {
            if(res.data.isInserted) {
                alert("관리자 강제 출석처리완료")
                onRefresh()
            }
            else {
                alert("관리자 강제 출석처리실패")
            }
        })
    }
    const forceDeleteAttend = (no) => {
        axios.post('/admin/board/forceDeleteAttend', {
            no: no,
            reg_id: userInfoId,
        }).then((res) => {
            if(res.data.isDeleted) {
                alert("관리자 강제 미출석처리완료")
                onRefresh()
            }
            else {
                alert("관리자 강제 미출석처리실패")
            }
        })
    }

    return <div className="sm-font">
        <table className="table table-bordered table-sm text-center align-middle">
            <thead>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>번호</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>연속 출석일 수<br/>(최대30일)</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>이달 마지막 연속 출석일 수</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>이달 총 출석일 수</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>상세</td>
            </tr>
            </thead>
            <tbody>
            {state?.checks && state.checks.map((item, index) => (
                <>
                    <tr>
                        <td>{item.no}</td>
                        <td style={{backgroundColor: item.back_ground_color, cursor: "pointer", color: item.font_color}} onClick={(e) => {
                            dispatch(openUserSimpleInfo({
                                isOpen: true,
                                openId: item.id,
                                xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                            }))
                        }}>{item.id}</td>
                        <td style={{backgroundColor: item.back_ground_color, cursor: "pointer", color: item.font_color}} onClick={(e) => {
                            dispatch(openUserSimpleInfo({
                                isOpen: true,
                                openId: item.id,
                                xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                            }))
                        }}>{item.nickname}</td>
                        <td>{item.consec_att_day}</td>
                        <td>{item.consec_att_day_month}</td>
                        <td>{item.att_day_total_month}</td>
                        <td><button data-bs-toggle="collapse" data-bs-target={`#colChk_${item.id}`} aria-expanded="false" aria-controls={`colChk_${item.id}`} style={{ backgroundColor: "#D7D4CF", border: "none", color: "black" ,fontSize: "9px", height: "20px", width: "fit-content"}} >상세보기</button></td>
                    </tr>
                    <tr className="collapse" id={`colChk_${item.id}`}><td colSpan="7">
                        <div className="card card-body">
                            <button style={{ marginRight: "10px", backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}} onClick={(e) => {
                                exportExcel(item.id, item.details)
                            }}>출석 상세 엑셀 다운로드</button>
                            <table className="table table-bordered table-sm text-center align-middle">
                                <thead>
                                <tr>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>수정</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>일자</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>연속일 수</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>이달 연속일 수</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>이달 출석일 수</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>출석당시 충전액</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>당일 충전액</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>당일 환전액</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>출석 지급 포인트</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>수행 아이디</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>수행일시</td>
                                    <td style={{ backgroundColor: "#1F68EE" ,border: "1px solid #D7D4CF", color: "white"}}>아이피</td>
                                </tr>
                                </thead>
                                <tbody>
                                {item.details.length > 0 && item.details.map((detail, index) => (
                                    <>
                                        {detail.isChecked? (
                                            <tr>
                                                <td>
                                                    <button style={{ marginRight: "1px", backgroundColor: "#D7D4CF", border: "none", color: "red" ,fontSize: "9px", height: "20px", width: "fit-content"}} onClick={(e) => {
                                                        forceDeleteAttend(detail.data.no)
                                                    }}>미출석 처리</button>
                                                </td>
                                                <td style={{backgroundColor: "lightyellow"}}>{moment(detail.date).format("YYYY-MM-DD")}</td>
                                                <td>{detail.data?.consec_att_day}</td>
                                                <td>{detail.data?.consec_att_day_month}</td>
                                                <td>{detail.data?.att_day_total_month}</td>
                                                <td><span style={{color: "#1F68EE"}}>{detail.data?.deposit_amt}</span></td>
                                                <td><span style={{color: "#1F68EE"}}>{detail.data?.standard_deposit_amt}</span></td>
                                                <td><span style={{color: "#1F68EE"}}>{detail.data?.standard_withdraw_amt * -1}</span></td>
                                                <td><span style={{color: "#1F68EE"}}>{detail.data?.result_point}</span></td>
                                                <td>{detail.data?.reg_id}</td>
                                                <td>{detail.data?.complete_dt? detail.data.complete_dt : detail.data.createdAt}</td>
                                                <td><span style={{ color: "#1F68EE" }}>{detail.data?.use_ip}</span></td>
                                            </tr>
                                        ) : (
                                            <tr style={{backgroundColor: "lightsalmon"}}>
                                                <td><button style={{ marginRight: "1px", backgroundColor: "#D7D4CF", border: "none", color: "black" ,fontSize: "9px", height: "20px", width: "fit-content"}} onClick={(e) => {
                                                    forceAttend(item.id, moment(detail.date).format("YYYY-MM-DD"))
                                                }}>출석 처리</button></td>
                                                <td style={{backgroundColor: "lightyellow"}}>{detail.date}</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                    </>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </td></tr>
                </>
            ))}

            </tbody>
        </table>
    </div>
}

export default CheckList