import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import Pagination from "../../../../components/common/Pagination2";
import {getLosingInfo,setStartDttm, setCreatedDttmGb,setEndDttm,setQuery,setPageSize,setOrgAdminId} from "../../../../redux/reducers/partner/LosingInfoReducer";
import {Route, useParams} from "react-router-dom";
import {getWithdrawInfo} from "../../../../redux/reducers/partner/WithdrawInfoReducer";
import {NumericFormat} from "react-number-format";
import {getOrgAuthListReducer} from "../../../../redux/reducers/partner/OrgAuthListReducer";
import {getRollingInfo} from "../../../../redux/reducers/partner/RollingInfoReducer";
import {insertAdminLog} from "../../../../lib/api";


const LosingInfo = (props) => {

    const dispatch = useDispatch()
    const {open, onClose} = props
    const initialState = useSelector((state) => state.losingInfo)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const param = useParams()

    const getList = () => {
        if(initialState.totalList !== null ) {
            dispatch(getLosingInfo({
                input : {
                    page: initialState.page,
                    pageSize: initialState.pageSize,
                    order: initialState.order,
                    startDttm: initialState.startDttm,
                    endDttm: initialState.endDttm,
                    orgAdminId: param.userId
                }
            }))
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "루징내역팝업"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }

    useEffect(getList, [dispatch])

    const inputQuery = (e) => {
        dispatch(setQuery(e.target.value))
    }
    const startDateHandler = (e) => {
        dispatch(setStartDttm(e.currentTarget.value))
    }
    const endDateHandler = (e) => {
        dispatch(setEndDttm(e.currentTarget.value))
    }
    const createdDttmGbHandler = (e) => {
        dispatch(setCreatedDttmGb(e.currentTarget.checked))
    }
    const changePageSize = (e) => {
        dispatch(setPageSize(parseInt(e.currentTarget.value)))
    }
    const onClickSearch = (e) => {
        //alert(JSON.stringify(selectedNos));
        dispatch(getLosingInfo({
            input : {
                page: initialState.page,
                pageSize: initialState.pageSize,
                startDttm: initialState.startDttm,
                endDttm: initialState.endDttm,
                query: initialState.query,
                createdDttmGb: initialState.createdDttmGb,
                orgAdminId: param.userId,
            }
        }))
    }

    const handlePageChange = (page) => {
        dispatch(getLosingInfo({
            input : {
                page: page,
                pageSize: initialState.pageSize,
                startDttm: initialState.startDttm,
                endDttm: initialState.endDttm,
                query: initialState.query,
                createdDttmGb: initialState.createdDttmGb,
                orgAdminId: param.userId,
            }
        }))
    }

    return (
        <div className="common" style={{width : "99%"}}>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            let userLvl = initialState.userLvl;
                            //alert("userLvl : "+userLvl);

                            if( userLvl === '01' )
                            {
                                window.location.href= "/partner/defaultInfo1/"+param.userId;
                            }else if( userLvl === '02' )
                            {
                                window.location.href= "/partner/defaultInfo2/"+param.userId;
                            }else if( userLvl === '03' )
                            {
                                window.location.href= "/partner/defaultInfo3/"+param.userId;
                            }

                        }}
                >기본정보</button>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/withdrawInfo/"+param.userId;
                        }}
                >출금</button>
                <button style={{ backgroundColor: "white",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                >루징내역</button>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/rollingInfo/"+param.userId;
                        }}
                >롤링내역</button>
                <button style={{     backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/memberInfo/"+param.userId;
                        }}
                >회원내역</button>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <input className="form-control sm-date" type="date" value={initialState.startDttm} onChange={startDateHandler} style={{width:"110px"}}/>
                    &nbsp;~&nbsp;
                    <input className="form-control sm-date" type="date" value={initialState.endDttm} onChange={endDateHandler} style={{width:"110px"}}/>
                    &nbsp;&nbsp;
                    <input value={initialState.query} onChange={inputQuery} className="form-check-input sm-input" type="text" name="insertNo" style={{width: "120px"}}
                           placeholder={"아이디/닉네임/예금주"}
                    />
                    &nbsp;&nbsp;
                    <input type="checkbox" checked={initialState.createdDttmGb} onChange={createdDttmGbHandler}></input>&nbsp;등록시간으로 검색
                    &nbsp;&nbsp;
                    <button style={{ backgroundColor: "lightskyblue",
                        color: "white", border: "none", fontSize: "9px", height: "20px", width: "70px", fontWeight: "bold", marginRight: "1px"}}
                            onClick={onClickSearch}>검색</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <font size={"2"}>입금</font> :  <NumericFormat value={initialState.sumData.deposit_amt} displayType={'text'} thousandSeparator={true}/> &nbsp;<font size={"2"}>출금</font> : <NumericFormat value={initialState.sumData.withdraw_amt} displayType={'text'} thousandSeparator={true}/>  &nbsp;<font size={"2"}>차액</font> : <NumericFormat value={initialState.sumData.dep_with_margin_amt} displayType={'text'} thousandSeparator={true}/> &nbsp;<font size={"2"} color={"red"}>루징금</font> <font color={"red"}> :  <NumericFormat value={initialState.sumData.dep_with_losing_amt} displayType={'text'} thousandSeparator={true}/> </font>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <font size={"2"}>배팅금</font> : <NumericFormat value={initialState.sumData.bet_money} displayType={'text'} thousandSeparator={true}/>  &nbsp;<font size={"2"}>당첨금</font> : <NumericFormat value={initialState.sumData.result_money} displayType={'text'} thousandSeparator={true}/>  &nbsp;<font size={"2"}>차액</font> : <NumericFormat value={initialState.sumData.bet_margin_amt} displayType={'text'} thousandSeparator={true}/>  &nbsp;<font size={"2"} color={"red"}>루징금</font> <font color={"red"}> : <NumericFormat value={initialState.sumData.bet_losing_amt} displayType={'text'} thousandSeparator={true}/> </font>
                </div>
                <div className="" style={{
                    display: "flex"
                }}>
                    <table>
                        <tr>
                            <td align={"right"}>
                                <select onChange={changePageSize}
                                    className="form-select sm-select"
                                    defaultValue={'01'}
                                    style={{
                                        width: "100px"
                                    }}
                                >
                                    <option value='25'>25건 출력</option>
                                    <option value='50'>50건 출력</option>
                                </select>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
                <table className="commonT table table-bordered table-sm text-center">
                    <thead >
                    <tr>
                        <th style={{backgroundColor: "lightgray",width: "3%"}}>번호</th>
                        <th style={{backgroundColor: "lightgray",width: "*%"}}>아이디</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>게임사</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>이용종목</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>배팅금</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>당첨금</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>배당루징(%)</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>입금</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>출금</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>입출루징(%)</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>루징금</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>이전루징금</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>이후루징금</th>
                        <th style={{backgroundColor: "lightgray",width: "5%"}}>종류</th>
                        <th style={{backgroundColor: "lightgray",width: "7%"}}>정산타입</th>
                        <th style={{backgroundColor: "lightgray",width: "10%"}}>배팅시간</th>
                        <th style={{backgroundColor: "lightgray",width: "10%"}}>등록시간</th>
                    </tr>
                    </thead>
                    {!initialState.totalList&& (
                        <tbody>
                        <tr>
                            <td colSpan="17">목록이 비어있습니다.</td>
                        </tr>
                        </tbody>
                    )}
                    {initialState.totalList&& initialState.gridList?.map((list, index) => (
                        <tbody key={list.no}>
                        <tr>
                            <td align={"center"}>{list.org_adjust_no}</td>
                            <td align={"center"}>{list.user_id}</td>
                            <td align={"center"}>{list.game_type_cd_nm}</td>
                            <td align={"center"}>{list.game_category_cd}</td>
                            <td align={"center"}>
                                <NumericFormat value={list.money} displayType={'text'} thousandSeparator={true}/>
                            </td>
                            <td align={"center"}>
                                <NumericFormat value={list.result_money} displayType={'text'} thousandSeparator={true}/>
                            </td>
                            <td align={"center"}>{list.losing_radio_per}</td>
                            <td align={"center"}><NumericFormat value={list.deposit_amt} displayType={'text'} thousandSeparator={true}/></td>
                            <td align={"center"}><NumericFormat value={list.withdraw_amt} displayType={'text'} thousandSeparator={true}/></td>
                            <td align={"center"}>{list.losing_deposit_widthraw_per}</td>
                            <td align={"center"}>
                                <NumericFormat value={list.losing_amt} displayType={'text'} thousandSeparator={true}/>
                            </td>
                            <td align={"center"}>
                                <NumericFormat value={list.beforeLosingAmt} displayType={'text'} thousandSeparator={true}/>
                            </td>
                            <td align={"center"}>
                                <NumericFormat value={list.afterLosingAmt} displayType={'text'} thousandSeparator={true}/>
                            </td>
                            <td align={"center"} style={{backgroundColor:"green"}}><font color={"white"}>{list.losing_type_cd_nm}</font> </td>
                            <td align={"center"}>{list.org_adj_gb_cd_nm}</td>
                            <td align={"center"}>{list.bet_dttm}</td>
                            <td align={"center"}>{list.createdAt}</td>
                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalCount && initialState.totalCount > 0 ?
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange} />
                    :""}
            </div>
        </div>


    )
}
export default LosingInfo;