import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    menuAuthList : [],
    totalList : [],
    totalCount : 0,
    gridList : [],
    gridCount : 0,
    input:{
        page: 1,
        pageSize: 20,
    },
    saveInput:{
        id : "",
        menuAuths : {
            info_auth_cd : ""
        }
    },
}

export const statsSlice = createSlice({
    name: 'OrgAuthListReducer',
    initialState,
    reducers: {
        getOrgAuthListReducer(state, action){
            console.log("#reducers getOrgAuthListReducer start#",
                action.payload.input.pageSize,
                action.payload.input.page)
            ;
            state.input.page = action.payload.input.pageSize === 999? 1: action.payload.input.page;
            state.input.pageSize = action.payload.input.pageSize;
        },
        setOrgAuthListReducer(state, action) {
            console.log("#reducers setOrgAuthListReducer start#",action.payload.menuAuthList);
            if((state.input.page -1) * state.input.pageSize > action.payload.totalCount) {
                state.input.page = 1
            }
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.gridCount = action.payload.gridCount;
            state.gridList = action.payload.gridList;
            state.menuAuthList = action.payload.menuAuthList;
            state.infoAuthList = action.payload.infoAuthList;
        },
        failedGetOrgAuthListReducer(state, action) {
            state.blackTypeCd = action.payload;
        },
        setLimitAuth(state,action){
            console.log("##setLimitAuth##",action.payload);
            const _index01 = action.payload.index01;
            const _gb = action.payload.gb;
            const _val = action.payload.val;

            if (!state.gridList[_index01].admin_limit_auths[0]) {
                state.gridList[_index01].admin_limit_auths[0] = {};
            }

            if(_gb ==='min_withdraw_amt'){
                state.gridList[_index01].admin_limit_auths[0].min_withdraw_amt = _val;
            }else{
                state.gridList[_index01].admin_limit_auths[0].min_unit_amt = _val;
            }
        },
        setMenuAuth(state, action){
            console.log("##setMenuAuth##",action.payload);
            console.log("## state.gridList01##", initialState.gridList);
            const _index01 = action.payload.index01;
            const _menu_auth_cd = action.payload.menu_auth_cd;
            const _checked = action.payload.checked;

            if(_checked){
                state.gridList[_index01].admin_menu_auths.push({
                    menu_auth_cd : _menu_auth_cd
                });
            }else{
                state.gridList[_index01].admin_menu_auths = state.gridList[_index01].admin_menu_auths.filter(a=>a.menu_auth_cd !== _menu_auth_cd)
            }
        },
        setInfoAuth(state, action){
            console.log("##setInfoAuth##",action.payload);
            console.log("## state.gridList01##", initialState.gridList);
            const _index01 = action.payload.index01;
            const _info_auth_cd = action.payload.info_auth_cd;
            const _checked = action.payload.checked;

            if(_checked){
                state.gridList[_index01].admin_info_auths.push({
                    info_auth_cd : _info_auth_cd
                });
            }else{
                state.gridList[_index01].admin_info_auths = state.gridList[_index01].admin_info_auths.filter(a=>a.info_auth_cd !== _info_auth_cd)
            }
        }
    }
});

export const {
    getOrgAuthListReducer,
    setOrgAuthListReducer,
    failedGetOrgAuthListReducer,
    setMenuAuth,
    setInfoAuth,
    setLimitAuth
} = statsSlice.actions;

export default statsSlice.reducer;