import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
const PointList = ({pointList, onRefresh}) => {
    const dispatch = useDispatch()


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead >
                        <tr>
                            <th style={{width: "2%"}}>순서</th>
                            <th style={{width: "6%"}}>부본사</th>
                            <th style={{width: "6%"}}>가맹점명</th>
                            <th style={{width: "6%"}}>아이디</th>
                            <th style={{width: "6%"}}>닉네임</th>
                            <th style={{width: "15%"}}>구분</th>
                            <th style={{width: "5%"}}>이전금액</th>
                            <th style={{width: "5%"}}>요청금액</th>
                            <th style={{width: "5%"}}>현재금액</th>
                            <th style={{width: "10%"}}>일시</th>
                            <th style={{width: "5%"}}>관련번호</th>
                            <th style={{width: "*%"}}>메모</th>
                            <th style={{width: "5%"}}>수행아이디</th>
                            <th style={{width: "7%"}}>수행아이피</th>
                        </tr>
                        </thead>
                        {!pointList.length && (
                            <tbody key={pointList.no}>
                            <tr>
                                <td colSpan="14">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {pointList?.map((pointList, index) => (
                            <tbody key={pointList.no}>
                            <tr>
                                <td align={"center"}>{pointList.no}</td>
                                <td align={"center"}>{pointList.psnl_cd}</td>
                                <td align={"center"}>{pointList.mrch_cd}</td>
                                <td align={"center"} style={{backgroundColor:pointList.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: pointList.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={pointList.font_color}>{pointList.id}</font>
                                </td>
                                <td align={"center"} style={{backgroundColor:pointList.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: pointList.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={pointList.font_color}>{pointList.nickname}</font>
                                </td>
                                <td align={"center"} ><font color="blue">{pointList.point_type_cd}</font></td>
                                <td align={"center"} >
                                    <font color="blue">
                                        <NumericFormat value={pointList.before_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"} >
                                    <font color="red">
                                        <NumericFormat value={pointList.request_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"} >
                                    <font color="blue">
                                        <NumericFormat value={pointList.now_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"}>{pointList.updatedAt}</td>
                                <td align={"center"}>{pointList.involvement_no}</td>
                                <td align={"center"} ><font color="green">{pointList.memo}</font></td>
                                <td align={"center"}>{pointList.reg_id}</td>
                                <td align={"center"} ><font color="blue">{pointList.reg_ip}</font></td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default PointList;