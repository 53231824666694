import React from 'react';
import RegSettingContainer from "../../containers/setting/RegSettingContainer";


const RegSetting = () => {
    return (
        <div>
            <RegSettingContainer />
        </div>
    );
};

export default RegSetting;