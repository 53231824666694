import {call, put, take,} from "redux-saga/effects";
import {fetchLeftAlerts} from "../../lib/api";
import {failedGetLeftAlerts, setLeftAlerts} from "../reducers/leftAlerts";

export function* getLeftAlertsSaga(action) {
    try {
        const res = yield call(fetchLeftAlerts, action.payload)
        yield put(setLeftAlerts(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetLeftAlerts(error))
    }
}
