module.exports = {
    CANCELLED: -1,
    NOT_SETTLED: 0,
    LOSER: 1,
    WINNER: 2,
    REFUND: 3,
    HALF_LOST: 4,
    HALF_WON: 5,
    getKorMessage: (settlement) => {
        switch(settlement) {
            case -1 : return "취소"
            case 0 : return "진행중"
            case 1 : return "미적중"
            case 2 : return "적중"
            case 3 : return "적특"
            case 4 : return "50%미적중"
            case 5 : return "50%적중"
            default : return "오류"
        }
    }
}