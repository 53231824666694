import React, {useState,useEffect} from "react";
import axios from "axios";
import {Link, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {NumericFormat} from "react-number-format";
import {getLeftAlerts} from "../../redux/reducers/leftAlerts";


const LeftComponent = () => {

    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo)
    const state = useSelector((state) => state.leftAlerts)

    useEffect(() => {

        dispatch(getLeftAlerts())
        setInterval(() => {
            dispatch(getLeftAlerts())
        },5000 )

    }, [])


    return (
        <div style={{
            position:"relative",
            width:"100%",
            background:"#3c3c3d",
            height:"100%",
            alignItems:"center",
            textAlign:"left",
        }}>

            <div>
                <table width={"99%"}>
                    <tr>
                        <td align={"left"}><font color={"lightskyblue"} size={1}>&nbsp;카지노 본사 보유</font></td>
                        <td align={"right"}>
                            <font color={"lightskyblue"} size={1}>
                                <NumericFormat value={state.leftAlerts?.casino_total_amt} displayType={'text'} thousandSeparator={true}/>
                            </font>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/deposit/Deposit_list/search02" >
                                <font color={"lightgreen"} size={1}>&nbsp;금일 입금</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/deposit/Deposit_list/search02" >
                                <font color={"lightgreen"} size={1}>
                                    <NumericFormat value={state.leftAlerts?state.leftAlerts.deposit_today_amt:0} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?state.leftAlerts.deposit_today_cnt:0} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>

                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/deposit/Withdraw_list/search02" >
                                <font color={"red"} size={1}>&nbsp;금일 출금</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/deposit/Withdraw_list/search02" >
                                <font color={"red"} size={1}>
                                    <NumericFormat value={state.leftAlerts?state.leftAlerts.withdraw_today_amt:0} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?state.leftAlerts.withdraw_today_cnt:0} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"orange"} size={1}>&nbsp;금일 마진</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"orange"} size={1}>
                                    <NumericFormat value={state.leftAlerts? (state.leftAlerts.deposit_today_amt - state.leftAlerts.withdraw_today_amt) : 0 } displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"white"} size={1}>&nbsp;금일 스포츠 배팅</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"white"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.sports_today_bet_amt} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?.sports_today_bet_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"lightskyblue"} size={1}>&nbsp;금일 스포츠 당첨</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"lightskyblue"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.sports_today_win_amt} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?.sports_today_win_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"yellow"} size={1}>&nbsp;금일 스포츠 대기</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"yellow"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.sports_today_wait_amt} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?.sports_today_wait_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/deposit/Money_list" >
                                <font color={"red"} size={1}>&nbsp;금일 스포츠 마진</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/deposit/Money_list" >
                                <font color={"red"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.sports_today_margin_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"white"} size={1}>&nbsp;금일 카지노 배팅</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"white"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.casino_today_bet_amt} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?.casino_today_bet_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"lightskyblue"} size={1}>&nbsp;금일 카지노 당첨</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"lightskyblue"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.casino_today_win_amt} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?.casino_today_win_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/deposit/Money_list" >
                                <font color={"red"} size={1}>&nbsp;금일 카지노 마진</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/deposit/Money_list" >
                                <font color={"red"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.casino_today_margin_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"white"} size={1}>&nbsp;금일 슬롯 배팅</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"white"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.slot_today_bet_amt} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?.slot_today_bet_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"lightskyblue"} size={1}>&nbsp;금일 슬롯 당첨</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"lightskyblue"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.slot_today_win_amt} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?.slot_today_win_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/deposit/Money_list" >
                                <font color={"red"} size={1}>&nbsp;금일 슬롯 마진</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/deposit/Money_list" >
                                <font color={"red"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.slot_today_margin_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"white"} size={1}>&nbsp;금일 미니게임 배팅</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"white"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.mini_today_bet_amt} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?.mini_today_bet_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"lightskyblue"} size={1}>&nbsp;금일 미니게임 당첨</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/betting/betting_list" >
                                <font color={"lightskyblue"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.mini_today_win_amt} displayType={'text'} thousandSeparator={true}/>
                                    &nbsp;/&nbsp;
                                    <NumericFormat value={state.leftAlerts?.mini_today_win_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/deposit/Money_list" >
                                <font color={"red"} size={1}>&nbsp;금일 미니게임 마진</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/deposit/Money_list" >
                                <font color={"red"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.mini_today_margin_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightskyblue"} size={1}>&nbsp;유저 본사 보유</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightskyblue"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.user_total_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightskyblue"} size={1}>&nbsp;유저 카지노 보유</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightskyblue"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.user_casino_total_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/deposit/Point_list" >
                                <font color={"lightskyblue"} size={1}>&nbsp;유저 포인트</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/deposit/Point_list" >
                                <font color={"lightskyblue"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.user_total_point} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}><font color={"lightgreen"} size={1}>&nbsp;유저 콤프</font></td>
                        <td align={"right"}><font color={"lightgreen"} size={1}>
                            <NumericFormat value={state.leftAlerts?.user_comp_total_amt} displayType={'text'} thousandSeparator={true}/>
                        </font></td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>&nbsp;금일 총판 루징</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.losing_today_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"yellow"} size={1}>&nbsp;금일 총판 롤링</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"yellow"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.rolling_today_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>&nbsp;월 마진</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.margin_month_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>&nbsp;월 스포츠 마진</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.sports_month_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>&nbsp;월 카지노 마진</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.casino_month_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>&nbsp;월 슬롯 마진</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.slot_month_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>&nbsp;월 미니게임 마진</font>
                            </Link>
                        </td>
                        <td align={"right"}>
                            <Link className="nav-link" to="/partner/Company_list" >
                                <font color={"lightgreen"} size={1}>
                                    <NumericFormat value={state.leftAlerts?.mini_month_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </Link>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    )
}

export default LeftComponent;