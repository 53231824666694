import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {getLvlSettingSingleReducer,setCmmnCdDtlNm} from "../../../redux/reducers/setting/LvlSettingSingleReducer";
import axios from "axios";

const LvlSettingComponent = (props) => {
    const {open, onClose} = props
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.lvlSettingSingle)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const getList = () => {
        if(initialState.gridList !== null ) {
            dispatch(getLvlSettingSingleReducer({input:{}}))
        }
        // //관리자활동 로그 추가
        // insertAdminLog({
        //     id: userInfoId,
        //     logContent: "도메인포워딩관리"
        // }).then((res) => {
        //     if(!res.data.isInserted) {
        //         alert("로그삽입실패 개발자문의")
        //     }
        // })
    }
    useEffect(getList, [dispatch])

    const handleCancel = () => {
        onClose()
    }

    const changeCmmnCdDtlNm = (idx,val) =>{
        console.log("##",idx,"##",val)
        dispatch(setCmmnCdDtlNm({idx : idx , val : val }))
    }

    const addRow = () =>{
        axios.post('/admin/setting/insertLvlSettingSingle').
        then((res) => {
            alert(res.data.message);
            getList();
        })
    }

    const destroyRow = (no) =>{
        axios.post('/admin/setting/deleteLvlSettingSingle',{no:no}).
        then((res) => {
            alert(res.data.message);
            getList();
        })
    }

    const updateUseYn = (no,useYn)=> {
        let _useYn;
        if(useYn==='Y'){
            _useYn ='N'
        }else{
            _useYn ='Y'
        }
        axios.post('/admin/setting/updateLvlSettingSingle',{
            no : no,
            useYn : _useYn
        }).
        then((res) => {
            alert(res.data.message);
            getList();
        })
    }

    const updateList = ()=> {

        axios.post('/admin/setting/updateLvlSettingSingle', initialState.gridList).
        then((res) => {
            alert(res.data.message);
            getList();
        })
    }
    return (
        <div className="partner_modal_background">
            <div className="partner_modal_box" >
                <div className="partner_modal_body">
                    <div className="common">
                        <div style={{
                            paddingLeft: "1px"
                        }}>

                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <font color={"blue"} size="3">레벨설정관리</font>
                            </div>
                            <br></br>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <table   width={"99%"}>
                                    <tr>
                                        <td align={"right"}>
                                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleCancel}>닫기</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <table width={"99%"}  >
                                    <tbody>
                                    <tr>
                                        <td align={"right"}>
                                            <button onClick={updateList} style={{
                                                width: "100px"
                                            }} type="submit" className="btn btn-primary" >저장하기</button>
                                            &nbsp;
                                            <button onClick={addRow} style={{
                                                width: "100px"
                                            }} type="submit" className="btn btn-primary" >추가하기</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br></br>
                            <div className="commonT container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>

                                <table className="commonT table table-bordered table-sm text-center align-middle">
                                    <thead>
                                    <tr>
                                        <th style={{width: "15%"}}>NO</th>
                                        <th style={{width: "*%"}}>레벨설정명</th>
                                        <th style={{width: "10%"}}>삭제</th>
                                    </tr>
                                    </thead>
                                    {initialState.gridList && initialState.gridList.length > 0 ? initialState.gridList.map((List, index) => (
                                            <tbody key={List.no}>
                                            <tr>
                                                <td align={"center"}>
                                                    {List.no}
                                                </td>
                                                <td align={"center"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input value={List.cmmnCdDtlNm} onChange={(e)=>changeCmmnCdDtlNm(index,e.target.value)} className="form-check-input sm-input" placeholder="" type="text" style={{ width: "100px" }}/>
                                                    </div>
                                                </td>
                                                <td align={"center"}>
                                                    <button onClick={()=>destroyRow(List.no)} style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "24px", width: "fit-content", marginRight: "5px"}}>삭제</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        )) :
                                        <tbody>
                                        <tr>
                                            <td colSpan= "8">조회된 데이터가 없습니다.</td>
                                        </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default LvlSettingComponent;