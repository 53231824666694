import React from 'react';
import LvlSettingContainer from "../../containers/setting/LvlSettingContainer";


const LvlSetting = () => {
    return (
        <div>
            <LvlSettingContainer />
        </div>
    );
};

export default LvlSetting;