import {createSlice} from "@reduxjs/toolkit";


export const leftAlertsSlice = createSlice({
    name: "leftAlerts",
    initialState: {
        isLoading: false,
        error: null,
        leftAlerts: null,
    },
    reducers: {
        getLeftAlerts(state, action) {
            state.isLoading = true;
        },
        setLeftAlerts(state, action) {
            state.isLoading = false;
            state.leftAlerts = action.payload.result
        },
        failedGetLeftAlerts(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
    }
});

export const {getLeftAlerts, setLeftAlerts, failedGetLeftAlerts} = leftAlertsSlice.actions

export default leftAlertsSlice.reducer;
