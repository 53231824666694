import {call, put, take,} from "redux-saga/effects";

import {
    failedGetWaitFixture,
    failedGetWaitFixtureDetail,
    getWaitFixtureDetail,
    setCurrentFixture,
    setWaitFixture
} from "../../../reducers/game/manage/parsingWait";
import {fetchFixtureDetail, fetchWaitFixture} from "../../../../lib/api";

export function* getWaitFixtureSaga(action) {
    try {
        const res = yield call(
            fetchWaitFixture,
            action.payload,
        );
        yield put(setWaitFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getWaitFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetWaitFixture(error));
    }
}

export function* getWaitFixtureDetailSaga(action) {
    try{
        const res = yield call(
            fetchFixtureDetail,
            action.payload,
            0,
        )
        yield put(setCurrentFixture(res.data))
    } catch(error) {
        console.log(error)
        yield put(failedGetWaitFixtureDetail(error))
    }
}


