import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    isOpen: false,
    lotteryListCount:0,
    lotteryList: [],
    totalListCount:0,
    totalList: [],
    sum: {},
    
    input: {
        page: 1,
        pageSize: 20,
        query: "",
    },
}

export const statsSlice = createSlice({
    name: 'LotteryReducer',
    initialState,
    reducers: {
        getLotteryReducer(state, action) {
            console.log("#reducers getLotteryList start#",action.payload.input.pageSize,action.payload.input.page,action.payload.input.query);
            state.input.page = action.payload.input.pageSize === 999? 1: action.payload.input.page;
            state.input.pageSize = action.payload.input.pageSize;
            state.input.query = action.payload.input.query

        },
        setLotteryReducer(state, action) {
            console.log("#reducers setLotteryList start#",action.payload.sum,action.payload.lotteryList);
            if((state.input.page -1) * state.input.pageSize > action.payload.totalCount) {
                state.input.page = 1
            }
            state.sum = action.payload.sum
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.lotteryList = action.payload.lotteryList;
            state.lotteryListCount = action.payload.lotteryListCount;
        },
        failedGetLotteryReducer(state, action){
            console.log("#reducers failedGetLotteryReducer start#");
        },
    }
});

export const {
    getLotteryReducer,
    setLotteryReducer,
    failedGetLotteryReducer,
} = statsSlice.actions;

export default statsSlice.reducer;