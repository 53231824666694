module.exports = {
    getKorProvider: (eng) => {
        switch(eng) {
            case "NEXTGAME" : return "넥스트게임"
            case "TOKEN" : return "토큰"
            default : return "오류"
        }
    },
    getKorSport: (eng) => {
        switch(eng) {
            case "LADDER" : return "사다리"
            case "POWERBALL" : return "파워볼"
            case "BACCARAT" : return "바카라"
            default : return "오류"
        }
    },
    getKorMarket: (eng) => {
        switch(eng) {
            case "ODDEVEN" : return "홀/짝"
            case "START" : return "좌/우"
            case "ROWS" : return "출발 3/4줄"
            case "WINNER" : return "플레이어,타이,뱅커"
            case "PAIR" : return "페어"
            case "POWERBALL_OE" : return "파워볼 홀짝"
            case "POWERBALL_UO" : return "파워볼 언더오버"
            case "DEF_BALL_OE" : return "일반볼합 홀짝"
            case "DEF_BALL_UO" : return "일반볼합 언더오버"
            default : return "오류"
        }
    },
}