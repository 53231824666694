import {call, put, take,} from "redux-saga/effects";
import {
    failedGetResultFixture,
    failedGetResultFixtureDetail,
    getResultFixtureDetail,
    setCurrentFixture,
    setResultFixture
} from "../../../reducers/game/manage/resultMatch";
import {fetchFixtureDetail, fetchResultFixture} from "../../../../lib/api";

export function* getResultFixtureSaga(action) {
    try {
        const res = yield call(fetchResultFixture,
            action.payload
        );
        yield put(setResultFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getResultFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetResultFixture(error));
    }
}

export function* getResultFixtureDetailSaga(action) {
    try {
        const res = yield call(fetchFixtureDetail, action.payload, 4)
        yield put(setCurrentFixture(res.data))
    } catch(error) {
        console.log(error)
        yield put(failedGetResultFixtureDetail(error));
    }
}


