import React from 'react';
import RegBankSettingContainer from "../../containers/setting/RegBankSettingContainer";


const RegBankSetting = () => {
    return (
        <div>
            <RegBankSettingContainer />
        </div>
    );
};

export default RegBankSetting;