import React from 'react';
import RouletteSettingContainer from "../../containers/setting/RouletteSettingContainer";


const rouletteSetting = () => {
    return (
        <div>
            <RouletteSettingContainer />
        </div>
    );
};

export default rouletteSetting;