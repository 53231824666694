import React from "react";
import LoginFailContainer from "../../containers/monitoring/LoginFailContainer";
const LoginFail = () => {
    return (
        <div>
            <font color={"blue"} size="3">로그인실패</font>&nbsp;
            <LoginFailContainer />
        </div>

    )
}
export default LoginFail;