import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const accountSlice = createSlice({
    name: 'account',
    initialState: {
        isLoading: false,
        error: null,
        accounts: [],
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        page: 1,
        pageSize: 100,
        totalCount: 0,
        searchGb: "bankAll",
        searchQuery: "",
        successYN: "All",
        depositYN: "All",
        hasDepositYN: "All",
        depositType: "All",
        pGrade: "",
    },
    reducers: {
        getAccounts(state, action) {
            state.isLoading = true;
            state.startDate = action.payload.startDate !== undefined? action.payload.startDate : moment().format('YYYY-MM-DD');
            state.endDate = action.payload.endDate !== undefined?  action.payload.endDate : moment().add(1, "day").format('YYYY-MM-DD');
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.searchGb = action.payload.searchGb;
            state.searchQuery = action.payload.searchQuery;
            state.successYN = action.payload.successYN;
            state.depositYN = action.payload.depositYN;
            state.hasDepositYN = action.payload.hasDepositYN;
            state.depositType = action.payload.depositType;
            state.pGrade = action.payload.pGrade;
        },
        failedGetAccounts(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setAccounts(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.accounts = action.payload.result;
        },
    }
});

export const { getAccounts, failedGetAccounts, setAccounts } = accountSlice.actions;

export default accountSlice.reducer;