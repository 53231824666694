import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination";
import {getDepositWithdrawCombine} from "../../redux/reducers/deposit/DepositWithdrawCombineReducer";
import DepositWithdrawCombine from "../../components/deposit/DepositWithdrawCombine";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import {NumericFormat} from "react-number-format";
import {getMemberUser} from "../../redux/reducers/user/MemberReducer";
import OrgSelect from "../../components/org/OrgSelect";
import {setSelectOrgId} from "../../redux/reducers/user/MessageReducer";
import {getWithdrawList} from "../../redux/reducers/deposit/WithdrawListReducer";
import {insertAdminLog} from "../../lib/api";

const DepositWithdrawCombineContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.depositWithdrawCombine)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [userGbCd, setUserGbCd] = useState("");
    const [searchCd01, setSearchCd01] = useState(); //총판/가맹점 검색

    const [date7, setDate7] = useState('#3fadf7')
    const [date1, setDate1] = useState('#3fadf7')
    const [date0, setDate0] = useState('red')


    const getCmmnCd = () => {
        if(changeFieldList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0028',
            }).then((res) => {
                setChangeFieldList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getDepositWithdrawCombine({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
        }))

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "충/환전 통합내역"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getDepositWithdrawCombine({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getDepositWithdrawCombine({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getDepositWithdrawCombine({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getDepositWithdrawCombine({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }


    const exportExcel = ()=>{

        //alert('initialState.totalList <>>>> '+initialState.totalList);

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {


            let jsonObject = { // JSON 객체를 생성합니다.
                no: initialState.totalList[i].no? initialState.totalList[i].no :'',
                소속: initialState.totalList[i].store_cd? initialState.totalList[i].store_cd :'',
                아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                변경필드명: initialState.totalList[i].change_field? initialState.totalList[i].change_field :'',
                기존값: initialState.totalList[i].asis_value? initialState.totalList[i].asis_value :'',
                수정값: initialState.totalList[i].tobe_value? initialState.totalList[i].tobe_value :'',
                수정아이디: initialState.totalList[i].upd_id? initialState.totalList[i].upd_id :'',
                수정아이피: initialState.totalList[i].update_ip? initialState.totalList[i].update_ip :'',
                수정시각: initialState.totalList[i].updatedAt? initialState.totalList[i].updatedAt :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '회원정보 수정내역');
        XLSX.writeFile(workBook, '회원정보 수정내역.xlsx');
    }

    const userGbCdChangeHandler = (e) => {
        alert('userGbCd000 >>>>>>>>>>>> '+e.currentTarget.value);
        setUserGbCd(e.currentTarget.value);

        alert('userGbCd >>>>>>>>>>>> '+userGbCd);
        onRefresh();
    }


    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
        setDate0('#3fadf7')
        setDate1('#3fadf7')
        setDate7('#3fadf7')
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
        setDate0('#3fadf7')
        setDate1('#3fadf7')
        setDate7('#3fadf7')
    }

    const add7Date = () => {
        setStartDate(moment().add(-7, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate0('#3fadf7')
        setDate1('#3fadf7')
        setDate7('red')

        dispatch(getDepositWithdrawCombine({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-7, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }
    const add1Date = () => {
        setStartDate(moment().add(-1, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate0('#3fadf7')
        setDate1('red')
        setDate7('#3fadf7')

        dispatch(getDepositWithdrawCombine({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-1, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }
    const add0Date = () => {
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate0('red')
        setDate1('#3fadf7')
        setDate7('#3fadf7')

        dispatch(getDepositWithdrawCombine({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }

    const setParentParam = (id) =>{
        setSearchCd01(id)
        dispatch(setSelectOrgId(id))
        dispatch(getDepositWithdrawCombine({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: id
        }))
    }


    const exportAddExcel = ()=>{

        //alert('initialState.totalList <>>>> '+initialState.totalList);

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {
            let jsonObject = { // JSON 객체를 생성합니다.
                순서: initialState.totalList[i].no? initialState.totalList[i].no :'',
                구분: initialState.totalList[i].deposit_withdraw_state_cd? initialState.totalList[i].deposit_withdraw_state_cd :'',
                소속: initialState.totalList[i].store_cd? initialState.totalList[i].store_cd :'',
                아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                P레벨: initialState.totalList[i].pnt_gd_cd? initialState.totalList[i].pnt_gd_cd :'',
                충전구분: initialState.totalList[i].deposit_state_cd? initialState.totalList[i].deposit_state_cd :'',
                금액: initialState.totalList[i].deposit_withdraw_amt? "+"+initialState.totalList[i].deposit_withdraw_amt :'',
                지급포인트: initialState.totalList[i].result_amt? initialState.totalList[i].result_amt :'',
                상태: initialState.totalList[i].result_state_cd? initialState.totalList[i].result_state_cd :'',
                일시: initialState.totalList[i].createdAt? initialState.totalList[i].createdAt :'',
                아이피: initialState.totalList[i].reg_ip? initialState.totalList[i].reg_ip :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '충환전통합내역(플러스)');
        XLSX.writeFile(workBook, '충환전통합내역(플러스).xlsx');
    }

    const exportMinusExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {
            let jsonObject = { // JSON 객체를 생성합니다.
                순서: initialState.totalList[i].no? initialState.totalList[i].no :'',
                구분: initialState.totalList[i].deposit_withdraw_state_cd? initialState.totalList[i].deposit_withdraw_state_cd :'',
                소속: initialState.totalList[i].store_cd? initialState.totalList[i].store_cd :'',
                아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                P레벨: initialState.totalList[i].pnt_gd_cd? initialState.totalList[i].pnt_gd_cd :'',
                충전구분: initialState.totalList[i].deposit_state_cd? initialState.totalList[i].deposit_state_cd :'',
                금액: initialState.totalList[i].deposit_withdraw_amt? "-"+initialState.totalList[i].deposit_withdraw_amt :'',
                지급포인트: initialState.totalList[i].result_amt? initialState.totalList[i].result_amt :'',
                상태: initialState.totalList[i].result_state_cd? initialState.totalList[i].result_state_cd :'',
                일시: initialState.totalList[i].createdAt? initialState.totalList[i].createdAt :'',
                아이피: initialState.totalList[i].reg_ip? initialState.totalList[i].reg_ip :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '충환전통합내역(마이너스)');
        XLSX.writeFile(workBook, '충환전통합내역(마이너스).xlsx');

    }



    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <div className="commonNew">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">충/환전 통합내역</font>
                    <table   width={"92%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                                &nbsp;
                                <button style={{
                                    width: "120px", backgroundColor :"gray"
                                }} type="submit" className="btn btn-primary" onClick={onClickReset}>새로고침(자동보류)</button>
                            </td>
                        </tr>
                    </table>

                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray" , width:"1400px"
                    }}>
                        <div className="container-fluid p-0 mx-1" style={{
                            display: "flex",border:"1px solid lightgray", width:"320px", height:"23px"
                        }}>
                            <div style={{zIndex : 999}}>
                                <OrgSelect setChildParam = {setParentParam} id = {1}/>
                            </div>
                        </div>
                        <select
                            className="form-select sm-select"
                            defaultValue={""}
                            style={{
                                width: "100px"
                            }}
                            value={initialState.userGbCd === ""? "" : initialState.userGbCd}
                            onChange={e =>
                                dispatch(getDepositWithdrawCombine({
                                    page: initialState.page,
                                    pageSize: initialState.pageSize,
                                    order: initialState.order,
                                    query: searchQuery,
                                    startDate: startDate? startDate : initialState.startDate,
                                    endDate: endDate? endDate : initialState.endDate,
                                    userGbCd: e.target.value,
                                    searchGbCd: initialState.searchGbCd,
                                    searchCd01: initialState.searchCd01
                                }))
                            }
                        >
                            <option value={""}>::구분::</option>
                            <option value={"01"}>충전</option>
                            <option value={"02"}>환전</option>
                        </select>
                        &nbsp;
                        <select
                            className="form-select sm-select"
                            defaultValue={""}
                            style={{
                                width: "100px"
                            }}
                            value={initialState.searchGbCd === ""? "" : initialState.searchGbCd}
                            onChange={e =>
                                dispatch(getDepositWithdrawCombine({
                                    page: initialState.page,
                                    pageSize: initialState.pageSize,
                                    order: initialState.order,
                                    query: searchQuery,
                                    startDate: startDate? startDate : initialState.startDate,
                                    endDate: endDate? endDate : initialState.endDate,
                                    userGbCd: initialState.userGbCd,
                                    searchGbCd: e.target.value,
                                    searchCd01: initialState.searchCd01
                                }))
                            }
                        >
                            <option value={""}>::전체 P등급::</option>
                            <option value={"01"}>1-1 [1]</option>
                            <option value={"02"}>1-2 [1]</option>
                            <option value={"03"}>1-3 [1]</option>
                            <option value={"04"}>1-4 [1]</option>
                            <option value={"05"}>1-5 [1]</option>
                            <option value={"06"}>2 [2]</option>
                            <option value={"07"}>2-3 [2]</option>
                            <option value={"08"}>3 [3]</option>
                            <option value={"09"}>4 [4]</option>
                            <option value={"10"}>5 [5]</option>
                            <option value={"11"}>6 [6]</option>
                            <option value={"12"}>6-2 [1]</option>
                            <option value={"13"}>6-3 [1]</option>
                        </select>

                        <button style={{ backgroundColor: date7, border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add7Date}>일주일</button>
                        <button style={{ backgroundColor: date1, border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add1Date}>어제</button>
                        <button style={{ backgroundColor: date0, border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add0Date}>오늘</button>
                        <input className="form-control sm-date" type="date" value={startDate} onChange={startDateHandler} style={{width:"110px"}}/>
                        &nbsp;~&nbsp;
                        <input className="form-control sm-date" type="date" value={endDate} onChange={endDateHandler} style={{width:"110px"}}/>
                        &nbsp;
                        <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}} onClick={exportMinusExcel}>엑셀 다운로드(환전금액 마니어스 숫자로 받기)</button>
                        <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}} onClick={exportAddExcel}>엑셀 다운로드(환전금액 플러스 숫자로 받기)</button>
                    </div>
                </div>

            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table   width={"99%"}>
                        <tr>
                            <td align={"right"}>
                                <font color="blue">
                                    [충전 :<NumericFormat className="ms-1" value={initialState.countList.deposit_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.deposit_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                                &nbsp;&nbsp;
                                <font color="red">[환전 :<NumericFormat className="ms-1" value={initialState.countList.withdraw_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.withdraw_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                                &nbsp;&nbsp;
                                [마진 : <font color="blue"><NumericFormat className="ms-1" value={initialState.countList.profit_amt} displayType={'text'} thousandSeparator={true}/></font>원)]
                                &nbsp;&nbsp;
                                <font color="red">[지급포인트 :<NumericFormat className="ms-1" value={initialState.countList.point_amt} displayType={'text'} thousandSeparator={true}/>원]</font>
                                &nbsp;&nbsp;
                                <font color="blue">[키지노 충전 :<NumericFormat className="ms-1" value={initialState.countList.casino_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.casino_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
        </div>

        <DepositWithdrawCombine depositWithdrawCombine={initialState.depositWithdrawCombine} onRefresh={onRefresh} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default DepositWithdrawCombineContainer