import React, {useEffect, useState} from "react";
import axios from "axios";
import Switch from "react-switch";

const GameMarketSetting = () => {
    const [marketByGames, setMarketByGames] = useState([])
    const [provider, setProvider] = useState(0)
    const [sports, setSports] = useState([])
    const [sportId, setSportId] = useState(0)
    const [searchQuery, setSearchQuery] = useState("")

    useEffect(() => {
        getMarketByGames(provider)
    }, [sportId])

    const getMarketByGames = (providerNum) => {
        axios.post('/admin/game/setting/getMarketByGames', {
            sportId: sportId,
            providerNum: providerNum,
            searchQuery: searchQuery,
        }).then((res) => {
            setMarketByGames(res.data.result)
            setSports(res.data.sports)
        })
    }

    const changeMarketByGame = (item) => {
        axios.post('/admin/game/setting/changeMarketByGame', item)
            .then((res) => {
                if(res.data.isChanged) {
                    alert("변경완료")
                    getMarketByGames(provider)
                }
                else {
                    alert("변경실패")
                }
            })
    }

    return <div className="common">
        <font color={"blue"} size="3">종목별 마켓 상세설정</font>
        <div className="btn-group">
            <button type="button" className={`btn btn-outline-secondary ${provider === 0? 'active': ''}`} onClick={(e) => {
                setProvider(0)
                getMarketByGames(0)
            }}>프리매치-Bet365</button>
            <button type="button" className={`btn btn-outline-secondary ${provider === 1? 'active': ''}`}  onClick={(e) => {
                setProvider(1)
                getMarketByGames(1)
            }}>프리매치-1XBet</button>
            <button type="button" className={`btn btn-outline-secondary ${provider === 2? 'active': ''}`} onClick={(e) => {
                setProvider(2)
                getMarketByGames(2)
            }}>프리매치-피나클</button>
            <button type="button" className={`btn btn-outline-secondary ${provider === 3? 'active': ''}`} onClick={(e) => {
                setProvider(3)
                getMarketByGames(3)
            }}>프리매치-마라톤</button>
            <button type="button" className={`btn btn-outline-secondary ${provider === 4? 'active': ''}`} onClick={(e) => {
                setProvider(4)
                getMarketByGames(4)
            }}>라이브-Bet365</button>
            <button type="button" className={`btn btn-outline-secondary ${provider === 5? 'active': ''}`} onClick={(e) => {
                setProvider(5)
                getMarketByGames(5)
            }}>라이브-1XBet</button>
            <button type="button" className={`btn btn-outline-secondary ${provider === 6? 'active': ''}`} onClick={(e) => {
                setProvider(6)
                getMarketByGames(6)
            }}>라이브-피나클</button>
            <button type="button" className={`btn btn-outline-secondary ${provider === 7? 'active': ''}`} onClick={(e) => {
                setProvider(7)
                getMarketByGames(7)
            }}>라이브-마라톤</button>
        </div>
        <div className="d-flex w-100">
            <select className="form-select" style={{width: "120px"}} onChange={(e) => {
                setSportId(parseInt(e.currentTarget.value))
            }}>
                <option value={0}>스포츠전체</option>
                {sports.map((item) => (
                    <option value={item.id}>{item.name}</option>
                ))}
            </select>
            <input style={{
                width: "100px"
            }} type="text" value={searchQuery} className="form-control" id="search_edit" placeholder="마켓명, 마켓아이디, 파싱명" onChange={(e) => {
                setSearchQuery(e.currentTarget.value)
            }} onKeyDown={(e) => {
                if(e.key === "Enter") {
                    getMarketByGames(provider)
                }
            }}/>
            <button style={{
                width: "80px", borderTopRightRadius: "5px" ,borderBottomRightRadius: "5px"
            }} type="submit" className="btn btn-primary" onClick={(e) => {
                getMarketByGames(provider)
            }}>검색</button>

        </div>

        <div className="p-1 w-100" >
            <div className="d-flex" style={{marginLeft:"auto"}}>
                <label>일괄 배당 증차감</label>
                <input className="form-control form-control-sm me-1" type="number" style={{width: "100px"}}/>
                <button className="btn btn-secondary">적용</button>
            </div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark sticky-top">
                <tr>
                    <th>종목</th>
                    <th>마켓명(파싱명)</th>
                    <th>국내형 언/오 제한</th>
                    <th>국내형 핸디캡 제한</th>
                    <th>해외형 언/오 제한</th>
                    <th>해외형 핸디캡 제한</th>
                    <th>스페셜 언/오 제한</th>
                    <th>스페셜 핸디캡 제한</th>
                    <th style={{width: "80px"}}>노출최소배당</th>
                    <th style={{width: "80px"}}>노출최대배당</th>
                    <th style={{width: "150px"}}>배당증감 정/무/역</th>
                    <th style={{width: "80px"}}>합배당 (작을시 그대로적용)</th>
                    {/*<th>배당증차감 홈,무,원</th>*/}
                    <th style={{width: "50px"}}>순서</th>
                    <th style={{width: "80px"}}>기준점</th>
                    {provider > 3? (
                        <th>인플레이현시여부</th>
                    ) : (
                        <>
                            <th>국내형현시여부</th>
                            <th>해외형현시여부</th>
                            <th>스페셜현시여부</th>
                        </>
                    )}
                    <th>단폴배팅불가능</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {marketByGames?.map((item, index) => (
                    <tr>
                        <td>{item.game_kind?.name}</td>
                        <td>{item.name}({item.market?.id})<br/>{item.market?.name}</td>
                        <td>
                            <select value={item.ou_limit_type}
                                    onChange={(e) => {
                                        const newItem = {...item}
                                        newItem.ou_limit_type = e.currentTarget.value
                                        const arr = [...marketByGames]
                                        arr[index] = newItem
                                        setMarketByGames(arr)
                                    }}>
                                <option value={0}>배당차액</option>
                                <option value={1}>최대기준점</option>
                                <option value={2}>최소기준점</option>
                                <option value={3}>특정기준점</option>
                                <option value={4}>기준점간격</option>
                            </select>
                            <input type="number" style={{ width: '100%' }} value={item.ou_limit}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.ou_limit = e.currentTarget.value
                                       const arr = [...marketByGames]
                                       arr[index] = newItem
                                       setMarketByGames(arr)
                                   }}/>
                        </td>
                        <td>
                            <select value={item.handicap_limit_type}
                                    onChange={(e) => {
                                        const newItem = {...item}
                                        newItem.handicap_limit_type = e.currentTarget.value
                                        const arr = [...marketByGames]
                                        arr[index] = newItem
                                        setMarketByGames(arr)
                                    }}>
                                <option value={0}>배당차액</option>
                                <option value={1}>최대기준점</option>
                                <option value={2}>최소기준점</option>
                                <option value={3}>특정기준점</option>
                                <option value={4}>기준점간격</option>
                            </select>
                            <input type="number" style={{ width: '100%' }} value={item.handicap_limit}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.handicap_limit = e.currentTarget.value
                                       const arr = [...marketByGames]
                                       arr[index] = newItem
                                       setMarketByGames(arr)
                                   }}/>
                        </td>
                        <td>
                            <select value={item.foreign_ou_limit_type}
                                    onChange={(e) => {
                                        const newItem = {...item}
                                        newItem.foreign_ou_limit_type = e.currentTarget.value
                                        const arr = [...marketByGames]
                                        arr[index] = newItem
                                        setMarketByGames(arr)
                                    }}>
                                <option value={0}>배당차액</option>
                                <option value={1}>최대기준점</option>
                                <option value={2}>최소기준점</option>
                                <option value={3}>특정기준점</option>
                                <option value={4}>기준점간격</option>
                            </select>
                            <input type="number" style={{ width: '100%' }} value={item.foreign_ou_limit}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.foreign_ou_limit = e.currentTarget.value
                                       const arr = [...marketByGames]
                                       arr[index] = newItem
                                       setMarketByGames(arr)
                                   }}/>
                        </td>
                        <td>
                            <select value={item.foreign_handicap_limit_type}
                                    onChange={(e) => {
                                        const newItem = {...item}
                                        newItem.foreign_handicap_limit_type = e.currentTarget.value
                                        const arr = [...marketByGames]
                                        arr[index] = newItem
                                        setMarketByGames(arr)
                                    }}>
                                <option value={0}>배당차액</option>
                                <option value={1}>최대기준점</option>
                                <option value={2}>최소기준점</option>
                                <option value={3}>특정기준점</option>
                                <option value={4}>기준점간격</option>
                            </select>
                            <input type="number" style={{ width: '100%' }} value={item.foreign_handicap_limit}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.foreign_handicap_limit = e.currentTarget.value
                                       const arr = [...marketByGames]
                                       arr[index] = newItem
                                       setMarketByGames(arr)
                                   }}/>
                        </td>
                        <td>
                            <select value={item.special_ou_limit_type}
                                    onChange={(e) => {
                                        const newItem = {...item}
                                        newItem.special_ou_limit_type = e.currentTarget.value
                                        const arr = [...marketByGames]
                                        arr[index] = newItem
                                        setMarketByGames(arr)
                                    }}>
                                <option value={0}>배당차액</option>
                                <option value={1}>최대기준점</option>
                                <option value={2}>최소기준점</option>
                                <option value={3}>특정기준점</option>
                                <option value={4}>기준점간격</option>
                            </select>
                            <input type="number" style={{ width: '100%' }} value={item.special_ou_limit}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.special_ou_limit = e.currentTarget.value
                                       const arr = [...marketByGames]
                                       arr[index] = newItem
                                       setMarketByGames(arr)
                                   }}/>
                        </td>
                        <td>
                            <select value={item.special_handicap_limit_type}
                                    onChange={(e) => {
                                        const newItem = {...item}
                                        newItem.special_handicap_limit_type = e.currentTarget.value
                                        const arr = [...marketByGames]
                                        arr[index] = newItem
                                        setMarketByGames(arr)
                                    }}>
                                <option value={0}>배당차액</option>
                                <option value={1}>최대기준점</option>
                                <option value={2}>최소기준점</option>
                                <option value={3}>특정기준점</option>
                                <option value={4}>기준점간격</option>
                            </select>
                            <input type="number" style={{ width: '100%' }} value={item.special_handicap_limit}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.special_handicap_limit = e.currentTarget.value
                                       const arr = [...marketByGames]
                                       arr[index] = newItem
                                       setMarketByGames(arr)
                                   }}/>
                        </td>
                        <td>
                            <input className="sm-input" type="number" style={{ width: '50px' }} value={item.min}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.min = e.currentTarget.value
                                       const arr = [...marketByGames]
                                       arr[index] = newItem
                                       setMarketByGames(arr)
                                   }}
                            />
                        </td>
                        <td>
                            <input className="sm-input" type="number" style={{ width: '50px' }} value={item.max}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.max = e.currentTarget.value
                                       const arr = [...marketByGames]
                                       arr[index] = newItem
                                       setMarketByGames(arr)
                                   }}
                            />
                        </td>
                        <td>
                            <div className="d-flex">
                                <input className="sm-input" type="number" style={{ width: '50px' }}  value={item.plus_minus1}
                                       onChange={(e) => {
                                           const newItem = {...item}
                                           newItem.plus_minus1 = e.currentTarget.value
                                           const arr = [...marketByGames]
                                           arr[index] = newItem
                                           setMarketByGames(arr)
                                       }}
                                />
                                <input className="sm-input" type="number" style={{ width: '50px' }}  value={item.plus_minusX}
                                       onChange={(e) => {
                                           const newItem = {...item}
                                           newItem.plus_minusX = e.currentTarget.value
                                           const arr = [...marketByGames]
                                           arr[index] = newItem
                                           setMarketByGames(arr)
                                       }}
                                />
                                <input className="sm-input" type="number" style={{ width: '50px' }}  value={item.plus_minus2}
                                       onChange={(e) => {
                                           const newItem = {...item}
                                           newItem.plus_minus2 = e.currentTarget.value
                                           const arr = [...marketByGames]
                                           arr[index] = newItem
                                           setMarketByGames(arr)
                                       }}
                                />
                            </div>
                        </td>
                        <td>
                            <div className="d-flex">
                                <input className="sm-input" type="number" style={{ width: '50px' }}  value={item.total_limit}
                                       onChange={(e) => {
                                           const newItem = {...item}
                                           newItem.total_limit = e.currentTarget.value
                                           const arr = [...marketByGames]
                                           arr[index] = newItem
                                           setMarketByGames(arr)
                                       }}
                                />
                                <Switch onChange={(checked) => {
                                    const newItem = {...item}
                                    newItem.use_sum_origin = checked
                                    const arr = [...marketByGames]
                                    arr[index] = newItem
                                    setMarketByGames(arr)
                                }} checked={item.use_sum_origin} onColor="#2196F3"/>
                            </div>

                        </td>
                        {/*<td>
                                <div className="d-flex">
                                    <input className="form-control-sm" type="number" style={{ width: '100%' }} value={item.plus_minus1}
                                           onChange={(e) => {
                                               const newItem = {...item}
                                               newItem.plus_minus1 = e.currentTarget.value
                                               const arr = [...marketByGames]
                                               arr[index] = newItem
                                               setMarketByGames(arr)
                                           }}
                                    />
                                    <input className="form-control-sm" type="number" style={{ width: '100%' }} value={item.plus_minusX}
                                           onChange={(e) => {
                                               const newItem = {...item}
                                               newItem.plus_minusX = e.currentTarget.value
                                               const arr = [...marketByGames]
                                               arr[index] = newItem
                                               setMarketByGames(arr)
                                           }}
                                    />
                                    <input className="form-control-sm" type="number" style={{ width: '100%' }} value={item.plus_minus2}
                                           onChange={(e) => {
                                               const newItem = {...item}
                                               newItem.plus_minus2 = e.currentTarget.value
                                               const arr = [...marketByGames]
                                               arr[index] = newItem
                                               setMarketByGames(arr)
                                           }}
                                    />
                                </div>
                            </td>*/}
                        <td>
                            <select className="sm-select" style={{width: "50px"}} value={item.print_rank} onChange={(e)=> {
                                const newItem = {...item}
                                newItem.print_rank = e.currentTarget.value
                                const arr = [...marketByGames]
                                arr[index] = newItem
                                setMarketByGames(arr)
                            }}>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                            </select>
                        </td>
                        <td>
                            <select className="sm-select" style={{width: "100px"}} defaultValue={null} value={item.baseline} onChange={(e) => {
                                const newItem = {...item}
                                newItem.baseline = e.currentTarget.value === "종목별 설정사용"? null : e.currentTarget.value
                                const arr = [...marketByGames]
                                arr[index] = newItem
                                setMarketByGames(arr)
                            }}>
                                <option value={null}>종목별 설정사용</option>
                                <option value={0}>모두사용</option>
                                <option value={1}>0만사용</option>
                                <option value={2}>.5만사용</option>
                            </select>
                        </td>
                        {provider > 3? (
                            <td>
                                <Switch onChange={(checked) => {
                                    const newItem = {...item}
                                    newItem.is_apply_inplay = checked
                                    const arr = [...marketByGames]
                                    arr[index] = newItem
                                    setMarketByGames(arr)
                                }} checked={item.is_apply_inplay} onColor="#2196F3"/>
                            </td>
                        ) : (
                            <>
                                <td>
                                    <Switch onChange={(checked) => {
                                        const newItem = {...item}
                                        newItem.is_apply_domestic = checked
                                        const arr = [...marketByGames]
                                        arr[index] = newItem
                                        setMarketByGames(arr)
                                    }} checked={item.is_apply_domestic} onColor="#2196F3"/>
                                </td>
                                <td>
                                    <Switch onChange={(checked) => {
                                        const newItem = {...item}
                                        newItem.is_apply_foreign = checked
                                        const arr = [...marketByGames]
                                        arr[index] = newItem
                                        setMarketByGames(arr)
                                    }} checked={item.is_apply_foreign} onColor="#2196F3"/>
                                </td>
                                <td>
                                    <Switch onChange={(checked) => {
                                        const newItem = {...item}
                                        newItem.is_apply_special = checked
                                        const arr = [...marketByGames]
                                        arr[index] = newItem
                                        setMarketByGames(arr)
                                    }} checked={item.is_apply_special} onColor="#2196F3"/>
                                </td>
                            </>
                        )}

                        <td>
                            <Switch onChange={(checked) => {
                                const newItem = {...item}
                                newItem.is_possible_one = checked
                                const arr = [...marketByGames]
                                arr[index] = newItem
                                setMarketByGames(arr)
                            }} checked={item.is_possible_one} onColor="#2196F3"/>
                        </td>
                        <td>
                            <button className="btn btn-sm btn-primary" onClick={(e) => {
                                changeMarketByGame(item)
                            }}>변경</button>
                        </td>
                    </tr>
                ))}

                </tbody>
            </table>

        </div >
    </div>
}
export default GameMarketSetting