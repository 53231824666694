import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import LoginFailList from "../../components/monitoring/LoginFailList";
import {getLoginFails} from "../../redux/reducers/monitoring/LoginFailReducer";
import moment from "moment/moment";
import {getActiveMembers} from "../../redux/reducers/monitoring/ActiveMemberReducer";
import Pagination from "../../components/common/Pagination2";
import {getAccounts} from "../../redux/reducers/monitoring/AccountReducer";


const LoginFailContainer = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.loginFail);

    const [searchQuery, setSearchQuery] = useState("");
    const [searchGb, setSearchGb] = useState("id");


    const input = {
        startDate: state.startDate,
        endDate: state.endDate,
        loginType: state.loginType,
        searchGb: state.searchGb,
        searchQuery: state.searchQuery,
        page: state.page,
        pageSize: state.pageSize,
    }

    useEffect(() => {
        dispatch(getLoginFails(input))
    }, [])

    const onRefresh = () => {
        dispatch(getLoginFails(input))
    }

    const onTypeChange = (e) => {
        const fixInput = {...input}
        fixInput.loginType = parseInt(e.target.value)
        dispatch(getLoginFails(fixInput))
    }

    const changeSearchGb = (e) => {
        setSearchGb(e.target.value)
    }

    const changeQuery = (e) => {
        setSearchQuery(e.target.value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }

    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.searchGb = searchGb
        fixInput.searchQuery = query
        dispatch(getLoginFails(fixInput))
    }

    const handlePageChange = (page) => {
        const fixInput = {...input}
        fixInput.searchGb = searchGb
        fixInput.searchQuery = searchQuery
        fixInput.page = page
        dispatch(getLoginFails(fixInput))
    }

    return <div className="common">
        <div className="sm-center-div-no-border-100">
            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={(e) => {
                const fixInput = {...input}
                fixInput.startDate = moment().add(-6, "days").format('YYYY-MM-DD')
                fixInput.endDate = moment().format('YYYY-MM-DD')
                dispatch(getLoginFails(fixInput))
            }}>일주일</button>
            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={(e) => {
                const fixInput = {...input}
                fixInput.startDate = moment().add(-1, "day").format('YYYY-MM-DD')
                fixInput.endDate = moment().add(-1, "day").format('YYYY-MM-DD')
                dispatch(getLoginFails(fixInput))
            }}>어제</button>
            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}} onClick={(e) => {
                const fixInput = {...input}
                fixInput.startDate = moment().format('YYYY-MM-DD')
                fixInput.endDate = moment().format('YYYY-MM-DD')
                dispatch(getLoginFails(fixInput))
            }}>오늘</button>
            <div style={{display: "flex", paddingLeft: "5px", paddingRight: "5px", alignItems: "center"}}>
                <span className="mx-1">◀</span>
                <input className="form-control sm-date" type="date" value={state.startDate} onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.startDate = e.currentTarget.value
                    dispatch(getLoginFails(fixInput))
                }}/>
                <span className="mx-1">~</span>
                <input className="form-control sm-date" type="date" value={state.endDate} onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.endDate = e.currentTarget.value
                    dispatch(getLoginFails(fixInput))
                }}/>
                <span className="mx-1">▶</span>
            </div>
            <div className="sm-center-div">
                <select className="form-select sm-select" value={state.loginType} onChange={onTypeChange}>
                    <option value={-1}>전체</option>
                    <option value={0}>관리자</option>
                    <option value={2}>일반유저</option>
                </select>
            </div>
            <div className="sm-center-div">
                <select className="form-select sm-select" value={searchGb} onChange={changeSearchGb}>
                    <option value={"id"}>아이디</option>
                    <option value={"login_ip"}>아이피</option>
                </select>
                <input className="form-control sm-input" type="text" value={searchQuery} onChange={changeQuery} onKeyDown={inputPress}/>
                <button onClick={(e) => {
                    onSearch(searchQuery)
                }}>검색</button>
            </div>
            <div style={{marginRight: "auto"}}></div>

            <button style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "50px", width: "fit-content"}} onClick={onRefresh}>현재 페이지<br/>새로고침</button>
        </div>

        <LoginFailList state={state} onRefresh={onRefresh} />
        <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange}/>
    </div>
}

export default LoginFailContainer