import {call, put} from "redux-saga/effects";
import {failedGetDistributorSingleReducer, setDistributorSingleReducer} from "../../reducers/partner/DistributorSingleReducer";
import {fetchDistributorSingle} from "../../../lib/api";

export function* getDistributorSingleSaga(action) {
    try{
        console.log("##getDistributorSingleSaga input#",action.payload.input)
        const res = yield call(fetchDistributorSingle,
            action.payload.input,
        );
        console.log("##getDistributorSingleSaga output#",res.data)
        yield put(setDistributorSingleReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDistributorSingleReducer(error))
    }
}