import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination";
import {getMemberUser, setIsOpen, setIsOpen2, setIsOpen3, setIsOpen4, setIsOpen5, setIsOpen6, setIsOpen7, setIsOpen8, setIsOpen9, setUserId, setSelectedNos,
    setSelectedNos2,setSelectedNos3,setSelectedNos4,setSelectedNos5,setSelectedNos6,setSelectedNos7,setSelectedNos8,setSelectedNos9,setIsEditing00} from "../../redux/reducers/user/MemberReducer";
import {setIsUserId, setIsUserNickname, setIsUserNo} from "../../redux/reducers/user/userInfoCombineReducer";


import MemberList from "../../components/user/Member";
import * as XLSX from "xlsx";
import typeCase from "../../util/typeCase";
import moment from "moment/moment";
import {ArrowsExpand} from "react-bootstrap-icons";
import MemberAddComponent from "../../components/MemberAddComponent";
import MemberInformationDetailModal from "../../components/modal/user/detail/MemberInformationDetailModal";
import MemberInformationCombineModal from "../../components/modal/user/combine/MemberInformationCombineModal";
import MemberExcelModal01 from "../../components/modal/user/MemberExcelModal01";
import MemberExcelModal02 from "../../components/modal/user/MemberExcelModal02";
import MemberExcelModal03 from "../../components/modal/user/MemberExcelModal03";

import MemberSearchModal01 from "../../components/modal/user/MemberSearchModal01";
import MemberSearchModal02 from "../../components/modal/user/MemberSearchModal02";
import MemberSearchModal03 from "../../components/modal/user/MemberSearchModal03";
import MemberSearchModal04 from "../../components/modal/user/MemberSearchModal04";

import OrgSelect from "../../components/org/OrgSelect";
import {setSelectOrgId} from "../../redux/reducers/user/MessageReducer";
import {getDepositList} from "../../redux/reducers/deposit/DepositListReducer";
import axios from "axios";
import MemberInformationSimpleModal from "../../components/modal/user/simple/MemberInformationSimpleModal";
import BettingContainer from "../betting/BettingContainer";
import {useNavigate} from "react-router-dom";
import {insertAdminLog} from "../../lib/api";
import Select from "react-select";


const MemberListContainer = ({topSearchVal01}) => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const userInfoId = useSelector((state) => state.userInfo.id)
    const userInfoIp = useSelector((state) => state.userInfo.ip)
    const initialState = useSelector((state) => state.member)
    const initialStateModal = useSelector((state) => state.userInfoDetail)
    const navigate = useNavigate();

    /*조회조건 start*/
    const [searchCd01, setSearchCd01] = useState();     //사이트코드
    const [searchCd02, setSearchCd02] = useState();     //게시판차단
    const [searchCd03, setSearchCd03] = useState();     //개인입금전용계좌
    const [searchCd04, setSearchCd04] = useState();     //마지막접속기기
    const [searchCd05, setSearchCd05] = useState();     //입금방식
    const [searchCd06, setSearchCd06] = useState();     //
    const [searchCd07, setSearchCd07] = useState();     //
    const [searchCd08, setSearchCd08] = useState();     //
    const [searchCd09, setSearchCd09] = useState();     //
    const [searchCd10, setSearchCd10] = useState();     //

    const [userGbList, setUserGbList] = useState([]);   //회원그룹DB맵핑
    const [userGbList2, setUserGbList2] = useState([]); //태그DB맵핑

    const [selectedNos2, setSelectNos22] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().add(-1, "month").format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));

    const [initPhone, setInitPhone] = useState(""); //ID까지 폰번호 초기화
    const [password, setPassword] = useState(""); //가라암호 일괄변경

    const getCmmnCd = () => {
        if(userGbList.length === 0){
            axios.post('/admin/member/getGroupSetting', {
                cmmnCdId : '0000',
            }).then((res) => {
                setUserGbList(res.data.cmmnCdList);
            })
        }

        if(userGbList2.length === 0){
            axios.post('/admin/member/getTagSetting', {
                cmmnCdId : '0000',
            }).then((res) => {
                setUserGbList2(res.data.cmmnCdList);
            })
        }

        const checks = [];
        checks.push('01','02','03','04','05','06','07','08','09','10','11','12','13')
        dispatch(setSelectedNos4(checks))
    }
    useEffect(getCmmnCd, [])

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {

        //alert("topSearchVal01 ::: "+topSearchVal01);

        dispatch(getMemberUser({
            page: initialState.page? initialState.page : 1,
            pageSize: initialState.pageSize? initialState.pageSize : 5,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
            topSearchVal: topSearchVal01 ? topSearchVal01 : "",
        }))

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "회원관리"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getMemberUser({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }


    const dupIpChange = (IP) => {

        setSearchQuery(IP);

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: IP,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: '28',
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }


    const dupIdChange = (ID) => {

        setSearchQuery(ID);

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: ID,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: '2',
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }


    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const changeInitPhone = (value) => {
        setInitPhone(value)
    }

    const changePassword = (value) => {
        setPassword(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getMemberUser({
            //page: initialState.pages? initialState.pages : 1,
            //pageSize: initialState.pageSizes? initialState.pageSizes : 5,
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const onRefresh = () => {

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const searchHandler = (sortColumn, sortValue) => {
        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: sortColumn,
            sortValue: sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const handleClickOpen = () => {
        dispatch(setIsOpen(true))
    }
    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        onRefresh();
    }

    // const handleClickOpen2 = () => {
    //     dispatch(setIsOpen2(true))
    // }

    const handleClickOpen2 = (userId) => {
        dispatch(setIsOpen2(true))
        dispatch(setIsUserId(userId))
    }

    const handleClickClose2 = () => {
        dispatch(setIsOpen2(false))
        onRefresh();
    }

    const handleClickOpen3 = (userId) => {
        dispatch(setIsOpen3(true))
        dispatch(setIsUserId(userId))
    }

    const handleClickClose3 = () => {
        dispatch(setIsOpen3(false))
        onRefresh();
    }

    const handleClickOpen4 = (userId) => {
        dispatch(setIsOpen4(true))
        dispatch(setIsUserId(userId))
    }

    const handleClickClose4 = () => {
        dispatch(setIsOpen4(false))
        onRefresh();
    }

    const handleClickOpen5 = (userId, userNo, userNickname) => {
        dispatch(setIsOpen5(true))
        dispatch(setIsUserId(userId))
        dispatch(setIsUserNo(userNo))
        dispatch(setIsUserNickname(userNickname))
    }

    const handleClickClose5 = () => {
        dispatch(setIsOpen5(false))
        onRefresh();
    }

    const handleClickRefresh = (userId) => {
        dispatch(setIsOpen5(false))
        dispatch(setIsOpen5(true))
        dispatch(setIsUserId(userId))
    }

    const handleClickClose6 = () => {
        dispatch(setIsOpen6(false))
        onRefresh();
    }

    const handleClickOpen6 = (userId) => {
        dispatch(setIsOpen6(true))
    }

    const handleClickClose7 = () => {
        dispatch(setIsOpen7(false))
        onRefresh();
    }

    const handleClickOpen7 = (userId) => {
        dispatch(setIsOpen7(true))
    }

    const handleClickClose8 = () => {
        dispatch(setIsOpen8(false))
        onRefresh();
    }

    const handleClickOpen8 = (userId) => {
        dispatch(setIsOpen8(true))
    }

    const handleClickClose9 = () => {
        dispatch(setIsOpen9(false))
        onRefresh();
    }

    const handleClickOpen9 = (userId) => {
        dispatch(setIsOpen9(true))
    }


    const exportExcel = ()=>{

        //alert('initialState.totalList <>>>> '+initialState.totalList);

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {


            let jsonObject = { // JSON 객체를 생성합니다.
                파트너:typeCase.userType(initialState.totalList[i].user_type),
                추천:'-',
                상태:typeCase.userState(initialState.totalList[i].user_state),
                등급:typeCase.userState(initialState.totalList[i].level),
                회원ID:initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임:initialState.totalList[i].nickname? initialState.totalList[i].nickname+"("+initialState.totalList[i].comment+")" : '',
                예금주:initialState.totalList[i].name? initialState.totalList[i].name :'',
                보유머니:initialState.totalList[i].money? initialState.totalList[i].money :'0',
                포인트:initialState.totalList[i].point? initialState.totalList[i].point :'0',
                충전:initialState.totalList[i].deposit_total? initialState.totalList[i].deposit_total :'0',
                환전:initialState.totalList[i].withdraw_total? initialState.totalList[i].withdraw_total :'0',
                충전차액:initialState.totalList[i].total_amount? initialState.totalList[i].total_amount :'0',
                이달수익:initialState.totalList[i].month_amount? initialState.totalList[i].month_amount :'0',
                접속수:initialState.totalList[i].login_count? initialState.totalList[i].login_count :'0',
                최근충전:initialState.totalList[i].recently_deposit? moment(initialState.totalList[i].recently_deposit).format("YYYY-MM-DD HH:mm:ss") :'-',
                최근접속일:initialState.totalList[i].login_createdAt? moment(initialState.totalList[i].login_createdAt).format("YYYY-MM-DD HH:mm:ss") :'-',
                최근도메인:initialState.totalList[i].login_domain? initialState.totalList[i].login_domain :'',
                가입일:initialState.totalList[i].createdAt? moment(initialState.totalList[i].createdAt).format("YYYY-MM-DD HH:mm:ss") :'-',
                관리자:'관리자',

            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '회원관리');
        XLSX.writeFile(workBook, '회원관리.xlsx');
    }

    const onClickReset = (e) => {
        //window.location.reload();
        window.location.href="/user/Member";
    }


    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)

    const setParentParam = (id) =>{
        setSearchCd01(id)
        dispatch(setSelectOrgId(id))
        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: id,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const search02ChangeHandler = (e) => {
        const data = e;
        // alert("type : "+JSON.stringify(e));
        // alert("type : "+data.e.value);

        setSearchCd02(data.e.value)

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: data.e.value,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const search03ChangeHandler = (e) => {
        const data = e;
        setSearchCd03(data.e.value)

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: data.e.value,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const search04ChangeHandler = (e) => {
        const data = e;
        setSearchCd04(data.e.value)

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: data.e.value,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const search05ChangeHandler = (e) => {
        const data = e;
        setSearchCd05(data.e.value)

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: data.e.value,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const checkHandler = (checked, no) => {
        if(checked) {
            dispatch(setSelectedNos([...initialState.selectedNos, no]))
            dispatch(getMemberUser({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                sortColumn: initialState.sortColumn,
                sortValue: initialState.sortValue,
                searchGb: initialState.searchGb,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                selectedNos: [...initialState.selectedNos, no],
                selectedNos2: initialState.selectedNos2,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4,
                selectedNos5: initialState.selectedNos5,
                selectedNos6: initialState.selectedNos6,
                selectedNos7: initialState.selectedNos7,
                selectedNos8: initialState.selectedNos8,
                selectedNos9: initialState.selectedNos9,
                searchIdArr: initialState.searchIdArr,
                searchIdSort: initialState.searchIdSort,
                searchNicknameArr: initialState.searchNicknameArr,
                searchNicknameSort: initialState.searchNicknameSort,
            }))
        }
        else {
            dispatch(setSelectedNos(initialState.selectedNos.filter((id) => id !== no)))
            dispatch(getMemberUser({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                sortColumn: initialState.sortColumn,
                sortValue: initialState.sortValue,
                searchGb: initialState.searchGb,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                selectedNos: initialState.selectedNos.filter((id) => id !== no),
                selectedNos2: initialState.selectedNos2,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4,
                selectedNos5: initialState.selectedNos5,
                selectedNos6: initialState.selectedNos6,
                selectedNos7: initialState.selectedNos7,
                selectedNos8: initialState.selectedNos8,
                selectedNos9: initialState.selectedNos9,
                searchIdArr: initialState.searchIdArr,
                searchIdSort: initialState.searchIdSort,
                searchNicknameArr: initialState.searchNicknameArr,
                searchNicknameSort: initialState.searchNicknameSort,
            }))
        }
    }

    const checkHandler2 = (e) => {

        const data = e;
        // alert("type : "+JSON.stringify(data));
        // alert("length : "+data.e.length);

        dispatch(setSelectedNos2([]));
        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: [],
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))

        if( data.e.length == 0 )
        {
            dispatch(getMemberUser({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                sortColumn: initialState.sortColumn,
                sortValue: initialState.sortValue,
                searchGb: initialState.searchGb,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                selectedNos: initialState.selectedNos,
                selectedNos2: [],
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4,
                selectedNos5: initialState.selectedNos5,
                selectedNos6: initialState.selectedNos6,
                selectedNos7: initialState.selectedNos7,
                selectedNos8: initialState.selectedNos8,
                selectedNos9: initialState.selectedNos9,
                searchIdArr: initialState.searchIdArr,
                searchIdSort: initialState.searchIdSort,
                searchNicknameArr: initialState.searchNicknameArr,
                searchNicknameSort: initialState.searchNicknameSort,
            }))
        }


        let selectNosParam = [];
        for ( var i=0; i< data.e.length; i++ )
        {
            selectNosParam.push(...selectNosParam, data.e[i].value)
            dispatch(setSelectedNos2([...selectedNos2, data.e[i].value]))

            //alert( "i : "+i+ " || initialState.selectedNos2 : "+selectedNos2);

            if( i == data.e.length-1 )
            {
                // alert("selectedNos2 : "+selectedNos2);
                dispatch(getMemberUser({
                    page: initialState.page,
                    pageSize: initialState.pageSize,
                    order: initialState.order,
                    query: searchQuery,
                    startDate: startDate? startDate : initialState.startDate,
                    endDate: endDate? endDate : initialState.endDate,
                    userGbCd: initialState.userGbCd,
                    sortColumn: initialState.sortColumn,
                    sortValue: initialState.sortValue,
                    searchGb: initialState.searchGb,
                    searchCd01: initialState.searchCd01,
                    searchCd02: initialState.searchCd02,
                    searchCd03: initialState.searchCd03,
                    searchCd04: initialState.searchCd04,
                    searchCd05: initialState.searchCd05,
                    searchCd06: initialState.searchCd06,
                    searchCd07: initialState.searchCd07,
                    searchCd08: initialState.searchCd08,
                    searchCd09: initialState.searchCd09,
                    searchCd10: initialState.searchCd10,
                    selectedNos: initialState.selectedNos,
                    selectedNos2: selectNosParam,
                    selectedNos3: initialState.selectedNos3,
                    selectedNos4: initialState.selectedNos4,
                    selectedNos5: initialState.selectedNos5,
                    selectedNos6: initialState.selectedNos6,
                    selectedNos7: initialState.selectedNos7,
                    selectedNos8: initialState.selectedNos8,
                    selectedNos9: initialState.selectedNos9,
                    searchIdArr: initialState.searchIdArr,
                    searchIdSort: initialState.searchIdSort,
                    searchNicknameArr: initialState.searchNicknameArr,
                    searchNicknameSort: initialState.searchNicknameSort,
                }))
            }
        }

    }

    // const checkHandler2 = (checked, no) => {
    //     if(checked) {
    //         dispatch(setSelectedNos2([...initialState.selectedNos2, no]))
    //         dispatch(getMemberUser({
    //             page: initialState.page,
    //             pageSize: initialState.pageSize,
    //             order: initialState.order,
    //             query: searchQuery,
    //             startDate: startDate? startDate : initialState.startDate,
    //             endDate: endDate? endDate : initialState.endDate,
    //             userGbCd: initialState.userGbCd,
    //             sortColumn: initialState.sortColumn,
    //             sortValue: initialState.sortValue,
    //             searchGb: initialState.searchGb,
    //             searchCd01: initialState.searchCd01,
    //             searchCd02: initialState.searchCd02,
    //             searchCd03: initialState.searchCd03,
    //             searchCd04: initialState.searchCd04,
    //             searchCd05: initialState.searchCd05,
    //             searchCd06: initialState.searchCd06,
    //             searchCd07: initialState.searchCd07,
    //             searchCd08: initialState.searchCd08,
    //             searchCd09: initialState.searchCd09,
    //             searchCd10: initialState.searchCd10,
    //             selectedNos: initialState.selectedNos,
    //             selectedNos2: [...initialState.selectedNos2, no],
    //             selectedNos3: initialState.selectedNos3,
    //             selectedNos4: initialState.selectedNos4,
    //             selectedNos5: initialState.selectedNos5,
    //             selectedNos6: initialState.selectedNos6,
    //             selectedNos7: initialState.selectedNos7,
    //             selectedNos8: initialState.selectedNos8,
    //             selectedNos9: initialState.selectedNos9,
    //             searchIdArr: initialState.searchIdArr,
    //             searchIdSort: initialState.searchIdSort,
    //             searchNicknameArr: initialState.searchNicknameArr,
    //             searchNicknameSort: initialState.searchNicknameSort,
    //         }))
    //     }
    //     else {
    //         dispatch(setSelectedNos2(initialState.selectedNos2.filter((id) => id !== no)))
    //         dispatch(getMemberUser({
    //             page: initialState.page,
    //             pageSize: initialState.pageSize,
    //             order: initialState.order,
    //             query: searchQuery,
    //             startDate: startDate? startDate : initialState.startDate,
    //             endDate: endDate? endDate : initialState.endDate,
    //             userGbCd: initialState.userGbCd,
    //             sortColumn: initialState.sortColumn,
    //             sortValue: initialState.sortValue,
    //             searchGb: initialState.searchGb,
    //             searchCd01: initialState.searchCd01,
    //             searchCd02: initialState.searchCd02,
    //             searchCd03: initialState.searchCd03,
    //             searchCd04: initialState.searchCd04,
    //             searchCd05: initialState.searchCd05,
    //             searchCd06: initialState.searchCd06,
    //             searchCd07: initialState.searchCd07,
    //             searchCd08: initialState.searchCd08,
    //             searchCd09: initialState.searchCd09,
    //             searchCd10: initialState.searchCd10,
    //             selectedNos: initialState.selectedNos,
    //             selectedNos2: initialState.selectedNos2.filter((id) => id !== no),
    //             selectedNos3: initialState.selectedNos3,
    //             selectedNos4: initialState.selectedNos4,
    //             selectedNos5: initialState.selectedNos5,
    //             selectedNos6: initialState.selectedNos6,
    //             selectedNos7: initialState.selectedNos7,
    //             selectedNos8: initialState.selectedNos8,
    //             selectedNos9: initialState.selectedNos9,
    //             searchIdArr: initialState.searchIdArr,
    //             searchIdSort: initialState.searchIdSort,
    //             searchNicknameArr: initialState.searchNicknameArr,
    //             searchNicknameSort: initialState.searchNicknameSort,
    //         }))
    //     }
    // }

    const checkHandler3 = (checked, no) => {
        if(checked) {
            dispatch(setSelectedNos3([...initialState.selectedNos3, no]))
            dispatch(getMemberUser({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                sortColumn: initialState.sortColumn,
                sortValue: initialState.sortValue,
                searchGb: initialState.searchGb,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                selectedNos: initialState.selectedNos,
                selectedNos2: initialState.selectedNos2,
                selectedNos3: [...initialState.selectedNos3, no],
                selectedNos4: initialState.selectedNos4,
                selectedNos5: initialState.selectedNos5,
                selectedNos6: initialState.selectedNos6,
                selectedNos7: initialState.selectedNos7,
                selectedNos8: initialState.selectedNos8,
                selectedNos9: initialState.selectedNos9,
                searchIdArr: initialState.searchIdArr,
                searchIdSort: initialState.searchIdSort,
                searchNicknameArr: initialState.searchNicknameArr,
                searchNicknameSort: initialState.searchNicknameSort,
            }))
        }
        else {
            dispatch(setSelectedNos3(initialState.selectedNos3.filter((id) => id !== no)))
            dispatch(getMemberUser({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                sortColumn: initialState.sortColumn,
                sortValue: initialState.sortValue,
                searchGb: initialState.searchGb,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                selectedNos: initialState.selectedNos,
                selectedNos2: initialState.selectedNos2,
                selectedNos3: initialState.selectedNos3.filter((id) => id !== no),
                selectedNos4: initialState.selectedNos4,
                selectedNos5: initialState.selectedNos5,
                selectedNos6: initialState.selectedNos6,
                selectedNos7: initialState.selectedNos7,
                selectedNos8: initialState.selectedNos8,
                selectedNos9: initialState.selectedNos9,
                searchIdArr: initialState.searchIdArr,
                searchIdSort: initialState.searchIdSort,
                searchNicknameArr: initialState.searchNicknameArr,
                searchNicknameSort: initialState.searchNicknameSort,
            }))
        }
    }

    const checkHandler4 = (checked, no) => {
        if(checked) {
            dispatch(setSelectedNos4([...initialState.selectedNos4, no]))
            dispatch(getMemberUser({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                sortColumn: initialState.sortColumn,
                sortValue: initialState.sortValue,
                searchGb: initialState.searchGb,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                selectedNos: initialState.selectedNos,
                selectedNos2: initialState.selectedNos2,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: [...initialState.selectedNos4, no],
                selectedNos5: initialState.selectedNos5,
                selectedNos6: initialState.selectedNos6,
                selectedNos7: initialState.selectedNos7,
                selectedNos8: initialState.selectedNos8,
                selectedNos9: initialState.selectedNos9,
                searchIdArr: initialState.searchIdArr,
                searchIdSort: initialState.searchIdSort,
                searchNicknameArr: initialState.searchNicknameArr,
                searchNicknameSort: initialState.searchNicknameSort,
            }))
        }
        else {
            dispatch(setSelectedNos4(initialState.selectedNos4.filter((id) => id !== no)))
            dispatch(getMemberUser({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                sortColumn: initialState.sortColumn,
                sortValue: initialState.sortValue,
                searchGb: initialState.searchGb,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                selectedNos: initialState.selectedNos,
                selectedNos2: initialState.selectedNos2,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4.filter((id) => id !== no),
                selectedNos5: initialState.selectedNos5,
                selectedNos6: initialState.selectedNos6,
                selectedNos7: initialState.selectedNos7,
                selectedNos8: initialState.selectedNos8,
                selectedNos9: initialState.selectedNos9,
                searchIdArr: initialState.searchIdArr,
                searchIdSort: initialState.searchIdSort,
                searchNicknameArr: initialState.searchNicknameArr,
                searchNicknameSort: initialState.searchNicknameSort,
            }))
        }
    }

    const allCheckHandler4 = (e) => {
        const checks = [];
        checks.push('01','02','03','04','05','06','07','08','09','10','11','12','13')
        dispatch(setSelectedNos4(checks))

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: checks,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const allDisableCheckHandler4 = (e) => {
        const checks = [];
        dispatch(setSelectedNos4(checks))

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: checks,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const checkHandler5 = (checked, no) => {
        if(checked) {
            dispatch(setSelectedNos5([...initialState.selectedNos5, no]))
            dispatch(getMemberUser({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                sortColumn: initialState.sortColumn,
                sortValue: initialState.sortValue,
                searchGb: initialState.searchGb,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                selectedNos: initialState.selectedNos,
                selectedNos2: initialState.selectedNos2,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4,
                selectedNos5: [...initialState.selectedNos5, no],
                selectedNos6: initialState.selectedNos6,
                selectedNos7: initialState.selectedNos7,
                selectedNos8: initialState.selectedNos8,
                selectedNos9: initialState.selectedNos9,
                searchIdArr: initialState.searchIdArr,
                searchIdSort: initialState.searchIdSort,
                searchNicknameArr: initialState.searchNicknameArr,
                searchNicknameSort: initialState.searchNicknameSort,
            }))
        }
        else {
            dispatch(setSelectedNos5(initialState.selectedNos5.filter((id) => id !== no)))
            dispatch(getMemberUser({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                sortColumn: initialState.sortColumn,
                sortValue: initialState.sortValue,
                searchGb: initialState.searchGb,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                selectedNos: initialState.selectedNos,
                selectedNos2: initialState.selectedNos2,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4,
                selectedNos5: initialState.selectedNos5.filter((id) => id !== no),
                selectedNos6: initialState.selectedNos6,
                selectedNos7: initialState.selectedNos7,
                selectedNos8: initialState.selectedNos8,
                selectedNos9: initialState.selectedNos9,
                searchIdArr: initialState.searchIdArr,
                searchIdSort: initialState.searchIdSort,
                searchNicknameArr: initialState.searchNicknameArr,
                searchNicknameSort: initialState.searchNicknameSort,
            }))
        }
    }

    const allCheckHandler5 = (e) => {
        const checks = [];
        checks.push(
            1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,
            16,17,18,19,20,21,22,23,24,25,26,27,28,29,30
        )
        dispatch(setSelectedNos5(checks))

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: checks,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const allDisableCheckHandler5 = (e) => {
        const checks = [];
        dispatch(setSelectedNos5(checks))

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: checks,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }


    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: e.currentTarget.value,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: e.currentTarget.value,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }



    const add1SDate = () => {

        const date = moment(initialState.startDate);
        setStartDate(date.add(1, "day").format('YYYY-MM-DD'));

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: date.format('YYYY-MM-DD'),
            endDate: initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const minus1SDate = () => {
        const date = moment(initialState.startDate);
        setStartDate(date.add(-1, "day").format('YYYY-MM-DD'));

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: date.format('YYYY-MM-DD'),
            endDate: initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }


    const add1EDate = () => {

        const date = moment(initialState.endDate);
        setEndDate(date.add(1, "day").format('YYYY-MM-DD'));

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: initialState.startDate,
            endDate: date.format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }

    const minus1EDate = () => {

        const date = moment(initialState.endDate);
        setEndDate(date.add(-1, "day").format('YYYY-MM-DD'));

        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: initialState.startDate,
            endDate: date.format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9,
            searchIdArr: initialState.searchIdArr,
            searchIdSort: initialState.searchIdSort,
            searchNicknameArr: initialState.searchNicknameArr,
            searchNicknameSort: initialState.searchNicknameSort,
        }))
    }


    const clickChangePassword = (e) => {
        //alert("password :: "+password);
        if( password === "" )
        {
            alert("변경을 원하시는 새 비밀번호를 입력하세요");
            return;
        }
        else
        {
            if(window.confirm("정말 입력하신 암호로 모든 가라아이디의 암호를 변경하시겠습니까?"))
            {
                axios.post('/admin/member/changePassword', {
                    password: password,
                    adminNo: userInfoId,
                    reg_ip: userInfoIp
                }).then((res) => {
                    alert(res.data.isUpdated? "암호변경완료" : "암호변경실패")
                    //onRefresh();
                    window.location.reload();
                })
            }
            else
            {
                return;
            }
        }
    }


    const clickInitPhone = (e) => {

        if( initPhone === "" )
        {
            alert("삭제하길 원하는 최종 가입일시의 아이디를 입력하세요");
            return;
        }
        else
        {
            let reply = prompt("정말 이 아이디까지의 휴대폰 번호를 삭제하시겠습니까?\n삭제하신 이후엔 복구할 수 없습니다.\n" +
                "정말 이 아이디까지의 모든 핸드폰 번호를 초기화 하리셔면,\n"+
                "'여기까지모든번호삭제'라고 입력해주세요.", "");
            //alert("reply :: "+reply);

            if( reply === "여기까지모든번호삭제" )
            {
                axios.post('/admin/member/initPhone', {
                    initPhoneId: initPhone,
                    adminNo: userInfoId,
                    reg_ip: userInfoIp
                }).then((res) => {
                    alert(res.data.isUpdated? "초기화완료" : "초기화실패")
                    onRefresh();
                })
            }
            else
            {
                alert("입력하신 값이 옳바르지 않습니다.");
                return;
            }
        }
    }

    //현재 검새결과에 적용->관심회원
    const setInterest = (e) => {

        let reply = prompt("현재 검색결과 회원들을 설정할 관심회원 번호를 입력하세요.\n" +
            "관심회원을 해제하려면 -1을 입력하세요\n"+
            "원복이 불가능 하므로 신중하게 입력하세요", "");



        if( parseInt(reply) > 30 || parseInt(reply) < -1)
        {
            alert("정상적인 관심회원 번호를 입력하세요.(1~30, 해제시:-1)");
            return;
        }
        else
        {
            if(window.confirm("현재 검색결과 회원들을 "+reply+"번 관심회원으로 설정하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (1/2)"))
            {
                if(window.confirm("현재 검색결과 회원들을 "+reply+"번 관심회원으로 설정하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (2/2)"))
                {
                    axios.post('/admin/member/changeInterest', {
                        interestCd: parseInt(reply),
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        alert(res.data.isUpdated? "설정완료" : "설정실패")
                        onRefresh();
                    })
                }
                else
                {
                    return;
                }
            }
            else
            {
                return;
            }
        }
    }


    const setDepositType = (type) => {
        //rcpt_meth_cd
        let typeNm = "";
        switch(type) {
            case "01" :
                typeNm = "현금"
                break;
            case "02" :
                typeNm = "가상"
                break;
            case "03" :
                typeNm = "가상2"
                break;
            case "04" :
                typeNm = "코인[B]"
                break;
            case "05" :
                typeNm = "코인[C]"
                break;
            default:
                break;
        }

        if(window.confirm("현재 검색결과 회원들을 "+typeNm+" 입금방식으로 바꾸시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/3)"))
        {
            if(window.confirm("현재 검색결과 회원들을 "+typeNm+" 입금방식으로 바꾸시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/3)"))
            {
                if(window.confirm("현재 검색결과 회원들을 "+typeNm+" 입금방식으로 바꾸시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (3/3)"))
                {
                    axios.post('/admin/member/changeDepositType', {
                        rcptMethCd: type,
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        alert(res.data.isUpdated? "입금방식변경완료" : "입금방식변경실패")
                        onRefresh();
                    })
                }
                else{ return; }
            }
            else{ return; }
        }
    }



    const setGroup = (e) => {

        let reply = prompt("현재 검색결과 회원들을 설정할 그룹 번호를 입력하세요.\n" +
            "그룹 해제시 -1을 입력하세요\n" +
            "원복이 불가능 하므로 신중하게 입력하세요", "");

        if(window.confirm("현재 검색결과 회원들을 "+reply+" 그룹으로 설정하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/2)"))
        {
            if(window.confirm("현재 검색결과 회원들을 "+reply+" 그룹으로 설정하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/2)"))
            {
                axios.post('/admin/member/changeGroup', {
                    groupCd: reply,
                    updateList: initialState.totalList,
                    adminNo: userInfoId,
                    reg_ip: userInfoIp
                }).then((res) => {
                    alert(res.data.isUpdated? "그룹변경완료" : reply+": 존재하지 않는 그룹 번호 입니다. 정확한 그룹번호를 입력하세요.")
                    onRefresh();
                })
            }
            else{ return; }
        }
        else{ return; }
    }


    const setTag = (e) => {

        let reply = prompt("현재 검색결과 회원들을 설정할 태그 번호를 입력하세요.\n" +
            "태그 해제시 -1을 입력하세요\n" +
            "원복이 불가능 하므로 신중하게 입력하세요", "");

        if(window.confirm("현재 검색결과 회원들을 "+reply+" 태그 설정하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/2)"))
        {
            if(window.confirm("현재 검색결과 회원들을 "+reply+" 태그 설정하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/2)"))
            {
                axios.post('/admin/member/changeTag', {
                    tagCd: reply,
                    updateList: initialState.totalList,
                    adminNo: userInfoId,
                    reg_ip: userInfoIp
                }).then((res) => {
                    alert(res.data.isUpdated? "태그변경완료" : reply+": 존재하지 않는 태그 번호 입니다. 정확한 태그 번호를 입력하세요.")
                    onRefresh();
                })
            }
            else{ return; }
        }
        else{ return; }
    }


    const setUserState = (type) => {
        //rcpt_meth_cd
        let typeNm = "";
        switch (type) {
            case "01" :
                typeNm = "정상"
                break;
            case "02" :
                typeNm = "정지"
                break;
            default:
                break;
        }

        if (window.confirm("현재 검색결과 회원들을 " + typeNm + " 설정 하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/3)")) {
            if (window.confirm("현재 검색결과 회원들을 " + typeNm + " 설정 하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/3)")) {
                if (window.confirm("현재 검색결과 회원들을 " + typeNm + " 설정 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (3/3)")) {
                    axios.post('/admin/member/changeUserState', {
                        userStateCd: type,
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        alert(res.data.isUpdated ? "설정완료" : "설정실패")
                        onRefresh();
                    })
                } else {
                    return;
                }
            } else {
                return;
            }
        }
    }


    const setDelYn = (type) => {
        //rcpt_meth_cd
        let typeNm = "";
        switch(type) {
            case "Y" :
                if(window.confirm("현재 검색결과 회원들을 삭제 처리 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (1/3)"))
                {
                    if(window.confirm("현재 검색결과 회원들을 삭제 처리 하시겠습니까?\n" +
                        "원복이 불가능 하므로 신중하게 입력하세요 (2/3)"))
                    {
                        if(window.confirm("현재 검색결과 회원들을 삭제 처리 하시겠습니까?\n" +
                            "원복이 불가능 하므로 신중하게 입력하세요 (3/3)"))
                        {
                            axios.post('/admin/member/changeDelYn', {
                                delYn: 'Y',
                                updateList: initialState.totalList,
                                adminNo: userInfoId,
                                reg_ip: userInfoIp
                            }).then((res) => {
                                alert(res.data.isUpdated? "처리완료" : "처리실패")
                                onRefresh();
                            })
                        }
                        else{ return; }
                    }
                    else{ return; }
                }
                break;
            case "N" :
                if(window.confirm("현재 검색결과 회원들을 복원 처리(검색결과중 삭제 회원만) 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (1/3)"))
                {
                    if(window.confirm("현재 검색결과 회원들을 복원 처리(검색결과중 삭제 회원만) 하시겠습니까?\n" +
                        "원복이 불가능 하므로 신중하게 입력하세요 (2/3)"))
                    {
                        if(window.confirm("현재 검색결과 회원들을 복원 처리(검색결과중 삭제 회원만) 하시겠습니까?\n" +
                            "원복이 불가능 하므로 신중하게 입력하세요 (3/3)"))
                        {
                            axios.post('/admin/member/changeDelYn', {
                                delYn: 'N',
                                updateList: initialState.totalList,
                                adminNo: userInfoId,
                                reg_ip: userInfoIp
                            }).then((res) => {
                                alert(res.data.isUpdated? "처리완료" : "처리실패")
                                onRefresh();
                            })
                        }
                        else{ return; }
                    }
                    else{ return; }
                }
                break;
            default:
                break;
        }
    }

    const clickAllDely = () => {
        if(window.confirm("전체 삭제 회원들을 복원 처리 하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/3)"))
        {
            if(window.confirm("전체 삭제 회원들을 복원 처리 하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/3)"))
            {
                if(window.confirm("전체 삭제 회원들을 복원 처리 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (3/3)"))
                {
                    axios.post('/admin/member/changeAllDelYn', {
                        delYn: 'N',
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        alert(res.data.isUpdated? "처리완료" : "처리실패")
                        onRefresh();
                    })
                }
                else{ return; }
            }
            else{ return; }
        }
    }


    const setIqryPopOutBlkYn = (type) => {
        //iqry_pop_out_blk_yn
        let typeNm = "";
        switch (type) {
            case "Y" :
                typeNm = "해제"
                break;
            case "N" :
                typeNm = "처리"
                break;
            default:
                break;
        }

        if (window.confirm("현재 검색결과 회원들을 문의/쪽지외차단 " + typeNm + " 하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/3)")) {
            if (window.confirm("현재 검색결과 회원들을 문의/쪽지외차단 " + typeNm + " 하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/3)")) {
                if (window.confirm("현재 검색결과 회원들을 문의/쪽지외차단 " + typeNm + " 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (3/3)")) {
                    axios.post('/admin/member/changeIqryPopOutBlkYn', {
                        iqryPopOutBlkYn: type,
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        alert(res.data.isUpdated ? "설정완료" : "설정실패")
                        onRefresh();
                    })
                } else {
                    return;
                }
            } else {
                return;
            }
        }
    }

    const initMoney = (e) => {

        if(window.confirm("현재 검색결과 회원들을 머니 회수 하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/3)"))
        {
            if(window.confirm("현재 검색결과 회원들을 머니 회수 하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/3)"))
            {
                if(window.confirm("현재 검색결과 회원들을 머니 회수 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (3/3)")) {
                    axios.post('/admin/member/changeInitMoney', {
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        alert(res.data.isUpdated ? "회수완료" : "회수실패")
                        onRefresh();
                    })
                }
                else{ return; }
            }
            else{ return; }
        }
        else{ return; }
    }

    const initPoint = (e) => {

        if(window.confirm("현재 검색결과 회원들을 포인트 회수 하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/3)"))
        {
            if(window.confirm("현재 검색결과 회원들을 포인트 회수 하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/3)"))
            {
                if(window.confirm("현재 검색결과 회원들을 포인트 회수 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (3/3)")) {
                    axios.post('/admin/member/changeInitPoint', {
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        alert(res.data.isUpdated ? "회수완료" : "회수실패")
                        onRefresh();
                    })
                }
                else{ return; }
            }
            else{ return; }
        }
        else{ return; }
    }


    const initPointMoney = (e) => {

        if(window.confirm("현재 검색결과 회원들을 포인트 및 머니 회수 하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/3)"))
        {
            if(window.confirm("현재 검색결과 회원들을 포인트 및 머니 회수 하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/3)"))
            {
                if(window.confirm("현재 검색결과 회원들을 포인트 및 머니 회수 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (3/3)")) {

                    axios.post('/admin/member/changeInitMoney', {
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        //alert(res.data.isUpdated ? "회수완료" : "회수실패")
                        //onRefresh();
                    })

                    axios.post('/admin/member/changeInitPoint', {
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        alert(res.data.isUpdated ? "회수완료" : "회수실패")
                        onRefresh();
                    })
                }
                else{ return; }
            }
            else{ return; }
        }
        else{ return; }
    }


    const payMoney = (e) => {

        let reply = prompt("현재 검색결과 회원들에게 머니 지급할 금액을 입력하세요.", "");
        let replyNm;
        if( !reply ){ return; }
        replyNm = reply.replace(/\B(?=(\d{3})+(?!\d))/g, ',');


        if(window.confirm("현재 검색결과 회원들을 "+replyNm+"원을 머니 지급 하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/3)"))
        {
            if(window.confirm("현재 검색결과 회원들을 "+replyNm+"원을 머니 지급 하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/3)"))
            {
                if(window.confirm("현재 검색결과 회원들을 "+replyNm+"원을 머니 지급 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (3/3)")) {
                    axios.post('/admin/member/payMoney', {
                        payAmt: reply,
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        alert(res.data.isUpdated ? "지급완료" : "지급실패")
                        onRefresh();
                    })
                }
            }
            else{ return; }
        }
        else{ return; }
    }


    const payPoint = (e) => {

        let reply = prompt("현재 검색결과 회원들에게 포인트 지급할 금액을 입력하세요.", "");

        let replyNm;
        if( !reply ){ return; }
        replyNm = reply.replace(/\B(?=(\d{3})+(?!\d))/g, ',');


        if(window.confirm("현재 검색결과 회원들을 "+replyNm+"원을 포인트 지급 하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/3)"))
        {
            if(window.confirm("현재 검색결과 회원들을 "+replyNm+"원을 포인트 지급 하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/3)"))
            {
                if(window.confirm("현재 검색결과 회원들을 "+replyNm+"원을 포인트 지급 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (3/3)")) {
                    axios.post('/admin/member/payPoint', {
                        payPoint: reply,
                        updateList: initialState.totalList,
                        adminNo: userInfoId,
                        reg_ip: userInfoIp
                    }).then((res) => {
                        alert(res.data.isUpdated ? "지급완료" : "지급실패")
                        onRefresh();
                    })
                }
            }
            else{ return; }
        }
        else{ return; }
    }


    const setAccDel = (e) => {

        if(window.confirm("현재 검색결과 회원들의 개인계좌 정보를 삭제하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/2)"))
        {
            if(window.confirm("현재 검색결과 회원들의 개인계좌 정보를 삭제하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/2)"))
            {
                axios.post('/admin/member/setAccDel', {
                    updateList: initialState.totalList,
                    adminNo: userInfoId,
                    reg_ip: userInfoIp
                }).then((res) => {
                    alert(res.data.isUpdated? "삭제완료" : "삭제실패")
                    onRefresh();
                })
            }
            else
            {
                return;
            }
        }
        else
        {
            return;
        }
    }

    const checkHandler10 = (checked) => {
        if (checked) {
            setSearchCd10('Y')
            dispatch(setIsEditing00(true))
            //alert("state :: "+initialState.isEditing00);
        } else {
            setSearchCd10('N')
            dispatch(setIsEditing00(false))
            //alert("state :: "+initialState.isEditing00);
        }
    }


    let SelectSearch02 = [
        { value: '', label: '모든유저' },
        { value: '01', label: '모든차단유저' },
        { value: '02', label: '고객센터만허용' },
        { value: '03', label: '자유게시판,고객센터 모두차단' }
    ];
    let SelectSearch03 = [
        { value: '', label: '전체' },
        { value: '01', label: '설정' },
        { value: '02', label: '미설정' }
    ];
    let SelectSearch04 = [
        { value: '', label: '전체' },
        { value: '01', label: 'PC' },
        { value: '02', label: '모바일' }
    ];
    let SelectSearch05 = [
        { value: '', label: '전체' },
        { value: '01', label: '현금' },
        { value: '02', label: '가상' },
        { value: '03', label: '가상2' },
        { value: '99', label: '코인(전체)' },
        { value: '04', label: '코인(A)' },
        { value: '05', label: '코인(B)' },
        { value: '06', label: '코인(C)' }
    ];
    let SelectSearch06 = [
        { value: '01', label: '정상' },
        { value: '02', label: '정지' }
    ];

    let SelectSearch07 = [];    //회원구분
    let SelectSearch08 = [];    //회원그룹
    let SelectSearch09 = [];    //P등급

    let hour = [];
    for (let i = 1; i < 25; i++) {
        let op = {};

        // 시간을 00시로 나타내기 위해
        op.value = ('0' + i).slice(-2);
        op.label = ('0' + i).slice(-2) + '시';

        hour.push(op);
    }

    const [timeValue, setTimeValue] = useState('');



    return <>
        <div className="container-fluid p-0 mx-1" style={{
            display: "flex"
        }}>

        </div>

        <div className="commonNew">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <a onClick={onClickReset} style={{cursor: 'pointer'}}>
                        <font color={"blue"} size="3">회원관리</font>
                    </a>
                    &nbsp;
                    <select
                        className="form-select sm-select"
                        defaultValue={120}
                        style={{
                            width: "100px"
                        }}
                        value={initialState.pageSize === "999"? "모두" : initialState.pageSize}
                        onChange={(e) => {
                            dispatch(getMemberUser({
                                page: initialState.page,
                                pageSize: parseInt(e.target.value),
                                order: initialState.order,
                                query: searchQuery,
                                startDate: startDate? startDate : initialState.startDate,
                                endDate: endDate? endDate : initialState.endDate,
                                userGbCd: initialState.userGbCd,
                                sortColumn: initialState.sortColumn,
                                sortValue: initialState.sortValue,
                                searchGb: initialState.searchGb,
                                searchCd01: initialState.searchCd01,
                                searchCd02: initialState.searchCd02,
                                searchCd03: initialState.searchCd03,
                                searchCd04: initialState.searchCd04,
                                searchCd05: initialState.searchCd05,
                                searchCd06: initialState.searchCd06,
                                searchCd07: initialState.searchCd07,
                                searchCd08: initialState.searchCd08,
                                searchCd09: initialState.searchCd09,
                                searchCd10: initialState.searchCd10,
                                selectedNos: initialState.selectedNos,
                                selectedNos2: initialState.selectedNos2,
                                selectedNos3: initialState.selectedNos3,
                                selectedNos4: initialState.selectedNos4,
                                selectedNos5: initialState.selectedNos5,
                                selectedNos6: initialState.selectedNos6,
                                selectedNos7: initialState.selectedNos7,
                                selectedNos8: initialState.selectedNos8,
                                selectedNos9: initialState.selectedNos9,
                                searchIdArr: initialState.searchIdArr,
                                searchIdSort: initialState.searchIdSort,
                                searchNicknameArr: initialState.searchNicknameArr,
                                searchNicknameSort: initialState.searchNicknameSort,
                            }))
                        }}
                    >
                        <option value={30}>30개</option>
                        <option value={60}>60개</option>
                        <option value={120}>120개</option>
                        <option value={240}>240개</option>
                        <option value={360}>360개</option>
                        <option value={500}>500개</option>
                        <option value={1000}>1000개</option>
                    </select>
                    &nbsp;
                    / EXCEL 다운로드
                    &nbsp;
                    <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}
                            onClick={handleClickOpen3}
                    >
                        현재 검색 결과
                    </button>
                    {initialState.isOpen3 && <MemberExcelModal01 open={initialState.isOpen3} onClose={handleClickClose3} initialState={initialState} open={handleClickOpen5} />}
                    <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}
                            onClick={handleClickOpen4}
                    >
                        전체 목록 기준
                    </button>
                    {initialState.isOpen4 && <MemberExcelModal02 open={initialState.isOpen4} onClose={handleClickClose4} />}
                    {initialState.isOpen5 && <MemberInformationCombineModal open={initialState.isOpen5} onClose={handleClickClose5} refresh={handleClickRefresh}/>}

                    <table   width={"70%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                                &nbsp;
                                <button style={{
                                    width: "140px", backgroundColor :"gray"
                                }} type="submit" className="btn btn-primary" onClick={onClickReset}>현재 페이지 새로고침</button>
                                &nbsp;
                                <button style={{
                                    width: "90px", backgroundColor :"green"
                                }} type="submit" className="btn btn-primary" onClick={handleClickOpen}>회원등록</button>
                                {initialState.isOpen && <MemberAddComponent open={initialState.isOpen} onClose={handleClickClose} />}
                                {initialState.isOpen2 && <MemberInformationDetailModal open={initialState.isOpen2} onClose={handleClickClose2} />}

                            </td>
                        </tr>
                    </table>

                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", height:"23px"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", height:"23px", width:"330px"
                    }}>
                        <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" /> 현재접속
                        &nbsp;
                        <div style={{zIndex : 999}}>
                            <OrgSelect setChildParam = {setParentParam} id = {1}/>
                        </div>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", height:"23px", width:"100%"
                    }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <select
                            className="form-select sm-select"
                            defaultValue={""}
                            style={{
                                width: "80px"
                            }}
                            value={initialState.userGbCd === ""? "" : initialState.userGbCd}
                            onChange={e =>
                                dispatch(getMemberUser({
                                    page: initialState.page,
                                    pageSize: initialState.pageSize,
                                    order: parseInt(e.target.value),
                                    query: searchQuery,
                                    startDate: startDate? startDate : initialState.startDate,
                                    endDate: endDate? endDate : initialState.endDate,
                                    userGbCd: e.target.value,
                                    sortColumn: initialState.sortColumn,
                                    sortValue: initialState.sortValue,
                                    searchGb: initialState.searchGb,
                                    searchCd01: initialState.searchCd01,
                                    searchCd02: initialState.searchCd02,
                                    searchCd03: initialState.searchCd03,
                                    searchCd04: initialState.searchCd04,
                                    searchCd05: initialState.searchCd05,
                                    searchCd06: initialState.searchCd06,
                                    searchCd07: initialState.searchCd07,
                                    searchCd08: initialState.searchCd08,
                                    searchCd09: initialState.searchCd09,
                                    searchCd10: initialState.searchCd10,
                                    selectedNos: initialState.selectedNos,
                                    selectedNos2: initialState.selectedNos2,
                                    selectedNos3: initialState.selectedNos3,
                                    selectedNos4: initialState.selectedNos4,
                                    selectedNos5: initialState.selectedNos5,
                                    selectedNos6: initialState.selectedNos6,
                                    selectedNos7: initialState.selectedNos7,
                                    selectedNos8: initialState.selectedNos8,
                                    selectedNos9: initialState.selectedNos9,
                                    searchIdArr: initialState.searchIdArr,
                                    searchIdSort: initialState.searchIdSort,
                                    searchNicknameArr: initialState.searchNicknameArr,
                                    searchNicknameSort: initialState.searchNicknameSort,
                                }))
                            }
                        >
                            <option value={""}>::회원분류::</option>
                            <option value={"01"}>대기회원</option>
                            <option value={"02"}>무료회원</option>
                            <option value={"03"}>유료회원</option>
                            <option value={"04"}>VIP회원</option>
                            <option value={"05"}>가라회원</option>
                            <option value={"06"}>관리자</option>
                            <option value={"07"}>최고관리자</option>
                        </select>
                        &nbsp;
                        <select
                            className="form-select sm-select"
                            defaultValue={0}
                            style={{
                                width: "140px"
                            }}
                            value={initialState.searchGb === ""? "0" : initialState.searchGb}
                            onChange={e =>
                                dispatch(getMemberUser({
                                    page: initialState.page,
                                    pageSize: initialState.pageSize,
                                    order: initialState.order,
                                    query: initialState.query,
                                    startDate: startDate? startDate : initialState.startDate,
                                    endDate: endDate? endDate : initialState.endDate,
                                    userGbCd: initialState.userGbCd,
                                    sortColumn: initialState.sortColumn,
                                    sortValue: initialState.sortValue,
                                    searchGb: e.target.value,
                                    searchCd01: initialState.searchCd01,
                                    searchCd02: initialState.searchCd02,
                                    searchCd03: initialState.searchCd03,
                                    searchCd04: initialState.searchCd04,
                                    searchCd05: initialState.searchCd05,
                                    searchCd06: initialState.searchCd06,
                                    searchCd07: initialState.searchCd07,
                                    searchCd08: initialState.searchCd08,
                                    searchCd09: initialState.searchCd09,
                                    searchCd10: initialState.searchCd10,
                                    selectedNos: initialState.selectedNos,
                                    selectedNos2: initialState.selectedNos2,
                                    selectedNos3: initialState.selectedNos3,
                                    selectedNos4: initialState.selectedNos4,
                                    selectedNos5: initialState.selectedNos5,
                                    selectedNos6: initialState.selectedNos6,
                                    selectedNos7: initialState.selectedNos7,
                                    selectedNos8: initialState.selectedNos8,
                                    selectedNos9: initialState.selectedNos9,
                                    searchIdArr: initialState.searchIdArr,
                                    searchIdSort: initialState.searchIdSort,
                                    searchNicknameArr: initialState.searchNicknameArr,
                                    searchNicknameSort: initialState.searchNicknameSort,
                                }))
                            }
                        >
                            <option value={0}>닉네임 또는 아이디</option>
                            <option value={1}>닉네임</option>
                            <option value={2}>아이디</option>
                            <option value={3}>도메인</option>
                            <option value={4}>이름</option>
                            <option value={5}>추천인(아이디)</option>
                            <option value={6}>추천인(닉네임)</option>
                            <option value={7}>핸드폰</option>
                            <option value={8}>핸드폰 일치(입력 아이디와)</option>
                            <option value={9}>이메일</option>
                            <option value={10}>가입일(일치 예:20240110)</option>
                            <option value={11}>가입일(이후~ 예:20240110)</option>
                            <option value={12}>가입일(이전~ 예:20240110)</option>
                            <option value={13}>최종접속일(이후~ 예:20240110)</option>
                            <option value={14}>최종접속일(이전~ 예:20240110)</option>
                            <option value={15}>최종충전일(이후~ 예:20240110)</option>
                            <option value={16}>최종충전일(이전~ 예:20240110)</option>
                            <option value={17}>최종환전일(이후~ 예:20240110)</option>
                            <option value={18}>최종환전일(이전~ 예:20240110)</option>
                            <option value={19}>총 충전액(~이상)</option>
                            <option value={20}>총 충전액(~이하)</option>
                            <option value={21}>총 환전액(~이상)</option>
                            <option value={22}>총 환전액(~이하)</option>
                            <option value={23}>충전횟수범위(입력예 : 0~2)</option>
                            <option value={24}>충전일수범위(입력예 : 0~2)</option>
                            <option value={25}>마진(~이상)</option>
                            <option value={26}>마진(~이하)</option>
                            <option value={27}>가입아이피</option>
                            <option value={28}>최근아이피</option>
                            <option value={29}>전체 사용아이피(완전 일치)</option>
                            <option value={30}>전체 사용아이피</option>
                            <option value={31}>사용아이피 동일회원</option>
                            <option value={32}>은행</option>
                            <option value={33}>은행코드(로컬)</option>
                            <option value={34}>은행코드(실제)</option>
                            <option value={35}>계좌번호</option>
                            <option value={36}>사용 추천인키</option>
                            <option value={37}>메모</option>
                            <option value={38}>개인설정(첫충)%입력</option>
                            <option value={39}>개인설정(매충)%입력</option>
                            <option value={40}>정산코드 조회</option>
                            <option value={41}>통합검색번호</option>
                        </select>
                        &nbsp;
                        <input style={{
                            width: "170px"
                        }} type="text" className="form-control" value={searchQuery}  id="search_edit" placeholder="검색조건을 입력하세요." onBlur={(e) => {
                            changeQuery(e.target.value)
                        }} onKeyDown={inputPress} />
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}}
                                onClick={handleClickOpen6} >{initialState.searchIdArr.length > 0 ? "아이디 다수 입력(입력됨)" : "아이디 다수 입력"}</button>
                        {initialState.isOpen6 && <MemberSearchModal01 open={initialState.isOpen6} onSearch={handleClickClose6} onClose={handleClickClose6} initialState={initialState} onRefresh={onRefresh} />}
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}}
                                onClick={handleClickOpen7} >{initialState.searchNicknameArr.length > 0 ? "닉네임 다수 입력(입력됨)" : "닉네임 다수 입력"}</button>
                        {initialState.isOpen7 && <MemberSearchModal02 open={initialState.isOpen7} onSearch={handleClickClose7} onClose={handleClickClose7} initialState={initialState} onRefresh={onRefresh} />}
                        &nbsp;
                        <input className="form-check-input sm-input" type="text"  style={{width:"100px"}} onBlur={(e) => {
                            changeInitPhone(e.target.value)
                        }}/>
                        &nbsp;
                        <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}
                                onClick={clickInitPhone} >ID까지 폰번호 초기화</button>
                        &nbsp;
                        <input className="form-check-input sm-input" type="password" style={{width:"100px"}} onBlur={(e) => {
                            changePassword(e.target.value)
                        }}/>
                        &nbsp;
                        <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}
                                onClick={clickChangePassword}>가라암호 일괄변경</button>
                        &nbsp;
                        <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}
                                onClick={clickAllDely}>삭제회원 일괄복원</button>
                    </div>
                </div>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", height:"45px"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"230px", textAlign:"center"
                    }}>
                        <table>
                            <tr>
                                <td>
                                    <ArrowsExpand />게시판차단 :
                                </td>
                                <td>
                                    <Select
                                        styles={{
                                            control: baseStyles => ({
                                                ...baseStyles,
                                                fontSize: '0.6rem',
                                                width: '150px',
                                                textAlign: 'left'
                                            }),
                                            option: baseStyles => ({
                                                ...baseStyles,
                                                textAlign: 'left',
                                                fontSize: '0.6rem',
                                                ':hover': {
                                                    background: '#250996',
                                                    color: '#EFEFF4',
                                                    cursor: 'pointer',
                                                },
                                            }),
                                        }}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => search02ChangeHandler({e})}
                                        placeholder=":: 선택 ::"
                                        options={SelectSearch02}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"210px", textAlign:"center"
                    }}>
                        <table>
                            <tr>
                                <td>
                                    <ArrowsExpand />개인입금전용계좌 :
                                </td>
                                <td>
                                    <Select
                                        styles={{
                                            control: baseStyles => ({
                                                ...baseStyles,
                                                fontSize: '0.6rem',
                                                width: '100px',
                                                textAlign: 'left'
                                            }),
                                            option: baseStyles => ({
                                                ...baseStyles,
                                                textAlign: 'left',
                                                fontSize: '0.6rem',
                                                ':hover': {
                                                    background: '#250996',
                                                    color: '#EFEFF4',
                                                    cursor: 'pointer',
                                                },
                                            }),
                                        }}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => search03ChangeHandler({e})}
                                        placeholder=":: 선택 ::"
                                        options={SelectSearch03}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"200px", textAlign:"center"
                    }}>
                        <table>
                            <tr>
                                <td>
                                    <ArrowsExpand />마지막접속기기 :
                                </td>
                                <td>
                                    <Select
                                        styles={{
                                            control: baseStyles => ({
                                                ...baseStyles,
                                                fontSize: '0.6rem',
                                                width: '100px',
                                                textAlign: 'left'
                                            }),
                                            option: baseStyles => ({
                                                ...baseStyles,
                                                textAlign: 'left',
                                                fontSize: '0.6rem',
                                                ':hover': {
                                                    background: '#250996',
                                                    color: '#EFEFF4',
                                                    cursor: 'pointer',
                                                },
                                            }),
                                        }}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => search04ChangeHandler({e})}
                                        placeholder=":: 선택 ::"
                                        options={SelectSearch04}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"170px", textAlign:"center"
                    }}>
                        <table>
                            <tr>
                                <td>
                                    <ArrowsExpand />입금방식 :
                                </td>
                                <td>
                                    <Select
                                        styles={{
                                            control: baseStyles => ({
                                                ...baseStyles,
                                                fontSize: '0.6rem',
                                                width: '100px',
                                                textAlign: 'left'
                                            }),
                                            option: baseStyles => ({
                                                ...baseStyles,
                                                textAlign: 'left',
                                                fontSize: '0.6rem',
                                                ':hover': {
                                                    background: '#250996',
                                                    color: '#EFEFF4',
                                                    cursor: 'pointer',
                                                },
                                            }),
                                        }}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => search05ChangeHandler({e})}
                                        placeholder=":: 선택 ::"
                                        options={SelectSearch05}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"270px", textAlign:"center"
                    }}>
                        <table>
                            <tr>
                                <td>
                                    <ArrowsExpand />회원상태 :
                                </td>
                                <td>
                                    <Select
                                        styles={{
                                            control: baseStyles => ({
                                                ...baseStyles,
                                                fontSize: '0.6rem',
                                                width: '200px',
                                                textAlign: 'left'
                                            }),
                                            option: baseStyles => ({
                                                ...baseStyles,
                                                textAlign: 'left',
                                                fontSize: '0.6rem',
                                                ':hover': {
                                                    background: '#250996',
                                                    color: '#EFEFF4',
                                                    cursor: 'pointer',
                                                },
                                            }),
                                        }}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={(e) => checkHandler2({e})}
                                        placeholder=":: 선택 ::"
                                        options={SelectSearch06}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"110px", backgroundColor:"blue"
                    }}>
                        <table>
                            <tr>
                                <td>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio2" id="inlineRadio1" style={{cursor: "pointer"}}
                                           checked={ searchCd10 === 'Y' ? true : false  }
                                           onChange={(e) => {checkHandler10(e.currentTarget.checked) }}
                                    />
                                    <font color={"yellow"}>부가정보/태그 표시</font>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", height:"23px"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"900px"
                    }}>
                        <ArrowsExpand />회원구분 :&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '01').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '01') }}/>
                        대기회원&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '02').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '02') }}/>
                        무료회원&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '03').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '03') }}/>
                        정상회원&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '04').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '04') }}/>
                        VIP회원&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '05').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '05') }}/>
                        가라회원&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '06').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '06') }}/>
                        주시회원&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '08').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '08') }}/>
                        탈퇴회원&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '9').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '9') }}/>
                        정지회원&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '11').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '11') }}/>
                        <span style={{"backgroundColor": "yellow"}}><b><font color={"red"}>수집기사용</font></b></span>&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '12').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '12') }}/>
                        <span style={{"backgroundColor": "yellow"}}><b><font color={"red"}>동일브라우저사용</font></b></span>&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '13').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '13') }}/>
                        <span style={{"backgroundColor": "yellow"}}><b><font color={"red"}>중복IP</font></b></span>&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '14').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '14') }}/>
                        <span style={{"backgroundColor": "yellow"}}><b><font color={"red"}>중복핸드폰</font></b></span>&nbsp;&nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio11" id="inlineRadio11"
                               style={{cursor:"pointer"}}
                               checked={initialState.selectedNos.filter((no) => no === '15').length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, '15') }}/>
                        개인총판&nbsp;&nbsp;
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", height:"23px"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"1000px"
                    }}>
                        <ArrowsExpand />회원그룹 :&nbsp;&nbsp;
                        {userGbList?.map(item => (
                            <React.Fragment key={item.no}>
                                &nbsp;<input className="form-check-input" type="checkbox" name="inlineRadio1" id="inlineRadio1"
                                             style={{cursor:"pointer"}}
                                             checked={initialState.selectedNos3.filter((no) => no === item.no).length > 0}
                                             onChange={(e) => {checkHandler3(e.currentTarget.checked, item.no) }}/>
                                <font  style={{backgroundColor: "lightgray"  }} color={ "yellow"}>
                                    {item.no}
                                </font>&nbsp;{item.group_nm}&nbsp;&nbsp;
                            </React.Fragment>
                        ))}
                    </div>

                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"870px", height:"23px"
                }}>
                    P등급 :
                    <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}} onClick={allCheckHandler4}>전체 P등급</button>
                    <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}} onClick={allDisableCheckHandler4}>전체 P해제</button>
                    &nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '01').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '01') }}/>
                    1-1 [1]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '02').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '02') }}/>
                    1-2 [1]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '03').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '03') }}/>
                    1-3 [1]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '04').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '04') }}/>
                    1-4 [1]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '05').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '05') }}/>
                    1-5 [1]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '06').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '06') }}/>
                    2 [2]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '07').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '07') }}/>
                    2-3 [2]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '08').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '08') }}/>
                    3 [3]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '09').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '09') }}/>
                    4 [4]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '10').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '10') }}/>
                    5 [5]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '11').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '11') }}/>
                    6 [6]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '12').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '12') }}/>
                    6-2 [1]&nbsp;&nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '13').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '13') }}/>
                    6-3 [1]&nbsp;
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", height:"23px"
                }}>
                    태그:&nbsp;
                    <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}} onClick={allCheckHandler5}>전체 태그</button>
                    <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}} onClick={allDisableCheckHandler5}>전체 태그 해제</button>
                    &nbsp;
                    {userGbList2?.map(item => (
                        <React.Fragment key={item.numkey}>
                            <div className={`looking_id_show type${item.no}`} >
                                &nbsp;<input className="form-check-input" type="checkbox" name="inlineRadioTag" id={'inlineRadioTag'+item.no}
                                             style={{cursor:"pointer"}}
                                             checked={initialState.selectedNos5.filter((no) => no === item.numkey).length > 0}
                                             onChange={(e) => {checkHandler5(e.currentTarget.checked, item.numkey) }} />
                                <label for={'inlineRadioTag'+item.no} style={{cursor:"pointer"}}>
                                    <font>
                                        {item.tag_nm}
                                    </font>
                                </label>
                            </div>
                            &nbsp;&nbsp;
                        </React.Fragment>
                    ))}
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", height:"23px"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"900px"
                    }}>
                        &nbsp;&nbsp;
                        <input className="form-control sm-date" type="date" value={startDate} onChange={startDateHandler} style={{width:"110px"}}/>
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}} onClick={add1SDate}>+</button>
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}} onClick={minus1SDate}>-</button>
                        &nbsp;~&nbsp;
                        <input className="form-control sm-date" type="date" value={endDate} onChange={endDateHandler} style={{width:"110px"}}/>
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}} onClick={add1EDate}>+</button>
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}} onClick={minus1EDate}>-</button>
                        &nbsp;
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}}>가입 유저</button>
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}}>로그인 유저</button>
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}}>충전 유저</button>
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}}>환전 유저</button>
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}}>베팅 유저</button>
                        <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}}>기간 충환마진 보기</button>
                        &nbsp;
                        <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>기간 검색 없음</button>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray", height:"23px"
            }}>
                현재 검색결과에 적용
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setDepositType("01")}><b>현금</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setDepositType("02")}><b>가상</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setDepositType("03")}><b>가상2</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setDepositType("04")}><b>코인[B]</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setDepositType("05")}><b>코인[C]</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={setInterest}><b>관심회원</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={setGroup}><b>그룹</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={setTag}><b>태그</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setUserState("02")}><b>정지</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setUserState("01")}><b>정상</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setDelYn("Y")}><b>삭제</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setDelYn("N")}><b>복원</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setIqryPopOutBlkYn("N")}><b>문의/쪽지외차단</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={() => setIqryPopOutBlkYn("Y")}><b>문의/쪽지외차단해제</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={initMoney}><b>머니 회수</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={initPoint}><b>포인트 회수</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={initPointMoney}><b>포인트/머니 회수</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={payMoney}><b>머니 지급</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={payPoint}><b>포인트 지급</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}}><b>복권 지급</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={handleClickOpen8}><b>레벨 설정</b></button>
                {initialState.isOpen8 && <MemberSearchModal03 open={initialState.isOpen8} onSearch={handleClickClose8} onClose={handleClickClose8} initialState={initialState} onRefresh={onRefresh} />}
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}}><b>소속 설정</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={setAccDel}><b>개인계좌 삭제</b></button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}}
                        onClick={()=> { window.open(`/user/Message/`,"_blank", "popup") }} ><b>쪽지 발송</b>
                </button>
                <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "8px", height: "19px", width: "fit-content"}} onClick={handleClickOpen9}><b>메모 삽입</b></button>
                {initialState.isOpen9 && <MemberSearchModal04 open={initialState.isOpen9} onSearch={handleClickClose9} onClose={handleClickClose9} initialState={initialState} onRefresh={onRefresh} />}
            </div>
        </div>

        <MemberList member={initialState.member} onRefresh={onRefresh} searchHandler={searchHandler} userGbList={userGbList} initialState={initialState} userGbList2={userGbList2} dupIpChange={dupIpChange} setSearchQuery={setSearchQuery} dupIdChange={dupIdChange} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default MemberListContainer