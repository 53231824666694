import {call, put, take,} from "redux-saga/effects";
import {fetchCustomers} from "../../../lib/api";
import {failedGetCustomers, setCustomers} from "../../reducers/board/CustomerReducer";

export function* getCustomerSaga(action) {
    try{
        const res = yield call(
            fetchCustomers,
            action.payload
        )

        yield put(setCustomers(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetCustomers(error))
    }
}