import {call, put, take,} from "redux-saga/effects";
import {
    failedGetGameKinds, failedGetLeagues,
    failedGetNations, failedGetTeams,
    setGameKinds,
    setLeagues,
    setNations, setTeams
} from "../../reducers/game/gameSetting";
import {fetchGameKindList, fetchLeagueList, fetchNationList, fetchTeamList} from "../../../lib/api";


export function* getGameKindsSaga(action) {
    try{
        const res = yield call(
            fetchGameKindList,
            action.payload
        )

        yield put(setGameKinds(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetGameKinds(error))
    }
}
export function* getNationsSaga(action) {
    try{
        const res = yield call(
            fetchNationList,
            action.payload
        )

        yield put(setNations(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetNations(error))
    }
}
export function* getLeaguesSaga(action) {
    try{
        const res = yield call(
            fetchLeagueList,
            action.payload
        )

        yield put(setLeagues(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetLeagues(error))
    }
}
export function* getTeamsSaga(action) {
    try{
        const res = yield call(
            fetchTeamList,
            action.payload
        )

        yield put(setTeams(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetTeams(error))
    }
}