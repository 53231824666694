import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {
    setSettingLiveYn,
    setSettingSlotYn,
    setSettingMiniYn,
    setSettingSportYn,
    setSettingMiniDtlYn,
    setSettingSportDtlYn,
    setOutbreakSdtArr,
    setOutbreakEdtArr,
    setBonusPaymentMethodArr,
    setIsOpen,
    getLvlSetting,
    setQuery,
    setSettingSportDomesticYn,
    setSettingSportForeignYn,
    setSettingSportInplayYn,
    setSettingSportSpecialYn,
    setSettingPaymentCycleTime,
    setSportPaymentMethodArr,
    setBonusQuery,
    setSelectedNos1,
    setSelectedNos2,
    setSelectedNos3,
    setSelectedNos4,
    setSelectedNos5
} from "../../redux/reducers/setting/AcctSettingListReducer";
import Switch from "react-switch";
import LvlSettingComponent from "../../components/modal/setting/LvlSettingComponent";
import MemberAddComponent from "../MemberAddComponent";

const AcctSetting = ({acctSetting, onRefresh, initialState, onSearch, mainList1, mainList2, mainList3, mainList4, mainList5

                    }) => {

    const dispatch = useDispatch()
    const [levels, setLevels] = useState([])

    const [accountWithdrawPwdYn, setAccountWithdrawPwdYn] = useState("");
    const [accountCustomerYn, setAccountCustomerYn] = useState("");
    const [accountPageYn, setAccountPageYn] = useState("");
    const [accountMemoYn, setAccountMemoYn] = useState("");
    const [accountPopupYn, setAccountPopupYn] = useState("");
    const [accountPageCnt, setAccountPageCnt] = useState("");
    const [accountMemoCnt, setAccountMemoCnt] = useState("");
    const [accountPopupCnt, setAccountPopupCnt] = useState("");

    const userInfoId = useSelector((state) => state.userInfo.id)
    const handleClickOpen = () => {
        dispatch(setIsOpen(true))
    }
    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        window.location.reload();
    }

    useEffect(() => {
        getGrades()
    }, [])

    const getGrades = () => {
        axios.post('/admin/admin/getPointGrade2', {

        }).then((res) => {
            setLevels(res.data.result)
            const arr = []
            for(const level of res.data.result) {
                arr.push(level.setting_level_no)
            }
        })
    }


    const onLevelChange = (setting_level_no) => {
        onSearch(setting_level_no)
    }

    const accountWithdrawPwdYnChangeHandler = (e) => {
        setAccountWithdrawPwdYn(e.currentTarget.value);
    }
    const accountCustomerYnChangeHandler = (e) => {
        setAccountCustomerYn(e.currentTarget.value);
    }
    const accountPageYnChangeHandler = (e) => {
        setAccountPageYn(e.currentTarget.value);
    }
    const accountMemoYnChangeHandler = (e) => {
        setAccountMemoYn(e.currentTarget.value);
    }
    const accountPopupYnChangeHandler = (e) => {
        setAccountPopupYn(e.currentTarget.value);
    }

    const updateDefaultBtnState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlAccttSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                accountWithdrawPwdYn: accountWithdrawPwdYn?accountWithdrawPwdYn:mainList1.account_withdraw_pwd_yn,
                accountCustomerYn: accountCustomerYn?accountCustomerYn:mainList1.account_customer_yn,
                accountPageYn: accountPageYn?accountPageYn:mainList1.account_page_yn,
                accountMemoYn: accountMemoYn?accountMemoYn:mainList1.account_memo_yn,
                accountPopupYn: accountPopupYn?accountPopupYn:mainList1.account_popup_yn,
                accountPageCnt: accountPageCnt?accountPageCnt:mainList1.account_page_cnt,
                accountMemoCnt: accountMemoCnt?accountMemoCnt:mainList1.account_memo_cnt,
                accountPopupCnt: accountPopupCnt?accountPopupCnt:mainList1.account_popup_cnt,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }

    const updateDefaultAllBtnState = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos1.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos1.length; i++)
            {
                axios.post('/admin/setting/saveLvlAccttSetting', {
                    pntGbCd: initialState.selectedNos1[i],
                    accountWithdrawPwdYn: accountWithdrawPwdYn?accountWithdrawPwdYn:mainList1.account_withdraw_pwd_yn,
                    accountCustomerYn: accountCustomerYn?accountCustomerYn:mainList1.account_customer_yn,
                    accountPageYn: accountPageYn?accountPageYn:mainList1.account_page_yn,
                    accountMemoYn: accountMemoYn?accountMemoYn:mainList1.account_memo_yn,
                    accountPopupYn: accountPopupYn?accountPopupYn:mainList1.account_popup_yn,
                    accountPageCnt: accountPageCnt?accountPageCnt:mainList1.account_page_cnt,
                    accountMemoCnt: accountMemoCnt?accountMemoCnt:mainList1.account_memo_cnt,
                    accountPopupCnt: accountPopupCnt?accountPopupCnt:mainList1.account_popup_cnt,
                    adminNo: userInfoId
                }).then((res) => {
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }
            alert("수정완료");
        }
    }


    const updateDefaultBtnState02 = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlAccttSetting02', {
                pntGbCd: mainList1?.pnt_gb_cd,
                accountWithdrawPwdYn: accountWithdrawPwdYn?accountWithdrawPwdYn:mainList1.account_withdraw_pwd_yn,
                accountCustomerYn: accountCustomerYn?accountCustomerYn:mainList1.account_customer_yn,
                accountPageYn: accountPageYn?accountPageYn:mainList1.account_page_yn,
                accountMemoYn: accountMemoYn?accountMemoYn:mainList1.account_memo_yn,
                accountPopupYn: accountPopupYn?accountPopupYn:mainList1.account_popup_yn,
                accountPageCnt: accountPageCnt?accountPageCnt:mainList1.account_page_cnt,
                accountMemoCnt: accountMemoCnt?accountMemoCnt:mainList1.account_memo_cnt,
                accountPopupCnt: accountPopupCnt?accountPopupCnt:mainList1.account_popup_cnt,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }

    const updateDefaultAllBtnState02 = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos2.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos2.length; i++)
            {
                axios.post('/admin/setting/saveLvlAccttSetting02', {
                    pntGbCd: initialState.selectedNos2[i],
                    accountWithdrawPwdYn: accountWithdrawPwdYn?accountWithdrawPwdYn:mainList1.account_withdraw_pwd_yn,
                    accountCustomerYn: accountCustomerYn?accountCustomerYn:mainList1.account_customer_yn,
                    accountPageYn: accountPageYn?accountPageYn:mainList1.account_page_yn,
                    accountMemoYn: accountMemoYn?accountMemoYn:mainList1.account_memo_yn,
                    accountPopupYn: accountPopupYn?accountPopupYn:mainList1.account_popup_yn,
                    accountPageCnt: accountPageCnt?accountPageCnt:mainList1.account_page_cnt,
                    accountMemoCnt: accountMemoCnt?accountMemoCnt:mainList1.account_memo_cnt,
                    accountPopupCnt: accountPopupCnt?accountPopupCnt:mainList1.account_popup_cnt,
                    adminNo: userInfoId
                }).then((res) => {
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }
            alert("수정완료");
        }
    }


    const updateDefaultBtnState03 = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlAccttSetting03', {
                pntGbCd: mainList1?.pnt_gb_cd,
                accountWithdrawPwdYn: accountWithdrawPwdYn?accountWithdrawPwdYn:mainList1.account_withdraw_pwd_yn,
                accountCustomerYn: accountCustomerYn?accountCustomerYn:mainList1.account_customer_yn,
                accountPageYn: accountPageYn?accountPageYn:mainList1.account_page_yn,
                accountMemoYn: accountMemoYn?accountMemoYn:mainList1.account_memo_yn,
                accountPopupYn: accountPopupYn?accountPopupYn:mainList1.account_popup_yn,
                accountPageCnt: accountPageCnt?accountPageCnt:mainList1.account_page_cnt,
                accountMemoCnt: accountMemoCnt?accountMemoCnt:mainList1.account_memo_cnt,
                accountPopupCnt: accountPopupCnt?accountPopupCnt:mainList1.account_popup_cnt,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }

    const updateDefaultAllBtnState03 = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos3.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos3.length; i++)
            {
                axios.post('/admin/setting/saveLvlAccttSetting03', {
                    pntGbCd: initialState.selectedNos3[i],
                    accountWithdrawPwdYn: accountWithdrawPwdYn?accountWithdrawPwdYn:mainList1.account_withdraw_pwd_yn,
                    accountCustomerYn: accountCustomerYn?accountCustomerYn:mainList1.account_customer_yn,
                    accountPageYn: accountPageYn?accountPageYn:mainList1.account_page_yn,
                    accountMemoYn: accountMemoYn?accountMemoYn:mainList1.account_memo_yn,
                    accountPopupYn: accountPopupYn?accountPopupYn:mainList1.account_popup_yn,
                    accountPageCnt: accountPageCnt?accountPageCnt:mainList1.account_page_cnt,
                    accountMemoCnt: accountMemoCnt?accountMemoCnt:mainList1.account_memo_cnt,
                    accountPopupCnt: accountPopupCnt?accountPopupCnt:mainList1.account_popup_cnt,
                    adminNo: userInfoId
                }).then((res) => {
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }
            alert("수정완료");
        }
    }


    const checkHandler1 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos1([...initialState.selectedNos1, no]))
        }
        else {
            dispatch(setSelectedNos1(initialState.selectedNos1.filter((id) => id !== no)))
        }
    }
    const checkHandler2 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos2([...initialState.selectedNos2, no]))
        }
        else {
            dispatch(setSelectedNos2(initialState.selectedNos2.filter((id) => id !== no)))
        }
    }
    const checkHandler3 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos3([...initialState.selectedNos3, no]))
        }
        else {
            dispatch(setSelectedNos3(initialState.selectedNos3.filter((id) => id !== no)))
        }
    }


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "green", border: "1", color: "white",fontSize: "10px", height: "22px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                            onClick={handleClickOpen}>레벨관리(추가,삭제)</button>
                    {initialState.isOpen && <LvlSettingComponent open={initialState.isOpen} onClose={handleClickClose} />}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {levels?.map((item, index) => (
                        <>
                            <button style={{ backgroundColor: initialState.query === item.setting_level_key ? "blue" : "white" , border: "1", color: initialState.query === item.setting_level_key ? "white" : "black",fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                    onClick={(e) => {
                                        onLevelChange(item.setting_level_key)
                                    }}
                            >
                                <b>{item.cmmn_cd_dtl_nm} 레벨</b>
                            </button>
                        </>
                    ))}
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"100%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> 기본설정</b></font>
                        </div>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>계좌 문의시 환전비번</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={accountWithdrawPwdYnChangeHandler} value={accountWithdrawPwdYn?accountWithdrawPwdYn:mainList1.account_withdraw_pwd_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>계좌 문의시 고객센터</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={accountCustomerYnChangeHandler} value={accountCustomerYn?accountCustomerYn:mainList1.account_customer_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>계좌 문의시 페이지 안내</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={accountPageYnChangeHandler} value={accountPageYn?accountPageYn:mainList1.account_page_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>계좌 문의시 쪽지 안내</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={accountMemoYnChangeHandler} value={accountMemoYn?accountMemoYn:mainList1.account_memo_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>계좌 문의시 팝업 안내</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={accountPopupYnChangeHandler} value={accountPopupYn?accountPopupYn:mainList1.account_popup_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                   <textarea onChange={(e)=> setAccountPageCnt(e.target.value)}
                                             placeholder={mainList1.account_page_cnt}
                                             value={accountPageCnt}
                                             name="textarea_name" cols="70" rows="6"/>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <textarea onChange={(e)=> setAccountMemoCnt(e.target.value)}
                                              placeholder={mainList1.account_memo_cnt}
                                              value={accountMemoCnt}
                                              name="textarea_name" cols="70" rows="6"/>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                   <textarea onChange={(e)=> setAccountPopupCnt(e.target.value)}
                                             placeholder={mainList1.account_popup_cnt}
                                             value={accountPopupCnt}
                                             name="textarea_name" cols="70" rows="6"/>
                                </td>
                            </tr>


                            <tr>
                                <td align={"center"}>
                                    <table>
                                        <tr>
                                            <td align={"center"}>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDefaultBtnState()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDefaultAllBtnState()}}
                                                >
                                                    선택 레벨 일괄적용
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align={"center"}>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio11"+item.setting_level_no} id={"inlineRadio11"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos1.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler1(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio11' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio11"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td align={"center"} colSpan={2}>
                                    <table>
                                        <tr>
                                            <td align={"center"}>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDefaultBtnState02()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDefaultAllBtnState02()}}
                                                >
                                                    선택 레벨 일괄적용
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align={"center"}>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio12"+item.setting_level_no} id={"inlineRadio12"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos2.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler2(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio12' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio12"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td align={"center"} colSpan={2}>
                                    <table>
                                        <tr>
                                            <td align={"center"}>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDefaultBtnState03()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDefaultAllBtnState03()}}
                                                >
                                                    선택 레벨 일괄적용
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align={"center"}>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio13"+item.setting_level_no} id={"inlineRadio13"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos3.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler3(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio13' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio13"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AcctSetting;