import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    isOpen: false,
    blackMemberListCount:0,
    blackMemberList: [],
    totalListCount:0,
    totalList: [],
    page: 1,
    pageSize: 20,
    query: "",
    highLightQuery: "",
}

export const statsSlice = createSlice({
    name: 'BlackMemberReducer',
    initialState,
    reducers: {
        getBlackMemberReducer(state, action) {
            console.log("#reducers getBlackMemberList start#",action.payload.pageSize,action.payload.page,action.payload.query);
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.query = action.payload.query
        },
        setBlackMemberReducer(state, action) {
            console.log("#reducers setBlackMemberList start#",action.payload.blackMemberList);
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.blackMemberList = action.payload.blackMemberList;
            state.blackMemberListCount = action.payload.blackMemberListCount;
        },
        failedGetBlackMemberReducer(state, action){
            console.log("#reducers failedGetBlackMemberReducer start#");
        },
        setSearchQuery(state, action){
            state.query  = action.payload;
        },
        setHighLightQuery(state, action){
            state.highLightQuery  = action.payload;
        },
        setIsOpen(state,action){
            state.isOpen = action.payload;
        },
    }
});

export const {
    getBlackMemberReducer,
    setBlackMemberReducer,
    failedGetBlackMemberReducer,
    setSearchQuery,
    setHighLightQuery,
    setIsOpen
} = statsSlice.actions;

export default statsSlice.reducer;