import {createSlice} from "@reduxjs/toolkit";

export const inplayMatchSlice = createSlice({
    name: 'inplayMatch',
    initialState: {
        isLoading: false,
        isLoadingDetail: false,
        fixtures: [],
        originFixtures: [],
        error: null,
        isConnect: false,
        tasks: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        sport: 0,
        market: 0,
        provider: 0,
        league: 0,
        sports: [],
        markets: [],
        providers: [],
        leagues: [],
        query: "",
        checkedInputs: [],
        checkedDetailInputs: [],
        betInputs: [],
        updateData: [],
        low_ratios: [],
        currentFixture: null,
        currentFixtureId: 0,
    },
    reducers: {
        getInplayFixture(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.sport = action.payload.sport;
            state.provider = action.payload.provider;
            state.league = action.payload.league;
            state.query = action.payload.query
        },
        setInplayFixture(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.low_ratios = action.payload.result;
            state.totalCount = action.payload.totalCount;
            state.sports = action.payload.sports;
            state.providers = action.payload.providers;
            state.leagues = action.payload.leagues;
            state.checkedInputs = [];

        },
        getInplayFixtureDetail(state, action) {
            state.isLoadingDetail = true;
            state.currentFixtureId = action.payload;
        },
        setCurrentFixture(state, action) {
            state.isLoadingDetail = false;
            state.currentFixture = action.payload;
            state.checkedDetailInputs = [];
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        setCheckedDetailInputs(state, action) {
            state.checkedDetailInputs = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        setBetInputs(state, action) {
            state.betInputs = action.payload;
        },
        failedGetInplayFixture(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        failedGetInplayFixtureDetail(state, action) {
            state.isLoadingDetail = false;
            state.error = action.payload;
        },
    },
});

export const {
    getInplayFixture,
    setInplayFixture,
    getInplayFixtureDetail,
    setCurrentFixture,
    setCheckedInputs,
    setCheckedDetailInputs,
    setUpdateData,
    setBetInputs,
    failedGetInplayFixture,
    failedGetInplayFixtureDetail,
} = inplayMatchSlice.actions;

export default inplayMatchSlice.reducer;