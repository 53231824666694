import {call, put} from "redux-saga/effects";
import {failedGetCompanySubListReducer, setCompanySubListReducer} from "../../reducers/partner/CompanySubListReducer";
import {fetchCompanySubList} from "../../../lib/api";

export function* getCompanySubListSaga(action) {
    try{
        console.log("##getCompanySubListSaga input#",action.payload.input)
        const res = yield call(fetchCompanySubList,
            action.payload.input,
        );
        console.log("##getCompanySubListSaga output#",res.data)
        yield put(setCompanySubListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetCompanySubListReducer(error))
    }
}