import {call, put, take,} from "redux-saga/effects";
import {fetchNotices} from "../../../lib/api";
import {failedGetNotices, setNotices} from "../../reducers/board/NoticeReducer";

export function* getNoticesSaga(action) {
    try{
        const res = yield call(
            fetchNotices,
            action.payload
        )

        yield put(setNotices(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetNotices(error))
    }
}