import {call, put, take,} from "redux-saga/effects";
import {failedGetMoneyInfo, setMoneyInfo} from "../reducers/moneyInfo";
import {fetchMoneyInfo} from "../../lib/api";


export function* getMoneyInfoSaga(action) {
    try {
        const res = yield call(fetchMoneyInfo, action.payload)
        yield put(setMoneyInfo(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetMoneyInfo(error))
    }
}
