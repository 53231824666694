import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";
const initialState = {
    isLoading: false,
    error: null,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),

    //등록관련 항목
    detail: {
        org_admin_id: "",
        adj_amt : 0,
        org_adj_menual_gb_cd : "",
        org_adj_type_cd: "",
        org_adj_gb_cd_nm : "",
        losing_type_cd_nm: "",
        org_adj_exe_cycl_cd: "",
        adjustment_dttm: "",
        adjustment_resv_dt : moment().format('YYYY-MM-DD'),
        adjustment_resv_hh : moment().format('HH'),
        payment_within_adj_amt: 0,
        reg_id : "",
        upd_id: "",
        org_auto_adj_detail_settings : []
    },

    //공통코드
    cmCd0042 : [],//조직정산수동구분코드
    cmCd0043 : [],//조직정산유형코드
    cmCd0044 : [],//조직정산유형상세코드
    cmCd0045 : [],//조직정산실행주기코드
}
export const AdjustmentSlice = createSlice({
    name: 'adjustment',
    initialState,
    reducers: {
        getAdjustment(state, action) {

            //alert("1111");
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userId = action.payload.userId;
        },
        setAdjustment(state, action) {
            state.isLoading = false;
            state.detail = action.payload.detail;
            state.cmCd0042 = action.payload.cmCd0042;//조직정산수동구분코드
            state.cmCd0043 = action.payload.cmCd0043;//조직정산유형코드
            state.cmCd0044 = action.payload.cmCd0044;//조직정산유형상세코드
            state.cmCd0045 = action.payload.cmCd0045;//조직정산실행주기코드
            console.log("detail", state.detail)

            if(state.detail.org_adj_menual_gb_cd === ""){
                state.detail.org_adj_menual_gb_cd = state.cmCd0042[0].cmmn_cd_dtl_id
            }

            if(state.detail.org_adj_type_cd === ""){
                state.detail.org_adj_type_cd = state.cmCd0043[0].cmmn_cd_dtl_id
            }

            if(state.detail.org_adj_exe_cycl_cd === ""){
                state.detail.org_adj_exe_cycl_cd = state.cmCd0045[0].cmmn_cd_dtl_id
            }
        },
        failedGetAdjustment(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setOrgAdminId(state,action){
            state.detail.org_admin_id = action.payload;
        },
        setOrgAdjTypeCd(state,action){
            state.detail.org_adj_type_cd = action.payload;
        },
        setOrgAdjMenualGbCd(state,action){
            state.detail.org_adj_menual_gb_cd = action.payload;
        },
        setOrgAdjExeCyclCd(state,action){
            state.detail.org_adj_exe_cycl_cd = action.payload;
        },
        setAdjustmentDttm(state,action){
            state.detail.adjustment_dttm = action.payload;
        },
        setAdjustmentResvDt(state,action){
            state.detail.adjustment_resv_dt = action.payload;
        },
        setAdjustmentResvHh(state,action){
            state.detail.adjustment_resv_hh = action.payload;
        },
        setPaymentWithinAdjAmt(state,action){
            state.detail.payment_within_adj_amt = action.payload;
        },
        setRegId(state,action){
            state.detail.reg_id = action.payload;
        },
        setUpdId(state,action){
            state.detail.upd_id = action.payload;
        },
        setOrgAutoAdjDetailSettings(state,action){
            if(action.payload.target.checked){
                state.detail.org_auto_adj_detail_settings.push({
                    org_adj_type_detail_cd : action.payload.target.value
                })
            }else{
                state.detail.org_auto_adj_detail_settings = state.detail.org_auto_adj_detail_settings.filter((item) => item.org_adj_type_detail_cd !== action.payload.target.value)
            }},
    }
});

export const {
    getAdjustment,
    setAdjustment,
    failedGetAdjustment,
    setOrgAdminId,
    setOrgAdjMenualGbCd,
    setOrgAdjTypeCd,
    setOrgAdjExeCyclCd,
    setAdjustmentResvDt,
    setAdjustmentResvHh,
    setPaymentWithinAdjAmt,
    setRegId,
    setUpdId,
    setOrgAutoAdjDetailSettings
} = AdjustmentSlice.actions;

export default AdjustmentSlice.reducer;