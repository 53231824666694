import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import Pagination from "../../../../components/common/Pagination2";
import {getWithdrawInfo,setUserLevel} from "../../../../redux/reducers/partner/WithdrawInfoReducer";


import {Route, useParams} from "react-router-dom";
import PartnerLosingInfo from "./LosingInfo";
import PartnerRollingInfo from "./RollingInfo";
import {getPointList} from "../../../../redux/reducers/deposit/PointListReducer";
import {insertAdminLog} from "../../../../lib/api";
import {NumericFormat} from "react-number-format";
import {getDistributorListReducer} from "../../../../redux/reducers/partner/DistributorListReducer";
import {getMemberUser} from "../../../../redux/reducers/user/MemberReducer";

const WithdrawInfo = (props) => {

    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.withdrawInfo)
    const {open, onClose} = props
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const param = useParams()
    const [userGroup, setUserGroup] = useState({})

    useEffect(() => {
        dispatch(getWithdrawInfo({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userId: param.userId
        }))
    }, [dispatch])

    const getCmmnCd = () => {

        axios.post('/admin/partner/getUserLevel', {
            userId: param.userId,
        }).then((res) => {
            setUserGroup(res.data.cmmnCdList);

            //setUserLevel
            dispatch(setUserLevel(res.data.cmmnCdList.org_level_cd))

            //alert("userGroup :: "+res.data.cmmnCdList.org_level_cd);
        })

    }
    useEffect(getCmmnCd, [])

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const onClickSearch = (e) => {

        //alert(JSON.stringify(selectedNos));
        dispatch(getWithdrawInfo({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userId: param.userId
        }))
    }

    const handlePageChange = (page) => {
        dispatch(getWithdrawInfo({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userId: param.userId
        }))
    }


    return (
        <div className="common" style={{width : "99%"}}>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            let userLvl = initialState.userLvl;
                            //alert("userLvl : "+userLvl);

                            if( userLvl === '01' )
                            {
                                window.location.href= "/partner/defaultInfo1/"+param.userId;
                            }else if( userLvl === '02' )
                            {
                                window.location.href= "/partner/defaultInfo2/"+param.userId;
                            }else if( userLvl === '03' )
                            {
                                window.location.href= "/partner/defaultInfo3/"+param.userId;
                            }

                        }}
                >기본정보</button>
                <button style={{ backgroundColor: "white",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                >출금</button>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/losingInfo/"+param.userId;
                        }}
                >루징내역</button>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/rollingInfo/"+param.userId;
                        }}
                >롤링내역</button>
                <button style={{     backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/rollingInfo/"+param.userId;
                        }}
                >회원내역</button>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <input className="form-control sm-date" type="date" value={startDate} onChange={startDateHandler} style={{width:"110px"}}/>
                    &nbsp;~&nbsp;
                    <input className="form-control sm-date" type="date" value={endDate} onChange={endDateHandler} style={{width:"110px"}}/>
                    &nbsp;
                    <button style={{ backgroundColor: "lightskyblue",
                        color: "white", border: "none", fontSize: "9px", height: "20px", width: "70px", fontWeight: "bold", marginRight: "1px"}}
                            onClick={onClickSearch}>검색</button>
                </div>
                <div className="" style={{
                    display: "flex"
                }}>
                    <table>
                        <tr>
                            <td align={"right"}>
                                <select
                                    className="form-select sm-select"
                                    defaultValue={'01'}
                                    style={{
                                        width: "100px"
                                    }}
                                    onChange={(e) => {
                                        dispatch(getWithdrawInfo({
                                            page: initialState.page,
                                            pageSize: parseInt(e.target.value),
                                            order: initialState.order,
                                            startDate: startDate? startDate : initialState.startDate,
                                            endDate: endDate? endDate : initialState.endDate,
                                            userId: param.userId
                                        }))
                                    }}
                                >
                                    <option>10건 출력</option>
                                    <option>50건 출력</option>
                                </select>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
                <table className="commonT table table-bordered table-sm text-center">
                    <thead >
                    <tr>
                        <th style={{backgroundColor: "lightgray",width: "20%"}}>오늘출금</th>
                        <th style={{backgroundColor: "lightgray",width: "40%"}}>기간</th>
                        <th style={{backgroundColor: "lightgray",width: "40%"}}>출금</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td align={"center"}>
                            <NumericFormat value={initialState.countList.today_sum} displayType={'text'} thousandSeparator={true}/>
                            ({<NumericFormat value={initialState.countList.today_cnt} displayType={'text'} thousandSeparator={true}/>}건)
                        </td>
                        <td align={"center"}>{initialState.countList.start_dt} ~ {initialState.countList.end_dt}</td>
                        <td align={"center"}>
                            <NumericFormat value={initialState.countList.total_sum} displayType={'text'} thousandSeparator={true}/>
                            ({<NumericFormat value={initialState.countList.total_cnt} displayType={'text'} thousandSeparator={true}/>}건)
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
                <table className="commonT table table-bordered table-sm text-center">
                    <thead >
                    <tr>
                        <th style={{backgroundColor: "lightgray",width: "3%"}}>번호</th>
                        <th style={{backgroundColor: "lightpink",width: "7%"}}>예금주</th>
                        <th style={{backgroundColor: "lightgray",width: "7%"}}>계좌메모</th>
                        <th style={{backgroundColor: "lightgray",width: "7%"}}>별칭</th>
                        <th style={{backgroundColor: "lightgray",width: "7%"}}>신청전금액</th>
                        <th style={{backgroundColor: "lightgray",width: "7%"}}>처리금액</th>
                        <th style={{backgroundColor: "lightgray",width: "7%"}}>처리후금액</th>
                        <th style={{backgroundColor: "lightgray",width: "7%"}}>오늘신청횟수</th>
                        <th style={{backgroundColor: "lightgray",width: "10%"}}>신청일자</th>
                        <th style={{backgroundColor: "lightgray",width: "10%"}}>처리일자</th>
                        <th style={{backgroundColor: "lightgray",width: "7%"}}>설명</th>
                        <th style={{backgroundColor: "lightgray",width: "7%"}}>상태</th>
                        <th style={{backgroundColor: "lightgray",width: "7%"}}>기능</th>
                    </tr>
                    </thead>
                    {!initialState.withdrawInfo.length && (
                        <tbody key={initialState.withdrawInfo.no}>
                        <tr>
                            <td colSpan="13">목록이 비어있습니다.</td>
                        </tr>
                        </tbody>
                    )}
                    {initialState.withdrawInfo?.map((withdrawInfo, index) => (
                        <tbody key={withdrawInfo.no}>
                        <tr>
                            <td align={"center"}>{withdrawInfo.no}</td>
                            <td align={"center"}>{withdrawInfo.account_name}</td>
                            <td align={"center"}></td>
                            <td align={"center"}>{withdrawInfo.nickname}</td>
                            <td align={"center"}>
                                <NumericFormat value={withdrawInfo.before_amt} displayType={'text'} thousandSeparator={true}/>
                            </td>
                            <td align={"center"} style={{backgroundColor:"red"}}><font color={"white"}>
                                <NumericFormat value={withdrawInfo.withdraw_amt} displayType={'text'} thousandSeparator={true}/>
                            </font> </td>
                            <td align={"center"}>
                                <NumericFormat value={withdrawInfo.result_amt} displayType={'text'} thousandSeparator={true}/>
                            </td>
                            <td align={"center"}>-</td>
                            <td align={"center"}>{withdrawInfo.request_dt}</td>
                            <td align={"center"}>{withdrawInfo.complete_dt}</td>
                            <td align={"center"}>-</td>
                            <td align={"center"} style={{backgroundColor:"red"}}><font color={"white"}>{withdrawInfo.withdraw_state_cd}</font> </td>
                            <td align={"center"}></td>
                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalCount && initialState.totalCount > 0 ?
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange} />
                    :""}
            </div>
        </div>

    )
}
export default WithdrawInfo;