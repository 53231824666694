import {call, put, take,} from "redux-saga/effects";
import {fetchLosingInfo} from "../../../lib/api";
import {failedGetLosingInfo, setLosingInfo} from "../../reducers/partner/LosingInfoReducer";

export function* getLosingInfoSaga(action) {
    try{
        const res = yield call(
            fetchLosingInfo,action.payload.input,
        )
        yield put(setLosingInfo(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetLosingInfo(error))
    }
}