import React, {useEffect, useState} from "react";
import axios from "axios";
import PlusMinusText from "../../components/common/PlusMinusText";
import {NumericFormat} from "react-number-format";
import moment from "moment/moment";
import BetSettlement from "../../constant/BetSettlement";
const BettingContainer = ({userId}) => {

    const [list, setList] = useState([])
    const [state, setState] = useState(new Map())
    const [stat, setStat] = useState(null)
    const [searchCd01, setSearchCd01] = useState(); //베팅머니
    const [searchCd02, setSearchCd02] = useState(); //게임결과
    const [selectedDate, setSelectedDate] = useState("today")
    const [isOpenAll, setIsOpenAll] = useState(false)

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    useEffect(() => {
        getBettingList()
    }, [startDate])

    const getBettingList = () => {
        axios.post('/admin/betting/getBettingList', {
            userId: userId,
            startDate: startDate,
            endDate: endDate,
            search01: searchCd01,
            search02: searchCd02
        }).then((res) => {
            setList(res.data.list)
            setStat(res.data.stat)
        })
    }

    const onClickSearch = (e) => {

        axios.post('/admin/betting/getBettingList', {
            userId: userId,
            startDate: startDate,
            endDate: endDate,
            search01: searchCd01,
            search02: searchCd02
        }).then((res) => {
            setList(res.data.list)
            setStat(res.data.stat)
        })
    }

    const search01ChangeHandler = (type) => {
        setSearchCd01(type)

        axios.post('/admin/betting/getBettingList', {
            userId: userId,
            startDate: startDate,
            endDate: endDate,
            search01: type,
            search02: searchCd02
        }).then((res) => {
            setList(res.data.list)
            setStat(res.data.stat)
        })
    }

    const search02ChangeHandler = (type) => {
        setSearchCd02(type)

        axios.post('/admin/betting/getBettingList', {
            userId: userId,
            startDate: startDate,
            endDate: endDate,
            search01: searchCd01,
            search02: type
        }).then((res) => {
            setList(res.data.list)
            setStat(res.data.stat)
        })
    }

    const getBettingDetails = (betNo) => {
        axios.post('/admin/betting/getBettingDetails', {
            betNo: betNo
        }).then((res) => {
            const details = res.data.result
            addItem(details[0].user_bet_no, details)
        })
    }

    const addItem = (key, value) => {
        setState((prev) => new Map([...prev, [key, value]]));
    };


    const upsertItem = (key, value) => {
        setState((prev) => new Map(prev).set(key, value));
    }

    const deleteItem = (key) => {
        setState((prev) => {
            const newState = new Map(prev);
            newState.delete(key);
            return newState;
        });
    }

    const clearAll = () => {
        setState((new Map()))
    }

    return (
        <div className="common">
            <div style={{ backgroundColor: "white"}}>
                <div style={{display: "flex"}}>
                    <div style={{flexGrow: "1"}}>
                        <div className="align-items-center" style={{display: "flex"}}>
                            <font color={"blue"} size="3">배팅내역</font>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "80px"}}>
                                <option selected>30개</option>
                            </select>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11" style={{color: "#1F68EE", fontWeight: "bold"}}>
                                데이터 자동 갱신(15초 단위)
                            </label>
                        </div>

                        <div className="align-items-center" style={{display: "flex"}}>
                            <button style={{ marginRight: "10px", backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}}>엑셀 다운로드</button>
                            <button style={{ backgroundColor: selectedDate === "360days"? "red": "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                                    onClick={(e) => {
                                        setStartDate(moment().add(-360, "days").format('YYYY-MM-DD'))
                                        setEndDate(moment().format('YYYY-MM-DD'))
                                        setSelectedDate("360days")
                                    }}
                            >365일</button>
                            <button style={{ backgroundColor: selectedDate === "180days"? "red": "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                                    onClick={(e) => {
                                        setStartDate(moment().add(-180, "days").format('YYYY-MM-DD'))
                                        setEndDate(moment().format('YYYY-MM-DD'))
                                        setSelectedDate("180days")
                                    }}
                            >180일</button>
                            <button style={{ backgroundColor: selectedDate === "90days"? "red": "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                                    onClick={(e) => {
                                        setStartDate(moment().add(-90, "days").format('YYYY-MM-DD'))
                                        setEndDate(moment().format('YYYY-MM-DD'))
                                        setSelectedDate("90days")
                                    }}
                            >90일</button>
                            <button style={{ backgroundColor: selectedDate === "60days"? "red": "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                                    onClick={(e) => {
                                        setStartDate(moment().add(-60, "days").format('YYYY-MM-DD'))
                                        setEndDate(moment().format('YYYY-MM-DD'))
                                        setSelectedDate("60days")
                                    }}
                            >60일</button>
                            <button style={{ backgroundColor: selectedDate === "30days"? "red": "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                                    onClick={(e) => {
                                        setStartDate(moment().add(-30, "days").format('YYYY-MM-DD'))
                                        setEndDate(moment().format('YYYY-MM-DD'))
                                        setSelectedDate("30days")
                                    }}
                            >30일</button>
                            <button style={{ backgroundColor: selectedDate === "week"? "red": "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                                    onClick={(e) => {
                                        setStartDate(moment().add(-1, "week").format('YYYY-MM-DD'))
                                        setEndDate(moment().format('YYYY-MM-DD'))
                                        setSelectedDate("week")
                                    }}
                            >7일</button>
                            <button style={{ backgroundColor: selectedDate === "yesterday"? "red": "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                                onClick={(e) => {
                                    setStartDate(moment().add(-1, "day").format('YYYY-MM-DD'))
                                    setEndDate(moment().add(-1, "day").format('YYYY-MM-DD'))
                                    setSelectedDate("yesterday")
                                }}
                            >어제</button>
                            <button style={{ backgroundColor: selectedDate === "today"? "red": "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                                onClick={(e) => {
                                    setStartDate(moment().format('YYYY-MM-DD'))
                                    setEndDate(moment().format('YYYY-MM-DD'))
                                    setSelectedDate("today")
                                }}
                            >오늘</button>
                            <button style={{ backgroundColor: isOpenAll? "red" : "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>베팅내역 전체 펼치기</button>
                            <div style={{display: "flex", paddingLeft: "5px", paddingRight: "5px", alignItems: "center"}}>
                                <span className="mx-1">◀</span>
                                <input className="form-control sm-date" type="date" value={startDate} onChange={startDateHandler} style={{width: "90px"}}/>
                                <span className="mx-1">~</span>
                                <input className="form-control sm-date" type="date" value={endDate} onChange={endDateHandler} style={{width: "90px"}}/>
                                <span className="mx-1">▶</span>
                            </div>
                        </div>

                        <div className="align-items-center" style={{display: "flex"}}>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "150px"}}>
                                <option selected>::총판/가맹점::</option>
                            </select>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "150px"}}>
                                <option selected>전체 P등급</option>
                            </select>
                            <div className="sm-center-div" style={{height: "30px"}}>
                                <label className="sm-label">
                                    게임결과 :
                                </label>
                                <input className="form-check-input"  type="radio" name="inlineRadio2" id="inlineRadio2" value={searchCd02} style={{ cursor: "pointer" }}
                                       onChange={() => search02ChangeHandler("")} defaultChecked={true}/> 전체
                                <input className="form-check-input"  type="radio" name="inlineRadio2" id="inlineRadio2" value={searchCd02} style={{ cursor: "pointer" }}
                                       onChange={() => search02ChangeHandler(0)} /> 베팅
                                <input className="form-check-input"  type="radio" name="inlineRadio2" id="inlineRadio2" value={searchCd02} style={{ cursor: "pointer" }}
                                       onChange={() => search02ChangeHandler(11)} /> 적중+적특
                                <input className="form-check-input"  type="radio" name="inlineRadio2" id="inlineRadio2" value={searchCd02} style={{ cursor: "pointer" }}
                                       onChange={() => search02ChangeHandler(1)} /> 적중
                                <input className="form-check-input"  type="radio" name="inlineRadio2" id="inlineRadio2" value={searchCd02} style={{ cursor: "pointer" }}
                                       onChange={() => search02ChangeHandler(12)} /> 적특
                                <input className="form-check-input"  type="radio" name="inlineRadio2" id="inlineRadio2" value={searchCd02} style={{ cursor: "pointer" }}
                                       onChange={() => search02ChangeHandler(2)} /> 실패
                                <input className="form-check-input"  type="radio" name="inlineRadio2" id="inlineRadio2" value={searchCd02} style={{ cursor: "pointer" }}
                                       onChange={() => search02ChangeHandler(13)} /> 취소
                            </div>
                        </div>


                        <div className="align-items-center" style={{display: "flex"}}>
                            <div className="sm-center-div" style={{height: "30px"}}>
                                <label>
                                    폴더갯수 :
                                </label>

                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    전체
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    1폴
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    2폴
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    3폴
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    4폴
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    5폴
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    6폴 이상
                                </label>
                            </div>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "150px"}}>
                                <option selected>[전체 종목]</option>
                            </select>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "150px"}}>
                                <option selected>아이디</option>
                            </select>
                            <input className="form-check-input sm-input me-1" type="text" defaultValue={userId? userId : ""} style={{height: "18px"}} />

                            <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px", fontWeight: "bold"}}>:: 적중 위험 베팅 확인 ::</button>
                        </div>
                    </div>
                    <button style={{
                        width: "50px", height: "50px"
                    }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                </div>
                <div style={{display: "flex"}}>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="me-1">
                            리그 :
                        </label>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>KBO</button>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>MLB</button>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>NPB</button>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>KBL</button>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>NBA</button>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>WKBL</button>
                    </div>

                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            베팅선택 :
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault1">
                            전체
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault2">
                            홈
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                            무
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                            원정
                        </label>
                    </div>
                </div>
                <div style={{display: "flex"}}>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            정렬 :
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault1">
                            베팅 최근 우선
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault2">
                            베팅 과거 우선
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                            결과처리 최근 우선
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                            color: "red"
                        }}>
                            배당률 큰 순
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                            color: "red"
                        }}>
                            당첨금 큰 순
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                            color: "red"
                        }}>
                            베팅금 큰 순
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                            color: "red",
                            backgroundColor: "yellow"
                        }}>
                            닉네임 순
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                            color: "red",
                            backgroundColor: "yellow"
                        }}>
                            닉네임 역순
                        </label>
                    </div>

                </div>
                <div style={{display: "flex"}}>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            게임구분 :
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            크로스
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            크로스2
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            일반게임
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            핸디캡
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            스페셜
                        </label>
                    </div>
                </div>

                <div style={{display: "flex"}}>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            알림베팅 :
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault1">
                            전체
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault2">
                            알림 베팅만
                        </label>
                    </div>

                </div>

                <div className="sm-center-div" style={{height: "30px"}}>
                    <label className="me-1">
                        게임구분 :
                    </label>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>전체선택</button>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>전체해제</button>
                    <button style={{ backgroundColor: "lightblue", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>일반게임</button>
                    <button style={{ backgroundColor: "lightsalmon", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>자동게임</button>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>크로스</button>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", fontWeight: "bold", width: "fit-content"}}>크로스2</button>
                </div>

                <div style={{display: "flex"}}>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            게임종류 :
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            승무패
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            핸디캡
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            오버언더
                        </label>
                    </div>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            회원구분 :
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            대기회원
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            무료회원
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            유료회원
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            VIP회원
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            가라회원
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            관리자
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            최고관리자
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11" style={{
                            backgroundColor: "yellow",
                            color: "red"
                        }}>
                            관심회원
                        </label>
                    </div>
                </div>

                <div className="sm-center-div" style={{height: "30px"}}>
                    <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                    <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                        조작된 베팅만 보기
                    </label>
                    <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                    <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                        중복 베팅만 보기
                    </label>
                </div>
                <div>
                    <table className="table table-bordered table-sm text-center align-middle" >
                        <tr>
                            <td colSpan="1" style={{backgroundColor:"lightgray"}}>현재 진행중인<br></br>실회원 베팅</td>
                            <td colSpan="1" style={{backgroundColor:"white"}}>
                                <font color="blue"><b><PlusMinusText number={stat?.progressTotalMoney}/></b> / <PlusMinusText number={stat?.progressTotalCount} /></font>
                            </td>
                            <td colSpan="1" style={{backgroundColor:"lightgray"}}>현재 진행중인<br></br>실회원 예상당첨금</td>
                            <td style={{backgroundColor:"white"}}>
                                <font color="blue"><b><PlusMinusText number={stat?.progressTotalWinMoney} /></b></font>
                            </td>
                            <td style={{backgroundColor:"lightgray"}}>조회 조건 내<br/>현재 진행중 베팅금</td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.progressMoney} />
                            </td>
                            <td style={{backgroundColor:"lightgray"}}></td>
                            <td style={{backgroundColor:"white"}}>
                                <font color="blue">-</font>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1" rowSpan="2" style={{backgroundColor:"lightgray"}}>총 건수 / 취소 제외 건수<br/>배팅 아이디수</td>
                            <td colSpan="1" style={{backgroundColor:"white"}}>
                                <span><PlusMinusText number={stat?.resultTotalCount} /> / <PlusMinusText number={stat?.resultExceptCancel} /></span>
                            </td>
                            <td colSpan="1" rowSpan="2" style={{backgroundColor:"lightgray"}}>배팅금(취소제외)<br/>평균 베팅금</td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.resultMoneyExceptCancel} />
                            </td>
                            <td rowSpan="2" style={{backgroundColor:"lightgray"}}>당첨금<br/>평균 당첨금</td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.resultWinMoney} />
                            </td>
                            <td rowSpan="2" style={{backgroundColor:"lightgray"}}>남은 예상당첨금</td>
                            <td rowSpan="2" style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.preWinMoney} />
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="1" style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.resultIdCount} />
                            </td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={Math.floor(stat?.avgMoney)} />
                            </td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={Math.floor(stat?.avgWinMoney)} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1" rowSpan="2" style={{backgroundColor:"lightgray"}}>단순마진</td>
                            <td colSpan="1" rowSpan="2" style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.simpleMargin} />
                            </td>
                            <td colSpan="1" rowSpan="2" style={{backgroundColor:"lightgray"}}>실제마진(단순 마진- 지급<br/>포인트</td>
                            <td rowSpan="2" style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.realMargin} />
                            </td>
                            <td rowSpan="2" style={{backgroundColor:"lightgray"}}>낙첨 지급 포인트<br/>추천인 지급 포인트</td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.totalPoint} />
                            </td>
                            <td rowSpan="2" style={{backgroundColor:"lightgray"}}>당첨 수 /실패 수<br/>베팅금 대비 당첨금 비율</td>
                            <td rowSpan="1" style={{backgroundColor:"white"}}>
                                <span color="red">
                                    <NumericFormat value={stat?.resultWin} displayType="text" thousandSeparator={true} />
                                    {" / "}
                                    <NumericFormat value={stat?.resultLose} displayType="text" thousandSeparator={true} />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={0} />
                            </td>
                            <td style={{backgroundColor:"white"}}>
                                <font color="blue"><PlusMinusText number={Math.floor(stat?.winMoneyRate * 100)} />%</font>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="sm-center-div-no-border" style={{height: "30px"}}>
                    <button className="me-1" style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", width: "fit-content"}}>유저 베팅내역 전체 삭제(유저에게서만 삭제됨)</button>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", width: "fit-content"}}>유저 베팅내역 전체 복원(유저에게서 복원됨)</button>
                </div>

                <div className="common">
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>번호</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>소속</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>가맹</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이피</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>회원분류</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>게임구분</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>상세내역</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>User&Game</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>User&All</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>IP&Game</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>IP&All</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="2">적중/조합</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>베팅일자</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>베팅액</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>배당률</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>당첨금</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>예상당첨금</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>낙첨포인트</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>배팅상태</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>조작여부</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>사용자삭제여부</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>처리</td>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map((item, index) => (
                            <>
                                <tr>
                                    <td>{item.no}</td>
                                    <td>{item.store_cd}</td>
                                    <td>{item.psnl_cd}</td>
                                    <td style={{backgroundColor: "gray"}}><span style={{color: "yellow"}}>{item.id}</span></td>
                                    <td style={{backgroundColor: "gray"}}><span style={{color: "yellow"}}>{item.nickname}</span></td>
                                    <td>{item.bet_ip}</td>
                                    <td><span style={{color: "green"}}>{item.user_gb_cd}</span></td>
                                    <td>{item.type}</td>
                                    <td><button className="btn btn-small btn-success" onClick={(e) => {
                                        console.log(state.get(item.no))
                                        if(state.get(item.no) !== undefined) {
                                            upsertItem(item.no, undefined)
                                        }
                                        else {
                                            getBettingDetails(item.no)
                                        }

                                    }}>상세정보</button></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{item.win_count}</td>
                                    <td>{item.folder_count}</td>
                                    <td>{item.createdAt}</td>
                                    <td><PlusMinusText number={item.money} /></td>
                                    <td>{item.total_ratio.toFixed(2)}</td>
                                    <td><PlusMinusText number={item.result_money} /></td>
                                    <td><PlusMinusText number={item.win_money} /></td>
                                    <td><PlusMinusText number={item.result_point} /></td>
                                    <td>{BetSettlement.getKorMessage(item.result)}</td>
                                    <td>-</td>
                                    <td>{item.is_deleted === 0? "X" : "O"}</td>
                                    <td><button className="btn btn-small btn-danger">취소</button></td>
                                </tr>
                                {state.get(item.no) && (
                                    <tr>
                                        <td colSpan="25">
                                            <table className="table table-bordered table-sm text-center align-middle">
                                                <thead>
                                                <tr>
                                                    <td>게임번호</td>
                                                    <td>경기일자</td>
                                                    <td>게임종류</td>
                                                    <td>종목</td>
                                                    <td>리그</td>
                                                    <td>홈 vs 원정</td>
                                                    <td>User&Game</td>
                                                    <td>User&All</td>
                                                    <td>IP&Game</td>
                                                    <td>IP&All</td>
                                                    <td>승</td>
                                                    <td>무/기준점</td>
                                                    <td>패</td>
                                                    <td>스코어</td>
                                                    <td>결과</td>
                                                    <td>수정일자</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {state.get(item.no).map((detail, dIndex) => (
                                                    <tr>
                                                        <td>{detail.no}</td>
                                                        <td>{detail.start_date}</td>
                                                        <td>{detail.market}</td>
                                                        <td>{detail.sport}</td>
                                                        <td>{detail.league}</td>
                                                        <td>{`${detail.home} vs ${detail.away}`}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td style={{
                                                            backgroundColor: detail.bet_no === 1? "green" : "white"
                                                        }}>{detail.bet1.toFixed(2)}</td>
                                                        <td style={{
                                                            backgroundColor: detail.bet_no === 2? "green" : "white"
                                                        }}>{detail.bet2 !== null?
                                                            detail.bet2:
                                                            (detail.baseline !== null?
                                                                (detail.market.includes("Under") || detail.market.includes("Handicap")?
                                                                    (detail.market.includes("Under")? "O/U " : detail.market.includes("Handicap")? "H " : ""):
                                                                    "")
                                                                    + detail.baseline.split(" (0")[0]:
                                                                "VS")}</td>
                                                        <td style={{
                                                            backgroundColor: detail.bet_no === 3? "green" : "white"
                                                        }}>{detail.bet3.toFixed(2)}</td>
                                                        <td>{`${detail.score_home !== null? detail.score_home : "-"} : ${detail.score_away !== null? detail.score_away : "-"}`}</td>
                                                        <td>{BetSettlement.getKorMessage(detail.result)}</td>
                                                        <td>{detail.updatedAt}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                )}

                            </>

                        ))}

                        </tbody>
                    </table>
                </div>

            </div>
        </div>

    )
}
export default BettingContainer;