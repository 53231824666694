import {call, put, take,} from "redux-saga/effects";
import {fetchDepositWithdrawCombine} from "../../../lib/api";
import {
    failedGetDepositWithdrawCombine,
    failedGetDepositWithdrawLog,
    setDepositWithdrawCombine,
    setDepositWithdrawLog
} from "../../reducers/deposit/DepositWithdrawCombineReducer";

export function* getDepositWithdrawCombineSaga(action) {
    try{
        const res = yield call(
            fetchDepositWithdrawCombine,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd, action.payload.searchCd01
        )
        yield put(setDepositWithdrawCombine(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDepositWithdrawCombine(error))
    }
}