import React from "react";
import CheckContainer from "../../containers/board/CheckContainer";
const Check = () => {



    return (
        <div className="sm-font">
            <p>
                <span style={{ color: "#1F68EE", fontWeight: "bold" }}>출석체크</span>
            </p>
            <CheckContainer />
        </div>

    )
}
export default Check;