import React from "react";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
import {useDispatch} from "react-redux";

const SameList = ({state, onRefresh}) => {
    const dispatch = useDispatch()
    return (
        <div className="common">
            <table className="table table-bordered table-sm text-center align-middle">
                <thead>
                <tr>
                    <td rowSpan="2" style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>번호</td>
                    <td colSpan="5" style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>새로 로그인</td>
                    <td colSpan="4" style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>이전 로그인</td>
                </tr>
                <tr>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>시간</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이피</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>소속</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>구분</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>유저</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>유저</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>구분</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>소속</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>시간</td>
                </tr>
                </thead>
                <tbody>
                {state?.sameBrowsers && state.sameBrowsers.map((item, index) => (
                    <tr>
                        <td>{item.no}</td>
                        <td>{item.recent_login_dt}</td>
                        <td><span>{item.use_ip}</span></td>
                        <td><span>{item.recent_store_cd}</span></td>
                        <td><span style={{ color: "#5dc434" }}>{item.recent_user_gb_cd}</span></td>
                        <td style={{backgroundColor: item.recent_back_ground_color, cursor: "pointer"}} onClick={(e) => {
                            dispatch(openUserSimpleInfo({
                                isOpen: true,
                                openId: item.recent_id,
                                xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                            }))
                        }}><span style={{ fontWeight: "bold", color: item.recent_font_color }}>{item.recent_nickname}</span></td>
                        <td style={{backgroundColor: item.before_back_ground_color, cursor: "pointer"}} onClick={(e) => {
                            dispatch(openUserSimpleInfo({
                                isOpen: true,
                                openId: item.before_id,
                                xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                            }))
                        }}><span style={{ fontWeight: "bold", color: item.before_font_color }}>{item.before_nickname}</span></td>
                        <td><span style={{ color: "#5dc434" }}>{item.before_user_gb_cd}</span></td>
                        <td><span>{item.before_store_cd}</span></td>
                        <td>{item.before_login_dt}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default SameList