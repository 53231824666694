import React from "react";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import MiniKorean from "../../../constant/MiniKorean";
const MiniGameList = ({state}) => {
    const dispatch = useDispatch()

    return (
        <div className="container-fluid">
            {state.isLoading && "로딩중..."}
            {!state.isLoading && state.miniGames && (
                <table className="table table-bordered table-hover text-center">
                <thead>
                <tr>
                    <th>고유ID</th>
                    <th>경기일정</th>
                    <th>회차</th>
                    <th>상태</th>
                    <th>업체</th>
                    <th>종목</th>
                    <th>종류</th>
                    <th>홈/홀/플/3줄/언더(배당)</th>
                    <th>무/타이(배당)</th>
                    <th>원정/짝/뱅/4줄/오버(배당)</th>
                </tr>
                </thead>
                <tbody>
                    {state.miniGames.length === 0 && (
                        <tr>
                            <td colSpan="3">목록이 비어있습니다.</td>
                        </tr>
                    )}
                    {state.miniGames.length > 0 && state.miniGames.map((item, index) => (
                        <tr>
                            <td>{item.id}</td>
                            <td>{moment(item.start_date).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{item.league}</td>
                            <td>{item.status === 0? "대기": item.status === 4? "완료" : "진행"}</td>
                            <td>{MiniKorean.getKorProvider(item.provider)}</td>
                            <td>{MiniKorean.getKorSport(item.sport)}</td>
                            <td>{MiniKorean.getKorMarket(item.market)}</td>
                            <td>
                                {`${item.home} (${item.bet1})`}
                            </td>
                            <td>{item.bet2}</td>
                            <td>{`${item.away} (${item.bet3})`}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            )}
        </div>
    );

}

export default MiniGameList;