import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const withdrawInfoSlice = createSlice({
    name: 'withdrawInfo',
    initialState: {
        isLoading: false,
        error: null,
        withdrawInfo: [],
        countList: {},
        page: 1,
        pageSize: 10,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        userId: "",
        userLvl:""
    },
    reducers: {
        getWithdrawInfo(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userId = action.payload.userId;
        },
        setWithdrawInfo(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.withdrawInfo = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList;
        },
        failedGetWithdrawInfo(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setUserLevel(state, action) {
            state.isLoading = false;
            state.userLvl = action.payload;
        },
    }
});

export const { getWithdrawInfo, setWithdrawInfo, failedGetWithdrawInfo,setUserLevel } = withdrawInfoSlice.actions;

export default withdrawInfoSlice.reducer;