import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"


export const levelSettingSlice = createSlice({
    name: 'levelSetting',
    initialState: {
        isLoading: false,
        error: null,
        levelSetting: [],
        countList: {},
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
        userGbCd: "",
        searchGbCd: "",
        arrayList: [],
        box01Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box01Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box01Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box01Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box01Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box01Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box01Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val8array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val9array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val10array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val11array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val12array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box02Val13array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box03Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box03Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box03Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box03Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box03Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box03Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box03Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box03Val8array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box03Val9array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box03Val10array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val8array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val9array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val10array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val11array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val12array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val13array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val14array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val15array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val16array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val17array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val18array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val19array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val20array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val21array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val22array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val23array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val24array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val25array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val26array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val27array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val28array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val29array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val30array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val31array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val32array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val33array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val34array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val35array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val36array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val37array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val38array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val39array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val40array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val41array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val42array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val43array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val44array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val45array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val46array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val47array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box04Val48array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val8array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val9array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val10array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val11array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val12array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val13array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val14array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val15array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val16array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val17array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val18array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val19array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val20array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val21array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val22array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val23array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val24array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val25array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val26array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val27array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val28array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val29array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val30array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val31array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val32array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val33array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val34array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val35array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val36array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val37array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val38array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val39array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val40array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val41array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val42array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val43array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val44array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val45array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val46array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val47array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box05Val48array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val8array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val9array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val10array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val11array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val12array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val13array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val14array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val15array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val16array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val17array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val18array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val19array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val20array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val21array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val22array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val23array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val24array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val25array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val26array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val27array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val28array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val29array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val30array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val31array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val32array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val33array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val34array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val35array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val36array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val37array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val38array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val39array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val40array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val41array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val42array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val43array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val44array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val45array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val46array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val47array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box06Val48array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val8array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val9array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val10array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val11array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val12array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val13array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val14array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val15array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val16array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val17array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val18array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val19array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val20array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val21array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val22array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val23array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val24array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val25array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val26array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val27array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val28array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val29array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val30array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val31array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val32array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val33array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val34array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val35array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val36array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val37array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val38array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val39array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val40array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val41array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val42array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val43array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val44array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val45array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val46array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val47array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box07Val48array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box08Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box08Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box08Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box08Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box08Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box08Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box08Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box09Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box09Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box09Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box09Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box09Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box09Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box09Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box10Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box10Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box10Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box10Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box10Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box10Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box10Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box11Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box11Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box11Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box11Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box11Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box11Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box11Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box12Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box12Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box12Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box12Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box12Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box12Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box12Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box13Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box13Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box13Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box13Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box13Val5array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box13Val6array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box13Val7array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box14Val1array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box14Val2array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box14Val3array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        box14Val4array	: ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""]

    },
    reducers: {
        getLevelSetting(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userGbCd = action.payload.userGbCd;
            state.searchGbCd = action.payload.searchGbCd;

        },
        setLevelSetting(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.levelSetting = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList;
            state.arrayList = action.payload.arrayList;

        },
        failedGeLevelSetting(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setBox01Val1array(state,action){
            state.box01Val1array = action.payload;
        },
        setBox01Val2array(state,action){
            state.box01Val2array = action.payload;
        },
        setBox01Val3array(state,action){
            state.box01Val3array = action.payload;
        },
        setBox01Val4array(state,action){
            state.box01Val4array = action.payload;
        },
        setBox01Val5array(state,action){
            state.box01Val5array = action.payload;
        },
        setBox01Val6array(state,action){
            state.box01Val6array = action.payload;
        },
        setBox01Val7array(state,action){
            state.box01Val7array = action.payload;
        },
        setBox02Val1array(state,action){
            state.box02Val1array = action.payload;
        },
        setBox02Val2array(state,action){
            state.box02Val2array = action.payload;
        },
        setBox02Val3array(state,action){
            state.box02Val3array = action.payload;
        },
        setBox02Val4array(state,action){
            state.box02Val4array = action.payload;
        },
        setBox02Val5array(state,action){
            state.box02Val5array = action.payload;
        },
        setBox02Val6array(state,action){
            state.box02Val6array = action.payload;
        },
        setBox02Val7array(state,action){
            state.box02Val7array = action.payload;
        },
        setBox02Val8array(state,action){
            state.box02Val8array = action.payload;
        },
        setBox02Val9array(state,action){
            state.box02Val9array = action.payload;
        },
        setBox02Val10array(state,action){
            state.box02Val10array = action.payload;
        },
        setBox02Val11array(state,action){
            state.box02Val11array = action.payload;
        },
        setBox02Val12array(state,action){
            state.box02Val12array = action.payload;
        },
        setBox02Val13array(state,action){
            state.box02Val13array = action.payload;
        },
        setBox03Val1array(state,action){
            state.box03Val1array = action.payload;
        },
        setBox03Val2array(state,action){
            state.box03Val2array = action.payload;
        },
        setBox03Val3array(state,action){
            state.box03Val3array = action.payload;
        },
        setBox03Val4array(state,action){
            state.box03Val4array = action.payload;
        },
        setBox03Val5array(state,action){
            state.box03Val5array = action.payload;
        },
        setBox03Val6array(state,action){
            state.box03Val6array = action.payload;
        },
        setBox03Val7array(state,action){
            state.box03Val7array = action.payload;
        },
        setBox03Val8array(state,action){
            state.box03Val8array = action.payload;
        },
        setBox03Val9array(state,action){
            state.box03Val9array = action.payload;
        },
        setBox03Val10array(state,action){
            state.box03Val10array = action.payload;
        },
        setBox04Val1array(state,action){
            state.box04Val1array = action.payload;
        },
        setBox04Val2array(state,action){
            state.box04Val2array = action.payload;
        },
        setBox04Val3array(state,action){
            state.box04Val3array = action.payload;
        },
        setBox04Val4array(state,action){
            state.box04Val4array = action.payload;
        },
        setBox04Val5array(state,action){
            state.box04Val5array = action.payload;
        },
        setBox04Val6array(state,action){
            state.box04Val6array = action.payload;
        },
        setBox04Val7array(state,action){
            state.box04Val7array = action.payload;
        },
        setBox04Val8array(state,action){
            state.box04Val8array = action.payload;
        },
        setBox04Val9array(state,action){
            state.box04Val9array = action.payload;
        },
        setBox04Val10array(state,action){
            state.box04Val10array = action.payload;
        },
        setBox04Val11array(state,action){
            state.box04Val11array = action.payload;
        },
        setBox04Val12array(state,action){
            state.box04Val12array = action.payload;
        },
        setBox04Val13array(state,action){
            state.box04Val13array = action.payload;
        },
        setBox04Val14array(state,action){
            state.box04Val14array = action.payload;
        },
        setBox04Val15array(state,action){
            state.box04Val15array = action.payload;
        },
        setBox04Val16array(state,action){
            state.box04Val16array = action.payload;
        },
        setBox04Val17array(state,action){
            state.box04Val17array = action.payload;
        },
        setBox04Val18array(state,action){
            state.box04Val18array = action.payload;
        },
        setBox04Val19array(state,action){
            state.box04Val19array = action.payload;
        },
        setBox04Val20array(state,action){
            state.box04Val20array = action.payload;
        },
        setBox04Val21array(state,action){
            state.box04Val21array = action.payload;
        },
        setBox04Val22array(state,action){
            state.box04Val22array = action.payload;
        },
        setBox04Val23array(state,action){
            state.box04Val23array = action.payload;
        },
        setBox04Val24array(state,action){
            state.box04Val24array = action.payload;
        },
        setBox04Val25array(state,action){
            state.box04Val25array = action.payload;
        },
        setBox04Val26array(state,action){
            state.box04Val26array = action.payload;
        },
        setBox04Val27array(state,action){
            state.box04Val27array = action.payload;
        },
        setBox04Val28array(state,action){
            state.box04Val28array = action.payload;
        },
        setBox04Val29array(state,action){
            state.box04Val29array = action.payload;
        },
        setBox04Val30array(state,action){
            state.box04Val30array = action.payload;
        },
        setBox04Val31array(state,action){
            state.box04Val31array = action.payload;
        },
        setBox04Val32array(state,action){
            state.box04Val32array = action.payload;
        },
        setBox04Val33array(state,action){
            state.box04Val33array = action.payload;
        },
        setBox04Val34array(state,action){
            state.box04Val34array = action.payload;
        },
        setBox04Val35array(state,action){
            state.box04Val35array = action.payload;
        },
        setBox04Val36array(state,action){
            state.box04Val36array = action.payload;
        },
        setBox04Val37array(state,action){
            state.box04Val37array = action.payload;
        },
        setBox04Val38array(state,action){
            state.box04Val38array = action.payload;
        },
        setBox04Val39array(state,action){
            state.box04Val39array = action.payload;
        },
        setBox04Val40array(state,action){
            state.box04Val40array = action.payload;
        },
        setBox04Val41array(state,action){
            state.box04Val41array = action.payload;
        },
        setBox04Val42array(state,action){
            state.box04Val42array = action.payload;
        },
        setBox04Val43array(state,action){
            state.box04Val43array = action.payload;
        },
        setBox04Val44array(state,action){
            state.box04Val44array = action.payload;
        },
        setBox04Val45array(state,action){
            state.box04Val45array = action.payload;
        },
        setBox04Val46array(state,action){
            state.box04Val46array = action.payload;
        },
        setBox04Val47array(state,action){
            state.box04Val47array = action.payload;
        },
        setBox04Val48array(state,action){
            state.box04Val48array = action.payload;
        },
        setBox05Val1array(state,action){
            state.box05Val1array = action.payload;
        },
        setBox05Val2array(state,action){
            state.box05Val2array = action.payload;
        },
        setBox05Val3array(state,action){
            state.box05Val3array = action.payload;
        },
        setBox05Val4array(state,action){
            state.box05Val4array = action.payload;
        },
        setBox05Val5array(state,action){
            state.box05Val5array = action.payload;
        },
        setBox05Val6array(state,action){
            state.box05Val6array = action.payload;
        },
        setBox05Val7array(state,action){
            state.box05Val7array = action.payload;
        },
        setBox05Val8array(state,action){
            state.box05Val8array = action.payload;
        },
        setBox05Val9array(state,action){
            state.box05Val9array = action.payload;
        },
        setBox05Val10array(state,action){
            state.box05Val10array = action.payload;
        },
        setBox05Val11array(state,action){
            state.box05Val11array = action.payload;
        },
        setBox05Val12array(state,action){
            state.box05Val12array = action.payload;
        },
        setBox05Val13array(state,action){
            state.box05Val13array = action.payload;
        },
        setBox05Val14array(state,action){
            state.box05Val14array = action.payload;
        },
        setBox05Val15array(state,action){
            state.box05Val15array = action.payload;
        },
        setBox05Val16array(state,action){
            state.box05Val16array = action.payload;
        },
        setBox05Val17array(state,action){
            state.box05Val17array = action.payload;
        },
        setBox05Val18array(state,action){
            state.box05Val18array = action.payload;
        },
        setBox05Val19array(state,action){
            state.box05Val19array = action.payload;
        },
        setBox05Val20array(state,action){
            state.box05Val20array = action.payload;
        },
        setBox05Val21array(state,action){
            state.box05Val21array = action.payload;
        },
        setBox05Val22array(state,action){
            state.box05Val22array = action.payload;
        },
        setBox05Val23array(state,action){
            state.box05Val23array = action.payload;
        },
        setBox05Val24array(state,action){
            state.box05Val24array = action.payload;
        },
        setBox05Val25array(state,action){
            state.box05Val25array = action.payload;
        },
        setBox05Val26array(state,action){
            state.box05Val26array = action.payload;
        },
        setBox05Val27array(state,action){
            state.box05Val27array = action.payload;
        },
        setBox05Val28array(state,action){
            state.box05Val28array = action.payload;
        },
        setBox05Val29array(state,action){
            state.box05Val29array = action.payload;
        },
        setBox05Val30array(state,action){
            state.box05Val30array = action.payload;
        },
        setBox05Val31array(state,action){
            state.box05Val31array = action.payload;
        },
        setBox05Val32array(state,action){
            state.box05Val32array = action.payload;
        },
        setBox05Val33array(state,action){
            state.box05Val33array = action.payload;
        },
        setBox05Val34array(state,action){
            state.box05Val34array = action.payload;
        },
        setBox05Val35array(state,action){
            state.box05Val35array = action.payload;
        },
        setBox05Val36array(state,action){
            state.box05Val36array = action.payload;
        },
        setBox05Val37array(state,action){
            state.box05Val37array = action.payload;
        },
        setBox05Val38array(state,action){
            state.box05Val38array = action.payload;
        },
        setBox05Val39array(state,action){
            state.box05Val39array = action.payload;
        },
        setBox05Val40array(state,action){
            state.box05Val40array = action.payload;
        },
        setBox05Val41array(state,action){
            state.box05Val41array = action.payload;
        },
        setBox05Val42array(state,action){
            state.box05Val42array = action.payload;
        },
        setBox05Val43array(state,action){
            state.box05Val43array = action.payload;
        },
        setBox05Val44array(state,action){
            state.box05Val44array = action.payload;
        },
        setBox05Val45array(state,action){
            state.box05Val45array = action.payload;
        },
        setBox05Val46array(state,action){
            state.box05Val46array = action.payload;
        },
        setBox05Val47array(state,action){
            state.box05Val47array = action.payload;
        },
        setBox05Val48array(state,action){
            state.box05Val48array = action.payload;
        },
        setBox06Val1array(state,action){
            state.box06Val1array = action.payload;
        },
        setBox06Val2array(state,action){
            state.box06Val2array = action.payload;
        },
        setBox06Val3array(state,action){
            state.box06Val3array = action.payload;
        },
        setBox06Val4array(state,action){
            state.box06Val4array = action.payload;
        },
        setBox06Val5array(state,action){
            state.box06Val5array = action.payload;
        },
        setBox06Val6array(state,action){
            state.box06Val6array = action.payload;
        },
        setBox06Val7array(state,action){
            state.box06Val7array = action.payload;
        },
        setBox06Val8array(state,action){
            state.box06Val8array = action.payload;
        },
        setBox06Val9array(state,action){
            state.box06Val9array = action.payload;
        },
        setBox06Val10array(state,action){
            state.box06Val10array = action.payload;
        },
        setBox06Val11array(state,action){
            state.box06Val11array = action.payload;
        },
        setBox06Val12array(state,action){
            state.box06Val12array = action.payload;
        },
        setBox06Val13array(state,action){
            state.box06Val13array = action.payload;
        },
        setBox06Val14array(state,action){
            state.box06Val14array = action.payload;
        },
        setBox06Val15array(state,action){
            state.box06Val15array = action.payload;
        },
        setBox06Val16array(state,action){
            state.box06Val16array = action.payload;
        },
        setBox06Val17array(state,action){
            state.box06Val17array = action.payload;
        },
        setBox06Val18array(state,action){
            state.box06Val18array = action.payload;
        },
        setBox06Val19array(state,action){
            state.box06Val19array = action.payload;
        },
        setBox06Val20array(state,action){
            state.box06Val20array = action.payload;
        },
        setBox06Val21array(state,action){
            state.box06Val21array = action.payload;
        },
        setBox06Val22array(state,action){
            state.box06Val22array = action.payload;
        },
        setBox06Val23array(state,action){
            state.box06Val23array = action.payload;
        },
        setBox06Val24array(state,action){
            state.box06Val24array = action.payload;
        },
        setBox06Val25array(state,action){
            state.box06Val25array = action.payload;
        },
        setBox06Val26array(state,action){
            state.box06Val26array = action.payload;
        },
        setBox06Val27array(state,action){
            state.box06Val27array = action.payload;
        },
        setBox06Val28array(state,action){
            state.box06Val28array = action.payload;
        },
        setBox06Val29array(state,action){
            state.box06Val29array = action.payload;
        },
        setBox06Val30array(state,action){
            state.box06Val30array = action.payload;
        },
        setBox06Val31array(state,action){
            state.box06Val31array = action.payload;
        },
        setBox06Val32array(state,action){
            state.box06Val32array = action.payload;
        },
        setBox06Val33array(state,action){
            state.box06Val33array = action.payload;
        },
        setBox06Val34array(state,action){
            state.box06Val34array = action.payload;
        },
        setBox06Val35array(state,action){
            state.box06Val35array = action.payload;
        },
        setBox06Val36array(state,action){
            state.box06Val36array = action.payload;
        },
        setBox06Val37array(state,action){
            state.box06Val37array = action.payload;
        },
        setBox06Val38array(state,action){
            state.box06Val38array = action.payload;
        },
        setBox06Val39array(state,action){
            state.box06Val39array = action.payload;
        },
        setBox06Val40array(state,action){
            state.box06Val40array = action.payload;
        },
        setBox06Val41array(state,action){
            state.box06Val41array = action.payload;
        },
        setBox06Val42array(state,action){
            state.box06Val42array = action.payload;
        },
        setBox06Val43array(state,action){
            state.box06Val43array = action.payload;
        },
        setBox06Val44array(state,action){
            state.box06Val44array = action.payload;
        },
        setBox06Val45array(state,action){
            state.box06Val45array = action.payload;
        },
        setBox06Val46array(state,action){
            state.box06Val46array = action.payload;
        },
        setBox06Val47array(state,action){
            state.box06Val47array = action.payload;
        },
        setBox06Val48array(state,action){
            state.box06Val48array = action.payload;
        },
        setBox07Val1array(state,action){
            state.box07Val1array = action.payload;
        },
        setBox07Val2array(state,action){
            state.box07Val2array = action.payload;
        },
        setBox07Val3array(state,action){
            state.box07Val3array = action.payload;
        },
        setBox07Val4array(state,action){
            state.box07Val4array = action.payload;
        },
        setBox07Val5array(state,action){
            state.box07Val5array = action.payload;
        },
        setBox07Val6array(state,action){
            state.box07Val6array = action.payload;
        },
        setBox07Val7array(state,action){
            state.box07Val7array = action.payload;
        },
        setBox07Val8array(state,action){
            state.box07Val8array = action.payload;
        },
        setBox07Val9array(state,action){
            state.box07Val9array = action.payload;
        },
        setBox07Val10array(state,action){
            state.box07Val10array = action.payload;
        },
        setBox07Val11array(state,action){
            state.box07Val11array = action.payload;
        },
        setBox07Val12array(state,action){
            state.box07Val12array = action.payload;
        },
        setBox07Val13array(state,action){
            state.box07Val13array = action.payload;
        },
        setBox07Val14array(state,action){
            state.box07Val14array = action.payload;
        },
        setBox07Val15array(state,action){
            state.box07Val15array = action.payload;
        },
        setBox07Val16array(state,action){
            state.box07Val16array = action.payload;
        },
        setBox07Val17array(state,action){
            state.box07Val17array = action.payload;
        },
        setBox07Val18array(state,action){
            state.box07Val18array = action.payload;
        },
        setBox07Val19array(state,action){
            state.box07Val19array = action.payload;
        },
        setBox07Val20array(state,action){
            state.box07Val20array = action.payload;
        },
        setBox07Val21array(state,action){
            state.box07Val21array = action.payload;
        },
        setBox07Val22array(state,action){
            state.box07Val22array = action.payload;
        },
        setBox07Val23array(state,action){
            state.box07Val23array = action.payload;
        },
        setBox07Val24array(state,action){
            state.box07Val24array = action.payload;
        },
        setBox07Val25array(state,action){
            state.box07Val25array = action.payload;
        },
        setBox07Val26array(state,action){
            state.box07Val26array = action.payload;
        },
        setBox07Val27array(state,action){
            state.box07Val27array = action.payload;
        },
        setBox07Val28array(state,action){
            state.box07Val28array = action.payload;
        },
        setBox07Val29array(state,action){
            state.box07Val29array = action.payload;
        },
        setBox07Val30array(state,action){
            state.box07Val30array = action.payload;
        },
        setBox07Val31array(state,action){
            state.box07Val31array = action.payload;
        },
        setBox07Val32array(state,action){
            state.box07Val32array = action.payload;
        },
        setBox07Val33array(state,action){
            state.box07Val33array = action.payload;
        },
        setBox07Val34array(state,action){
            state.box07Val34array = action.payload;
        },
        setBox07Val35array(state,action){
            state.box07Val35array = action.payload;
        },
        setBox07Val36array(state,action){
            state.box07Val36array = action.payload;
        },
        setBox07Val37array(state,action){
            state.box07Val37array = action.payload;
        },
        setBox07Val38array(state,action){
            state.box07Val38array = action.payload;
        },
        setBox07Val39array(state,action){
            state.box07Val39array = action.payload;
        },
        setBox07Val40array(state,action){
            state.box07Val40array = action.payload;
        },
        setBox07Val41array(state,action){
            state.box07Val41array = action.payload;
        },
        setBox07Val42array(state,action){
            state.box07Val42array = action.payload;
        },
        setBox07Val43array(state,action){
            state.box07Val43array = action.payload;
        },
        setBox07Val44array(state,action){
            state.box07Val44array = action.payload;
        },
        setBox07Val45array(state,action){
            state.box07Val45array = action.payload;
        },
        setBox07Val46array(state,action){
            state.box07Val46array = action.payload;
        },
        setBox07Val47array(state,action){
            state.box07Val47array = action.payload;
        },
        setBox07Val48array(state,action){
            state.box07Val48array = action.payload;
        },
        setBox08Val1array(state,action){
            state.box08Val1array = action.payload;
        },
        setBox08Val2array(state,action){
            state.box08Val2array = action.payload;
        },
        setBox08Val3array(state,action){
            state.box08Val3array = action.payload;
        },
        setBox08Val4array(state,action){
            state.box08Val4array = action.payload;
        },
        setBox08Val5array(state,action){
            state.box08Val5array = action.payload;
        },
        setBox08Val6array(state,action){
            state.box08Val6array = action.payload;
        },
        setBox08Val7array(state,action){
            state.box08Val7array = action.payload;
        },
        setBox09Val1array(state,action){
            state.box09Val1array = action.payload;
        },
        setBox09Val2array(state,action){
            state.box09Val2array = action.payload;
        },
        setBox09Val3array(state,action){
            state.box09Val3array = action.payload;
        },
        setBox09Val4array(state,action){
            state.box09Val4array = action.payload;
        },
        setBox09Val5array(state,action){
            state.box09Val5array = action.payload;
        },
        setBox09Val6array(state,action){
            state.box09Val6array = action.payload;
        },
        setBox09Val7array(state,action){
            state.box09Val7array = action.payload;
        },
        setBox10Val1array(state,action){
            state.box10Val1array = action.payload;
        },
        setBox10Val2array(state,action){
            state.box10Val2array = action.payload;
        },
        setBox10Val3array(state,action){
            state.box10Val3array = action.payload;
        },
        setBox10Val4array(state,action){
            state.box10Val4array = action.payload;
        },
        setBox10Val5array(state,action){
            state.box10Val5array = action.payload;
        },
        setBox10Val6array(state,action){
            state.box10Val6array = action.payload;
        },
        setBox10Val7array(state,action){
            state.box10Val7array = action.payload;
        },
        setBox11Val1array(state,action){
            state.box11Val1array = action.payload;
        },
        setBox11Val2array(state,action){
            state.box11Val2array = action.payload;
        },
        setBox11Val3array(state,action){
            state.box11Val3array = action.payload;
        },
        setBox11Val4array(state,action){
            state.box11Val4array = action.payload;
        },
        setBox11Val5array(state,action){
            state.box11Val5array = action.payload;
        },
        setBox11Val6array(state,action){
            state.box11Val6array = action.payload;
        },
        setBox11Val7array(state,action){
            state.box11Val7array = action.payload;
        },
        setBox12Val1array(state,action){
            state.box12Val1array = action.payload;
        },
        setBox12Val2array(state,action){
            state.box12Val2array = action.payload;
        },
        setBox12Val3array(state,action){
            state.box12Val3array = action.payload;
        },
        setBox12Val4array(state,action){
            state.box12Val4array = action.payload;
        },
        setBox12Val5array(state,action){
            state.box12Val5array = action.payload;
        },
        setBox12Val6array(state,action){
            state.box12Val6array = action.payload;
        },
        setBox12Val7array(state,action){
            state.box12Val7array = action.payload;
        },
        setBox13Val1array(state,action){
            state.box13Val1array = action.payload;
        },
        setBox13Val2array(state,action){
            state.box13Val2array = action.payload;
        },
        setBox13Val3array(state,action){
            state.box13Val3array = action.payload;
        },
        setBox13Val4array(state,action){
            state.box13Val4array = action.payload;
        },
        setBox13Val5array(state,action){
            state.box13Val5array = action.payload;
        },
        setBox13Val6array(state,action){
            state.box13Val6array = action.payload;
        },
        setBox13Val7array(state,action){
            state.box13Val7array = action.payload;
        },
        setBox14Val1array(state,action){
            state.box14Val1array = action.payload;
        },
        setBox14Val2array(state,action){
            state.box14Val2array = action.payload;
        },
        setBox14Val3array(state,action){
            state.box14Val3array = action.payload;
        },
        setBox14Val4array(state,action){
            state.box14Val4array = action.payload;
        }
    }
});

export const { getLevelSetting, setLevelSetting, failedGeLevelSetting
    ,setBox01Val1array
    ,setBox01Val2array
    ,setBox01Val3array
    ,setBox01Val4array
    ,setBox01Val5array
    ,setBox01Val6array
    ,setBox01Val7array
    ,setBox02Val1array
    ,setBox02Val2array
    ,setBox02Val3array
    ,setBox02Val4array
    ,setBox02Val5array
    ,setBox02Val6array
    ,setBox02Val7array
    ,setBox02Val8array
    ,setBox02Val9array
    ,setBox02Val10array
    ,setBox02Val11array
    ,setBox02Val12array
    ,setBox02Val13array
    ,setBox03Val1array
    ,setBox03Val2array
    ,setBox03Val3array
    ,setBox03Val4array
    ,setBox03Val5array
    ,setBox03Val6array
    ,setBox03Val7array
    ,setBox03Val8array
    ,setBox03Val9array
    ,setBox03Val10array
    ,setBox04Val1array
    ,setBox04Val2array
    ,setBox04Val3array
    ,setBox04Val4array
    ,setBox04Val5array
    ,setBox04Val6array
    ,setBox04Val7array
    ,setBox04Val8array
    ,setBox04Val9array
    ,setBox04Val10array
    ,setBox04Val11array
    ,setBox04Val12array
    ,setBox04Val13array
    ,setBox04Val14array
    ,setBox04Val15array
    ,setBox04Val16array
    ,setBox04Val17array
    ,setBox04Val18array
    ,setBox04Val19array
    ,setBox04Val20array
    ,setBox04Val21array
    ,setBox04Val22array
    ,setBox04Val23array
    ,setBox04Val24array
    ,setBox04Val25array
    ,setBox04Val26array
    ,setBox04Val27array
    ,setBox04Val28array
    ,setBox04Val29array
    ,setBox04Val30array
    ,setBox04Val31array
    ,setBox04Val32array
    ,setBox04Val33array
    ,setBox04Val34array
    ,setBox04Val35array
    ,setBox04Val36array
    ,setBox04Val37array
    ,setBox04Val38array
    ,setBox04Val39array
    ,setBox04Val40array
    ,setBox04Val41array
    ,setBox04Val42array
    ,setBox04Val43array
    ,setBox04Val44array
    ,setBox04Val45array
    ,setBox04Val46array
    ,setBox04Val47array
    ,setBox04Val48array
    ,setBox05Val1array
    ,setBox05Val2array
    ,setBox05Val3array
    ,setBox05Val4array
    ,setBox05Val5array
    ,setBox05Val6array
    ,setBox05Val7array
    ,setBox05Val8array
    ,setBox05Val9array
    ,setBox05Val10array
    ,setBox05Val11array
    ,setBox05Val12array
    ,setBox05Val13array
    ,setBox05Val14array
    ,setBox05Val15array
    ,setBox05Val16array
    ,setBox05Val17array
    ,setBox05Val18array
    ,setBox05Val19array
    ,setBox05Val20array
    ,setBox05Val21array
    ,setBox05Val22array
    ,setBox05Val23array
    ,setBox05Val24array
    ,setBox05Val25array
    ,setBox05Val26array
    ,setBox05Val27array
    ,setBox05Val28array
    ,setBox05Val29array
    ,setBox05Val30array
    ,setBox05Val31array
    ,setBox05Val32array
    ,setBox05Val33array
    ,setBox05Val34array
    ,setBox05Val35array
    ,setBox05Val36array
    ,setBox05Val37array
    ,setBox05Val38array
    ,setBox05Val39array
    ,setBox05Val40array
    ,setBox05Val41array
    ,setBox05Val42array
    ,setBox05Val43array
    ,setBox05Val44array
    ,setBox05Val45array
    ,setBox05Val46array
    ,setBox05Val47array
    ,setBox05Val48array
    ,setBox06Val1array
    ,setBox06Val2array
    ,setBox06Val3array
    ,setBox06Val4array
    ,setBox06Val5array
    ,setBox06Val6array
    ,setBox06Val7array
    ,setBox06Val8array
    ,setBox06Val9array
    ,setBox06Val10array
    ,setBox06Val11array
    ,setBox06Val12array
    ,setBox06Val13array
    ,setBox06Val14array
    ,setBox06Val15array
    ,setBox06Val16array
    ,setBox06Val17array
    ,setBox06Val18array
    ,setBox06Val19array
    ,setBox06Val20array
    ,setBox06Val21array
    ,setBox06Val22array
    ,setBox06Val23array
    ,setBox06Val24array
    ,setBox06Val25array
    ,setBox06Val26array
    ,setBox06Val27array
    ,setBox06Val28array
    ,setBox06Val29array
    ,setBox06Val30array
    ,setBox06Val31array
    ,setBox06Val32array
    ,setBox06Val33array
    ,setBox06Val34array
    ,setBox06Val35array
    ,setBox06Val36array
    ,setBox06Val37array
    ,setBox06Val38array
    ,setBox06Val39array
    ,setBox06Val40array
    ,setBox06Val41array
    ,setBox06Val42array
    ,setBox06Val43array
    ,setBox06Val44array
    ,setBox06Val45array
    ,setBox06Val46array
    ,setBox06Val47array
    ,setBox06Val48array
    ,setBox07Val1array
    ,setBox07Val2array
    ,setBox07Val3array
    ,setBox07Val4array
    ,setBox07Val5array
    ,setBox07Val6array
    ,setBox07Val7array
    ,setBox07Val8array
    ,setBox07Val9array
    ,setBox07Val10array
    ,setBox07Val11array
    ,setBox07Val12array
    ,setBox07Val13array
    ,setBox07Val14array
    ,setBox07Val15array
    ,setBox07Val16array
    ,setBox07Val17array
    ,setBox07Val18array
    ,setBox07Val19array
    ,setBox07Val20array
    ,setBox07Val21array
    ,setBox07Val22array
    ,setBox07Val23array
    ,setBox07Val24array
    ,setBox07Val25array
    ,setBox07Val26array
    ,setBox07Val27array
    ,setBox07Val28array
    ,setBox07Val29array
    ,setBox07Val30array
    ,setBox07Val31array
    ,setBox07Val32array
    ,setBox07Val33array
    ,setBox07Val34array
    ,setBox07Val35array
    ,setBox07Val36array
    ,setBox07Val37array
    ,setBox07Val38array
    ,setBox07Val39array
    ,setBox07Val40array
    ,setBox07Val41array
    ,setBox07Val42array
    ,setBox07Val43array
    ,setBox07Val44array
    ,setBox07Val45array
    ,setBox07Val46array
    ,setBox07Val47array
    ,setBox07Val48array
    ,setBox08Val1array
    ,setBox08Val2array
    ,setBox08Val3array
    ,setBox08Val4array
    ,setBox08Val5array
    ,setBox08Val6array
    ,setBox08Val7array
    ,setBox09Val1array
    ,setBox09Val2array
    ,setBox09Val3array
    ,setBox09Val4array
    ,setBox09Val5array
    ,setBox09Val6array
    ,setBox09Val7array
    ,setBox10Val1array
    ,setBox10Val2array
    ,setBox10Val3array
    ,setBox10Val4array
    ,setBox10Val5array
    ,setBox10Val6array
    ,setBox10Val7array
    ,setBox11Val1array
    ,setBox11Val2array
    ,setBox11Val3array
    ,setBox11Val4array
    ,setBox11Val5array
    ,setBox11Val6array
    ,setBox11Val7array
    ,setBox12Val1array
    ,setBox12Val2array
    ,setBox12Val3array
    ,setBox12Val4array
    ,setBox12Val5array
    ,setBox12Val6array
    ,setBox12Val7array
    ,setBox13Val1array
    ,setBox13Val2array
    ,setBox13Val3array
    ,setBox13Val4array
    ,setBox13Val5array
    ,setBox13Val6array
    ,setBox13Val7array
    ,setBox14Val1array
    ,setBox14Val2array
    ,setBox14Val3array
    ,setBox14Val4array

} = levelSettingSlice.actions;

export default levelSettingSlice.reducer;