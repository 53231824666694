import {call, put, take,} from "redux-saga/effects";
import {fetchAlerts} from "../../lib/api";
import {failedGetAlerts, setAlerts} from "../reducers/alerts";

export function* getAlertsSaga(action) {
    try {
        const res = yield call(fetchAlerts, action.payload)
        yield put(setAlerts(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetAlerts(error))
    }
}
