import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage"
import {persistReducer} from "redux-persist";
import userInfoReducer  from "./userInfo"
import BlackUploadReducer from "./user/BlackUploadReducer";
import BlackMemberReducer from "./user/BlackMemberReducer";
import BlackMemberAddModalReducer from "./user/BlackMemberAddModalReducer";
import MemberReducer from "./user/MemberReducer"
import MemberUploadReducer from "./user/MemberUploadReducer";
import CustomerReducer from "./board/CustomerReducer";
import CustomerDetailReducer from "./board/CustomerDetailReducer";
import NoticeReducer from "./board/NoticeReducer";
import CheckReducer from "./board/CheckReducer";
import EventReducer from "./board/EventReducer";
import ETCReducer from "./board/ETCReducer";
import MemberUpdateReducer from "./user/MemberUpdateReducer"
import LotteryReducer from "./deposit/LotteryReducer";
import MemberStateReducer from "./user/MemberStateReducer";
import MessageReducer from "./user/MessageReducer";
import MessagePopReducer from "./user/MessagePopReducer";
import MessageTemplateReducer from "./user/MessageTemplateReducer";

import OrgListReducer from "./partner/OrgListReducer";
import CompanySubListReducer from "./partner/CompanySubListReducer";
import CompanySubAddReducer from "./partner/CompanySubAddReducer";
import CompanySubSingleReducer from "./partner/CompanySubSingleReducer";
import DistributorListReducer from "./partner/DistributorListReducer";
import DistributorAddReducer from "./partner/DistributorAddReducer";
import DistributorSingleReducer from "./partner/DistributorSingleReducer";
import PartnerListReducer from "./partner/PartnerListReducer";
import PartnerAddReducer from "./partner/PartnerAddReducer";
import PartnerSingleReducer from "./partner/PartnerSingleReducer";
import RecommendKeyListReducer from "./partner/RecommendKeyListReducer";
import RecommendAddReducer from "./partner/RecommendAddReducer";
import OrgSelectReducer from "./partner/OrgSelectReducer";
import MemberInfoReducer from "./partner/MemberInfoReducer";


import OrgAuthListReducer from "./partner/OrgAuthListReducer";

import SiteSettingSingleReducer from "./setting/SiteSettingSingleReducer";
import LevelSettingSingleReducer from "./setting/LevelSettingSingleReducer";
import BettingSingleReducer from "./setting/BettingSingleReducer";
import TagSettingListReducer from "./setting/TagSettingListReducer";
import RegBankListReducer from "./setting/RegBankListReducer";
import DangerAccountSettingListReducer from "./setting/DangerAccountSettingListReducer";
import DomainSettingListReducer from "./setting/DomainSettingListReducer";
import IpBlockListReducer from "./setting/IpBlockListReducer";
import EtcSettingListReducer from "./setting/EtcSettingListReducer";
import GroupSettingListReducer from "./setting/GroupSettingListReducer";
import CodeSettingListReducer from "./setting/CodeSettingListReducer";

import DepositListReducer from "./deposit/DepositListReducer";
import WithdrawListReducer from "./deposit/WithdrawListReducer";
import DepositWithdrawLogReducer from "./deposit/DepositWithdrawLogReducer";
import DepositWithdrawCombineReducer from "./deposit/DepositWithdrawCombineReducer";
import MoneyListReducer from "./deposit/MoneyListReducer";
import MoneyStatReducer from "./deposit/MoneyStatReducer";
import PointListReducer from "./deposit/PointListReducer";
import PointStatReducer from "./deposit/PointStatReducer";
import CouponListReducer from "./deposit/CouponListReducer";

import SiteSettingReducer from "./setting/SiteSettingSingleReducer"

import AdminReducer from "./monitoring/AdminReducer"
import ActiveReducer from "./monitoring/ActiveReducer"
import ActiveMemberReducer from "./monitoring/ActiveMemberReducer";
import LoginFailReducer from "./monitoring/LoginFailReducer";
import AccountReducer from "./monitoring/AccountReducer";
import SameBrowserReducer from "./monitoring/SameBrowserReducer";
import OverlapReducer from "./monitoring/OverlapReducer";

import UserInfoDetailReducer from "./userInfoDetail";
import UserInfoCombineReducer from "./user/userInfoCombineReducer";

import MemberInformationSimpleModalReducer from "./member/MemberInformationSimpleModalReducer";

import MoneyInfoReducer from "./moneyInfo"
import AlertsReducer from "./alerts"
import LeftAlertsReducer from "./leftAlerts"

import LvlSettingListReducer from "./setting/LvlSettingListReducer";
import LvlSettingSingleReducer from "./setting/LvlSettingSingleReducer";


import DwpSettingListReducer from "./setting/DwpSettingListReducer";
import AcctSettingListReducer from "./setting/AcctSettingListReducer";
import RecommenderSettingListReducer from "./setting/RecommenderSettingListReducer";

import AttendanceListReducer from "./setting/AttendanceListReducer"
import RouletteListReducer from "./setting/RouletteListReducer"

import RegSettingListReducer from "./setting/RegSettingListReducer"

//games
import miniGameReducer from "./game/manage/miniGame"
import gameSettingReducer from "./game/gameSetting"
import parsingWaitReducer from "./game/manage/parsingWait"
import activeMatchReducer from "./game/manage/activeMatch"
import inplayMatchReducer from "./game/manage/inplayMatch"
import inplayResultReducer from "./game/manage/inplayResult"
import closeMatchReducer from "./game/manage/closeMatch"
import stopMatchReducer from "./game/manage/stopMatch"
import stopMatchByReducer from "./game/manage/stopMatchBy"
import resultMatchReducer from "./game/manage/resultMatch"
import cancelMatchReducer from "./game/manage/cancelMatch"
import cancelMatchByReducer from "./game/manage/cancelMatchBy"
import fixtureReducer from "./game/manage/fixture"

import withdrawInfoReducer from "./partner/WithdrawInfoReducer";
import losingInfoReducer from "./partner/LosingInfoReducer";
import rollingInfoReducer from "./partner/RollingInfoReducer";

import adjustmentReducer from "./partner/AdjustmentReducer";


const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userInfo"]
}

const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    BlackUploadReducer: BlackUploadReducer,
    BlackMemberReducer: BlackMemberReducer,
    BlackMemberAddModalReducer: BlackMemberAddModalReducer,
    member: MemberReducer,
    MemberUploadReducer: MemberUploadReducer,
    LotteryReducer : LotteryReducer,
    MemberStateReducer: MemberStateReducer,
    MessageReducer: MessageReducer,
    MessagePopReducer: MessagePopReducer,
    MessageTemplateReducer: MessageTemplateReducer,
    OrgListReducer: OrgListReducer,
    CompanySubListReducer: CompanySubListReducer,
    CompanySubAddReducer : CompanySubAddReducer,
    CompanySubSingleReducer: CompanySubSingleReducer,
    DistributorListReducer: DistributorListReducer,
    DistributorAddReducer : DistributorAddReducer,
    DistributorSingleReducer: DistributorSingleReducer,
    PartnerListReducer: PartnerListReducer,
    PartnerAddReducer : PartnerAddReducer,
    PartnerSingleReducer: PartnerSingleReducer,
    RecommendKeyListReducer: RecommendKeyListReducer,
    RecommendAddReducer : RecommendAddReducer,
    OrgSelectReducer : OrgSelectReducer,
    MemberInfoReducer : MemberInfoReducer,
    OrgAuthListReducer: OrgAuthListReducer,
    SiteSettingSingleReducer: SiteSettingSingleReducer,
    levelSetting: LevelSettingSingleReducer,
    bettingSetting: BettingSingleReducer,
    tagSetting: TagSettingListReducer,
    regBankSetting: RegBankListReducer,
    DangerAccountSettingListReducer: DangerAccountSettingListReducer,
    DomainSettingListReducer: DomainSettingListReducer,
    CodeSettingListReducer:CodeSettingListReducer,
    IpBlockListReducer: IpBlockListReducer,
    EtcSettingListReducer: EtcSettingListReducer,
    GroupSettingListReducer: GroupSettingListReducer,
    memberUpdate: MemberUpdateReducer,
    depositList: DepositListReducer,
    withdrawList: WithdrawListReducer,
    depositWithdrawLog: DepositWithdrawLogReducer,
    depositWithdrawCombine: DepositWithdrawCombineReducer,
    moneyList: MoneyListReducer,
    moneyStat: MoneyStatReducer,
    pointList: PointListReducer,
    pointStat: PointStatReducer,
    couponList: CouponListReducer,
    customer: CustomerReducer,
    customerDetail: CustomerDetailReducer,
    notice: NoticeReducer,
    check: CheckReducer,
    eventBoard: EventReducer,
    siteSetting: SiteSettingReducer,
    etc: ETCReducer,
    admin: AdminReducer,
    active: ActiveReducer,
    activeMember: ActiveMemberReducer,
    loginFail: LoginFailReducer,
    account: AccountReducer,
    sameBrowser: SameBrowserReducer,
    overlap: OverlapReducer,
    userInfoDetail: UserInfoDetailReducer,
    userInfoCombine: UserInfoCombineReducer,
    MemberInformationSimpleModalReducer:MemberInformationSimpleModalReducer,
    moneyInfo: MoneyInfoReducer,
    alerts: AlertsReducer,
    leftAlerts: LeftAlertsReducer,
    gameSetting: gameSettingReducer,
    parsingWait: parsingWaitReducer,
    activeMatch: activeMatchReducer,
    closeMatch: closeMatchReducer,
    stopMatch: stopMatchReducer,
    stopMatchBy: stopMatchByReducer,
    resultMatch: resultMatchReducer,
    cancelMatch: cancelMatchReducer,
    cancelMatchBy: cancelMatchByReducer,
    inplayMatch: inplayMatchReducer,
    inplayResult: inplayResultReducer,
    miniGame: miniGameReducer,
    fixture: fixtureReducer,
    withdrawInfo: withdrawInfoReducer,
    losingInfo: losingInfoReducer,
    rollingInfo: rollingInfoReducer,
    adjustment: adjustmentReducer,
    lvlSetting: LvlSettingListReducer,
    lvlSettingSingle: LvlSettingSingleReducer,
    dwpSetting: DwpSettingListReducer,
    acctSetting: AcctSettingListReducer,
    recommenderSetting: RecommenderSettingListReducer,
    attendanceSetting: AttendanceListReducer,
    rouletteSetting: RouletteListReducer,
    regSetting: RegSettingListReducer
});

export default persistReducer(persistConfig, rootReducer);