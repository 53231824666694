import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    totalList : [],
    totalCount : 0,
    gridList : [],
    gridCount : 0,
    input:{
        page: 1,
        pageSize: 20,
        query : "",
    },
    saveAllListInput:[]
}

export const statsSlice = createSlice({
    name: 'EtcSettingListReducer',
    initialState,
    reducers: {
        getEtcSettingListReducer(state, action){
            console.log("#reducers getEtcSettingListReducer start#",
                action.payload.input.pageSize,
                action.payload.input.page)
            ;
            state.input.page = action.payload.input.pageSize === 999? 1: action.payload.input.page;
            state.input.pageSize = action.payload.input.pageSize;
        },
        setEtcSettingListReducer(state, action) {
            console.log("#reducers setEtcSettingListReducer start#",action.payload.gridList);
            if((state.input.page -1) * state.input.pageSize > action.payload.totalCount) {
                state.input.page = 1
            }
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.gridCount = action.payload.gridCount;
            state.gridList = action.payload.gridList;
        },
        failedGetEtcSettingListReducer(state, action) {},
        setQuery(state,action){
            state.input.query = action.payload;
        },
        setEtc(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].etc =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setImageUrl(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].image_url =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setV1(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].V1 =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setV2(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].V2 =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setV3(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].V3 =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setV4(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].V4 =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setV5(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].V5 =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setText(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].text =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setStartDt(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].start_dt =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setStartTime(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].start_time =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setEndDt(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].end_dt =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setEndTime(state,action){
            const _idx = action.payload.idx;
            state.gridList[_idx].end_time =action.payload.val;
            if(action.payload._rowStat=== "X"){
                state.gridList[_idx]._rowStat = "U"
            }
        },
        setAddrow(state,action){
            state.gridList.unshift({
                _rowStat : "I",
                image_url: "",
                etc: "",
                V1: "",
                V2: "",
                V3: "",
                V4: "",
                V5: "",
                text: "",
                start_dt: "",
                start_time: "",
                end_dt: "",
                end_time: "",
                reg_id: "",
                upd_id: ""
            })
        }
    }
});

export const {
    getEtcSettingListReducer,
    setEtcSettingListReducer,
    failedGetEtcSettingListReducer,
    setEtc,
    setImageUrl,
    setV1,
    setV2,
    setV3,
    setV4,
    setV5,
    setText,
    setStartDt,
    setStartTime,
    setEndDt,
    setEndTime,
    setAddrow,
    setQuery
} = statsSlice.actions;

export default statsSlice.reducer;