
import React, {useEffect, useState} from "react";
import axios from "axios";
import moment from "moment";


const InsertManualPopup = () => {

    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"))
    const [row, setRow] = useState(20)
    const [list, setList] = useState([])
    const [selectList, setSelectList] = useState([])


    useEffect(() => {
        createList(row)
    }, [])

    const changeRows = (e) => {
        const r = e.currentTarget.value
        setRow(r)
        createList(r)
    }

    const createList = (r) => {
        const arr = []
        for(let i = 0; i < r; i++) {
            if(!list[i]) {
                arr.push({
                    time: "",
                    home: "",
                    away: "",
                    win: "",
                    draw: "",
                    lose: "",
                    wEvent: false,
                    hWin: "",
                    handicap: "",
                    hLose: "",
                    hEvent: false,
                    over: "",
                    baseline: "",
                    under: "",
                    oEvent: false
                })
            }
            else {
                arr.push(list[i])
            }
        }
        setList(arr)
    }

    const allCheckHandler = (e) => {
        if(e.currentTarget.checked) {
            const checks = [];
            for(let i = 0; i < list.length; i++) {
                checks.push(i)
            }
            setSelectList(checks)
        }
        else {
            setSelectList([])
        }
    }

    const checkHandler = (checked, index) => {
        const filterArr = selectList.filter((i) => i === index)
        if(filterArr.length > 0) {
            setSelectList(selectList.filter((i) => i !== index))
        }
        else {
            const arr = [...selectList]
            arr.push(index)
            setSelectList(arr)
        }
    }

    const registerManual = (isPrint) => {
        if(selectList.length === 0) {
            alert("선택된경기가 없습니다.")
        }
        else {
            const arr = []
            for(let i = 0; i < selectList.length; i++) {
                const item = list[selectList[i]]
                if(item.time !== "" && item.home !== "" && item.away !== "" && (
                    (item.win !== "" && item.lose !== "") || (item.hWin !== "" && item.hLose !== "") || (item.over !== "" && item.under !== "")
                )) {
                    arr.push(list[selectList[i]])
                }
            }
            if(arr.length > 0) {
                axios.post('/admin/game/manual/insert', {
                    isPrint: isPrint,
                    list: arr,
                    startDate: startDate
                }).then((res) => {

                })
            }
            console.log(arr)
        }
    }

    return <div>
        <div className="d-flex">
            <span>경기일자</span>
            <input value={startDate} type="date" />
            <span>입력 라인 수 : </span>
            <input value={row} type="number" onChange={changeRows}/>
        </div>
        <div className="d-flex">
            <p style={{
                marginRight: "auto"
            }}>* 무승부의 경우 아무것도 입력하지 않거나 경기 배당율을 0으로 입력. 이벤트 게임의 경우 배당 옆의 E 항목에 체크 </p>
            <button className="btn btn-info btn-sm mx-1" onClick={(e) => {
                registerManual(true)
            }}>출력으로 등록</button>
            <button className="btn btn-info btn-sm mx-1" onClick={(e) => {
                registerManual(false)
            }}>미출력으로 등록</button>
        </div>

        <div className="sm-font">
            <table className="table table-bordered table-sm text-center align-middle">
                <thead>
                <tr>
                    <td><input type="checkbox" checked={list.length === selectList.length} onChange={allCheckHandler}/></td>
                    <td>리그</td>
                    <td>시간</td>
                    <td>홈팀</td>
                    <td>원정팀</td>
                    <td>승무패<br/>(승:무:패)</td>
                    <td>E</td>
                    <td>핸디캡<br/>(홈:핸디:원정)</td>
                    <td>E</td>
                    <td>오버언더<br/>(오버:기준:언더)</td>
                    <td>E</td>
                    <td>복사</td>
                </tr>
                </thead>
                <tbody>
                {list?.map((item, index) => (
                    <tr>
                        <td><input type="checkbox" checked={selectList.filter((i) => i === index).length > 0} onChange={(e) => {
                            checkHandler(e.currentTarget.checked, index)
                        }}/></td>
                        <td>
                            <select>
                                <option value={0}>일반</option>
                            </select>
                            <select>
                                <option value={0}>::리그선택::</option>
                                <option value={1}>수동용리그</option>
                            </select>
                        </td>
                        <td><input type="text" value={item.time} maxLength={4} onChange={(e) => {
                            list[index].time = e.currentTarget.value
                            const arr = [...list]
                            setList(arr)
                        }}/></td>
                        <td><input type="text" value={item.home} onChange={(e) => {
                            list[index].home = e.currentTarget.value
                            const arr = [...list]
                            setList(arr)
                        }}/></td>
                        <td><input type="text" value={item.away} onChange={(e) => {
                            list[index].away = e.currentTarget.value
                            const arr = [...list]
                            setList(arr)
                        }}/></td>
                        <td>
                            <div className="d-flex">
                                <input type="text" value={item.win} onChange={(e) => {
                                    list[index].win = e.currentTarget.value
                                    const arr = [...list]
                                    setList(arr)
                                }}/>
                                <input type="text" value={item.draw} onChange={(e) => {
                                    list[index].draw = e.currentTarget.value
                                    const arr = [...list]
                                    setList(arr)
                                }}/>
                                <input type="text" value={item.lose} onChange={(e) => {
                                    list[index].lose = e.currentTarget.value
                                    const arr = [...list]
                                    setList(arr)
                                }}/>
                            </div>
                        </td>
                        <td><input type="checkbox" value={item.wEvent} onChange={(e) => {
                            list[index].wEvent = e.currentTarget.value
                            const arr = [...list]
                            setList(arr)
                        }}/></td>
                        <td>
                            <div className="d-flex">
                                <input type="text" value={item.hWin} onChange={(e) => {
                                    list[index].hWin = e.currentTarget.value
                                    const arr = [...list]
                                    setList(arr)
                                }}/>
                                <input type="text" value={item.handicap} onChange={(e) => {
                                    list[index].handicap = e.currentTarget.value
                                    const arr = [...list]
                                    setList(arr)
                                }}/>
                                <input type="text" value={item.hLose} onChange={(e) => {
                                    list[index].hLose = e.currentTarget.value
                                    const arr = [...list]
                                    setList(arr)
                                }}/>
                            </div>
                        </td>
                        <td><input type="checkbox" value={item.hEvent} onChange={(e) => {
                            list[index].hEvent = e.currentTarget.value
                            const arr = [...list]
                            setList(arr)
                        }}/></td>
                        <td>
                            <div className="d-flex">
                                <input type="text" value={item.over} onChange={(e) => {
                                    list[index].over = e.currentTarget.value
                                    const arr = [...list]
                                    setList(arr)
                                }}/>
                                <input type="text" value={item.baseline} onChange={(e) => {
                                    list[index].baseline = e.currentTarget.value
                                    const arr = [...list]
                                    setList(arr)
                                }}/>
                                <input type="text" value={item.under} onChange={(e) => {
                                    list[index].under = e.currentTarget.value
                                    const arr = [...list]
                                    setList(arr)
                                }}/>
                            </div>
                        </td>
                        <td><input type="checkbox" value={item.oEvent} onChange={(e) => {
                            list[index].oEvent = e.currentTarget.value
                            const arr = [...list]
                            setList(arr)
                        }}/></td>
                        <td><button className="btn btn-info btn-sm mx-1">복사</button></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

    </div>
}

export default InsertManualPopup