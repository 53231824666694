import React from "react";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {getStopFixtureDetail, setBetInputs, setCheckedInputs} from "../../../redux/reducers/game/manage/stopMatch";
import axios from "axios";
import common from "../../../util/common";
import FixtureStatus from "../../../constant/FixtureStatus";
import BetStatus from "../../../constant/BetStatus";
import {NumericFormat} from "react-number-format";

const StopList = ({state}) => {
    const dispatch = useDispatch()

    const changeHandler = (checked, id, index) => {
        if(checked) {
            dispatch(setCheckedInputs([...state.checkedInputs, id]));
        }
        else {
            dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== id)));
        }
    };

    const allChangeHandler = (e) => {
        const check_ids = []
        for(let index = 0; index < state.fixtures.length; index++) {
            check_ids.push(state.fixtures[index].id)
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []));
    }

    const changeBet = (e, id, index, type) => {
        if(e.target.value !== e.target.defaultValue) {
            e.target.style.color = "green"
        }
        else {
            e.target.style.color = "#555"
        }
        const value = parseFloat(e.target.value)
        const data = {
            bet1: state.fixtures[index].bet1,
            bet2: state.fixtures[index].bet2,
            bet3: state.fixtures[index].bet3,
        }

        const betInputs2 = state.betInputs.filter((el) => el.id !== id);

        const betInputs3 = state.betInputs.filter((el) => el.id === id && el.is_edited === 1);
        let inputs
        if(betInputs3.length > 0) {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: betInputs3[0].bet2, bet3: betInputs3[0].bet3}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: betInputs3[0].bet1, bet3: betInputs3[0].bet3}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1}
            }
        }
        else {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: data.bet2, bet3: data.bet3}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: data.bet1, bet3: data.bet3}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: data.bet2, bet1: data.bet1}
            }
        }
        inputs.is_edited = 1;
        dispatch(setBetInputs([...betInputs2, inputs]))

        //체크처리
        const checkedInput2 = state.checkedInputs.filter((el) => el !== id)
        dispatch(setCheckedInputs([...checkedInput2, id]));
    }

    const editSelectedMatch = () => {
        const updateData = []
        for(let i = 0; i < state.checkedInputs.length; i++) {
            const betInputs2 = state.betInputs.filter((el) => el.id === state.checkedInputs[i] && el.is_edited === 1)
            const data = {
                id: state.checkedInputs[i]
            }
            if(betInputs2.length > 0) {
                data.is_edited = 1
                const editedData = betInputs2[0];
                data.bet1 = editedData.bet1
                data.bet2 = editedData.bet2
                data.bet3 = editedData.bet3
            }
            else {
                data.is_edited = 0
            }
            updateData.push(data)
        }
        return updateData
    }
    const editAllMatch = () => {
        const updateData = []
        for(let i = 0; i < state.fixtures.length; i++) {
            const betInputs2 = state.betInputs.filter((el) => el.id === state.fixtures[i])
            const data = {
                id: state.fixtures[i].id
            }
            if(betInputs2.length > 0) {
                data.is_edited = 1
                const editedData = betInputs2[0];
                data.bet1 = editedData.bet1
                data.bet2 = editedData.bet2
                data.bet3 = editedData.bet3
            }
            else {
                data.is_edited = 0
            }
            updateData.push(data)
        }
        return updateData;
    }
    const getAllIds = () => {
        const updateData = []
        for(let i = 0; i < state.fixtures.length; i++) {
            updateData.push(state.fixtures[i].id)
        }
        return updateData
    }

    const editActiveMatch = (updateData) => {
        axios.post('/api/editActiveMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 수정완료");
                window.location.reload();
            }
        })
    }
    const activeMatch = (updateData) => {
        axios.post('/api/activeMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 활성완료");
                window.location.reload();
            }
        })
    }
    const closeMatch = (updateData) => {
        axios.post('/api/closeMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 마감처리완료");
                window.location.reload();
            }
        })
    }
    const refundMatch = (updateData) => {
        axios.post('/api/refundMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 적특처리완료");
                window.location.reload();
            }
        })
    }
    const changeIsEdited = (updateData) => {
        axios.post('/api/changeIsEdited', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 파싱상태변경완료");
                window.location.reload();
            }
        })
    }

    function getDetail() {
        const ratios = state.currentFixture.ratios
        const ratiosByMarket = common.groupBy(ratios, "market_id")
        const keys = Object.keys(ratiosByMarket)
        const result = []
        for(let i = 0; i < keys.length; i++) {

            //마켓아이디 처리
            const marketId = keys[i]

            const arr = ratiosByMarket[marketId]
            // 마켓아이디별 픽스쳐처리
            const trs = []
            for(const fixture of arr) {
                trs.push(<tr>
                    <td>{fixture.provider}</td>
                    <td className={fixture.bet1_settlement === 2 && "table-success"}>{fixture.bet1}</td>
                    <td className={fixture.market_id === 1 && (fixture.bet2_settlement === 2 && "table-success")}>
                        {fixture.baseline? fixture.baseline.split(' (0')[0] : fixture.bet2}
                    </td>
                    <td className={fixture.bet3_settlement === 2 && "table-success"}>{fixture.bet3}</td>
                    <td>{BetStatus.getKorMessage(fixture.bet1_status)}</td>
                    <td>{moment(fixture.bet1_updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{moment(fixture.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                </tr>)
            }

            const tableTemp = <div>
                <p className="h4 text-bg-dark mb-0 p-2">{arr[0].market}</p>
                <table className="table table-bordered table-hover text-center">
                    <thead className="table-secondary">
                    <tr>
                        <th style={{
                            width: "auto"
                        }}>업체</th>
                        <th style={{
                            width: "25%"
                        }}>{arr[0].home}</th>
                        <th style={{
                            width: "13%"
                        }}>{arr[0].market_id === 1? "무" : "기준점"}</th>
                        <th style={{
                            width: "25%"
                        }}>{arr[0].away}</th>
                        <th style={{
                            width: "auto"
                        }}>배당상태</th>
                        <th style={{
                            width: "auto"
                        }}>배당수정</th>
                        <th style={{
                            width: "auto"
                        }}>최근수정</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trs}
                    </tbody>
                </table>
            </div>

            result.push(tableTemp)
        }

        return result
    }

    return (
        <div className="container-fluid p-1 m-0">

            <div className="mb-1">
                <button className="btn btn-info me-1" onClick={() => {editActiveMatch(editAllMatch())}} >전체수정</button>
                <button className="btn btn-info me-1" onClick={() => {editActiveMatch(editSelectedMatch())}} >선택수정</button>
                <button className="btn btn-success me-1" onClick={() => {activeMatch(editAllMatch())}} >전체활성</button>
                <button className="btn btn-success me-1" onClick={() => {activeMatch(editSelectedMatch())}} >선택활성</button>
                <button className="btn btn-secondary me-1" onClick={() => {closeMatch(getAllIds())}} >전체마감</button>
                <button className="btn btn-secondary me-1" onClick={() => {closeMatch(state.checkedInputs)}} >선택마감</button>
                <button className="btn btn-secondary me-1" onClick={() => {refundMatch(getAllIds())}} >전체적특</button>
                <button className="btn btn-secondary me-1" onClick={() => {refundMatch(state.checkedInputs)}} >선택적특</button>
                <button className="btn btn-secondary me-1" onClick={() => {changeIsEdited(0)}} >파싱 ON</button>
                <button className="btn btn-secondary me-1" onClick={() => {changeIsEdited(1)}} >파싱 OFF</button>
            </div>


            <div className="d-flex">
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {state.low_ratios && (
                        <table className="table table-bordered table-hover text-center">
                            <thead className="table-dark">
                            <tr>
                                <th className="text-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            state.checkedInputs.length === state.low_ratios.length
                                        }
                                        onChange={(e) => {allChangeHandler(e)}}
                                    />
                                </th>
                                <th>종목</th>
                                <th>일정</th>
                                <th>리그</th>
                                <th>홈팀</th>
                                <th>원정팀</th>
                                <th>진행배팅금</th>
                                <th>Fix상태</th>
                                <th>Fix수정시간</th>
                                <th>FixID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.isLoading && "로딩중..."}
                            {!state.isLoading && !state.low_ratios.length > 0 && (
                                <tr>
                                    <td colSpan="3">목록이 비어있습니다.</td>
                                </tr>
                            )}
                            {!state.isLoading && !!state.low_ratios.length && state.low_ratios.map((item, index) => (
                                <tr className={state.checkedInputs.includes(item.id) && "info"} onClick={() => {
                                    dispatch(getStopFixtureDetail(item.fixture_id))
                                }}>
                                    <td>
                                        <input type="checkbox" name={item.fixture_id} id={item.fixture_id} value="true"
                                               checked={state.checkedInputs.includes(item.fixture_id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, item.fixture_id, index)}}
                                        />
                                    </td>
                                    <td>{`${item.sport}\n(${item.mCount})`}</td>
                                    <td>{moment(item.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{item.league}</td>
                                    <td>
                                        {item.home}
                                    </td>
                                    <td>{item.away}</td>
                                    <td>
                                        {item.total && (
                                            <div style={{
                                                width: "100px",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{color: "green"}} onClick={(e) => {
                                                    window.open("/game/manual/betting_popup/" + item.fixture_id + "/0", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                                }}><NumericFormat value={item.total.sumExtraMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betCount})`}</span></p>
                                                <p style={{color: "blue"}} onClick={(e) => {
                                                    window.open("/game/manual/betting_popup/" + item.fixture_id + "/0", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                                }}><NumericFormat value={item.total.sumTotalMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betTotalCount})`}</span></p>
                                            </div>
                                        )}

                                    </td>
                                    <td>{FixtureStatus.getKorMessage(item.fixture_status)}</td>
                                    <td>{moment(item.fixture_updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>{item.fixture_id}</td>

                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {state.isLoadingDetail && "로딩중..."}
                    {!state.isLoadingDetail && state.currentFixture === null && "선택된 경기가 없습니다."}
                    {!state.isLoadingDetail && state.currentFixture !== null && (
                        <div>
                            <div className="text-bg-dark p-2 mb-5">
                                <p>{`${state.currentFixture.ratios[0].sport} - ${state.currentFixture.ratios[0].league}`}</p>
                                <div className="text-center align-middle" style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}><p>{`${state.currentFixture.ratios[0].home}`}</p></div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center",
                                    }}>
                                        <p className="m-0">{`${moment(state.currentFixture.ratios[0].start_date).format("YYYY-MM-DD HH:mm")}`}</p>
                                    </div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}>{`${state.currentFixture.ratios[0].away}`}</div>

                                </div>

                            </div>
                            {getDetail()}

                        </div>
                    )}

                </div>
            </div>
        </div>
    );

}

export default StopList;