import {call, put} from "redux-saga/effects";
import {failedGetMessageTemplateReducer, setMessageTemplateReducer} from "../../reducers/user/MessageTemplateReducer";
import {fetchMessageTemplate} from "../../../lib/api";

export function* getMessageTemplateSaga(action) {
    try{
        console.log("##getMessageTemplateSaga input#",action.payload.input)
        const res = yield call(fetchMessageTemplate,
            action.payload.input,
        );
        console.log("##getMessageTemplateSaga output#",res.data)
        yield put(setMessageTemplateReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMessageTemplateReducer(error))
    }
}