import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../../redux/reducers/user/BlackMemberAddModalReducer";
import {getMemberUser} from "../../../../redux/reducers/user/MemberReducer";

const MemberInformationDetailModal = (props) => {


    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table width={"99%"}>
                    <tr>
                        <td align={"right"}>
                            <button style={{
                                backgroundColor: "#5dc434",
                                border: "none",
                                color: "white",
                                fontSize: "9px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "10px"
                            }}>회원 수정
                            </button>

                        </td>
                    </tr>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <b>본인에 대한 설정</b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "lightgray"}}>종류</td>
                            <td style={{width: "70%",backgroundColor: "lightgray"}}>값</td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>일일 최대 환전 신청 건수</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /> 건<br></br>
                                * 0으로 설정시, 환전 불가
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>일일 최대 환전 금액</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /> 원<br></br>
                                * 0으로 설정시, 환전 불가
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>1회 최대 환전 금액</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /> 원<br></br>
                                * 0으로 설정시, 환전 불가
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 낙첨금%</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /> %<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 첫충%</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /> %<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 매충%</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /> %<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>매충포인트 지급 불가 환전 횟수</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * -1일 경우 사이트설정을 따름. 0이면 무조건 지급, 1이상일 경우, 해당 환전횟수부터 매충포인트 지급 불가
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>환전이 있어도 첫충 포인트는 지급 여부</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * -1일 경우 사이트설정을 따름. 0일 경우 매충설정을 따름, 1일 경우 환전이 있더라도 지급
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 첫충/매충 일일 최대 지급 포인트</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 0이면 한도 없음. -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 첫충/매충 일일 최대 지급 횟수</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 0이면 한도 없음. -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 첫충/매충 1회 최대 지급 포인트</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 0이면 한도 없음. -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 1폴 낙첨 포인트 지급 여부</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * -1:기타설정 따름. 0:지급금지. 1:강제지급
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 2폴 낙첨 포인트 지급 여부</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * -1:기타설정 따름. 0:지급금지. 1:강제지급
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>크로스 베팅 최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>크로스 베팅 최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>크로스2 베팅
                                최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>크로스2 베팅
                                최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>승무패 베팅
                                최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>승무패 베팅
                                최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>핸디캡 베팅
                                최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>핸디캡 베팅
                                최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>스페셜 베팅
                                최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>스페셜 베팅
                                최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>실시간 베팅
                                최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>실시간 베팅
                                최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>베팅 최대 배당</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * -1:환경설정>레벨별설정 따름<br></br>
                                * 베팅시, 개인별로 최대 배당을 제한합니다.
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>최소 베팅금</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * -1:환경설정>레벨별설정 따름<br></br>
                                * 베팅시, 개인별로 최소 베팅금을 지정합니다.
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>환전시 시간 간격 제한(분)</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * -1:환경설정>사이트설정 따름<br></br>
                                * 환전신청시 마지막 성공한 환전으로부터 최소 몇 분이 지나야 신청 가능한 지. 0일 경우 제한 없음
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                            </td>
                        </tr>


                        </thead>
                    </table>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex", alignItems:'flex-start'
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <b>본인에 대한 설정</b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "lightgray"}}>종류</td>
                            <td style={{width: "55%",backgroundColor: "lightgray"}}>값</td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 낙첨금%<br></br>
                                (본인을 추천인으로 설정한 회원이 낙첨시, 본인이 받게될 낙첨금)
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" />%<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 첫충%<br></br>
                                (본인을 추천인으로 설정한 회원 첫충시 받을 %)
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" />%<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 매충%<br></br>
                                (본인을 추천인으로 설정한 회원 매충시 받을 %)
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" />%<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 첫충/매충 일일 최대 지급 포인트<br></br>
                                (본인을 추천인으로 설정한 회원 첫충/매충시 받을 보너스 일일 최대 금액)
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * 0이면 한도 없음. -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 1폴 낙첨 포인트 지급 여부
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * -1:환경설정>기타설정 따름. 0:지급금지. 1:강제지급<br></br>
                                * 본인을 추천인으로 설정한 회원의 1폴 낙첨시 포인트를 받을지 여부
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 2폴 낙첨 포인트 지급 여부
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" /><br></br>
                                * -1:환경설정>기타설정 따름. 0:지급금지. 1:강제지급<br></br>
                                * 본인을 추천인으로 설정한 회원의 2폴 낙첨시 포인트를 받을지 여부
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}><br></br></td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <b>본인이 추천인으로 설정한 이에 대한 설정</b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "lightgray"}}>종류</td>
                            <td style={{width: "55%",backgroundColor: "lightgray"}}>값</td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                추천인 지급 낙첨금%<br></br>
                                (본인 낙첨시 추천인으로 설정한 회원에게 지급할 낙첨금)
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" />%<br></br>
                                * -1이면 레벨별 설정을 따름<br></br>
                                <font color={"red"}>* 상대 회원에게 설정된 지인 낙첨금보다 우선됨</font>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                본인 첫충에 대해 추천인에게 지급할 %
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" />%<br></br>
                                * 추천인의 지인 첫충%보다 우선함<br></br>
                                * -1이면 추천인의 지인 첫충% 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                본인 매충에 대해 추천인에게 지급할 %
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "80px"}} placeholder="-1" />%<br></br>
                                * 추천인의 지인 매충%보다 우선함<br></br>
                                * -1이면 추천인의 지인 매충% 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}><br></br></td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <b>본인이 추천인으로 설정한 이에 대한 설정</b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "lightgray"}}>종류</td>
                            <td style={{width: "55%",backgroundColor: "lightgray"}}>값</td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                게시판 차단
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <select
                                    className="form-select sm-select"
                                    defaultValue={50}
                                    style={{
                                        width: "100px"
                                    }}
                                >
                                    <option value={50}>모두 허용</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                고위험IP접속 알림
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <select
                                    className="form-select sm-select"
                                    defaultValue={50}
                                    style={{
                                        width: "100px",
                                        backgroundColor:"lightskyblue"
                                    }}
                                >
                                    <option value={50}>알림</option>
                                </select>
                            </td>
                        </tr>
                        
                        </thead>
                    </table>
                </div>
            </div>
        </div>

    )
}
export default MemberInformationDetailModal;