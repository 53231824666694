import {call, put} from "redux-saga/effects";
import {failedGetRecommenderSetting, setRecommenderSetting} from "../../reducers/setting/RecommenderSettingListReducer";
import {fetchRecommenderSettingList} from "../../../lib/api";

export function* getRecommenderSettingSaga(action) {
    try{
        console.log("##getRecommenderSettingSaga input#",action.payload.input)
        const res = yield call(fetchRecommenderSettingList,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd
        );
        console.log("##getRecommenderSettingSaga output#",res.data)
        yield put(setRecommenderSetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetRecommenderSetting(error))
    }
}