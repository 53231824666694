import React from 'react';
import BettingSettingContainer from "../../containers/setting/BettingSettingContainer";


const BettingSetting = () => {
    return (
        <div>
            <BettingSettingContainer />
        </div>
    );
};

export default BettingSetting;