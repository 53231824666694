import {call, put, take,} from "redux-saga/effects";
import {fetchEvents} from "../../../lib/api";
import {failedGetEvents, setEvents} from "../../reducers/board/EventReducer";

export function* getEventsSaga(action) {
    try{
        const res = yield call(
            fetchEvents,
            action.payload
        )

        yield put(setEvents(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetEvents(error))
    }
}