import React, {useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Editor from "../../components/common/Editor";
import imageCompression from "browser-image-compression";
import {insertAdminLog} from "../../lib/api";


const InsertNoticePopup = () => {

    const userInfoId = useSelector((state) => state.userInfo.id);
    const userInfoIp = useSelector((state) => state.userInfo.ip);
    const param = useParams();

    const [siteCd, setSiteCd] = useState('')
    const [title, setTitle] = useState('')
    const [cont, setCont] = useState()
    const [nick, setNick] = useState('')
    const [level, setLevel] = useState(0)
    const [isPCDelete, setIsPCDelete] = useState(false)
    const [isMobileDelete, setIsMobileDelete] = useState(false)
    const [isNoti, setIsNoti] = useState(false)
    const [isFix, setIsFix] = useState(false)
    const [titleColor, setTitleColor] = useState('#000000')
    const [fixTime, setFixTime] = useState(0)
    const [printSeq, setPrintSeq] = useState(0)
    const [printYnCd, setPrintYnCd] = useState(null)
    const [regId, setRegId] = useState(null)

    const [pcImageFile, setPcImageFile] = useState()
    const [pcImageUrl, setPcImageUrl] = useState()
    const [mobileImageFile, setMobileImageFile] = useState()
    const [mobileImageUrl, setMobileImageUrl] = useState()


    const imageOptions = {
        maxSizeMB: 100,
        maxWidth: 1920,
        useWebWorker: true,
    }

    useEffect(() => {
        if(param.noticeNo) {
            axios.post('/admin/board/getNotice', {
                id: userInfoId,
                noticeNo: param.noticeNo
            }).then((res) => {
                const item = res.data.result
                setSiteCd(item.print_site_cd)
                setTitle(item.title)
                setCont(item.cont)
                setNick(item.id)
                setLevel(item.level)
                setIsNoti(item.notice_yn == 1)
                setIsFix(item.fix_yn == 1)
                setTitleColor(item.title_color)
                setPrintSeq(item.print_seq)
                setPrintYnCd(item.print_yn_cd)
                setFixTime(item.title_fix_time)
                setRegId(item.reg_id)
                setPcImageUrl(item.pc_image_url? item.pc_image_url : undefined)
                setMobileImageUrl(item.mb_image_url? item.mb_image_url : undefined)
            })
        }
        else {
            insertAdminLog({
                id: userInfoId,
                logContent: "공지게시판 글쓰기화면"
            }).then((res) => {
                if(!res.data.isInserted) {
                    alert("로그삽입실패 개발자문의")
                }
            })
        }
    }, [])
    const siteCdChangeHandler = (e) => {
        setSiteCd(e.currentTarget.value);
    }
    const titleChangeHandler = (e) => {
        setTitle(e.currentTarget.value);
    }
    const nickChangeHandler = (e) => {
        setNick(e.currentTarget.value);
    }
    const levelChangeHandler = (e) => {
        setLevel(e.currentTarget.value);
    }
    const colorChangeHandler = (e) => {
        setTitleColor(e.currentTarget.value)
    }
    const isPCDeleteChangeHandler = (e) => {
        setIsPCDelete(e.currentTarget.checked)
    }
    const isMobileDeleteChangeHandler = (e) => {
        setIsMobileDelete(e.currentTarget.checked)
    }
    const isNotiChangeHandler = (e) => {
        setIsNoti(e.currentTarget.checked)
    }
    const isFixChangeHandler = (e) => {
        setIsFix(e.currentTarget.checked)
    }
    const fixTimeChangeHandler = (e) => {
        setFixTime(e.currentTarget.value)
    }
    const contChangeHandler = (e) => {
        setCont(e.currentTarget.value)
    }

    const onCloseHandler = (e) => {
        if(window.confirm("정말 닫으시곘습니까?")) {
            window.close()
        }
    }

    const saveHandler = (e) => {
        const data = {
            print_site_cd: siteCd,
            print_yn_cd: '01',
            print_seq: printSeq,
            title: title,
            cont: cont,
            isPCDelete: isPCDelete,
            isMobileDelete: isMobileDelete,
            notice_yn: isNoti,
            fix_yn: isFix,
            id: nick,
            level: level,
            title_color: titleColor,
            title_fix_time: fixTime,
            use_ip: userInfoIp,
            reg_id: regId? regId : userInfoId,
            upd_id: userInfoId
        }
        if(param.noticeNo) {
            data.no = param.noticeNo
        }
        if(printYnCd) {
            data.print_yn_cd = printYnCd
        }
        const formData = new FormData()
        if(pcImageFile !== undefined) {
            formData.append("pcImage", pcImageFile)
        }
        if(mobileImageFile !== undefined) {
            formData.append("mobileImage", mobileImageFile)
        }
        formData.append("data", JSON.stringify(data))
        const headers = {
            "Content-Type": "multipart/form-data"
        };

        axios.post('/admin/board/insertNotice', formData, {headers}).then((res) => {
            if(res.data.isInserted) {
                alert('저장완료')
                window.close()
            }
            else {
                alert('저장실패')
            }
        })

    }

    const deleteNotice = (nNo) => {
        if(window.confirm("정말 삭제하시겠습니까?")) {
            axios.post("/admin/board/deleteNotice", {
                nNo: nNo
            }).then((res) => {
                if(res.data.isDeleted) {
                    window.close()
                }
            })
        }

    }

    const changeNoticePrint = (nNo, printYnCode) => {
        axios.post("/admin/board/changeNoticePrint", {
            nNo: nNo,
            printYn: printYnCode,
        }).then((res) => {
            if(res.data.isUpdated) {
                alert("수정완료")
                window.close()
            }
        })
    }

    const previewImage = async (e, change) => {
        e.preventDefault();
        const file = e.target.files[0];
        try {
            const compressedFile = await imageCompression(file, imageOptions);
            const reader = new FileReader();

            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                // 변환 완료!
                const base64data = reader.result;

                handlingDataForm(base64data, change, file.name);
            };
        } catch (error) {
            alert("이미지파일 등록실패")
        }
    }

    const handlingDataForm = async (base64data, change, fileName) => {
        // dataURL 값이 data:image/jpeg:base64,~~~~~~~ 이므로 ','를 기점으로 잘라서 ~~~~~인 부분만 다시 인코딩
        const byteString = atob(base64data.split(",")[1]);

        // Blob를 구성하기 위한 준비, 이 내용은 저도 잘 이해가 안가서 기술하지 않았습니다.
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
            type: "image/jpg"
        });
        const file = new File([blob], fileName);
        console.log(file)
        // 위 과정을 통해 만든 image폼을 FormData에 넣어줍니다.
        // 서버에서는 이미지를 받을 때, FormData가 아니면 받지 않도록 세팅해야합니다.

        if (change === 0) {
            const fileUrl = URL.createObjectURL(file); // (*)
            setPcImageFile(file)
            setPcImageUrl(fileUrl)
        } else {
            const fileUrl = URL.createObjectURL(file); // (*)
            setMobileImageFile(file)
            setMobileImageUrl(fileUrl)
        }
    }

    return <div>
        <p>공지게시판</p>
        <table className="table table-bordered table-sm text-center align-middle">
            <tbody>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    SITE CODE
                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <input className="form-check-input sm-input" type="text" style={{ width: "200px" }} placeholder="" value={siteCd} onChange={siteCdChangeHandler}/>
                        <p style={{fontSize: "9px"}}>
                            출력을 원하는 siteCode를 입력하시면 됩니다.
                            <br/>
                            여러군데에 출력할 경우, 콤마로 구분하시면 되며, 전체에 출력할 경우 비워 놓으시면 됩니다.
                        </p>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    글제목
                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <input className="form-check-input sm-input" type="text" style={{ width: "200px" }} placeholder="" value={title} onChange={titleChangeHandler}/>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    <div>
                        썸네일-PC
                        <br/>
                        <label className="form-check-label" htmlFor="chk_pc">
                            삭제
                        </label>
                        <input className="form-check-input ms-2" type="checkbox" id="chk_pc" checked={isPCDelete} onChange={isPCDeleteChangeHandler} />

                    </div>

                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <input type="file" accept=".jpeg, .jpg, .png" style={{ width: "300px", height: "40px" }} onChange={(e) => previewImage(e,0)}/>
                        <img  src={pcImageUrl} alt=""/>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    <div>
                        썸네일-모바일
                        <br/>
                        <label className="form-check-label" htmlFor="chk_mobile">
                            삭제
                        </label>
                        <input className="form-check-input ms-2" type="checkbox" id="chk_mobile" checked={isMobileDelete} onChange={isMobileDeleteChangeHandler} />

                    </div>

                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <input type="file" accept=".jpeg, .jpg, .png" style={{ width: "300px", height: "40px" }} onChange={(e) => previewImage(e,1)}/>
                        <img  src={mobileImageUrl} alt=""/>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    글쓴이
                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start", display: "flex"}}>
                        <label className="form-check-label" htmlFor="input_nick">
                            nick :
                        </label>
                        <input id="input_nick" className="sm-input ms-1" type="text" style={{ width: "120px" }} placeholder="" value={nick} onChange={nickChangeHandler}/>
                        <label className="form-check-label ms-1" htmlFor="input_level">
                            level :
                        </label>
                        <input id="input_level" className="sm-input mx-1" type="number" style={{ width: "50px" }} placeholder="" value={level} onChange={levelChangeHandler}/>
                    </div>
                </td>
            </tr>

            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    공지/고정
                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start", display: "flex"}}>
                        <label className="form-check-label sm-label" htmlFor="chk_noti">
                            공지여부 :
                        </label>
                        <input className="form-check-input ms-2" type="checkbox" id="chk_noti" checked={isNoti} onChange={isNotiChangeHandler} />
                        <label className="form-check-label ms-3 sm-label" htmlFor="chk_fix">
                            상단고정 :
                        </label>
                        <input className="form-check-input ms-2" type="checkbox" id="chk_fix" checked={isFix} onChange={isFixChangeHandler} />
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    제목색상
                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start", display: "flex"}}>
                        <input id="input_color" type="color" defaultValue="#000000" value={titleColor} onChange={colorChangeHandler}/>
                        <label className="form-check-label ms-1 sm-label" htmlFor="input_color" style={{color: titleColor}}>
                            {titleColor}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    상단고정시간
                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <div style={{display: "flex"}}>
                            <input id="input_time" type="number" style={{width: "50px"}} value={fixTime} onChange={fixTimeChangeHandler}/>
                            <label className="form-check-label ms-1 sm-label" htmlFor="input_time" >
                                시간
                            </label>
                        </div>
                        <p style={{fontSize: "9px"}}>
                            공지사항일 경우만 해당 : 등록시점으로 부터 몇 시간 동안 공지사항으로 표시될 지 정합니다.
                            <br/>
                            미입력시 영구히 공지사항으로 표시됩니다.
                        </p>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    내용
                </td>
                <td>

                    <Editor cont={cont} setCont={setCont} />
                    {/*<textarea style={{width: "100%", height: "300px"}} value={cont} onChange={contChangeHandler}></textarea>*/}
                </td>
            </tr>
            </tbody>

        </table>

        <div className="d-flex">
            <div className="d-flex">
                <button className="btn btn-outline-info btn-sm mx-1" onClick={onCloseHandler}>닫기</button>
                <button className="btn btn-outline-info btn-sm ms-1" onClick={saveHandler}>저장</button>
            </div>
            {param.noticeNo && (
                <div className="d-flex" style={{marginLeft: "auto"}}>
                    {printYnCd && (
                        <>
                            {printYnCd === "01"? (
                                <button className="btn btn-warning btn-sm mx-1" onClick={(e) => {
                                    changeNoticePrint(param.noticeNo, "02")
                                }}>미출력</button>
                            ) : (
                                <button className="btn btn-primary btn-sm mx-1" onClick={(e) => {
                                    changeNoticePrint(param.noticeNo, "01")
                                }}>출력</button>
                            )}
                        </>

                    )}
                    <button className="btn btn-danger btn-sm ms-1" onClick={(e) => {
                        deleteNotice(param.noticeNo)
                    }}>삭제</button>
                </div>
            )}
        </div>

    </div>
}

export default InsertNoticePopup