import {call, put} from "redux-saga/effects";
import {failedGeRegBankSetting, setRegBankSetting} from "../../reducers/setting/RegBankListReducer";
import {fetchRegBankList} from "../../../lib/api";

export function* getRegBankSettingSaga(action) {
    try{
        console.log("##getRegBankSettingSingleSaga input#",action.payload.input)
        const res = yield call(fetchRegBankList,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd
        );
        console.log("##getRegBankSettingSingleSaga output#",res.data)
        yield put(setRegBankSetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGeRegBankSetting(error))
    }
}