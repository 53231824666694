import React, {useState} from "react";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import {
    getActiveFixtureDetail,
    setBetInputs,
    setCheckedInputs,
    setCheckedDetailInputs,
    setCurrentFixture
} from "../../../redux/reducers/game/manage/activeMatch";
import axios from "axios";
import common from "../../../util/common";
import {NumericFormat} from "react-number-format";
import FixtureStatus from "../../../constant/FixtureStatus";
import BetStatus from "../../../constant/BetStatus";

const ActiveList = ({state, onRefresh}) => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo);

    const changeHandler = (checked, id, index) => {
        if(checked) {
            dispatch(setCheckedInputs([...state.checkedInputs, id]));
        }
        else {
            dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== id)));
        }
    };
    const changeDetailHandler = (checked, id) => {
        if(checked) {
            dispatch(setCheckedDetailInputs([...state.checkedDetailInputs, id]));
        }
        else {
            dispatch(setCheckedDetailInputs(state.checkedDetailInputs.filter((el) => el !== id)));
        }
    };

    const allChangeHandler = (e) => {
        const check_ids = []
        for(let index = 0; index < state.low_ratios.length; index++) {
            check_ids.push(state.low_ratios[index].fixture_id)
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []));
    }

    const changeBet = (e, id, index, type) => {
        if(e.target.value !== e.target.defaultValue) {
            e.target.style.color = "green"
        }
        else {
            e.target.style.color = "#555"
        }
        const value = parseFloat(e.target.value)
        const data = {
            bet1: state.fixtures[index].bet1,
            bet2: state.fixtures[index].bet2,
            bet3: state.fixtures[index].bet3,
        }

        const betInputs2 = state.betInputs.filter((el) => el.id !== id);

        const betInputs3 = state.betInputs.filter((el) => el.id === id && el.is_edited === 1);
        let inputs
        if(betInputs3.length > 0) {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: betInputs3[0].bet2, bet3: betInputs3[0].bet3}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: betInputs3[0].bet1, bet3: betInputs3[0].bet3}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1}
            }
        }
        else {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: data.bet2, bet3: data.bet3}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: data.bet1, bet3: data.bet3}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: data.bet2, bet1: data.bet1}
            }
        }
        inputs.is_edited = 1;
        dispatch(setBetInputs([...betInputs2, inputs]))

        //체크처리
        const checkedInput2 = state.checkedInputs.filter((el) => el !== id)
        dispatch(setCheckedInputs([...checkedInput2, id]));
    }

    const editSelectedMatch = async () => {
        const updateData = []
        for(let i = 0; i < state.checkedInputs.length; i++) {
            const betInputs2 = state.betInputs.filter((el) => el.id === state.checkedInputs[i] && el.is_edited === 1)
            const data = {
                id: state.checkedInputs[i]
            }
            if(betInputs2.length > 0) {
                data.is_edited = 1
                const editedData = betInputs2[0];
                data.bet1 = editedData.bet1
                data.bet2 = editedData.bet2
                data.bet3 = editedData.bet3
            }
            else {
                data.is_edited = 0
            }
            updateData.push(data)
        }
        await editActiveMatch(updateData)
        console.log(updateData)
    }
    /*const editAllMatch = async () => {
        const updateData = []
        for(let i = 0; i < state.fixtures.length; i++) {
            const betInputs2 = state.betInputs.filter((el) => el.id === state.fixtures[i])
            const data = {
                id: state.fixtures[i].id
            }
            if(betInputs2.length > 0) {
                data.is_edited = 1
                const editedData = betInputs2[0];
                data.bet1 = editedData.bet1
                data.bet2 = editedData.bet2
                data.bet3 = editedData.bet3
            }
            else {
                data.is_edited = 0
            }
            updateData.push(data)
        }
        await editActiveMatch(updateData)
        console.log(updateData)
    }*/
    const getAllIds = () => {
        const updateData = []
        for(let i = 0; i < state.fixtures.length; i++) {
            updateData.push(state.fixtures[i].id)
        }
        return updateData
    }

    const editActiveMatch = async (updateData) => {
        axios.post('/api/editActiveMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 수정완료");
                onRefresh()
            }
        })
    }
    const stopMatch = (updateData) => {
        axios.post('/api/stopMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 중지처리완료");
                onRefresh()
            }
        })
    }

    const closeMatch = (updateData) => {
        console.log(updateData)
        axios.post('/admin/game/manage/editCloseMatch', {
            adminNo: userInfo.userNo,
            fixtureIds: updateData
        }).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 마감처리완료");
                onRefresh()
            }
        })
    }
    const closeDetail = (gameIds) => {
        console.log(gameIds)
        axios.post('/admin/game/manage/editCloseDetail', {
            adminNo: userInfo.userNo,
            gameIds: gameIds
        }).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 마감처리완료");
                onRefresh()
            }
        })
    }
    const changeGames = (gameArr) => {
        axios.post('/admin/game/manage/changeGames', {
            gameArr: gameArr
        }).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 파싱상태변경완료");
                dispatch(getActiveFixtureDetail(gameArr[0].fixture_id))
            }
        })
    }

    function getDetail(ratios) {

        const ratiosByMarket = common.groupBy(ratios, "market_id")
        const keys = Object.keys(ratiosByMarket)
        const result = []
        for(let i = 0; i < keys.length; i++) {
            //마켓아이디 처리
            const marketId = keys[i]

            const arr = ratiosByMarket[marketId]
            const trs = []
            for(const fixture of arr) {
                trs.push(<tr style={{backgroundColor: fixture.is_edited? "lightyellow":"transparent"}}>
                    <td>
                        <input type="checkbox" name={fixture.id} id={fixture.id} value="true"
                               checked={state.checkedDetailInputs.includes(fixture.id)}
                               onChange={(e) => {changeDetailHandler(e.currentTarget.checked, fixture.id)}}
                        />
                    </td>
                    <td>{fixture.provider}</td>
                    <td className={fixture.bet1_settlement === 2 && "table-success"}>
                        <div>
                            <input type="number" value={fixture.bet1} onChange={(e) => {
                                const newItem = {...fixture}
                                newItem.bet1 = parseFloat(e.currentTarget.value)
                                newItem.is_edited = true
                                const newArr = [...state.currentFixture.ratios.filter(el => el.id !== fixture.id)]
                                newArr.push(newItem)
                                dispatch(setCurrentFixture({
                                    ratios: newArr
                                }))
                            }}/>
                            {fixture.total && (

                                    <p onClick={(e) => {
                                        window.open("/game/manual/betting_popup/" + fixture.id + "/1", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                    }}>
                                        <NumericFormat value={fixture.total.sumExtraMoney1} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount1})`}</span>
                                    </p>)}
                        </div>
                    </td>
                    <td className={fixture.baseline === null && (fixture.bet2_settlement  === 2 && "table-success")}>
                        <div>
                            <p>
                                {fixture.baseline? fixture.baseline.split(' (0')[0] : fixture.bet2? (
                                    <input type="number" value={fixture.bet2} onChange={(e) => {
                                        const newItem = {...fixture}
                                        newItem.bet2 = parseFloat(e.currentTarget.value)
                                        newItem.is_edited = true
                                        const newArr = [...state.currentFixture.ratios.filter(el => el.id !== fixture.id)]
                                        newArr.push(newItem)
                                        dispatch(setCurrentFixture({
                                            ratios: newArr
                                        }))
                                    }}/>
                                ) : "VS"}
                            </p>
                            {fixture.baseline === null && fixture.total && (
                                <p onClick={(e) => {
                                    window.open("/game/manual/betting_popup/" + fixture.id + "/2", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                }}>
                                    <NumericFormat value={fixture.total.sumExtraMoney2} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount2})`}</span>
                                </p>
                            )}
                        </div>
                    </td>
                    <td className={fixture.bet3_settlement  === 2 && "table-success"}>
                        <div>
                            <input type="number" value={fixture.bet3} onChange={(e) => {
                                const newItem = {...fixture}
                                newItem.bet3 = parseFloat(e.currentTarget.value)
                                newItem.is_edited = true
                                const newArr = [...state.currentFixture.ratios.filter(el => el.id !== fixture.id)]
                                newArr.push(newItem)
                                dispatch(setCurrentFixture({
                                    ratios: newArr
                                }))
                            }}/>
                            {fixture.total && (
                                <p onClick={(e) => {
                                    window.open("/game/manual/betting_popup/" + fixture.id + "/3", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                }}>
                                <NumericFormat value={fixture.total.sumExtraMoney3} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount3})`}</span>
                            </p>)}
                        </div>
                    </td>
                    <td>{BetStatus.getKorMessage(fixture.bet1_status)}</td>
                    <td>{moment(fixture.bet1_updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{moment(fixture.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td><button className="btn btn-primary" onClick={(e) => {
                        changeGames([fixture])
                    }}>변경</button></td>
                </tr>)
            }
            const tableTemp = <div>
                <p className="h4 text-bg-dark mb-0 p-2">{arr[0].market} 마켓아이디 {arr[0].market_id}</p>
                <table className="table table-bordered table-hover text-center">
                    <thead className="table-secondary">
                    <tr>
                        <th></th>
                        <th style={{
                            width: "auto"
                        }}>업체</th>
                        <th style={{
                            width: "25%"
                        }}>{arr[0].home}</th>
                        <th style={{
                            width: "13%"
                        }}>{"무/기준점"}</th>
                        <th style={{
                            width: "25%"
                        }}>{arr[0].away}</th>
                        <th style={{
                            width: "auto"
                        }}>상태</th>
                        <th style={{
                            width: "auto"
                        }}>배당수정시간</th>
                        <th style={{
                            width: "auto"
                        }}>최근수정</th>
                        <th style={{
                            width: "auto"
                        }}>변경</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trs}
                    </tbody>
                </table>
            </div>

            result.push(tableTemp)
        }



        return result
    }



    return (
        <div className="container-fluid p-1 m-0">

            <div className="mb-1">
                {/*<button className="btn btn-info me-1" onClick={editAllMatch} >전체수정</button>
                <button className="btn btn-info me-1" onClick={editSelectedMatch} >선택수정</button>*/}
                <button className="btn btn-warning me-1" onClick={() => {closeMatch(state.checkedInputs)}} >선택경기마감</button>
            </div>


            <div className="d-flex">
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {state.low_ratios && (
                        <table className="table table-bordered table-hover text-center">
                            <thead className="table-dark">
                            <tr>
                                <th className="text-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            state.checkedInputs.length === state.low_ratios.length
                                        }
                                        onChange={(e) => {allChangeHandler(e)}}
                                    />
                                </th>
                                <th>종목</th>
                                <th>일정</th>
                                <th>리그</th>
                                <th>홈팀</th>
                                <th>원정팀</th>
                                <th>진행배팅금</th>
                                <th>Fix상태</th>
                                <th>Fix수정시간</th>
                                <th>FixID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.isLoading && "로딩중..."}
                            {!state.isLoading && !state.low_ratios.length > 0 && (
                                <tr>
                                    <td colSpan="3">목록이 비어있습니다.</td>
                                </tr>
                            )}
                            {!state.isLoading && !!state.low_ratios.length && state.low_ratios.map((item, index) => (
                                <tr className={state.checkedInputs.includes(item.id) && "info"} onClick={() => {
                                    dispatch(getActiveFixtureDetail(item.fixture_id))
                                }}>
                                    <td>
                                        <input type="checkbox" name={item.fixture_id} id={item.fixture_id} value="true"
                                               checked={state.checkedInputs.includes(item.fixture_id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, item.fixture_id, index)}}
                                        />
                                    </td>
                                    <td>{`${item.sport}\n(${item.mCount})`}</td>
                                    <td>{moment(item.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{item.league}</td>
                                    <td>
                                        {item.home.split(" [")[0]}
                                    </td>
                                    <td>{item.away.split(" [")[0]}</td>
                                    <td>
                                        {item.total && (
                                            <div style={{
                                                width: "100px",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{color: "green"}} onClick={(e) => {
                                                    window.open("/game/manual/betting_popup/" + item.fixture_id + "/0", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                                }}><NumericFormat value={item.total.sumExtraMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betCount})`}</span></p>
                                                <p style={{color: "blue"}} onClick={(e) => {
                                                    window.open("/game/manual/betting_popup/" + item.fixture_id + "/0", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                                }}><NumericFormat value={item.total.sumTotalMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betTotalCount})`}</span></p>
                                            </div>
                                        )}

                                    </td>
                                    <td>{FixtureStatus.getKorMessage(item.fixture_status)}</td>
                                    <td>{moment(item.fixture_updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>{item.fixture_id}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {state.isLoadingDetail && "로딩중..."}
                    {!state.isLoadingDetail && state.currentFixture === null && "선택된 경기가 없습니다."}
                    {!state.isLoadingDetail && state.currentFixture !== null && (
                        <div>
                            <div className="text-bg-dark p-2 mb-5">
                                <p>{`${state.currentFixture.ratios[0].sport} - ${state.currentFixture.ratios[0].league}`}</p>
                                <div className="text-center align-middle" style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}><p>{`${state.currentFixture.ratios[0].home.split(" [")[0]}`}</p></div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center",
                                    }}>
                                        <p className="m-0">{`${moment(state.currentFixture.ratios[0].start_date).format("YYYY-MM-DD HH:mm")}`}</p>
                                    </div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}>{`${state.currentFixture.ratios[0].away.split(" [")[0]}`}</div>

                                </div>
                                <button className="btn btn-warning me-1" onClick={() => {closeDetail(state.checkedDetailInputs)}} >선택게임마감</button>
                                <button className="btn btn-primary" onClick={() => {changeGames(state.currentFixture.ratios.filter((el) => el.is_edited === true))}} >일괄변경</button>
                            </div>
                            {getDetail(state.currentFixture.ratios)}

                        </div>
                    )}

                </div>
            </div>

        </div>
    );

}

export default ActiveList;