import React from 'react';
import LevelSettingContainer from "../../containers/setting/LevelSettingContainer";
import LvlSettingComponent from "../../components/modal/setting/LvlSettingComponent";


const defaultSetting = () => {
    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">기본설정</font>
                </div>


            </div>
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"100%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> 기본설정</b></font>
                        </div>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>사이트명</b></font>
                                    &nbsp;&nbsp;
                                    <input type="text" style={{width:"340px"}}/>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>사이트 타이틀</b></font>
                                    &nbsp;&nbsp;
                                    <input type="text" style={{width:"340px"}}/>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>사이트 도메인</b></font>
                                    &nbsp;&nbsp;
                                    <input type="text" style={{width:"340px"}}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>전체 페이지</b></font>
                                    &nbsp;&nbsp;
                                    <select>
                                        <option>사용</option>
                                        <option>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>회원 페이지</b></font>
                                    &nbsp;&nbsp;
                                    <select>
                                        <option>사용</option>
                                        <option>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>파트너 페이지</b></font>
                                    &nbsp;&nbsp;
                                    <select>
                                        <option>사용</option>
                                        <option>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input type="text" style={{width:"385px"}} placeholder={"점검제목"}/>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input type="text" style={{width:"385px"}} placeholder={"점검제목"}/>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input type="text" style={{width:"385px"}} placeholder={"점검제목"}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <textarea name="textarea_name" cols="70" rows="6"/>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <textarea name="textarea_name" cols="70" rows="6"/>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <textarea name="textarea_name" cols="70" rows="6"/>
                                </td>
                            </tr>
                        </table>

                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>접속 제한시간</b></font>
                                    &nbsp;&nbsp;
                                    <select>
                                        <option>사용</option>
                                        <option>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 제한시간</b></font>
                                    &nbsp;&nbsp;
                                    <select>
                                        <option>사용</option>
                                        <option>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>환전 제한시간</b></font>
                                    &nbsp;&nbsp;
                                    <select>
                                        <option>사용</option>
                                        <option>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input type="text" style={{width:"60px", textAlign:"center"}} placeholder={"00 : 00"}/>
                                    &nbsp;-&nbsp;
                                    <input type="text" style={{width:"60px", textAlign:"center"}} placeholder={"00 : 00"}/>
                                    &nbsp;&nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                    >
                                        등록
                                    </button>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input type="text" style={{width:"60px", textAlign:"center"}} placeholder={"00 : 00"}/>
                                    &nbsp;-&nbsp;
                                    <input type="text" style={{width:"60px", textAlign:"center"}} placeholder={"00 : 00"}/>
                                    &nbsp;&nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                    >
                                        등록
                                    </button>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input type="text" style={{width:"60px", textAlign:"center"}} placeholder={"00 : 00"}/>
                                    &nbsp;-&nbsp;
                                    <input type="text" style={{width:"60px", textAlign:"center"}} placeholder={"00 : 00"}/>
                                    &nbsp;&nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                    >
                                        등록
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <textarea name="textarea_name" cols="70" rows="6"/>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <textarea name="textarea_name" cols="70" rows="6"/>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <textarea name="textarea_name" cols="70" rows="6"/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "28px", width: "50px"}}
                                    >
                                        삭제
                                    </button>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "28px", width: "50px"}}
                                    >
                                        삭제
                                    </button>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "28px", width: "50px"}}
                                    >
                                        삭제
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div style={{
                    position:"relative",
                    background:"#3c3c3d",
                    height:"30px",
                    alignItems:"center",
                    textAlign:"left",
                    alignContent:"center"
                }}>
                    <table style={{width: "99%"}}>
                        <tr>
                            <td style={{width: "50%"}} align={"left"}>
                                &nbsp;&nbsp;&nbsp;
                                <font color={"white"} size={1.5}><b> 배팅알림</b></font>
                            </td>
                            <td style={{width: "50%"}} align={"right"}>
                                <select style={{height: "25px", width:"100px"}}>
                                    <option>사용</option>
                                    <option>미사용</option>
                                </select>
                            </td>
                        </tr>
                    </table>

                </div>

                <table className="commonT table table-bordered table-sm text-center">
                    <tr>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>스포츠</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <select>
                                <option>사용</option>
                                <option>미사용</option>
                            </select>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>카지노</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <select>
                                <option>사용</option>
                                <option>미사용</option>
                            </select>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>슬롯</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <select>
                                <option>사용</option>
                                <option>미사용</option>
                            </select>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>미니게임</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <select>
                                <option>사용</option>
                                <option>미사용</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>스포츠 최소 베팅금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>카지노 최소 베팅금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>슬롯 최소 베팅금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>미니게임 최소 베팅금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>스포츠 최소 당첨금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>카지노 최소 당첨금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>슬롯 최소 당첨금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>미니게임 최소 당첨금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>악성회원 최소 배팅금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>악성회원 최소 배팅금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>악성회원 최소 배팅금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>악성회원 최소 배팅금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>악성회원 최소 당첨금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>악성회원 최소 당첨금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>악성회원 최소 당첨금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                        <td style={{width: "1%",backgroundColor: "white", height:"30px"}} align={"left"}>
                        </td>
                        <td style={{width: "14%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>악성회원 최소 당첨금</b>
                        </td>
                        <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"10,000"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                    </tr>
                    
                </table>


                <div style={{
                    position:"relative",
                    background:"#3c3c3d",
                    height:"30px",
                    alignItems:"center",
                    textAlign:"left",
                    alignContent:"center"
                }}>
                    <table style={{width: "99%"}}>
                        <tr>
                            <td style={{width: "100%"}} align={"left"}>
                                &nbsp;&nbsp;&nbsp;
                                <font color={"white"} size={1.5}><b> 로그인</b></font>
                            </td>
                        </tr>
                    </table>

                </div>

                <table className="commonT table table-bordered table-sm text-center">
                    <tr>
                        <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>마지막 로그인 몇일 전 회원 자동 휴면</b>
                            &nbsp;&nbsp;
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"0"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                일
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <b>몇 회 이상 로그인 실패시 계정 차단</b>
                            &nbsp;&nbsp;
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"0"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                회
                            </button>
                            &nbsp;&nbsp;
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"점검제목"} style={{width:"400px"}}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>마지막 입금 몇일 전 회원 자동 휴면</b>
                            &nbsp;&nbsp;
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"0"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                일
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <b>몇 회 이상 로그인 실패시 IP 차단</b>
                            &nbsp;&nbsp;
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"0"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                회
                            </button>
                            &nbsp;&nbsp;
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"점검제목"} style={{width:"400px"}}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>회원 중복 접속</b>
                            &nbsp;&nbsp;
                            <select>
                                <option>사용</option>
                                <option>미사용</option>
                            </select>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <b>자동 로그아웃</b>
                            &nbsp;&nbsp;
                            <input className="form-check-input sm-input" type="text"
                                   placeholder={"0"} style={{width:"100px"}}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                분
                            </button>
                        </td>
                    </tr>
                </table>

                <div style={{
                    position:"relative",
                    background:"#3c3c3d",
                    height:"30px",
                    alignItems:"center",
                    textAlign:"left",
                    alignContent:"center"
                }}>
                    <table style={{width: "99%"}}>
                        <tr>
                            <td style={{width: "100%"}} align={"left"}>
                                &nbsp;&nbsp;&nbsp;
                                <font color={"white"} size={1.5}><b> 알림음</b></font>
                            </td>
                        </tr>
                    </table>

                </div>

                <table className="commonT table table-bordered table-sm text-center">
                    <tr>
                        <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <font color={"black"} size={1.5}><b> 회원가입</b></font>
                            &nbsp;
                            <input type="file" placeholder={"알림파일 설정"} style={{width:"200px"}} />
                            &nbsp;
                            <font color={"black"} size={1.5}><b> 충전</b></font>
                            &nbsp;
                            <input type="file" placeholder={"알림파일 설정"} style={{width:"200px"}} />
                            &nbsp;
                            <font color={"black"} size={1.5}><b> 환전</b></font>
                            &nbsp;
                            <input type="file" placeholder={"알림파일 설정"} style={{width:"200px"}} />
                            &nbsp;
                            <font color={"black"} size={1.5}><b> 고객센터</b></font>
                            &nbsp;
                            <input type="file" placeholder={"알림파일 설정"} style={{width:"200px"}} />
                            &nbsp;
                            비회원문의, 스포츠배팅, 카지노배팅, 슬롯배팅, 미니게임배팅, 악성회원배팅, 쪽지, 답변
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <font color={"black"} size={1.5}><b> 회원상태</b></font>&nbsp;&nbsp;
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}>
                                추가
                            </button>
                            &nbsp;&nbsp;
                            <font color={"black"} size={1.5}><b> 회원레벨</b></font>&nbsp;&nbsp;
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}>
                                추가
                            </button>
                            &nbsp;&nbsp;
                            <font color={"black"} size={1.5}><b> 회원유형</b></font>&nbsp;&nbsp;
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}>
                                추가
                            </button>
                            &nbsp;&nbsp;
                            <font color={"black"} size={1.5}><b> 회원색상</b></font>&nbsp;&nbsp;
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}>
                                추가
                            </button>
                            &nbsp;&nbsp;
                            <font color={"black"} size={1.5}><b> 회원태그</b></font>&nbsp;&nbsp;
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}>
                                추가
                            </button>
                            &nbsp;&nbsp;
                            <font color={"black"} size={1.5}><b> 회원그룹</b></font>&nbsp;&nbsp;
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}>
                                추가
                            </button>
                            &nbsp;&nbsp;
                            <font color={"black"} size={1.5}><b> 전용계좌</b></font>&nbsp;&nbsp;
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}>
                                추가
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"center"}>
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "60px"}}>
                                변경
                            </button>
                        </td>
                    </tr>
                </table>


            </div>
        </div>
    );
};

export default defaultSetting;