import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    detail : {},
    input:{
        no: 0,
        query: "",
    },
}

export const statsSlice = createSlice({
    name: 'MessagePopReducer',
    initialState,
    reducers: {
        getMessagePopReducer(state, action){
            console.log("#reducers getMessagePopReducer start#")
        },
        setMessagePopReducer(state, action) {
            console.log("#reducers setMessagePopReducer start#",action.payload.detail);
            state.detail = action.payload.detail;
            state.totalList = action.payload.totalList;

        },
        failedGetMessagePopReducer(state, action) {
            state.blackTypeCd = action.payload;
        },
        setTitle(state, action) {
            state.detail.title = action.payload;
        },
        setCont(state, action){
            state.detail.cont = action.payload;
        },
        setQuery(state, action){
            state.input.query = action.payload;
        },
        setNo(state, action){
            state.input.no = action.payload;
        },
        resetState: () => initialState,
    }
});

export const {
    getMessagePopReducer,
    setMessagePopReducer,
    failedGetMessagePopReducer,
    resetState,
    setCont,
    setTitle,
    setQuery,
    setNo
} = statsSlice.actions;

export default statsSlice.reducer;