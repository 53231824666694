import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"
import {useSelector} from "react-redux";

const initialState = {
    totalList : [],
    totalCount : 0,
    gridList : [],
    gridCount : 0,
    input:{
        gb : "등록",
        _no: 2,
        template: "",
        message_title: "",
        message_cont: "",
        reg_id: "",
        upd_id: ""
    }
}

export const statsSlice = createSlice({
    name: 'MessageTemplateReducer',
    initialState,
    reducers: {
        getMessageTemplateReducer(state, action){
            console.log("#reducers getMessageTemplateReducer start#",
                action.payload.input.pageSize,
                action.payload.input.page)
            ;
            state.input.page = action.payload.input.pageSize === 999? 1: action.payload.input.page;
            state.input.pageSize = action.payload.input.pageSize;
        },
        setMessageTemplateReducer(state, action) {
            console.log("#reducers setMessageTemplateReducer start#",action.payload.gridList);
            if((state.input.page -1) * state.input.pageSize > action.payload.totalCount) {
                state.input.page = 1
            }
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.gridCount = action.payload.gridCount;
            state.gridList = action.payload.gridList;
        },
        setGb(state, action) {
            state.input.gb = action.payload;
        },
        setNo(state, action) {
            state.input._no = action.payload;
        },
        setTemplate(state, action) {
            state.input.template = action.payload;
        },
        setMessageTitle(state, action) {
            state.input.message_title = action.payload;
        },
        setMessageCont(state, action) {
            state.input.message_cont = action.payload;
        },
        setRegId(state, action) {
            state.input.reg_id = action.payload;
        },
        setUpdId(state, action) {
            state.input.upd_id = action.payload;
        },
        failedGetMessageTemplateReducer(state, action) {
            state.blackTypeCd = action.payload;
        },
        resetState: () => initialState,
    }
});

export const {
    getMessageTemplateReducer,
    setMessageTemplateReducer,
    failedGetMessageTemplateReducer,
    resetState,
    setGb,
    setMessageCont,
    setMessageTitle,
    setNo,
    setTemplate,
    setRegId,
    setUpdId
} = statsSlice.actions;

export default statsSlice.reducer;