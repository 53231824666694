import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {setBlackUploadList} from "../../redux/reducers/user/BlackUploadReducer";
import * as XLSX from 'xlsx';
import axios from "axios";
import {insertAdminLog} from "../../lib/api";
const Black_upload = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.BlackUploadReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)

    useEffect(() => {
        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "엑셀블랙회원등록"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])
    const handleFileUpload = (e) => {
        try {
            const file = e.target.files[0];

            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const binaryString = event.target.result;
                    const workbook = XLSX.read(binaryString, { type: 'binary' });

                    workbook.SheetNames.forEach((sheetName) => {
                        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        console.log('Sheet:', sheetName);
                        console.log('Data:', data);
                        // 여기서 데이터를 처리하거나 상태로 저장할 수 있습니다.
                        const _blackUploadList = [];
                        for (let i = 0; i <data.length; i++) {
                            _blackUploadList.push({
                                reg_type_cd: '02',
                                black_type_cd: data[i].블랙구분코드,
                                id:data[i].아이디,
                                nickname:data[i].닉네임,
                                mbphStart:data[i].핸드폰앞자리,
                                mbphCenter:data[i].핸드폰중간자리,
                                mbphEnd:data[i].핸드폰마지막자리,
                                account: data[i].계좌번호,
                                messange_info:data[i].메신저아이디정보,
                                damage_amt:data[i].피해금액,
                                black_memo:data[i].블랙사유메모,
                                reg_id:userInfoId,
                                upd_id:userInfoId,
                            });
                        }

                        dispatch(setBlackUploadList(_blackUploadList));
                    });
                } catch (error) {
                    console.error('Error while processing the Excelfile:', error);
                }
            };

            if (file) {
                reader.readAsBinaryString(file);
            }
        } catch (error) {
            console.error('Error while handling the file upload:', error);
        }
    };

    const handleDownload = () => {
        // 엑셀 데이터 예시 (임의로 데이터를 생성하거나 가져오세요)
// 새 워크북 생성
        const workbook = XLSX.utils.book_new();
        const sheetName = 'Sheet1';

// 데이터 생성
        const data = [
            ['아이디', '닉네임','핸드폰앞자리','핸드폰중간자리','핸드폰마지막자리','계좌번호','블랙구분코드','메신저아이디정보','피해금액','블랙사유메모'],
            ['hong1', '닉네임1','010','1111','2222','1234567890','01','mhong123',200000,'해킹의심으로 인한 블랙'],
        ];


// 시트 생성
        const worksheet = XLSX.utils.aoa_to_sheet(data);

// 모든 셀에 텍스트 형식 적용
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellAddress = { c: C, r: R };
                const cellRef = XLSX.utils.encode_cell(cellAddress);
                const cell = worksheet[cellRef];
                if (cell && cell.t === 'n') {
                    cell.t = 's'; // 숫자 타입을 문자열 타입으로 변경
                }
            }
        }

// 워크북에 시트 추가
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

// 파일 저장
        XLSX.writeFile(workbook, '블랙회원엑셀업로드포맷.xlsx');
    };

    const save = () => {
        if (window.confirm("블랙회원 엑셀업로드 하시겠습니까?")) {
            axios.post('/admin/user/Black_upload/insert', {
                id : userInfoId,
                blackUploadList:initialState.blackUploadList,
            }).then((res) => {
                if(res.data.isInserted) {
                    alert('업로드완료')
                }
                else {
                    alert('업로드실패')
                }
            })
        }
    };

    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"red"} size="5">엑셀로 블랙회원등록</font>
                </div>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="table table-bordered table-sm text-center align-middle" >
                        <thead>
                        <tr>
                            <th align={"center"} width={"75%"}>파일</th>
                            <th align={"center"} width={"25%"}>올리기</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <input type="file" onChange={handleFileUpload} />
                            </td>
                            <td>
                                <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}} onClick={save}>올리기</button>
                            </td>
                        </tr>
                        <tr>
                            <td align={"left"} colSpan="2">
                                <font color={"red"}>* 많은 양을 한 번에 올리실 경우 시간이 소요됩니다. 충분히 기다려주시기 바랍니다.</font>
                            </td>
                        </tr>
                        <tr>
                            <td align={"left"} colSpan="2">
                                <font color={"red"}>* 업로드 하는 피씨에서는 관리자가 잠시 멈춘 것처럼 보일 수 있습니다. 업로드 중엔 다른 작업을 삼가주세요.</font>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th align={"center"} width={"75%"}>블랙 회원 올리기 포맷 다운로드</th>
                            <th align={"center"} width={"25%"}>다운로드</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                블랙 회원 등록용 엑셀 포맷
                            </td>
                            <td>
                                <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleDownload}>다운로드</button>
                            </td>
                        </tr>
                        <tr>
                            <td align={"left"} colSpan="2">
                                <font color={"black"}>* 블랙 회원을 등록할 떄 : 신규회원 올리기 포맷 엑셀 파일을 다운로드해서 수정하신 후 업로드 해주세요.</font>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th align={"left"} >
                                <font color={"black"}>* 블랙 회원 업로드예정 리스트가 아래에 나옵니다.</font>
                            </th>
                        </tr>
                        </thead>
                    </table>
                </div>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th colSpan="5">업로드예정리스트</th>
                        </tr>
                        <tr>
                            <th>아이디</th>
                            <th>닉네임</th>
                            <th>핸드폰앞자리</th>
                            <th>핸드폰증간자리</th>
                            <th>핸드폰뒷자리</th>
                            <th>계좌번호</th>
                            <th>블랙구분코드</th>
                            <th>메신저아이디정보</th>
                            <th>피해금액</th>
                            <th>블랙사유메모</th>
                        </tr>
                        </thead>
                        {initialState.blackUploadList&&initialState.blackUploadList.length >0 ? initialState.blackUploadList.map((List, index) => (
                                <tbody key={List.id}>
                                <tr>
                                    <td>{List.id}</td>
                                    <td>{List.nickname}</td>
                                    <td>{List.mbphStart}</td>
                                    <td>{List.mbphCenter}</td>
                                    <td>{List.mbphEnd}</td>
                                    <td>{List.account}</td>
                                    <td>{List.black_type_cd}</td>
                                    <td>{List.messange_info}</td>
                                    <td>{List.damage_amt}</td>
                                    <td>{List.black_memo}</td>
                                </tr>
                                </tbody>
                            )) :
                            <tbody>
                            <tr>
                                <td colSpan= "5">업로드 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>

            </div>
        </div>


    )
}
export default Black_upload;