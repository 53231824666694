import {call, put, take,} from "redux-saga/effects";
import {fetchStopMatchBy} from "../../../../lib/api";
import {
    failedGetStopMatchBy, setStopMatchBy
} from "../../../reducers/game/manage/stopMatchBy";

export function* getStopMatchBySaga(action) {
    try {
        const res = yield call(fetchStopMatchBy, action.payload);
        yield put(setStopMatchBy(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetStopMatchBy(error));
    }
}


