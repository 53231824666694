import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../redux/reducers/user/BlackMemberAddModalReducer";
import {getMemberUser} from "../../../redux/reducers/user/MemberReducer";

const LimitETCContainer = ({userId, setSelectedTab}) => {

    const [detailInfo, setDetailInfo] = useState({});
    const userInfoId = useSelector((state) => state.userInfo.id)
    const userInfoIp = useSelector((state) => state.userInfo.ip)

    const [val01, setVal01] = useState('')
    const [val02, setVal02] = useState('')
    const [val03, setVal03] = useState('')
    const [val04, setVal04] = useState('')
    const [val05, setVal05] = useState('')
    const [val06, setVal06] = useState('')
    const [val07, setVal07] = useState('')
    const [val08, setVal08] = useState('')
    const [val09, setVal09] = useState('')
    const [val10, setVal10] = useState('')
    const [val11, setVal11] = useState('')
    const [val12, setVal12] = useState('')
    const [val13, setVal13] = useState('')
    const [val14, setVal14] = useState('')
    const [val15, setVal15] = useState('')
    const [val16, setVal16] = useState('')
    const [val17, setVal17] = useState('')
    const [val18, setVal18] = useState('')
    const [val19, setVal19] = useState('')
    const [val20, setVal20] = useState('')
    const [val21, setVal21] = useState('')
    const [val22, setVal22] = useState('')
    const [val23, setVal23] = useState('')
    const [val24, setVal24] = useState('')
    const [val25, setVal25] = useState('')
    const [val26, setVal26] = useState('')
    const [val27, setVal27] = useState('')
    const [val28, setVal28] = useState('')
    const [val29, setVal29] = useState('')
    const [val30, setVal30] = useState('')
    const [val31, setVal31] = useState('')
    const [val32, setVal32] = useState('')
    const [val33, setVal33] = useState('')
    const [val34, setVal34] = useState('')
    const [val35, setVal35] = useState('')
    const [val36, setVal36] = useState('')
    const [val37, setVal37] = useState('')
    const [boardBlockCd, setBoardBlockCd] = useState('')
    const [dangerIpCd, setDangerIpCd] = useState('')


    const getDetailInfo = () => {
        axios.post('/admin/member/detailInfoEtc', {
            userId : userId
        }).then((res) => {
            setDetailInfo(res.data.cmmnCdList);
        })
    }
    useEffect(getDetailInfo, [])

    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }

    const val01ChangeHandler = (e) => { setVal01(e.currentTarget.value); }
    const val02ChangeHandler = (e) => { setVal02(e.currentTarget.value); }
    const val03ChangeHandler = (e) => { setVal03(e.currentTarget.value); }
    const val04ChangeHandler = (e) => { setVal04(e.currentTarget.value); }
    const val05ChangeHandler = (e) => { setVal05(e.currentTarget.value); }
    const val06ChangeHandler = (e) => { setVal06(e.currentTarget.value); }
    const val07ChangeHandler = (e) => { setVal07(e.currentTarget.value); }
    const val08ChangeHandler = (e) => { setVal08(e.currentTarget.value); }
    const val09ChangeHandler = (e) => { setVal09(e.currentTarget.value); }
    const val10ChangeHandler = (e) => { setVal10(e.currentTarget.value); }
    const val11ChangeHandler = (e) => { setVal11(e.currentTarget.value); }
    const val12ChangeHandler = (e) => { setVal12(e.currentTarget.value); }
    const val13ChangeHandler = (e) => { setVal13(e.currentTarget.value); }
    const val14ChangeHandler = (e) => { setVal14(e.currentTarget.value); }
    const val15ChangeHandler = (e) => { setVal15(e.currentTarget.value); }
    const val16ChangeHandler = (e) => { setVal16(e.currentTarget.value); }
    const val17ChangeHandler = (e) => { setVal17(e.currentTarget.value); }
    const val18ChangeHandler = (e) => { setVal18(e.currentTarget.value); }
    const val19ChangeHandler = (e) => { setVal19(e.currentTarget.value); }
    const val20ChangeHandler = (e) => { setVal20(e.currentTarget.value); }
    const val21ChangeHandler = (e) => { setVal21(e.currentTarget.value); }
    const val22ChangeHandler = (e) => { setVal22(e.currentTarget.value); }
    const val23ChangeHandler = (e) => { setVal23(e.currentTarget.value); }
    const val24ChangeHandler = (e) => { setVal24(e.currentTarget.value); }
    const val25ChangeHandler = (e) => { setVal25(e.currentTarget.value); }
    const val26ChangeHandler = (e) => { setVal26(e.currentTarget.value); }
    const val27ChangeHandler = (e) => { setVal27(e.currentTarget.value); }
    const val28ChangeHandler = (e) => { setVal28(e.currentTarget.value); }
    const val29ChangeHandler = (e) => { setVal29(e.currentTarget.value); }
    const val30ChangeHandler = (e) => { setVal30(e.currentTarget.value); }
    const val31ChangeHandler = (e) => { setVal31(e.currentTarget.value); }
    const val32ChangeHandler = (e) => { setVal32(e.currentTarget.value); }
    const val33ChangeHandler = (e) => { setVal33(e.currentTarget.value); }
    const val34ChangeHandler = (e) => { setVal34(e.currentTarget.value); }
    const val35ChangeHandler = (e) => { setVal35(e.currentTarget.value); }
    const val36ChangeHandler = (e) => { setVal36(e.currentTarget.value); }
    const val37ChangeHandler = (e) => { setVal37(e.currentTarget.value); }

    const boardBlockCdHandler = (e) => { setBoardBlockCd(e.currentTarget.value); }
    const dangerIpCdHandler = (e) => { setDangerIpCd(e.currentTarget.value); }

    const handleRegister = () => {

        //alert("boardBlockCd ::: "+boardBlockCd);

        axios.post('/admin/member/updateLimitEtc', {
            id: userId,
            val01: val01,
            val02: val02,
            val03: val03,
            val04: val04,
            val05: val05,
            val06: val06,
            val07: val07,
            val08: val08,
            val09: val09,
            val10: val10,
            val11: val11,
            val12: val12,
            val13: val13,
            val14: val14,
            val15: val15,
            val16: val16,
            val17: val17,
            val18: val18,
            val19: val19,
            val20: val20,
            val21: val21,
            val22: val22,
            val23: val23,
            val24: val24,
            val25: val25,
            val26: val26,
            val27: val27,
            val28: val28,
            val29: val29,
            val30: val30,
            val31: val31,
            val32: val32,
            val33: val33,
            val34: val34,
            val35: val35,
            val36: val36,
            val37: val37,
            boardBlockCd: boardBlockCd,
            dangerIpCd: dangerIpCd,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    alert('수정완료')
                    setSelectedTab(0)
                    setSelectedTab(6)
                } else {
                    alert('수정실패')
                    setSelectedTab(0)
                    setSelectedTab(6)
                }
            })


    }

    const handleClose = () => {
        window.close();
    }


    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table width={"99%"}>
                    <tr>
                        <td align={"right"}>
                            <button style={{
                                backgroundColor: "#5dc434",
                                border: "none",
                                color: "white",
                                fontSize: "9px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "10px"
                            }}
                                    onClick={handleRegister}
                            >회원 수정
                            </button>
                            <button style={{
                                backgroundColor: "#3fadf7",
                                border: "none",
                                color: "white",
                                fontSize: "9px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "5px"
                            }}  onClick={handleClose}>닫기
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <b>본인에 대한 설정</b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "lightgray"}}>종류</td>
                            <td style={{width: "70%",backgroundColor: "lightgray"}}>값</td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>일일 최대 환전 신청 건수</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val01 != null && detailInfo.val01 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val01)} onChange={val01ChangeHandler}
                                />&nbsp;건<br></br>
                                * 0으로 설정시, 환전 불가
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>일일 최대 환전 금액</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val02 != null && detailInfo.val02 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val02)} onChange={val02ChangeHandler}
                                />&nbsp;원<br></br>
                                * 0으로 설정시, 환전 불가
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>1회 최대 환전 금액</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val03 != null && detailInfo.val03 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val03)} onChange={val03ChangeHandler}
                                />&nbsp;원<br></br>
                                * 0으로 설정시, 환전 불가
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 낙첨금%</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val04 != null && detailInfo.val04 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={detailInfo.val04} onChange={val04ChangeHandler}
                                />&nbsp;%<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 첫충%</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val05 != null && detailInfo.val05 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={detailInfo.val05} onChange={val05ChangeHandler}
                                />&nbsp;%<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 매충%</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val06 != null && detailInfo.val06 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={detailInfo.val06} onChange={val06ChangeHandler}
                                />&nbsp;%<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>매충포인트 지급 불가 환전 횟수</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val07 != null && detailInfo.val07 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val07)} onChange={val07ChangeHandler}
                                /><br></br>
                                * -1일 경우 사이트설정을 따름. 0이면 무조건 지급, 1이상일 경우, 해당 환전횟수부터 매충포인트 지급 불가
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>환전이 있어도 첫충 포인트는 지급 여부</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val08 != null && detailInfo.val08 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val08)} onChange={val08ChangeHandler}
                                /><br></br>
                                * -1일 경우 사이트설정을 따름. 0일 경우 매충설정을 따름, 1일 경우 환전이 있더라도 지급
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 첫충/매충 일일 최대 지급 포인트</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val09 != null && detailInfo.val09 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val09)} onChange={val09ChangeHandler}
                                /><br></br>
                                * 0이면 한도 없음. -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 첫충/매충 일일 최대 지급 횟수</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val10 != null && detailInfo.val10 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val10)} onChange={val10ChangeHandler}
                                /><br></br>
                                * 0이면 한도 없음. -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 첫충/매충 1회 최대 지급 포인트</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val11 != null && detailInfo.val11 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val11)} onChange={val11ChangeHandler}
                                /><br></br>
                                * 0이면 한도 없음. -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 1폴 낙첨 포인트 지급 여부</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val12 != null && detailInfo.val12 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val12)} onChange={val12ChangeHandler}
                                /><br></br>
                                * -1:기타설정 따름. 0:지급금지. 1:강제지급
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>본인 2폴 낙첨 포인트 지급 여부</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val13 != null && detailInfo.val13 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val13)} onChange={val13ChangeHandler}
                                /><br></br>
                                * -1:기타설정 따름. 0:지급금지. 1:강제지급
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>크로스 베팅 최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val14 != null && detailInfo.val14 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val14)} onChange={val14ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>크로스 베팅 최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val15 != null && detailInfo.val15 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val15)} onChange={val15ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>크로스2 베팅
                                최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val16 != null && detailInfo.val16 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val16)} onChange={val16ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>크로스2 베팅
                                최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val17 != null && detailInfo.val17 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val17)} onChange={val17ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>승무패 베팅
                                최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val18 != null && detailInfo.val18 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val18)} onChange={val18ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>승무패 베팅
                                최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val19 != null && detailInfo.val19 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val19)} onChange={val19ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>핸디캡 베팅
                                최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val20 != null && detailInfo.val20 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val20)} onChange={val20ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>핸디캡 베팅
                                최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val21 != null && detailInfo.val21 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val21)} onChange={val21ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>스페셜 베팅
                                최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val22 != null && detailInfo.val22 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val22)} onChange={val22ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>스페셜 베팅
                                최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val23 != null && detailInfo.val23 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val23)} onChange={val23ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>실시간 베팅
                                최소 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val24 != null && detailInfo.val24 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val24)} onChange={val24ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최소 이만큼을 조합해야 베팅되게 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>실시간 베팅
                                최대 폴더</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val25 != null && detailInfo.val25 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val25)} onChange={val25ChangeHandler}
                                /><br></br>
                                * 베팅시, 개인별로 최대 폴더 수를 제한합니다. -1이면 기본설정대로
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>베팅 최대 배당</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val26 != null && detailInfo.val26 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val26)} onChange={val26ChangeHandler}
                                /><br></br>
                                * -1:환경설정>레벨별설정 따름<br></br>
                                * 베팅시, 개인별로 최대 배당을 제한합니다.
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>최소 베팅금</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val27 != null && detailInfo.val27 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val27)} onChange={val27ChangeHandler}
                                /><br></br>
                                * -1:환경설정>레벨별설정 따름<br></br>
                                * 베팅시, 개인별로 최소 베팅금을 지정합니다.
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>환전시 시간 간격 제한(분)</td>
                            <td style={{width: "70%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val28 != null && detailInfo.val28 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val28)} onChange={val28ChangeHandler}
                                /><br></br>
                                * -1:환경설정>사이트설정 따름<br></br>
                                * 환전신청시 마지막 성공한 환전으로부터 최소 몇 분이 지나야 신청 가능한 지. 0일 경우 제한 없음
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                            </td>
                        </tr>


                        </thead>
                    </table>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex", alignItems:'flex-start'
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <b>본인에 대한 설정</b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "lightgray"}}>종류</td>
                            <td style={{width: "55%",backgroundColor: "lightgray"}}>값</td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 낙첨금%<br></br>
                                (본인을 추천인으로 설정한 회원이 낙첨시, 본인이 받게될 낙첨금)
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val29 != null && detailInfo.val29 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={detailInfo.val29} onChange={val29ChangeHandler}
                                />&nbsp;%<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 첫충%<br></br>
                                (본인을 추천인으로 설정한 회원 첫충시 받을 %)
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val30 != null && detailInfo.val30 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={detailInfo.val30} onChange={val30ChangeHandler}
                                />&nbsp;%<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 매충%<br></br>
                                (본인을 추천인으로 설정한 회원 매충시 받을 %)
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val31 != null && detailInfo.val31 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={detailInfo.val31} onChange={val31ChangeHandler}
                                />&nbsp;%<br></br>
                                * -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 첫충/매충 일일 최대 지급 포인트<br></br>
                                (본인을 추천인으로 설정한 회원 첫충/매충시 받을 보너스 일일 최대 금액)
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val32 != null && detailInfo.val32 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val32)} onChange={val32ChangeHandler}
                                /><br></br>
                                * 0이면 한도 없음. -1이면 레벨별 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 1폴 낙첨 포인트 지급 여부
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val33 != null && detailInfo.val33 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val33)} onChange={val33ChangeHandler}
                                /><br></br>
                                * -1:환경설정>기타설정 따름. 0:지급금지. 1:강제지급<br></br>
                                * 본인을 추천인으로 설정한 회원의 1폴 낙첨시 포인트를 받을지 여부
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                지인 2폴 낙첨 포인트 지급 여부
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val34 != null && detailInfo.val34 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={addComma(detailInfo.val34)} onChange={val34ChangeHandler}
                                /><br></br>
                                * -1:환경설정>기타설정 따름. 0:지급금지. 1:강제지급<br></br>
                                * 본인을 추천인으로 설정한 회원의 2폴 낙첨시 포인트를 받을지 여부
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}><br></br></td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <b>본인이 추천인으로 설정한 이에 대한 설정</b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "lightgray"}}>종류</td>
                            <td style={{width: "55%",backgroundColor: "lightgray"}}>값</td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                추천인 지급 낙첨금%<br></br>
                                (본인 낙첨시 추천인으로 설정한 회원에게 지급할 낙첨금)
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val35 != null && detailInfo.val35 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={detailInfo.val35} onChange={val35ChangeHandler}
                                />&nbsp;%<br></br>
                                * -1이면 레벨별 설정을 따름<br></br>
                                <font color={"red"}>* 상대 회원에게 설정된 지인 낙첨금보다 우선됨</font>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                본인 첫충에 대해 추천인에게 지급할 %
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val36 != null && detailInfo.val36 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={detailInfo.val36} onChange={val36ChangeHandler}
                                />&nbsp;%<br></br>
                                * 추천인의 지인 첫충%보다 우선함<br></br>
                                * -1이면 추천인의 지인 첫충% 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                본인 매충에 대해 추천인에게 지급할 %
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text" style={{width: "80px", backgroundColor:(detailInfo.val37 != null && detailInfo.val37 > 0 ? "lightskyblue" : "white")}}
                                       placeholder={detailInfo.val37} onChange={val37ChangeHandler}
                                />&nbsp;%<br></br>
                                * 추천인의 지인 매충%보다 우선함<br></br>
                                * -1이면 추천인의 지인 매충% 설정을 따름
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}><br></br></td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"left"}>
                                <b>본인이 추천인으로 설정한 이에 대한 설정</b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "lightgray"}}>종류</td>
                            <td style={{width: "55%",backgroundColor: "lightgray"}}>값</td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                게시판 차단
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <select
                                    className="form-select sm-select"
                                    defaultValue={'00'}
                                    style={{
                                        width: "250px"
                                    }}
                                    onChange={boardBlockCdHandler} value={boardBlockCd ? boardBlockCd : detailInfo.board_block_cd}
                                >
                                    <option value={'00'}>모두 허용</option>
                                    <option value={'01'}>게시판 차단/ 단, 고객센터만 허용</option>
                                    <option value={'02'}>모두 게시판(고객센터 포함) 차단</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                고위험IP접속 알림
                            </td>
                            <td style={{width: "55%",backgroundColor: "white"}} align={"left"}>
                                <select
                                    className="form-select sm-select"
                                    defaultValue={'00'}
                                    style={{
                                        width: "100px",
                                        backgroundColor:"lightskyblue"
                                    }}
                                    onChange={dangerIpCdHandler} value={dangerIpCd ? dangerIpCd : detailInfo.danger_ip_cd}
                                >
                                    <option value={'00'}>알림</option>
                                    <option value={'01'}>알림 끄기</option>
                                </select>
                            </td>
                        </tr>

                        </thead>
                    </table>
                </div>
            </div>
        </div>

    )
}
export default LimitETCContainer;