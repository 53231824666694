import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    gridList : [],
    gridCount : 0,
    input:{}
}

export const statsSlice = createSlice({
    name: 'IpBlockListReducer',
    initialState,
    reducers: {
        getIpBlockListReducer(state, action){
            console.log("#reducers getIpBlockListReducer start#")
        },
        setIpBlockListReducer(state, action) {
            console.log("#reducers setIpBlockListReducer start#",action.payload.gridList);
            state.gridCount = action.payload.gridCount;
            state.gridList = action.payload.gridList;
        },
        failedGetIpBlockListReducer(state, action) {},
        setBlockIp(state, action) {
            const _idx = action.payload.idx;
            state.gridList[_idx].blockIp =action.payload.val;
            state.gridList[_idx].changeYn = 'Y';
        },
        setEtc(state, action) {
            const _idx = action.payload.idx;
            state.gridList[_idx].etc =action.payload.val;
            state.gridList[_idx].changeYn = 'Y';
        },
        setChk(state,action){
            const _idx = action.payload.idx;
            const _val = action.payload.val;
            console.log("##",_idx,"##",_val)
            if(_val){
                state.gridList[_idx]._chkYn = 'Y';
            }else{
                state.gridList[_idx]._chkYn = 'N';
            }

        },
        setChkAll(state,action){
            const _val = action.payload.val;
            console.log("##",_val,"##")
            let _yn;
            if(_val){
                _yn = 'Y'
            }else{
                _yn = 'N'
            }
            for(let i=0;i<state.gridList.length;i++){
                state.gridList[i]._chkYn = _yn;
            }
        }
    }
});

export const {
    getIpBlockListReducer,
    setIpBlockListReducer,
    failedGetIpBlockListReducer,
    setBlockIp,
    setEtc,
    setChk,
    setChkAll
} = statsSlice.actions;

export default statsSlice.reducer;