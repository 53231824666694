import React from 'react';
import AttendanceSettingContainer from "../../containers/setting/AttendanceSettingContainer";


const attendanceSetting = () => {
    return (
        <div>
            <AttendanceSettingContainer />
        </div>
    );
};

export default attendanceSetting;