import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import {getOrgListReducer, setOrgListOpenYn, failedGetOrgListReducer} from "../../redux/reducers/partner/OrgListReducer";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
import {useDispatch, useSelector} from "react-redux";
import {insertAdminLog} from "../../lib/api";
import {useLocation, useNavigate} from "react-router-dom";
import {NumericFormat} from "react-number-format";
import MemberInformationSimpleModal from "../../components/modal/user/simple/MemberInformationSimpleModal";

const CompanyList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.OrgListReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [isOpen, setIsOpen] = useState(false);
    const [openId, setOpenId] = useState(null);
    const [xPos, setXPos] = useState(0.0);

    const getList = () => {
        if(initialState.gridList !== null ) {
            dispatch(getOrgListReducer({}))
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "루트총판목록"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }

    useEffect(getList, [dispatch])

    const reload = () => {
        if(initialState.gridList !== null ) {
            dispatch(getOrgListReducer({}))
        }
    }

    const popupClose = ()=>{
        reload()
    }

    const linklevel1AddPage = (e) => {
        window.open(`/partner/CompanySubAddModal`,"_blank", "width=1400,height=700,left=200,top=200")
        window.popupClose = popupClose;
    }

    const modifyOrg = (level, org) => {
        if( level === 1 )
        {
            window.open(`/partner/defaultInfo1/${org}`,"_blank", "width=1400,height=700,left=200,top=200")
        }
        else if( level === 2)
        {
            window.open(`/partner/defaultInfo2/${org}`,"_blank", "width=1400,height=700,left=200,top=200")
        }
        else if( level === 3)
        {
            window.open(`/partner/defaultInfo3/${org}`,"_blank", "width=1400,height=700,left=200,top=200")
        }
        window.popupClose = popupClose;
    }

    const memberInfoModal = (level, org) => {
        window.open(`/partner/memberInfo/${org}`,"_blank", "width=1400,height=700,left=200,top=200")
        window.popupClose = popupClose;
    }
    const openDomainPopup = (org_admin_id) => {
        window.open(`/partner/partnerDomainAdd/${org_admin_id}`,"_blank", "width=350,height=250,left=500,top=200")
        window.popupClose = popupClose;
    }
    const openLosingPopup = (org_admin_id) => {
        window.open(`/partner/setLosing/${org_admin_id}`,"_blank", "width=1200,height=500,left=200,top=200")
        window.popupClose = popupClose;
    }

    const openOrgModal = (up_org_id, level) => {
        let newWindow ;
        if( level === '01' )
        {
            window.open(`/partner/DistributorAddModal/등록/${up_org_id}`,"_blank", "width=1400,height=700,left=200,top=200")
        }
        else if( level === '02')
        {
            window.open(`/partner/PartnerAddModal/등록/${up_org_id}`,"_blank", "width=1400,height=700,left=200,top=200")
        }
        window.popupClose = popupClose;
    }
    const toggleLevel = (level, id) => {
        dispatch(setOrgListOpenYn({level,id}));
    };

    const handelClickOpen = (id, x) => {
        setOpenId(id)
        setXPos(x)
        setIsOpen(true)
    }
    const renderOrgRow = (level, org) => (
        <tr key={org.org_admin_id}>
            <td>{org.org_level_nm}</td>
            <td style={{ textAlign: 'left' , whiteSpace: 'pre'}}><span style={{ cursor: "pointer" }} onClick={() => toggleLevel(level, org.org_admin_id)}>{level === 1 ? `▶ ` : level === 2 ? `    ▶▶ ` : `       ▶▶▶ `}</span>
                <span style={{ cursor: "pointer" }} onClick={() => modifyOrg(level, org.org_admin_id)}>{org.org_nm}({org.org_admin_id})</span></td>
            <td><span style={{ cursor: "pointer" }} onClick={() => memberInfoModal(level, org.org_admin_id)}>{org.userListCnt}</span></td>
            <td>{org.domain}</td>{/*도메인*/}
            <td>-</td>{/*상태*/}
            <td><NumericFormat value={org.money_amt} displayType={'text'} thousandSeparator={true}/></td>{/*보유금액*/}
            <td>{org.org_adj_gb_cd}</td>{/*정산타입*/}
            <td>{org.ratio_deposit_withdraw}/{org.ratio_sport}/{org.ratio_casino}/{org.ratio_slot}/{org.ratio_mini}</td>{/*루징요율*/}
            <td><NumericFormat value={org.today_losing_amt?org.today_losing_amt:0} displayType={'text'} thousandSeparator={true}/></td>{/*루징금일*/}
            <td><NumericFormat value={org.seven_days_ago_losing_amt?org.seven_days_ago_losing_amt:0} displayType={'text'} thousandSeparator={true}/></td>{/*루징7일*/}
            <td><NumericFormat value={org.this_month_losing_amt?org.this_month_losing_amt:0} displayType={'text'} thousandSeparator={true}/></td>{/*루징이번달*/}
            <td><NumericFormat value={org.last_month_losing_amt?org.last_month_losing_amt:0} displayType={'text'} thousandSeparator={true}/></td>{/*루징지난달*/}
            <td>{org.ratio_sport_one}/{org.ratio_sport_two}/{org.ratio_sport_three}/{org.ratio_sport_more}/{org.ratio_sport_casino}/{org.ratio_sport_slot}/{org.ratio_sport_mini}</td>{/*롤링요율*/}
            <td><NumericFormat value={org.today_rolling_amt?org.today_rolling_amt:0} displayType={'text'} thousandSeparator={true}/></td>{/*롤링금일*/}
            <td><NumericFormat value={org.seven_days_ago_rolling_amt?org.seven_days_ago_rolling_amt:0} displayType={'text'} thousandSeparator={true}/></td>{/*롤링7일*/}
            <td><NumericFormat value={org.this_month_rolling_amt?org.this_month_rolling_amt:0} displayType={'text'} thousandSeparator={true}/></td>{/*롤링이번달*/}
            <td><NumericFormat value={org.last_month_rolling_amt?org.last_month_rolling_amt:0} displayType={'text'} thousandSeparator={true}/></td>{/*롤링지난달*/}
            <td>
                <button style={{ backgroundColor: "blue", border: "none", color: "white" ,fontSize: "8px", height: "23px", width: "fit-content", marginRight: "5px"}}
                        onClick={()=> openDomainPopup(org.org_admin_id)}
                >도메인(+)</button>

                <button style={{ backgroundColor: "blue", border: "none", color: "white" ,fontSize: "8px", height: "23px", width: "fit-content", marginRight: "5px"}}
                        onClick={()=> openLosingPopup(org.org_admin_id)}
                >루징롤링</button>
                {org.org_level_cd !== '03'?
                    <button style={{ backgroundColor: "blue", border: "none", color: "white" ,fontSize: "8px", height: "23px", width: "fit-content", marginRight: "5px"}}
                            onClick={(e)=> {openOrgModal(org.org_admin_id,org.org_level_cd)}}
                    >하위조직추가(+)</button>
                    :null}
            </td>{/*회원관리*/}
            <td>{org.userCnt?org.userCnt.freeUserCnt:0}</td>{/*무료*/}
            <td>{org.userCnt?org.userCnt.paidUserCnt:0}</td>{/*유료*/}
            <td>{org.userCnt?org.userCnt.delUserCnt:0}</td>{/*틸퇴*/}
            <td>{org.userCnt?org.userCnt.stopUserCnt:0}</td>{/*정지*/}
            <td>{org.createdAt}</td>{/*생성일시*/}
            <td>{org.rcnt_actv_dttm}</td>{/*최종로그인일시*/}
            <td><button style={{ backgroundColor: "#e1dede", border: "none", color: "black" ,fontSize: "8px", height: "23px", width: "fit-content", marginRight: "5px"}} onClick={(e)=> {window.open(`/partner/partnerAdjustment/${org.org_admin_id}`,"_blank", "width=700,height=700,left=500,top=200")}}>정산</button></td>{/*정산버튼*/}
        </tr>
    );
    const renderUserRow = (level, user) => (
        <tr key={user.id}>
            <td>유저</td>
            <td colSpan="23" style={{ textAlign: 'left' , whiteSpace: 'pre'}}>
                <span>{level === 1 ? `` : level === 2 ? `    ` : `        `}</span>
                <span>조직 : {user.org_admin_id}</span>  <span style={{ margin: '0 5px' }}></span> {/* 여기에서 한 칸 띄기 */}
                <span style={{cursor: "pointer"}} onClick={(e) => {
                    dispatch(openUserSimpleInfo({
                        isOpen: true,
                        openId: user.id,
                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                    }))
                }}>{user.nickname}({user.id})</span>  <span style={{ margin: '0 5px' }}></span> {/* 여기에서 한 칸 띄기 */}
                머니 : <NumericFormat value={user.game_money_amt} displayType={'text'} thousandSeparator={true}/> <span style={{ margin: '0 5px' }}></span> {/* 여기에서 한 칸 띄기 */}
                최종접속일시 : {user.rcnt_actv_dttm}
            </td>
        </tr>
    );
    return (
        <div className="common">
            <div className="commonU table-bordered text-center ">
                <div className="table table-striped mt-2 scroll-horizontal">
                    <table id='grid' className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th style={{width: "10%"}} rowSpan="2">루트총판</th>
                            <th style={{width: "5%"}} rowSpan="2">회원수</th>
                            <th style={{width: "5%"}} rowSpan="2">보유금액</th>
                            <th style={{width: "25%"}} colSpan="4">루징</th>
                            <th style={{width: "25%"}} colSpan="4">롤링</th>
                            <th style={{width: "*%"}} colSpan="4">상태</th>
                            <th style={{width: "5%"}} rowSpan="2">생성일시</th>
                            <th style={{width: "5%"}} rowSpan="2">최종로그인<br></br>일시</th>
                        </tr>
                        <tr>
                            <th>금일</th>
                            <th>7일</th>
                            <th>이번달</th>
                            <th>저번달</th>
                            <th>금일</th>
                            <th>7일</th>
                            <th>이번달</th>
                            <th>저번달</th>
                            <th>무료</th>
                            <th>유료</th>
                            <th>탈퇴</th>
                            <th>정지</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}><b>합계</b></td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b>{initialState.sumData.sumUserCnt}</b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}><b><NumericFormat value={initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.money_amt} displayType={'text'} thousandSeparator={true}/></b></td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b><NumericFormat value={initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.today_losing_amt} displayType={'text'} thousandSeparator={true}/></b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b><NumericFormat value={initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.seven_days_ago_losing_amt} displayType={'text'} thousandSeparator={true}/></b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b><NumericFormat value={initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.this_month_losing_amt} displayType={'text'} thousandSeparator={true}/></b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b><NumericFormat value={initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.last_month_losing_amt} displayType={'text'} thousandSeparator={true}/></b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b><NumericFormat value={initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.today_rolling_amt} displayType={'text'} thousandSeparator={true}/></b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b><NumericFormat value={initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.seven_days_ago_rolling_amt} displayType={'text'} thousandSeparator={true}/></b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b><NumericFormat value={initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.this_month_rolling_amt?initialState.sumData.sumOrgData.this_month_rolling_amt:0} displayType={'text'} thousandSeparator={true}/></b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b><NumericFormat value={initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.last_month_rolling_amt} displayType={'text'} thousandSeparator={true}/></b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b>{initialState.sumData.sumUserData&&initialState.sumData.sumUserData.freeUserCnt}</b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b>{initialState.sumData.sumUserData&&initialState.sumData.sumUserData.paidUserCnt}</b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b>{initialState.sumData.sumUserData&&initialState.sumData.sumUserData.delUserCnt}</b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>
                                <b>{initialState.sumData.sumUserData&&initialState.sumData.sumUserData.delUserCnt}</b>
                            </td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>{initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.createdAt}</td>
                            <td style={{backgroundColor: "lightcyan"}} align={"center"}>{initialState.sumData.sumOrgData&&initialState.sumData.sumOrgData.rcnt_actv_dttm}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table width={"100%"}>
                        <tbody>
                        <tr>
                            <td align={"right"}>onClick={linklevel1AddPage}
                                <button  style={{ backgroundColor: "#c4345f", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}} >부본사 신규등록</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {isOpen && (<MemberInformationSimpleModal setIsOpen={setIsOpen} isOpen={isOpen} userId={openId} xPos={xPos} />)}
                    <table id='grid2' className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th>레벨</th>
                            <th style={{ fontSize: '9px' , width: '200px'}}>루트총판</th>
                            <th>회원수</th>
                            <th>도메인</th>
                            <th>상태</th>
                            <th>보유금액</th>
                            <th>정산타입</th>
                            <th style={{ fontSize: '9px' , width: '100px'}}>루징요율</th>
                            <th>루징(금일)</th>
                            <th>루징(7일)</th>
                            <th>루징(이번달)</th>
                            <th>루징(지난달)</th>
                            <th style={{ fontSize: '9px' , width: '100px'}}>롤링요율(%)</th>
                            <th>롤링(금일)</th>
                            <th>롤링(7일)</th>
                            <th>롤링(이번달)</th>
                            <th>롤링(지난달)</th>
                            <th>회원관리</th>
                            <th>무료</th>
                            <th>유료</th>
                            <th>탈퇴</th>
                            <th>정지</th>
                            <th>생성일시</th>
                            <th><span>최종로그인<br></br>일시</span></th>
                            <th>정산</th>
                        </tr>
                        </thead>
                        {initialState.gridList && initialState.gridList.length > 0 ? (
                            initialState.gridList.map((List, index1) => (
                                <tbody key={List.org_admin_id}>
                                {/* 1단계 orgList1*/}
                                {renderOrgRow(1, { ...List, index: index1 })}

                                {/* 2단계: orgList2 */}
                                {List.orgList2OpenYn && List.orgList2 && List.orgList2.length > 0 && List.orgList2.map((List2, index2) => (
                                    <React.Fragment key={List2.org_admin_id}>
                                        {renderOrgRow(2, { ...List2, index: index1 })}
                                        {/* 3단계: orgList3 */}
                                        {List2.orgList3OpenYn && List2.orgList3 && List2.orgList3.length > 0 && List2.orgList3.map((List3, index3) => (
                                            <React.Fragment key={List3.org_admin_id}>
                                                {renderOrgRow(3, { ...List3, index: index3 })}

                                                {/*{List3.userList3penYn && List3.userList3 && List3.userList3.length > 0 && List3.userList3.map((user3, userIndex3) => (*/}
                                                {/*    renderUserRow(3, { ...user3, index: userIndex3 })*/}
                                                {/* ))}*/}
                                            </React.Fragment>
                                        ))}

                                        {/* 2단계 userList2*/}
                                        {/*{List2.orgList3OpenYn && List2.userList2 && List2.userList2.length > 0 && List2.userList2.map((user2, userIndex2) => (*/}
                                        {/*    renderUserRow(2, { ...user2, index: userIndex2 })*/}
                                        {/*))}*/}
                                    </React.Fragment>
                                ))}

                                {/* 1단계 userList1*/}
                                {/*{List.orgList2OpenYn && List.userList1 && List.userList1.length > 0 && List.userList1.map((user1, userIndex1) => (*/}
                                {/*    renderUserRow(1, { ...user1, index: userIndex1 })*/}
                                {/*))}*/}

                                </tbody>
                            ))
                        ) : (
                            <tbody>
                            <tr>
                                <td colSpan="24">조회된 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
}
export default CompanyList;