import React from 'react';
import CouponListContainer from "../../containers/deposit/CouponListContainer";


const CouponList = () => {
    return (
        <div>
            <CouponListContainer />
        </div>
    );
};

export default CouponList;