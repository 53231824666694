import React from "react";
import OverlapContainer from "../../containers/monitoring/OverlapContainer";
const Overlap = () => {
    return (
        <div className="sm-font">
            <font color={"blue"} size="3">중복 아이피</font>&nbsp;
            <OverlapContainer />
        </div>

    )
}
export default Overlap;