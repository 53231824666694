import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    gridList : [],
    sumData : {}
}

export const statsSlice = createSlice({
    name: 'OrgListReducer',
    initialState,
    reducers: {
        getOrgListReducer(state, action){
            console.log("#reducers getMessageReducer start#")
        },
        setOrgListReducer(state, action) {
            console.log("#reducers setOrgListReducer start#",action.payload.gridList);
            state.gridList = action.payload.gridList;
            state.sumData = action.payload.sumData;
        },
        failedGetOrgListReducer(state, action) {

        },
        setOrgListOpenYn(state, action) {
            console.log('오픈여부',action.payload);
            if(action.payload.level === 1){
                state.gridList = state.gridList.map((r) => {
                    if (r.org_admin_id === action.payload.id) {
                        if(r.orgList2OpenYn && r.orgList2OpenYn === true){
                            r.orgList2OpenYn = false;
                        }else{
                            r.orgList2OpenYn = true;
                        }
                    }
                    return r;
                });
            }
            if(action.payload.level === 2){
                state.gridList = state.gridList.map((r) => {
                    r.orgList2.map((r2) => {
                        if (r2.org_admin_id === action.payload.id) {
                            if(r2.orgList3OpenYn && r2.orgList3OpenYn === true){
                                r2.orgList3OpenYn = false;
                            }else{
                                r2.orgList3OpenYn = true;
                            }
                        }
                        return r2;
                    })
                    return r;
                });
            }
            if(action.payload.level === 3){
                state.gridList = state.gridList.map((r) => {
                    r.orgList2.map((r2) => {
                        r2.orgList3.map((r3) => {
                            if (r3.org_admin_id === action.payload.id) {
                                if(r3.userList3penYn && r3.userList3penYn === true){
                                    r3.userList3penYn = false;
                                }else{
                                    r3.userList3penYn = true;
                                }
                            }
                            return r3;
                        })
                        return r2;
                    })
                    return r;
                });
            }
        },
        setUserListOpenYn(state, action) {
            state.gridList = action.payload.gridList;
        },
    }
});

export const {
    setIsOpen,
    setOrgListOpenYn,
    getOrgListReducer,
    setOrgListReducer,
    failedGetOrgListReducer,
    setSelectOrgId,
    setRegId,
    setUpdId,
} = statsSlice.actions;

export default statsSlice.reducer;