import React, {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {MultiSelect} from "react-multi-select-component";


import {
    resetState,
    setAccount,
    setAccountName,
    setBank,
    setBfOrgGbCd,
    setCmmnCd,
    setCmmnCd0036,
    setComboOrgList,
    setComboOrgList2,
    setDomainDisYn,
    setDomainRegYn,
    setDtl,
    setEmail,
    setGb,
    setId,
    setIdCheckYn,
    setIdDupYn,
    setLosingAmt,
    setMbnoCenter,
    setMbnoEnd,
    setMbnoStart,
    setMbphCenter,
    setMbphEnd,
    setMbphStart,
    setMemo,
    setMoneyAmt,
    setMoveOrgId,
    setNm,
    setOrgAdjGbCd,
    setOrgGbCd,
    setOrgLevelCd,
    setOrgNm,
    setOrgNmCheckYn,
    setOrgNmDupYn,
    setPw1,
    setPw2,
    setRegCd,
    setRegId,
    setRollingAmt,
    setUpChangeOrgId,
    setUpdId,
    setUpOrgId,
    setUserColorList,
    setUserColorNo,
    setWithdrawPw
} from "../../../../redux/reducers/partner/DistributorAddReducer";
import {insertAdminLog} from "../../../../lib/api";
import {NumericFormat} from "react-number-format";

const DefaultInfo1 = (props) => {

    const options = [
        { label: "ht-caal.com(HOTEL)", value: "01" },
        { label: "ht-sln.com(HOTEL)", value: "02" },
        { label: "ht-edkd.com(HOTEL)", value: "03" },
    ];
    const [selected, setSelected] = useState([]);
    const param = useParams()


    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.DistributorAddReducer)
    const navigate = useNavigate();
    const userInfoId = useSelector((state) => state.userInfo.id)

    const location = useLocation();
    const { state } = location;
    // state 객체에서 name 값을 추출
    const titleName = state && state.name;
    const gb = "수정";
    const id = param.userId;
    const up_org_id = state && state.up_org_id;

    const onLoad = () => {
        dispatch(resetState());
        dispatch(setOrgLevelCd('01'))//01:부본사
        dispatch(setRegId(userInfoId))
        dispatch(setUpdId(userInfoId))

        //부본사리스트에서 추가버튼클릭할때 세팅
        if(up_org_id !==''){
            dispatch(setUpOrgId(up_org_id))
        }

        //1.상위조직아이디조회
        axios.post('/admin/getComboOrgList', {
            org_level_cd : '01',
        }).then((res) => {
            if(res.data.List !== null) {
                dispatch(setComboOrgList(res.data.List))
            }
            else {
                alert('상위조직아이디조회실패')
            }
        })

        //2.수정일경우 자신을 제외한 총판아이디조회. 가맹점전환을 위한 상위총판선택을 위해서
        if(gb === "수정") {
            axios.post('/admin/getComboOrgList', {
                org_level_cd: '02',
                id : id
            }).then((res) => {
                if (res.data.List !== null) {
                    dispatch(setComboOrgList2(res.data.List))
                } else {
                    alert('상위조직아이디조회실패')
                }
            })
        }
        //3.공통코드조회
        axios.post('/admin/cmmnCdList', {
            cmmnCdId : '0012',
            inCd: ["00", '02'], //00:관리자 , 02:총판
        }).then((res) => {
            if(res.data.cmmnCdList !== null) {
                dispatch(setCmmnCd(res.data.cmmnCdList))
            }
            else {
                alert('공통코드조회실패')
            }
        })

        //3-1.공통코드조회 (조직정산구분코드)
        axios.post('/admin/cmmnCdList', {
            cmmnCdId : '0036',
        }).then((res) => {
            if(res.data.cmmnCdList !== null) {
                dispatch(setCmmnCd0036(res.data.cmmnCdList))
            }
            else {
                alert('공통코드조회실패')
            }
        })

        //4.유저색상조회
        axios.post('/admin/userColorList').
        then((res) => {
            if(res.data.userColorList !== null) {
                dispatch(setUserColorList(res.data.userColorList))
            }
            else {
                alert('공통코드조회실패')
            }
        })

        //5.상세조회
        if(gb === "수정"){
            axios.post('/admin/partner/getOrgDtl',{id : id}).
            then((res) => {
                if(res.data.Dtl !== null) {
                    dispatch(setDtl(res.data.Dtl))
                    dispatch(setGb(gb))

                    //전환시에 조직구분코드가 관리자이면 전환을할수없으므로 원본조직구분코드를 담아둔다.
                    dispatch(setBfOrgGbCd(res.data.Dtl.org_gb_cd))
                }
                else {
                    alert('성세조회실패')
                }
            })
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "총판등록"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(onLoad, [titleName])

    const onSubmitHandler = (e) => {
        //navigate('/partner/Distributor_list');
        popClose()
    }

    const dupIdCheckHandle = (e) => {
        axios.post('/admin/partner/getOrgDupCheck', {
            checkGb : 'id',
            id : initialState.input.id
        }).then((res) => {
            if (res.data.dupYn) {
                alert('중복된 아이디가 있습니다. 다른 아이디를 입력해주세요')
                dispatch(setIdDupYn(true));
                dispatch(setIdCheckYn(false));

            } else {
                alert('중복된 아이디가 없습니다. 사용하셔도 좋습니다.')
                dispatch(setIdDupYn(false));
                dispatch(setIdCheckYn(true));
            }
        })
    }

    const dupCheckOrgNmHandle = (e) => {

        alert("initialState.input.org_nm :: "+initialState.input.org_nm);

        axios.post('/admin/partner/getOrgDupCheck', {
            checkGb : 'orgNm',
            org_nm : initialState.input.org_nm
        }).then((res) => {
            if (res.data.dupYn) {
                alert('중복된 총판명이 있습니다. 다른 이름을 입력해주세요')
                dispatch(setOrgNmDupYn(true));
                dispatch(setOrgNmCheckYn(false));

            } else {
                alert('중복된 총판명 없습니다. 사용하셔도 좋습니다.')
                dispatch(setOrgNmDupYn(false));
                dispatch(setOrgNmCheckYn(true));
            }
        })
    }

    const onIdHandler = (e) => {
        dispatch(setId(e.target.value))
        dispatch(setIdCheckYn(false))
    }

    const onNmHandler = (e) => {
        dispatch(setNm(e.target.value))
    }

    const onPw1Handler = (e) => {
        const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/;
        if(regex.test(e.target.value)){
            dispatch(setPw1(e.target.value))
        }
    }

    const onPw2Handler = (e) => {
        const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/;
        if(regex.test(e.target.value)) {
            dispatch(setPw2(e.target.value))
        }
    }

    const onOrgNmHandler = (e) => {
        dispatch(setOrgNm(e.target.value))
        dispatch(setOrgNmCheckYn(false))
    }

    const onOrgGbCdHandler = (e) => {
        dispatch(setOrgGbCd(e.target.value))
    }

    //전화번호앞자리
    const onMbnostartHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbnoStart(value))
        }
    }

    //전화번호가운데자리
    const onMbnoCenterHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbnoCenter(value))
        }
    }

    //전화번호끝자리
    const onMbnoEndHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbnoEnd(value))
        }
    }

    //이동전화번호앞자리
    const onMbphStartHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbphStart(value))
        }
    }

    //이동전화번호중간자리
    const onMbphCenterHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbphCenter(value))
        }
    }

    //이동전화번호끝자리
    const onMbphEndHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbphEnd(value))
        }
    }

    //이메일
    const onEmailHandler = (e) => {
        dispatch(setEmail(e.target.value))
    }

    //메모
    const onMemoHandler = (e) => {
        dispatch(setMemo(e.target.value))
    }

    //회원색상번호
    const onUserColorNoHandler = (e) => {
        dispatch(setUserColorNo(e.target.value))

    }

    //상위조직아이디 변경시
    const upOrgChangeHandler = (e) => {
        dispatch(setUpOrgId(e.target.value))
    }

    //회훤이동 조직아이디 입력값 변경시
    const onChageOrgIdHandler = (e) => {
        dispatch(setMoveOrgId(e.target.value))
    }

    //조직 전환할때 상위조직아이디 변경시
    const upChangeOrgIdHandler = (e) => {
        dispatch(setUpChangeOrgId(e.target.value))
    }

    const orgAdjGbCdChangeHandler = (e) => {
        dispatch(setOrgAdjGbCd(e.target.value))
    }

    //등록 혹은 수정버튼 클릭시
    const handleSave =() =>{

        //상위부본사선택
        if(gb!=="수정" && initialState.input.up_org_id ===''){
            alert("상위부본사를 선택하세요.");
            return false;
        }

        //아이디필수입력
        // if(gb!=="수정" && initialState.input.id === ''){
        //     alert("아이디를 입력하세요");
        //     return false;
        // }
        //아이디중복체크여부
        // if(gb!=="수정" && !initialState.idCheckYn){
        //     alert("아이디 중복 확인을 해주세요");
        //     return false;
        // }
        //아이디중복며부
        // if(gb !== "수정" && initialState.idDupYn){
        //     alert('중복된 아이디가 있습니다. 다른 아이디를 입력해주세요')
        //     return false;
        // }
        // //관리자이름필수입력
        // if(initialState.input.nm === ''){
        //     alert("관리자 이름을 입력하세요");
        //     return false;
        // }
        //비밀번호체크
        // if(initialState.input.pw !== initialState.pwcheck){
        //     alert('비밀번호가 다릅니다. 같은 비밀번호를 입력하세요')
        //     return false;
        // }

        //비밀번호필수입력
        if(initialState.input.pw === ''){
            alert("비밀번호를 입력하세요");
            return false;
        }
        //환전비밀번호필수입력
        if(initialState.input.withdraw_pw === ''){
            alert("환전 비밀번호를 입력하세요");
            return false;
        }
        // //조직명필수입력
        // if(initialState.input.org_nm === ''){
        //     alert("총판명을 입력하세요")
        //     return false;
        // }
        // //조직명중복체크여부
        // if(!initialState.orgNmCheckYn){
        //     alert("총판명 중복 확인을 해주세요");
        //     return false;
        // }
        // //조직명중복여부
        // if(initialState.orgNmDupYn){
        //     alert('중복된 총판명 이 있습니다. 다른 총판 명을 입력해주세요')
        //     return false;
        // }

        axios.post('/admin/partner/saveOrg', initialState.input
        ).then((res) => {
            if (res.data.isInserted) {
                alert('저장했습니다.')
                onSubmitHandler()
            } else {
                alert('저장실패했습니다.')
            }
        })
    }

    //삭제버튼 클릭
    const orgDelete=()=>{
        if(window.confirm("정말 삭제하시겠습니까?")) {
            axios.post('/admin/partner/orgDelete', {id: initialState.input.id}
            ).then((res) => {
                if (res.data.IsDeleted) {
                    alert('삭제했습니다.')
                    onSubmitHandler()
                } else {
                    alert('삭제실패했습니다.')
                }
            })
        }
    }

    //회원이동버튼 클릭시
    const moveOrgMember=()=>{
        if(initialState.moveOrgId === ""){
            alert("회원을 넘겨 받을 본사 혹은 총판 혹은 가맹점 아이디를 입력하세요");
            return false;
        }
        if(window.confirm("정말 현재 총판 소속의 회원들을 대상 총판 혹은 가맹점으로 이동하시겠습니까?")){
            axios.post('/admin/partner/moveOrgMember', {
                    id : initialState.input.id,
                    moveOrgId : initialState.moveOrgId
                }
            ).then((res) => {
                if(res.data.IsExistYn){
                    if (res.data.IsChanged) {
                        alert('이동했습니다.')
                        onSubmitHandler()
                    } else {
                        alert('이동실패했습니다.')
                    }
                }else{
                    alert('그런 본사 혹은 총판 혹은 가맹 아이디가 존재하지 않습니다.')
                    return false;
                }
            })
        }
    }

    //가맹으로전환 버튼 클릭시
    const changeOrg = (e) =>{
        if(initialState.upChangeOrgId=== ""){
            alert("전환시 소속될 상위 총판을 선택하세요.");
            return false;
        }
        if(initialState.bfOrgGbCd === "00"){
            alert("관리자 권한 총판은 전환할 수 없습니다");
            return false;
        }

        if(window.confirm("정말 이 총판을 가맹으로 전환하겠습니까?")){
            axios.post('/admin/partner/changeOrg', {
                    up_org_id : initialState.upChangeOrgId,
                    org_level_cd : '03',//가맹점
                    id : initialState.input.id,
                }
            ).then((res) => {
                if (res.data.IsUpdated) {
                    alert('전환했습니다.')
                    onSubmitHandler()
                } else {
                    alert('전환실패했습니다.')
                }
            })
        }
    }

    //환전비밀번호
    const onWithdrawPwHandler = (e) => {
        const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/;
        if(regex.test(e.target.value)){
            dispatch(setWithdrawPw(e.target.value))
        }
    }
    //보유머니
    const onMoneyHandler = (e) => {
        dispatch(setMoneyAmt(e.target.value))
    }
    //루징금
    const onLosingHandler = (e) => {
        dispatch(setLosingAmt(e.target.value))
    }
    //롤링금
    const onRollingHandler = (e) => {
        dispatch(setRollingAmt(e.target.value))
    }
    //은행명
    const onBankHandler = (e) => {
        dispatch(setBank(e.target.value))
    }
    //계좌
    const onAccountHandler = (e) => {
        dispatch(setAccount(e.target.value))
    }
    //예금주
    const onAccountNameHandler = (e) => {
        dispatch(setAccountName(e.target.value))
    }
    //코드가입여부
    const onDomainRegYnHandler = (e) => {
        dispatch(setDomainRegYn(e.target.value))
    }
    //가입코드현시여부
    const onDomainDisYnHandler = (e) => {
        dispatch(setDomainDisYn(e.target.value))
    }
    //가입코드
    const onRegCdHandler = (e) => {
        dispatch(setRegCd(e.target.value))
    }
    const popClose =() => {
        if (window.opener && typeof window.opener.popupClose === 'function') {
            window.opener.popupClose();
        }
        window.close();
    }
    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <button style={{ backgroundColor: "white",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                >기본정보</button>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/withdrawInfo/"+param.userId;
                        }}
                >출금</button>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/losingInfo/"+param.userId;
                        }}
                >루징내역</button>
                <button style={{     backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/rollingInfo/"+param.userId;
                        }}
                >롤링내역</button>
                <button style={{     backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/memberInfo/"+param.userId;
                        }}
                >회원내역</button>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <b><font size={"2"} color={"black"}>상태 : </font>&nbsp; <font color={"green"} size={"2"}>정상</font></b>&nbsp;&nbsp;&nbsp;
                    <font color={"black"} size={"2"}>/</font>&nbsp;&nbsp;&nbsp;
                    <b><font size={"2"} color={"red"}>총 출금금액 </font>&nbsp; <font color={"red"}> : 100,000,000</font></b>
                </div>
                <div className="" style={{
                    display: "flex"
                }}>
                    <table width={"500px"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "150px"
                                }} type="submit" style={{backgroundColor :"darkgreen"}} className="btn btn-primary" onClick={()=>handleSave()}>부본사수정</button>
                            </td>
                            <td align={"right"} width={"15px"}>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>

                <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                    <tbody>
                    <tr>
                        <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>상위<br/>본사<font color='red'>*</font></td>
                        <td width={"1%"}></td>
                        <td width={"*%"} colSpan="3">
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>

                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>보유머니</td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"} align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <font color="black"><NumericFormat className="ms-1" value={initialState.input.money_amt} displayType={'text'} thousandSeparator={true}/>원</font>
                                &nbsp;&nbsp;
                                <input className="form-check-input sm-input" type="text" style={{width: "100px"}} />&nbsp;
                                <button  style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} >지급</button>
                                <button  style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} >회수</button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>루징금</td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"} align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <font color="black"><NumericFormat className="ms-1" value={initialState.input.losing_amt} displayType={'text'} thousandSeparator={true}/>원</font>
                                &nbsp;&nbsp;
                                <input className="form-check-input sm-input" type="text" style={{width: "100px"}} />&nbsp;
                                <button  style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} >지급</button>
                                <button  style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} >회수</button>
                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>롤링금</td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"} align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <font color="black"><NumericFormat className="ms-1" value={initialState.input.rolling_amt} displayType={'text'} thousandSeparator={true}/>원</font>
                                &nbsp;&nbsp;
                                <input className="form-check-input sm-input" type="text" style={{width: "100px"}} />&nbsp;
                                <button  style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} >지급</button>
                                <button  style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} >회수</button>
                                &nbsp;자동승인
                                &nbsp;
                                <select className="form-select sm-select" style={{width: "80px"}}>
                                    <option value="">사용 안함</option>
                                    <option value="">사용</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>아이디 <font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input {...(gb === "수정" ? {disabled: true} : {})} className="form-check-input sm-input" type="text" onChange={onIdHandler} value={initialState.input.id}/>&nbsp;
                                {
                                    gb === "수정" ? <></> :
                                        <button  style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dupIdCheckHandle}>아이디 중복 확인</button>
                                }
                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>총판명 <font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input className="form-check-input sm-input" type="text" onChange={onOrgNmHandler} value={initialState.input.org_nm}/>&nbsp;
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"} >비밀번호 <font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input maxLength={25} className="form-check-input sm-input" type="text" onChange={onPw1Handler} value={initialState.input.pw}/>&nbsp;
                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>환전비번 <font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"} align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input maxLength={25} className="form-check-input sm-input" type="text" onChange={onWithdrawPwHandler} value={initialState.input.withdraw_pw}/>&nbsp;
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>연락처</td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input style={{width: "50px"}} value={initialState.input.mbno_start} maxLength={4} className="form-check-input sm-input" type="text" onChange={onMbnostartHandler}/> -
                                <input style={{width: "50px"}} value={initialState.input.mbno_center} maxLength={4} className="form-check-input sm-input" type="text" onChange={onMbnoCenterHandler}/> -
                                <input style={{width: "50px"}} value={initialState.input.mbno_end} maxLength={4} className="form-check-input sm-input" type="text" onChange={onMbnoEndHandler}/>
                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>계좌정보</td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"} align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                예금주&nbsp;
                                <input style={{width: "70px"}} className="form-check-input sm-input" type="text" onChange={onAccountNameHandler} value={initialState.input.account_name}/>
                                &nbsp;&nbsp;
                                은행명&nbsp;
                                <input style={{width: "80px"}} className="form-check-input sm-input" type="text" onChange={onBankHandler} value={initialState.input.bank}/>
                                &nbsp;&nbsp;
                                계좌&nbsp;
                                <input style={{width: "120px"}} className="form-check-input sm-input" type="text" onChange={onAccountHandler} value={initialState.input.account}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>가입코드<font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input className="form-check-input sm-input" type="text" onChange={onRegCdHandler} value={initialState.input.reg_cd}/>
                                &nbsp;
                                <button  style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dupIdCheckHandle}>중복확인</button>
                                &nbsp;
                                <select value={initialState.input.domain_reg_yn} className="form-select sm-select" style={{width: "95px"}} onChange={onDomainRegYnHandler}>
                                    <option value="N">코드가입불가</option>
                                    <option value="Y">코드가입가능</option>
                                </select>
                                &nbsp;
                                <select value={initialState.input.domain_dis_yn} className="form-select sm-select" style={{width: "105px"}} onChange={onDomainDisYnHandler}>
                                    <option value="N">가입코드 비현시</option>
                                    <option value="Y">가입코드 현시</option>
                                </select>
                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>도메인</td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"20%"} align={"left"}>
                            <MultiSelect
                                options={options}
                                value={selected}
                                onChange={setSelected}
                                labelledBy={"선택하세요"}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>조직정산구분<font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="6" width={"34%"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <select
                                    className="form-select sm-select"
                                    defaultValue={'01'}
                                    style={{
                                        width: "100px"
                                    }}
                                    onChange={orgAdjGbCdChangeHandler} value={initialState.input.org_adj_gb_cd}
                                >
                                    {initialState.cmmnCdList0036?.map((item, index) => (
                                        <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                    ))}
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>메모</td>
                        <td width={"1%"}></td>
                        <td  colSpan="8">
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input value={initialState.input.memo} className="form-check-input sm-input" type="text" style={{ width: "1000px" }} onChange={onMemoHandler}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>소속회원 색상값</td>
                        <td width={"1%"}></td>
                        <td width={"*%"} colSpan="8">
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <table>
                                    <tbody>
                                    <tr>
                                        {initialState.userColorList.map(item => (
                                            <React.Fragment key={item.no}>
                                                <td style={{backgroundColor:item.back_ground_color}} align={"center"} height={"20px"} >
                                                    <label
                                                        htmlFor={`flexColor${item.no}`}
                                                        style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                                    ><font color={item.font_color}>{item.no}</font>
                                                    </label>
                                                    <input
                                                        type="radio"
                                                        name="flexColor"
                                                        id={`flexColor${item.no}`}
                                                        value={item.no}
                                                        style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                                        onChange={onUserColorNoHandler}
                                                        checked={parseInt(item.no) === parseInt(initialState.input.user_color_no)}
                                                    />
                                                </td>
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>소속회원 이동</td>
                        <td width={"1%"}></td>
                        <td width={"*%"} colSpan="8" align={"left"}>
                            <table>
                                <tbody>
                                <tr>
                                    <td align={"left"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={onChageOrgIdHandler} className="form-check-input sm-input" type="text"  style={{
                                                width: "400px"
                                            }}/>
                                            &nbsp;
                                            <button onClick={moveOrgMember} style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>회원 이동</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <font color={"red"}>* 이 부본사에 속한 회원들을, 입력하신 총판 혹은 가맹점 아이디로 이동하게 됩니다. 신중하게 사용하시기 바랍니다.</font>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

    )
}
export default DefaultInfo1;