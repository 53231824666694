import {call, put} from "redux-saga/effects";
import {failedGetOrgListReducer, setOrgListReducer} from "../../reducers/partner/OrgListReducer";
import {fetchOrgList} from "../../../lib/api";

export function* getOrgListSaga(action) {
    try{
        console.log("##getOrgListSaga input#",action.payload.input)
        const res = yield call(fetchOrgList,
            action.payload.input,
        );
        console.log("##getOrgListSaga output#",res.data)
        yield put(setOrgListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetOrgListReducer(error))
    }
}