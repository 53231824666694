import React, {useState} from "react";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {
    setBetInputs,
    setCheckedInputs,
    setUpdateData,
    setCorrectScores,
    getWaitFixtureDetail
} from "../../../redux/reducers/game/manage/parsingWait";
import axios from "axios";
import common from "../../../util/common";
import {NumericFormat} from "react-number-format";
import BetSettlement from "../../../constant/BetSettlement";
import BetStatus from "../../../constant/BetStatus";
import FixtureStatus from "../../../constant/FixtureStatus";

const WaitList = ({state}) => {
    const dispatch = useDispatch()

    const changeHandler = (checked, id) => {
        const fixtures = state.fixtures.filter((el) => el.id === id);
        if(moment(fixtures[0].start_date).isAfter(moment().format("YYYY-MM-DD HH:mm"))) {
            if(checked) {
                dispatch(setCheckedInputs([...state.checkedInputs, id]));
            }
            else {
                dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== id)));
            }
        }
        else {
            alert("이미시작된 경기 입니다.");
        }
    };

    const allChangeHandler = (e) => {
        const check_ids = []
        let hasStartMatch = false;
        for(let index = 0; index < state.fixtures.length; index++) {
            if(moment(state.fixtures[index].start_date).isAfter(moment().format("YYYY-MM-DD HH:mm"))) {
                check_ids.push(state.fixtures[index].id)
            }
            else {
                hasStartMatch = true;
            }
        }
        if(hasStartMatch) {
            alert("이미시작된 경기를 제외하고 체크합니다.");
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []));
    }
    const showCorrectScore = (e, fixtureId) => {

        if(state.correctScores.includes(fixtureId)) {
            const arr = state.correctScores.filter((el) => el !== fixtureId)
            dispatch(setCorrectScores(arr))
            e.target.innerText = "정확한 스코어 펼치기"
        }
        else {
            const arr = [fixtureId, ...state.correctScores]
            dispatch(setCorrectScores(arr))
            e.target.innerText = "정확한 스코어 접기"
        }

    }

    const changeBet = (e, id, type) => {
        if(e.target.value !== e.target.defaultValue) {
            e.target.style.color = "green"
        }
        else {
            e.target.style.color = "#555"
        }
        const value = parseFloat(e.target.value)
        const fixtures = state.fixtures.filter((el) => el.id === id);
        if(moment(fixtures[0].start_date).isAfter(moment().format("YYYY-MM-DD HH:mm"))) {
            const data = {
                bet1: fixtures[0].bet1,
                bet2: fixtures[0].bet2,
                bet3: fixtures[0].bet3,
            }

            const betInputs2 = state.betInputs.filter((el) => el.id !== id);

            const betInputs3 = state.betInputs.filter((el) => el.id === id && el.is_edited === 1);
            let inputs
            if(betInputs3.length > 0) {
                if(type === 1) {
                    inputs = {id: id, bet1: value, bet2: betInputs3[0].bet2, bet3: betInputs3[0].bet3}
                }
                else if(type === 2) {
                    inputs = {id: id, bet2: value, bet1: betInputs3[0].bet1, bet3: betInputs3[0].bet3}
                }
                else if(type === 3) {
                    inputs = {id: id, bet3: value, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1}
                }
            }
            else {
                if(type === 1) {
                    inputs = {id: id, bet1: value, bet2: data.bet2, bet3: data.bet3}
                }
                else if(type === 2) {
                    inputs = {id: id, bet2: value, bet1: data.bet1, bet3: data.bet3}
                }
                else if(type === 3) {
                    inputs = {id: id, bet3: value, bet2: data.bet2, bet1: data.bet1}
                }
            }
            inputs.is_edited = 1;
            dispatch(setBetInputs([...betInputs2, inputs]))

            //수정된 데이터 처리. 보류 지금필요없음.
            /*const updateData2 = state.updateData.filter((el) => el.id !== id);
            dispatch(setUpdateData([...updateData2, inputs]))*/

            //체크처리
            const checkedInput2 = state.checkedInputs.filter((el) => el !== id)
            dispatch(setCheckedInputs([...checkedInput2, id]));
        }
        else {
            alert("이미시작된 경기 입니다.");
        }

    }

    const activeSelectedMatch = async () => {
        const updateData = []
        for(let i = 0; i < state.checkedInputs.length; i++) {
            const betInputs2 = state.betInputs.filter((el) => el.id === state.checkedInputs[i] && el.is_edited === 1)
            const data = {
                id: state.checkedInputs[i]
            }
            if(betInputs2.length > 0) {
                data.is_edited = 1
                const editedData = betInputs2[0];
                data.bet1 = editedData.bet1
                data.bet2 = editedData.bet2
                data.bet3 = editedData.bet3
            }
            else {
                data.is_edited = 0
            }
            updateData.push(data)
        }
        await activeMatch(updateData)
    }
    const activeAllMatch = async () => {
        const updateData = []
        for(let i = 0; i < state.fixtures.length; i++) {
            const fixture = state.fixtures[i];
            if(moment(fixture.start_date).isAfter(moment().format("YYYY-MM-DD HH:mm"))) {
                const betInputs2 = state.betInputs.filter((el) => el.id === state.fixtures[i])
                const data = {
                    id: state.fixtures[i].id
                }
                if(betInputs2.length > 0) {
                    data.is_edited = 1
                    const editedData = betInputs2[0];
                    data.bet1 = editedData.bet1
                    data.bet2 = editedData.bet2
                    data.bet3 = editedData.bet3
                }
                else {
                    data.is_edited = 0
                }
                updateData.push(data)
            }

        }
        await activeMatch(updateData)
    }

    const activeMatch = async (updateData) => {
        axios.post('/api/activeMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 활성완료");
                window.location.reload();
            }
        })
    }

    function groupBy(array, property) {
        const result = array.reduce((grouped, element) => ({
            ...grouped,
            [element[property]]: [...(grouped[element[property]] || []), element]
        }), {})
        return result
    }


    function getDetail() {
        const marketIds1x2 = [1,50,282,284,409,410,415,419]
        const ratios = state.currentFixture.ratios
        const ratiosByMarket = common.groupBy(ratios, "market_id")
        const keys = Object.keys(ratiosByMarket)
        const result = []
        for(let i = 0; i < keys.length; i++) {

            //마켓아이디 처리
            const marketId = keys[i]

            const arr = ratiosByMarket[marketId]
            // 마켓아이디별 픽스쳐처리
            const trs = []
            for(const fixture of arr) {
                trs.push(<tr>
                    <td>
                        <input
                            type="checkbox"
                        />
                    </td>
                    <td>{fixture.id}</td>
                    <td>{fixture.provider}</td>
                    <td className={fixture.bet1_settlement === BetSettlement.WINNER && "table-success"}>
                        <span style={{textDecoration: fixture.bet1_status !== BetStatus.OPEN? "line-through": "none"}}>{fixture.bet1}</span>
                    </td>
                    <td className={marketIds1x2.includes(fixture.market_id) && (fixture.bet2_settlement === BetSettlement.WINNER && "table-success")}>
                        <span style={{textDecoration: ((!marketIds1x2.includes(fixture.market_id)) || fixture.bet2_status === BetStatus.OPEN)? "none": "line-through"}}>
                            {fixture.baseline? fixture.baseline.split(' (0')[0] : fixture.bet2}
                        </span>
                    </td>
                    <td className={fixture.bet3_settlement === BetSettlement.WINNER && "table-success"}>
                        <span style={{textDecoration: fixture.bet3_status !== BetStatus.OPEN? "line-through": "none"}}>{fixture.bet3}</span>
                    </td>
                    <td>{BetStatus.getKorMessage(fixture.bet1_status)}</td>
                    <td>{moment(fixture.bet1_updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                </tr>)
            }

            const tableTemp = <div>
                <p className="h4 text-bg-dark mb-0 p-2">{arr[0].market}</p>
                <table className="table table-bordered table-hover text-center">
                    <thead className="table-secondary">
                    <tr>
                        <th style={{
                            width: "auto"
                        }}>
                            <input
                                type="checkbox"
                            />
                        </th>
                        <th style={{
                            width: "auto"
                        }}>아디</th>
                        <th style={{
                            width: "auto"
                        }}>업체</th>
                        <th style={{
                            width: "25%"
                        }}>{arr[0].home}</th>
                        <th style={{
                            width: "13%"
                        }}>{marketIds1x2.includes(arr[0].market_id)? "무" : "기준점"}</th>
                        <th style={{
                            width: "25%"
                        }}>{arr[0].away}</th>
                        <th style={{
                            width: "auto"
                        }}>상태</th>
                        <th style={{
                            width: "auto"
                        }}>배당수정시간</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trs}
                    </tbody>
                </table>
            </div>

            result.push(tableTemp)
        }

        return result
    }

    return (
        <div className="container-fluid p-1 m-0">

            {/*<div className="mb-1">
                <button className="btn btn-success me-1" onClick={activeAllMatch} >전체활성</button>
                <button className="btn btn-success me-1" onClick={activeSelectedMatch} >선택활성</button>
            </div>*/}

            <div className="d-flex">
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {state.low_ratios && (
                        <table className="table table-bordered table-hover text-center">
                            <thead className="table-dark">
                            <tr>
                                <th className="text-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            state.checkedInputs.length === state.low_ratios.length
                                        }
                                        onChange={(e) => {allChangeHandler(e)}}
                                    />
                                </th>
                                <th>종목</th>
                                <th>일정</th>
                                <th>리그</th>
                                <th>홈팀</th>
                                <th>원정팀</th>
                                <th>진행배팅금</th>
                                <th>Fix상태</th>
                                <th>Fix수정시간</th>
                                <th>FixID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.isLoading && "로딩중..."}
                            {!state.isLoading && !state.low_ratios.length > 0 && (
                                <tr>
                                    <td colSpan="3">목록이 비어있습니다.</td>
                                </tr>
                            )}
                            {!state.isLoading && !!state.low_ratios.length && state.low_ratios.map((item, index) => (
                                <tr className={state.checkedInputs.includes(item.id) && "info"} onClick={() => {
                                    dispatch(getWaitFixtureDetail(item.fixture_id))
                                }}>
                                    <td>
                                        <input type="checkbox" name={item.fixture_id} id={item.fixture_id} value="true"
                                               checked={state.checkedInputs.includes(item.fixture_id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, item.fixture_id, index)}}
                                        />
                                    </td>
                                    <td>{`${item.sport}\n(${item.mCount})`}</td>
                                    <td>{moment(item.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{item.league}</td>
                                    <td>
                                        {item.home.split(" [")[0]}
                                    </td>
                                    <td>{item.away.split(" [")[0]}</td>
                                    <td>
                                        {item.total && (
                                            <div style={{
                                                width: "100px",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{color: "green"}} onClick={(e) => {
                                                    window.open("/game/manual/betting_popup/" + item.fixture_id + "/0", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                                }}><NumericFormat value={item.total.sumExtraMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betCount})`}</span></p>
                                                <p style={{color: "blue"}} onClick={(e) => {
                                                    window.open("/game/manual/betting_popup/" + item.fixture_id + "/0", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                                }}><NumericFormat value={item.total.sumTotalMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betTotalCount})`}</span></p>
                                            </div>
                                        )}

                                    </td>
                                    <td>{FixtureStatus.getKorMessage(item.fixture_status)}</td>
                                    <td>{moment(item.fixture_updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>{item.fixture_id}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}

                </div>
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {state.isLoadingDetail && "로딩중..."}
                    {!state.isLoadingDetail && state.currentFixture === null && "선택된 경기가 없습니다."}
                    {!state.isLoadingDetail && state.currentFixture !== null && (
                        <div>
                            <div className="text-bg-dark p-2 mb-5">
                                <p>{`${state.currentFixture.ratios[0].sport} - ${state.currentFixture.ratios[0].league}`}</p>
                                <div className="text-center align-middle" style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}><p>{`${state.currentFixture.ratios[0].home}`}</p></div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center",
                                    }}>
                                        <p className="m-0">{`${moment(state.currentFixture.ratios[0].start_date).format("YYYY-MM-DD HH:mm")}`}</p>
                                    </div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}>{`${state.currentFixture.ratios[0].away}`}</div>

                                </div>

                            </div>
                            {getDetail()}

                        </div>
                    )}

                </div>
            </div>
        </div>
    );

}

export default WaitList;