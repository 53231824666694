import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

const LevelSetting = ({countList, index, arrayList, setArrayList, initialState}) => {

    const listArr = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40"];
    const selectArr = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40"];
    const [onceChargePoint, setOnceChargePoint] = useState("");
    const [minChangePoint, setMinChangePoint] = useState("");
    const [changePointUnit, setChangePointUnit] = useState("");
    const [pointLevelCd, setPointLevelCd] = useState("");

    const [isEditing01, setIsEditing01] = useState(true)
    const [isEditing02, setIsEditing02] = useState(true);
    const [isEditing03, setIsEditing03] = useState(true);
    const [isEditing04, setIsEditing04] = useState(true);


    const [settingLevelNoArr, setSettingLevelNoArr] = useState([]);
    const [adminLevelArr, setAdminLevelArr] = useState([]);
    const [userLevelArr, setUserLevelArr] = useState([]);
    const [etcArr, setEtcArr] = useState([]);


    const [box01Val1arr, setBox01Val1arr] = useState([]);
    const [box01Val2arr, setBox01Val2arr] = useState([]);
    const [box01Val3arr, setBox01Val3arr] = useState([]);
    const [box01Val4arr, setBox01Val4arr] = useState([]);
    const [box01Val5arr, setBox01Val5arr] = useState([]);
    const [box01Val6arr, setBox01Val6arr] = useState([]);
    const [box01Val7arr, setBox01Val7arr] = useState([]);

    const [box02Val1arr, setBox02Val1arr] = useState([]);
    const [box02Val2arr, setBox02Val2arr] = useState([]);
    const [box02Val3arr, setBox02Val3arr] = useState([]);
    const [box02Val4arr, setBox02Val4arr] = useState([]);
    const [box02Val5arr, setBox02Val5arr] = useState([]);
    const [box02Val6arr, setBox02Val6arr] = useState([]);
    const [box02Val7arr, setBox02Val7arr] = useState([]);
    const [box02Val8arr, setBox02Val8arr] = useState([]);
    const [box02Val9arr, setBox02Val9arr] = useState([]);
    const [box02Val10arr, setBox02Val10arr] = useState([]);
    const [box02Val11arr, setBox02Val11arr] = useState([]);
    const [box02Val12arr, setBox02Val12arr] = useState([]);
    const [box02Val13arr, setBox02Val13arr] = useState([]);

    const [box03Val1arr, setBox03Val1arr] = useState([]);
    const [box03Val2arr, setBox03Val2arr] = useState([]);
    const [box03Val3arr, setBox03Val3arr] = useState([]);
    const [box03Val4arr, setBox03Val4arr] = useState([]);
    const [box03Val5arr, setBox03Val5arr] = useState([]);
    const [box03Val6arr, setBox03Val6arr] = useState([]);
    const [box03Val7arr, setBox03Val7arr] = useState([]);
    const [box03Val8arr, setBox03Val8arr] = useState([]);
    const [box03Val9arr, setBox03Val9arr] = useState([]);
    const [box03Val10arr, setBox03Val10arr] = useState([]);

    const [box04Val1arr, setBox04Val1arr] = useState([]);
    const [box04Val2arr, setBox04Val2arr] = useState([]);
    const [box04Val3arr, setBox04Val3arr] = useState([]);
    const [box04Val4arr, setBox04Val4arr] = useState([]);
    const [box04Val5arr, setBox04Val5arr] = useState([]);
    const [box04Val6arr, setBox04Val6arr] = useState([]);
    const [box04Val7arr, setBox04Val7arr] = useState([]);
    const [box04Val8arr, setBox04Val8arr] = useState([]);
    const [box04Val9arr, setBox04Val9arr] = useState([]);
    const [box04Val10arr, setBox04Val10arr] = useState([]);
    const [box04Val11arr, setBox04Val11arr] = useState([]);
    const [box04Val12arr, setBox04Val12arr] = useState([]);
    const [box04Val13arr, setBox04Val13arr] = useState([]);
    const [box04Val14arr, setBox04Val14arr] = useState([]);
    const [box04Val15arr, setBox04Val15arr] = useState([]);
    const [box04Val16arr, setBox04Val16arr] = useState([]);
    const [box04Val17arr, setBox04Val17arr] = useState([]);
    const [box04Val18arr, setBox04Val18arr] = useState([]);
    const [box04Val19arr, setBox04Val19arr] = useState([]);
    const [box04Val20arr, setBox04Val20arr] = useState([]);
    const [box04Val21arr, setBox04Val21arr] = useState([]);
    const [box04Val22arr, setBox04Val22arr] = useState([]);
    const [box04Val23arr, setBox04Val23arr] = useState([]);
    const [box04Val24arr, setBox04Val24arr] = useState([]);
    const [box04Val25arr, setBox04Val25arr] = useState([]);
    const [box04Val26arr, setBox04Val26arr] = useState([]);
    const [box04Val27arr, setBox04Val27arr] = useState([]);
    const [box04Val28arr, setBox04Val28arr] = useState([]);
    const [box04Val29arr, setBox04Val29arr] = useState([]);
    const [box04Val30arr, setBox04Val30arr] = useState([]);
    const [box04Val31arr, setBox04Val31arr] = useState([]);
    const [box04Val32arr, setBox04Val32arr] = useState([]);
    const [box04Val33arr, setBox04Val33arr] = useState([]);
    const [box04Val34arr, setBox04Val34arr] = useState([]);
    const [box04Val35arr, setBox04Val35arr] = useState([]);
    const [box04Val36arr, setBox04Val36arr] = useState([]);
    const [box04Val37arr, setBox04Val37arr] = useState([]);
    const [box04Val38arr, setBox04Val38arr] = useState([]);
    const [box04Val39arr, setBox04Val39arr] = useState([]);
    const [box04Val40arr, setBox04Val40arr] = useState([]);
    const [box04Val41arr, setBox04Val41arr] = useState([]);
    const [box04Val42arr, setBox04Val42arr] = useState([]);
    const [box04Val43arr, setBox04Val43arr] = useState([]);
    const [box04Val44arr, setBox04Val44arr] = useState([]);
    const [box04Val45arr, setBox04Val45arr] = useState([]);
    const [box04Val46arr, setBox04Val46arr] = useState([]);
    const [box04Val47arr, setBox04Val47arr] = useState([]);
    const [box04Val48arr, setBox04Val48arr] = useState([]);

    const [box05Val1arr, setBox05Val1arr] = useState([]);
    const [box05Val2arr, setBox05Val2arr] = useState([]);
    const [box05Val3arr, setBox05Val3arr] = useState([]);
    const [box05Val4arr, setBox05Val4arr] = useState([]);
    const [box05Val5arr, setBox05Val5arr] = useState([]);
    const [box05Val6arr, setBox05Val6arr] = useState([]);
    const [box05Val7arr, setBox05Val7arr] = useState([]);
    const [box05Val8arr, setBox05Val8arr] = useState([]);
    const [box05Val9arr, setBox05Val9arr] = useState([]);
    const [box05Val10arr, setBox05Val10arr] = useState([]);
    const [box05Val11arr, setBox05Val11arr] = useState([]);
    const [box05Val12arr, setBox05Val12arr] = useState([]);
    const [box05Val13arr, setBox05Val13arr] = useState([]);
    const [box05Val14arr, setBox05Val14arr] = useState([]);
    const [box05Val15arr, setBox05Val15arr] = useState([]);
    const [box05Val16arr, setBox05Val16arr] = useState([]);
    const [box05Val17arr, setBox05Val17arr] = useState([]);
    const [box05Val18arr, setBox05Val18arr] = useState([]);
    const [box05Val19arr, setBox05Val19arr] = useState([]);
    const [box05Val20arr, setBox05Val20arr] = useState([]);
    const [box05Val21arr, setBox05Val21arr] = useState([]);
    const [box05Val22arr, setBox05Val22arr] = useState([]);
    const [box05Val23arr, setBox05Val23arr] = useState([]);
    const [box05Val24arr, setBox05Val24arr] = useState([]);
    const [box05Val25arr, setBox05Val25arr] = useState([]);
    const [box05Val26arr, setBox05Val26arr] = useState([]);
    const [box05Val27arr, setBox05Val27arr] = useState([]);
    const [box05Val28arr, setBox05Val28arr] = useState([]);
    const [box05Val29arr, setBox05Val29arr] = useState([]);
    const [box05Val30arr, setBox05Val30arr] = useState([]);
    const [box05Val31arr, setBox05Val31arr] = useState([]);
    const [box05Val32arr, setBox05Val32arr] = useState([]);
    const [box05Val33arr, setBox05Val33arr] = useState([]);
    const [box05Val34arr, setBox05Val34arr] = useState([]);
    const [box05Val35arr, setBox05Val35arr] = useState([]);
    const [box05Val36arr, setBox05Val36arr] = useState([]);
    const [box05Val37arr, setBox05Val37arr] = useState([]);
    const [box05Val38arr, setBox05Val38arr] = useState([]);
    const [box05Val39arr, setBox05Val39arr] = useState([]);
    const [box05Val40arr, setBox05Val40arr] = useState([]);
    const [box05Val41arr, setBox05Val41arr] = useState([]);
    const [box05Val42arr, setBox05Val42arr] = useState([]);
    const [box05Val43arr, setBox05Val43arr] = useState([]);
    const [box05Val44arr, setBox05Val44arr] = useState([]);
    const [box05Val45arr, setBox05Val45arr] = useState([]);
    const [box05Val46arr, setBox05Val46arr] = useState([]);
    const [box05Val47arr, setBox05Val47arr] = useState([]);
    const [box05Val48arr, setBox05Val48arr] = useState([]);

    const [box06Val1arr, setBox06Val1arr] = useState([]);
    const [box06Val2arr, setBox06Val2arr] = useState([]);
    const [box06Val3arr, setBox06Val3arr] = useState([]);
    const [box06Val4arr, setBox06Val4arr] = useState([]);
    const [box06Val5arr, setBox06Val5arr] = useState([]);
    const [box06Val6arr, setBox06Val6arr] = useState([]);
    const [box06Val7arr, setBox06Val7arr] = useState([]);
    const [box06Val8arr, setBox06Val8arr] = useState([]);
    const [box06Val9arr, setBox06Val9arr] = useState([]);
    const [box06Val10arr, setBox06Val10arr] = useState([]);
    const [box06Val11arr, setBox06Val11arr] = useState([]);
    const [box06Val12arr, setBox06Val12arr] = useState([]);
    const [box06Val13arr, setBox06Val13arr] = useState([]);
    const [box06Val14arr, setBox06Val14arr] = useState([]);
    const [box06Val15arr, setBox06Val15arr] = useState([]);
    const [box06Val16arr, setBox06Val16arr] = useState([]);
    const [box06Val17arr, setBox06Val17arr] = useState([]);
    const [box06Val18arr, setBox06Val18arr] = useState([]);
    const [box06Val19arr, setBox06Val19arr] = useState([]);
    const [box06Val20arr, setBox06Val20arr] = useState([]);
    const [box06Val21arr, setBox06Val21arr] = useState([]);
    const [box06Val22arr, setBox06Val22arr] = useState([]);
    const [box06Val23arr, setBox06Val23arr] = useState([]);
    const [box06Val24arr, setBox06Val24arr] = useState([]);
    const [box06Val25arr, setBox06Val25arr] = useState([]);
    const [box06Val26arr, setBox06Val26arr] = useState([]);
    const [box06Val27arr, setBox06Val27arr] = useState([]);
    const [box06Val28arr, setBox06Val28arr] = useState([]);
    const [box06Val29arr, setBox06Val29arr] = useState([]);
    const [box06Val30arr, setBox06Val30arr] = useState([]);
    const [box06Val31arr, setBox06Val31arr] = useState([]);
    const [box06Val32arr, setBox06Val32arr] = useState([]);
    const [box06Val33arr, setBox06Val33arr] = useState([]);
    const [box06Val34arr, setBox06Val34arr] = useState([]);
    const [box06Val35arr, setBox06Val35arr] = useState([]);
    const [box06Val36arr, setBox06Val36arr] = useState([]);
    const [box06Val37arr, setBox06Val37arr] = useState([]);
    const [box06Val38arr, setBox06Val38arr] = useState([]);
    const [box06Val39arr, setBox06Val39arr] = useState([]);
    const [box06Val40arr, setBox06Val40arr] = useState([]);
    const [box06Val41arr, setBox06Val41arr] = useState([]);
    const [box06Val42arr, setBox06Val42arr] = useState([]);
    const [box06Val43arr, setBox06Val43arr] = useState([]);
    const [box06Val44arr, setBox06Val44arr] = useState([]);
    const [box06Val45arr, setBox06Val45arr] = useState([]);
    const [box06Val46arr, setBox06Val46arr] = useState([]);
    const [box06Val47arr, setBox06Val47arr] = useState([]);
    const [box06Val48arr, setBox06Val48arr] = useState([]);

    const [box07Val1arr, setBox07Val1arr] = useState([]);
    const [box07Val2arr, setBox07Val2arr] = useState([]);
    const [box07Val3arr, setBox07Val3arr] = useState([]);
    const [box07Val4arr, setBox07Val4arr] = useState([]);
    const [box07Val5arr, setBox07Val5arr] = useState([]);
    const [box07Val6arr, setBox07Val6arr] = useState([]);
    const [box07Val7arr, setBox07Val7arr] = useState([]);
    const [box07Val8arr, setBox07Val8arr] = useState([]);
    const [box07Val9arr, setBox07Val9arr] = useState([]);
    const [box07Val10arr, setBox07Val10arr] = useState([]);
    const [box07Val11arr, setBox07Val11arr] = useState([]);
    const [box07Val12arr, setBox07Val12arr] = useState([]);
    const [box07Val13arr, setBox07Val13arr] = useState([]);
    const [box07Val14arr, setBox07Val14arr] = useState([]);
    const [box07Val15arr, setBox07Val15arr] = useState([]);
    const [box07Val16arr, setBox07Val16arr] = useState([]);
    const [box07Val17arr, setBox07Val17arr] = useState([]);
    const [box07Val18arr, setBox07Val18arr] = useState([]);
    const [box07Val19arr, setBox07Val19arr] = useState([]);
    const [box07Val20arr, setBox07Val20arr] = useState([]);
    const [box07Val21arr, setBox07Val21arr] = useState([]);
    const [box07Val22arr, setBox07Val22arr] = useState([]);
    const [box07Val23arr, setBox07Val23arr] = useState([]);
    const [box07Val24arr, setBox07Val24arr] = useState([]);
    const [box07Val25arr, setBox07Val25arr] = useState([]);
    const [box07Val26arr, setBox07Val26arr] = useState([]);
    const [box07Val27arr, setBox07Val27arr] = useState([]);
    const [box07Val28arr, setBox07Val28arr] = useState([]);
    const [box07Val29arr, setBox07Val29arr] = useState([]);
    const [box07Val30arr, setBox07Val30arr] = useState([]);
    const [box07Val31arr, setBox07Val31arr] = useState([]);
    const [box07Val32arr, setBox07Val32arr] = useState([]);
    const [box07Val33arr, setBox07Val33arr] = useState([]);
    const [box07Val34arr, setBox07Val34arr] = useState([]);
    const [box07Val35arr, setBox07Val35arr] = useState([]);
    const [box07Val36arr, setBox07Val36arr] = useState([]);
    const [box07Val37arr, setBox07Val37arr] = useState([]);
    const [box07Val38arr, setBox07Val38arr] = useState([]);
    const [box07Val39arr, setBox07Val39arr] = useState([]);
    const [box07Val40arr, setBox07Val40arr] = useState([]);
    const [box07Val41arr, setBox07Val41arr] = useState([]);
    const [box07Val42arr, setBox07Val42arr] = useState([]);
    const [box07Val43arr, setBox07Val43arr] = useState([]);
    const [box07Val44arr, setBox07Val44arr] = useState([]);
    const [box07Val45arr, setBox07Val45arr] = useState([]);
    const [box07Val46arr, setBox07Val46arr] = useState([]);
    const [box07Val47arr, setBox07Val47arr] = useState([]);
    const [box07Val48arr, setBox07Val48arr] = useState([]);

    const [box08Val1arr, setBox08Val1arr] = useState([]);
    const [box08Val2arr, setBox08Val2arr] = useState([]);
    const [box08Val3arr, setBox08Val3arr] = useState([]);
    const [box08Val4arr, setBox08Val4arr] = useState([]);
    const [box08Val5arr, setBox08Val5arr] = useState([]);
    const [box08Val6arr, setBox08Val6arr] = useState([]);
    const [box08Val7arr, setBox08Val7arr] = useState([]);

    const [box09Val1arr, setBox09Val1arr] = useState([]);
    const [box09Val2arr, setBox09Val2arr] = useState([]);
    const [box09Val3arr, setBox09Val3arr] = useState([]);
    const [box09Val4arr, setBox09Val4arr] = useState([]);
    const [box09Val5arr, setBox09Val5arr] = useState([]);
    const [box09Val6arr, setBox09Val6arr] = useState([]);
    const [box09Val7arr, setBox09Val7arr] = useState([]);

    const [box10Val1arr, setBox10Val1arr] = useState([]);
    const [box10Val2arr, setBox10Val2arr] = useState([]);
    const [box10Val3arr, setBox10Val3arr] = useState([]);
    const [box10Val4arr, setBox10Val4arr] = useState([]);
    const [box10Val5arr, setBox10Val5arr] = useState([]);
    const [box10Val6arr, setBox10Val6arr] = useState([]);
    const [box10Val7arr, setBox10Val7arr] = useState([]);

    const [box11Val1arr, setBox11Val1arr] = useState([]);
    const [box11Val2arr, setBox11Val2arr] = useState([]);
    const [box11Val3arr, setBox11Val3arr] = useState([]);
    const [box11Val4arr, setBox11Val4arr] = useState([]);
    const [box11Val5arr, setBox11Val5arr] = useState([]);
    const [box11Val6arr, setBox11Val6arr] = useState([]);
    const [box11Val7arr, setBox11Val7arr] = useState([]);

    const [box12Val1arr, setBox12Val1arr] = useState([]);
    const [box12Val2arr, setBox12Val2arr] = useState([]);
    const [box12Val3arr, setBox12Val3arr] = useState([]);
    const [box12Val4arr, setBox12Val4arr] = useState([]);
    const [box12Val5arr, setBox12Val5arr] = useState([]);
    const [box12Val6arr, setBox12Val6arr] = useState([]);
    const [box12Val7arr, setBox12Val7arr] = useState([]);

    const [box13Val1arr, setBox13Val1arr] = useState([]);
    const [box13Val2arr, setBox13Val2arr] = useState([]);
    const [box13Val3arr, setBox13Val3arr] = useState([]);
    const [box13Val4arr, setBox13Val4arr] = useState([]);
    const [box13Val5arr, setBox13Val5arr] = useState([]);
    const [box13Val6arr, setBox13Val6arr] = useState([]);
    const [box13Val7arr, setBox13Val7arr] = useState([]);

    const [box14Val1arr, setBox14Val1arr] = useState([]);
    const [box14Val2arr, setBox14Val2arr] = useState([]);
    const [box14Val3arr, setBox14Val3arr] = useState([]);
    const [box14Val4arr, setBox14Val4arr] = useState([]);


    const adminLevelNoHandler = (e, index) => {
        adminLevelArr[index] = e.target.value;
        setAdminLevelArr(adminLevelArr);
        settingLevelNoArr[index] = index;
        setSettingLevelNoArr(settingLevelNoArr);
        //alert("adminLevelArr: "+e.target.value);
    }
    const userLevelNoHandler = (e, index) => {
        userLevelArr[index] = e.target.value;
        setUserLevelArr(userLevelArr);
        settingLevelNoArr[index] = index;
        setSettingLevelNoArr(settingLevelNoArr);
    }
    const etcNoHandler = (e, index) => {
        etcArr[index] = e.target.value;
        setEtcArr(etcArr);
        settingLevelNoArr[index] = index;
        setSettingLevelNoArr(settingLevelNoArr);
    }

    const arrayAllCopyHandler = (copyGb, index, endCnt) => {


         // alert("countList.point_level_cd :: "+endCnt);
         // alert("idnex : "+index);


        if( copyGb === "1" )
        {
            for ( let i = 0; i < endCnt; i++)
            {
                if( i > index )
                {
                    // alert("01.box01Val1arr[index] :: "+box01Val1arr[index] );
                    // alert("02.initialState.box01Val1array :: "+initialState.box01Val1array );
                    // alert("03.arrayList[index].box01_val1 :: "+arrayList[index].box01_val1 );
                    // alert("04.box01Val1arr :: "+JSON.stringify(box01Val1arr) );
                    // alert("CJWbox01Val1arr>>> :: "+JSON.stringify(box01Val1arr[index]));
                    //
                    // alert(  (box01Val1arr[index] ===  undefined? "if"  : "else"));
                    // alert(  "initialState.box01Val1array  : "+initialState.box01Val1array );
                    // alert( "arrayList[index2].box01_val1 ::: "+arrayList[index].box01_val1 );

                    setArrayList(
                        index,
                        endCnt,
                        (box01Val1arr[index] === undefined? ( initialState.box01Val1array[index]? initialState.box01Val1array[index]: arrayList[index].box01_val1 ) : box01Val1arr[index]),
                        (box01Val2arr[index] === undefined? ( initialState.box01Val2array[index]? initialState.box01Val2array[index]: arrayList[index].box01_val2 ) : box01Val2arr[index]),
                        (box01Val3arr[index] === undefined? ( initialState.box01Val3array[index]? initialState.box01Val3array[index]: arrayList[index].box01_val3 ) : box01Val3arr[index]),
                        (box01Val4arr[index] === undefined? ( initialState.box01Val4array[index]? initialState.box01Val4array[index]: arrayList[index].box01_val4 ) : box01Val4arr[index]),
                        (box01Val5arr[index] === undefined? ( initialState.box01Val5array[index]? initialState.box01Val5array[index]: arrayList[index].box01_val5 ) : box01Val5arr[index]),
                        (box01Val6arr[index] === undefined? ( initialState.box01Val6array[index]? initialState.box01Val6array[index]: arrayList[index].box01_val6 ) : box01Val6arr[index]),
                        (box01Val7arr[index] === undefined? ( initialState.box01Val7array[index]? initialState.box01Val7array[index]: arrayList[index].box01_val7 ) : box01Val7arr[index]),
                        (box02Val1arr[index] === undefined? ( initialState.box02Val1array[index]? initialState.box02Val1array[index]: arrayList[index].box02_val1 ) : box02Val1arr[index]),
                        (box02Val2arr[index] === undefined? ( initialState.box02Val2array[index]? initialState.box02Val2array[index]: arrayList[index].box02_val2 ) : box02Val2arr[index]),
                        (box02Val3arr[index] === undefined? ( initialState.box02Val3array[index]? initialState.box02Val3array[index]: arrayList[index].box02_val3 ) : box02Val3arr[index]),
                        (box02Val4arr[index] === undefined? ( initialState.box02Val4array[index]? initialState.box02Val4array[index]: arrayList[index].box02_val4 ) : box02Val4arr[index]),
                        (box02Val5arr[index] === undefined? ( initialState.box02Val5array[index]? initialState.box02Val5array[index]: arrayList[index].box02_val5 ) : box02Val5arr[index]),
                        (box02Val6arr[index] === undefined? ( initialState.box02Val6array[index]? initialState.box02Val6array[index]: arrayList[index].box02_val6 ) : box02Val6arr[index]),
                        (box02Val7arr[index] === undefined? ( initialState.box02Val7array[index]? initialState.box02Val7array[index]: arrayList[index].box02_val7 ) : box02Val7arr[index]),
                        (box02Val8arr[index] === undefined? ( initialState.box02Val8array[index]? initialState.box02Val8array[index]: arrayList[index].box02_val8 ) : box02Val8arr[index]),
                        (box02Val9arr[index] === undefined? ( initialState.box02Val9array[index]? initialState.box02Val9array[index]: arrayList[index].box02_val9 ) : box02Val9arr[index]),
                        (box02Val10arr[index] === undefined? ( initialState.box02Val10array[index]? initialState.box02Val10array[index]: arrayList[index].box02_val10 ) : box02Val10arr[index]),
                        (box02Val11arr[index] === undefined? ( initialState.box02Val11array[index]? initialState.box02Val11array[index]: arrayList[index].box02_val11 ) : box02Val11arr[index]),
                        (box02Val12arr[index] === undefined? ( initialState.box02Val12array[index]? initialState.box02Val12array[index]: arrayList[index].box02_val12 ) : box02Val12arr[index]),
                        (box02Val13arr[index] === undefined? ( initialState.box02Val13array[index]? initialState.box02Val13array[index]: arrayList[index].box02_val13 ) : box02Val13arr[index]),
                        (box03Val1arr[index] === undefined? ( initialState.box03Val1array[index]? initialState.box03Val1array[index]: arrayList[index].box03_val1 ) : box03Val1arr[index]),
                        (box03Val2arr[index] === undefined? ( initialState.box03Val2array[index]? initialState.box03Val2array[index]: arrayList[index].box03_val2 ) : box03Val2arr[index]),
                        (box03Val3arr[index] === undefined? ( initialState.box03Val3array[index]? initialState.box03Val3array[index]: arrayList[index].box03_val3 ) : box03Val3arr[index]),
                        (box03Val4arr[index] === undefined? ( initialState.box03Val4array[index]? initialState.box03Val4array[index]: arrayList[index].box03_val4 ) : box03Val4arr[index]),
                        (box03Val5arr[index] === undefined? ( initialState.box03Val5array[index]? initialState.box03Val5array[index]: arrayList[index].box03_val5 ) : box03Val5arr[index]),
                        (box03Val6arr[index] === undefined? ( initialState.box03Val6array[index]? initialState.box03Val6array[index]: arrayList[index].box03_val6 ) : box03Val6arr[index]),
                        (box03Val7arr[index] === undefined? ( initialState.box03Val7array[index]? initialState.box03Val7array[index]: arrayList[index].box03_val7 ) : box03Val7arr[index]),
                        (box03Val8arr[index] === undefined? ( initialState.box03Val8array[index]? initialState.box03Val8array[index]: arrayList[index].box03_val8 ) : box03Val8arr[index]),
                        (box03Val9arr[index] === undefined? ( initialState.box03Val9array[index]? initialState.box03Val9array[index]: arrayList[index].box03_val9 ) : box03Val9arr[index]),
                        (box03Val10arr[index] === undefined? ( initialState.box03Val10array[index]? initialState.box03Val10array[index]: arrayList[index].box03_val10 ) : box03Val10arr[index]),
                        (box04Val1arr[index] === undefined? ( initialState.box04Val1array[index]? initialState.box04Val1array[index]: arrayList[index].box04_val1 ) : box04Val1arr[index]),
                        (box04Val2arr[index] === undefined? ( initialState.box04Val2array[index]? initialState.box04Val2array[index]: arrayList[index].box04_val2 ) : box04Val2arr[index]),
                        (box04Val3arr[index] === undefined? ( initialState.box04Val3array[index]? initialState.box04Val3array[index]: arrayList[index].box04_val3 ) : box04Val3arr[index]),
                        (box04Val4arr[index] === undefined? ( initialState.box04Val4array[index]? initialState.box04Val4array[index]: arrayList[index].box04_val4 ) : box04Val4arr[index]),
                        (box04Val5arr[index] === undefined? ( initialState.box04Val5array[index]? initialState.box04Val5array[index]: arrayList[index].box04_val5 ) : box04Val5arr[index]),
                        (box04Val6arr[index] === undefined? ( initialState.box04Val6array[index]? initialState.box04Val6array[index]: arrayList[index].box04_val6 ) : box04Val6arr[index]),
                        (box04Val7arr[index] === undefined? ( initialState.box04Val7array[index]? initialState.box04Val7array[index]: arrayList[index].box04_val7 ) : box04Val7arr[index]),
                        (box04Val8arr[index] === undefined? ( initialState.box04Val8array[index]? initialState.box04Val8array[index]: arrayList[index].box04_val8 ) : box04Val8arr[index]),
                        (box04Val9arr[index] === undefined? ( initialState.box04Val9array[index]? initialState.box04Val9array[index]: arrayList[index].box04_val9 ) : box04Val9arr[index]),
                        (box04Val10arr[index] === undefined? ( initialState.box04Val10array[index]? initialState.box04Val10array[index]: arrayList[index].box04_val10 ) : box04Val10arr[index]),
                        (box04Val11arr[index] === undefined? ( initialState.box04Val11array[index]? initialState.box04Val11array[index]: arrayList[index].box04_val11 ) : box04Val11arr[index]),
                        (box04Val12arr[index] === undefined? ( initialState.box04Val12array[index]? initialState.box04Val12array[index]: arrayList[index].box04_val12 ) : box04Val12arr[index]),
                        (box04Val13arr[index] === undefined? ( initialState.box04Val13array[index]? initialState.box04Val13array[index]: arrayList[index].box04_val13 ) : box04Val13arr[index]),
                        (box04Val14arr[index] === undefined? ( initialState.box04Val14array[index]? initialState.box04Val14array[index]: arrayList[index].box04_val14 ) : box04Val14arr[index]),
                        (box04Val15arr[index] === undefined? ( initialState.box04Val15array[index]? initialState.box04Val15array[index]: arrayList[index].box04_val15 ) : box04Val15arr[index]),
                        (box04Val16arr[index] === undefined? ( initialState.box04Val16array[index]? initialState.box04Val16array[index]: arrayList[index].box04_val16 ) : box04Val16arr[index]),
                        (box04Val17arr[index] === undefined? ( initialState.box04Val17array[index]? initialState.box04Val17array[index]: arrayList[index].box04_val17 ) : box04Val17arr[index]),
                        (box04Val18arr[index] === undefined? ( initialState.box04Val18array[index]? initialState.box04Val18array[index]: arrayList[index].box04_val18 ) : box04Val18arr[index]),
                        (box04Val19arr[index] === undefined? ( initialState.box04Val19array[index]? initialState.box04Val19array[index]: arrayList[index].box04_val19 ) : box04Val19arr[index]),
                        (box04Val20arr[index] === undefined? ( initialState.box04Val20array[index]? initialState.box04Val20array[index]: arrayList[index].box04_val20 ) : box04Val20arr[index]),
                        (box04Val21arr[index] === undefined? ( initialState.box04Val21array[index]? initialState.box04Val21array[index]: arrayList[index].box04_val21 ) : box04Val21arr[index]),
                        (box04Val22arr[index] === undefined? ( initialState.box04Val22array[index]? initialState.box04Val22array[index]: arrayList[index].box04_val22 ) : box04Val22arr[index]),
                        (box04Val23arr[index] === undefined? ( initialState.box04Val23array[index]? initialState.box04Val23array[index]: arrayList[index].box04_val23 ) : box04Val23arr[index]),
                        (box04Val24arr[index] === undefined? ( initialState.box04Val24array[index]? initialState.box04Val24array[index]: arrayList[index].box04_val24 ) : box04Val24arr[index]),
                        (box04Val25arr[index] === undefined? ( initialState.box04Val25array[index]? initialState.box04Val25array[index]: arrayList[index].box04_val25 ) : box04Val25arr[index]),
                        (box04Val26arr[index] === undefined? ( initialState.box04Val26array[index]? initialState.box04Val26array[index]: arrayList[index].box04_val26 ) : box04Val26arr[index]),
                        (box04Val27arr[index] === undefined? ( initialState.box04Val27array[index]? initialState.box04Val27array[index]: arrayList[index].box04_val27 ) : box04Val27arr[index]),
                        (box04Val28arr[index] === undefined? ( initialState.box04Val28array[index]? initialState.box04Val28array[index]: arrayList[index].box04_val28 ) : box04Val28arr[index]),
                        (box04Val29arr[index] === undefined? ( initialState.box04Val29array[index]? initialState.box04Val29array[index]: arrayList[index].box04_val29 ) : box04Val29arr[index]),
                        (box04Val30arr[index] === undefined? ( initialState.box04Val30array[index]? initialState.box04Val30array[index]: arrayList[index].box04_val30 ) : box04Val30arr[index]),
                        (box04Val31arr[index] === undefined? ( initialState.box04Val31array[index]? initialState.box04Val31array[index]: arrayList[index].box04_val31 ) : box04Val31arr[index]),
                        (box04Val32arr[index] === undefined? ( initialState.box04Val32array[index]? initialState.box04Val32array[index]: arrayList[index].box04_val32 ) : box04Val32arr[index]),
                        (box04Val33arr[index] === undefined? ( initialState.box04Val33array[index]? initialState.box04Val33array[index]: arrayList[index].box04_val33 ) : box04Val33arr[index]),
                        (box04Val34arr[index] === undefined? ( initialState.box04Val34array[index]? initialState.box04Val34array[index]: arrayList[index].box04_val34 ) : box04Val34arr[index]),
                        (box04Val35arr[index] === undefined? ( initialState.box04Val35array[index]? initialState.box04Val35array[index]: arrayList[index].box04_val35 ) : box04Val35arr[index]),
                        (box04Val36arr[index] === undefined? ( initialState.box04Val36array[index]? initialState.box04Val36array[index]: arrayList[index].box04_val36 ) : box04Val36arr[index]),
                        (box04Val37arr[index] === undefined? ( initialState.box04Val37array[index]? initialState.box04Val37array[index]: arrayList[index].box04_val37 ) : box04Val37arr[index]),
                        (box04Val38arr[index] === undefined? ( initialState.box04Val38array[index]? initialState.box04Val38array[index]: arrayList[index].box04_val38 ) : box04Val38arr[index]),
                        (box04Val39arr[index] === undefined? ( initialState.box04Val39array[index]? initialState.box04Val39array[index]: arrayList[index].box04_val39 ) : box04Val39arr[index]),
                        (box04Val40arr[index] === undefined? ( initialState.box04Val40array[index]? initialState.box04Val40array[index]: arrayList[index].box04_val40 ) : box04Val40arr[index]),
                        (box04Val41arr[index] === undefined? ( initialState.box04Val41array[index]? initialState.box04Val41array[index]: arrayList[index].box04_val41 ) : box04Val41arr[index]),
                        (box04Val42arr[index] === undefined? ( initialState.box04Val42array[index]? initialState.box04Val42array[index]: arrayList[index].box04_val42 ) : box04Val42arr[index]),
                        (box04Val43arr[index] === undefined? ( initialState.box04Val43array[index]? initialState.box04Val43array[index]: arrayList[index].box04_val43 ) : box04Val43arr[index]),
                        (box04Val44arr[index] === undefined? ( initialState.box04Val44array[index]? initialState.box04Val44array[index]: arrayList[index].box04_val44 ) : box04Val44arr[index]),
                        (box04Val45arr[index] === undefined? ( initialState.box04Val45array[index]? initialState.box04Val45array[index]: arrayList[index].box04_val45 ) : box04Val45arr[index]),
                        (box04Val46arr[index] === undefined? ( initialState.box04Val46array[index]? initialState.box04Val46array[index]: arrayList[index].box04_val46 ) : box04Val46arr[index]),
                        (box04Val47arr[index] === undefined? ( initialState.box04Val47array[index]? initialState.box04Val47array[index]: arrayList[index].box04_val47 ) : box04Val47arr[index]),
                        (box04Val48arr[index] === undefined? ( initialState.box04Val48array[index]? initialState.box04Val48array[index]: arrayList[index].box04_val48 ) : box04Val48arr[index]),
                        (box05Val1arr[index] === undefined? ( initialState.box05Val1array[index]? initialState.box05Val1array[index]: arrayList[index].box05_val1 ) : box05Val1arr[index]),
                        (box05Val2arr[index] === undefined? ( initialState.box05Val2array[index]? initialState.box05Val2array[index]: arrayList[index].box05_val2 ) : box05Val2arr[index]),
                        (box05Val3arr[index] === undefined? ( initialState.box05Val3array[index]? initialState.box05Val3array[index]: arrayList[index].box05_val3 ) : box05Val3arr[index]),
                        (box05Val4arr[index] === undefined? ( initialState.box05Val4array[index]? initialState.box05Val4array[index]: arrayList[index].box05_val4 ) : box05Val4arr[index]),
                        (box05Val5arr[index] === undefined? ( initialState.box05Val5array[index]? initialState.box05Val5array[index]: arrayList[index].box05_val5 ) : box05Val5arr[index]),
                        (box05Val6arr[index] === undefined? ( initialState.box05Val6array[index]? initialState.box05Val6array[index]: arrayList[index].box05_val6 ) : box05Val6arr[index]),
                        (box05Val7arr[index] === undefined? ( initialState.box05Val7array[index]? initialState.box05Val7array[index]: arrayList[index].box05_val7 ) : box05Val7arr[index]),
                        (box05Val8arr[index] === undefined? ( initialState.box05Val8array[index]? initialState.box05Val8array[index]: arrayList[index].box05_val8 ) : box05Val8arr[index]),
                        (box05Val9arr[index] === undefined? ( initialState.box05Val9array[index]? initialState.box05Val9array[index]: arrayList[index].box05_val9 ) : box05Val9arr[index]),
                        (box05Val10arr[index] === undefined? ( initialState.box05Val10array[index]? initialState.box05Val10array[index]: arrayList[index].box05_val10 ) : box05Val10arr[index]),
                        (box05Val11arr[index] === undefined? ( initialState.box05Val11array[index]? initialState.box05Val11array[index]: arrayList[index].box05_val11 ) : box05Val11arr[index]),
                        (box05Val12arr[index] === undefined? ( initialState.box05Val12array[index]? initialState.box05Val12array[index]: arrayList[index].box05_val12 ) : box05Val12arr[index]),
                        (box05Val13arr[index] === undefined? ( initialState.box05Val13array[index]? initialState.box05Val13array[index]: arrayList[index].box05_val13 ) : box05Val13arr[index]),
                        (box05Val14arr[index] === undefined? ( initialState.box05Val14array[index]? initialState.box05Val14array[index]: arrayList[index].box05_val14 ) : box05Val14arr[index]),
                        (box05Val15arr[index] === undefined? ( initialState.box05Val15array[index]? initialState.box05Val15array[index]: arrayList[index].box05_val15 ) : box05Val15arr[index]),
                        (box05Val16arr[index] === undefined? ( initialState.box05Val16array[index]? initialState.box05Val16array[index]: arrayList[index].box05_val16 ) : box05Val16arr[index]),
                        (box05Val17arr[index] === undefined? ( initialState.box05Val17array[index]? initialState.box05Val17array[index]: arrayList[index].box05_val17 ) : box05Val17arr[index]),
                        (box05Val18arr[index] === undefined? ( initialState.box05Val18array[index]? initialState.box05Val18array[index]: arrayList[index].box05_val18 ) : box05Val18arr[index]),
                        (box05Val19arr[index] === undefined? ( initialState.box05Val19array[index]? initialState.box05Val19array[index]: arrayList[index].box05_val19 ) : box05Val19arr[index]),
                        (box05Val20arr[index] === undefined? ( initialState.box05Val20array[index]? initialState.box05Val20array[index]: arrayList[index].box05_val20 ) : box05Val20arr[index]),
                        (box05Val21arr[index] === undefined? ( initialState.box05Val21array[index]? initialState.box05Val21array[index]: arrayList[index].box05_val21 ) : box05Val21arr[index]),
                        (box05Val22arr[index] === undefined? ( initialState.box05Val22array[index]? initialState.box05Val22array[index]: arrayList[index].box05_val22 ) : box05Val22arr[index]),
                        (box05Val23arr[index] === undefined? ( initialState.box05Val23array[index]? initialState.box05Val23array[index]: arrayList[index].box05_val23 ) : box05Val23arr[index]),
                        (box05Val24arr[index] === undefined? ( initialState.box05Val24array[index]? initialState.box05Val24array[index]: arrayList[index].box05_val24 ) : box05Val24arr[index]),
                        (box05Val25arr[index] === undefined? ( initialState.box05Val25array[index]? initialState.box05Val25array[index]: arrayList[index].box05_val25 ) : box05Val25arr[index]),
                        (box05Val26arr[index] === undefined? ( initialState.box05Val26array[index]? initialState.box05Val26array[index]: arrayList[index].box05_val26 ) : box05Val26arr[index]),
                        (box05Val27arr[index] === undefined? ( initialState.box05Val27array[index]? initialState.box05Val27array[index]: arrayList[index].box05_val27 ) : box05Val27arr[index]),
                        (box05Val28arr[index] === undefined? ( initialState.box05Val28array[index]? initialState.box05Val28array[index]: arrayList[index].box05_val28 ) : box05Val28arr[index]),
                        (box05Val29arr[index] === undefined? ( initialState.box05Val29array[index]? initialState.box05Val29array[index]: arrayList[index].box05_val29 ) : box05Val29arr[index]),
                        (box05Val30arr[index] === undefined? ( initialState.box05Val30array[index]? initialState.box05Val30array[index]: arrayList[index].box05_val30 ) : box05Val30arr[index]),
                        (box05Val31arr[index] === undefined? ( initialState.box05Val31array[index]? initialState.box05Val31array[index]: arrayList[index].box05_val31 ) : box05Val31arr[index]),
                        (box05Val32arr[index] === undefined? ( initialState.box05Val32array[index]? initialState.box05Val32array[index]: arrayList[index].box05_val32 ) : box05Val32arr[index]),
                        (box05Val33arr[index] === undefined? ( initialState.box05Val33array[index]? initialState.box05Val33array[index]: arrayList[index].box05_val33 ) : box05Val33arr[index]),
                        (box05Val34arr[index] === undefined? ( initialState.box05Val34array[index]? initialState.box05Val34array[index]: arrayList[index].box05_val34 ) : box05Val34arr[index]),
                        (box05Val35arr[index] === undefined? ( initialState.box05Val35array[index]? initialState.box05Val35array[index]: arrayList[index].box05_val35 ) : box05Val35arr[index]),
                        (box05Val36arr[index] === undefined? ( initialState.box05Val36array[index]? initialState.box05Val36array[index]: arrayList[index].box05_val36 ) : box05Val36arr[index]),
                        (box05Val37arr[index] === undefined? ( initialState.box05Val37array[index]? initialState.box05Val37array[index]: arrayList[index].box05_val37 ) : box05Val37arr[index]),
                        (box05Val38arr[index] === undefined? ( initialState.box05Val38array[index]? initialState.box05Val38array[index]: arrayList[index].box05_val38 ) : box05Val38arr[index]),
                        (box05Val39arr[index] === undefined? ( initialState.box05Val39array[index]? initialState.box05Val39array[index]: arrayList[index].box05_val39 ) : box05Val39arr[index]),
                        (box05Val40arr[index] === undefined? ( initialState.box05Val40array[index]? initialState.box05Val40array[index]: arrayList[index].box05_val40 ) : box05Val40arr[index]),
                        (box05Val41arr[index] === undefined? ( initialState.box05Val41array[index]? initialState.box05Val41array[index]: arrayList[index].box05_val41 ) : box05Val41arr[index]),
                        (box05Val42arr[index] === undefined? ( initialState.box05Val42array[index]? initialState.box05Val42array[index]: arrayList[index].box05_val42 ) : box05Val42arr[index]),
                        (box05Val43arr[index] === undefined? ( initialState.box05Val43array[index]? initialState.box05Val43array[index]: arrayList[index].box05_val43 ) : box05Val43arr[index]),
                        (box05Val44arr[index] === undefined? ( initialState.box05Val44array[index]? initialState.box05Val44array[index]: arrayList[index].box05_val44 ) : box05Val44arr[index]),
                        (box05Val45arr[index] === undefined? ( initialState.box05Val45array[index]? initialState.box05Val45array[index]: arrayList[index].box05_val45 ) : box05Val45arr[index]),
                        (box05Val46arr[index] === undefined? ( initialState.box05Val46array[index]? initialState.box05Val46array[index]: arrayList[index].box05_val46 ) : box05Val46arr[index]),
                        (box05Val47arr[index] === undefined? ( initialState.box05Val47array[index]? initialState.box05Val47array[index]: arrayList[index].box05_val47 ) : box05Val47arr[index]),
                        (box05Val48arr[index] === undefined? ( initialState.box05Val48array[index]? initialState.box05Val48array[index]: arrayList[index].box05_val48 ) : box05Val48arr[index]),
                        (box06Val1arr[index] === undefined? ( initialState.box06Val1array[index]? initialState.box06Val1array[index]: arrayList[index].box06_val1 ) : box06Val1arr[index]),
                        (box06Val2arr[index] === undefined? ( initialState.box06Val2array[index]? initialState.box06Val2array[index]: arrayList[index].box06_val2 ) : box06Val2arr[index]),
                        (box06Val3arr[index] === undefined? ( initialState.box06Val3array[index]? initialState.box06Val3array[index]: arrayList[index].box06_val3 ) : box06Val3arr[index]),
                        (box06Val4arr[index] === undefined? ( initialState.box06Val4array[index]? initialState.box06Val4array[index]: arrayList[index].box06_val4 ) : box06Val4arr[index]),
                        (box06Val5arr[index] === undefined? ( initialState.box06Val5array[index]? initialState.box06Val5array[index]: arrayList[index].box06_val5 ) : box06Val5arr[index]),
                        (box06Val6arr[index] === undefined? ( initialState.box06Val6array[index]? initialState.box06Val6array[index]: arrayList[index].box06_val6 ) : box06Val6arr[index]),
                        (box06Val7arr[index] === undefined? ( initialState.box06Val7array[index]? initialState.box06Val7array[index]: arrayList[index].box06_val7 ) : box06Val7arr[index]),
                        (box06Val8arr[index] === undefined? ( initialState.box06Val8array[index]? initialState.box06Val8array[index]: arrayList[index].box06_val8 ) : box06Val8arr[index]),
                        (box06Val9arr[index] === undefined? ( initialState.box06Val9array[index]? initialState.box06Val9array[index]: arrayList[index].box06_val9 ) : box06Val9arr[index]),
                        (box06Val10arr[index] === undefined? ( initialState.box06Val10array[index]? initialState.box06Val10array[index]: arrayList[index].box06_val10 ) : box06Val10arr[index]),
                        (box06Val11arr[index] === undefined? ( initialState.box06Val11array[index]? initialState.box06Val11array[index]: arrayList[index].box06_val11 ) : box06Val11arr[index]),
                        (box06Val12arr[index] === undefined? ( initialState.box06Val12array[index]? initialState.box06Val12array[index]: arrayList[index].box06_val12 ) : box06Val12arr[index]),
                        (box06Val13arr[index] === undefined? ( initialState.box06Val13array[index]? initialState.box06Val13array[index]: arrayList[index].box06_val13 ) : box06Val13arr[index]),
                        (box06Val14arr[index] === undefined? ( initialState.box06Val14array[index]? initialState.box06Val14array[index]: arrayList[index].box06_val14 ) : box06Val14arr[index]),
                        (box06Val15arr[index] === undefined? ( initialState.box06Val15array[index]? initialState.box06Val15array[index]: arrayList[index].box06_val15 ) : box06Val15arr[index]),
                        (box06Val16arr[index] === undefined? ( initialState.box06Val16array[index]? initialState.box06Val16array[index]: arrayList[index].box06_val16 ) : box06Val16arr[index]),
                        (box06Val17arr[index] === undefined? ( initialState.box06Val17array[index]? initialState.box06Val17array[index]: arrayList[index].box06_val17 ) : box06Val17arr[index]),
                        (box06Val18arr[index] === undefined? ( initialState.box06Val18array[index]? initialState.box06Val18array[index]: arrayList[index].box06_val18 ) : box06Val18arr[index]),
                        (box06Val19arr[index] === undefined? ( initialState.box06Val19array[index]? initialState.box06Val19array[index]: arrayList[index].box06_val19 ) : box06Val19arr[index]),
                        (box06Val20arr[index] === undefined? ( initialState.box06Val20array[index]? initialState.box06Val20array[index]: arrayList[index].box06_val20 ) : box06Val20arr[index]),
                        (box06Val21arr[index] === undefined? ( initialState.box06Val21array[index]? initialState.box06Val21array[index]: arrayList[index].box06_val21 ) : box06Val21arr[index]),
                        (box06Val22arr[index] === undefined? ( initialState.box06Val22array[index]? initialState.box06Val22array[index]: arrayList[index].box06_val22 ) : box06Val22arr[index]),
                        (box06Val23arr[index] === undefined? ( initialState.box06Val23array[index]? initialState.box06Val23array[index]: arrayList[index].box06_val23 ) : box06Val23arr[index]),
                        (box06Val24arr[index] === undefined? ( initialState.box06Val24array[index]? initialState.box06Val24array[index]: arrayList[index].box06_val24 ) : box06Val24arr[index]),
                        (box06Val25arr[index] === undefined? ( initialState.box06Val25array[index]? initialState.box06Val25array[index]: arrayList[index].box06_val25 ) : box06Val25arr[index]),
                        (box06Val26arr[index] === undefined? ( initialState.box06Val26array[index]? initialState.box06Val26array[index]: arrayList[index].box06_val26 ) : box06Val26arr[index]),
                        (box06Val27arr[index] === undefined? ( initialState.box06Val27array[index]? initialState.box06Val27array[index]: arrayList[index].box06_val27 ) : box06Val27arr[index]),
                        (box06Val28arr[index] === undefined? ( initialState.box06Val28array[index]? initialState.box06Val28array[index]: arrayList[index].box06_val28 ) : box06Val28arr[index]),
                        (box06Val29arr[index] === undefined? ( initialState.box06Val29array[index]? initialState.box06Val29array[index]: arrayList[index].box06_val29 ) : box06Val29arr[index]),
                        (box06Val30arr[index] === undefined? ( initialState.box06Val30array[index]? initialState.box06Val30array[index]: arrayList[index].box06_val30 ) : box06Val30arr[index]),
                        (box06Val31arr[index] === undefined? ( initialState.box06Val31array[index]? initialState.box06Val31array[index]: arrayList[index].box06_val31 ) : box06Val31arr[index]),
                        (box06Val32arr[index] === undefined? ( initialState.box06Val32array[index]? initialState.box06Val32array[index]: arrayList[index].box06_val32 ) : box06Val32arr[index]),
                        (box06Val33arr[index] === undefined? ( initialState.box06Val33array[index]? initialState.box06Val33array[index]: arrayList[index].box06_val33 ) : box06Val33arr[index]),
                        (box06Val34arr[index] === undefined? ( initialState.box06Val34array[index]? initialState.box06Val34array[index]: arrayList[index].box06_val34 ) : box06Val34arr[index]),
                        (box06Val35arr[index] === undefined? ( initialState.box06Val35array[index]? initialState.box06Val35array[index]: arrayList[index].box06_val35 ) : box06Val35arr[index]),
                        (box06Val36arr[index] === undefined? ( initialState.box06Val36array[index]? initialState.box06Val36array[index]: arrayList[index].box06_val36 ) : box06Val36arr[index]),
                        (box06Val37arr[index] === undefined? ( initialState.box06Val37array[index]? initialState.box06Val37array[index]: arrayList[index].box06_val37 ) : box06Val37arr[index]),
                        (box06Val38arr[index] === undefined? ( initialState.box06Val38array[index]? initialState.box06Val38array[index]: arrayList[index].box06_val38 ) : box06Val38arr[index]),
                        (box06Val39arr[index] === undefined? ( initialState.box06Val39array[index]? initialState.box06Val39array[index]: arrayList[index].box06_val39 ) : box06Val39arr[index]),
                        (box06Val40arr[index] === undefined? ( initialState.box06Val40array[index]? initialState.box06Val40array[index]: arrayList[index].box06_val40 ) : box06Val40arr[index]),
                        (box06Val41arr[index] === undefined? ( initialState.box06Val41array[index]? initialState.box06Val41array[index]: arrayList[index].box06_val41 ) : box06Val41arr[index]),
                        (box06Val42arr[index] === undefined? ( initialState.box06Val42array[index]? initialState.box06Val42array[index]: arrayList[index].box06_val42 ) : box06Val42arr[index]),
                        (box06Val43arr[index] === undefined? ( initialState.box06Val43array[index]? initialState.box06Val43array[index]: arrayList[index].box06_val43 ) : box06Val43arr[index]),
                        (box06Val44arr[index] === undefined? ( initialState.box06Val44array[index]? initialState.box06Val44array[index]: arrayList[index].box06_val44 ) : box06Val44arr[index]),
                        (box06Val45arr[index] === undefined? ( initialState.box06Val45array[index]? initialState.box06Val45array[index]: arrayList[index].box06_val45 ) : box06Val45arr[index]),
                        (box06Val46arr[index] === undefined? ( initialState.box06Val46array[index]? initialState.box06Val46array[index]: arrayList[index].box06_val46 ) : box06Val46arr[index]),
                        (box06Val47arr[index] === undefined? ( initialState.box06Val47array[index]? initialState.box06Val47array[index]: arrayList[index].box06_val47 ) : box06Val47arr[index]),
                        (box06Val48arr[index] === undefined? ( initialState.box06Val48array[index]? initialState.box06Val48array[index]: arrayList[index].box06_val48 ) : box06Val48arr[index]),
                        (box07Val1arr[index] === undefined? ( initialState.box07Val1array[index]? initialState.box07Val1array[index]: arrayList[index].box07_val1 ) : box07Val1arr[index]),
                        (box07Val2arr[index] === undefined? ( initialState.box07Val2array[index]? initialState.box07Val2array[index]: arrayList[index].box07_val2 ) : box07Val2arr[index]),
                        (box07Val3arr[index] === undefined? ( initialState.box07Val3array[index]? initialState.box07Val3array[index]: arrayList[index].box07_val3 ) : box07Val3arr[index]),
                        (box07Val4arr[index] === undefined? ( initialState.box07Val4array[index]? initialState.box07Val4array[index]: arrayList[index].box07_val4 ) : box07Val4arr[index]),
                        (box07Val5arr[index] === undefined? ( initialState.box07Val5array[index]? initialState.box07Val5array[index]: arrayList[index].box07_val5 ) : box07Val5arr[index]),
                        (box07Val6arr[index] === undefined? ( initialState.box07Val6array[index]? initialState.box07Val6array[index]: arrayList[index].box07_val6 ) : box07Val6arr[index]),
                        (box07Val7arr[index] === undefined? ( initialState.box07Val7array[index]? initialState.box07Val7array[index]: arrayList[index].box07_val7 ) : box07Val7arr[index]),
                        (box07Val8arr[index] === undefined? ( initialState.box07Val8array[index]? initialState.box07Val8array[index]: arrayList[index].box07_val8 ) : box07Val8arr[index]),
                        (box07Val9arr[index] === undefined? ( initialState.box07Val9array[index]? initialState.box07Val9array[index]: arrayList[index].box07_val9 ) : box07Val9arr[index]),
                        (box07Val10arr[index] === undefined? ( initialState.box07Val10array[index]? initialState.box07Val10array[index]: arrayList[index].box07_val10 ) : box07Val10arr[index]),
                        (box07Val11arr[index] === undefined? ( initialState.box07Val11array[index]? initialState.box07Val11array[index]: arrayList[index].box07_val11 ) : box07Val11arr[index]),
                        (box07Val12arr[index] === undefined? ( initialState.box07Val12array[index]? initialState.box07Val12array[index]: arrayList[index].box07_val12 ) : box07Val12arr[index]),
                        (box07Val13arr[index] === undefined? ( initialState.box07Val13array[index]? initialState.box07Val13array[index]: arrayList[index].box07_val13 ) : box07Val13arr[index]),
                        (box07Val14arr[index] === undefined? ( initialState.box07Val14array[index]? initialState.box07Val14array[index]: arrayList[index].box07_val14 ) : box07Val14arr[index]),
                        (box07Val15arr[index] === undefined? ( initialState.box07Val15array[index]? initialState.box07Val15array[index]: arrayList[index].box07_val15 ) : box07Val15arr[index]),
                        (box07Val16arr[index] === undefined? ( initialState.box07Val16array[index]? initialState.box07Val16array[index]: arrayList[index].box07_val16 ) : box07Val16arr[index]),
                        (box07Val17arr[index] === undefined? ( initialState.box07Val17array[index]? initialState.box07Val17array[index]: arrayList[index].box07_val17 ) : box07Val17arr[index]),
                        (box07Val18arr[index] === undefined? ( initialState.box07Val18array[index]? initialState.box07Val18array[index]: arrayList[index].box07_val18 ) : box07Val18arr[index]),
                        (box07Val19arr[index] === undefined? ( initialState.box07Val19array[index]? initialState.box07Val19array[index]: arrayList[index].box07_val19 ) : box07Val19arr[index]),
                        (box07Val20arr[index] === undefined? ( initialState.box07Val20array[index]? initialState.box07Val20array[index]: arrayList[index].box07_val20 ) : box07Val20arr[index]),
                        (box07Val21arr[index] === undefined? ( initialState.box07Val21array[index]? initialState.box07Val21array[index]: arrayList[index].box07_val21 ) : box07Val21arr[index]),
                        (box07Val22arr[index] === undefined? ( initialState.box07Val22array[index]? initialState.box07Val22array[index]: arrayList[index].box07_val22 ) : box07Val22arr[index]),
                        (box07Val23arr[index] === undefined? ( initialState.box07Val23array[index]? initialState.box07Val23array[index]: arrayList[index].box07_val23 ) : box07Val23arr[index]),
                        (box07Val24arr[index] === undefined? ( initialState.box07Val24array[index]? initialState.box07Val24array[index]: arrayList[index].box07_val24 ) : box07Val24arr[index]),
                        (box07Val25arr[index] === undefined? ( initialState.box07Val25array[index]? initialState.box07Val25array[index]: arrayList[index].box07_val25 ) : box07Val25arr[index]),
                        (box07Val26arr[index] === undefined? ( initialState.box07Val26array[index]? initialState.box07Val26array[index]: arrayList[index].box07_val26 ) : box07Val26arr[index]),
                        (box07Val27arr[index] === undefined? ( initialState.box07Val27array[index]? initialState.box07Val27array[index]: arrayList[index].box07_val27 ) : box07Val27arr[index]),
                        (box07Val28arr[index] === undefined? ( initialState.box07Val28array[index]? initialState.box07Val28array[index]: arrayList[index].box07_val28 ) : box07Val28arr[index]),
                        (box07Val29arr[index] === undefined? ( initialState.box07Val29array[index]? initialState.box07Val29array[index]: arrayList[index].box07_val29 ) : box07Val29arr[index]),
                        (box07Val30arr[index] === undefined? ( initialState.box07Val30array[index]? initialState.box07Val30array[index]: arrayList[index].box07_val30 ) : box07Val30arr[index]),
                        (box07Val31arr[index] === undefined? ( initialState.box07Val31array[index]? initialState.box07Val31array[index]: arrayList[index].box07_val31 ) : box07Val31arr[index]),
                        (box07Val32arr[index] === undefined? ( initialState.box07Val32array[index]? initialState.box07Val32array[index]: arrayList[index].box07_val32 ) : box07Val32arr[index]),
                        (box07Val33arr[index] === undefined? ( initialState.box07Val33array[index]? initialState.box07Val33array[index]: arrayList[index].box07_val33 ) : box07Val33arr[index]),
                        (box07Val34arr[index] === undefined? ( initialState.box07Val34array[index]? initialState.box07Val34array[index]: arrayList[index].box07_val34 ) : box07Val34arr[index]),
                        (box07Val35arr[index] === undefined? ( initialState.box07Val35array[index]? initialState.box07Val35array[index]: arrayList[index].box07_val35 ) : box07Val35arr[index]),
                        (box07Val36arr[index] === undefined? ( initialState.box07Val36array[index]? initialState.box07Val36array[index]: arrayList[index].box07_val36 ) : box07Val36arr[index]),
                        (box07Val37arr[index] === undefined? ( initialState.box07Val37array[index]? initialState.box07Val37array[index]: arrayList[index].box07_val37 ) : box07Val37arr[index]),
                        (box07Val38arr[index] === undefined? ( initialState.box07Val38array[index]? initialState.box07Val38array[index]: arrayList[index].box07_val38 ) : box07Val38arr[index]),
                        (box07Val39arr[index] === undefined? ( initialState.box07Val39array[index]? initialState.box07Val39array[index]: arrayList[index].box07_val39 ) : box07Val39arr[index]),
                        (box07Val40arr[index] === undefined? ( initialState.box07Val40array[index]? initialState.box07Val40array[index]: arrayList[index].box07_val40 ) : box07Val40arr[index]),
                        (box07Val41arr[index] === undefined? ( initialState.box07Val41array[index]? initialState.box07Val41array[index]: arrayList[index].box07_val41 ) : box07Val41arr[index]),
                        (box07Val42arr[index] === undefined? ( initialState.box07Val42array[index]? initialState.box07Val42array[index]: arrayList[index].box07_val42 ) : box07Val42arr[index]),
                        (box07Val43arr[index] === undefined? ( initialState.box07Val43array[index]? initialState.box07Val43array[index]: arrayList[index].box07_val43 ) : box07Val43arr[index]),
                        (box07Val44arr[index] === undefined? ( initialState.box07Val44array[index]? initialState.box07Val44array[index]: arrayList[index].box07_val44 ) : box07Val44arr[index]),
                        (box07Val45arr[index] === undefined? ( initialState.box07Val45array[index]? initialState.box07Val45array[index]: arrayList[index].box07_val45 ) : box07Val45arr[index]),
                        (box07Val46arr[index] === undefined? ( initialState.box07Val46array[index]? initialState.box07Val46array[index]: arrayList[index].box07_val46 ) : box07Val46arr[index]),
                        (box07Val47arr[index] === undefined? ( initialState.box07Val47array[index]? initialState.box07Val47array[index]: arrayList[index].box07_val47 ) : box07Val47arr[index]),
                        (box07Val48arr[index] === undefined? ( initialState.box07Val48array[index]? initialState.box07Val48array[index]: arrayList[index].box07_val48 ) : box07Val48arr[index]),
                        (box08Val1arr[index] === undefined? ( initialState.box08Val1array[index]? initialState.box08Val1array[index]: arrayList[index].box08_val1 ) : box08Val1arr[index]),
                        (box08Val2arr[index] === undefined? ( initialState.box08Val2array[index]? initialState.box08Val2array[index]: arrayList[index].box08_val2 ) : box08Val2arr[index]),
                        (box08Val3arr[index] === undefined? ( initialState.box08Val3array[index]? initialState.box08Val3array[index]: arrayList[index].box08_val3 ) : box08Val3arr[index]),
                        (box08Val4arr[index] === undefined? ( initialState.box08Val4array[index]? initialState.box08Val4array[index]: arrayList[index].box08_val4 ) : box08Val4arr[index]),
                        (box08Val5arr[index] === undefined? ( initialState.box08Val5array[index]? initialState.box08Val5array[index]: arrayList[index].box08_val5 ) : box08Val5arr[index]),
                        (box08Val6arr[index] === undefined? ( initialState.box08Val6array[index]? initialState.box08Val6array[index]: arrayList[index].box08_val6 ) : box08Val6arr[index]),
                        (box08Val7arr[index] === undefined? ( initialState.box08Val7array[index]? initialState.box08Val7array[index]: arrayList[index].box08_val7 ) : box08Val7arr[index]),
                        (box09Val1arr[index] === undefined? ( initialState.box09Val1array[index]? initialState.box09Val1array[index]: arrayList[index].box09_val1 ) : box09Val1arr[index]),
                        (box09Val2arr[index] === undefined? ( initialState.box09Val2array[index]? initialState.box09Val2array[index]: arrayList[index].box09_val2 ) : box09Val2arr[index]),
                        (box09Val3arr[index] === undefined? ( initialState.box09Val3array[index]? initialState.box09Val3array[index]: arrayList[index].box09_val3 ) : box09Val3arr[index]),
                        (box09Val4arr[index] === undefined? ( initialState.box09Val4array[index]? initialState.box09Val4array[index]: arrayList[index].box09_val4 ) : box09Val4arr[index]),
                        (box09Val5arr[index] === undefined? ( initialState.box09Val5array[index]? initialState.box09Val5array[index]: arrayList[index].box09_val5 ) : box09Val5arr[index]),
                        (box09Val6arr[index] === undefined? ( initialState.box09Val6array[index]? initialState.box09Val6array[index]: arrayList[index].box09_val6 ) : box09Val6arr[index]),
                        (box09Val7arr[index] === undefined? ( initialState.box09Val7array[index]? initialState.box09Val7array[index]: arrayList[index].box09_val7 ) : box09Val7arr[index]),
                        (box10Val1arr[index] === undefined? ( initialState.box10Val1array[index]? initialState.box10Val1array[index]: arrayList[index].box10_val1 ) : box10Val1arr[index]),
                        (box10Val2arr[index] === undefined? ( initialState.box10Val2array[index]? initialState.box10Val2array[index]: arrayList[index].box10_val2 ) : box10Val2arr[index]),
                        (box10Val3arr[index] === undefined? ( initialState.box10Val3array[index]? initialState.box10Val3array[index]: arrayList[index].box10_val3 ) : box10Val3arr[index]),
                        (box10Val4arr[index] === undefined? ( initialState.box10Val4array[index]? initialState.box10Val4array[index]: arrayList[index].box10_val4 ) : box10Val4arr[index]),
                        (box10Val5arr[index] === undefined? ( initialState.box10Val5array[index]? initialState.box10Val5array[index]: arrayList[index].box10_val5 ) : box10Val5arr[index]),
                        (box10Val6arr[index] === undefined? ( initialState.box10Val6array[index]? initialState.box10Val6array[index]: arrayList[index].box10_val6 ) : box10Val6arr[index]),
                        (box10Val7arr[index] === undefined? ( initialState.box10Val7array[index]? initialState.box10Val7array[index]: arrayList[index].box10_val7 ) : box10Val7arr[index]),
                        (box11Val1arr[index] === undefined? ( initialState.box11Val1array[index]? initialState.box11Val1array[index]: arrayList[index].box11_val1 ) : box11Val1arr[index]),
                        (box11Val2arr[index] === undefined? ( initialState.box11Val2array[index]? initialState.box11Val2array[index]: arrayList[index].box11_val2 ) : box11Val2arr[index]),
                        (box11Val3arr[index] === undefined? ( initialState.box11Val3array[index]? initialState.box11Val3array[index]: arrayList[index].box11_val3 ) : box11Val3arr[index]),
                        (box11Val4arr[index] === undefined? ( initialState.box11Val4array[index]? initialState.box11Val4array[index]: arrayList[index].box11_val4 ) : box11Val4arr[index]),
                        (box11Val5arr[index] === undefined? ( initialState.box11Val5array[index]? initialState.box11Val5array[index]: arrayList[index].box11_val5 ) : box11Val5arr[index]),
                        (box11Val6arr[index] === undefined? ( initialState.box11Val6array[index]? initialState.box11Val6array[index]: arrayList[index].box11_val6 ) : box11Val6arr[index]),
                        (box11Val7arr[index] === undefined? ( initialState.box11Val7array[index]? initialState.box11Val7array[index]: arrayList[index].box11_val7 ) : box11Val7arr[index]),
                        (box12Val1arr[index] === undefined? ( initialState.box12Val1array[index]? initialState.box12Val1array[index]: arrayList[index].box12_val1 ) : box12Val1arr[index]),
                        (box12Val2arr[index] === undefined? ( initialState.box12Val2array[index]? initialState.box12Val2array[index]: arrayList[index].box12_val2 ) : box12Val2arr[index]),
                        (box12Val3arr[index] === undefined? ( initialState.box12Val3array[index]? initialState.box12Val3array[index]: arrayList[index].box12_val3 ) : box12Val3arr[index]),
                        (box12Val4arr[index] === undefined? ( initialState.box12Val4array[index]? initialState.box12Val4array[index]: arrayList[index].box12_val4 ) : box12Val4arr[index]),
                        (box12Val5arr[index] === undefined? ( initialState.box12Val5array[index]? initialState.box12Val5array[index]: arrayList[index].box12_val5 ) : box12Val5arr[index]),
                        (box12Val6arr[index] === undefined? ( initialState.box12Val6array[index]? initialState.box12Val6array[index]: arrayList[index].box12_val6 ) : box12Val6arr[index]),
                        (box12Val7arr[index] === undefined? ( initialState.box12Val7array[index]? initialState.box12Val7array[index]: arrayList[index].box12_val7 ) : box12Val7arr[index]),
                        (box13Val1arr[index] === undefined? ( initialState.box13Val1array[index]? initialState.box13Val1array[index]: arrayList[index].box13_val1 ) : box13Val1arr[index]),
                        (box13Val2arr[index] === undefined? ( initialState.box13Val2array[index]? initialState.box13Val2array[index]: arrayList[index].box13_val2 ) : box13Val2arr[index]),
                        (box13Val3arr[index] === undefined? ( initialState.box13Val3array[index]? initialState.box13Val3array[index]: arrayList[index].box13_val3 ) : box13Val3arr[index]),
                        (box13Val4arr[index] === undefined? ( initialState.box13Val4array[index]? initialState.box13Val4array[index]: arrayList[index].box13_val4 ) : box13Val4arr[index]),
                        (box13Val5arr[index] === undefined? ( initialState.box13Val5array[index]? initialState.box13Val5array[index]: arrayList[index].box13_val5 ) : box13Val5arr[index]),
                        (box13Val6arr[index] === undefined? ( initialState.box13Val6array[index]? initialState.box13Val6array[index]: arrayList[index].box13_val6 ) : box13Val6arr[index]),
                        (box13Val7arr[index] === undefined? ( initialState.box13Val7array[index]? initialState.box13Val7array[index]: arrayList[index].box13_val7 ) : box13Val7arr[index]),
                        (box14Val1arr[index] === undefined? ( initialState.box14Val1array[index]? initialState.box14Val1array[index]: arrayList[index].box14_val1 ) : box14Val1arr[index]),
                        (box14Val2arr[index] === undefined? ( initialState.box14Val2array[index]? initialState.box14Val2array[index]: arrayList[index].box14_val2 ) : box14Val2arr[index]),
                        (box14Val3arr[index] === undefined? ( initialState.box14Val3array[index]? initialState.box14Val3array[index]: arrayList[index].box14_val3 ) : box14Val3arr[index]),
                        (box14Val4arr[index] === undefined? ( initialState.box14Val4array[index]? initialState.box14Val4array[index]: arrayList[index].box14_val4 ) : box14Val4arr[index])

                    );
                    //setArrayList(box01Val1arr);
                    //alert("ififi :: "+i);
                }

            }
        }
        else
        {
            //alert("단건복사 ::: ");
            for ( let i = 0; i <= endCnt; i++)
            {
                if( i = endCnt )
                {
                   //alert("복사완료??");
                    // alert("01.box01Val1arr[index] :: "+box01Val1arr[index] );
                    // alert("02.initialState.box01Val1array :: "+initialState.box01Val1array );
                    // alert("03.arrayList[index].box01_val1 :: "+arrayList[index].box01_val1 );
                    // alert("04.box01Val1arr :: "+JSON.stringify(box01Val1arr) );
                    // alert("CJWbox01Val1arr>>> :: "+JSON.stringify(box01Val1arr[index]));
                    //
                    // alert(  (box01Val1arr[index] ===  undefined? "if"  : "else"));
                    // alert(  "initialState.box01Val1array  : "+initialState.box01Val1array );
                    // alert( "arrayList[index2].box01_val1 ::: "+arrayList[index].box01_val1 );

                    setArrayList(
                        index,
                        endCnt+1,
                        (box01Val1arr[index] === undefined? ( initialState.box01Val1array[index]? initialState.box01Val1array[index]: arrayList[index].box01_val1 ) : box01Val1arr[index]),
                        (box01Val2arr[index] === undefined? ( initialState.box01Val2array[index]? initialState.box01Val2array[index]: arrayList[index].box01_val2 ) : box01Val2arr[index]),
                        (box01Val3arr[index] === undefined? ( initialState.box01Val3array[index]? initialState.box01Val3array[index]: arrayList[index].box01_val3 ) : box01Val3arr[index]),
                        (box01Val4arr[index] === undefined? ( initialState.box01Val4array[index]? initialState.box01Val4array[index]: arrayList[index].box01_val4 ) : box01Val4arr[index]),
                        (box01Val5arr[index] === undefined? ( initialState.box01Val5array[index]? initialState.box01Val5array[index]: arrayList[index].box01_val5 ) : box01Val5arr[index]),
                        (box01Val6arr[index] === undefined? ( initialState.box01Val6array[index]? initialState.box01Val6array[index]: arrayList[index].box01_val6 ) : box01Val6arr[index]),
                        (box01Val7arr[index] === undefined? ( initialState.box01Val7array[index]? initialState.box01Val7array[index]: arrayList[index].box01_val7 ) : box01Val7arr[index]),
                        (box02Val1arr[index] === undefined? ( initialState.box02Val1array[index]? initialState.box02Val1array[index]: arrayList[index].box02_val1 ) : box02Val1arr[index]),
                        (box02Val2arr[index] === undefined? ( initialState.box02Val2array[index]? initialState.box02Val2array[index]: arrayList[index].box02_val2 ) : box02Val2arr[index]),
                        (box02Val3arr[index] === undefined? ( initialState.box02Val3array[index]? initialState.box02Val3array[index]: arrayList[index].box02_val3 ) : box02Val3arr[index]),
                        (box02Val4arr[index] === undefined? ( initialState.box02Val4array[index]? initialState.box02Val4array[index]: arrayList[index].box02_val4 ) : box02Val4arr[index]),
                        (box02Val5arr[index] === undefined? ( initialState.box02Val5array[index]? initialState.box02Val5array[index]: arrayList[index].box02_val5 ) : box02Val5arr[index]),
                        (box02Val6arr[index] === undefined? ( initialState.box02Val6array[index]? initialState.box02Val6array[index]: arrayList[index].box02_val6 ) : box02Val6arr[index]),
                        (box02Val7arr[index] === undefined? ( initialState.box02Val7array[index]? initialState.box02Val7array[index]: arrayList[index].box02_val7 ) : box02Val7arr[index]),
                        (box02Val8arr[index] === undefined? ( initialState.box02Val8array[index]? initialState.box02Val8array[index]: arrayList[index].box02_val8 ) : box02Val8arr[index]),
                        (box02Val9arr[index] === undefined? ( initialState.box02Val9array[index]? initialState.box02Val9array[index]: arrayList[index].box02_val9 ) : box02Val9arr[index]),
                        (box02Val10arr[index] === undefined? ( initialState.box02Val10array[index]? initialState.box02Val10array[index]: arrayList[index].box02_val10 ) : box02Val10arr[index]),
                        (box02Val11arr[index] === undefined? ( initialState.box02Val11array[index]? initialState.box02Val11array[index]: arrayList[index].box02_val11 ) : box02Val11arr[index]),
                        (box02Val12arr[index] === undefined? ( initialState.box02Val12array[index]? initialState.box02Val12array[index]: arrayList[index].box02_val12 ) : box02Val12arr[index]),
                        (box02Val13arr[index] === undefined? ( initialState.box02Val13array[index]? initialState.box02Val13array[index]: arrayList[index].box02_val13 ) : box02Val13arr[index]),
                        (box03Val1arr[index] === undefined? ( initialState.box03Val1array[index]? initialState.box03Val1array[index]: arrayList[index].box03_val1 ) : box03Val1arr[index]),
                        (box03Val2arr[index] === undefined? ( initialState.box03Val2array[index]? initialState.box03Val2array[index]: arrayList[index].box03_val2 ) : box03Val2arr[index]),
                        (box03Val3arr[index] === undefined? ( initialState.box03Val3array[index]? initialState.box03Val3array[index]: arrayList[index].box03_val3 ) : box03Val3arr[index]),
                        (box03Val4arr[index] === undefined? ( initialState.box03Val4array[index]? initialState.box03Val4array[index]: arrayList[index].box03_val4 ) : box03Val4arr[index]),
                        (box03Val5arr[index] === undefined? ( initialState.box03Val5array[index]? initialState.box03Val5array[index]: arrayList[index].box03_val5 ) : box03Val5arr[index]),
                        (box03Val6arr[index] === undefined? ( initialState.box03Val6array[index]? initialState.box03Val6array[index]: arrayList[index].box03_val6 ) : box03Val6arr[index]),
                        (box03Val7arr[index] === undefined? ( initialState.box03Val7array[index]? initialState.box03Val7array[index]: arrayList[index].box03_val7 ) : box03Val7arr[index]),
                        (box03Val8arr[index] === undefined? ( initialState.box03Val8array[index]? initialState.box03Val8array[index]: arrayList[index].box03_val8 ) : box03Val8arr[index]),
                        (box03Val9arr[index] === undefined? ( initialState.box03Val9array[index]? initialState.box03Val9array[index]: arrayList[index].box03_val9 ) : box03Val9arr[index]),
                        (box03Val10arr[index] === undefined? ( initialState.box03Val10array[index]? initialState.box03Val10array[index]: arrayList[index].box03_val10 ) : box03Val10arr[index]),
                        (box04Val1arr[index] === undefined? ( initialState.box04Val1array[index]? initialState.box04Val1array[index]: arrayList[index].box04_val1 ) : box04Val1arr[index]),
                        (box04Val2arr[index] === undefined? ( initialState.box04Val2array[index]? initialState.box04Val2array[index]: arrayList[index].box04_val2 ) : box04Val2arr[index]),
                        (box04Val3arr[index] === undefined? ( initialState.box04Val3array[index]? initialState.box04Val3array[index]: arrayList[index].box04_val3 ) : box04Val3arr[index]),
                        (box04Val4arr[index] === undefined? ( initialState.box04Val4array[index]? initialState.box04Val4array[index]: arrayList[index].box04_val4 ) : box04Val4arr[index]),
                        (box04Val5arr[index] === undefined? ( initialState.box04Val5array[index]? initialState.box04Val5array[index]: arrayList[index].box04_val5 ) : box04Val5arr[index]),
                        (box04Val6arr[index] === undefined? ( initialState.box04Val6array[index]? initialState.box04Val6array[index]: arrayList[index].box04_val6 ) : box04Val6arr[index]),
                        (box04Val7arr[index] === undefined? ( initialState.box04Val7array[index]? initialState.box04Val7array[index]: arrayList[index].box04_val7 ) : box04Val7arr[index]),
                        (box04Val8arr[index] === undefined? ( initialState.box04Val8array[index]? initialState.box04Val8array[index]: arrayList[index].box04_val8 ) : box04Val8arr[index]),
                        (box04Val9arr[index] === undefined? ( initialState.box04Val9array[index]? initialState.box04Val9array[index]: arrayList[index].box04_val9 ) : box04Val9arr[index]),
                        (box04Val10arr[index] === undefined? ( initialState.box04Val10array[index]? initialState.box04Val10array[index]: arrayList[index].box04_val10 ) : box04Val10arr[index]),
                        (box04Val11arr[index] === undefined? ( initialState.box04Val11array[index]? initialState.box04Val11array[index]: arrayList[index].box04_val11 ) : box04Val11arr[index]),
                        (box04Val12arr[index] === undefined? ( initialState.box04Val12array[index]? initialState.box04Val12array[index]: arrayList[index].box04_val12 ) : box04Val12arr[index]),
                        (box04Val13arr[index] === undefined? ( initialState.box04Val13array[index]? initialState.box04Val13array[index]: arrayList[index].box04_val13 ) : box04Val13arr[index]),
                        (box04Val14arr[index] === undefined? ( initialState.box04Val14array[index]? initialState.box04Val14array[index]: arrayList[index].box04_val14 ) : box04Val14arr[index]),
                        (box04Val15arr[index] === undefined? ( initialState.box04Val15array[index]? initialState.box04Val15array[index]: arrayList[index].box04_val15 ) : box04Val15arr[index]),
                        (box04Val16arr[index] === undefined? ( initialState.box04Val16array[index]? initialState.box04Val16array[index]: arrayList[index].box04_val16 ) : box04Val16arr[index]),
                        (box04Val17arr[index] === undefined? ( initialState.box04Val17array[index]? initialState.box04Val17array[index]: arrayList[index].box04_val17 ) : box04Val17arr[index]),
                        (box04Val18arr[index] === undefined? ( initialState.box04Val18array[index]? initialState.box04Val18array[index]: arrayList[index].box04_val18 ) : box04Val18arr[index]),
                        (box04Val19arr[index] === undefined? ( initialState.box04Val19array[index]? initialState.box04Val19array[index]: arrayList[index].box04_val19 ) : box04Val19arr[index]),
                        (box04Val20arr[index] === undefined? ( initialState.box04Val20array[index]? initialState.box04Val20array[index]: arrayList[index].box04_val20 ) : box04Val20arr[index]),
                        (box04Val21arr[index] === undefined? ( initialState.box04Val21array[index]? initialState.box04Val21array[index]: arrayList[index].box04_val21 ) : box04Val21arr[index]),
                        (box04Val22arr[index] === undefined? ( initialState.box04Val22array[index]? initialState.box04Val22array[index]: arrayList[index].box04_val22 ) : box04Val22arr[index]),
                        (box04Val23arr[index] === undefined? ( initialState.box04Val23array[index]? initialState.box04Val23array[index]: arrayList[index].box04_val23 ) : box04Val23arr[index]),
                        (box04Val24arr[index] === undefined? ( initialState.box04Val24array[index]? initialState.box04Val24array[index]: arrayList[index].box04_val24 ) : box04Val24arr[index]),
                        (box04Val25arr[index] === undefined? ( initialState.box04Val25array[index]? initialState.box04Val25array[index]: arrayList[index].box04_val25 ) : box04Val25arr[index]),
                        (box04Val26arr[index] === undefined? ( initialState.box04Val26array[index]? initialState.box04Val26array[index]: arrayList[index].box04_val26 ) : box04Val26arr[index]),
                        (box04Val27arr[index] === undefined? ( initialState.box04Val27array[index]? initialState.box04Val27array[index]: arrayList[index].box04_val27 ) : box04Val27arr[index]),
                        (box04Val28arr[index] === undefined? ( initialState.box04Val28array[index]? initialState.box04Val28array[index]: arrayList[index].box04_val28 ) : box04Val28arr[index]),
                        (box04Val29arr[index] === undefined? ( initialState.box04Val29array[index]? initialState.box04Val29array[index]: arrayList[index].box04_val29 ) : box04Val29arr[index]),
                        (box04Val30arr[index] === undefined? ( initialState.box04Val30array[index]? initialState.box04Val30array[index]: arrayList[index].box04_val30 ) : box04Val30arr[index]),
                        (box04Val31arr[index] === undefined? ( initialState.box04Val31array[index]? initialState.box04Val31array[index]: arrayList[index].box04_val31 ) : box04Val31arr[index]),
                        (box04Val32arr[index] === undefined? ( initialState.box04Val32array[index]? initialState.box04Val32array[index]: arrayList[index].box04_val32 ) : box04Val32arr[index]),
                        (box04Val33arr[index] === undefined? ( initialState.box04Val33array[index]? initialState.box04Val33array[index]: arrayList[index].box04_val33 ) : box04Val33arr[index]),
                        (box04Val34arr[index] === undefined? ( initialState.box04Val34array[index]? initialState.box04Val34array[index]: arrayList[index].box04_val34 ) : box04Val34arr[index]),
                        (box04Val35arr[index] === undefined? ( initialState.box04Val35array[index]? initialState.box04Val35array[index]: arrayList[index].box04_val35 ) : box04Val35arr[index]),
                        (box04Val36arr[index] === undefined? ( initialState.box04Val36array[index]? initialState.box04Val36array[index]: arrayList[index].box04_val36 ) : box04Val36arr[index]),
                        (box04Val37arr[index] === undefined? ( initialState.box04Val37array[index]? initialState.box04Val37array[index]: arrayList[index].box04_val37 ) : box04Val37arr[index]),
                        (box04Val38arr[index] === undefined? ( initialState.box04Val38array[index]? initialState.box04Val38array[index]: arrayList[index].box04_val38 ) : box04Val38arr[index]),
                        (box04Val39arr[index] === undefined? ( initialState.box04Val39array[index]? initialState.box04Val39array[index]: arrayList[index].box04_val39 ) : box04Val39arr[index]),
                        (box04Val40arr[index] === undefined? ( initialState.box04Val40array[index]? initialState.box04Val40array[index]: arrayList[index].box04_val40 ) : box04Val40arr[index]),
                        (box04Val41arr[index] === undefined? ( initialState.box04Val41array[index]? initialState.box04Val41array[index]: arrayList[index].box04_val41 ) : box04Val41arr[index]),
                        (box04Val42arr[index] === undefined? ( initialState.box04Val42array[index]? initialState.box04Val42array[index]: arrayList[index].box04_val42 ) : box04Val42arr[index]),
                        (box04Val43arr[index] === undefined? ( initialState.box04Val43array[index]? initialState.box04Val43array[index]: arrayList[index].box04_val43 ) : box04Val43arr[index]),
                        (box04Val44arr[index] === undefined? ( initialState.box04Val44array[index]? initialState.box04Val44array[index]: arrayList[index].box04_val44 ) : box04Val44arr[index]),
                        (box04Val45arr[index] === undefined? ( initialState.box04Val45array[index]? initialState.box04Val45array[index]: arrayList[index].box04_val45 ) : box04Val45arr[index]),
                        (box04Val46arr[index] === undefined? ( initialState.box04Val46array[index]? initialState.box04Val46array[index]: arrayList[index].box04_val46 ) : box04Val46arr[index]),
                        (box04Val47arr[index] === undefined? ( initialState.box04Val47array[index]? initialState.box04Val47array[index]: arrayList[index].box04_val47 ) : box04Val47arr[index]),
                        (box04Val48arr[index] === undefined? ( initialState.box04Val48array[index]? initialState.box04Val48array[index]: arrayList[index].box04_val48 ) : box04Val48arr[index]),
                        (box05Val1arr[index] === undefined? ( initialState.box05Val1array[index]? initialState.box05Val1array[index]: arrayList[index].box05_val1 ) : box05Val1arr[index]),
                        (box05Val2arr[index] === undefined? ( initialState.box05Val2array[index]? initialState.box05Val2array[index]: arrayList[index].box05_val2 ) : box05Val2arr[index]),
                        (box05Val3arr[index] === undefined? ( initialState.box05Val3array[index]? initialState.box05Val3array[index]: arrayList[index].box05_val3 ) : box05Val3arr[index]),
                        (box05Val4arr[index] === undefined? ( initialState.box05Val4array[index]? initialState.box05Val4array[index]: arrayList[index].box05_val4 ) : box05Val4arr[index]),
                        (box05Val5arr[index] === undefined? ( initialState.box05Val5array[index]? initialState.box05Val5array[index]: arrayList[index].box05_val5 ) : box05Val5arr[index]),
                        (box05Val6arr[index] === undefined? ( initialState.box05Val6array[index]? initialState.box05Val6array[index]: arrayList[index].box05_val6 ) : box05Val6arr[index]),
                        (box05Val7arr[index] === undefined? ( initialState.box05Val7array[index]? initialState.box05Val7array[index]: arrayList[index].box05_val7 ) : box05Val7arr[index]),
                        (box05Val8arr[index] === undefined? ( initialState.box05Val8array[index]? initialState.box05Val8array[index]: arrayList[index].box05_val8 ) : box05Val8arr[index]),
                        (box05Val9arr[index] === undefined? ( initialState.box05Val9array[index]? initialState.box05Val9array[index]: arrayList[index].box05_val9 ) : box05Val9arr[index]),
                        (box05Val10arr[index] === undefined? ( initialState.box05Val10array[index]? initialState.box05Val10array[index]: arrayList[index].box05_val10 ) : box05Val10arr[index]),
                        (box05Val11arr[index] === undefined? ( initialState.box05Val11array[index]? initialState.box05Val11array[index]: arrayList[index].box05_val11 ) : box05Val11arr[index]),
                        (box05Val12arr[index] === undefined? ( initialState.box05Val12array[index]? initialState.box05Val12array[index]: arrayList[index].box05_val12 ) : box05Val12arr[index]),
                        (box05Val13arr[index] === undefined? ( initialState.box05Val13array[index]? initialState.box05Val13array[index]: arrayList[index].box05_val13 ) : box05Val13arr[index]),
                        (box05Val14arr[index] === undefined? ( initialState.box05Val14array[index]? initialState.box05Val14array[index]: arrayList[index].box05_val14 ) : box05Val14arr[index]),
                        (box05Val15arr[index] === undefined? ( initialState.box05Val15array[index]? initialState.box05Val15array[index]: arrayList[index].box05_val15 ) : box05Val15arr[index]),
                        (box05Val16arr[index] === undefined? ( initialState.box05Val16array[index]? initialState.box05Val16array[index]: arrayList[index].box05_val16 ) : box05Val16arr[index]),
                        (box05Val17arr[index] === undefined? ( initialState.box05Val17array[index]? initialState.box05Val17array[index]: arrayList[index].box05_val17 ) : box05Val17arr[index]),
                        (box05Val18arr[index] === undefined? ( initialState.box05Val18array[index]? initialState.box05Val18array[index]: arrayList[index].box05_val18 ) : box05Val18arr[index]),
                        (box05Val19arr[index] === undefined? ( initialState.box05Val19array[index]? initialState.box05Val19array[index]: arrayList[index].box05_val19 ) : box05Val19arr[index]),
                        (box05Val20arr[index] === undefined? ( initialState.box05Val20array[index]? initialState.box05Val20array[index]: arrayList[index].box05_val20 ) : box05Val20arr[index]),
                        (box05Val21arr[index] === undefined? ( initialState.box05Val21array[index]? initialState.box05Val21array[index]: arrayList[index].box05_val21 ) : box05Val21arr[index]),
                        (box05Val22arr[index] === undefined? ( initialState.box05Val22array[index]? initialState.box05Val22array[index]: arrayList[index].box05_val22 ) : box05Val22arr[index]),
                        (box05Val23arr[index] === undefined? ( initialState.box05Val23array[index]? initialState.box05Val23array[index]: arrayList[index].box05_val23 ) : box05Val23arr[index]),
                        (box05Val24arr[index] === undefined? ( initialState.box05Val24array[index]? initialState.box05Val24array[index]: arrayList[index].box05_val24 ) : box05Val24arr[index]),
                        (box05Val25arr[index] === undefined? ( initialState.box05Val25array[index]? initialState.box05Val25array[index]: arrayList[index].box05_val25 ) : box05Val25arr[index]),
                        (box05Val26arr[index] === undefined? ( initialState.box05Val26array[index]? initialState.box05Val26array[index]: arrayList[index].box05_val26 ) : box05Val26arr[index]),
                        (box05Val27arr[index] === undefined? ( initialState.box05Val27array[index]? initialState.box05Val27array[index]: arrayList[index].box05_val27 ) : box05Val27arr[index]),
                        (box05Val28arr[index] === undefined? ( initialState.box05Val28array[index]? initialState.box05Val28array[index]: arrayList[index].box05_val28 ) : box05Val28arr[index]),
                        (box05Val29arr[index] === undefined? ( initialState.box05Val29array[index]? initialState.box05Val29array[index]: arrayList[index].box05_val29 ) : box05Val29arr[index]),
                        (box05Val30arr[index] === undefined? ( initialState.box05Val30array[index]? initialState.box05Val30array[index]: arrayList[index].box05_val30 ) : box05Val30arr[index]),
                        (box05Val31arr[index] === undefined? ( initialState.box05Val31array[index]? initialState.box05Val31array[index]: arrayList[index].box05_val31 ) : box05Val31arr[index]),
                        (box05Val32arr[index] === undefined? ( initialState.box05Val32array[index]? initialState.box05Val32array[index]: arrayList[index].box05_val32 ) : box05Val32arr[index]),
                        (box05Val33arr[index] === undefined? ( initialState.box05Val33array[index]? initialState.box05Val33array[index]: arrayList[index].box05_val33 ) : box05Val33arr[index]),
                        (box05Val34arr[index] === undefined? ( initialState.box05Val34array[index]? initialState.box05Val34array[index]: arrayList[index].box05_val34 ) : box05Val34arr[index]),
                        (box05Val35arr[index] === undefined? ( initialState.box05Val35array[index]? initialState.box05Val35array[index]: arrayList[index].box05_val35 ) : box05Val35arr[index]),
                        (box05Val36arr[index] === undefined? ( initialState.box05Val36array[index]? initialState.box05Val36array[index]: arrayList[index].box05_val36 ) : box05Val36arr[index]),
                        (box05Val37arr[index] === undefined? ( initialState.box05Val37array[index]? initialState.box05Val37array[index]: arrayList[index].box05_val37 ) : box05Val37arr[index]),
                        (box05Val38arr[index] === undefined? ( initialState.box05Val38array[index]? initialState.box05Val38array[index]: arrayList[index].box05_val38 ) : box05Val38arr[index]),
                        (box05Val39arr[index] === undefined? ( initialState.box05Val39array[index]? initialState.box05Val39array[index]: arrayList[index].box05_val39 ) : box05Val39arr[index]),
                        (box05Val40arr[index] === undefined? ( initialState.box05Val40array[index]? initialState.box05Val40array[index]: arrayList[index].box05_val40 ) : box05Val40arr[index]),
                        (box05Val41arr[index] === undefined? ( initialState.box05Val41array[index]? initialState.box05Val41array[index]: arrayList[index].box05_val41 ) : box05Val41arr[index]),
                        (box05Val42arr[index] === undefined? ( initialState.box05Val42array[index]? initialState.box05Val42array[index]: arrayList[index].box05_val42 ) : box05Val42arr[index]),
                        (box05Val43arr[index] === undefined? ( initialState.box05Val43array[index]? initialState.box05Val43array[index]: arrayList[index].box05_val43 ) : box05Val43arr[index]),
                        (box05Val44arr[index] === undefined? ( initialState.box05Val44array[index]? initialState.box05Val44array[index]: arrayList[index].box05_val44 ) : box05Val44arr[index]),
                        (box05Val45arr[index] === undefined? ( initialState.box05Val45array[index]? initialState.box05Val45array[index]: arrayList[index].box05_val45 ) : box05Val45arr[index]),
                        (box05Val46arr[index] === undefined? ( initialState.box05Val46array[index]? initialState.box05Val46array[index]: arrayList[index].box05_val46 ) : box05Val46arr[index]),
                        (box05Val47arr[index] === undefined? ( initialState.box05Val47array[index]? initialState.box05Val47array[index]: arrayList[index].box05_val47 ) : box05Val47arr[index]),
                        (box05Val48arr[index] === undefined? ( initialState.box05Val48array[index]? initialState.box05Val48array[index]: arrayList[index].box05_val48 ) : box05Val48arr[index]),
                        (box06Val1arr[index] === undefined? ( initialState.box06Val1array[index]? initialState.box06Val1array[index]: arrayList[index].box06_val1 ) : box06Val1arr[index]),
                        (box06Val2arr[index] === undefined? ( initialState.box06Val2array[index]? initialState.box06Val2array[index]: arrayList[index].box06_val2 ) : box06Val2arr[index]),
                        (box06Val3arr[index] === undefined? ( initialState.box06Val3array[index]? initialState.box06Val3array[index]: arrayList[index].box06_val3 ) : box06Val3arr[index]),
                        (box06Val4arr[index] === undefined? ( initialState.box06Val4array[index]? initialState.box06Val4array[index]: arrayList[index].box06_val4 ) : box06Val4arr[index]),
                        (box06Val5arr[index] === undefined? ( initialState.box06Val5array[index]? initialState.box06Val5array[index]: arrayList[index].box06_val5 ) : box06Val5arr[index]),
                        (box06Val6arr[index] === undefined? ( initialState.box06Val6array[index]? initialState.box06Val6array[index]: arrayList[index].box06_val6 ) : box06Val6arr[index]),
                        (box06Val7arr[index] === undefined? ( initialState.box06Val7array[index]? initialState.box06Val7array[index]: arrayList[index].box06_val7 ) : box06Val7arr[index]),
                        (box06Val8arr[index] === undefined? ( initialState.box06Val8array[index]? initialState.box06Val8array[index]: arrayList[index].box06_val8 ) : box06Val8arr[index]),
                        (box06Val9arr[index] === undefined? ( initialState.box06Val9array[index]? initialState.box06Val9array[index]: arrayList[index].box06_val9 ) : box06Val9arr[index]),
                        (box06Val10arr[index] === undefined? ( initialState.box06Val10array[index]? initialState.box06Val10array[index]: arrayList[index].box06_val10 ) : box06Val10arr[index]),
                        (box06Val11arr[index] === undefined? ( initialState.box06Val11array[index]? initialState.box06Val11array[index]: arrayList[index].box06_val11 ) : box06Val11arr[index]),
                        (box06Val12arr[index] === undefined? ( initialState.box06Val12array[index]? initialState.box06Val12array[index]: arrayList[index].box06_val12 ) : box06Val12arr[index]),
                        (box06Val13arr[index] === undefined? ( initialState.box06Val13array[index]? initialState.box06Val13array[index]: arrayList[index].box06_val13 ) : box06Val13arr[index]),
                        (box06Val14arr[index] === undefined? ( initialState.box06Val14array[index]? initialState.box06Val14array[index]: arrayList[index].box06_val14 ) : box06Val14arr[index]),
                        (box06Val15arr[index] === undefined? ( initialState.box06Val15array[index]? initialState.box06Val15array[index]: arrayList[index].box06_val15 ) : box06Val15arr[index]),
                        (box06Val16arr[index] === undefined? ( initialState.box06Val16array[index]? initialState.box06Val16array[index]: arrayList[index].box06_val16 ) : box06Val16arr[index]),
                        (box06Val17arr[index] === undefined? ( initialState.box06Val17array[index]? initialState.box06Val17array[index]: arrayList[index].box06_val17 ) : box06Val17arr[index]),
                        (box06Val18arr[index] === undefined? ( initialState.box06Val18array[index]? initialState.box06Val18array[index]: arrayList[index].box06_val18 ) : box06Val18arr[index]),
                        (box06Val19arr[index] === undefined? ( initialState.box06Val19array[index]? initialState.box06Val19array[index]: arrayList[index].box06_val19 ) : box06Val19arr[index]),
                        (box06Val20arr[index] === undefined? ( initialState.box06Val20array[index]? initialState.box06Val20array[index]: arrayList[index].box06_val20 ) : box06Val20arr[index]),
                        (box06Val21arr[index] === undefined? ( initialState.box06Val21array[index]? initialState.box06Val21array[index]: arrayList[index].box06_val21 ) : box06Val21arr[index]),
                        (box06Val22arr[index] === undefined? ( initialState.box06Val22array[index]? initialState.box06Val22array[index]: arrayList[index].box06_val22 ) : box06Val22arr[index]),
                        (box06Val23arr[index] === undefined? ( initialState.box06Val23array[index]? initialState.box06Val23array[index]: arrayList[index].box06_val23 ) : box06Val23arr[index]),
                        (box06Val24arr[index] === undefined? ( initialState.box06Val24array[index]? initialState.box06Val24array[index]: arrayList[index].box06_val24 ) : box06Val24arr[index]),
                        (box06Val25arr[index] === undefined? ( initialState.box06Val25array[index]? initialState.box06Val25array[index]: arrayList[index].box06_val25 ) : box06Val25arr[index]),
                        (box06Val26arr[index] === undefined? ( initialState.box06Val26array[index]? initialState.box06Val26array[index]: arrayList[index].box06_val26 ) : box06Val26arr[index]),
                        (box06Val27arr[index] === undefined? ( initialState.box06Val27array[index]? initialState.box06Val27array[index]: arrayList[index].box06_val27 ) : box06Val27arr[index]),
                        (box06Val28arr[index] === undefined? ( initialState.box06Val28array[index]? initialState.box06Val28array[index]: arrayList[index].box06_val28 ) : box06Val28arr[index]),
                        (box06Val29arr[index] === undefined? ( initialState.box06Val29array[index]? initialState.box06Val29array[index]: arrayList[index].box06_val29 ) : box06Val29arr[index]),
                        (box06Val30arr[index] === undefined? ( initialState.box06Val30array[index]? initialState.box06Val30array[index]: arrayList[index].box06_val30 ) : box06Val30arr[index]),
                        (box06Val31arr[index] === undefined? ( initialState.box06Val31array[index]? initialState.box06Val31array[index]: arrayList[index].box06_val31 ) : box06Val31arr[index]),
                        (box06Val32arr[index] === undefined? ( initialState.box06Val32array[index]? initialState.box06Val32array[index]: arrayList[index].box06_val32 ) : box06Val32arr[index]),
                        (box06Val33arr[index] === undefined? ( initialState.box06Val33array[index]? initialState.box06Val33array[index]: arrayList[index].box06_val33 ) : box06Val33arr[index]),
                        (box06Val34arr[index] === undefined? ( initialState.box06Val34array[index]? initialState.box06Val34array[index]: arrayList[index].box06_val34 ) : box06Val34arr[index]),
                        (box06Val35arr[index] === undefined? ( initialState.box06Val35array[index]? initialState.box06Val35array[index]: arrayList[index].box06_val35 ) : box06Val35arr[index]),
                        (box06Val36arr[index] === undefined? ( initialState.box06Val36array[index]? initialState.box06Val36array[index]: arrayList[index].box06_val36 ) : box06Val36arr[index]),
                        (box06Val37arr[index] === undefined? ( initialState.box06Val37array[index]? initialState.box06Val37array[index]: arrayList[index].box06_val37 ) : box06Val37arr[index]),
                        (box06Val38arr[index] === undefined? ( initialState.box06Val38array[index]? initialState.box06Val38array[index]: arrayList[index].box06_val38 ) : box06Val38arr[index]),
                        (box06Val39arr[index] === undefined? ( initialState.box06Val39array[index]? initialState.box06Val39array[index]: arrayList[index].box06_val39 ) : box06Val39arr[index]),
                        (box06Val40arr[index] === undefined? ( initialState.box06Val40array[index]? initialState.box06Val40array[index]: arrayList[index].box06_val40 ) : box06Val40arr[index]),
                        (box06Val41arr[index] === undefined? ( initialState.box06Val41array[index]? initialState.box06Val41array[index]: arrayList[index].box06_val41 ) : box06Val41arr[index]),
                        (box06Val42arr[index] === undefined? ( initialState.box06Val42array[index]? initialState.box06Val42array[index]: arrayList[index].box06_val42 ) : box06Val42arr[index]),
                        (box06Val43arr[index] === undefined? ( initialState.box06Val43array[index]? initialState.box06Val43array[index]: arrayList[index].box06_val43 ) : box06Val43arr[index]),
                        (box06Val44arr[index] === undefined? ( initialState.box06Val44array[index]? initialState.box06Val44array[index]: arrayList[index].box06_val44 ) : box06Val44arr[index]),
                        (box06Val45arr[index] === undefined? ( initialState.box06Val45array[index]? initialState.box06Val45array[index]: arrayList[index].box06_val45 ) : box06Val45arr[index]),
                        (box06Val46arr[index] === undefined? ( initialState.box06Val46array[index]? initialState.box06Val46array[index]: arrayList[index].box06_val46 ) : box06Val46arr[index]),
                        (box06Val47arr[index] === undefined? ( initialState.box06Val47array[index]? initialState.box06Val47array[index]: arrayList[index].box06_val47 ) : box06Val47arr[index]),
                        (box06Val48arr[index] === undefined? ( initialState.box06Val48array[index]? initialState.box06Val48array[index]: arrayList[index].box06_val48 ) : box06Val48arr[index]),
                        (box07Val1arr[index] === undefined? ( initialState.box07Val1array[index]? initialState.box07Val1array[index]: arrayList[index].box07_val1 ) : box07Val1arr[index]),
                        (box07Val2arr[index] === undefined? ( initialState.box07Val2array[index]? initialState.box07Val2array[index]: arrayList[index].box07_val2 ) : box07Val2arr[index]),
                        (box07Val3arr[index] === undefined? ( initialState.box07Val3array[index]? initialState.box07Val3array[index]: arrayList[index].box07_val3 ) : box07Val3arr[index]),
                        (box07Val4arr[index] === undefined? ( initialState.box07Val4array[index]? initialState.box07Val4array[index]: arrayList[index].box07_val4 ) : box07Val4arr[index]),
                        (box07Val5arr[index] === undefined? ( initialState.box07Val5array[index]? initialState.box07Val5array[index]: arrayList[index].box07_val5 ) : box07Val5arr[index]),
                        (box07Val6arr[index] === undefined? ( initialState.box07Val6array[index]? initialState.box07Val6array[index]: arrayList[index].box07_val6 ) : box07Val6arr[index]),
                        (box07Val7arr[index] === undefined? ( initialState.box07Val7array[index]? initialState.box07Val7array[index]: arrayList[index].box07_val7 ) : box07Val7arr[index]),
                        (box07Val8arr[index] === undefined? ( initialState.box07Val8array[index]? initialState.box07Val8array[index]: arrayList[index].box07_val8 ) : box07Val8arr[index]),
                        (box07Val9arr[index] === undefined? ( initialState.box07Val9array[index]? initialState.box07Val9array[index]: arrayList[index].box07_val9 ) : box07Val9arr[index]),
                        (box07Val10arr[index] === undefined? ( initialState.box07Val10array[index]? initialState.box07Val10array[index]: arrayList[index].box07_val10 ) : box07Val10arr[index]),
                        (box07Val11arr[index] === undefined? ( initialState.box07Val11array[index]? initialState.box07Val11array[index]: arrayList[index].box07_val11 ) : box07Val11arr[index]),
                        (box07Val12arr[index] === undefined? ( initialState.box07Val12array[index]? initialState.box07Val12array[index]: arrayList[index].box07_val12 ) : box07Val12arr[index]),
                        (box07Val13arr[index] === undefined? ( initialState.box07Val13array[index]? initialState.box07Val13array[index]: arrayList[index].box07_val13 ) : box07Val13arr[index]),
                        (box07Val14arr[index] === undefined? ( initialState.box07Val14array[index]? initialState.box07Val14array[index]: arrayList[index].box07_val14 ) : box07Val14arr[index]),
                        (box07Val15arr[index] === undefined? ( initialState.box07Val15array[index]? initialState.box07Val15array[index]: arrayList[index].box07_val15 ) : box07Val15arr[index]),
                        (box07Val16arr[index] === undefined? ( initialState.box07Val16array[index]? initialState.box07Val16array[index]: arrayList[index].box07_val16 ) : box07Val16arr[index]),
                        (box07Val17arr[index] === undefined? ( initialState.box07Val17array[index]? initialState.box07Val17array[index]: arrayList[index].box07_val17 ) : box07Val17arr[index]),
                        (box07Val18arr[index] === undefined? ( initialState.box07Val18array[index]? initialState.box07Val18array[index]: arrayList[index].box07_val18 ) : box07Val18arr[index]),
                        (box07Val19arr[index] === undefined? ( initialState.box07Val19array[index]? initialState.box07Val19array[index]: arrayList[index].box07_val19 ) : box07Val19arr[index]),
                        (box07Val20arr[index] === undefined? ( initialState.box07Val20array[index]? initialState.box07Val20array[index]: arrayList[index].box07_val20 ) : box07Val20arr[index]),
                        (box07Val21arr[index] === undefined? ( initialState.box07Val21array[index]? initialState.box07Val21array[index]: arrayList[index].box07_val21 ) : box07Val21arr[index]),
                        (box07Val22arr[index] === undefined? ( initialState.box07Val22array[index]? initialState.box07Val22array[index]: arrayList[index].box07_val22 ) : box07Val22arr[index]),
                        (box07Val23arr[index] === undefined? ( initialState.box07Val23array[index]? initialState.box07Val23array[index]: arrayList[index].box07_val23 ) : box07Val23arr[index]),
                        (box07Val24arr[index] === undefined? ( initialState.box07Val24array[index]? initialState.box07Val24array[index]: arrayList[index].box07_val24 ) : box07Val24arr[index]),
                        (box07Val25arr[index] === undefined? ( initialState.box07Val25array[index]? initialState.box07Val25array[index]: arrayList[index].box07_val25 ) : box07Val25arr[index]),
                        (box07Val26arr[index] === undefined? ( initialState.box07Val26array[index]? initialState.box07Val26array[index]: arrayList[index].box07_val26 ) : box07Val26arr[index]),
                        (box07Val27arr[index] === undefined? ( initialState.box07Val27array[index]? initialState.box07Val27array[index]: arrayList[index].box07_val27 ) : box07Val27arr[index]),
                        (box07Val28arr[index] === undefined? ( initialState.box07Val28array[index]? initialState.box07Val28array[index]: arrayList[index].box07_val28 ) : box07Val28arr[index]),
                        (box07Val29arr[index] === undefined? ( initialState.box07Val29array[index]? initialState.box07Val29array[index]: arrayList[index].box07_val29 ) : box07Val29arr[index]),
                        (box07Val30arr[index] === undefined? ( initialState.box07Val30array[index]? initialState.box07Val30array[index]: arrayList[index].box07_val30 ) : box07Val30arr[index]),
                        (box07Val31arr[index] === undefined? ( initialState.box07Val31array[index]? initialState.box07Val31array[index]: arrayList[index].box07_val31 ) : box07Val31arr[index]),
                        (box07Val32arr[index] === undefined? ( initialState.box07Val32array[index]? initialState.box07Val32array[index]: arrayList[index].box07_val32 ) : box07Val32arr[index]),
                        (box07Val33arr[index] === undefined? ( initialState.box07Val33array[index]? initialState.box07Val33array[index]: arrayList[index].box07_val33 ) : box07Val33arr[index]),
                        (box07Val34arr[index] === undefined? ( initialState.box07Val34array[index]? initialState.box07Val34array[index]: arrayList[index].box07_val34 ) : box07Val34arr[index]),
                        (box07Val35arr[index] === undefined? ( initialState.box07Val35array[index]? initialState.box07Val35array[index]: arrayList[index].box07_val35 ) : box07Val35arr[index]),
                        (box07Val36arr[index] === undefined? ( initialState.box07Val36array[index]? initialState.box07Val36array[index]: arrayList[index].box07_val36 ) : box07Val36arr[index]),
                        (box07Val37arr[index] === undefined? ( initialState.box07Val37array[index]? initialState.box07Val37array[index]: arrayList[index].box07_val37 ) : box07Val37arr[index]),
                        (box07Val38arr[index] === undefined? ( initialState.box07Val38array[index]? initialState.box07Val38array[index]: arrayList[index].box07_val38 ) : box07Val38arr[index]),
                        (box07Val39arr[index] === undefined? ( initialState.box07Val39array[index]? initialState.box07Val39array[index]: arrayList[index].box07_val39 ) : box07Val39arr[index]),
                        (box07Val40arr[index] === undefined? ( initialState.box07Val40array[index]? initialState.box07Val40array[index]: arrayList[index].box07_val40 ) : box07Val40arr[index]),
                        (box07Val41arr[index] === undefined? ( initialState.box07Val41array[index]? initialState.box07Val41array[index]: arrayList[index].box07_val41 ) : box07Val41arr[index]),
                        (box07Val42arr[index] === undefined? ( initialState.box07Val42array[index]? initialState.box07Val42array[index]: arrayList[index].box07_val42 ) : box07Val42arr[index]),
                        (box07Val43arr[index] === undefined? ( initialState.box07Val43array[index]? initialState.box07Val43array[index]: arrayList[index].box07_val43 ) : box07Val43arr[index]),
                        (box07Val44arr[index] === undefined? ( initialState.box07Val44array[index]? initialState.box07Val44array[index]: arrayList[index].box07_val44 ) : box07Val44arr[index]),
                        (box07Val45arr[index] === undefined? ( initialState.box07Val45array[index]? initialState.box07Val45array[index]: arrayList[index].box07_val45 ) : box07Val45arr[index]),
                        (box07Val46arr[index] === undefined? ( initialState.box07Val46array[index]? initialState.box07Val46array[index]: arrayList[index].box07_val46 ) : box07Val46arr[index]),
                        (box07Val47arr[index] === undefined? ( initialState.box07Val47array[index]? initialState.box07Val47array[index]: arrayList[index].box07_val47 ) : box07Val47arr[index]),
                        (box07Val48arr[index] === undefined? ( initialState.box07Val48array[index]? initialState.box07Val48array[index]: arrayList[index].box07_val48 ) : box07Val48arr[index]),
                        (box08Val1arr[index] === undefined? ( initialState.box08Val1array[index]? initialState.box08Val1array[index]: arrayList[index].box08_val1 ) : box08Val1arr[index]),
                        (box08Val2arr[index] === undefined? ( initialState.box08Val2array[index]? initialState.box08Val2array[index]: arrayList[index].box08_val2 ) : box08Val2arr[index]),
                        (box08Val3arr[index] === undefined? ( initialState.box08Val3array[index]? initialState.box08Val3array[index]: arrayList[index].box08_val3 ) : box08Val3arr[index]),
                        (box08Val4arr[index] === undefined? ( initialState.box08Val4array[index]? initialState.box08Val4array[index]: arrayList[index].box08_val4 ) : box08Val4arr[index]),
                        (box08Val5arr[index] === undefined? ( initialState.box08Val5array[index]? initialState.box08Val5array[index]: arrayList[index].box08_val5 ) : box08Val5arr[index]),
                        (box08Val6arr[index] === undefined? ( initialState.box08Val6array[index]? initialState.box08Val6array[index]: arrayList[index].box08_val6 ) : box08Val6arr[index]),
                        (box08Val7arr[index] === undefined? ( initialState.box08Val7array[index]? initialState.box08Val7array[index]: arrayList[index].box08_val7 ) : box08Val7arr[index]),
                        (box09Val1arr[index] === undefined? ( initialState.box09Val1array[index]? initialState.box09Val1array[index]: arrayList[index].box09_val1 ) : box09Val1arr[index]),
                        (box09Val2arr[index] === undefined? ( initialState.box09Val2array[index]? initialState.box09Val2array[index]: arrayList[index].box09_val2 ) : box09Val2arr[index]),
                        (box09Val3arr[index] === undefined? ( initialState.box09Val3array[index]? initialState.box09Val3array[index]: arrayList[index].box09_val3 ) : box09Val3arr[index]),
                        (box09Val4arr[index] === undefined? ( initialState.box09Val4array[index]? initialState.box09Val4array[index]: arrayList[index].box09_val4 ) : box09Val4arr[index]),
                        (box09Val5arr[index] === undefined? ( initialState.box09Val5array[index]? initialState.box09Val5array[index]: arrayList[index].box09_val5 ) : box09Val5arr[index]),
                        (box09Val6arr[index] === undefined? ( initialState.box09Val6array[index]? initialState.box09Val6array[index]: arrayList[index].box09_val6 ) : box09Val6arr[index]),
                        (box09Val7arr[index] === undefined? ( initialState.box09Val7array[index]? initialState.box09Val7array[index]: arrayList[index].box09_val7 ) : box09Val7arr[index]),
                        (box10Val1arr[index] === undefined? ( initialState.box10Val1array[index]? initialState.box10Val1array[index]: arrayList[index].box10_val1 ) : box10Val1arr[index]),
                        (box10Val2arr[index] === undefined? ( initialState.box10Val2array[index]? initialState.box10Val2array[index]: arrayList[index].box10_val2 ) : box10Val2arr[index]),
                        (box10Val3arr[index] === undefined? ( initialState.box10Val3array[index]? initialState.box10Val3array[index]: arrayList[index].box10_val3 ) : box10Val3arr[index]),
                        (box10Val4arr[index] === undefined? ( initialState.box10Val4array[index]? initialState.box10Val4array[index]: arrayList[index].box10_val4 ) : box10Val4arr[index]),
                        (box10Val5arr[index] === undefined? ( initialState.box10Val5array[index]? initialState.box10Val5array[index]: arrayList[index].box10_val5 ) : box10Val5arr[index]),
                        (box10Val6arr[index] === undefined? ( initialState.box10Val6array[index]? initialState.box10Val6array[index]: arrayList[index].box10_val6 ) : box10Val6arr[index]),
                        (box10Val7arr[index] === undefined? ( initialState.box10Val7array[index]? initialState.box10Val7array[index]: arrayList[index].box10_val7 ) : box10Val7arr[index]),
                        (box11Val1arr[index] === undefined? ( initialState.box11Val1array[index]? initialState.box11Val1array[index]: arrayList[index].box11_val1 ) : box11Val1arr[index]),
                        (box11Val2arr[index] === undefined? ( initialState.box11Val2array[index]? initialState.box11Val2array[index]: arrayList[index].box11_val2 ) : box11Val2arr[index]),
                        (box11Val3arr[index] === undefined? ( initialState.box11Val3array[index]? initialState.box11Val3array[index]: arrayList[index].box11_val3 ) : box11Val3arr[index]),
                        (box11Val4arr[index] === undefined? ( initialState.box11Val4array[index]? initialState.box11Val4array[index]: arrayList[index].box11_val4 ) : box11Val4arr[index]),
                        (box11Val5arr[index] === undefined? ( initialState.box11Val5array[index]? initialState.box11Val5array[index]: arrayList[index].box11_val5 ) : box11Val5arr[index]),
                        (box11Val6arr[index] === undefined? ( initialState.box11Val6array[index]? initialState.box11Val6array[index]: arrayList[index].box11_val6 ) : box11Val6arr[index]),
                        (box11Val7arr[index] === undefined? ( initialState.box11Val7array[index]? initialState.box11Val7array[index]: arrayList[index].box11_val7 ) : box11Val7arr[index]),
                        (box12Val1arr[index] === undefined? ( initialState.box12Val1array[index]? initialState.box12Val1array[index]: arrayList[index].box12_val1 ) : box12Val1arr[index]),
                        (box12Val2arr[index] === undefined? ( initialState.box12Val2array[index]? initialState.box12Val2array[index]: arrayList[index].box12_val2 ) : box12Val2arr[index]),
                        (box12Val3arr[index] === undefined? ( initialState.box12Val3array[index]? initialState.box12Val3array[index]: arrayList[index].box12_val3 ) : box12Val3arr[index]),
                        (box12Val4arr[index] === undefined? ( initialState.box12Val4array[index]? initialState.box12Val4array[index]: arrayList[index].box12_val4 ) : box12Val4arr[index]),
                        (box12Val5arr[index] === undefined? ( initialState.box12Val5array[index]? initialState.box12Val5array[index]: arrayList[index].box12_val5 ) : box12Val5arr[index]),
                        (box12Val6arr[index] === undefined? ( initialState.box12Val6array[index]? initialState.box12Val6array[index]: arrayList[index].box12_val6 ) : box12Val6arr[index]),
                        (box12Val7arr[index] === undefined? ( initialState.box12Val7array[index]? initialState.box12Val7array[index]: arrayList[index].box12_val7 ) : box12Val7arr[index]),
                        (box13Val1arr[index] === undefined? ( initialState.box13Val1array[index]? initialState.box13Val1array[index]: arrayList[index].box13_val1 ) : box13Val1arr[index]),
                        (box13Val2arr[index] === undefined? ( initialState.box13Val2array[index]? initialState.box13Val2array[index]: arrayList[index].box13_val2 ) : box13Val2arr[index]),
                        (box13Val3arr[index] === undefined? ( initialState.box13Val3array[index]? initialState.box13Val3array[index]: arrayList[index].box13_val3 ) : box13Val3arr[index]),
                        (box13Val4arr[index] === undefined? ( initialState.box13Val4array[index]? initialState.box13Val4array[index]: arrayList[index].box13_val4 ) : box13Val4arr[index]),
                        (box13Val5arr[index] === undefined? ( initialState.box13Val5array[index]? initialState.box13Val5array[index]: arrayList[index].box13_val5 ) : box13Val5arr[index]),
                        (box13Val6arr[index] === undefined? ( initialState.box13Val6array[index]? initialState.box13Val6array[index]: arrayList[index].box13_val6 ) : box13Val6arr[index]),
                        (box13Val7arr[index] === undefined? ( initialState.box13Val7array[index]? initialState.box13Val7array[index]: arrayList[index].box13_val7 ) : box13Val7arr[index]),
                        (box14Val1arr[index] === undefined? ( initialState.box14Val1array[index]? initialState.box14Val1array[index]: arrayList[index].box14_val1 ) : box14Val1arr[index]),
                        (box14Val2arr[index] === undefined? ( initialState.box14Val2array[index]? initialState.box14Val2array[index]: arrayList[index].box14_val2 ) : box14Val2arr[index]),
                        (box14Val3arr[index] === undefined? ( initialState.box14Val3array[index]? initialState.box14Val3array[index]: arrayList[index].box14_val3 ) : box14Val3arr[index]),
                        (box14Val4arr[index] === undefined? ( initialState.box14Val4array[index]? initialState.box14Val4array[index]: arrayList[index].box14_val4 ) : box14Val4arr[index])

                    );
                    //setArrayList(box01Val1arr);
                    //alert("ififi :: "+i);
                }

            }
        }


    }






    const valueSetHandler = (e, index, srtIdx, strSubIdx) => {

        console.log("event srtIdx ::::: "+srtIdx);
        console.log("event strSubIdx ::::: "+strSubIdx);

        switch(srtIdx) {
            //box01
            case "1" :
                switch( strSubIdx ){
                    case "1": box01Val1arr[index] = e.target.value; setBox01Val1arr(box01Val1arr); break;
                    case "2": box01Val2arr[index] = e.target.value; setBox01Val2arr(box01Val2arr); break;
                    case "3": box01Val3arr[index] = e.target.value; setBox01Val3arr(box01Val3arr); break;
                    case "4": box01Val4arr[index] = e.target.value; setBox01Val4arr(box01Val4arr); break;
                    case "5": box01Val5arr[index] = e.target.value; setBox01Val5arr(box01Val5arr); break;
                    case "6": box01Val6arr[index] = e.target.value; setBox01Val6arr(box01Val6arr); break;
                    case "7": box01Val7arr[index] = e.target.value; setBox01Val7arr(box01Val7arr); break;
                    default: break;
                }
                break;
            //box02
            case "2" :
                switch( strSubIdx ){
                    case "1": box02Val1arr[index] = e.target.value; setBox02Val1arr(box02Val1arr); break;
                    case "2": box02Val2arr[index] = e.target.value; setBox02Val2arr(box02Val2arr); break;
                    case "3": box02Val3arr[index] = e.target.value; setBox02Val3arr(box02Val3arr); break;
                    case "4": box02Val4arr[index] = e.target.value; setBox02Val4arr(box02Val4arr); break;
                    case "5": box02Val5arr[index] = e.target.value; setBox02Val5arr(box02Val5arr); break;
                    case "6": box02Val6arr[index] = e.target.value; setBox02Val6arr(box02Val6arr); break;
                    case "7": box02Val7arr[index] = e.target.value; setBox02Val7arr(box02Val7arr); break;
                    case "8": box02Val8arr[index] = e.target.value; setBox02Val8arr(box02Val8arr); break;
                    case "9": box02Val9arr[index] = e.target.value; setBox02Val9arr(box02Val9arr); break;
                    case "10": box02Val10arr[index] = e.target.value; setBox02Val10arr(box02Val10arr); break;
                    case "11": box02Val11arr[index] = e.target.value; setBox02Val11arr(box02Val11arr); break;
                    case "12": box02Val12arr[index] = e.target.value; setBox02Val12arr(box02Val12arr); break;
                    case "13": box02Val13arr[index] = e.target.value; setBox02Val13arr(box02Val13arr); break;
                    default: break;
                }
                break;
            //box03
            case "3" :
                switch( strSubIdx ){
                    case "1": box03Val1arr[index] = e.target.value; setBox03Val1arr(box03Val1arr); break;
                    case "2": box03Val2arr[index] = e.target.value; setBox03Val2arr(box03Val2arr); break;
                    case "3": box03Val3arr[index] = e.target.value; setBox03Val3arr(box03Val3arr); break;
                    case "4": box03Val4arr[index] = e.target.value; setBox03Val4arr(box03Val4arr); break;
                    case "5": box03Val5arr[index] = e.target.value; setBox03Val5arr(box03Val5arr); break;
                    case "6": box03Val6arr[index] = e.target.value; setBox03Val6arr(box03Val6arr); break;
                    case "7": box03Val7arr[index] = e.target.value; setBox03Val7arr(box03Val7arr); break;
                    case "8": box03Val8arr[index] = e.target.value; setBox03Val8arr(box03Val8arr); break;
                    case "9": box03Val9arr[index] = e.target.value; setBox03Val9arr(box03Val9arr); break;
                    case "10": box03Val10arr[index] = e.target.value; setBox03Val10arr(box03Val10arr); break;
                    default: break;
                }
                break;
            //box04
            case "4" :
                switch( strSubIdx ){
                    case "1": box04Val1arr[index] = e.target.value; setBox04Val1arr(box04Val1arr); break;
                    case "2": box04Val2arr[index] = e.target.value; setBox04Val2arr(box04Val2arr); break;
                    case "3": box04Val3arr[index] = e.target.value; setBox04Val3arr(box04Val3arr); break;
                    case "4": box04Val4arr[index] = e.target.value; setBox04Val4arr(box04Val4arr); break;
                    case "5": box04Val5arr[index] = e.target.value; setBox04Val5arr(box04Val5arr); break;
                    case "6": box04Val6arr[index] = e.target.value; setBox04Val6arr(box04Val6arr); break;
                    case "7": box04Val7arr[index] = e.target.value; setBox04Val7arr(box04Val7arr); break;
                    case "8": box04Val8arr[index] = e.target.value; setBox04Val8arr(box04Val8arr); break;
                    case "9": box04Val9arr[index] = e.target.value; setBox04Val9arr(box04Val9arr); break;
                    case "10": box04Val10arr[index] = e.target.value; setBox04Val10arr(box04Val10arr); break;
                    case "11": box04Val11arr[index] = e.target.value; setBox04Val11arr(box04Val11arr); break;
                    case "12": box04Val12arr[index] = e.target.value; setBox04Val12arr(box04Val12arr); break;
                    case "13": box04Val13arr[index] = e.target.value; setBox04Val13arr(box04Val13arr); break;
                    case "14": box04Val14arr[index] = e.target.value; setBox04Val14arr(box04Val14arr); break;
                    case "15": box04Val15arr[index] = e.target.value; setBox04Val15arr(box04Val15arr); break;
                    case "16": box04Val16arr[index] = e.target.value; setBox04Val16arr(box04Val16arr); break;
                    case "17": box04Val17arr[index] = e.target.value; setBox04Val17arr(box04Val17arr); break;
                    case "18": box04Val18arr[index] = e.target.value; setBox04Val18arr(box04Val18arr); break;
                    case "19": box04Val19arr[index] = e.target.value; setBox04Val19arr(box04Val19arr); break;
                    case "20": box04Val20arr[index] = e.target.value; setBox04Val20arr(box04Val20arr); break;
                    case "21": box04Val21arr[index] = e.target.value; setBox04Val21arr(box04Val21arr); break;
                    case "22": box04Val22arr[index] = e.target.value; setBox04Val22arr(box04Val22arr); break;
                    case "23": box04Val23arr[index] = e.target.value; setBox04Val23arr(box04Val23arr); break;
                    case "24": box04Val24arr[index] = e.target.value; setBox04Val24arr(box04Val24arr); break;
                    case "25": box04Val25arr[index] = e.target.value; setBox04Val25arr(box04Val25arr); break;
                    case "26": box04Val26arr[index] = e.target.value; setBox04Val26arr(box04Val26arr); break;
                    case "27": box04Val27arr[index] = e.target.value; setBox04Val27arr(box04Val27arr); break;
                    case "28": box04Val28arr[index] = e.target.value; setBox04Val28arr(box04Val28arr); break;
                    case "29": box04Val29arr[index] = e.target.value; setBox04Val29arr(box04Val29arr); break;
                    case "30": box04Val30arr[index] = e.target.value; setBox04Val30arr(box04Val30arr); break;
                    case "31": box04Val31arr[index] = e.target.value; setBox04Val31arr(box04Val31arr); break;
                    case "32": box04Val32arr[index] = e.target.value; setBox04Val32arr(box04Val32arr); break;
                    case "33": box04Val33arr[index] = e.target.value; setBox04Val33arr(box04Val33arr); break;
                    case "34": box04Val34arr[index] = e.target.value; setBox04Val34arr(box04Val34arr); break;
                    case "35": box04Val35arr[index] = e.target.value; setBox04Val35arr(box04Val35arr); break;
                    case "36": box04Val36arr[index] = e.target.value; setBox04Val36arr(box04Val36arr); break;
                    case "37": box04Val37arr[index] = e.target.value; setBox04Val37arr(box04Val37arr); break;
                    case "38": box04Val38arr[index] = e.target.value; setBox04Val38arr(box04Val38arr); break;
                    case "39": box04Val39arr[index] = e.target.value; setBox04Val39arr(box04Val39arr); break;
                    case "40": box04Val40arr[index] = e.target.value; setBox04Val40arr(box04Val40arr); break;
                    case "41": box04Val41arr[index] = e.target.value; setBox04Val41arr(box04Val41arr); break;
                    case "42": box04Val42arr[index] = e.target.value; setBox04Val42arr(box04Val42arr); break;
                    case "43": box04Val43arr[index] = e.target.value; setBox04Val43arr(box04Val43arr); break;
                    case "44": box04Val44arr[index] = e.target.value; setBox04Val44arr(box04Val44arr); break;
                    case "45": box04Val45arr[index] = e.target.value; setBox04Val45arr(box04Val45arr); break;
                    case "46": box04Val46arr[index] = e.target.value; setBox04Val46arr(box04Val46arr); break;
                    case "47": box04Val47arr[index] = e.target.value; setBox04Val47arr(box04Val47arr); break;
                    case "48": box04Val48arr[index] = e.target.value; setBox04Val48arr(box04Val48arr); break;
                    default: break;
                }
                break;
            //box05
            case "5" :
                switch( strSubIdx ){
                    case "1": box05Val1arr[index] = e.target.value; setBox05Val1arr(box05Val1arr); break;
                    case "2": box05Val2arr[index] = e.target.value; setBox05Val2arr(box05Val2arr); break;
                    case "3": box05Val3arr[index] = e.target.value; setBox05Val3arr(box05Val3arr); break;
                    case "4": box05Val4arr[index] = e.target.value; setBox05Val4arr(box05Val4arr); break;
                    case "5": box05Val5arr[index] = e.target.value; setBox05Val5arr(box05Val5arr); break;
                    case "6": box05Val6arr[index] = e.target.value; setBox05Val6arr(box05Val6arr); break;
                    case "7": box05Val7arr[index] = e.target.value; setBox05Val7arr(box05Val7arr); break;
                    case "8": box05Val8arr[index] = e.target.value; setBox05Val8arr(box05Val8arr); break;
                    case "9": box05Val9arr[index] = e.target.value; setBox05Val9arr(box05Val9arr); break;
                    case "10": box05Val10arr[index] = e.target.value; setBox05Val10arr(box05Val10arr); break;
                    case "11": box05Val11arr[index] = e.target.value; setBox05Val11arr(box05Val11arr); break;
                    case "12": box05Val12arr[index] = e.target.value; setBox05Val12arr(box05Val12arr); break;
                    case "13": box05Val13arr[index] = e.target.value; setBox05Val13arr(box05Val13arr); break;
                    case "14": box05Val14arr[index] = e.target.value; setBox05Val14arr(box05Val14arr); break;
                    case "15": box05Val15arr[index] = e.target.value; setBox05Val15arr(box05Val15arr); break;
                    case "16": box05Val16arr[index] = e.target.value; setBox05Val16arr(box05Val16arr); break;
                    case "17": box05Val17arr[index] = e.target.value; setBox05Val17arr(box05Val17arr); break;
                    case "18": box05Val18arr[index] = e.target.value; setBox05Val18arr(box05Val18arr); break;
                    case "19": box05Val19arr[index] = e.target.value; setBox05Val19arr(box05Val19arr); break;
                    case "20": box05Val20arr[index] = e.target.value; setBox05Val20arr(box05Val20arr); break;
                    case "21": box05Val21arr[index] = e.target.value; setBox05Val21arr(box05Val21arr); break;
                    case "22": box05Val22arr[index] = e.target.value; setBox05Val22arr(box05Val22arr); break;
                    case "23": box05Val23arr[index] = e.target.value; setBox05Val23arr(box05Val23arr); break;
                    case "24": box05Val24arr[index] = e.target.value; setBox05Val24arr(box05Val24arr); break;
                    case "25": box05Val25arr[index] = e.target.value; setBox05Val25arr(box05Val25arr); break;
                    case "26": box05Val26arr[index] = e.target.value; setBox05Val26arr(box05Val26arr); break;
                    case "27": box05Val27arr[index] = e.target.value; setBox05Val27arr(box05Val27arr); break;
                    case "28": box05Val28arr[index] = e.target.value; setBox05Val28arr(box05Val28arr); break;
                    case "29": box05Val29arr[index] = e.target.value; setBox05Val29arr(box05Val29arr); break;
                    case "30": box05Val30arr[index] = e.target.value; setBox05Val30arr(box05Val30arr); break;
                    case "31": box05Val31arr[index] = e.target.value; setBox05Val31arr(box05Val31arr); break;
                    case "32": box05Val32arr[index] = e.target.value; setBox05Val32arr(box05Val32arr); break;
                    case "33": box05Val33arr[index] = e.target.value; setBox05Val33arr(box05Val33arr); break;
                    case "34": box05Val34arr[index] = e.target.value; setBox05Val34arr(box05Val34arr); break;
                    case "35": box05Val35arr[index] = e.target.value; setBox05Val35arr(box05Val35arr); break;
                    case "36": box05Val36arr[index] = e.target.value; setBox05Val36arr(box05Val36arr); break;
                    case "37": box05Val37arr[index] = e.target.value; setBox05Val37arr(box05Val37arr); break;
                    case "38": box05Val38arr[index] = e.target.value; setBox05Val38arr(box05Val38arr); break;
                    case "39": box05Val39arr[index] = e.target.value; setBox05Val39arr(box05Val39arr); break;
                    case "40": box05Val40arr[index] = e.target.value; setBox05Val40arr(box05Val40arr); break;
                    case "41": box05Val41arr[index] = e.target.value; setBox05Val41arr(box05Val41arr); break;
                    case "42": box05Val42arr[index] = e.target.value; setBox05Val42arr(box05Val42arr); break;
                    case "43": box05Val43arr[index] = e.target.value; setBox05Val43arr(box05Val43arr); break;
                    case "44": box05Val44arr[index] = e.target.value; setBox05Val44arr(box05Val44arr); break;
                    case "45": box05Val45arr[index] = e.target.value; setBox05Val45arr(box05Val45arr); break;
                    case "46": box05Val46arr[index] = e.target.value; setBox05Val46arr(box05Val46arr); break;
                    case "47": box05Val47arr[index] = e.target.value; setBox05Val47arr(box05Val47arr); break;
                    case "48": box05Val48arr[index] = e.target.value; setBox05Val48arr(box05Val48arr); break;
                    default: break;
                }
                break;
            //box06
            case "6" :
                switch( strSubIdx ){
                    case "1": box06Val1arr[index] = e.target.value; setBox06Val1arr(box06Val1arr); break;
                    case "2": box06Val2arr[index] = e.target.value; setBox06Val2arr(box06Val2arr); break;
                    case "3": box06Val3arr[index] = e.target.value; setBox06Val3arr(box06Val3arr); break;
                    case "4": box06Val4arr[index] = e.target.value; setBox06Val4arr(box06Val4arr); break;
                    case "5": box06Val5arr[index] = e.target.value; setBox06Val5arr(box06Val5arr); break;
                    case "6": box06Val6arr[index] = e.target.value; setBox06Val6arr(box06Val6arr); break;
                    case "7": box06Val7arr[index] = e.target.value; setBox06Val7arr(box06Val7arr); break;
                    case "8": box06Val8arr[index] = e.target.value; setBox06Val8arr(box06Val8arr); break;
                    case "9": box06Val9arr[index] = e.target.value; setBox06Val9arr(box06Val9arr); break;
                    case "10": box06Val10arr[index] = e.target.value; setBox06Val10arr(box06Val10arr); break;
                    case "11": box06Val11arr[index] = e.target.value; setBox06Val11arr(box06Val11arr); break;
                    case "12": box06Val12arr[index] = e.target.value; setBox06Val12arr(box06Val12arr); break;
                    case "13": box06Val13arr[index] = e.target.value; setBox06Val13arr(box06Val13arr); break;
                    case "14": box06Val14arr[index] = e.target.value; setBox06Val14arr(box06Val14arr); break;
                    case "15": box06Val15arr[index] = e.target.value; setBox06Val15arr(box06Val15arr); break;
                    case "16": box06Val16arr[index] = e.target.value; setBox06Val16arr(box06Val16arr); break;
                    case "17": box06Val17arr[index] = e.target.value; setBox06Val17arr(box06Val17arr); break;
                    case "18": box06Val18arr[index] = e.target.value; setBox06Val18arr(box06Val18arr); break;
                    case "19": box06Val19arr[index] = e.target.value; setBox06Val19arr(box06Val19arr); break;
                    case "20": box06Val20arr[index] = e.target.value; setBox06Val20arr(box06Val20arr); break;
                    case "21": box06Val21arr[index] = e.target.value; setBox06Val21arr(box06Val21arr); break;
                    case "22": box06Val22arr[index] = e.target.value; setBox06Val22arr(box06Val22arr); break;
                    case "23": box06Val23arr[index] = e.target.value; setBox06Val23arr(box06Val23arr); break;
                    case "24": box06Val24arr[index] = e.target.value; setBox06Val24arr(box06Val24arr); break;
                    case "25": box06Val25arr[index] = e.target.value; setBox06Val25arr(box06Val25arr); break;
                    case "26": box06Val26arr[index] = e.target.value; setBox06Val26arr(box06Val26arr); break;
                    case "27": box06Val27arr[index] = e.target.value; setBox06Val27arr(box06Val27arr); break;
                    case "28": box06Val28arr[index] = e.target.value; setBox06Val28arr(box06Val28arr); break;
                    case "29": box06Val29arr[index] = e.target.value; setBox06Val29arr(box06Val29arr); break;
                    case "30": box06Val30arr[index] = e.target.value; setBox06Val30arr(box06Val30arr); break;
                    case "31": box06Val31arr[index] = e.target.value; setBox06Val31arr(box06Val31arr); break;
                    case "32": box06Val32arr[index] = e.target.value; setBox06Val32arr(box06Val32arr); break;
                    case "33": box06Val33arr[index] = e.target.value; setBox06Val33arr(box06Val33arr); break;
                    case "34": box06Val34arr[index] = e.target.value; setBox06Val34arr(box06Val34arr); break;
                    case "35": box06Val35arr[index] = e.target.value; setBox06Val35arr(box06Val35arr); break;
                    case "36": box06Val36arr[index] = e.target.value; setBox06Val36arr(box06Val36arr); break;
                    case "37": box06Val37arr[index] = e.target.value; setBox06Val37arr(box06Val37arr); break;
                    case "38": box06Val38arr[index] = e.target.value; setBox06Val38arr(box06Val38arr); break;
                    case "39": box06Val39arr[index] = e.target.value; setBox06Val39arr(box06Val39arr); break;
                    case "40": box06Val40arr[index] = e.target.value; setBox06Val40arr(box06Val40arr); break;
                    case "41": box06Val41arr[index] = e.target.value; setBox06Val41arr(box06Val41arr); break;
                    case "42": box06Val42arr[index] = e.target.value; setBox06Val42arr(box06Val42arr); break;
                    case "43": box06Val43arr[index] = e.target.value; setBox06Val43arr(box06Val43arr); break;
                    case "44": box06Val44arr[index] = e.target.value; setBox06Val44arr(box06Val44arr); break;
                    case "45": box06Val45arr[index] = e.target.value; setBox06Val45arr(box06Val45arr); break;
                    case "46": box06Val46arr[index] = e.target.value; setBox06Val46arr(box06Val46arr); break;
                    case "47": box06Val47arr[index] = e.target.value; setBox06Val47arr(box06Val47arr); break;
                    case "48": box06Val48arr[index] = e.target.value; setBox06Val48arr(box06Val48arr); break;
                    default: break;
                }
                break;
            //box07
            case "7" :
                switch( strSubIdx ){
                    case "1": box07Val1arr[index] = e.target.value; setBox07Val1arr(box07Val1arr); break;
                    case "2": box07Val2arr[index] = e.target.value; setBox07Val2arr(box07Val2arr); break;
                    case "3": box07Val3arr[index] = e.target.value; setBox07Val3arr(box07Val3arr); break;
                    case "4": box07Val4arr[index] = e.target.value; setBox07Val4arr(box07Val4arr); break;
                    case "5": box07Val5arr[index] = e.target.value; setBox07Val5arr(box07Val5arr); break;
                    case "6": box07Val6arr[index] = e.target.value; setBox07Val6arr(box07Val6arr); break;
                    case "7": box07Val7arr[index] = e.target.value; setBox07Val7arr(box07Val7arr); break;
                    case "8": box07Val8arr[index] = e.target.value; setBox07Val8arr(box07Val8arr); break;
                    case "9": box07Val9arr[index] = e.target.value; setBox07Val9arr(box07Val9arr); break;
                    case "10": box07Val10arr[index] = e.target.value; setBox07Val10arr(box07Val10arr); break;
                    case "11": box07Val11arr[index] = e.target.value; setBox07Val11arr(box07Val11arr); break;
                    case "12": box07Val12arr[index] = e.target.value; setBox07Val12arr(box07Val12arr); break;
                    case "13": box07Val13arr[index] = e.target.value; setBox07Val13arr(box07Val13arr); break;
                    case "14": box07Val14arr[index] = e.target.value; setBox07Val14arr(box07Val14arr); break;
                    case "15": box07Val15arr[index] = e.target.value; setBox07Val15arr(box07Val15arr); break;
                    case "16": box07Val16arr[index] = e.target.value; setBox07Val16arr(box07Val16arr); break;
                    case "17": box07Val17arr[index] = e.target.value; setBox07Val17arr(box07Val17arr); break;
                    case "18": box07Val18arr[index] = e.target.value; setBox07Val18arr(box07Val18arr); break;
                    case "19": box07Val19arr[index] = e.target.value; setBox07Val19arr(box07Val19arr); break;
                    case "20": box07Val20arr[index] = e.target.value; setBox07Val20arr(box07Val20arr); break;
                    case "21": box07Val21arr[index] = e.target.value; setBox07Val21arr(box07Val21arr); break;
                    case "22": box07Val22arr[index] = e.target.value; setBox07Val22arr(box07Val22arr); break;
                    case "23": box07Val23arr[index] = e.target.value; setBox07Val23arr(box07Val23arr); break;
                    case "24": box07Val24arr[index] = e.target.value; setBox07Val24arr(box07Val24arr); break;
                    case "25": box07Val25arr[index] = e.target.value; setBox07Val25arr(box07Val25arr); break;
                    case "26": box07Val26arr[index] = e.target.value; setBox07Val26arr(box07Val26arr); break;
                    case "27": box07Val27arr[index] = e.target.value; setBox07Val27arr(box07Val27arr); break;
                    case "28": box07Val28arr[index] = e.target.value; setBox07Val28arr(box07Val28arr); break;
                    case "29": box07Val29arr[index] = e.target.value; setBox07Val29arr(box07Val29arr); break;
                    case "30": box07Val30arr[index] = e.target.value; setBox07Val30arr(box07Val30arr); break;
                    case "31": box07Val31arr[index] = e.target.value; setBox07Val31arr(box07Val31arr); break;
                    case "32": box07Val32arr[index] = e.target.value; setBox07Val32arr(box07Val32arr); break;
                    case "33": box07Val33arr[index] = e.target.value; setBox07Val33arr(box07Val33arr); break;
                    case "34": box07Val34arr[index] = e.target.value; setBox07Val34arr(box07Val34arr); break;
                    case "35": box07Val35arr[index] = e.target.value; setBox07Val35arr(box07Val35arr); break;
                    case "36": box07Val36arr[index] = e.target.value; setBox07Val36arr(box07Val36arr); break;
                    case "37": box07Val37arr[index] = e.target.value; setBox07Val37arr(box07Val37arr); break;
                    case "38": box07Val38arr[index] = e.target.value; setBox07Val38arr(box07Val38arr); break;
                    case "39": box07Val39arr[index] = e.target.value; setBox07Val39arr(box07Val39arr); break;
                    case "40": box07Val40arr[index] = e.target.value; setBox07Val40arr(box07Val40arr); break;
                    case "41": box07Val41arr[index] = e.target.value; setBox07Val41arr(box07Val41arr); break;
                    case "42": box07Val42arr[index] = e.target.value; setBox07Val42arr(box07Val42arr); break;
                    case "43": box07Val43arr[index] = e.target.value; setBox07Val43arr(box07Val43arr); break;
                    case "44": box07Val44arr[index] = e.target.value; setBox07Val44arr(box07Val44arr); break;
                    case "45": box07Val45arr[index] = e.target.value; setBox07Val45arr(box07Val45arr); break;
                    case "46": box07Val46arr[index] = e.target.value; setBox07Val46arr(box07Val46arr); break;
                    case "47": box07Val47arr[index] = e.target.value; setBox07Val47arr(box07Val47arr); break;
                    case "48": box07Val48arr[index] = e.target.value; setBox07Val48arr(box07Val48arr); break;
                    default: break;
                }
                break;
            //box08
            case "8" :
                switch( strSubIdx ){
                    case "1": box08Val1arr[index] = e.target.value; setBox08Val1arr(box08Val1arr); break;
                    case "2": box08Val2arr[index] = e.target.value; setBox08Val2arr(box08Val2arr); break;
                    case "3": box08Val3arr[index] = e.target.value; setBox08Val3arr(box08Val3arr); break;
                    case "4": box08Val4arr[index] = e.target.value; setBox08Val4arr(box08Val4arr); break;
                    case "5": box08Val5arr[index] = e.target.value; setBox08Val5arr(box08Val5arr); break;
                    case "6": box08Val6arr[index] = e.target.value; setBox08Val6arr(box08Val6arr); break;
                    case "7": box08Val7arr[index] = e.target.value; setBox08Val7arr(box08Val7arr); break;
                    default: break;
                }
                break;
            //box09
            case "9" :
                switch( strSubIdx ){
                    case "1": box09Val1arr[index] = e.target.value; setBox09Val1arr(box09Val1arr); break;
                    case "2": box09Val2arr[index] = e.target.value; setBox09Val2arr(box09Val2arr); break;
                    case "3": box09Val3arr[index] = e.target.value; setBox09Val3arr(box09Val3arr); break;
                    case "4": box09Val4arr[index] = e.target.value; setBox09Val4arr(box09Val4arr); break;
                    case "5": box09Val5arr[index] = e.target.value; setBox09Val5arr(box09Val5arr); break;
                    case "6": box09Val6arr[index] = e.target.value; setBox09Val6arr(box09Val6arr); break;
                    case "7": box09Val7arr[index] = e.target.value; setBox09Val7arr(box09Val7arr); break;
                    default: break;
                }
                break;
            //box10
            case "10" :
                switch( strSubIdx ){
                    case "1": box10Val1arr[index] = e.target.value; setBox10Val1arr(box10Val1arr); break;
                    case "2": box10Val2arr[index] = e.target.value; setBox10Val2arr(box10Val2arr); break;
                    case "3": box10Val3arr[index] = e.target.value; setBox10Val3arr(box10Val3arr); break;
                    case "4": box10Val4arr[index] = e.target.value; setBox10Val4arr(box10Val4arr); break;
                    case "5": box10Val5arr[index] = e.target.value; setBox10Val5arr(box10Val5arr); break;
                    case "6": box10Val6arr[index] = e.target.value; setBox10Val6arr(box10Val6arr); break;
                    case "7": box10Val7arr[index] = e.target.value; setBox10Val7arr(box10Val7arr); break;
                    default: break;
                }
                break;
            //box11
            case "11" :
                switch( strSubIdx ){
                    case "1": box11Val1arr[index] = e.target.value; setBox11Val1arr(box11Val1arr); break;
                    case "2": box11Val2arr[index] = e.target.value; setBox11Val2arr(box11Val2arr); break;
                    case "3": box11Val3arr[index] = e.target.value; setBox11Val3arr(box11Val3arr); break;
                    case "4": box11Val4arr[index] = e.target.value; setBox11Val4arr(box11Val4arr); break;
                    case "5": box11Val5arr[index] = e.target.value; setBox11Val5arr(box11Val5arr); break;
                    case "6": box11Val6arr[index] = e.target.value; setBox11Val6arr(box11Val6arr); break;
                    case "7": box11Val7arr[index] = e.target.value; setBox11Val7arr(box11Val7arr); break;
                    default: break;
                }
                break;
            //box12
            case "12" :
                switch( strSubIdx ){
                    case "1": box12Val1arr[index] = e.target.value; setBox12Val1arr(box12Val1arr); break;
                    case "2": box12Val2arr[index] = e.target.value; setBox12Val2arr(box12Val2arr); break;
                    case "3": box12Val3arr[index] = e.target.value; setBox12Val3arr(box12Val3arr); break;
                    case "4": box12Val4arr[index] = e.target.value; setBox12Val4arr(box12Val4arr); break;
                    case "5": box12Val5arr[index] = e.target.value; setBox12Val5arr(box12Val5arr); break;
                    case "6": box12Val6arr[index] = e.target.value; setBox12Val6arr(box12Val6arr); break;
                    case "7": box12Val7arr[index] = e.target.value; setBox12Val7arr(box12Val7arr); break;
                    default: break;
                }
                break;
            //box13
            case "13" :
                switch( strSubIdx ){
                    case "1": box13Val1arr[index] = e.target.value; setBox13Val1arr(box13Val1arr); break;
                    case "2": box13Val2arr[index] = e.target.value; setBox13Val2arr(box13Val2arr); break;
                    case "3": box13Val3arr[index] = e.target.value; setBox13Val3arr(box13Val3arr); break;
                    case "4": box13Val4arr[index] = e.target.value; setBox13Val4arr(box13Val4arr); break;
                    case "5": box13Val5arr[index] = e.target.value; setBox13Val5arr(box13Val5arr); break;
                    case "6": box13Val6arr[index] = e.target.value; setBox13Val6arr(box13Val6arr); break;
                    case "7": box13Val7arr[index] = e.target.value; setBox13Val7arr(box13Val7arr); break;
                    default: break;
                }
                break;
            //box14
            case "14" :
                switch( strSubIdx ){
                    case "1": box14Val1arr[index] = e.target.value; setBox14Val1arr(box14Val1arr); break;
                    case "2": box14Val2arr[index] = e.target.value; setBox14Val2arr(box14Val2arr); break;
                    case "3": box14Val3arr[index] = e.target.value; setBox14Val3arr(box14Val3arr); break;
                    case "4": box14Val4arr[index] = e.target.value; setBox14Val4arr(box14Val4arr); break;
                    default: break;
                }
                break;

            default : break;
        }


        console.log("box01Val1arr :: "+JSON.stringify(box01Val1arr));
        console.log("box02Val1arr :: "+JSON.stringify(box02Val1arr));
        console.log("box03Val1arr :: "+JSON.stringify(box03Val1arr));
        console.log("box04Val1arr :: "+JSON.stringify(box04Val1arr));


        //alert("box01Val1arr :: "+JSON.stringify(box01Val1arr));

        settingLevelNoArr[index] = index;
        setSettingLevelNoArr(settingLevelNoArr);
    }




    const updateState = () => {


        if (window.confirm("저장 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveLevelSetting', {
                onceChargePoint: (onceChargePoint === "" ? countList.once_charge_point : onceChargePoint),
                minChangePoint: (minChangePoint === "" ? countList.min_change_point : minChangePoint),
                changePointUnit: (changePointUnit === "" ? countList.change_point_unit : changePointUnit),
                pointLevelCd: (pointLevelCd === "" ? countList.point_level_cd : pointLevelCd),
                settingLevelNoArr	: settingLevelNoArr,
                adminLevelArr	: adminLevelArr	,
                userLevelArr	: userLevelArr	,
                etcArr	        : etcArr,
                box01Val1arr	: box01Val1arr	,
                box01Val2arr	: box01Val2arr	,
                box01Val3arr	: box01Val3arr	,
                box01Val4arr	: box01Val4arr	,
                box01Val5arr	: box01Val5arr	,
                box01Val6arr	: box01Val6arr	,
                box01Val7arr	: box01Val7arr	,
                box02Val1arr	: box02Val1arr	,
                box02Val2arr	: box02Val2arr	,
                box02Val3arr	: box02Val3arr	,
                box02Val4arr	: box02Val4arr	,
                box02Val5arr	: box02Val5arr	,
                box02Val6arr	: box02Val6arr	,
                box02Val7arr	: box02Val7arr	,
                box02Val8arr	: box02Val8arr	,
                box02Val9arr	: box02Val9arr	,
                box02Val10arr	: box02Val10arr	,
                box02Val11arr	: box02Val11arr	,
                box02Val12arr	: box02Val12arr	,
                box02Val13arr	: box02Val13arr	,
                box03Val1arr	: box03Val1arr	,
                box03Val2arr	: box03Val2arr	,
                box03Val3arr	: box03Val3arr	,
                box03Val4arr	: box03Val4arr	,
                box03Val5arr	: box03Val5arr	,
                box03Val6arr	: box03Val6arr	,
                box03Val7arr	: box03Val7arr	,
                box03Val8arr	: box03Val8arr	,
                box03Val9arr	: box03Val9arr	,
                box03Val10arr	: box03Val10arr	,
                box04Val1arr	: box04Val1arr	,
                box04Val2arr	: box04Val2arr	,
                box04Val3arr	: box04Val3arr	,
                box04Val4arr	: box04Val4arr	,
                box04Val5arr	: box04Val5arr	,
                box04Val6arr	: box04Val6arr	,
                box04Val7arr	: box04Val7arr	,
                box04Val8arr	: box04Val8arr	,
                box04Val9arr	: box04Val9arr	,
                box04Val10arr	: box04Val10arr	,
                box04Val11arr	: box04Val11arr	,
                box04Val12arr	: box04Val12arr	,
                box04Val13arr	: box04Val13arr	,
                box04Val14arr	: box04Val14arr	,
                box04Val15arr	: box04Val15arr	,
                box04Val16arr	: box04Val16arr	,
                box04Val17arr	: box04Val17arr	,
                box04Val18arr	: box04Val18arr	,
                box04Val19arr	: box04Val19arr	,
                box04Val20arr	: box04Val20arr	,
                box04Val21arr	: box04Val21arr	,
                box04Val22arr	: box04Val22arr	,
                box04Val23arr	: box04Val23arr	,
                box04Val24arr	: box04Val24arr	,
                box04Val25arr	: box04Val25arr	,
                box04Val26arr	: box04Val26arr	,
                box04Val27arr	: box04Val27arr	,
                box04Val28arr	: box04Val28arr	,
                box04Val29arr	: box04Val29arr	,
                box04Val30arr	: box04Val30arr	,
                box04Val31arr	: box04Val31arr	,
                box04Val32arr	: box04Val32arr	,
                box04Val33arr	: box04Val33arr	,
                box04Val34arr	: box04Val34arr	,
                box04Val35arr	: box04Val35arr	,
                box04Val36arr	: box04Val36arr	,
                box04Val37arr	: box04Val37arr	,
                box04Val38arr	: box04Val38arr	,
                box04Val39arr	: box04Val39arr	,
                box04Val40arr	: box04Val40arr	,
                box04Val41arr	: box04Val41arr	,
                box04Val42arr	: box04Val42arr	,
                box04Val43arr	: box04Val43arr	,
                box04Val44arr	: box04Val44arr	,
                box04Val45arr	: box04Val45arr	,
                box04Val46arr	: box04Val46arr	,
                box04Val47arr	: box04Val47arr	,
                box04Val48arr	: box04Val48arr	,
                box05Val1arr	: box05Val1arr	,
                box05Val2arr	: box05Val2arr	,
                box05Val3arr	: box05Val3arr	,
                box05Val4arr	: box05Val4arr	,
                box05Val5arr	: box05Val5arr	,
                box05Val6arr	: box05Val6arr	,
                box05Val7arr	: box05Val7arr	,
                box05Val8arr	: box05Val8arr	,
                box05Val9arr	: box05Val9arr	,
                box05Val10arr	: box05Val10arr	,
                box05Val11arr	: box05Val11arr	,
                box05Val12arr	: box05Val12arr	,
                box05Val13arr	: box05Val13arr	,
                box05Val14arr	: box05Val14arr	,
                box05Val15arr	: box05Val15arr	,
                box05Val16arr	: box05Val16arr	,
                box05Val17arr	: box05Val17arr	,
                box05Val18arr	: box05Val18arr	,
                box05Val19arr	: box05Val19arr	,
                box05Val20arr	: box05Val20arr	,
                box05Val21arr	: box05Val21arr	,
                box05Val22arr	: box05Val22arr	,
                box05Val23arr	: box05Val23arr	,
                box05Val24arr	: box05Val24arr	,
                box05Val25arr	: box05Val25arr	,
                box05Val26arr	: box05Val26arr	,
                box05Val27arr	: box05Val27arr	,
                box05Val28arr	: box05Val28arr	,
                box05Val29arr	: box05Val29arr	,
                box05Val30arr	: box05Val30arr	,
                box05Val31arr	: box05Val31arr	,
                box05Val32arr	: box05Val32arr	,
                box05Val33arr	: box05Val33arr	,
                box05Val34arr	: box05Val34arr	,
                box05Val35arr	: box05Val35arr	,
                box05Val36arr	: box05Val36arr	,
                box05Val37arr	: box05Val37arr	,
                box05Val38arr	: box05Val38arr	,
                box05Val39arr	: box05Val39arr	,
                box05Val40arr	: box05Val40arr	,
                box05Val41arr	: box05Val41arr	,
                box05Val42arr	: box05Val42arr	,
                box05Val43arr	: box05Val43arr	,
                box05Val44arr	: box05Val44arr	,
                box05Val45arr	: box05Val45arr	,
                box05Val46arr	: box05Val46arr	,
                box05Val47arr	: box05Val47arr	,
                box05Val48arr	: box05Val48arr	,
                box06Val1arr	: box06Val1arr	,
                box06Val2arr	: box06Val2arr	,
                box06Val3arr	: box06Val3arr	,
                box06Val4arr	: box06Val4arr	,
                box06Val5arr	: box06Val5arr	,
                box06Val6arr	: box06Val6arr	,
                box06Val7arr	: box06Val7arr	,
                box06Val8arr	: box06Val8arr	,
                box06Val9arr	: box06Val9arr	,
                box06Val10arr	: box06Val10arr	,
                box06Val11arr	: box06Val11arr	,
                box06Val12arr	: box06Val12arr	,
                box06Val13arr	: box06Val13arr	,
                box06Val14arr	: box06Val14arr	,
                box06Val15arr	: box06Val15arr	,
                box06Val16arr	: box06Val16arr	,
                box06Val17arr	: box06Val17arr	,
                box06Val18arr	: box06Val18arr	,
                box06Val19arr	: box06Val19arr	,
                box06Val20arr	: box06Val20arr	,
                box06Val21arr	: box06Val21arr	,
                box06Val22arr	: box06Val22arr	,
                box06Val23arr	: box06Val23arr	,
                box06Val24arr	: box06Val24arr	,
                box06Val25arr	: box06Val25arr	,
                box06Val26arr	: box06Val26arr	,
                box06Val27arr	: box06Val27arr	,
                box06Val28arr	: box06Val28arr	,
                box06Val29arr	: box06Val29arr	,
                box06Val30arr	: box06Val30arr	,
                box06Val31arr	: box06Val31arr	,
                box06Val32arr	: box06Val32arr	,
                box06Val33arr	: box06Val33arr	,
                box06Val34arr	: box06Val34arr	,
                box06Val35arr	: box06Val35arr	,
                box06Val36arr	: box06Val36arr	,
                box06Val37arr	: box06Val37arr	,
                box06Val38arr	: box06Val38arr	,
                box06Val39arr	: box06Val39arr	,
                box06Val40arr	: box06Val40arr	,
                box06Val41arr	: box06Val41arr	,
                box06Val42arr	: box06Val42arr	,
                box06Val43arr	: box06Val43arr	,
                box06Val44arr	: box06Val44arr	,
                box06Val45arr	: box06Val45arr	,
                box06Val46arr	: box06Val46arr	,
                box06Val47arr	: box06Val47arr	,
                box06Val48arr	: box06Val48arr	,
                box07Val1arr	: box07Val1arr	,
                box07Val2arr	: box07Val2arr	,
                box07Val3arr	: box07Val3arr	,
                box07Val4arr	: box07Val4arr	,
                box07Val5arr	: box07Val5arr	,
                box07Val6arr	: box07Val6arr	,
                box07Val7arr	: box07Val7arr	,
                box07Val8arr	: box07Val8arr	,
                box07Val9arr	: box07Val9arr	,
                box07Val10arr	: box07Val10arr	,
                box07Val11arr	: box07Val11arr	,
                box07Val12arr	: box07Val12arr	,
                box07Val13arr	: box07Val13arr	,
                box07Val14arr	: box07Val14arr	,
                box07Val15arr	: box07Val15arr	,
                box07Val16arr	: box07Val16arr	,
                box07Val17arr	: box07Val17arr	,
                box07Val18arr	: box07Val18arr	,
                box07Val19arr	: box07Val19arr	,
                box07Val20arr	: box07Val20arr	,
                box07Val21arr	: box07Val21arr	,
                box07Val22arr	: box07Val22arr	,
                box07Val23arr	: box07Val23arr	,
                box07Val24arr	: box07Val24arr	,
                box07Val25arr	: box07Val25arr	,
                box07Val26arr	: box07Val26arr	,
                box07Val27arr	: box07Val27arr	,
                box07Val28arr	: box07Val28arr	,
                box07Val29arr	: box07Val29arr	,
                box07Val30arr	: box07Val30arr	,
                box07Val31arr	: box07Val31arr	,
                box07Val32arr	: box07Val32arr	,
                box07Val33arr	: box07Val33arr	,
                box07Val34arr	: box07Val34arr	,
                box07Val35arr	: box07Val35arr	,
                box07Val36arr	: box07Val36arr	,
                box07Val37arr	: box07Val37arr	,
                box07Val38arr	: box07Val38arr	,
                box07Val39arr	: box07Val39arr	,
                box07Val40arr	: box07Val40arr	,
                box07Val41arr	: box07Val41arr	,
                box07Val42arr	: box07Val42arr	,
                box07Val43arr	: box07Val43arr	,
                box07Val44arr	: box07Val44arr	,
                box07Val45arr	: box07Val45arr	,
                box07Val46arr	: box07Val46arr	,
                box07Val47arr	: box07Val47arr	,
                box07Val48arr	: box07Val48arr	,
                box08Val1arr	: box08Val1arr	,
                box08Val2arr	: box08Val2arr	,
                box08Val3arr	: box08Val3arr	,
                box08Val4arr	: box08Val4arr	,
                box08Val5arr	: box08Val5arr	,
                box08Val6arr	: box08Val6arr	,
                box08Val7arr	: box08Val7arr	,
                box09Val1arr	: box09Val1arr	,
                box09Val2arr	: box09Val2arr	,
                box09Val3arr	: box09Val3arr	,
                box09Val4arr	: box09Val4arr	,
                box09Val5arr	: box09Val5arr	,
                box09Val6arr	: box09Val6arr	,
                box09Val7arr	: box09Val7arr	,
                box10Val1arr	: box10Val1arr	,
                box10Val2arr	: box10Val2arr	,
                box10Val3arr	: box10Val3arr	,
                box10Val4arr	: box10Val4arr	,
                box10Val5arr	: box10Val5arr	,
                box10Val6arr	: box10Val6arr	,
                box10Val7arr	: box10Val7arr	,
                box11Val1arr	: box11Val1arr	,
                box11Val2arr	: box11Val2arr	,
                box11Val3arr	: box11Val3arr	,
                box11Val4arr	: box11Val4arr	,
                box11Val5arr	: box11Val5arr	,
                box11Val6arr	: box11Val6arr	,
                box11Val7arr	: box11Val7arr	,
                box12Val1arr	: box12Val1arr	,
                box12Val2arr	: box12Val2arr	,
                box12Val3arr	: box12Val3arr	,
                box12Val4arr	: box12Val4arr	,
                box12Val5arr	: box12Val5arr	,
                box12Val6arr	: box12Val6arr	,
                box12Val7arr	: box12Val7arr	,
                box13Val1arr	: box13Val1arr	,
                box13Val2arr	: box13Val2arr	,
                box13Val3arr	: box13Val3arr	,
                box13Val4arr	: box13Val4arr	,
                box13Val5arr	: box13Val5arr	,
                box13Val6arr	: box13Val6arr	,
                box13Val7arr	: box13Val7arr	,
                box14Val1arr	: box14Val1arr	,
                box14Val2arr	: box14Val2arr	,
                box14Val3arr	: box14Val3arr	,
                box14Val4arr	: box14Val4arr
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }



    const startAllEditingHandler = () => {
        setIsEditing01(true);
        setIsEditing02(true);
        setIsEditing03(true);
        setIsEditing04(true);
    };
    const start01EditingHandler = () => {
        setIsEditing01(true);
        setIsEditing02(false);
        setIsEditing03(false);
        setIsEditing04(false);
    };

    const start02EditingHandler = () => {
        setIsEditing01(false);
        setIsEditing02(true);
        setIsEditing03(false);
        setIsEditing04(false);
    };
    const start03EditingHandler = () => {
        setIsEditing01(false);
        setIsEditing02(false);
        setIsEditing03(true);
        setIsEditing04(false);
    };
    const start04EditingHandler = () => {
        setIsEditing01(false);
        setIsEditing02(false);
        setIsEditing03(false);
        setIsEditing04(true);
    };







    const [changeColorList, setChangeColorList] = useState([]);




    const getCmmnCd = () => {
        if(changeColorList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0023',
            }).then((res) => {
                setChangeColorList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])


    const [rowsData, setRowsData] = useState([]);
    const addTableRows = ()=>{

        const rowsInput={
            insertNo:'',
            insertEtc:'',
            insertColor:''
        }
        setRowsData([...rowsData, rowsInput])

    }
    const deleteTableRows = (index)=>{

        const rows = [...rowsData];
        //alert("index :: "+index);
        rows.splice(index, 1);
        alert("rows :: "+JSON.stringify(rows));
        setRowsData(rows);
    }

    const pointLevelCdChangeHandler = (e) => {
        setPointLevelCd(e.currentTarget.value);

        axios.post('/admin/setting/saveLevelOneSetting', {
            onceChargePoint: (onceChargePoint === "" ? countList.once_charge_point : onceChargePoint),
            minChangePoint: (minChangePoint === "" ? countList.min_change_point : minChangePoint),
            changePointUnit: (changePointUnit === "" ? countList.change_point_unit : changePointUnit),
            pointLevelCd: e.currentTarget.value
        }).then((res) => {
            //alert(res.data.isUpdated? "수정완료" : "수정실패")
            //getAllList()
            //window.location.reload();
            //onRefresh();
            window.location.reload();
        })
        // dispatch(getMoneyList({
        //     page: initialState.page,
        //     pageSize: initialState.pageSize,
        //     order: initialState.order,
        //     query: searchQuery,
        //     startDate: startDate? startDate : initialState.startDate,
        //     endDate: endDate? endDate : initialState.endDate,
        //     userGbCd: e.currentTarget.value,
        //     searchGbCd: initialState.searchGbCd
        // }))
    }

    const start01Editing = () => {
        //childComponentRef.current.willBeUsedInParentComponent();
    };






    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }





    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <div className="container-fluid p-0 mx-1" style={{
                            display: "flex"
                        }}>
                            <table width={"99%"}>
                                <tr>
                                    <td align={"right"}>
                                        <button style={{
                                            width: "80px"
                                        }} type="submit" className="btn btn-primary" onClick={() => {updateState()}}>전체 저장
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <div className="container-fluid p-0 mx-1" style={{
                            display: "flex"
                        }}>
                            <br></br>
                            <table className="table table-bordered table-sm text-center align-middle">
                                <tr>
                                    <td style={{backgroundColor: "lightgray"}} align={"center"} width={"10%"}>가입후 첫충전시 지급 포인트%
                                    </td>
                                    <td width={"1%"}></td>
                                    <td width={"15%"} align={"left"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                                   placeholder={countList.once_charge_point}
                                                   value={onceChargePoint}
                                                   onChange={(e) => setOnceChargePoint(e.target.value)}
                                            />
                                            %
                                        </div>
                                        * 0일 경우, 레벨 별 설정을 따름.
                                    </td>
                                    <td style={{backgroundColor: "lightgray"}} align={"center"} width={"10%"}>최소 전환 포인트</td>
                                    <td width={"1%"}></td>
                                    <td width={"15%"} align={"left"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                                   placeholder={addComma(countList.min_change_point)}
                                                   value={minChangePoint}
                                                   onChange={(e) => setMinChangePoint(e.target.value)}
                                            />
                                            p
                                        </div>
                                    </td>
                                    <td style={{backgroundColor: "lightgray"}} align={"center"} width={"10%"}>전환 가능 포인트 단위</td>
                                    <td width={"1%"}></td>
                                    <td width={"15%"} align={"left"} >
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                                   placeholder={addComma(countList.change_point_unit)}
                                                   value={changePointUnit}
                                                   onChange={(e) => setChangePointUnit(e.target.value)}
                                            />
                                            p
                                        </div>
                                    </td>
                                    <td style={{backgroundColor: "lightgray"}} align={"center"} width={"10%"}>포인트 레벨 사용</td>
                                    <td width={"1%"}></td>
                                    <td width={"15%"} align={"left"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <select
                                                className="form-select sm-select"
                                                defaultValue={7}
                                                style={{
                                                    width: "70px"
                                                }}
                                                value={( pointLevelCd === "" ? countList.point_level_cd : pointLevelCd)}
                                                onChange={pointLevelCdChangeHandler}
                                            >

                                                {selectArr.map((selectArr, index) => (
                                                    <option value={selectArr}>{selectArr}개</option>
                                                ))}
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="8" height={"70px"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            * 각 레벨에 있는
                                            <button className="ms-2" style={{
                                                backgroundColor: "#CECECE",
                                                border: "1px solid black",
                                                fontSize: "9px",
                                                height: "30px",
                                                width: "fit-content"
                                            }}>▼copy
                                            </button>
                                            &nbsp;버튼을 누르시면 아래쪽 레벨을 모두 동일한 값으로 셋팅됩니다.
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex", backgroundColor: "gray"
                    }}>
                        <button style={{
                            backgroundColor: "#CECECE",
                            border: "none",
                            color: "black",
                            fontSize: "9px",
                            height: "29px",
                            width: "fit-content",
                            marginRight: "5px"
                        }}
                                onClick={() => {startAllEditingHandler()}}
                        >전체보기
                        </button>
                        <button style={{
                            backgroundColor: "#CECECE",
                            border: "none",
                            color: "black",
                            fontSize: "9px",
                            height: "29px",
                            width: "fit-content",
                            marginRight: "5px"
                        }}
                                onClick={() => {start01EditingHandler()}}
                        >보상
                        </button>
                        <button style={{
                            backgroundColor: "#CECECE",
                            border: "none",
                            color: "black",
                            fontSize: "9px",
                            height: "29px",
                            width: "fit-content",
                            marginRight: "5px"
                        }}
                                onClick={() => {start02EditingHandler()}}
                        >베팅금/당첨금
                        </button>
                        <button style={{
                            backgroundColor: "#CECECE",
                            border: "none",
                            color: "black",
                            fontSize: "9px",
                            height: "29px",
                            width: "fit-content",
                            marginRight: "5px"
                        }}
                                onClick={() => {start03EditingHandler()}}
                        >축폴더
                        </button>
                        <button style={{
                            backgroundColor: "#CECECE",
                            border: "none",
                            color: "black",
                            fontSize: "9px",
                            height: "29px",
                            width: "fit-content",
                            marginRight: "5px"
                        }}
                                onClick={() => {start04EditingHandler()}}
                        >기타
                        </button>

                    </div>
                </div>


                {listArr.map((listArr, index2) => (



                    (countList.point_level_cd > index2) && (

                        <div>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex", backgroundColor: "lightskyblue"
                                }}>
                                    &nbsp;&nbsp;&nbsp;<font size="4"><b>Level key [{index2+1}] 관리자 표시 레벨 : </b></font>
                                    &nbsp;
                                    <input style={{
                                        width: "70px"
                                    }} type="text" className="form-control" id="search_edit" placeholder={arrayList[index2].admin_level} onBlur={(e) => {
                                    }}
                                           onChange={(e) => {adminLevelNoHandler(e, index2)}}
                                    />&nbsp;
                                    <font size="4"><b>
                                        / 사용자 표시 레벨 :
                                    </b></font>&nbsp;
                                    <input style={{
                                        width: "70px"
                                    }} type="text" className="form-control" id="search_edit" placeholder={arrayList[index2].user_level} onBlur={(e) => {
                                    }}
                                           onChange={(e) => {userLevelNoHandler(e, index2)}}
                                    />&nbsp;
                                    <font size="4"><b>/ 설명 :</b></font>&nbsp;
                                    <input style={{
                                        width: "300px"
                                    }} type="text" className="form-control" id="search_edit" placeholder={arrayList[index2].etc} onBlur={(e) => {
                                    }}
                                           onChange={(e) => {etcNoHandler(e, index2)}}
                                    />&nbsp;
                                    <button className="ms-2" style={{
                                        backgroundColor: "#CECECE",
                                        border: "1px solid black",
                                        fontSize: "9px",
                                        height: "30px",
                                        width: "fit-content"
                                    }}
                                            onClick={(e) => {arrayAllCopyHandler("1", index2, countList.point_level_cd)}}
                                    >▼아래로 전체 복사 (PID_1)
                                    </button>
                                    <button className="ms-2" style={{
                                        backgroundColor: "#CECECE",
                                        border: "1px solid black",
                                        fontSize: "9px",
                                        height: "30px",
                                        width: "fit-content"
                                    }}
                                            onClick={(e) => {arrayAllCopyHandler("2", index2, index2+1)}}
                                    >▼아래로 한 칸 복사 (PID_1)
                                    </button>
                                </div>
                            </div>


                            <br></br>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                {isEditing01 && (

                                    <div style={{border: "solid gray", width: "250px", height: "240px", backgroundColor: "gray"}}
                                         align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}}>로그인</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>당일 첫 로그인 / {initialState.box01Val1array}</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit1_1"
                                                               defaultValue={( initialState.box01Val1array[index2]? initialState.box01Val1array[index2]: arrayList[index2].box01_val1 )}
                                                               key={( initialState.box01Val1array[index2]? initialState.box01Val1array[index2]: arrayList[index2].box01_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "1", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="P" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="2">낙첨</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>본인 낙첨</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit1_2"
                                                               defaultValue={( initialState.box01Val2array[index2]? initialState.box01Val2array[index2]: arrayList[index2].box01_val2 )}
                                                               key={( initialState.box01Val2array[index2]? initialState.box01Val2array[index2]: arrayList[index2].box01_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "1", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>추천인 낙첨</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit1_3"
                                                               key={( initialState.box01Val3array[index2]? initialState.box01Val3array[index2]: arrayList[index2].box01_val3 )}
                                                               defaultValue={( initialState.box01Val3array[index2]? initialState.box01Val3array[index2]: arrayList[index2].box01_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "1", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="2">게시글 작성</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>작성시</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit1_4"
                                                               key={( initialState.box01Val4array[index2]? initialState.box01Val4array[index2]: arrayList[index2].box01_val4 )}
                                                               defaultValue={( initialState.box01Val4array[index2]? initialState.box01Val4array[index2]: arrayList[index2].box01_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "1", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="P" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일일 최대</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit1_5"
                                                               key={( initialState.box01Val5array[index2]? initialState.box01Val5array[index2]: arrayList[index2].box01_val5 )}
                                                               defaultValue={( initialState.box01Val5array[index2]? initialState.box01Val5array[index2]: arrayList[index2].box01_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "1", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="P" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="2">댓글 작성</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>작성시</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit1_6"
                                                               key={( initialState.box01Val6array[index2]? initialState.box01Val6array[index2]: arrayList[index2].box01_val6 )}
                                                               defaultValue={( initialState.box01Val6array[index2]? initialState.box01Val6array[index2]: arrayList[index2].box01_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "1", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="P" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일일 최대</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit1_7"
                                                               key={( initialState.box01Val7array[index2]? initialState.box01Val7array[index2]: arrayList[index2].box01_val7 )}
                                                               defaultValue={( initialState.box01Val7array[index2]? initialState.box01Val7array[index2]: arrayList[index2].box01_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "1", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="P" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                                &nbsp;&nbsp;
                                {isEditing01 && (
                                    <div style={{border: "solid gray", width: "270px", height: "450px", backgroundColor: "gray"}}
                                         align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}}>본인 매충전시</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>지급 포인트</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_1"
                                                               key={( initialState.box02Val1array[index2]? initialState.box02Val1array[index2]: arrayList[index2].box02_val1 )}
                                                               defaultValue={( initialState.box02Val1array[index2]? initialState.box02Val1array[index2]: arrayList[index2].box02_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="2">본인 매충/첫충</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일일 최대<br></br>지급 포인트</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_2"
                                                               key={( initialState.box02Val2array[index2]? initialState.box02Val2array[index2]: arrayList[index2].box02_val2 )}
                                                               defaultValue={( initialState.box02Val2array[index2]? initialState.box02Val2array[index2]: arrayList[index2].box02_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="P" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일일 최대<br></br>지급 횟수</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_3"
                                                               key={( initialState.box02Val3array[index2]? initialState.box02Val3array[index2]: arrayList[index2].box02_val3 )}
                                                               defaultValue={( initialState.box02Val3array[index2]? initialState.box02Val3array[index2]: arrayList[index2].box02_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="회" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="7">본인 첫충전<br></br>요일별</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_4"
                                                               key={( initialState.box02Val4array[index2]? initialState.box02Val4array[index2]: arrayList[index2].box02_val4 )}
                                                               defaultValue={( initialState.box02Val4array[index2]? initialState.box02Val4array[index2]: arrayList[index2].box02_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>월</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_5"
                                                               key={( initialState.box02Val5array[index2]? initialState.box02Val5array[index2]: arrayList[index2].box02_val5 )}
                                                               defaultValue={( initialState.box02Val5array[index2]? initialState.box02Val5array[index2]: arrayList[index2].box02_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>화</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_6"
                                                               key={( initialState.box02Val6array[index2]? initialState.box02Val6array[index2]: arrayList[index2].box02_val6 )}
                                                               defaultValue={( initialState.box02Val6array[index2]? initialState.box02Val6array[index2]: arrayList[index2].box02_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>수</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_7"
                                                               key={( initialState.box02Val7array[index2]? initialState.box02Val7array[index2]: arrayList[index2].box02_val7 )}
                                                               defaultValue={( initialState.box02Val7array[index2]? initialState.box02Val7array[index2]: arrayList[index2].box02_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>목</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_8"
                                                               key={( initialState.box02Val8array[index2]? initialState.box02Val8array[index2]: arrayList[index2].box02_val8 )}
                                                               defaultValue={( initialState.box02Val8array[index2]? initialState.box02Val8array[index2]: arrayList[index2].box02_val8 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "8")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>금</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_9"
                                                               key={( initialState.box02Val9array[index2]? initialState.box02Val9array[index2]: arrayList[index2].box02_val9 )}
                                                               defaultValue={( initialState.box02Val9array[index2]? initialState.box02Val9array[index2]: arrayList[index2].box02_val9 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "9")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>토</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_10"
                                                               key={( initialState.box02Val10array[index2]? initialState.box02Val10array[index2]: arrayList[index2].box02_val10 )}
                                                               defaultValue={( initialState.box02Val10array[index2]? initialState.box02Val10array[index2]: arrayList[index2].box02_val10 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "10")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}}>지인 매충전시</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>추천인 지급<br></br>포인트</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_11"
                                                               key={( initialState.box02Val11array[index2]? initialState.box02Val11array[index2]: arrayList[index2].box02_val11 )}
                                                               defaultValue={( initialState.box02Val11array[index2]? initialState.box02Val11array[index2]: arrayList[index2].box02_val11 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "11")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}}>지인 첫충전시</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>추천인 지급<br></br>포인트</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_12"
                                                               key={( initialState.box02Val12array[index2]? initialState.box02Val12array[index2]: arrayList[index2].box02_val12 )}
                                                               defaultValue={( initialState.box02Val12array[index2]? initialState.box02Val12array[index2]: arrayList[index2].box02_val12 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "12")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}}>지인 매충/첫충</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일일 최대<br></br>지급 포인트</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit2_13"
                                                               key={( initialState.box02Val13array[index2]? initialState.box02Val13array[index2]: arrayList[index2].box02_val13 )}
                                                               defaultValue={( initialState.box02Val13array[index2]? initialState.box02Val13array[index2]: arrayList[index2].box02_val13 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "2", "13")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>


                                        </table>
                                    </div>
                                )}
                                &nbsp;&nbsp;
                                {isEditing01 && (
                                    <div style={{border: "solid gray", width: "300px", height: "355px", backgroundColor: "gray"}}
                                         align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}}>베팅</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>1일 베팅취소<br></br>가능횟수<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit3_1"
                                                               key={( initialState.box03Val1array[index2]? initialState.box03Val1array[index2]: arrayList[index2].box03_val1 )}
                                                               defaultValue={( initialState.box03Val1array[index2]? initialState.box03Val1array[index2]: arrayList[index2].box03_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "3", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="회" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="18">다폴더 추가</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>2폴더</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit3_2"
                                                               key={( initialState.box03Val2array[index2]? initialState.box03Val2array[index2]: arrayList[index2].box03_val2 )}
                                                               defaultValue={( initialState.box03Val2array[index2]? initialState.box03Val2array[index2]: arrayList[index2].box03_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "3", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>3폴더</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit3_3"
                                                               key={( initialState.box03Val3array[index2]? initialState.box03Val3array[index2]: arrayList[index2].box03_val3 )}
                                                               defaultValue={( initialState.box03Val3array[index2]? initialState.box03Val3array[index2]: arrayList[index2].box03_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "3", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>4폴더</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit3_4"
                                                               key={( initialState.box03Val4array[index2]? initialState.box03Val4array[index2]: arrayList[index2].box03_val4 )}
                                                               defaultValue={( initialState.box03Val4array[index2]? initialState.box03Val4array[index2]: arrayList[index2].box03_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "3", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>5폴더</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit3_5"
                                                               key={( initialState.box03Val5array[index2]? initialState.box03Val5array[index2]: arrayList[index2].box03_val5 )}
                                                               defaultValue={( initialState.box03Val5array[index2]? initialState.box03Val5array[index2]: arrayList[index2].box03_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "3", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>6폴더</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit3_6"
                                                               key={( initialState.box03Val6array[index2]? initialState.box03Val6array[index2]: arrayList[index2].box03_val6 )}
                                                               defaultValue={( initialState.box03Val6array[index2]? initialState.box03Val6array[index2]: arrayList[index2].box03_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "3", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>7폴더</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit3_7"
                                                               key={( initialState.box03Val7array[index2]? initialState.box03Val7array[index2]: arrayList[index2].box03_val7 )}
                                                               defaultValue={( initialState.box03Val7array[index2]? initialState.box03Val7array[index2]: arrayList[index2].box03_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "3", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>8폴더</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit3_8"
                                                               key={( initialState.box03Val8array[index2]? initialState.box03Val8array[index2]: arrayList[index2].box03_val8 )}
                                                               defaultValue={( initialState.box03Val8array[index2]? initialState.box03Val8array[index2]: arrayList[index2].box03_val8 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "3", "8")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>9폴더</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit3_9"
                                                               key={( initialState.box03Val9array[index2]? initialState.box03Val9array[index2]: arrayList[index2].box03_val9 )}
                                                               defaultValue={( initialState.box03Val9array[index2]? initialState.box03Val9array[index2]: arrayList[index2].box03_val9 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "3", "9")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>10폴더 이상</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit3_10"
                                                               key={( initialState.box03Val10array[index2]? initialState.box03Val10array[index2]: arrayList[index2].box03_val10 )}
                                                               defaultValue={( initialState.box03Val10array[index2]? initialState.box03Val10array[index2]: arrayList[index2].box03_val10 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "3", "10")}}
                                                        />&nbsp;
                                                        <input type="button" value="%" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                            </div>
                            <br></br>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                {isEditing02 && (
                                    <div style={{border: "solid gray", width: "260px", height: "1605px", backgroundColor: "gray"}} align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="100">최대 단폴<br></br>베팅금</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크로스</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_1"
                                                               key={( initialState.box04Val1array[index2]? initialState.box04Val1array[index2]: arrayList[index2].box04_val1 )}
                                                               defaultValue={( initialState.box04Val1array[index2]? initialState.box04Val1array[index2]: arrayList[index2].box04_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1" style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크로스2</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_2"
                                                               key={( initialState.box04Val2array[index2]? initialState.box04Val2array[index2]: arrayList[index2].box04_val2 )}
                                                               defaultValue={( initialState.box04Val2array[index2]? initialState.box04Val2array[index2]: arrayList[index2].box04_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일반게임</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_3"
                                                               key={( initialState.box04Val3array[index2]? initialState.box04Val3array[index2]: arrayList[index2].box04_val3 )}
                                                               defaultValue={( initialState.box04Val3array[index2]? initialState.box04Val3array[index2]: arrayList[index2].box04_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>핸디캡</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_4"
                                                               key={( initialState.box04Val4array[index2]? initialState.box04Val4array[index2]: arrayList[index2].box04_val4 )}
                                                               defaultValue={( initialState.box04Val4array[index2]? initialState.box04Val4array[index2]: arrayList[index2].box04_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스폐셜</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_5"
                                                               key={( initialState.box04Val5array[index2]? initialState.box04Val5array[index2]: arrayList[index2].box04_val5 )}
                                                               defaultValue={( initialState.box04Val5array[index2]? initialState.box04Val5array[index2]: arrayList[index2].box04_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>실시간</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_6"
                                                               key={( initialState.box04Val6array[index2]? initialState.box04Val6array[index2]: arrayList[index2].box04_val6 )}
                                                               defaultValue={( initialState.box04Val6array[index2]? initialState.box04Val6array[index2]: arrayList[index2].box04_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_7"
                                                               key={( initialState.box04Val7array[index2]? initialState.box04Val7array[index2]: arrayList[index2].box04_val7 )}
                                                               defaultValue={( initialState.box04Val7array[index2]? initialState.box04Val7array[index2]: arrayList[index2].box04_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_8"
                                                               key={( initialState.box04Val8array[index2]? initialState.box04Val8array[index2]: arrayList[index2].box04_val8 )}
                                                               defaultValue={( initialState.box04Val8array[index2]? initialState.box04Val8array[index2]: arrayList[index2].box04_val8 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "8")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 축구</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_9"
                                                               key={( initialState.box04Val9array[index2]? initialState.box04Val9array[index2]: arrayList[index2].box04_val9 )}
                                                               defaultValue={( initialState.box04Val9array[index2]? initialState.box04Val9array[index2]: arrayList[index2].box04_val9 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "9")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 개경주</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_10"
                                                               key={( initialState.box04Val10array[index2]? initialState.box04Val10array[index2]: arrayList[index2].box04_val10 )}
                                                               defaultValue={( initialState.box04Val10array[index2]? initialState.box04Val10array[index2]: arrayList[index2].box04_val10 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "10")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 경마</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_11"
                                                               key={( initialState.box04Val11array[index2]? initialState.box04Val11array[index2]: arrayList[index2].box04_val11 )}
                                                               defaultValue={( initialState.box04Val11array[index2]? initialState.box04Val11array[index2]: arrayList[index2].box04_val11 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "11")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 농구</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_12"
                                                               key={( initialState.box04Val12array[index2]? initialState.box04Val12array[index2]: arrayList[index2].box04_val12 )}
                                                               defaultValue={( initialState.box04Val12array[index2]? initialState.box04Val12array[index2]: arrayList[index2].box04_val12 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "12")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>썬시티바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_13"
                                                               key={( initialState.box04Val13array[index2]? initialState.box04Val13array[index2]: arrayList[index2].box04_val13 )}
                                                               defaultValue={( initialState.box04Val13array[index2]? initialState.box04Val13array[index2]: arrayList[index2].box04_val13 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "13")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>키노사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_14"
                                                               key={( initialState.box04Val14array[index2]? initialState.box04Val14array[index2]: arrayList[index2].box04_val14 )}
                                                               defaultValue={( initialState.box04Val14array[index2]? initialState.box04Val14array[index2]: arrayList[index2].box04_val14 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "14")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>로투스바카라1</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_15"
                                                               key={( initialState.box04Val15array[index2]? initialState.box04Val15array[index2]: arrayList[index2].box04_val15 )}
                                                               defaultValue={( initialState.box04Val15array[index2]? initialState.box04Val15array[index2]: arrayList[index2].box04_val15 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "15")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>로투스홀짝</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_16"
                                                               key={( initialState.box04Val16array[index2]? initialState.box04Val16array[index2]: arrayList[index2].box04_val16 )}
                                                               defaultValue={( initialState.box04Val16array[index2]? initialState.box04Val16array[index2]: arrayList[index2].box04_val16 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "16")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스피드키노</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_17"
                                                               key={( initialState.box04Val17array[index2]? initialState.box04Val17array[index2]: arrayList[index2].box04_val17 )}
                                                               defaultValue={( initialState.box04Val17array[index2]? initialState.box04Val17array[index2]: arrayList[index2].box04_val17 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "17")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>라이언무지</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_18"
                                                               key={( initialState.box04Val18array[index2]? initialState.box04Val18array[index2]: arrayList[index2].box04_val18 )}
                                                               defaultValue={( initialState.box04Val18array[index2]? initialState.box04Val18array[index2]: arrayList[index2].box04_val18 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "18")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>황금돼지</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_19"
                                                               key={( initialState.box04Val19array[index2]? initialState.box04Val19array[index2]: arrayList[index2].box04_val19 )}
                                                               defaultValue={( initialState.box04Val19array[index2]? initialState.box04Val19array[index2]: arrayList[index2].box04_val19 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "19")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스플릿</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_20"
                                                               key={( initialState.box04Val20array[index2]? initialState.box04Val20array[index2]: arrayList[index2].box04_val20 )}
                                                               defaultValue={( initialState.box04Val20array[index2]? initialState.box04Val20array[index2]: arrayList[index2].box04_val20 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "20")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_21"
                                                               key={( initialState.box04Val21array[index2]? initialState.box04Val21array[index2]: arrayList[index2].box04_val21 )}
                                                               defaultValue={( initialState.box04Val21array[index2]? initialState.box04Val21array[index2]: arrayList[index2].box04_val21 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "21")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>바이낸스1분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_22"
                                                               key={( initialState.box04Val22array[index2]? initialState.box04Val22array[index2]: arrayList[index2].box04_val22 )}
                                                               defaultValue={( initialState.box04Val22array[index2]? initialState.box04Val22array[index2]: arrayList[index2].box04_val22 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "22")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>바이낸스3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_23"
                                                               key={( initialState.box04Val23array[index2]? initialState.box04Val23array[index2]: arrayList[index2].box04_val23 )}
                                                               defaultValue={( initialState.box04Val23array[index2]? initialState.box04Val23array[index2]: arrayList[index2].box04_val23 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "23")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이룰렛</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_24"
                                                               key={( initialState.box04Val24array[index2]? initialState.box04Val24array[index2]: arrayList[index2].box04_val24 )}
                                                               defaultValue={( initialState.box04Val24array[index2]? initialState.box04Val24array[index2]: arrayList[index2].box04_val24 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "24")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이홈런</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_25"
                                                               key={( initialState.box04Val25array[index2]? initialState.box04Val25array[index2]: arrayList[index2].box04_val25 )}
                                                               defaultValue={( initialState.box04Val25array[index2]? initialState.box04Val25array[index2]: arrayList[index2].box04_val25 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "25")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워프리킥</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_26"
                                                               key={( initialState.box04Val26array[index2]? initialState.box04Val26array[index2]: arrayList[index2].box04_val26 )}
                                                               defaultValue={( initialState.box04Val26array[index2]? initialState.box04Val26array[index2]: arrayList[index2].box04_val26 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "26")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_27"
                                                               key={( initialState.box04Val27array[index2]? initialState.box04Val27array[index2]: arrayList[index2].box04_val27 )}
                                                               defaultValue={( initialState.box04Val27array[index2]? initialState.box04Val27array[index2]: arrayList[index2].box04_val27 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "27")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_28"
                                                               key={( initialState.box04Val28array[index2]? initialState.box04Val28array[index2]: arrayList[index2].box04_val28 )}
                                                               defaultValue={( initialState.box04Val28array[index2]? initialState.box04Val28array[index2]: arrayList[index2].box04_val28 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "28")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_29"
                                                               key={( initialState.box04Val29array[index2]? initialState.box04Val29array[index2]: arrayList[index2].box04_val29 )}
                                                               defaultValue={( initialState.box04Val29array[index2]? initialState.box04Val29array[index2]: arrayList[index2].box04_val29 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "29")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>보글 사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_30"
                                                               key={( initialState.box04Val30array[index2]? initialState.box04Val30array[index2]: arrayList[index2].box04_val30 )}
                                                               defaultValue={( initialState.box04Val30array[index2]? initialState.box04Val30array[index2]: arrayList[index2].box04_val30 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "30")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>보글 파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_31"
                                                               key={( initialState.box04Val31array[index2]? initialState.box04Val31array[index2]: arrayList[index2].box04_val31 )}
                                                               defaultValue={( initialState.box04Val31array[index2]? initialState.box04Val31array[index2]: arrayList[index2].box04_val31 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "31")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 블랙잭</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_32"
                                                               key={( initialState.box04Val32array[index2]? initialState.box04Val32array[index2]: arrayList[index2].box04_val32 )}
                                                               defaultValue={( initialState.box04Val32array[index2]? initialState.box04Val32array[index2]: arrayList[index2].box04_val32 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "32")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 바카라1</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_33"
                                                               key={( initialState.box04Val33array[index2]? initialState.box04Val33array[index2]: arrayList[index2].box04_val33 )}
                                                               defaultValue={( initialState.box04Val33array[index2]? initialState.box04Val33array[index2]: arrayList[index2].box04_val33 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "33")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 룰렛</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_34"
                                                               key={( initialState.box04Val34array[index2]? initialState.box04Val34array[index2]: arrayList[index2].box04_val34 )}
                                                               defaultValue={( initialState.box04Val34array[index2]? initialState.box04Val34array[index2]: arrayList[index2].box04_val34 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "34")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 홀짝</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_35"
                                                               key={( initialState.box04Val35array[index2]? initialState.box04Val35array[index2]: arrayList[index2].box04_val35 )}
                                                               defaultValue={( initialState.box04Val35array[index2]? initialState.box04Val35array[index2]: arrayList[index2].box04_val35 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "35")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 주사위</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_36"
                                                               key={( initialState.box04Val36array[index2]? initialState.box04Val36array[index2]: arrayList[index2].box04_val36 )}
                                                               defaultValue={( initialState.box04Val36array[index2]? initialState.box04Val36array[index2]: arrayList[index2].box04_val36 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "36")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 스피드바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_37"
                                                               key={( initialState.box04Val37array[index2]? initialState.box04Val37array[index2]: arrayList[index2].box04_val37 )}
                                                               defaultValue={( initialState.box04Val37array[index2]? initialState.box04Val37array[index2]: arrayList[index2].box04_val37 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "37")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 드래곤타이거</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_38"
                                                               key={( initialState.box04Val38array[index2]? initialState.box04Val38array[index2]: arrayList[index2].box04_val38 )}
                                                               defaultValue={( initialState.box04Val38array[index2]? initialState.box04Val38array[index2]: arrayList[index2].box04_val38 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "38")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 삼치기</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_39"
                                                               key={( initialState.box04Val39array[index2]? initialState.box04Val39array[index2]: arrayList[index2].box04_val39 )}
                                                               defaultValue={( initialState.box04Val39array[index2]? initialState.box04Val39array[index2]: arrayList[index2].box04_val39 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "39")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼1m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_40"
                                                               key={( initialState.box04Val40array[index2]? initialState.box04Val40array[index2]: arrayList[index2].box04_val40 )}
                                                               defaultValue={( initialState.box04Val40array[index2]? initialState.box04Val40array[index2]: arrayList[index2].box04_val40 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "40")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼3m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_41"
                                                               key={( initialState.box04Val41array[index2]? initialState.box04Val41array[index2]: arrayList[index2].box04_val41 )}
                                                               defaultValue={( initialState.box04Val41array[index2]? initialState.box04Val41array[index2]: arrayList[index2].box04_val41 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "41")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼5m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_42"
                                                               key={( initialState.box04Val42array[index2]? initialState.box04Val42array[index2]: arrayList[index2].box04_val42 )}
                                                               defaultValue={( initialState.box04Val42array[index2]? initialState.box04Val42array[index2]: arrayList[index2].box04_val42 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "42")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워볼3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_43"
                                                               key={( initialState.box04Val43array[index2]? initialState.box04Val43array[index2]: arrayList[index2].box04_val43 )}
                                                               defaultValue={( initialState.box04Val43array[index2]? initialState.box04Val43array[index2]: arrayList[index2].box04_val43 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "43")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워볼5분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_44"
                                                               key={( initialState.box04Val44array[index2]? initialState.box04Val44array[index2]: arrayList[index2].box04_val44 )}
                                                               defaultValue={( initialState.box04Val44array[index2]? initialState.box04Val44array[index2]: arrayList[index2].box04_val44 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "44")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워사다리3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_45"
                                                               key={( initialState.box04Val45array[index2]? initialState.box04Val45array[index2]: arrayList[index2].box04_val45 )}
                                                               defaultValue={( initialState.box04Val45array[index2]? initialState.box04Val45array[index2]: arrayList[index2].box04_val45 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "45")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워사다리5분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_46"
                                                               key={( initialState.box04Val46array[index2]? initialState.box04Val46array[index2]: arrayList[index2].box04_val46 )}
                                                               defaultValue={( initialState.box04Val46array[index2]? initialState.box04Val46array[index2]: arrayList[index2].box04_val46 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "46")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크라운섯다</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_47"
                                                               key={( initialState.box04Val47array[index2]? initialState.box04Val47array[index2]: arrayList[index2].box04_val47 )}
                                                               defaultValue={( initialState.box04Val47array[index2]? initialState.box04Val47array[index2]: arrayList[index2].box04_val47 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "47")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크라운하이로우</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit4_48"
                                                               key={( initialState.box04Val48array[index2]? initialState.box04Val48array[index2]: arrayList[index2].box04_val48 )}
                                                               defaultValue={( initialState.box04Val48array[index2]? initialState.box04Val48array[index2]: arrayList[index2].box04_val48 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "4", "48")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {isEditing02 && (
                                    <div style={{border: "solid gray", width: "260px", height: "1605px", backgroundColor: "gray"}} align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="100">최대 단폴<br></br>당첨금</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크로스</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_1"
                                                               key={( initialState.box05Val1array[index2]? initialState.box05Val1array[index2]: arrayList[index2].box05_val1 )}
                                                               defaultValue={( initialState.box05Val1array[index2]? initialState.box05Val1array[index2]: arrayList[index2].box05_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1" style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크로스2</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_2"
                                                               key={( initialState.box05Val2array[index2]? initialState.box05Val2array[index2]: arrayList[index2].box05_val2 )}
                                                               defaultValue={( initialState.box05Val2array[index2]? initialState.box05Val2array[index2]: arrayList[index2].box05_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일반게임</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_3"
                                                               key={( initialState.box05Val3array[index2]? initialState.box05Val3array[index2]: arrayList[index2].box05_val3 )}
                                                               defaultValue={( initialState.box05Val3array[index2]? initialState.box05Val3array[index2]: arrayList[index2].box05_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>핸디캡</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_4"
                                                               key={( initialState.box05Val4array[index2]? initialState.box05Val4array[index2]: arrayList[index2].box05_val4 )}
                                                               defaultValue={( initialState.box05Val4array[index2]? initialState.box05Val4array[index2]: arrayList[index2].box05_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스폐셜</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_5"
                                                               key={( initialState.box05Val5array[index2]? initialState.box05Val5array[index2]: arrayList[index2].box05_val5 )}
                                                               defaultValue={( initialState.box05Val5array[index2]? initialState.box05Val5array[index2]: arrayList[index2].box05_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>실시간</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_6"
                                                               key={( initialState.box05Val6array[index2]? initialState.box05Val6array[index2]: arrayList[index2].box05_val6 )}
                                                               defaultValue={( initialState.box05Val6array[index2]? initialState.box05Val6array[index2]: arrayList[index2].box05_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_7"
                                                               key={( initialState.box05Val7array[index2]? initialState.box05Val7array[index2]: arrayList[index2].box05_val7 )}
                                                               defaultValue={( initialState.box05Val7array[index2]? initialState.box05Val7array[index2]: arrayList[index2].box05_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_8"
                                                               key={( initialState.box05Val8array[index2]? initialState.box05Val8array[index2]: arrayList[index2].box05_val8 )}
                                                               defaultValue={( initialState.box05Val8array[index2]? initialState.box05Val8array[index2]: arrayList[index2].box05_val8 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "8")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 축구</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_9"
                                                               key={( initialState.box05Val9array[index2]? initialState.box05Val9array[index2]: arrayList[index2].box05_val9 )}
                                                               defaultValue={( initialState.box05Val9array[index2]? initialState.box05Val9array[index2]: arrayList[index2].box05_val9 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "9")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 개경주</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_10"
                                                               key={( initialState.box05Val10array[index2]? initialState.box05Val10array[index2]: arrayList[index2].box05_val10 )}
                                                               defaultValue={( initialState.box05Val10array[index2]? initialState.box05Val10array[index2]: arrayList[index2].box05_val10 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "10")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 경마</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_11"
                                                               key={( initialState.box05Val11array[index2]? initialState.box05Val11array[index2]: arrayList[index2].box05_val11 )}
                                                               defaultValue={( initialState.box05Val11array[index2]? initialState.box05Val11array[index2]: arrayList[index2].box05_val11 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "11")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 농구</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_12"
                                                               key={( initialState.box05Val12array[index2]? initialState.box05Val12array[index2]: arrayList[index2].box05_val12 )}
                                                               defaultValue={( initialState.box05Val12array[index2]? initialState.box05Val12array[index2]: arrayList[index2].box05_val12 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "12")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>썬시티바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_13"
                                                               key={( initialState.box05Val13array[index2]? initialState.box05Val13array[index2]: arrayList[index2].box05_val13 )}
                                                               defaultValue={( initialState.box05Val13array[index2]? initialState.box05Val13array[index2]: arrayList[index2].box05_val13 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "13")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>키노사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_14"
                                                               key={( initialState.box05Val14array[index2]? initialState.box05Val14array[index2]: arrayList[index2].box05_val14 )}
                                                               defaultValue={( initialState.box05Val14array[index2]? initialState.box05Val14array[index2]: arrayList[index2].box05_val14 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "14")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>로투스바카라1</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_15"
                                                               key={( initialState.box05Val15array[index2]? initialState.box05Val15array[index2]: arrayList[index2].box05_val15 )}
                                                               defaultValue={( initialState.box05Val15array[index2]? initialState.box05Val15array[index2]: arrayList[index2].box05_val15 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "15")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>로투스홀짝</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_16"
                                                               key={( initialState.box05Val16array[index2]? initialState.box05Val16array[index2]: arrayList[index2].box05_val16 )}
                                                               defaultValue={( initialState.box05Val16array[index2]? initialState.box05Val16array[index2]: arrayList[index2].box05_val16 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "16")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스피드키노</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_17"
                                                               key={( initialState.box05Val17array[index2]? initialState.box05Val17array[index2]: arrayList[index2].box05_val17 )}
                                                               defaultValue={( initialState.box05Val17array[index2]? initialState.box05Val17array[index2]: arrayList[index2].box05_val17 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "17")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>라이언무지</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_18"
                                                               key={( initialState.box05Val18array[index2]? initialState.box05Val18array[index2]: arrayList[index2].box05_val18 )}
                                                               defaultValue={( initialState.box05Val18array[index2]? initialState.box05Val18array[index2]: arrayList[index2].box05_val18 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "18")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>황금돼지</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_19"
                                                               key={( initialState.box05Val19array[index2]? initialState.box05Val19array[index2]: arrayList[index2].box05_val19 )}
                                                               defaultValue={( initialState.box05Val19array[index2]? initialState.box05Val19array[index2]: arrayList[index2].box05_val19 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "19")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스플릿</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_20"
                                                               key={( initialState.box05Val20array[index2]? initialState.box05Val20array[index2]: arrayList[index2].box05_val20 )}
                                                               defaultValue={( initialState.box05Val20array[index2]? initialState.box05Val20array[index2]: arrayList[index2].box05_val20 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "20")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_21"
                                                               key={( initialState.box05Val21array[index2]? initialState.box05Val21array[index2]: arrayList[index2].box05_val21 )}
                                                               defaultValue={( initialState.box05Val21array[index2]? initialState.box05Val21array[index2]: arrayList[index2].box05_val21 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "21")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>바이낸스1분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_22"
                                                               key={( initialState.box05Val22array[index2]? initialState.box05Val22array[index2]: arrayList[index2].box05_val22 )}
                                                               defaultValue={( initialState.box05Val22array[index2]? initialState.box05Val22array[index2]: arrayList[index2].box05_val22 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "22")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>바이낸스3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_23"
                                                               key={( initialState.box05Val23array[index2]? initialState.box05Val23array[index2]: arrayList[index2].box05_val23 )}
                                                               defaultValue={( initialState.box05Val23array[index2]? initialState.box05Val23array[index2]: arrayList[index2].box05_val23 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "23")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이룰렛</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_24"
                                                               key={( initialState.box05Val24array[index2]? initialState.box05Val24array[index2]: arrayList[index2].box05_val24 )}
                                                               defaultValue={( initialState.box05Val24array[index2]? initialState.box05Val24array[index2]: arrayList[index2].box05_val24 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "24")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이홈런</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_25"
                                                               key={( initialState.box05Val25array[index2]? initialState.box05Val25array[index2]: arrayList[index2].box05_val25 )}
                                                               defaultValue={( initialState.box05Val25array[index2]? initialState.box05Val25array[index2]: arrayList[index2].box05_val25 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "25")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워프리킥</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_26"
                                                               key={( initialState.box05Val26array[index2]? initialState.box05Val26array[index2]: arrayList[index2].box05_val26 )}
                                                               defaultValue={( initialState.box05Val26array[index2]? initialState.box05Val26array[index2]: arrayList[index2].box05_val26 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "26")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_27"
                                                               key={( initialState.box05Val27array[index2]? initialState.box05Val27array[index2]: arrayList[index2].box05_val27 )}
                                                               defaultValue={( initialState.box05Val27array[index2]? initialState.box05Val27array[index2]: arrayList[index2].box05_val27 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "27")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_28"
                                                               key={( initialState.box05Val28array[index2]? initialState.box05Val28array[index2]: arrayList[index2].box05_val28 )}
                                                               defaultValue={( initialState.box05Val28array[index2]? initialState.box05Val28array[index2]: arrayList[index2].box05_val28 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "28")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_29"
                                                               key={( initialState.box05Val29array[index2]? initialState.box05Val29array[index2]: arrayList[index2].box05_val29 )}
                                                               defaultValue={( initialState.box05Val29array[index2]? initialState.box05Val29array[index2]: arrayList[index2].box05_val29 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "29")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>보글 사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_30"
                                                               key={( initialState.box05Val30array[index2]? initialState.box05Val30array[index2]: arrayList[index2].box05_val30 )}
                                                               defaultValue={( initialState.box05Val30array[index2]? initialState.box05Val30array[index2]: arrayList[index2].box05_val30 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "30")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>보글 파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_31"
                                                               key={( initialState.box05Val31array[index2]? initialState.box05Val31array[index2]: arrayList[index2].box05_val31 )}
                                                               defaultValue={( initialState.box05Val31array[index2]? initialState.box05Val31array[index2]: arrayList[index2].box05_val31 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "31")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 블랙잭</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_32"
                                                               key={( initialState.box05Val32array[index2]? initialState.box05Val32array[index2]: arrayList[index2].box05_val32 )}
                                                               defaultValue={( initialState.box05Val32array[index2]? initialState.box05Val32array[index2]: arrayList[index2].box05_val32 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "32")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 바카라1</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_33"
                                                               key={( initialState.box05Val33array[index2]? initialState.box05Val33array[index2]: arrayList[index2].box05_val33 )}
                                                               defaultValue={( initialState.box05Val33array[index2]? initialState.box05Val33array[index2]: arrayList[index2].box05_val33 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "33")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 룰렛</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_34"
                                                               key={( initialState.box05Val34array[index2]? initialState.box05Val34array[index2]: arrayList[index2].box05_val34 )}
                                                               defaultValue={( initialState.box05Val34array[index2]? initialState.box05Val34array[index2]: arrayList[index2].box05_val34 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "34")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 홀짝</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_35"
                                                               key={( initialState.box05Val35array[index2]? initialState.box05Val35array[index2]: arrayList[index2].box05_val35 )}
                                                               defaultValue={( initialState.box05Val35array[index2]? initialState.box05Val35array[index2]: arrayList[index2].box05_val35 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "35")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 주사위</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_36"
                                                               key={( initialState.box05Val36array[index2]? initialState.box05Val36array[index2]: arrayList[index2].box05_val36 )}
                                                               defaultValue={( initialState.box05Val36array[index2]? initialState.box05Val36array[index2]: arrayList[index2].box05_val36 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "36")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 스피드바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_37"
                                                               key={( initialState.box05Val37array[index2]? initialState.box05Val37array[index2]: arrayList[index2].box05_val37 )}
                                                               defaultValue={( initialState.box05Val37array[index2]? initialState.box05Val37array[index2]: arrayList[index2].box05_val37 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "37")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 드래곤타이거</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_38"
                                                               key={( initialState.box05Val38array[index2]? initialState.box05Val38array[index2]: arrayList[index2].box05_val38 )}
                                                               defaultValue={( initialState.box05Val38array[index2]? initialState.box05Val38array[index2]: arrayList[index2].box05_val38 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "38")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 삼치기</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_39"
                                                               key={( initialState.box05Val39array[index2]? initialState.box05Val39array[index2]: arrayList[index2].box05_val39 )}
                                                               defaultValue={( initialState.box05Val39array[index2]? initialState.box05Val39array[index2]: arrayList[index2].box05_val39 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "39")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼1m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_40"
                                                               key={( initialState.box05Val40array[index2]? initialState.box05Val40array[index2]: arrayList[index2].box05_val40 )}
                                                               defaultValue={( initialState.box05Val40array[index2]? initialState.box05Val40array[index2]: arrayList[index2].box05_val40 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "40")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼3m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_41"
                                                               key={( initialState.box05Val41array[index2]? initialState.box05Val41array[index2]: arrayList[index2].box05_val41 )}
                                                               defaultValue={( initialState.box05Val41array[index2]? initialState.box05Val41array[index2]: arrayList[index2].box05_val41 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "41")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼5m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_42"
                                                               key={( initialState.box05Val42array[index2]? initialState.box05Val42array[index2]: arrayList[index2].box05_val42 )}
                                                               defaultValue={( initialState.box05Val42array[index2]? initialState.box05Val42array[index2]: arrayList[index2].box05_val42 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "42")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워볼3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_43"
                                                               key={( initialState.box05Val43array[index2]? initialState.box05Val43array[index2]: arrayList[index2].box05_val43 )}
                                                               defaultValue={( initialState.box05Val43array[index2]? initialState.box05Val43array[index2]: arrayList[index2].box05_val43 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "43")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워볼5분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_44"
                                                               key={( initialState.box05Val44array[index2]? initialState.box05Val44array[index2]: arrayList[index2].box05_val44 )}
                                                               defaultValue={( initialState.box05Val44array[index2]? initialState.box05Val44array[index2]: arrayList[index2].box05_val44 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "44")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워사다리3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_45"
                                                               key={( initialState.box05Val45array[index2]? initialState.box05Val45array[index2]: arrayList[index2].box05_val45 )}
                                                               defaultValue={( initialState.box05Val45array[index2]? initialState.box05Val45array[index2]: arrayList[index2].box05_val45 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "45")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워사다리5분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_46"
                                                               key={( initialState.box05Val46array[index2]? initialState.box05Val46array[index2]: arrayList[index2].box05_val46 )}
                                                               defaultValue={( initialState.box05Val46array[index2]? initialState.box05Val46array[index2]: arrayList[index2].box05_val46 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "46")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크라운섯다</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_47"
                                                               key={( initialState.box05Val47array[index2]? initialState.box05Val47array[index2]: arrayList[index2].box05_val47 )}
                                                               defaultValue={( initialState.box05Val47array[index2]? initialState.box05Val47array[index2]: arrayList[index2].box05_val47 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "47")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크라운하이로우</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit5_48"
                                                               key={( initialState.box05Val48array[index2]? initialState.box05Val48array[index2]: arrayList[index2].box05_val48 )}
                                                               defaultValue={( initialState.box05Val48array[index2]? initialState.box05Val48array[index2]: arrayList[index2].box05_val48 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "5", "48")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {isEditing02 && (
                                    <div style={{border: "solid gray", width: "260px", height: "1605px", backgroundColor: "gray"}} align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="100">최대 배팅금</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크로스</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_1"
                                                               key={( initialState.box06Val1array[index2]? initialState.box06Val1array[index2]: arrayList[index2].box06_val1 )}
                                                               defaultValue={( initialState.box06Val1array[index2]? initialState.box06Val1array[index2]: arrayList[index2].box06_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1" style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크로스2</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_2"
                                                               key={( initialState.box06Val2array[index2]? initialState.box06Val2array[index2]: arrayList[index2].box06_val2 )}
                                                               defaultValue={( initialState.box06Val2array[index2]? initialState.box06Val2array[index2]: arrayList[index2].box06_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일반게임</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_3"
                                                               key={( initialState.box06Val3array[index2]? initialState.box06Val3array[index2]: arrayList[index2].box06_val3 )}
                                                               defaultValue={( initialState.box06Val3array[index2]? initialState.box06Val3array[index2]: arrayList[index2].box06_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>핸디캡</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_4"
                                                               key={( initialState.box06Val4array[index2]? initialState.box06Val4array[index2]: arrayList[index2].box06_val4 )}
                                                               defaultValue={( initialState.box06Val4array[index2]? initialState.box06Val4array[index2]: arrayList[index2].box06_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스폐셜</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_5"
                                                               key={( initialState.box06Val5array[index2]? initialState.box06Val5array[index2]: arrayList[index2].box06_val5 )}
                                                               defaultValue={( initialState.box06Val5array[index2]? initialState.box06Val5array[index2]: arrayList[index2].box06_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>실시간</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_6"
                                                               key={( initialState.box06Val6array[index2]? initialState.box06Val6array[index2]: arrayList[index2].box06_val6 )}
                                                               defaultValue={( initialState.box06Val6array[index2]? initialState.box06Val6array[index2]: arrayList[index2].box06_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_7"
                                                               key={( initialState.box06Val7array[index2]? initialState.box06Val7array[index2]: arrayList[index2].box06_val7 )}
                                                               defaultValue={( initialState.box06Val7array[index2]? initialState.box06Val7array[index2]: arrayList[index2].box06_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_8"
                                                               key={( initialState.box06Val8array[index2]? initialState.box06Val8array[index2]: arrayList[index2].box06_val8 )}
                                                               defaultValue={( initialState.box06Val8array[index2]? initialState.box06Val8array[index2]: arrayList[index2].box06_val8 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "8")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 축구</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_9"
                                                               key={( initialState.box06Val9array[index2]? initialState.box06Val9array[index2]: arrayList[index2].box06_val9 )}
                                                               defaultValue={( initialState.box06Val9array[index2]? initialState.box06Val9array[index2]: arrayList[index2].box06_val9 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "9")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 개경주</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_10"
                                                               key={( initialState.box06Val10array[index2]? initialState.box06Val10array[index2]: arrayList[index2].box06_val10 )}
                                                               defaultValue={( initialState.box06Val10array[index2]? initialState.box06Val10array[index2]: arrayList[index2].box06_val10 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "10")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 경마</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_11"
                                                               key={( initialState.box06Val11array[index2]? initialState.box06Val11array[index2]: arrayList[index2].box06_val11 )}
                                                               defaultValue={( initialState.box06Val11array[index2]? initialState.box06Val11array[index2]: arrayList[index2].box06_val11 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "11")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 농구</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_12"
                                                               key={( initialState.box06Val12array[index2]? initialState.box06Val12array[index2]: arrayList[index2].box06_val12 )}
                                                               defaultValue={( initialState.box06Val12array[index2]? initialState.box06Val12array[index2]: arrayList[index2].box06_val12 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "12")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>썬시티바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_13"
                                                               key={( initialState.box06Val13array[index2]? initialState.box06Val13array[index2]: arrayList[index2].box06_val13 )}
                                                               defaultValue={( initialState.box06Val13array[index2]? initialState.box06Val13array[index2]: arrayList[index2].box06_val13 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "13")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>키노사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_14"
                                                               key={( initialState.box06Val14array[index2]? initialState.box06Val14array[index2]: arrayList[index2].box06_val14 )}
                                                               defaultValue={( initialState.box06Val14array[index2]? initialState.box06Val14array[index2]: arrayList[index2].box06_val14 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "14")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>로투스바카라1</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_15"
                                                               key={( initialState.box06Val15array[index2]? initialState.box06Val15array[index2]: arrayList[index2].box06_val15 )}
                                                               defaultValue={( initialState.box06Val15array[index2]? initialState.box06Val15array[index2]: arrayList[index2].box06_val15 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "15")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>로투스홀짝</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_16"
                                                               key={( initialState.box06Val16array[index2]? initialState.box06Val16array[index2]: arrayList[index2].box06_val16 )}
                                                               defaultValue={( initialState.box06Val16array[index2]? initialState.box06Val16array[index2]: arrayList[index2].box06_val16 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "16")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스피드키노</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_17"
                                                               key={( initialState.box06Val17array[index2]? initialState.box06Val17array[index2]: arrayList[index2].box06_val17 )}
                                                               defaultValue={( initialState.box06Val17array[index2]? initialState.box06Val17array[index2]: arrayList[index2].box06_val17 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "17")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>라이언무지</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_18"
                                                               key={( initialState.box06Val18array[index2]? initialState.box06Val18array[index2]: arrayList[index2].box06_val18 )}
                                                               defaultValue={( initialState.box06Val18array[index2]? initialState.box06Val18array[index2]: arrayList[index2].box06_val18 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "18")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>황금돼지</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_19"
                                                               key={( initialState.box06Val19array[index2]? initialState.box06Val19array[index2]: arrayList[index2].box06_val19 )}
                                                               defaultValue={( initialState.box06Val19array[index2]? initialState.box06Val19array[index2]: arrayList[index2].box06_val19 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "19")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스플릿</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_20"
                                                               key={( initialState.box06Val20array[index2]? initialState.box06Val20array[index2]: arrayList[index2].box06_val20 )}
                                                               defaultValue={( initialState.box06Val20array[index2]? initialState.box06Val20array[index2]: arrayList[index2].box06_val20 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "20")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_21"
                                                               key={( initialState.box06Val21array[index2]? initialState.box06Val21array[index2]: arrayList[index2].box06_val21 )}
                                                               defaultValue={( initialState.box06Val21array[index2]? initialState.box06Val21array[index2]: arrayList[index2].box06_val21 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "21")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>바이낸스1분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_22"
                                                               key={( initialState.box06Val22array[index2]? initialState.box06Val22array[index2]: arrayList[index2].box06_val22 )}
                                                               defaultValue={( initialState.box06Val22array[index2]? initialState.box06Val22array[index2]: arrayList[index2].box06_val22 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "22")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>바이낸스3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_23"
                                                               key={( initialState.box06Val23array[index2]? initialState.box06Val23array[index2]: arrayList[index2].box06_val23 )}
                                                               defaultValue={( initialState.box06Val23array[index2]? initialState.box06Val23array[index2]: arrayList[index2].box06_val23 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "23")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이룰렛</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_24"
                                                               key={( initialState.box06Val24array[index2]? initialState.box06Val24array[index2]: arrayList[index2].box06_val24 )}
                                                               defaultValue={( initialState.box06Val24array[index2]? initialState.box06Val24array[index2]: arrayList[index2].box06_val24 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "24")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이홈런</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_25"
                                                               key={( initialState.box06Val25array[index2]? initialState.box06Val25array[index2]: arrayList[index2].box06_val25 )}
                                                               defaultValue={( initialState.box06Val25array[index2]? initialState.box06Val25array[index2]: arrayList[index2].box06_val25 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "25")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워프리킥</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_26"
                                                               key={( initialState.box06Val26array[index2]? initialState.box06Val26array[index2]: arrayList[index2].box06_val26 )}
                                                               defaultValue={( initialState.box06Val26array[index2]? initialState.box06Val26array[index2]: arrayList[index2].box06_val26 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "26")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_27"
                                                               key={( initialState.box06Val27array[index2]? initialState.box06Val27array[index2]: arrayList[index2].box06_val27 )}
                                                               defaultValue={( initialState.box06Val27array[index2]? initialState.box06Val27array[index2]: arrayList[index2].box06_val27 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "27")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_28"
                                                               key={( initialState.box06Val28array[index2]? initialState.box06Val28array[index2]: arrayList[index2].box06_val28 )}
                                                               defaultValue={( initialState.box06Val28array[index2]? initialState.box06Val28array[index2]: arrayList[index2].box06_val28 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "28")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_29"
                                                               key={( initialState.box06Val29array[index2]? initialState.box06Val29array[index2]: arrayList[index2].box06_val29 )}
                                                               defaultValue={( initialState.box06Val29array[index2]? initialState.box06Val29array[index2]: arrayList[index2].box06_val29 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "29")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>보글 사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_30"
                                                               key={( initialState.box06Val30array[index2]? initialState.box06Val30array[index2]: arrayList[index2].box06_val30 )}
                                                               defaultValue={( initialState.box06Val30array[index2]? initialState.box06Val30array[index2]: arrayList[index2].box06_val30 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "30")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>보글 파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_31"
                                                               key={( initialState.box06Val31array[index2]? initialState.box06Val31array[index2]: arrayList[index2].box06_val31 )}
                                                               defaultValue={( initialState.box06Val31array[index2]? initialState.box06Val31array[index2]: arrayList[index2].box06_val31 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "31")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 블랙잭</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_32"
                                                               key={( initialState.box06Val32array[index2]? initialState.box06Val32array[index2]: arrayList[index2].box06_val32 )}
                                                               defaultValue={( initialState.box06Val32array[index2]? initialState.box06Val32array[index2]: arrayList[index2].box06_val32 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "32")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 바카라1</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_33"
                                                               key={( initialState.box06Val33array[index2]? initialState.box06Val33array[index2]: arrayList[index2].box06_val33 )}
                                                               defaultValue={( initialState.box06Val33array[index2]? initialState.box06Val33array[index2]: arrayList[index2].box06_val33 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "33")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 룰렛</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_34"
                                                               key={( initialState.box06Val34array[index2]? initialState.box06Val34array[index2]: arrayList[index2].box06_val34 )}
                                                               defaultValue={( initialState.box06Val34array[index2]? initialState.box06Val34array[index2]: arrayList[index2].box06_val34 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "34")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 홀짝</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_35"
                                                               key={( initialState.box06Val35array[index2]? initialState.box06Val35array[index2]: arrayList[index2].box06_val35 )}
                                                               defaultValue={( initialState.box06Val35array[index2]? initialState.box06Val35array[index2]: arrayList[index2].box06_val35 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "35")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 주사위</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_36"
                                                               key={( initialState.box06Val36array[index2]? initialState.box06Val36array[index2]: arrayList[index2].box06_val36 )}
                                                               defaultValue={( initialState.box06Val36array[index2]? initialState.box06Val36array[index2]: arrayList[index2].box06_val36 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "36")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 스피드바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_37"
                                                               key={( initialState.box06Val37array[index2]? initialState.box06Val37array[index2]: arrayList[index2].box06_val37 )}
                                                               defaultValue={( initialState.box06Val37array[index2]? initialState.box06Val37array[index2]: arrayList[index2].box06_val37 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "37")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 드래곤타이거</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_38"
                                                               key={( initialState.box06Val38array[index2]? initialState.box06Val38array[index2]: arrayList[index2].box06_val38 )}
                                                               defaultValue={( initialState.box06Val38array[index2]? initialState.box06Val38array[index2]: arrayList[index2].box06_val38 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "38")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 삼치기</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_39"
                                                               key={( initialState.box06Val39array[index2]? initialState.box06Val39array[index2]: arrayList[index2].box06_val39 )}
                                                               defaultValue={( initialState.box06Val39array[index2]? initialState.box06Val39array[index2]: arrayList[index2].box06_val39 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "39")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼1m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_40"
                                                               key={( initialState.box06Val40array[index2]? initialState.box06Val40array[index2]: arrayList[index2].box06_val40 )}
                                                               defaultValue={( initialState.box06Val40array[index2]? initialState.box06Val40array[index2]: arrayList[index2].box06_val40 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "40")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼3m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_41"
                                                               key={( initialState.box06Val41array[index2]? initialState.box06Val41array[index2]: arrayList[index2].box06_val41 )}
                                                               defaultValue={( initialState.box06Val41array[index2]? initialState.box06Val41array[index2]: arrayList[index2].box06_val41 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "41")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼5m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_42"
                                                               key={( initialState.box06Val42array[index2]? initialState.box06Val42array[index2]: arrayList[index2].box06_val42 )}
                                                               defaultValue={( initialState.box06Val42array[index2]? initialState.box06Val42array[index2]: arrayList[index2].box06_val42 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "42")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워볼3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_43"
                                                               key={( initialState.box06Val43array[index2]? initialState.box06Val43array[index2]: arrayList[index2].box06_val43 )}
                                                               defaultValue={( initialState.box06Val43array[index2]? initialState.box06Val43array[index2]: arrayList[index2].box06_val43 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "43")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워볼5분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_44"
                                                               key={( initialState.box06Val44array[index2]? initialState.box06Val44array[index2]: arrayList[index2].box06_val44 )}
                                                               defaultValue={( initialState.box06Val44array[index2]? initialState.box06Val44array[index2]: arrayList[index2].box06_val44 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "44")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워사다리3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_45"
                                                               key={( initialState.box06Val45array[index2]? initialState.box06Val45array[index2]: arrayList[index2].box06_val45 )}
                                                               defaultValue={( initialState.box06Val45array[index2]? initialState.box06Val45array[index2]: arrayList[index2].box06_val45 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "45")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워사다리5분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_46"
                                                               key={( initialState.box06Val46array[index2]? initialState.box06Val46array[index2]: arrayList[index2].box06_val46 )}
                                                               defaultValue={( initialState.box06Val46array[index2]? initialState.box06Val46array[index2]: arrayList[index2].box06_val46 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "46")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크라운섯다</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_47"
                                                               key={( initialState.box06Val47array[index2]? initialState.box06Val47array[index2]: arrayList[index2].box06_val47 )}
                                                               defaultValue={( initialState.box06Val47array[index2]? initialState.box06Val47array[index2]: arrayList[index2].box06_val47 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "47")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크라운하이로우</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit6_48"
                                                               key={( initialState.box06Val48array[index2]? initialState.box06Val48array[index2]: arrayList[index2].box06_val48 )}
                                                               defaultValue={( initialState.box06Val48array[index2]? initialState.box06Val48array[index2]: arrayList[index2].box06_val48 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "6", "48")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {isEditing02 && (
                                    <div style={{border: "solid gray", width: "260px", height: "1605px", backgroundColor: "gray"}} align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="100">최대 당첨금</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크로스</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_1"
                                                               key={( initialState.box07Val1array[index2]? initialState.box07Val1array[index2]: arrayList[index2].box07_val1 )}
                                                               defaultValue={( initialState.box07Val1array[index2]? initialState.box07Val1array[index2]: arrayList[index2].box07_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1" style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크로스2</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_2"
                                                               key={( initialState.box07Val2array[index2]? initialState.box07Val2array[index2]: arrayList[index2].box07_val2 )}
                                                               defaultValue={( initialState.box07Val2array[index2]? initialState.box07Val2array[index2]: arrayList[index2].box07_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일반게임</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_3"
                                                               key={( initialState.box07Val3array[index2]? initialState.box07Val3array[index2]: arrayList[index2].box07_val3 )}
                                                               defaultValue={( initialState.box07Val3array[index2]? initialState.box07Val3array[index2]: arrayList[index2].box07_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>핸디캡</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_4"
                                                               key={( initialState.box07Val4array[index2]? initialState.box07Val4array[index2]: arrayList[index2].box07_val4 )}
                                                               defaultValue={( initialState.box07Val4array[index2]? initialState.box07Val4array[index2]: arrayList[index2].box07_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스폐셜</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_5"
                                                               key={( initialState.box07Val5array[index2]? initialState.box07Val5array[index2]: arrayList[index2].box07_val5 )}
                                                               defaultValue={( initialState.box07Val5array[index2]? initialState.box07Val5array[index2]: arrayList[index2].box07_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>실시간</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_6"
                                                               key={( initialState.box07Val6array[index2]? initialState.box07Val6array[index2]: arrayList[index2].box07_val6 )}
                                                               defaultValue={( initialState.box07Val6array[index2]? initialState.box07Val6array[index2]: arrayList[index2].box07_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_7"
                                                               key={( initialState.box07Val7array[index2]? initialState.box07Val7array[index2]: arrayList[index2].box07_val7 )}
                                                               defaultValue={( initialState.box07Val7array[index2]? initialState.box07Val7array[index2]: arrayList[index2].box07_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_8"
                                                               key={( initialState.box07Val8array[index2]? initialState.box07Val8array[index2]: arrayList[index2].box07_val8 )}
                                                               defaultValue={( initialState.box07Val8array[index2]? initialState.box07Val8array[index2]: arrayList[index2].box07_val8 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "8")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 축구</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_9"
                                                               key={( initialState.box07Val9array[index2]? initialState.box07Val9array[index2]: arrayList[index2].box07_val9 )}
                                                               defaultValue={( initialState.box07Val9array[index2]? initialState.box07Val9array[index2]: arrayList[index2].box07_val9 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "9")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 개경주</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_10"
                                                               key={( initialState.box07Val10array[index2]? initialState.box07Val10array[index2]: arrayList[index2].box07_val10 )}
                                                               defaultValue={( initialState.box07Val10array[index2]? initialState.box07Val10array[index2]: arrayList[index2].box07_val10 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "10")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 경마</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_11"
                                                               key={( initialState.box07Val11array[index2]? initialState.box07Val11array[index2]: arrayList[index2].box07_val11 )}
                                                               defaultValue={( initialState.box07Val11array[index2]? initialState.box07Val11array[index2]: arrayList[index2].box07_val11 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "11")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>가상 농구</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_12"
                                                               key={( initialState.box07Val12array[index2]? initialState.box07Val12array[index2]: arrayList[index2].box07_val12 )}
                                                               defaultValue={( initialState.box07Val12array[index2]? initialState.box07Val12array[index2]: arrayList[index2].box07_val12 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "12")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>썬시티바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_13"
                                                               key={( initialState.box07Val13array[index2]? initialState.box07Val13array[index2]: arrayList[index2].box07_val13 )}
                                                               defaultValue={( initialState.box07Val13array[index2]? initialState.box07Val13array[index2]: arrayList[index2].box07_val13 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "13")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>키노사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_14"
                                                               key={( initialState.box07Val14array[index2]? initialState.box07Val14array[index2]: arrayList[index2].box07_val14 )}
                                                               defaultValue={( initialState.box07Val14array[index2]? initialState.box07Val14array[index2]: arrayList[index2].box07_val14 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "14")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>로투스바카라1</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_15"
                                                               key={( initialState.box07Val15array[index2]? initialState.box07Val15array[index2]: arrayList[index2].box07_val15 )}
                                                               defaultValue={( initialState.box07Val15array[index2]? initialState.box07Val15array[index2]: arrayList[index2].box07_val15 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "15")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>로투스홀짝</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_16"
                                                               key={( initialState.box07Val16array[index2]? initialState.box07Val16array[index2]: arrayList[index2].box07_val16 )}
                                                               defaultValue={( initialState.box07Val16array[index2]? initialState.box07Val16array[index2]: arrayList[index2].box07_val16 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "16")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스피드키노</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_17"
                                                               key={( initialState.box07Val17array[index2]? initialState.box07Val17array[index2]: arrayList[index2].box07_val17 )}
                                                               defaultValue={( initialState.box07Val17array[index2]? initialState.box07Val17array[index2]: arrayList[index2].box07_val17 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "17")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>라이언무지</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_18"
                                                               key={( initialState.box07Val18array[index2]? initialState.box07Val18array[index2]: arrayList[index2].box07_val18 )}
                                                               defaultValue={( initialState.box07Val18array[index2]? initialState.box07Val18array[index2]: arrayList[index2].box07_val18 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "18")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>황금돼지</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_19"
                                                               key={( initialState.box07Val19array[index2]? initialState.box07Val19array[index2]: arrayList[index2].box07_val19 )}
                                                               defaultValue={( initialState.box07Val19array[index2]? initialState.box07Val19array[index2]: arrayList[index2].box07_val19 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "19")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스플릿</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_20"
                                                               key={( initialState.box07Val20array[index2]? initialState.box07Val20array[index2]: arrayList[index2].box07_val20 )}
                                                               defaultValue={( initialState.box07Val20array[index2]? initialState.box07Val20array[index2]: arrayList[index2].box07_val20 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "20")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_21"
                                                               key={( initialState.box07Val21array[index2]? initialState.box07Val21array[index2]: arrayList[index2].box07_val21 )}
                                                               defaultValue={( initialState.box07Val21array[index2]? initialState.box07Val21array[index2]: arrayList[index2].box07_val21 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "21")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>바이낸스1분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_22"
                                                               key={( initialState.box07Val22array[index2]? initialState.box07Val22array[index2]: arrayList[index2].box07_val22 )}
                                                               defaultValue={( initialState.box07Val22array[index2]? initialState.box07Val22array[index2]: arrayList[index2].box07_val22 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "22")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>바이낸스3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_23"
                                                               key={( initialState.box07Val23array[index2]? initialState.box07Val23array[index2]: arrayList[index2].box07_val23 )}
                                                               defaultValue={( initialState.box07Val23array[index2]? initialState.box07Val23array[index2]: arrayList[index2].box07_val23 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "23")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이룰렛</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_24"
                                                               key={( initialState.box07Val24array[index2]? initialState.box07Val24array[index2]: arrayList[index2].box07_val24 )}
                                                               defaultValue={( initialState.box07Val24array[index2]? initialState.box07Val24array[index2]: arrayList[index2].box07_val24 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "24")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>스카이홈런</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_25"
                                                               key={( initialState.box07Val25array[index2]? initialState.box07Val25array[index2]: arrayList[index2].box07_val25 )}
                                                               defaultValue={( initialState.box07Val25array[index2]? initialState.box07Val25array[index2]: arrayList[index2].box07_val25 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "25")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>파워프리킥</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_26"
                                                               key={( initialState.box07Val26array[index2]? initialState.box07Val26array[index2]: arrayList[index2].box07_val26 )}
                                                               defaultValue={( initialState.box07Val26array[index2]? initialState.box07Val26array[index2]: arrayList[index2].box07_val26 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "26")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_27"
                                                               key={( initialState.box07Val27array[index2]? initialState.box07Val27array[index2]: arrayList[index2].box07_val27 )}
                                                               defaultValue={( initialState.box07Val27array[index2]? initialState.box07Val27array[index2]: arrayList[index2].box07_val27 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "27")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_28"
                                                               key={( initialState.box07Val28array[index2]? initialState.box07Val28array[index2]: arrayList[index2].box07_val28 )}
                                                               defaultValue={( initialState.box07Val28array[index2]? initialState.box07Val28array[index2]: arrayList[index2].box07_val28 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "28")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>넥스트 파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_29"
                                                               key={( initialState.box07Val29array[index2]? initialState.box07Val29array[index2]: arrayList[index2].box07_val29 )}
                                                               defaultValue={( initialState.box07Val29array[index2]? initialState.box07Val29array[index2]: arrayList[index2].box07_val29 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "29")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>보글 사다리</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_30"
                                                               key={( initialState.box07Val30array[index2]? initialState.box07Val30array[index2]: arrayList[index2].box07_val30 )}
                                                               defaultValue={( initialState.box07Val30array[index2]? initialState.box07Val30array[index2]: arrayList[index2].box07_val30 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "30")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>보글 파워볼</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_31"
                                                               key={( initialState.box07Val31array[index2]? initialState.box07Val31array[index2]: arrayList[index2].box07_val31 )}
                                                               defaultValue={( initialState.box07Val31array[index2]? initialState.box07Val31array[index2]: arrayList[index2].box07_val31 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "31")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 블랙잭</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_32"
                                                               key={( initialState.box07Val32array[index2]? initialState.box07Val32array[index2]: arrayList[index2].box07_val32 )}
                                                               defaultValue={( initialState.box07Val32array[index2]? initialState.box07Val32array[index2]: arrayList[index2].box07_val32 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "32")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 바카라1</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_33"
                                                               key={( initialState.box07Val33array[index2]? initialState.box07Val33array[index2]: arrayList[index2].box07_val33 )}
                                                               defaultValue={( initialState.box07Val33array[index2]? initialState.box07Val33array[index2]: arrayList[index2].box07_val33 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "33")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 룰렛</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_34"
                                                               key={( initialState.box07Val34array[index2]? initialState.box07Val34array[index2]: arrayList[index2].box07_val34 )}
                                                               defaultValue={( initialState.box07Val34array[index2]? initialState.box07Val34array[index2]: arrayList[index2].box07_val34 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "34")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 홀짝</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_35"
                                                               key={( initialState.box07Val35array[index2]? initialState.box07Val35array[index2]: arrayList[index2].box07_val35 )}
                                                               defaultValue={( initialState.box07Val35array[index2]? initialState.box07Val35array[index2]: arrayList[index2].box07_val35 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "35")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 주사위</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_36"
                                                               key={( initialState.box07Val36array[index2]? initialState.box07Val36array[index2]: arrayList[index2].box07_val36 )}
                                                               defaultValue={( initialState.box07Val36array[index2]? initialState.box07Val36array[index2]: arrayList[index2].box07_val36 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "36")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 스피드바카라</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_37"
                                                               key={( initialState.box07Val37array[index2]? initialState.box07Val37array[index2]: arrayList[index2].box07_val37 )}
                                                               defaultValue={( initialState.box07Val37array[index2]? initialState.box07Val37array[index2]: arrayList[index2].box07_val37 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "37")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 드래곤타이거</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_38"
                                                               key={( initialState.box07Val38array[index2]? initialState.box07Val38array[index2]: arrayList[index2].box07_val38 )}
                                                               defaultValue={( initialState.box07Val38array[index2]? initialState.box07Val38array[index2]: arrayList[index2].box07_val38 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "38")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>skp 삼치기</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_39"
                                                               key={( initialState.box07Val39array[index2]? initialState.box07Val39array[index2]: arrayList[index2].box07_val39 )}
                                                               defaultValue={( initialState.box07Val39array[index2]? initialState.box07Val39array[index2]: arrayList[index2].box07_val39 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "39")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼1m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_40"
                                                               key={( initialState.box07Val40array[index2]? initialState.box07Val40array[index2]: arrayList[index2].box07_val40 )}
                                                               defaultValue={( initialState.box07Val40array[index2]? initialState.box07Val40array[index2]: arrayList[index2].box07_val40 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "40")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼3m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_41"
                                                               key={( initialState.box07Val41array[index2]? initialState.box07Val41array[index2]: arrayList[index2].box07_val41 )}
                                                               defaultValue={( initialState.box07Val41array[index2]? initialState.box07Val41array[index2]: arrayList[index2].box07_val41 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "41")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>EOS 파워볼5m</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_42"
                                                               key={( initialState.box07Val42array[index2]? initialState.box07Val42array[index2]: arrayList[index2].box07_val42 )}
                                                               defaultValue={( initialState.box07Val42array[index2]? initialState.box07Val42array[index2]: arrayList[index2].box07_val42 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "42")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워볼3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_43"
                                                               key={( initialState.box07Val43array[index2]? initialState.box07Val43array[index2]: arrayList[index2].box07_val43 )}
                                                               defaultValue={( initialState.box07Val43array[index2]? initialState.box07Val43array[index2]: arrayList[index2].box07_val43 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "43")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워볼5분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_44"
                                                               key={( initialState.box07Val44array[index2]? initialState.box07Val44array[index2]: arrayList[index2].box07_val44 )}
                                                               defaultValue={( initialState.box07Val44array[index2]? initialState.box07Val44array[index2]: arrayList[index2].box07_val44 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "44")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워사다리3분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_45"
                                                               key={( initialState.box07Val45array[index2]? initialState.box07Val45array[index2]: arrayList[index2].box07_val45 )}
                                                               defaultValue={( initialState.box07Val45array[index2]? initialState.box07Val45array[index2]: arrayList[index2].box07_val45 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "45")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>N파워사다리5분</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_46"
                                                               key={( initialState.box07Val46array[index2]? initialState.box07Val46array[index2]: arrayList[index2].box07_val46 )}
                                                               defaultValue={( initialState.box07Val46array[index2]? initialState.box07Val46array[index2]: arrayList[index2].box07_val46 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "46")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크라운섯다</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_47"
                                                               key={( initialState.box07Val47array[index2]? initialState.box07Val47array[index2]: arrayList[index2].box07_val47 )}
                                                               defaultValue={( initialState.box07Val47array[index2]? initialState.box07Val47array[index2]: arrayList[index2].box07_val47 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "47")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>크라운하이로우</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit7_48"
                                                               key={( initialState.box07Val48array[index2]? initialState.box07Val48array[index2]: arrayList[index2].box07_val48 )}
                                                               defaultValue={( initialState.box07Val48array[index2]? initialState.box07Val48array[index2]: arrayList[index2].box07_val48 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "7", "48")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                )}
                            </div>
                            <br></br>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                {isEditing03 && (
                                    <div style={{border: "solid gray", width: "290px", height: "395px", backgroundColor: "gray"}}
                                         align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="14">죽폴더 조합식</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 설정<br></br>1:제한,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit8_1"
                                                               key={( initialState.box08Val1array[index2]? initialState.box08Val1array[index2]: arrayList[index2].box08_val1 )}
                                                               defaultValue={( initialState.box08Val1array[index2]? initialState.box08Val1array[index2]: arrayList[index2].box08_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "8", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>마헨 0.5와 승패<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit8_2"
                                                               key={( initialState.box08Val2array[index2]? initialState.box08Val2array[index2]: arrayList[index2].box08_val2 )}
                                                               defaultValue={( initialState.box08Val2array[index2]? initialState.box08Val2array[index2]: arrayList[index2].box08_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "8", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>실패한 베팅도<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit8_3"
                                                               key={( initialState.box08Val3array[index2]? initialState.box08Val3array[index2]: arrayList[index2].box08_val3 )}
                                                               defaultValue={( initialState.box08Val3array[index2]? initialState.box08Val3array[index2]: arrayList[index2].box08_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "8", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 타임<br></br>1:게임,0:팀</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit8_4"
                                                               key={( initialState.box08Val4array[index2]? initialState.box08Val4array[index2]: arrayList[index2].box08_val4 )}
                                                               defaultValue={( initialState.box08Val4array[index2]? initialState.box08Val4array[index2]: arrayList[index2].box08_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "8", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 갯수<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit8_5"
                                                               key={( initialState.box08Val5array[index2]? initialState.box08Val5array[index2]: arrayList[index2].box08_val5 )}
                                                               defaultValue={( initialState.box08Val5array[index2]? initialState.box08Val5array[index2]: arrayList[index2].box08_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "8", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="개" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 금액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit8_6"
                                                               key={( initialState.box08Val6array[index2]? initialState.box08Val6array[index2]: arrayList[index2].box08_val6 )}
                                                               defaultValue={( initialState.box08Val6array[index2]? initialState.box08Val6array[index2]: arrayList[index2].box08_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "8", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>예상당첨금<br></br>제한액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit8_7"
                                                               key={( initialState.box08Val7array[index2]? initialState.box08Val7array[index2]: arrayList[index2].box08_val7 )}
                                                               defaultValue={( initialState.box08Val7array[index2]? initialState.box08Val7array[index2]: arrayList[index2].box08_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "8", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {isEditing03 && (
                                    <div style={{border: "solid gray", width: "290px", height: "395px", backgroundColor: "gray"}}
                                         align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="14">죽폴더 조합식2</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 설정<br></br>1:제한,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit9_1"
                                                               key={( initialState.box09Val1array[index2]? initialState.box09Val1array[index2]: arrayList[index2].box09_val1 )}
                                                               defaultValue={( initialState.box09Val1array[index2]? initialState.box09Val1array[index2]: arrayList[index2].box09_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "9", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>마헨 0.5와 승패<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit9_2"
                                                               key={( initialState.box09Val2array[index2]? initialState.box09Val2array[index2]: arrayList[index2].box09_val2 )}
                                                               defaultValue={( initialState.box09Val2array[index2]? initialState.box09Val2array[index2]: arrayList[index2].box09_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "9", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>실패한 베팅도<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit9_3"
                                                               key={( initialState.box09Val3array[index2]? initialState.box09Val3array[index2]: arrayList[index2].box09_val3 )}
                                                               defaultValue={( initialState.box09Val3array[index2]? initialState.box09Val3array[index2]: arrayList[index2].box09_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "9", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 타임<br></br>1:게임,0:팀</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit9_4"
                                                               key={( initialState.box09Val4array[index2]? initialState.box09Val4array[index2]: arrayList[index2].box09_val4 )}
                                                               defaultValue={( initialState.box09Val4array[index2]? initialState.box09Val4array[index2]: arrayList[index2].box09_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "9", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 갯수<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit9_5"
                                                               key={( initialState.box09Val5array[index2]? initialState.box09Val5array[index2]: arrayList[index2].box09_val5 )}
                                                               defaultValue={( initialState.box09Val5array[index2]? initialState.box09Val5array[index2]: arrayList[index2].box09_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "9", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="개" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 금액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit9_6"
                                                               key={( initialState.box09Val6array[index2]? initialState.box09Val6array[index2]: arrayList[index2].box09_val6 )}
                                                               defaultValue={( initialState.box09Val6array[index2]? initialState.box09Val6array[index2]: arrayList[index2].box09_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "9", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>예상당첨금<br></br>제한액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit9_7"
                                                               key={( initialState.box09Val7array[index2]? initialState.box09Val7array[index2]: arrayList[index2].box09_val7 )}
                                                               defaultValue={( initialState.box09Val7array[index2]? initialState.box09Val7array[index2]: arrayList[index2].box09_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "9", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {isEditing03 && (
                                    <div style={{border: "solid gray", width: "290px", height: "395px", backgroundColor: "gray"}}
                                         align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="14">죽폴더 승무패</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 설정<br></br>1:제한,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit10_1"
                                                               key={( initialState.box10Val1array[index2]? initialState.box10Val1array[index2]: arrayList[index2].box10_val1 )}
                                                               defaultValue={( initialState.box10Val1array[index2]? initialState.box10Val1array[index2]: arrayList[index2].box10_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "10", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>마헨 0.5와 승패<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit10_2"
                                                               key={( initialState.box10Val2array[index2]? initialState.box10Val2array[index2]: arrayList[index2].box10_val2 )}
                                                               defaultValue={( initialState.box10Val2array[index2]? initialState.box10Val2array[index2]: arrayList[index2].box10_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "10", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>실패한 베팅도<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit10_3"
                                                               key={( initialState.box10Val3array[index2]? initialState.box10Val3array[index2]: arrayList[index2].box10_val3 )}
                                                               defaultValue={( initialState.box10Val3array[index2]? initialState.box10Val3array[index2]: arrayList[index2].box10_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "10", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 타임<br></br>1:게임,0:팀</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit10_4"
                                                               key={( initialState.box10Val4array[index2]? initialState.box10Val4array[index2]: arrayList[index2].box10_val4 )}
                                                               defaultValue={( initialState.box10Val4array[index2]? initialState.box10Val4array[index2]: arrayList[index2].box10_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "10", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 갯수<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit10_5"
                                                               key={( initialState.box10Val5array[index2]? initialState.box10Val5array[index2]: arrayList[index2].box10_val5 )}
                                                               defaultValue={( initialState.box10Val5array[index2]? initialState.box10Val5array[index2]: arrayList[index2].box10_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "10", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="개" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 금액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit10_6"
                                                               key={( initialState.box10Val6array[index2]? initialState.box10Val6array[index2]: arrayList[index2].box10_val6 )}
                                                               defaultValue={( initialState.box10Val6array[index2]? initialState.box10Val6array[index2]: arrayList[index2].box10_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "10", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>예상당첨금<br></br>제한액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit10_7"
                                                               key={( initialState.box10Val7array[index2]? initialState.box10Val7array[index2]: arrayList[index2].box10_val7 )}
                                                               defaultValue={( initialState.box10Val7array[index2]? initialState.box10Val7array[index2]: arrayList[index2].box10_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "10", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {isEditing03 && (
                                    <div style={{border: "solid gray", width: "290px", height: "395px", backgroundColor: "gray"}}
                                         align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="14">죽폴더 핸디</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 설정<br></br>1:제한,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit11_1"
                                                               key={( initialState.box11Val1array[index2]? initialState.box11Val1array[index2]: arrayList[index2].box11_val1 )}
                                                               defaultValue={( initialState.box11Val1array[index2]? initialState.box11Val1array[index2]: arrayList[index2].box11_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "11", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>마헨 0.5와 승패<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit11_2"
                                                               key={( initialState.box11Val2array[index2]? initialState.box11Val2array[index2]: arrayList[index2].box11_val2 )}
                                                               defaultValue={( initialState.box11Val2array[index2]? initialState.box11Val2array[index2]: arrayList[index2].box11_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "11", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>실패한 베팅도<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit11_3"
                                                               key={( initialState.box11Val3array[index2]? initialState.box11Val3array[index2]: arrayList[index2].box11_val3 )}
                                                               defaultValue={( initialState.box11Val3array[index2]? initialState.box11Val3array[index2]: arrayList[index2].box11_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "11", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 타임<br></br>1:게임,0:팀</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit11_4"
                                                               key={( initialState.box11Val4array[index2]? initialState.box11Val4array[index2]: arrayList[index2].box11_val4 )}
                                                               defaultValue={( initialState.box11Val4array[index2]? initialState.box11Val4array[index2]: arrayList[index2].box11_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "11", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 갯수<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit11_5"
                                                               key={( initialState.box11Val5array[index2]? initialState.box11Val5array[index2]: arrayList[index2].box11_val5 )}
                                                               defaultValue={( initialState.box11Val5array[index2]? initialState.box11Val5array[index2]: arrayList[index2].box11_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "11", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="개" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 금액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit11_6"
                                                               key={( initialState.box11Val6array[index2]? initialState.box11Val6array[index2]: arrayList[index2].box11_val6 )}
                                                               defaultValue={( initialState.box11Val6array[index2]? initialState.box11Val6array[index2]: arrayList[index2].box11_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "11", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>예상당첨금<br></br>제한액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit11_7"
                                                               key={( initialState.box11Val7array[index2]? initialState.box11Val7array[index2]: arrayList[index2].box11_val7 )}
                                                               defaultValue={( initialState.box11Val7array[index2]? initialState.box11Val7array[index2]: arrayList[index2].box11_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "11", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {isEditing03 && (
                                    <div style={{border: "solid gray", width: "290px", height: "395px", backgroundColor: "gray"}}
                                         align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="14">죽폴더 스페셜</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 설정<br></br>1:제한,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit12_1"
                                                               key={( initialState.box12Val1array[index2]? initialState.box12Val1array[index2]: arrayList[index2].box12_val1 )}
                                                               defaultValue={( initialState.box12Val1array[index2]? initialState.box12Val1array[index2]: arrayList[index2].box12_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "12", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>마헨 0.5와 승패<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit12_2"
                                                               key={( initialState.box12Val2array[index2]? initialState.box12Val2array[index2]: arrayList[index2].box12_val2 )}
                                                               defaultValue={( initialState.box12Val2array[index2]? initialState.box12Val2array[index2]: arrayList[index2].box12_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "12", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>실패한 베팅도<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit12_3"
                                                               key={( initialState.box12Val3array[index2]? initialState.box12Val3array[index2]: arrayList[index2].box12_val3 )}
                                                               defaultValue={( initialState.box12Val3array[index2]? initialState.box12Val3array[index2]: arrayList[index2].box12_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "12", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 타임<br></br>1:게임,0:팀</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit12_4"
                                                               key={( initialState.box12Val4array[index2]? initialState.box12Val4array[index2]: arrayList[index2].box12_val4 )}
                                                               defaultValue={( initialState.box12Val4array[index2]? initialState.box12Val4array[index2]: arrayList[index2].box12_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "12", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 갯수<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit12_5"
                                                               key={( initialState.box12Val5array[index2]? initialState.box12Val5array[index2]: arrayList[index2].box12_val5 )}
                                                               defaultValue={( initialState.box12Val5array[index2]? initialState.box12Val5array[index2]: arrayList[index2].box12_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "12", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="개" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 금액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit12_6"
                                                               key={( initialState.box12Val6array[index2]? initialState.box12Val6array[index2]: arrayList[index2].box12_val6 )}
                                                               defaultValue={( initialState.box12Val6array[index2]? initialState.box12Val6array[index2]: arrayList[index2].box12_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "12", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>예상당첨금<br></br>제한액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit12_7"
                                                               key={( initialState.box12Val7array[index2]? initialState.box12Val7array[index2]: arrayList[index2].box12_val7 )}
                                                               defaultValue={( initialState.box12Val7array[index2]? initialState.box12Val7array[index2]: arrayList[index2].box12_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "12", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {isEditing03 && (
                                    <div style={{border: "solid gray", width: "290px", height: "395px", backgroundColor: "gray"}}
                                         align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="14">죽폴더 실시간</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 설정<br></br>1:제한,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit13_1"
                                                               key={( initialState.box13Val1array[index2]? initialState.box13Val1array[index2]: arrayList[index2].box13_val1 )}
                                                               defaultValue={( initialState.box13Val1array[index2]? initialState.box13Val1array[index2]: arrayList[index2].box13_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "13", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>마헨 0.5와 승패<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit13_2"
                                                               key={( initialState.box13Val2array[index2]? initialState.box13Val2array[index2]: arrayList[index2].box13_val2 )}
                                                               defaultValue={( initialState.box13Val2array[index2]? initialState.box13Val2array[index2]: arrayList[index2].box13_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "13", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>실패한 베팅도<br></br>축체크<br></br>1:체크,0:안함</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit13_3"
                                                               key={( initialState.box13Val3array[index2]? initialState.box13Val3array[index2]: arrayList[index2].box13_val3 )}
                                                               defaultValue={( initialState.box13Val3array[index2]? initialState.box13Val3array[index2]: arrayList[index2].box13_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "13", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 타임<br></br>1:게임,0:팀</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit13_4"
                                                               key={( initialState.box13Val4array[index2]? initialState.box13Val4array[index2]: arrayList[index2].box13_val4 )}
                                                               defaultValue={( initialState.box13Val4array[index2]? initialState.box13Val4array[index2]: arrayList[index2].box13_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "13", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 갯수<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit13_5"
                                                               key={( initialState.box13Val5array[index2]? initialState.box13Val5array[index2]: arrayList[index2].box13_val5 )}
                                                               defaultValue={( initialState.box13Val5array[index2]? initialState.box13Val5array[index2]: arrayList[index2].box13_val5 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "13", "5")}}
                                                        />&nbsp;
                                                        <input type="button" value="개" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>제한 금액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit13_6"
                                                               key={( initialState.box13Val6array[index2]? initialState.box13Val6array[index2]: arrayList[index2].box13_val6 )}
                                                               defaultValue={( initialState.box13Val6array[index2]? initialState.box13Val6array[index2]: arrayList[index2].box13_val6 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "13", "6")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>예상당첨금<br></br>제한액<br></br>(0:제한없음)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit13_7"
                                                               key={( initialState.box13Val7array[index2]? initialState.box13Val7array[index2]: arrayList[index2].box13_val7 )}
                                                               defaultValue={( initialState.box13Val7array[index2]? initialState.box13Val7array[index2]: arrayList[index2].box13_val7 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "13", "7")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                            </div>
                            <br></br>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                {isEditing04 && (
                                    <div style={{border: "solid gray", width: "300px", height: "285px", backgroundColor: "gray"}}
                                         align={"center"}>
                                        <table className="table table-bordered table-sm text-center align-middle" border="1">
                                            <tr>
                                                <td style={{backgroundColor: "lightgray"}} rowSpan="8">환전</td>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일일 최대환전<br></br>신청 건수<br></br>(-1이면 사이트<br></br>설정을 따름)</td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit14_1"
                                                               key={( initialState.box14Val1array[index2]? initialState.box14Val1array[index2]: arrayList[index2].box14_val1 )}
                                                               defaultValue={( initialState.box14Val1array[index2]? initialState.box14Val1array[index2]: arrayList[index2].box14_val1 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "14", "1")}}
                                                        />&nbsp;
                                                        <input type="button" value="개" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>일일 최대환전<br></br>금액<br></br>(-1이면
                                                    사이트<br></br>설정을 따름)
                                                </td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit14_2"
                                                               key={( initialState.box14Val2array[index2]? initialState.box14Val2array[index2]: arrayList[index2].box14_val2 )}
                                                               defaultValue={( initialState.box14Val2array[index2]? initialState.box14Val2array[index2]: arrayList[index2].box14_val2 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "14", "2")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>1일 최대 환전<br></br>신청 금액<br></br>(-1이면
                                                    사이트<br></br>설정을 따름)
                                                </td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit14_3"
                                                               key={( initialState.box14Val3array[index2]? initialState.box14Val3array[index2]: arrayList[index2].box14_val3 )}
                                                               defaultValue={( initialState.box14Val3array[index2]? initialState.box14Val3array[index2]: arrayList[index2].box14_val3 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "14", "3")}}
                                                        />&nbsp;
                                                        <input type="button" value="원" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <td style={{backgroundColor: "white"}} width="1px"></td>
                                                <td style={{backgroundColor: "lightgray"}}>환전시 시간 간격<br></br>제한(분)<br></br>(-1이면
                                                    사이트<br></br>설정을 따름)
                                                </td>
                                                <td cospan="2" style={{backgroundColor: "white"}}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                        display: "flex"
                                                    }}>
                                                        <input style={{
                                                            width: "60px"
                                                        }} type="text" className="form-control" id="search_edit14_4"
                                                               key={( initialState.box14Val4array[index2]? initialState.box14Val4array[index2]: arrayList[index2].box14_val4 )}
                                                               defaultValue={( initialState.box14Val4array[index2]? initialState.box14Val4array[index2]: arrayList[index2].box14_val4 )}
                                                               onChange={(e) => {valueSetHandler(e, index2, "14", "4")}}
                                                        />&nbsp;
                                                        <input type="button" value="분" id="inlineRadio1"
                                                               style={{backgroundColor: "lightgray"}}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                )}

                            </div>
                        </div>
                    )

                ))}

            </div>
        </div>

    )
}

export default LevelSetting;