import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import LvlSettingComponent from "../../components/modal/setting/LvlSettingComponent";
import {setIsOpen, setQuery, setSelectedNos1, setPayCouponCdArr, setPayCouponCdSeqArr} from "../../redux/reducers/setting/AttendanceListReducer";

const AttendanceSetting = ({attendanceSetting, onRefresh, initialState, onSearch, selectList1, selectList2}) => {

    const dispatch = useDispatch()
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [levels, setLevels] = useState([])

    const [useYn, setUseYn] = useState("");
    const [dayFirstLogin, setDayFirstLogin] = useState("")
    const [attendanceGbCd, setAttendanceGbCd] = useState("")
    const [attendanceInitGbCd, setAttendanceInitGbCd] = useState("")
    const [attendanceAmt, setAttendanceAmt] = useState("")
    const [attendanceVerdictGbCd, setAttendanceVerdictGbCd] = useState("")
    const [sessionYn, setSessionYn] = useState("")
    const [sequenceYn, setSequenceYn] = useState("")

    const [sessionDayArr, setSessionDayArr] = useState([]);
    const [payPointArr, setPayPointArr] = useState([]);
    const [payPointCntArr, setPayPointCntArr] = useState([]);

    const [sessionDaySeqArr, setSessionDaySeqArr] = useState([]);
    const [payPointSeqArr, setPayPointSeqArr] = useState([]);
    const [payPointCntSeqArr, setPayPointCntSeqArr] = useState([]);


    const onLevelChange = (setting_level_no) => {
        onSearch(setting_level_no)
    }

    useEffect(() => {
        getGrades()
    }, [])

    const getGrades = () => {
        axios.post('/admin/admin/getPointGrade2', {

        }).then((res) => {
            setLevels(res.data.result)
            const arr = []
            for(const level of res.data.result) {
                arr.push(level.setting_level_no)
            }
        })
    }
    const handleClickOpen = () => {
        dispatch(setIsOpen(true))
    }
    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        window.location.reload();
    }
    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }


    const useYnChangeHandler = (e) => {
        setUseYn(e.currentTarget.value);
    }
    const attendanceGbCdChangeHandler = (e) => {
        setAttendanceGbCd(e.currentTarget.value);
    }
    const attendanceInitGbCdChangeHandler = (e) => {
        setAttendanceInitGbCd(e.currentTarget.value);
    }
    const attendanceVerdictGbCdChangeHandler = (e) => {
        setAttendanceVerdictGbCd(e.currentTarget.value);
    }
    const sessionYnChangeHandler = (e) => {
        setSessionYn(e.currentTarget.value);
    }
    const sequenceYnChangeHandler = (e) => {
        setSequenceYn(e.currentTarget.value);
    }

    const checkHandler1 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos1([...initialState.selectedNos1, no]))
        }
        else {
            dispatch(setSelectedNos1(initialState.selectedNos1.filter((id) => id !== no)))
        }
    }

    const updateState = (state, depositNos) => {
        //alert("sessionDayArr :: "+JSON.stringify(sessionDayArr));

        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveAttendanceDefaultSetting', {
                pntGbCd: attendanceSetting?.pnt_gb_cd,
                useYn:useYn?useYn:attendanceSetting.use_yn,
                dayFirstLogin: dayFirstLogin?parseInt(dayFirstLogin):attendanceSetting.day_first_login,
                attendanceGbCd:attendanceGbCd?attendanceGbCd:attendanceSetting.attendance_gb_cd,
                attendanceInitGbCd:attendanceInitGbCd?attendanceInitGbCd:attendanceSetting.attendance_init_gb_cd,
                attendanceAmt: attendanceAmt?parseInt(attendanceAmt):attendanceSetting.attendance_amt,
                attendanceVerdictGbCd:attendanceVerdictGbCd?attendanceVerdictGbCd:attendanceSetting.attendance_verdict_gb_cd,
                sessionYn:sessionYn?sessionYn:attendanceSetting.session_yn,
                sequenceYn:sequenceYn?sequenceYn:attendanceSetting.sequence_yn,
                adminNo: userInfoId
            }).then((res) => {

                //회차출석
                selectList1?.map((selectList1, index) => (
                    axios.post('/admin/setting/saveAttendanceSessionSetting', {
                        pntGbCd: attendanceSetting?.pnt_gb_cd,
                        pntSubSeq: selectList1?.pnt_sub_seq,
                        sessionDay: sessionDayArr[selectList1?.pnt_sub_seq]?sessionDayArr[selectList1?.pnt_sub_seq]:selectList1?.session_day ,
                        payPoint: payPointArr[selectList1?.pnt_sub_seq]?payPointArr[selectList1?.pnt_sub_seq]:selectList1?.pay_point ,
                        payCouponCd: initialState.payCouponCdArr[selectList1?.pnt_sub_seq]?initialState.payCouponCdArr[selectList1?.pnt_sub_seq]:selectList1?.pay_coupon_cd ,
                        payPointCnt: payPointCntArr[selectList1?.pnt_sub_seq]?payPointCntArr[selectList1?.pnt_sub_seq]:selectList1?.pay_point_cnt ,
                        adminNo: userInfoId
                    }).then((res) => {

                    })
                ))

                //연속출석
                selectList2?.map((selectList2, index) => (
                    axios.post('/admin/setting/saveAttendanceSequenceSetting', {
                        pntGbCd: attendanceSetting?.pnt_gb_cd,
                        pntSubSeq: selectList2?.pnt_sub_seq,
                        sessionDay: sessionDaySeqArr[selectList2?.pnt_sub_seq]?sessionDaySeqArr[selectList2?.pnt_sub_seq]:selectList2?.session_day ,
                        payPoint: payPointSeqArr[selectList2?.pnt_sub_seq]?payPointSeqArr[selectList2?.pnt_sub_seq]:selectList2?.pay_point ,
                        payCouponCd: initialState.payCouponCdSeqArr[selectList2?.pnt_sub_seq]?initialState.payCouponCdSeqArr[selectList2.pnt_sub_seq]:selectList2?.pay_coupon_cd ,
                        payPointCnt: payPointCntSeqArr[selectList2?.pnt_sub_seq]?payPointCntSeqArr[selectList2?.pnt_sub_seq]:selectList2?.pay_point_cnt ,
                        adminNo: userInfoId
                    }).then((res) => {

                    })
                ))

                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }

    const updateMultiState = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos1.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos1.length; i++)
            {
                axios.post('/admin/setting/saveAttendanceDefaultSetting', {
                    pntGbCd: initialState.selectedNos1[i],
                    useYn:useYn?useYn:attendanceSetting.use_yn,
                    dayFirstLogin: dayFirstLogin?parseInt(dayFirstLogin):attendanceSetting.day_first_login,
                    attendanceGbCd:attendanceGbCd?attendanceGbCd:attendanceSetting.attendance_gb_cd,
                    attendanceInitGbCd:attendanceInitGbCd?attendanceInitGbCd:attendanceSetting.attendance_init_gb_cd,
                    attendanceAmt: attendanceAmt?parseInt(attendanceAmt):attendanceSetting.attendance_amt,
                    attendanceVerdictGbCd:attendanceVerdictGbCd?attendanceVerdictGbCd:attendanceSetting.attendance_verdict_gb_cd,
                    sessionYn:sessionYn?sessionYn:attendanceSetting.session_yn,
                    sequenceYn:sequenceYn?sequenceYn:attendanceSetting.sequence_yn,
                    adminNo: userInfoId
                }).then((res) => {
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }

            alert("수정완료");

        }

    }


    const insertAddSequence = () => {
        if (window.confirm("추가 하시겠습니까?"))
        {
            axios.post('/admin/setting/insertAddSequence', {
                pntGbCd: attendanceSetting?.pnt_gb_cd,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "추가완료" : "추가실패")
                window.location.reload();
            })
        }
    }
    const insertAddSession = () => {
        if (window.confirm("추가 하시겠습니까?"))
        {
            axios.post('/admin/setting/insertAddSession', {
                pntGbCd: attendanceSetting?.pnt_gb_cd,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "추가완료" : "추가실패")
                window.location.reload();
            })
        }
    }

    const sessionDayArrHandler = (e, index) => {
        sessionDayArr[index] = e.target.value;
        setSessionDayArr(sessionDayArr);
    }
    const payPointArrHandler = (e, index) => {
        payPointArr[index] = e.target.value;
        setPayPointArr(payPointArr);
    }
    const payPointCntArrHandler = (e, index) => {
        payPointCntArr[index] = e.target.value;
        setPayPointCntArr(payPointCntArr);
    }
    const payCouponCdArrHandler = (e, index) => {
        const rows = [...initialState.payCouponCdArr];
        rows[index] = e.target.value;
        dispatch(setPayCouponCdArr(rows));
    }

    const sessionDayArrSeqHandler = (e, index) => {
        sessionDaySeqArr[index] = e.target.value;
        setSessionDaySeqArr(sessionDaySeqArr);
    }
    const payPointArrSeqHandler = (e, index) => {
        payPointSeqArr[index] = e.target.value;
        setPayPointSeqArr(payPointSeqArr);
    }
    const payPointCntArrSeqHandler = (e, index) => {
        payPointCntSeqArr[index] = e.target.value;
        setPayPointCntSeqArr(payPointCntSeqArr);
    }
    const payCouponCdArrSeqHandler = (e, index) => {
        const rows = [...initialState.payCouponCdSeqArr];
        rows[index] = e.target.value;
        dispatch(setPayCouponCdSeqArr(rows));
    }



    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "green", border: "1", color: "white",fontSize: "10px", height: "22px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                            onClick={handleClickOpen}>레벨관리(추가,삭제)</button>
                    {initialState.isOpen && <LvlSettingComponent open={initialState.isOpen} onClose={handleClickClose} />}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {levels?.map((item, index) => (
                        <>
                            <button style={{ backgroundColor: initialState.query === item.setting_level_key ? "blue" : "white" , border: "1", color: initialState.query === item.setting_level_key ? "white" : "black",fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                    onClick={(e) => {
                                        onLevelChange(item.setting_level_key)
                                    }}
                            >
                                <b>{item.cmmn_cd_dtl_nm} 레벨</b>
                            </button>
                        </>
                    ))}
                </div>
                <br></br>

            </div>
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"100%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> 기본설정</b></font>
                        </div>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>기본설정</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={useYnChangeHandler} value={useYn?useYn:attendanceSetting.use_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>일 첫 로그인</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                           placeholder={addComma(attendanceSetting.day_first_login)}
                                           value={dayFirstLogin}
                                           onChange={(e) => setDayFirstLogin(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        P
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>출석체크</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={attendanceGbCdChangeHandler} value={attendanceGbCd?attendanceGbCd:attendanceSetting.attendance_gb_cd}
                                    >
                                        <option value={'01'}>자동</option>
                                        <option value={'02'}>수동</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>출석체크 초기화</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={attendanceInitGbCdChangeHandler} value={attendanceInitGbCd?attendanceInitGbCd:attendanceSetting.attendance_init_gb_cd}
                                    >
                                        <option value={'01'}>1일</option>
                                        <option value={'02'}>2일</option>
                                        <option value={'03'}>3일</option>
                                        <option value={'04'}>4일</option>
                                        <option value={'05'}>5일</option>
                                        <option value={'06'}>6일</option>
                                        <option value={'07'}>7일</option>
                                        <option value={'08'}>8일</option>
                                        <option value={'09'}>9일</option>
                                        <option value={'10'}>10일</option>
                                        <option value={'11'}>11일</option>
                                        <option value={'12'}>12일</option>
                                        <option value={'13'}>13일</option>
                                        <option value={'14'}>14일</option>
                                        <option value={'15'}>15일</option>
                                        <option value={'16'}>16일</option>
                                        <option value={'17'}>17일</option>
                                        <option value={'18'}>18일</option>
                                        <option value={'19'}>19일</option>
                                        <option value={'20'}>20일</option>
                                        <option value={'21'}>21일</option>
                                        <option value={'22'}>22일</option>
                                        <option value={'23'}>23일</option>
                                        <option value={'24'}>24일</option>
                                        <option value={'25'}>25일</option>
                                        <option value={'26'}>26일</option>
                                        <option value={'27'}>27일</option>
                                        <option value={'28'}>28일</option>
                                        <option value={'29'}>29일</option>
                                        <option value={'30'}>30일</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>출석체크 금액</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                           placeholder={addComma(attendanceSetting.attendance_amt)}
                                           value={attendanceAmt}
                                           onChange={(e) => setAttendanceAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>일 출석체크 판정</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={attendanceVerdictGbCdChangeHandler} value={attendanceVerdictGbCd?attendanceVerdictGbCd:attendanceSetting.attendance_verdict_gb_cd}
                                    >
                                        <option value={'01'}>일 누적금액</option>
                                        <option value={'02'}>월 누적금액</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center" style={{width: "50%"}}>
                            <tr>
                                <td colSpan={2} style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>회차 출석</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={sessionYnChangeHandler} value={sessionYn?sessionYn:attendanceSetting.session_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td colSpan={2} style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "50px"}}
                                            onClick={() => {insertAddSession()}}
                                    >
                                        추가
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center" style={{width: "50%"}}>
                            <thead >
                            <tr>
                                <th style={{width: "25%",backgroundColor: "lightgray"}}></th>
                                <th style={{width: "25%",backgroundColor: "lightgray"}}></th>
                                <th style={{width: "25%",backgroundColor: "lightgray"}}></th>
                                <th style={{width: "25%",backgroundColor: "lightgray"}}></th>
                            </tr>
                            </thead>
                            {!selectList1.length && (
                                <tbody key={selectList1.pnt_sub_seq}>
                                <tr>
                                    <td colSpan="4">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}
                            {selectList1?.map((selectList1, index) => (
                                <tbody key={selectList1.pnt_sub_seq}>
                                <tr>
                                    <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <font color={"black"}><b>회차 출석</b></font>
                                        &nbsp;&nbsp;
                                        <input className="form-check-input sm-input" type="text" style={{width: "50%"}}
                                               placeholder={selectList1.session_day}
                                               onChange={(e) => {sessionDayArrHandler(e, selectList1.pnt_sub_seq)}}
                                        />
                                        <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                        >
                                            일
                                        </button>
                                    </td>
                                    <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <font color={"black"}><b>지급 포인트</b></font>
                                        &nbsp;&nbsp;
                                        <input className="form-check-input sm-input" type="text" style={{width: "50%"}}
                                               placeholder={selectList1.pay_point}
                                               onChange={(e) => {payPointArrHandler(e, selectList1.pnt_sub_seq)}}
                                        />
                                        <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                        >
                                            P
                                        </button>
                                    </td>
                                    <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <font color={"black"}><b>지급 쿠폰 종류</b></font>
                                        &nbsp;&nbsp;
                                        <select  style={{width: "50%", height:"20px"}}
                                                 onChange={(e) => {payCouponCdArrHandler(e, selectList1.pnt_sub_seq)}}
                                                 value={initialState.payCouponCdArr[selectList1.pnt_sub_seq]?initialState.payCouponCdArr[selectList1.pnt_sub_seq]:selectList1.pay_coupon_cd}
                                        >
                                            <option value={"01"}>기본룰렛</option>
                                            <option value={"02"}>프리미엄룰렛</option>
                                        </select>
                                    </td>
                                    <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <font color={"black"}><b>지급 쿠폰 갯수</b></font>
                                        &nbsp;&nbsp;
                                        <input className="form-check-input sm-input" type="text" style={{width: "50%"}}
                                               placeholder={selectList1.pay_point_cnt}
                                               onChange={(e) => {payPointCntArrHandler(e, selectList1.pnt_sub_seq)}}
                                        />
                                        <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                        >
                                            개
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            ))}
                        </table>
                        <br></br>

                        <table className="commonT table table-bordered table-sm text-center" style={{width: "50%"}}>
                            <tr>
                                <td colSpan={2} style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>연속 출석</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={sequenceYnChangeHandler} value={sequenceYn?sequenceYn:attendanceSetting.sequence_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td colSpan={2} style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "50px"}}
                                            onClick={() => {insertAddSequence()}}
                                    >
                                        추가
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center" style={{width: "50%"}}>
                            <thead >
                            <tr>
                                <th style={{width: "25%",backgroundColor: "lightgray"}}></th>
                                <th style={{width: "25%",backgroundColor: "lightgray"}}></th>
                                <th style={{width: "25%",backgroundColor: "lightgray"}}></th>
                                <th style={{width: "25%",backgroundColor: "lightgray"}}></th>
                            </tr>
                            </thead>
                            {!selectList2.length && (
                                <tbody key={selectList2.pnt_sub_seq}>
                                <tr>
                                    <td colSpan="4">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}
                            {selectList2?.map((selectList2, index) => (
                                <tbody key={selectList2.pnt_sub_seq}>
                                <tr>
                                    <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <font color={"black"}><b>연속 출석</b></font>
                                        &nbsp;&nbsp;
                                        <input className="form-check-input sm-input" type="text" style={{width: "50%"}}
                                               placeholder={selectList2.session_day}
                                               onChange={(e) => {sessionDayArrSeqHandler(e, selectList2.pnt_sub_seq)}}
                                        />
                                        <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                        >
                                            일
                                        </button>
                                    </td>
                                    <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <font color={"black"}><b>지급 포인트</b></font>
                                        &nbsp;&nbsp;
                                        <input className="form-check-input sm-input" type="text" style={{width: "50%"}}
                                               placeholder={selectList2.pay_point}
                                               onChange={(e) => {payPointArrSeqHandler(e, selectList2.pnt_sub_seq)}}
                                        />
                                        <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                        >
                                            P
                                        </button>
                                    </td>
                                    <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <font color={"black"}><b>지급 쿠폰 종류</b></font>
                                        &nbsp;&nbsp;
                                        <select  style={{width: "50%", height:"20px"}}
                                                 onChange={(e) => {payCouponCdArrSeqHandler(e, selectList2.pnt_sub_seq)}}
                                                 value={initialState.payCouponCdSeqArr[selectList2.pnt_sub_seq]?initialState.payCouponCdArr[selectList2.pnt_sub_seq]:selectList2.pay_coupon_cd}
                                        >
                                            <option value={"01"}>기본룰렛</option>
                                            <option value={"02"}>프리미엄룰렛</option>
                                        </select>
                                    </td>
                                    <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <font color={"black"}><b>지급 쿠폰 갯수</b></font>
                                        &nbsp;&nbsp;
                                        <input className="form-check-input sm-input" type="text" style={{width: "50%"}}
                                               placeholder={selectList2.pay_point_cnt}
                                               onChange={(e) => {payPointCntArrSeqHandler(e, selectList2.pnt_sub_seq)}}
                                        />
                                        <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                        >
                                            개
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            ))}

                        </table>
                        <table className="commonT table table-bordered table-sm text-center" style={{width: "50%"}}>
                            <tr>
                                <td align={"center"}>
                                    <table>
                                        <tr>
                                            <td>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateState()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateMultiState()}}
                                                >
                                                    선택 레벨 일괄적용
                                                </button>
                                            </td>
                                            <td>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio11"+item.setting_level_no} id={"inlineRadio11"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos1.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler1(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio11' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio11"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>


            </div>
        </div>

    )
}

export default AttendanceSetting;