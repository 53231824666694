import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
const SiteSetting = ({siteSetting, onRefresh}) => {

    const userInfoId = useSelector((state) => state.userInfo.id)
    const [siteNm, setSiteNm] = useState("");
    const [siteTitle, setSiteTitle] = useState("");
    const [lineNoticeYn, setLineNoticeYn] = useState("");
    const [lineNotice, setLineNotice] = useState("");

    const [minDepositAmt, setMinDepositAmt] = useState("");
    const [depositUnit, setDepositUnit] = useState("");
    const [minWithdrawAmt, setMinWithdrawAmt] = useState("");
    const [withdrawUnit, setWithdrawUnit] = useState("");
    const [exchangeMaxCnt, setExchangeMaxCnt] = useState("");
    const [exchangeMaxAmt, setExchangeMaxAmt] = useState("");
    const [onceExchangeMaxAmt, setOnceExchangeMaxAmt] = useState("");
    const [exchangeLimitUnit, setExchangeLimitUnit] = useState("");
    const [littleExchangeAmt, setLittleExchangeAmt] = useState("");
    const [nextExchangeUnit, setNextExchangeUnit] = useState("")
    const [insolvencyCnt, setInsolvencyCnt] = useState("");
    const [paymentYn, setPaymentYn] = useState("");


    const siteNmChangeHandler = (e) => {
        setSiteNm(e.currentTarget.value);
    }
    const siteTitleChangeHandler = (e) => {
        setSiteTitle(e.currentTarget.value);
    }
    const lineNoticeYnChangeHandler = (e) => {
        setLineNoticeYn(e.currentTarget.value);
    }
    const lineNoticeChangeHandler = (e) => {
        setLineNotice(e.currentTarget.value);
    }
    const minDepositAmtChangeHandler = (e) => {
        setMinDepositAmt(e.currentTarget.value);
    }
    const depositUnitChangeHandler = (e) => {
        setDepositUnit(e.currentTarget.value);
    }
    const minWithdrawAmtChangeHandler = (e) => {
        setMinWithdrawAmt(e.currentTarget.value);
    }
    const withdrawUnitChangeHandler = (e) => {
        setWithdrawUnit(e.currentTarget.value);
    }
    const exchangeMaxCntChangeHandler = (e) => {
        setExchangeMaxCnt(e.currentTarget.value);
    }
    const exchangeMaxAmtChangeHandler = (e) => {
        setExchangeMaxAmt(e.currentTarget.value);
    }
    const onceExchangeMaxAmtChangeHandler = (e) => {
        setOnceExchangeMaxAmt(e.currentTarget.value);
    }
    const exchangeLimitUnitChangeHandler = (e) => {
        setExchangeLimitUnit(e.currentTarget.value);
    }
    const littleExchangeAmtChangeHandler = (e) => {
        setLittleExchangeAmt(e.currentTarget.value);
    }
    const nextExchangeUnitChangeHandler = (e) => {
        setNextExchangeUnit(e.currentTarget.value);
    }
    const insolvencyCntChangeHandler = (e) => {
        setInsolvencyCnt(e.currentTarget.value);
    }
    const paymentYnChangeHandler = (e) => {
        setPaymentYn(e.currentTarget.value);
    }


    const inputPriceFormat = (str) => {
        console.log("s", str);
        return str;
        // const comma = (str) => {
        //     str = String(str);
        //     return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
        // };
        // const uncomma = (str) => {
        //     str = String(str);
        //     return str.replace(/[^\d]+/g, "");
        // };
        // return comma(uncomma(str));
    };


    const updateState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveSiteSetting', {
                adminNo: userInfoId,
                siteNm: siteNm?siteNm:siteSetting.site_nm,
                siteTitle: siteTitle?siteTitle:siteSetting.site_title,
                lineNotice: lineNotice?lineNotice:siteSetting.line_notice,
                lineNoticeYn: lineNoticeYn?lineNoticeYn:siteSetting.line_notice_yn,
                minDepositAmt: minDepositAmt?minDepositAmt:siteSetting.min_deposit_amt,
                depositUnit: depositUnit?depositUnit:siteSetting.deposit_unit,
                minWithdrawAmt: minWithdrawAmt?minWithdrawAmt:siteSetting.min_withdraw_amt,
                withdrawUnit: withdrawUnit?withdrawUnit:siteSetting.withdraw_unit,
                exchangeMaxCnt: exchangeMaxCnt?exchangeMaxCnt:siteSetting.exchange_max_cnt,
                exchangeMaxAmt: exchangeMaxAmt?exchangeMaxAmt:siteSetting.exchange_max_amt,
                onceExchangeMaxAmt: onceExchangeMaxAmt?onceExchangeMaxAmt:siteSetting.once_exchange_max_amt,
                exchangeLimitUnit: exchangeLimitUnit?exchangeLimitUnit:siteSetting.exchange_limit_unit,
                littleExchangeAmt: littleExchangeAmt?littleExchangeAmt:siteSetting.little_exchange_amt,
                nextExchangeUnit: nextExchangeUnit?nextExchangeUnit:siteSetting.next_exchange_unit,
                insolvencyCnt: insolvencyCnt?insolvencyCnt:siteSetting.insolvency_cnt,
                paymentYn: paymentYn?paymentYn:siteSetting.payment_yn
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }

    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "120px"
                                }} type="submit" className="btn btn-primary" onClick={() => {updateState()}} >사이트 설정 수정
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="table table-bordered table-sm text-center align-middle" style={{width: "100%"}}>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 사이트명</td>
                            <td width={"1%"}></td>
                            <td width={"34%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text" style={{width: "220px"}}
                                           placeholder={siteSetting.site_nm} value={siteNm} onChange={siteNmChangeHandler}
                                    />
                                </div>
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>사이트 타이틀</td>
                            <td width={"1%"}></td>
                            <td width={"34%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text" style={{width: "220px"}}
                                           placeholder={siteSetting.site_title} value={siteTitle} onChange={siteTitleChangeHandler} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>라인 공지</td>
                            <td width={"1%"}></td>
                            <td width={"*%"} align={"left"} colSpan="4">
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>

                                    <input className="form-check-input" type="radio" value="Y" checked={lineNoticeYn === "" ? (siteSetting.line_notice_yn ==="Y" ?true:false) : ( lineNoticeYn ==="Y" ? true:false) }
                                           onChange={lineNoticeYnChangeHandler} style={{ cursor: "pointer" }}/> 사용
                                    &nbsp;
                                    <input className="form-check-input" type="radio" value="N" checked={lineNoticeYn === "" ? (siteSetting.line_notice_yn ==="N" ?true:false) : ( lineNoticeYn ==="N" ? true:false) }
                                           onChange={lineNoticeYnChangeHandler} style={{ cursor: "pointer" }}/> 미사용
                                </div>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text" style={{width: "1030px"}}
                                           placeholder={siteSetting.line_notice} value={lineNotice} onChange={lineNoticeChangeHandler}/>
                                </div>
                            </td>
                        </tr>

                    </table>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <b>* 입출금설정</b>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <br></br>
                    <table className="table table-bordered table-sm text-center align-middle" style={{width: "80%"}}>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 최소입금금액</td>
                            <td width={"1%"}></td>
                            <td width={"34%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.min_deposit_amt)}
                                           value={minDepositAmt}
                                           onChange={(e) => setMinDepositAmt(e.target.value)}
                                    />
                                </div>
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 입금단위</td>
                            <td width={"1%"}></td>
                            <td width={"34%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.deposit_unit)}
                                           value={depositUnit}
                                           onChange={(e) => setDepositUnit(inputPriceFormat(e.target.value))}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 최소출금금액</td>
                            <td width={"1%"}></td>
                            <td width={"34%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.min_withdraw_amt)}
                                           value={minWithdrawAmt}
                                           onChange={(e) => setMinWithdrawAmt(inputPriceFormat(e.target.value))}
                                    />
                                </div>
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 출금단위</td>
                            <td width={"1%"}></td>
                            <td width={"34%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.withdraw_unit)}
                                           value={withdrawUnit}
                                           onChange={(e) => setWithdrawUnit(inputPriceFormat(e.target.value))}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 일일최대환전신청건수</td>
                            <td width={"1%"}></td>
                            <td width={"34%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.exchange_max_cnt)}
                                           value={exchangeMaxCnt}
                                           onChange={(e) => setExchangeMaxCnt(inputPriceFormat(e.target.value))}
                                    />
                                    &nbsp;0일 경우 무시됨
                                </div>
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 일일최대환전금액</td>
                            <td width={"1%"}></td>
                            <td width={"34%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.exchange_max_amt)}
                                           value={exchangeMaxAmt}
                                           onChange={(e) => setExchangeMaxAmt(inputPriceFormat(e.target.value))}
                                    />
                                    &nbsp;0일 경우 무시됨
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 1회 최대환전신청금액</td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.once_exchange_max_amt)}
                                           value={onceExchangeMaxAmt}
                                           onChange={(e) => setOnceExchangeMaxAmt(inputPriceFormat(e.target.value))}
                                    />
                                    &nbsp;0일 경우 무시됨
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 환전시 시간 간격 제한(분)
                                - 기본
                            </td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.exchange_limit_unit)}
                                           value={exchangeLimitUnit}
                                           onChange={(e) => setExchangeLimitUnit(inputPriceFormat(e.target.value))}
                                    />
                                    &nbsp;환전신청시 마지막 성공한 환전으로부터 최소 몇 분이 지나야 신청 가능한 지, 0일 경우 무시됨
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <b>* 소액 환전시 환전 간격 - 위 조건보다 우선함</b>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <br></br>
                    <table className="table table-bordered table-sm text-center align-middle" style={{width: "80%"}}>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 소액 환전 금액
                                기준<br></br>(이 금액보다 같거나 적은 금액을 환전하면 아래 시간제한 적용)
                            </td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.little_exchange_amt)}
                                           value={littleExchangeAmt}
                                           onChange={(e) => setLittleExchangeAmt(inputPriceFormat(e.target.value))}
                                    />
                                    &nbsp;원 이하일 경우(0일 경우 소액환전 시간간격 적용안됨)
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 다음 환전 신청 간격(분) -
                                특수
                            </td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.next_exchange_unit)}
                                           value={nextExchangeUnit}
                                           onChange={(e) => setNextExchangeUnit(inputPriceFormat(e.target.value))}
                                    />
                                    &nbsp;환전신청시 마지막 성공한 환전으로부터 최소 몇 분이 지나야 신청 가능한 지, 0일 경우 무시됨.
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <b>* 첫충/매충 포인트 관련</b>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <br></br>
                    <table className="table table-bordered table-sm text-center align-middle" style={{width: "80%"}}>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 매충포인트 지급 불가 환전
                                횟수
                            </td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(siteSetting.insolvency_cnt)}
                                           value={insolvencyCnt}
                                           onChange={(e) => setInsolvencyCnt(inputPriceFormat(e.target.value))}
                                    />
                                    &nbsp;0일 경우 무시됨(1로 설정하면, 당일 1회 환전시점 이후로 매충 포인트 지급 안됨)
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 환전이 있어도 첫충 포인트는
                                지급 여부
                            </td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={siteSetting.payment_yn} value={paymentYn} onChange={paymentYnChangeHandler} />
                                    &nbsp;0일 경우 위설정을 따름. 1일 경우, 첫충 포인트는 환전이 있더라도 지급
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <b>* 최고관리자용 설정</b>
                </div>
                <br></br><br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color="blue"><b>팝업공지 수정</b></font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color="red">* 팝업 공지 관리는 게시판/이벤트 관리 > 공지팝업으로 이동되었습니다.</font>
                </div>

            </div>
        </div>

    )
}

export default SiteSetting;