import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState ={
    isLoading: false,
    error: null,
    blackUploadList: [],
}
export const statsSlice = createSlice({
    name: 'BlackUploadReducer',
    initialState,
    reducers: {
        getBlackUploadList(state, action) {
            state.isLoading = true;
        },
        setBlackUploadList(state, action) {
            state.blackUploadList = action.payload;
        },
    }
});

export const {
    setBlackUploadList,
} = statsSlice.actions;

export default statsSlice.reducer;