import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
import {closeUserSimpleInfo, openUserSimpleInfo} from "../../redux/reducers/userInfo";
const MoneyList = ({moneyList, onRefresh}) => {
    const dispatch = useDispatch()

    const getCmmnCd = () => {
        dispatch(closeUserSimpleInfo())
    }
    useEffect(getCmmnCd, [])


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead >
                        <tr>
                            <th style={{width: "2%"}}>순서</th>
                            <th style={{width: "5%"}}>부본사</th>
                            <th style={{width: "5%"}}>가맹</th>
                            <th style={{width: "5%"}}>아이디</th>
                            <th style={{width: "5%"}}>닉네임</th>
                            <th style={{width: "5%"}}>이름</th>
                            <th style={{width: "5%"}}>회원구분</th>
                            <th style={{width: "10%"}}>로그구분</th>
                            <th style={{width: "10%"}}>베팅상세</th>
                            <th style={{width: "5%"}}>이전금액</th>
                            <th style={{width: "5%"}}>요청금액</th>
                            <th style={{width: "5%"}}>현재금액</th>
                            <th style={{width: "5%"}}>일시</th>
                            <th style={{width: "5%"}}>관련번호</th>
                            <th style={{width: "*%"}}>메모</th>
                            <th style={{width: "5%"}}>수행아이디</th>
                            <th style={{width: "5%"}}>수행아이피</th>
                        </tr>
                        </thead>
                        {!moneyList.length && (
                            <tbody key={moneyList.no}>
                            <tr>
                                <td colSpan="17">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {moneyList?.map((moneyList, index) => (
                            <tbody key={moneyList.no}>
                            <tr>
                                <td align={"center"}>{moneyList.no}</td>
                                <td align={"center"}>{moneyList.company_sub_no}</td>
                                <td align={"center"}>{moneyList.mrch_cd}</td>
                                <td align={"center"} style={{backgroundColor:moneyList.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: moneyList.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={moneyList.font_color}>{moneyList.id}</font>
                                </td>
                                <td align={"center"} style={{backgroundColor:moneyList.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: moneyList.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={moneyList.font_color}>{moneyList.nickname}</font>
                                </td>
                                <td align={"center"} style={{backgroundColor:moneyList.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: moneyList.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={moneyList.font_color}>{moneyList.name}</font>
                                </td>
                                <td align={"center"} ><font color="green">{moneyList.user_gb_cd}</font></td>
                                <td align={"center"} ><font color="blue">{moneyList.log_gb_cd}</font></td>
                                <td align={"center"}>{moneyList.bet_detail}</td>
                                <td align={"center"} >
                                    <font color="blue">
                                        <NumericFormat value={moneyList.before_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"} >
                                    <font color="red">
                                        <NumericFormat value={moneyList.request_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"} >
                                    <font color="blue">
                                        <NumericFormat value={moneyList.now_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"}>{moneyList.updatedAt}</td>
                                <td align={"center"} ><font color="blue">{moneyList.involvement_no}</font></td>
                                <td align={"center"}>{moneyList.memo}</td>
                                <td align={"center"}>{moneyList.reg_id}</td>
                                <td align={"center"} ><font color="blue">{moneyList.reg_ip}</font></td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default MoneyList;