import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {useNavigate } from 'react-router-dom';
import {getCompanySubListReducer,setComboGb,setQuery} from "../../redux/reducers/partner/CompanySubListReducer";
import Pagination from "../../components/common/Pagination2";
import * as XLSX from "xlsx";
import {insertAdminLog} from "../../lib/api";

const CompanySub_list = () => {
    const userInfoId = useSelector((state) => state.userInfo.id)
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.CompanySubListReducer)
    const navigate = useNavigate();

    const getList = () => {
        if(initialState.totalList !== null ) {
            dispatch(getCompanySubListReducer({
                input:{
                    page: 1,
                    pageSize: initialState.input.pageSize,
                    query : initialState.input.query,
                    comboGb : initialState.input.comboGb,
                }
            }))
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "부본사목록"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getCompanySubListReducer({
            input:{
                page: page,
                pageSize: initialState.input.pageSize,
                query : initialState.input.query,
                comboGb : initialState.input.comboGb,
            }
        }))
    }

    const onSubmitHandler = (e) => {
        navigate('/partner/CompanySub_add');
    }

    const updatePageMove = (id) => {
        navigate('/partner/CompanySub_add',{state:{gb:'수정',id:id}});
    }

    const orgSelect = (e) =>{
        navigate('/partner/Distributor_add',{state:{gb:'수정',id:e.target.value}});
    }

    const look = (org_nm) => {
        navigate('/partner/Distributor_list',{state:{comboGb:'부본사명',query:org_nm}});
    }

    const inputQuery = (e) => {
        dispatch(setQuery(e.target.value))
        if (e.key === 'Enter') {
            dispatch(getCompanySubListReducer({
                input:{
                    page: 1,
                    pageSize: initialState.input.pageSize,
                    query : initialState.input.query,
                    comboGb : initialState.input.comboGb,
                }
            }))
        }
    }

    const queryKeyDown = (e) => {
        if (e.key === 'Enter') {
            dispatch(getCompanySubListReducer({
                input:{
                    page: 1,
                    pageSize: initialState.input.pageSize,
                    query : initialState.input.query,
                    comboGb : initialState.input.comboGb,
                }
            }))
        }
    }

    const handleBlur = (e) =>{
        dispatch(getCompanySubListReducer({
            input:{
                page: 1,
                pageSize: initialState.input.pageSize,
                query : initialState.input.query,
                comboGb : initialState.input.comboGb,
            }
        }))
    }

    const addOrg = (id) => {
        navigate('/partner/Distributor_add',{state:{up_org_id:id}});
    }

    const selectComboGb = (e) => {
        dispatch(setComboGb(e.target.value))
    }
    const exportExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {
            let jsonObject = { // JSON 객체를 생성합니다.
                부본사명: initialState.totalList[i].orgNm? initialState.totalList[i].orgNm :'',
                부본사아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                관리자: initialState.totalList[i].nm? initialState.totalList[i].nm :'',
                권한: initialState.totalList[i].orgGbCdNm? initialState.totalList[i].orgGbCdNm :'',
                츙전액_7일간: initialState.totalList[i].depositAmt7? initialState.totalList[i].depositAmt7 :'',
                환전액_7일간: initialState.totalList[i].withdrawAmt7? initialState.totalList[i].withdrawAmt7 :'',
                마진_7일간: initialState.totalList[i].marginAmt7? initialState.totalList[i].marginAmt7 :'',
                유저보유액: initialState.totalList[i].userHoldingAmount? initialState.totalList[i].userHoldingAmount :'',
                유저보유포인트 : initialState.totalList[i].userHoldingPoint? initialState.totalList[i].userHoldingPoint :'',
                구분_전체 : initialState.totalList[i].userGbAll? initialState.totalList[i].userGbAll :'',
                구분_대기 : initialState.totalList[i].userGbHold? initialState.totalList[i].userGbHold :'',
                구븐_무료 : initialState.totalList[i].userGbFree? initialState.totalList[i].userGbFree :'',
                구분_유료 : initialState.totalList[i].userGbPaid? initialState.totalList[i].userGbPaid :'',
                구분_vip : initialState.totalList[i].userGbVip? initialState.totalList[i].userGbVip :'',
                구분_가라 : initialState.totalList[i].userGbFake? initialState.totalList[i].userGbFake :'',
                상태_삭제 : initialState.totalList[i].userGbDel? initialState.totalList[i].userGbDel :'',
                생성일시 : initialState.totalList[i].createdAt? initialState.totalList[i].createdAt :'',
                최근접속일시 : initialState.totalList[i].rcntActvDttm? initialState.totalList[i].rcntActvDttm :'',
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '부본사목록');
        XLSX.writeFile(workBook, '부본사목록.xlsx');
    }

    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">부본사목록</font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table   width={"50%"}>
                        <tbody>
                        <tr>
                            <td align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <button onClick={exportExcel} style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>엑셀 다운로드</button>
                                    <select onChange={selectComboGb} value={initialState.input.comboGb} className="form-select sm-select" defaultValue={0} style={{width: "100px"}}>
                                        <option value=''>전체</option>
                                        <option value='부본사명'>부본사명</option>
                                    </select>
                                    &nbsp;
                                    <input onChange={inputQuery} onKeyDown={queryKeyDown} onBlur={handleBlur} value={initialState.input.query} className="form-check-input sm-input" type="text" style={{ width: "200px" }}/>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table   width={"49%"}>
                        <tbody>
                        <tr>
                            <td align={"right"}>
                                <button onClick={onSubmitHandler} style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}} >부본사 등록</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>

            </div>


            <table className="table table-bordered table-sm text-center align-middle">
                <thead>
                <tr>

                </tr>
                </thead>
            </table>
            <div className="commonT container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table id = 'grid' className="commonT table table-bordered table-sm text-center align-middle">
                    <thead>
                    <tr>
                        <th style={{width: "5%"}} rowSpan="2" >부본사명</th>
                        <th style={{width: "5%"}} rowSpan="2" >부본사아이디</th>
                        <th style={{width: "5%"}} rowSpan="2" >관리자</th>
                        <th style={{width: "16%"}} rowSpan="2" >총판</th>
                        <th style={{width: "3%"}} rowSpan="2" >권한</th>
                        <th style={{width: "5%"}} rowSpan="2" >충전액(7일간)</th>
                        <th style={{width: "5%"}} rowSpan="2" >환전액(7일간)</th>
                        <th style={{width: "5%"}} rowSpan="2" >마진(7일간)</th>
                        <th style={{width: "5%"}} rowSpan="2" >유저보유액</th>
                        <th style={{width: "5%"}} rowSpan="2" >유저보유포인트</th>
                        <th style={{width: "*%"}} colSpan="7">상태</th>
                        <th style={{width: "5%"}} rowSpan="2" >생성일시</th>
                        <th style={{width: "5%"}} rowSpan="2" >최종로그인일시</th>
                    </tr>
                    <tr>
                        <th>전체</th>
                        <th>대기</th>
                        <th>무료</th>
                        <th>유료</th>
                        <th>VIP</th>
                        <th>가라</th>
                        <th>삭제</th>
                    </tr>
                    </thead>
                    {initialState.totalList && initialState.totalList.length > 0 ? initialState.gridList.map((List, index) => (
                            <tbody key={List.id}>
                            <tr>
                                <td align={"center"} style={{cursor: 'pointer'}} onClick={() => updatePageMove(List.id)}><font color="blue">{List.orgNm}</font></td>
                                <td align={"center"} style={{cursor: 'pointer'}} onClick={() => updatePageMove(List.id)}><font color="blue">{List.id}</font></td>
                                <td align={"center"}>{List.nm}</td>
                                <td align={"center"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <select onChange={orgSelect} className="form-select sm-select">
                                            <option value="">보유 총판 ({List.orgList.length}개)</option>
                                            {List.orgList?.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.org_nm}
                                                </option>
                                            ))}
                                        </select>
                                        &nbsp;
                                        <button onClick={()=>look(List.orgNm)} style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>보기</button>
                                        <button onClick={()=>addOrg(List.id)} style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>추가</button>
                                    </div>
                                </td>
                                <td align={"center"}>{List.orgGbCdNm}</td>
                                <td align={"center"} ><font color="blue">{List.depositAmt7}</font></td>
                                <td align={"center"} ><font color="red">{List.withdrawAmt7}</font></td>
                                <td align={"center"} ><font color="blue">{List.marginAmt7}</font></td>
                                <td align={"center"}>{List.userHoldingAmount}</td>
                                <td align={"center"}>{List.userHoldingPoint}</td>
                                <td align={"center"}>{List.userGbAll}</td>
                                <td align={"center"} ><font color="blue">{List.userGbHold}</font></td>
                                <td align={"center"} ><font color="blue">{List.userGbFree}</font></td>
                                <td align={"center"} ><font color="blue">{List.userGbPaid}</font></td>
                                <td align={"center"} ><font color="blue">{List.userGbVip}</font></td>
                                <td align={"center"} ><font color="blue">{List.userGbFake}</font></td>
                                <td align={"center"} ><font color="blue">{List.userGbDel}</font></td>
                                <td align={"center"}>{List.createdAt}</td>
                                <td align={"center"}>{List.rcntActvDttm}</td>
                            </tr>
                            </tbody>
                        )) :
                        <tbody>
                        <tr>
                            <td colSpan= "18">조회된 데이터가 없습니다.</td>
                        </tr>
                        </tbody>
                    }
                </table>
            </div>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalCount && initialState.totalCount > 0 ?
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.input.pageSize} currentPage={initialState.input.page} onPageChange={handlePageChange} />
                    :""}
            </div>
        </div>

    )
}
export default CompanySub_list;