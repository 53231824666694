import React from "react";
import ActiveContainer from "../../containers/monitoring/ActiveContainer";
const Active = () => {
    return (
        <div className="sm-font">
            <font color={"blue"} size="3">관리자 활동 로그</font>&nbsp;
            <p style={{ fontSize: "9px" }}>
                <span>* 90일 이내 검색만 가능합니다.</span>
            </p>
            <ActiveContainer />
        </div>

    )
}
export default Active;