import moment from "moment";

const common = {
    groupBy : (array, property) => array.reduce((grouped, element) => ({
        ...grouped,
        [element[property]]: [...(grouped[element[property]] || []), element]
    }), {}),
    //날짜문자열에대한 지난시간 표기 4주 이후면 그대로표기.
    getRemainTime : (dateStr) => {
        const milliSeconds = new Date() - new Date(dateStr);
        const seconds = milliSeconds / 1000;
        const minutes = seconds / 60;
        const hours = minutes / 60;
        const days = hours / 24;
        const weeks = days / 7;
        if (seconds < 60) {
            return `방금 전`
        }
        else if (minutes < 60) {
            return `${Math.floor(minutes)}분 전`
        }
        else if (hours < 24) {
            return `${Math.floor(hours)}시간 전 `
        }
        else if (days < 7) {
            return `${Math.floor(days)}일 전`
        }
        else if (weeks < 5) {
            return `${Math.floor(weeks)}주 전`
        }
        else {
            return dateStr
        }

    },
}

export default common;