import {createSlice} from "@reduxjs/toolkit";


export const alertsSlice = createSlice({
    name: "alerts",
    initialState: {
        isLoading: false,
        error: null,
        alerts: null,
    },
    reducers: {
        getAlerts(state, action) {
            state.isLoading = true;
        },
        setAlerts(state, action) {
            state.isLoading = false;
            state.alerts = action.payload.result
        },
        failedGetAlerts(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
    }
});

export const {getAlerts, setAlerts, failedGetAlerts} = alertsSlice.actions

export default alertsSlice.reducer;
