import React from 'react';
import MiniGameContainer from "../../../containers/game/manage/MiniGameContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const MiniGame = () => {
    return (
        <div className="common">
            <font color={"blue"} size="3">미니게임</font>
            <MiniGameContainer />
        </div>
    );
};

export default MiniGame;