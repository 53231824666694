import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {setAddrow,getGroupSettingListReducer,setGroupNm,setColorNo,setV1,setText,setV4,setV5,setV2,setV3} from "../../redux/reducers/setting/GroupSettingListReducer";
import Pagination from "../../components/common/Pagination2";
import axios from "axios";
import {insertAdminLog} from "../../lib/api";

import SiteSettingContainer from "../../containers/setting/SiteSettingContainer";
import CodeSettingPage from "../../pages/setting/codeSetting";

const GroupSetting = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.GroupSettingListReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const getList = () => {
        if(initialState.totalList !== null ) {
            dispatch(getGroupSettingListReducer({
                input:{
                    page : 1,
                    pageSize : initialState.input.pageSize
                }
            }))
        }
        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "그룹관리"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const reload = () =>{
        dispatch(getGroupSettingListReducer({
            input:{
                page : 1,
                pageSize : initialState.input.pageSize
            }
        }))
    }

    const setGroupNmHandle = (idx,val,stat) =>{
        dispatch(setGroupNm({idx:idx,val:val,_rowStat:stat}))
    }

    const setColorNoHandle = (idx,val,stat) =>{
        dispatch(setColorNo({idx:idx,val:val,_rowStat:stat}))
    }

    const setV1Handle = (idx,val,stat) =>{
        dispatch(setV1({idx:idx,val:val,_rowStat:stat}))
    }

    const setV2Handle = (idx,val,stat) =>{
        dispatch(setV2({idx:idx,val:val,_rowStat:stat}))
    }

    const setV3Handle = (idx,val,stat) =>{
        dispatch(setV3({idx:idx,val:val,_rowStat:stat}))
    }

    const setV4Handle = (idx,val,stat) =>{
        dispatch(setV4({idx:idx,val:val,_rowStat:stat}))
    }

    const setV5Handle = (idx,val,stat) =>{
        dispatch(setV5({idx:idx,val:val,_rowStat:stat}))
    }

    const setTextHandle = (idx,val,stat) =>{
        dispatch(setText({idx:idx,val:val,_rowStat:stat}))
    }

    const deleteRow = (no) => {
        axios.post('/admin/setting/deleteGroupSetting', {no : no}).
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }

    const insertUpdate = (idx,stat) =>{
        if(stat === 'X'){
            alert("변경사항이 없습니다.");
            return false;
        }
        console.log("##userInfo.reg_id##",userInfoId)
        const _input = { ...initialState.gridList[idx], reg_id: userInfoId , upd_id : userInfoId};

        axios.post('/admin/setting/insertUpdateGroupSetting',{_input}).
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }

    const addRow = () =>{
        dispatch(setAddrow())
    }

    const saveAll = () => {
        axios.post('/admin/setting/saveGroupSettingList',initialState.gridList).
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }

    const handlePageChange = (page) => {
        dispatch(getGroupSettingListReducer({
            input:{
                page: page,
                pageSize: initialState.input.pageSize,
            }
        }))
    }

    return (
        <div className="common">
            <table border={0}>
                <tr>
                    <td>
                        <SiteSettingContainer />
                    </td>
                    <td>
                        <CodeSettingPage/>
                    </td>
                </tr>
            </table>



            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">그룹관리</font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"}>
                        <tbody>
                        <tr>
                            <td align={"right"}>
                                <button onClick={addRow} style={{
                                    width: "100px", backgroundColor:"green"
                                }} type="submit" className="btn btn-primary" >신규 등록</button>
                                &nbsp;
                                <button onClick={saveAll} style={{
                                    width: "100px", backgroundColor:"green"
                                }} type="submit" className="btn btn-primary" >일괄 저장</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th style={{width: "5%"}}>고유번호</th>
                            <th style={{width: "7%"}}>그룹명</th>
                            <th style={{width: "7%"}}>색상번호</th>
                            <th style={{width: "5%"}}>v1</th>
                            <th style={{width: "5%"}}>v2</th>
                            <th style={{width: "5%"}}>v3</th>
                            <th style={{width: "5%"}}>v4</th>
                            <th style={{width: "5%"}}>v5</th>
                            <th style={{width: "*%"}}>text</th>
                            <th style={{width: "7%"}}>수정/삭제</th>
                        </tr>
                        </thead>
                        {initialState.totalList && initialState.totalList.length > 0 ? initialState.gridList.map((List, index) => (
                                <tbody key={index}>
                                <tr>
                                    <td align={"center"}>{List.no}</td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setGroupNmHandle(index,e.target.value,List._rowStat)} value={List.group_nm} className="form-check-input sm-input" type="text" style={{ width: "140px" }} placeholder="설명을 입력하세요"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setColorNoHandle(index,e.target.value,List._rowStat)} value={List.color_no} className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="색상번호"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setV1Handle(index,e.target.value,List._rowStat)} value={List.V1} className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="value 1"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setV2Handle(index,e.target.value,List._rowStat)} value={List.V2} className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="value 2"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setV3Handle(index,e.target.value,List._rowStat)} value={List.V3} className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="value 3"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setV4Handle(index,e.target.value,List._rowStat)} value={List.V4} className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="value 4"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setV5Handle(index,e.target.value,List._rowStat)} value={List.V5} className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="value 5"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <textarea onChange={(e)=> setTextHandle(index,e.target.value,List._rowStat)} value={List.text} name="textarea_name" cols="50" rows="3"/>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <button onClick={()=>insertUpdate(index,List._rowStat)} style={{ backgroundColor: List._rowStat==='I'?'#5dc434':'deepskyblue', border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>{List._rowStat==='I'?'추가':'변경'}</button>
                                            <button onClick={()=>deleteRow(List.no)} style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>삭제</button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            )) :
                            <tbody>
                            <tr>
                                <td colSpan= "14">조회된 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalCount && initialState.totalCount > 0 ?
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.input.pageSize} currentPage={initialState.input.page} onPageChange={handlePageChange} />
                    :""}
            </div>
        </div>
    )
}
export default GroupSetting;