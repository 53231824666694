import React from "react";
import MarketContainer from "../../../containers/game/setting/MarketContainer";


const MarketSetting = () => {



    return <div>
        <font color={"blue"} size="3">마켓설정</font>
        <MarketContainer />
    </div>
}

export default MarketSetting