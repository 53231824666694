import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
const MemberUpdate = ({memberUpdate, onRefresh}) => {



    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead >
                        <tr>
                            <th style={{width: "2%"}}>no</th>
                            <th style={{width: "7%"}}>소속</th>
                            <th style={{width: "5%"}}>아이디</th>
                            <th style={{width: "7%"}}>닉네임</th>
                            <th style={{width: "*%"}}>변경 필드명</th>
                            <th style={{width: "23%"}}>기존 값</th>
                            <th style={{width: "23%"}}>수정 값</th>
                            <th style={{width: "5%"}}>수정 아이디</th>
                            <th style={{width: "7%"}}>수정 아이피</th>
                            <th style={{width: "8%"}}>수정 시각</th>
                        </tr>
                        </thead>
                        {!memberUpdate.length && (
                            <tbody key={memberUpdate.no}>
                            <tr>
                                <td colSpan="10">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {memberUpdate.map((memberUpdate, index) => (
                            <tbody key={memberUpdate.no}>
                            <tr>
                                <td align={"center"}>{memberUpdate.no}</td>
                                <td align={"center"}>{memberUpdate.store_cd}</td>
                                <td align={"center"}>{memberUpdate.id}</td>
                                <td align={"center"}>{memberUpdate.nickname}</td>
                                <td align={"center"} style={{backgroundColor:"lightgray"}} >{memberUpdate.change_field}</td>
                                <td align={"center"}>{memberUpdate.asis_value}</td>
                                <td align={"center"}>{memberUpdate.tobe_value}</td>
                                <td align={"center"}>{memberUpdate.upd_id}</td>
                                <td align={"center"}>{memberUpdate.update_ip}</td>
                                <td align={"center"}>{memberUpdate.updatedAt}</td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default MemberUpdate;