import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../redux/reducers/user/BlackMemberAddModalReducer";

const DepositAndWithdrawLogContainer = ({userId}) => {


    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <b>일 단위 접속 및 사용 기록</b>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                * 최대 90일 까지의 사용 기록을 보여줍니다.
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
                <table className="commonT table table-bordered table-sm text-center" style={{width: "100%"}}>
                    <thead >
                    <tr>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>기준일</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>로그인 성공 횟수</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>로그인 실패 횟수</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>충전</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>환전</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>마진</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>베팅금</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>당첨금</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>베팅단순득실</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>베팅당첨률</th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>



    )
}
export default DepositAndWithdrawLogContainer;