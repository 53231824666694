import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import OverlapList from "../../components/monitoring/OverlapList";
import {getOverlaps} from "../../redux/reducers/monitoring/OverlapReducer";
import * as XLSX from "xlsx";
import moment from "moment/moment";


const OverlapContainer = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.overlap);

    useEffect(() => {
        dispatch(getOverlaps({

        }))
    }, [])

    const onRefresh = () => {
        dispatch(getOverlaps({

        }))
    }

    const exportExcel = () => {
        if(state.overlaps.length > 0) {
            let excelList = [];
            for(let i = 0; i < state.overlaps.length; i++) {
                const row = state.overlaps[i]
                let jsonObject = {
                    "순서": row.no? row.no : "",
                    "번호": row.access_no? row.access_no : "",
                    "아이디": row.id? row.id : "",
                    "닉네임": row.nickname? row.nickname : "",
                    "총판": row.store_cd? row.store_cd : "",
                    "가맹점": row.mrch_cd? row.mrch_cd : "",
                    "회원등급": row.user_gb_cd? row.user_gb_cd : "",
                    "회원상태": row.user_state_cd? row.user_state_cd : "",
                    "충전": row.deposit_amt > 0? row.deposit_amt : 0,
                    "환전": row.withdraw_amt > 0? row.withdraw_amt : 0,
                    "마진": row.profit_amt > 0? row.profit_amt : 0,
                    "가입일": row.reg_dt? row.reg_dt : "",
                    "최근활동": row.login_dt? row.login_dt : "",
                    "로그인": row.login_cnt > 0? row.login_cnt : 0,
                    "접속IP": row.use_ip? row.use_ip : "",
                    "삭제여부": "-",
                }
                excelList.push(jsonObject)
            }

            const workSheet = XLSX.utils.json_to_sheet(excelList);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, '중복아이피');
            XLSX.writeFile(workBook, `중복아이피_${moment().format("YYYYMMDD")}.xlsx`);
        }
        else {
            alert("다운로드할 내용이 없습니다.")
        }
    }

    return (
        <>

        <div className="common">
                <br></br>
                <button style={{ marginLeft: "5px" ,position: "relative", top: "-10px" , backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px"}} onClick={exportExcel}>엑셀 다운로드</button>
            </div>

            <OverlapList state={state} onRefresh={onRefresh} />
        </>
    )
}

export default OverlapContainer