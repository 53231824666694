import React, {useEffect, useState} from "react";
import CheckList from "../../components/board/CheckList";
import {useDispatch, useSelector} from "react-redux";
import {getChecks} from "../../redux/reducers/board/CheckReducer";
import * as XLSX from "xlsx";
import moment from "moment";

const CheckContainer = () => {

    const dispatch = useDispatch()
    const state = useSelector((state) => state.check)
    const [searchQuery, setSearchQuery] = useState("")
    const [month, setMonth] = useState(moment().format("YYYY-MM"))
    const userInfoId = useSelector((state) => state.userInfo.id);

    useEffect(() => {
        dispatch(getChecks({
            month: month,
            query: searchQuery,
            id: userInfoId
        }))
    }, [])

    const onRefresh = () => {
        dispatch(getChecks({
            month: month,
            query: state.query,
            id: userInfoId
        }))
    }

    const changeQuery = (e) => {
        setSearchQuery(e.currentTarget.value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.currentTarget.value)
        }
    }

    const changeMonth = (e) => {
        const currentMonth = e.currentTarget.value
        //alert(moment().endOf("month").format("YYYY-MM-DD"))
        if(!moment(currentMonth).isAfter(moment().endOf("month"))) {
            setMonth(currentMonth)
            dispatch(getChecks({
                month: currentMonth,
                query: state.query,
                id: userInfoId
            }))
        }
        else {
            alert("이번달 까지만 조회가능합니다")
        }
    }

    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getChecks({
            month: month,
            query: searchQuery,
            id: userInfoId
        }))
    }

    const exportExcel = () => {
        if(state.checks.length > 0) {
            let excelList = [];
            for(let i = 0; i < state.checks.length; i++) {
                const row = state.checks[i]
                let jsonObject = {
                    번호: row.no? row.no : "",
                    아이디: row.id? row.id : "",
                    닉네임: row.nickname? row.nickname : "",
                    "연속 출석일 수 : (최대30일)": row.consec_att_day > 0? row.consec_att_day : 0,
                    "이달 마지막 연속 출석일 수": row.consec_att_day_month > 0? row.consec_att_day_month : 0,
                    "이달 총 출석일 수": row.att_day_total_month > 0? row.att_day_total_month : 0,
                    상세: "",
                }
                excelList.push(jsonObject)
            }

            const workSheet = XLSX.utils.json_to_sheet(excelList);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, '출석체크');
            XLSX.writeFile(workBook, `출석체크_${moment().format("YYYYMMDD")}.xlsx`);
        }
        else {
            alert("다운로드할 내용이 없습니다.")
        }
    }

    return <>
        <div>
            <div className="sm-center-div-no-border-100" style={{justifyContent: "start"}}>
                <button style={{ marginRight: "10px", backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}} onClick={exportExcel}>엑셀 다운로드</button>
                <div className="sm-center-div">
                    <label className="sm-label">조회년월</label>
                    <input className="form-check-input sm-input" type="month" value={month} onChange={changeMonth}/>

                    <label className="sm-label">, 닉네임/아이디 검색 :</label>

                    <input className="form-check-input sm-input" type="text" value={searchQuery} onChange={changeQuery} onKeyDown={inputPress}/>
                    <button onClick={(e) => {
                        onSearch(searchQuery)
                    }}>검색</button>
                </div>
            </div>
            <p>
                <span style={{ color: "red" }}>* 상세보기를 눌러, 각 출석처리/미출석처리 버튼을 클릭하여 출석체크수정할 수 있습니다. 딘, 오늘 이후의 출석신청은 불가하며, 출석체크를 관리자가 할 경우에 대해서는 포인트가 따로 지급되지 않습니다.</span>
                <br/>
                <span style={{ color: "red" }}>* 상세보기에 나오는 날짜는, 검색월 이전 달 1일부터 최대 오늘까지 입니다.</span>
            </p>

        </div>

        <CheckList state={state} onRefresh={onRefresh} month={month} />
    </>
}

export default CheckContainer