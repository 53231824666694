import React from 'react';

const HighlightedText = ({ text, searchText }) => {
    // 검색어와 일치하는 부분을 정규표현식을 사용하여 찾고, 해당 부분을 강조하는 HTML로 반환합니다.
    const getHighlightedText = () => {
        if (!searchText || searchText === '') {
            return <span>{text}</span>;
        }

        const regex = new RegExp(`(${searchText})`, 'gi');
        const parts = text.split(regex);

        return (
            <span>
        {parts?.map((part, index) =>
            regex.test(part) ? (
                <span key={index} style={{ backgroundColor: 'yellow',color: 'red' }}>
              {part}
            </span>
            ) : (
                <span key={index}>{part}</span>
            )
        )}
      </span>
        );
    };

    return <>{getHighlightedText()}</>;
};

export default HighlightedText;