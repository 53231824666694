import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    isLoading: false,
    error: null,
    kinds: [],
    selectedKind: -1,
    selectedKindName: "",
    nations: [],
    selectedNation: -1,
    selectedNationName: "",
    leagues: [],
    selectedLeague: -1,
    selectedLeagueName: "",
    teams: [],
    page3: 1,
    pageSize3: 20,
    totalCount3: 0,
}

export const gameSettingSlice = createSlice({
    name: 'gameSetting',
    initialState,
    reducers: {
        getGameKinds(state, action) {
            state.isLoading = true;
        },
        failedGetGameKinds(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        changeIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setGameKinds(state, action) {
            state.isLoading = false;
            state.kinds = action.payload.result
        },
        setSelectedKind(state, action) {
            state.selectedKind = action.payload.id
            state.selectedKindName = action.payload.name_kor? action.payload.name_kor: action.payload.name
        },
        getNations(state, action) {
            state.isLoading = true;
        },
        failedGetNations(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setNations(state, action) {
            state.isLoading = false;
            state.nations = action.payload.result
        },
        setSelectedNation(state, action) {
            state.selectedNation = action.payload.id
            state.selectedNationName = action.payload.name? action.payload.name : action.payload.name_kor
        },
        getLeagues(state, action) {
            state.isLoading = true;
            state.page3 = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize3 = action.payload.pageSize;
        },
        failedGetLeagues(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setLeagues(state, action) {
            state.isLoading = false;
            state.totalCount3 = action.payload.totalCount
            state.leagues = action.payload.result
        },
        setSelectedLeagues(state, action) {
            state.selectedLeague = action.payload.id
            state.selectedLeagueName = action.payload.name? action.payload.name : action.payload.name_kor
        },
        getTeams(state, action) {
            state.isLoading = true;
        },
        failedGetTeams(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setTeams(state, action) {
            state.isLoading = false;
            state.teams = action.payload.result
        },
    }
});

export const {
    getGameKinds,
    failedGetGameKinds,
    setGameKinds,
    setSelectedKind,
    getNations,
    failedGetNations,
    setNations,
    setSelectedNation,
    getLeagues,
    failedGetLeagues,
    setLeagues,
    setSelectedLeagues,
    getTeams,
    failedGetTeams,
    setTeams,
    changeIsLoading,
} = gameSettingSlice.actions;

export default gameSettingSlice.reducer