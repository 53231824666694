import React from 'react';
import CancelContainer from "../../../containers/game/manage/CancelContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const Cancel = () => {
    return (
        <div className="common">
            <font color={"blue"} size="3">파싱취소</font>
            <CancelContainer />
        </div>
    );
};

export default Cancel;