import React from 'react';
import FixtureContainer from "../../../containers/game/manage/FixtureContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const Fixture = () => {
    return (
        <div className="common">
            <font color={"blue"} size="3">경기일정</font>
            <FixtureContainer />
        </div>
    );
};

export default Fixture;