import {call, put, take,} from "redux-saga/effects";
import {
    failedGetActiveFixture, failedGetActiveFixtureDetail,
    getActiveFixtureDetail,
    setActiveFixture, setCurrentFixture
} from "../../../reducers/game/manage/activeMatch";
import {fetchActiveFixture, fetchFixtureDetail} from "../../../../lib/api";

export function* getActiveFixtureSaga(action) {
    try {
        const res = yield call(fetchActiveFixture,
            action.payload
        );
        yield put(setActiveFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getActiveFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetActiveFixture(error));
    }
}

export function* getActiveFixtureDetailSaga(action) {
    try {
        const res = yield call(fetchFixtureDetail, action.payload, 1)
        yield put(setCurrentFixture(res.data))
    } catch(error) {
        console.log(error)
        yield put(failedGetActiveFixtureDetail(error))
    }
}

