import React from 'react';
import AcctSettingContainer from "../../containers/setting/AcctSettingContainer";


const AcctSetting = () => {
    return (
        <div>
            <AcctSettingContainer />
        </div>
    );
};

export default AcctSetting;