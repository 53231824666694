import {call, put, take,} from "redux-saga/effects";
import {fetchCustomerDetail} from "../../../lib/api";
import {failedGetCustomerDetail, setCustomerDetail} from "../../reducers/board/CustomerDetailReducer";

export function* getCustomerDetailSaga(action) {
    try{
        const res = yield call(
            fetchCustomerDetail,
            action.payload.csNo
        )

        yield put(setCustomerDetail(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetCustomerDetail(error))
    }
}