import React, {useState} from "react";
import axios from "axios";


const GameCombination = () => {

    const [combinations, setCombinations] = useState([])
    const [combination, setCombination] = useState("")
    const [explain, setExplain] = useState("")
    const [game, setGame] = useState()
    const [mark, setMark] = useState(1)
    const [isSame, setIsSame] = useState(0)
    const [marks, setMarks] = useState([])
    const [games, setGames] = useState([])
    const [nowIndex, setNowIndex] = useState(0)

    const addCombination = (item) => {
        if(!combination.includes(`${item.market.id}(${item.name})`)) {
            if(combination === "") {
                setCombination(`${item.market.id}(${item.name})`)
            }
            else {
                setCombination(combination + `+${item.market.id}(${item.name})`)
            }
        }
        else {
            alert('이미포함되었습니다.')
        }

    }

    const insertCombination = () => {
        if(combination === "") {
            alert("조합배팅이 비었습니다.")
            return
        }
        if(explain === "") {
            alert("조합배팅 설명이 비었습니다.")
            return
        }
        if(combination !== "") {
            axios.post('/admin/game/setting/insertCombination', {
                game_no: game,
                mark_no: mark,
                combination: combination,
                explain: explain,
                is_same: isSame === 1
            }).then((res) => {
                if(res.data.isInserted) {
                    alert('추가완료')
                    getCombinations()
                }
                else {
                    alert('추가실패')
                }
            })
        }
    }

    const getCombinations = () => {
        axios.post('/admin/game/setting/getCombinations', {

        }).then((res) => {
            setCombinations(res.data.combinations)
        })
    }

    const getMarksAndGames = () => {
        axios.post('/admin/game/setting/getMarksAndGames', {

        }).then((res) => {
            setMarks(res.data.marks)
            setGames(res.data.games)
            if(res.data.marks.length > 0) {
                setMark(res.data.marks[0].no)
            }
            if(res.data.games.length > 0) {
                setGame(res.data.games[0].no)
            }

        })
    }

    return <div>
        <div>
            <label>마켓조합</label>
            <input className="form-control form-control-sm me-1" type="text" placeholder="e.g. 1+2" value={combination} onChange={(e) => {
                setCombination(e.currentTarget.value)
            }} readOnly={true}/>
        </div>
        <div>
            <label>조합설명</label>
            <input className="form-control form-control-sm me-1" type="text" value={explain} onChange={(e) => {
                setExplain(e.currentTarget.value)
            }} />
        </div>
        <div className="d-flex">
            <select className="form-select" style={{width: "120px"}} value={mark} onChange={(e) => {
                setMark(parseInt(e.currentTarget.value))
            }}>
                {marks?.map((item, index) => (
                    <option value={item.no}>{item.name}</option>
                ))}
            </select>
            <select className="form-select" style={{width: "120px"}} value={game} onChange={(e) => {
                setGame(parseInt(e.currentTarget.value))
            }}>
                {games?.map((item, index) => (
                    <option value={item.no}>{item.name_kor}</option>
                ))}
            </select>
            <select className="form-select" style={{width: "120px"}} value={isSame} onChange={(e) => {
                setIsSame(parseInt(e.currentTarget.value))
            }}>
                <option value={1}>동일경기허용</option>
                <option value={0}>동일경기비허용</option>
            </select>
        </div>
        <div className="d-flex">
            <button className="btn btn-sm btn-primary" onClick={(e) => {
                setCombination("")
                setExplain("")
                setIsSame(0)
                setGame(null)
                setMark(null)
            }}>RESET</button>
            <button className="btn btn-sm btn-danger" onClick={(e) => {
                insertCombination()
            }}>추가</button>
        </div>
        <table className="table table-bordered table-sm text-center align-middle">
            <thead className="table-dark">
            <tr>
                <th>번호</th>
                <th>타입</th>
                <th>종목</th>
                <th>배팅조합</th>
                <th>-</th>
            </tr>
            </thead>
            <tbody>
            {combinations.map((item, index) => (
                <tr>
                    <td>{item.no}</td>
                    <td>{item.game_mark_setting.name}</td>
                    <td>{item.game_kind.name_kor}</td>
                    <td>{item.combination}<br/>{item.explain}</td>
                    <td>
                        <div className="d-flex">
                            <button className="btn btn-sm btn-primary">변경</button>
                            <button className="btn btn-sm btn-danger">삭제</button>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>
}

export default GameCombination