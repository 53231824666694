import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {getDangerAccountSettingListReducer} from "../../redux/reducers/setting/DangerAccountSettingListReducer";
import axios from "axios";
import {insertAdminLog} from "../../lib/api";

const AccountSetting = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.DangerAccountSettingListReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const getList = () => {
        if(initialState.gridList !== null ) {
            dispatch(getDangerAccountSettingListReducer({input:{}}))
        }
        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "위험계좌설정"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const onChangeUseYn = (no,_useYn) => {
        let inputUseYn = '';

        if(_useYn === "Y"){
            inputUseYn = 'N'
        }else{
            inputUseYn = 'Y'
        }

        axios.post('/admin/setting/updateDangerAccountSetting', {
            no : no,
            useYn : inputUseYn,
        }).then((res) => {
            alert(res.data.message);
            getList();
        })
    }

    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">위험계좌설정</font>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"black"} size="5">현재 적용중인 위험계좌 규칙</font>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"red"} size="2">* 추가/수정할 규칙이 있으면 아래 형식대로 개발팀에 요청 바랍니다.</font>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                            <tr>
                                <th style={{width: "5%"}}>은행</th>
                                <th style={{width: "25%"}} colSpan="2">위험기준</th>
                                <th style={{width: "*%"}}>사유</th>
                                <th style={{width: "7%"}}>사용여부</th>
                                <th style={{width: "7%"}}>변경</th>
                            </tr>
                        </thead>
                        {initialState.gridList && initialState.gridList.length > 0 ? initialState.gridList.map((List, index) => (
                            <tbody key={List.no}>
                                <tr>
                                    <td align={"center"} style={{backgroundColor:List.useYn==="Y"?"lightyellow":"lightgrey" , color:List.useYn==="Y"?"red":"black" , fontWeight:List.useYn==="Y"?"bold":""}} >{List.bankCdNm}</td>
                                    <td align={"center"} style={{backgroundColor:List.useYn==="Y"?"lightyellow":"lightgrey" , color:List.useYn==="Y"?"red":"black" , fontWeight:List.useYn==="Y"?"bold":""}} >{List.dangerCdNm}</td>
                                    <td align={"center"} style={{backgroundColor:List.useYn==="Y"?"lightyellow":"lightgrey" , color:List.useYn==="Y"?"red":"black" , fontWeight:List.useYn==="Y"?"bold":""}} >{List.dangerDtl}</td>
                                    <td align={"center"} style={{backgroundColor:List.useYn==="Y"?"lightyellow":"lightgrey" , color:List.useYn==="Y"?"red":"black" , fontWeight:List.useYn==="Y"?"bold":""}} >{List.etc}</td>
                                    <td align={"center"} style={{backgroundColor:List.useYn==="Y"?"lightyellow":"lightgrey" , color:List.useYn==="Y"?"red":"black" , fontWeight:List.useYn==="Y"?"bold":""}} >{List.useYn}</td>
                                    <td align={"center"} style={{backgroundColor:List.useYn==="Y"?"lightyellow":"lightgrey" , color:List.useYn==="Y"?"red":"black" , fontWeight:List.useYn==="Y"?"bold":""}} >
                                        <button onClick={()=>onChangeUseYn(List.no,List.useYn)} className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "23px", width: "fit-content"}}>미사용</button>
                                    </td>
                                </tr>
                            </tbody>
                            )) :
                            <tbody>
                                <tr>
                                    <td colSpan= "6">조회된 데이터가 없습니다.</td>
                                </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}
export default AccountSetting;