import React from "react";
import CustomerContainer from "../../containers/board/CustomerContainer";
import {useParams} from "react-router-dom";
const Customer = () => {
    const param = useParams()
    return (
        <div className="sm-font">
            <div>
                <p>
                    <span style={{ color: "#1F68EE", fontWeight: "bold" }}>고객센터</span>
                </p>
                <CustomerContainer userId={param.userId}/>
            </div>


        </div>

    )
}
export default Customer;