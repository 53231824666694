import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {MultiSelect} from "react-multi-select-component";
import {
    resetState,
    setCmmnCd, setCmmnCd0036, setDtl, setGb,
    setOrgLevelCd,
    setRegId,
    setUpdId, setUserColorList
} from "../../../../redux/reducers/partner/CompanySubAddReducer";
import {insertAdminLog} from "../../../../lib/api";
import {useParams} from "react-router-dom";
import {setUpOrgId} from "../../../../redux/reducers/partner/DistributorAddReducer";

const DomainAdd = (props) => {

    const {open, onClose, initialState} = props
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [detail, setDetail] = useState({});
    const [domainCombo, setDomainCombo] = useState([])
    const [domainNo, setDomainNo] = useState(null)
    const param = useParams();

    const onLoad = () => {
        //조직도메인조회
        axios.post('/admin/partner/getOrgDomain', {
            id : param.userId
        }).then((res) => {
            if(res.data.detail !== null) {
                setDetail(res.data.detail)
            }
            if(res.data.domainCombo !== null) {
                setDomainCombo(res.data.domainCombo)
            }
        })

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "도메인등록팝업"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(onLoad, [param.userId])

    const domainChangeHandler = (e) =>{
        setDetail((prevDetail) => ({
            ...prevDetail,
            domain: e.target.value, // 'a' 항목의 새로운 값
        }));;
    }

    const handleSave =() => {
        axios.post('/admin/partner/updateOrgDomain', {
                id : param.userId,
                domain_no : detail.domain
            }
        ).then((res) => {
            if (res.data.isUpdated) {
                alert('저장했습니다.')
                popClose()
            } else {
                alert('저장실패했습니다.')
            }
        })
    }

    const popClose =() => {
        if (window.opener && typeof window.opener.popupClose === 'function') {
            window.opener.popupClose();
        }
        window.close();
    }

    return (
        <div className="common" style={{width : "99%"}}>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <button style={{ backgroundColor: "white",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                >도메인등록</button>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="" style={{
                    display: "flex"
                }}>
                    <table>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"center"} width={"100px"}>총판</td>
                            <td align={"center"} width={"200px"}>
                                <span>{detail&&detail.id}({detail&&detail.org_nm})</span>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td align={"center"} width={"100px"}>도메인</td>
                            <td align={"center"} width={"200px"}>
                                <select value={detail.domain} className="form-select sm-select" onChange={domainChangeHandler}>
                                    <option value="">::도메인 선택::</option>
                                    {domainCombo?.map((option) => (
                                        <option key={option.no} value={option.no}>
                                            {option.forward_domain}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td height="20px"></td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"right"}>
                                <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}
                                        onClick={handleSave}
                                >
                                    도메인수정
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align={"right"}>
                                <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}
                                        onClick={popClose}
                                >
                                    닫기
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>


    )
}
export default DomainAdd;