import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../redux/reducers/user/BlackMemberAddModalReducer";

const LoginHistoryContainer = ({userId}) => {


    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <b>접속 IP 기록 (180일 이내 / 최대 1,000건)</b>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                * 아이피 사용 기록을 순서대로 보여줍니다.
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                * 접속 페이지 구분은 2018-04-06 부터 기록되었습니다.
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                * 접속 장치 구분은 2018-06-02 부터 기록되었습니다.
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
                <table className="commonT table table-bordered table-sm text-center" style={{width: "100%"}}>
                    <thead >
                    <tr>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>시각</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>IP</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>국가</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>통신사</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>로그인구분</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>접속장치</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>접속페이지</th>
                        <th style={{width: "30%",backgroundColor: "lightgray"}}>User Agent</th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>



    )
}
export default LoginHistoryContainer;