import {call, put, take,} from "redux-saga/effects";
import {fetchMemberInfo} from "../../../lib/api";
import {failedGetMemberInfo, setMemberInfo} from "../../reducers/partner/MemberInfoReducer";

export function* getMemberInfoSaga(action) {
    try{
        const res = yield call(
            fetchMemberInfo, action.payload.input,
        )
        yield put(setMemberInfo(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMemberInfo(error))
    }
}