import {setLeagues} from "../../../redux/reducers/game/gameSetting";
import Switch from "react-switch";
import axios from "axios";


const MarketList = ({markets, onRefresh, setMarkets}) => {

    const changeMarket = (item) => {
        axios.post('/admin/game/setting/changeMarket', {
            marketItem: item,
        }).then((res) => {
            if(res.data.isChanged) {
                alert("변경완료")
            }
            else {
                alert("변경실패")
            }
        })
    }

    const changeAllMarket = () => {
        axios.post('/admin/game/setting/changeAllMarket', {
            list: markets,
        }).then((res) => {
            if(res.data.isChanged) {
                alert("변경완료")
                onRefresh()
            }
            else {
                alert("변경실패")
            }
        })
    }

    return <div>
        <table className="table table-bordered table-sm text-center align-middle">
            <thead className="table-dark">
            <tr>
                <th>마켓코드</th>
                <th>파싱명</th>
                <th>마켓명</th>
                <th>사용여부</th>
                <th>
                    <button className="btn btn-sm btn-primary" onClick={(e) => {
                        changeAllMarket()
                    }}>일괄변경</button>
                </th>
            </tr>
            </thead>
            <tbody>
            {markets && markets.map((item, index) => (
                <tr>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>
                        <input className="form-control-sm" type="text" style={{ width: '100%' }} value={(item.name_kor && item.name_kor !== "")? item.name_kor : ""}
                               onChange={(e) => {
                                   const newItem = {...item}
                                   newItem.name_kor = e.currentTarget.value
                                   const arr = [...markets]
                                   arr[index] = newItem
                                   setMarkets(arr)
                               }}

                        />
                    </td>
                    <td>
                        <Switch onChange={(checked) => {
                            const newItem = {...item}
                            newItem.is_use = checked
                            const arr = [...markets]
                            arr[index] = newItem
                            setMarkets(arr)
                        }} checked={item.is_use} onColor="#2196F3"/>
                    </td>
                    <td>
                        <button className="btn btn-sm btn-primary" onClick={(e) => {
                            changeMarket(item)
                        }}>변경</button>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>
}

export default MarketList