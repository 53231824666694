import React from 'react';
import moment from "moment";
import common from "../../util/common";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
import {useDispatch} from "react-redux";

const AccountList = ({state, onRefresh}) => {
    const dispatch = useDispatch()
    return  <div className="common">
        <table className="table table-bordered table-sm text-center align-middle">
            <thead>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>이름</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>은행조회일시</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>은행조회이전<br/>최종입금</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>현재시점<br/>최종입금</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>조회IP</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>성공여부</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>30분내<br/>입금여부</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>당일<br/>입금횟수</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>상세</td>
            </tr>
            </thead>
            <tbody>
            {state?.accounts && state.accounts.map((item, index) => (
                <tr>
                    <td style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                        dispatch(openUserSimpleInfo({
                            isOpen: true,
                            openId: item.id,
                            xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                        }))
                    }}><span style={{ color: item.font_color }}>{item.id}</span></td>
                    <td style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                        dispatch(openUserSimpleInfo({
                            isOpen: true,
                            openId: item.id,
                            xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                        }))
                    }}><span style={{ color: item.font_color }}>{item.nickname}</span></td>
                    <td style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                        dispatch(openUserSimpleInfo({
                            isOpen: true,
                            openId: item.id,
                            xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                        }))
                    }}><span style={{ color: item.font_color }}>{item.name}</span></td>
                    <td >{moment(item.account_read_dt).format("YY/MM/DD HH:mm")}</td>
                    <td>{common.getRemainTime(item.complete_dt)}</td>
                    <td>{common.getRemainTime(item.lately_deposit)}</td>
                    <td><a href={"https://www.ipaddress.com/ipv4/" + item.use_ip} target="_blank"><span style={{ color: "#1F68EE" }}>{item.use_ip}</span></a></td>
                    <td><span style={{ color: item.success_yn === "Y"? "#1F68EE": "red" }}>{item.success_yn === "Y"? "성공" : "실패"}</span></td>
                    <td><span style={{ color: item.minutes_deposit_yn === "Y"? "#1F68EE": "red" }}>{item.minutes_deposit_yn === "Y"? "입금확인":"입금안됨"}</span></td>
                    <td><span style={{ color: item.day_deposit_cnt > 0? "#000000": "red" }}>{item.day_deposit_cnt > 0? item.day_deposit_cnt : "입금없음"}</span></td>
                    <td>{item.detail}</td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>

}


export default AccountList;