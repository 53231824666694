import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {getDomainSettingListReducer,setDomain,setEtc,setForwardDomain} from "../../redux/reducers/setting/DomainSettingListReducer";
import axios from "axios";
import {insertAdminLog} from "../../lib/api";

const DomainSetting = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.DomainSettingListReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const getList = () => {
        if(initialState.gridList !== null ) {
            dispatch(getDomainSettingListReducer({input:{}}))
        }
        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "도메인포워딩관리"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const changeDomain = (idx,val) =>{
        console.log("##",idx,"##",val)
        dispatch(setDomain({idx : idx , val : val }))
    }

    const changeForwardDomain = (idx,val) =>{
        dispatch(setForwardDomain({idx : idx , val : val }))
    }

    const changeEtc = (idx,val) =>{
        dispatch(setEtc({idx : idx , val : val }))
    }

    const addRow = () =>{
        axios.post('/admin/setting/insertDomainSetting').
        then((res) => {
            alert(res.data.message);
            getList();
        })
    }

    const destroyRow = (no) =>{
        axios.post('/admin/setting/deleteDomainSetting',{no:no}).
        then((res) => {
            alert(res.data.message);
            getList();
        })
    }

    const updateUseYn = (no,useYn)=> {
        let _useYn;
        if(useYn==='Y'){
            _useYn ='N'
        }else{
            _useYn ='Y'
        }
        axios.post('/admin/setting/updateDomainSetting',{
            no : no,
            useYn : _useYn
        }).
        then((res) => {
            alert(res.data.message);
            getList();
        })
    }

    const updateList = ()=> {

        axios.post('/admin/setting/updateDomainSettingList', initialState.gridList).
        then((res) => {
            alert(res.data.message);
            getList();
        })
    }
    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">도메인포워딩관리</font>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"}  >
                        <tbody>
                        <tr>
                            <td align={"right"}>
                                <button onClick={updateList} style={{
                                    width: "100px"
                                }} type="submit" className="btn btn-primary" >저장하기</button>
                                &nbsp;
                                <button onClick={addRow} style={{
                                    width: "100px"
                                }} type="submit" className="btn btn-primary" >추가하기</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th style={{width: "7%"}}>포워딩 사용여부</th>
                            <th style={{width: "15%"}}>기준 도메인</th>
                            <th style={{width: "15%"}}>포워딩할 도메인</th>
                            <th style={{width: "*%"}}>메모</th>
                            <th style={{width: "10%"}}>안내한 아이디/횟수</th>
                            <th style={{width: "10%"}}>최종 접속 일시</th>
                            <th style={{width: "10%"}}>최근 7일간</th>
                            <th style={{width: "5%"}}>삭제</th>
                        </tr>
                        </thead>
                        {initialState.gridList && initialState.gridList.length > 0 ? initialState.gridList.map((List, index) => (
                                <tbody key={List.no}>
                                <tr>
                                    <td onClick={()=>updateUseYn(List.no,List.useYn)} align={"center"} ><font style={{cursor:"pointer"}} color= {List.useYn ==='Y' ? "blue" : 'lightgrey'}>{List.useYn === 'Y' ? '[사용]' : '[미사용]'}</font></td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input value={List.domain} onChange={(e)=>changeDomain(index,e.target.value)} className="form-check-input sm-input" placeholder="un-1111.com" type="text" style={{ width: "300px" }}/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input value={List.forwardDomain} onChange={(e)=>changeForwardDomain(index,e.target.value)} className="form-check-input sm-input" placeholder="un-aaa.com" type="text" style={{ width: "300px" }}/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <textarea value = {List.etc} onChange={(e)=>changeEtc(index,e.target.value)} name="textarea_name" cols="60" rows="3"/>
                                    </td>
                                    <td align={"center"}>{List.useIdCnt}명 / {List.useCnt}회</td>
                                    <td align={"center"}>{List.lastDt}</td>
                                    <td align={"center"}>{List.latelyUseIdCnt}명 / {List.latelyUseCnt}회</td>
                                    <td align={"center"}>
                                        <button onClick={()=>destroyRow(List.no)} style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "24px", width: "fit-content", marginRight: "5px"}}>삭제</button>
                                    </td>
                                </tr>
                                </tbody>
                            )) :
                            <tbody>
                            <tr>
                                <td colSpan= "8">조회된 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>


    )
}
export default DomainSetting;