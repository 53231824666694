import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import LvlSetting from "../../components/setting/LvlSetting";
import {NumericFormat} from "react-number-format";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import {getLvlSetting, setQuery} from "../../redux/reducers/setting/LvlSettingListReducer";
import {insertAdminLog} from "../../lib/api";

const LvlSettingContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.lvlSetting)
    const [searchQuery, setSearchQuery] = useState(initialState.query);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM')+'-01');
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [userGbCd, setUserGbCd] = useState("");



    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getLvlSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "레벨별설정"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])


    const onSearch = (query) => {
        dispatch(getLvlSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
        dispatch(setQuery(query))
        setSearchQuery(query)
    }
    const onClickSearch = (e) => {
        dispatch(getLvlSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getLvlSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }



    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }


    const deleteState = (no) => {
        //alert("no :: "+no)
        if (window.confirm("삭제 하시겠습니까?"))
        {
            axios.post('/admin/setting/deleteTagSetting', {
                adminNo: userInfoId,
                no: no
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }



    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">레벨별설정</font>
                </div>

            </div>

        </div>
        <LvlSetting lvlSetting={initialState.lvlSetting} settingLiveYn={initialState.settingLiveYn} onSearch={onSearch} mainList1={initialState.countList} mainList2={initialState.countList2} mainList3={initialState.countList3} mainList4={initialState.countList4} mainList5={initialState.countList5} onRefresh={onRefresh} initialState={initialState} deleteState={deleteState} />
    </>





}
export default LvlSettingContainer