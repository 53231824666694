import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

export const miniGameSlice = createSlice({
    name: 'miniGame',
    initialState: {
        isLoading: false,
        error: null,
        miniGames: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        sport: 0,
        status: 999,
        provider: 0,
        market: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        providers: [],
        sports: [],
        markets: [],
        query: "",
    },
    reducers: {
        getMiniGames(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.status = action.payload.status;
            state.provider = action.payload.provider;
            state.sport = action.payload.sport;
            state.market = action.payload.market;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setMiniGames(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.miniGames = action.payload.result;
            state.totalCount = action.payload.totalCount;

        },
        failedGetMiniGames(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setMiniSorts(state, action) {
            state.providers = action.payload.providers
            state.sports = action.payload.sports
            state.markets = action.payload.markets
        }
    },
});

export const {
    getMiniGames,
    setMiniGames,
    failedGetMiniGames,
    setMiniSorts,
} = miniGameSlice.actions;

export default miniGameSlice.reducer;