import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {
    setSearchIdArr,
    setSearchIdSort,
    setSearchNicknameArr,
    setSearchNicknameSort
} from "../../../redux/reducers/user/MemberReducer";

const MemberSearchModal04 = (props) => {

    const dispatch = useDispatch()
    const userInfoId = useSelector((state) => state.userInfo.id)
    const userInfoIp = useSelector((state) => state.userInfo.ip)
    const {open, onClose, initialState, onRefresh} = props
    //회원등급
    const [userGbCd, setUserGbCd] = useState('')
    const [userGbList, setUserGbList] = useState([]);

    const [memo, setMemo] = useState('')

    const memoChangeHandler = (e) => {
        setMemo(e.currentTarget.value);
    }



    const handleCancel = () => {
        onClose()
    }

    const handleSearch = () => {
        onClose()
        onRefresh()
    }


    const idChangeHandler = (e) => {
        dispatch(setSearchNicknameArr(e.currentTarget.value));
    }

    const sortChangeHandler = (value) => {
        dispatch(setSearchNicknameSort(value));
    }


    const userGbCdChangeHandler = (e, initialState) => {
        setUserGbCd(e.currentTarget.value);
        //alert("initialState :: "+initialState);
    }

    const handleRegister = () => {

        if(window.confirm("현재 검색결과 회원들에게 메모를 추가하시겠습니까?\n" +
            "원복이 불가능 하므로 신중하게 입력하세요 (1/2)"))
        {
            if(window.confirm("현재 검색결과 회원들에게 메모를 추가하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (2/2)"))
            {

                //alert("userGbCd : "+userGbCd);
                //alert("memo : "+memo);
                axios.post('/admin/member/changeMemo', {
                    key: userGbCd,
                    memo: memo,
                    updateList: initialState.totalList,
                    adminNo: userInfoId,
                    reg_ip: userInfoIp
                }).then((res) => {
                    alert(res.data.isUpdated? "설정완료" : "설정실패")
                    onClose()
                    onRefresh();
                })
            }
            else
            {
                return;
            }
        }
        else
        {
            return;
        }

    }


    return (
        <div className="member_memo_select_modal_background">
            <div className="member_memo_select_modal_box" >
                <div className="member_memo_select_modal_body">

                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table   width={"99%"}>
                            <tr>
                                <td style={{backgroundColor:"orange"}} align={"left"} height={"30px"}>
                                    <b><font color={"white"} size={"2"}>&nbsp;&nbsp; 메모삽입</font></b>
                                </td>
                                <td style={{backgroundColor:"orange"}} align={"right"}>
                                    <button style={{ backgroundColor: "lightgray", border: "none", color: "black" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleCancel}><b>X</b></button>
                                    &nbsp;&nbsp;
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%", backgroundColor:"lightgray"}}>
                            <tr>
                                <td style={{backgroundColor:"white"}} width={"100%"} align={"left"}>
                                    &nbsp;&nbsp;
                                    <select
                                        className=""
                                        style={{
                                            width: "100px"
                                        }}
                                        onChange={(e) => userGbCdChangeHandler(e, initialState)}
                                        value={userGbCd}
                                    >
                                        <option key={"1"} value={"1"}>1번 메모</option>
                                        <option key={"2"} value={"2"}>2번 메모</option>
                                        <option key={"3"} value={"3"}>3번 메모</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"white"}} width={"100%"} align={"center"}>
                                    <textarea name="memo1" id="memo1" cols="135" rows="23" value={memo} onChange={memoChangeHandler}></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"skyblue", cursor: "pointer"} } width={"100%"} align={"center"} height={"30px"} onClick={handleRegister}>
                                    <font color={"white"}><b>메모 삽입</b></font>
                                </td>
                            </tr>
                        </table>
                    </div>


                </div>
            </div>
        </div>

    )
}
export default MemberSearchModal04;