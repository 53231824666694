import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import axios from "axios";
import {
    resetState,
    setDtl,
    setMoveOrgId,
    setCmmnCd,
    setCmmnCd0036,
    setIdCheckYn,
    setOrgNmDupYn,
    setOrgNmCheckYn,
    setUserColorList,
    setIdDupYn,
    setGb,
    setId,
    setOrgLevelCd,
    setNm,
    setPw1,
    setPw2,
    setOrgNm,
    setOrgGbCd,
    setMbnoStart,
    setMbnoCenter,
    setMbnoEnd,
    setMbphStart,
    setMbphCenter,
    setMbphEnd,
    setEmail,
    setMemo,
    setOrgAdjGbCd,
    setUserColorNo,
    setRegId,
    setUpdId,
} from "../../redux/reducers/partner/CompanySubAddReducer";
import {useNavigate,useLocation } from "react-router-dom";
import {setDamageAmt} from "../../redux/reducers/user/BlackMemberAddModalReducer";
import {insertAdminLog} from "../../lib/api";

const CompanySub_add = (param) => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.CompanySubAddReducer)
    const navigate = useNavigate();
    const userInfoId = useSelector((state) => state.userInfo.id)

    const location = useLocation();
    const { state } = location;
    // state 객체에서 name 값을 추출
    const gb = state && state.gb;
    const id = state && state.id;

    const onLoad = () => {
        dispatch(resetState());
        dispatch(setOrgLevelCd('01'))//01:부본사
        dispatch(setRegId(userInfoId))
        dispatch(setUpdId(userInfoId))

        //1.공통코드조회
        axios.post('/admin/cmmnCdList', {
            cmmnCdId : '0012',
            inCd: ["00", "01"], //00:관리자 , 01:부본사
        }).then((res) => {
            if(res.data.cmmnCdList !== null) {
                dispatch(setCmmnCd(res.data.cmmnCdList))
            }
            else {
                alert('공통코드조회실패')
            }
        })

        //2.유저색상조회
        axios.post('/admin/userColorList').
        then((res) => {
            if(res.data.userColorList !== null) {
                dispatch(setUserColorList(res.data.userColorList))
            }
            else {
                alert('공통코드조회실패')
            }
        })

        //3.단건조회
        if(gb === "수정"){
            axios.post('/admin/partner/getOrgDtl',{id : id}).
            then((res) => {
                if(res.data.Dtl !== null) {
                    dispatch(setDtl(res.data.Dtl))
                    dispatch(setGb(gb))
                }
                else {
                    alert('성세조회실패')
                }
            })
        }

        //공통코드조회 (조직정산구분코드)
        axios.post('/admin/cmmnCdList', {
            cmmnCdId : '0036',
        }).then((res) => {
            if(res.data.cmmnCdList !== null) {
                dispatch(setCmmnCd0036(res.data.cmmnCdList))
            }
            else {
                alert('공통코드조회실패')
            }
        })

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "부본사등록"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(onLoad, [gb])

    const onSubmitHandler = (e) => {
        navigate('/partner/Company_list');
    }

    const dupIdCheckHandle = (e) => {
        axios.post('/admin/partner/getOrgDupCheck', {
            checkGb : 'id',
            id : initialState.input.id
        }).then((res) => {
            if (res.data.dupYn) {
                alert('중복된 아이디가 있습니다. 다른 아이디를 입력해주세요')
                dispatch(setIdDupYn(true));
                dispatch(setIdCheckYn(false));

            } else {
                alert('중복된 아이디가 없습니다. 사용하셔도 좋습니다.')
                dispatch(setIdDupYn(false));
                dispatch(setIdCheckYn(true));
            }
        })
    }

    const dupCheckOrgNmHandle = (e) => {
        axios.post('/admin/partner/getOrgDupCheck', {
            checkGb : 'orgNm',
            id : initialState.input.id,
            org_nm : initialState.input.org_nm
        }).then((res) => {
            if (res.data.dupYn) {
                alert('중복된 부본사명이 있습니다. 다른 이름을 입력해주세요')
                dispatch(setOrgNmDupYn(true));
                dispatch(setOrgNmCheckYn(false));

            } else {
                alert('중복된 부본사명 없습니다. 사용하셔도 좋습니다.')
                dispatch(setOrgNmDupYn(false));
                dispatch(setOrgNmCheckYn(true));
            }
        })
    }

    const onIdHandler = (e) => {
        dispatch(setId(e.target.value))
        dispatch(setIdCheckYn(false))
    }

    const onNmHandler = (e) => {
        dispatch(setNm(e.target.value))
    }

    const onPw1Handler = (e) => {
        //한글입력안되게
        const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/;
        if(regex.test(e.target.value)){
            dispatch(setPw1(e.target.value))
        }
    }

    const onPw2Handler = (e) => {
        const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/;
        if(regex.test(e.target.value)) {
            dispatch(setPw2(e.target.value))
        }
    }

    const onOrgNmHandler = (e) => {
        dispatch(setOrgNm(e.target.value))
        dispatch(setOrgNmCheckYn(false))
    }

    const onOrgGbCdHandler = (e) => {
        dispatch(setOrgGbCd(e.target.value))
    }

    //전화번호앞자리
    const onMbnostartHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbnoStart(value))
        }
    }

    //전화번호가운데자리
    const onMbnoCenterHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbnoCenter(value))
        }
    }

    //전화번호끝자리
    const onMbnoEndHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbnoEnd(value))
        }
    }

    //이동전화번호앞자리
    const onMbphStartHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbphStart(value))
        }
    }

    //이동전화번호중간자리
    const onMbphCenterHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbphCenter(value))
        }
    }

    //이동전화번호끝자리
    const onMbphEndHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbphEnd(value))
        }
    }

    //이메일
    const onEmailHandler = (e) => {
        dispatch(setEmail(e.target.value))
    }

    //메모
    const onMemoHandler = (e) => {
        dispatch(setMemo(e.target.value))
    }

    //회원색상번호
    const onUserColorNoHandler = (e) => {
        dispatch(setUserColorNo(e.target.value))
    }
    const onChageOrgIdHandler = (e) => {
        dispatch(setMoveOrgId(e.target.value))
    }

    const orgAdjGbCdChangeHandler = (e) => {
        dispatch(setOrgAdjGbCd(e.target.value))
    }

    const handleSave =() =>{
        //아이디필수입력

        if(gb!=="수정" && initialState.input.id === ''){
            alert("아이디를 입력하세요");
            return false;
        }
        //아이디중복체크여부
        if(gb!=="수정" &&!initialState.idCheckYn){
            alert("아이디 중복 확인을 해주세요");
            return false;
        }

        //아이디중복며부
        if(gb !== "수정" && initialState.idDupYn){
            alert('중복된 아이디가 있습니다. 다른 아이디를 입력해주세요')
            return false;
        }

        //관리자이름필수입력
        if(initialState.input.nm === ''){
            alert("관리자 이름을 입력하세요");
            return false;
        }

        //비밀번호필수입력
        if(initialState.input.pw === ''){
            alert("비밀번호를 입력하세요");
            return false;
        }

        //비밀번호체크
        if(initialState.input.pw !== initialState.pwcheck){
            alert('비밀번호가 다릅니다. 같은 비밀번호를 입력하세요')
            return false;
        }

        //조직명필수입력
        if(initialState.input.org_nm === ''){
            alert("부본사명을 입력하세요")
            return false;
        }

        //조직명중복체크여부
        if(!initialState.orgNmCheckYn){
            alert("부본사명 중복 확인을 해주세요");
            return false;
        }

        //조직명중복여부
        if(initialState.orgNmDupYn){
            alert('중복된 부본사명 이 있습니다. 다른 부본사 명을 입력해주세요')
            return false;
        }

        axios.post('/admin/partner/saveOrg', initialState.input
        ).then((res) => {
            if (res.data.isInserted) {
                alert('저장했습니다.')
                onSubmitHandler()
            } else {
                alert('저장실패했습니다.')
            }
        })
    }

    //삭제버튼 클릭
    const orgDelete=()=>{
        if(window.confirm("정말 삭제하시겠습니까?")) {
            axios.post('/admin/partner/orgDelete', {id: initialState.input.id}
            ).then((res) => {
                if (res.data.IsDeleted) {
                    alert('삭제했습니다.')
                    onSubmitHandler()
                } else {
                    alert('삭제실패했습니다.')
                }
            })
        }
    }
    const moveOrgMember=()=>{
        if(initialState.moveOrgId === ""){
            alert("회원을 넘겨 받을 본사 혹은 총판 혹은 가맹점 아이디를 입력하세요");
            return false;
        }
        if(window.confirm("정말 현재 총판 소속의 회원들을 대상 총판 혹은 가맹점으로 이동하시겠습니까?")){
            axios.post('/admin/partner/moveOrgMember', {
                    id : initialState.input.id,
                    moveOrgId : initialState.moveOrgId
                }
            ).then((res) => {
                if(res.data.IsExistYn){
                    if (res.data.IsChanged) {
                        alert('이동했습니다.')
                    } else {
                        alert('이동실패했습니다.')
                    }
                }else{
                    alert('그런 본사 혹은 총판 혹은 가맹 아이디가 존재하지 않습니다.')
                    return false;
                }
            })
        }
    }


    return (
        <div className="common" >
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">루트총판 {gb==='수정'?'수정':'등록'}</font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"70%"}>
                        <tbody>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "150px"
                                }} type="submit" style={{backgroundColor :"darkgreen"}} className="btn btn-primary" onClick={()=>handleSave()}>부본사{gb==='수정'?'수정':'등록'}</button>
                                &nbsp;
                                {gb === '수정' ?
                                    <button onClick={orgDelete} style={{
                                        width: "80px",backgroundColor :"red"
                                    }} type="submit" className="btn btn-primary" >부본사삭제</button>
                                    : <></>
                                }
                                &nbsp;
                                <button onClick={onSubmitHandler} style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" >목록</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="table table-bordered table-sm text-center align-middle" style={{width : "70%"}}>
                    <tbody>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>아이디 <font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input {...(gb === "수정" ? {disabled: true} : {})} className="form-check-input sm-input" type="text" onChange={onIdHandler} value={initialState.input.id}/>&nbsp;
                                {
                                    gb === "수정" ? <></> :
                                        <button  style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dupIdCheckHandle}>아이디 중복 확인</button>
                                }
                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>관리자 이름 <font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"} align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input className="form-check-input sm-input" type="text" onChange={onNmHandler} value={initialState.input.nm}/>&nbsp;
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"} >비밀번호 <font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input maxLength={25} className="form-check-input sm-input" type="text" onChange={onPw1Handler} value={initialState.input.pw}/>&nbsp;
                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>비밀번호 확인 <font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"} align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input maxLength={25} className="form-check-input sm-input" type="text" onChange={onPw2Handler} value={initialState.pwcheck}/>&nbsp;
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>부본사명 <font color="red">*</font></td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input className="form-check-input sm-input" type="text" onChange={onOrgNmHandler} value={initialState.input.org_nm}/>&nbsp;
                                <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dupCheckOrgNmHandle}>부본사명 중복 확인</button>
                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>구분</td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"} align={"left"}>
                            {initialState.cmmnCdList.map(item => (
                                <React.Fragment key={item.cmmn_cd_dtl_id}>
                                    <input
                                        type="radio"
                                        name="flexcmmn"
                                        id={`flexcmmn${item.cmmn_cd_dtl_id}`}
                                        value={item.cmmn_cd_dtl_id}
                                        style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                        onChange={onOrgGbCdHandler}
                                        checked={item.cmmn_cd_dtl_id === initialState.input.org_gb_cd}
                                    />
                                    <label
                                        htmlFor={`flexcmmn${item.cmmn_cd_dtl_id}`}
                                        style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                    >
                                        {item.cmmn_cd_dtl_nm}
                                    </label>
                                </React.Fragment>
                            ))}
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>연락처</td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input value={initialState.input.mbno_start} maxLength={4} className="form-check-input sm-input" type="text" onChange={onMbnostartHandler}/> -
                                <input value={initialState.input.mbno_center} maxLength={4} className="form-check-input sm-input" type="text" onChange={onMbnoCenterHandler}/> -
                                <input value={initialState.input.mbno_end} maxLength={4} className="form-check-input sm-input" type="text" onChange={onMbnoEndHandler}/>
                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>핸드폰</td>
                        <td width={"1%"}></td>
                        <td colSpan="3" width={"34%"} align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input value={initialState.input.mbph_start} maxLength={4} className="form-check-input sm-input" type="text" onChange={onMbphStartHandler}/> -
                                <input value={initialState.input.mbph_center} maxLength={4} className="form-check-input sm-input" type="text" onChange={onMbphCenterHandler}/> -
                                <input value={initialState.input.mbph_end} maxLength={4} className="form-check-input sm-input" type="text" onChange={onMbphEndHandler}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>이메일</td>
                        <td width={"1%"}></td>
                        <td width={"*%"} colSpan="3" align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input value={initialState.input.email} className="form-check-input sm-input" type="text" style={{ width: "400px" }} onChange={onEmailHandler}/>
                            </div>
                        </td>
                        <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>조직정산구분</td>
                        <td width={"1%"}></td>
                        <td width={"*%"} colSpan="3" align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <select
                                    className="form-select sm-select"
                                    defaultValue={'01'}
                                    style={{
                                        width: "100px"
                                    }}
                                     onChange={orgAdjGbCdChangeHandler} value={initialState.input.org_adj_gb_cd}
                                >
                                    {initialState.cmmnCdList0036?.map((item, index) => (
                                        <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                    ))}
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>메모</td>
                        <td width={"1%"}></td>
                        <td width={"*%"} colSpan="8">
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <input value={initialState.input.memo} className="form-check-input sm-input" type="text" style={{ width: "1000px" }} onChange={onMemoHandler}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>소속회원 색상값</td>
                        <td width={"1%"}></td>
                        <td width={"*%"} colSpan="8">
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <table>
                                    <tbody>
                                    <tr>
                                        {initialState.userColorList.map(item => (
                                            <React.Fragment key={item.no}>
                                                <td style={{backgroundColor:item.back_ground_color}} align={"center"} height={"20px"} >
                                                    <label
                                                        htmlFor={`flexColor${item.no}`}
                                                        style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                                    ><font color={item.font_color}>{item.no}</font>
                                                    </label>
                                                    <input
                                                        type="radio"
                                                        name="flexColor"
                                                        id={`flexColor${item.no}`}
                                                        value={item.no}
                                                        style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                                        onChange={onUserColorNoHandler}
                                                        checked={parseInt(item.no) === parseInt(initialState.input.user_color_no)}
                                                    />
                                                </td>
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td colSpan="16">
                                            <font color={"red"}>
                                                * 부본사 색상 / 총판 색상 / 가맹점 색상 / 개인별 관심회원 색이 우선하며, 개인별 설정이 없을 경우 소속(부본사/총판/가맹)의 색을 사용합니다.
                                            </font>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                    {gb !== '수정' ? <></> :
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>소속회원 이동</td>
                            <td width={"1%"}></td>
                            <td width={"*%"} colSpan="8" align={"left"}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td align={"left"}>
                                            <div className="container-fluid p-0 mx-1" style={{
                                                display: "flex"
                                            }}>
                                                <input onChange={onChageOrgIdHandler} className="form-check-input sm-input" type="text"  style={{
                                                    width: "400px"
                                                }}/>
                                                &nbsp;
                                                <button onClick={moveOrgMember} style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>회원 이동</button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <font color={"red"}>* 이 부본사에 속한 회원들을, 입력하신 총판 혹은 가맹점 아이디로 이동하게 됩니다. 신중하게 사용하시기 바랍니다.</font>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default CompanySub_add;