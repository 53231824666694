import {call, put, take,} from "redux-saga/effects";
import {fetchSameBrowsers} from "../../../lib/api";
import {failedGetSameBrowsers, setSameBrowsers} from "../../reducers/monitoring/SameBrowserReducer";

export function* getSameBrowserSaga(action) {
    try{
        const res = yield call(
            fetchSameBrowsers,
            action.payload.query,
            action.payload.page, action.payload.pageSize
        )

        yield put(setSameBrowsers(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetSameBrowsers(error))
    }
}