import axios from "axios";
import {Link, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import moment from "moment";
import React, {useEffect, useState} from "react";
import HomePage from "./pages/home/HomePage";
import {useDispatch, useSelector} from "react-redux";

import {persistor} from "./index";
import {List, Search, House, MenuButton, ArrowClockwise } from "react-bootstrap-icons";

import { ImSwitch } from "react-icons/im";

//게임관리MenuButton
import Manual from "./pages/game/Manual"
import InsertManualPopup from "./pages/game/InsertManualPopup"
import GameSetting from "./pages/game/setting/GameSetting"
import MarketSetting from "./pages/game/setting/MarketSetting";
import SportSetting from "./pages/game/setting/SportSetting";
import GameMarketSetting from "./pages/game/setting/GameMarketSetting";
import GameCombination from "./pages/game/setting/GameCombination";
import MarketByGame from "./pages/game/setting/MarketByGame";
import MiniSetting from "./pages/game/setting/MiniSetting";

import Fixture from "./pages/game/manage/Fixture";
import Wait from "./pages/game/manage/Wait";
import ActiveGame from "./pages/game/manage/ActiveGame";
import Close from "./pages/game/manage/Close";
import Result from "./pages/game/manage/Result";
import Stop from "./pages/game/manage/Stop";
import StopBy from "./pages/game/manage/StopBy";
import Cancel from "./pages/game/manage/Cancel";
import CancelBy from "./pages/game/manage/CancelBy";
import Inplay from "./pages/game/manage/Inplay";
import MiniGame from "./pages/game/manage/MiniGame";
import InplayResult from "./pages/game/manage/InplayResult";
import LeftComponent from "./components/common/LeftComponent";

import Sidebar from '../src/components/common/Sidebar';
import Contact from '../src/components/common/Contact';

//베팅관리
import BettingList from "./pages/betting/BettingList"
import KGON from "./pages/betting/KGON"

//충환전관리
import DepositList from "./pages/deposit/Deposit_list";
import WithdrawList from "./pages/deposit/Withdraw_list";
import DepositWithdrawLog from "./pages/deposit/Deposit_withdraw_log";
import DepositWithdrawCombine from "./pages/deposit/Deposit_withdraw_combine";
import MoneyList from "./pages/deposit/Money_list";
import MoneyStat from "./pages/deposit/Money_stat";
import PointList from "./pages/deposit/Point_list";
import PointStat from "./pages/deposit/Point_stat";
import CouponList from "./pages/deposit/Coupon_list";

//게시판관리
import Customer from "./pages/board/Customer"
import CustomerDetail from "./pages/board/CustomerDetail"
import Notice from "./pages/board/Notice"
import InsertNoticePopup from "./pages/board/InsertNoticePopup"
import Event from "./pages/board/Event"
import InsertEventPopup from "./pages/board/InsertEventPopup"
import Check from "./pages/board/Check"
import ETC from "./pages/board/ETC"
import InsertETCPopup from "./pages/board/InsertETCPopup"

//회원관리
import MemberTotalInfo from "./pages/user/MemberTotalInfo";
import MemberInfo from "./pages/user/MemberInfo";
import BlackMember from "./pages/user/Black_member";
import BlackMemberAddModal from "./components/modal/user/BlackMemberAddModal";
import BlackUpload from "./pages/user/Black_upload";
import Lottery from "./pages/user/Lottery";
import Member from "./pages/user/Member";
import MemberStat from "./pages/user/Member_stat";
import MemberUpdate from "./pages/user/Member_update";
import MemberUpload from "./pages/user/Member_upload";
import Message from "./pages/user/Message";

//총판/가맹관리
import CompanyList from "./pages/partner/Company_list.js";
import CompanySubList from "./pages/partner/CompanySub_list";
import CompanySubAdd from "./pages/partner/CompanySub_add";
import DistributorList from "./pages/partner/Distributor_list";
import DistributorAdd from "./pages/partner/Distributor_add";
import PartnerList from "./pages/partner/Partner_list";
import PartnerAdd from "./pages/partner/Partner_add";
import RecommendList from "./pages/partner/recommend_list";
import PartnerAuth from "./pages/partner/partner_auth";
import RecommendAddComponent from "./components/modal/partner/RecommendAddComponent";
import SetRolling from "./components/modal/partner/SetRolling"
import SetLosing from "./components/modal/partner/SetLosing";
import CompanySubAddModal from "./components/modal/partner/detail/CompanySubAddModal";
import DistributorAddModal from "./components/modal/partner/detail/DistributorAddModal";
import PartnerAddModal from "./components/modal/partner/detail/PartnerAddModal";
import PartnerDefaultInfo1 from "./components/modal/partner/detail/DefaultInfo1";
import PartnerDefaultInfo2 from "./components/modal/partner/detail/DefaultInfo2";
import PartnerDefaultInfo2_mv from "./components/modal/partner/detail/DefaultInfo2_mv";
import PartnerDefaultInfo3 from "./components/modal/partner/detail/DefaultInfo3";
import PartnerDefaultInfo3_mv from "./components/modal/partner/detail/DefaultInfo3_mv";
import PartnerWithdrawInfo from "./components/modal/partner/detail/WithdrawInfo";
import PartnerLosingInfo from "./components/modal/partner/detail/LosingInfo";
import PartnerRollingInfo from "./components/modal/partner/detail/RollingInfo";
import PartnerMemberInfo from "./components/modal/partner/detail/MemberInfo";
import PartnerDomainAdd from "./components/modal/partner/detail/DomainAdd"
import PartnerTreeMove from "./components/modal/partner/detail/TreeMove"
import PartnerAdjustment from "./components/modal/partner/detail/Adjustment"

//환경설정
import SiteSetting from "./pages/setting/siteSetting";
import LevelSetting from "./pages/setting/levelSetting";
import BettingSetting from "./pages/setting/bettingSetting";
import TagSetting from "./pages/setting/tagSetting";
import RegBankSetting from "./pages/setting/regBankSetting";
import AccountSetting from "./pages/setting/accountSetting";
import DomainSetting from "./pages/setting/domainSetting";
import IpSetting from "./pages/setting/ipSetting";
import EtcSetting from "./pages/setting/etcSetting";
import GroupSetting from "./pages/setting/groupSetting";
import CodeSetting from "./pages/setting/codeSetting";
import LvlSetting from "./pages/setting/lvlSetting";
import LvlSettingSportDtl from "./components/modal/setting/LvlSettingSportDtl";
import LvlSettingMiniDtl from "./components/modal/setting/LvlSettingMiniDtl";

import DwpSetting from "./pages/setting/dwpSetting";
import AcctSetting from "./pages/setting/acctSetting";
import RecommenderSetting from "./pages/setting/recommenderSetting";
import AttendanceSetting from "./pages/setting/attendanceSetting";
import RouletteSetting from "./pages/setting/rouletteSetting";
import RegSetting from "./pages/setting/regSetting";
import DefaultSetting from "./pages/setting/defaultSetting";

//모니터링
import LoginFail from "./pages/monitor/LoginFail";
import Account from "./pages/monitor/Account";
import Admin from "./pages/monitor/Admin";
import Active from "./pages/monitor/Active";
import Same from "./pages/monitor/Same";
import Overlap from "./pages/monitor/Overlap";
import MonitorMember from "./pages/monitor/ActiveMember";


//로그인
import LoginModal from "./components/LoginModal";
import MemberInformationSimpleModal from "./components/modal/user/simple/MemberInformationSimpleModal";
import MoneyInfoBar from "./components/top/MoneyInfoBar";
import AlertBar from "./components/top/AlertBar";
import {NumericFormat} from "react-number-format";
import {Badge} from "@mui/material";
import {getAlerts} from "./redux/reducers/alerts";
import BettingPopup from "./components/modal/game/BettingPopup";



function App() {

    return (
        <div>
            <Routes>
                <Route path="/board/notice/insert" element={<InsertNoticePopup />}/>
                <Route path="/board/notice/insert/:noticeNo" element={<InsertNoticePopup />}/>
                <Route path="/board/event/insert" element={<InsertEventPopup />}/>
                <Route path="/board/event/insert/:eventNo" element={<InsertEventPopup />}/>
                <Route path="/board/etc/insert" element={<InsertETCPopup />}/>
                <Route path="/board/etc/insert/:etcNo" element={<InsertETCPopup />}/>
                <Route path="/game/manual/insert/" element={<InsertManualPopup />}/>
                <Route path="/game/manual/betting_popup/:gameId/:betNo" element={<BettingPopup />}/>
                <Route path="/user/member_total_info/:userId" element={<MemberTotalInfo />}/>
                <Route path="/user/member_info/:userId" element={<MemberInfo />}/>
                <Route path="/user/add_black/:userId" element={<BlackMemberAddModal />}/>
                <Route path="/user/add_black/" element={<BlackMemberAddModal />}/>
                <Route path="/partner/recommendAddComponent" element={<RecommendAddComponent />}/>
                <Route path="/partner/setRolling" element={<SetRolling />}/>
                <Route path="/partner/setRolling/:userId" element={<SetRolling />}/>
                <Route path="/partner/setLosing" element={<SetLosing />}/>
                <Route path="/partner/setLosing/:userId" element={<SetLosing />}/>
                <Route path="/partner/CompanySubAddModal" element={<CompanySubAddModal />}/>
                <Route path="/partner/DistributorAddModal/:gb/:up_org_id" element={<DistributorAddModal />}/>
                <Route path="/partner/PartnerAddModal/:gb/:up_org_id" element={<PartnerAddModal />}/>
                <Route path="/partner/defaultInfo1" element={<PartnerDefaultInfo1 />}/>
                <Route path="/partner/defaultInfo1/:userId" element={<PartnerDefaultInfo1 />}/>
                <Route path="/partner/defaultInfo2" element={<PartnerDefaultInfo2 />}/>
                <Route path="/partner/defaultInfo2/:userId" element={<PartnerDefaultInfo2 />}/>
                <Route path="/partner/defaultInfo2_mv" element={<PartnerDefaultInfo2_mv />}/>
                <Route path="/partner/defaultInfo2_mv/:userId" element={<PartnerDefaultInfo2_mv />}/>
                <Route path="/partner/defaultInfo3" element={<PartnerDefaultInfo3 />}/>
                <Route path="/partner/defaultInfo3/:userId" element={<PartnerDefaultInfo3 />}/>
                <Route path="/partner/defaultInfo3_mv" element={<PartnerDefaultInfo3_mv />}/>
                <Route path="/partner/defaultInfo3_mv/:userId" element={<PartnerDefaultInfo3_mv />}/>
                <Route path="/partner/withdrawInfo" element={<PartnerWithdrawInfo />}/>
                <Route path="/partner/withdrawInfo/:userId" element={<PartnerWithdrawInfo />}/>
                <Route path="/partner/losingInfo" element={<PartnerLosingInfo />}/>
                <Route path="/partner/losingInfo/:userId" element={<PartnerLosingInfo />}/>
                <Route path="/partner/rollingInfo" element={<PartnerRollingInfo />}/>
                <Route path="/partner/rollingInfo/:userId" element={<PartnerRollingInfo />}/>
                <Route path="/partner/memberInfo" element={<PartnerMemberInfo />}/>
                <Route path="/partner/memberInfo/:userId" element={<PartnerMemberInfo />}/>

                <Route path="/partner/partnerDomainAdd" element={<PartnerDomainAdd />}/>
                <Route path="/partner/partnerDomainAdd/:userId" element={<PartnerDomainAdd />}/>
                <Route path="/partner/partnerTreeMove" element={<PartnerTreeMove />}/>
                <Route path="/partner/partnerTreeMove/:userId" element={<PartnerTreeMove />}/>
                <Route path="/partner/partnerAdjustment" element={<PartnerAdjustment />}/>
                <Route path="/partner/partnerAdjustment/:userId" element={<PartnerAdjustment />}/>

                <Route path="/setting/LvlSettingSportDtl/:userId/:yn01/:yn02/:yn03/:yn04" element={<LvlSettingSportDtl />}/>
                <Route path="/setting/LvlSettingMiniDtl/:userId/:yn01/:yn02/:yn03/:yn04" element={<LvlSettingMiniDtl />}/>


                <Route element={<Layout />}>
                    <Route path="/" element={<HomePage />}/>

                    {/*게임관리*/}
                    <Route path="/game/manual" element={<Manual />}/>
                    <Route path="/game/setting" element={<GameSetting />}/>
                    <Route path="/game/market_setting" element={<MarketSetting />}/>
                    <Route path="/game/sport_setting" element={<SportSetting />}/>
                    <Route path="/game/game_market_setting" element={<GameMarketSetting />}/>
                    <Route path="/game/game_combination" element={<GameCombination />}/>
                    <Route path="/game/market_by_game" element={<MarketByGame />}/>

                    <Route path="/game/manual/fixture" element={<Fixture />}/>
                    <Route path="/game/manual/wait" element={<Wait />}/>
                    <Route path="/game/manual/active" element={<ActiveGame />}/>
                    <Route path="/game/manual/close" element={<Close />}/>
                    <Route path="/game/manual/result" element={<Result />}/>
                    <Route path="/game/manual/stop" element={<Stop />}/>
                    <Route path="/game/manual/stop_by" element={<StopBy />}/>
                    <Route path="/game/manual/cancel" element={<Cancel />}/>
                    <Route path="/game/manual/cancel_by" element={<CancelBy />}/>

                    <Route path="/game/manual/inplay" element={<Inplay />}/>
                    <Route path="/game/manual/inplay_result" element={<InplayResult />}/>
                    <Route path="/game/manual/mini" element={<MiniGame />}/>
                    <Route path="/game/manual/mini_setting" element={<MiniSetting />}/>

                    {/*베팅관리*/}
                    <Route path="/betting/betting_list" element={<BettingList />}/>
                    <Route path="/betting/betting_list/:userId" element={<BettingList />}/>
                    <Route path="/betting/kgon" element={<KGON />}/>

                    {/*충환전관리*/}
                    <Route path="/deposit/Deposit_list" element={<DepositList />}/>
                    <Route path="/deposit/Deposit_list/:userId" element={<DepositList />}/>
                    <Route path="/deposit/Withdraw_list" element={<WithdrawList />}/>
                    <Route path="/deposit/Withdraw_list/:userId" element={<WithdrawList />}/>
                    <Route path="/deposit/Deposit_withdraw_log" element={<DepositWithdrawLog />}/>
                    <Route path="/deposit/Deposit_withdraw_combine" element={<DepositWithdrawCombine />}/>
                    <Route path="/deposit/Money_list" element={<MoneyList />}/>
                    <Route path="/deposit/Money_list/:userId" element={<MoneyList />}/>
                    <Route path="/deposit/Money_stat" element={<MoneyStat />}/>
                    <Route path="/deposit/Point_list" element={<PointList />}/>
                    <Route path="/deposit/Point_list/:userId" element={<PointList />}/>
                    <Route path="/deposit/Point_stat" element={<PointStat />}/>
                    <Route path="/deposit/Coupon_list" element={<CouponList />}/>
                    <Route path="/deposit/Lottery" element={<Lottery />}/>

                    {/*게시판관리*/}
                    <Route path="/board/center" element={<Customer />}/>
                    <Route path="/board/center/:userId" element={<Customer />}/>
                    <Route path="/board/center_view" element={<CustomerDetail />}/>
                    <Route path="/board/center_view/:cs_no" element={<CustomerDetail />}/>
                    <Route path="/board/notice" element={<Notice />}/>
                    <Route path="/board/event" element={<Event />}/>
                    <Route path="/board/check" element={<Check />}/>
                    <Route path="/board/etc" element={<ETC />}/>

                    {/*회원관리*/}

                    <Route path="/user/Black_member" element={<BlackMember />}/>
                    <Route path="/user/Black_upload" element={<BlackUpload />}/>
                    <Route path="/user/Member" element={<Member />}/>
                    <Route path="/user/Member/:userId" element={<Member />}/>
                    <Route path="/user/Member_stat" element={<MemberStat />}/>
                    <Route path="/user/Member_update" element={<MemberUpdate />}/>
                    <Route path="/user/Member_upload" element={<MemberUpload />}/>
                    <Route path="/user/Message" element={<Message />}/>
                    <Route path="/user/Message/:userId" element={<Message />}/>

                    {/*총판/가맹 관리*/}
                    <Route path="/partner/Company_list" element={<CompanyList />}/>
                    <Route path="/partner/CompanySub_list" element={<CompanySubList />}/>
                    <Route path="/partner/CompanySub_add" element={<CompanySubAdd />}/>
                    <Route path="/partner/Distributor_list" element={<DistributorList />}/>
                    <Route path="/partner/Distributor_add" element={<DistributorAdd />}/>
                    <Route path="/partner/Distributor_add/:orgAdminId" element={<DistributorAdd />}/>
                    <Route path="/partner/Partner_list" element={<PartnerList />}/>
                    <Route path="/partner/Partner_add" element={<PartnerAdd />}/>
                    <Route path="/partner/recommend_list" element={<RecommendList />}/>
                    <Route path="/partner/recommend_list/:userId" element={<RecommendList />}/>
                    <Route path="/partner/partner_auth" element={<PartnerAuth />}/>

                    {/*환경설정*/}
                    <Route path="/setting/siteSetting" element={<SiteSetting />}/>
                    <Route path="/setting/levelSetting" element={<LevelSetting />}/>
                    <Route path="/setting/bettingSetting" element={<BettingSetting />}/>
                    <Route path="/setting/tagSetting" element={<TagSetting />}/>
                    <Route path="/setting/regBankSetting" element={<RegBankSetting />}/>
                    <Route path="/setting/accountSetting" element={<AccountSetting />}/>
                    <Route path="/setting/domainSetting" element={<DomainSetting />}/>
                    <Route path="/setting/ipSetting" element={<IpSetting />}/>
                    <Route path="/setting/etcSetting" element={<EtcSetting />}/>
                    <Route path="/setting/groupSetting" element={<GroupSetting />}/>
                    <Route path="/setting/codeSetting" element={<CodeSetting />}/>
                    <Route path="/setting/lvlSetting" element={<LvlSetting />}/>

                    <Route path="/setting/dwpSetting" element={<DwpSetting />}/>
                    <Route path="/setting/acctSetting" element={<AcctSetting />}/>
                    <Route path="/setting/recommenderSetting" element={<RecommenderSetting />}/>
                    <Route path="/setting/attendanceSetting" element={<AttendanceSetting />}/>
                    <Route path="/setting/rouletteSetting" element={<RouletteSetting />}/>
                    <Route path="/setting/regSetting" element={<RegSetting />}/>
                    <Route path="/setting/defaultSetting" element={<DefaultSetting />}/>

                    {/*모니터링*/}
                    <Route path="/monitoring/user" element={<MonitorMember />}/>
                    <Route path="/monitoring/account" element={<Account />}/>
                    <Route path="/monitoring/fail" element={<LoginFail />}/>
                    <Route path="/monitoring/admin" element={<Admin />}/>
                    <Route path="/monitoring/active" element={<Active />}/>
                    <Route path="/monitoring/same" element={<Same />}/>
                    <Route path="/monitoring/same/:userId" element={<Same />}/>
                    <Route path="/monitoring/overlap" element={<Overlap />}/>


                </Route>
            </Routes>
        </div>
    );
}

function Layout() {
    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch()
    const [isLogin, setIsLogin] = useState(false);
    const [activeNav, setActiveNav] = useState();
    const navigate = useNavigate();
    const state = useSelector((state) => state.alerts)

    //TODO useEffect 할지 체크.
    useEffect(() => {
        axios.post('/admin/admin/checkLogin', {
            id: userInfo.id
        })
            .then((res) => {
                console.log(res.data.isLogin)
                setIsLogin(res.data.isLogin)
            })
    }, [])

    useEffect(() => {
        dispatch(getAlerts())
        setInterval(() => {
            dispatch(getAlerts())
        },5000 )

    }, [])

    const ontoggle = (index, address) => {
        navigate(address)
    }

    const purge = async () => {
        await persistor.purge();
    }

    const onLogoutHandler = (e) => {
        axios.post('/admin/admin/logout', {
            id: userInfo.id
        }).then((res) => {
            if(res.data.isLogout) {
                purge()
                window.location.href="/"
            }
            else {
                alert("잘못됨")
            }
        })

    }



    const [selectedMenu, setSelectedMenu] = useState(0); // 초기에 선택된 메뉴
    const menuStructures = [
        //회원관리
        [
            {name:"전체회원", address:"/user/Member"},
            {name:"가라회원"},
            {name:"엑셀등록", address:"/user/Member_upload"},
            {name:"블랙회원", address:"/user/Black_member"},
            {name:"블랙회원등록", address:"/user/Black_upload"},
            {name:"추천인키관리", address:"/partner/recommend_list"},
            // {name:"쿠폰내역", address:"/deposit/Coupon_list"},
            // {name:"복권", address:"/deposit/Lottery"},
            {name:"수정로그", address:"/user/Member_update"},

            //{name:"회원별 충/환전 통계", address:"/user/Member_stat"},
            //{name:"쪽지관리", address:"/user/Message"},
        ],
        //스포츠설정
        [
            {name:"스포츠설정", address:"/game/sport_setting"},
            {name:"레벨별설정"},
            {name:"리그설정", address:"/game/setting"},
            {name:"마켓설정", address:"/game/market_setting"},
            {name:"종목마켓설정", address: "/game/market_by_game"},
            {name:"종목배당설정", address: "/game/game_market_setting"},
            {name:"종목조합설정", address: "/game/game_combination"},
            {name:"수정로그"},
        ],
        //스포츠관리
        [
            {name:"일정", address:"/game/manual/fixture"},
            {name:"대기", address:"/game/manual/wait"},
            {name:"진행", address:"/game/manual/active"},
            {name:"마감", address:"/game/manual/close"},
            {name:"종료", address:"/game/manual/result"},
            {name:"중지", address:"/game/manual/stop"},
            {name:"취소", address:"/game/manual/cancel"},
            {name:"수동등록", address:"/game/manual"},
            {name:"수정로그"},
        ],
        //카지노설정
        [
            {name:"기본설정"},
            {name:"레벨별설정"},
            {name:"수정로그"},
        ],
        //슬롯설정
        [
            {name:"기본설정"},
            {name:"레벨별설정"},
            {name:"수정로그"},
        ],
        //미니게임설정
        [
            {name:"기본설정"},
            {name:"레벨별설정"},
            {name:"수정로그"},
        ],
        //미니게임관리
        [
            {name:"진행중"},
            {name:"마감"},
            {name:"결과"},
            {name:"수정로그"},
        ],
        //베팅현황
        [
            {name:"스포츠유저베팅", address: "/betting/betting_list"},
            {name:"스포츠경기베팅"},
            {name:"카지노베팅"},
            {name:"슬롯베팅"},
            {name:"미니게임유저베팅"},
            {name:"미니게임회차베팅"},
        ],
        //총판관리
        [
            {name:"총판리스트", address:"/partner/Company_list"},
            {name:"총판접속현황"},
            {name:"수정로그"},
        ],
        //머니로그
        [
            {name:"충전로그", address:"/deposit/Deposit_list"},
            {name:"환전로그", address:"/deposit/Withdraw_list"},
            {name:"보유머니로그", address:"/deposit/Money_list"},
            {name:"포인트로그", address:"/deposit/Point_list"},
            {name:"콤프로그"},
        ],
        //정산통계
        [
            {name:"전체통계"},
            {name:"총판통계"},
            {name:"회원통계"},
            {name:"출석통계"},
            {name:"쿠폰통계"},
            {name:"로그인통계"},
        ],
        //레벨별설정
        [
            {name:"충전/환전/페이백", address:"/setting/dwpSetting"},
            {name:"계좌", address:"/setting/acctSetting"},
            {name:"추천인", address:"/setting/recommenderSetting"},
            //{name:"계좌", address:"/setting/accountSetting"},
            //{name:"추천인", address:"/partner/recommend_list"},
            //{name:"출석체크", address:"/board/check"},
            {name:"출석체크", address:"/setting/attendanceSetting"},
            {name:"룰렛", address:"/setting/rouletteSetting"},
            {name:"수정로그"},
        ],
        //게시판설정
        [
            {name:"공지사항", address:"/board/notice"},
            {name:"이벤트", address:"/board/event"},
            {name:"쪽지", address:"/board/etc"},
            {name:"팝업", address:"/board/etc"},
            {name:"고객센터", address:"/board/center"},
            {name:"수정로그"},
        ],
        //환경설정
        [
            {name:"기본설정", address:"/setting/defaultSetting"},
            {name:"가입설정", address:"/setting/regSetting"},
            // {name:"기본설정", address:"/setting/groupSetting"},
            // {name:"가입설정", address:"/setting/regBankSetting"},
            {name:"관리자설정", },
            {name:"이미지설정", },
            {name:"도메인설정", address:"/setting/domainSetting"},
            {name:"IP설정", address:"/setting/ipSetting"},
            {name:"수정로그"},
            {name:"레벨별설정(asis)", address:"/setting/lvlSetting"},
            /*
            {name:"레벨별설정", address:"/setting/levelSetting"},
            {name:"베팅설정", address:"/setting/bettingSetting"},
            {name:"태그설정", address:"/setting/tagSetting"},
            {name:"기타값관리", address:"/setting/etcSetting"},
            {name:"레벨별설정(신규)", address:"/setting/lvlSetting"},
            {name:"임시코드셋팅", address:"/setting/codeSetting"},
            {name:"그룹관리", address:"/setting/groupSetting"},
            */
        ],




//         //게임관리
//         [
//
//
//
//             {name:"게임마켓사용설정", address:"/game/market_by_game"},
//             {name:"게임마켓상세설정", address:"/game/game_market_setting"},
//
//             {name:"인플레이진행", address:"/game/manual/inplay"},
//             {name:"인플레이종료", address:"/game/manual/inplay_result"},
//             {name:"미니게임", address:"/game/manual/mini"},
//             {name:"미니게임설정", address:"/game/manual/mini_setting"},
//         ],
//         //베팅관리
//         [
//             {name:"베팅내역", address:"/betting/betting_list"},
//             {name:"[kgon]베팅내역", address:"/betting/kgon"},
//         ],
//         //충환전관리
//         [
//
//             {name:"충/환전 처리로그", address:"/deposit/Deposit_withdraw_log"},
//             {name:"충/환전 통합내역", address:"/deposit/Deposit_withdraw_combine"},
//
//             {name:"머니내역통계[전체]", address:"/deposit/Money_stat"},
//
//             {name:"포인트통계[전체]", address:"/deposit/Point_stat"},
//             {name:"쿠폰내역", address:"/deposit/Coupon_list"},
//             {name:"복권", address:"/deposit/Lottery"},
//         ],
//         //게시판이벤트관리
//         [
//
//         ],
//
//         //총판/가맹관리
//         [
//
//             /*
//             {name:"부본사목록", address:"/partner/CompanySub_list"},
//             {name:"부본사등록", address:"/partner/CompanySub_add"},
//             {name:"총판목록", address:"/partner/Distributor_list"},
//             {name:"총판등록", address:"/partner/Distributor_add"},
//             {name:"가맹점목록", address:"/partner/Partner_list"},
//             {name:"가맹점등록", address:"/partner/Partner_add"},
//             */
//
//             {name:"조회권한관리", address:"/partner/partner_auth"},
//
//         ],
//
// //자동게임
//         [
//             {name:"자동게임", address:"/"},
//         ],
// //모니터링
//         [
//             {name:"회원활동", address:"/monitoring/user"},
//             {name:"계좌조회", address:"/monitoring/account"},
//             {name:"로그인실패", address:"/monitoring/fail"},
//             {name:"관리지활동", address:"/monitoring/admin"},
//             {name:"[활동로그]", address:"/monitoring/active"},
//             {name:"동일브라우저", address:"/monitoring/same"},
//             {name:"중복IP", address:"/monitoring/overlap"},
//         ],
    ];

    const handleMouseOver = (index) => {
        setSelectedMenu(index); // 선택된 메뉴를 변경
    };

    const [isEditing01, setIsEditing01] = useState(true);
    const start01EditingHandler = () => {
        if( isEditing01 )
        {
            setIsEditing01(false);
        }
        else
        {
            setIsEditing01(true);
        }

    };

    return (
        <div>
            <table width={"99%"}>
                <tr>
                    <td>
                        <div className ="todayStatusContainer" style={{ background:"#3c3c3d",}}>
                            <div style={{ background:"black" , width:"1px"}}>
                            </div>
                            <div style={{ background:"#3c3c3d", width:"100%", overflowX:"auto" }}>
                                <table>
                                    <tr>
                                        <td>
                                            <a style={{ cursor:"pointer" }}
                                               onClick={() => {start01EditingHandler()}}>
                                                <b><List size={50} style={{color:"white"}}/></b>
                                            </a>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/user/Member/search01" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >

                                                    <b>분실문의: <NumericFormat value={0} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/user/Member/search01" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>회원가입: <NumericFormat value={state.alerts?.join_month_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/board/center/search01" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>회원문의: <NumericFormat value={state.alerts?.board_month_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/deposit/Deposit_list/search01" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>회원입금 : <NumericFormat value={state.alerts?.deposit_month_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/deposit/Withdraw_list/search01" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>회원출금 : <NumericFormat value={state.alerts?.withdraw_month_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                            >
                                                <b>콤프전환 : <NumericFormat value={state.alerts?.comp_month_amt} displayType={'text'} thousandSeparator={true}/></b>
                                            </button>
                                            <Link className="nav-link" to="/partner/Company_list" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>총판정산 : <NumericFormat value={state.alerts?.adjustment_month_amt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/game/manual/active" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>스포츠 알림 : <NumericFormat value={state.alerts?.sport_alert_amt} displayType={'text'} thousandSeparator={true}/> / <NumericFormat value={state.alerts?.sport_alert_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/game/manual/active" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>미니게임 알림 : <NumericFormat value={state.alerts?.mini_alert_amt} displayType={'text'} thousandSeparator={true}/> / <NumericFormat value={state.alerts?.mini_alert_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/game/manual/active" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>카지노 알림 : <NumericFormat value={state.alerts?.casino_alert_amt} displayType={'text'} thousandSeparator={true}/> / <NumericFormat value={state.alerts?.casino_alert_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/game/manual/active" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>슬롯 알림 : <NumericFormat value={state.alerts?.slot_alert_amt} displayType={'text'} thousandSeparator={true}/> / <NumericFormat value={state.alerts?.slot_alert_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/user/Member" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "120px", marginRight: "30px", marginTop:"10px"}}
                                                >
                                                    <b>관심회원 : <NumericFormat value={state.alerts?.interested_amt} displayType={'text'} thousandSeparator={true}/> / <NumericFormat value={state.alerts?.interested_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/game/manual" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "120px", marginRight: "30px", marginTop:"10px"}}
                                                >
                                                    <b>수동 처리 : <NumericFormat value={state.alerts?.manual_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/monitoring/user" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>접속중 : <NumericFormat value={state.alerts?.session_now_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/monitoring/active" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>금일 접속 : <NumericFormat value={state.alerts?.session_today_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/user/Member/search02" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>금일 가입 : <NumericFormat value={state.alerts?.join_day_cnt} displayType={'text'} thousandSeparator={true}/> / <NumericFormat value={state.alerts?.approval_day_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/deposit/Deposit_list" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>가입 첫 충전 : <NumericFormat value={state.alerts?.join_deposit_amt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/user/Member/search07" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>총 정지 회원 : <NumericFormat value={state.alerts?.stop_total_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/user/Member" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>금일 정지 회원 : <NumericFormat value={state.alerts?.stop_today_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/user/Member/search03" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>총 회원 : <NumericFormat value={state.alerts?.join_total_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/user/Member/search04" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>총 무료 회원 : <NumericFormat value={state.alerts?.free_total_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/user/Member/search05" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>총 탈퇴 : <NumericFormat value={state.alerts?.del_total_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/user/Member/search06" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>금일 탈퇴 : <NumericFormat value={state.alerts?.del_today_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="nav-link" to="/monitoring/fail" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>로그인 실패 : <NumericFormat value={state.alerts?.login_fail_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                            <Link className="nav-link" to="/user/Member" >
                                                <button style={{ backgroundColor: "white", border: "none", color: "black" ,fontSize: "11px", height: "30px", width: "110px", marginRight: "5px", marginTop:"10px"}}
                                                >
                                                    <b>예금주 변경 : <NumericFormat value={state.alerts?.depositor_update_cnt} displayType={'text'} thousandSeparator={true}/></b>
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="13">
                                            <nav className="navbar navbar-expand-sm navbar-dark ">
                                                <div className="navbar-collapse collapse justify-content-start adminMenu">
                                                    <div className ="menuSearch">
                                                        <Search/>&nbsp;
                                                        <input
                                                            style={{width:"190px"}}
                                                            type="text"
                                                            placeholder="검색어 입력 후 엔터"
                                                        />
                                                    </div>
                                                    <ul className="navbar-nav">
                                                        <li className="nav-item">
                                                            <Link className="nav-link" to="/">
                                                                <House size={20}/>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" to="/user/Member" onClick={() => handleMouseOver(0)}>회원관리</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(1)}>스포츠설정</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(2)}>스포츠관리</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(3)}>카지노설정</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(4)}>슬롯설정</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(5)}>미니게임설정</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(6)}>미니게임관리</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(7)}>베팅현황</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(8)}>총판관리</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(9)}>머니로그</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(10)}>정산통계</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(11)}>레벨별설정</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(12)}>게시판설정</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" onClick={() => handleMouseOver(13)}>환경설정</Link>
                                                        </li>
                                                    </ul>
                                                    <div className="d-flex">
                                                        <Badge badgeContent={0} color="primary">
                                                            <a onClick={onLogoutHandler} style={{cursor: 'pointer'}}>
                                                                <ImSwitch size={20} style={{color:"white"}}/>
                                                            </a>
                                                        </Badge>
                                                    </div>
                                                </div>
                                            </nav>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>


                <tr>
                    <td>
                        <div className="container-fluid p-0 mx-1" style={{
                            display: "flex"
                        }}>
                            {isEditing01 && (
                                <div style={{
                                    position:"relative",
                                    width:"13%"
                                }}>
                                    <div style={{
                                        position:"relative",
                                        background:"#3c3c3d",
                                        height:"30px",
                                        alignItems:"center",
                                        textAlign:"right",
                                        alignContent:"center"
                                    }}>
                                        <font color={"white"} size={"2"}><b>새로고침&nbsp;<ArrowClockwise size={20}/>&nbsp;&nbsp;</b></font>
                                    </div>
                                    <div style={{
                                        position:"relative",
                                        background:"black",
                                        height:"3px",
                                        alignItems:"center",
                                        textAlign:"center",
                                        alignContent:"center"
                                    }}>
                                    </div>
                                    <div style={{
                                        position:"relative",
                                        background:"black",
                                        alignItems:"center",
                                        textAlign:"center",
                                        alignContent:"center"
                                    }}>
                                        <LeftComponent/>
                                    </div>
                                </div>
                            )}

                            {isEditing01 && (
                                <div style={{
                                    position:"relative",
                                    width:"87%",
                                    float:"right"
                                }}>
                                    {userInfo.isOpen && (<MemberInformationSimpleModal isOpen={userInfo.isOpen} userId={userInfo.openId} xPos={userInfo.xPos}/>)}


                                    {/* 선택된 메뉴 구조를 렌더링 */}
                                    <div className="adminSubMenuContainer_slide">
                                        {menuStructures[selectedMenu].map((menu,index) => (
                                            <div className="adminSubMenuBoard" key={index} onClick={() => ontoggle(index, menu.address,menu.name)}>
                                                {menu.name}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex">
                                        {isLogin?
                                            <>
                                                <div style={{
                                                    width: "1400px",
                                                    position:"relative",
                                                }}>
                                                    <Outlet/>
                                                </div>
                                            </>
                                            :
                                            (
                                                <div>
                                                    <LoginModal/>
                                                </div>
                                            )}
                                    </div>

                                </div>
                            )}


                            {!isEditing01 && (
                                <div style={{
                                    position:"relative",
                                    width:"99%",
                                    float:"right"
                                }}>
                                    {userInfo.isOpen && (<MemberInformationSimpleModal isOpen={userInfo.isOpen} userId={userInfo.openId} xPos={userInfo.xPos}/>)}
                                    {/* 선택된 메뉴 구조를 렌더링 */}
                                    <div className="adminSubMenuContainer">
                                        {menuStructures[selectedMenu].map((menu,index) => (
                                            <div className="adminSubMenuBoard" key={index} onClick={() => ontoggle(index, menu.address,menu.name)}>
                                                {menu.name}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex">
                                        {isLogin?
                                            <>
                                                <div style={{
                                                    width: "1600px",
                                                    position:"relative",
                                                }}>
                                                    <Outlet/>
                                                </div>
                                            </>
                                            :
                                            (
                                                <div>
                                                    <LoginModal/>
                                                </div>
                                            )}
                                    </div>

                                </div>
                            )}
                        </div>
                    </td>
                </tr>
            </table>
        </div>

    );
}

export default App;

