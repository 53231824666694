import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const tagSettingSlice = createSlice({
    name: 'tagSetting',
    initialState: {
        isLoading: false,
        error: null,
        tagSetting: [],
        countList: {},
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        query: "",
        userGbCd: "",
        searchGbCd: "",
        tagNmArr: ["111","222","333"]
    },
    reducers: {
        getTagSetting(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userGbCd = action.payload.userGbCd;
            state.searchGbCd = action.payload.searchGbCd;
        },
        setTagSetting(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.tagSetting = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList
        },
        failedGetTagSetting(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { getTagSetting, setTagSetting, failedGetTagSetting, tagNmArr } = tagSettingSlice.actions;

export default tagSettingSlice.reducer;