import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Pagination from "../../../../components/common/Pagination2";
import {getMemberInfo,setQuery,setPageSize,setStartDttm,setEndDttm} from "../../../../redux/reducers/partner/MemberInfoReducer";
import {useParams} from "react-router-dom";
import {insertAdminLog} from "../../../../lib/api";
import {NumericFormat} from "react-number-format";

const MemberInfo = (props) => {

    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.MemberInfoReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const param = useParams()
    const [activeIndex, setActiveIndex] = useState(null); // 클릭된 버튼의 인덱스를 저장

    const getList = () => {
        if(initialState.totalList !== null ) {
            dispatch(getMemberInfo({
                input :{
                    page : initialState.page,
                    pageSize : initialState.pageSize,
                    orgAdminId : param.userId,
                    query : initialState.query,
                    startDttm : initialState.startDttm,
                    endDttm : initialState.endDttm,
                    userGbCd : initialState.userGbCd,
                }
            }))
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "조직회원내역팝업"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }

    useEffect(getList, [dispatch])
    const inputQuery = (e) => {
        dispatch(setQuery(e.target.value))
    }
    const onClickSearch = (e) => {
        dispatch(getMemberInfo({
            input :{
                page : initialState.page,
                pageSize : initialState.pageSize,
                orgAdminId : param.userId,
                query : initialState.query,
                startDttm : initialState.startDttm,
                endDttm : initialState.endDttm,
                userGbCd : initialState.userGbCd,
            }
        }))
    }

    const handlePageChange = (page) => {
        dispatch(getMemberInfo({
            input :{
                page : page,
                pageSize : initialState.pageSize,
                orgAdminId : param.userId,
                query : initialState.query,
                startDttm : initialState.startDttm,
                endDttm : initialState.endDttm,
                userGbCd : initialState.userGbCd,
            }
        }))
    }

    const userGbCdClick = (index,cd) => {
        setActiveIndex(index)
        dispatch(getMemberInfo({
            input :{
                page : initialState.page,
                pageSize : initialState.pageSize,
                orgAdminId : param.userId,
                query : initialState.query,
                startDttm : initialState.startDttm,
                endDttm : initialState.endDttm,
                userGbCd : cd,
            }
        }))
    }

    const changePageSize = (e) => {
        dispatch(setPageSize(parseInt(e.currentTarget.value)))
    }

    const startDateHandler = (e) => {
        dispatch(setStartDttm(e.currentTarget.value))
    }
    const endDateHandler = (e) => {
        dispatch(setEndDttm(e.currentTarget.value))
    }

    return (
        <div className="common" style={{width : "99%"}}>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            let userLvl = initialState.userLvl;
                            //alert("userLvl : "+userLvl);

                            if( userLvl === '01' )
                            {
                                window.location.href= "/partner/defaultInfo1/"+param.userId;
                            }else if( userLvl === '02' )
                            {
                                window.location.href= "/partner/defaultInfo2/"+param.userId;
                            }else if( userLvl === '03' )
                            {
                                window.location.href= "/partner/defaultInfo3/"+param.userId;
                            }

                        }}
                >기본정보</button>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/withdrawInfo/"+param.userId;
                        }}
                >출금</button>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/losingInfo/"+param.userId;
                        }}
                >루징내역</button>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/rollingInfo/"+param.userId;
                        }}
                >롤링내역</button>
                <button style={{ backgroundColor: "white",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                >회원내역</button>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div
                className="container-fluid p-0 mx-1"
                style={{
                    display: "flex", // Flexbox를 사용
                    justifyContent: "center", // 수평 중앙 정렬
                    alignItems: "center", // 수직 중앙 정렬
                    backgroundColor: "#f0f0f0", // 회색 배경
                    padding: "20px 40px", // 상하 20px, 좌우 40px 여백 추가
                    borderRadius: "5px", // 모서리 둥글게
                    minHeight: "40px", // 회색 바탕 최소 높이
                    width: "fit-content", // 내용물에 맞게 너비 조절
                    margin: "0 auto", // 페이지 가운데로 이동
                }}
            >
                {/* "전체" 버튼 추가 */}
                <button
                    onClick={() => userGbCdClick(-1, null)} // -1 또는 null을 통해 전체 선택 처리
                    style={{
                        backgroundColor: activeIndex === -1 ? "#007BFF" : "#ffffff",
                        color: activeIndex === -1 ? "#ffffff" : "#000000",
                        border: "1px solid #ccc",
                        padding: "2px 10px",
                        margin: "0 5px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "background-color 0.3s, color 0.3s",
                    }}
                >
                    전체
                </button>
                {initialState.cmCd0002 && initialState.cmCd0002.map((list, index) => (
                    <button
                        key={index}
                        onClick={(e) => userGbCdClick(index,list.cmmn_cd_dtl_id)} // 클릭된 버튼의 인덱스를 저장
                        style={{
                            backgroundColor: activeIndex === index ? "#007BFF" : "#ffffff", // 클릭된 버튼은 파란색
                            color: activeIndex === index ? "#ffffff" : "#000000", // 클릭된 버튼은 흰색 글자
                            border: "1px solid #ccc", // 테두리 설정
                            padding: "2px 2px", // 버튼 내부 여백
                            margin: "0 5px", // 버튼 사이 여백
                            borderRadius: "5px", // 모서리 둥글게
                            cursor: "pointer", // 마우스 커서 포인터
                            transition: "background-color 0.3s, color 0.3s", // 부드러운 전환 효과
                        }}
                    >
                        {list.cmmn_cd_dtl_nm}
                    </button>
                ))}
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <input className="form-control sm-date" type="date" value={initialState.startDttm}
                           onChange={startDateHandler} style={{width: "110px"}}/>
                    &nbsp;~&nbsp;
                    <input className="form-control sm-date" type="date" value={initialState.endDttm}
                           onChange={endDateHandler} style={{width: "110px"}}/>
                    &nbsp;&nbsp;
                    <input value={initialState.query} onChange={inputQuery} className="form-check-input sm-input" type="text" name="insertNo" style={{width: "120px"}}
                           placeholder={"아이디/닉네임/예금주"} onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onClickSearch(); // Enter 키 입력 시 onClickSearch 호출
                        }
                    }}
                    />
                    &nbsp;&nbsp;
                    <button style={{ backgroundColor: "lightskyblue",
                        color: "white", border: "none", fontSize: "9px", height: "20px", width: "70px", fontWeight: "bold", marginRight: "1px"}}
                            onClick={onClickSearch}>검색</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <font size={"2"}>총 회원수</font> &nbsp;: &nbsp;<b><NumericFormat value={initialState.totalCount}
                                                                 displayType={'text'} thousandSeparator={true}/></b>  &nbsp;&nbsp;
                    <font size={"2"}>총 보유금</font> &nbsp;: &nbsp; <b><NumericFormat value={initialState.totalGroupedData&&initialState.totalGroupedData.game_money_amt}
                                                                                   displayType={'text'} thousandSeparator={true}/></b>   &nbsp;&nbsp;
                    <font size={"2"}>총 포인트</font> &nbsp;: &nbsp; <b><NumericFormat value={initialState.totalGroupedData&&initialState.totalGroupedData.point}
                                                                   displayType={'text'} thousandSeparator={true}/></b>   &nbsp;&nbsp;
                    <font size={"2"}>총 입금</font> &nbsp;: &nbsp; <b><NumericFormat value={initialState.totalGroupedData&&initialState.totalGroupedData.deposit_amt}
                                                                   displayType={'text'} thousandSeparator={true}/></b>   &nbsp;&nbsp;
                    <font size={"2"}>총 출금</font> &nbsp;: &nbsp; <b><NumericFormat value={initialState.totalGroupedData&&initialState.totalGroupedData.withdraw_amt}
                                                                   displayType={'text'} thousandSeparator={true}/></b>   &nbsp;&nbsp;
                    <font size={"2"}>총 입출마진</font> &nbsp;: &nbsp; <b><NumericFormat value={initialState.totalGroupedData&&initialState.totalGroupedData.profit_amt}
                                                                   displayType={'text'} thousandSeparator={true}/></b>  &nbsp;&nbsp;
                    <font size={"2"}>총 베팅</font> &nbsp;: &nbsp; <b><NumericFormat value={initialState.totalGroupedData2&&initialState.totalGroupedData2.bet_amt}
                                                                   displayType={'text'} thousandSeparator={true}/></b>   &nbsp;&nbsp;
                    <font size={"2"}>총 당첨</font> &nbsp;: &nbsp; <b><NumericFormat value={initialState.totalGroupedData2&&initialState.totalGroupedData2.win_amt}
                                                                   displayType={'text'} thousandSeparator={true}/></b>   &nbsp;&nbsp;
                    <font size={"2"}>총 베팅차액</font> &nbsp;: &nbsp; <b><NumericFormat value={initialState.totalGroupedData2&&initialState.totalGroupedData2.bet_margin_amt}
                                                                  displayType={'text'} thousandSeparator={true}/></b>   &nbsp;&nbsp;
                </div>
                <div className="" style={{
                    display: "flex"
                }}>
                    <table>
                        <tr>
                            <td align={"right"}>
                                <select
                                    className="form-select sm-select"
                                    defaultValue={'01'}
                                    style={{
                                        width: "100px"
                                    }}
                                    onChange={changePageSize}
                                >
                                    <option value={10}>10건 출력</option>
                                    <option value={25}>25건 출력</option>
                                    <option value={50}>50건 출력</option>
                                </select>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
                <table className="commonT table table-bordered table-sm text-center">
                    <thead >
                    <tr>
                        <th style={{backgroundColor: "lightgray",width: "3%"}}>루트총판</th>
                        <th style={{backgroundColor: "lightgray",width: "3%"}}>상위총판</th>
                        <th style={{backgroundColor: "lightgray",width: "3%"}}>아이디</th>
                        <th style={{backgroundColor: "lightgray",width: "3%"}}>닉네임</th>
                        <th style={{backgroundColor: "lightpink",width: "3%"}}>예금주</th>
                        <th style={{backgroundColor: "lightgray",width: "3%"}}>명칭</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>보유금액</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>포인트</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>롤링금</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>입금</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>출금</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>입-출</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>베팅</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>당첨</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>베팅차액</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>접속일자</th>
                        <th style={{backgroundColor: "lightgray",width: "6%"}}>가입일자</th>
                    </tr>
                    </thead>
                    {!initialState.totalList && (
                        <tbody>
                        <tr>
                            <td colSpan="19">목록이 비어있습니다.</td>
                        </tr>
                        </tbody>
                    )}
                    {initialState.totalList&&initialState.gridList?.map((gridList, index) => (
                        <tbody key={gridList.no}>
                        <tr>
                            <td align={"center"}>{gridList.org_admin_id}</td>{/*루트총판*/}
                            <td align={"center"}>{gridList.up_org_id}</td>{/*상위총판*/}
                            <td align={"center"}>{gridList.id}</td>{/*아이디*/}
                            <td align={"center"}>{gridList.nickname}</td>{/*닉네임*/}
                            <td align={"center"}>{gridList.account_name}</td>{/*예금주*/}
                            <td align={"center"}>{gridList.alias}</td>{/*명칭*/}
                            <td align={"center"}><NumericFormat value={gridList.game_money_amt} displayType={'text'} thousandSeparator={true}/></td>{/*보유금액*/}
                            <td align={"center"}><NumericFormat value={gridList.point} displayType={'text'} thousandSeparator={true}/></td>{/*포인트*/}
                            <td align={"center"}><NumericFormat value={gridList.rolling_amt} displayType={'text'} thousandSeparator={true}/></td>{/*롤링금*/}
                            <td align={"center"}><NumericFormat value={gridList.deposit_amt} displayType={'text'} thousandSeparator={true}/></td>{/*입금*/}
                            <td align={"center"}><NumericFormat value={gridList.withdraw_amt} displayType={'text'} thousandSeparator={true}/></td>{/*출금*/}
                            <td align={"center"}><NumericFormat value={gridList.profit_amt} displayType={'text'} thousandSeparator={true}/></td>{/*입-출*/}
                            <td align={"center"}><NumericFormat value={gridList.bet_amt} displayType={'text'} thousandSeparator={true}/></td>{/*베팅*/}
                            <td align={"center"}><NumericFormat value={gridList.win_amt} displayType={'text'} thousandSeparator={true}/></td>{/*당첨*/}
                            <td align={"center"}><NumericFormat value={gridList.bet_margin_amt} displayType={'text'} thousandSeparator={true}/></td>{/*배당*/}
                            <td align={"center"}>{gridList.rcnt_actv_dttm}</td>{/*접속일자*/}
                            <td align={"center"}>{gridList.entr_dttm}</td>{/*가입일자*/}
                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalCount && initialState.totalCount > 0 ?
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange} />
                    :""}
            </div>
        </div>

    )
}
export default MemberInfo;