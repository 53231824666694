import {call, put, take,} from "redux-saga/effects";
import {fetchCouponList} from "../../../lib/api";
import {failedGetCouponList, setCouponList} from "../../reducers/deposit/CouponListReducer";

export function* getCouponListSaga(action) {
    try{
        const res = yield call(
            fetchCouponList,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd, action.payload.searchCd01
        )
        yield put(setCouponList(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetCouponList(error))
    }
}