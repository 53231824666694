import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
const BettingSetting = ({bettingSetting, onRefresh}) => {

    const userInfoId = useSelector((state) => state.userInfo.id)
    const [minBetCnt, setMinBetCnt] = useState("");
    const [maxBetCnt, setMaxBetCnt] = useState("");
    const [betTime, setBetTime] = useState("");
    const [beforeTime, setBeforeTime] = useState("");
    const [afterTime, setAfterTime] = useState("");
    const [betLimitYn, setBetLimitYn] = useState("");
    const [betLimitCd, setBetLimitCd] = useState("");
    const [eventFont, setEventFont] = useState("");
    const [eventBackgroundColor, setEventBackgroundColor] = useState("");
    const [gameOutputTime, setGameOutputTime] = useState("");

    const minBetCntChangeHandler = (e) => {
        setMinBetCnt(e.currentTarget.value);
    }
    const maxBetCntChangeHandler = (e) => {
        setMaxBetCnt(e.currentTarget.value);
    }
    const betTimeChangeHandler = (e) => {
        setBetTime(e.currentTarget.value);
    }
    const beforeTimeChangeHandler = (e) => {
        setBeforeTime(e.currentTarget.value);
    }
    const afterTimeChangeHandler = (e) => {
        setAfterTime(e.currentTarget.value);
    }
    const betLimitYnChangeHandler = (e) => {
        setBetLimitYn(e.currentTarget.value);
    }
    const betLimitCdChangeHandler = (e) => {
        setBetLimitCd(e.currentTarget.value);
    }
    const eventFontChangeHandler = (e) => {
        setEventFont(e.currentTarget.value);
    }
    const eventBackgroundColorChangeHandler = (e) => {
        setEventBackgroundColor(e.currentTarget.value);
    }
    const gameOutputTimeChangeHandler = (e) => {
        setGameOutputTime(e.currentTarget.value);
    }




    const inputPriceFormat = (str) => {
        console.log("s", str);
        return str;
        // const comma = (str) => {
        //     str = String(str);
        //     return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
        // };
        // const uncomma = (str) => {
        //     str = String(str);
        //     return str.replace(/[^\d]+/g, "");
        // };
        // return comma(uncomma(str));
    };


    const updateState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveBettingSetting', {
                adminNo: userInfoId,
                minBetCnt: minBetCnt?minBetCnt:bettingSetting.min_bet_cnt,
                maxBetCnt: maxBetCnt?maxBetCnt:bettingSetting.max_bet_cnt,
                betTime: betTime?betTime:bettingSetting.bet_time,
                beforeTime: beforeTime?beforeTime:bettingSetting.before_time,
                afterTime: afterTime?afterTime:bettingSetting.after_time,
                betLimitYn: betLimitYn?betLimitYn:bettingSetting.bet_limit_yn,
                betLimitCd: betLimitCd?betLimitCd:bettingSetting.bet_limit_cd,
                eventFont: eventFont?eventFont:bettingSetting.event_font,
                eventBackgroundColor: eventBackgroundColor?eventBackgroundColor:bettingSetting.event_background_color,
                gameOutputTime: gameOutputTime?gameOutputTime:bettingSetting.game_output_time
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }

    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "120px"
                                }} type="submit" className="btn btn-primary" onClick={() => {updateState()}}>베팅 설정 수정
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <b>베팅 제한</b>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="table table-bordered table-sm text-center align-middle">
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>최소베팅금</td>
                            <td width={"1%"}></td>
                            <td width={"34%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                           placeholder={addComma(bettingSetting.min_bet_cnt)}
                                           value={minBetCnt}
                                           onChange={(e) => setMinBetCnt(e.target.value)}
                                    />
                                    &nbsp;원
                                </div>
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>최대베팅폴더수</td>
                            <td width={"1%"}></td>
                            <td width={"34%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                           placeholder={addComma(bettingSetting.max_bet_cnt)}
                                           value={maxBetCnt}
                                           onChange={(e) => setMaxBetCnt(e.target.value)}
                                    />
                                    &nbsp;개
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <b>시간 제한</b>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <br></br>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>베팅가능시간</td>
                            <td width={"1%"}></td>
                            <td width={"14%"} align={"left"} colSpan="2">
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}> 경기 전&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width: "50px"}}
                                           placeholder={addComma(bettingSetting.bet_time)}
                                           value={betTime}
                                           onChange={(e) => setBetTime(e.target.value)}
                                    />
                                    &nbsp;분
                                </div>
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"20%"}>경기 시작전 베팅취소가능시간
                            </td>
                            <td width={"1%"}></td>
                            <td width={"14%"} align={"left"} colSpan="2">
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}> 경기 전&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width: "50px"}}
                                           placeholder={addComma(bettingSetting.before_time)}
                                           value={beforeTime}
                                           onChange={(e) => setBeforeTime(e.target.value)}
                                    />
                                    &nbsp;분
                                </div>
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"20%"}>베팅 후 베팅취소가능시간</td>
                            <td width={"1%"}></td>
                            <td width={"14%"} align={"left"} colSpan="2">
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}> 베팅 후&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width: "50px"}}
                                           placeholder={addComma(bettingSetting.after_time)}
                                           value={afterTime}
                                           onChange={(e) => setAfterTime(e.target.value)}
                                    />
                                    &nbsp;분
                                </div>
                            </td>

                        </tr>

                    </table>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <b>단폴더 제한</b>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <br></br>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>단폴더 베팅제한</td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input" type="radio" id="betLimitYn" value="Y" checked={betLimitYn === "" ? (bettingSetting.bet_limit_yn ==="Y" ?true:false) : ( betLimitYn ==="Y" ? true:false) }
                                           onChange={betLimitYnChangeHandler} style={{ cursor: "pointer" }}/> 사용
                                    &nbsp;
                                    <input className="form-check-input" type="radio" id="betLimitYn" value="N" checked={betLimitYn === "" ? (bettingSetting.bet_limit_yn ==="N" ?true:false) : ( betLimitYn ==="N" ? true:false) }
                                           onChange={betLimitYnChangeHandler} style={{ cursor: "pointer" }}/> 미사용
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>단폴더 베팅제한 금액</td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    * '레벨별 설정'에서 설정 가능
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>승무패 단폴더 베팅 갯수 제한</td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input" type="radio" id="betLimitCd" value="01" checked={betLimitCd === "" ? (bettingSetting.bet_limit_cd ==="01" ?true:false) : ( betLimitCd ==="01" ? true:false) }
                                           onChange={betLimitCdChangeHandler} style={{ cursor: "pointer" }}/> 승무패 단폴더 베팅 불가능
                                    &nbsp;
                                    <input className="form-check-input" type="radio" id="betLimitCd" value="02" checked={betLimitCd === "" ? (bettingSetting.bet_limit_cd ==="02" ?true:false) : ( betLimitCd ==="02" ? true:false) }
                                           onChange={betLimitCdChangeHandler} style={{ cursor: "pointer" }}/> 1개
                                    &nbsp;
                                    <input className="form-check-input" type="radio" id="betLimitCd" value="03" checked={betLimitCd === "" ? (bettingSetting.bet_limit_cd ==="03" ?true:false) : ( betLimitCd ==="03" ? true:false) }
                                           onChange={betLimitCdChangeHandler} style={{ cursor: "pointer" }}/> 2개
                                    &nbsp;
                                    <input className="form-check-input" type="radio" id="betLimitCd" value="04" checked={betLimitCd === "" ? (bettingSetting.bet_limit_cd ==="04" ?true:false) : ( betLimitCd ==="04" ? true:false) }
                                           onChange={betLimitCdChangeHandler} style={{ cursor: "pointer" }}/> 3개
                                    &nbsp;
                                    <input className="form-check-input" type="radio" id="betLimitCd" value="05" checked={betLimitCd === "" ? (bettingSetting.bet_limit_cd ==="05" ?true:false) : ( betLimitCd ==="05" ? true:false) }
                                           onChange={betLimitCdChangeHandler} style={{ cursor: "pointer" }}/> 제한없음
                                </div>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    * 승무패 게임에 대한 단폴더 갯수 제한 : 1을 입력할 경우, 승무패 게임에 대해 승/무/패 중 하나만 걸 수 있음(단폴더만 카운팅)
                                </div>
                            </td>
                        </tr>

                    </table>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <b>이벤트</b>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <br></br>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>이벤트게임색상
                            </td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    글씨 : &nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                           placeholder={bettingSetting.event_font} value={eventFont} onChange={eventFontChangeHandler}
                                    />
                                    &nbsp;배경
                                    <input className="form-check-input sm-input" type="text" style={{width: "100px"}}
                                           placeholder={bettingSetting.event_background_color} value={eventBackgroundColor} onChange={eventBackgroundColorChangeHandler}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>게임출력시간
                            </td>
                            <td width={"1%"}></td>
                            <td colSpan="4" align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    베팅 종료 후&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width: "50px"}}
                                           placeholder={bettingSetting.game_output_time} value={gameOutputTime} onChange={gameOutputTimeChangeHandler}
                                    />
                                    &nbsp;시간
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>

    )
}

export default BettingSetting;