import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {NumericFormat} from "react-number-format";
import { ArrowsExpand, ArrowLeftCircleFill, Controller } from "react-bootstrap-icons";
import Switch from 'react-switch';
import {getMemberStateReducer} from "../../redux/reducers/user/MemberStateReducer";
import Pagination from "../../components/common/Pagination2";
import {insertAdminLog} from "../../lib/api";

const Member_stat = () => {
    const userInfoId = useSelector((state) => state.userInfo.id)
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.MemberStateReducer)
    const getList = () => {
        if(initialState.totalList !== null ) {
            dispatch(getMemberStateReducer({
                input:{
                    page: 1,
                    pageSize: initialState.input.pageSize,
                }
            }))
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "회원별 충/환전통계"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getMemberStateReducer({
            input:{
                page: page,
                pageSize: initialState.input.pageSize,
                query : initialState.input.query,
            }
        }))
    }

    return (
        <div className="commonNew">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">충/환 통계</font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>지난 달</button>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>이번 달</button>
                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>일주일</button>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>어제</button>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>오늘</button>
                    &nbsp;
                    <input className="form-control sm-date" type="date"/>
                    ~
                    <input className="form-control sm-date" type="date"/>
                    &nbsp;
                    <select
                        className="form-select sm-select"
                        defaultValue={100}
                        style={{
                            width: "170px"
                        }}
                        value={"999"}
                    >
                        <option value={999}>전체 사이트 코드</option>
                    </select>
                    &nbsp;
                    <select
                        className="form-select sm-select"
                        defaultValue={100}
                        style={{
                            width: "200px"
                        }}
                        value={"999"}
                    >
                        <option value={999}>::총판/가맹점::</option>
                    </select>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"1600px"
                }}>
                    <table width={"75%"} className="table table-bordered table-sm text-center align-middle" >
                        <tbody>
                        <tr className="table-secondary">
                            <td style={{ backgroundColor:"white"}}>
                                <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" /> 하위 포함 검색
                            </td>
                            <td style={{ backgroundColor:"white"}}>
                                <ArrowsExpand />환전구분 :
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio1" /> 전체
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio2" /> 일반
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio3" /> 총판정산
                            </td>
                            <td style={{ backgroundColor:"white"}}> </td>
                            <td style={{ backgroundColor:"white"}}>
                                <ArrowsExpand />마진 :
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio1" /> 적은순
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio2" /> 많은순
                            </td>
                            <td style={{ backgroundColor:"white"}}> </td>
                            <td style={{ backgroundColor:"white"}}>
                                <ArrowsExpand />충전금 :
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio1" /> 적은순
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio2" /> 많은순
                            </td>
                            <td style={{ backgroundColor:"white"}}> </td>
                            <td style={{ backgroundColor:"white"}}>
                                <ArrowsExpand />충전횟수 :
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio1" /> 적은순
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio2" /> 많은순
                            </td>
                            <td style={{ backgroundColor:"white"}}> </td>
                            <td style={{ backgroundColor:"white"}}>
                                <ArrowsExpand />환전금 :
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio1" /> 적은순
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio2" /> 많은순
                            </td>
                            <td style={{ backgroundColor:"white"}}> </td>
                            <td style={{ backgroundColor:"white"}}>
                                <ArrowsExpand />환전횟수 :
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio1" /> 적은순
                                <input className="form-check-input" type="radio" name="inlineRadio2" id="inlineRadio2" /> 많은순
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} >[충전 : {initialState.sum.depositAmtSum} 원 / {initialState.sum.depositcntSum} 건]</font>
                    &nbsp;
                    <font color={"red"} >[환전 : {initialState.sum.withdrawAmtSum} 원 / {initialState.sum.withdrawCntSum} 건]</font>
                    &nbsp;
                    <font color={initialState.sum.marginAmtSumColor} >[마진 : {initialState.sum.marginAmtSum} 원]</font>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th style={{width: "4%"}}>순위</th>
                            <th style={{width: "10%"}}>도메인</th>
                            <th style={{width: "10%"}}>가맹</th>
                            <th style={{width: "10%"}}>아이디</th>
                            <th style={{width: "10%"}}>닉네임</th>
                            <th style={{width: "10%"}}>회원분류</th>
                            <th style={{width: "15%"}}>충전</th>
                            <th style={{width: "15%"}}>환전</th>
                            <th style={{width: "15%"}}>마진</th>
                        </tr>
                        </thead>
                        {initialState.totalList && initialState.totalList.length > 0 ?
                            initialState.gridList?.map((List, index) => (
                                <tbody key={List.no}>
                                <tr>
                                    <td align={"center"}>{List.no}</td>
                                    <td align={"center"}>{List.domain}</td>
                                    <td align={"center"}>{List.mrchCdNm}</td>
                                    <td align={"center"} style={{backgroundColor:"blue"}} ><font color="yellow">{List.id}</font></td>
                                    <td align={"center"} style={{backgroundColor:"blue"}} ><font color="yellow">{List.nickname}</font></td>
                                    <td align={"center"}>{List.userGbCdNm}</td>
                                    <td align={"center"}><font color="blue">{List.depositAmt} / {List.depositcnt}</font>회</td>
                                    <td align={"center"}><font color="red">{List.withdrawAmt} / {List.withdrawCnt}</font>회</td>
                                    <td align={"center"}><font color={List.marginColor}>{List.marginAmt}</font></td>
                                </tr>
                                </tbody>
                            )) :
                            <tbody>
                            <tr>
                                <td colSpan= "15">조회된 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
                <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                    {initialState.totalCount && initialState.totalCount > 0 ?
                        <Pagination totalCount={initialState.totalCount} pageSize={initialState.input.pageSize} currentPage={initialState.input.page} onPageChange={handlePageChange} />
                        :""}
                </div>
            </div>
        </div>


    )
}
export default Member_stat;