import {createSlice} from "@reduxjs/toolkit"

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        isLoading: false,
        error: null,
        admins: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
    },
    reducers: {
        getAdmins(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
        },
        failedGetAdmins(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setAdmins(state, action) {
            state.isLoading = false;
            state.admins = action.payload.result;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            console.log(state.page)
            console.log(action.payload.totalCount)
            state.totalCount = action.payload.totalCount;
        },
    }
});

export const { getAdmins, failedGetAdmins, setAdmins } = adminSlice.actions;

export default adminSlice.reducer;