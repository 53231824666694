import {createSlice} from "@reduxjs/toolkit";


export const moneyInfoSlice = createSlice({
    name: "moneyInfo",
    initialState: {
        isLoading: false,
        error: null,
        moneyInfo: null
    },
    reducers: {
        getMoneyInfo(state, action) {
            state.isLoading = true;
        },
        setMoneyInfo(state, action) {
            state.isLoading = false;
            state.moneyInfo = action.payload.result
        },
        failedGetMoneyInfo(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
    }
});

export const {getMoneyInfo, setMoneyInfo, failedGetMoneyInfo} = moneyInfoSlice.actions

export default moneyInfoSlice.reducer;
