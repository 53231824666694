import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCustomerDetail, setReply} from "../../redux/reducers/board/CustomerDetailReducer";
import axios from "axios";
import moment from "moment";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
import {NumericFormat} from "react-number-format";


const CustomerDetailContainer = () => {

    const dispatch = useDispatch();
    const state = useSelector((state) => state.customerDetail);
    const userInfoId = useSelector((state) => state.userInfo.id);
    const [isOpenUserAgent, setIsOpenUserAgent] = useState(false)

    const [templates, setTemplates] = useState([])
    useEffect(() => {
        getTemplates()
    }, [])

    //TODO 고객 삭제
    const replyHandler = (e) =>{
        dispatch(setReply(e.currentTarget.value))
    }
    const insertReply = (e) =>{

        if( state.replies.length > 0 )
        {
            alert("이미 답글이 달린 문의글입니다.\n기존 댓글을 삭제해야 추가 댓글을 달 수 있습니다. ");
            return;
        }

        if(state.reply in [null, "", undefined]) {
            alert('내용이 비었습니다.')
        }
        else {
            axios.post('/admin/board/insertReply', {
                adminID : userInfoId,
                replyCont: state.reply,
                csNo: state.customer.no
            }).then((res) => {
                if(res.data.result) {
                    alert("댓글등록완료")
                    window.location.reload();
                }
                else {
                    alert('댓글달기 실패')
                }
            })
        }
    }
    const deleteReply = (replyNo, subNo) =>{
        if(replyNo <= 0) {
            alert('잘못된 삭제입니다.')
        }
        else {

            axios.post('/admin/board/deleteReply', {
                adminID : userInfoId,
                replyNo: replyNo,
                subNo: subNo
            }).then((res) => {
                if(res.data.result) {
                    alert("삭제완료")
                    window.location.reload();
                }
                else {
                    alert('이미 삭제됬거나 삭제실패입니다.')
                }
            })
        }
    }

    const titleChange = (e, index) => {
        const item = {...templates[index]}
        let arr = [...templates]
        item.title = e.currentTarget.value
        arr[index] = item
        setTemplates(arr)
    }
    const contChange = (e, index) => {
        const item = {...templates[index]}
        const arr = [...templates]
        item.cont = e.currentTarget.value
        arr[index] = item
        setTemplates(arr)
    }

    const getTemplates = () => {
        axios.get('/admin/board/getTemplates').then((res) => {
            if(res.data.result.length > 0) {
                setTemplates(res.data.result)
            }
        })
    }
    const insertEmptyTemplate = () => {
        axios.post('/admin/board/insertTemplates', {
            reg_id: userInfoId,
            upd_id: userInfoId,
        }).then((res) => {
            if(res.data.isInserted) {
                alert("추가완료")
                getTemplates()
                window.location.reload();
            }
        })
    }
    const updateTemplate = (index) => {
        const changedItem = templates[index]
        axios.post('/admin/board/updateTemplate', {
            no: changedItem.no,
            upd_id: userInfoId,
            title: changedItem.title,
            cont: changedItem.cont,
        }).then((res) => {
            if(res.data.isUpdated) {
                alert("수정완료")
                getTemplates()
                window.location.reload();
            }
        })
    }
    const deleteTemplate = (index) => {
        const changedItem = templates[index]
        axios.post('/admin/board/deleteTemplate', {
            no: changedItem.no,
            adminId: userInfoId
        }).then((res) => {
            if(res.data.isDeleted) {
                alert("삭제완료")
                getTemplates()
            }
        })
    }

    const sequenceSaveHandler = () => {
        const seqList = []
        for(const item of templates) {
            seqList.push({
                no: item.no,
                seq: item.seq
            })
        }
        axios.post('/admin/board/saveTemplates', {
            seqList: seqList,
        }).then((res) => {
            if(res.data.isUpdated) {
                alert("저장완료")
                getTemplates()
                window.location.reload();
            }
        })
    }

    const upHandler = (index) => {
        if(index > 0) {
            const upItem = {...templates[index]}
            const changeItem = {...templates[index - 1]}
            let arr = [...templates]
            const upSeq = upItem.seq
            upItem.seq = changeItem.seq
            changeItem.seq = upSeq
            arr[index] = changeItem
            arr[index - 1] = upItem
            setTemplates(arr)
        }
    }

    const downHandler = (index) => {
        if(index < templates.length - 1) {
            const downItem = {...templates[index]}
            const changeItem = {...templates[index + 1]}
            let arr = [...templates]
            const downSeq = downItem.seq
            downItem.seq = changeItem.seq
            changeItem.seq = downSeq
            arr[index] = changeItem
            arr[index + 1] = downItem
            setTemplates(arr)
        }
    }

    const applyTemplate = (index) => {
        const templateCont = templates[index].cont
        const reply = "안녕하세요, " + state.customer.nickname + " 회원님, 운영진입니다.\n"

        dispatch(setReply(reply + templateCont))
    }

    const directInsert = (index) => {
        const templateCont = templates[index].cont
        const hi = "안녕하세요, " + state.customer.nickname + " 회원님, 운영진입니다.\n"
        const reply = hi + templateCont
        dispatch(setReply(reply))

        if(window.confirm(`아래 내용으로 바로 댓글을 다시겠습니까?\n\n
        -----------------------------------------------------------------
        ${reply}
        `)) {
            if(reply in [null, "", undefined]) {
                alert('내용이 비었습니다.')
            }
            else {
                axios.post('/admin/board/insertReply', {
                    adminID : userInfoId,
                    replyCont: reply,
                    csNo: state.customer.no
                }).then((res) => {
                    if(res.data.result) {
                        alert("댓글등록완료")
                        window.location.reload();
                    }
                    else {
                        alert('댓글달기 실패')
                    }
                })
            }
        }
    }

    return <div className="common">
        <p>
            <span style={{fontWeight: "bold"}}>고객센터 글 읽기</span>
        </p>
        <table style={{backgroundColor: "white"}} className="table table-bordered table-sm text-center align-middle">
            <tbody>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>siteCode</td>
                <td className="text-start" colSpan="3">{state.customer?.site_cd}</td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>그룹</td>
                <td colSpan="3">{state.customer?.user_group_cd}</td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>글제목</td>
                <td className="text-start" ><span style={{color: "white", backgroundColor: "red", padding: "2px"}}>unb</span>{state.customer?.title}</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>조회수</td>
                <td className="text-start" >{state.customer?.read_cnt}</td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>소속</td>
                <td className="text-start"  colSpan="3"><span style={{fontWeight: "bold", fontSize: "15px"}}>{state.customer?.mrch_cd}</span></td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>작성자</td>
                <td className="text-start" style={{backgroundColor: state.customer?.back_ground_color}}><span style={{color: state.customer?.font_color, cursor: "pointer"}}onClick={(e) => {
                    dispatch(openUserSimpleInfo({
                        isOpen: true,
                        openId: state.customer?.reg_id,
                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                    }))
                }}>{state.customer?.pnt_gd_cd} {state.customer?.nickname}({state.customer?.reg_id})</span>{state.customer?.domain}</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>가입일</td>
                <td className="text-start" >{state.customer?.entr_dttm}</td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>마지막충전</td>
                <td className="text-start"  colSpan="3" style={{fontSize:"10px"}}><span style={{color: "#1F68EE",}}>{state.customer?.lately_deposit}</span>
                    &nbsp;/ <span style={{color: "#1F68EE",}}>
                        <NumericFormat value={state.customer?.last_deposit_amt} displayType={'text'} thousandSeparator={true}/>
                        </span>
                </td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>충전</td>
                <td className="text-start"  colSpan="3" style={{fontSize:"10px"}}><span style={{color: "#1F68EE",}}>
                    <NumericFormat value={state.customer?.deposit_amt} displayType={'text'} thousandSeparator={true}/>
                </span> (<span style={{color: "#1F68EE"}}>{state.customer?.deposit_cnt}</span>회)</td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>환전</td>
                <td className="text-start"  colSpan="3" style={{fontSize:"10px"}}><span style={{color: "red",}}>-
                    <NumericFormat value={state.customer?.withdraw_amt} displayType={'text'} thousandSeparator={true}/>
                </span> (<span style={{color: "#1F68EE"}}>{state.customer?.withdraw_cnt}</span>회)</td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>마진</td>
                <td className="text-start"  colSpan="3" style={{fontSize:"10px"}}><span style={{color: "red",}}>
                    <NumericFormat value={state.customer?.profit_amt} displayType={'text'} thousandSeparator={true}/>
                </span></td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>입금방식</td>
                <td className="text-start"  colSpan="3" style={{fontSize:"11px"}}><span style={{color: "#5dc434",}}>{state.customer?.rcpt_meth_cd}</span></td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>태그</td>
                <td className="text-start"  colSpan="3">
                    {state.customer?.addn_info_tag}
                </td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>내용</td>
                <td className="text-start"  colSpan="3">
                    {state.customer?.cont}
                </td>
            </tr>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>작성일</td>
                <td className="text-start"  colSpan="3">{state.customer?.createdAt}</td>
            </tr>
            </tbody>
        </table>

        <div style={{backgroundColor: "white"}}>
            <span style={{color: "red"}}>User Agent 정보</span>
            <table className="table table-bordered table-sm text-center align-middle">
                <tbody>
                <tr>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>글 작성 IP</td>
                    <td>{state.customer?.use_ip}</td>
                </tr>
                <tr>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>90일 이내 동일 agent 로그인</td>
                    <td>{`${state.customer?.same_agent}회 (${Math.round(((state.customer?.same_agent * 1.00) / state.customer?.total_agent) * 10000) / 100}%)`}</td>
                </tr>
                <tr>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>90일 이내 다른 agent 로그인</td>
                    {state.customer?.different_agent > 0? (
                        <td style={{backgroundColor: "yellow", color: "red"}}>
                            {`${state.customer?.different_agent}회 (${Math.round(((state.customer?.different_agent * 1.00) / state.customer?.total_agent) * 10000) / 100}%)`} (다른 agent 로그인이 있습니다. 주의하시기 바랍니다)
                        </td>
                    ) : (<td>
                        {state.customer?.different_agent}회
                    </td>)}

                </tr>
                <tr>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>90일 이내 사용 agent 수</td>
                    <td>{state.customer?.agent_count}개</td>
                </tr>
                <tr>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>90일 이내 다른 agent 최종 로그인</td>
                    <td>{state.customer?.recently_date}</td>
                </tr>
                </tbody>
            </table>

        </div>
        <div className="text-center">
            {isOpenUserAgent? (
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead>
                    <tr>
                        <td>최종 로그인 시각</td>
                        <td>90일 이내 사용 횟수</td>
                        <td>User Agent</td>
                    </tr>
                    </thead>
                    <tbody>
                    {state.agentList?.map((agent, aIndex) => (
                        <tr style={{backgroundColor: state.customer?.use_user_agent === agent.br_user_agent? "yellow": "white"}}>
                            <td>
                                {state.customer?.use_user_agent === agent.br_user_agent && (
                                    <>
                                        <span style={{fontWeight: "bold"}}>[글작성시]</span>
                                        <br />
                                    </>
                                )}
                                {agent.login_dt}</td>
                            <td>{agent.use_count + `(${(agent.use_count/state.totalAgent * 100).toFixed(2)}%)`}</td>
                            <td>{agent.br_user_agent}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            ) : (
                <button style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "50px", width: "fit-content"}} onClick={(e) => {
                    setIsOpenUserAgent(true)
                }}>User Agent 목록 보기</button>
            )}

        </div>
        <div className="text-center mt-3">
            {state.betList.length > 0? (
                state.betList.map((item, index) => (
                    <div className="my-3">
                        <table className="table table-borderlesstable-sm text-center align-middle m-0">
                            <thead>
                            <tr className="table-dark">
                                <td style={{ width: "110px"}}>리그명</td>
                                <td style={{ width: "110px"}}>경기일시</td>
                                <td>승(home)</td>
                                <td style={{ width: "70px"}}>무</td>
                                <td>패(away)</td>
                                <td style={{ width: "60px"}}>점수</td>
                                <td style={{ width: "60px"}}>결과</td>
                            </tr>
                            </thead>
                            <tbody>
                            {item.details.map((detail, dIndex) => (
                                <tr>
                                    <td>
                                        <div style={{border: "1px solid #d7cc91", height: "50px", borderRadius: "5px", paddingTop: "12px"}}>
                                            수동등록리그
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{border: "1px solid #d7cc91", height: "50px", borderRadius: "5px", paddingTop: "12px"}}>
                                            {moment(detail.start_date).format('MM/DD H:mm')}
                                        </div>

                                    </td>
                                    <td style={{cursor: "pointer"}}>
                                        <div className="d-flex px-2 align-middle" style={{
                                            border: "1px solid #d7cc91", height: "50px", borderRadius: "5px", paddingTop: "12px",
                                            backgroundColor: detail.bet_no === 1? "#d7cc91" : "#FFFFFF"
                                        }}>
                                            <div style={{height:"fit-content", marginRight: "auto"}}>{detail.home}</div>
                                            <div style={{height:"fit-content"}}>{detail.bet1.toFixed(2)}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{border: "1px solid #d7cc91", height: "50px", borderRadius: "5px", paddingTop: "12px"}}>
                                            VS
                                        </div>
                                    </td>
                                    <td style={{cursor: "pointer"}}>
                                        <div className="d-flex px-2 align-middle"
                                             style={{
                                                 border: "1px solid #d7cc91", height: "50px", borderRadius: "5px", paddingTop: "12px",
                                                 backgroundColor: detail.bet_no === 3? "#d7cc91" : "#FFFFFF"
                                             }}>
                                            <div style={{height:"fit-content", marginRight: "auto"}}>{detail.bet3.toFixed(2)}</div>
                                            <div style={{height:"fit-content"}}>{detail.away}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{border: "1px solid #d7cc91", height: "50px", borderRadius: "5px", paddingTop: "12px"}}>
                                            {`${detail.score_home} : ${detail.score_away}`}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{border: "1px solid #d7cc91", height: "50px", borderRadius: "5px", paddingTop: "12px"}}>
                                            {detail.result}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <div className="d-flex text-center" style={{
                            backgroundColor: "#d7cc91"
                        }}>
                            <p style={{width: "200px"}}>{`베팅시간 : ${moment(item.createdAt).format('MM/DD H:mm')}`}</p>
                            <p style={{width: "200px"}}>{`베팅금액 : ${item.money}`}</p>
                            <p style={{width: "200px"}}>{`배당률 : ${item.total_ratio}`}</p>
                            <p style={{width: "200px"}}>{`예상 적중금액 : ${item.win_money}`}</p>
                            <p style={{width: "200px"}}>{`적중금액 : ${item.result_money}`}</p>
                            <p style={{width: "100px"}}>{item.result === 1? "당첨" : (item.result === 2? "미당첨" : "진행중")}</p>
                        </div>

                    </div>
                ))
            ) : (
                <p>
                    <span style={{fontWeight: "bold"}}>베팅내역이 없습니다.</span>
                </p>
            )}

        </div>
        {state.replies.length > 0?
            (
                <>
                    {state.replies.map((reply, index) => (
                        <div style={{backgroundColor: "white"}}>
                            <p>
                                [7]<span style={{fontWeight: "bold"}}> 운영자 </span>( ID : {reply.reg_id} )  {reply.createdAt}
                            </p>
                            <div className="text-start" style={{height: "fit-content", width: "100%", border: "lightgray solid 1px"}}>
                                <p>
                                    {reply.cont}
                                </p>
                                <div className="text-end">
                                    <button style={{ backgroundColor: "red", color: "white" , fontSize: "9px", border: "none"}} onClick={(e) => {
                                        deleteReply(reply.no, reply.sub_no)
                                    }}>삭제</button>
                                </div>

                            </div>
                        </div>
                    ))}

                </>
            )
            : (<></>)}

        <div className="my-2" style={{display: "flex"}}>
            <textarea style={{width: "100%", height: "250px", padding: "5px"}} value={state.reply} onChange={replyHandler} />
            <button className="mx-3" style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", height: "250px", width: "70px", color: "white", fontWeight: "bold"}} onClick={insertReply}>댓글달기</button>
        </div>
        <div className="my-2">
            <p>
                <span style={{fontWeight: "bold"}}>댓글 템플릿</span>
            </p>
            <div style={{display: "flex"}}>
                <button className="mx-3" style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", height: "50px", width: "50%", color: "white", fontWeight: "bold"}}
                        onClick={insertEmptyTemplate}
                >템플릿 추가</button>
                <button className="mx-3" style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", height: "50px", width: "50%", color: "white", fontWeight: "bold"}}
                        onClick={sequenceSaveHandler}
                >템플릿 순서 저장</button>
            </div>
        </div>
        <div>
            {templates.length > 0 && templates.map((item, index) => (
                <>
                    <div className="m-1" style={{display: "flex"}}>
                        <button data-bs-toggle="collapse" data-bs-target={`#collap_temp_${index}`} aria-expanded="false" aria-controls={`collap_temp_${index}`} className="mx-1" style={{ backgroundColor: "darkolivegreen", border: "1px solid black", fontSize: "9px", height: "40px", width: "fit-content", color: "white", fontWeight: "bold"}}>펴기/접기</button>
                        <input type="text" style={{backgroundColor: "red", color: "yellow", fontSize: "9px"}}
                               value={item.title}
                               onChange={(e) => {
                                   titleChange(e, index)
                               }}/>

                        <button className="mx-1" onClick={(e) => {
                            applyTemplate(index)
                        }} style={{ backgroundColor: "skyblue", border: "1px solid black", fontSize: "9px", height: "40px", width: "70px", fontWeight: "bold"}}>사용</button>
                        <button className="mx-1" onClick={(e) => {
                            directInsert(index)
                        }} style={{ backgroundColor: "skyblue", border: "1px solid black", fontSize: "9px", height: "40px", width: "70px", fontWeight: "bold"}}>바로댓글달기</button>
                    </div>
                    <div className="collapse px-2" id={`collap_temp_${index}`}>
                        <div style={{ display: "flex"}}>
                            <textarea style={{width: "fit-content", height: "80px", padding: "5px", flexGrow: "1", color: "white", backgroundColor: "darkgray"}} value={item.cont} onChange={(e) => {
                                contChange(e, index)
                            }}/>
                            <button className="mx-1" style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", height: "80px", width: "30px", color: "white", fontWeight: "bold"}}
                                    onClick={(e) => {
                                        updateTemplate(index)
                                    }}>수정</button>
                            <button className="mx-1" onClick={(e) => {
                                deleteTemplate(index)
                            }} style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", height: "80px", width: "30px", color: "white", fontWeight: "bold"}}>삭제</button>
                            {index === 0? (<></>) : (
                                <button className="mx-1" onClick={(e) => {
                                    upHandler(index)
                                }} style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", height: "80px", width: "fit-content", color: "white", fontWeight: "bold"}}>↑</button>
                            )}
                            {index === templates.length - 1? (<></>) : (
                                <button className="mx-1" onClick={(e) => {
                                    downHandler(index)
                                }} style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "9px", height: "80px", width: "fit-content", color: "white", fontWeight: "bold"}}>↓</button>
                            )}
                            <select className="form-select" aria-label="Default select example" style={{height: "80px", width: "80px", backgroundColor: "#1F68EE", color: "white"}}>
                                <option selected>1번 색</option>
                                <option value="1">2번 색</option>
                            </select>
                        </div>

                    </div>
                </>
            ))}



        </div>
    </div>
}

export default CustomerDetailContainer