import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    setQuery,
    setAddrow,
    getEtcSettingListReducer,
    setV1,
    setEndDt,
    setEtc,
    setEndTime,
    setStartDt,
    setStartTime,
    setText,
    setV4,
    setV5,
    setV2,
    setV3,
    setImageUrl
} from "../../redux/reducers/setting/EtcSettingListReducer";
import axios from "axios";
import Pagination from "../../components/common/Pagination2";
import imageCompression from "browser-image-compression";
import {insertAdminLog} from "../../lib/api";
const EtcSetting = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.EtcSettingListReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [uploads, setUploads] = useState([])

    const getList = () => {
        if(initialState.totalList !== null ) {
            dispatch(getEtcSettingListReducer({
                input:{
                    page : 1,
                    pageSize : initialState.input.pageSize
                }
            }))
        }
        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "기타값관리"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const reload = () =>{
        dispatch(getEtcSettingListReducer({
            input:{
                page : 1,
                pageSize : initialState.input.pageSize,
                query : initialState.input.query
            }
        }))
    }
    const changeQuery = (e) =>{
        dispatch(setQuery(e.target.value))
    }

    const inputPress = (e) => {
        if (e.key === 'Enter') {
            reload();
        }
    }

    const handleBlur = (e) =>{
        reload();
    }
    const setEtcHandle = (idx,val,stat) =>{
        dispatch(setEtc({idx:idx,val:val,_rowStat:stat}))
    }

    const setV1Handle = (idx,val,stat) =>{
        dispatch(setV1({idx:idx,val:val,_rowStat:stat}))
    }

    const setV2Handle = (idx,val,stat) =>{
        dispatch(setV2({idx:idx,val:val,_rowStat:stat}))
    }

    const setV3Handle = (idx,val,stat) =>{
        dispatch(setV3({idx:idx,val:val,_rowStat:stat}))
    }

    const setV4Handle = (idx,val,stat) =>{
        dispatch(setV4({idx:idx,val:val,_rowStat:stat}))
    }

    const setV5Handle = (idx,val,stat) =>{
        dispatch(setV5({idx:idx,val:val,_rowStat:stat}))
    }

    const setTextHandle = (idx,val,stat) =>{
        dispatch(setText({idx:idx,val:val,_rowStat:stat}))
    }

    const setStartDtHandle = (idx,val,stat) =>{
        dispatch(setStartDt({idx:idx,val:val,_rowStat:stat}))
    }

    const setStartTimeHandle = (idx,val,stat) =>{
        dispatch(setStartTime({idx:idx,val:val,_rowStat:stat}))
    }

    const setEndDtHandle= (idx,val,stat) =>{
        dispatch(setEndDt({idx:idx,val:val,_rowStat:stat}))
    }

    const setEndTimeHandle = (idx,val,stat) =>{
        dispatch(setEndTime({idx:idx,val:val,_rowStat:stat}))
    }

    const deleteRow = (no) => {
        axios.post('/admin/setting/deleteEtcSetting', {no : no}).
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }
    const insertUpdate = (idx,stat) =>{
        if(stat === 'X'){
            alert("변경사항이 없습니다.");
            return false;
        }
        console.log("##userInfo.reg_id##",userInfoId)
        const _input = { ...initialState.gridList[idx], reg_id: userInfoId , upd_id : userInfoId};

        axios.post('/admin/setting/insertUpdateEtcSetting',{_input}).
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }

    const addRow = () =>{
        dispatch(setAddrow())
    }

    const saveAll = () => {
        axios.post('/admin/setting/saveEtcSettingList',initialState.gridList).
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }

    const handlePageChange = (page) => {
        dispatch(getEtcSettingListReducer({
            input:{
                page: page,
                pageSize: initialState.input.pageSize,
                query : initialState.input.query
            }
        }))
    }


    const uploadImage = (index, file, stat) => {
        const data = new FormData();
        data.append("file", file)
        axios.post('/admin/board/upload', data)
            .then((res) => {
                dispatch(setImageUrl({idx:index,val:res.data.url,_rowStat:stat}))
            })
    }

    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">기타값관리</font>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"red"}>
                        * 이 설정값은 개발자에 의해 기본 세팅이 되어야 합니다. 따로 세팅이 없는 경우, 필요가 없는 경우 입니다.<br></br>
                        * 우선 순위 : 시작일이 있는 경우 시작일이 큰 경우 우선, 종료일이 있는 경우, 종료일이 큰 경우 우선, 나중 등록 우선<br></br>
                        * 즉, 같은 값이 있다면, 시작일이 없는 것보다 있는 쪽이 우선하고, 시작일이 모두 있을 경우, 가장 큰 값이 우선함.
                    </font>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"}  >
                        <tbody>
                        <tr>
                            <td align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    설명 검색&nbsp;
                                    <input value={initialState.input.query} onBlur={handleBlur} onKeyDown={inputPress} onChange={changeQuery} className="form-check-input sm-input" type="text" style={{ width: "220px" }}/>
                                </div>
                            </td>
                            <td align={"right"}>
                                <button onClick={addRow} style={{
                                    width: "100px", backgroundColor:"green"
                                }} type="submit" className="btn btn-primary" >신규 등록</button>
                                &nbsp;
                                <button onClick={saveAll} style={{
                                    width: "100px", backgroundColor:"green"
                                }} type="submit" className="btn btn-primary" >일괄 저장</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th style={{width: "5%"}}>고유번호</th>
                            <th style={{width: "10%"}}>이미지</th>
                            <th style={{width: "5%"}}>구분</th>
                            <th style={{width: "5%"}}>v1</th>
                            <th style={{width: "5%"}}>v2</th>
                            <th style={{width: "5%"}}>v3</th>
                            <th style={{width: "5%"}}>v4</th>
                            <th style={{width: "5%"}}>v5</th>
                            <th style={{width: "*%"}}>text</th>
                            <th style={{width: "5%"}}>시작일</th>
                            <th style={{width: "5%"}}>시작시</th>
                            <th style={{width: "5%"}}>종료일</th>
                            <th style={{width: "5%"}}>종료시</th>
                            <th style={{width: "10%"}}>수정/삭제</th>
                        </tr>
                        </thead>
                        {initialState.totalList && initialState.totalList.length > 0 ? initialState.gridList.map((List, index) => (
                                <tbody key={index}>
                                <tr>
                                    <td align={"center"}>{List.no?List.no:'추가'}</td>
                                    <td align={"center"}>
                                        <div className="d-flex">
                                            <img src={List.image_url}/>
                                            <input style={{
                                                width: "200px"
                                            }} type="file" className="form-control" id="search_edit"
                                                   onChange={(e) => {
                                                       uploadImage(index, e.currentTarget.files[0],List._rowStat)
                                                   }}/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid d-flex p-0 mx-1">
                                            <input onChange={(e)=> setEtcHandle(index,e.target.value,List._rowStat)} value={List.etc} className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="설명을 입력하세요"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setV1Handle(index,e.target.value,List._rowStat)} value={List.V1} className="form-check-input sm-input" type="text" style={{ width: "80px" }} placeholder="value 1"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setV2Handle(index,e.target.value,List._rowStat)} value={List.V2} className="form-check-input sm-input" type="text" style={{ width: "80px" }} placeholder="value 2"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setV3Handle(index,e.target.value,List._rowStat)} value={List.V3} className="form-check-input sm-input" type="text" style={{ width: "80px" }} placeholder="value 3"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setV4Handle(index,e.target.value,List._rowStat)} value={List.V4} className="form-check-input sm-input" type="text" style={{ width: "80px" }} placeholder="value 4"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setV5Handle(index,e.target.value,List._rowStat)} value={List.V5} className="form-check-input sm-input" type="text" style={{ width: "80px" }} placeholder="value 5"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <textarea onChange={(e)=> setTextHandle(index,e.target.value,List._rowStat)} value={List.text} name="textarea_name" cols="40" rows="3"/>
                                    </td>
                                    <td align={"center"}>
                                        <input onChange={(e)=> setStartDtHandle(index,e.target.value,List._rowStat)} value={List.start_dt} style={{
                                            width: "100px"
                                        }} type="text" className="form-control" id="search_edit" placeholder="예 : 20200101"
                                               onBlur={(e) => {
                                               }}/>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setStartTimeHandle(index,e.target.value,List._rowStat)} value={List.start_time} className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="예 : 1130"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setEndDtHandle(index,e.target.value,List._rowStat)} value={List.end_dt} className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="예 : 20200101"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input onChange={(e)=> setEndTimeHandle(index,e.target.value,List._rowStat)} value={List.end_time} className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="예 : 1130"/>
                                        </div>
                                    </td>
                                    <td align={"center"}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <button onClick={()=>insertUpdate(index,List._rowStat)} style={{ backgroundColor: List._rowStat==='I'?'#5dc434':'deepskyblue', border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>{List._rowStat==='I'?'추가':'변경'}</button>
                                            <button onClick={()=>deleteRow(List.no)} style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>삭제</button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            )) :
                            <tbody>
                            <tr>
                                <td colSpan= "14">조회된 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalCount && initialState.totalCount > 0 ?
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.input.pageSize} currentPage={initialState.input.page} onPageChange={handlePageChange} />
                    :""}
            </div>
        </div>
    )
}
export default EtcSetting;