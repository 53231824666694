import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    totalList : [],
    totalCount : 0,
    gridList : [],
    gridCount : 0,
    input:{
        page: 1,
        pageSize: 20,
        comboGb:'',
        query: '',
    }
}

export const statsSlice = createSlice({
    name: 'CompanySubListReducer',
    initialState,
    reducers: {
        getCompanySubListReducer(state, action){
            console.log("#reducers getCompanySubListReducer start#",
                action.payload.input.pageSize,
                action.payload.input.page)
            ;
            state.input.page = action.payload.input.pageSize === 999? 1: action.payload.input.page;
            state.input.pageSize = action.payload.input.pageSize;
        },
        setCompanySubListReducer(state, action) {
            console.log("#reducers setCompanySubListReducer start#",action.payload.gridList);
            if((state.input.page -1) * state.input.pageSize > action.payload.totalCount) {
                state.input.page = 1
            }
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.gridCount = action.payload.gridCount;
            state.gridList = action.payload.gridList;
        },
        setComboGb(state,action){
            state.input.comboGb = action.payload
        },
        setQuery(state,action){
            state.input.query = action.payload
        },
        failedGetCompanySubListReducer(state, action) {
            state.blackTypeCd = action.payload;
        },
    }
});

export const {
    getCompanySubListReducer,
    setCompanySubListReducer,
    failedGetCompanySubListReducer,
    setComboGb,
    setQuery
} = statsSlice.actions;

export default statsSlice.reducer;