import React, {useState} from 'react';
import {NumericFormat} from "react-number-format";
import common from "../../util/common";
import {getActiveMembers} from "../../redux/reducers/monitoring/ActiveMemberReducer";
import {useDispatch} from "react-redux";
import PlusMinusText from "../common/PlusMinusText";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";

const ActiveMemberList = ({state, onRefresh}) => {
    const dispatch = useDispatch();

    const [noSort, setNoSort] = useState(0)
    const [moneySort, setMoneySort] = useState(0)
    const [pointSort, setPointSort] = useState(0)
    const [casinoSort, setCasinoSort] = useState(0)
    const [lateDepositSort, setLateDepositSort] = useState(0)
    const [depositSort, setDepositSort] = useState(0)
    const [withdrawSort, setWithdrawSort] = useState(0)
    const [profitSort, setProfitSort] = useState(0)
    const [recentIPSort, setRecentIPSort] = useState(0)

    function getSortArrow(type) {
        switch (type) {
            case 0 : return " (-)"
            case 1 : return " (▲)"
            case 2 : return " (▼)"
            default : return " (-)"
        }
    }

    const getChangeSortValue = (sortValue) => {
        let changeSortValue = 0
        if(sortValue === 0) {
            changeSortValue = 1
        }
        else if(sortValue === 1) {
            changeSortValue = 2
        }
        else if(sortValue === 2) {
            changeSortValue = 1
        }
        return changeSortValue
    }

    function changeSortValue(sortColum, sortValue) {
        setNoSort(sortColum === "no"? getChangeSortValue(sortValue) : 0)
        setMoneySort(sortColum === "game_money_amt"? getChangeSortValue(sortValue) : 0)
        setPointSort(sortColum === "point"? getChangeSortValue(sortValue) : 0)
        setCasinoSort(sortColum === "kg_casino_money_amt"? getChangeSortValue(sortValue) : 0)
        setLateDepositSort(sortColum === "lately_deposit"? getChangeSortValue(sortValue) : 0)
        setDepositSort(sortColum === "deposit_amt"? getChangeSortValue(sortValue) : 0)
        setWithdrawSort(sortColum === "withdraw_amt"? getChangeSortValue(sortValue) : 0)
        setProfitSort(sortColum === "profit_amt"? getChangeSortValue(sortValue) : 0)
        setRecentIPSort(sortColum === "rcnt_actv_ip"? getChangeSortValue(sortValue) : 0)
        if(sortColum !== "" && sortColum !== undefined){
            dispatch(getActiveMembers({
                sortColum: sortColum,
                sortValue: sortValue,
                device: state.device,
                searchGb: state.searchGb,
                searchQuery: state.searchQuery,
                orgId: state.orgId,
                userGbCd: state.userGbCd,
                userStateCd: state.userStateCd,
                pGrade: state.pGrade,
            }))
        }
    }


    return  <div className="common">
        <table className="table table-bordered table-sm text-center align-middle">
            <thead>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>관심</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>순서</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF", cursor: 'pointer'}} onClick={(e) => {
                    changeSortValue("no", noSort)
                }}>번호<span>{getSortArrow(noSort)}</span></td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>SITE</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>도메인</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>이름</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>가맹점</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF", cursor: 'pointer'}} onClick={(e) => {
                    changeSortValue("game_money_amt", moneySort)
                }}>게임머니<span>{getSortArrow(moneySort)}</span></td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF", cursor: 'pointer'}} onClick={(e) => {
                    changeSortValue("point", pointSort)
                }}>포인트<span>{getSortArrow(pointSort)}</span></td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF", cursor: 'pointer'}} onClick={(e) => {
                    changeSortValue("kg_casino_money_amt", casinoSort)
                }}>kg카지노머니<span>{getSortArrow(casinoSort)}</span></td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>회원등급</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>회원상태</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>P등급</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>핸드폰</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>문자</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF", cursor: 'pointer'}} onClick={(e) => {
                    changeSortValue("lately_deposit", lateDepositSort)
                }}>마지막충전<span>{getSortArrow(lateDepositSort)}</span></td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF", cursor: 'pointer'}} onClick={(e) => {
                    changeSortValue("deposit_amt", depositSort)
                }}>충전<span>{getSortArrow(depositSort)}</span></td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF", cursor: 'pointer'}} onClick={(e) => {
                    changeSortValue("withdraw_amt", withdrawSort)
                }}>환전<span>{getSortArrow(withdrawSort)}</span></td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF", cursor: 'pointer'}} onClick={(e) => {
                    changeSortValue("profit_amt", profitSort)
                }}>마진<span>{getSortArrow(profitSort)}</span></td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>현재위치</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF", cursor: 'pointer'}} onClick={(e) => {
                    changeSortValue("rcnt_actv_ip", recentIPSort)
                }}>아이피<span>{getSortArrow(recentIPSort)}</span></td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>접속기기</td>
            </tr>
            </thead>
            <tbody>
            {state?.activeMembers && state.activeMembers.map((item, index) => (
                <tr style={{ backgroundColor: item.rcnt_actv_device_cd === '01'? "#FFE40055" : "#FFFFFF"}}>
                    <td><input type="checkbox" checked={item.interest_cd}/></td>
                    <td>{index + 1}</td>
                    <td>{item.no}</td>
                    <td style={{backgroundColor: "red"}}><span style={{color: "white"}}>{item.site_cd}</span></td>
                    <td>{item.reg_domain_nm}</td>
                    <td style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                        dispatch(openUserSimpleInfo({
                            isOpen: true,
                            openId: item.id,
                            xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                        }))
                    }}><span style={{ color: item.font_color }}>{item.id}</span></td>
                    <td style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                        dispatch(openUserSimpleInfo({
                            isOpen: true,
                            openId: item.id,
                            xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                        }))
                    }}><span style={{ color: item.font_color }}>{item.nickname}</span></td>
                    <td style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                        dispatch(openUserSimpleInfo({
                            isOpen: true,
                            openId: item.id,
                            xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                        }))
                    }}><span style={{ color: item.font_color }}>{item.name}</span></td>
                    <td>{item.org_admin_id}</td>
                    <td><PlusMinusText number={item.game_money_amt} /></td>
                    <td><PlusMinusText number={item.point} /></td>
                    <td><PlusMinusText number={item.kg_casino_money_amt} /></td>
                    <td><span style={{ color: "#5dc434" }}>{item.user_gb_cd}</span></td>
                    <td><span style={{ color: "#5dc434" }}>{item.user_state_cd}</span></td>
                    <td><span style={{ color: "#1F68EE" }}>{item.pnt_gd_cd}</span></td>
                    <td><button style={{ color: "orange" }}>보기</button></td>
                    <td>{item.sms_yn}</td>
                    <td>{common.getRemainTime(item.lately_deposit)}</td>
                    <td><PlusMinusText number={item.deposit_amt} /></td>
                    <td><PlusMinusText number={item.withdraw_amt * -1} /></td>
                    <td><PlusMinusText number={item.profit_amt} /></td>
                    <td>메인화면</td>

                    <td><span style={{ color: "#1F68EE" }}>{item.rcnt_actv_ip}</span><br/>{item.recent_country}</td>
                    <td><span style={{ color: "#5dc434" }}>{item.rcnt_actv_device_cd === "01"? "PC" : "Mobile"}</span></td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>

}


export default ActiveMemberList;