import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination2";
import {getMoneyStat} from "../../redux/reducers/deposit/MoneyStatReducer";
import MoneyStat from "../../components/deposit/MoneyStat";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import {getMoneyList} from "../../redux/reducers/deposit/MoneyListReducer";
import OrgSelect from "../../components/org/OrgSelect";
import {setSelectOrgId} from "../../redux/reducers/user/MessageReducer";
import {getDepositWithdrawCombine} from "../../redux/reducers/deposit/DepositWithdrawCombineReducer";
import {insertAdminLog} from "../../lib/api";

const MoneyStatContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.moneyStat)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [userGbCd, setUserGbCd] = useState("");

    const [pntGbList, setPntGbList] = useState([]);
    const [searchCd01, setSearchCd01] = useState(); //총판/가맹점 검색



    const getCmmnCd = () => {
        if(pntGbList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0004',
            }).then((res) => {
                setPntGbList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getMoneyStat({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "머니내역통계[전체]"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getMoneyStat({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getMoneyStat({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getMoneyStat({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getMoneyStat({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }


    const exportExcel = ()=>{

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {


            let jsonObject = { // JSON 객체를 생성합니다.
                구분: initialState.totalList[i].log_gb_cd? initialState.totalList[i].log_gb_cd :'',
                합계: initialState.totalList[i].result_amt? initialState.totalList[i].result_amt :'',
                횟수: initialState.totalList[i].result_cnt? initialState.totalList[i].result_cnt :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '머니내역통계');
        XLSX.writeFile(workBook, '머니내역통계.xlsx');
    }

    const userGbCdChangeHandler = (e) => {
        alert('userGbCd000 >>>>>>>>>>>> '+e.currentTarget.value);
        setUserGbCd(e.currentTarget.value);

        alert('userGbCd >>>>>>>>>>>> '+userGbCd);
        onRefresh();
    }

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const add7Date = () => {
        setStartDate(moment().add(-7, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add1Date = () => {
        setStartDate(moment().add(-1, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add0Date = () => {
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }

    const add10Date = () => {
        setStartDate(moment().format('YYYY-MM')+"-01");
        setEndDate(moment().format('YYYY-MM')+"-10");
    }

    const add20Date = () => {
        setStartDate(moment().format('YYYY-MM')+"-11");
        setEndDate(moment().format('YYYY-MM')+"-20");
    }

    const add30Date = () => {
        setStartDate(moment().format('YYYY-MM')+"-21");
        setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
    }


    const pntGbCdChangeHandler = (e) => {
        setUserGbCd(e.currentTarget.value);
        dispatch(getMoneyStat({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: e.currentTarget.value,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01
        }))
    }

    const setParentParam = (id) =>{
        setSearchCd01(id)
        dispatch(setSelectOrgId(id))
        dispatch(getMoneyStat({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: id
        }))
    }



    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <div className="commonNew">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">머니 지급 통계</font>
                    <table   width={"93%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                            </td>
                        </tr>
                    </table>

                </div>
                <font color="red">*실회원에게 지급된 머니만 홥산됩니다.(가라유저 등 제외)</font>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray" , width:"1100px"
                    }}>

                        <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}} onClick={exportExcel}>엑셀 다운로드</button>
                        <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add7Date}>일주일</button>
                        <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add1Date}>어제</button>
                        <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add0Date}>오늘</button>
                        &nbsp;&nbsp;
                        <input className="form-control sm-date" type="date" value={startDate} onChange={startDateHandler} style={{width:"110px"}}/>
                        &nbsp;~&nbsp;
                        <input className="form-control sm-date" type="date" value={endDate} onChange={endDateHandler} style={{width:"110px"}}/>
                        &nbsp;

                        <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>이번 정산일 기준</button>
                        <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>지난 정산일 기준</button>
                        <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add10Date}>1일~10일</button>
                        <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add20Date}>11일~20일</button>
                        <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add30Date}>21일~말일</button>
                    </div>

                </div>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray" , width:"1100px"
                    }}>
                        검색
                        <div className="container-fluid p-0 mx-1" style={{
                            display: "flex",border:"1px solid lightgray", width:"320px", height:"23px"
                        }}>
                            <div style={{zIndex : 999}}>
                                <OrgSelect setChildParam = {setParentParam} id = {1}/>
                            </div>
                        </div>
                        &nbsp;
                        <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" /> 하위 포함 검색
                        &nbsp;
                        <select
                            className="form-select sm-select"
                            defaultValue={'01'}
                            style={{
                                width: "100px"
                            }}
                            onChange={pntGbCdChangeHandler} value={userGbCd}
                        >
                            <option key={''} value={''}>전체 P등급</option>
                            {pntGbList.map((item, index) => (
                                <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                            ))}
                        </select>
                        &nbsp;
                        <select
                            className="form-select sm-select"
                            defaultValue={'1'}
                            style={{
                                width: "100px"
                            }}
                            value={initialState.searchGb === ""? "0" : initialState.searchGb}
                            onChange={e =>
                                dispatch(getMoneyStat({
                                    page: initialState.page,
                                    pageSize: initialState.pageSize,
                                    order: initialState.order,
                                    query: initialState.query,
                                    startDate: startDate? startDate : initialState.startDate,
                                    endDate: endDate? endDate : initialState.endDate,
                                    userGbCd: e.currentTarget.value,
                                    searchGbCd: initialState.searchGbCd,
                                    searchCd01: initialState.searchCd01
                                }))
                            }
                        >
                            <option value={'1'}>닉네임</option>
                            <option value={'2'}>아이디</option>
                            <option value={'3'}>가맹점명</option>
                            <option value={'4'}>관련번호</option>
                            <option value={'5'}>전체</option>
                        </select>
                        &nbsp;
                        <input style={{
                            width: "220px"
                        }} type="text" className="form-check-input sm-input" id="search_edit" placeholder="검색조건을 입력하세요." onBlur={(e) => {
                            changeQuery(e.target.value)
                        }} onKeyDown={inputPress} />
                    </div>
                </div>

            </div>
        </div>
        <MoneyStat moneyStat={initialState.moneyStat} onRefresh={onRefresh} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default MoneyStatContainer