import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {Route, useLocation, useNavigate, useParams} from "react-router-dom";
import PartnerWithdrawInfo from "./WithdrawInfo";
import PartnerLosingInfo from "./LosingInfo";
import PartnerRollingInfo from "./RollingInfo";
import { MultiSelect } from "react-multi-select-component";


import {
    resetState,
    setBfOrgGbCd,
    setCmmnCd,
    setComboOrgList,
    setComboOrgList2,
    setDtl,
    setEmail,
    setGb,
    setId,
    setIdCheckYn,
    setIdDupYn,
    setMbnoCenter,
    setMbnoEnd,
    setMbnoStart,
    setMbphCenter,
    setMbphEnd,
    setMbphStart,
    setMemo, setMoveOrgId,
    setNm,
    setOrgGbCd,
    setOrgLevelCd,
    setOrgNm,
    setOrgNmCheckYn,
    setOrgNmDupYn,
    setPw1,
    setPw2,
    setRegId, setUpChangeOrgId,
    setUpdId,
    setUpOrgId,
    setUserColorList, setUserColorNo,
    setWithdrawPw,
    setMoneyAmt,
    setLosingAmt,
    setRollingAmt,
    setBank,
    setAccount,
    setAccountName,
    setDomainRegYn,
    setDomainDisYn,
    setRegCd
} from "../../../../redux/reducers/partner/DistributorAddReducer";
import {insertAdminLog} from "../../../../lib/api";
import {NumericFormat} from "react-number-format";

const DefaultInfo3 = (props) => {

    const options = [
        { label: "ht-caal.com(HOTEL)", value: "01" },
        { label: "ht-sln.com(HOTEL)", value: "02" },
    ];
    const [selected, setSelected] = useState([]);
    const param = useParams()


    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.DistributorAddReducer)
    const navigate = useNavigate();
    const userInfoId = useSelector((state) => state.userInfo.id)

    const location = useLocation();
    const { state } = location;
    // state 객체에서 name 값을 추출
    const titleName = state && state.name;
    const gb = "수정";
    const id = param.userId;
    const up_org_id = state && state.up_org_id;

    const onLoad = () => {
        dispatch(resetState());
        dispatch(setOrgLevelCd('03'))//02:총판
        dispatch(setRegId(userInfoId))
        dispatch(setUpdId(userInfoId))

        //부본사리스트에서 추가버튼클릭할때 세팅
        if(up_org_id !==''){
            dispatch(setUpOrgId(up_org_id))
        }

        //1.상위조직아이디조회
        axios.post('/admin/getComboOrgList', {
            org_level_cd : '02',
        }).then((res) => {
            if(res.data.List !== null) {
                dispatch(setComboOrgList(res.data.List))
            }
            else {
                alert('상위조직아이디조회실패')
            }
        })

        //2.수정일경우 자신을 제외한 총판아이디조회. 가맹점전환을 위한 상위총판선택을 위해서
        if(gb === "수정") {
            axios.post('/admin/getComboOrgList', {
                org_level_cd: '02',
                id : id
            }).then((res) => {
                if (res.data.List !== null) {
                    dispatch(setComboOrgList2(res.data.List))
                } else {
                    alert('상위조직아이디조회실패')
                }
            })
        }
        //3.공통코드조회
        axios.post('/admin/cmmnCdList', {
            cmmnCdId : '0012',
            inCd: ["00", '02'], //00:관리자 , 02:총판
        }).then((res) => {
            if(res.data.cmmnCdList !== null) {
                dispatch(setCmmnCd(res.data.cmmnCdList))
            }
            else {
                alert('공통코드조회실패')
            }
        })

        //4.유저색상조회
        axios.post('/admin/userColorList').
        then((res) => {
            if(res.data.userColorList !== null) {
                dispatch(setUserColorList(res.data.userColorList))
            }
            else {
                alert('공통코드조회실패')
            }
        })

        //5.상세조회
        if(gb === "수정"){
            axios.post('/admin/partner/getOrgDtl',{id : id}).
            then((res) => {
                if(res.data.Dtl !== null) {
                    dispatch(setDtl(res.data.Dtl))
                    dispatch(setGb(gb))

                    //전환시에 조직구분코드가 관리자이면 전환을할수없으므로 원본조직구분코드를 담아둔다.
                    dispatch(setBfOrgGbCd(res.data.Dtl.org_gb_cd))
                }
                else {
                    alert('성세조회실패')
                }
            })
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "총판등록"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(onLoad, [titleName])

    const onSubmitHandler = (e) => {
        //navigate('/partner/Distributor_list');
        window.location.reload();
    }

    const dupIdCheckHandle = (e) => {
        axios.post('/admin/partner/getOrgDupCheck', {
            checkGb : 'id',
            id : initialState.input.id
        }).then((res) => {
            if (res.data.dupYn) {
                alert('중복된 아이디가 있습니다. 다른 아이디를 입력해주세요')
                dispatch(setIdDupYn(true));
                dispatch(setIdCheckYn(false));

            } else {
                alert('중복된 아이디가 없습니다. 사용하셔도 좋습니다.')
                dispatch(setIdDupYn(false));
                dispatch(setIdCheckYn(true));
            }
        })
    }

    const dupCheckOrgNmHandle = (e) => {

        alert("initialState.input.org_nm :: "+initialState.input.org_nm);

        axios.post('/admin/partner/getOrgDupCheck', {
            checkGb : 'orgNm',
            org_nm : initialState.input.org_nm
        }).then((res) => {
            if (res.data.dupYn) {
                alert('중복된 총판명이 있습니다. 다른 이름을 입력해주세요')
                dispatch(setOrgNmDupYn(true));
                dispatch(setOrgNmCheckYn(false));

            } else {
                alert('중복된 총판명 없습니다. 사용하셔도 좋습니다.')
                dispatch(setOrgNmDupYn(false));
                dispatch(setOrgNmCheckYn(true));
            }
        })
    }

    const onIdHandler = (e) => {
        dispatch(setId(e.target.value))
        dispatch(setIdCheckYn(false))
    }

    const onNmHandler = (e) => {
        dispatch(setNm(e.target.value))
    }

    const onPw1Handler = (e) => {
        const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/;
        if(regex.test(e.target.value)){
            dispatch(setPw1(e.target.value))
        }
    }

    const onPw2Handler = (e) => {
        const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/;
        if(regex.test(e.target.value)) {
            dispatch(setPw2(e.target.value))
        }
    }

    const onOrgNmHandler = (e) => {
        dispatch(setOrgNm(e.target.value))
        dispatch(setOrgNmCheckYn(false))
    }

    const onOrgGbCdHandler = (e) => {
        dispatch(setOrgGbCd(e.target.value))
    }

    //전화번호앞자리
    const onMbnostartHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbnoStart(value))
        }
    }

    //전화번호가운데자리
    const onMbnoCenterHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbnoCenter(value))
        }
    }

    //전화번호끝자리
    const onMbnoEndHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbnoEnd(value))
        }
    }

    //이동전화번호앞자리
    const onMbphStartHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbphStart(value))
        }
    }

    //이동전화번호중간자리
    const onMbphCenterHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbphCenter(value))
        }
    }

    //이동전화번호끝자리
    const onMbphEndHandler = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const regex = /^\d{0,18}$/; // 숫자 18자리까지만 허용하는 정규식
        if(regex.test(value)){
            dispatch(setMbphEnd(value))
        }
    }

    //이메일
    const onEmailHandler = (e) => {
        dispatch(setEmail(e.target.value))
    }

    //메모
    const onMemoHandler = (e) => {
        dispatch(setMemo(e.target.value))
    }

    //회원색상번호
    const onUserColorNoHandler = (e) => {
        dispatch(setUserColorNo(e.target.value))

    }

    //상위조직아이디 변경시
    const upOrgChangeHandler = (e) => {
        dispatch(setUpOrgId(e.target.value))
    }

    //회훤이동 조직아이디 입력값 변경시
    const onChageOrgIdHandler = (e) => {
        dispatch(setMoveOrgId(e.target.value))
    }

    //조직 전환할때 상위조직아이디 변경시
    const upChangeOrgIdHandler = (e) => {
        dispatch(setUpChangeOrgId(e.target.value))
    }


    //등록 혹은 수정버튼 클릭시
    const handleSave =() =>{
        //상위부본사선택
        if(gb!=="수정" && initialState.input.up_org_id ===''){
            alert("상위부본사를 선택하세요.");
            return false;
        }

        //아이디필수입력
        // if(gb!=="수정" && initialState.input.id === ''){
        //     alert("아이디를 입력하세요");
        //     return false;
        // }
        //아이디중복체크여부
        // if(gb!=="수정" && !initialState.idCheckYn){
        //     alert("아이디 중복 확인을 해주세요");
        //     return false;
        // }
        //아이디중복며부
        // if(gb !== "수정" && initialState.idDupYn){
        //     alert('중복된 아이디가 있습니다. 다른 아이디를 입력해주세요')
        //     return false;
        // }
        // //관리자이름필수입력
        // if(initialState.input.nm === ''){
        //     alert("관리자 이름을 입력하세요");
        //     return false;
        // }
        //비밀번호체크
        // if(initialState.input.pw !== initialState.pwcheck){
        //     alert('비밀번호가 다릅니다. 같은 비밀번호를 입력하세요')
        //     return false;
        // }

        //비밀번호필수입력
        if(initialState.input.pw === ''){
            alert("비밀번호를 입력하세요");
            return false;
        }
        //환전비밀번호필수입력
        if(initialState.input.withdraw_pw === ''){
            alert("환전 비밀번호를 입력하세요");
            return false;
        }
        // //조직명필수입력
        // if(initialState.input.org_nm === ''){
        //     alert("총판명을 입력하세요")
        //     return false;
        // }
        // //조직명중복체크여부
        // if(!initialState.orgNmCheckYn){
        //     alert("총판명 중복 확인을 해주세요");
        //     return false;
        // }
        // //조직명중복여부
        // if(initialState.orgNmDupYn){
        //     alert('중복된 총판명 이 있습니다. 다른 총판 명을 입력해주세요')
        //     return false;
        // }

        axios.post('/admin/partner/saveOrg', initialState.input
        ).then((res) => {
            if (res.data.isInserted) {
                alert('저장했습니다.')
                onSubmitHandler()
            } else {
                alert('저장실패했습니다.')
            }
        })
    }

    //삭제버튼 클릭
    const orgDelete=()=>{
        if(window.confirm("정말 삭제하시겠습니까?")) {
            axios.post('/admin/partner/orgDelete', {id: initialState.input.id}
            ).then((res) => {
                if (res.data.IsDeleted) {
                    alert('삭제했습니다.')
                    onSubmitHandler()
                } else {
                    alert('삭제실패했습니다.')
                }
            })
        }
    }

    //회원이동버튼 클릭시
    const moveOrgMember=()=>{
        if(initialState.moveOrgId === ""){
            alert("회원을 넘겨 받을 본사 혹은 총판 혹은 가맹점 아이디를 입력하세요");
            return false;
        }
        if(window.confirm("정말 현재 총판 소속의 회원들을 대상 총판 혹은 가맹점으로 이동하시겠습니까?")){
            axios.post('/admin/partner/moveOrgMember', {
                    id : initialState.input.id,
                    moveOrgId : initialState.moveOrgId
                }
            ).then((res) => {
                if(res.data.IsExistYn){
                    if (res.data.IsChanged) {
                        alert('이동했습니다.')
                        onSubmitHandler()
                    } else {
                        alert('이동실패했습니다.')
                    }
                }else{
                    alert('그런 본사 혹은 총판 혹은 가맹 아이디가 존재하지 않습니다.')
                    return false;
                }
            })
        }
    }

    //가맹으로전환 버튼 클릭시
    const changeOrg = (e) =>{
        if(initialState.upChangeOrgId=== ""){
            alert("전환시 소속될 상위 총판을 선택하세요.");
            return false;
        }
        if(initialState.bfOrgGbCd === "00"){
            alert("관리자 권한 총판은 전환할 수 없습니다");
            return false;
        }

        if(window.confirm("정말 이 총판을 가맹으로 전환하겠습니까?")){
            axios.post('/admin/partner/changeOrg', {
                    up_org_id : initialState.upChangeOrgId,
                    org_level_cd : '03',//가맹점
                    id : initialState.input.id,
                }
            ).then((res) => {
                if (res.data.IsUpdated) {
                    alert('전환했습니다.')
                    onSubmitHandler()
                } else {
                    alert('전환실패했습니다.')
                }
            })
        }
    }

    //환전비밀번호
    const onWithdrawPwHandler = (e) => {
        const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/;
        if(regex.test(e.target.value)){
            dispatch(setWithdrawPw(e.target.value))
        }
    }
    //보유머니
    const onMoneyHandler = (e) => {
        dispatch(setMoneyAmt(e.target.value))
    }
    //루징금
    const onLosingHandler = (e) => {
        dispatch(setLosingAmt(e.target.value))
    }
    //롤링금
    const onRollingHandler = (e) => {
        dispatch(setRollingAmt(e.target.value))
    }
    //은행명
    const onBankHandler = (e) => {
        dispatch(setBank(e.target.value))
    }
    //계좌
    const onAccountHandler = (e) => {
        dispatch(setAccount(e.target.value))
    }
    //예금주
    const onAccountNameHandler = (e) => {
        dispatch(setAccountName(e.target.value))
    }
    //코드가입여부
    const onDomainRegYnHandler = (e) => {
        dispatch(setDomainRegYn(e.target.value))
    }
    //가입코드현시여부
    const onDomainDisYnHandler = (e) => {
        dispatch(setDomainDisYn(e.target.value))
    }
    //가입코드
    const onRegCdHandler = (e) => {
        dispatch(setRegCd(e.target.value))
    }





    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <b>총판으로 전환</b>
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            <div className="container-fluid p-0 mx-1" style={{
                                display: "flex"
                            }}>
                                <p style={{margin : 0 , marginRight : '4px'}}> 전환시 상위 소속 부본사명 : </p>&nbsp;
                                <select onChange={upChangeOrgIdHandler}>
                                    <option value="">::전환시 소속될 상위 부본사 선택::</option>
                                    {initialState.comboOrgList2?.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.org_nm}
                                        </option>
                                    ))}
                                </select>
                                &nbsp;
                                <button onClick={changeOrg} style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>총판으로 전환</button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <font color={"red"}>* 이 가맹점을 선택한 부본사의 총판으로 전환합니다. 실시간 정산 등에 문제가 있을 수 있으므로, 전환 후, 반드시 실시간 정산 설정을 확인하시기 바랍니다.</font>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>


        </div>

    )
}
export default DefaultInfo3;