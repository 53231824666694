import React from 'react';
import WaitContainer from "../../../containers/game/manage/WaitContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const Wait = () => {
    return (
        <div className="common">
            <font color={"blue"} size="3">파싱대기</font>
            <WaitContainer />
        </div>
    );
};

export default Wait;