import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getAccounts} from "../../redux/reducers/monitoring/AccountReducer";
import AccountList from "../../components/monitoring/AccountList";
import Pagination from "../../components/common/Pagination2";
import moment from "moment";
import common from "../../util/common";
import * as XLSX from "xlsx";
import PointGrade from "../../components/common/PointGrade";


const AccountContainer = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.account);

    const [searchQuery, setSearchQuery] = useState("");
    const [searchGb, setSearchGb] = useState("bankAll");
    const [successYN, setSuccessYN] = useState("All");
    const [depositYN, setDepositYN] = useState("All");
    const [hasDepositYN, setHasDepositYN] = useState("All");
    const [depositType, setDepositType] = useState("All");


    const input = {
        page: state.page,
        pageSize: state.pageSize,
        startDate: state.startDate,
        endDate: state.endDate,
        searchGb: state.searchGb,
        searchQuery: state.searchQuery,
        successYN: successYN,
        depositYN: depositYN,
        hasDepositYN: hasDepositYN,
        depositType: depositType,
        pGrade: state.pGrade,
    }

    useEffect(() => {
        dispatch(getAccounts(input))
    }, [])

    const onRefresh = () => {
        dispatch(getAccounts(input))
    }


    const handlePageChange = (page) => {
        const fixInput = {...input}
        fixInput.page = page
        dispatch(getAccounts(fixInput))
    }

    const startIndex = ((state.page - 1) * state.pageSize) + 1;

    const changeSearchGb = (e) => {
        setSearchGb(e.target.value)
    }

    const changeQuery = (e) => {
        setSearchQuery(e.target.value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.searchGb = searchGb
        fixInput.searchQuery = searchQuery
        dispatch(getAccounts(fixInput))
    }

    const onChangePG = (selectedPGNos) => {
        const fixInput = {...input}
        fixInput.pGrade = selectedPGNos
        dispatch(getAccounts(fixInput))
    }

    const changeTotal = (e) => {
        const fixInput = {...input}
        fixInput.pageSize = e.currentTarget.value
        dispatch(getAccounts(fixInput))
    }

    const exportExcel = () => {
        if(state?.accounts.length > 0) {
            let excelList = [];
            for(let i = 0; i < state.accounts.length; i++) {
                const item = state.accounts[i]
                let jsonObject = {
                    "아이디": item.id? item.id : "-",
                    "닉네임": item.nickname? item.nickname : "-",
                    "이름": item.name? item.name : "-",
                    "은행조회일시": item.account_read_dt? moment(item.account_read_dt).format("YY/MM/DD HH:mm") : "-",
                    "은행조회이전최종입금": item.complete_dt? common.getRemainTime(item.complete_dt) : "-",
                    "현재시점최종입금": item.lately_deposit? common.getRemainTime(item.lately_deposit) : "-",
                    "조회IP": item.use_ip? item.use_ip : "-",
                    "성공여부": item.success_yn === "Y"? "성공" : "실패",
                    "30분내입금여부": item.minutes_deposit_yn === "Y"? "입금확인":"입금안됨",
                    "당일입금횟수": item.day_deposit_cnt? item.day_deposit_cnt : 0,
                    "상세": item.detail? item.detail : "-",
                }
                excelList.push(jsonObject)
            }
            const workSheet = XLSX.utils.json_to_sheet(excelList);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, '충전계좌조회내역');
            XLSX.writeFile(workBook, `충전계좌조회내역${moment().format("YYYYMMDD")}.xlsx`);
        }
    }

    return <div className="commonNew">
        <div className="sm-center-div-no-border">
            <select
                className="form-select sm-select"

                style={{
                    width: "100px"
                }}
                value={state.pageSize}
                onChange={changeTotal}
            >
                <option value={100}>100개</option>
                <option value={240}>240개</option>
                <option value={360}>360개</option>
                <option value={500}>500개</option>
                <option value={1000}>1000개</option>
                <option value={2000}>2000개</option>
                <option value={3000}>3000개</option>
                <option value={4000}>4000개</option>
                <option value={5000}>5000개</option>
                <option value={10000}>10000개</option>
            </select>
            <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}} onClick={(e) => {
                exportExcel()
            }}>엑셀 다운로드</button>
            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                    onClick={(e) => {
                        const fixInput = {...input}
                        fixInput.startDate = moment().add(-6, "days").format('YYYY-MM-DD')
                        fixInput.endDate = moment().format('YYYY-MM-DD')
                        dispatch(getAccounts(fixInput))
                    }}>일주일</button>
            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                    onClick={(e) => {
                        const fixInput = {...input}
                        fixInput.startDate = moment().add(-1, "day").format('YYYY-MM-DD')
                        fixInput.endDate = moment().add(-1, "day").format('YYYY-MM-DD')
                        dispatch(getAccounts(fixInput))
                    }}>어제</button>
            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}}
                    onClick={(e) => {
                        const fixInput = {...input}
                        fixInput.startDate = moment().format('YYYY-MM-DD')
                        fixInput.endDate = moment().format('YYYY-MM-DD')
                        dispatch(getAccounts(fixInput))
                    }}>오늘</button>
            <div style={{display: "flex", paddingLeft: "5px", paddingRight: "5px", alignItems: "center"}}>
                <span className="mx-1">◀</span>
                <input className="form-control sm-date" type="date" value={state.startDate} onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.startDate = e.currentTarget.value
                    dispatch(getAccounts(fixInput))
                }}/>
                <span className="mx-1">~</span>
                <input className="form-control sm-date" type="date" value={state.endDate} onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.endDate = e.currentTarget.value
                    dispatch(getAccounts(fixInput))
                }}/>
                <span className="mx-1">▶</span>
            </div>
        </div>
        <div className="container-fluid p-0 mx-1 commonNew" style={{
            display: "flex", paddingLeft: "1px"
        }}>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <label className="sm-label">
                </label>
                <input className="form-check-input sm-radio" type="radio" name="successRadio" id="successAll" checked={successYN === "All"}
                       onChange={(e) => {
                           if(e.currentTarget.checked) {
                               setSuccessYN("All")
                               const fixInput = {...input}
                               fixInput.successYN = "All"
                               dispatch(getAccounts(fixInput))
                           }
                       }}/>
                <label className="form-check-label sm-label" htmlFor="successAll">
                    전체
                </label>
                <input className="form-check-input sm-radio" type="radio" name="successRadio" id="successY" checked={successYN === "Y"}
                       onChange={(e) => {
                           if(e.currentTarget.checked) {
                               setSuccessYN("Y")
                               const fixInput = {...input}
                               fixInput.successYN = "Y"
                               dispatch(getAccounts(fixInput))
                           }
                       }}/>
                <label className="form-check-label sm-label" htmlFor="successY">
                    성공
                </label>
                <input className="form-check-input sm-radio" type="radio" name="successRadio" id="successN" checked={successYN === "N"}
                       onChange={(e) => {
                           if(e.currentTarget.checked) {
                               setSuccessYN("N")
                               const fixInput = {...input}
                               fixInput.successYN = "N"
                               dispatch(getAccounts(fixInput))
                           }
                       }}/>
                <label className="form-check-label sm-label" htmlFor="successN">
                    실패
                </label>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <label>
                    입금여부 :
                </label>
                <input className="form-check-input" type="radio" name="depositRadio" id="depositAll" checked={depositYN === "All"} onChange={(e) => {
                    if(e.currentTarget.checked) {
                        setDepositYN("All")
                        const fixInput = {...input}
                        fixInput.depositYN = "All"
                        dispatch(getAccounts(fixInput))
                    }
                }}/>
                <label className="form-check-label" htmlFor="depositAll">
                    전체
                </label>
                <input className="form-check-input" type="radio" name="depositRadio" id="depositY" checked={depositYN === "Y"}
                       onChange={(e) => {
                           if(e.currentTarget.checked) {
                               setDepositYN("Y")
                               const fixInput = {...input}
                               fixInput.depositYN = "Y"
                               dispatch(getAccounts(fixInput))
                           }
                       }}/>
                <label className="form-check-label" htmlFor="depositY">
                    입금확인
                </label>
                <input className="form-check-input" type="radio" name="depositRadio" id="depositN" checked={depositYN === "N"}
                       onChange={(e) => {
                           if(e.currentTarget.checked) {
                               setDepositYN("N")
                               const fixInput = {...input}
                               fixInput.depositYN = "N"
                               dispatch(getAccounts(fixInput))
                           }
                       }}/>
                <label className="form-check-label" htmlFor="depositN">
                    입금안됨
                </label>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <label>
                    검색기간내입금 :
                </label>
                <input className="form-check-input" type="radio" name="hasDepositRadio" id="hasDepositAll" checked={hasDepositYN === "All"}  onChange={(e) => {
                    if(e.currentTarget.checked) {
                        setHasDepositYN("All")
                        const fixInput = {...input}
                        fixInput.hasDepositYN = "All"
                        dispatch(getAccounts(fixInput))
                    }
                }}/>
                <label className="form-check-label" htmlFor="hasDepositAll">
                    전체
                </label>
                <input className="form-check-input" type="radio" name="hasDepositRadio" id="hasDepositY" checked={hasDepositYN === "Y"} onChange={(e) => {
                    if(e.currentTarget.checked) {
                        setHasDepositYN("Y")
                        const fixInput = {...input}
                        fixInput.hasDepositYN = "Y"
                        dispatch(getAccounts(fixInput))
                    }
                }}/>
                <label className="form-check-label" htmlFor="hasDepositY">
                    입금있음
                </label>
                <input className="form-check-input" type="radio" name="hasDepositRadio" id="hasDepositN" checked={hasDepositYN === "N"} onChange={(e) => {
                    if(e.currentTarget.checked) {
                        setHasDepositYN("N")
                        const fixInput = {...input}
                        fixInput.hasDepositYN = "N"
                        dispatch(getAccounts(fixInput))
                    }
                }} />
                <label className="form-check-label" htmlFor="hasDepositN">
                    입금없음
                </label>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <label>
                    입금방식 :
                </label>
                <input className="form-check-input" type="radio" name="depositTypeRadio" id="depositTypeAll" checked={depositType === "All"} onChange={(e) => {
                    if(e.currentTarget.checked) {
                        setDepositType("All")
                        const fixInput = {...input}
                        fixInput.depositType = "All"
                        dispatch(getAccounts(fixInput))
                    }
                }}/>
                <label className="form-check-label" htmlFor="depositTypeAll">
                    전체
                </label>
                <input className="form-check-input" type="radio" name="depositTypeRadio" id="cash" checked={depositType === "cash"} onChange={(e) => {
                    if(e.currentTarget.checked) {
                        setDepositType("cash")
                        const fixInput = {...input}
                        fixInput.depositType = "cash"
                        dispatch(getAccounts(fixInput))
                    }
                }}/>
                <label className="form-check-label" htmlFor="cash">
                    현금
                </label>
                <input className="form-check-input" type="radio" name="depositTypeRadio" id="virtual1" checked={depositType === "virtual1"} onChange={(e) => {
                    if(e.currentTarget.checked) {
                        setDepositType("virtual1")
                        const fixInput = {...input}
                        fixInput.depositType = "virtual1"
                        dispatch(getAccounts(fixInput))
                    }
                }}/>
                <label className="form-check-label" htmlFor="virtual1">
                    가상
                </label>
                <input className="form-check-input" type="radio" name="depositTypeRadio" id="virtual2" checked={depositType === "virtual2"} onChange={(e) => {
                    if(e.currentTarget.checked) {
                        setDepositType("virtual2")
                        const fixInput = {...input}
                        fixInput.depositType = "virtual2"
                        dispatch(getAccounts(fixInput))
                    }
                }}/>
                <label className="form-check-label" htmlFor="virtual2">
                    가상2
                </label>
                <input className="form-check-input" type="radio" name="depositTypeRadio" id="coinB" checked={depositType === "coinB"} onChange={(e) => {
                    if(e.currentTarget.checked) {
                        setDepositType("coinB")
                        const fixInput = {...input}
                        fixInput.depositType = "coinB"
                        dispatch(getAccounts(fixInput))
                    }
                }}/>
                <label className="form-check-label" htmlFor="coinB">
                    코인(B)
                </label>
                <input className="form-check-input" type="radio" name="depositTypeRadio" id="coinC" checked={depositType === "coinC"} onChange={(e) => {
                    if(e.currentTarget.checked) {
                        setDepositType("coinC")
                        const fixInput = {...input}
                        fixInput.depositType = "coinC"
                        dispatch(getAccounts(fixInput))
                    }
                }}/>
                <label className="form-check-label" htmlFor="coinC">
                    코인(C)
                </label>
            </div>
        </div>

        <div>
            <div style={{display: "flex", paddingRight: "5px", paddingLeft: "5px"}}>
                <PointGrade onChangePG={onChangePG}/>
            </div>

            <div className="sm-center-div mt-1">
                <select className="form-select sm-select" aria-label="Default select example" style={{width: "150px", height: "18px"}} value={searchGb} onChange={changeSearchGb}>
                    <option value="nickname" selected>닉네임</option>
                    <option value="id">아이디</option>
                </select>
                <input className="form-check-input sm-input" type="text" style={{height: "18px"}} value={searchQuery} onKeyDown={inputPress} onChange={changeQuery}/>
            </div>
        </div>

        <AccountList state={state} onRefresh={onRefresh} />
        <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange}/>
    </div>
}

export default AccountContainer