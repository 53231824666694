import {call, put} from "redux-saga/effects";
import {failedGeRouletteSetting, setRouletteSetting} from "../../reducers/setting/RouletteListReducer";
import {fetchRouletteSettingList} from "../../../lib/api";

export function* getRouletteSettingSaga(action) {
    try{
        console.log("##getRouletteSettingSaga input#",action.payload.input)
        const res = yield call(fetchRouletteSettingList,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd
        );
        console.log("##getRouletteSettingSaga output#",res.data)
        yield put(setRouletteSetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGeRouletteSetting(error))
    }
}