import GameKindList from "../../../components/game/setting/GameKindList";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {changeIsLoading, getGameKinds, getNations, getTeams} from "../../../redux/reducers/game/gameSetting";
import {useDispatch, useSelector} from "react-redux";
import Pagination from "../../../components/common/Pagination";


const GameKindContainer = () => {
    const state = useSelector((state) => state.gameSetting);
    const [useCode, setUseCode] = useState(999)
    const [searchQuery, setSearchQuery] = useState("")
    const dispatch = useDispatch();
    const [addList, setAddList] = useState([])


    const input = {
        query: searchQuery,
        useCode: useCode,
    }
    useEffect(() => {
        dispatch(getGameKinds(input))
        dispatch(changeIsLoading(true));
    }, [])

    const onRefresh = () => {
        dispatch(getGameKinds(input))
    }
    const insertGameKind = (item) => {
        dispatch(changeIsLoading(true))
        axios.post('/admin/game/setting/insertGameKind', {
            kindItem: item
        }).then((res) => {
            dispatch(changeIsLoading(false))
            if(res.data.isInserted) {
                alert("등록완료")
                const arr = addList.filter((el) => el.instantId !== item.instantId)
                setAddList(arr)
                onRefresh()
            }
        })
    }
    const insertAllGameKind = () => {
        dispatch(changeIsLoading(true))
        axios.post('/admin/game/setting/insertAllGameKind', {
            kinds: addList
        }).then((res) => {
            dispatch(changeIsLoading(false))
            if(res.data.isInserted) {
                alert("일괄등록완료")
                setAddList([])
                onRefresh()
            }
        })
    }
    const getGameKindsFromMatchServer = () => {
        axios.post('/admin/game/setting/getGameKindsFromMatchServer', {

        }).then((res) => {
            if(res.data.inserted) {
                alert("완료")
                onRefresh()
            }
            else {
                alert("실패")
            }
        })
    }

    const changeUseCode = (e) => {
        setUseCode(e.currentTarget.value)
        const fixInput = {...input}
        fixInput.useCode = e.currentTarget.value
        dispatch(getGameKinds(fixInput))
    }
    const changeQuery = (e) => {
        setSearchQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.currentTarget.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.query = query
        dispatch(getGameKinds(fixInput))
    }

    return <div className="common">
        <div className="d-flex">
            <div className="d-flex w-50">
                <select className="form-select" onChange={changeUseCode} value={useCode}>
                    <option value={999}>사용여부전체</option>
                    <option value={1}>사용</option>
                    <option value={0}>미사용</option>
                </select>
                <input style={{
                    width: "100px"
                }} type="text" className="form-control" id="search_edit" placeholder="종목명" value={searchQuery} onChange={changeQuery} onKeyDown={inputPress}/>
                <button style={{
                    width: "80px", borderTopRightRadius: "5px" ,borderBottomRightRadius: "5px"
                }} type="submit" className="btn btn-primary" onClick={(e) => {
                    onSearch(searchQuery)
                }}>검색</button>
            </div>
            <div className="d-flex justify-content-end mb-1 w-50">
                <button className="btn btn-secondary me-1" onClick={(e) => {
                    const arr = [...addList]
                    arr.push({
                        instantId: new Date().getTime(),
                        name_kor: "",
                        bookmaker: "",
                        is_use: "",
                    })
                    setAddList(arr)
                }}>추가</button>
                <button className="btn btn-primary mx-1" onClick={(e) => {
                    onRefresh()
                }}>새로고침</button>
                <button className="btn btn-success ms-1" onClick={(e) => {
                    getGameKindsFromMatchServer()
                }}>가져오기</button>
            </div>
        </div>

        {addList.length > 0 && (
            <div>
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-secondary">
                    <tr>
                        <td colSpan="4">
                            종목신규등록
                        </td>
                    </tr>
                    <tr>
                        <th>아이콘</th>
                        <th>종목</th>
                        <th>업체</th>
                        <th>
                            <button className="btn btn-secondary mb-1" onClick={(e) => {
                                insertAllGameKind()
                            }}>일괄저장</button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {addList.map((item, index) => (
                        <tr>
                            <td>
                                <div className="d-flex align-items-center me-1">
                                    <img src="/icon/2641.png" width="20px" height="20px"/>
                                    <input className="form-control form-control-sm" type="file"/>
                                </div>
                            </td>
                            <td>
                                <input className="form-control form-control-sm me-1" type="text" value={item.name_kor} style={{width: "130px"}} placeholder="종목"
                                       onChange={(e) => {
                                           item.name_kor = e.currentTarget.value
                                           const arr = [...addList]
                                           arr[index] = item
                                           setAddList(arr)
                                       }}/>
                            </td>
                            <td>
                                <input className="form-control form-control-sm me-1" type="text" style={{width: "130px"}} placeholder="업체" value={item.bookmaker}
                                       onChange={(e) => {
                                           item.bookmaker = e.currentTarget.value
                                           const arr = [...addList]
                                           arr[index] = item
                                           setAddList(arr)
                                       }}/>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <button className="btn btn-secondary me-1" onClick={(e) => {
                                        insertGameKind(item)
                                    }}>저장</button>
                                    <button className="btn btn-warning ms-1" onClick={(e) => {

                                        const arr = addList.filter((el) => el.instantId !== item.instantId)
                                        const arr2 = [...arr]
                                        setAddList(arr2)
                                    }}>삭제</button>
                                </div>

                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

        )}
        <GameKindList state={state} onRefresh={onRefresh}/>
    </div>
}

export default GameKindContainer