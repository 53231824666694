import {call, put} from "redux-saga/effects";
import {failedGetMessageReducer, setMessageReducer} from "../../reducers/user/MessageReducer";
import {fetchMessage} from "../../../lib/api";

export function* getMessageSaga(action) {
    try{
        console.log("##getMessageSaga input#",action.payload.input)
        const res = yield call(fetchMessage,
            action.payload.input,
        );
        console.log("##getMessageSaga output#",res.data)
        yield put(setMessageReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMessageReducer(error))
    }
}