import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../redux/reducers/user/BlackMemberAddModalReducer";

const UserHistoryContainer = ({userId}) => {

    const [updateInfo, setUpdateInfo] = useState([]);
    const userState = useSelector((state) => state.userInfoCombine)


    const getDetailInfo = () => {
        axios.post('/admin/member/userHistoryInfo', {
            userId : userId
        }).then((res) => {
            setUpdateInfo(res.data.updateList);
        })
    }
    useEffect(getDetailInfo, [])

    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <b>회원 정보 수정 기록</b>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                * 회원 정보 수정 정보를 보여줍니다.
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <font color={"red"}>* 전화 번호와 비밀번호는 보안상의 문제로 수정 여부만 파악 가능하며, 실제 값을 알 수는 없습니다.</font>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
                <table className="commonT table table-bordered table-sm text-center">
                    <thead >
                    <tr>
                        <th style={{width: "25%",backgroundColor: "lightgray"}} >변경 필드명</th>
                        <th style={{width: "20%",backgroundColor: "lightgray"}}>기존 값</th>
                        <th style={{width: "20%",backgroundColor: "lightgray"}}>수정 값</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>수정 아이디</th>
                        <th style={{width: "10%",backgroundColor: "lightgray"}}>수정 아이피</th>
                        <th style={{width: "15%",backgroundColor: "lightgray"}}>수정 시각</th>
                    </tr>
                    </thead>
                    { setUpdateInfo === null && (
                        <tbody>
                        <tr>
                            <td colSpan="6">목록이 비어있습니다.</td>
                        </tr>
                        </tbody>
                    )}
                    { updateInfo != null &&  updateInfo.map((updateInfo, index) => (
                        <tbody key={updateInfo.no}>
                        <td align={"center"} style={{backgroundColor: "lightgray"}}>{updateInfo.change_field}</td>
                        <td align={"left"}>{updateInfo.asis_value}</td>
                        <td align={"left"}>{updateInfo.tobe_value}</td>
                        <td align={"left"}>{updateInfo.reg_id}</td>
                        <td align={"left"}>{updateInfo.update_ip}</td>
                        <td align={"left"}>{updateInfo.updatedAt}</td>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>




    )
}
export default UserHistoryContainer;