import GameKindContainer from "../../../containers/game/setting/GameKindContainer";
import NationContainer from "../../../containers/game/setting/NationContainer";
import LeagueContainer from "../../../containers/game/setting/LeagueContainer";
import TeamContainer from "../../../containers/game/setting/TeamContainer";
import React, {useState} from "react";
import {useSelector} from "react-redux";


const GameSetting = () => {
    const state = useSelector((state) => state.gameSetting);

    return <div>
        {/* ✅ 전체화면 로딩 UI */}
        {state.isLoading && (
            <div className="loading-overlay">
                <div className="spinner">
                </div>
            </div>
        )}

        <font color={"blue"} size="3">리그관리</font>
        <div className="d-flex">
            <div className="card w-50">
                <font color={"black"} size="2">국가목록</font>
                <NationContainer/>
            </div>
            <div className="card w-50">
                <font color={"black"} size="2">리그목록</font>
                <LeagueContainer/>
            </div>
        </div>
        <div className="d-flex">
            <div className="card w-50">
                <font color={"black"} size="2">종목목록</font>
                <GameKindContainer/>
            </div>
            <div className="card w-50">
                <font color={"black"} size="2">팀목록</font>
                <TeamContainer />
            </div>
        </div>

    </div>
}


export default GameSetting