import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {
    setSettingLiveYn,
    setSettingSlotYn,
    setSettingMiniYn,
    setSettingSportYn,
    setSettingMiniDtlYn,
    setSettingSportDtlYn,
    setOutbreakSdtArr,
    setOutbreakEdtArr,
    setPaymentMethodArr,
    setCouponMethodArr,
    setIsOpen,
    getLvlSetting,
    setQuery,
    setBonusQuery,
    setSelectedNos1,
    setSelectedNos2,
    setSelectedNos3,
    setSelectedNos4,
    setSelectedNos5
} from "../../redux/reducers/setting/DwpSettingListReducer";
import Switch from "react-switch";
import LvlSettingComponent from "../../components/modal/setting/LvlSettingComponent";
import MemberAddComponent from "../MemberAddComponent";

const DwpSetting = ({dwpSetting, onRefresh, initialState, onSearch, mainList1, mainList2, mainList3, mainList4, mainList5,
                        mainList6
                    }) => {

    const dispatch = useDispatch()

    const userInfoId = useSelector((state) => state.userInfo.id)
    const [changeColorList, setChangeColorList] = useState([]);
    const [levels, setLevels] = useState([])

    //기본설정
    const [minDepositAmt, setMinDepositAmt] = useState("");
    const [minWithdrawAmt, setMinWithdrawAmt] = useState("");
    const [depositAmtUnit, setDepositAmtUnit] = useState("");
    const [withdrawAmtUnit, setWithdrawAmtUnit] = useState("");
    const [oneDepositAmt, setOneDepositAmt] = useState("");
    const [oneWithdrawAmt, setOneWithdrawAmt] = useState("");
    const [autoDepositYn, setAutoDepositYn] = useState("");
    const [dayMaxWithdrawAmt, setDayMaxWithdrawAmt] = useState("");
    const [exchangeMinPoint, setExchangeMinPoint] = useState("");
    const [compMinPoint, setCompMinPoint] = useState("");
    const [depositRetryMinute, setDepositRetryMinute] = useState("");
    const [withdrawRetryMinute, setWithdrawRetryMinute] = useState("");

    //페이백설정-일별페이백
    const [useYn1, setUseYn1] = useState("");
    const [paybackBounsPercent1, setPaybackBounsPercent1] = useState("");
    const [minPaymentAmt1, setMinPaymentAmt1] = useState("");
    const [maxPaymentAmt1, setMaxPaymentAmt1] = useState("");
    const [chargeAmtMore1, setChargeAmtMore1] = useState("");
    const [manyDayAttendance1, setManyDayAttendance1] = useState("");
    const [manyCntMore1, setManyCntMore1] = useState("");
    const [monthPaymentOfficial1, setMonthPaymentOfficial1] = useState("");
    const [totalPaymentOfficial1, setTotalPaymentOfficial1] = useState("");
    const [paymentOfficialCd1, setPaymentOfficialCd1] = useState("");
    const [paymentMethodCd1, setPaymentMethodCd1] = useState("");
    const [paymentDay1, setPaymentDay1] = useState("");
    const [paymentDate1, setPaymentDate1] = useState("");
    const [paybackSdt1, setPaybackSdt1] = useState("");
    const [paybackEdt1, setPaybackEdt1] = useState("");

    //페이백설정-주별페이백
    const [useYn2, setUseYn2] = useState("");
    const [paybackBounsPercent2, setPaybackBounsPercent2] = useState("");
    const [minPaymentAmt2, setMinPaymentAmt2] = useState("");
    const [maxPaymentAmt2, setMaxPaymentAmt2] = useState("");
    const [chargeAmtMore2, setChargeAmtMore2] = useState("");
    const [manyDayAttendance2, setManyDayAttendance2] = useState("");
    const [manyCntMore2, setManyCntMore2] = useState("");
    const [monthPaymentOfficial2, setMonthPaymentOfficial2] = useState("");
    const [totalPaymentOfficial2, setTotalPaymentOfficial2] = useState("");
    const [paymentOfficialCd2, setPaymentOfficialCd2] = useState("");
    const [paymentMethodCd2, setPaymentMethodCd2] = useState("");
    const [paymentDay2, setPaymentDay2] = useState("");
    const [paymentDate2, setPaymentDate2] = useState("");
    const [paybackSdt2, setPaybackSdt2] = useState("");
    const [paybackEdt2, setPaybackEdt2] = useState("");

    //페이백설정-월별페이백
    const [useYn3, setUseYn3] = useState("");
    const [paybackBounsPercent3, setPaybackBounsPercent3] = useState("");
    const [minPaymentAmt3, setMinPaymentAmt3] = useState("");
    const [maxPaymentAmt3, setMaxPaymentAmt3] = useState("");
    const [chargeAmtMore3, setChargeAmtMore3] = useState("");
    const [manyDayAttendance3, setManyDayAttendance3] = useState("");
    const [manyCntMore3, setManyCntMore3] = useState("");
    const [monthPaymentOfficial3, setMonthPaymentOfficial3] = useState("");
    const [totalPaymentOfficial3, setTotalPaymentOfficial3] = useState("");
    const [paymentOfficialCd3, setPaymentOfficialCd3] = useState("");
    const [paymentMethodCd3, setPaymentMethodCd3] = useState("");
    const [paymentDay3, setPaymentDay3] = useState("");
    const [paymentDate3, setPaymentDate3] = useState("");
    const [paybackSdt3, setPaybackSdt3] = useState("");
    const [paybackEdt3, setPaybackEdt3] = useState("");

    //페이백설정-1회용페이백
    const [useYn4, setUseYn4] = useState("");
    const [paybackBounsPercent4, setPaybackBounsPercent4] = useState("");
    const [minPaymentAmt4, setMinPaymentAmt4] = useState("");
    const [maxPaymentAmt4, setMaxPaymentAmt4] = useState("");
    const [chargeAmtMore4, setChargeAmtMore4] = useState("");
    const [manyDayAttendance4, setManyDayAttendance4] = useState("");
    const [manyCntMore4, setManyCntMore4] = useState("");
    const [monthPaymentOfficial4, setMonthPaymentOfficial4] = useState("");
    const [totalPaymentOfficial4, setTotalPaymentOfficial4] = useState("");
    const [paymentOfficialCd4, setPaymentOfficialCd4] = useState("");
    const [paymentMethodCd4, setPaymentMethodCd4] = useState("");
    const [paymentDay4, setPaymentDay4] = useState("");
    const [paymentDate4, setPaymentDate4] = useState("");
    const [paybackSdt4, setPaybackSdt4] = useState("");
    const [paybackEdt4, setPaybackEdt4] = useState("");

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [defaultBtnArr, setDefaultBtnArr] = useState([]);


    //충전보너스설정-지급
    const [chargeBounsNo, setChargeBounsNo] = useState("");
    const [paymentCd, setPaymentCd] = useState("");
    const [firstChargeBonusPercent, setFirstChargeBonusPercent] = useState("");
    const [everyChargeBonusPercent, setEveryChargeBonusPercent] = useState("");
    const [maxBonusCnt, setMaxBonusCnt] = useState("");
    const [maxBonusDay, setMaxBonusDay] = useState("");
    const [bounsLimitMoney, setBounsLimitMoney] = useState("");
    const [bounsLimitPoint, setBounsLimitPoint] = useState("");
    const [withdrawFirstChargeBonus, setWithdrawFirstChargeBonus] = useState("");
    const [withdrawEveryChargeBonus, setWithdrawEveryChargeBonus] = useState("");
    const [outbreakLimitYn, setOutbreakLimitYn] = useState("");
    const [outbreakBonusDayCnt, setOutbreakBonusDayCnt] = useState("");

    //충전보너스설정-추가
    const [sportsCd, setSportsCd] = useState("01");
    const [cntArr, setCntArr] = useState([]);
    const [paymentCdArr, setPaymentCdArr] = useState([]);
    const [couponPayCdArr, setCouponPayCdArr] = useState([]);
    const [rollingPercentArr, setRollingPercentArr] = useState([]);
    const [outbreakPercentArr, setOutbreakPercentArr] = useState([]);


    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }


    useEffect(() => {
        getGrades()
    }, [])

    const getGrades = () => {
        axios.post('/admin/admin/getPointGrade2', {

        }).then((res) => {
            setLevels(res.data.result)
            const arr = []
            for(const level of res.data.result) {
                arr.push(level.setting_level_no)
            }
        })
    }

    const handleClickOpen = () => {
        dispatch(setIsOpen(true))
    }
    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        window.location.reload();
    }

    const onLevelChange = (setting_level_no) => {

        setMinDepositAmt("");
        setMinWithdrawAmt("");
        setDepositAmtUnit("");
        setWithdrawAmtUnit("");
        setOneDepositAmt("");
        setOneWithdrawAmt("");
        setAutoDepositYn("");
        setDayMaxWithdrawAmt("");
        setExchangeMinPoint("");
        setCompMinPoint("");
        setDepositRetryMinute("");
        setWithdrawRetryMinute("");

        dispatch(setSelectedNos1([]))
        dispatch(setSelectedNos2([]))
        dispatch(setSelectedNos3([]))
        dispatch(setSelectedNos4([]))
        dispatch(setSelectedNos5([]))

        setStartDate("");
        setEndDate("");

        setBonusQuery(0);
        setChargeBounsNo(0);

        setFirstChargeBonusPercent("");
        setEveryChargeBonusPercent("");
        setMaxBonusCnt("");
        setMaxBonusDay("");
        setBounsLimitMoney("");
        setBounsLimitPoint("");
        setWithdrawFirstChargeBonus("");
        setWithdrawEveryChargeBonus("");
        setOutbreakLimitYn("N");
        setOutbreakBonusDayCnt("");

        onSearch(setting_level_no)
    }

    const autoDepositYnChangeHandler = (e) => {
        setAutoDepositYn(e.currentTarget.value);
    }

    const useYn1ChangeHandler = (e) => {
        setUseYn1(e.currentTarget.value);
    }
    const useYn2ChangeHandler = (e) => {
        setUseYn2(e.currentTarget.value);
    }
    const useYn3ChangeHandler = (e) => {
        setUseYn3(e.currentTarget.value);
    }
    const useYn4ChangeHandler = (e) => {
        setUseYn4(e.currentTarget.value);
    }

    const paymentOfficialCd1ChangeHandler = (e) => {
        setPaymentOfficialCd1(e.currentTarget.value);
    }
    const paymentOfficialCd2ChangeHandler = (e) => {
        setPaymentOfficialCd2(e.currentTarget.value);
    }
    const paymentOfficialCd3ChangeHandler = (e) => {
        setPaymentOfficialCd3(e.currentTarget.value);
    }
    const paymentOfficialCd4ChangeHandler = (e) => {
        setPaymentOfficialCd4(e.currentTarget.value);
    }

    const paymentMethodCd1ChangeHandler = (e) => {
        setPaymentMethodCd1(e.currentTarget.value);
    }
    const paymentMethodCd2ChangeHandler = (e) => {
        setPaymentMethodCd2(e.currentTarget.value);
    }
    const paymentMethodCd3ChangeHandler = (e) => {
        setPaymentMethodCd3(e.currentTarget.value);
    }
    const paymentMethodCd4ChangeHandler = (e) => {
        setPaymentMethodCd4(e.currentTarget.value);
    }

    const paymentDay1ChangeHandler = (e) => {
        setPaymentDay1(e.currentTarget.value);
    }
    const paymentDay2ChangeHandler = (e) => {
        setPaymentDay2(e.currentTarget.value);
    }
    const paymentDay3ChangeHandler = (e) => {
        setPaymentDay3(e.currentTarget.value);
    }
    const paymentDay4ChangeHandler = (e) => {
        setPaymentDay4(e.currentTarget.value);
    }

    const paymentDate1ChangeHandler = (e) => {
        setPaymentDate1(e.currentTarget.value);
    }
    const paymentDate2ChangeHandler = (e) => {
        setPaymentDate2(e.currentTarget.value);
    }
    const paymentDate3ChangeHandler = (e) => {
        setPaymentDate3(e.currentTarget.value);
    }
    const paymentDate4ChangeHandler = (e) => {
        setPaymentDate4(e.currentTarget.value);
    }



    const updateDefaultBtnState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlDwpDefaultSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                minDepositAmt: minDepositAmt?parseInt(minDepositAmt):mainList1.min_deposit_amt,
                minWithdrawAmt: minWithdrawAmt?parseInt(minWithdrawAmt):mainList1.min_withdraw_amt,
                depositAmtUnit: depositAmtUnit?parseInt(depositAmtUnit):mainList1.deposit_amt_unit,
                withdrawAmtUnit: withdrawAmtUnit?parseInt(withdrawAmtUnit):mainList1.withdraw_amt_unit,
                oneDepositAmt: oneDepositAmt?parseInt(oneDepositAmt):mainList1.one_deposit_amt,
                oneWithdrawAmt: oneWithdrawAmt?parseInt(oneWithdrawAmt):mainList1.one_withdraw_amt,
                autoDepositYn: autoDepositYn == '' ? mainList1.auto_deposit_yn : autoDepositYn,
                dayMaxWithdrawAmt: dayMaxWithdrawAmt?parseInt(dayMaxWithdrawAmt):mainList1.day_max_withdraw_amt,
                exchangeMinPoint: exchangeMinPoint?parseInt(exchangeMinPoint):mainList1.exchange_min_point,
                compMinPoint: compMinPoint?parseInt(compMinPoint):mainList1.comp_min_point,
                depositRetryMinute: depositRetryMinute?parseInt(depositRetryMinute):mainList1.deposit_retry_minute,
                withdrawRetryMinute: withdrawRetryMinute?parseInt(withdrawRetryMinute):mainList1.withdraw_retry_minute,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }

    const updateDefaultAllBtnState = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos1.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos1.length; i++)
            {
                axios.post('/admin/setting/saveLvlDwpDefaultSetting', {
                    pntGbCd: initialState.selectedNos1[i],
                    minDepositAmt: minDepositAmt?parseInt(minDepositAmt):mainList1.min_deposit_amt,
                    minWithdrawAmt: minWithdrawAmt?parseInt(minWithdrawAmt):mainList1.min_withdraw_amt,
                    depositAmtUnit: depositAmtUnit?parseInt(depositAmtUnit):mainList1.deposit_amt_unit,
                    withdrawAmtUnit: withdrawAmtUnit?parseInt(withdrawAmtUnit):mainList1.withdraw_amt_unit,
                    oneDepositAmt: oneDepositAmt?parseInt(oneDepositAmt):mainList1.one_deposit_amt,
                    oneWithdrawAmt: oneWithdrawAmt?parseInt(oneWithdrawAmt):mainList1.one_withdraw_amt,
                    autoDepositYn: autoDepositYn == '' ? mainList1.auto_deposit_yn : autoDepositYn,
                    dayMaxWithdrawAmt: dayMaxWithdrawAmt?parseInt(dayMaxWithdrawAmt):mainList1.day_max_withdraw_amt,
                    exchangeMinPoint: exchangeMinPoint?parseInt(exchangeMinPoint):mainList1.exchange_min_point,
                    compMinPoint: compMinPoint?parseInt(compMinPoint):mainList1.comp_min_point,
                    depositRetryMinute: depositRetryMinute?parseInt(depositRetryMinute):mainList1.deposit_retry_minute,
                    withdrawRetryMinute: withdrawRetryMinute?parseInt(withdrawRetryMinute):mainList1.withdraw_retry_minute,
                    adminNo: userInfoId
                }).then((res) => {
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }

            alert("수정완료");

        }
    }

    const updateDayPaybackBtnState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("useYn1 :: "+useYn1);
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlDwpDayPaybackSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                paybackCd: '01',
                useYn: useYn1 == '' ? mainList2.use_yn : useYn1,
                paybackBounsPercent: paybackBounsPercent1?parseInt(paybackBounsPercent1):mainList2.payback_bouns_percent,
                minPaymentAmt: minPaymentAmt1?parseInt(minPaymentAmt1):mainList2.min_payment_amt,
                maxPaymentAmt: maxPaymentAmt1?parseInt(maxPaymentAmt1):mainList2.max_payment_amt,
                chargeAmtMore: chargeAmtMore1?parseInt(chargeAmtMore1):mainList2.charge_amt_more,
                manyDayAttendance: manyDayAttendance1?parseInt(manyDayAttendance1):mainList2.many_day_attendance,
                manyCntMore: manyCntMore1?parseInt(manyCntMore1):mainList2.many_cnt_more,
                monthPaymentOfficial: monthPaymentOfficial1?parseInt(monthPaymentOfficial1):mainList2.month_payment_official,
                totalPaymentOfficial: totalPaymentOfficial1?parseInt(totalPaymentOfficial1):mainList2.total_payment_official,
                paymentOfficialCd: paymentOfficialCd1 == '' ? mainList2.payment_official_cd : paymentOfficialCd1,
                paymentMethodCd: paymentMethodCd1 == '' ? mainList2.payment_method_cd : paymentMethodCd1,
                paymentDay: paymentDay1 == '' ? mainList2.payment_day : paymentDay1,
                paymentDate: paymentDate1 == '' ? mainList2.payment_date : paymentDate1,
                paybackSdt: paybackSdt1 == '' ? mainList2.payback_sdt : paybackSdt1,
                paybackEdt: paybackEdt1 == '' ? mainList2.payback_edt : paybackEdt1,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }

    const updateDayPaybackAllBtnState = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos2.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos2.length; i++)
            {
                axios.post('/admin/setting/saveLvlDwpDayPaybackSetting', {
                    pntGbCd: initialState.selectedNos2[i],
                    paybackCd: '01',
                    useYn: useYn1 == '' ? mainList2.use_yn : useYn1,
                    paybackBounsPercent: paybackBounsPercent1?parseInt(paybackBounsPercent1):mainList2.payback_bouns_percent,
                    minPaymentAmt: minPaymentAmt1?parseInt(minPaymentAmt1):mainList2.min_payment_amt,
                    maxPaymentAmt: maxPaymentAmt1?parseInt(maxPaymentAmt1):mainList2.max_payment_amt,
                    chargeAmtMore: chargeAmtMore1?parseInt(chargeAmtMore1):mainList2.charge_amt_more,
                    manyDayAttendance: manyDayAttendance1?parseInt(manyDayAttendance1):mainList2.many_day_attendance,
                    manyCntMore: manyCntMore1?parseInt(manyCntMore1):mainList2.many_cnt_more,
                    monthPaymentOfficial: monthPaymentOfficial1?parseInt(monthPaymentOfficial1):mainList2.month_payment_official,
                    totalPaymentOfficial: totalPaymentOfficial1?parseInt(totalPaymentOfficial1):mainList2.total_payment_official,
                    paymentOfficialCd: paymentOfficialCd1 == '' ? mainList2.payment_official_cd : paymentOfficialCd1,
                    paymentMethodCd: paymentMethodCd1 == '' ? mainList2.payment_method_cd : paymentMethodCd1,
                    paymentDay: paymentDay1 == '' ? mainList2.payment_day : paymentDay1,
                    paymentDate: paymentDate1 == '' ? mainList2.payment_date : paymentDate1,
                    paybackSdt: paybackSdt1 == '' ? mainList2.payback_sdt : paybackSdt1,
                    paybackEdt: paybackEdt1 == '' ? mainList2.payback_edt : paybackEdt1,
                    adminNo: userInfoId
                }).then((res) => {
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }
            alert("수정완료");
        }
    }


    const updateWeekPaybackBtnState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlDwpWeekPaybackSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                paybackCd: '02',
                useYn: useYn2 == '' ? mainList3.use_yn : useYn2,
                paybackBounsPercent: paybackBounsPercent2?parseInt(paybackBounsPercent2):mainList3.payback_bouns_percent,
                minPaymentAmt: minPaymentAmt2?parseInt(minPaymentAmt2):mainList3.min_payment_amt,
                maxPaymentAmt: maxPaymentAmt2?parseInt(maxPaymentAmt2):mainList3.max_payment_amt,
                chargeAmtMore: chargeAmtMore2?parseInt(chargeAmtMore2):mainList3.charge_amt_more,
                manyDayAttendance: manyDayAttendance2?parseInt(manyDayAttendance2):mainList3.many_day_attendance,
                manyCntMore: manyCntMore2?parseInt(manyCntMore2):mainList3.many_cnt_more,
                monthPaymentOfficial: monthPaymentOfficial2?parseInt(monthPaymentOfficial2):mainList3.month_payment_official,
                totalPaymentOfficial: totalPaymentOfficial2?parseInt(totalPaymentOfficial2):mainList3.total_payment_official,
                paymentOfficialCd: paymentOfficialCd2 == '' ? mainList3.payment_official_cd : paymentOfficialCd2,
                paymentMethodCd: paymentMethodCd2 == '' ? mainList3.payment_method_cd : paymentMethodCd2,
                paymentDay: paymentDay2 == '' ? mainList3.payment_day : paymentDay2,
                paymentDate: paymentDate2 == '' ? mainList3.payment_date : paymentDate2,
                paybackSdt: paybackSdt2 == '' ? mainList3.payback_sdt : paybackSdt2,
                paybackEdt: paybackEdt2 == '' ? mainList3.payback_edt : paybackEdt2,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }

    const updateWeekPaybackAllBtnState = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos3.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos3.length; i++)
            {
                axios.post('/admin/setting/saveLvlDwpWeekPaybackSetting', {
                    pntGbCd: initialState.selectedNos3[i],
                    paybackCd: '02',
                    useYn: useYn2 == '' ? mainList3.use_yn : useYn2,
                    paybackBounsPercent: paybackBounsPercent2?parseInt(paybackBounsPercent2):mainList3.payback_bouns_percent,
                    minPaymentAmt: minPaymentAmt2?parseInt(minPaymentAmt2):mainList3.min_payment_amt,
                    maxPaymentAmt: maxPaymentAmt2?parseInt(maxPaymentAmt2):mainList3.max_payment_amt,
                    chargeAmtMore: chargeAmtMore2?parseInt(chargeAmtMore2):mainList3.charge_amt_more,
                    manyDayAttendance: manyDayAttendance2?parseInt(manyDayAttendance2):mainList3.many_day_attendance,
                    manyCntMore: manyCntMore2?parseInt(manyCntMore2):mainList3.many_cnt_more,
                    monthPaymentOfficial: monthPaymentOfficial2?parseInt(monthPaymentOfficial2):mainList3.month_payment_official,
                    totalPaymentOfficial: totalPaymentOfficial2?parseInt(totalPaymentOfficial2):mainList3.total_payment_official,
                    paymentOfficialCd: paymentOfficialCd2 == '' ? mainList3.payment_official_cd : paymentOfficialCd2,
                    paymentMethodCd: paymentMethodCd2 == '' ? mainList3.payment_method_cd : paymentMethodCd2,
                    paymentDay: paymentDay2 == '' ? mainList3.payment_day : paymentDay2,
                    paymentDate: paymentDate2 == '' ? mainList3.payment_date : paymentDate2,
                    paybackSdt: paybackSdt2 == '' ? mainList3.payback_sdt : paybackSdt2,
                    paybackEdt: paybackEdt2 == '' ? mainList3.payback_edt : paybackEdt2,
                    adminNo: userInfoId
                }).then((res) => {
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }
            alert("수정완료");
        }
    }


    const updateMonthPaybackBtnState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlDwpMonthPaybackSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                paybackCd: '03',
                useYn: useYn3 == '' ? mainList4.use_yn : useYn3,
                paybackBounsPercent: paybackBounsPercent3?parseInt(paybackBounsPercent3):mainList4.payback_bouns_percent,
                minPaymentAmt: minPaymentAmt3?parseInt(minPaymentAmt3):mainList4.min_payment_amt,
                maxPaymentAmt: maxPaymentAmt3?parseInt(maxPaymentAmt3):mainList4.max_payment_amt,
                chargeAmtMore: chargeAmtMore3?parseInt(chargeAmtMore3):mainList4.charge_amt_more,
                manyDayAttendance: manyDayAttendance3?parseInt(manyDayAttendance3):mainList4.many_day_attendance,
                manyCntMore: manyCntMore3?parseInt(manyCntMore3):mainList4.many_cnt_more,
                monthPaymentOfficial: monthPaymentOfficial3?parseInt(monthPaymentOfficial3):mainList4.month_payment_official,
                totalPaymentOfficial: totalPaymentOfficial3?parseInt(totalPaymentOfficial3):mainList4.total_payment_official,
                paymentOfficialCd: paymentOfficialCd3 == '' ? mainList4.payment_official_cd : paymentOfficialCd3,
                paymentMethodCd: paymentMethodCd3 == '' ? mainList4.payment_method_cd : paymentMethodCd3,
                paymentDay: paymentDay3 == '' ? mainList4.payment_day : paymentDay3,
                paymentDate: paymentDate3 == '' ? mainList4.payment_date : paymentDate3,
                paybackSdt: paybackSdt3 == '' ? mainList4.payback_sdt : paybackSdt3,
                paybackEdt: paybackEdt3 == '' ? mainList4.payback_edt : paybackEdt3,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }

    const updateMonthPaybackAllBtnState = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos4.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos4.length; i++)
            {
                axios.post('/admin/setting/saveLvlDwpMonthPaybackSetting', {
                    pntGbCd: initialState.selectedNos4[i],
                    paybackCd: '03',
                    useYn: useYn3 == '' ? mainList4.use_yn : useYn3,
                    paybackBounsPercent: paybackBounsPercent3?parseInt(paybackBounsPercent3):mainList4.payback_bouns_percent,
                    minPaymentAmt: minPaymentAmt3?parseInt(minPaymentAmt3):mainList4.min_payment_amt,
                    maxPaymentAmt: maxPaymentAmt3?parseInt(maxPaymentAmt3):mainList4.max_payment_amt,
                    chargeAmtMore: chargeAmtMore3?parseInt(chargeAmtMore3):mainList4.charge_amt_more,
                    manyDayAttendance: manyDayAttendance3?parseInt(manyDayAttendance3):mainList4.many_day_attendance,
                    manyCntMore: manyCntMore3?parseInt(manyCntMore3):mainList4.many_cnt_more,
                    monthPaymentOfficial: monthPaymentOfficial3?parseInt(monthPaymentOfficial3):mainList4.month_payment_official,
                    totalPaymentOfficial: totalPaymentOfficial3?parseInt(totalPaymentOfficial3):mainList4.total_payment_official,
                    paymentOfficialCd: paymentOfficialCd3 == '' ? mainList4.payment_official_cd : paymentOfficialCd3,
                    paymentMethodCd: paymentMethodCd3 == '' ? mainList4.payment_method_cd : paymentMethodCd3,
                    paymentDay: paymentDay3 == '' ? mainList4.payment_day : paymentDay3,
                    paymentDate: paymentDate3 == '' ? mainList4.payment_date : paymentDate3,
                    paybackSdt: paybackSdt3 == '' ? mainList4.payback_sdt : paybackSdt3,
                    paybackEdt: paybackEdt3 == '' ? mainList4.payback_edt : paybackEdt3,
                    adminNo: userInfoId
                }).then((res) => {
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }
            alert("수정완료");
        }
    }



    const updateDisposablePaybackBtnState = (state, depositNos) => {

        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlDwpDisposablePaybackSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                paybackCd: '04',
                useYn: useYn4 == '' ? mainList5.use_yn : useYn4,
                paybackBounsPercent: paybackBounsPercent4?parseInt(paybackBounsPercent4):mainList5.payback_bouns_percent,
                minPaymentAmt: minPaymentAmt4?parseInt(minPaymentAmt4):mainList5.min_payment_amt,
                maxPaymentAmt: maxPaymentAmt4?parseInt(maxPaymentAmt4):mainList5.max_payment_amt,
                chargeAmtMore: chargeAmtMore4?parseInt(chargeAmtMore4):mainList5.charge_amt_more,
                manyDayAttendance: manyDayAttendance4?parseInt(manyDayAttendance4):mainList5.many_day_attendance,
                manyCntMore: manyCntMore4?parseInt(manyCntMore4):mainList5.many_cnt_more,
                monthPaymentOfficial: monthPaymentOfficial4?parseInt(monthPaymentOfficial4):mainList5.month_payment_official,
                totalPaymentOfficial: totalPaymentOfficial4?parseInt(totalPaymentOfficial4):mainList5.total_payment_official,
                paymentOfficialCd: paymentOfficialCd4 == '' ? mainList5.payment_official_cd : paymentOfficialCd4,
                paymentMethodCd: paymentMethodCd4 == '' ? mainList5.payment_method_cd : paymentMethodCd4,
                paymentDay: paymentDay4 == '' ? mainList5.payment_day : paymentDay4,
                paymentDate: paymentDate4 == '' ? mainList5.payment_date : paymentDate4,
                paybackSdt: startDate == '' ? mainList5.payback_sdt : startDate,
                paybackEdt: endDate == '' ? mainList5.payback_edt : endDate,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }

    const updateDisposablePaybackAllBtnState = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos5.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos5.length; i++)
            {
                axios.post('/admin/setting/saveLvlDwpDisposablePaybackSetting', {
                    pntGbCd: initialState.selectedNos5[i],
                    paybackCd: '04',
                    useYn: useYn4 == '' ? mainList5.use_yn : useYn4,
                    paybackBounsPercent: paybackBounsPercent4?parseInt(paybackBounsPercent4):mainList5.payback_bouns_percent,
                    minPaymentAmt: minPaymentAmt4?parseInt(minPaymentAmt4):mainList5.min_payment_amt,
                    maxPaymentAmt: maxPaymentAmt4?parseInt(maxPaymentAmt4):mainList5.max_payment_amt,
                    chargeAmtMore: chargeAmtMore4?parseInt(chargeAmtMore4):mainList5.charge_amt_more,
                    manyDayAttendance: manyDayAttendance4?parseInt(manyDayAttendance4):mainList5.many_day_attendance,
                    manyCntMore: manyCntMore4?parseInt(manyCntMore4):mainList5.many_cnt_more,
                    monthPaymentOfficial: monthPaymentOfficial4?parseInt(monthPaymentOfficial4):mainList5.month_payment_official,
                    totalPaymentOfficial: totalPaymentOfficial4?parseInt(totalPaymentOfficial4):mainList5.total_payment_official,
                    paymentOfficialCd: paymentOfficialCd4 == '' ? mainList5.payment_official_cd : paymentOfficialCd4,
                    paymentMethodCd: paymentMethodCd4 == '' ? mainList5.payment_method_cd : paymentMethodCd4,
                    paymentDay: paymentDay4 == '' ? mainList5.payment_day : paymentDay4,
                    paymentDate: paymentDate4 == '' ? mainList5.payment_date : paymentDate4,
                    paybackSdt: paybackSdt4 == '' ? mainList5.payback_sdt : paybackSdt4,
                    paybackEdt: paybackEdt4 == '' ? mainList5.payback_edt : paybackEdt4,
                    adminNo: userInfoId
                }).then((res) => {
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }
            alert("수정완료");
        }
    }

    const searchChangeHandler = (no, pntGbCd) => {
        //alert("no : "+no+" || pntGbCd : "+pntGbCd);
        setBonusQuery(no);
        setChargeBounsNo(no);

        axios.post('/admin/setting/settingPaymentQuery', {
            no : no,
            pntGbCd: pntGbCd
        }).then((res) => {

            //alert("res.data.countList2 : "+JSON.stringify(res.data.countList2));

            setPaymentCd(res.data.countList2 ? res.data.countList2.payment_cd : 0);
            setFirstChargeBonusPercent(res.data.countList2 ? res.data.countList2.first_charge_bonus_percent : 0);
            setEveryChargeBonusPercent(res.data.countList2 ? res.data.countList2.every_charge_bonus_percent : 0);
            setMaxBonusCnt(res.data.countList2 ? res.data.countList2.max_bonus_cnt : 0);
            setMaxBonusDay(res.data.countList2 ? res.data.countList2.max_bonus_day : 0);
            setBounsLimitMoney(res.data.countList2 ? res.data.countList2.bouns_limit_money : 0);
            setBounsLimitPoint(res.data.countList2 ? res.data.countList2.bouns_limit_point : 0);
            setWithdrawFirstChargeBonus(res.data.countList2 ? res.data.countList2.withdraw_first_charge_bonus : 0);
            setWithdrawEveryChargeBonus(res.data.countList2 ? res.data.countList2.withdraw_every_charge_bonus : 0);
            setOutbreakLimitYn(res.data.countList2 ? res.data.countList2.outbreak_limit_yn : 'N');
            setOutbreakBonusDayCnt(res.data.countList2 ? res.data.countList2.outbreak_bonus_day_cnt : 0);

            //alert("res.data.countList2.FirstChargeBonusPercent : "+firstChargeBonusPercent);
        })
    }

    const checkHandler1 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos1([...initialState.selectedNos1, no]))
        }
        else {
            dispatch(setSelectedNos1(initialState.selectedNos1.filter((id) => id !== no)))
        }
    }
    const checkHandler2 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos2([...initialState.selectedNos2, no]))
        }
        else {
            dispatch(setSelectedNos2(initialState.selectedNos2.filter((id) => id !== no)))
        }
    }
    const checkHandler3 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos3([...initialState.selectedNos3, no]))
        }
        else {
            dispatch(setSelectedNos3(initialState.selectedNos3.filter((id) => id !== no)))
        }
    }
    const checkHandler4 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos4([...initialState.selectedNos4, no]))
        }
        else {
            dispatch(setSelectedNos4(initialState.selectedNos4.filter((id) => id !== no)))
        }
    }
    const checkHandler5 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos5([...initialState.selectedNos5, no]))
        }
        else {
            dispatch(setSelectedNos5(initialState.selectedNos5.filter((id) => id !== no)))
        }
    }

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }

    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const outbreakLimitYnChangeHandler = (e) => {
        setOutbreakLimitYn(e.currentTarget.value);
    }


    const insertDwpAddChargeLvl = () => {
        if (window.confirm("추가 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/insertDwpAddChargeLvl', {
                pntGbCd: mainList1?.pnt_gb_cd,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }

    const updatAddChargeBtnState = (state, depositNos) => {

        alert("everyChargeBonusPercent :: "+everyChargeBonusPercent);

        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveDwpAddChargeLvl', {
                pntGbCd: mainList1?.pnt_gb_cd,
                paymentCd: paymentCd,
                firstChargeBonusPercent: firstChargeBonusPercent,
                everyChargeBonusPercent: everyChargeBonusPercent,
                maxBonusCnt: maxBonusCnt,
                maxBonusDay: maxBonusDay,
                bounsLimitMoney: bounsLimitMoney,
                bounsLimitPoint: bounsLimitPoint,
                withdrawFirstChargeBonus: withdrawFirstChargeBonus,
                withdrawEveryChargeBonus: withdrawEveryChargeBonus,
                outbreakLimitYn: outbreakLimitYn,
                outbreakBonusDayCnt: outbreakBonusDayCnt,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }

    const insertDwpAddChargeList = () => {
        if (window.confirm("추가 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            //alert("sportsCd >> "+sportsCd);

            axios.post('/admin/setting/insertDwpAddChargeList', {
                pntGbCd: mainList1?.pnt_gb_cd,
                sportsCd: sportsCd ? sportsCd : "01",
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "추가완료" : "추가실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }


    const sportsCdChangeHandler = (e) => {
        setSportsCd(e.currentTarget.value);
    }


    const cntArrHandler = (e, index) => {
        cntArr[index] = e.target.value;
        setCntArr(cntArr);
    }

    const paymentMethodArrHandler = (e, index) => {
        const rows = [...initialState.paymentMethodArr];
        rows[index] = e.target.value;
        dispatch(setPaymentMethodArr(rows));
    }

    const couponMethodArrHandler = (e, index) => {
        const rows = [...initialState.couponMethodArr];
        rows[index] = e.target.value;
        dispatch(setCouponMethodArr(rows));
    }

    const rollingPercentArrHandler = (e, index) => {
        rollingPercentArr[index] = e.target.value;
        setRollingPercentArr(rollingPercentArr);
    }

    const outbreakPercentArrHandler = (e, index) => {
        outbreakPercentArr[index] = e.target.value;
        setOutbreakPercentArr(outbreakPercentArr);
    }
    const outbreakSdtArrHandler = (e, index) => {
        const rows = [...initialState.outbreakSdtArr];
        rows[index] = e.target.value;
        //alert("index : "+index);
        dispatch(setOutbreakSdtArr(rows));
        //alert("outbreakSdtArr[index] :: "+initialState.outbreakSdtArr[index]);
    }
    const outbreakEdtArrHandler = (e, index) => {
        const rows = [...initialState.outbreakEdtArr];
        rows[index] = e.target.value;
        //alert("index : "+index);
        dispatch(setOutbreakEdtArr(rows));
    }

    const updateArrayState = (state, no) => {
        // alert("lvlSetting.no ::: "+cntArr[state]?cntArr[state]:dwpSetting.cnt);
        // alert("outbreakPercentArr[state] :: "+outbreakPercentArr[state]);
        // alert("rollingPercentArr[state] :: "+rollingPercentArr[state]);
        //alert("outbreakPercentArr[state] :: "+initialState.outbreakSdtArr[state]);
        if (window.confirm("수정 하시겠습니까?"))
        {

            axios.post('/admin/setting/saveDwpArraySetting', {
                eventNo: state,
                cnt: cntArr[state]?cntArr[state]:dwpSetting.cnt,
                paymentMethod: initialState.paymentMethodArr[state]?initialState.paymentMethodArr[state]:dwpSetting.payment_cd,
                couponMethod: initialState.couponMethodArr[state]?initialState.couponMethodArr[state]:dwpSetting.coupon_pay_cd,
                rollingPercent: rollingPercentArr[state]?rollingPercentArr[state]:dwpSetting.rolling_percent,
                outbreakSdt: initialState.outbreakSdtArr[state]?initialState.outbreakSdtArr[state]:dwpSetting.outbreak_sdt,
                outbreakEdt: initialState.outbreakEdtArr[state]?initialState.outbreakEdtArr[state]:dwpSetting.outbreak_edt,
                outbreakPercent: outbreakPercentArr[state]?outbreakPercentArr[state]:dwpSetting.outbreak_percent,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                window.location.reload();
            })

        }
    }


    const deleteArrayState = (state, no) => {
        if (window.confirm("삭제 하시겠습니까?"))
        {
            axios.post('/admin/setting/deleteDwpArraySetting', {
                eventNo: state,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                window.location.reload();
            })

        }
    }



    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "green", border: "1", color: "white",fontSize: "10px", height: "22px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                            onClick={handleClickOpen}>레벨관리(추가,삭제)</button>
                    {initialState.isOpen && <LvlSettingComponent open={initialState.isOpen} onClose={handleClickClose} />}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {levels?.map((item, index) => (
                        <>
                            <button style={{ backgroundColor: initialState.query === item.setting_level_key ? "blue" : "white" , border: "1", color: initialState.query === item.setting_level_key ? "white" : "black",fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                    onClick={(e) => {
                                        onLevelChange(item.setting_level_key)
                                    }}
                            >
                                <b>{item.cmmn_cd_dtl_nm} 레벨</b>
                            </button>
                        </>
                    ))}
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"100%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> 기본설정</b></font>
                        </div>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "15%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최소 충전금액</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.min_deposit_amt)}
                                           value={minDepositAmt} style={{width:"100px"}}
                                           onChange={(e) => setMinDepositAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "15%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 금액단위</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.deposit_amt_unit)}
                                           value={depositAmtUnit} style={{width:"100px"}}
                                           onChange={(e) => setDepositAmtUnit(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "15%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>1회 충전금액</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.one_deposit_amt)}
                                           value={oneDepositAmt} style={{width:"100px"}}
                                           onChange={(e) => setOneDepositAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "15%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 완료 후 재신청</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.deposit_retry_minute)}
                                           value={depositRetryMinute} style={{width:"100px"}}
                                           onChange={(e) => setDepositRetryMinute(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        분
                                    </button>
                                </td>
                                <td style={{width: "15%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 자동 처리</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={autoDepositYnChangeHandler} value={autoDepositYn?autoDepositYn:mainList1.auto_deposit_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "*%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>포인트 최소 전환</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.exchange_min_point)}
                                           value={exchangeMinPoint} style={{width:"100px"}}
                                           onChange={(e) => setExchangeMinPoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        P
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최소 환전금액</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.min_withdraw_amt)}
                                           value={minWithdrawAmt} style={{width:"100px"}}
                                           onChange={(e) => setMinWithdrawAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>환전 금액단위</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.withdraw_amt_unit)}
                                           value={withdrawAmtUnit} style={{width:"100px"}}
                                           onChange={(e) => setWithdrawAmtUnit(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>1회 환전금액</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.one_withdraw_amt)}
                                           value={oneWithdrawAmt} style={{width:"100px"}}
                                           onChange={(e) => setOneWithdrawAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>환전 완료 후 재신청</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.withdraw_retry_minute)}
                                           value={withdrawRetryMinute} style={{width:"100px"}}
                                           onChange={(e) => setWithdrawRetryMinute(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        분
                                    </button>
                                </td>
                                <td style={{width: "15%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>환전 1일 최대 횟수</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.day_max_withdraw_amt)}
                                           value={dayMaxWithdrawAmt} style={{width:"100px"}}
                                           onChange={(e) => setDayMaxWithdrawAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        회
                                    </button>
                                </td>
                                <td style={{width: "*%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>콤프 최소 전환</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.comp_min_point)}
                                           value={compMinPoint} style={{width:"100px"}}
                                           onChange={(e) => setCompMinPoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        P
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={"6"} align={"center"}>
                                    <table>
                                        <tr>
                                            <td>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDefaultBtnState()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDefaultAllBtnState()}}
                                                >
                                                    선택 레벨 일괄적용
                                                </button>
                                            </td>
                                            <td>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio11"+item.setting_level_no} id={"inlineRadio11"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos1.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler1(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio11' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio11"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>



                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"100%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> 충전 보너스 설정</b></font>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"48.5%"}}>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "99%",backgroundColor: "white"}} align={"right"}>
                                    종목&nbsp;&nbsp;
                                    <select
                                        onChange={sportsCdChangeHandler} value={sportsCd}
                                    >
                                        <option value={'01'}>스포츠</option>
                                        <option value={'02'}>슬롯</option>
                                        <option value={'03'}>카지노</option>
                                        <option value={'04'}>미니게임</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                            onClick={() => {insertDwpAddChargeList()}}
                                    >
                                        <b>추가</b>
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center">
                            <thead >
                            <tr>
                                <th style={{width: "*%",backgroundColor: "lightgray"}}>설명</th>
                                <th style={{width: "10%",backgroundColor: "lightgray"}}>지급방식</th>
                                <th style={{width: "10%",backgroundColor: "lightgray"}}>쿠폰지급</th>
                                <th style={{width: "10%",backgroundColor: "lightgray"}}>롤링%</th>
                                <th style={{width: "12%",backgroundColor: "lightgray"}}>돌발시작</th>
                                <th style={{width: "12%",backgroundColor: "lightgray"}}>돌발종료</th>
                                <th style={{width: "10%",backgroundColor: "lightgray"}}>돌발%</th>
                                <th style={{width: "12%",backgroundColor: "lightgray"}}>
                                </th>
                            </tr>
                            </thead>
                            {!dwpSetting.length && (
                                <tbody key={dwpSetting.event_no}>
                                <tr>
                                    <td colSpan="8">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}
                            {dwpSetting?.map((dwpSetting, index) => (
                                <tbody key={dwpSetting.event_no}>
                                <tr>
                                    <td align={"center"}>
                                        <input className="form-check-input sm-input" type="text" style={{width: "90%"}}
                                               placeholder={dwpSetting.cnt}
                                               onChange={(e) => {cntArrHandler(e, dwpSetting.event_no)}}
                                        />
                                    </td>
                                    <td align={"center"}>
                                        <select  style={{width: "100%", height:"20px"}}
                                                 onChange={(e) => {paymentMethodArrHandler(e, dwpSetting.event_no)}}
                                                 value={initialState.paymentMethodArr[dwpSetting.event_no]?initialState.paymentMethodArr[dwpSetting.event_no]:dwpSetting.payment_cd}
                                        >
                                            {Array(mainList6.payment_cd).fill(0).map((_,i) =>(
                                                <option value={i+1}>지급 {i+1}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td align={"center"}>
                                        <select  style={{width: "100%", height:"20px"}}
                                                 onChange={(e) => {couponMethodArrHandler(e, dwpSetting.event_no)}}
                                                 value={initialState.couponMethodArr[dwpSetting.event_no]?initialState.couponMethodArr[dwpSetting.event_no]:dwpSetting.coupon_pay_cd}
                                        >
                                            <option value={"Y"}>사용</option>
                                            <option value={"N"}>미사용</option>
                                        </select>
                                    </td>
                                    <td align={"center"}>
                                        <input className="form-check-input sm-input" type="text" style={{width: "90%"}}
                                               placeholder={dwpSetting.rolling_percent}
                                               onChange={(e) => {rollingPercentArrHandler(e, dwpSetting.event_no)}}
                                        />
                                    </td>
                                    <td>
                                        <input onChange={(e) => {outbreakSdtArrHandler(e, dwpSetting.event_no)}} className="form-control sm-date" type="date" value={initialState.outbreakSdtArr[dwpSetting.event_no]?initialState.outbreakSdtArr[dwpSetting.event_no]:dwpSetting.outbreak_sdt} style={{width:"95px"}}/>
                                    </td>
                                    <td>
                                        <input onChange={(e) => {outbreakEdtArrHandler(e, dwpSetting.event_no)}} className="form-control sm-date" type="date" value={initialState.outbreakEdtArr[dwpSetting.event_no]?initialState.outbreakEdtArr[dwpSetting.event_no]:dwpSetting.outbreak_edt} style={{width:"95px"}}/>
                                    </td>
                                    <td align={"center"}>
                                        <input className="form-check-input sm-input" type="text" style={{width: "90%"}}
                                               placeholder={dwpSetting.outbreak_percent}
                                               onChange={(e) => {outbreakPercentArrHandler(e, dwpSetting.event_no)}}
                                        />
                                    </td>
                                    <button style={{ backgroundColor: "lightskyblue", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "30px", marginRight: "5px", marginTop:"1px"}}
                                            onClick={() => {updateArrayState(dwpSetting.event_no)}}
                                    >
                                        <font color={"white"}>변경</font>
                                    </button>
                                    <button style={{ backgroundColor: "red", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "30px", marginRight: "5px", marginTop:"1px"}}
                                            onClick={() => {deleteArrayState(dwpSetting.event_no)}}
                                    >
                                        <font color={"white"}>삭제</font>
                                    </button>
                                </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>
                    <div style={{width:"3%"}}></div>
                    <div style={{width:"48.5%"}}>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                                    {Array(mainList6.payment_cd).fill(0).map((_,i) =>(
                                        <button style={{ backgroundColor: chargeBounsNo === i+1 ? "blue" : "white" , border: "1", color: chargeBounsNo === i+1 ? "white" : "black",fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                onClick={() => searchChangeHandler(i+1, mainList1?.pnt_gb_cd)}
                                        >
                                            <b>지급 {i+1} </b>
                                        </button>
                                    ))}
                                </td>
                                <td style={{width: "50%",backgroundColor: "white"}} align={"right"}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                            onClick={(e) => {
                                                insertDwpAddChargeLvl()
                                            }}
                                    >
                                        <b>추가</b>
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "30%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>첫 충전 보너스</b></font>
                                </td>
                                <td style={{width: "19%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text" style={{width:"70px"}}
                                           placeholder={firstChargeBonusPercent ? firstChargeBonusPercent : 0}
                                           value={firstChargeBonusPercent}
                                           onChange={(e) => setFirstChargeBonusPercent(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white"}} align={"left"}>
                                </td>
                                <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                                    <font color={"black"}><b>매 충전 보너스</b></font>
                                </td>
                                <td style={{width: "19%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text" style={{width:"70px"}}
                                           placeholder={everyChargeBonusPercent ? everyChargeBonusPercent : 0}
                                           value={everyChargeBonusPercent}
                                           onChange={(e) => setEveryChargeBonusPercent(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "30%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최대 보너스 지급(1회)</b></font>
                                </td>
                                <td style={{width: "19%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text" style={{width:"70px"}}
                                           placeholder={maxBonusCnt ? maxBonusCnt : 0}
                                           value={maxBonusCnt}
                                           onChange={(e) => setMaxBonusCnt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white"}} align={"left"}>
                                </td>
                                <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                                    <font color={"black"}><b>최대 보너스 지급(1일)</b></font>
                                </td>
                                <td style={{width: "19%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text" style={{width:"70px"}}
                                           placeholder={maxBonusDay ? maxBonusDay : 0}
                                           value={maxBonusDay}
                                           onChange={(e) => setMaxBonusDay(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "30%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>보너스제한 보유머니</b></font>
                                </td>
                                <td style={{width: "19%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text" style={{width:"70px"}}
                                           placeholder={bounsLimitMoney ? bounsLimitMoney : 0}
                                           value={bounsLimitMoney}
                                           onChange={(e) => setBounsLimitMoney(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white"}} align={"left"}>
                                </td>
                                <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                                    <font color={"black"}><b>보너스제한 보유+포인트</b></font>
                                </td>
                                <td style={{width: "19%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text" style={{width:"70px"}}
                                           placeholder={bounsLimitPoint ? bounsLimitPoint : 0}
                                           value={bounsLimitPoint}
                                           onChange={(e) => setBounsLimitPoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "30%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>환전 후 첫 충전 보너스</b></font>
                                </td>
                                <td style={{width: "19%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text" style={{width:"70px"}}
                                           placeholder={withdrawFirstChargeBonus ? withdrawFirstChargeBonus : 0}
                                           value={withdrawFirstChargeBonus}
                                           onChange={(e) => setWithdrawFirstChargeBonus(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white"}} align={"left"}>
                                </td>
                                <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                                    <font color={"black"}><b>환전 후 매 충전 보너스</b></font>
                                </td>
                                <td style={{width: "19%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text" style={{width:"70px"}}
                                           placeholder={withdrawEveryChargeBonus ? withdrawEveryChargeBonus : 0}
                                           value={withdrawEveryChargeBonus}
                                           onChange={(e) => setWithdrawEveryChargeBonus(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "30%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>돌발 환전 후 보너스제한</b></font>
                                </td>
                                <td style={{width: "19%",backgroundColor: "white"}} align={"right"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={outbreakLimitYnChangeHandler} value={outbreakLimitYn?outbreakLimitYn:'N'}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white"}} align={"left"}>
                                </td>
                                <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                                    <font color={"black"}><b>돌발 보너스 일 참여 횟수</b></font>
                                </td>
                                <td style={{width: "19%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text" style={{width:"70px"}}
                                           placeholder={outbreakBonusDayCnt ? outbreakBonusDayCnt : 0}
                                           value={outbreakBonusDayCnt}
                                           onChange={(e) => setOutbreakBonusDayCnt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        회
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={"5"} align={"center"}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={() => {updatAddChargeBtnState()}}
                                    >
                                        변경
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"100%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> 페이백 설정</b></font>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"48.5%"}}>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 일별 페이백</b></font>
                                </td>
                                <td style={{width: "50%",backgroundColor: "white"}} align={"right"}>
                                    <select
                                        onChange={useYn1ChangeHandler} value={useYn1?useYn1:mainList2.use_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>페이백 보너스</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList2.payback_bouns_percent)}
                                           value={paybackBounsPercent1} style={{width:"50px"}}
                                           onChange={(e) => setPaybackBounsPercent1(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최소 지급금액</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList2.min_payment_amt)}
                                           value={minPaymentAmt1} style={{width:"50px"}}
                                           onChange={(e) => setMinPaymentAmt1(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최대 지급금액</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList2.max_payment_amt)}
                                           value={maxPaymentAmt1} style={{width:"50px"}}
                                           onChange={(e) => setMaxPaymentAmt1(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 금액 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList2.charge_amt_more)}
                                           value={chargeAmtMore1} style={{width:"50px"}}
                                           onChange={(e) => setChargeAmtMore1(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>몇일 출석체크</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList2.many_day_attendance)}
                                           value={manyDayAttendance1} style={{width:"50px"}}
                                           onChange={(e) => setManyDayAttendance1(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        일
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 몇회 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList2.many_cnt_more)}
                                           value={manyCntMore1} style={{width:"50px"}}
                                           onChange={(e) => setManyCntMore1(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        회
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>월 마진 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList2.month_payment_official)}
                                           value={monthPaymentOfficial1} style={{width:"50px"}}
                                           onChange={(e) => setMonthPaymentOfficial1(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>총 마진 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList2.total_payment_official)}
                                           value={totalPaymentOfficial1} style={{width:"50px"}}
                                           onChange={(e) => setTotalPaymentOfficial1(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급 공식</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentOfficialCd1ChangeHandler} value={paymentOfficialCd1?paymentOfficialCd1:mainList2.payment_official_cd}
                                    >
                                        <option value={'01'}>입-출-보-포-배</option>
                                        <option value={'02'}>입-출-보-배</option>
                                    </select>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급방식 </b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentMethodCd1ChangeHandler} value={paymentMethodCd1?paymentMethodCd1:mainList2.payment_method_cd}
                                    >
                                        <option value={'01'}>자동</option>
                                        <option value={'02'}>수동</option>
                                    </select>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급일시</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentDay1ChangeHandler} value={paymentDay1?paymentDay1:mainList2.payment_day}
                                    >
                                        <option value={'00'}>00시</option>
                                        <option value={'01'}>01시</option>
                                        <option value={'02'}>02시</option>
                                        <option value={'03'}>03시</option>
                                        <option value={'04'}>04시</option>
                                        <option value={'05'}>05시</option>
                                        <option value={'06'}>06시</option>
                                        <option value={'07'}>07시</option>
                                        <option value={'08'}>08시</option>
                                        <option value={'09'}>09시</option>
                                        <option value={'10'}>10시</option>
                                        <option value={'11'}>11시</option>
                                        <option value={'12'}>12시</option>
                                        <option value={'13'}>13시</option>
                                        <option value={'14'}>14시</option>
                                        <option value={'15'}>15시</option>
                                        <option value={'16'}>16시</option>
                                        <option value={'17'}>17시</option>
                                        <option value={'18'}>18시</option>
                                        <option value={'19'}>19시</option>
                                        <option value={'20'}>20시</option>
                                        <option value={'21'}>21시</option>
                                        <option value={'22'}>22시</option>
                                        <option value={'23'}>23시</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={"8"} align={"center"}>
                                    <table>
                                        <tr>
                                            <td>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDayPaybackBtnState()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDayPaybackAllBtnState()}}
                                                >
                                                    선택 레벨 일괄적용
                                                </button>
                                            </td>
                                            <td>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio12"+item.setting_level_no} id={"inlineRadio12"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos2.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler2(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio12' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio12/"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style={{width:"3%"}}></div>
                    <div style={{width:"48.5%"}}>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 주별 페이백</b></font>
                                </td>
                                <td style={{width: "50%",backgroundColor: "white"}} align={"right"}>
                                    <select
                                        onChange={useYn2ChangeHandler} value={useYn2?useYn2:mainList3.use_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>페이백 보너스</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList3.payback_bouns_percent)}
                                           value={paybackBounsPercent2} style={{width:"50px"}}
                                           onChange={(e) => setPaybackBounsPercent2(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최소 지급금액</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList3.min_payment_amt)}
                                           value={minPaymentAmt2} style={{width:"50px"}}
                                           onChange={(e) => setMinPaymentAmt2(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최대 지급금액</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList3.max_payment_amt)}
                                           value={maxPaymentAmt2} style={{width:"50px"}}
                                           onChange={(e) => setMaxPaymentAmt2(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 금액 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList3.charge_amt_more)}
                                           value={chargeAmtMore2} style={{width:"50px"}}
                                           onChange={(e) => setChargeAmtMore2(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>몇일 출석체크</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList3.many_day_attendance)}
                                           value={manyDayAttendance2} style={{width:"50px"}}
                                           onChange={(e) => setManyDayAttendance2(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        일
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 몇회 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList3.many_cnt_more)}
                                           value={manyCntMore2} style={{width:"50px"}}
                                           onChange={(e) => setManyCntMore2(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        회
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>월 마진 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList3.month_payment_official)}
                                           value={monthPaymentOfficial2} style={{width:"50px"}}
                                           onChange={(e) => setMonthPaymentOfficial2(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>총 마진 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList3.total_payment_official)}
                                           value={totalPaymentOfficial2} style={{width:"50px"}}
                                           onChange={(e) => setTotalPaymentOfficial2(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급 공식</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentOfficialCd2ChangeHandler} value={paymentOfficialCd2?paymentOfficialCd2:mainList3.payment_official_cd}
                                    >
                                        <option>입-출-보-포-배</option>
                                    </select>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급방식 </b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentMethodCd2ChangeHandler} value={paymentMethodCd2?paymentMethodCd2:mainList3.payment_method_cd}
                                    >
                                        <option>자동</option>
                                        <option>수동</option>
                                    </select>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "7%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급일시</b></font>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentDate2ChangeHandler} value={paymentDate2?paymentDate2:mainList3.payment_date}
                                    >
                                        <option value={'01'}>월요일</option>
                                        <option value={'02'}>화요일</option>
                                        <option value={'03'}>수요일</option>
                                        <option value={'04'}>목요일</option>
                                        <option value={'05'}>금요일</option>
                                        <option value={'06'}>토요일</option>
                                        <option value={'07'}>일요일</option>
                                    </select>&nbsp;
                                    <select
                                        onChange={paymentDay2ChangeHandler} value={paymentDay2?paymentDay2:mainList3.payment_day}
                                    >
                                        <option value={'00'}>00시</option>
                                        <option value={'01'}>01시</option>
                                        <option value={'02'}>02시</option>
                                        <option value={'03'}>03시</option>
                                        <option value={'04'}>04시</option>
                                        <option value={'05'}>05시</option>
                                        <option value={'06'}>06시</option>
                                        <option value={'07'}>07시</option>
                                        <option value={'08'}>08시</option>
                                        <option value={'09'}>09시</option>
                                        <option value={'10'}>10시</option>
                                        <option value={'11'}>11시</option>
                                        <option value={'12'}>12시</option>
                                        <option value={'13'}>13시</option>
                                        <option value={'14'}>14시</option>
                                        <option value={'15'}>15시</option>
                                        <option value={'16'}>16시</option>
                                        <option value={'17'}>17시</option>
                                        <option value={'18'}>18시</option>
                                        <option value={'19'}>19시</option>
                                        <option value={'20'}>20시</option>
                                        <option value={'21'}>21시</option>
                                        <option value={'22'}>22시</option>
                                        <option value={'23'}>23시</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={"8"} align={"center"}>
                                    <table>
                                        <tr>
                                            <td>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateWeekPaybackBtnState()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateWeekPaybackAllBtnState()}}
                                                >
                                                    전체 레벨 일괄적용
                                                </button>
                                            </td>
                                            <td>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio13"+item.setting_level_no} id={"inlineRadio13"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos3.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler3(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio13' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio13"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"48.5%"}}>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 월별 페이백</b></font>
                                </td>
                                <td style={{width: "50%",backgroundColor: "white"}} align={"right"}>
                                    <select
                                        onChange={useYn3ChangeHandler} value={useYn3?useYn3:mainList4.use_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>페이백 보너스</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList4.payback_bouns_percent)}
                                           value={paybackBounsPercent3} style={{width:"50px"}}
                                           onChange={(e) => setPaybackBounsPercent3(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최소 지급금액</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList4.min_payment_amt)}
                                           value={minPaymentAmt3} style={{width:"50px"}}
                                           onChange={(e) => setMinPaymentAmt3(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최대 지급금액</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList4.max_payment_amt)}
                                           value={maxPaymentAmt3} style={{width:"50px"}}
                                           onChange={(e) => setMaxPaymentAmt3(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 금액 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList4.charge_amt_more)}
                                           value={chargeAmtMore3} style={{width:"50px"}}
                                           onChange={(e) => setChargeAmtMore3(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>몇일 출석체크</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList4.many_day_attendance)}
                                           value={manyDayAttendance3} style={{width:"50px"}}
                                           onChange={(e) => setManyDayAttendance3(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        일
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 몇회 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList4.many_cnt_more)}
                                           value={manyCntMore3} style={{width:"50px"}}
                                           onChange={(e) => setManyCntMore3(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        회
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>월 마진 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList4.month_payment_official)}
                                           value={monthPaymentOfficial3} style={{width:"50px"}}
                                           onChange={(e) => setMonthPaymentOfficial3(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>총 마진 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList4.total_payment_official)}
                                           value={totalPaymentOfficial3} style={{width:"50px"}}
                                           onChange={(e) => setTotalPaymentOfficial3(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급 공식</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentOfficialCd3ChangeHandler} value={paymentOfficialCd3?paymentOfficialCd3:mainList4.payment_official_cd}
                                    >
                                        <option value={'01'}>입-출-보-포-배</option>
                                        <option value={'02'}>입-출-보-배</option>
                                    </select>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급방식 </b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentMethodCd3ChangeHandler} value={paymentMethodCd3?paymentMethodCd3:mainList4.payment_method_cd}
                                    >
                                        <option>자동</option>
                                        <option>수동</option>
                                    </select>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급일시</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentDay3ChangeHandler} value={paymentDay3?paymentDay3:mainList4.payment_day}
                                    >
                                        <option value={'00'}>00시</option>
                                        <option value={'01'}>01시</option>
                                        <option value={'02'}>02시</option>
                                        <option value={'03'}>03시</option>
                                        <option value={'04'}>04시</option>
                                        <option value={'05'}>05시</option>
                                        <option value={'06'}>06시</option>
                                        <option value={'07'}>07시</option>
                                        <option value={'08'}>08시</option>
                                        <option value={'09'}>09시</option>
                                        <option value={'10'}>10시</option>
                                        <option value={'11'}>11시</option>
                                        <option value={'12'}>12시</option>
                                        <option value={'13'}>13시</option>
                                        <option value={'14'}>14시</option>
                                        <option value={'15'}>15시</option>
                                        <option value={'16'}>16시</option>
                                        <option value={'17'}>17시</option>
                                        <option value={'18'}>18시</option>
                                        <option value={'19'}>19시</option>
                                        <option value={'20'}>20시</option>
                                        <option value={'21'}>21시</option>
                                        <option value={'22'}>22시</option>
                                        <option value={'23'}>23시</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={"8"} align={"center"}>
                                    <table>
                                        <tr>
                                            <td>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateMonthPaybackBtnState()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateMonthPaybackAllBtnState()}}
                                                >
                                                    전체 레벨 일괄적용
                                                </button>
                                            </td>
                                            <td>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio15"+item.setting_level_no} id={"inlineRadio15"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos4.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler4(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio15' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio15"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style={{width:"3%"}}></div>
                    <div style={{width:"48.5%"}}>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 1회용 페이백</b></font>
                                </td>
                                <td style={{width: "50%",backgroundColor: "white"}} align={"right"}>
                                    <select
                                        onChange={useYn4ChangeHandler} value={useYn4?useYn4:mainList5.use_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>페이백 보너스</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList5.payback_bouns_percent)}
                                           value={paybackBounsPercent4} style={{width:"50px"}}
                                           onChange={(e) => setPaybackBounsPercent4(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최소 지급금액</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList5.min_payment_amt)}
                                           value={minPaymentAmt4} style={{width:"50px"}}
                                           onChange={(e) => setMinPaymentAmt4(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최대 지급금액</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList5.max_payment_amt)}
                                           value={maxPaymentAmt4} style={{width:"50px"}}
                                           onChange={(e) => setMaxPaymentAmt4(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 금액 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList5.charge_amt_more)}
                                           value={chargeAmtMore4} style={{width:"50px"}}
                                           onChange={(e) => setChargeAmtMore4(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>몇일 출석체크</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList5.many_day_attendance)}
                                           value={manyDayAttendance4} style={{width:"50px"}}
                                           onChange={(e) => setManyDayAttendance4(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        일
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전 몇회 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList5.many_cnt_more)}
                                           value={manyCntMore4} style={{width:"50px"}}
                                           onChange={(e) => setManyCntMore4(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        회
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>월 마진 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList5.month_payment_official)}
                                           value={monthPaymentOfficial4} style={{width:"50px"}}
                                           onChange={(e) => setMonthPaymentOfficial4(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>총 마진 이상</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList5.total_payment_official)}
                                           value={totalPaymentOfficial4} style={{width:"50px"}}
                                           onChange={(e) => setTotalPaymentOfficial4(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급 공식</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentOfficialCd4ChangeHandler} value={paymentOfficialCd4?paymentOfficialCd4:mainList5.payment_official_cd}
                                    >
                                        <option value={'01'}>입-출-보-포-배</option>
                                        <option value={'02'}>입-출-보-배</option>
                                    </select>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급방식 </b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select
                                        onChange={paymentMethodCd4ChangeHandler} value={paymentMethodCd4?paymentMethodCd4:mainList5.payment_method_cd}
                                    >
                                        <option>자동</option>
                                        <option>수동</option>
                                    </select>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "7%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>페이백시작</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <input className="form-control sm-date" type="date" value={startDate?startDate:mainList5.payback_sdt} onChange={startDateHandler} style={{width:"100px"}}/>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>페이백마감</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>

                                    <input className="form-control sm-date" type="date" value={endDate?endDate:mainList5.payback_edt} onChange={endDateHandler} style={{width:"100px"}}/>
                                </td>
                                <td style={{width: "3%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급일시</b></font>
                                </td>
                                <td style={{width: "11%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    익일&nbsp;
                                    <select
                                        onChange={paymentDay4ChangeHandler} value={paymentDay4?paymentDay4:mainList5.payment_day}
                                    >
                                        <option value={'00'}>00시</option>
                                        <option value={'01'}>01시</option>
                                        <option value={'02'}>02시</option>
                                        <option value={'03'}>03시</option>
                                        <option value={'04'}>04시</option>
                                        <option value={'05'}>05시</option>
                                        <option value={'06'}>06시</option>
                                        <option value={'07'}>07시</option>
                                        <option value={'08'}>08시</option>
                                        <option value={'09'}>09시</option>
                                        <option value={'10'}>10시</option>
                                        <option value={'11'}>11시</option>
                                        <option value={'12'}>12시</option>
                                        <option value={'13'}>13시</option>
                                        <option value={'14'}>14시</option>
                                        <option value={'15'}>15시</option>
                                        <option value={'16'}>16시</option>
                                        <option value={'17'}>17시</option>
                                        <option value={'18'}>18시</option>
                                        <option value={'19'}>19시</option>
                                        <option value={'20'}>20시</option>
                                        <option value={'21'}>21시</option>
                                        <option value={'22'}>22시</option>
                                        <option value={'23'}>23시</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={"8"} align={"center"}>
                                    <table>
                                        <tr>
                                            <td>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDisposablePaybackBtnState()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateDisposablePaybackAllBtnState()}}
                                                >
                                                    전체 레벨 일괄적용
                                                </button>
                                            </td>
                                            <td>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio16"+item.setting_level_no} id={"inlineRadio16"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos5.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler5(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio16' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio16"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>



            </div>
        </div>

    )
}

export default DwpSetting;