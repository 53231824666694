import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const dwpSettingSlice = createSlice({
    name: 'dwpSetting',
    initialState: {
        isLoading: false,
        error: null,
        dwpSetting: [],
        countList: {},
        countList2: {},
        countList3: {},
        countList4: {},
        countList5: {},
        countList6: {},
        countList7: {},
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        query: "01",
        userGbCd: "",
        searchGbCd: "",
        tagNmArr: ["111","222","333"],
        settingLiveYn:"",
        settingSlotYn:"",
        settingMiniYn:"",
        settingSportYn:"",
        settingMiniDtlYn:"",
        settingSportDtlYn:"",
        outbreakSdtArr:[],
        outbreakEdtArr:[],
        paymentMethodArr:[],
        couponMethodArr:[],
        isOpen : false,
        settingSportDomesticYn:"",
        settingSportForeignYn:"",
        settingSportInplayYn:"",
        settingSportSpecialYn:"",
        settingPaymentCycleTime:"",
        settingMini01Yn:"",
        settingMini02Yn:"",
        settingMini03Yn:"",
        settingMini04Yn:"",
        bonusQuery: 1,
        selectedNos1: [],
        selectedNos2: [],
        selectedNos3: [],
        selectedNos4: [],
        selectedNos5: [],
    },
    reducers: {
        getDwpSetting(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userGbCd = action.payload.userGbCd;
            state.searchGbCd = action.payload.searchGbCd;
        },
        setDwpSetting(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.dwpSetting = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList;
            state.countList2 = action.payload.countList2;
            state.countList3 = action.payload.countList3;
            state.countList4 = action.payload.countList4;
            state.countList5 = action.payload.countList5;
            state.countList6 = action.payload.countList6;
            state.countList7 = action.payload.countList7;
        },
        failedGetDwpSetting(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setQuery(state, action){
            state.query = action.payload;
        },
        setSettingLiveYn(state, action){
            state.settingLiveYn = action.payload;
        },
        setSettingSlotYn(state, action){
            state.settingSlotYn = action.payload;
        },
        setSettingMiniYn(state, action){
            state.settingMiniYn = action.payload;
        },
        setSettingSportYn(state, action){
            state.settingSportYn = action.payload;
        },
        setSettingMiniDtlYn(state, action){
            state.settingMiniDtlYn = action.payload;
        },
        setSettingSportDtlYn(state, action){
            state.settingSportDtlYn = action.payload;
        },
        setOutbreakSdtArr(state, action){
            state.outbreakSdtArr = action.payload;
        },
        setOutbreakEdtArr(state, action){
            state.outbreakEdtArr = action.payload;
        },
        setPaymentMethodArr(state, action){
            state.paymentMethodArr = action.payload;
        },
        setCouponMethodArr(state, action){
            state.couponMethodArr = action.payload;
        },
        setIsOpen(state,action){
            state.isOpen = action.payload;
        },
        setSettingSportDomesticYn(state, action){
            state.settingSportDomesticYn = action.payload;
        },
        setSettingSportForeignYn(state, action){
            state.settingSportForeignYn = action.payload;
        },
        setSettingSportInplayYn(state, action){
            state.settingSportInplayYn = action.payload;
        },
        setSettingSportSpecialYn(state, action){
            state.settingSportSpecialYn = action.payload;
        },
        setSettingPaymentCycleTime(state, action){
            state.settingPaymentCycleTime = action.payload;
        },
        setSettingMini01Yn(state, action){
            state.settingMini01Yn = action.payload;
        },
        setSettingMini02Yn(state, action){
            state.settingMini02Yn = action.payload;
        },
        setSettingMini03Yn(state, action){
            state.settingMini03Yn = action.payload;
        },
        setSettingMini04Yn(state, action){
            state.settingMini04Yn = action.payload;
        },
        setBonusQuery(state, action){
            state.bonusQuery = action.payload;
        },
        setSelectedNos1(state,action){
            state.selectedNos1 = action.payload;
        },
        setSelectedNos2(state,action){
            state.selectedNos2 = action.payload;
        },
        setSelectedNos3(state,action){
            state.selectedNos3 = action.payload;
        },
        setSelectedNos4(state,action){
            state.selectedNos4 = action.payload;
        },
        setSelectedNos5(state,action){
            state.selectedNos5 = action.payload;
        },
    }
});

export const { getDwpSetting, setDwpSetting, failedGetDwpSetting, setQuery, setSettingLiveYn, setSettingSlotYn,
    setSettingMiniYn, setSettingSportYn, setSettingMiniDtlYn, setSettingSportDtlYn, setOutbreakSdtArr,
    setOutbreakEdtArr, setCouponMethodArr, setIsOpen, setSettingSportDomesticYn, setSettingSportForeignYn,
    setSettingSportInplayYn, setSettingSportSpecialYn, setSettingPaymentCycleTime, setPaymentMethodArr, setSettingMini01Yn,
    setSettingMini02Yn, setSettingMini03Yn, setSettingMini04Yn, setBonusQuery,
    setSelectedNos1,setSelectedNos2,setSelectedNos3,setSelectedNos4,setSelectedNos5
} = dwpSettingSlice.actions;

export default dwpSettingSlice.reducer;