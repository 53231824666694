import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {
    setSettingLiveYn,
    setSettingSlotYn,
    setSettingMiniYn,
    setSettingSportYn,
    setSettingMiniDtlYn,
    setSettingSportDtlYn,
    setOutbreakSdtArr,
    setOutbreakEdtArr,
    setBonusPaymentMethodArr,
    setIsOpen,
    getLvlSetting,
    setQuery,
    setSettingSportDomesticYn,
    setSettingSportForeignYn,
    setSettingSportInplayYn,
    setSettingSportSpecialYn,
    setSettingPaymentCycleTime,
    setSportPaymentMethodArr,
    setBonusQuery,
    setSelectedNos1,
    setSelectedNos2,
    setSelectedNos3,
    setSelectedNos4,
    setSelectedNos5,
    setSelectedNos6,
    setSelectedNos7,
    setSelectedNos8,
    setSelectedNos9,
    setSelectedNos10,
    setSelectedNos11,
    setSelectedNos12,
    setSelectedNos13
} from "../../redux/reducers/setting/RecommenderSettingListReducer";
import Switch from "react-switch";
import LvlSettingComponent from "../../components/modal/setting/LvlSettingComponent";
import MemberAddComponent from "../MemberAddComponent";

const RecommenderSetting = ({recommenderSetting, onRefresh, initialState, onSearch, mainList1,
                                countList1,countList2,countList3,countList4,countList5,countList6,countList7,countList8,countList9,
                                countList11,countList12,countList13
                            }) => {

    const dispatch = useDispatch()
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [levels, setLevels] = useState([])

    const [updateArr01, setUpdateArr01] = useState([]);

    const [updateArr11, setUpdateArr11] = useState([]);
    const [updateArr12, setUpdateArr12] = useState([]);
    const [updateArr13, setUpdateArr13] = useState([]);
    const [updateArr14, setUpdateArr14] = useState([]);
    const [updateArr15, setUpdateArr15] = useState([]);
    const [updateArr16, setUpdateArr16] = useState([]);
    const [updateArr17, setUpdateArr17] = useState([]);
    const [updateArr18, setUpdateArr18] = useState([]);
    const [updateArr19, setUpdateArr19] = useState([]);


    const [userGbCd, setUserGbCd] = useState("");
    const [userLvlCd, setUserLvlCd] = useState("");
    const [payPoint, setPayPoint] = useState("");
    const [recommenderChangerCd, setreCommenderChangerCd] = useState("");
    const [sportsChangeYn, setSportsChangeYn] = useState("");
    const [sportsPaymentPercent, setSportsPaymentPercent] = useState("");
    const [casinoChangeYn, setCasinoChangeYn] = useState("");
    const [casinoPaymentPercent, setCasinoPaymentPercent] = useState("");
    const [slotChangeYn, setSlotChangeYn] = useState("");
    const [slotPaymentPercent, setSlotPaymentPercent] = useState("");
    const [miniChangeYn, setMiniChangeYn] = useState("");
    const [miniPaymentPercent, setMiniPaymentPercent] = useState("");

    const [useYn01, setUseYn01] = useState("");
    const [useYn02, setUseYn02] = useState("");
    const [useYn03, setUseYn03] = useState("");
    const [useYn04, setUseYn04] = useState("");
    const [useYn05, setUseYn05] = useState("");
    const [useYn06, setUseYn06] = useState("");
    const [useYn07, setUseYn07] = useState("");
    const [useYn08, setUseYn08] = useState("");
    const [useYn09, setUseYn09] = useState("");
    const [betOnePointArr, setBetOnePointArr] = useState([]);
    const [betTwoPointArr, setBetTwoPointArr] = useState([]);
    const [betThreePointArr, setBetThreePointArr] = useState([]);
    const [betFourPointArr, setBetFourPointArr] = useState([]);
    const [betManyPointArr, setBetManyPointArr] = useState([]);
    const [minOneAllocationArr, setMinOneAllocationArr] = useState([]);
    const [minTwoAllocationArr, setMinTwoAllocationArr] = useState([]);
    const [minThreeAllocationArr, setMinThreeAllocationArr] = useState([]);
    const [minFourAllocationArr, setMinFourAllocationArr] = useState([]);
    const [minManyAllocationArr, setMinManyAllocationArr] = useState([]);

    const [useYn11, setUseYn11] = useState("");
    const [useYn12, setUseYn12] = useState("");
    const [useYn13, setUseYn13] = useState("");
    const [betCompArr, setBetCompArr] = useState([]);
    const [winningCompArr, setWinningCompArr] = useState([]);
    const [loseCompArr, setLoseCompArr] = useState([]);
    const [timeMaxCntCompArr, setTimeMaxCntCompArr] = useState([]);
    const [dayMaxCntCompArr, setDayMaxCntCompArr] = useState([]);


    const useYnHandler01 = (e) => {
        setUseYn01(e.target.value);
    }
    const useYnHandler02 = (e) => {
        setUseYn02(e.target.value);
    }
    const useYnHandler03 = (e) => {
        setUseYn03(e.target.value);
    }
    const useYnHandler04 = (e) => {
        setUseYn04(e.target.value);
    }
    const useYnHandler05 = (e) => {
        setUseYn05(e.target.value);
    }
    const useYnHandler06 = (e) => {
        setUseYn06(e.target.value);
    }
    const useYnHandler07 = (e) => {
        setUseYn07(e.target.value);
    }
    const useYnHandler08 = (e) => {
        setUseYn08(e.target.value);
    }
    const useYnHandler09 = (e) => {
        setUseYn09(e.target.value);
    }

    const useYnHandler11 = (e) => {
        setUseYn11(e.target.value);
    }
    const useYnHandler12 = (e) => {
        setUseYn12(e.target.value);
    }
    const useYnHandler13 = (e) => {
        setUseYn13(e.target.value);
    }
    const betCompArrHandler = (e, index) => {
        betCompArr[index] = e;
        setBetCompArr(betCompArr);
    }
    const winningCompArrHandler = (e, index) => {
        winningCompArr[index] = e;
        setWinningCompArr(winningCompArr);
    }
    const loseCompArrHandler = (e, index) => {
        loseCompArr[index] = e;
        setLoseCompArr(loseCompArr);
    }
    const timeMaxCntCompArrHandler = (e, index) => {
        timeMaxCntCompArr[index] = e;
        setTimeMaxCntCompArr(timeMaxCntCompArr);
    }
    const dayMaxCntCompArrHandler = (e, index) => {
        dayMaxCntCompArr[index] = e;
        setDayMaxCntCompArr(dayMaxCntCompArr);
    }


    const betOnePointArrHandler = (e, index) => {
        betOnePointArr[index] = e;
        setBetOnePointArr(betOnePointArr);
    }
    const betTwoPointArrHandler = (e, index) => {
        betTwoPointArr[index] = e;
        setBetTwoPointArr(betTwoPointArr);
    }
    const betThreePointArrHandler = (e, index) => {
        betThreePointArr[index] = e;
        setBetThreePointArr(betThreePointArr);
    }
    const betFourPointArrHandler = (e, index) => {
        betFourPointArr[index] = e;
        setBetFourPointArr(betFourPointArr);
    }
    const betManyPointArrHandler = (e, index) => {
        betManyPointArr[index] = e;
        setBetManyPointArr(betManyPointArr);
    }

    const minOneAllocationArrHandler = (e, index) => {
        minOneAllocationArr[index] = e;
        setMinOneAllocationArr(minOneAllocationArr);
    }
    const minTwoAllocationArrHandler = (e, index) => {
        minTwoAllocationArr[index] = e;
        setMinTwoAllocationArr(minTwoAllocationArr);
    }
    const minThreeAllocationArrHandler = (e, index) => {
        minThreeAllocationArr[index] = e;
        setMinThreeAllocationArr(minThreeAllocationArr);
    }
    const minFourAllocationArrHandler = (e, index) => {
        minFourAllocationArr[index] = e;
        setMinFourAllocationArr(minFourAllocationArr);
    }
    const minManyAllocationArrHandler = (e, index) => {
        minManyAllocationArr[index] = e;
        setMinManyAllocationArr(minManyAllocationArr);
    }


    const userGbCdChangeHandler = (e) => {
        setUserGbCd(e.currentTarget.value);
    }
    const userLvlCdChangeHandler = (e) => {
        setUserLvlCd(e.currentTarget.value);
    }
    const recommenderChangerCdChangeHandler = (e) => {
        setreCommenderChangerCd(e.currentTarget.value);
    }
    const sportsChangeYnChangeHandler = (e) => {
        setSportsChangeYn(e.currentTarget.value);
    }
    const casinoChangeYnChangeHandler = (e) => {
        setCasinoChangeYn(e.currentTarget.value);
    }
    const slotChangeYnChangeHandler = (e) => {
        setSlotChangeYn(e.currentTarget.value);
    }
    const miniChangeYnChangeHandler = (e) => {
        setMiniChangeYn(e.currentTarget.value);
    }


    useEffect(() => {
        getGrades()
    }, [])

    const getGrades = () => {
        axios.post('/admin/admin/getPointGrade2', {

        }).then((res) => {
            setLevels(res.data.result)
            const arr = []
            for(const level of res.data.result) {
                arr.push(level.setting_level_no)
            }
        })
    }

    const onLevelChange = (setting_level_no) => {
        onSearch(setting_level_no)
    }

    const handleClickOpen = () => {
        dispatch(setIsOpen(true))
    }
    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        window.location.reload();
    }

    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }

    const updateState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveRecommenderDefaultSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                userGbCd:userGbCd?userGbCd:mainList1.user_gb_cd,
                userLvlCd:userLvlCd?userLvlCd:mainList1.user_lvl_cd,
                payPoint: payPoint?parseInt(payPoint):mainList1.pay_point,
                recommenderChangerCd:recommenderChangerCd?recommenderChangerCd:mainList1.recommender_changer_cd,
                sportsChangeYn:sportsChangeYn?sportsChangeYn:mainList1.sports_change_yn,
                sportsPaymentPercent: sportsPaymentPercent?parseInt(sportsPaymentPercent):mainList1.sports_payment_percent,
                casinoChangeYn:casinoChangeYn?casinoChangeYn:mainList1.casino_change_yn,
                casinoPaymentPercent: casinoPaymentPercent?parseInt(casinoPaymentPercent):mainList1.casino_payment_percent,
                slotChangeYn:slotChangeYn?slotChangeYn:mainList1.slot_change_yn,
                slotPaymentPercent: slotPaymentPercent?parseInt(slotPaymentPercent):mainList1.slot_payment_percent,
                miniChangeYn:miniChangeYn?miniChangeYn:mainList1.mini_change_yn,
                miniPaymentPercent: miniPaymentPercent?parseInt(miniPaymentPercent):mainList1.mini_payment_percent,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }


    const updateMultiState = (state, depositNos) => {
        if (window.confirm("선택레벨 일괄 적용 하시겠습니까?")) {

            if( initialState.selectedNos1.length < 1 )
            {
                alert("레벨을 선택하세요");
                return;
            }

            for (let i = 0; i < initialState.selectedNos1.length; i++)
            {
                axios.post('/admin/setting/saveRecommenderDefaultSetting', {
                    pntGbCd: initialState.selectedNos1[i],
                    userGbCd:userGbCd?userGbCd:mainList1.user_gb_cd,
                    userLvlCd:userLvlCd?userLvlCd:mainList1.user_lvl_cd,
                    payPoint: payPoint?parseInt(payPoint):mainList1.pay_point,
                    recommenderChangerCd:recommenderChangerCd?recommenderChangerCd:mainList1.recommender_changer_cd,
                    sportsChangeYn:sportsChangeYn?sportsChangeYn:mainList1.sports_change_yn,
                    sportsPaymentPercent: sportsPaymentPercent?parseInt(sportsPaymentPercent):mainList1.sports_payment_percent,
                    casinoChangeYn:casinoChangeYn?casinoChangeYn:mainList1.casino_change_yn,
                    casinoPaymentPercent: casinoPaymentPercent?parseInt(casinoPaymentPercent):mainList1.casino_payment_percent,
                    slotChangeYn:slotChangeYn?slotChangeYn:mainList1.slot_change_yn,
                    slotPaymentPercent: slotPaymentPercent?parseInt(slotPaymentPercent):mainList1.slot_payment_percent,
                    miniChangeYn:miniChangeYn?miniChangeYn:mainList1.mini_change_yn,
                    miniPaymentPercent: miniPaymentPercent?parseInt(miniPaymentPercent):mainList1.mini_payment_percent,
                    adminNo: userInfoId
                }).then((res) => {
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }

            alert("수정완료");

        }

    }

    const checkHandler = (checked, no) => {
        if(checked) {
            updateArr01[no] = 'Y';
            setUpdateArr01(updateArr01);
            //alert("updateArr01 : "+updateArr01);
        }
        else
        {
            updateArr01[no] = 'N';
            setUpdateArr01(updateArr01);
            //alert("updateArr01 : "+updateArr01);
        }
    }

    const checkHandler1 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos1([...initialState.selectedNos1, no]))
        }
        else {
            dispatch(setSelectedNos1(initialState.selectedNos1.filter((id) => id !== no)))
        }
    }
    const checkHandler2 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos2([...initialState.selectedNos2, no]))
        }
        else {
            dispatch(setSelectedNos2(initialState.selectedNos2.filter((id) => id !== no)))
        }
    }
    const checkHandler3 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos3([...initialState.selectedNos3, no]))
        }
        else {
            dispatch(setSelectedNos3(initialState.selectedNos3.filter((id) => id !== no)))
        }
    }
    const checkHandler4 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos4([...initialState.selectedNos4, no]))
        }
        else {
            dispatch(setSelectedNos4(initialState.selectedNos4.filter((id) => id !== no)))
        }
    }
    const checkHandler5 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos5([...initialState.selectedNos5, no]))
        }
        else {
            dispatch(setSelectedNos5(initialState.selectedNos5.filter((id) => id !== no)))
        }
    }
    const checkHandler6 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos6([...initialState.selectedNos6, no]))
        }
        else {
            dispatch(setSelectedNos6(initialState.selectedNos6.filter((id) => id !== no)))
        }
    }
    const checkHandler7 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos7([...initialState.selectedNos7, no]))
        }
        else {
            dispatch(setSelectedNos7(initialState.selectedNos7.filter((id) => id !== no)))
        }
    }
    const checkHandler8 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos8([...initialState.selectedNos8, no]))
        }
        else {
            dispatch(setSelectedNos8(initialState.selectedNos8.filter((id) => id !== no)))
        }
    }
    const checkHandler9 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos9([...initialState.selectedNos9, no]))
        }
        else {
            dispatch(setSelectedNos9(initialState.selectedNos9.filter((id) => id !== no)))
        }
    }
    const checkHandler10 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos10([...initialState.selectedNos10, no]))
        }
        else {
            dispatch(setSelectedNos10(initialState.selectedNos10.filter((id) => id !== no)))
        }
    }

    const checkHandler11 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos11([...initialState.selectedNos11, no]))
        }
        else {
            dispatch(setSelectedNos11(initialState.selectedNos11.filter((id) => id !== no)))
        }
    }
    const checkHandler12 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos12([...initialState.selectedNos12, no]))
        }
        else {
            dispatch(setSelectedNos12(initialState.selectedNos12.filter((id) => id !== no)))
        }
    }
    const checkHandler13 = (checked, no) => {
        if (checked) {
            dispatch(setSelectedNos13([...initialState.selectedNos13, no]))
        }
        else {
            dispatch(setSelectedNos13(initialState.selectedNos13.filter((id) => id !== no)))
        }
    }


    const updateRecommenderEtcState = (index, sportsCd, benefitGbCd, useYn) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            if( index == 0 )
            {
                axios.post('/admin/setting/updateRecommenderEtcState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betComp: betCompArr[index]?parseInt(betCompArr[index]):countList11.bet_comp,
                    winningComp: winningCompArr[index]?parseInt(winningCompArr[index]):countList11.winning_comp,
                    loseComp: loseCompArr[index]?parseInt(loseCompArr[index]):countList11.lose_comp,
                    timeMaxCntComp: timeMaxCntCompArr[index]?parseInt(timeMaxCntCompArr[index]):countList11.time_max_cnt_comp,
                    dayMaxCntComp: dayMaxCntCompArr[index]?parseInt(dayMaxCntCompArr[index]):countList11.day_max_cnt_comp,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }
            else if( index == 1 )
            {
                axios.post('/admin/setting/updateRecommenderEtcState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betComp: betCompArr[index]?parseInt(betCompArr[index]):countList12.bet_comp,
                    winningComp: winningCompArr[index]?parseInt(winningCompArr[index]):countList12.winning_comp,
                    loseComp: loseCompArr[index]?parseInt(loseCompArr[index]):countList12.lose_comp,
                    timeMaxCntComp: timeMaxCntCompArr[index]?parseInt(timeMaxCntCompArr[index]):countList12.time_max_cnt_comp,
                    dayMaxCntComp: dayMaxCntCompArr[index]?parseInt(dayMaxCntCompArr[index]):countList12.day_max_cnt_comp,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }
            else if( index == 2 )
            {
                axios.post('/admin/setting/updateRecommenderEtcState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betComp: betCompArr[index]?parseInt(betCompArr[index]):countList13.bet_comp,
                    winningComp: winningCompArr[index]?parseInt(winningCompArr[index]):countList13.winning_comp,
                    loseComp: loseCompArr[index]?parseInt(loseCompArr[index]):countList13.lose_comp,
                    timeMaxCntComp: timeMaxCntCompArr[index]?parseInt(timeMaxCntCompArr[index]):countList13.time_max_cnt_comp,
                    dayMaxCntComp: dayMaxCntCompArr[index]?parseInt(dayMaxCntCompArr[index]):countList13.day_max_cnt_comp,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }

        }
    }

    const updateMultiRecommenderEtcState = (index, sportsCd, benefitGbCd, useYn, ArrayList) => {

        alert("ArrayList :::: "+ArrayList);

        if (window.confirm("선택하신 레벨을 일괄 변경 하시겠습니까?"))
        {
            for( let i=0; i<ArrayList.length; i++ )
            {
                if( ArrayList[i] == 'Y' )
                {

                    if( index == 0 )
                    {
                        axios.post('/admin/setting/updateRecommenderEtcState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betComp: betCompArr[index]?parseInt(betCompArr[index]):countList11.bet_comp,
                            winningComp: winningCompArr[index]?parseInt(winningCompArr[index]):countList11.winning_comp,
                            loseComp: loseCompArr[index]?parseInt(loseCompArr[index]):countList11.lose_comp,
                            timeMaxCntComp: timeMaxCntCompArr[index]?parseInt(timeMaxCntCompArr[index]):countList11.time_max_cnt_comp,
                            dayMaxCntComp: dayMaxCntCompArr[index]?parseInt(dayMaxCntCompArr[index]):countList11.day_max_cnt_comp,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }
                    else if( index == 1 )
                    {
                        axios.post('/admin/setting/updateRecommenderEtcState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betComp: betCompArr[index]?parseInt(betCompArr[index]):countList12.bet_comp,
                            winningComp: winningCompArr[index]?parseInt(winningCompArr[index]):countList12.winning_comp,
                            loseComp: loseCompArr[index]?parseInt(loseCompArr[index]):countList12.lose_comp,
                            timeMaxCntComp: timeMaxCntCompArr[index]?parseInt(timeMaxCntCompArr[index]):countList12.time_max_cnt_comp,
                            dayMaxCntComp: dayMaxCntCompArr[index]?parseInt(dayMaxCntCompArr[index]):countList12.day_max_cnt_comp,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }
                    else if( index == 2 )
                    {
                        axios.post('/admin/setting/updateRecommenderEtcState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betComp: betCompArr[index]?parseInt(betCompArr[index]):countList13.bet_comp,
                            winningComp: winningCompArr[index]?parseInt(winningCompArr[index]):countList13.winning_comp,
                            loseComp: loseCompArr[index]?parseInt(loseCompArr[index]):countList13.lose_comp,
                            timeMaxCntComp: timeMaxCntCompArr[index]?parseInt(timeMaxCntCompArr[index]):countList13.time_max_cnt_comp,
                            dayMaxCntComp: dayMaxCntCompArr[index]?parseInt(dayMaxCntCompArr[index]):countList13.day_max_cnt_comp,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }

                }
            }
            alert("수정완료");
            window.location.reload();
        }
    }




    const updateRecommenderState = (index, sportsCd, benefitGbCd, useYn) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            // alert("index : "+index);
            // alert("useYn01 : "+useYn01);
            // alert("betOnePointArr[0] : "+betOnePointArr[0]);

            if( index == 0 )
            {
                axios.post('/admin/setting/updateRecommenderState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList1.bet_one_point,
                    betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList1.bet_two_point,
                    betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList1.bet_three_point,
                    betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList1.bet_four_point,
                    betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList1.bet_many_point,
                    minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList1.min_one_allocation,
                    minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList1.min_two_allocation,
                    minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList1.min_three_allocation,
                    minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList1.min_four_allocation,
                    minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList1.min_many_allocation,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })
            }
            else if( index == 1 )
            {
                axios.post('/admin/setting/updateRecommenderState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList2.bet_one_point,
                    betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList2.bet_two_point,
                    betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList2.bet_three_point,
                    betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList2.bet_four_point,
                    betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList2.bet_many_point,
                    minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList2.min_one_allocation,
                    minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList2.min_two_allocation,
                    minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList2.min_three_allocation,
                    minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList2.min_four_allocation,
                    minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList2.min_many_allocation,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }
            else if( index == 2 )
            {
                axios.post('/admin/setting/updateRecommenderState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList3.bet_one_point,
                    betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList3.bet_two_point,
                    betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList3.bet_three_point,
                    betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList3.bet_four_point,
                    betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList3.bet_many_point,
                    minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList3.min_one_allocation,
                    minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList3.min_two_allocation,
                    minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList3.min_three_allocation,
                    minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList3.min_four_allocation,
                    minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList3.min_many_allocation,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }
            else if( index == 3 )
            {
                axios.post('/admin/setting/updateRecommenderState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList4.bet_one_point,
                    betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList4.bet_two_point,
                    betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList4.bet_three_point,
                    betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList4.bet_four_point,
                    betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList4.bet_many_point,
                    minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList4.min_one_allocation,
                    minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList4.min_two_allocation,
                    minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList4.min_three_allocation,
                    minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList4.min_four_allocation,
                    minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList4.min_many_allocation,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }
            else if( index == 4 )
            {
                axios.post('/admin/setting/updateRecommenderState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList5.bet_one_point,
                    betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList5.bet_two_point,
                    betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList5.bet_three_point,
                    betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList5.bet_four_point,
                    betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList5.bet_many_point,
                    minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList5.min_one_allocation,
                    minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList5.min_two_allocation,
                    minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList5.min_three_allocation,
                    minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList5.min_four_allocation,
                    minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList5.min_many_allocation,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }
            else if( index == 5 )
            {
                axios.post('/admin/setting/updateRecommenderState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList6.bet_one_point,
                    betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList6.bet_two_point,
                    betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList6.bet_three_point,
                    betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList6.bet_four_point,
                    betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList6.bet_many_point,
                    minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList6.min_one_allocation,
                    minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList6.min_two_allocation,
                    minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList6.min_three_allocation,
                    minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList6.min_four_allocation,
                    minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList6.min_many_allocation,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }
            else if( index == 6 )
            {
                axios.post('/admin/setting/updateRecommenderState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList7.bet_one_point,
                    betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList7.bet_two_point,
                    betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList7.bet_three_point,
                    betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList7.bet_four_point,
                    betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList7.bet_many_point,
                    minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList7.min_one_allocation,
                    minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList7.min_two_allocation,
                    minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList7.min_three_allocation,
                    minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList7.min_four_allocation,
                    minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList7.min_many_allocation,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }
            else if( index == 7 )
            {
                axios.post('/admin/setting/updateRecommenderState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList8.bet_one_point,
                    betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList8.bet_two_point,
                    betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList8.bet_three_point,
                    betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList8.bet_four_point,
                    betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList8.bet_many_point,
                    minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList8.min_one_allocation,
                    minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList8.min_two_allocation,
                    minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList8.min_three_allocation,
                    minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList8.min_four_allocation,
                    minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList8.min_many_allocation,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }
            else if( index == 8 )
            {
                axios.post('/admin/setting/updateRecommenderState', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    sportsCd: sportsCd,
                    useYn: useYn,
                    benefitGbCd : benefitGbCd,
                    betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList9.bet_one_point,
                    betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList9.bet_two_point,
                    betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList9.bet_three_point,
                    betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList9.bet_four_point,
                    betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList9.bet_many_point,
                    minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList9.min_one_allocation,
                    minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList9.min_two_allocation,
                    minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList9.min_three_allocation,
                    minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList9.min_four_allocation,
                    minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList9.min_many_allocation,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    window.location.reload();
                })
            }


        }
    }


    const updateMultiRecommenderState = (index, sportsCd, benefitGbCd, useYn, ArrayList) => {

        alert("ArrayList :::: "+ArrayList);

        if (window.confirm("선택하신 레벨을 일괄 변경 하시겠습니까?"))
        {
            for( let i=0; i<ArrayList.length; i++ )
            {
                if( ArrayList[i] == 'Y' )
                {

                    if( index == 0 )
                    {
                        axios.post('/admin/setting/updateRecommenderState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList1.bet_one_point,
                            betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList1.bet_two_point,
                            betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList1.bet_three_point,
                            betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList1.bet_four_point,
                            betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList1.bet_many_point,
                            minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList1.min_one_allocation,
                            minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList1.min_two_allocation,
                            minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList1.min_three_allocation,
                            minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList1.min_four_allocation,
                            minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList1.min_many_allocation,
                            adminNo: userInfoId
                        }).then((res) => {

                        })
                    }
                    else if( index == 1 )
                    {
                        axios.post('/admin/setting/updateRecommenderState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList2.bet_one_point,
                            betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList2.bet_two_point,
                            betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList2.bet_three_point,
                            betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList2.bet_four_point,
                            betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList2.bet_many_point,
                            minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList2.min_one_allocation,
                            minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList2.min_two_allocation,
                            minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList2.min_three_allocation,
                            minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList2.min_four_allocation,
                            minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList2.min_many_allocation,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }
                    else if( index == 2 )
                    {
                        axios.post('/admin/setting/updateRecommenderState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList3.bet_one_point,
                            betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList3.bet_two_point,
                            betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList3.bet_three_point,
                            betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList3.bet_four_point,
                            betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList3.bet_many_point,
                            minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList3.min_one_allocation,
                            minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList3.min_two_allocation,
                            minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList3.min_three_allocation,
                            minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList3.min_four_allocation,
                            minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList3.min_many_allocation,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }
                    else if( index == 3 )
                    {
                        axios.post('/admin/setting/updateRecommenderState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList4.bet_one_point,
                            betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList4.bet_two_point,
                            betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList4.bet_three_point,
                            betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList4.bet_four_point,
                            betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList4.bet_many_point,
                            minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList4.min_one_allocation,
                            minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList4.min_two_allocation,
                            minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList4.min_three_allocation,
                            minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList4.min_four_allocation,
                            minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList4.min_many_allocation,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }
                    else if( index == 4 )
                    {
                        axios.post('/admin/setting/updateRecommenderState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList5.bet_one_point,
                            betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList5.bet_two_point,
                            betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList5.bet_three_point,
                            betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList5.bet_four_point,
                            betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList5.bet_many_point,
                            minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList5.min_one_allocation,
                            minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList5.min_two_allocation,
                            minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList5.min_three_allocation,
                            minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList5.min_four_allocation,
                            minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList5.min_many_allocation,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }
                    else if( index == 5 )
                    {
                        axios.post('/admin/setting/updateRecommenderState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList6.bet_one_point,
                            betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList6.bet_two_point,
                            betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList6.bet_three_point,
                            betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList6.bet_four_point,
                            betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList6.bet_many_point,
                            minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList6.min_one_allocation,
                            minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList6.min_two_allocation,
                            minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList6.min_three_allocation,
                            minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList6.min_four_allocation,
                            minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList6.min_many_allocation,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }
                    else if( index == 6 )
                    {
                        axios.post('/admin/setting/updateRecommenderState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList7.bet_one_point,
                            betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList7.bet_two_point,
                            betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList7.bet_three_point,
                            betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList7.bet_four_point,
                            betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList7.bet_many_point,
                            minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList7.min_one_allocation,
                            minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList7.min_two_allocation,
                            minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList7.min_three_allocation,
                            minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList7.min_four_allocation,
                            minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList7.min_many_allocation,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }
                    else if( index == 7 )
                    {
                        axios.post('/admin/setting/updateRecommenderState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList8.bet_one_point,
                            betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList8.bet_two_point,
                            betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList8.bet_three_point,
                            betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList8.bet_four_point,
                            betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList8.bet_many_point,
                            minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList8.min_one_allocation,
                            minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList8.min_two_allocation,
                            minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList8.min_three_allocation,
                            minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList8.min_four_allocation,
                            minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList8.min_many_allocation,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }
                    else if( index == 8 )
                    {
                        axios.post('/admin/setting/updateRecommenderState', {
                            pntGbCd: i,
                            sportsCd: sportsCd,
                            useYn: useYn,
                            benefitGbCd : benefitGbCd,
                            betOnePoint: betOnePointArr[index]?parseInt(betOnePointArr[index]):countList9.bet_one_point,
                            betTwoPoint: betTwoPointArr[index]?parseInt(betTwoPointArr[index]):countList9.bet_two_point,
                            betThreePoint: betThreePointArr[index]?parseInt(betThreePointArr[index]):countList9.bet_three_point,
                            betFourPoint: betFourPointArr[index]?parseInt(betFourPointArr[index]):countList9.bet_four_point,
                            betManyPoint: betManyPointArr[index]?parseInt(betManyPointArr[index]):countList9.bet_many_point,
                            minOneAllocation: minOneAllocationArr[index]?parseInt(minOneAllocationArr[index]):countList9.min_one_allocation,
                            minTwoAllocation: minTwoAllocationArr[index]?parseInt(minTwoAllocationArr[index]):countList9.min_two_allocation,
                            minThreeAllocation: minThreeAllocationArr[index]?parseInt(minThreeAllocationArr[index]):countList9.min_three_allocation,
                            minFourAllocation: minFourAllocationArr[index]?parseInt(minFourAllocationArr[index]):countList9.min_four_allocation,
                            minManyAllocation: minManyAllocationArr[index]?parseInt(minManyAllocationArr[index]):countList9.min_many_allocation,
                            adminNo: userInfoId
                        }).then((res) => {
                            // alert(res.data.isUpdated? "수정완료" : "수정실패")
                            // window.location.reload();
                        })
                    }

                }
            }
            alert("수정완료");
            window.location.reload();
        }
    }


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "green", border: "1", color: "white",fontSize: "10px", height: "22px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                            onClick={handleClickOpen}>레벨관리(추가,삭제)</button>
                    {initialState.isOpen && <LvlSettingComponent open={initialState.isOpen} onClose={handleClickClose} />}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {levels?.map((item, index) => (
                        <>
                            <button style={{ backgroundColor: initialState.query === item.setting_level_key ? "blue" : "white" , border: "1", color: initialState.query === item.setting_level_key ? "white" : "black",fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                    onClick={(e) => {
                                        onLevelChange(item.setting_level_key)
                                    }}
                            >
                                <b>{item.cmmn_cd_dtl_nm} 레벨</b>
                            </button>
                        </>
                    ))}
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"100%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> 설정</b></font>
                        </div>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>기본설정</b></font>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>회원상태</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={userGbCdChangeHandler} value={userGbCd?userGbCd:mainList1.user_gb_cd}
                                    >
                                        <option value={'01'}>정상</option>
                                        <option value={'02'}>정지</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>회원레벨</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={userLvlCdChangeHandler} value={userLvlCd?userLvlCd:mainList1.user_lvl_cd}
                                    >
                                        {levels?.map((item, index) => (
                                            <>
                                                <option value={item.setting_level_key}>
                                                    {item.cmmn_cd_dtl_nm} 레벨
                                                </option>
                                            </>
                                        ))}

                                        <option value={'01'}>정상</option>
                                        <option value={'02'}>정지</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>지급포인트</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width:"100px"}}
                                           placeholder={addComma(mainList1.pay_point)}
                                           value={payPoint}
                                           onChange={(e) => setPayPoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        P
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>추천인 가입시 전환코드</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={recommenderChangerCdChangeHandler} value={recommenderChangerCd?recommenderChangerCd:mainList1.recommender_changer_cd}
                                    >
                                        <option value={'01'}>총판</option>
                                        <option value={'02'}>가맹점</option>
                                    </select>
                                </td>

                            </tr>
                            <tr>
                                <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전시 추천인 혜택</b></font>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>스포츠충전</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={sportsChangeYnChangeHandler} value={sportsChangeYn?sportsChangeYn:mainList1.sports_change_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>지급</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                           placeholder={addComma(mainList1.sports_payment_percent)}
                                           value={sportsPaymentPercent}
                                           onChange={(e) => setSportsPaymentPercent(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>카지노충전</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={casinoChangeYnChangeHandler} value={casinoChangeYn?casinoChangeYn:mainList1.casino_change_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>지급</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                           placeholder={addComma(mainList1.casino_payment_percent)}
                                           value={casinoPaymentPercent}
                                           onChange={(e) => setCasinoPaymentPercent(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>슬롯충전</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={slotChangeYnChangeHandler} value={slotChangeYn?slotChangeYn:mainList1.slot_change_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>지급</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                           placeholder={addComma(mainList1.slot_payment_percent)}
                                           value={slotPaymentPercent}
                                           onChange={(e) => setSlotPaymentPercent(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>미니게임충전</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        onChange={miniChangeYnChangeHandler} value={miniChangeYn?miniChangeYn:mainList1.mini_change_yn}
                                    >
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <font color={"black"}><b>지급</b></font>
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                           placeholder={addComma(mainList1.mini_payment_percent)}
                                           value={miniPaymentPercent}
                                           onChange={(e) => setMiniPaymentPercent(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td align={"center"}>
                                    <table>
                                        <tr>
                                            <td>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateState()}}
                                                >
                                                    변경
                                                </button>
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                                        onClick={() => {updateMultiState()}}
                                                >
                                                    선택 레벨 일괄적용
                                                </button>
                                            </td>
                                            <td>
                                                {levels?.map((item, index) => (
                                                    <>
                                                        <input className="form-check-input" type="checkbox" name={"inlineRadio11"+item.setting_level_no} id={"inlineRadio11"+item.setting_level_no}
                                                               style={{cursor:"pointer"}}
                                                               checked={initialState.selectedNos1.filter((no) => no === item.setting_level_no  ).length > 0}
                                                               onChange={(e) => {checkHandler1(e.currentTarget.checked, item.setting_level_no ) }}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor={'inlineRadio11' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio11"+item.setting_level_no}>
                                                            <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                        </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"100%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> 스포츠 추천인 혜택</b></font>
                        </div>

                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 프리매치 [1레벨]</b></font>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 인플레이 [1레벨]</b></font>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 스폐셜 [1레벨]</b></font>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 베팅</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler01}
                                        value={useYn01?useYn01:countList1.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 베팅</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler04}
                                        value={useYn04?useYn04:countList4.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 베팅</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler07}
                                        value={useYn07?useYn07:countList7.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <table style={{width: "100%"}}>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList1.bet_one_point}
                                                       value={betOnePointArr[0]}
                                                       onChange={(e) => betOnePointArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList1.min_one_allocation}
                                                       value={minOneAllocationArr[0]}
                                                       onChange={(e) => minOneAllocationArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 2폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList1.bet_two_point}
                                                       value={betTwoPointArr[0]}
                                                       onChange={(e) => betTwoPointArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList1.min_two_allocation}
                                                       value={minTwoAllocationArr[0]}
                                                       onChange={(e) => minTwoAllocationArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 3폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList1.bet_three_point}
                                                       value={betThreePointArr[0]}
                                                       onChange={(e) => betThreePointArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList1.min_three_allocation}
                                                       value={minThreeAllocationArr[0]}
                                                       onChange={(e) => minThreeAllocationArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 4폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList1.bet_four_point}
                                                       value={betFourPointArr[0]}
                                                       onChange={(e) => betFourPointArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList1.min_four_allocation}
                                                       value={minFourAllocationArr[0]}
                                                       onChange={(e) => minFourAllocationArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 다폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList1.bet_many_point}
                                                       value={betManyPointArr[0]}
                                                       onChange={(e) => betManyPointArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList1.min_many_allocation}
                                                       value={minManyAllocationArr[0]}
                                                       onChange={(e) => minManyAllocationArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} align={"center"}>
                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderState(0, '01', '01', useYn01)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderState(0, '01', '01', useYn01, initialState.selectedNos2)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio02"+item.setting_level_no} id={"inlineRadio02"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos2.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler2(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio02' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio02"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <table style={{width: "100%"}}>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList4.bet_one_point}
                                                       value={betOnePointArr[3]}
                                                       onChange={(e) => betOnePointArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList4.min_one_allocation}
                                                       value={minOneAllocationArr[3]}
                                                       onChange={(e) => minOneAllocationArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 2폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList4.bet_two_point}
                                                       value={betTwoPointArr[3]}
                                                       onChange={(e) => betTwoPointArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList4.min_two_allocation}
                                                       value={minTwoAllocationArr[3]}
                                                       onChange={(e) => minTwoAllocationArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 3폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList4.bet_three_point}
                                                       value={betThreePointArr[3]}
                                                       onChange={(e) => betThreePointArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList4.min_three_allocation}
                                                       value={minThreeAllocationArr[3]}
                                                       onChange={(e) => minThreeAllocationArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 4폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList4.bet_four_point}
                                                       value={betFourPointArr[3]}
                                                       onChange={(e) => betFourPointArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList4.min_four_allocation}
                                                       value={minFourAllocationArr[3]}
                                                       onChange={(e) => minFourAllocationArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 다폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList4.bet_many_point}
                                                       value={betManyPointArr[3]}
                                                       onChange={(e) => betManyPointArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList4.min_many_allocation}
                                                       value={minManyAllocationArr[3]}
                                                       onChange={(e) => minManyAllocationArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} align={"center"}>
                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderState(3, '02', '01', useYn04)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderState(3, '02', '01', useYn04, initialState.selectedNos3)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio03"+item.setting_level_no} id={"inlineRadio03"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos3.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler3(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio03' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio03"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <table style={{width: "100%"}}>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList7.bet_one_point}
                                                       value={betOnePointArr[6]}
                                                       onChange={(e) => betOnePointArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList7.min_one_allocation}
                                                       value={minOneAllocationArr[6]}
                                                       onChange={(e) => minOneAllocationArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 2폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList7.bet_two_point}
                                                       value={betTwoPointArr[6]}
                                                       onChange={(e) => betTwoPointArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList7.min_two_allocation}
                                                       value={minTwoAllocationArr[6]}
                                                       onChange={(e) => minTwoAllocationArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 3폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList7.bet_three_point}
                                                       value={betThreePointArr[6]}
                                                       onChange={(e) => betThreePointArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList7.min_three_allocation}
                                                       value={minThreeAllocationArr[6]}
                                                       onChange={(e) => minThreeAllocationArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 4폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList7.bet_four_point}
                                                       value={betFourPointArr[6]}
                                                       onChange={(e) => betFourPointArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList7.min_four_allocation}
                                                       value={minFourAllocationArr[6]}
                                                       onChange={(e) => minFourAllocationArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 다폴더 배팅 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList7.bet_many_point}
                                                       value={betManyPointArr[6]}
                                                       onChange={(e) => betManyPointArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList7.min_many_allocation}
                                                       value={minManyAllocationArr[6]}
                                                       onChange={(e) => minManyAllocationArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} align={"center"}>
                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderState(6, '03', '01', useYn07)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderState(6, '03', '01', useYn07, initialState.selectedNos4)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio04"+item.setting_level_no} id={"inlineRadio04"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos4.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler4(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio04' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio04"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 당첨</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler02}
                                        value={useYn02?useYn02:countList2.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 당첨</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler05}
                                        value={useYn05?useYn05:countList5.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 당첨</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler08}
                                        value={useYn08?useYn08:countList8.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <table style={{width: "100%"}}>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList2.bet_one_point}
                                                       value={betOnePointArr[1]}
                                                       onChange={(e) => betOnePointArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList2.min_one_allocation}
                                                       value={minOneAllocationArr[1]}
                                                       onChange={(e) => minOneAllocationArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 2폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList2.bet_two_point}
                                                       value={betTwoPointArr[1]}
                                                       onChange={(e) => betTwoPointArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList2.min_two_allocation}
                                                       value={minTwoAllocationArr[1]}
                                                       onChange={(e) => minTwoAllocationArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 3폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList2.bet_three_point}
                                                       value={betThreePointArr[1]}
                                                       onChange={(e) => betThreePointArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList2.min_three_allocation}
                                                       value={minThreeAllocationArr[1]}
                                                       onChange={(e) => minThreeAllocationArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 4폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList2.bet_four_point}
                                                       value={betFourPointArr[1]}
                                                       onChange={(e) => betFourPointArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList2.min_four_allocation}
                                                       value={minFourAllocationArr[1]}
                                                       onChange={(e) => minFourAllocationArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 다폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList2.bet_many_point}
                                                       value={betManyPointArr[1]}
                                                       onChange={(e) => betManyPointArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList2.min_many_allocation}
                                                       value={minManyAllocationArr[1]}
                                                       onChange={(e) => minManyAllocationArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} align={"center"}>

                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderState(1, '01', '02', useYn02)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderState(1, '01', '02', useYn02, initialState.selectedNos5)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio05"+item.setting_level_no} id={"inlineRadio05"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos5.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler5(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio05' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio05"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <table style={{width: "100%"}}>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList5.bet_one_point}
                                                       value={betOnePointArr[4]}
                                                       onChange={(e) => betOnePointArrHandler(e.target.value, 4)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList5.min_one_allocation}
                                                       value={minOneAllocationArr[4]}
                                                       onChange={(e) => minOneAllocationArrHandler(e.target.value, 4)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 2폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList5.bet_two_point}
                                                       value={betTwoPointArr[4]}
                                                       onChange={(e) => betTwoPointArrHandler(e.target.value, 4)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList5.min_two_allocation}
                                                       value={minTwoAllocationArr[4]}
                                                       onChange={(e) => minTwoAllocationArrHandler(e.target.value, 4)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 3폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList5.bet_three_point}
                                                       value={betThreePointArr[4]}
                                                       onChange={(e) => betThreePointArrHandler(e.target.value, 4)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList5.min_three_allocation}
                                                       value={minThreeAllocationArr[4]}
                                                       onChange={(e) => minThreeAllocationArrHandler(e.target.value, 4)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 4폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList5.bet_four_point}
                                                       value={betFourPointArr[4]}
                                                       onChange={(e) => betFourPointArrHandler(e.target.value, 4)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList5.min_four_allocation}
                                                       value={minFourAllocationArr[4]}
                                                       onChange={(e) => minFourAllocationArrHandler(e.target.value, 4)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 다폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList5.bet_many_point}
                                                       value={betManyPointArr[4]}
                                                       onChange={(e) => betManyPointArrHandler(e.target.value, 4)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList5.min_many_allocation}
                                                       value={minManyAllocationArr[4]}
                                                       onChange={(e) => minManyAllocationArrHandler(e.target.value, 4)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} align={"center"}>
                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderState(4, '02', '02', useYn05)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderState(4, '02', '02', useYn05, initialState.selectedNos6)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio06"+item.setting_level_no} id={"inlineRadio06"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos6.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler6(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio06' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio06"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <table style={{width: "100%"}}>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList8.bet_one_point}
                                                       value={betOnePointArr[7]}
                                                       onChange={(e) => betOnePointArrHandler(e.target.value, 7)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList8.min_one_allocation}
                                                       value={minOneAllocationArr[7]}
                                                       onChange={(e) => minOneAllocationArrHandler(e.target.value, 7)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 2폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList8.bet_two_point}
                                                       value={betTwoPointArr[7]}
                                                       onChange={(e) => betTwoPointArrHandler(e.target.value, 7)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList8.min_two_allocation}
                                                       value={minTwoAllocationArr[7]}
                                                       onChange={(e) => minTwoAllocationArrHandler(e.target.value, 7)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 3폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList8.bet_three_point}
                                                       value={betThreePointArr[7]}
                                                       onChange={(e) => betThreePointArrHandler(e.target.value, 7)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList8.min_three_allocation}
                                                       value={minThreeAllocationArr[7]}
                                                       onChange={(e) => minThreeAllocationArrHandler(e.target.value, 7)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 4폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList8.bet_four_point}
                                                       value={betFourPointArr[7]}
                                                       onChange={(e) => betFourPointArrHandler(e.target.value, 7)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList8.min_four_allocation}
                                                       value={minFourAllocationArr[7]}
                                                       onChange={(e) => minFourAllocationArrHandler(e.target.value, 7)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 다폴더 당첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList8.bet_many_point}
                                                       value={betManyPointArr[7]}
                                                       onChange={(e) => betManyPointArrHandler(e.target.value, 7)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList8.min_many_allocation}
                                                       value={minManyAllocationArr[7]}
                                                       onChange={(e) => minManyAllocationArrHandler(e.target.value, 7)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} align={"center"}>

                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderState(7, '03', '02', useYn08)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderState(7, '03', '02', useYn08, initialState.selectedNos7)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio07"+item.setting_level_no} id={"inlineRadio07"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos7.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler7(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio07' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio07"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 낙첨</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler03}
                                        value={useYn03?useYn03:countList3.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 낙첨</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler06}
                                        value={useYn06?useYn06:countList6.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 낙첨</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler09}
                                        value={useYn09?useYn09:countList9.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <table style={{width: "100%"}}>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList3.bet_one_point}
                                                       value={betOnePointArr[2]}
                                                       onChange={(e) => betOnePointArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList3.min_one_allocation}
                                                       value={minOneAllocationArr[2]}
                                                       onChange={(e) => minOneAllocationArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 2폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList3.bet_two_point}
                                                       value={betTwoPointArr[2]}
                                                       onChange={(e) => betTwoPointArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList3.min_two_allocation}
                                                       value={minTwoAllocationArr[2]}
                                                       onChange={(e) => minTwoAllocationArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 3폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList3.bet_three_point}
                                                       value={betThreePointArr[2]}
                                                       onChange={(e) => betThreePointArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList3.min_three_allocation}
                                                       value={minThreeAllocationArr[2]}
                                                       onChange={(e) => minThreeAllocationArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 4폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList3.bet_four_point}
                                                       value={betFourPointArr[2]}
                                                       onChange={(e) => betFourPointArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList3.min_four_allocation}
                                                       value={minFourAllocationArr[2]}
                                                       onChange={(e) => minFourAllocationArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 다폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList3.bet_many_point}
                                                       value={betManyPointArr[2]}
                                                       onChange={(e) => betManyPointArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList3.min_many_allocation}
                                                       value={minManyAllocationArr[2]}
                                                       onChange={(e) => minManyAllocationArrHandler(e.target.value, 3)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} align={"center"}>

                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderState(2, '01', '03', useYn03)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderState(2, '01', '03', useYn03, initialState.selectedNos8)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio08"+item.setting_level_no} id={"inlineRadio08"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos8.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler8(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio08' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio08"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <table style={{width: "100%"}}>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList6.bet_one_point}
                                                       value={betOnePointArr[5]}
                                                       onChange={(e) => betOnePointArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList6.min_one_allocation}
                                                       value={minOneAllocationArr[5]}
                                                       onChange={(e) => minOneAllocationArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 2폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList6.bet_two_point}
                                                       value={betTwoPointArr[5]}
                                                       onChange={(e) => betTwoPointArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList6.min_two_allocation}
                                                       value={minTwoAllocationArr[5]}
                                                       onChange={(e) => minTwoAllocationArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 3폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList6.bet_three_point}
                                                       value={betThreePointArr[5]}
                                                       onChange={(e) => betThreePointArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList6.min_three_allocation}
                                                       value={minThreeAllocationArr[5]}
                                                       onChange={(e) => minThreeAllocationArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 4폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList6.bet_four_point}
                                                       value={betFourPointArr[5]}
                                                       onChange={(e) => betFourPointArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList6.min_four_allocation}
                                                       value={minFourAllocationArr[5]}
                                                       onChange={(e) => minFourAllocationArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 다폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList6.bet_many_point}
                                                       value={betManyPointArr[5]}
                                                       onChange={(e) => betManyPointArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList6.min_many_allocation}
                                                       value={minManyAllocationArr[5]}
                                                       onChange={(e) => minManyAllocationArrHandler(e.target.value, 6)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} align={"center"}>

                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderState(5, '02', '03', useYn06)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderState(5, '02', '03', useYn06, initialState.selectedNos9)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio09"+item.setting_level_no} id={"inlineRadio09"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos9.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler9(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio09' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio09"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <table style={{width: "100%"}}>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList9.bet_one_point}
                                                       value={betOnePointArr[8]}
                                                       onChange={(e) => betOnePointArrHandler(e.target.value, 9)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList9.min_one_allocation}
                                                       value={minOneAllocationArr[8]}
                                                       onChange={(e) => minOneAllocationArrHandler(e.target.value, 9)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 2폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList9.bet_two_point}
                                                       value={betTwoPointArr[8]}
                                                       onChange={(e) => betTwoPointArrHandler(e.target.value, 9)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList9.min_two_allocation}
                                                       value={minTwoAllocationArr[8]}
                                                       onChange={(e) => minTwoAllocationArrHandler(e.target.value, 9)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 3폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList9.bet_three_point}
                                                       value={betThreePointArr[8]}
                                                       onChange={(e) => betThreePointArrHandler(e.target.value, 9)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList9.min_three_allocation}
                                                       value={minThreeAllocationArr[8]}
                                                       onChange={(e) => minThreeAllocationArrHandler(e.target.value, 9)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 4폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList9.bet_four_point}
                                                       value={betFourPointArr[8]}
                                                       onChange={(e) => betFourPointArrHandler(e.target.value, 9)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList9.min_four_allocation}
                                                       value={minFourAllocationArr[8]}
                                                       onChange={(e) => minFourAllocationArrHandler(e.target.value, 9)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 다폴더 낙첨 포인트</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList9.bet_many_point}
                                                       value={betManyPointArr[8]}
                                                       onChange={(e) => betManyPointArrHandler(e.target.value, 9)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                            <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                            </td>
                                            <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 인정 최소 배당</b></font>
                                            </td>
                                            <td style={{width: "25%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList9.min_many_allocation}
                                                       value={minManyAllocationArr[8]}
                                                       onChange={(e) => minManyAllocationArrHandler(e.target.value, 9)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                >
                                                    배당
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} align={"center"}>
                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderState(8, '03', '03', useYn09)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderState(8, '03', '03', useYn09, initialState.selectedNos10)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio10"+item.setting_level_no} id={"inlineRadio10"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos10.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler10(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio10' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio10"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"20px"}} align={"left"}>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 카지노 추천인 혜택</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler11}
                                        value={useYn11?useYn11:countList11.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 슬롯 추천인 혜택</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler12}
                                        value={useYn12?useYn12:countList12.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"} size={1.5}><b> 미니게임 추천인 혜택</b></font>
                                    &nbsp;&nbsp;
                                    <select
                                        style={{width: "80px"}}
                                        onChange={useYnHandler13}
                                        value={useYn13?useYn13:countList13.use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>

                                    <table>
                                        <tr>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList11.bet_comp}
                                                       value={betCompArr[0]}
                                                       onChange={(e) => betCompArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList11.winning_comp}
                                                       value={winningCompArr[0]}
                                                       onChange={(e) => winningCompArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList11.lose_comp}
                                                       value={loseCompArr[0]}
                                                       onChange={(e) => loseCompArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1회 최대 지급 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList11.time_max_cnt_comp}
                                                       value={timeMaxCntCompArr[0]}
                                                       onChange={(e) => timeMaxCntCompArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    P
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1일 최대 지급 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList11.day_max_cnt_comp}
                                                       value={dayMaxCntCompArr[0]}
                                                       onChange={(e) => dayMaxCntCompArrHandler(e.target.value, 0)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    P
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} align={"center"}>
                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderEtcState(0, '01', '01', useYn11)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderEtcState(0, '01', '01', useYn11, initialState.selectedNos11)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio21"+item.setting_level_no} id={"inlineRadio21"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos11.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler11(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio21' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio21"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>

                                    <table>
                                        <tr>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList12.bet_comp}
                                                       value={betCompArr[1]}
                                                       onChange={(e) => betCompArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1회 최대 지급 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList12.time_max_cnt_comp}
                                                       value={timeMaxCntCompArr[1]}
                                                       onChange={(e) => timeMaxCntCompArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    P
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1일 최대 지급 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList12.day_max_cnt_comp}
                                                       value={dayMaxCntCompArr[1]}
                                                       onChange={(e) => dayMaxCntCompArrHandler(e.target.value, 1)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    P
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}></td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} align={"center"}>
                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderEtcState(1, '02', '01', useYn12)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderEtcState(1, '02', '01', useYn12, initialState.selectedNos12)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio22"+item.setting_level_no} id={"inlineRadio22"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos12.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler12(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio22' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio22"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                </td>
                                <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>

                                    <table>
                                        <tr>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 배팅 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList13.bet_comp}
                                                       value={betCompArr[2]}
                                                       onChange={(e) => betCompArrHandler(e.target.value, 2)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 당첨 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList13.winning_comp}
                                                       value={winningCompArr[2]}
                                                       onChange={(e) => winningCompArrHandler(e.target.value, 2)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 낙첨 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList13.lose_comp}
                                                       value={loseCompArr[2]}
                                                       onChange={(e) => loseCompArrHandler(e.target.value, 2)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    %
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1회 최대 지급 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList13.time_max_cnt_comp}
                                                       value={timeMaxCntCompArr[2]}
                                                       onChange={(e) => timeMaxCntCompArrHandler(e.target.value, 2)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    P
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                                <font color={"black"} size={1.5}><b> 1일 최대 지급 콤프</b></font>
                                            </td>
                                            <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                                <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                                       placeholder={countList13.day_max_cnt_comp}
                                                       value={dayMaxCntCompArr[2]}
                                                       onChange={(e) => dayMaxCntCompArrHandler(e.target.value, 2)}
                                                />
                                                <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                                >
                                                    P
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} align={"center"}>
                                                <table>
                                                    <tr>
                                                        <td colSpan={5} align={"center"}>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateRecommenderEtcState(2, '03', '01', useYn13)}}
                                                            >
                                                                변경
                                                            </button>
                                                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                                                                    onClick={() => {updateMultiRecommenderEtcState(2, '03', '01', useYn13, initialState.selectedNos13)}}
                                                            >
                                                                선택 레벨 일괄적용
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {levels?.map((item, index) => (
                                                                <>
                                                                    <input className="form-check-input" type="checkbox" name={"inlineRadio23"+item.setting_level_no} id={"inlineRadio23"+item.setting_level_no}
                                                                           style={{cursor:"pointer"}}
                                                                           checked={initialState.selectedNos13.filter((no) => no === item.setting_level_no  ).length > 0}
                                                                           onChange={(e) => {checkHandler13(e.currentTarget.checked, item.setting_level_no ) }}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor={'inlineRadio23' + item.setting_level_no} style={{cursor: "pointer"}} id={"inlineRadio23"+item.setting_level_no}>
                                                                        <font size={1.5}><b>{item.cmmn_cd_dtl_nm} 레벨</b></font>
                                                                    </label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>


                        </table>
                    </div>
                </div>




            </div>
        </div>

    )
}

export default RecommenderSetting;