import Switch from "react-switch";
import {useDispatch} from "react-redux";
import {
    changeIsLoading,
    getLeagues,
    getTeams,
    setLeagues,
    setSelectedLeagues,
    setTeams
} from "../../../redux/reducers/game/gameSetting";
import axios from "axios";


const LeagueList = ({state, onRefresh}) => {
    const dispatch = useDispatch();


    const changeLeague = (item) => {
        dispatch(changeIsLoading(true))
        axios.post('/admin/game/setting/changeLeague', {
            leagueItem: item,
        }).then((res) => {
            dispatch(changeIsLoading(false))
            if(res.data.isChanged) {
                alert("변경완료")
            }
            else {
                alert("변경실패")
            }
        })
    }
    const changeAllLeague = () => {
        dispatch(changeIsLoading(true))
        axios.post('/admin/game/setting/changeAllLeague', {
            list: state.leagues,
        }).then((res) => {
            dispatch(changeIsLoading(false))
            if(res.data.isChanged) {
                alert("변경완료")
                onRefresh()
            }
            else {
                alert("변경실패")
            }
        })
    }

    return <div className="card-body" style={{height: "800px", overflowY: "scroll"}}>
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>코드</th>
                    <th>국가</th>
                    <th>종목</th>
                    <th>리그</th>
                    <th>팀명</th>
                    <th>파싱명</th>
                    <th>사용</th>
                    <th>
                        <button className="btn btn-sm btn-primary" onClick={(e) => {
                            changeAllLeague()
                        }}>일괄변경</button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {state.leagues && state.leagues.map((item, index) => (
                    <tr key={item.id} style={{backgroundColor: state.selectedLeague === item.id? '#ead980': "transparent"}}>
                        <td>{item.id}</td>
                        <td>
                            <div className="d-flex align-items-center">
                                <img src="/icon/2641.png" width="20px" height="20px"/>
                                <input className="form-control form-control-sm" type="file"/>
                            </div>
                        </td>
                        <td>{item.game_kind.name_kor? item.game_kind.name_kor:item.game_kind.name}</td>
                        <td>{item.nation.name_kor? item.nation.name_kor:item.nation.name}</td>
                        <td style={{ textAlign: 'center',paddingLeft: '10px' }}>
                            <input className="form-control-sm" type="text" style={{ width: '100%' }} value={item.name_kor? item.name_kor : ""}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.name_kor = e.currentTarget.value
                                       const arr = [...state.leagues]
                                       arr[index] = newItem
                                       dispatch(setLeagues({
                                           result: arr,
                                       }))
                                   }}

                            />
                        </td>
                        <td>{item.name}</td>
                        <td>
                            <Switch onChange={(checked) => {
                            const newItem = {...item}
                            newItem.is_use = checked
                            const arr = [...state.leagues]
                            arr[index] = newItem
                            dispatch(setLeagues({
                                result: arr,
                            }))
                        }} checked={item.is_use} onColor="#2196F3"/>
                        </td>
                        <td>
                            <div>
                                <button className="btn btn-sm btn-primary" onClick={(e) => {
                                    changeLeague(item)
                                }}>변경</button>
                                <button className="btn btn-sm btn-danger" onClick={(e) => {
                                    dispatch(setSelectedLeagues(item))
                                    dispatch(setTeams({
                                        result: []
                                    }))
                                    dispatch(getTeams({
                                        leagueName: (item.name_kor && item.name_kor !== "")? item.name_kor : item.name,
                                        page: 1,
                                        pageSize: 20,
                                        query: "",
                                    }))
                                }}>팀정보</button>
                            </div>
                        </td>
                    </tr>
                ))}

                </tbody>
            </table>
        </div>

    </div>
}

export default LeagueList