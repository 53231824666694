import React from 'react';
import LevelSettingContainer from "../../containers/setting/LevelSettingContainer";


const LevelSetting = () => {
    return (
        <div>
            <LevelSettingContainer />
        </div>
    );
};

export default LevelSetting;