import React, {useEffect, useState} from "react";
import { NumericFormat } from "react-number-format";
import BetSettlement from "../../../constant/BetSettlement";
import PlusMinusText from "../../common/PlusMinusText";
import {useParams} from "react-router-dom";
import axios from "axios";
import moment from "moment";

const BettingPopup = () => {
    const param = useParams();
    const gameId = param.gameId;
    const betNo = param.betNo;
    const [bettingDetails, setBettingDetails] = useState([]);


    useEffect(() => {
        getBettingListByGameId()
    }, []);

    const getBettingListByGameId = () => {
        axios.post('/admin/betting/getBettingListByGameId', {
            gameId: gameId,
            betNo: betNo
        }).then((res) => {
            setBettingDetails(res.data.betList)
        })
    }


    return (
        <div style={{ padding: "20px" }}>
            <h2>베팅 상세 내역</h2>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead>
                <tr>
                    <td>번호</td>
                    <td>소속</td>
                    <td>가맹</td>
                    <td>아이디</td>
                    <td>닉네임</td>
                    <td>아이피</td>
                    <td>회원분류</td>
                    <td>게임구분</td>
                    <td>베팅일자</td>
                    <td>베팅액</td>
                    <td>배당률</td>
                    <td>당첨금</td>
                    <td>예상당첨금</td>
                    <td>배팅상태</td>
                    <td>조작여부</td>
                    <td>사용자삭제여부</td>
                    <td>처리</td>
                </tr>
                </thead>
                <tbody>
                {bettingDetails.map((item, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td>{item.no}</td>
                            <td>{item.store_cd}</td>
                            <td>{item.psnl_cd}</td>
                            <td>{item.id}</td>
                            <td>{item.nickname}</td>
                            <td>{item.bet_ip}</td>
                            <td>{item.user_gb_cd}</td>
                            <td>{item.type}</td>
                            <td>{moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss')}</td>
                            <td><PlusMinusText number={item.money} /></td>
                            <td>{item.total_ratio?.toFixed(2)}</td>
                            <td><PlusMinusText number={item.result_money} /></td>
                            <td><PlusMinusText number={item.win_money} /></td>
                            <td>{BetSettlement.getKorMessage(item.result)}</td>
                            <td>-</td>
                            <td>{item.is_deleted === 0 ? "X" : "O"}</td>
                            <td><button className="btn btn-danger">취소</button></td>
                        </tr>

                        {/* 🆕 추가된 상세 내역 */}
                        {item.details && item.details.length > 0 && (
                            <tr>
                                <td colSpan="17">
                                    <table className="table table-bordered table-sm text-center align-middle">
                                        <thead>
                                        <tr>
                                            <td>게임번호</td>
                                            <td>경기일자</td>
                                            <td>게임종류</td>
                                            <td>종목</td>
                                            <td>리그</td>
                                            <td>홈 vs 원정</td>
                                            <td>승</td>
                                            <td>무/기준점</td>
                                            <td>패</td>
                                            <td>스코어</td>
                                            <td>결과</td>
                                            <td>수정일자</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {item.details.map((detail, dIndex) => (
                                            <tr key={dIndex}>
                                                <td>{detail.no}</td>
                                                <td>{moment(detail.start_date).format('YYYY-MM-DD hh:mm:ss')}</td>
                                                <td>{detail.market}</td>
                                                <td>{detail.sport}</td>
                                                <td>{detail.league}</td>
                                                <td>{`${detail.home} vs ${detail.away}`}</td>
                                                <td style={{ backgroundColor: detail.bet_no === 1 ? "green" : "white" }}>
                                                    {detail.bet1?.toFixed(2)}
                                                </td>
                                                <td style={{ backgroundColor: detail.bet_no === 2 ? "green" : "white" }}>
                                                    {detail.bet2 !== null ? detail.bet2 : (detail.baseline ? `H ${detail.baseline.split(" (0")[0]}` : "VS")}
                                                </td>
                                                <td style={{ backgroundColor: detail.bet_no === 3 ? "green" : "white" }}>
                                                    {detail.bet3?.toFixed(2)}
                                                </td>
                                                <td>{`${detail.score_home !== null ? detail.score_home : "-"} : ${detail.score_away !== null ? detail.score_away : "-"}`}</td>
                                                <td>{BetSettlement.getKorMessage(detail.result)}</td>
                                                <td>{moment(detail.updatedAt).format('YYYY-MM-DD hh:mm:ss')}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default BettingPopup;