import React from "react";
import ActiveMemberContainer from "../../containers/monitoring/ActiveMemberContainer";
const ActiveMember = () => {
    return (
        <div className="sm-font">
            &nbsp;<font color={"blue"} size="3">회원활동</font>&nbsp;
            <ActiveMemberContainer />
        </div>

    )
}
export default ActiveMember;