import React from "react";
import MemberTotalInfoContainer from "../../containers/user/MemberTotalInfoContainer";
import {useParams} from "react-router-dom";



const MemberTotalInfo = () => {
    const param = useParams();

    return (
        <MemberTotalInfoContainer userId={param.userId} />
    )
}
export default MemberTotalInfo;