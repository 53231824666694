import {call, put} from "redux-saga/effects";
import {failedGetOrgAuthListReducer, setOrgAuthListReducer} from "../../reducers/partner/OrgAuthListReducer";
import {fetchOrgAuthList} from "../../../lib/api";

export function* getOrgAuthListSaga(action) {
    try{
        console.log("##getOrgAuthListSaga input#",action.payload.input)
        const res = yield call(fetchOrgAuthList,
            action.payload.input,
        );
        console.log("##getOrgAuthListSaga output#",res.data)
        yield put(setOrgAuthListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetOrgAuthListReducer(error))
    }
}