import React from "react";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import {getResultFixtureDetail, setBetInputs, setCheckedInputs, setCheckedDetailInputs} from "../../../redux/reducers/game/manage/resultMatch";
import axios from "axios";
import _ from 'lodash';
import common from "../../../util/common";
import {NumericFormat} from "react-number-format";
import BetStatus from "../../../constant/BetStatus";
import FixtureStatus from "../../../constant/FixtureStatus";
import BetSettlement from "../../../constant/BetSettlement";

const ResultList = ({state, onRefresh}) => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo);

    const changeHandler = (checked, id, index) => {
        if(checked) {
            dispatch(setCheckedInputs([...state.checkedInputs, id]));
        }
        else {
            dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== id)));
        }
    };

    const changeDetailHandler = (checked, id) => {
        if(checked) {
            dispatch(setCheckedDetailInputs([...state.checkedDetailInputs, id]));
        }
        else {
            dispatch(setCheckedDetailInputs(state.checkedDetailInputs.filter((el) => el !== id)));
        }
    };

    const allChangeHandler = (e) => {
        const check_ids = []
        for(let index = 0; index < state.low_ratios.length; index++) {
            check_ids.push(state.low_ratios[index].fixture_id)
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []));
    }

    const allChangeDetailHandler = (checked, arr) => {
        let check_ids = [...state.checkedDetailInputs]

        for(let index = 0; index < arr.length; index++) {
            if(checked) {
                if (!check_ids.includes(arr[index].id)) {
                    check_ids.push(arr[index].id)
                }
            }
            else {
                if(check_ids.includes(arr[index].id)) {
                    check_ids = check_ids.filter((id) => id !== arr[index].id)
                }
            }
        }

        dispatch(setCheckedDetailInputs(check_ids));
    }

    const changeBet = (e, id, index, type) => {
        if(e.target.value !== e.target.defaultValue) {
            e.target.style.color = "green"
        }
        else {
            e.target.style.color = "#555"
        }
        let value;
        if([1,2,3].includes(type)) {
            value = parseFloat(e.target.value)
        }
        else {
            value = e.target.value
        }
        const data = {
            bet1: state.fixtures[index].bet1,
            bet2: state.fixtures[index].bet2,
            bet3: state.fixtures[index].bet3,
            home_score: state.fixtures[index].home_score,
            away_score: state.fixtures[index].away_score,
        }

        const betInputs2 = state.betInputs.filter((el) => el.id !== id);

        const betInputs3 = state.betInputs.filter((el) => el.id === id && el.is_edited === 1);
        let inputs
        if(betInputs3.length > 0) {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: betInputs3[0].bet2, bet3: betInputs3[0].bet3, home_score: betInputs3[0].home_score, away_score: betInputs3[0].away_score}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: betInputs3[0].bet1, bet3: betInputs3[0].bet3, home_score: betInputs3[0].home_score, away_score: betInputs3[0].away_score}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1, home_score: betInputs3[0].home_score, away_score: betInputs3[0].away_score}
            }
            else if(type === 4) {
                inputs = {id: id, bet3: betInputs3[0].bet3, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1, home_score: value, away_score: betInputs3[0].away_score}
            }
            else if(type === 5) {
                inputs = {id: id, bet3: betInputs3[0].bet3, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1, home_score: betInputs3[0].home_score, away_score: value}
            }
        }
        else {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: data.bet2, bet3: data.bet3, home_score: data.home_score, away_score: data.away_score}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: data.bet1, bet3: data.bet3, home_score: data.home_score, away_score: data.away_score}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: data.bet2, bet1: data.bet1, home_score: data.home_score, away_score: data.away_score}
            }
            else if(type === 4) {
                inputs = {id: id, bet3: data.bet3, bet2: data.bet2, bet1: data.bet1, home_score: value, away_score: data.away_score}
            }
            else if(type === 5) {
                inputs = {id: id, bet3: data.bet3, bet2: data.bet2, bet1: data.bet1, home_score: data.home_score, away_score: value}
            }
        }
        inputs.is_edited = 1;
        dispatch(setBetInputs([...betInputs2, inputs]))

        //체크처리
        const checkedInput2 = state.checkedInputs.filter((el) => el !== id)
        dispatch(setCheckedInputs([...checkedInput2, id]));
    }

    const editResultToCloseMatch = (fixtureIds) => {
        console.log(fixtureIds)
        axios.post('/admin/game/manage/editResultToCloseMatch', {
            adminNo: userInfo.userNo,
            fixture_id: fixtureIds
        }).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 마감완료");
                onRefresh()
            }
        })
    }

    const editResultToCloseGames = (rowIds) => {
        axios.post('/admin/game/manage/editResultToCloseGames', {
            adminNo: userInfo.userNo,
            id: rowIds
        }).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 마감완료");
                onRefresh()
            }
        })
    }

    function getSettlement(fixture) {
        let settlement = "진행중"
        if(fixture.baseline === null && !fixture.market.includes("Correct Score")) {
            if(fixture.bet2) {
                if(fixture.bet1_settlement === BetSettlement.WINNER) {
                    settlement = "승"
                }
                else if(fixture.bet2_settlement === BetSettlement.WINNER) {
                    settlement = "무"
                }
                else if(fixture.bet3_settlement === BetSettlement.WINNER) {
                    settlement = "패"
                }
                else if(fixture.bet1_settlement === BetSettlement.REFUND) {
                    settlement = "적특"
                }
            }
            else {
                if(fixture.home.includes("[홀]")) {
                    if(fixture.bet1_settlement === BetSettlement.WINNER) {
                        settlement = "홀"
                    }
                    else if(fixture.bet3_settlement === BetSettlement.WINNER) {
                        settlement = "짝"
                    }
                    else if(fixture.bet1_settlement === BetSettlement.REFUND) {
                        settlement = "적특"
                    }

                }
                else {
                    if(fixture.bet1_settlement === BetSettlement.WINNER) {
                        settlement = "승"
                    }
                    else if(fixture.bet3_settlement === BetSettlement.WINNER) {
                        settlement = "패"
                    }
                    else if(fixture.bet1_settlement === BetSettlement.REFUND) {
                        settlement = "적특"
                    }

                }
            }
        }
        else if(fixture.baseline !== null) {
            if(fixture.market.includes("Under")) {
                if(fixture.bet1_settlement === BetSettlement.WINNER) {
                    settlement = "오버"
                }
                else if(fixture.bet3_settlement === BetSettlement.WINNER) {
                    settlement = "언더"
                }
                else if(fixture.bet1_settlement === BetSettlement.REFUND) {
                    settlement = "적특"
                }
            }
            else {
                if(fixture.bet1_settlement === BetSettlement.WINNER) {
                    settlement = "승"
                }
                else if(fixture.bet3_settlement === BetSettlement.WINNER) {
                    settlement = "패"
                }
                else if(fixture.bet1_settlement === BetSettlement.REFUND) {
                    settlement = "적특"
                }
            }

        }
        else if(fixture.market.includes("Correct Score")) {
            settlement = BetSettlement.getKorMessage(fixture.bet1_settlement)
        }

        return settlement;
    }

    function getDetail() {
        const ratios = state.currentFixture.ratios
        const ratiosByMarket = common.groupBy(ratios, "market_id")
        const keys = Object.keys(ratiosByMarket)
        const result = []
        for(let i = 0; i < keys.length; i++) {

            //마켓아이디 처리
            const marketId = keys[i]

            const arr = ratiosByMarket[marketId]
            // 마켓아이디별 픽스쳐처리
            const trs = []
            for(const fixture of arr) {
                const settle = getSettlement(fixture)
                let settleClass = ""
                if(settle === "적특") {
                    settleClass = "table-danger"
                }
                else if(settle === "진행중") {
                    settleClass = "table-primary"
                }
                trs.push(<tr>
                    <td>
                        <input type="checkbox" name={fixture.id} id={fixture.id} value="false"
                               checked={state.checkedDetailInputs.includes(fixture.id)}
                               onChange={(e) => {changeDetailHandler(e.currentTarget.checked, fixture.id)}}
                        />
                    </td>
                    <td>{fixture.provider}</td>
                    <td className={fixture.bet1_settlement === 2 && "table-success"}>
                        <div>
                            <p>{fixture.bet1}</p>
                            {fixture.total && (
                                <div>
                                    <p style={{color: "green"}} onClick={(e) => {
                                        window.open("/game/manual/betting_popup/" + fixture.id + "/1", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                    }}>
                                        <NumericFormat value={fixture.total.sumExtraMoney1} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount1})`}</span>
                                    </p>
                                    <p style={{color: "red"}} onClick={(e) => {
                                        window.open("/game/manual/betting_popup/" + fixture.id + "/1", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                    }}>
                                        <NumericFormat value={fixture.total.sumWinMoney1} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betWinCount1})`}</span>
                                    </p>
                                    <p style={{color: "blue"}} onClick={(e) => {
                                        window.open("/game/manual/betting_popup/" + fixture.id + "/1", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                    }}>
                                        <NumericFormat value={fixture.total.sumTotalMoney1} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betTotalCount1})`}</span>
                                    </p>
                                </div>
                            )}
                        </div>
                    </td>
                    <td className={fixture.baseline === null && (fixture.bet2_settlement  === 2 && "table-success")}>
                        <div>
                            <p>
                                {fixture.baseline? fixture.baseline.split(' (0')[0] : fixture.bet2? fixture.bet2: "VS"}
                            </p>
                            {fixture.baseline === null && fixture.total && fixture.bet2 && (
                                <div>
                                    <p style={{color: "green"}} onClick={(e) => {
                                        window.open("/game/manual/betting_popup/" + fixture.id + "/2", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                    }}>
                                        <NumericFormat value={fixture.total.sumExtraMoney2} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount2})`}</span>
                                    </p>
                                    <p style={{color: "red"}} onClick={(e) => {
                                        window.open("/game/manual/betting_popup/" + fixture.id + "/2", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                    }}>
                                        <NumericFormat value={fixture.total.sumWinMoney2} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betWinCount2})`}</span>
                                    </p>
                                    <p style={{color: "blue"}} onClick={(e) => {
                                        window.open("/game/manual/betting_popup/" + fixture.id + "/2", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                    }}>
                                        <NumericFormat value={fixture.total.sumTotalMoney2} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betTotalCount2})`}</span>
                                    </p>
                                </div>
                            )}
                        </div>
                    </td>
                    <td className={fixture.bet3_settlement  === 2 && "table-success"}>
                        <div>
                            <p>{fixture.bet3}</p>
                            {fixture.total && (
                                <div>
                                    <p style={{color: "green"}} onClick={(e) => {
                                        window.open("/game/manual/betting_popup/" + fixture.id + "/3", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                    }}>
                                        <NumericFormat value={fixture.total.sumExtraMoney3} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount3})`}</span>
                                    </p>
                                    <p style={{color: "red"}} onClick={(e) => {
                                        window.open("/game/manual/betting_popup/" + fixture.id + "/3", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                    }}>
                                        <NumericFormat value={fixture.total.sumWinMoney3} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betWinCount3})`}</span>
                                    </p>
                                    <p style={{color: "blue"}} onClick={(e) => {
                                        window.open("/game/manual/betting_popup/" + fixture.id + "/3", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                    }}>
                                        <NumericFormat value={fixture.total.sumTotalMoney3} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betTotalCount3})`}</span>
                                    </p>
                                </div>
                            )}
                        </div>
                    </td>
                    <td className={settleClass}>{settle}</td>
                    <td>{BetStatus.getKorMessage(fixture.bet1_status)}</td>
                    <td>{moment(fixture.bet1_updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{moment(fixture.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                </tr>)
            }
            const ids = []
            for(const row of arr) {
                if(!ids.includes(row.id)) {
                    ids.push(row.id)
                }
            }
            const tableTemp = <div>
                <p className="h4 text-bg-dark mb-0 p-2">{arr[0].market}</p>
                <table className="table table-bordered table-hover text-center">
                    <thead className="table-secondary">
                    <tr>
                        <th style={{
                            width: "auto",
                        }}>
                            <input type="checkbox" name={arr[0].market_id} id={"chk_" + arr[0].market_id} value="true"
                                   checked={_.difference(ids, state.checkedDetailInputs).length === 0 && state.checkedDetailInputs.length > 0}
                                   onChange={(e) => {
                                       allChangeDetailHandler(e.currentTarget.checked, arr)
                                   }}
                            />
                        </th>
                        <th style={{
                            width: "auto"
                        }}>업체</th>
                        <th style={{
                            width: "35%"
                        }}>{arr[0].home}</th>
                        <th style={{
                            width: "13%"
                        }}>{arr[0].market_id === 1? "무" : "기준점"}</th>
                        <th style={{
                            width: "35%"
                        }}>{arr[0].away}</th>
                        <th style={{
                            width: "auto"
                        }}>당첨여부</th>
                        <th style={{
                            width: "auto"
                        }}>배당상태</th>
                        <th style={{
                            width: "auto"
                        }}>배당수정시간</th>
                        <th style={{
                            width: "auto"
                        }}>수정시간</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trs}
                    </tbody>
                </table>
            </div>

            result.push(tableTemp)
        }

        return result
    }

    return (
        <div className="container-fluid p-1 m-0">

            <div className="mb-1">
                <button className="btn btn-warning me-1" onClick={() => {editResultToCloseMatch(state.checkedInputs)}} >선택경기마감</button>
            </div>

            <div className="d-flex">
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {state.low_ratios && (
                        <table className="table table-bordered table-hover text-center">
                            <thead className="table-dark">
                            <tr>
                                <th className="text-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            state.checkedInputs.length === state.low_ratios.length
                                        }
                                        onChange={(e) => {allChangeHandler(e)}}
                                    />
                                </th>
                                <th>종목</th>
                                <th>일정</th>
                                <th>리그</th>
                                <th>홈팀</th>
                                <th>원정팀</th>
                                <th>
                                    <div>
                                        <p>결과</p>
                                        <p>상태</p>
                                    </div>
                                </th>
                                <th>진행배팅금</th>
                                <th>Fix수정시간</th>
                                <th>FixID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.isLoading && "로딩중..."}
                            {!state.isLoading && !state.low_ratios.length > 0 && (
                                <tr>
                                    <td colSpan="3">목록이 비어있습니다.</td>
                                </tr>
                            )}
                            {!state.isLoading && !!state.low_ratios.length && state.low_ratios.map((item, index) => (
                                <tr className={state.checkedInputs.includes(item.id) && "info"} onClick={() => {
                                    dispatch(getResultFixtureDetail(item.fixture_id))
                                }}>
                                    <td>
                                        <input type="checkbox" name={item.fixture_id} id={item.fixture_id} value="true"
                                               checked={state.checkedInputs.includes(item.fixture_id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, item.fixture_id, index)}}
                                        />
                                    </td>
                                    <td>{`${item.sport}\n(${item.mCount})`}</td>
                                    <td>{moment(item.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{item.league}</td>
                                    <td>
                                        {item.home.split(" [")[0]}
                                    </td>
                                    <td>{item.away.split(" [")[0]}</td>
                                    <td>
                                        <div style={{
                                            width: "50px",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <p>{item.home_score !== -1? item.home_score : "-"}</p>
                                            <p className="mx-1">:</p>
                                            <p>{item.away_score !== -1? item.away_score : "-"}</p>
                                        </div>
                                        <div>
                                            <p>{FixtureStatus.getKorMessage(item.fixture_status)}</p>
                                        </div>
                                    </td>
                                    <td>
                                        {item.total && (
                                            <div style={{
                                                width: "100px",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{color: "green"}} onClick={(e) => {
                                                    window.open("/game/manual/betting_popup/" + item.fixture_id + "/0", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                                }}><NumericFormat value={item.total.sumExtraMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betCount})`}</span></p>
                                                <p style={{color: "red"}} onClick={(e) => {
                                                    window.open("/game/manual/betting_popup/" + item.fixture_id + "/0", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                                }}><NumericFormat value={item.total.sumWinMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betWinCount})`}</span></p>
                                                <p style={{color: "blue"}} onClick={(e) => {
                                                    window.open("/game/manual/betting_popup/" + item.fixture_id + "/0", "BettingDetailsPopup", "width=1280,height=1024,scrollbars=yes");
                                                }}><NumericFormat value={item.total.sumTotalMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betTotalCount})`}</span></p>
                                            </div>
                                        )}
                                    </td>
                                    <td>{moment(item.fixture_updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>{item.fixture_id}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {state.isLoadingDetail && "로딩중..."}
                    {!state.isLoadingDetail && state.currentFixture === null && "선택된 경기가 없습니다."}
                    {!state.isLoadingDetail && state.currentFixture !== null && (
                        <div>
                            <div className="text-bg-dark p-2 mb-1">
                                <p>{`${state.currentFixture.ratios[0].sport} - ${state.currentFixture.ratios[0].league}`}</p>
                                <div className="text-center align-middle" style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}><p>{`${state.currentFixture.ratios[0].home.split(" [")[0]}`}</p></div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center",
                                    }}>
                                        <p className="m-0">{`${moment(state.currentFixture.ratios[0].start_date).format("YYYY-MM-DD HH:mm")}`}</p>
                                        <p className="m-0">{`${state.currentFixture.ratios[0].home_score !== -1? state.currentFixture.ratios[0].home_score : "-"} : ${state.currentFixture.ratios[0].away_score !== -1? state.currentFixture.ratios[0].away_score : "-"}`}</p>
                                    </div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}>{`${state.currentFixture.ratios[0].away.split(" [")[0]}`}</div>

                                </div>

                            </div>
                            <div className="mb-2">
                                <button className="btn btn-warning me-1" onClick={() => {editResultToCloseGames(state.checkedDetailInputs)}} >선택경기마감</button>
                            </div>
                            {getDetail()}

                        </div>
                    )}

                </div>
            </div>
        </div>
    );

}

export default ResultList;