import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"


export const rouletteSettingSlice = createSlice({
    name: 'rouletteSetting',
    initialState: {
        isLoading: false,
        error: null,
        rouletteSetting: [],
        countList: {},
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        selectedNos1: [],
        query: "01",
        userGbCd: "",
        searchGbCd: "",
        selectList1: [],
        selectList2: [],
        holeGbCdArr:[],
        vipHoleGbCdArr:[],
    },
    reducers: {
        getRouletteSetting(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userGbCd = action.payload.userGbCd;
            state.searchGbCd = action.payload.searchGbCd;

        },
        setRouletteSetting(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.rouletteSetting = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList;
            state.selectList1 = action.payload.selectList1;
            state.selectList2 = action.payload.selectList2;
        },
        failedGeRouletteSetting(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setIsOpen(state,action){
            state.isOpen = action.payload;
        },
        setQuery(state, action){
            state.query = action.payload;
        },
        setSelectedNos1(state,action){
            state.selectedNos1 = action.payload;
        },
        setHoleGbCdArr(state,action){
            state.holeGbCdArr = action.payload;
        },
        setVipHoleGbCdArr(state,action){
            state.vipHoleGbCdArr = action.payload;
        },
    }
});

export const { getRouletteSetting, setRouletteSetting, failedGeRouletteSetting, setIsOpen, setQuery, setSelectedNos1, setHoleGbCdArr, setVipHoleGbCdArr
} = rouletteSettingSlice.actions;

export default rouletteSettingSlice.reducer;