import React, {useEffect, useState} from "react";
import BettingContainer from "../betting/BettingContainer";
import DepositWithdrawLogContainer from "../deposit/DepositWithdrawLogContainer";
import MarginContainer from "../deposit/MarginContainer";
import DepositListContainer from "../deposit/DepositListContainer";
import WithdrawListContainer from "../deposit/WithdrawListContainer";
import MoneyListContainer from "../deposit/MoneyListContainer";
import PointListContainer from "../deposit/PointListContainer";
import CustomerContainer from "../board/CustomerContainer";
import Lottery from "../../pages/user/Lottery";
import CouponListContainer from "../deposit/CouponListContainer";
import axios from "axios";
import MemberDetailInfoContainer from "./detail/MemberDetailInfoContainer";


const MemberTotalInfoContainer = ({userId}) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [user, setUser] = useState()


    useEffect(() => {
        if(userId && userId !== "") {
            getUserInfo(userId)
        }

    }, [])
    const getUserInfo = (id) => {
        axios.post('/admin/member/total/getMemberInfo', {
            userId: id
        }).then((res) => {
            setUser(res.data.user)
        })
    }

    const getContainer = () => {
        switch (selectedTab) {
            case 0: return <BettingContainer userId={userId} />             //베팅내역
            case 1: return <DepositWithdrawLogContainer userId={userId}/>   //충/환전로그
            case 2: return <MarginContainer userId={userId}/>               //마진 로그
            case 3: return <DepositListContainer userId={userId} />         //충전내역
            case 4: return <WithdrawListContainer userId={userId} />        //환전내역
            case 5: return <MoneyListContainer userId={userId} />           //머니거래내역
            case 6: return <PointListContainer userId={userId} />           //포인트내역
            case 7: return <MemberDetailInfoContainer userId={userId} />    //회원정보
            case 8: return <CustomerContainer userId={userId} />            //고객센터 글 보기
            case 9: return <Lottery userId={userId} />                      //복권내역
            case 10: return <CouponListContainer userId={userId} />         //쿠폰내역
            default: return <BettingContainer userId={userId} />

        }
    }
    
    return <div className="ms-1" style={{width: "100%", backgroundColor: "#D7D4CF"}}>
        <div className="row align-items-center" style={{backgroundColor: "#1F68EE", margin: "1px"}}>
            <div style={{color: "white", fontSize: "15px"}}>
                {`[2] ${user?.nickname} (${user?.id}) 님의 관련 정보`}
            </div>
        </div>
        <div style={{ display: "flex", backgroundColor: "white", margin: "1px"}}>
            <button style={{ backgroundColor: selectedTab === 0? "red" : "#D7D4CF",
                color: selectedTab === 0? "white" : "", border: "none", fontSize: "9px", height: "40px",
                width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(0)
            }}>베팅내역</button>
            <button style={{ backgroundColor: selectedTab === 1? "red" : "#D7D4CF",
                color: selectedTab === 1? "white" : "", border: "none", fontSize: "9px", height: "40px", width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(1)
            }}>충/환전 로그</button>
            <button style={{ backgroundColor: selectedTab === 2? "red" : "#D7D4CF",
                color: selectedTab === 2? "white" : "", border: "none", fontSize: "9px", height: "40px", width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(2)
            }}>마진 로그</button>
            <button style={{ backgroundColor: selectedTab === 3? "red" : "#D7D4CF",
                color: selectedTab === 3? "white" : "", border: "none", fontSize: "9px", height: "40px", width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(3)
            }}>충전내역</button>
            <button style={{ backgroundColor: selectedTab === 4? "red" : "#D7D4CF",
                color: selectedTab === 4? "white" : "", border: "none", fontSize: "9px", height: "40px", width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(4)
            }}>환전내역</button>
            <button style={{ backgroundColor: selectedTab === 5? "red" : "#D7D4CF",
                color: selectedTab === 5? "white" : "", border: "none", fontSize: "9px", height: "40px", width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(5)
            }}>머니거래내역</button>
            <button style={{ backgroundColor: selectedTab === 6? "red" : "#D7D4CF",
                color: selectedTab === 6? "white" : "", border: "none", fontSize: "9px", height: "40px", width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(6)
            }}>포인트내역</button>
            <button style={{ backgroundColor: selectedTab === 7? "red" : "#D7D4CF",
                color: selectedTab === 7? "white" : "", border: "none", fontSize: "9px", height: "40px", width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(7)
            }}>회원정보</button>
            <button style={{ backgroundColor: selectedTab === 8? "red" : "#D7D4CF",
                color: selectedTab === 8? "white" : "", border: "none", fontSize: "9px", height: "40px", width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(8)
            }}>고객센터 글 보기</button>
            <button style={{ backgroundColor: selectedTab === 9? "red" : "#D7D4CF",
                color: selectedTab === 9? "white" : "", border: "none", fontSize: "9px", height: "40px", width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(9)
            }}>복권 내역</button>
            <button style={{ backgroundColor: selectedTab === 10? "red" : "#D7D4CF",
                color: selectedTab === 10? "white" : "", border: "none", fontSize: "9px", height: "40px", width: "120px", fontWeight: "bold", marginRight: "1px"}} onClick={(e) => {
                setSelectedTab(10)
            }}>쿠폰 내역</button>
        </div>
        <div style={{backgroundColor: "white"}}>
            {getContainer()}
        </div>

    </div>
}

export default MemberTotalInfoContainer