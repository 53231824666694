import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {
    setSettingLiveYn,
    setSettingSlotYn,
    setSettingMiniYn,
    setSettingSportYn,
    setSettingMiniDtlYn,
    setSettingSportDtlYn,
    setOutbreakSdtArr,
    setOutbreakEdtArr,
    setBonusPaymentMethodArr,
    setIsOpen,
    getLvlSetting,
    setQuery,
    setSettingSportDomesticYn,
    setSettingSportForeignYn,
    setSettingSportInplayYn,
    setSettingSportSpecialYn,
    setSettingPaymentCycleTime,
    setSportPaymentMethodArr,
    setBonusQuery
} from "../../redux/reducers/setting/LvlSettingListReducer";
import Switch from "react-switch";
import LvlSettingComponent from "../../components/modal/setting/LvlSettingComponent";
import MemberAddComponent from "../MemberAddComponent";

const LvlSetting = ({lvlSetting, onRefresh, initialState, deleteState, insertState, mainList1, mainList2, mainList3, mainList4, mainList5, onSearch}) => {

    const dispatch = useDispatch()

    const userInfoId = useSelector((state) => state.userInfo.id)
    const [changeColorList, setChangeColorList] = useState([]);
    const [colorCd, setColorCd] = useState('')
    const [insertColorCd, setInsertColorCd] = useState('')
    const [noArr, setNoArr] = useState([]);
    const [etcArr, setEtcNmArr] = useState([]);
    const [tagNmArr, setTagNmArr] = useState([]);
    const [colorNmArr, setColorNmArr] = useState([]);

    const [insertNoArr, setInsertNoArr] = useState([]);
    const [insertEtcArr, setInsertEtcNmArr] = useState([]);
    const [insertTagNmArr, setInsertTagNmArr] = useState([]);
    const [insertColorNmArr, setInsertColorNmArr] = useState([]);

    const [updateArr01, setUpdateArr01] = useState([]);
    const [updateArr02, setUpdateArr02] = useState([]);
    const [updateArr03, setUpdateArr03] = useState([]);
    const [updateArr04, setUpdateArr04] = useState([]);
    const [updateArr05, setUpdateArr05] = useState([]);
    const [updateArr06, setUpdateArr06] = useState([]);

    const [minDepositAmt, setMinDepositAmt] = useState("");
    const [minWithdrawAmt, setMinWithdrawAmt] = useState("");
    const [maxDepositAmt, setMaxDepositAmt] = useState("");
    const [maxWithdrawAmt, setMaxWithdrawAmt] = useState("");
    const [dayMaxWithdrawAmt, setDayMaxWithdrawAmt] = useState("");
    const [minConvertAmt, setMinConvertAmt] = useState("");
    const [depositAmtUnit, setDepositAmtUnit] = useState("");
    const [withdrawAmtUnit, setWithdrawAmtUnit] = useState("");
    const [passwordYn, setPasswordYn] = useState("");
    const [miniOneLosePoint, setMiniOneLosePoint] = useState("")
    const [miniCombiLosePoint, setMiniCombiLosePoint] = useState("");
    const [miniAllLosePoint, setMiniAllLosePoint] = useState("");
    const [sportLiveOneLosePoint, setSportLiveOneLosePoint] = useState("");
    const [sportLiveTwoLosePoint, setSportLiveTwoLosePoint] = useState("")
    const [sportLiveThreeLosePoint, setSportLiveThreeLosePoint] = useState("");
    const [sportLiveFourLosePoint, setSportLiveFourLosePoint] = useState("");
    const [sportLiveManyLosePoint, setSportLiveManyLosePoint] = useState("");
    const [sportLiveAllLosePoint, setSportLiveAllLosePoint] = useState("");
    const [sportFreeOneLosePoint, setSportFreeOneLosePoint] = useState("")
    const [sportFreeTwoLosePoint, setSportFreeTwoLosePoint] = useState("");
    const [sportFreeThreeLosePoint, setSportFreeThreeLosePoint] = useState("");
    const [sportFreeFourLosePoint, setSportFreeFourLosePoint] = useState("")
    const [sportFreeManyLosePoint, setSportFreeManyLosePoint] = useState("");
    const [sportFreeAllLosePoint, setSportFreeAllLosePoint] = useState("");
    const [sportMaxDayLosePoint, setSportMaxDayLosePoint] = useState("");
    const [casinoLiveMaxRolling, setCasinoLiveMaxRolling] = useState("");
    const [casinoLiveMinRolling, setCasinoLiveMinRolling] = useState("");
    const [casinoSlotMaxRolling, setCasinoSlotMaxRolling] = useState("");
    const [casinoSlotMinRolling, setCasinoSlotMinRolling] = useState("");
    const [miniMinBetAmt, setMiniMinBetAmt] = useState("");
    const [miniMaxRolling, setMiniMaxRolling] = useState("");
    const [miniMinRolling, setMiniMinRolling] = useState("");
    const [sportMaxRolling, setSportMaxRolling] = useState("");
    const [sportMinRolling, setSportMinRolling] = useState("");

    const [rollingMinConversionAmt, setRollingMinConversionAmt] = useState("");
    const [rollingConversionLimitCnt, setRollingConversionLimitCnt] = useState("");
    const [rollingConversionLimitDay, setRollingConversionLimitDay] = useState("");
    const [withdrawRetryWaitTime, setWithdrawRetryWaitTime] = useState("");
    const [depositRetryWaitTime, setDepositRetryWaitTime] = useState("");
    const [depositWithdrawWaitTime, setDepositWithdrawWaitTime] = useState("");
    const [todayWithdrawLimitTime, setTodayWithdrawLimitTime] = useState("");
    const [todayWithdrawLimitCnt, setTodayWithdrawLimitCnt] = useState("");
    const [betCutMaxAmt, setBetCutMaxAmt] = useState("");
    const [betCutPercent, setBetCutPercent] = useState("");



    const [boonMiniCd, setBoonMiniCd] = useState("");
    const [boonMiniPer, setBoonMiniPer] = useState("")
    const [boonCasinoCd, setBoonCasinoCd] = useState("");
    const [boonCasinoPer, setBoonCasinoPer] = useState("");
    const [boonSlotCd, setBoonSlotCd] = useState("");
    const [boonSlotPer, setBoonSlotPer] = useState("");
    const [boonSportOneCd, setBoonSportOneCd] = useState("");
    const [boonSportOnePer, setBoonSportOnePer] = useState("");
    const [boonSportTwoCd, setBoonSportTwoCd] = useState("");
    const [boonSportTwoPer, setBoonSportTwoPer] = useState("");
    const [boonSportThreeCd, setBoonSportThreeCd] = useState("");
    const [boonSportThreePer, setBoonSportThreePer] = useState("")
    const [boonSportFourCd, setBoonSportFourCd] = useState("");
    const [boonSportFourPer, setBoonSportFourPer] = useState("")
    const [boonSportManyCd, setBoonSportManyCd] = useState("");
    const [boonSportManyPer, setBoonSportManyPer] = useState("");

    const [paymentCycle, setPaymentCycle] = useState("");
    const [paymentCycleDtlCd, setPaymentCycleDtlCd] = useState("");
    const [paymentCycleTime, setPaymentCycleTime] = useState("");
    const [paymentCycleDay, setPaymentCycleDay] = useState("");
    const [startDt, setStartDt] = useState("");
    const [deadlineDt, setDeadlineDt] = useState("");
    const [paymentDt, setPaymentDt] = useState("");
    const [paymentCd, setPaymentCd] = useState("");
    const [paymentFormula, setPaymentFormula] = useState("");
    const [payback, setPayback] = useState("");
    const [minusYn, setMinusYn] = useState("");
    const [paymentMaxAmt, setPaymentMaxAmt] = useState("");
    const [paymentDepositCount, setPaymentDepositCount] = useState("");
    const [paymentDepositDay, setPaymentDepositDay] = useState("");
    const [marginSetAmt, setMarginSetAmt] = useState("");
    const [paymentMinAmt, setPaymentMinAmt] = useState("");

    const [bonusUseYn, setBonusUseYn] = useState("");
    const [bonusPaymentCnt, setBonusPaymentCnt] = useState("");

    const [chargeBounsAction, setChargeBounsAction] = useState("");
    const [chargeBounsNo, setChargeBounsNo] = useState("");
    const [chargeBounsLimitMoney, setChargeBounsLimitMoney] = useState("");
    const [chargeBounsLimitAll, setChargeBounsLimitAll] = useState("");
    const [firstChargeLimitDayYn, setFirstChargeLimitDayYn] = useState("");
    const [everyChargeLimitDayYn, setEveryChargeLimitDayYn] = useState("");
    const [firstBonusPer, setFirstBonusPer] = useState("");
    const [everyBonusPer, setEveryBonusPer] = useState("");
    const [maxBonusCnt, setMaxBonusCnt] = useState("");
    const [maxBonusDay, setMaxBonusDay] = useState("");
    const [outbreakLimitYn, setOutbreakLimitYn] = useState("");
    const [maxOutbreakCnt, setMaxOutbreakCnt] = useState("");
    const [sportPaymentMethod, setSportPaymentMethod] = useState("");
    const [bonusNm, setBonusNm] = useState("");

    const [memoArr, setMemoArr] = useState([]);
    const [addPercentArr, setAddPercentArr] = useState([]);


    /*
    const [settingLiveYn, settSettingLiveYn] = useState("");
    const [settingSlotYn, setSettingSlotYn] = useState("N");
    const [settingMiniYn, setSettingMiniYn] = useState("N");
    const [settingSportYn, setSettingSportYn] = useState("N");
    const [settingMiniDtlYn, setSettingMiniDtlYn] = useState("N");
    const [settingSportDtlYn, setSettingSportDtlYn] = useState("N");
    */

    const [levels, setLevels] = useState([])

    useEffect(() => {
        getGrades()
    }, [])

    const getGrades = () => {
        axios.post('/admin/admin/getPointGrade2', {

        }).then((res) => {
            setLevels(res.data.result)
            const arr = []
            for(const level of res.data.result) {
                arr.push(level.setting_level_no)
            }
        })
    }

    const insertTagNmArrHandler = (e, index) => {
        insertTagNmArr[index] = e.target.value;
        setInsertTagNmArr(insertTagNmArr);
        insertNoArr[index] = index;
        setInsertNoArr(insertNoArr);

        const {name, value} = e.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;

        //alert("selectedNos >>> "+JSON.stringify(insertTagNmArr));
    }
    const insertEtcArrHandler = (e, index) => {
        //alert("selectedNos0 >>> "+JSON.stringify(noArr));
        insertEtcArr[index] = e.target.value;
        setInsertEtcNmArr(insertEtcArr);
        insertNoArr[index] = index;
        setInsertNoArr(insertNoArr);
        const {name, value} = e.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        //alert("selectedNos >>> "+JSON.stringify(etcArr));
        //alert("selectedNos2 >>> "+JSON.stringify(noArr));
    }
    const insertColorNmArrHandler = (e, index) => {
        insertColorNmArr[index] = e.target.value;
        setInsertColorNmArr(insertColorNmArr);
        setInsertColorCd(e.target.value);

        //alert("insertColorNmArr ::: "+JSON.stringify(insertColorNmArr));

        insertNoArr[index] = index;
        setInsertNoArr(insertNoArr);
        //const {name, value} = e.target;
        const name = "insertColor";
        const value = e.target.value;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;

    }


    const tagNmArrHandler = (e, index) => {
        tagNmArr[index] = e.target.value;
        setTagNmArr(tagNmArr);
        noArr[index] = index;
        setNoArr(noArr);
        //alert("selectedNos >>> "+JSON.stringify(tagNmArr));
    }
    const etcArrHandler = (e, index) => {
        //alert("selectedNos0 >>> "+JSON.stringify(noArr));
        etcArr[index] = e.target.value;
        setEtcNmArr(etcArr);
        noArr[index] = index;
        setNoArr(noArr);
        //alert("selectedNos >>> "+JSON.stringify(etcArr));
        //alert("selectedNos2 >>> "+JSON.stringify(noArr));
    }
    const colorNmArrHandler = (e, index) => {
        colorNmArr[index] = e.target.value;
        setColorCd(e.target.value);
        setColorNmArr(colorNmArr);

        noArr[index] = index;
        setNoArr(noArr);
    }


    const getCmmnCd = () => {
        if(changeColorList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0023',
            }).then((res) => {
                setChangeColorList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])


    const [rowsData, setRowsData] = useState([]);
    const addTableRows = ()=>{

        const rowsInput={
            insertNo:'',
            insertEtc:'',
            insertColor:''
        }
        setRowsData([...rowsData, rowsInput])

    }
    const deleteTableRows = (index)=>{

        const rows = [...rowsData];
        //alert("index :: "+index);
        rows.splice(index, 1);
        //alert("rows :: "+JSON.stringify(rows));
        setRowsData(rows);
    }

    const minDepositAmtChangeHandler = (e) => {
        setMinDepositAmt(e.currentTarget.value);
    }
    const minWithdrawAmtChangeHandler = (e) => {
        setMinWithdrawAmt(e.currentTarget.value);
    }
    const maxDepositAmtChangeHandler = (e) => {
        setMaxDepositAmt(e.currentTarget.value);
    }
    const maxWithdrawAmtChangeHandler = (e) => {
        setMaxWithdrawAmt(e.currentTarget.value);
    }
    const dayMaxWithdrawAmtChangeHandler = (e) => {
        setDayMaxWithdrawAmt(e.currentTarget.value);
    }
    const minConvertAmtChangeHandler = (e) => {
        setMinConvertAmt(e.currentTarget.value);
    }
    const depositAmtUnitChangeHandler = (e) => {
        setDepositAmtUnit(e.currentTarget.value);
    }
    const withdrawAmtUnitChangeHandler = (e) => {
        setWithdrawAmtUnit(e.currentTarget.value);
    }
    const passwordYnChangeHandler = (e) => {
        setPasswordYn(e.currentTarget.value);
    }
    const miniOneLosePointChangeHandler = (e) => {
        setMiniOneLosePoint(e.currentTarget.value);
    }
    const miniCombiLosePointChangeHandler = (e) => {
        setMiniCombiLosePoint(e.currentTarget.value);
    }
    const miniAllLosePointChangeHandler = (e) => {
        setMiniAllLosePoint(e.currentTarget.value);
    }
    const sportLiveOneLosePointChangeHandler = (e) => {
        setSportLiveOneLosePoint(e.currentTarget.value);
    }
    const sportLiveTwoLosePointChangeHandler = (e) => {
        setSportLiveTwoLosePoint(e.currentTarget.value);
    }
    const sportLiveThreeLosePointChangeHandler = (e) => {
        setSportLiveThreeLosePoint(e.currentTarget.value);
    }
    const sportLiveFourLosePointChangeHandler = (e) => {
        setSportLiveFourLosePoint(e.currentTarget.value);
    }
    const sportLiveManyLosePointChangeHandler = (e) => {
        setSportLiveManyLosePoint(e.currentTarget.value);
    }
    const sportLiveAllLosePointChangeHandler = (e) => {
        setSportLiveAllLosePoint(e.currentTarget.value);
    }
    const sportFreeOneLosePointChangeHandler = (e) => {
        setSportFreeOneLosePoint(e.currentTarget.value);
    }
    const sportFreeTwoLosePointChangeHandler = (e) => {
        setSportFreeTwoLosePoint(e.currentTarget.value);
    }
    const sportFreeThreeLosePointChangeHandler = (e) => {
        setSportFreeThreeLosePoint(e.currentTarget.value);
    }
    const sportFreeFourLosePointChangeHandler = (e) => {
        setSportFreeFourLosePoint(e.currentTarget.value);
    }
    const sportFreeManyLosePointChangeHandler = (e) => {
        setSportFreeManyLosePoint(e.currentTarget.value);
    }
    const sportFreeAllLosePointChangeHandler = (e) => {
        setSportFreeAllLosePoint(e.currentTarget.value);
    }
    const sportMaxDayLosePointChangeHandler = (e) => {
        setSportMaxDayLosePoint(e.currentTarget.value);
    }
    const casinoLiveMaxRollingChangeHandler = (e) => {
        setCasinoLiveMaxRolling(e.currentTarget.value);
    }
    const casinoLiveMinRollingChangeHandler = (e) => {
        setCasinoLiveMinRolling(e.currentTarget.value);
    }
    const casinoSlotMaxRollingChangeHandler = (e) => {
        setCasinoSlotMaxRolling(e.currentTarget.value);
    }
    const casinoSlotMinRollingChangeHandler = (e) => {
        setCasinoSlotMinRolling(e.currentTarget.value);
    }
    const miniMinBetAmtChangeHandler = (e) => {
        setMiniMinBetAmt(e.currentTarget.value);
    }
    const miniMaxRollingChangeHandler = (e) => {
        setMiniMaxRolling(e.currentTarget.value);
    }
    const miniMinRollingChangeHandler = (e) => {
        setMiniMinRolling(e.currentTarget.value);
    }

    const boonMiniCdChangeHandler = (e) => {
        setBoonMiniCd(e.currentTarget.value);
    }
    const boonCasinoCdChangeHandler = (e) => {
        setBoonCasinoCd(e.currentTarget.value);
    }
    const boonSlotCdChangeHandler = (e) => {
        setBoonSlotCd(e.currentTarget.value);
    }
    const boonSportOneCdChangeHandler = (e) => {
        setBoonSportOneCd(e.currentTarget.value);
    }
    const boonSportTwoCdChangeHandler = (e) => {
        setBoonSportTwoCd(e.currentTarget.value);
    }
    const boonSportThreeCdChangeHandler = (e) => {
        setBoonSportThreeCd(e.currentTarget.value);
    }
    const boonSportFourCdChangeHandler = (e) => {
        setBoonSportFourCd(e.currentTarget.value);
    }
    const boonSportManyCdChangeHandler = (e) => {
        setBoonSportManyCd(e.currentTarget.value);
    }

    const paymentCycleChangeHandler = (e) => {
        setPaymentCycle(e.currentTarget.value);
        dispatch(setSettingPaymentCycleTime(e.currentTarget.value));
    }
    const paymentCycleDtlCdChangeHandler = (e) => {
        setPaymentCycleDtlCd(e.currentTarget.value);
    }
    const paymentCycleTimeChangeHandler = (e) => {
        setPaymentCycleTime(e.currentTarget.value);
    }
    const paymentCycleDayChangeHandler = (e) => {
        setPaymentCycleDay(e.currentTarget.value);
    }
    const startDtChangeHandler = (e) => {
        setStartDt(e.currentTarget.value);
    }
    const deadlineDtChangeHandler = (e) => {
        setDeadlineDt(e.currentTarget.value);
    }
    const paymentDtChangeHandler = (e) => {
        setPaymentDt(e.currentTarget.value);
    }
    const paymentCdChangeHandler = (e) => {
        setPaymentCd(e.currentTarget.value);
    }
    const paymentFormulaChangeHandler = (e) => {
        setPaymentFormula(e.currentTarget.value);
    }
    const paybackChangeHandler = (e) => {
        setPayback(e.currentTarget.value);
    }
    const minusYnChangeHandler = (e) => {
        setMinusYn(e.currentTarget.value);
    }
    const paymentMaxAmtChangeHandler = (e) => {
        setPaymentMaxAmt(e.currentTarget.value);
    }
    const paymentDepositCountChangeHandler = (e) => {
        setPaymentDepositCount(e.currentTarget.value);
    }
    const paymentDepositDayChangeHandler = (e) => {
        setPaymentDepositDay(e.currentTarget.value);
    }

    const bonusUseYnChangeHandler = (e) => {
        setBonusUseYn(e.currentTarget.value);
    }

    const firstChargeLimitDayYnChangeHandler = (e) => {
        setFirstChargeLimitDayYn(e.currentTarget.value);
    }
    const everyChargeLimitDayYnChangeHandler = (e) => {
        setEveryChargeLimitDayYn(e.currentTarget.value);
    }
    const outbreakLimitYnChangeHandler = (e) => {
        setOutbreakLimitYn(e.currentTarget.value);
    }

    const rollingMinConversionAmtHandler = (e) => {
        setRollingMinConversionAmt(e.currentTarget.value);
    }
    const rollingConversionLimitCntHandler = (e) => {
        setRollingConversionLimitCnt(e.currentTarget.value);
    }
    const rollingConversionLimitDayHandler = (e) => {
        setRollingConversionLimitDay(e.currentTarget.value);
    }
    const withdrawRetryWaitTimeHandler = (e) => {
        setWithdrawRetryWaitTime(e.currentTarget.value);
    }
    const depositRetryWaitTimeHandler = (e) => {
        setDepositRetryWaitTime(e.currentTarget.value);
    }
    const depositWithdrawWaitTimeHandler = (e) => {
        setDepositWithdrawWaitTime(e.currentTarget.value);
    }
    const todayWithdrawLimitTimeHandler = (e) => {
        setTodayWithdrawLimitTime(e.currentTarget.value);
    }
    const todayWithdrawLimitCntHandler = (e) => {
        setTodayWithdrawLimitCnt(e.currentTarget.value);
    }
    const betCutMaxAmtHandler = (e) => {
        setBetCutMaxAmt(e.currentTarget.value);
    }
    const betCutPercentHandler = (e) => {
        setBetCutPercent(e.currentTarget.value);
    }


    const updateState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveLvlMainSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                minDepositAmt: minDepositAmt?parseInt(minDepositAmt):mainList1.min_deposit_amt,
                minWithdrawAmt: minWithdrawAmt?parseInt(minWithdrawAmt):mainList1.min_withdraw_amt,
                maxDepositAmt: maxDepositAmt?parseInt(maxDepositAmt):mainList1.max_deposit_amt,
                maxWithdrawAmt: maxWithdrawAmt?parseInt(maxWithdrawAmt):mainList1.min_withdraw_amt,
                dayMaxWithdrawAmt: dayMaxWithdrawAmt?parseInt(dayMaxWithdrawAmt):mainList1.day_max_withdraw_amt,
                minConvertAmt: minConvertAmt?parseInt(minConvertAmt):mainList1.min_convert_amt,
                depositAmtUnit: depositAmtUnit?parseInt(depositAmtUnit):mainList1.deposit_amt_unit,
                withdrawAmtUnit: withdrawAmtUnit?parseInt(withdrawAmtUnit):mainList1.withdraw_amt_unit,
                passwordYn: passwordYn?passwordYn:mainList1.password_yn,
                miniOneLosePoint: miniOneLosePoint?parseInt(miniOneLosePoint):mainList1.mini_one_lose_point,
                miniCombiLosePoint: miniCombiLosePoint?parseInt(miniCombiLosePoint):mainList1.mini_combi_lose_point,
                miniAllLosePoint: miniAllLosePoint?parseInt(miniAllLosePoint):mainList1.mini_all_lose_point,
                sportLiveOneLosePoint: sportLiveOneLosePoint?parseInt(sportLiveOneLosePoint):mainList1.sport_live_one_lose_point,
                sportLiveTwoLosePoint: sportLiveTwoLosePoint?parseInt(sportLiveTwoLosePoint):mainList1.sport_live_two_lose_point,
                sportLiveThreeLosePoint: sportLiveThreeLosePoint?parseInt(sportLiveThreeLosePoint):mainList1.sport_live_three_lose_point,
                sportLiveFourLosePoint: sportLiveFourLosePoint?parseInt(sportLiveFourLosePoint):mainList1.sport_live_four_lose_point,
                sportLiveManyLosePoint: sportLiveManyLosePoint?parseInt(sportLiveManyLosePoint):mainList1.sport_live_many_lose_point,
                sportLiveAllLosePoint: sportLiveAllLosePoint?parseInt(sportLiveAllLosePoint):mainList1.sport_live_all_lose_point,
                sportFreeOneLosePoint: sportFreeOneLosePoint?parseInt(sportFreeOneLosePoint):mainList1.sport_free_one_lose_point,
                sportFreeTwoLosePoint: sportFreeTwoLosePoint?parseInt(sportFreeTwoLosePoint):mainList1.sport_free_two_lose_point,
                sportFreeThreeLosePoint: sportFreeThreeLosePoint?parseInt(sportFreeThreeLosePoint):mainList1.sport_free_three_lose_point,
                sportFreeFourLosePoint: sportFreeFourLosePoint?parseInt(sportFreeFourLosePoint):mainList1.sport_free_four_lose_point,
                sportFreeManyLosePoint: sportFreeManyLosePoint?parseInt(sportFreeManyLosePoint):mainList1.sport_free_many_lose_point,
                sportFreeAllLosePoint: sportFreeAllLosePoint?parseInt(sportFreeAllLosePoint):mainList1.sport_free_all_lose_point,
                sportMaxDayLosePoint: sportMaxDayLosePoint?parseInt(sportMaxDayLosePoint):mainList1.sport_max_day_lose_point,
                casinoLiveMaxRolling: casinoLiveMaxRolling?parseInt(casinoLiveMaxRolling):mainList1.casino_live_max_rolling,
                casinoLiveMinRolling: casinoLiveMinRolling?parseInt(casinoLiveMinRolling):mainList1.casino_live_min_rolling,
                casinoSlotMaxRolling: casinoSlotMaxRolling?parseInt(casinoSlotMaxRolling):mainList1.casino_slot_max_rolling,
                casinoSlotMinRolling: casinoSlotMinRolling?parseInt(casinoSlotMinRolling):mainList1.casino_slot_min_rolling,
                miniMinBetAmt: miniMinBetAmt?parseInt(miniMinBetAmt):mainList1.mini_min_bet_amt,
                miniMaxRolling: miniMaxRolling?parseInt(miniMaxRolling):mainList1.mini_max_rolling,
                miniMinRolling: miniMinRolling?parseInt(miniMinRolling):mainList1.mini_min_rolling,
                sportMaxRolling: sportMaxRolling?parseInt(sportMaxRolling):mainList1.sport_max_rolling,
                sportMinRolling: sportMinRolling?parseInt(sportMinRolling):mainList1.sport_min_rolling,
                rollingMinConversionAmt: rollingMinConversionAmt?parseInt(rollingMinConversionAmt):mainList1.rolling_min_conversion_amt,
                rollingConversionLimitCnt: rollingConversionLimitCnt?parseInt(rollingConversionLimitCnt):mainList1.rolling_conversion_limit_cnt,
                rollingConversionLimitDay: rollingConversionLimitDay?parseInt(rollingConversionLimitDay):mainList1.rolling_conversion_limit_day,
                withdrawRetryWaitTime: withdrawRetryWaitTime?parseInt(withdrawRetryWaitTime):mainList1.withdraw_retry_wait_time,
                depositRetryWaitTime: depositRetryWaitTime?parseInt(depositRetryWaitTime):mainList1.deposit_retry_wait_time,
                depositWithdrawWaitTime: depositWithdrawWaitTime?parseInt(depositWithdrawWaitTime):mainList1.deposit_withdraw_wait_time,
                todayWithdrawLimitTime: todayWithdrawLimitTime?parseInt(todayWithdrawLimitTime):mainList1.today_withdraw_limit_time,
                todayWithdrawLimitCnt: todayWithdrawLimitCnt?parseInt(todayWithdrawLimitCnt):mainList1.today_withdraw_limit_cnt,
                betCutMaxAmt: betCutMaxAmt?parseInt(betCutMaxAmt):mainList1.bet_cut_max_amt,
                betCutPercent: betCutPercent?parseInt(betCutPercent):mainList1.bet_cut_percent,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }

    const updateMultiState = (state, depositNos) => {
        if (window.confirm("선택하신 레벨을 일괄 변경 하시겠습니까?"))
        {
            for( let i=0; i<updateArr01.length; i++ )
            {
                if( updateArr01[i] == 'Y' )
                {
                    axios.post('/admin/setting/saveLvlMainSetting', {
                        pntGbCd: i,
                        minDepositAmt: minDepositAmt?parseInt(minDepositAmt):mainList1.min_deposit_amt,
                        minWithdrawAmt: minWithdrawAmt?parseInt(minWithdrawAmt):mainList1.min_withdraw_amt,
                        maxDepositAmt: maxDepositAmt?parseInt(maxDepositAmt):mainList1.max_deposit_amt,
                        maxWithdrawAmt: maxWithdrawAmt?parseInt(maxWithdrawAmt):mainList1.min_withdraw_amt,
                        dayMaxWithdrawAmt: dayMaxWithdrawAmt?parseInt(dayMaxWithdrawAmt):mainList1.day_max_withdraw_amt,
                        minConvertAmt: minConvertAmt?parseInt(minConvertAmt):mainList1.min_convert_amt,
                        depositAmtUnit: depositAmtUnit?parseInt(depositAmtUnit):mainList1.deposit_amt_unit,
                        withdrawAmtUnit: withdrawAmtUnit?parseInt(withdrawAmtUnit):mainList1.withdraw_amt_unit,
                        passwordYn: passwordYn?passwordYn:mainList1.password_yn,
                        miniOneLosePoint: miniOneLosePoint?parseInt(miniOneLosePoint):mainList1.mini_one_lose_point,
                        miniCombiLosePoint: miniCombiLosePoint?parseInt(miniCombiLosePoint):mainList1.mini_combi_lose_point,
                        miniAllLosePoint: miniAllLosePoint?parseInt(miniAllLosePoint):mainList1.mini_all_lose_point,
                        sportLiveOneLosePoint: sportLiveOneLosePoint?parseInt(sportLiveOneLosePoint):mainList1.sport_live_one_lose_point,
                        sportLiveTwoLosePoint: sportLiveTwoLosePoint?parseInt(sportLiveTwoLosePoint):mainList1.sport_live_two_lose_point,
                        sportLiveThreeLosePoint: sportLiveThreeLosePoint?parseInt(sportLiveThreeLosePoint):mainList1.sport_live_three_lose_point,
                        sportLiveFourLosePoint: sportLiveFourLosePoint?parseInt(sportLiveFourLosePoint):mainList1.sport_live_four_lose_point,
                        sportLiveManyLosePoint: sportLiveManyLosePoint?parseInt(sportLiveManyLosePoint):mainList1.sport_live_many_lose_point,
                        sportLiveAllLosePoint: sportLiveAllLosePoint?parseInt(sportLiveAllLosePoint):mainList1.sport_live_all_lose_point,
                        sportFreeOneLosePoint: sportFreeOneLosePoint?parseInt(sportFreeOneLosePoint):mainList1.sport_free_one_lose_point,
                        sportFreeTwoLosePoint: sportFreeTwoLosePoint?parseInt(sportFreeTwoLosePoint):mainList1.sport_free_two_lose_point,
                        sportFreeThreeLosePoint: sportFreeThreeLosePoint?parseInt(sportFreeThreeLosePoint):mainList1.sport_free_three_lose_point,
                        sportFreeFourLosePoint: sportFreeFourLosePoint?parseInt(sportFreeFourLosePoint):mainList1.sport_free_four_lose_point,
                        sportFreeManyLosePoint: sportFreeManyLosePoint?parseInt(sportFreeManyLosePoint):mainList1.sport_free_many_lose_point,
                        sportFreeAllLosePoint: sportFreeAllLosePoint?parseInt(sportFreeAllLosePoint):mainList1.sport_free_all_lose_point,
                        sportMaxDayLosePoint: sportMaxDayLosePoint?parseInt(sportMaxDayLosePoint):mainList1.sport_max_day_lose_point,
                        casinoLiveMaxRolling: casinoLiveMaxRolling?parseInt(casinoLiveMaxRolling):mainList1.casino_live_max_rolling,
                        casinoLiveMinRolling: casinoLiveMinRolling?parseInt(casinoLiveMinRolling):mainList1.casino_live_min_rolling,
                        casinoSlotMaxRolling: casinoSlotMaxRolling?parseInt(casinoSlotMaxRolling):mainList1.casino_slot_max_rolling,
                        casinoSlotMinRolling: casinoSlotMinRolling?parseInt(casinoSlotMinRolling):mainList1.casino_slot_min_rolling,
                        miniMinBetAmt: miniMinBetAmt?parseInt(miniMinBetAmt):mainList1.mini_min_bet_amt,
                        miniMaxRolling: miniMaxRolling?parseInt(miniMaxRolling):mainList1.mini_max_rolling,
                        miniMinRolling: miniMinRolling?parseInt(miniMinRolling):mainList1.mini_min_rolling,
                        sportMaxRolling: sportMaxRolling?parseInt(sportMaxRolling):mainList1.sport_max_rolling,
                        sportMinRolling: sportMinRolling?parseInt(sportMinRolling):mainList1.sport_min_rolling,
                        adminNo: userInfoId
                    }).then((res) => {
                        //alert(res.data.isUpdated? "수정완료" : "수정실패")
                        //getAllList()
                        //window.location.reload();
                        //onRefresh();

                    })
                }
            }
            alert("수정완료");
            window.location.reload();
        }

    }


    const updateRecommenderState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveLvlRecommenderSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                boonMiniCd: boonMiniCd?parseInt(boonMiniCd):mainList5.recommender_boon_mini_cd,
                boonMiniPer: boonMiniPer?boonMiniPer:mainList5.recommender_boon_mini_per,
                boonCasinoCd: boonCasinoCd?parseInt(boonCasinoCd):mainList5.recommender_boon_casino_cd,
                boonCasinoPer: boonCasinoPer?boonCasinoPer:mainList5.recommender_boon_mini_per,
                boonSlotCd: boonSlotCd?parseInt(boonSlotCd):mainList5.recommender_boon_slot_cd,
                boonSlotPer: boonSlotPer?boonSlotPer:mainList5.recommender_boon_slot_per,
                boonSportOneCd: boonSportOneCd?parseInt(boonSportOneCd):mainList5.recommender_boon_sport_one_cd,
                boonSportOnePer: boonSportOnePer?boonSportOnePer:mainList5.recommender_boon_sport_one_per,
                boonSportTwoCd: boonSportTwoCd?parseInt(boonSportTwoCd):mainList5.recommender_boon_sport_two_cd,
                boonSportTwoPer: boonSportTwoPer?boonSportTwoPer:mainList5.recommender_boon_sport_two_per,
                boonSportThreeCd: boonSportThreeCd?parseInt(boonSportThreeCd):mainList5.recommender_boon_sport_three_cd,
                boonSportThreePer: boonSportThreePer?boonSportThreePer:mainList5.recommender_boon_sport_three_per,
                boonSportFourCd: boonSportFourCd?parseInt(boonSportFourCd):mainList5.recommender_boon_sport_four_cd,
                boonSportFourPer: boonSportFourPer?boonSportFourPer:mainList5.recommender_boon_sport_four_per,
                boonSportManyCd: boonSportManyCd?parseInt(boonSportManyCd):mainList5.recommender_boon_sport_many_cd,
                boonSportManyPer: boonSportManyPer?boonSportManyPer:mainList5.recommender_boon_sport_many_per,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }


    const updateMultiRecommenderState = (state, depositNos) => {
        if (window.confirm("선택하신 레벨을 일괄 변경 하시겠습니까?"))
        {
            for( let i=0; i<updateArr03.length; i++ )
            {
                if( updateArr03[i] == 'Y' )
                {
                    axios.post('/admin/setting/saveLvlRecommenderSetting', {
                        pntGbCd: i,
                        boonMiniCd: boonMiniCd?parseInt(boonMiniCd):mainList5.recommender_boon_mini_cd,
                        boonMiniPer: boonMiniPer?boonMiniPer:mainList5.recommender_boon_mini_per,
                        boonCasinoCd: boonCasinoCd?parseInt(boonCasinoCd):mainList5.recommender_boon_casino_cd,
                        boonCasinoPer: boonCasinoPer?boonCasinoPer:mainList5.recommender_boon_mini_per,
                        boonSlotCd: boonSlotCd?parseInt(boonSlotCd):mainList5.recommender_boon_slot_cd,
                        boonSlotPer: boonSlotPer?boonSlotPer:mainList5.recommender_boon_slot_per,
                        boonSportOneCd: boonSportOneCd?parseInt(boonSportOneCd):mainList5.recommender_boon_sport_one_cd,
                        boonSportOnePer: boonSportOnePer?boonSportOnePer:mainList5.recommender_boon_sport_one_per,
                        boonSportTwoCd: boonSportTwoCd?parseInt(boonSportTwoCd):mainList5.recommender_boon_sport_two_cd,
                        boonSportTwoPer: boonSportTwoPer?boonSportTwoPer:mainList5.recommender_boon_sport_two_per,
                        boonSportThreeCd: boonSportThreeCd?parseInt(boonSportThreeCd):mainList5.recommender_boon_sport_three_cd,
                        boonSportThreePer: boonSportThreePer?boonSportThreePer:mainList5.recommender_boon_sport_three_per,
                        boonSportFourCd: boonSportFourCd?parseInt(boonSportFourCd):mainList5.recommender_boon_sport_four_cd,
                        boonSportFourPer: boonSportFourPer?boonSportFourPer:mainList5.recommender_boon_sport_four_per,
                        boonSportManyCd: boonSportManyCd?parseInt(boonSportManyCd):mainList5.recommender_boon_sport_many_cd,
                        boonSportManyPer: boonSportManyPer?boonSportManyPer:mainList5.recommender_boon_sport_many_per,
                        adminNo: userInfoId
                    }).then((res) => {
                        //alert(res.data.isUpdated? "수정완료" : "수정실패")
                        //getAllList()
                        //window.location.reload();
                        //onRefresh();

                    })
                }
            }
            alert("수정완료");
            window.location.reload();
        }

    }



    const updateMainBtnState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlMainBtnSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                settingLiveYn: initialState.settingLiveYn == '' ? mainList1.setting_live_yn : initialState.settingLiveYn,
                settingMiniYn: initialState.settingMiniYn == '' ? mainList1.setting_mini_yn : initialState.settingMiniYn,
                settingSlotYn: initialState.settingSlotYn == '' ? mainList1.setting_slot_yn : initialState.settingSlotYn,
                settingSportYn: initialState.settingSportYn == '' ? mainList1.setting_sport_yn : initialState.settingSportYn,
                settingMiniDtlYn: initialState.settingMiniDtlYn == '' ? mainList1.setting_mini_dtl_yn : initialState.settingMiniDtlYn,
                settingSportDtlYn: initialState.settingSportDtlYn == '' ? mainList1.setting_sport_dtl_yn : initialState.settingSportDtlYn,
                settingSportDomesticYn: initialState.settingSportDomesticYn == '' ? mainList1.setting_sport_domestic_yn : initialState.settingSportDomesticYn,
                settingSportForeignYn: initialState.settingSportForeignYn == '' ? mainList1.setting_sport_foreign_yn : initialState.settingSportForeignYn,
                settingSportInplayYn: initialState.settingSportInplayYn == '' ? mainList1.setting_sport_inplay_yn : initialState.settingSportInplayYn,
                settingSportSpecialYn: initialState.settingSportSpecialYn == '' ? mainList1.setting_sport_special_yn : initialState.settingSportSpecialYn,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }


    const updateMultiMainBtnState = (state, depositNos) => {
        if (window.confirm("선택하신 레벨을 일괄 변경 하시겠습니까?"))
        {
            for( let i=0; i<updateArr02.length; i++ )
            {
                if( updateArr02[i] == 'Y' )
                {
                    //alert("initialState.settingLiveYn : "+initialState.setting_live_yn);
                    //alert("mainList1.settingLiveYn : "+mainList1.setting_mini_yn);

                    axios.post('/admin/setting/saveLvlMainBtnSetting', {
                        pntGbCd: i,
                        settingLiveYn: initialState.settingLiveYn == '' ? mainList1.setting_live_yn : initialState.settingLiveYn,
                        settingMiniYn: initialState.settingMiniYn == '' ? mainList1.setting_mini_yn : initialState.settingMiniYn,
                        settingSlotYn: initialState.settingSlotYn == '' ? mainList1.setting_slot_yn : initialState.settingSlotYn,
                        settingSportYn: initialState.settingSportYn == '' ? mainList1.setting_sport_yn : initialState.settingSportYn,
                        settingMiniDtlYn: initialState.settingMiniDtlYn == '' ? mainList1.setting_mini_dtl_yn : initialState.settingMiniDtlYn,
                        settingSportDtlYn: initialState.settingSportDtlYn == '' ? mainList1.setting_sport_dtl_yn : initialState.settingSportDtlYn,
                        adminNo: userInfoId
                    }).then((res) => {
                        //alert(res.data.isUpdated? "수정완료" : "수정실패")
                        //getAllList()
                        //window.location.reload();
                        //onRefresh();

                    })
                }
            }
            alert("수정완료");
            window.location.reload();
        }

    }


    const updatePaybackState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlPaybackSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                startDt: startDt?startDt:mainList4.start_dt,
                deadlineDt: deadlineDt?deadlineDt:mainList4.deadline_dt,
                paymentDt: paymentDt?paymentDt:mainList4.payment_dt,
                paymentCd: paymentCd?paymentCd:mainList4.payment_cd,
                paymentFormula: paymentFormula?paymentFormula:mainList4.payment_formula,
                payback: payback?parseInt(payback):mainList4.payback,
                minusYn: minusYn?minusYn:mainList4.minus_yn,
                paymentMaxAmt: paymentMaxAmt?parseInt(paymentMaxAmt):mainList4.payment_max_amt,
                paymentDepositCount: paymentDepositCount?parseInt(paymentDepositCount):mainList4.payment_deposit_count,
                paymentDepositDay: paymentDepositDay?parseInt(paymentDepositDay):mainList4.payment_deposit_day,
                paymentCycle: paymentCycle?parseInt(paymentCycle):mainList4.payment_cycle,
                paymentCycleDtlCd: paymentCycleDtlCd?parseInt(paymentCycleDtlCd):mainList4.payment_cycle_dtl_cd,
                paymentCycleTime: paymentCycleTime?parseInt(paymentCycleTime):mainList4.payment_cycle_time,
                marginSetAmt: marginSetAmt?parseInt(marginSetAmt):mainList4.margin_set_amt,
                paymentMinAmt: paymentMinAmt?parseInt(paymentMinAmt):mainList4.payment_min_amt,
                paymentCycleDay: paymentCycle?parseInt(paymentCycleDay):mainList4.payment_cycle_day,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }


    const updateMultiPaybackState = (state, depositNos) => {
        if (window.confirm("선택하신 레벨을 일괄 변경 하시겠습니까?"))
        {
            for( let i=0; i<updateArr04.length; i++ )
            {
                if( updateArr04[i] == 'Y' )
                {
                    axios.post('/admin/setting/saveLvlPaybackSetting', {
                        pntGbCd: i,
                        startDt: startDt?startDt:mainList4.start_dt,
                        deadlineDt: deadlineDt?deadlineDt:mainList4.deadline_dt,
                        paymentDt: paymentDt?paymentDt:mainList4.payment_dt,
                        paymentCd: paymentCd?paymentCd:mainList4.payment_cd,
                        paymentFormula: paymentFormula?paymentFormula:mainList4.payment_formula,
                        payback: payback?parseInt(payback):mainList4.payback,
                        minusYn: minusYn?minusYn:mainList4.minus_yn,
                        paymentMaxAmt: paymentMaxAmt?parseInt(paymentMaxAmt):mainList4.payment_max_amt,
                        paymentDepositCount: paymentDepositCount?parseInt(paymentDepositCount):mainList4.payment_deposit_count,
                        paymentDepositDay: paymentDepositDay?parseInt(paymentDepositDay):mainList4.payment_deposit_day,
                        paymentCycle: paymentCycle?parseInt(paymentCycle):mainList4.payment_cycle,
                        paymentCycleDtlCd: paymentCycleDtlCd?parseInt(paymentCycleDtlCd):mainList4.payment_cycle_dtl_cd,
                        paymentCycleTime: paymentCycleTime?parseInt(paymentCycleTime):mainList4.payment_cycle_time,
                        marginSetAmt: marginSetAmt?parseInt(marginSetAmt):mainList4.margin_set_amt,
                        paymentMinAmt: paymentMinAmt?parseInt(paymentMinAmt):mainList4.payment_min_amt,
                        adminNo: userInfoId
                    }).then((res) => {
                        //alert(res.data.isUpdated? "수정완료" : "수정실패")
                        //getAllList()
                        //window.location.reload();
                        //onRefresh();

                    })
                }
            }
            alert("수정완료");
            window.location.reload();
        }

    }

    const updateChargeState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlChargeSetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                bonusUseYn: bonusUseYn?bonusUseYn:mainList3.bonus_use_yn,
                bonusPaymentCnt: bonusPaymentCnt?parseInt(bonusPaymentCnt):mainList4.bonus_payment_cnt,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })

        }
    }

    const updateBonusState = (state, depositNos) => {

        //alert("sportPaymentMethod :: "+sportPaymentMethod);
        //alert("bonusNm :: "+bonusNm);

        if( chargeBounsAction == 'UPDATE' )
        {
            if (window.confirm("수정 하시겠습니까?"))
            {
                //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
                axios.post('/admin/setting/saveLvlBonusSetting', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    no: chargeBounsNo,
                    chargeBounsLimitMoney: chargeBounsLimitMoney?parseInt(chargeBounsLimitMoney):mainList2.charge_bouns_limit_money,
                    chargeBounsLimitAll: chargeBounsLimitAll?parseInt(chargeBounsLimitAll):mainList2.charge_bouns_limit_all,
                    firstChargeLimitDayYn: firstChargeLimitDayYn?firstChargeLimitDayYn:mainList2.first_charge_limit_day_yn,
                    everyChargeLimitDayYn: everyChargeLimitDayYn?everyChargeLimitDayYn:mainList2.every_charge_limit_day_yn,
                    firstBonusPer: firstBonusPer?parseInt(firstBonusPer):mainList2.first_bonus_per,
                    everyBonusPer: everyBonusPer?parseInt(everyBonusPer):mainList2.every_bonus_per,
                    maxBonusCnt: maxBonusCnt?parseInt(maxBonusCnt):mainList2.max_bonus_cnt,
                    maxBonusDay: maxBonusDay?parseInt(maxBonusDay):mainList2.max_bonus_day,
                    outbreakLimitYn: outbreakLimitYn?outbreakLimitYn:mainList2.outbreak_limit_yn,
                    maxOutbreakCnt: maxOutbreakCnt?parseInt(maxOutbreakCnt):mainList2.max_outbreak_cnt,
                    sportPaymentMethod: sportPaymentMethod?sportPaymentMethod:mainList2.sport_payment_method,
                    bonusNm: bonusNm?bonusNm:mainList2.bonus_nm,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })

            }
        }
        else
        {
            if (window.confirm("수정 하시겠습니까?"))
            {
                //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
                axios.post('/admin/setting/insertLvlBonusSetting', {
                    pntGbCd: mainList1?.pnt_gb_cd,
                    no: chargeBounsNo,
                    chargeBounsLimitMoney: chargeBounsLimitMoney?parseInt(chargeBounsLimitMoney):mainList2.charge_bouns_limit_money,
                    chargeBounsLimitAll: chargeBounsLimitAll?parseInt(chargeBounsLimitAll):mainList2.charge_bouns_limit_all,
                    firstChargeLimitDayYn: firstChargeLimitDayYn?firstChargeLimitDayYn:mainList2.first_charge_limit_day_yn,
                    everyChargeLimitDayYn: everyChargeLimitDayYn?everyChargeLimitDayYn:mainList2.every_charge_limit_day_yn,
                    firstBonusPer: firstBonusPer?parseInt(firstBonusPer):mainList2.first_bonus_per,
                    everyBonusPer: everyBonusPer?parseInt(everyBonusPer):mainList2.every_bonus_per,
                    maxBonusCnt: maxBonusCnt?parseInt(maxBonusCnt):mainList2.max_bonus_cnt,
                    maxBonusDay: maxBonusDay?parseInt(maxBonusDay):mainList2.max_bonus_day,
                    outbreakLimitYn: outbreakLimitYn?outbreakLimitYn:mainList2.outbreak_limit_yn,
                    maxOutbreakCnt: maxOutbreakCnt?parseInt(maxOutbreakCnt):mainList2.max_outbreak_cnt,
                    adminNo: userInfoId
                }).then((res) => {
                    alert(res.data.isUpdated? "수정완료" : "수정실패")
                    //getAllList()
                    //window.location.reload();
                    //onRefresh();
                    window.location.reload();
                })

            }
        }



    }


    const updateMultiBonusState = (state, depositNos) => {
        if (window.confirm("선택하신 레벨을 일괄 변경 하시겠습니까?"))
        {
            for( let i=0; i<updateArr06.length; i++ )
            {
                if( updateArr06[i] == 'Y' )
                {
                    axios.post('/admin/setting/saveLvlBonusSetting', {
                        pntGbCd: i,
                        chargeBounsLimitMoney: chargeBounsLimitMoney?parseInt(chargeBounsLimitMoney):mainList2.charge_bouns_limit_money,
                        chargeBounsLimitAll: chargeBounsLimitAll?parseInt(chargeBounsLimitAll):mainList2.charge_bouns_limit_all,
                        firstChargeLimitDayYn: firstChargeLimitDayYn?firstChargeLimitDayYn:mainList2.first_charge_limit_day_yn,
                        everyChargeLimitDayYn: everyChargeLimitDayYn?everyChargeLimitDayYn:mainList2.every_charge_limit_day_yn,
                        firstBonusPer: firstBonusPer?parseInt(firstBonusPer):mainList2.first_bonus_per,
                        everyBonusPer: everyBonusPer?parseInt(everyBonusPer):mainList2.every_bonus_per,
                        maxBonusCnt: maxBonusCnt?parseInt(maxBonusCnt):mainList2.max_bonus_cnt,
                        maxBonusDay: maxBonusDay?parseInt(maxBonusDay):mainList2.max_bonus_day,
                        outbreakLimitYn: outbreakLimitYn?outbreakLimitYn:mainList2.outbreak_limit_yn,
                        maxOutbreakCnt: maxOutbreakCnt?parseInt(maxOutbreakCnt):mainList2.max_outbreak_cnt,
                        adminNo: userInfoId
                    }).then((res) => {
                        //alert(res.data.isUpdated? "수정완료" : "수정실패")
                        //getAllList()
                        //window.location.reload();
                        //onRefresh();

                    })
                }
            }
            alert("수정완료");
            window.location.reload();
        }

    }

    const handleClickOpen = () => {
        dispatch(setIsOpen(true))
    }
    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        window.location.reload();
    }

    const insertArrayState = (state, no) => {
        axios.post('/admin/setting/insertLvlArraySetting', {
            pntGbCd: mainList1?.pnt_gb_cd,
            adminNo: userInfoId
        }).then((res) => {
            alert(res.data.isUpdated? "추가완료" : "추가실패")
            window.location.reload();
        })
    }


    const updateArrayState = (state, no) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            // alert("state :: "+state);
            // alert("no :: "+no);
            //
            // alert("memoArr : "+memoArr[state]);
            // alert("bonusPaymentMethodArr : "+initialState.bonusPaymentMethodArr[state]);
            // alert("outbreakSdtArr : "+initialState.outbreakSdtArr[state]);
            // alert("outbreakEdtArr : "+initialState.outbreakEdtArr[state]);
            // alert("addPercentArr : "+addPercentArr[state]);

            //alert("lvlSetting.no ::: "+lvlSetting.no);
            axios.post('/admin/setting/saveLvlArraySetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                no: state,
                memo: memoArr[state]?memoArr[state]:lvlSetting.memo,
                bonusPaymentMethod: initialState.bonusPaymentMethodArr[state]?initialState.bonusPaymentMethodArr[state]:lvlSetting.bonus_payment_method,
                outbreakSdt: initialState.outbreakSdtArr[state]?initialState.outbreakSdtArr[state]:lvlSetting.outbreak_sdt,
                outbreakEdt: initialState.outbreakEdtArr[state]?initialState.outbreakEdtArr[state]:lvlSetting.outbreak_edt,
                addPercent: addPercentArr[state]?addPercentArr[state]:lvlSetting.add_percent,
                sportPaymentMethod: initialState.sportPaymentMethodArr[state]?initialState.sportPaymentMethodArr[state]:lvlSetting.sport_payment_method,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                window.location.reload();
            })

        }
    }


    const deleteArrayState = (state, no) => {
        if (window.confirm("삭제 하시겠습니까?"))
        {
            axios.post('/admin/setting/deleteLvlArraySetting', {
                pntGbCd: mainList1?.pnt_gb_cd,
                no: state,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                window.location.reload();
            })

        }
    }


    const popupClose = ()=>{
        window.location.reload();
    }

    const linkSportDtlPage = (pnt_gb_cd,setting_sport_domestic_yn,setting_sport_foreign_yn,setting_sport_inplay_yn,setting_sport_special_yn ) => {
        window.open(`/setting/LvlSettingSportDtl/${pnt_gb_cd}/${setting_sport_domestic_yn}/${setting_sport_foreign_yn}/${setting_sport_inplay_yn}/${setting_sport_special_yn}` ,"_blank", "width=500,height=300,left=200,top=200")
        window.popupClose = popupClose;
    }

    const linkMiniDtlPage = (pnt_gb_cd,setting_mini01_yn,setting_mini02_yn,setting_mini03_yn,setting_mini04_yn ) => {
        window.open(`/setting/LvlSettingMiniDtl/${pnt_gb_cd}/${setting_mini01_yn}/${setting_mini02_yn}/${setting_mini03_yn}/${setting_mini04_yn}` ,"_blank", "width=500,height=300,left=200,top=200")
        window.popupClose = popupClose;
    }



    function TableRows({rowsData, deleteTableRows, handleChange}) {
        return(

            rowsData.map((data, index)=>{
                const {insertNo, insertEtc, insertColor}= data;
                return(
                    <tr key={index} >
                        <td align={"center"}>추가</td>
                        <td align={"center"}>
                            <input className="form-check-input sm-input" type="text" name="insertNo" style={{width: "300px"}}
                                   placeholder={insertNo}
                                   onChange={(e) => {insertTagNmArrHandler(e, index)}}
                            />
                        </td>
                        <td align={"center"}>
                            <input className="form-check-input sm-input" type="text" name="insertEtc" style={{width: "400px"}}
                                   placeholder={insertEtc}
                                   onChange={(e) => {insertEtcArrHandler(e, index)}}
                            />
                        </td>

                        {changeColorList.map(item => (
                            <React.Fragment key={item.cmmn_cd_dtl_id}>
                                <td align={"center"} height={"20px"} >
                                    <label className={`looking_id_show type${item.cmmn_cd_dtl_id}`}  >
                                        <font color={item.etc}>{item.cmmn_cd_dtl_id}</font>
                                    </label>
                                    <div className={`looking_id_show type${item.cmmn_cd_dtl_id}`} >
                                        <input
                                            type="radio"
                                            name={`insertColor${index}`}
                                            id={`insertColor${index}`}
                                            value={item.cmmn_cd_dtl_id}
                                            style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                            checked={( item.cmmn_cd_dtl_id === insertColor  ? true : false )}
                                            onChange={(e) => {insertColorNmArrHandler(e, index)}}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                </td>
                            </React.Fragment>
                        ))}
                        <td align={"center"}>
                            <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "23px", width: "fit-content", marginRight: "5px"}} onClick={()=>(deleteTableRows(index))}>삭제</button>
                        </td>
                    </tr>
                )
            })

        )

    }


    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }

    const changeSettingLiveYn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingLiveYn('N')); }
            else{ dispatch(setSettingLiveYn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingLiveYn('N')); }
            else{ dispatch(setSettingLiveYn('Y')); }
        }
    }

    const changeSettingSlotYn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingSlotYn('N')); }
            else{ dispatch(setSettingSlotYn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingSlotYn('N')); }
            else{ dispatch(setSettingSlotYn('Y')); }
        }
    }

    const changeSettingMiniYn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingMiniYn('N')); }
            else{ dispatch(setSettingMiniYn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingMiniYn('N')); }
            else{ dispatch(setSettingMiniYn('Y')); }
        }
    }

    const changeSettingSportYn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingSportYn('N')); }
            else{ dispatch(setSettingSportYn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingSportYn('N')); }
            else{ dispatch(setSettingSportYn('Y')); }
        }
    }

    const changeSettingMiniDtlYn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingMiniDtlYn('N')); }
            else{ dispatch(setSettingMiniDtlYn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingMiniDtlYn('N')); }
            else{ dispatch(setSettingMiniDtlYn('Y')); }
        }
    }

    const changeSettingSportDtlYn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingSportDtlYn('N')); }
            else{ dispatch(setSettingSportDtlYn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingSportDtlYn('N')); }
            else{ dispatch(setSettingSportDtlYn('Y')); }
        }
    }


    const searchChangeHandler = (no, pntGbCd) => {
        //alert("no : "+no+" || pntGbCd : "+pntGbCd);
        setBonusQuery(no);
        setChargeBounsNo(no);
        setBonusNm("");
        axios.post('/admin/setting/settingBonusQuery', {
            no : no,
            pntGbCd: pntGbCd
        }).then((res) => {
            setChargeBounsAction(res.data.countList2 ? 'UPDATE' : 'INSERT');
            setChargeBounsLimitMoney(res.data.countList2 ? res.data.countList2.charge_bouns_limit_money : 0);
            setChargeBounsLimitAll(res.data.countList2 ? res.data.countList2.charge_bouns_limit_all : 0);
            setFirstChargeLimitDayYn(res.data.countList2 ? res.data.countList2.first_charge_limit_day_yn : 'N');
            setEveryChargeLimitDayYn(res.data.countList2 ? res.data.countList2.every_charge_limit_day_yn : 'N');
            setFirstBonusPer(res.data.countList2 ? res.data.countList2.first_bonus_per : 0);
            setEveryBonusPer(res.data.countList2 ? res.data.countList2.every_bonus_per : 0);
            setMaxBonusCnt(res.data.countList2 ? res.data.countList2.max_bonus_cnt : 0);
            setMaxBonusDay(res.data.countList2 ? res.data.countList2.max_bonus_day : 0);
            setOutbreakLimitYn(res.data.countList2 ? res.data.countList2.outbreak_limit_yn : 'N');
            setMaxOutbreakCnt(res.data.countList2 ? res.data.countList2.max_outbreak_cnt : 0);
            setSportPaymentMethod(res.data.countList2 ? res.data.countList2.sport_payment_method : '');
            setBonusNm(res.data.countList2 ? res.data.countList2.bonus_nm : '');

            // alert(res.data.countList2.no);
            // alert(res.data.countList2.pnt_gb_cd);
            //alert(res.data.countList2.sport_payment_method);
            //alert(res.data.countList2.bonus_nm);
        })
    }

    const onLevelChange = (setting_level_no) => {
        setMinDepositAmt("");
        setMinWithdrawAmt("");
        setMaxDepositAmt("");
        setMaxWithdrawAmt("");
        setDayMaxWithdrawAmt("");
        setMinConvertAmt("");
        setDepositAmtUnit("");
        setWithdrawAmtUnit("");
        setPasswordYn("");
        setMiniOneLosePoint("")
        setMiniCombiLosePoint("");
        setMiniAllLosePoint("");
        setSportLiveOneLosePoint("");
        setSportLiveTwoLosePoint("")
        setSportLiveThreeLosePoint("");
        setSportLiveFourLosePoint("");
        setSportLiveManyLosePoint("");
        setSportLiveAllLosePoint("");
        setSportFreeOneLosePoint("")
        setSportFreeTwoLosePoint("");
        setSportFreeThreeLosePoint("");
        setSportFreeFourLosePoint("")
        setSportFreeManyLosePoint("");
        setSportFreeAllLosePoint("");
        setSportMaxDayLosePoint("");
        setCasinoLiveMaxRolling("");
        setCasinoLiveMinRolling("");
        setCasinoSlotMaxRolling("");
        setCasinoSlotMinRolling("");
        setMiniMinBetAmt("");
        setMiniMaxRolling("");
        setMiniMinRolling("");
        setSportMaxRolling("");
        setSportMinRolling("");
        setRollingMinConversionAmt("")
        setRollingConversionLimitCnt("");
        setRollingConversionLimitDay("");
        setWithdrawRetryWaitTime("");
        setDepositRetryWaitTime("");
        setDepositWithdrawWaitTime("");
        setTodayWithdrawLimitTime("");
        setTodayWithdrawLimitCnt("");
        setBetCutMaxAmt("");
        setBetCutPercent("");

        dispatch(setSettingLiveYn(""))
        dispatch(setSettingSlotYn(""))
        dispatch(setSettingMiniYn(""))
        dispatch(setSettingSportYn(""))
        dispatch(setSettingMiniDtlYn(""))
        dispatch(setSettingSportDtlYn(""))
        dispatch(setSettingSportDomesticYn(""))
        dispatch(setSettingSportForeignYn(""))
        dispatch(setSettingSportInplayYn(""))
        dispatch(setSettingSportSpecialYn(""))

        setBoonMiniCd("");
        setBoonMiniPer("");
        setBoonCasinoCd("");
        setBoonCasinoPer("");
        setBoonSlotCd("");
        setBoonSlotPer("");
        setBoonSportOneCd("");
        setBoonSportOnePer("");
        setBoonSportTwoCd("");
        setBoonSportTwoPer("");
        setBoonSportThreeCd("");
        setBoonSportThreePer("");
        setBoonSportFourCd("");
        setBoonSportFourPer("");
        setBoonSportManyCd("");
        setBoonSportManyPer("");

        setPaymentCycle("");
        setPaymentCycleDtlCd("");
        setStartDt("");
        setDeadlineDt("");
        setPaymentDt("");
        setPaymentCd("");
        setPaymentFormula("");
        setPayback("");
        setMinusYn("");
        setPaymentMaxAmt("");
        setPaymentDepositCount("");
        setPaymentDepositDay("");
        setMarginSetAmt("");
        setPaymentMinAmt("");

        setBonusUseYn("");
        setBonusPaymentCnt("");

        setChargeBounsLimitMoney("");
        setChargeBounsLimitAll("");
        setFirstChargeLimitDayYn("");
        setEveryChargeLimitDayYn("");
        setFirstBonusPer("");
        setEveryBonusPer("");
        setMaxBonusCnt("");
        setMaxBonusDay("");
        setOutbreakLimitYn("");
        setMaxOutbreakCnt("");

        setBonusNm("");

        onSearch(setting_level_no)
    }

    const memoArrHandler = (e, index) => {
        memoArr[index] = e.target.value;
        setMemoArr(memoArr);
    }
    const sportPaymentMethodArrHandler = (e, index) => {
        const rows = [...initialState.sportPaymentMethodArr];
        rows[index] = e.target.value;
        dispatch(setSportPaymentMethodArr(rows));
    }

    const bonusPaymentMethodArrHandler = (e, index) => {
        const rows = [...initialState.bonusPaymentMethodArr];
        rows[index] = e.target.value;
        dispatch(setBonusPaymentMethodArr(rows));
    }
    const outbreakSdtArrHandler = (e, index) => {
        const rows = [...initialState.outbreakSdtArr];
        rows[index] = e.target.value;
        //alert("index : "+index);
        dispatch(setOutbreakSdtArr(rows));
        //alert("outbreakSdtArr[index] :: "+initialState.outbreakSdtArr[index]);
    }
    const outbreakEdtArrHandler = (e, index) => {
        const rows = [...initialState.outbreakEdtArr];
        rows[index] = e.target.value;
        //alert("index : "+index);
        dispatch(setOutbreakEdtArr(rows));
    }
    const addPercentArrHandler = (e, index) => {
        addPercentArr[index] = e.target.value;
        setAddPercentArr(addPercentArr);
    }

    const checkHandler = (checked, no) => {
        if(checked) {
            updateArr01[no] = 'Y';
            setUpdateArr01(updateArr01);
            //alert("updateArr01 : "+updateArr01);
        }
        else
        {
            updateArr01[no] = 'N';
            setUpdateArr01(updateArr01);
            //alert("updateArr01 : "+updateArr01);
        }
    }

    const checkHandler02 = (checked, no) => {
        if(checked) {
            updateArr02[no] = 'Y';
            setUpdateArr02(updateArr02);
        }
        else
        {
            updateArr02[no] = 'N';
            setUpdateArr02(updateArr02);
        }
    }

    const checkHandler03 = (checked, no) => {
        if(checked) {
            updateArr03[no] = 'Y';
            setUpdateArr03(updateArr03);
        }
        else
        {
            updateArr03[no] = 'N';
            setUpdateArr03(updateArr03);
        }
    }

    const checkHandler04 = (checked, no) => {
        if(checked) {
            updateArr04[no] = 'Y';
            setUpdateArr04(updateArr04);
        }
        else
        {
            updateArr04[no] = 'N';
            setUpdateArr04(updateArr04);
        }
    }

    const checkHandler05 = (checked, no) => {
        if(checked) {
            updateArr05[no] = 'Y';
            setUpdateArr05(updateArr05);
        }
        else
        {
            updateArr05[no] = 'N';
            setUpdateArr05(updateArr05);
        }
    }

    const checkHandler06 = (checked, no) => {
        if(checked) {
            updateArr06[no] = 'Y';
            setUpdateArr06(updateArr06);
        }
        else
        {
            updateArr06[no] = 'N';
            setUpdateArr06(updateArr06);
        }
    }

    const sportPaymentMethodChangeHandler = (e) => {
        setSportPaymentMethod(e.currentTarget.value);
    }
    const bonusNmChangeHandler = (e) => {
        setBonusNm(e.currentTarget.value);
    }




    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "green", border: "1", color: "white",fontSize: "10px", height: "22px", width: "100px", marginRight: "5px", marginTop:"10px"}}
                            onClick={handleClickOpen}>레벨관리(추가,삭제)</button>
                    {initialState.isOpen && <LvlSettingComponent open={initialState.isOpen} onClose={handleClickClose} />}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {levels?.map((item, index) => (
                        <>
                            <button style={{ backgroundColor: initialState.query === item.setting_level_key ? "blue" : "white" , border: "1", color: initialState.query === item.setting_level_key ? "white" : "black",fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                    onClick={(e) => {
                                        onLevelChange(item.setting_level_key)
                                    }}
                            >
                                <b>{item.cmmn_cd_dtl_nm} 레벨</b>
                            </button>
                        </>
                    ))}
                </div>
                <br></br>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"49.5%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> {initialState.query}레벨 설정</b></font>
                        </div>

                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>최소 입금금액</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.min_deposit_amt)}
                                           value={minDepositAmt}
                                           onChange={(e) => setMinDepositAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>카지노 라이브 최대 롤링</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.casino_live_max_rolling)}
                                           value={casinoLiveMaxRolling}
                                           onChange={(e) => setCasinoLiveMaxRolling(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>최소 출금금액</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.min_withdraw_amt)}
                                           value={minWithdrawAmt}
                                           onChange={(e) => setMinWithdrawAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>카지노 라이브 최소 롤링</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.casino_live_min_rolling)}
                                           value={casinoLiveMinRolling}
                                           onChange={(e) => setCasinoLiveMinRolling(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>최대 입금금액</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.max_deposit_amt)}
                                           value={maxDepositAmt}
                                           onChange={(e) => setMaxDepositAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>카지노 슬롯 최대 롤링</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.casino_slot_max_rolling)}
                                           value={casinoSlotMaxRolling}
                                           onChange={(e) => setCasinoSlotMaxRolling(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>최대 출금금액</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.max_withdraw_amt)}
                                           value={maxWithdrawAmt}
                                           onChange={(e) => setMaxWithdrawAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>카지노 슬롯 최소 롤링</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.casino_slot_min_rolling)}
                                           value={casinoSlotMinRolling}
                                           onChange={(e) => setCasinoSlotMinRolling(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>1일 최대 출금금액</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.day_max_withdraw_amt)}
                                           value={dayMaxWithdrawAmt}
                                           onChange={(e) => setDayMaxWithdrawAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>미니게임 최소 베팅금액</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.mini_min_bet_amt)}
                                           value={miniMinBetAmt}
                                           onChange={(e) => setMiniMinBetAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>포인트 최소 전환금액</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.min_convert_amt)}
                                           value={minConvertAmt}
                                           onChange={(e) => setMinConvertAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        P
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>미니게임 최대 롤링</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.mini_max_rolling)}
                                           value={miniMaxRolling}
                                           onChange={(e) => setMiniMaxRolling(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>입금 금액 단위</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <select
                                        style={{width: "80px"}}
                                        onChange={depositAmtUnitChangeHandler} value={depositAmtUnit?depositAmtUnit:mainList1.deposit_amt_unit}
                                    >
                                        <option key={9} value={9}>전체</option>
                                        <option key={1} value={1}>1만</option>
                                        <option key={2} value={2}>10만</option>
                                    </select>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>미니게임 최소 롤링</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.mini_min_rolling)}
                                           value={miniMinRolling}
                                           onChange={(e) => setMiniMinRolling(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>출금 금액 단위</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <select
                                        style={{width: "80px"}}
                                        onChange={withdrawAmtUnitChangeHandler} value={withdrawAmtUnit?withdrawAmtUnit:mainList1.withdraw_amt_unit}
                                    >
                                        <option key={1} value={1}>1만</option>
                                        <option key={2} value={2}>10만</option>
                                    </select>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 최대 롤링</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_max_rolling)}
                                           value={sportMaxRolling}
                                           onChange={(e) => setSportMaxRolling(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>계좌문의시 비번입력</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <select
                                        style={{width: "80px"}}
                                        onChange={passwordYnChangeHandler} value={passwordYn?passwordYn:mainList1.password_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 최소 롤링</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_min_rolling)}
                                           value={sportMinRolling}
                                           onChange={(e) => setSportMinRolling(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>

                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 라이브 단폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_live_one_lose_point)}
                                           value={sportLiveOneLosePoint}
                                           onChange={(e) => setSportLiveOneLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>미니게임 단폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.mini_one_lose_point)}
                                           value={miniOneLosePoint}
                                           onChange={(e) => setMiniOneLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 라이브 2폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_live_two_lose_point)}
                                           value={sportLiveTwoLosePoint}
                                           onChange={(e) => setSportLiveTwoLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>미니게임 조합 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.mini_combi_lose_point)}
                                           value={miniCombiLosePoint}
                                           onChange={(e) => setMiniCombiLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 라이브 3폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_live_three_lose_point)}
                                           value={sportLiveThreeLosePoint}
                                           onChange={(e) => setSportLiveThreeLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>미니게임 전체 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.mini_all_lose_point)}
                                           value={miniAllLosePoint}
                                           onChange={(e) => setMiniAllLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 라이브 4폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_live_four_lose_point)}
                                           value={sportLiveFourLosePoint}
                                           onChange={(e) => setSportLiveFourLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>롤링 전환 최소금액</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.rolling_min_conversion_amt)}
                                           value={rollingMinConversionAmt}
                                           onChange={(e) => setRollingMinConversionAmt(e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 라이브 다폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_live_many_lose_point)}
                                           value={sportLiveManyLosePoint}
                                           onChange={(e) => setSportLiveManyLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>롤링 전환 1일 횟수 제한</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.rolling_conversion_limit_cnt)}
                                           value={rollingConversionLimitCnt}
                                           onChange={(e) => setRollingConversionLimitCnt(e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 라이브 전체 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_live_all_lose_point)}
                                           value={sportLiveAllLosePoint}
                                           onChange={(e) => setSportLiveAllLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>롤링 전환 1일 금액 제한</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.rolling_conversion_limit_day)}
                                           value={rollingConversionLimitDay}
                                           onChange={(e) => setRollingConversionLimitDay(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 프리매치 단폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_free_one_lose_point)}
                                           value={sportFreeOneLosePoint}
                                           onChange={(e) => setSportFreeOneLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>환전완료후 재신청 대기시간</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.withdraw_retry_wait_time)}
                                           value={withdrawRetryWaitTime}
                                           onChange={(e) => setWithdrawRetryWaitTime(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        분
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 프리매치 2폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_free_two_lose_point)}
                                           value={sportFreeTwoLosePoint}
                                           onChange={(e) => setSportFreeTwoLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>충전완료후 재신청 대기시간</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.deposit_retry_wait_time)}
                                           value={depositRetryWaitTime}
                                           onChange={(e) => setDepositRetryWaitTime(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        분
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 프리매치 3폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_free_three_lose_point)}
                                           value={sportFreeThreeLosePoint}
                                           onChange={(e) => setSportFreeThreeLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>충전완료후 환전신청 대기시간</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.deposit_withdraw_wait_time)}
                                           value={depositWithdrawWaitTime}
                                           onChange={(e) => setDepositWithdrawWaitTime(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        분
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 프리매치 4폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_free_four_lose_point)}
                                           value={sportFreeFourLosePoint}
                                           onChange={(e) => setSportFreeFourLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>베팅내역 삭감 최대금액</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.bet_cut_max_amt)}
                                           value={betCutMaxAmt}
                                           onChange={(e) => setBetCutMaxAmt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 프리매치 다폴 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_free_many_lose_point)}
                                           value={sportFreeManyLosePoint}
                                           onChange={(e) => setSportFreeManyLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>베팅금액 삭감</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.bet_cut_percent)}
                                           value={betCutPercent}
                                           onChange={(e) => setBetCutPercent(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 프리매치 전체 낙첨 포인트</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_free_all_lose_point)}
                                           value={sportFreeAllLosePoint}
                                           onChange={(e) => setSportFreeAllLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>스포츠 낙첨포인트 최대금액(1일)</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.sport_max_day_lose_point)}
                                           value={sportMaxDayLosePoint}
                                           onChange={(e) => setSportMaxDayLosePoint(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                    <font color={"black"}><b>당일 X회이상 환전시 시간제한</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.today_withdraw_limit_time)}
                                           value={todayWithdrawLimitTime}
                                           onChange={(e) => setTodayWithdrawLimitTime(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        회
                                    </button>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"left"} colSpan={2}>
                                    이상 환전시 제한시간
                                    &nbsp;&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={addComma(mainList1.today_withdraw_limit_cnt)}
                                           value={todayWithdrawLimitCnt}
                                           onChange={(e) => setTodayWithdrawLimitCnt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        분
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4} style={{height:"30px"}}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={() => {updateState()}}
                                    >
                                        변경
                                    </button>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={() => {updateMultiState()}}
                                    >
                                        선택 레벨 일괄적용
                                    </button>
                                    {levels?.map((item, index) => (
                                        <>
                                            <input className="form-check-input" type="checkbox" name="inlineRadio11" id={'inlineRadioGrp' + item.setting_level_no}
                                                   style={{cursor:"pointer"}}
                                                   onChange={(e) => {checkHandler(e.currentTarget.checked, item.setting_level_no) }}
                                            />
                                            <label htmlFor={'inlineRadioGrp' + item.setting_level_no} style={{cursor: "pointer"}}>
                                                {item.setting_level_no}레벨
                                            </label>
                                        </>
                                    ))}
                                </td>
                            </tr>
                        </table>
                        <br></br>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> {initialState.query}레벨 게임사용 설정</b></font>
                        </div>
                        <table>
                            <tr>
                                <td align={"left"}>
                                    <button style={{ backgroundColor: initialState.settingLiveYn == '' ? (mainList1.setting_live_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingLiveYn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingLiveYn == '' ? (mainList1.setting_live_yn == 'Y' ? "white" : "black") : (initialState.settingLiveYn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={(e) => {
                                                changeSettingLiveYn(initialState.settingLiveYn, mainList1.setting_live_yn)
                                            }}
                                    >
                                        <b>라이브</b>
                                    </button>
                                    <button style={{ backgroundColor: initialState.settingSlotYn == '' ? (mainList1.setting_slot_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingSlotYn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingSlotYn == '' ? (mainList1.setting_slot_yn == 'Y' ? "white" : "black") : (initialState.settingSlotYn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={(e) => {
                                                changeSettingSlotYn(initialState.settingSlotYn, mainList1.setting_slot_yn)
                                            }}
                                    >
                                        <b>슬롯</b>
                                    </button>
                                    <button style={{ backgroundColor: initialState.settingMiniYn == '' ? (mainList1.setting_mini_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingMiniYn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingMiniYn == '' ? (mainList1.setting_mini_yn == 'Y' ? "white" : "black") : (initialState.settingMiniYn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={(e) => {
                                                changeSettingMiniYn(initialState.settingMiniYn, mainList1.setting_mini_yn)
                                            }}
                                    >
                                        <b>미니</b>
                                    </button>
                                    <button style={{ backgroundColor: initialState.settingSportYn == '' ? (mainList1.setting_sport_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingSportYn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingSportYn == '' ? (mainList1.setting_sport_yn == 'Y' ? "white" : "black") : (initialState.settingSportYn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={(e) => {
                                                changeSettingSportYn(initialState.settingSportYn, mainList1.setting_sport_yn)
                                            }}
                                    >
                                        <b>스포츠</b>
                                    </button>
                                    <br></br>
                                    <button style={{ backgroundColor: initialState.settingMiniDtlYn == '' ? (mainList1.setting_mini_dtl_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingMiniDtlYn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingMiniDtlYn == '' ? (mainList1.setting_mini_dtl_yn == 'Y' ? "white" : "black") : (initialState.settingMiniDtlYn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "80px", marginRight: "5px", marginTop:"10px"}}
                                        // onClick={(e) => {
                                        //     changeSettingMiniDtlYn(initialState.settingMiniDtlYn, mainList1.setting_mini_dtl_yn)
                                        // }}
                                            onClick={(e) => {
                                                linkMiniDtlPage(mainList1?.pnt_gb_cd,mainList1.setting_mini01_yn,mainList1.setting_mini02_yn,mainList1.setting_mini03_yn,mainList1.setting_mini04_yn)
                                            }}
                                    >
                                        <b>미니게임 상세</b>
                                    </button>
                                    &nbsp;
                                    <button style={{ backgroundColor: initialState.settingSportDtlYn == '' ? (mainList1.setting_sport_dtl_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingSportDtlYn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingSportDtlYn == '' ? (mainList1.setting_sport_dtl_yn == 'Y' ? "white" : "black") : (initialState.settingSportDtlYn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "80px", marginRight: "5px", marginTop:"10px"}}
                                        // onClick={(e) => {
                                        //     changeSettingSportDtlYn(initialState.settingSportDtlYn, mainList1.setting_sport_dtl_yn)
                                        // }}
                                            onClick={(e) => {
                                                linkSportDtlPage(mainList1?.pnt_gb_cd,mainList1.setting_sport_domestic_yn,mainList1.setting_sport_foreign_yn,mainList1.setting_sport_inplay_yn,mainList1.setting_sport_special_yn)
                                            }}
                                    >
                                        <b>스포츠 상세</b>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td align={"center"}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={() => {updateMainBtnState()}}
                                    >
                                        변경
                                    </button>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={() => {updateMultiMainBtnState()}}
                                    >
                                        선택 레벨 일괄적용
                                    </button>
                                    {levels?.map((item, index) => (
                                        <>
                                            <input className="form-check-input" type="checkbox" name="inlineRadio02" id={'inlineRadioGrp02' + item.setting_level_no}
                                                   style={{cursor:"pointer"}}
                                                   onChange={(e) => {checkHandler02(e.currentTarget.checked, item.setting_level_no) }}
                                            />
                                            <label htmlFor={'inlineRadioGrp02' + item.setting_level_no} style={{cursor: "pointer"}}>
                                                {item.setting_level_no}레벨
                                            </label>
                                        </>
                                    ))}
                                </td>
                            </tr>
                        </table>
                        <br></br>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> {initialState.query}레벨 추천인혜택 설정</b></font>
                        </div>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>추천인혜택(미니)</b></font>
                                </td>
                                <td style={{width: "80%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={boonMiniCdChangeHandler} value={boonMiniCd?boonMiniCd:mainList5.recommender_boon_mini_cd}
                                    >
                                        <option key={1} value={1}>사용안함</option>
                                        <option key={2} value={2}>베팅금%</option>
                                        <option key={3} value={3}>낙첨금%</option>
                                        <option key={4} value={4}>충전금%</option>
                                    </select>
                                    &nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList5.recommender_boon_mini_per}
                                           value={boonMiniPer}
                                           onChange={(e) => setBoonMiniPer(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>추천인혜택(카지노)</b></font>
                                </td>
                                <td style={{width: "90%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={boonCasinoCdChangeHandler} value={boonCasinoCd?boonCasinoCd:mainList5.recommender_boon_casino_cd}
                                    >
                                        <option key={1} value={1}>사용안함</option>
                                        <option key={2} value={2}>베팅금%</option>
                                        <option key={3} value={3}>낙첨금%</option>
                                        <option key={4} value={4}>충전금%</option>
                                    </select>
                                    &nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList5.recommender_boon_casino_per}
                                           value={boonCasinoPer}
                                           onChange={(e) => setBoonCasinoPer(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>추천인혜택(슬롯)</b></font>
                                </td>
                                <td style={{width: "90%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={boonSlotCdChangeHandler} value={boonSlotCd?boonSlotCd:mainList5.recommender_boon_slot_cd}
                                    >
                                        <option key={1} value={1}>사용안함</option>
                                        <option key={2} value={2}>베팅금%</option>
                                        <option key={3} value={3}>낙첨금%</option>
                                        <option key={4} value={4}>충전금%</option>
                                    </select>
                                    &nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList5.recommender_boon_slot_per}
                                           value={boonSlotPer}
                                           onChange={(e) => setBoonSlotPer(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>추천인혜택(스포츠단폴)</b></font>
                                </td>
                                <td style={{width: "90%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={boonSportOneCdChangeHandler} value={boonSportOneCd?boonSportOneCd:mainList5.recommender_boon_sport_one_cd}
                                    >
                                        <option key={1} value={1}>사용안함</option>
                                        <option key={2} value={2}>베팅금%</option>
                                        <option key={3} value={3}>낙첨금%</option>
                                        <option key={4} value={4}>충전금%</option>
                                    </select>
                                    &nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList5.recommender_boon_sport_one_per}
                                           value={boonSportOnePer}
                                           onChange={(e) => setBoonSportOnePer(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>추천인혜택(스포츠2폴)</b></font>
                                </td>
                                <td style={{width: "90%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={boonSportTwoCdChangeHandler} value={boonSportTwoCd?boonSportTwoCd:mainList5.recommender_boon_sport_two_cd}
                                    >
                                        <option key={1} value={1}>사용안함</option>
                                        <option key={2} value={2}>베팅금%</option>
                                        <option key={3} value={3}>낙첨금%</option>
                                        <option key={4} value={4}>충전금%</option>
                                    </select>
                                    &nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList5.recommender_boon_sport_two_per}
                                           value={boonSportTwoPer}
                                           onChange={(e) => setBoonSportTwoPer(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>추천인혜택(스포츠3폴)</b></font>
                                </td>
                                <td style={{width: "90%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={boonSportThreeCdChangeHandler} value={boonSportThreeCd?boonSportThreeCd:mainList5.recommender_boon_sport_three_cd}
                                    >
                                        <option key={1} value={1}>사용안함</option>
                                        <option key={2} value={2}>베팅금%</option>
                                        <option key={3} value={3}>낙첨금%</option>
                                        <option key={4} value={4}>충전금%</option>
                                    </select>
                                    &nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList5.recommender_boon_sport_three_per}
                                           value={boonSportThreePer}
                                           onChange={(e) => setBoonSportThreePer(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>추천인혜택(스포츠4폴)</b></font>
                                </td>
                                <td style={{width: "90%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={boonSportFourCdChangeHandler} value={boonSportFourCd?boonSportFourCd:mainList5.recommender_boon_sport_four_cd}
                                    >
                                        <option key={1} value={1}>사용안함</option>
                                        <option key={2} value={2}>베팅금%</option>
                                        <option key={3} value={3}>낙첨금%</option>
                                        <option key={4} value={4}>충전금%</option>
                                    </select>
                                    &nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList5.recommender_boon_sport_four_per}
                                           value={boonSportFourPer}
                                           onChange={(e) => setBoonSportFourPer(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"right"}>
                                </td>
                                <td style={{width: "5%",backgroundColor: "white"}} align={"right"}>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>추천인혜택(스포츠다폴)</b></font>
                                </td>
                                <td style={{width: "90%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={boonSportManyCdChangeHandler} value={boonSportManyCd?boonSportManyCd:mainList5.recommender_boon_sport_many_cd}
                                    >
                                        <option key={1} value={1}>사용안함</option>
                                        <option key={2} value={2}>베팅금%</option>
                                        <option key={3} value={3}>낙첨금%</option>
                                        <option key={4} value={4}>충전금%</option>
                                    </select>
                                    &nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList5.recommender_boon_sport_many_per}
                                           value={boonSportManyPer}
                                           onChange={(e) => setBoonSportManyPer(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td align={"center"} colSpan={2}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={() => {updateRecommenderState()}}
                                    >
                                        변경
                                    </button>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={() => {updateMultiRecommenderState()}}
                                    >
                                        선택 레벨 일괄적용
                                    </button>
                                    {levels?.map((item, index) => (
                                        <>
                                            <input className="form-check-input" type="checkbox" name="inlineRadio03" id={'inlineRadioGrp03' + item.setting_level_no}
                                                   style={{cursor:"pointer"}}
                                                   onChange={(e) => {checkHandler03(e.currentTarget.checked, item.setting_level_no) }}
                                            />
                                            <label htmlFor={'inlineRadioGrp03' + item.setting_level_no} style={{cursor: "pointer"}}>
                                                {item.setting_level_no}레벨
                                            </label>
                                        </>
                                    ))}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style={{width:"1%"}}>

                    </div>
                    <div style={{width:"49.5%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> {initialState.query}레벨 페이백 설정</b></font>
                        </div>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급주기</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    <select  style={{width: "20%"}}
                                             onChange={paymentCycleChangeHandler} value={paymentCycle?paymentCycle:mainList4.payment_cycle}
                                    >
                                        <option key={''} value={''}>선택</option>
                                        <option key={'1'} value={'1'}>1회용</option>
                                        <option key={'2'} value={'2'}>일별</option>
                                        <option key={'2'} value={'3'}>주별</option>
                                        <option key={'3'} value={'4'}>월별</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;
                                    { (
                                        ((initialState.settingPaymentCycleTime == '' && mainList4.payment_cycle == 3 ) || initialState.settingPaymentCycleTime == 3)
                                    )&& (
                                        <select  style={{width: "20%"}}
                                                 onChange={paymentCycleDtlCdChangeHandler} value={paymentCycleDtlCd?paymentCycleDtlCd:mainList4.payment_cycle_dtl_cd}
                                        >
                                            <option key={'1'} value={'1'}>월요일</option>
                                            <option key={'2'} value={'2'}>화요일</option>
                                            <option key={'3'} value={'3'}>수요일</option>
                                            <option key={'3'} value={'4'}>목요일</option>
                                            <option key={'3'} value={'5'}>금요일</option>
                                            <option key={'3'} value={'6'}>토요일</option>
                                            <option key={'3'} value={'7'}>일요일</option>
                                        </select>
                                    )}
                                    &nbsp;&nbsp;
                                    { ((initialState.settingPaymentCycleTime == '' && mainList4.payment_cycle == 4  )
                                        || initialState.settingPaymentCycleTime == 4
                                    )&& (
                                    <select  style={{width: "20%"}}
                                             onChange={paymentCycleDayChangeHandler} value={paymentCycleDay?paymentCycleDay:mainList4.payment_cycle_day}
                                    >
                                        <option key={1} value={1}>1일</option>
                                        <option key={2} value={2}>2일</option>
                                        <option key={3} value={3}>3일</option>
                                        <option key={4} value={4}>4일</option>
                                        <option key={5} value={5}>5일</option>
                                        <option key={6} value={6}>6일</option>
                                        <option key={7} value={7}>7일</option>
                                        <option key={8} value={8}>8일</option>
                                        <option key={9} value={9}>9일</option>
                                        <option key={10} value={10}>10일</option>
                                        <option key={11} value={11}>11일</option>
                                        <option key={12} value={12}>12일</option>
                                        <option key={13} value={13}>13일</option>
                                        <option key={14} value={14}>14일</option>
                                        <option key={15} value={15}>15일</option>
                                        <option key={16} value={16}>16일</option>
                                        <option key={17} value={17}>17일</option>
                                        <option key={18} value={18}>18일</option>
                                        <option key={19} value={19}>19일</option>
                                        <option key={20} value={20}>20일</option>
                                        <option key={21} value={21}>21일</option>
                                        <option key={22} value={22}>22일</option>
                                        <option key={23} value={23}>23일</option>
                                        <option key={24} value={24}>24일</option>
                                        <option key={25} value={25}>25일</option>
                                        <option key={26} value={26}>26일</option>
                                        <option key={27} value={27}>27일</option>
                                        <option key={28} value={28}>28일</option>
                                    </select>
                                    )}
                                    &nbsp;&nbsp;
                                    <select  style={{width: "20%"}}
                                             onChange={paymentCycleTimeChangeHandler} value={paymentCycleTime?paymentCycleTime:mainList4.payment_cycle_time}
                                    >
                                        <option key={0} value={0}>0시</option>
                                        <option key={1} value={1}>1시</option>
                                        <option key={2} value={2}>2시</option>
                                        <option key={3} value={3}>3시</option>
                                        <option key={4} value={4}>4시</option>
                                        <option key={5} value={5}>5시</option>
                                        <option key={6} value={6}>6시</option>
                                        <option key={7} value={7}>7시</option>
                                        <option key={8} value={8}>8시</option>
                                        <option key={9} value={9}>9시</option>
                                        <option key={10} value={10}>10시</option>
                                        <option key={11} value={11}>11시</option>
                                        <option key={12} value={12}>12시</option>
                                        <option key={13} value={13}>13시</option>
                                        <option key={14} value={14}>14시</option>
                                        <option key={15} value={15}>15시</option>
                                        <option key={16} value={16}>16시</option>
                                        <option key={17} value={17}>17시</option>
                                        <option key={18} value={18}>18시</option>
                                        <option key={19} value={19}>19시</option>
                                        <option key={20} value={20}>20시</option>
                                        <option key={21} value={21}>21시</option>
                                        <option key={22} value={22}>22시</option>
                                        <option key={23} value={23}>23시</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>시작일시</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    { ((initialState.settingPaymentCycleTime == '' && mainList4.payment_cycle == 1  )
                                        || initialState.settingPaymentCycleTime == 1
                                    )&& (
                                        <input onChange={startDtChangeHandler} className="form-control sm-date" type="date" value={startDt?startDt:mainList4.start_dt} style={{width:"120px"}}/>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>마감일시</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    { ((initialState.settingPaymentCycleTime == '' && mainList4.payment_cycle == 1  )
                                        || initialState.settingPaymentCycleTime == 1
                                    )&& (
                                        <input onChange={deadlineDtChangeHandler} className="form-control sm-date" type="date" value={deadlineDt?deadlineDt:mainList4.deadline_dt} style={{width:"120px"}}/>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급일시</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    { ((initialState.settingPaymentCycleTime == '' && mainList4.payment_cycle == 1  )
                                        || initialState.settingPaymentCycleTime == 1
                                    )&& (
                                    <input onChange={paymentDtChangeHandler} className="form-control sm-date" type="date" value={paymentDt?paymentDt:mainList4.payment_dt} style={{width:"120px"}}/>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급방식</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    <select  style={{width: "100%"}}
                                             onChange={paymentCdChangeHandler} value={paymentCd?paymentCd:mainList4.payment_cd}
                                    >
                                        <option key={'1'} value={'1'}>자동</option>
                                        <option key={'2'} value={'2'}>수동</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>지급공식</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    <select  style={{width: "100%"}}
                                             onChange={paymentFormulaChangeHandler} value={paymentFormula?paymentFormula:mainList4.payment_formula}
                                    >
                                        <option key={'1'} value={'1'}>(입-출-보유-베팅중)*요율%</option>
                                        <option key={'2'} value={'2'}>(입-출-보유-포인트-베팅중)*요율%</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>페이백%</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList4.payback}
                                           value={payback}
                                           onChange={(e) => setPayback(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>(-)금액이면 0으로 처리</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    <select  style={{width: "100%"}}
                                             onChange={minusYnChangeHandler} value={minusYn?minusYn:mainList4.minus_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최대지급금액</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList4.payment_max_amt}
                                           value={paymentMaxAmt}
                                           onChange={(e) => setPaymentMaxAmt(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>최소지급금액</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList4.payment_min_amt}
                                           value={paymentMinAmt}
                                           onChange={(e) => setPaymentMinAmt(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>마진설정</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList4.margin_set_amt}
                                           value={marginSetAmt}
                                           onChange={(e) => setMarginSetAmt(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>x회 이상 입금시 지급</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList4.payment_deposit_count}
                                           value={paymentDepositCount}
                                           onChange={(e) => setPaymentDepositCount(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        회
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>x일 이상 입금시 지급</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList4.payment_deposit_day}
                                           value={paymentDepositDay}
                                           onChange={(e) => setPaymentDepositDay(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        일
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4} style={{height:"30px"}}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={() => {updatePaybackState()}}
                                    >
                                        변경
                                    </button>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "120px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={() => {updateMultiPaybackState()}}
                                    >
                                        선택 레벨 일괄적용
                                    </button>
                                    {levels?.map((item, index) => (
                                        <>
                                            <input className="form-check-input" type="checkbox" name="inlineRadio04" id={'inlineRadioGrp04' + item.setting_level_no}
                                                   style={{cursor:"pointer"}}
                                                   onChange={(e) => {checkHandler04(e.currentTarget.checked, item.setting_level_no) }}
                                            />
                                            <label htmlFor={'inlineRadioGrp04' + item.setting_level_no} style={{cursor: "pointer"}}>
                                                {item.setting_level_no}레벨
                                            </label>
                                        </>
                                    ))}
                                </td>
                            </tr>
                        </table>

                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <font color={"white"} size={1.5}><b> {initialState.query}레벨 충전보너스선택 설정</b></font>
                        </div>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{height:"50px"}} >
                                    충전보너스선택 사용
                                </td>
                                <td style={{height:"30px"}} align={"left"}>
                                    <select  style={{width: "100%"}}
                                             onChange={bonusUseYnChangeHandler} value={bonusUseYn?bonusUseYn:mainList3.bonus_use_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td>
                                    보너스지급형태 갯수&nbsp;
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList3.bonus_payment_cnt}
                                           value={bonusPaymentCnt}
                                           onChange={(e) => setBonusPaymentCnt(e.target.value)}
                                    />
                                    &nbsp;
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                            onClick={() => {updateChargeState()}}
                                    >
                                        변경
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <table className="commonT table table-bordered table-sm text-center">
                            <thead >
                            <tr>
                                <th style={{width: "30%",backgroundColor: "lightgray"}}>메모</th>
                                <th style={{width: "12%",backgroundColor: "lightgray"}}>보너스지급방식</th>
                                <th style={{width: "13%",backgroundColor: "lightgray"}}>돌발시작</th>
                                <th style={{width: "13%",backgroundColor: "lightgray"}}>돌발종료</th>
                                <th style={{width: "10%",backgroundColor: "lightgray"}}>돌발추가</th>
                                <th style={{width: "*%",backgroundColor: "lightgray"}}>
                                    <button style={{ backgroundColor: "green", border: "1", color: "black" ,fontSize: "10px", height: "20px", width: "40px", marginRight: "5px", marginTop:"1px"}}
                                            onClick={() => {insertArrayState()}}
                                    >
                                        <font color={"white"}>추가</font>
                                    </button>
                                </th>
                            </tr>
                            </thead>
                            {!lvlSetting.length && (
                                <tbody key={lvlSetting.no}>
                                <tr>
                                    <td colSpan="8">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}

                            {lvlSetting.map((lvlSetting, index) => (
                                <tbody key={lvlSetting.no}>
                                <tr>
                                    <td>
                                        <input className="form-check-input sm-input" type="text" style={{width: "90%"}}
                                               placeholder={lvlSetting.memo}
                                               onChange={(e) => {memoArrHandler(e, lvlSetting.no)}}
                                        />
                                    </td>
                                    <td>
                                        <select  style={{width: "100%", height:"20px"}}
                                                 onChange={(e) => {bonusPaymentMethodArrHandler(e, lvlSetting.no)}}
                                                 value={initialState.bonusPaymentMethodArr[lvlSetting.no]?initialState.bonusPaymentMethodArr[lvlSetting.no]:lvlSetting.bonus_payment_method}
                                        >
                                            <option value={0}>미지급</option>
                                            {Array(mainList3.bonus_payment_cnt).fill(0).map((_,i) =>(
                                                <option value={i+1}>지급{i+1}</option>
                                            ))}

                                        </select>

                                    </td>
                                    <td>
                                        <input onChange={(e) => {outbreakSdtArrHandler(e, lvlSetting.no)}} className="form-control sm-date" type="date" value={initialState.outbreakSdtArr[lvlSetting.no]?initialState.outbreakSdtArr[lvlSetting.no]:lvlSetting.outbreak_sdt} style={{width:"95px"}}/>
                                    </td>
                                    <td>
                                        <input onChange={(e) => {outbreakEdtArrHandler(e, lvlSetting.no)}} className="form-control sm-date" type="date" value={initialState.outbreakEdtArr[lvlSetting.no]?initialState.outbreakEdtArr[lvlSetting.no]:lvlSetting.outbreak_edt} style={{width:"95px"}}/>
                                    </td>
                                    <td>
                                        <input className="form-check-input sm-input" type="text" style={{width: "80%"}}
                                               placeholder={lvlSetting.add_percent}
                                               onChange={(e) => {addPercentArrHandler(e, lvlSetting.no)}}
                                        />%
                                    </td>
                                    <td>
                                        <button style={{ backgroundColor: "lightskyblue", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"1px"}}
                                                onClick={() => {updateArrayState(lvlSetting.no)}}
                                        >
                                            <font color={"white"}>변경</font>
                                        </button>
                                        <button style={{ backgroundColor: "red", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "40px", marginRight: "5px", marginTop:"1px"}}
                                                onClick={() => {deleteArrayState(lvlSetting.no)}}
                                        >
                                            <font color={"white"}>삭제</font>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            ))}
                        </table>
                        <br></br>
                        {Array(mainList3.bonus_payment_cnt).fill(0).map((_,i) =>(
                                <button style={{ backgroundColor: chargeBounsNo === i+1 ? "blue" : "white" , border: "1", color: chargeBounsNo === i+1 ? "white" : "black",fontSize: "10px", height: "22px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                    onClick={() => searchChangeHandler(i+1, mainList1?.pnt_gb_cd)}
                                >
                                    <b>지급 {i+1} </b>
                                </button>
                        ))}
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            &nbsp;&nbsp;&nbsp;
                            <input className="form-check-input sm-input" type="text"
                                   style={{"width":"400px"}}
                                   placeholder={mainList2.bonus_nm ? mainList2.bonus_nm : '입력시 유저페이지 충전보너스선택란에 표시, 기본값(지급+종목코드)'}
                                   value={bonusNm}
                                   onChange={(e) => setBonusNm(e.target.value)}
                            />
                        </div>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>종목</b></font>
                                </td>
                                <td>
                                    <select  style={{width: "100%", height:"20px"}}
                                             onChange={(e) => {sportPaymentMethodChangeHandler(e, lvlSetting.no)}}
                                             value={sportPaymentMethod?sportPaymentMethod:mainList2.sport_payment_method}
                                    >
                                        <option key={1} value={1}>스포츠</option>
                                        <option key={2} value={2}>미니게임</option>
                                        <option key={3} value={3}>카지노</option>
                                        <option key={4} value={4}>슬롯</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>충전보너스제한(보유머니)</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"} colSpan={3}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList2.charge_bouns_limit_money}
                                           value={chargeBounsLimitMoney}
                                           onChange={(e) => setChargeBounsLimitMoney(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}  >
                                    <font color={"black"}><b>충전보너스제한(보유머니+포인트 최대금액)</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"} colSpan={3}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList2.charge_bouns_limit_all}
                                           value={chargeBounsLimitAll}
                                           onChange={(e) => setChargeBounsLimitAll(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        원
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "10%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>당일 첫충보너스제한(출금후)</b></font>
                                </td>
                                <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={firstChargeLimitDayYnChangeHandler} value={firstChargeLimitDayYn?firstChargeLimitDayYn:mainList2.first_charge_limit_day_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                                <td style={{width: "15%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    <font color={"black"}><b>당일 매충보너스제한(출금후)</b></font>
                                </td>
                                <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={everyChargeLimitDayYnChangeHandler} value={everyChargeLimitDayYn?everyChargeLimitDayYn:mainList2.every_charge_limit_day_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}  >
                                    <font color={"black"}><b>첫충 보너스</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"} colSpan={3}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList2.first_bonus_per}
                                           value={firstBonusPer}
                                           onChange={(e) => setFirstBonusPer(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}  >
                                    <font color={"black"}><b>매충 보너스</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"} colSpan={3}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList2.every_bonus_per}
                                           value={everyBonusPer}
                                           onChange={(e) => setEveryBonusPer(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        %
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}  >
                                    <font color={"black"}><b>최대 보너스 머니(1회)</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"} colSpan={3}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList2.max_bonus_cnt}
                                           value={maxBonusCnt}
                                           onChange={(e) => setMaxBonusCnt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        P
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}  >
                                    <font color={"black"}><b>최대 보너스 머니(1일)</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"} colSpan={3}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList2.max_bonus_day}
                                           value={maxBonusDay}
                                           onChange={(e) => setMaxBonusDay(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        P
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}  >
                                    <font color={"black"}><b>돌발보너스 출금후 제한 적용</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"} colSpan={3}>
                                    <select  style={{width: "80px", height:"22px"}}
                                             onChange={outbreakLimitYnChangeHandler} value={outbreakLimitYn?outbreakLimitYn:mainList2.outbreak_limit_yn}
                                    >
                                        <option key={'Y'} value={'Y'}>사용</option>
                                        <option key={'N'} value={'N'}>미사용</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "20%",backgroundColor: "white", height:"30px"}} align={"left"}  >
                                    <font color={"black"}><b>돌발보너스 일일 최대 지급횟수</b></font>
                                </td>
                                <td style={{width: "45%",backgroundColor: "white"}} align={"left"} colSpan={3}>
                                    <input className="form-check-input sm-input" type="text"
                                           placeholder={mainList2.max_outbreak_cnt}
                                           value={maxOutbreakCnt}
                                           onChange={(e) => setMaxOutbreakCnt(e.target.value)}
                                    />
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                                    >
                                        회
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4} style={{height:"30px"}}>
                                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                            onClick={() => {updateBonusState()}}
                                    >
                                        변경
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>



            </div>
        </div>

    )
}

export default LvlSetting;