import {call, put, take,} from "redux-saga/effects";
import {fetchCancelMatchBy} from "../../../../lib/api";
import {
    failedGetCancelMatchBy,
    setCancelMatchBy
} from "../../../reducers/game/manage/cancelMatchBy";

export function* getCancelMatchBySaga(action) {
    try {
        const res = yield call(
            fetchCancelMatchBy,
            action.payload
        )
        yield put(setCancelMatchBy(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetCancelMatchBy(error));
    }
}


