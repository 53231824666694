import {call, put} from "redux-saga/effects";
import {failedGetIpBlockListReducer, setIpBlockListReducer} from "../../reducers/setting/IpBlockListReducer";
import {fetchIpBlockList} from "../../../lib/api";

export function* getIpBlockListSaga(action) {
    try{
        console.log("##getIpBlockListSaga input#",action.payload.input)
        const res = yield call(fetchIpBlockList,
            action.payload.input,
        );
        console.log("##getIpBlockListSaga output#",res.data)
        yield put(setIpBlockListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetIpBlockListReducer(error))
    }
}