import React from 'react';
import StopByContainer from "../../../containers/game/manage/StopByContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const StopBy = () => {
    return (
        <div>
            <h1>수동중지</h1>
            <StopByContainer />
        </div>
    );
};

export default StopBy;