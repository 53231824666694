import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../redux/reducers/user/BlackMemberAddModalReducer";

const PrivateRatioContainer = ({userId, setSelectedTab}) => {

    const userInfoId = useSelector((state) => state.userInfo.id)
    const [detailBetInfo01, setDetailBetInfo01] = useState([]);
    const [noArr, setNoArr] = useState([]);
    const [betArr01, setBetArr01] = useState([]);


    const getDetailInfo = () => {

        axios.post('/admin/member/getDivideInfo', {
            userId : userId
        }).then((res) => {
            setDetailBetInfo01(res.data.type01List);
        })

    }
    useEffect(getDetailInfo, [])


    const setBetArr01Handler = (e, index, value) => {
        betArr01[index] = e.target.value;
        setBetArr01(betArr01);
        noArr[index] = value;
        setNoArr(noArr);
    }

    const updateState = (noArr, betArr01) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/member/saveDivide', {
                adminNo: userInfoId,
                userId: userId,
                noArr: noArr,
                betArr01: betArr01
            }).then((res) => {
                alert(res.data.isSaved? "저장완료" : "저장실패")
                setSelectedTab(0)
                setSelectedTab(3)
            })
        }
    }
    const handleClose = () => {
        window.close();
    }


    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table width={"99%"}>
                    <tr>
                        <td align={"right"}>
                            <button style={{
                                backgroundColor: "#5dc434",
                                border: "none",
                                color: "white",
                                fontSize: "9px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "10px"
                            }}
                                    onClick={()=>(updateState(noArr, betArr01)) }
                            >회원 수정
                            </button>
                            <button style={{
                                backgroundColor: "#3fadf7",
                                border: "none",
                                color: "white",
                                fontSize: "9px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "5px"
                            }}  onClick={handleClose}>닫기
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <b>게임 종류별 개인 &nbsp;<font color={"red"}> 두가지 선택지 게임</font>&nbsp; 배당 설정</b>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="commonT table table-bordered table-sm text-center" style={{width: "60%"}}>
                    <thead >
                    <tr>
                        <td style={{width: "50%",backgroundColor: "lightgray"}}>종류</td>
                        <td style={{width: "50%",backgroundColor: "lightgray"}}>2선택지 개인 배당</td>
                    </tr>
                    {detailBetInfo01.map((detailBetInfo01, index) => (
                        <tr>
                            <td style={{width: "50%",backgroundColor: detailBetInfo01.bet_devide > 0 ? "lightskyblue":"white"}} align={"left"}>
                                <button style={{
                                    backgroundColor: "darkgray",
                                    border: "none",
                                    color: "yellow",
                                    fontSize: "9px",
                                    height: "20px",
                                    width: "30px",
                                    marginRight: "10px"
                                }} >{detailBetInfo01.type_cd}
                                </button>
                                {detailBetInfo01.type_nm}
                            </td>
                            <td style={{width: "50%",backgroundColor: detailBetInfo01.bet_devide > 0 ? "lightskyblue":"white"}} align={"left"}>
                                <input className="form-check-input sm-input" type="text"
                                       style={{width: "120px"}} placeholder={detailBetInfo01.bet_devide}
                                       onChange={(e) => {setBetArr01Handler(e, detailBetInfo01.no, detailBetInfo01.type_cd)}}
                                />
                            </td>
                        </tr>
                    ))}
                    </thead>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <font color={"red"}> * '-1'로 설정하면, 기본 배당을 따름</font>
            </div>
        </div>



    )
}
export default PrivateRatioContainer;