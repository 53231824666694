import React from 'react';
import DepositWithdrawLogContainer from "../../containers/deposit/DepositWithdrawLogContainer";


const DepositWithdrawLog = () => {
    return (
        <div>
            <DepositWithdrawLogContainer />
        </div>
    );
};

export default DepositWithdrawLog;