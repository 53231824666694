
import TeamList from "../../../components/game/setting/TeamList";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import axios from "axios";
import Switch from "react-switch";
import {getLeagues, getTeams, setSelectedLeagues} from "../../../redux/reducers/game/gameSetting";
import Pagination from "../../../components/common/Pagination";


const TeamContainer = () => {
    const state = useSelector((state) => state.gameSetting);
    const dispatch = useDispatch();
    const [addList, setAddList] = useState([])
    const [searchQuery, setSearchQuery] = useState("")

    const input = {
        leagueName: state.selectedLeagueName,
        leagueId: state.selectedLeague,
        query: searchQuery,
    }

    const onRefresh = () => {
        dispatch(getTeams(input))
    }
    const insertTeam = (item) => {
        item.league_id = state.selectedLeague
        axios.post('/admin/game/setting/insertTeam', {
            teamItem: item
        }).then((res) => {
            if(res.data.isInserted) {
                alert('등록완료')
                const arr = addList.filter((el) => el.instantId !== item.instantId)
                setAddList(arr)
                onRefresh()
            }
            else {
                alert('등록실패')
            }
        })
    }

    const insertAllTeam = () => {
        axios.post('/admin/game/setting/insertAllTeam', {
            list: addList,
            leagueId: state.selectedLeague
        }).then((res) => {
            if(res.data.isInserted) {
                alert("일괄등록완료")
                setAddList([])
                onRefresh()
            }
            else {
                alert('등록실패')
            }
        })
    }
    const getTeamsFromMatchServer = () => {
        axios.post('/admin/game/setting/getTeamsFromMatchServer', {

        }).then((res) => {
            if(res.data.inserted) {
                alert("완료")
                onRefresh()
            }
            else {
                alert('실패')
            }
        })
    }

    const changeQuery = (e) => {
        setSearchQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.currentTarget.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.query = query
        dispatch(getTeams(fixInput))
    }

    return <div className="common">
        <p>선택리그: {state.selectedLeagueName}</p>
        <div className="d-flex">
            <div className="d-flex w-50">
                <input style={{
                    width: "100px"
                }} type="text" className="form-control" placeholder="리그명" value={state.selectedLeagueName} onChange={(e) => {
                    dispatch(setSelectedLeagues({name: e.currentTarget.value}))
                }} onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        dispatch(getTeams(input))
                    }
                }} />
                <input style={{
                    width: "100px"
                }} type="text" className="form-control" placeholder="팀명" value={searchQuery} onChange={changeQuery} onKeyDown={inputPress} />
                <button style={{
                    width: "80px", borderTopRightRadius: "5px" ,borderBottomRightRadius: "5px"
                }} type="submit" className="btn btn-primary" onClick={(e) => {
                    onSearch(searchQuery)
                }}>검색</button>
            </div>
            <div className="d-flex justify-content-end mb-1 w-50">

                <button className="btn btn-secondary" onClick={(e) => {
                    const arr = [...addList]
                    arr.push({
                        instantId: new Date().getTime(),
                        name_kor: "",
                    })
                    setAddList(arr)
                }}>추가</button>
                <button className="btn btn-primary mx-1" onClick={(e) => {
                    onRefresh()
                }}>새로고침</button>
                <button className="btn btn-success" onClick={(e) => {
                    getTeamsFromMatchServer()
                }}>가져오기</button>
            </div>
        </div>
        {addList.length > 0 && (
            <div>
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-secondary">
                    <tr>
                        <td colSpan="2">
                            팀신규등록
                        </td>
                    </tr>
                    <tr>
                        <th>팀</th>
                        <th>
                            <button className="btn btn-secondary mb-1" onClick={(e) => {
                                insertAllTeam()
                            }}>일괄저장</button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {addList.map((item, index) => (
                        <tr>
                            <td>
                                <input className="form-control form-control-sm me-1" type="text" value={item.name_kor} placeholder="리그"
                                       onChange={(e) => {
                                           item.name_kor = e.currentTarget.value
                                           const arr = [...addList]
                                           arr[index] = item
                                           setAddList(arr)
                                       }}/>
                            </td>
                            <td>
                                <div className="d-flex w-100 justify-content-center">
                                    <button className="btn btn-secondary me-1" onClick={(e) => {
                                        insertTeam(item)
                                    }}>저장</button>
                                    <button className="btn btn-warning ms-1" onClick={(e) => {

                                        const arr = addList.filter((el) => el.instantId !== item.instantId)
                                        const arr2 = [...arr]
                                        setAddList(arr2)
                                    }}>삭제</button>
                                </div>

                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

        )}

        <TeamList state={state} onRefresh={onRefresh}/>
    </div>
}

export default TeamContainer