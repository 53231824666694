import {call, put, take,} from "redux-saga/effects";
import {fetchActives} from "../../../lib/api";
import {failedGetActives, setActives} from "../../reducers/monitoring/ActiveReducer";

export function* getActiveSaga(action) {
    try{
        const res = yield call(
            fetchActives,
            action.payload.idQuery, action.payload.ipQuery,
            action.payload.onlyAdmin, action.payload.includeChild,
            action.payload.page, action.payload.pageSize, action.payload.orgId
        )

        yield put(setActives(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetActives(error))
    }
}