import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {getMemberUser} from "../../../redux/reducers/user/MemberReducer";
import * as XLSX from "xlsx";

const MemberExcelModal01 = (props) => {

    const {open, onClose, initialState} = props

    const [searchCd01, setSearchCd01] = useState();
    const [totalList, setTotalList] = useState({});
    const [pwd, setPwd] = useState();

    const search01ChangeHandler = (type) => {

        console.log("initialState >>>>>>>>>>>>>"+JSON.stringify(initialState));

        setSearchCd01(type)
        axios.post('/admin/member/getExelList', {
            orderCode : type,
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.searchQuery,
            startDate: initialState.startDate,
            endDate: initialState.endDate,
            userGbCd: initialState.userGbCd,
            sortColumn: initialState.sortColumn,
            sortValue: initialState.sortValue,
            searchGb: initialState.searchGb,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            selectedNos: initialState.selectedNos,
            selectedNos2: initialState.selectedNos2,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            selectedNos5: initialState.selectedNos5,
            selectedNos6: initialState.selectedNos6,
            selectedNos7: initialState.selectedNos7,
            selectedNos8: initialState.selectedNos8,
            selectedNos9: initialState.selectedNos9
        }).then((res) => {
            setTotalList(res.data.totalList);
        })
    }

    const handleCancel = () => {
        onClose()
    }

    const pwChangeHandler = (e) => {
        setPwd(e.currentTarget.value);
    }

    const openPopup = ()=> {
        open()
    }

    const exportExcel = ()=> {

        //alert("searchCd01 :: "+searchCd01);

        if( pwd === "" )
        {
            alert("엑셀 다운로드 비밀번호를 입력해주세요.");
            return;
        }
        if( pwd != "zx5454" )
        {
            alert("엑셀 다운로드 비밀번호가 옳바르지 않습니다.");
            return;
        }
        if( searchCd01 === undefined )
        {
            alert("정렬기준을 선택해주세요.");
            return;
        }

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < totalList.length; i++) {
            let jsonObject = { // JSON 객체를 생성합니다.
                순서: totalList[i].no? totalList[i].no :'',
                번호: totalList[i].user_no? totalList[i].user_no :'',
                SITE: totalList[i].site_cd? totalList[i].site_cd :'',
                도메인: totalList[i].domain? totalList[i].domain :'',
                그룹: totalList[i].user_group_cd? totalList[i].user_group_cd :'',
                개인총판: '-',
                아이디: totalList[i].id? totalList[i].id :'',
                닉네임: totalList[i].nickname? totalList[i].nickname :'',
                이름: totalList[i].name? totalList[i].name :'',
                소속: totalList[i].store_cd? totalList[i].store_cd :'',
                총판: totalList[i].psnl_cd? totalList[i].psnl_cd :'',
                가맹점: totalList[i].mrch_cd? totalList[i].mrch_cd :'',
                입금방식: totalList[i].rcpt_meth_cd? totalList[i].rcpt_meth_cd :'',
                게임머니: totalList[i].game_money_amt? totalList[i].game_money_amt :'',
                포인트: totalList[i].point? totalList[i].point :'',
                kgon머니: totalList[i].kg_casino_money_amt? totalList[i].kg_casino_money_amt :'',
                회원등급: totalList[i].user_gb_cd? totalList[i].user_gb_cd :'',
                회원상태: totalList[i].user_state_cd? totalList[i].user_state_cd :'',
                p등급: totalList[i].pnt_gd_cd? totalList[i].pnt_gd_cd :'',
                베팅횟수: totalList[i].betting_cnt? totalList[i].betting_cnt :'',
                환전비번: totalList[i].withdraw_pw? totalList[i].withdraw_pw :'',
                핸드폰: '-',
                문의쪽지외차단: totalList[i].iqry_pop_out_blk_yn? totalList[i].iqry_pop_out_blk_yn :'',
                전체베팅차단: totalList[i].all_block_yn? totalList[i].all_block_yn :'',
                단폴베팅가능여부: totalList[i].one_bet_yn? totalList[i].one_bet_yn :'',
                두폴베팅가능여부: totalList[i].two_bet_yn? totalList[i].two_bet_yn :'',
                Kg카지노베팅가능여부: totalList[i].kg_casino_bet_posb_yn? totalList[i].kg_casino_bet_posb_yn :'',
                토큰베팅가능여부: totalList[i].token_bet_posb_yn? totalList[i].token_bet_posb_yn :'',
                Inplay베팅가능여부: totalList[i].inplay_bet_posb_yn? totalList[i].inplay_bet_posb_yn :'',
                출석체크가능여부: totalList[i].attend_chk_posb_yn? totalList[i].attend_chk_posb_yn :'',
                충전: totalList[i].deposit_amt? totalList[i].deposit_amt :'',
                환전: totalList[i].withdraw_amt? totalList[i].withdraw_amt :'',
                마진: totalList[i].profit_amt? totalList[i].profit_amt :'',
                충전일수: totalList[i].deposit_days? totalList[i].deposit_days :'',
                충전횟수: totalList[i].deposit_cnt? totalList[i].deposit_cnt :'',
                추천인: totalList[i].up_rcmr_id? totalList[i].up_rcmr_id :'',
                추천수: totalList[i].rcmr_cnt? totalList[i].rcmr_cnt :'',
                가입일: totalList[i].entr_dttm? totalList[i].entr_dttm :'',
                최근활동: totalList[i].updatedAt? totalList[i].updatedAt :'',
                최근충전: totalList[i].lately_deposit? totalList[i].lately_deposit :'',
                중복계좌: totalList[i].acc_dup_cnt? totalList[i].acc_dup_cnt :'',
                로그인: totalList[i].login_cnt? totalList[i].login_cnt :'',
                출석: totalList[i].attend_cnt? totalList[i].attend_cnt :'',
                접속IP: totalList[i].rcnt_actv_ip? totalList[i].rcnt_actv_ip :'',
                가입IP: totalList[i].entr_ip? totalList[i].entr_ip :'',
                중복IP: totalList[i].dup_ip? totalList[i].dup_ip :'',
                블랙: totalList[i].black_reg_cnt? totalList[i].black_reg_cnt :''

            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가 합니다.
        }
        //alert(JSON.stringify(totalList));
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '회원관리');
        XLSX.writeFile(workBook, '회원관리.xlsx');
    }



    return (
        <div className="member_modal_background">
            <div className="member_modal_box" >
                <div className="mebmer_modal_body">

                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <br></br>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table   width={"99%"}>
                            <tr>
                                <td align={"right"}>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleCancel}>닫기</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>정렬기준</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"left"} width={"68%"}>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("01")} />
                                    기본 정렬 (삭제여부/회원구분/가입최근)
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("02")} />
                                    가입일 최근 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("03")} />
                                    가입일 오래된 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("04")} />
                                    최근활동 최근 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("05")} />
                                    최근활동 오래된 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("06")} />
                                    마진 많은 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("07")} />
                                    마진 적은 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("08")} />
                                    충전 최근순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("09")} />
                                    충전 오래된순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("10")} />
                                    충전 많은순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }}
                                           value={searchCd01} onChange={() => search01ChangeHandler("11")} />
                                    충전 적은순
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>엑셀 다운로드 비밀번호</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"left"} width={"68%"}>
                                    <input className="form-check-input sm-input" type="password" style={{ width: "200px" }} placeholder="" value={pwd} onChange={pwChangeHandler}/>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray", cursor: "pointer"}} align={"center"} width={"49.5%"} height={"40px"} onClick={exportExcel}>다운로드 받기</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"49.5%"} onClick={openPopup}>다운로드 기록보기</td>
                            </tr>
                        </table>
                    </div>




                </div>
            </div>
        </div>

    )
}
export default MemberExcelModal01;