import React from "react";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import {
    getInplayResultDetail,
} from "../../../redux/reducers/game/manage/inplayResult";
import axios from "axios";
import common from "../../../util/common";
import {NumericFormat} from "react-number-format";
import FixtureStatus from "../../../constant/FixtureStatus";
import BetStatus from "../../../constant/BetStatus";

const InplayResultList = ({state, onRefresh}) => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo);

    function getDetail() {
        const result = []
        const totalTrs = []
        const markets = []
        let trs = []
        let tempMarketId = -1

        state.currentFixture.ratios.map((fixture, index) => {

            if(fixture.market_id !== tempMarketId) {
                if(tempMarketId !== -1) {
                    totalTrs.push({trs: trs, fixture: fixture})
                    markets.push(fixture.market)
                }
                tempMarketId = fixture.market_id
                trs = []
            }

            trs.push(<tr style={{backgroundColor: fixture.is_edited? "lightyellow":"transparent"}} onClick={(e) => {
                console.log(fixture)
            }}>
                    <td>{fixture.provider}</td>
                    <td className={fixture.bet1_settlement === 2 && "table-success"}>
                        <div>
                            <p>{fixture.bet1}</p>
                            {fixture.total && (<p><NumericFormat value={fixture.total.sumExtraMoney1} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount1})`}</span></p>)}
                        </div>
                    </td>
                    <td className={fixture.baseline === null && (fixture.bet2_settlement  === 2 && "table-success")}>
                        <div>
                            <p>
                                {fixture.baseline? fixture.baseline.split(' (0')[0] : fixture.bet2}
                            </p>
                            {fixture.baseline === null && fixture.total && (
                                <p>
                                    <NumericFormat value={fixture.total.sumExtraMoney2} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount2})`}</span>
                                </p>
                            )}
                        </div>
                    </td>
                    <td className={fixture.bet3_settlement  === 2 && "table-success"}>
                        <div>
                            <p>{fixture.bet3}</p>
                            {fixture.total && (<p><NumericFormat value={fixture.total.sumExtraMoney3} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount3})`}</span></p>)}
                        </div>
                    </td>
                    <td>{BetStatus.getKorMessage(fixture.bet1_status)}</td>
                    <td>{moment(fixture.bet1_updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{moment(fixture.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                </tr>)
            //마지막 인덱스고, 마켓아이디가 이전과 같다면 여기서 totalTrs에 푸쉬해준다. 안그러면 마지막꺼 빼먹음.
            if(index === state.currentFixture.ratios.length - 1 && fixture.market_id === tempMarketId) {
                totalTrs.push({trs: trs, fixture: fixture})
                markets.push(fixture.market)
            }
        })


        for(let i = 0; i < totalTrs.length; i++) {
            const _trs = totalTrs[i].trs
            const _fixture = totalTrs[i].fixture
            const tableTemp = <div>
                <p className="h4 text-bg-dark mb-0 p-2">{_fixture.market}</p>
                <table className="table table-bordered table-hover text-center">
                    <thead className="table-secondary">
                    <tr>
                        <th style={{
                            width: "auto"
                        }}>업체</th>
                        <th style={{
                            width: "25%"
                        }}>{_fixture.home}</th>
                        <th style={{
                            width: "13%"
                        }}>{"무/기준점"}</th>
                        <th style={{
                            width: "25%"
                        }}>{_fixture.away}</th>
                        <th style={{
                            width: "auto"
                        }}>상태</th>
                        <th style={{
                            width: "auto"
                        }}>배당수정시간</th>
                        <th style={{
                            width: "auto"
                        }}>최근수정</th>
                    </tr>
                    </thead>
                    <tbody>
                    {_trs}
                    </tbody>
                </table>
            </div>

            result.push(tableTemp)
        }


        return result
    }

    return (
        <div className="container-fluid p-1 m-0">

            <div className="d-flex">
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {state.low_ratios && (
                        <table className="table table-bordered table-hover text-center">
                            <thead className="table-dark">
                            <tr>
                                <th>종목</th>
                                <th>일정</th>
                                <th>리그</th>
                                <th>홈팀</th>
                                <th>원정팀</th>
                                <th>진행배팅금</th>
                                <th>Fix상태</th>
                                <th>Fix수정시간</th>
                                <th>FixID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.isLoading && "로딩중..."}
                            {!state.isLoading && !state.low_ratios.length > 0 && (
                                <tr>
                                    <td colSpan="3">목록이 비어있습니다.</td>
                                </tr>
                            )}
                            {!state.isLoading && !!state.low_ratios.length && state.low_ratios.map((item, index) => (
                                <tr className={state.checkedInputs.includes(item.id) && "info"} onClick={() => {
                                    dispatch(getInplayResultDetail(item.fixture_id))
                                }}>
                                    <td>{`${item.sport}\n(${item.mCount})`}</td>
                                    <td>{moment(item.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{item.league}</td>
                                    <td>
                                        {item.home.split(" [")[0]}
                                    </td>
                                    <td>{item.away.split(" [")[0]}</td>
                                    <td>
                                        {item.total && (
                                            <div style={{
                                                width: "100px",
                                                justifyContent: "center"
                                            }}>
                                                <p><NumericFormat value={item.total.sumExtraMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betCount})`}</span></p>
                                            </div>
                                        )}

                                    </td>
                                    <td>{FixtureStatus.getKorMessage(item.fixture_status)}</td>
                                    <td>{moment(item.fixture_updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>{item.fixture_id}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {state.isLoadingDetail && "로딩중..."}
                    {!state.isLoadingDetail && state.currentFixture === null && "선택된 경기가 없습니다."}
                    {!state.isLoadingDetail && state.currentFixture !== null && (
                        <div>
                            <div className="text-bg-dark p-2 mb-5">
                                <p>{`${state.currentFixture.ratios[0]?.sport} - ${state.currentFixture.ratios[0]?.league}`}</p>
                                <div className="text-center align-middle" style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}><p>{`${state.currentFixture.ratios[0].home.split(" [")[0]}`}</p></div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center",
                                    }}>
                                        <p className="m-0">{`${moment(state.currentFixture.ratios[0].start_date).format("YYYY-MM-DD HH:mm")}`}</p>
                                    </div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}>{`${state.currentFixture.ratios[0].away.split(" [")[0]}`}</div>

                                </div>
                            </div>
                            {getDetail()}

                        </div>
                    )}

                </div>
            </div>

        </div>
    );

}

export default InplayResultList;