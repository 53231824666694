import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

export const fixtureSlice = createSlice({
    name: 'fixture',
    initialState: {
        isLoading: false,
        fixtures: [],
        originFixtures: [],
        error: null,
        isConnect: false,
        tasks: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        sport: 0,
        status: 0,
        league: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        sports: [],
        leagues: [],
        query: "",
        checkedInputs: [],
        updateData: [],
    },
    reducers: {
        getFixture(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.status = action.payload.status;
            state.sport = action.payload.sport;
            state.league = action.payload.league;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setFixture(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.fixtures = action.payload.result;
            state.totalCount = action.payload.totalCount;
            state.sports = action.payload.sports;
            state.markets = action.payload.markets;
            state.providers = action.payload.providers;
            state.leagues = action.payload.leagues;
            state.checkedInputs = [];

        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        failedGetFixture(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export const {
    getFixture,
    setFixture,
    setCheckedInputs,
    setUpdateData,
    failedGetFixture,
} = fixtureSlice.actions;

export default fixtureSlice.reducer;