import {call, put} from "redux-saga/effects";
import {failedGetBlackMemberReducer, setBlackMemberReducer} from "../../reducers/user/BlackMemberReducer";
import {fetchBlackMemberList} from "../../../lib/api";

export function* getBlackMemberSaga(action) {
    try{
        console.log("##getBlackMemberSaga input#",action.payload.page,action.payload.pageSize, action.payload.query)
        const res = yield call(fetchBlackMemberList,
            action.payload.page, action.payload.pageSize,action.payload.query,
        );
        console.log("##getBlackMemberSaga output#",res.data)
        yield put(setBlackMemberReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetBlackMemberReducer(error))
    }
}