import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
const PointStat = ({pointStat, onRefresh}) => {



    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead >
                        <tr>
                            <th style={{width: "20%"}}>구분</th>
                            <th style={{width: "40%"}}>지급 포인트</th>
                            <th style={{width: "40%"}}>지급 횟수</th>
                        </tr>
                        </thead>
                        {!pointStat.length && (
                            <tbody key={pointStat.point_type}>
                            <tr>
                                <td colSpan="3">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {pointStat?.map((pointStat, index) => (
                            <tbody key={pointStat.point_type_cd}>
                            <tr>
                                <td align={"center"}>{pointStat.point_type_cd}</td>
                                <td align={"center"} >
                                    <font color="blue">
                                        <NumericFormat value={pointStat.result_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"} >
                                    <font color="blue">
                                        <NumericFormat value={pointStat.result_cnt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default PointStat;