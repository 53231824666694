import {createSlice} from "@reduxjs/toolkit"

export const activeMemberSlice = createSlice({
    name: 'activeMember',
    initialState: {
        isLoading: false,
        error: null,
        activeMembers: [],
        userGbCd: "",
        userStateCd: "",
        device: 0,
        searchGb: "nickname",
        searchQuery: "",
        sortColum: "",
        sortValue: 0,
        orgId: "",
        pGrade: "",
        interestUser: "",

    },
    reducers: {
        getActiveMembers(state, action) {
            state.isLoading = true;
            state.device = action.payload.device? action.payload.device : 0
            state.searchGb = action.payload.searchGb? action.payload.searchGb : "nickname"
            state.searchQuery = action.payload.searchQuery? action.payload.searchQuery : ""
            state.sortColum = action.payload.sortColum? action.payload.sortColum : ""
            state.sortValue = action.payload.sortValue? action.payload.sortValue : 0
            state.userGbCd = action.payload.userGbCd
            state.userStateCd = action.payload.userStateCd
            state.pGrade = action.payload.pGrade
            state.interestUser = action.payload.interestUser
        },
        failedGetActiveMembers(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setActiveMembers(state, action) {
            state.isLoading = false;
            state.activeMembers = action.payload.result;
        },
    }
});

export const { getActiveMembers, failedGetActiveMembers, setActiveMembers } = activeMemberSlice.actions;

export default activeMemberSlice.reducer;