import React from 'react';
import ResultMatchContainer from "../../../containers/game/manage/ResultContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const Result = () => {
    return (
        <div className="common">
            <font color={"blue"} size="3">경기결과</font>
            <ResultMatchContainer />
        </div>
    );
};

export default Result;