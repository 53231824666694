import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {
    setIsOpen,
    setSelectedNos1,
    setSelectedNos2,
    setSelectedNos3,
    setSelectedNos4,
    setSelectedNos5,
    setRegBankCdArr,setRegBankNmArr,setRegUseYnArr,setRegOrderCdArr,
    setDangerBankCdArr,setDangerBankNmArr,setDangerUseYnArr,setDangerOrderCdArr
} from "../../redux/reducers/setting/RegSettingListReducer";
import Switch from "react-switch";
import {setPaymentMethodArr} from "../../redux/reducers/setting/DwpSettingListReducer";


const RegSetting = ({regSetting, onRefresh, initialState, onSearch, mainList1, mainList2, mainList3, mainList4, mainList5

                    }) => {

    const dispatch = useDispatch()
    const [levels, setLevels] = useState([])
    const [pntGbList, setPntGbList] = useState([]);

    const [useYn, setUseYn] = useState("");
    const [pwdComplicationYn, setPwdComplicationYn] = useState("");
    const [referralCdYn, setReferralCdYn] = useState("");
    const [smsAuthYn, setSmsAuthYn] = useState("");
    const [agencyYn, setAgencyYn] = useState("");
    const [genderYn, setGenderYn] = useState("");
    const [birthdtYn, setBirthdtYn] = useState("");
    const [dupAccountYn, setDupAccountYn] = useState("");
    const [dupPhoneYn, setDupPhoneYn] = useState("");
    const [dupIpYn, setDupIpYn] = useState("");
    const [dupIpRegTime, setDupIpRegTime] = useState("");
    const [memberStatCd, setMemberStatCd] = useState("");
    const [memberLvlCd, setMemberLvlCd] = useState("");
    const [payPoint, setPayPoint] = useState("");
    const [totalYn, setTotalYn] = useState("");
    const [memberYn, setMemberYn] = useState("");
    const [totalTitle, setTotalTitle] = useState("");
    const [totalCnt, setTotalCnt] = useState("");
    const [memberTitle, setMemberTitle] = useState("");
    const [memberCnt, setMemberCnt] = useState("");

    const useYnHandler = (e) => {
        setUseYn(e.currentTarget.value);
    }
    const pwdComplicationYnHandler = (e) => {
        setPwdComplicationYn(e.currentTarget.value);
    }
    const referralCdYnHandler = (e) => {
        setReferralCdYn(e.currentTarget.value);
    }
    const smsAuthYnHandler = (e) => {
        setSmsAuthYn(e.currentTarget.value);
    }
    const agencyYnHandler = (e) => {
        setAgencyYn(e.currentTarget.value);
    }
    const genderYnHandler = (e) => {
        setGenderYn(e.currentTarget.value);
    }
    const birthdtYnHandler = (e) => {
        setBirthdtYn(e.currentTarget.value);
    }
    const dupAccountYnHandler = (e) => {
        setDupAccountYn(e.currentTarget.value);
    }
    const dupPhoneYnHandler = (e) => {
        setDupPhoneYn(e.currentTarget.value);
    }
    const dupIpYnHandler = (e) => {
        setDupIpYn(e.currentTarget.value);
    }
    const totalYnHandler = (e) => {
        setTotalYn(e.currentTarget.value);
    }
    const memberYnHandler = (e) => {
        setMemberYn(e.currentTarget.value);
    }
    const memberStatCdHandler = (e) => {
        setMemberStatCd(e.currentTarget.value);
    }
    const memberLvlCdHandler = (e) => {
        setMemberLvlCd(e.currentTarget.value);
    }


    const userInfoId = useSelector((state) => state.userInfo.id)
    const handleClickOpen = () => {
        dispatch(setIsOpen(true))
    }
    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        window.location.reload();
    }

    useEffect(() => {
        getGrades()
    }, [])

    const getGrades = () => {
        axios.post('/admin/admin/getPointGrade2', {

        }).then((res) => {
            setLevels(res.data.result)
            const arr = []
            for(const level of res.data.result) {
                arr.push(level.setting_level_no)
            }
        })

        axios.post('/admin/cmmnCdList', {
            cmmnCdId : '0048',
        }).then((res) => {
            setPntGbList(res.data.cmmnCdList);
        })
    }


    const onLevelChange = (setting_level_no) => {
        onSearch(setting_level_no)
    }

    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }


    const updateState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveRegSetting', {
                siteCd: regSetting?.site_cd,
                useYn: useYn?useYn:regSetting.use_yn,
                pwdComplicationYn: pwdComplicationYn?pwdComplicationYn:regSetting.pwd_complication_yn,
                referralCdYn: referralCdYn?referralCdYn:regSetting.referral_cd_yn,
                smsAuthYn: smsAuthYn?smsAuthYn:regSetting.sms_auth_yn,
                agencyYn: agencyYn?agencyYn:regSetting.agency_yn,
                genderYn: genderYn?genderYn:regSetting.gender_yn,
                birthdtYn: birthdtYn?birthdtYn:regSetting.birthdt_yn,
                dupAccountYn: dupAccountYn?dupAccountYn:regSetting.dup_account_yn,
                dupPhoneYn: dupPhoneYn?dupPhoneYn:regSetting.dup_phone_yn,
                dupIpYn: dupIpYn?dupIpYn:regSetting.dup_ip_yn,
                dupIpRegTime: dupIpRegTime?dupIpRegTime:regSetting.dup_ip_reg_time,
                memberStatCd: memberStatCd?memberStatCd:regSetting.member_stat_cd,
                memberLvlCd: memberLvlCd?memberLvlCd:regSetting.member_lvl_cd,
                payPoint: payPoint?payPoint:regSetting.pay_point,
                totalYn: totalYn?totalYn:regSetting.total_yn,
                memberYn: memberYn?memberYn:regSetting.member_yn,
                totalTitle: totalTitle?totalTitle:regSetting.total_title,
                totalCnt: totalCnt?totalCnt:regSetting.total_cnt,
                memberTitle: memberTitle?memberTitle:regSetting.member_title,
                memberCnt: memberCnt?memberCnt:regSetting.member_cnt,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }

    const addRegBankList = () => {
        axios.post('/admin/setting/insertAddRegBank', {
            adminNo: userInfoId
        }).then((res) => {
            alert(res.data.isUpdated? "추가완료" : "추가실패")
            window.location.reload();
        })
    }

    const addDangerBankList = () => {
        axios.post('/admin/setting/insertAddDangerBank', {
            adminNo: userInfoId
        }).then((res) => {
            alert(res.data.isUpdated? "추가완료" : "추가실패")
            window.location.reload();
        })
    }

    const regBankCdArrHandler = (e, index) => {
        const rows = [...initialState.regBankCdArr];
        rows[index] = e.target.value;
        dispatch(setRegBankCdArr(rows));
    }
    const regBankNmArrHandler = (e, index) => {
        const rows = [...initialState.regBankNmArr];
        rows[index] = e.target.value;
        dispatch(setRegBankNmArr(rows));
    }
    const regUseYnArrHandler = (e, index) => {
        const rows = [...initialState.regUseYnArr];
        rows[index] = e.target.value;
        dispatch(setRegUseYnArr(rows));
    }
    const regOrderCdArrHandler = (e, index) => {
        const rows = [...initialState.regOrderCdArr];
        rows[index] = e.target.value;
        dispatch(setRegOrderCdArr(rows));
    }
    const dangerBankCdArrHandler = (e, index) => {
        const rows = [...initialState.dangerBankCdArr];
        rows[index] = e.target.value;
        dispatch(setDangerBankCdArr(rows));
    }
    const dangerBankNmArrHandler = (e, index) => {
        const rows = [...initialState.dangerBankNmArr];
        rows[index] = e.target.value;
        dispatch(setDangerBankNmArr(rows));
    }
    const dangerUseYnArrHandler = (e, index) => {
        const rows = [...initialState.dangerUseYnArr];
        rows[index] = e.target.value;
        dispatch(setDangerUseYnArr(rows));
    }
    const dangerOrderCdArrHandler = (e, index) => {
        const rows = [...initialState.dangerOrderCdArr];
        rows[index] = e.target.value;
        dispatch(setDangerOrderCdArr(rows));
    }

    const updateRegState = (state, no) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveRegArraySetting', {
                bankNo: state,
                bankCd: initialState.regBankCdArr[state]?initialState.regBankCdArr[state]:mainList1.bank_cd,
                useYn: initialState.regUseYnArr[state]?initialState.regUseYnArr[state]:mainList1.use_yn,
                orderCd: initialState.regOrderCdArr[state]?initialState.regOrderCdArr[state]:mainList1.order_cd,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                window.location.reload();
            })
        }
    }

    const deleteRegState = (state, no) => {
        if (window.confirm("삭제 하시겠습니까?"))
        {
            axios.post('/admin/setting/deleteRegArraySetting', {
                bankNo: state,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                window.location.reload();
            })

        }
    }

    const updateDangerState = (state, no) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveDangerArraySetting', {
                bankNo: state,
                bankCd: initialState.dangerBankCdArr[state]?initialState.dangerBankCdArr[state]:mainList2.bank_cd,
                useYn: initialState.dangerUseYnArr[state]?initialState.dangerUseYnArr[state]:mainList2.use_yn,
                orderCd: initialState.dangerOrderCdArr[state]?initialState.dangerOrderCdArr[state]:mainList2.order_cd,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                window.location.reload();
            })
        }
    }

    const deleteDangerState = (state, no) => {
        if (window.confirm("삭제 하시겠습니까?"))
        {
            axios.post('/admin/setting/deleteDangerArraySetting', {
                bankNo: state,
                adminNo: userInfoId
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                window.location.reload();
            })

        }
    }


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">가입설정</font>
                    &nbsp;&nbsp;
                    <select
                        onChange={useYnHandler} value={useYn?useYn:regSetting.use_yn}
                    >
                        <option value={'Y'}>사용</option>
                        <option value={'N'}>미사용</option>
                    </select>
                </div>
            </div>
            <br></br>
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"49%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            <table style={{width: "100%"}}>
                                <tr>
                                    <td style={{width: "50%"}} align={"left"}>
                                        &nbsp;&nbsp;&nbsp;
                                        <font color={"white"} size={1.5}><b> 가입시 사용 은행</b></font>
                                    </td>
                                    <td style={{width: "50%"}} align={"right"}>
                                        <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                onClick={() => {addRegBankList()}}
                                        >
                                            <b>추가</b>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div style={{width:"2%"}}>
                    </div>
                    <div style={{width:"49%"}}>
                        <div style={{
                            position:"relative",
                            background:"#3c3c3d",
                            height:"30px",
                            alignItems:"center",
                            textAlign:"left",
                            alignContent:"center"
                        }}>
                            <table style={{width: "100%"}}>
                                <tr>
                                    <td style={{width: "50%"}} align={"left"}>
                                        &nbsp;&nbsp;&nbsp;
                                        <font color={"white"} size={1.5}><b> 위험계좌 등록</b></font>
                                    </td>
                                    <td style={{width: "50%"}} align={"right"}>
                                        <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                onClick={() => {addDangerBankList()}}
                                        >
                                            <b>추가</b>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{width:"49%"}}>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "5%",backgroundColor: "lightgray", height:"30px"}} align={"center"}>
                                    <font color={"black"}><b>번호</b></font>
                                </td>
                                <td style={{width: "20%",backgroundColor: "lightgray", height:"30px"}} align={"center"}>
                                    <font color={"black"}><b>은행명</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "lightgray", height:"30px"}} align={"center"}>
                                    <font color={"black"}><b>사용여부</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "lightgray", height:"30px"}} align={"center"}>
                                    <font color={"black"}><b>순서</b></font>
                                </td>
                                <td style={{width: "15%",backgroundColor: "lightgray", height:"30px"}} align={"center"}>
                                </td>
                            </tr>
                            {!mainList1.length && (
                                <tbody key={mainList1.bank_no}>
                                <tr>
                                    <td colSpan="5">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}
                            {mainList1?.map((mainList1, index) => (
                                <tbody key={mainList1.bank_no}>
                                <tr>
                                    <td style={{width: "5%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                        <font color={"black"}><b>{mainList1.bank_no}</b></font>
                                    </td>
                                    <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <select
                                            className="form-select sm-select"
                                            style={{
                                                width: "300px"
                                            }}
                                            onChange={(e) => {regBankCdArrHandler(e, mainList1.bank_no)}}
                                            value={initialState.regBankCdArr[mainList1.bank_no]?initialState.regBankCdArr[mainList1.bank_no]:mainList1.bank_cd}
                                        >
                                            {pntGbList?.map((item, index) => (
                                                <option key={item.cmmn_cd_dtl_id}
                                                        value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: "5%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                        <select
                                            className="form-select sm-select"
                                            style={{height: "25px", width:"70px"}}
                                            onChange={(e) => {regUseYnArrHandler(e, mainList1.bank_no)}}
                                            value={initialState.regUseYnArr[mainList1.bank_no]?initialState.regUseYnArr[mainList1.bank_no]:mainList1.use_yn}
                                        >
                                            <option value={"Y"}>사용</option>
                                            <option value={"N"}>미사용</option>
                                        </select>
                                    </td>
                                    <td style={{width: "5%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                        <select
                                            className="form-select sm-select"
                                            style={{height: "25px", width:"70px"}}
                                            onChange={(e) => {regOrderCdArrHandler(e, mainList1.bank_no)}}
                                            value={initialState.regOrderCdArr[mainList1.bank_no]?initialState.regOrderCdArr[mainList1.bank_no]:mainList1.order_cd}
                                        >
                                            {Array(99).fill(0).map((_,i) =>(
                                                <option value={i+1}>{i+1}번째</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: "*%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                        <button style={{ backgroundColor: "lightskyblue", border: "1", color: "white" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                onClick={() => {updateRegState(mainList1.bank_no)}}
                                        >
                                            변경
                                        </button>
                                        &nbsp;
                                        <button style={{ backgroundColor: "red", border: "1", color: "white" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                onClick={() => {deleteRegState(mainList1.bank_no)}}
                                        >
                                            삭제
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>
                    <div style={{width:"2%"}}></div>
                    <div style={{width:"49%"}}>
                        <table className="commonT table table-bordered table-sm text-center">
                            <tr>
                                <td style={{width: "5%",backgroundColor: "lightgray", height:"30px"}} align={"center"}>
                                    <font color={"black"}><b>번호</b></font>
                                </td>
                                <td style={{width: "20%",backgroundColor: "lightgray", height:"30px"}} align={"center"}>
                                    <font color={"black"}><b>은행명</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "lightgray", height:"30px"}} align={"center"}>
                                    <font color={"black"}><b>사용여부</b></font>
                                </td>
                                <td style={{width: "5%",backgroundColor: "lightgray", height:"30px"}} align={"center"}>
                                    <font color={"black"}><b>순서</b></font>
                                </td>
                                <td style={{width: "15%",backgroundColor: "lightgray", height:"30px"}} align={"center"}>
                                </td>
                            </tr>
                            {!mainList2.length && (
                                <tbody key={mainList2.bank_no}>
                                <tr>
                                    <td colSpan="5">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}
                            {mainList2?.map((mainList2, index) => (
                                <tbody key={mainList2.bank_no}>
                                <tr>
                                    <td style={{width: "5%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                        <font color={"black"}><b>{mainList2.bank_no}</b></font>
                                    </td>
                                    <td style={{width: "24%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <select
                                            className="form-select sm-select"
                                            style={{
                                                width: "300px"
                                            }}
                                            onChange={(e) => {dangerBankCdArrHandler(e, mainList2.bank_no)}}
                                            value={initialState.dangerBankCdArr[mainList2.bank_no]?initialState.dangerBankCdArr[mainList2.bank_no]:mainList2.bank_cd}
                                        >
                                            {pntGbList?.map((item, index) => (
                                                <option key={item.cmmn_cd_dtl_id}
                                                        value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: "5%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                        <select
                                            className="form-select sm-select"
                                            style={{height: "25px", width:"70px"}}
                                            onChange={(e) => {dangerUseYnArrHandler(e, mainList2.bank_no)}}
                                            value={initialState.dangerUseYnArr[mainList2.bank_no]?initialState.dangerUseYnArr[mainList2.bank_no]:mainList2.use_yn}
                                        >
                                            <option value={"Y"}>사용</option>
                                            <option value={"N"}>미사용</option>
                                        </select>
                                    </td>
                                    <td style={{width: "5%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                        <select
                                            className="form-select sm-select"
                                            style={{height: "25px", width:"70px"}}
                                            onChange={(e) => {dangerOrderCdArrHandler(e, mainList2.bank_no)}}
                                            value={initialState.dangerOrderCdArr[mainList2.bank_no]?initialState.dangerOrderCdArr[mainList2.bank_no]:mainList2.order_cd}
                                        >
                                            {Array(99).fill(0).map((_,i) =>(
                                                <option value={i+1}>{i+1}번째</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: "*%",backgroundColor: "white", height:"30px"}} align={"center"}>
                                        <button style={{ backgroundColor: "lightskyblue", border: "1", color: "white" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                onClick={() => {updateDangerState(mainList2.bank_no)}}
                                        >
                                            변경
                                        </button>
                                        &nbsp;
                                        <button style={{ backgroundColor: "red", border: "1", color: "white" ,fontSize: "10px", height: "22px", width: "40px"}}
                                                onClick={() => {deleteDangerState(mainList2.bank_no)}}
                                        >
                                            삭제
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            ))}

                        </table>
                    </div>
                </div>

                <div style={{
                    position:"relative",
                    background:"#3c3c3d",
                    height:"30px",
                    alignItems:"center",
                    textAlign:"left",
                    alignContent:"center"
                }}>
                    <table style={{width: "99%"}}>
                        <tr>
                            <td style={{width: "50%"}} align={"left"}>
                                &nbsp;&nbsp;&nbsp;
                                <font color={"white"} size={1.5}><b> 회원가입 페이지</b></font>
                            </td>
                        </tr>
                    </table>
                </div>

                <table className="commonT table table-bordered table-sm text-center">
                    <tr>
                        <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>비밀번호 복잡도(* 영어 대문자, 특수문자, 숫자 포함 8자리 이상)</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                onChange={pwdComplicationYnHandler} value={pwdComplicationYn?pwdComplicationYn:regSetting.pwd_complication_yn}
                            >
                                <option value={'Y'}>사용</option>
                                <option value={'N'}>미사용</option>
                            </select>
                            &nbsp;  &nbsp;  &nbsp;
                            <b>추천인코드</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                onChange={referralCdYnHandler} value={referralCdYn?referralCdYn:regSetting.referral_cd_yn}
                                >
                                <option value={'Y'}>사용</option>
                                <option value={'N'}>미사용</option>
                            </select>
                            <b>SMS인증</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                onChange={smsAuthYnHandler} value={smsAuthYn?smsAuthYn:regSetting.sms_auth_yn}
                                >
                                <option value={'Y'}>사용</option>
                                <option value={'N'}>미사용</option>
                            </select>
                            &nbsp;  &nbsp;  &nbsp;
                            <b>통신사</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                onChange={agencyYnHandler} value={agencyYn?agencyYn:regSetting.agency_yn}
                                >
                                <option value={'Y'}>사용</option>
                                <option value={'N'}>미사용</option>
                            </select>
                            &nbsp;  &nbsp;  &nbsp;
                            <b>성별</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                onChange={genderYnHandler} value={genderYn?genderYn:regSetting.gender_yn}
                                >
                                <option value={'Y'}>사용</option>
                                <option value={'N'}>미사용</option>
                            </select>
                            &nbsp;  &nbsp;  &nbsp;
                            <b>생년월일</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                onChange={birthdtYnHandler} value={birthdtYn?birthdtYn:regSetting.birthdt_yn}
                                >
                                <option value={'Y'}>사용</option>
                                <option value={'N'}>미사용</option>
                            </select>
                        </td>
                    </tr>
                </table>


                <div style={{
                    position:"relative",
                    background:"#3c3c3d",
                    height:"30px",
                    alignItems:"center",
                    textAlign:"left",
                    alignContent:"center"
                }}>
                    <table style={{width: "99%"}}>
                        <tr>
                            <td style={{width: "50%"}} align={"left"}>
                                &nbsp;&nbsp;&nbsp;
                                <font color={"white"} size={1.5}><b> 중복 가입 체크</b></font>
                            </td>
                        </tr>
                    </table>
                </div>

                <table className="commonT table table-bordered table-sm text-center">
                    <tr>
                        <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>중복 계좌번호 가입</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                onChange={dupAccountYnHandler} value={dupAccountYn?dupAccountYn:regSetting.dup_account_yn}
                                >
                                <option value={'Y'}>사용</option>
                                <option value={'N'}>미사용</option>
                            </select>
                            &nbsp;&nbsp;&nbsp;
                            <b>중복 핸드폰 가입</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                onChange={dupPhoneYnHandler} value={dupPhoneYn?dupPhoneYn:regSetting.dup_phone_yn}
                                >
                                <option value={'Y'}>사용</option>
                                <option value={'N'}>미사용</option>
                            </select>
                            &nbsp;&nbsp;&nbsp;
                            <b>중복 IP 가입</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                onChange={dupIpYnHandler} value={dupIpYn?dupIpYn:regSetting.dup_ip_yn}
                                >
                                <option value={'Y'}>사용</option>
                                <option value={'N'}>미사용</option>
                            </select>
                            &nbsp;&nbsp;&nbsp;
                            <b>중복 IP 가입 가능 시간</b>
                            &nbsp;
                            <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                   placeholder={addComma(regSetting.dup_ip_reg_time)}
                                   value={dupIpRegTime}
                                   onChange={(e) => setDupIpRegTime(e.target.value)}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                분
                            </button>
                        </td>
                    </tr>
                </table>


                <div style={{
                    position:"relative",
                    background:"#3c3c3d",
                    height:"30px",
                    alignItems:"center",
                    textAlign:"left",
                    alignContent:"center"
                }}>
                    <table style={{width: "99%"}}>
                        <tr>
                            <td style={{width: "50%"}} align={"left"}>
                                &nbsp;&nbsp;&nbsp;
                                <font color={"white"} size={1.5}><b> 회원 가입 시</b></font>
                            </td>
                        </tr>
                    </table>
                </div>

                <table className="commonT table table-bordered table-sm text-center">
                    <tr>
                        <td style={{width: "100%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <b>회원상태</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                    onChange={memberStatCdHandler} value={memberStatCd?memberStatCd:regSetting.member_stat_cd}
                            >
                                <option value={'01'}>정상</option>
                                <option value={'02'}>정지</option>
                                <option value={'04'}>대기</option>
                            </select>
                            &nbsp;&nbsp;&nbsp;
                            <b>회원레벨</b>
                            &nbsp;
                            <select style={{height: "25px", width:"70px"}}
                                    onChange={memberLvlCdHandler} value={memberLvlCd?memberLvlCd:regSetting.member_lvl_cd}
                            >
                                <option value={'1'}>1</option>
                                <option value={'2'}>2</option>
                                <option value={'3'}>3</option>
                                <option value={'4'}>4</option>
                                <option value={'5'}>5</option>
                                <option value={'6'}>6</option>
                                <option value={'7'}>7</option>
                                <option value={'8'}>8</option>
                                <option value={'9'}>9</option>
                            </select>
                            &nbsp;&nbsp;&nbsp;
                            <b>지급포인트</b>
                            &nbsp;
                            <input className="form-check-input sm-input" type="text" style={{width:"50px"}}
                                   placeholder={addComma(regSetting.pay_point)}
                                   value={payPoint}
                                   onChange={(e) => setPayPoint(e.target.value)}
                            />
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "22px", width: "20px"}}
                            >
                                원
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white", height:"30px"}} align={"left"}>
                            <table style={{width:"50%"}}>
                                <tr>
                                    <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <font color={"black"}><b>쪽지1</b></font>
                                        &nbsp;&nbsp;
                                        <select style={{height: "25px", width:"70px"}}
                                                onChange={totalYnHandler} value={totalYn?totalYn:regSetting.total_yn}
                                        >
                                            <option value={'Y'}>사용</option>
                                            <option value={'N'}>미사용</option>
                                        </select>
                                    </td>
                                    <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    </td>
                                    <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <font color={"black"}><b>쪽지2</b></font>
                                        &nbsp;&nbsp;
                                        <select style={{height: "25px", width:"70px"}}
                                                onChange={memberYnHandler} value={memberYn?memberYn:regSetting.member_yn}
                                        >
                                            <option value={'Y'}>사용</option>
                                            <option value={'N'}>미사용</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <input className="form-check-input sm-input" type="text"
                                               placeholder={regSetting.total_title}
                                               value={totalTitle}
                                               onChange={(e) => setTotalTitle(e.target.value)}
                                        />
                                    </td>
                                    <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    </td>
                                    <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <input className="form-check-input sm-input" type="text"
                                               placeholder={regSetting.member_title}
                                               value={memberTitle}
                                               onChange={(e) => setMemberTitle(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                        <textarea onChange={(e)=> setTotalCnt(e.target.value)}
                                                  placeholder={regSetting.total_cnt}
                                                  value={totalCnt}
                                                  name="textarea_name" cols="70" rows="6"/>
                                    </td>
                                    <td style={{width: "2%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                    </td>
                                    <td style={{width: "32%",backgroundColor: "white", height:"30px"}} align={"left"}>
                                       <textarea onChange={(e)=> setMemberCnt(e.target.value)}
                                                 placeholder={regSetting.member_cnt}
                                                 value={memberCnt}
                                                 name="textarea_name" cols="70" rows="6"/>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td align={"center"}>
                            <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                                    onClick={() => {updateState()}}
                            >
                                변경
                            </button>
                        </td>
                    </tr>
                </table>

            </div>
        </div>

    )
}

export default RegSetting;