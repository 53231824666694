import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar, Line} from 'react-chartjs-2';

const LineChart = ({title}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
        },
    };
    const labels = [
        '~01시',
        '~02시',
        '~03시',
        '~04시',
        '~05시',
        '~06시',
        '~07시',
        '~08시',
        '~09시',
        '~10시',
        '~11시',
        '~12시',
        '~13시',
        '~14시',
        '~15시',
        '~16시',
        '~17시',
        '~18시',
        '~19시',
        '~20시',
        '~21시',
        '~22시',
        '~23시',
        '~24시',
    ];

    const data = {
        labels,
        datasets: [
            {
                label: '오늘',
                data: [1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 4, 3, 2, 3, 4, 5, 6],
                borderColor: 'rgb(255, 99, 132)', //그래프 선 color
                backgroundColor: 'rgba(255, 99, 132, 0.5)', //마우스 호버시 나타나는 분류네모 표시 bg
            },
            {
                label: '지난 일주일 평균',
                data: [2, 3, 4, 5, 4, 7, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return (
        <div className='contentWrap' style={{width: "800px", height: "200px"}}>
            <div className='contentInner'>
                <Line options={options} data={data} />
            </div>
        </div>
    );


}

export default LineChart