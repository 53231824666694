import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    totalList : [],
    totalCount : 0,
    gridList : [],
    gridCount : 0,
    input:{
        page: 1,
        pageSize: 20,
    }
}

export const statsSlice = createSlice({
    name: 'PartnerSingleReducer',
    initialState,
    reducers: {
        getPartnerSingleReducer(state, action){
            console.log("#reducers getPartnerSingleReducer start#",
                action.payload.input.pageSize,
                action.payload.input.page)
            ;
            state.input.page = action.payload.input.pageSize === 999? 1: action.payload.input.page;
            state.input.pageSize = action.payload.input.pageSize;
        },
        setPartnerSingleReducer(state, action) {
            console.log("#reducers setPartnerSingleReducer start#",action.payload.gridList);
            if((state.input.page -1) * state.input.pageSize > action.payload.totalCount) {
                state.input.page = 1
            }
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.gridCount = action.payload.gridCount;
            state.gridList = action.payload.gridList;
        },
        failedGetPartnerSingleReducer(state, action) {
            state.blackTypeCd = action.payload;
        },
    }
});

export const {
    getPartnerSingleReducer,
    setPartnerSingleReducer,
    failedGetPartnerSingleReducer,
} = statsSlice.actions;

export default statsSlice.reducer;