import LeagueList from "../../../components/game/setting/LeagueList";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import Switch from "react-switch";
import axios from "axios";
import {
    changeIsLoading,
    getLeagues,
    getNations,
    setSelectedKind,
    setSelectedNation
} from "../../../redux/reducers/game/gameSetting";
import Pagination from "../../../components/common/Pagination";


const LeagueContainer = () => {
    const state = useSelector((state) => state.gameSetting);
    const dispatch = useDispatch();
    const [addList, setAddList] = useState([])
    const [useCode, setUseCode] = useState(999)
    const [searchQuery, setSearchQuery] = useState("")

    const input = {
        page: state.page3,
        pageSize: state.pageSize3,
        nationName: state.selectedNationName,
        gameName: state.selectedKindName,
        query: searchQuery,
        useCode: useCode,
    }

    const onRefresh = () => {
        dispatch(getLeagues(input))
    }

    const insertLeague = async (item) => {
        dispatch(changeIsLoading(true))
        item.nation_id = state.selectedNation
        await axios.post('/admin/game/setting/insertLeague', {
            leagueItem: item
        }).then((res) => {
            dispatch(changeIsLoading(false))
            if (res.data.isInserted) {
                alert('등록완료')
                const arr = addList.filter((el) => el.instantId !== item.instantId)
                setAddList(arr)
                onRefresh()
            } else {
                alert('등록실패')
            }
        })
    }

    const insertAllLeague = () => {
        dispatch(changeIsLoading(true))
        axios.post('/admin/game/setting/insertAllLeague', {
            list: addList,
            nationNo: state.selectedNation
        }).then((res) => {
            dispatch(changeIsLoading(false))
            if(res.data.isInserted) {
                alert("일괄등록완료")
                setAddList([])
                onRefresh()
            }
            else {
                alert('등록실패')
            }
        })
    }
    const getLeaguesFromMatchServer = () => {
        axios.post('/admin/game/setting/getLeaguesFromMatchServer', {

        }).then((res) => {
            if(res.data.inserted) {
                alert("완료")
                onRefresh()
            }
            else {
                alert('등록실패')
            }
        })
    }

    //TODO 리그 사용시 해당리그 땡겨오기, 리그 미사용시 바로 파싱오프기능.
    const changeUseCode = (e) => {
        setUseCode(e.currentTarget.value)
        const fixInput = {...input}
        fixInput.useCode = e.currentTarget.value
        dispatch(getLeagues(fixInput))
    }
    const changeQuery = (e) => {
        setSearchQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.currentTarget.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.query = query
        dispatch(getLeagues(fixInput))
    }
    const handlePageChange = (page) => {
        const fixInput = {...input}
        fixInput.page = page
        dispatch(getLeagues(fixInput))
    }

    return <div className="common">
        <p>선택국가: {state.selectedNationName}</p>
        <div className="d-flex">
            <div className="d-flex w-50">
                <select className="form-select" onChange={changeUseCode} value={useCode}>
                    <option value={999}>사용여부전체</option>
                    <option value={1}>사용</option>
                    <option value={0}>미사용</option>
                </select>
                <input style={{
                    width: "100px"
                }} type="text" className="form-control" placeholder="종목명" value={state.selectedKindName} onChange={(e) => {
                    dispatch(setSelectedKind({name: e.currentTarget.value}))
                }} onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        dispatch(getLeagues(input))
                    }
                }} />
                <input style={{
                    width: "100px"
                }} type="text" className="form-control" placeholder="국가명" value={state.selectedNationName} onChange={(e) => {
                    dispatch(setSelectedNation({name: e.currentTarget.value}))
                }} onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        dispatch(getLeagues(input))
                    }
                }} />
                <input style={{
                    width: "100px"
                }} type="text" className="form-control" id="search_edit" placeholder="리그명" value={searchQuery} onChange={changeQuery} onKeyDown={inputPress} />
                <button style={{
                    width: "80px", borderTopRightRadius: "5px" ,borderBottomRightRadius: "5px"
                }} type="submit" className="btn btn-primary" onClick={(e) => {
                    onSearch(searchQuery)
                }}>검색</button>
            </div>
            <div className="d-flex justify-content-end mb-1 w-50">

                <button className="btn btn-secondary" onClick={(e) => {
                    const arr = [...addList]
                    arr.push({
                        instantId: new Date().getTime(),
                        name_kor: "",
                        is_use: false
                    })
                    setAddList(arr)
                }}>추가</button>
                <button className="btn btn-primary mx-1" onClick={(e) => {
                    onRefresh()
                }}>새로고침</button>
                <button className="btn btn-success" onClick={(e) => {
                    getLeaguesFromMatchServer()
                }}>가져오기</button>
            </div>

        </div>
        {addList.length > 0 && (
            <div>
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-secondary">
                    <tr>
                        <td colSpan="4">
                            리그신규등록
                        </td>
                    </tr>
                    <tr>
                        <th>아이콘</th>
                        <th>리그</th>
                        <th>사용여부</th>
                        <th>
                            <button className="btn btn-secondary mb-1" onClick={(e) => {
                                insertAllLeague()
                            }}>일괄저장</button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {addList.map((item, index) => (
                        <tr>
                            <td>
                                <div className="d-flex align-items-center me-1">
                                    <img src="/icon/2641.png" width="20px" height="20px"/>
                                    <input className="form-control form-control-sm" type="file"/>
                                </div>
                            </td>
                            <td>
                                <input className="form-control form-control-sm me-1" type="text" value={item.name_kor} style={{width: "130px"}} placeholder="리그"
                                       onChange={(e) => {
                                           item.name_kor = e.currentTarget.value
                                           const arr = [...addList]
                                           arr[index] = item
                                           setAddList(arr)
                                       }}/>
                            </td>
                            <td>
                                <Switch onChange={(checked) => {
                                    item.is_use = checked
                                    const arr = [...addList]
                                    arr[index] = item
                                    setAddList(arr)
                                }} checked={item.is_use} onColor="#2196F3"/>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <button className="btn btn-secondary me-1" onClick={(e) => {
                                        insertLeague(item)
                                    }}>저장</button>
                                    <button className="btn btn-warning ms-1" onClick={(e) => {

                                        const arr = addList.filter((el) => el.instantId !== item.instantId)
                                        const arr2 = [...arr]
                                        setAddList(arr2)
                                    }}>삭제</button>
                                </div>

                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

        )}

        <LeagueList state={state} onRefresh={onRefresh}/>
        <Pagination totalCount={state.totalCount3} pageSize={state.pageSize3} currentPage={state.page3} onPageChange={handlePageChange}/>
    </div>
}

export default LeagueContainer