import React from 'react';
import SiteSettingContainer from "../../containers/setting/SiteSettingContainer";


const SiteSetting = () => {
    return (
        <div>
            <SiteSettingContainer />
        </div>
    );
};

export default SiteSetting;