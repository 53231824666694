import {call, put} from "redux-saga/effects";
import {failedGeLevelSetting, setLevelSetting} from "../../reducers/setting/LevelSettingSingleReducer";
import {fetchLevelSettingSingle} from "../../../lib/api";

export function* getLevelSettingSingleSaga(action) {
    try{
        console.log("##getLevelSettingSingleSaga input#",action.payload.input)
        const res = yield call(fetchLevelSettingSingle,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd
        );
        console.log("##getLevelSettingSingleSaga output#",res.data)
        yield put(setLevelSetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGeLevelSetting(error))
    }
}