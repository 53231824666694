import React, {useEffect, useState} from 'react';
import axios from "axios";
import PlusMinusText from "../../components/common/PlusMinusText";
import moment from "moment";
import 'moment/locale/ko'
import {useSelector} from "react-redux";

const UserPage = () => {

    const userInfoId = useSelector((state) => state.userInfo.id);

    const [loginInfo, setLoginInfo] = useState([])
    const [moneyInfo, setMoneyInfo] = useState(null)
    const [totalMoneyInfo, setTotalMoneyInfo] = useState([])
    const [totalInfo, setTotalInfo] = useState(null)
    const [moneyDate, setMoneyDate] = useState("")
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
    const [startDate, setStartDate] = useState(moment().add(-9, "days").format("YYYY-MM-DD"))
    const [searchTime, setSearchTime] = useState(moment().format("YYYY-MM-DD HH:mm:ss"))

    useEffect(() => {
        getLoginInfo()
        getMoneyInfo()
        getTotalMoneyInfo()
    }, [])

    const getLoginInfo = () => {
        axios.post('/admin/home/getLoginInfo', {
            id: userInfoId
        }).then((res) => {
            setLoginInfo(res.data.result)
        })
    }

    const getMoneyInfo = () => {
        setMoneyDate(moment().format("YY/MM/DD HH:mm"))
        axios.post('/admin/home/getMoneyInfo', {

        }).then((res) => {
            setMoneyInfo(res.data.result)
        })
    }
    const getTotalMoneyInfo = (start, end) => {
        axios.post('/admin/home/getTotalMoneyInfo', {
            startDate: start? start : startDate,
            endDate: end? end : endDate,
        }).then((res) => {
            setTotalMoneyInfo(res.data.infoList)
            setTotalInfo(res.data.totalInfo)
            setSearchTime(moment().format("YYYY-MM-DD HH:mm:ss"))
        })
    }

    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <br></br>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <b><font color={"black"} size="3">접속 정보</font></b>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table   width={"60%"}>
                        <tr>
                            <td align={"left"}>
                                * 로그인 횟수를 기반으로 1회원당 최대 1일당 모바일 1회/피씨 1회씩 카운트 합니다. 모바일만 로그인 했다면 모바일 1회, 피씨버전만 로그인 했다면 피시 1회, 둘 다 했다면 둘 다 1회씩 카운트 됩니다.
                            </td>
                        </tr>
                    </table>
                    <table   width={"39%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>도메인별 접속 아이피수 체크</button>
                                <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>메모장 켜기</button>
                            </td>
                        </tr>
                    </table>

                </div>


                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                </div>

            </div>
            <div style={{
                paddingLeft: "1px"
            }}>
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-dark">
                    <tr>

                    </tr>
                    </thead>
                </table>
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-dark">
                    <tr>
                        <th style={{width: "*%"}}>기간</th>
                        <th style={{width: "6.5%"}}>오늘</th>
                        <th style={{width: "6.5%"}}>어제</th>
                        <th style={{width: "6.5%"}}>2일전</th>
                        <th style={{width: "6.5%"}}>3일전</th>
                        <th style={{width: "6.5%"}}>4일전</th>
                        <th style={{width: "6.5%"}}>5일전</th>
                        <th style={{width: "6.5%"}}>6일전</th>
                        <th style={{width: "6.5%"}}>7일전</th>
                        <th style={{width: "6.5%"}}>총 10일</th>
                        <th style={{width: "6.5%"}}>총 30일</th>
                        <th style={{width: "6.5%"}}>총 60일</th>
                        <th style={{width: "6.5%"}}>총 90일</th>
                        <th style={{width: "6.5%"}}>총 120일</th>
                        <th style={{width: "6.5%"}}>총 180일</th>
                    </tr>
                    </thead>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"}>모바일</td>
                        {loginInfo?.map((item, index) => (
                            <td align={"center"} >
                                <font color="blue">{item.mobile_count}</font>
                                명 ({item.mobile_count === 0? 0 : (item.mobile_count / item.id_count).toFixed(2) * 100}%)
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"}>피씨</td>
                        {loginInfo?.map((item, index) => (
                            <td align={"center"} >
                                <font color="blue">{item.pc_count}</font>
                                명 ({item.pc_count === 0? 0 : (item.pc_count / item.id_count).toFixed(2) * 100}%)
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td style={{backgroundColor:"lightgray"}} align={"center"}>전체<br></br>(중복횟수제거)</td>
                        {loginInfo?.map((item, index) => (
                            index < 8 ? (
                                <td align={"center"} >
                                    <font color="blue">{item.id_count}</font>
                                    명</td>
                            ) : (
                                <td align={"center"} >
                                    <font color="blue">{item.id_count}</font>명<br></br>
                                    일 평균 <font color="blue">{item.avg_count}</font>명
                                </td>
                            )
                        ))}
                    </tr>
                </table>
            </div>

            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    * <b>자산 정보</b> (충전과 환전은 처리일 기준) : 조회 기준일시 : {moneyDate}
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="table table-bordered table-sm text-center align-middle" >
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"} >회원보유 게임머니<br></br>(유료회원/무료회원)</td>
                            <td style={{backgroundColor:"white"}} align={"center"} width={"23%"} >
                                <b><PlusMinusText number={moneyInfo?.game_money}/></b> / <PlusMinusText number={moneyInfo?.free_game_money}/>
                            </td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"} >회원보유 포인트<br></br>(유료회원/무료회원)</td>
                            <td style={{backgroundColor:"white"}} align={"center"} width={"23%"} >
                                <b><PlusMinusText number={moneyInfo?.point} /></b> / <PlusMinusText number={moneyInfo?.free_point} />
                            </td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"} >진행중인 베팅금</td>
                            <td style={{backgroundColor:"white"}} align={"center"} width={"23%"} >
                                <b><PlusMinusText number={moneyInfo?.bet_money} /></b> / <PlusMinusText number={moneyInfo?.bet_count} /> 건
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"} >Kgon알</td>
                            <td style={{backgroundColor:"white"}} align={"center"} width={"23%"} >
                                {/*TODO 카지노 붙이면 가져와야함*/}
                                <b><PlusMinusText number={97312772} /></b>
                            </td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"} >Kgon회원보유</td>
                            <td style={{backgroundColor:"white"}} align={"center"} width={"23%"} >
                                <b><PlusMinusText number={moneyInfo?.kg_money}/></b>
                            </td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"} >환전대기중 금액</td>
                            <td style={{backgroundColor:"white"}} align={"center"} width={"23%"} >
                                <b><PlusMinusText number={moneyInfo?.withdraw_total} /></b> / <font color="blue">{moneyInfo?.withdraw_count} </font>건
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div style={{paddingLeft: "1px"}}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color="blue"><b>자동게임 관련 현황</b></font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    * 사용중인 자동게임의 타사 운영 현황입니다. 타사 가동률이 낮을 경우, 유출픽 등으로 인한 운영 중단될 수 있으므로 확인 후 중지/시작 결정을 하시면 됩니다.
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    * 전체 가동률이 60%미만인데 가동중인 경우 빨갛게 표시가 됩니다.
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{ border: "solid red", width:"120px", height:"100px"}} align={"center"}>
                        <font color="red">뱃이스트 야구</font>
                        <br></br>
                        <a href="" >[공식사이트]</a>
                        <br></br>
                        <font color="red">46% 가동중</font>
                        <br></br>
                        <font color="red">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid red", width:"120px", height:"100px"}} align={"center"}>
                        <font color="red">뱃이스트 농구</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="red">38% 가동중</font>
                        <br></br>
                        <font color="red">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid red", width:"140px", height:"100px"}} align={"center"}>
                        <font color="red">뱃이스트 크리켓</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="red">46% 가동중</font>
                        <br></br>
                        <font color="red">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid red", width:"140px", height:"100px"}} align={"center"}>
                        <font color="red">뱃이스트 다이스</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="red">50% 가동중</font>
                        <br></br>
                        <font color="red">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid red", width:"140px", height:"100px"}} align={"center"}>
                        <font color="red">뱃이스트 축구</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="red">48% 가동중</font>
                        <br></br>
                        <font color="red">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">비트코인 1분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">58% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">비트코인 3분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">75% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">달다리</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">100% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">별다리 1분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">90% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">보글사다리</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">77% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">보글파워볼</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">77% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"150px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">COIN파월볼 3분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">86% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"150px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">COIN파월볼 5분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">80% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"140px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">크라운 하이로우</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">100% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">크라운 섯다</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">100% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">EOS파워볼 1분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">69% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">EOS파워볼 2분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">67% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid red", width:"120px", height:"100px"}} align={"center"}>
                        <font color="red">EOS파워볼 3분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="red">55% 가동중</font>
                        <br></br>
                        <font color="red">[가동중]</font>
                    </div>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{ border: "solid red", width:"120px", height:"100px"}} align={"center"}>
                        <font color="red">EOS파워볼 3분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="red">56% 가동중</font>
                        <br></br>
                        <font color="red">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">EOS파워볼 5분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">66% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">천사악마</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">80% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">과일룰렛</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">89% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">슈퍼마리오</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">80% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">타조</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">80% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">키노사다리</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">39% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"135px", height:"100px"}} align={"center"}>
                        <font color="green">로투스 바카라1</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">81% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">로투스 홀짝</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">80% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">사다리</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">60% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid red", width:"120px", height:"100px"}} align={"center"}>
                        <font color="red">다리다리</font>
                        <br></br>
                        <a href="" >[공식사이트]</a>
                        <br></br>
                        <font color="red">53% 가동중</font>
                        <br></br>
                        <font color="red">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"180px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">네임트 N파워사다리 3분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">55% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"180px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">네임트 N파워사다리 5분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">75% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"170px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">네임트 N파워사다리 3분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">75% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"170px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">네임트 N파워사다리 5분</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">57% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">파워사다리</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">26% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">넥스트 바카라</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">83% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">넥스트 사다리</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">76% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">넥스트 파워볼</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">81% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">온게임존 다트</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">89% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">온게임존 나인</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">86% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"130px", height:"100px"}} align={"center"}>
                        <font color="green">온게임존 파라오</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">86% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"130px", height:"100px"}} align={"center"}>
                        <font color="green">온게임존 해와달</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">83% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">파워볼</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">26% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">파워프리킥</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">69% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">프리즌 사다리</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">100% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">skp 바키라</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">69% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">skp 블랙잭</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">86% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">skp 주사위</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">64% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"170px", height:"100px"}} align={"center"}>
                        <font color="green">skp 드래곤타이거</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">100% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">skp 홀짝</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">73% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">skp 포커</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">71% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">skp 룰렛</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">100% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">skp 삼치기</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">75% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"140px", height:"100px"}} align={"center"}>
                        <font color="green">skp 스피드바카라</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">69% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">스카이 사다리</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">100% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">스카이 룰렛</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">100% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">황금돼지</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">94% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">라이언무지</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">76% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">라이언무지</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">76% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px", backgroundColor:"lightgray"}} align={"center"}>
                        <font color="black">스피드키노</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="black">46% 가동중</font>
                        <br></br>
                        <font color="black">[중지]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">스피드홈런</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">69% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                    <div style={{ border: "solid gray", width:"120px", height:"100px"}} align={"center"}>
                        <font color="green">썬시티</font>
                        <br></br>
                        [공식사이트]
                        <br></br>
                        <font color="green">95% 가동중</font>
                        <br></br>
                        <font color="green">[가동중]</font>
                    </div>
                    &nbsp;
                </div>


            </div>

            <br></br><br></br><br></br>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table   width={"60%"}>
                    <tr>
                        <td align={"left"}>
                            최근 {totalInfo?.days}일 충전 현황 (본사누적 : <PlusMinusText number={totalInfo?.depositB} />원, 파트너사 누적 : <PlusMinusText number={totalInfo?.depositP}/>원, 전체 누적 : <PlusMinusText number={(totalInfo?.depositB + totalInfo?.depositP)} />원)
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            최근 {totalInfo?.days}일 환전 현황 (본사누적 : <PlusMinusText number={totalInfo?.withdrawB}/>원, 파트너사 누적 : <PlusMinusText number={totalInfo?.withdrawP}/>원, 전체 누적 : <PlusMinusText number={(totalInfo?.withdrawB + totalInfo?.withdrawP)}/>원)
                        </td>
                    </tr>
                    <tr>
                        <td align={"left"}>
                            최근 {totalInfo?.days}일 수익 현황 (본사누적 : <PlusMinusText number={totalInfo?.marginB}/>원, 파트너사 누적 : <PlusMinusText number={totalInfo?.marginP}/>원, 전체 누적 : <PlusMinusText number={(totalInfo?.marginB + totalInfo?.marginP)}/>원)
                        </td>
                    </tr>
                </table>
                <table   width={"39%"}>
                    <tr>
                        <td align="right">{searchTime}</td>
                    </tr>
                    <tr>
                        <td align={"right"}>
                            <button className="greenBtn" onClick={(e) => {
                                getTotalMoneyInfo(
                                    moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
                                    moment().format("YYYY-MM-DD HH:mm:ss"),
                                )
                            }}>이번 달</button>
                            <button className="greenBtn" onClick={(e) => {
                                getTotalMoneyInfo(
                                    moment().add(-1, "month").startOf("month").format("YYYY-MM-DD 00:00:00"),
                                    moment().add(-1, "month").endOf("month").format("YYYY-MM-DD 23:59:59"),
                                )
                            }}>1달전</button>
                            <button className="greenBtn" onClick={(e) => {
                                getTotalMoneyInfo(
                                    moment().add(-2, "month").startOf("month").format("YYYY-MM-DD 00:00:00"),
                                    moment().add(-1, "month").endOf("month").format("YYYY-MM-DD 23:59:59")
                                )
                            }}>2달전</button>
                            <button className="greenBtn" onClick={(e) => {
                                getTotalMoneyInfo(
                                    moment().add(-3, "month").startOf("month").format("YYYY-MM-DD 00:00:00"),
                                    moment().add(-1, "month").endOf("month").format("YYYY-MM-DD 23:59:59")
                                )
                            }}>3달전</button>
                            &nbsp;&nbsp;
                            <button className="greenBtn" onClick={(e) => {
                                getTotalMoneyInfo(
                                    moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
                                    moment().format("YYYY-MM-10 23:59:59")
                                )
                            }}>1~10</button>
                            <button className="greenBtn" onClick={(e) => {
                                getTotalMoneyInfo(
                                    moment().format("YYYY-MM-11 00:00:00"),
                                    moment().format("YYYY-MM-20 23:59:59")
                                )
                            }}>11~20</button>
                            <button className="greenBtn" onClick={(e) => {
                                getTotalMoneyInfo(
                                    moment().format("YYYY-MM-21 00:00:00"),
                                    moment().endOf("month").format("YYYY-MM-DD 23:59:59")
                                )
                            }}>21~말일</button>
                            <button className="greenBtn" onClick={(e) => {
                                getTotalMoneyInfo(
                                    moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
                                    moment().format("YYYY-MM-15 23:59:59")
                                )
                            }}>1~15</button>
                            <button className="greenBtn" onClick={(e) => {
                                getTotalMoneyInfo(
                                    moment().format("YYYY-MM-16 00:00:00"),
                                    moment().endOf("month").format("YYYY-MM-DD 23:59:59")
                                )
                            }}>16~말일</button>
                        </td>
                    </tr>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-secondary">
                    <tr>
                        <th style={{width: "5%"}} rowspan="2">날짜</th>
                        <th style={{width: "25%"}} colspan="5">본사</th>
                        <th style={{width: "30%"}} colspan="5">파트너</th>
                        <th style={{width: "30%"}} colspan="5">토탈</th>
                    </tr>
                    <tr>
                        <th>충전(<PlusMinusText number={totalInfo?.depositB}/>)<br></br>평균 <PlusMinusText number={
                            Math.floor(totalInfo?.depositCountB === 0 ? 0 : totalInfo?.depositCountB / totalInfo?.days)}/>건</th>
                        <th>환전(<PlusMinusText number={totalInfo?.withdrawB}/>)<br></br>평균 <PlusMinusText number={
                            Math.floor(totalInfo?.withdrawCountB === 0 ? 0 : totalInfo?.withdrawCountB / totalInfo?.days)}/>건</th>
                        <th>수익(<PlusMinusText number={totalInfo?.marginB}/>)<br></br>평균 <PlusMinusText number={
                            totalInfo?.marginB === 0 ? 0 : totalInfo?.marginB / totalInfo?.days} /></th>
                        <th>가입/삭제/가입벳/실벳</th>
                        <th>입id/환id</th>
                        <th>충전(<PlusMinusText number={totalInfo?.depositP}/>)<br></br>평균 <PlusMinusText number={
                            Math.floor(totalInfo?.depositCountP === 0 ? 0 : totalInfo?.depositCountP / totalInfo?.days)}/>건</th>
                        <th>환전(<PlusMinusText number={totalInfo?.withdrawP}/>)<br></br>평균 <PlusMinusText number={
                            Math.floor(totalInfo?.withdrawCountP === 0 ? 0 : totalInfo?.withdrawCountP / totalInfo?.days)}/>건</th>
                        <th>수익(<PlusMinusText number={totalInfo?.marginP}/>)<br></br>평균 <PlusMinusText number={Math.floor(totalInfo?.marginP === 0 ? 0 : totalInfo?.marginP / totalInfo?.days)} /></th>
                        <th>가입/삭제/가입벳/실벳</th>
                        <th>입id/환id</th>
                        <th>충전(<PlusMinusText number={totalInfo?.depositB + totalInfo?.depositP}/>)<br></br>평균 <PlusMinusText number={
                            Math.floor(totalInfo?.depositCountB + totalInfo?.depositCountP === 0 ? 0 : (totalInfo?.depositCountB + totalInfo?.depositCountP) / totalInfo?.days)}/>건</th>
                        <th>환전(<PlusMinusText number={totalInfo?.withdrawB + totalInfo?.withdrawP}/>)<br></br>평균 <PlusMinusText number={
                            Math.floor(totalInfo?.withdrawCountB + totalInfo?.withdrawCountP === 0 ? 0 : (totalInfo?.withdrawCountB + totalInfo?.withdrawCountP) / totalInfo?.days)}/>건</th>
                        <th>수익(<PlusMinusText number={totalInfo?.marginB + totalInfo?.marginP}/>)<br></br>평균 <PlusMinusText number={
                            Math.floor(totalInfo?.marginB + totalInfo?.marginP === 0?
                                0 : (totalInfo?.marginB + totalInfo?.marginP) / totalInfo?.days)} /></th>
                        <th>가입/삭제/가입벳/실벳</th>
                        <th>입id/환id</th>
                    </tr>
                    </thead>
                    <tbody>
                    {totalMoneyInfo.map((item, index) => (
                        <tr>
                            <td align={"center"} ><font color="black">{moment(item.searchDate).locale('ko').format("MM/DD(ddd)")}</font></td>
                            <td align={"center"} ><PlusMinusText number={item.depositTotalB} /> / {item.depositDayCountB}건</td>
                            <td align={"center"} ><PlusMinusText number={item.withdrawTotalB} /> / {item.withdrawDayCountB}건</td>
                            <td style={{backgroundColor:"yellow"}} align={"center"}><PlusMinusText number={item.marginB}/></td>
                            <td style={{backgroundColor:"lightgreen"}} align={"center"}>
                                <PlusMinusText number={item.joinCountB}/> / <PlusMinusText number={item.deleteCountB}/> / <PlusMinusText number={item.regBetCountB}/> / <PlusMinusText number={item.betCountB}/>
                            </td>
                            <td style={{backgroundColor:"lightgreen"}} align={"center"}><PlusMinusText number={item.depositIdCountB}/> / <PlusMinusText number={item.withdrawIdCountB}/></td>

                            <td align={"center"} ><PlusMinusText number={item.depositTotalP} /> / {item.depositDayCountP}건</td>
                            <td align={"center"} ><PlusMinusText number={item.withdrawTotalP} /> / {item.withdrawDayCountP}건</td>
                            <td style={{backgroundColor:"yellow"}} align={"center"}><PlusMinusText number={item.marginP}/></td>
                            <td style={{backgroundColor:"lightgreen"}} align={"center"}>
                                <PlusMinusText number={item.joinCountP}/> / <PlusMinusText number={item.deleteCountP}/> / <PlusMinusText number={item.regBetCountP}/> / <PlusMinusText number={item.betCountP}/>
                            </td>
                            <td style={{backgroundColor:"lightgreen"}} align={"center"}><PlusMinusText number={item.depositIdCountP}/> / <PlusMinusText number={item.withdrawIdCountP}/></td>

                            <td align={"center"} ><PlusMinusText number={item.depositTotalB + item.depositTotalP} /> / {item.depositDayCountB + item.depositDayCountP}건</td>
                            <td align={"center"} ><PlusMinusText number={item.withdrawTotalB + item.withdrawTotalP} /> / {item.withdrawDayCountB + item.withdrawDayCountP}건</td>
                            <td style={{backgroundColor:"yellow"}} align={"center"}><PlusMinusText number={item.marginB + item.marginP}/></td>
                            <td style={{backgroundColor:"lightgreen"}} align={"center"}>
                                <PlusMinusText number={item.joinCountB + item.joinCountP}/> / <PlusMinusText number={item.deleteCountB + item.deleteCountP}/> / <PlusMinusText number={item.regBetCountB + item.regBetCountP}/> / <PlusMinusText number={item.betCountB + item.betCountP}/>
                            </td>
                            <td style={{backgroundColor:"lightgreen"}} align={"center"}><PlusMinusText number={item.depositIdCountB + item.depositIdCountP}/> / <PlusMinusText number={item.withdrawIdCountB + item.withdrawIdCountP}/></td>

                        </tr>
                    ))}
                    <tr>
                        <td style={{backgroundColor:"yellow"}} align={"center"}>총 {totalInfo?.days}일</td>
                        <td style={{backgroundColor:"yellow"}} align={"center"} colSpan="5">환전율 : {
                            totalInfo?.withdrawB > 0? ((totalInfo?.withdrawB / totalInfo?.depositB) * 100).toFixed(2) : 0.00
                        }%</td>
                        <td style={{backgroundColor: "yellow"}} align={"center"} colSpan="5">환전율 : {
                            totalInfo?.withdrawP > 0? ((totalInfo?.withdrawP / totalInfo?.depositP) * 100).toFixed(2) : 0.00
                        }%</td>
                        <td style={{backgroundColor: "yellow"}} align={"center"} colSpan="5">환전율 : {
                            totalInfo?.withdrawB + totalInfo?.withdrawP > 0?
                                (((totalInfo?.withdrawB + totalInfo?.withdrawP) / (totalInfo?.depositB + totalInfo?.depositP)) * 100).toFixed(2) : 0.00
                        }%</td>
                    </tr>
                    </tbody>

                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="table table-bordered table-sm text-center align-middle" >
                    <thead className="table-gray">
                    <tr>
                        <th style={{width: "5%"}} rowSpan="3" style={{backgroundColor:"lightgray"}}>날짜</th>
                        <th style={{width: "25%"}} colSpan="10" style={{backgroundColor:"lightgray"}}>본사</th>
                        <th style={{width: "30%"}} colSpan="10" style={{backgroundColor:"lightgray"}}>파트너</th>
                        <th style={{width: "30%"}} colSpan="10" style={{backgroundColor:"lightgray"}}>토탈</th>
                    </tr>
                    <tr>
                        <th colspan="2" style={{backgroundColor:"lightgray"}}>일반베팅</th>
                        <th colSpan="2" style={{backgroundColor:"green"}} >Kgon카지노</th>
                        <th colSpan="2" style={{backgroundColor:"pink"}} >인플레이</th>
                        <th colSpan="2" style={{backgroundColor:"yellow"}} ><font color="black">토큰</font></th>
                        <th rowSpan="2" style={{backgroundColor:"lightgray"}}>포인트지급<font color="blue">0</font></th>
                        <th rowSpan="2" style={{backgroundColor:"lightgray"}}>총마진<font color="blue">0</font></th>
                        <th colSpan="2" style={{backgroundColor:"lightgray"}}>일반베팅</th>
                        <th colSpan="2" style={{backgroundColor: "green"}}>Kgon카지노</th>
                        <th colSpan="2" style={{backgroundColor: "pink"}}>인플레이</th>
                        <th colSpan="2" style={{backgroundColor: "yellow"}}><font color="black">토큰</font></th>
                        <th rowSpan="2" style={{backgroundColor:"lightgray"}}>포인트지급<br></br><font color="red">-394,479,885</font></th>
                        <th rowSpan="2" style={{backgroundColor:"lightgray"}}>총마진<br></br><font color="blue">714,631,090</font></th>
                        <th colSpan="2" style={{backgroundColor:"lightgray"}}>일반베팅</th>
                        <th colSpan="2" style={{backgroundColor: "green"}}>Kgon카지노</th>
                        <th colSpan="2" style={{backgroundColor: "pink"}}>인플레이</th>
                        <th colSpan="2" style={{backgroundColor: "yellow"}}><font color="black">토큰</font></th>
                        <th rowSpan="2" style={{backgroundColor:"lightgray"}}>포인트지급<br></br><font color="red">-394,479,885</font></th>
                        <th rowSpan="2" style={{backgroundColor:"lightgray"}}>총마진<br></br><font color="blue">714,631,090</font></th>
                    </tr>
                    <tr>
                        <th style={{backgroundColor:"lightgray"}}>베팅<font color="blue">0</font></th>
                        <th style={{backgroundColor:"lightgray"}}>마진<font color="blue">0</font></th>
                        <th style={{backgroundColor:"green"}}>베팅<font color="blue">0</font></th>
                        <th style={{backgroundColor:"green"}}>마진<font color="blue">0</font></th>
                        <th style={{backgroundColor:"pink"}}>베팅<font color="blue">0</font></th>
                        <th style={{backgroundColor:"pink"}}>마진<font color="blue">0</font></th>
                        <th style={{backgroundColor:"yellow"}}>베팅<font color="blue">0</font></th>
                        <th style={{backgroundColor:"yellow"}}>마진<font color="blue">0</font></th>
                        <th style={{backgroundColor:"lightgray"}}>베팅<font color="blue">5,820,265,530</font></th>
                        <th style={{backgroundColor:"lightgray"}}>마진<font color="blue">529,957,192</font></th>
                        <th style={{backgroundColor:"green"}}>베팅<font color="blue">17,399,001,976</font></th>
                        <th style={{backgroundColor:"green"}}>마진<font color="blue">470,908,048</font></th>
                        <th style={{backgroundColor:"pink"}}>베팅<font color="blue">963,748,760</font></th>
                        <th style={{backgroundColor:"pink"}}>마진<font color="blue">74,944,666</font></th>
                        <th style={{backgroundColor:"yellow"}}>베팅<font color="blue">433,229,750</font></th>
                        <th style={{backgroundColor:"yellow"}}>마진<font color="blue">33,301,069</font></th>
                        <th style={{backgroundColor:"lightgray"}}>베팅<font color="blue">5,820,265,530</font></th>
                        <th style={{backgroundColor:"lightgray"}}>마진<font color="blue">529,957,192</font></th>
                        <th style={{backgroundColor:"green"}}>베팅<font color="blue">17,399,001,976</font></th>
                        <th style={{backgroundColor:"green"}}>마진<font color="blue">470,908,048</font></th>
                        <th style={{backgroundColor:"pink"}}>베팅<font color="blue">963,748,760</font></th>
                        <th style={{backgroundColor:"pink"}}>마진<font color="blue">74,944,666</font></th>
                        <th style={{backgroundColor:"yellow"}}>베팅<font color="blue">433,229,750</font></th>
                        <th style={{backgroundColor:"yellow"}}>마진<font color="blue">33,301,069</font></th>
                    </tr>
                    </thead>
                    <tr>
                        <td align={"center"} ><font color="black">11/10(금)</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                    </tr>
                    <tr>
                        <td align={"center"} ><font color="black">11/09(목)</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                    </tr>
                    <tr>
                        <td align={"center"} ><font color="black">11/08(수)</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                    </tr>
                    <tr>
                        <td align={"center"} ><font color="black">11/07(화)</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                    </tr>
                    <tr>
                        <td align={"center"} ><font color="black">11/06(월)</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                    </tr>
                    <tr>
                        <td align={"center"} ><font color="black">11/05(일)</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                    </tr>
                    <tr>
                        <td align={"center"} ><font color="black">11/04(토)</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                    </tr>
                    <tr>
                        <td align={"center"} ><font color="black">11/03(금)</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                    </tr>
                    <tr>
                        <td align={"center"} ><font color="black">11/02(목)</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                    </tr>
                    <tr>
                        <td align={"center"} ><font color="black">11/01(수)</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">0</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">0</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                        <td align={"center"} ><font color="blue">726,266,445</font></td>
                        <td align={"center"} ><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">726,266,445</font></td>
                        <td style={{backgroundColor:"lightgreen"}} align={"center"}><font color="blue">45,027,542</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">1,115,402,530</font></td>
                        <td style={{backgroundColor:"lightpink"}} align={"center"}><font color="blue">29,660,884</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">67,268,027</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">8,285,993</font></td>
                        <td align={"center"} ><font color="red">-20,273,458</font></td>
                        <td style={{backgroundColor:"lightyellow"}} align={"center"}><font color="blue">61,651,043</font></td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: "yellow"}} align={"center"}>총 10일</td>
                        <td style={{backgroundColor: "yellow"}} align={"center"} colSpan="10">적중율 : 0.00%</td>
                        <td style={{backgroundColor: "yellow"}} align={"center"} colSpan="10">적중율 : 90.87%</td>
                        <td style={{backgroundColor: "yellow"}} align={"center"} colSpan="10">적중율 : 90.87%</td>
                    </tr>
                </table>
            </div>


        </div>

    )
}
export default UserPage;