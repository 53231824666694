import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {MultiSelect} from "react-multi-select-component";

const DomainAdd = (props) => {

    const {open, onClose, initialState} = props
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const options = [
        { label: "ht-caal.com(HOTEL)", value: "01" },
        { label: "ht-sln.com(HOTEL)", value: "02" },
    ];
    const [selected, setSelected] = useState([]);

    const handleCancel = () => {
        onClose()
    }

    const openPopup = ()=> {
        open()
    }



    return (
        <div className="common" style={{width : "99%"}}>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <button style={{ backgroundColor: "white",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "200px", fontWeight: "bold", marginRight: "1px"}}
                >총판+회원 트리구조 이동</button>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="" style={{
                    display: "flex"
                }}>
                    <table>
                        <tr>
                            <td align={"center"} width={"100px"}>총판명</td>
                            <td align={"center"} width={"200px"}>
                                <select
                                    className="form-select sm-select"
                                    defaultValue={'01'}
                                    style={{
                                        width: "200px"
                                    }}
                                >
                                    <option>본사1</option>
                                    <option>본사2</option>
                                </select>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>


    )
}
export default DomainAdd;