import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Link} from "react-router-dom";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {NumericFormat} from 'react-number-format';
import typeCase from "../../util/typeCase"
import {getMemberUser, setIsOpen2} from "../../redux/reducers/user/MemberReducer";
import MemberInformationSimpleModal from "../modal/user/simple/MemberInformationSimpleModal";
import BettingContainer from "../../containers/betting/BettingContainer";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";

const Member = ({member, onRefresh, searchHandler, initialState, userGbList2, dupIpChange, setSearchQuery, dupIdChange}) => {

    const [isEditing00, setIsEditing00] = useState(false);

    const dispatch = useDispatch()
    const [userList, setUserList] = useState([]);
    const [selectedNos, setSelectedNos] = useState([]);
    const [query, setQuery] = useState("");
    const [total, setTotal] = useState(0);
    const [levelCount, setLevelCount] = useState([]);
    const [stateCount, setStateCount] = useState([]);

    const [userInformation, setUserInformation] = useState(false);
    const [dormancyDate, setDormancyDate] = useState("");

    const [userTypeSort, setUserTypeSort] = useState(0);
    const [codeSort, setCodeSort] = useState(0);
    const [userStateSort, setUserStateSort] = useState(0);
    const [levelSort, setLevelSort] = useState(0);
    const [moneySort, setMoneySort] = useState(0);
    const [pointSort, setPointSort] = useState(0);
    const [depositSort, setDepositSort] = useState(0);
    const [withdrawSort, setWithdrawSort] = useState(0);
    const [totalAmountSort, setTotalAmountSort] = useState(0);
    const [monthAmountSort, setMonthAmountSort] = useState(0);
    const [loginCountSort, setLoginCountSort] = useState(0);
    const [recentlyDepositSort, setRecentlyDepositSort] = useState(0);
    const [recentlyLoginSort, setRecentlyLoginSort] = useState(0);
    const [recentlyDomainSort, setRecentlyDomainSort] = useState(0);
    const [createdAtSort, setCreatedAtSort] = useState(0);


    const [entrDttmSort, setEntrDttmSort] = useState(0);
    const [sortGb02Sort, setSortGb02Sort] = useState(0);
    const [sortGb03Sort, setSortGb03Sort] = useState(0);
    const [sortGb04Sort, setSortGb04Sort] = useState(0);
    const [sortGb05Sort, setSortGb05Sort] = useState(0);
    const [sortGb06Sort, setSortGb06Sort] = useState(0);
    const [sortGb07Sort, setSortGb07Sort] = useState(0);
    const [sortGb08Sort, setSortGb08Sort] = useState(0);
    const [sortGb09Sort, setSortGb09Sort] = useState(0);
    const [sortGb10Sort, setSortGb10Sort] = useState(0);
    const [sortGb11Sort, setSortGb11Sort] = useState(0);
    const [sortGb12Sort, setSortGb12Sort] = useState(0);
    const [sortGb13Sort, setSortGb13Sort] = useState(0);
    const [sortGb14Sort, setSortGb14Sort] = useState(0);


    //그룹
    const [userGroupCd, setUserGroupCd] = useState('')
    const [userGroupList, setUserGroupList] = useState([]);
    //회원등급
    const [userGbCd, setUserGbCd] = useState('')
    const [userGbList, setUserGbList] = useState([]);
    //회원상태
    const [userStateCd, setUserStateCd] = useState('')
    const [userStateList, setUserStateList] = useState([]);
    //P등급
    const [pntGbCd, setPntGbCd] = useState('')
    const [pntGbList, setPntGbList] = useState([]);

    const userInfoId = useSelector((state) => state.userInfo.id);
    const userInfoNo = useSelector((state) => state.userInfo.no);
    const userInfoIp = useSelector((state) => state.userInfo.ip);


    const start01EditingHandler = () => {
        setIsEditing00(false);
    };
    const start02EditingHandler = () => {
        setIsEditing00(true);
    };

    const getCmmnCd = () => {

        if(userGroupList.length === 0){
            axios.post('/admin/member/getGroupSetting', {
                cmmnCdId : '0000',
            }).then((res) => {
                setUserGroupList(res.data.cmmnCdList);
            })
        }
        if(userGbList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0002',
            }).then((res) => {
                setUserGbList(res.data.cmmnCdList);
            })
        }
        if(userStateList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0003',
            }).then((res) => {
                setUserStateList(res.data.cmmnCdList);
            })
        }
        if(pntGbList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0004',
            }).then((res) => {
                setPntGbList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])



    const getAllList = () => {
        axios.post('/admin/member/get_member_list', {
            adminNo: userInfoNo
        }).then((res) => {
            setSelectedNos([])
            setUserList(res.data.userList)
            setTotal(res.data.userTotal)
            //console.log(res.data.userTotal)
            setLevelCount(res.data.userLevelCount)
            setStateCount(res.data.userStateCount)
            setUserTypeSort(0)
            setCodeSort(0)
            setUserStateSort(0)
            setLevelSort(0)
            setMoneySort(0)
            setPointSort(0)
            setDepositSort(0)
            setWithdrawSort(0)
            setTotalAmountSort(0)
            setMonthAmountSort(0)
            setLoginCountSort(0)
            setRecentlyDepositSort(0)
            setRecentlyLoginSort(0)
            setRecentlyDomainSort(0)
            setCreatedAtSort(0)
        })


    }
    useEffect(getAllList, [])

    const informationOpenHandler = () => {
        setUserInformation(true);
    }
    const dormancyDateHandler = (e) => {
        setDormancyDate(e.target.value)
        //console.log(e.target.value)
    }
    const dormancyState = (change_state) => {
        if ( dormancyDate !== "" ) {
            axios.post('/admin/member/dormancy_state', {
                dormancyDate: dormancyDate,
                adminNo: userInfoNo,
                user_state: change_state
            }).then((res) => {
                if(res.data.isUpdated) {
                    alert("휴면변경완료")
                    setDormancyDate("")
                    getAllList()
                } else {
                    alert("실패")
                }
            })
        } else {
            alert("날짜를 선택해주세요.")
        }
    }
    const allCheckHandler = (e) => {
        if(userList.length > 0 && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < userList.length; i++) {
                checks.push(userList[i].no)
                total += userList[i].request_money
            }
            setSelectedNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let selectedNos2 = []
        if(checked) {
            selectedNos2 = [...selectedNos, no]
            setSelectedNos([...selectedNos, no])
        }
        else {
            selectedNos2 = selectedNos.filter((id) => id !== no)
            setSelectedNos(selectedNos.filter((id) => id !== no))
        }
        let total = 0;
        for(let i = 0; i < selectedNos2.length; i++) {
            total += userList.filter((id) => id.no === selectedNos2[i])[0]
        }
    }
    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const partnerSelectList =[
        {value : "1", name: "총판1"},
        {value : "2", name: "총판2"},
        {value : "3", name: "총판3"},
        {value : "4", name: "총판4"},
    ]


    const allList = () => {
        getAllList()
    }

    const updateState = (user_state, selectedNos) => {
        //console.log(selectedNos)
        if(selectedNos.length > 0) {

            if (window.confirm("변경 하시겠습니까?")) {

                axios.post('/admin/member/change_state', {
                    adminNo: userInfoNo,
                    selectedNos: selectedNos,
                    user_state: user_state
                }).then((res) => {
                    if (res.data.isUpdated) {
                        alert("변경완료")
                        setSelectedNos([])

                        getAllList()
                        onRefresh()
                    } else {
                        alert("실패")
                    }
                })
            }
        }
        else {
            alert('선택해주세요')
        }
    }

    function onCommentColor (color) {
        switch (color) {
            case 0 : return "red"
            case 1 : return "orange"
            case 2 : return "yellow"
            case 3 : return "green"
            case 4 : return "blue"
            case 5 : return "navy"
            case 6 : return "purple"
            case 10 : return ""
            default : return color
        }
    }

    function userLevel (level) {
        switch (level) {
            case 0 : return "레벨 1 "
            case 1 : return "레벨 2 "
            case 2 : return "레벨 3 "
            case 3 : return "레벨 4 "
            case 4 : return "레벨 5 "
            case 5 : return "VIP "
            default : return level
        }
    }
    const newUserColor = (state) => {
        switch(state) {
            case 0 :
                return "bgRequest"
            default : return state
        }
    }

    function sortGb01Set(id, userTypeSort) {

        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>가입일(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>가입일▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>가입일▼</div>
            default : return userTypeSort
        }
    }
    function sortGb02Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>최근활동(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>최근활동▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>최근활동▼</div>
            default : return userTypeSort
        }
    }
    function sortGb03Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>최근충전(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>최근충전▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>최근충전▼</div>
            default : return userTypeSort
        }
    }
    function sortGb04Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>게임머니(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>게임머니▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>게임머니▼</div>
            default : return userTypeSort
        }
    }
    function sortGb05Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>포인트(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>포인트▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>포인트▼</div>
            default : return userTypeSort
        }
    }
    function sortGb06Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>충전일수(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>충전일수▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>충전일수▼</div>
            default : return userTypeSort
        }
    }
    function sortGb07Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>kgon머니(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>kgon머니▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>kgon머니▼</div>
            default : return userTypeSort
        }
    }
    function sortGb08Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>충전(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>충전▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>충전▼</div>
            default : return userTypeSort
        }
    }
    function sortGb09Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>환전(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>환전▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>환전▼</div>
            default : return userTypeSort
        }
    }
    function sortGb10Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>마진(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>마진▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>마진▼</div>
            default : return userTypeSort
        }
    }
    function sortGb11Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>추천인(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>추천인▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>추천인▼</div>
            default : return userTypeSort
        }
    }
    function sortGb12Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>로그인(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>로그인▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>로그인▼</div>
            default : return userTypeSort
        }
    }
    function sortGb13Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>중복계좌(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>중복계좌▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>중복계좌▼</div>
            default : return userTypeSort
        }
    }
    function sortGb14Set(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)} style={{cursor:'pointer'}}>출석(-)</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)} style={{cursor:'pointer'}}>출석▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 0)} style={{cursor:'pointer'}}>출석▼</div>
            default : return userTypeSort
        }
    }




    const sortByColNameUser = (colName, orderOption) => {

        //alert("orderOption>>>>>"+orderOption);

        switch(colName) {
            //가입일
            case "sortGb01" :
                setEntrDttmSort(orderOption);
                break;
            //최근활동
            case "sortGb02" :
                setSortGb02Sort(orderOption);
                break;
            //최근충전
            case "sortGb03" :
                setSortGb03Sort(orderOption);
                break;
            //게임머니
            case "sortGb04" :
                setSortGb04Sort(orderOption);
                break;
            //포인트
            case "sortGb05" :
                setSortGb05Sort(orderOption);
                break;
            //충전일수
            case "sortGb06" :
                setSortGb06Sort(orderOption);
                break;
            //kgon머니
            case "sortGb07" :
                setSortGb07Sort(orderOption);
                break;
            //충전
            case "sortGb08" :
                setSortGb08Sort(orderOption);
                break;
            //환전
            case "sortGb09" :
                setSortGb09Sort(orderOption);
                break;
            //마진
            case "sortGb10" :
                setSortGb10Sort(orderOption);
                break;
            //추천인
            case "sortGb11" :
                setSortGb11Sort(orderOption);
                break;
            //로그인횟수
            case "sortGb12" :
                setSortGb12Sort(orderOption);
                break;
            //중복계좌
            case "sortGb13" :
                setSortGb13Sort(orderOption);
                break;
            //출석체크
            case "sortGb14" :
                setSortGb14Sort(orderOption);
                break;

            default:
                break;
        }

        searchHandler(colName, orderOption);


    }


    const sortHandler = (sortColumn, sortValue) => {
    }


    const userDelState = (state, id) => {
        switch(state) {
            //대기
            case 'Y' :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "20px", width: "fit-content"}}
                                            onClick={(e) => {
                                                userRestore(id)
                                                //alert("member.id :: "+member.id)
                                            }}

                                    >복원</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            default : return ''
        }
    }

    const userRestore = (id) => {
        if(window.confirm("이 회원을 복구하시겠습니까?"))
        {
            axios.post('/admin/member/restoreUser', {
                id: id,
                reg_id: userInfoId,
                upd_id: userInfoId,
                reg_ip: userInfoIp,
                upd_ip: userInfoIp
            })
                .then((res) => {
                    if (res.data.isInserted) {
                        alert('복구완료')
                        onRefresh()
                    } else {
                        alert('복구실패')
                    }
                })
        }
    }


    const rcptMethCdChangeHandler = (id, value) => {
        // alert("e :: "+e.target.value);
        // alert("id :: "+id);
        // alert("value :: "+value);

        let rcptMethValue;
        switch(value) {
            case '01' : rcptMethValue = '02'; break;
            case '02' : rcptMethValue = '03'; break;
            case '03' : rcptMethValue = '04'; break;
            case '04' : rcptMethValue = '05'; break;
            case '05' : rcptMethValue = '06'; break;
            case '06' : rcptMethValue = '01'; break;
        }

        axios.post('/admin/member/updateOneRcptMethCd', {
            rcptMethCd: rcptMethValue,
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }


    const userGroupCdChangeHandler = (e, id) => {
        // alert("e :: "+e.target.value);
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneUserGroupCd', {
            userGroupCd: e.currentTarget.value,
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }

    const userGbCdChangeHandler = (e, id, asisValue) => {
        // alert("e :: "+e.target.value);
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneUserGbCd', {
            userGbCd: e.currentTarget.value,
            asisValue: asisValue,
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }

    const userStateCdChangeHandler = (e, id) => {
        // alert("e :: "+e.target.value);
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneUserStateCd', {
            userStateCd: e.currentTarget.value,
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }

    const pntGbCdChangeHandler = (e, id) => {
        // alert("e :: "+e.target.value);
        // alert("id :: "+id);
        axios.post('/admin/member/updateOnePntGbCd', {
            pntGbCd: e.currentTarget.value,
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }

    const phoneViewHandler = (e, pwd, mbph_start, mbph_center, mbph_end) => {
        let reply = prompt("핸드폰 조회 비번을 입력하세요.", "");

        //alert("reply ::: "+reply);
        //alert("pwd ::: "+pwd);
        if( reply === pwd )
        {
            alert("핸드폰번호 : "+mbph_start+"-"+mbph_center+"-"+mbph_end);
        }
        else
        {
            alert("핸드폰 조회 비번이 옳바르지 않습니다.");
            return;
        }
    }

    const withdrawPwChangeHandler = (e, id) => {
        // alert("e :: "+e.target.value);
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneWithdrawPw', {
            withdrawPw: e.currentTarget.value,
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }



    //iqryPopOutBlkYn
    const iqryPopOutBlkYnChangeHandler = (e, id, value) => {
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneIqryPopOutBlkYn', {
            iqryPopOutBlkYn: (value === 'Y' ? 'N' : 'Y'),
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }

    const allBlockYnChangeHandler = (e, id, value) => {
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneAllBlockYn', {
            allBlockYn: (value === 'Y' ? 'N' : 'Y'),
            oneBetYn: (value === 'Y' ? 'N' : 'Y'),
            twoBetYn: (value === 'Y' ? 'N' : 'Y'),
            kgCasinoBetPosbYn: (value === 'Y' ? 'N' : 'Y'),
            tokenBetPosbYn: (value === 'Y' ? 'N' : 'Y'),
            inplayBetPosbYn: (value === 'Y' ? 'N' : 'Y'),
            attendChkPosbYn: (value === 'Y' ? 'N' : 'Y'),
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }
    //one_bet_yn
    const oneBetYnChangeHandler = (e, id, value) => {
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneOneBetYn', {
            oneBetYn: (value === 'Y' ? 'N' : 'Y'),
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }
    //two_bet_yn
    const twoBetYnChangeHandler = (e, id, value) => {
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneTwoBetYn', {
            twoBetYn: (value === 'Y' ? 'N' : 'Y'),
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }
    //kg_casino_bet_posb_yn
    const kgCasinoBetPosbYnChangeHandler = (e, id, value) => {
        axios.post('/admin/member/updateOneKgCasinoBetPosbYn', {
            kgCasinoBetPosbYn: (value === 'Y' ? 'N' : 'Y'),
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }
    //token_bet_posb_yn
    const tokenBetPosbYnChangeHandler = (e, id, value) => {
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneTokenBetPosbYn', {
            tokenBetPosbYn: (value === 'Y' ? 'N' : 'Y'),
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }
    //inplay_bet_posb_yn
    const inplayBetPosbYnChangeHandler = (e, id, value) => {
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneInplayBetPosbYn', {
            inplayBetPosbYn: (value === 'Y' ? 'N' : 'Y'),
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }
    //attend_chk_posb_yn
    const attendChkPosbYnChangeHandler = (e, id, value) => {
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneAttendChkPosbYn', {
            attendChkPosbYn: (value === 'Y' ? 'N' : 'Y'),
            id: id,
            reg_id: userInfoId,
            upd_id: userInfoId,
            reg_ip: userInfoIp,
            upd_ip: userInfoIp
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }

    const updateGbState = (value, id) => {

        //승인
        if( value === '01' )
        {
            axios.post('/admin/member/updateOneUserGbCd', {
                userGbCd: '02',
                asisValue: '01',
                id: id,
                reg_id: userInfoId,
                upd_id: userInfoId,
                reg_ip: userInfoIp,
                upd_ip: userInfoIp
            })
                .then((res) => {
                    if (res.data.isUpdated) {
                        //alert('복구완료')
                        onRefresh()
                    } else {
                        alert('수정실패')
                    }
                })
        }
        //보류
        else if( value === '02' )
        {
            axios.post('/admin/member/waitUser', {
                id: id,
                reg_id: userInfoId,
                upd_id: userInfoId,
                reg_ip: userInfoIp,
                upd_ip: userInfoIp
            })
                .then((res) => {
                    if (res.data.isInserted) {
                        onRefresh()
                    } else {
                        alert('삭제실패')
                    }
                })
        }
        //거절
        else if( value === '03' )
        {
            axios.post('/admin/member/deleteUser', {
                id: id,
                reg_id: userInfoId,
                upd_id: userInfoId,
                reg_ip: userInfoIp,
                upd_ip: userInfoIp
            })
                .then((res) => {
                    if (res.data.isInserted) {
                        onRefresh()
                    } else {
                        alert('삭제실패')
                    }
                })
        }



    }





    return (
        <div className="common">
            <table className="table table-bordered table-sm text-center align-middle">
                <thead>
                <tr>
                </tr>
                </thead>
            </table>
            <div className="commonT container-fluid p-0 mx-1" style={{
                display: "flex", width:"100%", overflowX:"scroll", alignItems:'flex-start'
            }}>
                <table className="commonU table-bordered text-center ">
                    <thead>
                    <tr>
                        <th style={{width: "1%"}}>번호</th>
                        <th style={{width: "2%"}}>도메인</th>
                        <th style={{width: "3%"}}>그룹</th>
                        <th style={{width: "2%"}}>아이디</th>
                        <th style={{width: "2%"}}>닉네임</th>
                        <th style={{width: "2%"}}>이름</th>
                        {initialState.isEditing00 && (
                            <th style={{width: "3%"}}>부가정보</th>
                        )}
                        <th style={{width: "3%"}}>소속</th>
                        <th style={{width: "3%"}}>가맹점<br></br>추천인</th>
                        <th style={{width: "2%"}}>입금방식</th>
                        <th style={{width: "2%"}}>{sortGb04Set("sortGb04", sortGb04Sort)}</th>
                        <th style={{width: "2%"}}>{sortGb05Set("sortGb05", sortGb05Sort)}</th>
                        <th style={{width: "2%"}}>{sortGb07Set("sortGb07", sortGb07Sort)}</th>
                        <th style={{width: "2%"}}>회원상태</th>
                        <th style={{width: "2%"}}>p등급</th>
                        <th style={{width: "2%"}}>베팅횟수</th>
                        <th style={{width: "2%"}}>핸드폰</th>
                        <th style={{width: "2%"}}>
                            전체베팅차단
                            {isEditing00 && (
                                <button style={{
                                    backgroundColor: "red",
                                    border: "none",
                                    color: "black",
                                    fontSize: "9px",
                                    height: "29px",
                                    width: "fit-content",
                                    marginRight: "5px"
                                }}
                                        onClick={() => {start01EditingHandler()}}
                                >축소
                                </button>
                            )}
                            {!isEditing00 && (
                                <button style={{
                                    backgroundColor: "green",
                                    border: "none",
                                    color: "white",
                                    fontSize: "9px",
                                    height: "29px",
                                    width: "fit-content",
                                    marginRight: "5px"
                                }}
                                        onClick={() => {start02EditingHandler()}}
                                >확장
                                </button>
                            )}
                        </th>
                        {isEditing00 && (
                            <th style={{width: "2%"}}>단폴베팅가능여부</th>
                        )}
                        {isEditing00 && (
                            <th style={{width: "2%"}}>두폴베팅가능여부</th>
                        )}
                        {isEditing00 && (
                            <th style={{width: "2%"}}>Kg카지노베팅가능여부</th>
                        )}
                        {isEditing00 && (
                            <th style={{width: "2%"}}>토큰베팅가능여부</th>
                        )}
                        {isEditing00 && (
                            <th style={{width: "2%"}}>Inplay베팅가능여부</th>
                        )}
                        {isEditing00 && (
                            <th style={{width: "2%"}}>출석체크가능여부</th>
                        )}
                        <th style={{width: "2%"}}>{sortGb08Set("sortGb08", sortGb08Sort)}</th>
                        <th style={{width: "2%"}}>{sortGb09Set("sortGb09", sortGb09Sort)}</th>
                        <th style={{width: "2%"}}>{sortGb10Set("sortGb10", sortGb10Sort)}</th>
                        <th style={{width: "2%"}}>{sortGb06Set("sortGb06", sortGb06Sort)}</th>
                        <th style={{width: "2%"}}>충전횟수</th>
                        <th style={{width: "2%"}}>추천수</th>
                        <th style={{width: "3%"}}>{sortGb01Set("sortGb01", entrDttmSort)}</th>
                        <th style={{width: "3%"}}>{sortGb02Set("sortGb02", sortGb02Sort)}</th>
                        <th style={{width: "3%"}}>{sortGb03Set("sortGb03", sortGb03Sort)}</th>
                        <th style={{width: "2%"}}>{sortGb12Set("sortGb12", sortGb12Sort)}</th>
                        <th style={{width: "2%"}}>접속IP</th>
                        <th style={{width: "2%"}}>중복IP</th>
                        <th style={{width: "2%"}}>블랙</th>
                    </tr>
                    </thead>

                    {!member.length && (
                        <tbody key={member.no}>
                        <tr>
                            <td colSpan="50">목록이 비어있습니다.</td>
                        </tr>
                        </tbody>
                    )}
                    {member.map((member, index) => (
                        <tbody key={member.no}>
                        <tr style={{backgroundColor:member.user_gb_id === '08' ? "lightgreen" : "white"} }   >
                            <td align={"center"} >{member.no}</td>
                            <td align={"center"}>
                                {member.domain?.substring(0,10)}
                                <br></br>
                                {member.domain?.substring(10,member.domain.length)}
                            </td>
                            <td align={"center"}>
                                <select

                                    className="sm-select2"
                                    style={{
                                        width: "90px"
                                    }}
                                    disabled={ member.del_yn === 'Y' ? true : false  }
                                    onChange={(e) => userGroupCdChangeHandler(e, member.id)}
                                    value={member.user_group_id}
                                >
                                    <option key={""} value={""}>[그룹없음]</option>
                                    {userGroupList?.map((item, index) => (
                                        <option key={item.no} value={item.no}>
                                            {( member.user_group_id == item.no ? item.group_nm : '▼'+item.group_nm )}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            {member.del_yn != 'Y'  && (
                                <td align={"center"} style={{backgroundColor:member.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: member.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={member.font_color}>{member.id}</font>
                                </td>
                            )}
                            {member.del_yn === 'Y'  && (
                                <td align={"center"} >
                                    <font color={member.font_color}>{member.id}</font>
                                </td>
                            )}
                            {member.del_yn != 'Y'  && (
                                <td align={"center"} style={{backgroundColor:member.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: member.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}><font color={member.font_color}>{
                                    member.nickname
                                }</font>
                                </td>
                            )}
                            {member.del_yn === 'Y'  && (
                                <td align={"center"} ><font color={member.font_color}>{
                                    member.nickname
                                }</font>
                                </td>
                            )}

                            {member.del_yn != 'Y'  && (
                                <td align={"center"} style={{backgroundColor:member.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: member.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={member.font_color}>{member.name}</font>
                                    {userDelState(member.del_yn,member.id)}
                                </td>
                            )}
                            {member.del_yn === 'Y'  && (
                                <td align={"center"} >
                                    <font color={member.font_color}>{member.name}</font>
                                    {userDelState(member.del_yn,member.id)}
                                </td>
                            )}
                            {initialState.isEditing00 && (
                                <td align={"center"}>
                                    {userGbList2?.map(item => (
                                        <React.Fragment key={item.numkey}>
                                            { item.numkey == member.addn_info_tag || (member.addn_info_tag !=null && member.addn_info_tag.indexOf(item.numkey) > -1 && (
                                                <font  className={`looking_id_show type${item.no}`}>
                                                    { item.numkey == member.addn_info_tag || (member.addn_info_tag !=null && member.addn_info_tag.indexOf(item.numkey) > -1)  ? item.tag_nm : ""}
                                                </font>
                                            ))}
                                            { item.numkey == member.addn_info_tag || (member.addn_info_tag !=null && member.addn_info_tag.indexOf(item.numkey) > -1 && (
                                                <br></br>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </td>
                            )}
                            <td align={"center"}>
                                {member.store_cd}
                            </td>
                            <td align={"center"}>
                                {(member.psnl_cd != null && member.psnl_cd === member.mrch_cd ? member.mrch_cd+'[직영]' : member.mrch_cd  )}
                                / <br></br>
                                {member.up_rcmr_id}
                            </td>
                            {member.del_yn != 'Y'  && (
                                <td align={"center"} style={{cursor: "pointer"}} onClick={(e) => {
                                    rcptMethCdChangeHandler(member.id, member.rcpt_meth_id)
                                }}>
                                    <font color="#228b22">{member.rcpt_meth_cd}</font>
                                </td>
                            )}
                            {member.del_yn === 'Y'  && (
                                <td align={"center"} >
                                    <font color="#228b22">{member.rcpt_meth_cd}</font>
                                </td>
                            )}
                            <td align={"center"}>
                                <font color={member.game_money_amt > -1 ? "blue" : "red"}>
                                    <NumericFormat value={member.game_money_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </td>
                            <td align={"center"}>
                                <font color={member.point > -1 ? "blue" : "red"}>
                                    <NumericFormat value={member.point} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </td>
                            <td align={"center"}>
                                <font color={member.kg_casino_money_amt > -1 ? "blue" : "red"}>
                                    <NumericFormat value={member.kg_casino_money_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </td>
                            <td align={"center"}>
                                <select
                                    className="sm-select2"
                                    style={{
                                        width: "50px"
                                    }}
                                    disabled={ member.del_yn === 'Y' ? true : false  }
                                    onChange={(e) => userStateCdChangeHandler(e, member.id)}
                                    value={member.user_state_id}
                                >
                                    {userStateList?.map((item, index) => (
                                        <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>
                                            {( member.user_state_id == item.cmmn_cd_dtl_id ? item.cmmn_cd_dtl_nm : '▼'+item.cmmn_cd_dtl_nm )}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td align={"center"}>
                                <select
                                    className="sm-select2"
                                    style={{
                                        width: "55px"
                                    }}
                                    disabled={ member.del_yn === 'Y' ? true : false  }
                                    onChange={(e) => pntGbCdChangeHandler(e, member.id)}
                                    value={member.pnt_gd_id}
                                >
                                    {pntGbList?.map((item, index) => (
                                        <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>
                                            {( member.pnt_gd_id == item.cmmn_cd_dtl_id ? item.cmmn_cd_dtl_nm : '▼'+item.cmmn_cd_dtl_nm )}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td align={"center"}>
                                <a style={{ cursor: "pointer" }}  href={"/betting/betting_list/" + member.id}>
                                    <font color="blue">{member.betting_cnt}</font>
                                </a>
                            </td>
                            <td align={"center"}>
                                {member.mbph_start}<br></br>{member.mbph_center}<br></br>{member.mbph_end}
                            </td>
                            <td align={"center"} >
                                {member.del_yn != 'Y'  && (
                                    <a style={{ cursor: "pointer" }}  onClick={(e) => allBlockYnChangeHandler(e, member.id, member.all_block_id)}>
                                        <font color={member.all_block_id === 'Y' ? 'green' : 'red'}>{member.all_block_yn}</font>
                                    </a>
                                )}
                                {member.del_yn === 'Y'  && (
                                    <font color={member.all_block_id === 'Y' ? 'green' : 'red'}>{member.all_block_yn}</font>
                                )}
                            </td>

                            {isEditing00 && (
                                <td align={"center"}>
                                    {member.del_yn != 'Y'  && (
                                        <a style={{ cursor: "pointer" }}  onClick={(e) => oneBetYnChangeHandler(e, member.id, member.one_bet_id)}>
                                            <font color={member.one_bet_id === 'Y' ? 'green' : 'red'}>{member.one_bet_yn}</font>
                                        </a>
                                    )}
                                    {member.del_yn === 'Y'  && (
                                        <font color={member.one_bet_id === 'Y' ? 'green' : 'red'}>{member.one_bet_yn}</font>
                                    )}
                                </td>
                            )}
                            {isEditing00 && (
                                <td align={"center"}>
                                    {member.del_yn != 'Y'  && (
                                        <a style={{ cursor: "pointer" }}  onClick={(e) => twoBetYnChangeHandler(e, member.id, member.two_bet_id)}>
                                            <font color={member.two_bet_id === 'Y' ? 'green' : 'red'}>{member.two_bet_yn}</font>
                                        </a>
                                    )}
                                    {member.del_yn === 'Y'  && (
                                        <font color={member.two_bet_id === 'Y' ? 'green' : 'red'}>{member.two_bet_yn}</font>
                                    )}
                                </td>
                            )}
                            {isEditing00 && (
                                <td align={"center"}>
                                    {member.del_yn != 'Y'  && (
                                        <a style={{ cursor: "pointer" }}  onClick={(e) => kgCasinoBetPosbYnChangeHandler(e, member.id, member.kg_casino_bet_posb_id)}>
                                            <font color={member.kg_casino_bet_posb_id === 'Y' ? 'green' : 'red'}>{member.kg_casino_bet_posb_yn}</font>
                                        </a>
                                    )}
                                    {member.del_yn === 'Y'  && (
                                        <font color={member.kg_casino_bet_posb_id === 'Y' ? 'green' : 'red'}>{member.kg_casino_bet_posb_yn}</font>
                                    )}
                                </td>
                            )}
                            {isEditing00 && (
                                <td align={"center"}>
                                    {member.del_yn != 'Y'  && (
                                        <a style={{ cursor: "pointer" }}  onClick={(e) => tokenBetPosbYnChangeHandler(e, member.id, member.token_bet_posb_id)}>
                                            <font color={member.token_bet_posb_id === 'Y' ? 'green' : 'red'}>{member.token_bet_posb_yn}</font>
                                        </a>
                                    )}
                                    {member.del_yn === 'Y'  && (
                                        <font color={member.token_bet_posb_id === 'Y' ? 'green' : 'red'}>{member.token_bet_posb_yn}</font>
                                    )}
                                </td>
                            )}
                            {isEditing00 && (
                                <td align={"center"}>
                                    {member.del_yn != 'Y'  && (
                                        <a style={{ cursor: "pointer" }}  onClick={(e) => inplayBetPosbYnChangeHandler(e, member.id, member.inplay_bet_posb_id)}>
                                            <font color={member.inplay_bet_posb_id === 'Y' ? 'green' : 'red'}>{member.inplay_bet_posb_yn}</font>
                                        </a>
                                    )}
                                    {member.del_yn === 'Y'  && (
                                        <font color={member.inplay_bet_posb_id === 'Y' ? 'green' : 'red'}>{member.inplay_bet_posb_yn}</font>
                                    )}
                                </td>
                            )}
                            {isEditing00 && (
                                <td align={"center"}>
                                    {member.del_yn != 'Y'  && (
                                        <a style={{ cursor: "pointer" }}  onClick={(e) => attendChkPosbYnChangeHandler(e, member.id, member.attend_chk_posb_id)}>
                                            <font color={member.attend_chk_posb_id === 'Y' ? 'green' : 'red'}>{member.attend_chk_posb_yn}</font>
                                        </a>
                                    )}
                                    {member.del_yn === 'Y'  && (
                                        <font color={member.attend_chk_posb_id === 'Y' ? 'green' : 'red'}>{member.attend_chk_posb_yn}</font>
                                    )}
                                </td>
                            )}
                            <td align={"center"}>
                                <a style={{ cursor: "pointer" }}  href={"/deposit/Deposit_list/" + member.id}>
                                    <font color="blue">
                                        <NumericFormat value={member.deposit_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </a>
                            </td>
                            <td align={"center"}>
                                <a style={{ cursor: "pointer" }}  href={"/deposit/Withdraw_list/" + member.id}>
                                    <font color="blue">
                                        <NumericFormat value={member.withdraw_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </a>
                            </td>
                            <td align={"center"}>
                                <a style={{ cursor: "pointer" }}  href={"/deposit/Money_list/" + member.id}>
                                    <font color={(member.deposit_amt-member.withdraw_amt > 0 ? "blue": "red")}>
                                        <NumericFormat value={member.deposit_amt-member.withdraw_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </a>
                            </td>
                            <td align={"center"}>{member.deposit_days}</td>
                            <td align={"center"}>{member.deposit_cnt}</td>
                            <td align={"center"}>{member.rcmr_cnt}</td>
                            <td align={"center"}>{member.entr_dttm}</td>
                            <td align={"center"}>{member.updatedAt}</td>
                            <td align={"center"}>{member.lately_deposit}</td>
                            <td align={"center"}>{member.login_cnt}</td>
                            <td align={"center"}>
                                <font color="blue">
                                    <a style={{cursor:"pointer"}} onClick={(e)=> {window.open(`https://www.ipaddress.com/ipv4/${member.rcnt_actv_ip}`,"_blank", "width=700,height=700,left=500,top=200")}}>
                                        <span style={{ color: "#1F68EE" }}>{member.rcnt_actv_ip1}</span><br></br>
                                        <span style={{ color: "#1F68EE" }}>{member.rcnt_actv_ip2}</span>
                                        <br></br>
                                        <span style={{ color: "#5dc434" }}>{member.rcnt_actv_device_cd === "01"? "PC" : "Mobile"}</span>
                                    </a>
                                </font>
                            </td>
                            <td align={"center"}>
                                <font color="blue">
                                    <a style={{cursor:"pointer"}} onClick={(e)=> {
                                        //window.open(`https://www.ipaddress.com/ipv4/${member.dup_ip}`,"_blank", "width=700,height=700,left=500,top=200")}
                                        //setSearchQuery(member.rcnt_actv_ip);
                                        dupIpChange(member.rcnt_actv_ip);
                                    }}>
                                        <span style={{ color: "red" }}>{member.dup_ip}</span>
                                    </a>
                                </font>
                            </td>
                            <td align={"center"}>{member.black_reg_cnt}</td>
                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>

    )

}


export default Member;