import {call, put} from "redux-saga/effects";
import {failedGetDomainSettingListReducer, setDomainSettingListReducer} from "../../reducers/setting/DomainSettingListReducer";
import {fetchDomainSettingList} from "../../../lib/api";

export function* getDomainSettingListSaga(action) {
    try{
        console.log("##getDomainSettingListSaga input#",action.payload.input)
        const res = yield call(fetchDomainSettingList,
            action.payload.input,
        );
        console.log("##getDomainSettingListSaga output#",res.data)
        yield put(setDomainSettingListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDomainSettingListReducer(error))
    }
}