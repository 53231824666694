import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {getBlackMemberReducer,setSearchQuery,setHighLightQuery,setIsOpen} from "../../redux/reducers/user/BlackMemberReducer";
import Pagination from "../../components/common/Pagination2";
import BlackMemberAddModal from "../../components/modal/user/BlackMemberAddModal"
import HighlightedText from "../../components/common/HighlightedText";
import axios from "axios";
import {insertAdminLog} from "../../lib/api";


const Black_member = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.BlackMemberReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)

    useEffect(() => {
        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "블랙회원관리"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const getBlackMemberList = () => {
        if(initialState.totalList !== null ) {
            dispatch(getBlackMemberReducer({
                page: initialState.page,
                pageSize: initialState.pageSize,
                query: initialState.query,
            }))
        }
    }
    useEffect(getBlackMemberList, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getBlackMemberReducer({
            page: page,
            pageSize: initialState.pageSize,
            query : initialState.query,
        }))
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            dispatch(getBlackMemberReducer({
                page: 1,
                pageSize: initialState.pageSize,
                query: initialState.query,
            }))
            dispatch(setHighLightQuery(initialState.query))
        }
    }

    const refresh = () =>{
        dispatch(getBlackMemberReducer({
            page: 1,
            pageSize: initialState.pageSize,
            query: initialState.query,
        }))
    }

    const queryHandler = (e) => {
        dispatch(setSearchQuery(e.target.value));
    }



    const handleBlackExcept = (no) => {
        if (window.confirm("(1/2) 순서("+no+"번)을 블랙리스트에서 제외하시겠습니까?\n" +
            "블랙리스트에서 제외되더라도,회원목록에서의 복구는 직접하셔야 합니다.\n" +
            "또한 제외된 블랙리스트 데이터는 어떠한 경우에도 복구할 수 없습니다.")) {
            if (window.confirm("(2/2)제외된 블랙리스트 데이터는 어떠한 경우에도 복구할 수 없습니다."+
                "수행하시겠습니까?")) {
                axios.post('/admin/user/Black_member/except', {
                    no: no,
                }).then((res) => {
                    if (res.data.isDeleted) {
                        alert('삭제했습니다.')
                        refresh()
                    } else {
                        alert('삭제실패했습니다.')
                    }
                })
            }
        }
    }

    const handleClickOpen = () => {

    }
    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        refresh()
    }

    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">블랙회원관리</font>
                </div>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"800px"
                }}>
                    &nbsp;검색 : &nbsp;
                    <input className="form-check-input sm-input" value={initialState.query} type="text" onKeyDown={inputPress} onChange={queryHandler} />&nbsp;
                    (검색 대상 : 아이디,닉네임,이름,핸드폰,계좌번호,메모,사용아이피)
                </div>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"}  >
                        <tbody>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "100px", backgroundColor :"darkgreen"
                                }} type="submit" className="btn btn-primary" onClick={(e) => {
                                    window.open(`/user/add_black`,"_blank", "popup")
                                }}>블랙 등록</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br></br>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead>
                        <tr>
                            <th style={{width: "2%"}}>순서</th>
                            <th style={{width: "5%"}}>등록방식</th>
                            <th style={{width: "5%"}}>블랙구분</th>
                            <th style={{width: "10%"}}>소속</th>
                            <th style={{width: "5%"}}>아이디</th>
                            <th style={{width: "5%"}}>닉네임</th>
                            <th style={{width: "10%"}}>연락처</th>
                            <th style={{width: "5%"}}>이름</th>
                            <th style={{width: "5%"}}>은행명</th>
                            <th style={{width: "5%"}}>계좌번호</th>
                            <th style={{width: "7%"}}>사용 아이피 갯수</th>
                            <th style={{width: "10%"}}>사용 아이피<br/>(최종 사용일시)</th>
                            <th style={{width: "*%"}}>메모</th>
                            <th style={{width: "8%"}}>등록일시</th>
                            <th style={{width: "5%"}}>제외</th>
                        </tr>
                        </thead>
                        {initialState.totalList && initialState.totalList.length > 0 ?
                            initialState.blackMemberList.map((List, index) => (
                                <tbody key={List.no}>
                                <tr>
                                    <td>{List.no}</td>
                                    <td align={"center"}>{List.regTypeCdNm}</td>
                                    <td align={"center"}>{List.blackTypeCdNm}</td>
                                    <td align={"center"}>{List.storeCdNm}</td>
                                    <td align={"center"}><HighlightedText text={List.id} searchText={initialState.highLightQuery} /></td>
                                    <td align={"center"}><HighlightedText text={List.nickname} searchText={initialState.highLightQuery} /></td>
                                    <td align={"center"}><HighlightedText text={List.mbphNo} searchText={initialState.highLightQuery} /></td>
                                    <td align={"center"}><HighlightedText text={List.name} searchText={initialState.highLightQuery} /></td>
                                    <td align={"center"}><HighlightedText text={List.bankNm} searchText={initialState.highLightQuery} /></td>
                                    <td align={"center"}><HighlightedText text={List.account} searchText={initialState.highLightQuery} /></td>
                                    <td align={"center"}>{List.useIpCnt}</td>
                                    <td align={"center"}>{List.useIp}</td>
                                    <td style={{ maxWidth: '300px', wordWrap: 'break-word' }}>
                                        <HighlightedText text={List.blackMemo}searchText={initialState.highLightQuery} />
                                    </td>
                                    <td align={"center"}>{List.createdAt}</td>
                                    <td align={"center"}>
                                        <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}
                                                onClick={() => handleBlackExcept(List.no)}
                                        >블랙 제외
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            )) :
                            <tbody>
                            <tr>
                                <td colSpan= "15">조회된 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        }
                    </table>

                </div>
                <div className="d-flex justify-content-center"> {/* text-center 클래스추가 */}
                    {initialState.totalCount && initialState.totalCount > 0 ?
                        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange} />
                        :""}
                </div>
            </div>
        </div>


    )
}
export default Black_member;