import React from 'react';
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
import {useDispatch} from "react-redux";

const LoginFailList = ({state, onRefresh}) => {
    const dispatch = useDispatch()
    const getTypeStr = (type) => {
        if(type === 0 ) {
            return "관리자"
        }
        else if(type === 1 ) {
            return "파트너"
        }
        else {
            return "X"
        }
    }

    const hasNickName = (item) => {
        if(item.type === 0 && item.admin_nick != null) {
            return (<>
                <td colSpan="1"><span style={{ fontWeight: "bold" }}>{item.id}</span></td>
                <td colSpan="1"><span style={{ fontWeight: "bold" }}>{item.admin_nick}</span></td>
            </>)
        }
        else if(item.type === 1 && item.partner_nick != null) {
            return (<>
                <td colSpan="1"><span style={{ fontWeight: "bold" }}>{item.id}</span></td>
                <td colSpan="1"><span style={{ fontWeight: "bold" }}>{item.partner_nick}</span></td>
            </>)
        }
        else if(item.type > 1 && item.user_nick != null) {
            return (<>
                <td colSpan="1" style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                    dispatch(openUserSimpleInfo({
                        isOpen: true,
                        openId: item.id,
                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                    }))
                }}><span style={{ fontWeight: "bold", color: item.font_color}}>{item.id}</span></td>
                <td colSpan="1" style={{backgroundColor: item.back_ground_color, cursor: "pointer"}} onClick={(e) => {
                    dispatch(openUserSimpleInfo({
                        isOpen: true,
                        openId: item.id,
                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                    }))
                }}><span style={{ fontWeight: "bold", color: item.font_color }}>{item.user_nick}</span></td>
            </>)
        }
        else {
            return (<td colSpan="2"><span style={{ fontWeight: "bold" }}>{item.id}</span></td>)
        }
    }
    return <div className="common">
        <table className="table table-bordered table-sm text-center align-middle">
            <thead>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>로그인 시도 일시</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>로그인 시도 IP</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>관리자 여부</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임<br/>(매칭되는 아이디가 있을 시)</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>로그인 시도 암호<br/>{/*<span style={{color: "#E60303", fontWeight: "bold"}}>(암호화된 키값 - 동일 암호면 동일한 값을 가짐)</span>*/}</td>
            </tr>
            </thead>
            {!state?.loginFails.length && (
                <tbody key={state?.loginFails.no}>
                <tr>
                    <td colSpan="6">목록이 비어있습니다.</td>
                </tr>
                </tbody>
            )}
            <tbody>
            {state?.loginFails && state.loginFails.map((item, index) => (
                <tr>
                    <td>{item.createdAt}</td>
                    <td><a href={"https://www.ipaddress.com/ipv4/" + item.login_ip} target="_blank"><span style={{ color: "#1F68EE" }}>{item.login_ip}</span></a></td>
                    <td><span style={{ color: "#1F68EE" }}>{getTypeStr(item.type)}</span></td>
                    {hasNickName(item)}
                    <td><span style={{ fontWeight: "bold" }}>{item.pw}</span></td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>

}


export default LoginFailList;