import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import axios from "axios";

import {useParams} from "react-router-dom"
import {
    setSettingMini01Yn, setSettingMini02Yn, setSettingMini03Yn, setSettingMini04Yn
} from "../../../redux/reducers/setting/LvlSettingListReducer";

const LvlSettingMinitDtl = (props) => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.lvlSetting)
    const userInfoId = useSelector((state) => state.userInfo.id)

    const param = useParams()
    const id = param && param.userId;
    const setting_mini01_yn = param && param.yn01;
    const setting_mini02_yn = param && param.yn02;
    const setting_mini03_yn = param && param.yn03;
    const setting_mini04_yn = param && param.yn04;


    const popClose =() => {
        if (window.opener && typeof window.opener.popupClose === 'function') {
            window.opener.popupClose();
        }
        window.close();
    }

    const changeSettingMini01Yn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingMini01Yn('N')); }
            else{ dispatch(setSettingMini01Yn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingMini01Yn('N')); }
            else{ dispatch(setSettingMini01Yn('Y')); }
        }
    }
    const changeSettingMini02Yn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingMini02Yn('N')); }
            else{ dispatch(setSettingMini02Yn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingMini02Yn('N')); }
            else{ dispatch(setSettingMini02Yn('Y')); }
        }
    }
    const changeSettingMini03Yn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingMini03Yn('N')); }
            else{ dispatch(setSettingMini03Yn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingMini03Yn('N')); }
            else{ dispatch(setSettingMini03Yn('Y')); }
        }
    }
    const changeSettingMini04Yn = (reduceVal, dbval) => {
        if( reduceVal == '' )
        {
            if( dbval == 'Y' ) { dispatch(setSettingMini04Yn('N')); }
            else{ dispatch(setSettingMini04Yn('Y')); }
        }
        else
        {
            if( reduceVal == 'Y' ){ dispatch(setSettingMini04Yn('N')); }
            else{ dispatch(setSettingMini04Yn('Y')); }
        }
    }


    const updateMainBtnState = (state, depositNos) => {
        if (window.confirm("수정 하시겠습니까?"))
        {
            //alert("initialState.settingLiveYn : "+initialState.settingLiveYn);
            axios.post('/admin/setting/saveLvlMainDtlMiniSetting', {
                pntGbCd: id,
                settingMini01Yn: initialState.settingMini01Yn == '' ? setting_mini01_yn : initialState.settingMini01Yn,
                settingMini02Yn: initialState.settingMini02Yn == '' ? setting_mini02_yn : initialState.settingMini02Yn,
                settingMini03Yn: initialState.settingMini03Yn == '' ? setting_mini03_yn : initialState.settingMini03Yn,
                settingMini04Yn: initialState.settingMini04Yn == '' ? setting_mini04_yn : initialState.settingMini04Yn,
            }).then((res) => {
                alert(res.data.isUpdated? "수정완료" : "수정실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                popClose();
            })

        }
    }


    return (
        <div className="common" >
            <div style={{
                paddingLeft: "1px"
            }}>

                <div style={{
                    position:"relative",
                    background:"#3c3c3d",
                    height:"30px",
                    alignItems:"center",
                    textAlign:"left",
                    alignContent:"center"
                }}>
                    &nbsp;&nbsp;&nbsp;
                    <font color={"white"} size={1.5}><b> 미니게임 상세 설정</b></font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                        <button style={{ backgroundColor: initialState.settingMini01Yn == '' ? (setting_mini01_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingMini01Yn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingMini01Yn == '' ? (setting_mini01_yn == 'Y' ? "white" : "black") : (initialState.settingMini01Yn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "80px", marginRight: "5px", marginTop:"10px"}}
                                onClick={(e) => {
                                    changeSettingMini01Yn(initialState.settingMini01Yn, setting_mini01_yn)
                                }}
                        >
                            <b>넥스트사다리</b>
                        </button>
                        <button style={{ backgroundColor: initialState.settingMini02Yn == '' ? (setting_mini02_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingMini02Yn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingMini02Yn == '' ? (setting_mini02_yn == 'Y' ? "white" : "black") : (initialState.settingMini02Yn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "80px", marginRight: "5px", marginTop:"10px"}}
                                onClick={(e) => {
                                    changeSettingMini02Yn(initialState.settingMini02Yn, setting_mini02_yn)
                                }}
                        >
                            <b>넥스트파워볼</b>
                        </button>
                        <button style={{ backgroundColor: initialState.settingMini03Yn == '' ? (setting_mini03_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingMini03Yn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingMini03Yn == '' ? (setting_mini03_yn == 'Y' ? "white" : "black") : (initialState.settingMini03Yn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "80px", marginRight: "5px", marginTop:"10px"}}
                                onClick={(e) => {
                                    changeSettingMini03Yn(initialState.settingMini03Yn, setting_mini03_yn)
                                }}
                        >
                            <b>넥스트바카라</b>
                        </button>
                        <button style={{ backgroundColor: initialState.settingMini04Yn == '' ? (setting_mini04_yn == 'Y' ? "blue" : "lightgray") : (initialState.settingMini04Yn == 'Y' ? "blue" : "lightgray"), border: "1", color: initialState.settingMini04Yn == '' ? (setting_mini04_yn == 'Y' ? "white" : "black") : (initialState.settingMini04Yn == 'Y' ? "white" : "black") ,fontSize: "10px", height: "22px", width: "80px", marginRight: "5px", marginTop:"10px"}}
                                onClick={(e) => {
                                    changeSettingMini04Yn(initialState.settingMini04Yn, setting_mini04_yn)
                                }}
                        >
                            <b>토큰파워볼</b>
                        </button>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "lightgray", border: "1", color: "black" ,fontSize: "10px", height: "25px", width: "60px", marginRight: "5px", marginTop:"10px"}}
                            onClick={() => {updateMainBtnState()}}
                    >
                        변경
                    </button>
                </div>
            </div>
        </div>
    )
}
export default LvlSettingMinitDtl;