import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getETCs, setETCs} from "../../redux/reducers/board/ETCReducer";
import axios from "axios";
import Pagination from "../../components/common/Pagination2";
const ETC = () => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state.etc)
    const userInfoId = useSelector((state) => state.userInfo.id);

    useEffect(() => {
        dispatch(getETCs({
            id: userInfoId,
            page: state.page,
            pageSize: state.pageSize,
        }))
    }, [])

    const upHandler = (index) => {
        if(index > 0) {
            const upItem = {...state.gridList[index]}
            const changedItem = {...state.gridList[index - 1]}
            let arr = [...state.gridList]
            console.log(state.gridList)
            const upPrintSeq = upItem.print_seq
            upItem.print_seq = changedItem.print_seq
            changedItem.print_seq = upPrintSeq
            arr[index] = changedItem
            arr[index - 1] = upItem
            console.log(arr)
            dispatch(setETCs({gridList: arr}))
        }
    }
    const downHandler = (index) => {
        if(index < state.gridList.length - 1) {
            const upItem = {...state.gridList[index]}
            const changedItem = {...state.gridList[index + 1]}
            let arr = [...state.gridList]
            console.log(state.gridList)
            const upPrintSeq = upItem.print_seq
            upItem.print_seq = changedItem.print_seq
            changedItem.print_seq = upPrintSeq
            arr[index] = changedItem
            arr[index + 1] = upItem
            console.log(arr)
            dispatch(setETCs({gridList: arr}))
        }
    }

    const saveHandler = () => {
        const arr = []
        for(const item of state.gridList) {
            arr.push({
                no: item.no,
                print_seq: item.print_seq
            })
        }
        if(arr.length > 0) {
            axios.post('/admin/board/saveETCOrder', {
                etcs: arr
            }).then((res) => {
                if(res.data.isUpdated) {
                    alert("저장완료")
                    window.location.reload()
                }
                else {
                    alert("저장실패")
                }
            })
        }
        else {
            alert("저장할게없습니다.")
        }
    }
    const handlePageChange = (page) => {
        dispatch(getETCs({
            id: userInfoId,
            page: page,
            pageSize: state.pageSize,
        }))
    }
    return (
        <div>
            <div className="sm-font">
                <div>
                    <p>
                        <span style={{ color: "#1F68EE", fontWeight: "bold" }}>공지팝업/가입쪽지/도메인안내</span>
                    </p>
                    <p>
                        <span>* 가입시 자동쪽지는 제목을 - 가입쪽지 - 로 저장하시면 됩니다. 가입쪽지라는 단어만 들어간다면 앞뒤로 어떤 단어가 붙어도 인식합니다.</span>
                        <br/>
                        <span>* 가입쪽지는 부본사/총판/가맹 별로 구분해 발송하실 수 있으며,</span>
                        <br/>
                        <span>제목에 [가입쪽지][부본사:<span style={{color: "red"}}>부본사아이디</span>] 라고 적으면 해당 부본사로 가입시에만 전달됩니다.</span>
                        <br/>
                        <span>제목에 [가입쪽지][총판:<span style={{color: "red"}}>총판아이디</span>] 라고 적으면 해당 총판으로 가입시에만 전달됩니다.</span>
                        <br/>
                        <span>제목에 [가입쪽지][가맹:<span style={{color: "red"}}>가맹아이디</span>] 라고 적으면 해당 가맹으로 가입시에만 전달됩니다.</span>
                        <br/>
                        <span>제목에 [가입쪽지][제목:<span style={{color: "red"}}>쪽지제목을 여기에 쓰세요</span>] 라고 적으면 쪽지 발송시 제목을 이렇게 보냅니다.</span>
                        <br/>
                        <span>* 도메인 안내용 페이지는 제목을 - 도메인안내 - 를 넣어 저장하시면 됩니다.</span>
                        <br/>
                        <span>* 위 두 경우는 출력중일 때만 작동합니다.</span>
                    </p>

                    <div className="sm-center-div-no-border-100" style={{justifyContent:"end"}}>
                        <button style={{ marginRight: "10px", backgroundColor: "#1F68EE", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}} onClick={saveHandler}>순서 저장</button>
                        <button style={{ marginRight: "10px", backgroundColor: "#1F68EE", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}}>
                            <Link to="/board/etc/insert" target="_blank" rel="noopener noreferrer" >
                                글쓰기
                            </Link>
                        </button>
                    </div>

                </div>

                <div className="sm-font">
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>출력순서</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>번호</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>출력여부</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>사이트코드</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>제목</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>등록일</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이피</td>
                        </tr>
                        </thead>
                        <tbody>
                        {state.gridList && state.gridList.map((item, index) => (
                            <tr>
                                <td>
                                    <div>
                                        {index === 0? (<></>) : (
                                            <button style={{ marginRight: "1px", backgroundColor: "#D7D4CF", border: "none", color: "black" ,fontSize: "9px", height: "20px", width: "fit-content"}}
                                                    onClick={(e) => {
                                                        upHandler(index)
                                                    }}>↑</button>
                                        )}
                                        {index === state.gridList.length - 1? (<></>) : (
                                            <button style={{ backgroundColor: "#D7D4CF", border: "none", color: "black" ,fontSize: "9px", height: "20px", width: "fit-content"}}
                                                    onClick={(e) => {
                                                        downHandler(index)
                                                    }}>↓</button>
                                        )}
                                    </div>
                                </td>
                                <td>{item.no}</td>
                                <td><span style={{ color: item.print_yn_cd === "01"? "#1F68EE" : "#CECECE" }}>{item.print_yn_cd === "01"? "출력중" : "미출력중"}</span></td>
                                <td>{item.print_site_cd}</td>
                                <td>
                                    <Link to={"/board/etc/insert/" + item.no} target="_blank" rel="noopener noreferrer" >
                                        {item.title}
                                    </Link>
                                </td>
                                <td>{item.createdAt}</td>
                                <td><span style={{ color: "#1F68EE" }}>{item.use_ip}</span></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {state.totalCount && state.totalCount > 0 ?
                    <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange} />
                    :""}
            </div>
        </div>

    )
}
export default ETC;