import React, {useEffect, useState} from "react";
import axios from "axios";
import PlusMinusText from "../../components/common/PlusMinusText";
import {NumericFormat} from "react-number-format";
import moment from "moment";
const MarginContainer = ({userId}) => {

    const [list, setList] = useState([])
    const [startDate, setStartDate] = useState(moment().add(-29, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
    const [nickname, setNickname] = useState("")
    const [depositAllCount, setDepositAllCount] = useState(0)
    const [depositAllTotal, setDepositAllTotal] = useState(0)
    const [withdrawAllCount, setWithdrawAllCount] = useState(0)
    const [withdrawAllTotal, setWithdrawAllTotal] = useState(0)
    const [selectedBtn, setSelectedBtn] = useState(moment().format("YYYY-MM-DD"))

    useEffect(() => {
        getMarginList(startDate, endDate)
    }, [])

    const getMarginList = (start, end) => {
        axios.post('/admin/deposit/getMarginList', {
            userId: userId,
            startDate: start,
            endDate: end,
        }).then((res) => {
            setList(res.data.list)
            setNickname(res.data.nickname)
            setDepositAllCount(res.data.depositAllCount)
            setDepositAllTotal(res.data.depositAllTotal)
            setWithdrawAllCount(res.data.withdrawAllCount)
            setWithdrawAllTotal(res.data.withdrawAllTotal)

        })
    }

    const dataBtnListener = (e) => {
        setStartDate(e.currentTarget.name)
        setEndDate(moment().format("YYYY-MM-DD"))
        getMarginList(e.currentTarget.name, moment().format("YYYY-MM-DD"))
    }

    return (
        <div className="sm-font mx-3" style={{marginTop: "30px", height: "fit-content"}}>
            <div style={{ backgroundColor: "white"}}>
                <p style={{color:"#3fadf7"}}>마진로그</p>
                <div className="d-flex align-items-center">
                    <button name={moment().add(-729, "days").format("YYYY-MM-DD")} style={{
                        backgroundColor: startDate === moment().add(-729, "days").format("YYYY-MM-DD")? "red": "#3fadf7",
                        border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dataBtnListener}>730일</button>
                    <button name={moment().add(-364, "days").format("YYYY-MM-DD")} style={{
                        backgroundColor: startDate === moment().add(-364, "days").format("YYYY-MM-DD")? "red": "#3fadf7",
                        border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dataBtnListener}>365일</button>
                    <button name={moment().add(-179, "days").format("YYYY-MM-DD")} style={{
                        backgroundColor: startDate === moment().add(-179, "days").format("YYYY-MM-DD")? "red": "#3fadf7",
                        border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dataBtnListener}>180일</button>
                    <button name={moment().add(-89, "days").format("YYYY-MM-DD")} style={{
                        backgroundColor: startDate === moment().add(-89, "days").format("YYYY-MM-DD")? "red": "#3fadf7",
                        border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dataBtnListener}>90일</button>
                    <button name={moment().add(-59, "days").format("YYYY-MM-DD")} style={{
                        backgroundColor: startDate === moment().add(-59, "days").format("YYYY-MM-DD")? "red": "#3fadf7",
                        border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dataBtnListener}>60일</button>
                    <button name={moment().add(-29, "days").format("YYYY-MM-DD")} style={{
                        backgroundColor: startDate === moment().add(-29, "days").format("YYYY-MM-DD")? "red": "#3fadf7",
                        border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dataBtnListener}>30일</button>
                    <button name={moment().add(-6, "days").format("YYYY-MM-DD")} style={{
                        backgroundColor: startDate === moment().add(-6, "days").format("YYYY-MM-DD")? "red": "#3fadf7",
                        border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dataBtnListener}>7일</button>
                    <button name={moment().add(-1, "days").format("YYYY-MM-DD")} style={{
                        backgroundColor: startDate === moment().add(-1, "days").format("YYYY-MM-DD")? "red": "#3fadf7",
                        border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dataBtnListener}>어제</button>
                    <button name={moment().format("YYYY-MM-DD")} style={{
                        backgroundColor: startDate === moment().format("YYYY-MM-DD")? "red": "#3fadf7",
                        border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={dataBtnListener}>오늘</button>
                    <div style={{display: "flex", paddingLeft: "5px", paddingRight: "5px", alignItems: "center"}}>
                        <span className="mx-1">◀</span>
                        <input className="form-control sm-date" type="date" value={startDate} style={{width: "120px"}} onChange={(e) => {
                            setStartDate(e.currentTarget.value)
                            getMarginList(e.currentTarget.value, endDate)
                        }}/>
                        <span className="mx-1">~</span>
                        <input className="form-control sm-date" type="date" value={endDate} style={{width: "120px"}} onChange={(e) => {
                            setEndDate(e.currentTarget.value)
                            getMarginList(startDate, e.currentTarget.value)
                        }}/>
                        <span className="mx-1">▶</span>
                    </div>
                </div>

                <div className="w-100" style={{height: "30px"}}>
                    <p style={{float:"right"}}>
                        <span className="me-2" style={{color: "blue"}}>[충전 : {depositAllCount}건 (<PlusMinusText number={depositAllTotal}/>원)]</span>
                        <span className="me-2" style={{color: "red"}}>[환전 : {withdrawAllCount}건 (<NumericFormat value={withdrawAllTotal} displayType="text" thousandSeparator="true"/>원)]</span>
                        <span>[마진 : (<PlusMinusText number={depositAllTotal - withdrawAllTotal}/>원)]</span>
                    </p>

                </div>

                <div className="sm-font">
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>날짜</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>충전금</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>충전횟수</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>환전금</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>환전횟수</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>마진</td>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map((item, index) => (
                            <tr>
                                <td>{item.complete_dt}</td>
                                <td>{userId}</td>
                                <td>{nickname}</td>
                                <td><PlusMinusText number={item.deposit_total}/></td>
                                <td>{item.deposit_count}</td>
                                <td><PlusMinusText number={item.withdraw_total * -1}/></td>
                                <td>{item.withdraw_count}</td>
                                <td><PlusMinusText number={item.deposit_total - item.withdraw_total} /></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

    )
}
export default MarginContainer;