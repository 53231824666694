import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import imageCompression from "browser-image-compression";
import Editor from "../../components/common/Editor";
import {insertAdminLog} from "../../lib/api";


const InsertETCPopup = () => {

    const userInfoId = useSelector((state) => state.userInfo.id);
    const userInfoIp = useSelector((state) => state.userInfo.ip);
    const param = useParams();

    const [siteCd, setSiteCd] = useState('')
    const [leftPos, setLeftPos] = useState(-1)
    const [topPos, setTopPos] = useState(-1)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [title, setTitle] = useState('')
    const [cont, setCont] = useState('')
    const [isPCDelete, setIsPCDelete] = useState(false)
    const [isMobileDelete, setIsMobileDelete] = useState(false)
    const [regId, setRegId] = useState(null)

    const [pcImageFile, setPcImageFile] = useState()
    const [pcImageUrl, setPcImageUrl] = useState()
    const [mobileImageFile, setMobileImageFile] = useState()
    const [mobileImageUrl, setMobileImageUrl] = useState()
    const [printSeq, setPrintSeq] = useState()
    const [printYnCd, setPrintYnCd] = useState(null)


    const imageOptions = {
        maxSizeMB: 100,
        maxWidth: 1920,
        useWebWorker: true,
    }

    useEffect(() => {
        if(param.etcNo) {
            axios.post('/admin/board/getETC', {
                etcNo: param.etcNo
            }).then((res) => {
                const etc = res.data.result
                setSiteCd(etc.print_site_cd)
                setTitle(etc.title)
                setCont(etc.cont)
                setRegId(etc.reg_id)
                setPrintSeq(etc.print_seq)
                setPrintYnCd(etc.print_yn_cd)
                setLeftPos(etc.x_coordinate)
                setTopPos(etc.y_coordinate)
                setWidth(etc.width_pixel)
                setHeight(etc.height_pixel)
                setPcImageUrl(etc.pc_image_url? etc.pc_image_url : undefined)
                setMobileImageUrl(etc.mb_image_url? etc.mb_image_url : undefined)
            })
        }
        else {
            insertAdminLog({
                id: userInfoId,
                logContent: "기타게시판 글쓰기화면"
            }).then((res) => {
                if(!res.data.isInserted) {
                    alert("로그삽입실패 개발자문의")
                }
            })
        }
    }, [])
    const siteCdChangeHandler = (e) => {
        setSiteCd(e.currentTarget.value);
    }
    const leftPosChangeHandler = (e) => {
        setLeftPos(e.currentTarget.value);
    }
    const topPosChangeHandler = (e) => {
        setTopPos(e.currentTarget.value);
    }
    const widthChangeHandler = (e) => {
        setWidth(e.currentTarget.value);
    }
    const heightChangeHandler = (e) => {
        setHeight(e.currentTarget.value);
    }
    const titleChangeHandler = (e) => {
        setTitle(e.currentTarget.value);
    }
    const isPCDeleteChangeHandler = (e) => {
        setIsPCDelete(e.currentTarget.checked)
    }
    const isMobileDeleteChangeHandler = (e) => {
        setIsMobileDelete(e.currentTarget.checked)
    }
    const contChangeHandler = (e) => {
        setCont(e.currentTarget.value)
    }

    const onCloseHandler = (e) => {
        if(window.confirm("정말 닫으시곘습니까?")) {
            window.close()
        }
    }

    const saveHandler = (e) => {
        const data = {
            print_site_cd: siteCd,
            print_yn_cd: '01',
            print_seq: printSeq,
            title: title,
            cont: cont,
            x_coordinate: leftPos,
            y_coordinate: topPos,
            width_pixel: width,
            height_pixel: height,
            isPCDelete: isPCDelete,
            isMobileDelete: isMobileDelete,
            use_ip: userInfoIp,
            reg_id: regId? regId : userInfoId,
            upd_id: userInfoId
        }
        if(param.etcNo) {
            data.no = param.etcNo
        }
        if(printYnCd) {
            data.print_yn_cd = printYnCd
        }
        const formData = new FormData()
        if(pcImageFile !== undefined) {
            formData.append("pcImage", pcImageFile)
        }
        if(mobileImageFile !== undefined) {
            formData.append("mobileImage", mobileImageFile)
        }
        formData.append("data", JSON.stringify(data))
        const headers = {
            "Content-Type": "multipart/form-data"
        };

        axios.post('/admin/board/insertETC', formData, {headers}).then((res) => {
            if (res.data.isInserted) {
                alert('저장완료')
                window.close()
            } else {
                alert('저장실패')
            }
        })
    }

    const deleteETC = (eNo) => {
        if(window.confirm("정말 삭제하시겠습니까?")) {
            axios.post("/admin/board/deleteETC", {
                eNo: eNo
            }).then((res) => {
                if(res.data.isDeleted) {
                    window.close()
                }
            })
        }

    }

    const changeETCPrint = (eNo, printYnCode) => {
        axios.post("/admin/board/changeETCPrint", {
            eNo: eNo,
            printYn: printYnCode,
        }).then((res) => {
            if(res.data.isUpdated) {
                alert("수정완료")
                window.close()
            }
        })
    }

    const previewImage = async (e, change) => {
        e.preventDefault();
        const file = e.target.files[0];
        try {
            const compressedFile = await imageCompression(file, imageOptions);
            const reader = new FileReader();

            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                // 변환 완료!
                const base64data = reader.result;

                handlingDataForm(base64data, change, file.name);
            };
        } catch (error) {
            alert("이미지파일 등록실패")
        }
    }

    const handlingDataForm = async (base64data, change, fileName) => {
        // dataURL 값이 data:image/jpeg:base64,~~~~~~~ 이므로 ','를 기점으로 잘라서 ~~~~~인 부분만 다시 인코딩
        const byteString = atob(base64data.split(",")[1]);

        // Blob를 구성하기 위한 준비, 이 내용은 저도 잘 이해가 안가서 기술하지 않았습니다.
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
            type: "image/jpg"
        });
        const file = new File([blob], fileName);
        console.log(file)
        // 위 과정을 통해 만든 image폼을 FormData에 넣어줍니다.
        // 서버에서는 이미지를 받을 때, FormData가 아니면 받지 않도록 세팅해야합니다.

        if (change === 0) {
            const fileUrl = URL.createObjectURL(file); // (*)
            setPcImageFile(file)
            setPcImageUrl(fileUrl)
        } else {
            const fileUrl = URL.createObjectURL(file); // (*)
            setMobileImageFile(file)
            setMobileImageUrl(fileUrl)
        }
    }

    return <div>
        <p>기타</p>
        <table className="table table-bordered table-sm text-center align-middle">
            <tbody>

            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    글제목
                </td>
                <td colSpan="3">
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <input className="form-check-input sm-input" type="text" style={{ width: "400px" }} placeholder="" value={title} onChange={titleChangeHandler}/>
                        <p style={{fontSize: "9px"}}>
                            * 제목을 [가입쪽지] 로 시작하시면 아래 작성된 내용이 회원 가입시 자동으로 쪽지 발송이 됩니다. (공지 팝업으로 표시되지 않음)
                            <br/>
                            * 제목을 [도메인안내] 로 시작하시고, 사이트코드에 도메인을 넣으면(콤마로 구분), 입력하신 도메인 접속시 아래 내용이 표시됩니다.(로그인화면 대신)
                            <br/>
                            * 단 위의 경우들은 이 게시글이 [출력중] 상태에서만 작동합니다. (미출력시 작동하지 않음)
                        </p>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    <div>
                        썸네일-PC
                        <br/>
                        <label className="form-check-label" htmlFor="chk_pc">
                            삭제
                        </label>
                        <input className="form-check-input ms-2" type="checkbox" id="chk_pc" checked={isPCDelete} onChange={isPCDeleteChangeHandler} />
                    </div>
                </td>
                <td colSpan="3">
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <input type="file" accept=".jpeg, .jpg, .png" style={{ width: "300px", height: "40px" }} onChange={(e) => previewImage(e,0)}/>
                        <img  src={pcImageUrl} alt=""/>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    <div>
                        썸네일-모바일
                        <br/>
                        <label className="form-check-label" htmlFor="chk_mobile">
                            삭제
                        </label>
                        <input className="form-check-input ms-2" type="checkbox" id="chk_mobile" checked={isMobileDelete} onChange={isMobileDeleteChangeHandler} />
                    </div>
                </td>
                <td colSpan="3">
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <input type="file" accept=".jpeg, .jpg, .png" style={{ width: "300px", height: "40px" }} onChange={(e) => previewImage(e,1)}/>
                        <img  src={mobileImageUrl} alt=""/>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    SITE CODE
                </td>
                <td colSpan="3">
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <input className="form-check-input sm-input" type="text" style={{ width: "200px" }} placeholder="" value={siteCd} onChange={siteCdChangeHandler}/>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    왼쪽 좌표
                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start", display: "flex"}}>
                        <input className="form-check-input sm-input me-1" type="text" style={{ width: "100px" }} placeholder="" value={leftPos} onChange={leftPosChangeHandler}/>
                        <span style={{fontSize: "9px"}}> * 비워두면 가운데로 정렬됩니다.</span>
                    </div>
                </td>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    상단 좌표
                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <input className="form-check-input sm-input me-1" type="text" style={{ width: "100px" }} placeholder="" value={topPos} onChange={topPosChangeHandler}/>
                        <span style={{fontSize: "9px"}}> * 비워두면 상단으로 정렬됩니다.</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    가로 폭(pixel)
                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start", display: "flex"}}>
                        <input className="form-check-input sm-input me-1" type="text" style={{ width: "100px" }} placeholder="" value={width} onChange={widthChangeHandler}/>
                        <span style={{fontSize: "9px"}}> * 비워두면 기본 크기로 설정됩니다.</span>
                    </div>
                </td>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    세로 폭(pixel)
                </td>
                <td>
                    <div className="container-fluid p-0 mx-1" style={{textAlign: "start"}}>
                        <input className="form-check-input sm-input me-1" type="text" style={{ width: "100px" }} placeholder="" value={height} onChange={heightChangeHandler}/>
                        <span style={{fontSize: "9px"}}> * 비워두면 기본 크기로 설정됩니다.</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{backgroundColor: "lightgray", width: "15%", fontSize: "9px"}}>
                    내용
                </td>
                <td colSpan="3">
                    <Editor cont={cont} setCont={setCont} />
                </td>
            </tr>
            </tbody>

        </table>

        <div className="d-flex">
            <div style={{display: "flex", textAlign: "end", width: "100%"}}>
                <button className="btn btn-outline-info btn-sm mx-1" onClick={onCloseHandler}>닫기</button>
                <button className="btn btn-outline-info btn-sm ms-1" onClick={saveHandler}>저장</button>
            </div>
            {param.etcNo && (
                <div className="d-flex" style={{marginLeft: "auto"}}>
                    {printYnCd && (
                        <>
                            {printYnCd === "01"? (
                                <button className="btn btn-warning btn-sm mx-1" onClick={(e) => {
                                    changeETCPrint(param.etcNo, "02")
                                }}>미출력</button>
                            ) : (
                                <button className="btn btn-primary btn-sm mx-1" onClick={(e) => {
                                    changeETCPrint(param.etcNo, "01")
                                }}>출력</button>
                            )}
                        </>

                    )}
                    <button className="btn btn-danger btn-sm ms-1" onClick={(e) => {
                        deleteETC(param.etcNo)
                    }}>삭제</button>
                </div>
            )}
        </div>


    </div>
}

export default InsertETCPopup