import React, {useEffect} from "react";
import AdminList from "../../components/monitoring/AdminList";
import {useDispatch, useSelector} from "react-redux";
import {getAdmins} from "../../redux/reducers/monitoring/AdminReducer";
import Pagination from "../../components/common/Pagination";
const Admin = () => {

    const dispatch = useDispatch();
    const state = useSelector((state) => state.admin)

    const input = {
        page: state.page,
        pageSize: state.pageSize,
    }


    useEffect(() => {
        const fixInput = {...input}
        dispatch(getAdmins(fixInput))
    }, [])

    const onRefresh = () => {
        const fixInput = {...input}
        dispatch(getAdmins(fixInput))
    }

    const handlePageChange = (page) => {
        const fixInput = {...input}
        fixInput.page = page
        dispatch(getAdmins(fixInput))
    }

    return (
        <div className="sm-font">
            <font color={"blue"} size="3">관리자 모니터링</font>&nbsp;
            <AdminList state={state} onRefresh={onRefresh} />
            <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange}/>
        </div>

    )
}
export default Admin;