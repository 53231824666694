import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    gridList : [],
    gridCount : 0,
    input : {},
    inputSave:{
        no: 0,
        useYn: 'Y',
    }
}

export const statsSlice = createSlice({
    name: 'DangerAccountSettingListReducer',
    initialState,
    reducers: {
        getDangerAccountSettingListReducer(state, action){
            console.log("#reducers getDangerAccountSettingListReducer start#")
        },
        setDangerAccountSettingListReducer(state, action) {
            console.log("#reducers setDangerAccountSettingListReducer start#",action.payload.gridList);
            state.gridCount = action.payload.gridCount;
            state.gridList = action.payload.gridList;
        },
        failedGetDangerAccountSettingListReducer(state, action) {
            state.blackTypeCd = action.payload;
        },
    }
});

export const {
    getDangerAccountSettingListReducer,
    setDangerAccountSettingListReducer,
    failedGetDangerAccountSettingListReducer,
} = statsSlice.actions;

export default statsSlice.reducer;