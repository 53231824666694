import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"
import {getActiveFixture} from "../../../redux/reducers/game/manage/activeMatch";
import ActiveList from "../../../components/game/manage/ActiveList";
import Pagination from "../../../components/common/Pagination";

const ActiveContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const state = useSelector((state) => state.activeMatch)
    const [searchQuery, setSearchQuery] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    const input = {
        page: state.page,
        pageSize: state.pageSize,
        sport: state.sport,
        provider: state.provider,
        league: state.league,
        query: searchQuery,
    }
    useEffect(() => {
        dispatch(getActiveFixture(input))
    }, [])

    const handlePageChange = (page) => {
        const fixInput = {...input}
        fixInput.page = page
        dispatch(getActiveFixture(fixInput))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.query = query
        dispatch(getActiveFixture(fixInput))
    }
    const onClickSearch = (e) => {
        dispatch(getActiveFixture(input))
    }

    const onRefresh = () => {
        dispatch(getActiveFixture(input))
    }

    const startIndex = ((state.page - 1) * state.pageSize) + 1;
    const currentMaxCount = state.totalCount < (startIndex + state.pageSize - 1)? state.totalCount : (startIndex + state.pageSize - 1)
    return <>
        <p className="h5 ps-2">{ startIndex + "~" + currentMaxCount + " / " + state.totalCount}</p>
        <div className="container-fluid m-1 p-0" style={{
            display: "flex"
        }}>
            <select
                className="form-select me-1"
                defaultValue={100}
                style={{
                    width: "100px"
                }}
                value={state.pageSize === "999"? "모두" : state.pageSize}
                onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.pageSize = parseInt(e.currentTarget.value)
                    dispatch(getActiveFixture(input))
                }}
            >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={999}>모두</option>
            </select>
            <select
                className="form-select me-1"
                defaultValue={0}
                style={{
                    width: "200px"
                }}
                value={state.provider === "0"? "업체" : state.provider}
                onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.provider = parseInt(e.currentTarget.value)
                    dispatch(getActiveFixture(input))
                }}
            >
                <option value={0}>업체</option>
                {state.providers.map(m => (
                    <option value={m.id}>{m.name}</option>
                ))}
            </select>
            <select
                className="form-select me-1"
                defaultValue={0}
                style={{
                    width: "250px"
                }}
                value={state.sport === "0"? "종목" : state.sport}
                onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.sport = parseInt(e.currentTarget.value)
                    dispatch(getActiveFixture(input))
                }}
            >
                <option value={0}>종목</option>
                {state.sports.map(m => (
                    <option value={m.id}>{m.name}</option>
                ))}
            </select>
            <select
                className="form-select me-1"
                defaultValue={0}
                style={{
                    width: "350px"
                }}
                value={state.league === "0"? "리그" : state.league}
                onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.league = parseInt(e.currentTarget.value)
                    dispatch(getActiveFixture(fixInput))
                }}
            >
                <option value={0}>리그</option>
                {state.leagues.map(m => (
                    <option value={m.id}>{m.name}</option>
                ))}
            </select>

            <div className="input-group me-1" style={{
                display: "flex",
                width: "300px"
            }}>
                <input style={{
                    width: "220px"
                }} type="text" className="form-control" id="search_edit" placeholder="리그,팀명,ID를 입력하세요." onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
            </div>
        </div>
        <div className="p-0 m-0">
            {state.leagues.map(m => (
                <button type="button" className="btn btn-secondary m-1" onClick={ (e) => {
                    const fixInput = {...input}
                    fixInput.league = parseInt(m.id)
                    dispatch(getActiveFixture(fixInput))
                }}>
                    {m.name}
                </button>
            ))}
        </div>

        <ActiveList state={state} onRefresh={onRefresh} />
        <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange}/>
    </>

}
export default ActiveContainer