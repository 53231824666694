import Switch from "react-switch";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {setGameKinds} from "../../../redux/reducers/game/gameSetting";


const SportSetting = () => {
    const [basics, setBasics] = useState([])
    const [details, setDetails] = useState([])
    const [marks, setMarks] = useState([])

    useEffect(() => {
        getGameSetting()
    }, [])

    const getGameSetting = () => {
        axios.post('/admin/game/setting/getGameSetting', {

        }).then((res) => {
            setBasics([])
            setBasics(res.data.basics)
            setDetails(res.data.details)
            setMarks(res.data.marks)
        })
    }

    const changeBasic = (item) => {
        axios.post('/admin/game/setting/changeBasic', {
            item: item,
        }).then((res) => {
            if(res.data.isChanged) {
                alert("변경완료")
                getGameSetting()
            }
            else {
                alert("변경실패")
            }
        })
    }
    const changeMark = (item) => {
        axios.post('/admin/game/setting/changeMark', {
            item: item,
        }).then((res) => {
            if(res.data.isChanged) {
                alert("변경완료")
            }
            else {
                alert("변경실패")
            }
        })
    }
    const changeDetail = (item) => {
        axios.post('/admin/game/setting/changeDetail', {
            item: item,
        }).then((res) => {
            if(res.data.isChanged) {
                alert("변경완료")
            }
            else {
                alert("변경실패")
            }
        })
    }

    return <div className="common">
        <font color={"blue"} size="3">스포츠 기본설정</font>
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>종목</th>
                    <th>사용</th>
                    <th>점검</th>
                    <th>점검메세지</th>
                    <th>정렬</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {basics.map((item, index) => (
                    <tr>
                        <td>{item.game_kind.name_kor? item.game_kind.name_kor : item.game_kind.name}</td>
                        <td>
                            <Switch onChange={(checked) => {
                                const newItem = {...item}
                                newItem.is_use = checked
                                const arr = [...basics]
                                arr[index] = newItem
                                setBasics(arr)
                            }} checked={item.is_use} onColor="#2196F3"/>
                        </td>
                        <td>
                            <Switch onChange={(checked) => {
                                const newItem = {...item}
                                newItem.is_check = checked
                                const arr = [...basics]
                                arr[index] = newItem
                                setBasics(arr)
                            }} checked={item.is_check} onColor="#2196F3"/>
                        </td>
                        <td>
                            <input className="sm-input" type="text" value={item.check_msg} style={{ width: '100%' }}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.check_msg = e.currentTarget.value
                                       const arr = [...basics]
                                       arr[index] = newItem
                                       setBasics(arr)
                                   }}/>
                        </td>
                        <td>
                            <select className="sm-select" value={item.print_rank} onChange={(e) => {
                                const newItem = {...item}
                                newItem.print_rank = e.currentTarget.value
                                const arr = [...basics]
                                arr[index] = newItem
                                setBasics(arr)
                            }}>
                                <option value={null}>출력순서</option>
                                {basics.map((_, index) => (
                                    <option value={index + 1}>{index + 1}번째</option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={(e) => {
                                changeBasic(item)
                            }}>변경</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>표기</th>
                    <th>사용</th>
                    <th>점검</th>
                    <th>로그인/로그아웃 경기 노출</th>
                    <th>점검메세지</th>
                    <th>한 페이지 경기 노출 수</th>
                    <th>시작전 경기 노출 시작</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {marks.map((item, index) => (
                    <tr>
                        <td>{item.name}</td>
                        <td>
                            <Switch onChange={(checked) => {
                                const newItem = {...item}
                                newItem.is_use = checked
                                const arr = [...marks]
                                arr[index] = newItem
                                setMarks(arr)
                            }} checked={item.is_use} onColor="#2196F3"/>
                        </td>
                        <td>
                            <Switch onChange={(checked) => {
                                const newItem = {...item}
                                newItem.is_check = checked
                                const arr = [...marks]
                                arr[index] = newItem
                                setMarks(arr)
                            }} checked={item.is_check} onColor="#2196F3"/>
                        </td>
                        <td>
                            <Switch onChange={(checked) => {
                                const newItem = {...item}
                                newItem.is_need_login = checked
                                const arr = [...marks]
                                arr[index] = newItem
                                setMarks(arr)
                            }} checked={item.is_need_login} onColor="#2196F3"/>
                        </td>

                        <td>
                            <input className="sm-input" type="text" style={{ width: '100%' }} value={item.check_msg}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.check_msg = e.currentTarget.value
                                       const arr = [...marks]
                                       arr[index] = newItem
                                       setMarks(arr)
                                   }}/>
                        </td>
                        <td>
                            <input className="sm-input" type="number" style={{ width: '100%' }} value={item.print_cnt}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.print_cnt = e.currentTarget.value
                                       const arr = [...marks]
                                       arr[index] = newItem
                                       setMarks(arr)
                                   }}/>
                        </td>
                        <td>
                            <input className="sm-input" type="number" style={{ width: '100%' }} value={item.print_time}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.print_time = e.currentTarget.value
                                       const arr = [...marks]
                                       arr[index] = newItem
                                       setMarks(arr)
                                   }}/>
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={(e) => {
                                changeMark(item)
                            }}>변경</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th rowSpan={2}>종목</th>
                    <th colSpan={4}>기준점설정</th>
                    <th rowSpan={2}>라이브<br/>배팅대기시간</th>
                    <th rowSpan={2}>마켓최소배당<br/>(이하일시 강제적용)</th>
                    <th rowSpan={2}>마켓최대배당<br/>(이상일시 강제적용)</th>
                    <th rowSpan={2}>핸디캡(0.5, -0.5)사용</th>
                    <th rowSpan={2}></th>
                </tr>
                <tr>
                    <th style={{width: "120px"}}>국내형</th>
                    <th style={{width: "120px"}}>해외형</th>
                    <th style={{width: "120px"}}>라이브</th>
                    <th style={{width: "120px"}}>스페셜</th>
                </tr>
                </thead>
                <tbody>
                {details.map((item, index) => (
                    <tr>
                        <td>{item.game_kind.name_kor? item.game_kind.name_kor : item.game_kind.name}</td>
                        <td>
                            <select className="sm-select" value={item.baseline_use}
                                    onChange={(e) => {
                                        const newItem = {...item}
                                        newItem.baseline_use = e.currentTarget.value
                                        const arr = [...details]
                                        arr[index] = newItem
                                        setDetails(arr)
                                    }}>
                                <option value={0}>모두사용</option>
                                <option value={1}>0만사용</option>
                                <option value={2}>.5만사용</option>
                            </select>
                        </td>
                        <td>
                            <select className="sm-select" value={item.foreign_baseline_use}
                                    onChange={(e) => {
                                        const newItem = {...item}
                                        newItem.foreign_baseline_use = e.currentTarget.value
                                        const arr = [...details]
                                        arr[index] = newItem
                                        setDetails(arr)
                                    }}>
                                <option value={0}>모두사용</option>
                                <option value={1}>0만사용</option>
                                <option value={2}>.5만사용</option>
                            </select>
                        </td>
                        <td>
                            <select className="sm-select" value={item.live_baseline_use}
                                    onChange={(e) => {
                                        const newItem = {...item}
                                        newItem.live_baseline_use = e.currentTarget.value
                                        const arr = [...details]
                                        arr[index] = newItem
                                        setDetails(arr)
                                    }}>
                                <option value={0}>모두사용</option>
                                <option value={1}>0만사용</option>
                                <option value={2}>.5만사용</option>
                            </select>
                        </td>
                        <td>
                            <select className="sm-select" value={item.special_baseline_use}
                                    onChange={(e) => {
                                        const newItem = {...item}
                                        newItem.special_baseline_use = e.currentTarget.value
                                        const arr = [...details]
                                        arr[index] = newItem
                                        setDetails(arr)
                                    }}>
                                <option value={0}>모두사용</option>
                                <option value={1}>0만사용</option>
                                <option value={2}>.5만사용</option>
                            </select>
                        </td>
                        <td>
                            <div className="input-group">
                                <input className="sm-input" type="number" value={item.live_sec} onChange={(e) => {
                                    const newItem = {...item}
                                    newItem.live_sec = e.currentTarget.value
                                    const arr = [...details]
                                    arr[index] = newItem
                                    setDetails(arr)
                                }}/>
                                <span className="input-group-text">초</span>
                            </div>
                        </td>
                        <td>
                            <div className="input-group">
                                <input className="sm-input" type="number" value={item.market_min} onChange={(e) => {
                                    const newItem = {...item}
                                    newItem.market_min = e.currentTarget.value
                                    const arr = [...details]
                                    arr[index] = newItem
                                    setDetails(arr)
                                }}/>
                                <span className="input-group-text">배당</span>
                            </div>
                        </td>
                        <td>
                            <div className="input-group">
                                <input className="sm-input" type="number" value={item.market_max} onChange={(e) => {
                                    const newItem = {...item}
                                    newItem.market_max = e.currentTarget.value
                                    const arr = [...details]
                                    arr[index] = newItem
                                    setDetails(arr)
                                }}/>
                                <span className="input-group-text">배당</span>
                            </div>

                        </td>
                        <td>
                            <Switch onChange={(checked) => {
                                const newItem = {...item}
                                newItem.is_use_point_five = checked
                                const arr = [...details]
                                arr[index] = newItem
                                setDetails(arr)
                            }} checked={item.is_use_point_five} onColor="#2196F3"/>
                        </td>

                        <td>
                            <button className="btn btn-primary" onClick={(e) => {
                                changeDetail(item)
                            }}>변경</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

        </div>
    </div>
}
export default SportSetting