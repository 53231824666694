import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getActives} from "../../redux/reducers/monitoring/ActiveReducer";
import ActiveList from "../../components/monitoring/ActiveList";
import Pagination from "../../components/common/Pagination";
import OrgSelect from "../../components/org/OrgSelect";

const ActiveContainer = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.active);

    const [idQuery, setIdQuery] = useState("")
    const [ipQuery, setIpQuery] = useState("")


    const input = {
        idQuery: state.idQuery,
        ipQuery: state.ipQuery,
        onlyAdmin: state.onlyAdmin,
        includeChild: state.includeChild,
        page: state.page,
        pageSize: state.pageSize,
        orgId: state.orgId,
    }

    useEffect(() => {
        dispatch(getActives(input))
    }, [])

    const onRefresh = () => {
        dispatch(getActives(input))
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch()
        }
    }

    const onSearch = () => {
        const fixInput = {...input}
        fixInput.idQuery = idQuery
        fixInput.ipQuery = ipQuery
        dispatch(getActives(fixInput))
    }

    const handlePageChange = (page) => {
        const fixInput = {...input}
        fixInput.idQuery = idQuery
        fixInput.ipQuery = ipQuery
        fixInput.page = page
        dispatch(getActives(fixInput))
    }
    const setParentParam = (id) => {
        const fixInput = {...input}
        fixInput.orgId = id
        dispatch(getActives(fixInput))
    }

    return <div className="commonNew">
        <div style={{display: "flex", alignItems: "center"}}>
            <input className="form-check-input ms-2" type="checkbox" id="chk_admin" checked={state.onlyAdmin} onChange={(e) => {
                const fixInput = {...input}
                fixInput.onlyAdmin = e.currentTarget.checked
                dispatch(getActives(fixInput))
            }}/>
            <label className="form-check-label" htmlFor="chk_admin">
                관리자만 보기
            </label>
            <div className="me-1" style={{width: "300px", height: "20px"}}>
                <div style={{zIndex : 998}}>
                    <OrgSelect setChildParam={setParentParam} id={0}/>
                </div>
            </div>
            <input className="form-check-input ms-1" type="checkbox" id="chk_include" checked={state.includeChild} onChange={(e) => {
                const fixInput = {...input}
                fixInput.includeChild = e.currentTarget.checked
                dispatch(getActives(fixInput))
            }}/>
            <label className="form-check-label" htmlFor="chk_include">
                하위 포함
            </label>
            <label className="ms-3">
                검색 아이피 :
            </label>
            <input className="form-control sm-input ms-1" type="text" value={ipQuery} onChange={(e) => {
                setIpQuery(e.currentTarget.value)
            }} onKeyDown={inputPress}/>
            <label className="ms-1">
                아이디 :
            </label>
            <input className="form-control sm-input ms-1" type="text" value={idQuery} onChange={(e) => {
                setIdQuery(e.currentTarget.value)
            }} onKeyDown={inputPress}/>
            <button onClick={onSearch}>검색</button>

        </div>
        <ActiveList state={state} onRefresh={onRefresh} />
        <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange}/>
    </div>
}

export default ActiveContainer