import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {setIsOpen,setOrg,setGb,setOrg1,setOrg2,setOrg3,setCntLimitList,setRecommedKey,setRecommendOrgAdminId,setUseCnt,setCntLimit,setTimeLimit,setRecommendUserId,setLink,setRegChargePoint,setRegJoinPoint,setMaeChargePoint,setFirstChargePoint,resetState}
    from "../../../redux/reducers/partner/RecommendAddReducer";
import {useDispatch, useSelector} from "react-redux";


const RecommendAddComponent = (props) => {
    const {refresh, onClose} = props
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.RecommendAddReducer)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(null);


    const init = () => {
        dispatch(setCntLimitList());

        //1.부본사만
        axios.post('/admin/partner/getOrg1').
        then((res) => {
            if(res.data.List.length>0){
                dispatch(setOrg1(res.data.List))
            }
        })

        //2.상위부본사 + 총판
        axios.post('/admin/partner/getOrg2').
        then((res) => {
            if(res.data.List.length>0){
                dispatch(setOrg2(res.data.List))
            }
        })

        //3.상위부본사+상위총판+가맹점
        axios.post('/admin/partner/getOrg3').
        then((res) => {
            if(res.data.List.length>0){
                dispatch(setOrg3(res.data.List))
            }
        })
    }
    useEffect(init, [dispatch])

    const makekey = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let randomString = '';

        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }
        dispatch(setRecommedKey(randomString));
    }

    const changeTimeLimit = (e) => {
        dispatch(setTimeLimit(e.target.value))
    }
    const changeCntLimit = (e) => {
        dispatch(setCntLimit(e.target.value))
    }

    const radioChange = (gb) => {
        dispatch(setGb(gb))
        dispatch(setRecommendOrgAdminId(''));
        dispatch(setRecommendUserId(''));
        if(gb === '부본사'){
            dispatch(setOrg(initialState.org1))
        }else if(gb === '총판'){
            dispatch(setOrg(initialState.org2))
        }else if(gb === '가맹점'){
            dispatch(setOrg(initialState.org3))
        }
    }

    const saveRecommendKey = () =>{
        axios.post('/admin/partner/saveRecommendKey',initialState.input).
        then((res) => {
            if(res.data.code === 'suc'){
                alert(res.data.message);
                dispatch(setRecommedKey(''));
                //refresh();
                // const myButton = document.getElementById('cancle');
                // myButton.click();
                //window.close();
                handleCancel();
            }else{
                alert(res.data.message);
            }
        })
    }

    const orgChangeHandler = (e) => {
        dispatch(setRecommendOrgAdminId(e.target.value));
    }

    const changeUserId = (e) => {
        dispatch(setRecommendUserId(e.target.value));
    }

    const changeKey = (e) => {
        dispatch(setRecommedKey(e.target.value));
    }

    const handleCancel = () => {
        onClose()
    }


    return (
        <div className="member02_modal_background" >
            <div className="member02_modal_box" style={{overflowY:"scroll"}}>
                <div className="mebmer02_modal_body" >
                    <div>
                        <h1 className="modal-title fs-5" >추천인 키 등록</h1>
                    </div>
                    <div>
                        <table className="table table-bordered table-sm text-center align-middle" >
                            <tbody>
                            <tr>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>
                                    추천인 키
                                </td>
                                <td width={"1%"}></td>
                                <td width={"*%"} >
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input style={{
                                            width: "250px"
                                        }} value={initialState.input.recommend_key} type="text" className="form-control" id="search_edit" placeholder="" onChange={(e)=>changeKey} />
                                        &nbsp;
                                        <input onClick={makekey} type="button" value="자동생성" id="inlineRadio1" style={{backgroundColor :"lightgray"}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>
                                    추천 대상
                                </td>
                                <td width={"1%"}></td>
                                <td width={"*%"} >
                                    <div className="container-fluid p-0 mx-1" style={{display: "flex"}}>
                                        <input value={'부본사'} className="form-check-input" type="radio" name="inlineRadio11" id="inlineRadio1"  onChange={() => radioChange('부본사')}/>부본사
                                        &nbsp;
                                        <input value={'총판'} className="form-check-input" type="radio" name="inlineRadio11" id="inlineRadio2" onChange={() => radioChange('총판')}/>총판
                                        &nbsp;
                                        <input value={'가맹점'} className="form-check-input" type="radio" name="inlineRadio11" id="inlineRadio3" onChange={() => radioChange('가맹점')}/>가맹점
                                        &nbsp;
                                        <input value={'회원'} className="form-check-input" type="radio" name="inlineRadio11" id="inlineRadio4" onChange={() => radioChange('회원')}/>회원
                                    </div><br></br>
                                    {initialState.gb !== '회원' ?
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <select value={initialState.input.recommend_org_admin_id} style={{width : '300px'}} className="form-select sm-select" onChange={orgChangeHandler}>
                                                <option value="">::{initialState.gb} 선택::</option>
                                                {initialState.org?.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {initialState.gb ==='총판'||initialState.gb ==='가맹점' ? '['+'' + option.org1?.org_nm + '' +'] > ' : ''}
                                                        {initialState.gb ==='가맹점' ? '['+option.org2?.org_nm+'] > ' : ''}
                                                        {option?.org_nm}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        :
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <span>회원아이디 : </span>&nbsp;
                                            <input onChange={changeUserId} maxLength={20} className="form-check-input sm-input" type="text" />
                                        </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>
                                    시간 제한
                                </td>
                                <td width={"1%"}></td>
                                <td width={"*%"} >
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <select value={initialState.input.time_limit} className="form-select sm-select me-1" onChange={changeTimeLimit}>
                                            {initialState.timeList?.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.nm}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>
                                    사용 횟수 제한
                                </td>
                                <td width={"1%"}></td>
                                <td width={"*%"} >
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <select value={initialState.input.cnt_limit} className="form-select sm-select me-1" onChange={changeCntLimit}>
                                            <option value="0" >제한 없음</option>
                                            {initialState.cntLimitList&&initialState.cntLimitList.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.nm}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>
                                    가입시 지급 포인트
                                </td>
                                <td width={"1%"}></td>
                                <td width={"*%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input style={{
                                            width: "250px"
                                        }} type="text" className="form-control" id="search_edit" placeholder="-1" onBlur={(e) => {
                                        }}  />
                                    </div>
                                    * 이 코드를 사용하여 가입하는 경우 지급되는 가입포인트
                                    <br></br>
                                    * -1로 설정된 경우, 환결설정>레벨별설정에 설정된 내용을 따름
                                    <br></br>
                                    * 0으로 설정시, 무조건 지급하지 않음
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"} height={"50px"}>
                                    사이트코드
                                </td>
                                <td width={"1%"}></td>
                                <td width={"*%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input style={{
                                            width: "250px"
                                        }} type="text" className="form-control" id="search_edit" placeholder="-1" onBlur={(e) => {
                                        }}  />
                                    </div>
                                    * 콤마로 구분, 비워놓으면 전체 사이트 사용 가능
                                    <br></br>
                                    * 사이트코드는 환결설정>기타설정 참고
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer" align={"left"}>
                        * 생성된 추천인 키는 해당 총판/가맹이 삭제된 경우, 회원이 삭제되거나 정지/대기 상태인 경우엔 사용하지 못하게 되며, 자동 삭제 됩니다.
                        <br></br>
                        * 보안을 위해 시간 제한이나 사용 횟수 제한을 설정하기 바랍니다.
                        <br></br>
                        <button onClick={handleCancel} type="button" className="btn btn-secondary" >취소</button>
                        <button onClick={saveRecommendKey} type="button" className="btn btn-primary" >신규 생성</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecommendAddComponent;