import Ladder from "../../../containers/game/setting/mini/Ladder";


const MiniSetting = () => {

    return (
        <div>
            <p>미니게임설정</p>
            <Ladder />
        </div>
    )
}

export default MiniSetting