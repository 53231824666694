import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const couponListSlice = createSlice({
    name: 'couponList',
    initialState: {
        isLoading: false,
        error: null,
        couponList: [],
        countList: {},
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
        userGbCd: "",
        searchGbCd: "",
        searchCd01: "",
    },
    reducers: {
        getCouponList(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userGbCd = action.payload.userGbCd;
            state.searchGbCd = action.payload.searchGbCd;
            state.searchCd01 = action.payload.searchCd01;
        },
        setCouponList(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.couponList = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList;
        },
        failedGetCouponList(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { getCouponList, setCouponList, failedGetCouponList } = couponListSlice.actions;

export default couponListSlice.reducer;