import React, {useMemo, useRef, useState} from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import imageCompression from "browser-image-compression";


const Editor = ({cont, setCont}) => {

    const customUploadAdapter = (loader) => {
        return {
            upload() {
                return new Promise((resolve, reject) => {
                    const data = new FormData();
                    loader.file.then(async (file) => {
                        data.append("file", file)
                        axios.post('/admin/board/upload', data)
                            .then((res) => {
                                resolve({
                                    default: res.data.url
                                })
                            })
                            .catch((err) => reject(err))
                    })
                })
            }
        }
    }

    function uploadPlugin (editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader)
        }
    }


    return <div style={{ margin: "50px"}}>
        <h2>Using CKEditor&nbsp;5 build in React</h2>
        <CKEditor
            editor={ ClassicEditor }
            config={{ extraPlugins: [uploadPlugin]}}
            data={cont}
            onReady={ editor => {
                // You can store the "editor" and use when it is needed.
                //console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( event, editor ) => {
                const data = editor.getData()
                setCont(data)
                console.log( cont );
            } }
            onBlur={ ( event, editor ) => {
                //console.log( 'Blur.', editor );
            } }
            onFocus={ ( event, editor ) => {
                //console.log( 'Focus.', editor );
            } }
        />
    </div>
}
export default Editor