import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
const Manual = () => {

    const [games, setGames] = useState([])

    useEffect(() => {
        getGames()
    }, [])

    const getGames = () => {
        axios.get('/admin/game/manual/getGames').then((res) => {
            setGames(res.data.games)
        })
    }

    const changeResult = (item) => {
        axios.post('/admin/game/manual/changeResult', {
            game: item
        }).then((res) => {
            if(res.data.isUpdated) {
                getGames()
            }
        })
    }

    return (
        <div className="commonNew">
            <div style={{ backgroundColor: "white", paddingTop: "20px"}}>
                <div style={{display: "flex"}}>
                    <div style={{flexGrow: "1"}}>
                        <font color={"blue"} size="3">게임관리</font>
                        <div className="align-items-center" style={{display: "flex"}}>

                            <select className="form-select me-1 sm-select" aria-label="Default select example" style={{width: "80px"}}>
                                <option selected>30개</option>
                            </select>
                        </div>

                        <div className="align-items-center" style={{display: "flex"}}>
                            <button style={{ marginRight: "10px", backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}}>엑셀 다운로드</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>7일</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>어제</button>
                            <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>오늘</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>지난 24시간</button>
                            <div style={{display: "flex", paddingLeft: "5px", paddingRight: "5px", alignItems: "center"}}>
                                <span className="mx-1">◀</span>
                                <input className="form-control sm-date" type="date" style={{width: "120px"}}/>
                                <input className="sm-input" type="text" style={{width: "80px"}}/>
                                <span className="mx-1">부터 ~</span>
                                <input className="form-control sm-date" type="date" style={{width: "120px"}}/>
                                <span className="mx-1">▶</span>
                                <input className="sm-input" type="text" style={{width: "80px"}}/>
                                <span className="mx-1">까지 (시간분 예시 : 1230)</span>
                            </div>
                            <div className="sm-center-div">
                                <input className="sm-input" type="text" style={{width: "120px"}}/>
                            </div>
                            <button style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "50px", width: "fit-content"}}>현재 페이지<br/>새로고침</button>
                        </div>

                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "50px", width: "fit-content"}}>
                            <Link to="/game/manual/insert" target="_blank" rel="noopener noreferrer" >
                                수동게임 추가
                            </Link>
                        </button>
                    </div>

                </div>
                <div className="common">
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>v</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>게임번호</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>출력</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>구분</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>Cross</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>Cross2</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>베팅대기</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>종목</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>리그</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>상단고정</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>버전</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>홈팀VS원정팀</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>경기일시</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>승무패 베팅</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>승무패<br/>홈/무승부/원정</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>핸디캡 베팅</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>핸디캡<br/>홈/핸디/원정</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>오버언더 베팅</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>오버언더<br/>오버/기준/언더</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>점수<br/>(홈:원정)</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>관리</td>
                        </tr>
                        </thead>
                        {!games.length && (
                            <tbody key={games.id}>
                            <tr>
                                <td colSpan="21">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        <tbody>
                        {games?.map((item, index) => (
                            <tr>
                                <td><input type="checkbox"/></td>
                                <td>{item.id}</td>
                                <td>{item.status}</td>
                                <td>{item.sport}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{item.sport}</td>
                                <td>{item.league}</td>
                                <td></td>
                                <td></td>
                                <td>{item.home} <span>vs</span> {item.away}</td>
                                <td>{item.start_date}</td>
                                <td></td>
                                <td>{`${item.bet1?.toFixed(2)}  ${item.bet2 === ""? "0.00" : item.bet2}  ${item.bet3?.toFixed(2)}`}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <div className="d-flex">
                                        <input type="number" value={item.home_score === -1? "" : item.home_score} onChange={(e) => {
                                            item.home_score = e.currentTarget.value
                                            const arr = [...games]
                                            arr[index] = item
                                            setGames(arr)
                                        }}/>
                                        :
                                        <input type="number" value={item.away_score === -1? "" : item.away_score} onChange={(e) => {
                                            item.away_score = e.currentTarget.value
                                            const arr = [...games]
                                            arr[index] = item
                                            setGames(arr)
                                        }}/>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <button onClick={(e) => {
                                            changeResult(item)
                                        }}>결과등록</button>
                                    </div>


                                </td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                </div>

            </div>
        </div>

    )
}
export default Manual;