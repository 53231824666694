import React from 'react';
import CancelByContainer from "../../../containers/game/manage/CancelByContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const CancelBy = () => {
    return (
        <div>
            <h1>수동취소</h1>
            <CancelByContainer />
        </div>
    );
};

export default CancelBy;