import { createSlice } from "@reduxjs/toolkit";

export const parsingWaitSlice = createSlice({
    name: 'parsingWait',
    initialState: {
        isLoading: false,
        isLoadingDetail: false,
        fixtures: [],
        originFixtures: [],
        error: null,
        isConnect: false,
        tasks: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        sport: 0,
        market: 0,
        provider: 0,
        league: 0,
        sports: [],
        markets: [],
        providers: [],
        leagues: [],
        query: "",
        checkedInputs: [],
        betInputs: [],
        updateData: [],
        correctScores: [],
        low_ratios: [],
        currentFixture: null,
        currentFixtureId: 0,
    },
    reducers: {
        getWaitFixture(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.sport = action.payload.sport;
            state.provider = action.payload.provider;
            state.league = action.payload.league;
            state.query = action.payload.query
        },
        setWaitFixture(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.low_ratios = action.payload.result;
            state.totalCount = action.payload.totalCount;
            state.sports = action.payload.sports;
            state.providers = action.payload.providers;
            state.leagues = action.payload.leagues;
            const inputs = [];
            for(const r of action.payload.result) {
                inputs.push({id: r.id, bet1: r.bet1, bet2: r.bet2, bet3: r.bet3, is_edited: 0})
            }
            state.betInputs = inputs;
            state.checkedInputs = [];

        },
        getWaitFixtureDetail(state, action) {
            state.isLoadingDetail = true;
            state.currentFixtureId = action.payload;
        },
        setCurrentFixture(state, action) {
            state.isLoadingDetail = false;
            state.currentFixture = action.payload;
        },
        failedGetWaitFixtureDetail(state, action) {
            state.isLoadingDetail = false;
            state.error = action.payload;
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        setCorrectScores(state, action) {
            state.correctScores = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        setBetInputs(state, action) {
            state.betInputs = action.payload;
        },
        failedGetWaitFixture(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export const {
    getWaitFixture,
    setWaitFixture,
    getWaitFixtureDetail,
    setCurrentFixture,
    failedGetWaitFixtureDetail,
    setCheckedInputs,
    setCorrectScores,
    setUpdateData,
    setBetInputs,
    failedGetWaitFixture,
} = parsingWaitSlice.actions;

export default parsingWaitSlice.reducer;