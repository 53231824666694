import {call, put} from "redux-saga/effects";
import {failedGetPartnerListReducer, setPartnerListReducer} from "../../reducers/partner/PartnerListReducer";
import {fetchPartnerList} from "../../../lib/api";

export function* getPartnerListSaga(action) {
    try{
        console.log("##getPartnerListSaga input#",action.payload.input)
        const res = yield call(fetchPartnerList,
            action.payload.input,
        );
        console.log("##getPartnerListSaga output#",res.data)
        yield put(setPartnerListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetPartnerListReducer(error))
    }
}