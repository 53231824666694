import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"
import Pagination from "../../../components/common/Pagination";
import {getMiniGames, setMiniSorts} from "../../../redux/reducers/game/manage/miniGame";
import MiniGameList from "../../../components/game/manage/MiniGameList";
import axios from "axios";
import MiniKorean from "../../../constant/MiniKorean";

const MainGameContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const state = useSelector((state) => state.miniGame)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    const input = {
        page: state.page,
        pageSize: state.pageSize,
        status: state.status,
        sport: state.sport,
        provider: state.provider,
        market: state.market,
        league: state.league,
        query: searchQuery,
        startDate: startDate? startDate : state.startDate,
        endDate: endDate? endDate : state.endDate,
    }
    useEffect(() => {
        getSortData()
        dispatch(getMiniGames(input))
    }, [])

    const getSortData = () => {
        axios.post('/admin/game/getMiniGameSorts', {

        }).then((response) => {
            dispatch(setMiniSorts(response.data))
        })
    }

    const handlePageChange = (page) => {
        const fixInput = {...input}
        fixInput.page = page
        dispatch(getMiniGames(fixInput))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.query = query
        dispatch(getMiniGames(fixInput))
    }
    const onClickSearch = (e) => {
        dispatch(getMiniGames(input))
    }

    const startIndex = ((state.page - 1) * state.pageSize) + 1;
    return <>
        <p>{ startIndex + "~" + (startIndex + state.pageSize - 1) + " / " + state.totalCount}</p>
        <div style={{
            display: "flex"
        }}>

            <input style={{
                width: "220px"
            }} type="date" id="start_date" value={startDate? startDate : state.startDate} onChange={ event => {
                setStartDate(event.target.value)
            }}/>
            <big> ~ </big>
            <input style={{
                width: "220px"
            }} type="date" id="end_date" value={endDate? endDate : state.endDate} onChange={ event => {
                setEndDate(event.target.value)
            }}/>

            <select
                style={{
                    width: "80px"
                }}
                className="form-control"
                defaultValue={0}
                value={state.provider === 0? "모든업체" : state.provider}
                onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.provider = e.currentTarget.value
                    dispatch(getMiniGames(fixInput))
                }}
            >
                <option value={0}>모든업체</option>
                {state.providers?.length > 0 && state.providers.map((item, index) => (
                    <option value={item}>{MiniKorean.getKorProvider(item)}</option>
                ))}
            </select>

            <select
                style={{
                    width: "80px"
                }}
                className="form-control"
                defaultValue={0}
                value={state.sport === 0? "모든종목" : state.sport}
                onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.sport = e.currentTarget.value
                    dispatch(getMiniGames(fixInput))
                }}
            >
                <option value={0}>모든종목</option>
                {state.sports?.length > 0 && state.sports.map((item, index) => (
                    <option value={item}>{MiniKorean.getKorSport(item)}</option>
                ))}
            </select>

            <select
                style={{
                    width: "80px"
                }}
                className="form-control"
                defaultValue={0}
                value={state.market === 0? "모든종류" : state.market}
                onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.market = e.currentTarget.value
                    dispatch(getMiniGames(fixInput))
                }}
            >
                <option value={0}>모든종류</option>
                {state.markets?.length > 0 && state.markets.map((item, index) => (
                    <option value={item}>{MiniKorean.getKorMarket(item)}</option>
                ))}
            </select>

            <select
                style={{
                    width: "80px"
                }}
                className="form-control"
                defaultValue={999}
                value={state.status === 999? "모든상태" : state.status}
                onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.status = parseInt(e.currentTarget.value)
                    dispatch(getMiniGames(fixInput))
                }}
            >
                <option value={999}>모든상태</option>
                <option value={0}>대기</option>
                <option value={1}>진행</option>
                <option value={4}>완료</option>
            </select>


            <div style={{
                display: "flex",
                width: "300px"
            }}>
                <input style={{
                    width: "220px"
                }} type="text" className="form-control" id="search_edit" placeholder="리그,팀명,ID를 입력하세요." onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
            </div>
        </div>
        <div>
            <select
                style={{
                    width: "80px"
                }}
                className="form-control"
                defaultValue={100}
                value={state.pageSize === "999"? "모두" : state.pageSize}
                onChange={(e) => {
                    const fixInput = {...input}
                    fixInput.pageSize = parseInt(e.currentTarget.value)
                    dispatch(getMiniGames(fixInput))
                }}
            >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={999}>모두</option>
            </select>
        </div>

        <MiniGameList state={state} />
        <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange}/>
    </>
}
export default MainGameContainer