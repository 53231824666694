
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {
    changeIsLoading,
    getLeagues,
    setGameKinds,
    setLeagues,
    setNations,
    setSelectedNation
} from "../../../redux/reducers/game/gameSetting";
import {useState} from "react";
import Switch from "react-switch";


const NationList = ({onRefresh}) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.gameSetting);

    const changeNation = (item) => {
        dispatch(changeIsLoading(true))
        axios.post('/admin/game/setting/changeNation', {
            nationItem: item,
        }).then((res) => {
            dispatch(changeIsLoading(false))
            if(res.data.isChanged) {
                alert("변경완료")
            }
            else {
                alert("변경실패")
            }
        })
    }
    const changeAllNation = () => {
        dispatch(changeIsLoading(true))
        axios.post('/admin/game/setting/changeAllNation', {
            list: state.nations,
        }).then((res) => {
            dispatch(changeIsLoading(false))
            if(res.data.isChanged) {
                alert("변경완료")
                onRefresh()
            }
            else {
                alert("변경실패")
            }
        })
    }

    return <div className="card-body" style={{height: "800px", overflowY: "scroll"}}>
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>코드</th>
                    <th>국가</th>
                    <th>파싱명</th>
                    <th>사용</th>
                    <th>
                        <button className="btn btn-sm btn-primary" onClick={(e) => {
                            changeAllNation()
                        }}>일괄변경</button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {state.nations && state.nations.map((item, index) => (
                    <tr key={item.id} style={{backgroundColor: state.selectedNation === item.id? '#ead980': "transparent"}}>
                        <td>{item.id}</td>
                        <td style={{ textAlign: 'center',paddingLeft: '10px' }}>
                            <input className="form-control-sm" type="text" style={{ width: '100%' }} value={item.name_kor? item.name_kor : ""}
                                   onChange={(e) => {
                                       const newItem = {...item}
                                       newItem.name_kor = e.currentTarget.value
                                       const arr = [...state.nations]
                                       arr[index] = newItem
                                       dispatch(setNations({
                                           result: arr,
                                       }))
                                   }} />
                        </td>
                        <td>{item.name}</td>
                        <td>
                            <Switch onChange={(checked) => {
                                const newItem = {...item}
                                newItem.is_use = checked
                                const arr = [...state.nations]
                                arr[index] = newItem
                                dispatch(setNations({
                                    result: arr,
                                }))
                            }} checked={item.is_use} onColor="#2196F3"/>
                        </td>
                        <td>
                            <div>
                                <button className="btn btn-sm btn-primary" onClick={(e) => {
                                    changeNation(item)
                                }}>변경</button>
                                <button className="btn btn-sm btn-danger" onClick={(e) => {
                                    dispatch(setSelectedNation(item))
                                    dispatch(setLeagues({result: []}))
                                    dispatch(getLeagues({
                                        page: 1,
                                        pageSize: 20,
                                        nationName: (item.name_kor && item.name_kor !== "")? item.name_kor : item.name,
                                        gameName: state.selectedKindName,
                                        query: "",
                                        useCode: 999,
                                    }))

                                }}>리그정보</button>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

    </div>
}

export default NationList