import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const checkSlice = createSlice({
    name: 'check',
    initialState: {
        isLoading: false,
        error: null,
        checks: [],
        month: moment().format("YYYY-MM"),
        query: ""
    },
    reducers: {
        getChecks(state, action) {
            state.isLoading = true;
            state.month = action.payload.month
            state.query = action.payload.query
        },
        failedGetChecks(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setChecks(state, action) {
            state.isLoading = false;
            state.checks = action.payload.result;
        },
    }
});

export const { getChecks, failedGetChecks, setChecks } = checkSlice.actions;

export default checkSlice.reducer;