import {call, put, take,} from "redux-saga/effects";
import {fetchActiveMembers} from "../../../lib/api";
import {failedGetActiveMembers, setActiveMembers} from "../../reducers/monitoring/ActiveMemberReducer";

export function* getActiveMemberSaga(action) {
    try{
        const res = yield call(
            fetchActiveMembers,
            action.payload
        )

        yield put(setActiveMembers(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetActiveMembers(error))
    }
}