import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const losingInfoSlice = createSlice({
    name: 'losingInfo',
    initialState: {
        sumData : {},
        totalList : [],
        totalCount: 0,
        gridList : [],
        gridListCount : 0,
        isLoading: false,
        error: null,

        page: 1,
        pageSize: 25,
        orgAdminId : "",
        query : "",
        createdDttmGb : false,
        startDttm: moment().format('YYYY-MM-DD'),
        endDttm: moment().format('YYYY-MM-DD'),
    },
    reducers: {
        getLosingInfo(state, action) {
            state.isLoading = true;
            state.page = action.payload.input.pageSize === 999? 1: action.payload.input.page;
            state.pageSize = action.payload.input.pageSize;
            state.query = action.payload.input.query;
            state.startDttm = action.payload.input.startDttm;
            state.endDttm = action.payload.input.endDttm;
            state.orgAdminId = action.payload.input.orgAdminId;
            state.createdDttmGb = action.payload.input.createdDttmGb;
        },
        setLosingInfo(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            console.log('##totalList##',action.payload.gridList)
            state.isLoading = false;
            state.sumData = action.payload.sumData;
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.gridList = action.payload.gridList;
            state.gridListCount = action.payload.gridListCount;
        },
        failedGetLosingInfo(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setOrgAdminId(state, action) {
            state.orgAdminId = action.payload;
        },
        setStartDttm(state, action){
            state.startDttm = action.payload
        },
        setEndDttm(state, action){
            state.endDttm = action.payload
        },
        setQuery(state, action){
            state.query = action.payload
        },
        setCreatedDttmGb(state, action){
            state.createdDttmGb = action.payload
        },
    }
});

export const {
    getLosingInfo,
    setLosingInfo,
    failedGetLosingInfo,
    setPageSize,
    setOrgAdminId,
    setStartDttm,
    setEndDttm,
    setQuery,
    setCreatedDttmGb,
} = losingInfoSlice.actions;

export default losingInfoSlice.reducer;