import {call, put, take,} from "redux-saga/effects";
import {fetchMember} from "../../../lib/api";
import {failedGetMember, setMember} from "../../reducers/user/MemberReducer";

export function* getMemberSaga(action) {
    try{
        const res = yield call(
            fetchMember,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.sortColumn, action.payload.sortValue,
            action.payload.searchGb,
            action.payload.searchCd01, action.payload.searchCd02, action.payload.searchCd03,
            action.payload.searchCd04, action.payload.searchCd05, action.payload.searchCd06,
            action.payload.searchCd07, action.payload.searchCd08, action.payload.searchCd09,
            action.payload.searchCd10, action.payload.selectedNos, action.payload.selectedNos2,
            action.payload.selectedNos3, action.payload.selectedNos4, action.payload.selectedNos5,
            action.payload.selectedNos6, action.payload.selectedNos7, action.payload.selectedNos8,
            action.payload.selectedNos9, action.payload.searchIdArr, action.payload.searchIdSort,
            action.payload.searchNicknameArr, action.payload.searchNicknameSort, action.payload.topSearchVal
        )
        yield put(setMember(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMember(error))
    }
}