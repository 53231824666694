import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"


export const attendanceSettingSlice = createSlice({
    name: 'attendanceSetting',
    initialState: {
        isLoading: false,
        error: null,
        attendanceSetting: [],
        countList: {},
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "01",
        userGbCd: "",
        searchGbCd: "",
        isOpen : false,
        selectedNos1: [],
        selectList1: [],
        selectList2: [],
        payCouponCdArr:[],
        payCouponCdSeqArr:[],
    },
    reducers: {
        getAttendanceSetting(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userGbCd = action.payload.userGbCd;
            state.searchGbCd = action.payload.searchGbCd;

        },
        setAttendanceSetting(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.attendanceSetting = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList;
            state.selectList1 = action.payload.selectList1;
            state.selectList2 = action.payload.selectList2;
        },
        failedGeAttendanceSetting(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setIsOpen(state,action){
            state.isOpen = action.payload;
        },
        setQuery(state, action){
            state.query = action.payload;
        },
        setSelectedNos1(state,action){
            state.selectedNos1 = action.payload;
        },
        setPayCouponCdArr(state, action){
            state.payCouponCdArr = action.payload;
        },
        setPayCouponCdSeqArr(state, action){
            state.payCouponCdSeqArr = action.payload;
        },

    }
});

export const { getAttendanceSetting, setAttendanceSetting, failedGeAttendanceSetting, setIsOpen, setQuery, setSelectedNos1,
    setPayCouponCdArr, setPayCouponCdSeqArr

} = attendanceSettingSlice.actions;

export default attendanceSettingSlice.reducer;