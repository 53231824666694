import {call, put, take,} from "redux-saga/effects";
import {
    failedGetInplayResult, failedGetInplayResultDetail,
    getInplayResultDetail,
    setInplayResult, setCurrentFixture
} from "../../../reducers/game/manage/inplayResult";
import {fetchInplayResult, fetchInplayResultDetail} from "../../../../lib/api";

export function* getInplayResultSaga(action) {
    try {
        const res = yield call(fetchInplayResult,
            action.payload
        );
        yield put(setInplayResult(res.data));
        if(res.data.result?.length > 0) {
            yield put(getInplayResultDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetInplayResult(error));
    }
}

export function* getInplayResultDetailSaga(action) {
    try {
        const res = yield call(fetchInplayResultDetail, action.payload)
        yield put(setCurrentFixture(res.data))
    } catch(error) {
        console.log(error)
        yield put(failedGetInplayResultDetail(error))
    }
}

