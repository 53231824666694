import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";
const initialState = {
    isLoading: false,
    error: null,
    page : 1,
    pageSize : 10,
    query : "",
    startDttm: moment().subtract(5, 'years').format('YYYY-MM-DD'),
    endDttm: moment().format('YYYY-MM-DD'),
    userGbCd : "",
    totalGroupedData : null,
    totalGroupedData2 : null,
    totalList :[],
    gridList :[],
    totalCount :0,
    gridCount :0,
    cmCd0002 : []
}
export const MemberInfoReducerSlice = createSlice({
    name: 'MemberInfoReducer',
    initialState,
    reducers: {
        getMemberInfo(state, action) {
            state.isLoading = true;
            state.page = action.payload.input.pageSize === 999? 1: action.payload.input.page;
            state.pageSize = action.payload.input.pageSize;
            state.query = action.payload.input.query;
            state.startDttm = action.payload.input.startDttm;
            state.endDttm = action.payload.input.endDttm;
            state.userGbCd = action.payload.input.userGbCd;
        },
        setMemberInfo(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalGroupedData = action.payload.totalGroupedData;
            state.totalGroupedData2 = action.payload.totalGroupedData2;
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.gridList = action.payload.gridList;
            state.gridCount = action.payload.gridCount;
            state.cmCd0002 = action.payload.cmCd0002;

        },
        failedGetMemberInfo(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setQuery(state, action){
            state.query = action.payload
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setStartDttm(state, action){
            state.startDttm = action.payload
        },
        setEndDttm(state, action){
            state.endDttm = action.payload
        },
    }
});

export const {
    getMemberInfo,
    setMemberInfo,
    failedGetMemberInfo,
    setQuery,
    setPageSize,
    setStartDttm,
    setEndDttm,
} = MemberInfoReducerSlice.actions;

export default MemberInfoReducerSlice.reducer;