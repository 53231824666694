import {createSlice} from "@reduxjs/toolkit"

export const overlapSlice = createSlice({
    name: 'overlap',
    initialState: {
        isLoading: false,
        error: null,
        overlaps: [],
    },
    reducers: {
        getOverlaps(state, action) {
            state.isLoading = true;
        },
        failedGetOverlaps(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setOverlaps(state, action) {
            state.isLoading = false;
            state.overlaps = action.payload.result;
        },
    }
});

export const { getOverlaps, failedGetOverlaps, setOverlaps } = overlapSlice.actions;

export default overlapSlice.reducer;