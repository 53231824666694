import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination";
import {getMemberUpdate} from "../../redux/reducers/user/MemberUpdateReducer";
import MemberUpdate from "../../components/user/MemberUpdate";
import {getMemberUser} from "../../redux/reducers/user/MemberReducer";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {insertAdminLog} from "../../lib/api";

const MemberUpdateContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.memberUpdate)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [userGbCd, setUserGbCd] = useState("");



    const getCmmnCd = () => {
        if(changeFieldList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0028',
            }).then((res) => {
                setChangeFieldList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getMemberUpdate({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "회원정보수정내역"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getMemberUpdate({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getMemberUpdate({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getMemberUpdate({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getMemberUpdate({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }


    const exportExcel = ()=>{

        //alert('initialState.totalList <>>>> '+initialState.totalList);

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {


            let jsonObject = { // JSON 객체를 생성합니다.
                no: initialState.totalList[i].no? initialState.totalList[i].no :'',
                소속: initialState.totalList[i].store_cd? initialState.totalList[i].store_cd :'',
                아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                변경필드명: initialState.totalList[i].change_field? initialState.totalList[i].change_field :'',
                기존값: initialState.totalList[i].asis_value? initialState.totalList[i].asis_value :'',
                수정값: initialState.totalList[i].tobe_value? initialState.totalList[i].tobe_value :'',
                수정아이디: initialState.totalList[i].upd_id? initialState.totalList[i].upd_id :'',
                수정아이피: initialState.totalList[i].update_ip? initialState.totalList[i].update_ip :'',
                수정시각: initialState.totalList[i].updatedAt? initialState.totalList[i].updatedAt :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '회원정보 수정내역');
        XLSX.writeFile(workBook, '회원정보 수정내역.xlsx');
    }

    const userGbCdChangeHandler = (e) => {
        alert('userGbCd000 >>>>>>>>>>>> '+e.currentTarget.value);
        setUserGbCd(e.currentTarget.value);

        alert('userGbCd >>>>>>>>>>>> '+userGbCd);
        onRefresh();
    }


    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <p className="h5 ps-2">{ startIndex + "~" + currentMaxCount + " / " + initialState.totalCount}</p>
        <div className="commonNew">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <select
                    className="form-select sm-select"
                    defaultValue={100}
                    style={{
                        width: "100px"
                    }}
                    value={initialState.pageSize === "999"? "모두" : initialState.pageSize}
                    onChange={(e) => {
                        dispatch(getMemberUpdate({
                            page: initialState.page,
                            pageSize: parseInt(e.target.value),
                            order: initialState.order,
                            query: searchQuery,
                            startDate: startDate? startDate : initialState.startDate,
                            endDate: endDate? endDate : initialState.endDate,
                            userGbCd: initialState.userGbCd,
                            searchGbCd: initialState.searchGbCd
                        }))
                    }}

                >
                    <option value={50}>50개</option>
                    <option value={100}>100개</option>
                    <option value={200}>200개</option>
                    <option value={30}>30개</option>
                </select>
                <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "100px", marginRight: "10px"}} onClick={exportExcel}>엑셀 다운로드</button>
                변경 필드 검색
                &nbsp;
                <select
                    className="form-select sm-select"
                    defaultValue={""}
                    style={{
                        width: "150px"
                    }}
                    value={initialState.userGbCd === ""? "" : initialState.userGbCd}
                    onChange={e =>
                        dispatch(getMemberUpdate({
                            page: initialState.page,
                            pageSize: initialState.pageSize,
                            order: initialState.order,
                            query: searchQuery,
                            startDate: startDate? startDate : initialState.startDate,
                            endDate: endDate? endDate : initialState.endDate,
                            userGbCd: e.target.value,
                            searchGbCd: initialState.searchGbCd
                        }))
                    }
                >
                    <option key='' value=''>전체</option>
                    {changeFieldList.map((item, index) => (
                        <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                    ))}
                </select>

                &nbsp;
                <select
                    className="form-select sm-select"
                    defaultValue={"1"}
                    style={{
                        width: "150px"
                    }}
                    value={initialState.searchGbCd === ""? "" : initialState.searchGbCd}
                    onChange={e =>
                        dispatch(getMemberUpdate({
                            page: initialState.page,
                            pageSize: initialState.pageSize,
                            order: initialState.order,
                            query: searchQuery,
                            startDate: startDate? startDate : initialState.startDate,
                            endDate: endDate? endDate : initialState.endDate,
                            userGbCd: initialState.userGbCd,
                            searchGbCd: e.target.value
                        }))
                    }
                >
                    <option value={"1"}>닉네임 또는 아이디</option>
                    <option value={"2"}>기존 값 또는 수정 값</option>
                    <option value={"3"}>기존 값</option>
                    <option value={"4"}>수정 값</option>
                </select>
                &nbsp;
                <input style={{
                    width: "220px", height:"20px"
                }} type="text" className="form-control" id="search_edit" placeholder="검색조건을 입력하세요." onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                &nbsp;
                <table  width={"49%"}>
                    <tr>
                        <td align={"right"}>
                            <button style={{
                                width: "80px"
                            }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                            &nbsp;
                            <button style={{
                                width: "100px", backgroundColor :"darkblue"
                            }} type="submit" className="btn btn-primary" onClick={onClickReset}>새로고침</button>
                        </td>
                    </tr>
                </table>
            </div>
            <br></br>
            * 회원 정보 수정 정보를 보여줍니다.
            <br></br>
            <font color={"red"}>* 전화 번호와 비밀번호는 보안상의 문제로 수정 여부만 파악 가능하며, 실제 값을 알 수는 없습니다.</font>
            <br></br>
            <font color={"red"}>* 90일 이내의 수정내역만 확인 가능합니다. 그 이전의 수정내역은 개별 회원의 회원정보 팝업,수정내역을 확인 바랍니다.</font>
            <br></br>
            <br></br>
        </div>
        <MemberUpdate memberUpdate={initialState.memberUpdate} onRefresh={onRefresh} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default MemberUpdateContainer