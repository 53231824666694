import {call, put} from "redux-saga/effects";
import {failedGetCodeSettingListReducer, setCodeSettingListReducer} from "../../reducers/setting/CodeSettingListReducer";
import {fetchCodeSettingList} from "../../../lib/api";

export function* getCodeSettingListSaga(action) {
    try{
        console.log("##getCodeSettingListSaga input#",action.payload.input)
        const res = yield call(fetchCodeSettingList,
            action.payload.input,
        );
        console.log("##getCodeSettingListSaga output#",res.data)
        yield put(setCodeSettingListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetCodeSettingListReducer(error))
    }
}