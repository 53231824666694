import React from "react";
const CenterView = () => {

    return (
        <div className="sm-font mx-3" style={{display: "flex", marginTop: "30px", height: "fit-content"}}>
            <div style={{ backgroundColor: "white", paddingTop: "20px"}}>
                <div style={{display: "flex"}}>
                    <div style={{flexGrow: "1"}}>
                        <div className="align-items-center" style={{display: "flex"}}>
                            <font color={"blue"} size="3">[kgon]베팅내역</font>
                            <select className="form-select mx-1 sm-select" aria-label="Default select example" style={{width: "80px"}}>
                                <option selected>30개</option>
                            </select>
                        </div>

                        <div className="align-items-center" style={{display: "flex"}}>
                            <button style={{ marginRight: "10px", backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}}>엑셀 다운로드</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>7일</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>어제</button>
                            <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>오늘</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>지난 24시간</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>베팅내역 전체 펼치기</button>
                            <div style={{display: "flex", paddingLeft: "5px", paddingRight: "5px", alignItems: "center"}}>
                                <span className="mx-1">◀</span>
                                <input className="form-control sm-date" type="date" style={{width: "120px"}}/>
                                <input className="sm-input" type="text" style={{width: "80px"}}/>
                                <span className="mx-1">부터 ~</span>
                                <input className="form-control sm-date" type="date" style={{width: "120px"}}/>
                                <span className="mx-1">▶</span>
                                <input className="sm-input" type="text" style={{width: "80px"}}/>
                                <span className="mx-1">까지 (시간분 예시 : 1230)</span>
                            </div>
                            <div className="sm-center-div">
                                <label className="sm-label">
                                    카지노 :
                                </label>
                                <select className="form-select mx-1 sm-select" aria-label="Default select example" style={{width: "150px"}}>
                                    <option selected>[1] 에볼루션 카지노</option>
                                </select>
                                <label className="sm-label">
                                    게임
                                </label>
                                <input className="sm-input" type="text" style={{width: "120px"}}/>
                            </div>
                        </div>

                        <div className="sm-center-div">
                            <select className="form-select sm-select" aria-label="Default select example">
                                <option selected>전체 사이트 코드</option>
                            </select>
                            <select className="form-select sm-select" aria-label="Default select example">
                                <option selected>::총판/가맹점::</option>
                                <option value="1">* 본사총판 [본사직영]</option>
                            </select>
                            <select className="form-select sm-select" aria-label="Default select example">
                                <option selected>::회원분류::</option>
                                <option value="1">대기회원</option>
                                <option value="1">무료회원</option>
                                <option value="1">유료회원</option>
                                <option value="1">VIP회원</option>
                                <option value="1">가라회원</option>
                                <option value="1">관리자</option>
                                <option value="1">최고관리자</option>
                            </select>

                            <select className="form-select sm-select" aria-label="Default select example">
                                <option selected>닉네임</option>
                                <option value="1">도메인</option>
                                <option value="1">아이디</option>
                                <option value="1">이름</option>
                                <option value="1">핸드폰</option>
                                <option value="1">이메일</option>
                                <option value="1">가입아이피</option>
                                <option value="1">최근아이피</option>
                            </select>
                            <input className="form-check-input sm-input" type="text"/>

                            <select className="form-select sm-select" aria-label="Default select example">
                                <option selected>베팅금 ~ 이상</option>
                            </select>
                            <input className="form-check-input sm-input" type="text"/>
                        </div>
                        <div className="sm-center-div">
                            <label>
                                P등급 :
                            </label>

                            <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}}>전체 P등급</button>
                            <button className="ms-1 me-1" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "16px", width: "fit-content"}}>전체 P해제</button>

                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                1-1 [1]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                1-2 [1]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                1-3 [1]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                1-4 [1]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                1-5 [1]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                2 [1]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                2-3 [2]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                3 [3]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                4 [3]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                5 [3]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                6 [3]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                6-2 [1]
                            </label>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label" htmlFor="flexRadioDefault11">
                                6-3 [1]
                            </label>

                        </div>

                    </div>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "50px", width: "fit-content"}}>현재 페이지<br/>새로고침</button>
                </div>
                <div className="sm-font">
                    <span style={{fontWeight: "bold"}}>[베팅: 원 / 0건] [적중: <span style={{color: "#3fadf7"}}>0</span>원] [마진: <span style={{color: "#3fadf7"}}>0</span>원]</span>
                    <br/>
                    <span>* 이 통계 금액은 기간/유저 까지 적용된 금액이며, 베팅금, 카지노, 게임상세 검색은 적용되지 않습니다.<span style={{color: "red", backgroundColor: "yellow"}}>(가라 회원 제외), 단 아래 베팅내역에서는 가라회원 베팅내역도 표기 됩니다.</span></span>
                    <br/>
                    <span style={{color: "red"}}>* 베팅내역은 1분마다 한 번씩 갱신됩니다. 따라서, 1분 이내에는 베팅내역이 실시간으로 확인되지 않습니다.(최대 지연시 5~10분 가능)</span>
                    <br/>
                    <span style={{color: "red"}}>* 베팅정보 항목은 클릭하면 자동으로 복사 됩니다.</span>
                    <br/>
                    <span style={{color: "red", backgroundColor: "yellow"}}>* 카지노 베팅내역은 최대 60일간 보관되며 그 이전은 통계항목으로만 조회 가능합니다.</span>

                </div>

                <div className="common">
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>시간</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>소속</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>회원구분</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>P레벨</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>카지노</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>게임</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>게임구분</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>txn_id</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>구분</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>구분상세</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>베팅금</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>적중금</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>기존 보유금</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>이후 보유금</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>베팅정보</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>23/11/13 16:28<span style={{color: "lightgray"}}>:36</span></td>
                            <td>sjk장문</td>
                            <td style={{backgroundColor: "gray"}}><span style={{color: "yellow"}}>jsh7618</span></td>
                            <td style={{backgroundColor: "gray"}}><span style={{color: "yellow"}}>보괴맨</span></td>
                            <td><span style={{color: "green"}}>유료회원</span></td>
                            <td>1-2 [1]</td>
                            <td>에볼루션 카지노</td>
                            <td>코리안 스피드 바카라 C</td>
                            <td>baccarat</td>
                            <td>656ee763eea8377</td>
                            <td>베팅</td>
                            <td><span style={{color: "lightgray"}}>낙첨</span></td>
                            <td><span style={{color: "#3fadf7"}}>50,000</span></td>
                            <td><span style={{color: "#3fadf7"}}>0</span></td>
                            <td><span style={{color: "#3fadf7"}}>153,250</span></td>
                            <td><span style={{color: "#3fadf7"}}>103,250</span></td>
                            <td><span style={{fontWeight: "bold", color: "blue"}}>Player </span><span style={{color: "#3fadf7"}}>50,000</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

    )
}
export default CenterView;