import {call, put} from "redux-saga/effects";
import {failedGetDangerAccountSettingListReducer, setDangerAccountSettingListReducer} from "../../reducers/setting/DangerAccountSettingListReducer";
import {fetchDangerAccountSettingList} from "../../../lib/api";

export function* getDangerAccountSettingListSaga(action) {
    try{
        console.log("##getDangerAccountSettingListSaga input#",action.payload.input)
        const res = yield call(fetchDangerAccountSettingList,
            action.payload.input,
        );
        console.log("##getDangerAccountSettingListSaga output#",res.data)
        yield put(setDangerAccountSettingListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDangerAccountSettingListReducer(error))
    }
}