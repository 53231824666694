import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {getRecommendKeyListReducer,setIsOpen} from "../../redux/reducers/partner/RecommendKeyListReducer";
import {Link, useNavigate, useParams} from "react-router-dom"
import Pagination from "../../components/common/Pagination2";
import axios from "axios";
import * as XLSX from "xlsx";
import {insertAdminLog} from "../../lib/api";
import RecommendAddComponent from "../../components/modal/partner/RecommendAddComponent";
import MemberInformationDetailModal from "../../components/modal/user/detail/MemberInformationDetailModal";

const Recommend_list = () => {
    const param = useParams()
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.RecommendKeyListReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)

    const getList = () => {
        //유저아이디 인자있는거 가져온거 이거 활용해서 만드세요
        //TODO 유저아이디 있을씨 자동선택처리
        const userId = param.userId

        if(initialState.totalList !== null ) {
            dispatch(getRecommendKeyListReducer({
                input:{
                    page: 1,
                    pageSize: initialState.input.pageSize,
                }
            }))
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "추천인키관리"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getRecommendKeyListReducer({
            input:{
                page: page,
                pageSize: initialState.input.pageSize,
            }
        }))
    }

    const search = () => {
        dispatch(getRecommendKeyListReducer({
            input:{
                page: 1,
                pageSize: initialState.input.pageSize,
            }
        }))
    }

    const deleteHandler = (no) => {
        if(!window.confirm("이 키를 삭제하시겠습니까? (1/3)")) return false
        if(!window.confirm("이 키를 삭제하시겠습니까? (2/3)")) return false
        if(!window.confirm("이 키를 삭제하시겠습니까? (3/3)")) return false

        axios.post('/admin/partner/deleteRecommendKey', {
            no : no,
        }).then((res) => {
            if(res.data.IsDeleted) {
                alert("삭제했습니다");
            }
            else {
                alert('삭제실패')
            }
            getList();
        })
    }

    const updatePointVal = (no,pointGb,pointVal) => {
        let message = "";
        if(pointGb === "reg_join_point"){
            message = "변경할 가입시 지급 포인트를 입력하세요. 기본 설정을 따르게 하려면 -1을 입력하세요";
        }else if(pointGb === "reg_charge_point"|| pointGb === "first_charge_point" || pointGb === "mae_charge_point"){
            message = "이 코드로 가입한 회원에게 지급할 가입첫충전 포인트 비율(%)을 입력세요. 기본 설정을 따르게 하려면 -1을 입력하세요. 이 설정은 레벨별설정보다 우선하지만,개인설정이 있다면 개인설정을 따릅니다. ";
        }

        let inputResult = prompt(message,pointVal);

        if (inputResult !== null) {

            //숫자가 아닌 글자가 들어올경우 0 으로 치환
            let inputValueAsNumber= parseInt(inputResult, 10);
            if (isNaN(inputValueAsNumber)) {
                inputValueAsNumber = 0;
            }
            inputResult =  inputValueAsNumber;
            //수정 api 호출
            axios.post('/admin/partner/updateRecommendKey', {
                no : no,
                pointGb : pointGb,
                pointVal : inputResult,
            }).then((res) => {
                if(res.data.IsUpdated) {
                    alert("수정했습니다");
                }
                else {
                    alert('수정실패')
                }
                getList();
            })
        }
    }


    const convertMinutesToDaysHoursMinutes = (totalMinutes) => {
        if(totalMinutes === 0){
            return '제한없음';
        }
        const days = Math.floor(totalMinutes / (24 * 60)) === 0 ? "" : Math.floor(totalMinutes / (24 * 60));
        const hours = Math.floor((totalMinutes % (24 * 60)) / 60) === 0 ? "" : Math.floor((totalMinutes % (24 * 60)) / 60);
        const minutes = totalMinutes % 60 === 0 ? "" : totalMinutes % 60;
        const daysNm = days === "" ?"":"일";
        const hourNm = hours === "" ?"":"시간";
        const minutesNm = minutes === "" ?"":"분";

        return days+daysNm+hours+hourNm+minutes+minutesNm;
    };

    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        refresh()
    }

    const refresh = () =>{

        dispatch(getRecommendKeyListReducer({
            input:{
                page: 1,
                pageSize: initialState.input.pageSize,
            }
        }))
    }
    const handleClickOpen = () => {
        dispatch(setIsOpen(true))
    }
    const exportExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {
            let jsonObject = { // JSON 객체를 생성합니다.
                번호: initialState.totalList[i].no? initialState.totalList[i].no :'',
                추천인키 : initialState.totalList[i].recommendKey? initialState.totalList[i].recommendKey :'',
                바로가입_링크: initialState.totalList[i].link? initialState.totalList[i].link :'',
                부본사명: initialState.totalList[i].companySub? initialState.totalList[i].companySub :'',
                총판명 : initialState.totalList[i].distributor? initialState.totalList[i].distributor :'',
                가맹점명 : initialState.totalList[i].partner? initialState.totalList[i].partner :'',
                회원명 : initialState.totalList[i].recommendUserId? initialState.totalList[i].recommendUserId :'',
                가입축하포인트 : initialState.totalList[i].regJoinPoint? initialState.totalList[i].regJoinPoint :'',
                가입첫충포인트 : initialState.totalList[i].regChargePoint? initialState.totalList[i].regChargePoint :'',
                첫충포인트 : initialState.totalList[i].firstChargePoint? initialState.totalList[i].firstChargePoint :'',
                매충포인트 : initialState.totalList[i].maeChargePoint? initialState.totalList[i].maeChargePoint :'',
                생성일 : initialState.totalList[i].createdAt? initialState.totalList[i].createdAt :'',
                시간제한 : initialState.totalList[i].timeLimit? initialState.totalList[i].timeLimit :'',
                횟수제한 : initialState.totalList[i].cntLimit? initialState.totalList[i].cntLimit :'',
                사용횟수 : initialState.totalList[i].useCnt? initialState.totalList[i].useCnt :'',
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '추천인키목록');
        XLSX.writeFile(workBook, '추천인키목록.xlsx');
    }
    return (
        <div className="common" >
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">추천인키관리</font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"50%"}>
                        <tbody>
                        <tr>
                            <td align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <button onClick={exportExcel} style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>엑셀 다운로드</button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table width={"49%"}>
                        <tbody>
                        <tr>
                            <td align={"right"}>


                                <button onClick={handleClickOpen} style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>추천인 키 등록</button>
                                {initialState.isOpen && <RecommendAddComponent open={initialState.isOpen} onClose={handleClickClose} refresh={refresh} />}

                                <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>삭제된 추천인 키 전체 초기화</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <table className="table table-bordered table-sm text-center align-middle">
                <tbody className="table-dark">
                <tr>

                </tr>
                </tbody>
            </table>
            <div className="commonT container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="commonT table table-bordered table-sm text-center align-middle">
                    <thead>
                    <tr>
                        <th style={{width: "3%"}} >번호</th>
                        <th style={{width: "5%"}} >추천인 키</th>
                        <th style={{width: "*%"}} >바로가입 링크</th>
                        <th style={{width: "7%"}} >부본사명</th>
                        <th style={{width: "7%"}} >총판명</th>
                        <th style={{width: "7%"}} >가맹명</th>
                        <th style={{width: "7%"}} >회원</th>
                        <th style={{width: "5%"}} >가입축하<br></br>포인트</th>
                        <th style={{width: "5%"}} >가입첫충<br></br>포인트(%)</th>
                        <th style={{width: "5%"}} >첫충<br></br>포인트(%)</th>
                        <th style={{width: "5%"}} >매충<br></br>포인트(%)</th>
                        <th style={{width: "5%"}} >생성일</th>
                        <th style={{width: "5%"}} >시간제한</th>
                        <th style={{width: "5%"}} >횟수제한</th>
                        <th style={{width: "7%"}} >사용횟수</th>
                        <th style={{width: "5%"}} >삭제</th>
                    </tr>
                    </thead>
                    {initialState.totalList && initialState.totalList.length > 0 ? initialState.gridList.map((List, index) => (
                            <tbody key={List.no}>
                            <tr>
                                <td align={"center"}>{List.no}</td>
                                <td align={"center"}><b>{List.recommendKey}</b></td>
                                <td align={"center"} ><b></b><font color="red">{List.link}</font></td>
                                <td align={"center"}>{List.companySub}</td>
                                <td align={"center"}>{List.distributor}</td>
                                <td align={"center"}>{List.partner}</td>
                                <td align={"center"}>{List.recommendUserId}</td>
                                <td align={"center"} onClick={()=>updatePointVal(List.no,"reg_join_point",List.regJoinPoint)}>{List.regJoinPoint === -1 ? "[기본설정]" : List.regJoinPoint}</td>
                                <td align={"center"} onClick={()=>updatePointVal(List.no,"reg_charge_point",List.regChargePoint)}>{List.regChargePoint === -1 ? "[기본설정]" : List.regChargePoint}</td>
                                <td align={"center"} onClick={()=>updatePointVal(List.no,"first_charge_point",List.firstChargePoint)}>{List.firstChargePoint === -1 ? "[기본설정]" : List.firstChargePoint}</td>
                                <td align={"center"} onClick={()=>updatePointVal(List.no,"mae_charge_point",List.maeChargePoint)}>{List.maeChargePoint === -1 ? "[기본설정]" : List.maeChargePoint}</td>
                                <td align={"center"}>{List.createdAt}</td>
                                <td align={"center"}>{convertMinutesToDaysHoursMinutes(List.timeLimit)}</td>
                                <td align={"center"}>{List.cntLimit === 0 ? '제한없음' : List.cntLimit}</td>
                                <td align={"center"}>{List.useCnt}회 사용<br></br><a href=""><font color="blue">[사용 회원 확인]</font></a></td>
                                <td align={"center"}>
                                    <button onClick={()=>deleteHandler(List.no)} style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "23px", width: "fit-content", marginRight: "10px"}}>삭제</button>
                                </td>
                            </tr>
                            </tbody>
                        )) :
                        <tbody>
                        <tr>
                            <td colSpan= "18">조회된 데이터가 없습니다.</td>
                        </tr>
                        </tbody>
                    }
                </table>
            </div>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가*/}
                {initialState.totalCount && initialState.totalCount > 0 ?
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.input.pageSize} currentPage={initialState.input.page} onPageChange={handlePageChange} />
                    :""}
            </div>
        </div>

    )
}
export default Recommend_list;