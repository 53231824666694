import {useDispatch} from "react-redux";
import {
    changeIsLoading,
    getLeagues,
    setGameKinds,
    setLeagues,
    setSelectedKind
} from "../../../redux/reducers/game/gameSetting";
import axios from "axios";
import Switch from "react-switch";


const GameKindList = ({state, onRefresh}) => {
    const dispatch = useDispatch();

    const changeKind = (item) => {
        dispatch(changeIsLoading(true))
        axios.post('/admin/game/setting/changeKind', {
            kindItem: item
        }).then((res) => {
            dispatch(changeIsLoading(false))
            if(res.data.isChanged) {
                alert("변경완료")
                onRefresh()
            }
            else {
                alert("변경실패")
            }
        })
    }
    const changeAllKind = () => {
        if(state.kinds.length > 0) {
            dispatch(changeIsLoading(true))
            axios.post('/admin/game/setting/changeAllKind', {
                list: state.kinds
            }).then((res) => {
                dispatch(changeIsLoading(false))
                if(res.data.isChanged) {
                    alert("변경완료")
                    onRefresh()
                }
                else {
                    alert("변경실패")
                }
            })
        }
    }

    return <div className="card-body" style={{height: "800px", overflowY: "scroll"}}>
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>코드</th>
                    <th>아이콘</th>
                    <th>종목</th>
                    <th>파싱명</th>
                    <th>프리매치업체</th>
                    <th>라이브업체</th>
                    <th>사용여부</th>
                    <th>
                        <button className="btn btn-sm btn-primary" onClick={(e) => {
                            changeAllKind()
                        }}>일괄변경</button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {state.kinds && state.kinds.map((item, index) => (
                    <tr style={{backgroundColor: state.selectedKind === item.id? '#ead980': "transparent"}}
                        key={item.id}
                    >
                        <td>{item.id}</td>
                        <td>
                            <div className="d-flex align-items-center">
                                <img src="/icon/2641.png" width="20px" height="20px"/>
                                <input className="form-control form-control-sm" type="file"/>
                            </div>
                        </td>
                        <td style={{ textAlign: 'center',paddingLeft: '10px' }}>
                            <input className="sm-input" type="text" style={{ width: '100px' }} value={item.name_kor? item.name_kor: ""} onChange={(e) => {
                                const newItem = {...item}
                                newItem.name_kor = e.currentTarget.value
                                const arr = [...state.kinds]
                                arr[index] = newItem
                                dispatch(setGameKinds({
                                    result: arr
                                }))
                            }}/>
                        </td>
                        <td>{item.name}</td>
                        <td>
                            <select className="sm-select" value={item.bookmaker} onChange={(e) => {
                                const newItem = {...item}
                                newItem.bookmaker = e.currentTarget.value
                                const arr = [...state.kinds]
                                arr[index] = newItem
                                dispatch(setGameKinds({
                                    result: arr
                                }))
                            }}>
                                <option value={null}>배당업체</option>
                                <option value={8}>Bet365</option>
                                <option value={4}>Pinnacle</option>
                                <option value={145}>1xBet</option>
                                <option value={74}>MarathonBet</option>
                            </select>
                        </td>
                        <td>
                            <select className="sm-select" value={item.live_bookmaker} onChange={(e) => {
                                const newItem = {...item}
                                newItem.live_bookmaker = e.currentTarget.value
                                const arr = [...state.kinds]
                                arr[index] = newItem
                                dispatch(setGameKinds({
                                    result: arr
                                }))
                            }}>
                                <option value={null}>배당업체</option>
                                <option value={8}>Bet365</option>
                                <option value={4}>Pinnacle</option>
                                <option value={145}>1xBet</option>
                                <option value={74}>MarathonBet</option>
                            </select>
                        </td>
                        <td>
                            <Switch onChange={(checked) => {
                                const newItem = {...item}
                                newItem.is_use = checked
                                const arr = [...state.kinds]
                                arr[index] = newItem
                                dispatch(setGameKinds({
                                    result: arr,
                                }))
                            }} checked={item.is_use} onColor="#2196F3"/>
                        </td>
                        <td>
                            <div>
                                <button className="btn btn-sm btn-primary" onClick={(e) => {
                                    changeKind(item)
                                }}>변경</button>
                                <button className="btn btn-sm btn-danger" onClick={(e) => {
                                    dispatch(setSelectedKind(item))
                                    dispatch(setLeagues({result: []}))
                                    dispatch(getLeagues({
                                        page: 1,
                                        pageSize: 20,
                                        nationName: state.selectedNationName,
                                        gameName: (item.name_kor && item.name_kor !== "")? item.name_kor : item.name,
                                        query: "",
                                        useCode: 999,
                                    }))
                                }}>리그정보</button>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

    </div>
}

export default GameKindList