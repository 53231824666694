import {call, put} from "redux-saga/effects";
import {failedGetLvlSettingSingleReducer, setLvlSettingSingleReducer} from "../../reducers/setting/LvlSettingSingleReducer";
import {fetchLvlSettingSingleList} from "../../../lib/api";

export function* getLvlSettingSingleSaga(action) {
    try{
        console.log("##getLvlSettingSingleSaga input#",action.payload.input)
        const res = yield call(fetchLvlSettingSingleList,
            action.payload.input,
        );
        console.log("##getLvlSettingSingleSaga output#",res.data)
        yield put(setLvlSettingSingleReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetLvlSettingSingleReducer(error))
    }
}