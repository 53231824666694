import React from "react";

const Pagination = ({totalCount, pageSize, currentPage, onPageChange }) => {
    const pageCount = Math.ceil(totalCount / pageSize);
    let startFromNumber = 1
    let pagesToShow = 7
    let totalPage = Math.ceil(totalCount / pageSize)
    if(totalPage <= pagesToShow) {
        startFromNumber = 1
        pagesToShow = totalPage
    }
    else {
        if(currentPage <= Math.ceil(pagesToShow / 2)) {
            startFromNumber = 1;
        }
        else if(currentPage + Math.floor((pagesToShow - 1) / 2) >= totalPage) {
            startFromNumber = totalPage - (pagesToShow - 1);
        }
        else {
            startFromNumber = currentPage - Math.floor(pagesToShow / 2);
        }
    }
    const pages = [];
    for(let i = 1; i <= pagesToShow; i++) {
        pages.push(startFromNumber++);

    }

    if (pageCount === 1 || pageSize === 999) return null;
    //const pages = _.range(1, pageCount + 1);

    return (
        <div>
            <nav>
                <ul className="pagination">
                    <li
                        key="first"
                        className="page-item" // Bootstrap을 이용하여 현재 페이지를 시각적으로 표시
                        style={{ cursor: "pointer" }}>
                        <a className="page-link" onClick={() => onPageChange(1)}><font size="2">처음</font></a> {/* 페이지 번호 클릭 이벤트 처리기 지정 */}
                    </li>
                    {pages.map(page => (
                        <li
                            key={page}
                            className={page === currentPage ? "page-item active" : "page-item"} // Bootstrap을 이용하여 현재 페이지를 시각적으로 표시
                            style={{ cursor: "pointer" }}>
                            <a className="page-link" onClick={() => onPageChange(page)}><font size="2">{page}</font></a> {/* 페이지 번호 클릭 이벤트 처리기 지정 */}
                        </li>
                    ))}
                    <li
                        key="last"
                        className="page-item" // Bootstrap을 이용하여 현재 페이지를 시각적으로 표시
                        style={{ cursor: "pointer" }}>
                        <a className="page-link" onClick={() => onPageChange(totalPage)}><font size="2">마지막</font></a> {/* 페이지 번호 클릭 이벤트 처리기 지정 */}
                    </li>
                </ul>
            </nav>
        </div>

    )
}
export default Pagination;