import React from 'react';
import MemberContainer from "../../containers/user/MemberListContainer";
import {useParams} from "react-router-dom";


const Member = () => {
    const param = useParams()
    const onClickReset = (e) => {
        window.location.reload();
    }

    return (
        <div>
            <MemberContainer topSearchVal01={param.userId}/>
        </div>
    );
};

export default Member;