import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {useParams} from "react-router-dom";
import {insertAdminLog} from "../../../lib/api";

const SetRolling = (props) => {
    const userInfoId = useSelector((state) => state.userInfo.id)
    const {open, onClose, initialState} = props
    const param = useParams()
    const [rollingList, setRollingList] = useState([]);

    const [ratioSportOne, setRatioSportOne] = useState([]);
    const [ratioSportTwo, setRatioSportTwo] = useState([]);
    const [ratioSportThree, setRatioSportThree] = useState([]);
    const [ratioSportMore, setRatioSportMore] = useState([]);
    const [ratioSportCasino, setRatioSportCasino] = useState([]);
    const [ratioSportSlot, setRatioSportSlot] = useState([]);
    const [ratioSportMini, setRatioSportMini] = useState([]);

    const [ratioSportOneAll, setRatioSportOneAll] = useState('');
    const [ratioSportTwoAll, setRatioSportTwoAll] = useState('');
    const [ratioSportThreeAll, setRatioSportThreeAll] = useState('');
    const [ratioSportMoreAll, setRatioSportMoreAll] = useState('');
    const [ratioSportCasinoAll, setRatioSportCasinoAll] = useState('');
    const [ratioSportSlotAll, setRatioSportSlotAll] = useState('');
    const [ratioSportMiniAll, setRatioSportMiniAll] = useState('');


    const getCmmnCd = () => {
        //alert("param.userId :: "+param.userId);
        if(rollingList.length === 0){
            axios.post('/admin/partner/getRolling01', {
                id : param.userId,
            }).then((res) => {
                //alert("res.data.cmmnCdList : "+JSON.stringify(res.data.cmmnCdList));
                setRollingList(res.data.cmmnCdList);
            })
        }
        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "롤링설정팝업"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getCmmnCd, [])

    const handleCancel = () => {
        onClose()
    }

    const openPopup = ()=> {
        open()
    }

    const setRatioSportOneHandler = (e, index) => {
        ratioSportOne[index] = e.target.value;
        setRatioSportOne(ratioSportOne);
    }
    const setRatioSportTwoHandler = (e, index) => {
        ratioSportTwo[index] = e.target.value;
        setRatioSportTwo(ratioSportTwo);
    }
    const setRatioSportThreeHandler = (e, index) => {
        ratioSportThree[index] = e.target.value;
        setRatioSportThree(ratioSportThree);
    }
    const setRatioSportMoreHandler = (e, index) => {
        ratioSportMore[index] = e.target.value;
        setRatioSportMore(ratioSportMore);
    }
    const setRatioSportCasinoHandler = (e, index) => {
        ratioSportCasino[index] = e.target.value;
        setRatioSportCasino(ratioSportCasino);
    }
    const setRatioSportSlotHandler = (e, index) => {
        ratioSportSlot[index] = e.target.value;
        setRatioSportSlot(ratioSportSlot);
    }
    const setRatioSportMiniHandler = (e, index) => {
        ratioSportMini[index] = e.target.value;
        setRatioSportMini(ratioSportMini);
    }

    const saveSetRolling = (id, index) => {

        //alert("id :: "+id + " index : "+index+ " ratioSportOne[index] : "+ratioSportOne[index]);

        if (window.confirm("변경 하시겠습니까?"))
        {
            //alert("userInfo.id :: "+userInfo.id);
            axios.post('/admin/partner/updateSetRolling', {
                id: id,
                ratioSportOne: ratioSportOne[index],
                ratioSportTwo: ratioSportTwo[index],
                ratioSportThree: ratioSportThree[index],
                ratioSportMore: ratioSportMore[index],
                ratioSportCasino: ratioSportCasino[index],
                ratioSportSlot: ratioSportSlot[index],
                ratioSportMini: ratioSportMini[index]
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }

    }


    const setRatioSportOneAllHandler = (e) => {
        setRatioSportOneAll(e.target.value);
    }
    const setRatioSportTwoAllHandler = (e) => {
        setRatioSportTwoAll(e.target.value);
    }
    const setRatioSportThreeAllHandler = (e) => {
        setRatioSportThreeAll(e.target.value);
    }
    const setRatioSportMoreAllHandler = (e) => {
        setRatioSportMoreAll(e.target.value);
    }
    const setRatioSportCasinoAllHandler = (e) => {
        setRatioSportCasinoAll(e.target.value);
    }
    const setRatioSportSlotAllHandler = (e) => {
        setRatioSportSlotAll(e.target.value);
    }
    const setRatioSportMiniAllHandler = (e) => {
        setRatioSportMiniAll(e.target.value);
    }


    const saveSetRollingAll = (id) => {
        if (window.confirm("변경 하시겠습니까?"))
        {
            axios.post('/admin/partner/updateSetRollingAll', {
                id: id,
                ratioSportOne: ratioSportOneAll,
                ratioSportTwo: ratioSportTwoAll,
                ratioSportThree: ratioSportThreeAll,
                ratioSportMore: ratioSportMoreAll,
                ratioSportCasino: ratioSportCasinoAll,
                ratioSportSlot: ratioSportSlotAll,
                ratioSportMini: ratioSportMiniAll
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패 " + res.data.message)
                popClose()
            })
        }

    }

    const popClose =() => {
        if (window.opener && typeof window.opener.popupClose === 'function') {
            window.opener.popupClose();
        }
        window.close();
    }

    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <button style={{ backgroundColor: "lightgray",
                    color: "black", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                        onClick={(e)=> {
                            window.location.href= "/partner/setLosing/"+param.userId;
                        }}
                >루징설정</button>
                <button style={{ backgroundColor: "blue",
                    color: "white", border: "none", fontSize: "9px", height: "20px", width: "100px", fontWeight: "bold", marginRight: "1px"}}
                >롤링설정</button>
                <br></br>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="table table-striped mt-2 scroll-horizontal">
                    <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                        <thead>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"23%"}>지점</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"}>스포츠1폴(%)</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"}>스포츠2폴더</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"}>스포츠3폴더</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"}>스포츠4폴더이상</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"}>카지노</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"}>슬롯(%)</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"10%"}>미니게임</td>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"7%"}></td>

                        </tr>
                        </thead>
                        {!rollingList.length && (
                            <tbody key={rollingList.id}>
                            <tr>
                                <td colSpan="9">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        <tbody>
                            <tr>
                                <td style={{backgroundColor: "lightseagreen"}} align={"left"}></td>
                                <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}}
                                           onChange={(e) => {setRatioSportOneAllHandler(e)}} />
                                </td>
                                <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}}
                                           onChange={(e) => {setRatioSportTwoAllHandler(e)}} />
                                </td>
                                <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}}
                                           onChange={(e) => {setRatioSportThreeAllHandler(e)}} />
                                </td>
                                <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}}
                                           onChange={(e) => {setRatioSportMoreAllHandler(e)}} />
                                </td>
                                <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}}
                                           onChange={(e) => {setRatioSportCasinoAllHandler(e)}} />
                                </td>
                                <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}}
                                           onChange={(e) => {setRatioSportSlotAllHandler(e)}} />
                                </td>
                                <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}}
                                           onChange={(e) => {setRatioSportMiniAllHandler(e)}} />
                                </td>
                                <td style={{backgroundColor: "lightseagreen"}} align={"center"}>
                                    <button style={{
                                        backgroundColor: "lightgray",
                                        border: "none",
                                        color: "black",
                                        fontSize: "9px",
                                        height: "20px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {saveSetRollingAll(rollingList)}}
                                    >일괄변경
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        {rollingList?.map((rollingList, index) => (
                            <tbody key={rollingList.id}>
                            <tr>
                                <td style={{backgroundColor: "white"}} align={"left"}>
                                    {rollingList.lev2 ===  '하위조직'? 'ㄴ' : ''}{rollingList.lev1}
                                    ({rollingList.id})
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}} placeholder={rollingList.ratio_sport_one}
                                           onChange={(e) => {setRatioSportOneHandler(e, index)}} />
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}} placeholder={rollingList.ratio_sport_two}
                                           onChange={(e) => {setRatioSportTwoHandler(e, index)}} />
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}} placeholder={rollingList.ratio_sport_three}
                                           onChange={(e) => {setRatioSportThreeHandler(e, index)}} />
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}} placeholder={rollingList.ratio_sport_more}
                                           onChange={(e) => {setRatioSportMoreHandler(e, index)}} />
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}} placeholder={rollingList.ratio_sport_casino}
                                           onChange={(e) => {setRatioSportCasinoHandler(e, index)}} />
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}} placeholder={rollingList.ratio_sport_slot}
                                           onChange={(e) => {setRatioSportSlotHandler(e, index)}} />
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <input className="form-check-input sm-input" type="text"
                                           style={{width: "80px"}} placeholder={rollingList.ratio_sport_mini}
                                           onChange={(e) => {setRatioSportMiniHandler(e, index)}} />
                                </td>
                                <td style={{backgroundColor: "white"}} align={"center"}>
                                    <button style={{
                                        backgroundColor: "lightgray",
                                        border: "none",
                                        color: "black",
                                        fontSize: "9px",
                                        height: "20px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {saveSetRolling(rollingList.id, index)}}
                                    >변경
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>

    )
}
export default SetRolling;