import {call, put, take,} from "redux-saga/effects";
import {fetchLoginFails} from "../../../lib/api";
import {failedGetLoginFails, setLoginFails} from "../../reducers/monitoring/LoginFailReducer";

export function* getLoginFailSaga(action) {
    try{
        const res = yield call(
            fetchLoginFails,
            action.payload.startDate, action.payload.endDate, action.payload.loginType,
            action.payload.searchGb, action.payload.searchQuery,
            action.payload.page, action.payload.pageSize
        )

        yield put(setLoginFails(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetLoginFails(error))
    }
}