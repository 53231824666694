import {call, put} from "redux-saga/effects";
import {failedGetMemberInformationSimpleModalReducer, setMemberInformationSimpleModalReducer} from "../../reducers/member/MemberInformationSimpleModalReducer";
import {fetchMemberInformationSimpleModal} from "../../../lib/api";

export function* getMemberInformationSimpleModalSaga(action) {
    try{
        console.log("##getMemberInformationSimpleModalSaga input#",action.payload.input)
        const res = yield call(fetchMemberInformationSimpleModal,
            action.payload.input,
        );
        console.log("##getMemberInformationSimpleModalSaga output#",res.data)
        yield put(setMemberInformationSimpleModalReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMemberInformationSimpleModalReducer(error))
    }
}