import NationList from "../../../components/game/setting/NationList";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Switch from "react-switch";
import {changeIsLoading, getGameKinds, getNations} from "../../../redux/reducers/game/gameSetting";
import Pagination from "../../../components/common/Pagination";
import {getCustomers} from "../../../redux/reducers/board/CustomerReducer";


const NationContainer = () => {

    const state = useSelector((state) => state.gameSetting);
    const dispatch = useDispatch();
    const [addList, setAddList] = useState([])
    const [useCode, setUseCode] = useState(999)
    const [searchQuery, setSearchQuery] = useState("")

    const input = {
        query: searchQuery,
        useCode: useCode,
    }

    useEffect(() => {
        dispatch(getNations(input))
    }, [])

    const onRefresh = () => {
        dispatch(getNations(input))
    }
    const insertNation = (item) => {
        if(state.selectedKind > 0) {
            dispatch(changeIsLoading(true))
            axios.post('/admin/game/setting/insertNation', {
                nationItem: item,
            }).then((res) => {
                dispatch(changeIsLoading(false))
                if(res.data.isInserted) {
                    alert("등록완료")
                    const arr = addList.filter((el) => el.instantId !== item.instantId)
                    setAddList(arr)
                    onRefresh()
                }
            })
        }
        else {
            alert("종목을 선택해주세요")
        }
    }

    const insertAllNation = () => {
        if(state.selectedKind > 0) {
            dispatch(changeIsLoading(true))
            axios.post('/admin/game/setting/insertAllNation', {
                nations: addList,
            }).then((res) => {
                dispatch(changeIsLoading(false))
                if (res.data.isInserted) {
                    alert("일괄등록완료")
                    setAddList([])
                    onRefresh()
                }
            })
        }
        else {
            alert("종목을 선택해주세요")
        }
    }

    const changeUseCode = (e) => {
        setUseCode(e.currentTarget.value)
        const fixInput = {...input}
        fixInput.useCode = e.currentTarget.value
        dispatch(getNations(fixInput))
    }
    const changeQuery = (e) => {
        setSearchQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.currentTarget.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.query = query
        dispatch(getNations(fixInput))
    }

    const getNationsFromMatchServer = () => {
        axios.post('/admin/game/setting/getNationsFromMatchServer', {

        }).then((res) => {
            if(res.data.inserted) {
                alert('완료')
                onRefresh()
            }
            else {
                alert('실패')
            }
        })
    }

    return <div className="common">
        <div className="d-flex">
            <div className="d-flex w-50">
                <input style={{
                    width: "200px"
                }} type="text" className="form-control" id="search_edit" placeholder="국가명" value={searchQuery} onChange={changeQuery} onKeyDown={inputPress}/>
                <button style={{
                    width: "80px", borderTopRightRadius: "5px" ,borderBottomRightRadius: "5px"
                }} type="submit" className="btn btn-primary" onClick={(e) => {
                    onSearch(searchQuery)
                }}>검색</button>
            </div>
            <div className="d-flex justify-content-end mb-1 w-50">

                <button className="btn btn-secondary" onClick={(e) => {
                    if(state.selectedKind > 0) {
                        const arr = [...addList]
                        arr.push({
                            instantId: new Date().getTime(),
                            name_kor: "",
                        })
                        setAddList(arr)
                    }
                    else {
                        alert('종목을 선택해 주세요')
                    }
                }}>추가</button>
                <button className="btn btn-primary mx-1" onClick={(e) => {
                    onRefresh()
                }}>새로고침</button>
                <button className="btn btn-success" onClick={(e) => {
                    getNationsFromMatchServer()
                }}>가져오기</button>
            </div>
        </div>

        {addList.length > 0 && (
            <div>
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-secondary">
                    <tr>
                        <td colSpan="4">
                            국가신규등록
                        </td>
                    </tr>
                    <tr>
                        <th>국가</th>
                        <th>사용여부</th>
                        <th>
                            <button className="btn btn-secondary mb-1" onClick={(e) => {
                                insertAllNation()
                            }}>일괄저장</button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {addList.map((item, index) => (
                        <tr>
                            <td>
                                <input className="form-control form-control-sm me-1" type="text" value={item.name_kor} style={{width: "130px"}} placeholder="종목"
                                       onChange={(e) => {
                                           item.name_kor = e.currentTarget.value
                                           const arr = [...addList]
                                           arr[index] = item
                                           setAddList(arr)
                                       }}/>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <button className="btn btn-secondary me-1" onClick={(e) => {
                                        insertNation(item)
                                    }}>저장</button>
                                    <button className="btn btn-warning ms-1" onClick={(e) => {

                                        const arr = addList.filter((el) => el.instantId !== item.instantId)
                                        const arr2 = [...arr]
                                        setAddList(arr2)
                                    }}>삭제</button>
                                </div>

                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )}

        <NationList onRefresh={onRefresh} />
    </div>
}

export default NationContainer