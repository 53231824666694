import axios from "axios";

export const fetchBlackMemberList = async (page, pageSize, query) => {
    return axios.post("/admin/user/Black_member/getBlackMemberList", {page: page, pageSize: pageSize,query:query})
}

export function fetchMember(page, pageSize, query, order, startDate, endDate, userGbCd, sortColumn, sortValue, searchGb,
                            searchCd01,searchCd02,searchCd03,searchCd04,searchCd05,searchCd06,searchCd07,searchCd08,searchCd09,searchCd10,selectedNos,
                            selectedNos2,selectedNos3,selectedNos4,selectedNos5,selectedNos6,selectedNos7,selectedNos8,selectedNos9,
                            searchIdArr,searchIdSort,searchNicknameArr,searchNicknameSort,topSearchVal
) {

    return axios.get("/admin/member/getMemberUser", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
            userGbCd: userGbCd,
            sortColumn: sortColumn,
            sortValue: sortValue,
            searchGb: searchGb,
            searchCd01: searchCd01,
            searchCd02: searchCd02,
            searchCd03: searchCd03,
            searchCd04: searchCd04,
            searchCd05: searchCd05,
            searchCd06: searchCd06,
            searchCd07: searchCd07,
            searchCd08: searchCd08,
            searchCd09: searchCd09,
            searchCd10: searchCd10,
            selectedNos: selectedNos,
            selectedNos2: selectedNos2,
            selectedNos3: selectedNos3,
            selectedNos4: selectedNos4,
            selectedNos5: selectedNos5,
            selectedNos6: selectedNos6,
            selectedNos7: selectedNos7,
            selectedNos8: selectedNos8,
            selectedNos9: selectedNos9,
            searchIdArr: searchIdArr,
            searchIdSort: searchIdSort,
            searchNicknameArr: searchNicknameArr,
            searchNicknameSort: searchNicknameSort,
            topSearchVal: topSearchVal
        }
    })
}

export const fetchLotteryList = async (input) => {
    return axios.post("/admin/deposit/Lottery/getLotteryList", input)
}

export const fetchOrgList = async (input) => {
    return axios.post("/admin/partner/getOrgList", input)
}

export const fetchCompanySubList = async (input) => {
    return axios.post("/admin/partner/getCompanySubList", input)
}

export const fetchCompanySubSingle = async (input) => {
    return axios.post("/admin/partner/getCompanySubSingle", input)
}

export const fetchDistributorList = async (input) => {
    return axios.post("/admin/partner/getDistributorList", input)
}

export const fetchDistributorSingle = async (input) => {
    return axios.post("/admin/partner/getDistributorSingle", input)
}

export const fetchOrgAuthList = async (input) => {
    return axios.post("/admin/partner/getOrgAuthList", input)
}
export const fetchPartnerList = async (input) => {
    return axios.post("/admin/partner/getPartnerList", input)
}

export const fetchPartnerSingle = async (input) => {
    return axios.post("/admin/partner/getPartnerSingle", input)
}

export const fetchRecommendKeyList = async (input) => {
    return axios.post("/admin/partner/getRecommendKeyList", input)
}

export const fetchDangerAccountSettingList = async (input) => {
    return axios.post("/admin/setting/getDangerAccountSettingList", input)
}

export const fetchDomainSettingList = async (input) => {
    return axios.post("/admin/setting/getDomainSettingList", input)
}

export const fetchEtcSettingList = async (input) => {
    return axios.post("/admin/setting/getEtcSettingList", input)
}

export const fetchGroupSettingList = async (input) => {
    return axios.post("/admin/setting/getGroupSettingList", input)
}

export const fetchIpBlockList = async (input) => {
    return axios.post("/admin/setting/getIpBlockList", input)
}


export const fetchLevelSettingSingle = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getLevelSetting", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}


export const fetchSiteSettingSingle = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getSiteSettingSingle", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}


export const fetchBettingSingle = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getBettingSingle", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}


export const fetchTagSettingList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getTagSettingList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}


export const fetchRegBankList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getBankRegSettingList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}

export const fetchMemberState = async (input) => {
    return axios.post("/admin/user/Member_stat/getMemberDepWidStatList", input)
}

export const fetchMessage = async (input) => {
    return axios.post("/admin/user/Message/getMessage", input)
}

export const fetchMessagePop = async (input) => {
    return axios.post("/admin/user/MessagePop/getMessagePop", input)
}

export const fetchMessageTemplate = async (input) => {
    return axios.post("/admin/user/MessageTemplate/getMessageTemplate", input)
}

export function fetchMemberUpdate(page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) {

    return axios.post("/admin/memberUpdate/getMemberUpdate", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}

export const fetchDepositList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd,
                                       searchCd01,searchCd02,searchCd03,searchCd04,searchCd05,searchCd06,searchCd07,searchCd08,searchCd09,searchCd10,
                                       searchCd11,searchCd12,searchCd13,searchCd14,searchCd15,searchCd16,keyCd,valueCd,userId, selectedNos, selectedNos3, selectedNos4
) => {
    return axios.post("/admin/depositList/getDepositList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        searchCd01: searchCd01,
        searchCd02: searchCd02,
        searchCd03: searchCd03,
        searchCd04: searchCd04,
        searchCd05: searchCd05,
        searchCd06: searchCd06,
        searchCd07: searchCd07,
        searchCd08: searchCd08,
        searchCd09: searchCd09,
        searchCd10: searchCd10,
        searchCd11: searchCd11,
        searchCd12: searchCd12,
        searchCd13: searchCd13,
        searchCd14: searchCd14,
        searchCd15: searchCd15,
        searchCd16: searchCd16,
        keyCd: keyCd,
        valueCd: valueCd,
        userId: userId,
        selectedNos: selectedNos,
        selectedNos3: selectedNos3,
        selectedNos4: selectedNos4
    })
}

export const fetchWithdrawList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd,
                                        searchCd01,searchCd02,searchCd03,searchCd04,searchCd05,searchCd06,searchCd07,searchCd08,searchCd09,searchCd10,
                                        searchCd11,searchCd12,searchCd13,searchCd14,searchCd15,searchCd16,keyCd,valueCd,userId, selectedNos, selectedNos3, selectedNos4) => {
    return axios.post("/admin/withdrawList/getWithdrawList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        searchCd01: searchCd01,
        searchCd02: searchCd02,
        searchCd03: searchCd03,
        searchCd04: searchCd04,
        searchCd05: searchCd05,
        searchCd06: searchCd06,
        searchCd07: searchCd07,
        searchCd08: searchCd08,
        searchCd09: searchCd09,
        searchCd10: searchCd10,
        searchCd11: searchCd11,
        searchCd12: searchCd12,
        searchCd13: searchCd13,
        searchCd14: searchCd14,
        searchCd15: searchCd15,
        searchCd16: searchCd16,
        keyCd: keyCd,
        valueCd: valueCd,
        userId: userId,
        selectedNos: selectedNos,
        selectedNos3: selectedNos3,
        selectedNos4: selectedNos4
    })
}

export const fetchDepositWithdrawLog = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, searchCd01, searchCd02, searchCd03, searchCd04, searchCd05, searchCd06, userId) => {
    return axios.post("/admin/depositWithdrawLog/getDepositWithdrawLog", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        searchCd01: searchCd01,
        searchCd02: searchCd02,
        searchCd03: searchCd03,
        searchCd04: searchCd04,
        searchCd05: searchCd05,
        searchCd06: searchCd06,
        userId: userId
    })
}

export const fetchDepositWithdrawCombine = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, searchCd01) => {
    return axios.post("/admin/depositWithdrawCombine/getDepositWithdrawCombine", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        searchCd01: searchCd01,
    })
}

export const fetchMoneyList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, userId) => {
    return axios.post("/admin/moneyList/getMoneyList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        userId: userId
    })
}

export const fetchMoneyStat = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, searchCd01) => {
    return axios.post("/admin/moneyStat/getMoneyStat", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        searchCd01: searchCd01,
    })
}

export const fetchPointList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, selectedNos, userId) => {
    return axios.post("/admin/pointList/getPointList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        selectedNos: selectedNos,
        userId: userId
    })
}

export const fetchPointStat = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, searchCd01) => {
    return axios.post("/admin/pointStat/getPointStat", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        searchCd01: searchCd01,
    })
}

export const fetchCouponList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, searchCd01) => {
    return axios.post("/admin/couponList/getCouponList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        searchCd01: searchCd01,
    })
}

export function fetchCustomers(input) {

    return axios.post("/admin/board/getCustomers", input)
}
export function fetchCustomerDetail(csNo) {

    return axios.post("/admin/board/getCustomerDetail", {
        csNo: csNo
    })
}

export const fetchNotices = async (input) => {
    return axios.post("/admin/board/getNotices", input)
}

export function fetchChecks(month, query) {

    return axios.post("/admin/board/getChecks", {
        month: month,
        query: query
    })
}

export const fetchEvents = async (input) => {
    return axios.post("/admin/board/getEvents", input)
}

export const fetchETCs = async (input) => {
    return axios.post("/admin/board/getETCs", input)
}

export const fetchAdmins =  async (input) => {

    console.log("fetchAdmins >>>>>>>>>>>>>>>>> "+JSON.stringify(input));

    return axios.post("/admin/monitoring/getAdmins", input)
}
export function fetchActives(idQuery, ipQuery, onlyAdmin, includeChild, page, pageSize, orgId) {

    return axios.post("/admin/monitoring/getActiveLogs", {
        idQuery: idQuery,
        ipQuery: ipQuery,
        onlyAdmin: onlyAdmin,
        includeChild: includeChild,
        page: page,
        pageSize: pageSize,
        orgId: orgId,
    })
}
export function fetchActiveMembers(input) {
    return axios.post("/admin/monitoring/getActiveMembers", input)
}
export function fetchLoginFails(startDate, endDate, loginType, searchGb, searchQuery, page, pageSize) {
    return axios.post("/admin/monitoring/getLoginFails", {
        startDate: startDate,
        endDate: endDate,
        loginType: loginType,
        searchGb: searchGb,
        searchQuery: searchQuery,
        page: page,
        pageSize: pageSize
    })
}

export const fetchAccounts = async (input) => {
    return axios.post("/admin/monitoring/getAccounts", input)
}

export function fetchSameBrowsers(query,page, pageSize) {

    return axios.post("/admin/monitoring/getSameBrowsers", {
        query: query,
        page: page,
        pageSize: pageSize
    })
}
export function fetchOverlaps() {

    return axios.post("/admin/monitoring/getOverlaps", {

    })
}


//회원정보심플모달
export const fetchMemberInformationSimpleModal = async (input) => {
    return axios.post("/admin/member/simple/memberInfoSimpleModal/getMemberInfo", input)
}

export const fetchMoneyInfo = async (input) => {
    return axios.post("/admin/admin/getMoneyInfo", input)
}
export const fetchAlerts = async (input) => {
    return axios.post("/admin/admin/getAlerts", input)
}

export const fetchLeftAlerts = async (input) => {
    return axios.post("/admin/admin/getLeftAlerts", input)
}

export const insertAdminLog = async (input) => {
    return axios.post("/admin/admin/insertLog", input)
}
export const fetchGameKindList = async (input) => {
    return axios.post("/admin/game/setting/getGameKindList", input)
}
export const fetchNationList = async (input) => {
    return axios.post("/admin/game/setting/getNationList", input)
}
export const fetchLeagueList = async (input) => {
    return axios.post("/admin/game/setting/getLeagueList", input)
}
export const fetchTeamList = async (input) => {
    return axios.post("/admin/game/setting/getTeamList", input)
}

export const fetchWaitFixture = async (input) => {
    return axios.post("/admin/game/manage/getWaitFixture", input)
}
export const fetchFixtureDetail = async (fixtureId, status) => {
    return axios.post("/admin/game/manage/getFixtureDetail", {
        fixtureId: fixtureId,
        status: status,
    })
}
export function fetchActiveFixture(input) {
    return axios.post("/admin/game/manage/getActiveFixture",input)
}
export function fetchCloseFixture(input) {
    return axios.post("/admin/game/manage/getCloseFixture",input)
}
export function fetchStopFixture(input) {
    return axios.post("/admin/game/manage/getStopFixture",input)
}
export function fetchStopMatchBy(input) {
    return axios.post("/admin/game/manage/getStopMatchBy", input)
}

export function fetchResultFixture(input) {
    return axios.post("/admin/game/manage/getResultFixture",input)
}
export function fetchCancelFixture(input) {
    return axios.post("/admin/game/manage/getCancelFixture",input)
}
export function fetchCancelMatchBy(input) {
    return axios.post("/admin/game/manage/getCancelMatchBy",input)
}
export function fetchFixture(input) {
    return axios.post("/admin/game/manage/getFixture",input)
}

export function fetchMiniGames(input) {
    return axios.post("/admin/game/getMiniGames",input)
}

export function fetchInplayFixture(input) {
    return axios.post("/admin/game/manage/getInplayFixture",input)
}

export const fetchInplayFixtureDetail = async (fixtureId) => {
    return axios.post("/admin/game/manage/getInplayFixtureDetail", {
        fixtureId: fixtureId,
    })
}
export function fetchInplayResult(input) {
    return axios.post("/admin/game/manage/getInplayResult",input)
}

export const fetchInplayResultDetail = async (fixtureId) => {
    return axios.post("/admin/game/manage/getInplayResultDetail", {
        fixtureId: fixtureId,
    })
}

export const fetchWithdrawInfo = async (page, pageSize, query, order, startDate, endDate, userId) => {
    return axios.post("/admin/partner/getWithdrawInfo", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userId: userId
    })
}


export const fetchLosingInfo = async (input) => {
    return axios.post("/admin/partner/getLosingInfo",input)
}

export const fetchRollingInfo = async (input) => {
    return axios.post("/admin/partner/getRollingInfo", input)
}

export const fetchMemberInfo = async (input) => {
    return axios.post("/admin/partner/getMemberInfo",input)
}

export const fetchAdjustment = async (page, pageSize, query, order, startDate, endDate, userId) => {
    return axios.post("/admin/partner/getAdjustment", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userId: userId
    })
}

export const fetchCodeSettingList = async (input) => {
    return axios.post("/admin/setting/getCodeSettingList", input)
}


export const fetchLvlSettingList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getLvlSettingList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}

export const fetchLvlSettingSingleList = async (input) => {
    return axios.post("/admin/setting/getLvlSettingSingle", input)
}


export const fetchDwpSettingList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getDwpSettingList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}

export const fetchAcctSettingList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getAcctSettingList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}

export const fetchRecommenderSettingList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getRecommenderSettingList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}

export const fetchAttendanceSettingList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getAttendanceSettingList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}

export const fetchRouletteSettingList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getRouletteSettingList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}

export const fetchRegSettingList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd) => {
    return axios.post("/admin/setting/getRegSettingList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd
    })
}