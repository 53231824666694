import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import {getPartnerListReducer,setComboGb,setQuery} from "../../redux/reducers/partner/PartnerListReducer";
import {useLocation, useNavigate} from "react-router-dom";
import Pagination from "../../components/common/Pagination2";
import {getDistributorListReducer} from "../../redux/reducers/partner/DistributorListReducer";
import * as XLSX from "xlsx";
import {insertAdminLog} from "../../lib/api";

const Partner_list = () => {
    const userInfoId = useSelector((state) => state.userInfo.id)
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.PartnerListReducer)
    const navigate = useNavigate();

    const location = useLocation();
    const { state } = location;

    const getList = () => {
        dispatch(setComboGb(state && state.comboGb))
        dispatch(setQuery(state && state.query))

        if(initialState.totalList !== null ) {
            dispatch(getPartnerListReducer({
                input:{
                    page: 1,
                    pageSize: initialState.input.pageSize,
                    query : initialState.input.query,
                    comboGb : initialState.input.comboGb,
                }
            }))
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "가맹점목록"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getPartnerListReducer({
            input:{
                page: page,
                pageSize: initialState.input.pageSize,
                query : initialState.input.query,
                comboGb : initialState.input.comboGb,
            }
        }))
    }

    const onSubmitHandler = (e) => {
        navigate('/partner/Partner_add');
    }

    const updatePageMove = (id) => {
        navigate('/partner/Partner_add',{state:{gb:'수정',id:id}});
    }

    const inputQuery = (e) => {
        dispatch(setQuery(e.target.value))
    }

    const queryKeyDown = (e) => {
        if (e.key === 'Enter') {
            dispatch(getPartnerListReducer({
                input:{
                    page: 1,
                    pageSize: initialState.input.pageSize,
                    query : initialState.input.query,
                    comboGb : initialState.input.comboGb,
                }
            }))
        }
    }

    const handleBlur = (e) =>{
        dispatch(getPartnerListReducer({
            input:{
                page: 1,
                pageSize: initialState.input.pageSize,
                query : initialState.input.query,
                comboGb : initialState.input.comboGb,
            }
        }))
    }

    const selectComboGb = (e) => {
        dispatch(setComboGb(e.target.value))
        dispatch(setQuery(''))
        dispatch(getPartnerListReducer({
            input:{
                page: 1,
                pageSize: initialState.input.pageSize,
                query : '',
                comboGb : initialState.input.comboGb,
            }
        }))
    }

    const exportExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {
            let jsonObject = { // JSON 객체를 생성합니다.
                소속총판명: initialState.totalList[i].upOrgNm? initialState.totalList[i].upOrgNm :'',
                가맹점명 : initialState.totalList[i].orgNm? initialState.totalList[i].orgNm :'',
                가맹점아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                관리자: initialState.totalList[i].nm? initialState.totalList[i].nm :'',
                권한: initialState.totalList[i].orgGbCdNm? initialState.totalList[i].orgGbCdNm :'',
                츙전액_7일간: initialState.totalList[i].depositAmt7? initialState.totalList[i].depositAmt7 :'',
                환전액_7일간: initialState.totalList[i].withdrawAmt7? initialState.totalList[i].withdrawAmt7 :'',
                마진_7일간: initialState.totalList[i].marginAmt7? initialState.totalList[i].marginAmt7 :'',
                유저보유액: initialState.totalList[i].userHoldingAmount? initialState.totalList[i].userHoldingAmount :'',
                유저보유포인트 : initialState.totalList[i].userHoldingPoint? initialState.totalList[i].userHoldingPoint :'',
                구분_전체 : initialState.totalList[i].userGbAll? initialState.totalList[i].userGbAll :'',
                구분_대기 : initialState.totalList[i].userGbHold? initialState.totalList[i].userGbHold :'',
                구븐_무료 : initialState.totalList[i].userGbFree? initialState.totalList[i].userGbFree :'',
                구분_유료 : initialState.totalList[i].userGbPaid? initialState.totalList[i].userGbPaid :'',
                구분_vip : initialState.totalList[i].userGbVip? initialState.totalList[i].userGbVip :'',
                구분_가라 : initialState.totalList[i].userGbFake? initialState.totalList[i].userGbFake :'',
                상태_삭제 : initialState.totalList[i].userGbDel? initialState.totalList[i].userGbDel :'',
                생성일시 : initialState.totalList[i].createdAt? initialState.totalList[i].createdAt :'',
                최근접속일시 : initialState.totalList[i].rcntActvDttm? initialState.totalList[i].rcntActvDttm :'',
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '가맹점목록');
        XLSX.writeFile(workBook, '가맹점목록.xlsx');
    }


    return (
        <div className="common" >
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">가맹점목록</font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table   width={"50%"}>
                        <tr>
                            <td align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <button onClick={exportExcel} style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>엑셀 다운로드</button>
                                    <select onChange={selectComboGb} value={initialState.input.comboGb} className="form-select sm-select" style={{width: "100px"}}>
                                        <option value= ''>전체</option>
                                        <option value= '총판명'>총판명</option>
                                        <option value= '가맹점명'>가맹점명</option>
                                    </select>
                                    &nbsp;
                                    <input onChange={inputQuery} onKeyDown={queryKeyDown} onBlur={handleBlur} value={initialState.input.query}  className="form-check-input sm-input" type="text" style={{ width: "200px" }}/>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table   width={"49%"}>
                        <tr>
                            <td align={"right"}>
                                <button onClick={onSubmitHandler} style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}}>가맹점 등록</button>
                            </td>
                        </tr>
                    </table>

                </div>

            </div>


            <table className="table table-bordered table-sm text-center align-middle">
                <thead>
                <tr>

                </tr>
                </thead>
            </table>
            <div className="commonT container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="commonT table table-bordered table-sm text-center align-middle">
                    <thead>
                    <tr>
                        <th style={{width: "5%"}} rowSpan="2" >소속총판명</th>
                        <th style={{width: "5%"}} rowSpan="2" >가맹점명</th>
                        <th style={{width: "5%"}} rowSpan="2" >아이디</th>
                        <th style={{width: "3%"}} rowSpan="2">도메인</th>
                        <th style={{width: "7%"}} rowSpan="2" >충전액<br></br>(10/27(금)~11/02(목)</th>
                        <th style={{width: "7%"}} rowSpan="2" >환전액<br></br>(10/27(금)~11/02(목)</th>
                        <th style={{width: "7%"}} rowSpan="2" >마진<br></br>(10/27(금)~11/02(목)</th>
                        <th style={{width: "5%"}} rowSpan="2" >유저보유금</th>
                        <th style={{width: "5%"}} rowSpan="2" >유저포인트</th>
                        <th style={{width: "*%"}} colSpan="7">회원</th>
                        <th style={{width: "8%"}} rowSpan="2" >생성</th>
                        <th style={{width: "8%"}} rowSpan="2" >최종로그인</th>
                    </tr>
                    <tr>
                        <th>전체</th>
                        <th>대기</th>
                        <th>무료</th>
                        <th>유료</th>
                        <th>VIP</th>
                        <th>가라</th>
                        <th>삭제</th>
                    </tr>
                    </thead>
                    {initialState.totalList && initialState.totalList.length > 0 ? initialState.gridList.map((List, index) => (
                            <tbody key={List.id}>
                            <tr>
                                <td align={"center"}>{List.upOrgNm}</td>
                                <td align={"center"} style={{cursor: 'pointer'}} onClick={() => updatePageMove(List.id)}><font color="blue">{List.orgNm}</font></td>
                                <td align={"center"} style={{cursor: 'pointer'}} onClick={() => updatePageMove(List.id)}><font color="blue">{List.id}</font></td>
                                <td align={"center"}>-</td>
                                <td align={"center"}>{List.depositAmt7}</td>
                                <td align={"center"}>{List.withdrawAmt7}</td>
                                <td align={"center"}><font color="blue">{List.marginAmt7}</font></td>
                                <td align={"center"}>{List.userHoldingAmount}</td>
                                <td align={"center"}>{List.userHoldingPoint}</td>
                                <td align={"center"}>{List.userGbAll}</td>
                                <td align={"center"}>{List.userGbHold}</td>
                                <td align={"center"}>{List.userGbFree}7</td>
                                <td align={"center"}><font color="red">{List.userGbPaid}</font></td>
                                <td align={"center"}>{List.userGbVip}</td>
                                <td align={"center"}>{List.userGbFake}</td>
                                <td align={"center"}>{List.userGbDel}</td>
                                <td align={"center"}>{List.createdAt}</td>
                                <td align={"center"}>{List.rcntActvDttm}</td>
                            </tr>
                            </tbody>
                        )) :
                        <tbody>
                        <tr>
                            <td colSpan= "18">조회된 데이터가 없습니다.</td>
                        </tr>
                        </tbody>
                    }
                </table>
            </div>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalCount && initialState.totalCount > 0 ?
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.input.pageSize} currentPage={initialState.input.page} onPageChange={handlePageChange} />
                    :""}
            </div>
        </div>

    )
}
export default Partner_list;