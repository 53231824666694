import {createSlice} from "@reduxjs/toolkit"

export const activeSlice = createSlice({
    name: 'active',
    initialState: {
        isLoading: false,
        error: null,
        actives: [],
        idQuery: "",
        ipQuery: "",
        onlyAdmin: false,
        includeChild: false,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        orgId: "",
    },
    reducers: {
        getActives(state, action) {
            console.log("getActives: " + action.payload.page )
            state.isLoading = true;
            state.idQuery = action.payload.idQuery;
            state.ipQuery = action.payload.ipQuery;
            state.onlyAdmin = action.payload.onlyAdmin;
            state.includeChild = action.payload.includeChild;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.orgId = action.payload.orgId;
        },
        failedGetActives(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setActives(state, action) {
            state.isLoading = false;
            state.actives = action.payload.result;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            console.log(state.page)
            console.log(action.payload.totalCount)
            state.totalCount = action.payload.totalCount;
        },
    }
});

export const { getActives, failedGetActives, setActives } = activeSlice.actions;

export default activeSlice.reducer;