import {call, put, take,} from "redux-saga/effects";
import {fetchFixture} from "../../../../lib/api";
import {
    failedGetFixture,
    setFixture,
} from "../../../reducers/game/manage/fixture";

export function* getFixtureSaga(action) {
    try {
        const res = yield call(
            fetchFixture,
            action.payload
        );
        yield put(setFixture(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetFixture(error));
    }
}


