import React from 'react';
import RecommenderSettingContainer from "../../containers/setting/RecommenderSettingContainer";


const RecommenderSetting = () => {
    return (
        <div>
            <RecommenderSettingContainer />
        </div>
    );
};

export default RecommenderSetting;