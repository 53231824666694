import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
const DepositWithdrawCombine = ({depositWithdrawCombine, onRefresh}) => {
    const dispatch = useDispatch()

    const stateNm = (state, no) => {
        switch(state) {

            //보류
            case 1 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    [보류]
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //완료
            case 2 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    [완료]
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //취소완료
            case 3 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    [취소]
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //완료->취소
            case 4 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    [취소]
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //완료->확인중
            case 5 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    [완료->확인중]
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            default : return state
        }
    }

    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead >
                        <tr>
                            <th style={{width: "2%"}}>순서</th>
                            <th style={{width: "5%"}}>구분</th>
                            <th style={{width: "10%"}}>소속</th>
                            <th style={{width: "10%"}}>아이디</th>
                            <th style={{width: "10%"}}>닉네임</th>
                            <th style={{width: "5%"}}>P레벨</th>
                            <th style={{width: "5%"}}>충전구분</th>
                            <th style={{width: "7%"}}>금액</th>
                            <th style={{width: "7%"}}>지급포인트</th>
                            <th style={{width: "5%"}}>상태</th>
                            <th style={{width: "10%"}}>일시</th>
                            <th style={{width: "10%"}}>아이피</th>
                        </tr>
                        </thead>
                        {!depositWithdrawCombine.length && (
                            <tbody key={depositWithdrawCombine.no}>
                            <tr>
                                <td colSpan="12">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {depositWithdrawCombine?.map((depositWithdrawCombine, index) => (
                            <tbody key={depositWithdrawCombine.no}>
                            <tr>
                                <td align={"center"} style={{backgroundColor: ( depositWithdrawCombine.deposit_withdraw_state_cd === '충전' ? "lightskyblue" : "lightpink")}} >{depositWithdrawCombine.no}</td>
                                <td align={"center"} style={{backgroundColor: ( depositWithdrawCombine.deposit_withdraw_state_cd === '충전' ? "lightskyblue" : "lightpink")}}>{depositWithdrawCombine.deposit_withdraw_state_cd}</td>
                                <td align={"center"} style={{backgroundColor: ( depositWithdrawCombine.deposit_withdraw_state_cd === '충전' ? "lightskyblue" : "lightpink")}}>{depositWithdrawCombine.store_cd}</td>

                                <td align={"center"} style={{backgroundColor:depositWithdrawCombine.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: depositWithdrawCombine.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={depositWithdrawCombine.font_color}>{depositWithdrawCombine.id}</font>
                                </td>
                                <td align={"center"} style={{backgroundColor:depositWithdrawCombine.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: depositWithdrawCombine.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={depositWithdrawCombine.font_color}>{depositWithdrawCombine.nickname}</font>
                                </td>
                                <td align={"center"} style={{backgroundColor: ( depositWithdrawCombine.deposit_withdraw_state_cd === '충전' ? "lightskyblue" : "lightpink")}}>{depositWithdrawCombine.pnt_gd_cd}</td>
                                <td align={"center"} style={{backgroundColor: ( depositWithdrawCombine.deposit_withdraw_state_cd === '충전' ? "lightskyblue" : "lightpink")}}>{depositWithdrawCombine.deposit_state_cd}</td>
                                <td align={"center"} style={{backgroundColor: ( depositWithdrawCombine.deposit_withdraw_state_cd === '충전' ? "lightskyblue" : "lightpink")}}>
                                    <font color="blue">
                                        <NumericFormat value={depositWithdrawCombine.deposit_withdraw_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"} style={{backgroundColor: ( depositWithdrawCombine.deposit_withdraw_state_cd === '충전' ? "lightskyblue" : "lightpink")}}>
                                    <font color="blue">
                                        <NumericFormat value={depositWithdrawCombine.result_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"} style={{backgroundColor: ( depositWithdrawCombine.deposit_withdraw_state_cd === '충전' ? "lightskyblue" : "lightpink")}}>
                                    {stateNm(depositWithdrawCombine.result_state_cd)}
                                </td>
                                <td align={"center"} style={{backgroundColor: ( depositWithdrawCombine.deposit_withdraw_state_cd === '충전' ? "lightskyblue" : "lightpink")}}>{depositWithdrawCombine.createdAt}</td>
                                <td align={"center"} style={{backgroundColor: ( depositWithdrawCombine.deposit_withdraw_state_cd === '충전' ? "lightskyblue" : "lightpink")}}>
                                    <a href={"https://www.ipaddress.com/ipv4/" + depositWithdrawCombine.reg_ip} target="_blank"><span style={{ color: "#1F68EE" }}>{depositWithdrawCombine.reg_ip}</span></a>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default DepositWithdrawCombine;