import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    comboOrgList : [],
    comboOrgList2 : [],
    cmmnCdList : [],
    cmmnCdList0036 : [],
    userColorList : [],
    pwcheck : "",//비밀번호확인
    idDupYn : true, //아이디중복여부
    idCheckYn : false,//아이디체크여부

    orgNmDupYn : true, //총판명중복여부
    orgNmCheckYn : false,//총판명체크여부

    Dtl : {},//상세

    moveOrgId : "",//회원이동할조직id

    upChangeOrgId : "",//조직전환시 이동할조직의상위조직id

    bfOrgGbCd:"",//원본조직구분코드

    input : {
        gb : "",//등록수정구분
        id : "",//아이디
        org_level_cd : "",
        up_org_id: "", //상위기관td
        nm : "",//이름
        pw : "",//비밀번호
        org_nm : "",//조직명
        org_gb_cd : "00",//조직구분코드
        mbno_start : "",//전화번호앞자리
        mbno_center : "",//전화번호가운데자리
        mbno_end : "",//전화번호끝자리
        mbph_start : "",//이동전화번호앞자리
        mbph_center : "",//이동전화번호중간자리
        mbph_end : "",//이동전화번호끝자리
        email : "",//이메일
        memo : "",//메모
        user_color_no : 1,//회원색상번호
        org_adj_gb_cd : "01",//조직정산구분코드
        reg_id : "",//등록자id
        upd_id : "",//수정자id
    }
}

export const statsSlice = createSlice({
    name: 'PartnerAddReducer',
    initialState,
    reducers: {
        setDtl(state, action){
            state.input = action.payload;
        },
        setBfOrgGbCd(state, action){
            state.bfOrgGbCd = action.payload
        },
        setMoveOrgId(state,action){
            state.moveOrgId = action.payload;
        },
        setUpChangeOrgId(state,action){
            state.upChangeOrgId = action.payload;
        },
        setComboOrgList(state, action){
            state.comboOrgList = action.payload;
        },
        setComboOrgList2(state, action){
            state.comboOrgList2 = action.payload;
        },
        setCmmnCd(state, action){
            state.cmmnCdList = action.payload;
        },
        setCmmnCd0036(state, action){
            state.cmmnCdList0036 = action.payload;
        },
        setUserColorList(state, action){
            state.userColorList = action.payload;
        },
        setIdDupYn(state, action){
            state.idDupYn = action.payload;
        },
        setIdCheckYn(state, action){
            state.idCheckYn = action.payload;
        },
        setOrgNmDupYn(state, action){
            state.orgNmDupYn = action.payload;
        },
        setOrgNmCheckYn(state, action){
            state.orgNmCheckYn = action.payload;
        },
        setGb(state, action){
            state.input.gb = action.payload;
        },
        setId(state, action) {
            state.input.id = action.payload;
        },
        setUpOrgId(state, action){
            state.input.up_org_id = action.payload;
        },
        setOrgLevelCd(state, action) {
            state.input.org_level_cd = action.payload;
        },
        setNm(state, action) {
            state.input.nm = action.payload;
        },
        setPw1(state, action) {
            state.input.pw = action.payload;
        },
        setPw2(state, action) {
            state.pwcheck = action.payload;
        },
        setOrgNm(state, action) {
            state.input.org_nm = action.payload;
        },
        setOrgGbCd(state, action) {
            state.input.org_gb_cd = action.payload;
        },
        setMbnoStart(state, action){
            state.input.mbno_start = action.payload;
        },
        setMbnoCenter(state, action){
            state.input.mbno_center = action.payload;
        },
        setMbnoEnd(state, action){
            state.input.mbno_end = action.payload;
        },
        setMbphStart(state, action){
            state.input.mbph_start = action.payload;
        },
        setMbphCenter(state, action){
            state.input.mbph_center = action.payload;
        },
        setMbphEnd(state, action){
            state.input.mbph_end = action.payload;
        },
        setEmail(state, action){
            state.input.email = action.payload;
        },
        setMemo(state, action){
            state.input.memo = action.payload;
        },
        setUserColorNo(state, action){
            state.input.user_color_no = action.payload;
        },
        setOrgAdjGbCd(state, action){
            state.input.org_adj_gb_cd = action.payload;
        },
        setRegId(state, action){
            state.input.reg_id = action.payload;
        },
        setUpdId(state, action){
            state.input.upd_id = action.payload;
        },
        resetState: () => initialState,
    }
});

export const {
    resetState,
    setDtl,
    setMoveOrgId,
    setUpChangeOrgId,
    setComboOrgList,
    setComboOrgList2,
    setCmmnCd,
    setCmmnCd0036,
    setOrgAdjGbCd,
    setUserColorList,
    setIdDupYn,
    setIdCheckYn,
    setOrgNmDupYn,
    setOrgNmCheckYn,
    setGb,
    setId,
    setUpOrgId,
    setOrgLevelCd,
    setNm,
    setPw1,
    setPw2,
    setOrgNm,
    setOrgGbCd,
    setBfOrgGbCd,
    setMbnoStart,
    setMbnoCenter,
    setMbnoEnd,
    setMbphStart,
    setMbphCenter,
    setMbphEnd,
    setEmail,
    setMemo,
    setUserColorNo,
    setRegId,
    setUpdId,
} = statsSlice.actions;

export default statsSlice.reducer;