import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination2";
import {getDepositWithdrawLog} from "../../redux/reducers/deposit/DepositWithdrawLogReducer";
import DepositWithdrawLog from "../../components/deposit/DepositWithdrawLog";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import OrgSelect from "../../components/org/OrgSelect";
import {setSelectOrgId} from "../../redux/reducers/user/MessageReducer";
import {insertAdminLog} from "../../lib/api";
import {getPointList} from "../../redux/reducers/deposit/PointListReducer";
import PointGrade from "../../components/common/PointGrade";
import {getActiveMembers} from "../../redux/reducers/monitoring/ActiveMemberReducer";

const DepositWithdrawLogContainer = ({userId}) => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.depositWithdrawLog)
    const userState = useSelector((state) => state.userInfoCombine)
    const [searchQuery, setSearchQuery] = useState(0);
    const [query, setQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [userGbCd, setUserGbCd] = useState("");
    const [searchCd01, setSearchCd01] = useState(); //구분
    const [searchCd02, setSearchCd02] = useState(); //수행구분
    const [searchCd03, setSearchCd03] = useState(0); //상태
    const [searchCd04, setSearchCd04] = useState(); //회원구분
    const [searchCd05, setSearchCd05] = useState(); //전체P레벨
    const [searchCd06, setSearchCd06] = useState(); //조직선택

    const [date1, setDate1] = useState('#3fadf7')
    const [date0, setDate0] = useState('#3fadf7')

    const getCmmnCd = () => {
        if(userId && userId !== "") {
            setQuery(userId)
        }

        if(changeFieldList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0028',
            }).then((res) => {
                setChangeFieldList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "충/환전 처리로그"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getDepositWithdrawLog({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }

    const changeQuery = (value) => {
        setQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setQuery(query)
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }

    const changeSelectBoxQuery = (type) => {
        setSearchQuery(type)
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: type,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }


    const search01ChangeHandler = (type) => {
        setSearchCd01(type)
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: type,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }
    const search02ChangeHandler = (type) => {
        setSearchCd02(type)
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: type,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }
    const search03ChangeHandler = (type) => {
        setSearchCd03(type)
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: type,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }
    const search04ChangeHandler = (type) => {
        setSearchCd04(type)
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: type,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }
    const search05ChangeHandler = (type) => {
        setSearchCd05(type)
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: type,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }


    const exportExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {


            let jsonObject = { // JSON 객체를 생성합니다.
                log_no: initialState.totalList[i].no? initialState.totalList[i].no :'',
                사이트코드: initialState.totalList[i].site_cd? initialState.totalList[i].site_cd :'',
                소속: initialState.totalList[i].store_cd? initialState.totalList[i].store_cd :'',
                가맹점명: initialState.totalList[i].mrch_cd? initialState.totalList[i].mrch_cd :'',
                도메인: initialState.totalList[i].domain? initialState.totalList[i].domain :'',
                아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                등급: initialState.totalList[i].user_gb_cd? initialState.totalList[i].user_gb_cd :'',
                포인트등급: initialState.totalList[i].pnt_gd_cd? initialState.totalList[i].pnt_gd_cd :'',
                구분: initialState.totalList[i].deposit_withdraw_state_cd?
                    (initialState.totalList[i].deposit_withdraw_state_cd === '01' ? '충전' : '환전') :'',
                대상번호: initialState.totalList[i].target_no? initialState.totalList[i].target_no :'',
                이전상태: initialState.totalList[i].before_state_cd? initialState.totalList[i].before_state_cd :'',
                처리상태: initialState.totalList[i].result_state_cd? initialState.totalList[i].result_state_cd :'',
                금액: initialState.totalList[i].deposit_withdraw_amt? initialState.totalList[i].deposit_withdraw_amt :'',
                처리일시: initialState.totalList[i].result_dt? initialState.totalList[i].result_dt :'',
                처리간격: initialState.totalList[i].result_interval? initialState.totalList[i].result_interval+'초' :'',
                수행아이디: initialState.totalList[i].reg_id? initialState.totalList[i].reg_id :'',
                수행아이피: initialState.totalList[i].reg_ip? initialState.totalList[i].reg_ip :'',

            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '충환전 처리로그');
        XLSX.writeFile(workBook, '충환전 처리로그.xlsx');

    }

    const userGbCdChangeHandler = (e) => {
        alert('userGbCd000 >>>>>>>>>>>> '+e.currentTarget.value);
        setUserGbCd(e.currentTarget.value);

        alert('userGbCd >>>>>>>>>>>> '+userGbCd);
        onRefresh();
    }

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
        setDate0('#3fadf7')
        setDate1('#3fadf7')

        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: e.currentTarget.value,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))

    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
        setDate0('#3fadf7')
        setDate1('#3fadf7')

        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: e.currentTarget.value,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }

    const add7Date = () => {
        setStartDate(moment().add(-7, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add1Date = () => {
        setStartDate(moment().add(-1, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate1('red')
        setDate0('#3fadf7')
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: moment().add(-1, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }
    const add0Date = () => {
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate0('red')
        setDate1('#3fadf7')
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }

    const setParentParam = (id) =>{
        setSearchCd06(id)
        dispatch(setSelectOrgId(id))
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: id,
            userId: userId?userId:userState.userId
        }))
    }

    const onChangePG = (selectedPGNos) => {
        dispatch(getDepositWithdrawLog({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: selectedPGNos,
            searchCd06: initialState.searchCd06,
            userId: userId?userId:userState.userId
        }))
    }

    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <div className="commonNew">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">충/환전 처리로그</font>
                    <table   width={"92%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                                &nbsp;
                                <button style={{
                                    width: "120px", backgroundColor :"gray"
                                }} type="submit" className="btn btn-primary" onClick={onClickReset}>새로고침(자동보류)</button>
                            </td>
                        </tr>
                    </table>

                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray" , width:"420px"
                    }}>
                        <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}} onClick={exportExcel}>엑셀 다운로드</button>
                        <button style={{ backgroundColor: date1, border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add1Date}>어제</button>
                        <button style={{ backgroundColor: date0, border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}} onClick={add0Date}>오늘</button>
                        &nbsp;&nbsp;
                        <input className="form-control sm-date" type="date" value={startDate} onChange={startDateHandler} style={{width:"110px"}}/>
                        &nbsp;~&nbsp;
                        <input className="form-control sm-date" type="date" value={endDate} onChange={endDateHandler} style={{width:"110px"}}/>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"160px", height:"25px"
                    }}>
                        구분 :&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio1" id="inlineRadio1" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler("")} defaultChecked={true}/> 전체&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio1" id="inlineRadio1" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler("01")} /> 충전&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio1" id="inlineRadio1" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler("02")} /> 환전
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"160px"
                    }}>
                        수행구분 :&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio2" id="inlineRadio2" value={searchCd02} style={{ cursor: "pointer" }}
                               onChange={() => search02ChangeHandler("")} defaultChecked={true}/> 전체&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio2" id="inlineRadio2" value={searchCd02} style={{ cursor: "pointer" }}
                               onChange={() => search02ChangeHandler("01")} /> 자동&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio2" id="inlineRadio2" value={searchCd02} style={{ cursor: "pointer" }}
                               onChange={() => search02ChangeHandler("02")} /> 수동
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"370px"
                    }}>
                        <ArrowsExpand />상태 :&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio3" id="inlineRadio3" value={searchCd03} style={{ cursor: "pointer" }}
                               onChange={() => search03ChangeHandler(0)} defaultChecked={true}/> 전체&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio3" id="inlineRadio3" value={searchCd03} style={{ cursor: "pointer" }}
                               onChange={() => search03ChangeHandler(99)} /> 대기&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio3" id="inlineRadio3" value={searchCd03} style={{ cursor: "pointer" }}
                               onChange={() => search03ChangeHandler(2)} /> 완료&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio3" id="inlineRadio3" value={searchCd03} style={{ cursor: "pointer" }}
                               onChange={() => search03ChangeHandler(1)} /> 확인중&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio3" id="inlineRadio3" value={searchCd03} style={{ cursor: "pointer" }}
                               onChange={() => search03ChangeHandler(3)} /> 취소&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio3" id="inlineRadio3" value={searchCd03} style={{ cursor: "pointer" }}
                               onChange={() => search03ChangeHandler(4)} /> 완료->취소
                        <input className="form-check-input"  type="radio" name="inlineRadio3" id="inlineRadio3" value={searchCd03} style={{ cursor: "pointer" }}
                               onChange={() => search03ChangeHandler(5)} /> 완료->확인중
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"220px"
                    }}>
                        [ 회원구분 :&nbsp;
                        <input className="form-check-input"  type="radio" name="inlineRadio4" id="inlineRadio4" value={searchCd04} style={{ cursor: "pointer" }}
                               onChange={() => search04ChangeHandler("")}defaultChecked={true} /> 전체
                        <input className="form-check-input"  type="radio" name="inlineRadio4" id="inlineRadio4" value={searchCd04} style={{ cursor: "pointer" }}
                               onChange={() => search04ChangeHandler("01")} /> 일반회원
                        <input className="form-check-input"  type="radio" name="inlineRadio4" id="inlineRadio4" value={searchCd04} style={{ cursor: "pointer" }}
                               onChange={() => search04ChangeHandler("05")} /> 가라회원
                    </div>
                </div>

            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"800px", height:"25px"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"320px", height:"23px"
                    }}>
                        <div style={{zIndex : 999}}>
                            <OrgSelect setChildParam = {setParentParam} id = {1}/>
                        </div>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"450px", height:"23px"
                    }}>
                        <input className="form-check-input" type="checkbox" name="inlineRadio6" id="inlineRadio6" /> 하위 포함 검색
                        &nbsp;
                        <select
                            className="form-select sm-select"
                            defaultValue={999}
                            style={{
                                width: "150px"
                            }}
                            value={searchQuery ? searchQuery : initialState.searchGbCd === ""? "3" : initialState.searchGbCd}
                            onChange={(e) => {
                                changeSelectBoxQuery(e.currentTarget.value)
                            }}
                        >
                            <option value={1}>처리간격(~초 이상)</option>
                            <option value={2}>닉네임</option>
                            <option value={3}>아이디</option>
                            <option value={4}>이름</option>
                            <option value={5}>ip</option>
                            <option value={6}>가맹점</option>
                        </select>
                        &nbsp;
                        <input className="sm-input" type="text" value={query} onChange={(e) => {
                            changeQuery(e.currentTarget.value)
                        }} onKeyDown={inputPress}/>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"700px"
                }}>
                    <PointGrade onChangePG={onChangePG}/>
                </div>
            </div>
        </div>
        <br></br>
        <DepositWithdrawLog depositWithdrawLog={initialState.depositWithdrawLog} onRefresh={onRefresh} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default DepositWithdrawLogContainer