import {call, put, take,} from "redux-saga/effects";
import {fetchMoneyStat} from "../../../lib/api";
import {failedGetMoneyStat, setMoneyStat} from "../../reducers/deposit/MoneyStatReducer";

export function* getMoneyStatSaga(action) {
    try{
        const res = yield call(
            fetchMoneyStat,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd, action.payload.searchCd01
        )
        yield put(setMoneyStat(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMoneyStat(error))
    }
}