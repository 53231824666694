import React from 'react';
import MemberUpdateContainer from "../../containers/user/MemberUpdateContainer";


const MemberUpdate = () => {
    return (
        <div>
            <h5>회원정보 수정내역</h5>
            <MemberUpdateContainer />
        </div>
    );
};

export default MemberUpdate;