import {all, fork, takeLatest, takeEvery} from 'redux-saga/effects';
import {getBlackMemberSaga} from "./user/BlackMemberSaga";
import {getBlackMemberReducer} from "../reducers/user/BlackMemberReducer";
import {getMemberSaga} from "./user/member";
import {getMemberUser} from "../reducers/user/MemberReducer";
import {getLotterySaga} from "./deposit/LotterySaga";
import {getLotteryReducer} from "../reducers/deposit/LotteryReducer";
import {getMemberStateSaga} from "./user/MemberStateSaga";
import {getMemberStateReducer} from "../reducers/user/MemberStateReducer";
import {getMemberUpdateSaga} from "./user/memberUpdateSaga";
import {getMemberUpdate} from "../reducers/user/MemberUpdateReducer";

import {getMessageSaga} from "./user/MessageSaga";
import {getMessageReducer} from "../reducers/user/MessageReducer";

import {getMessagePopSaga} from "./user/MessagePopSaga";
import {getMessagePopReducer} from "../reducers/user/MessagePopReducer";

import {getMessageTemplateSaga} from "./user/MessageTemplateSaga";
import {getMessageTemplateReducer} from "../reducers/user/MessageTemplateReducer";

import {getOrgListSaga} from "./partner/OrgListSaga";
import {getOrgListReducer} from "../reducers/partner/OrgListReducer";

import {getCompanySubListSaga} from "./partner/CompanySubListSaga";
import {getCompanySubListReducer} from "../reducers/partner/CompanySubListReducer";

import {getCompanySubSingleSaga} from "./partner/CompanySubSingleSaga";
import {getCompanySubSingleReducer} from "../reducers/partner/CompanySubSingleReducer";

import {getDistributorListSaga} from "./partner/DistributorListSaga";
import {getDistributorListReducer} from "../reducers/partner/DistributorListReducer";

import {getDistributorSingleSaga} from "./partner/DistributorSingleSaga";
import {getDistributorSingleReducer} from "../reducers/partner/DistributorSingleReducer";

import {getPartnerListSaga} from "./partner/PartnerListSaga";
import {getPartnerListReducer} from "../reducers/partner/PartnerListReducer";

import {getPartnerSingleSaga} from "./partner/PartnerSingleSaga";
import {getPartnerSingleReducer} from "../reducers/partner/PartnerSingleReducer";

import {getRecommendKeyListSaga} from "./partner/RecommendKeyListSaga";
import {getRecommendKeyListReducer} from "../reducers/partner/RecommendKeyListReducer";

import {getOrgAuthListSaga} from "./partner/OrgAuthListSaga";
import {getOrgAuthListReducer} from "../reducers/partner/OrgAuthListReducer";

import {getSiteSettingSingleSaga} from "./setting/SiteSettingSingleSaga";
import {getSiteSetting} from "../reducers/setting/SiteSettingSingleReducer";

import {getLevelSettingSingleSaga} from "./setting/LevelSettingSingleSaga";
import {getLevelSetting} from "../reducers/setting/LevelSettingSingleReducer";

import {getBettingSettingSingleSaga} from "./setting/BettingSingleSaga";
import {getBettingSetting} from "../reducers/setting/BettingSingleReducer";

import {getTagSettingSaga} from "./setting/TagSettingListSaga";
import {getTagSetting} from "../reducers/setting/TagSettingListReducer";

import {getRegBankSettingSaga} from "./setting/RegBankListSaga";
import {getRegBankSetting} from "../reducers/setting/RegBankListReducer";

import {getDangerAccountSettingListSaga} from "./setting/DangerAccountSettingListSaga";
import {getDangerAccountSettingListReducer} from "../reducers/setting/DangerAccountSettingListReducer";

import {getDomainSettingListSaga} from "./setting/DomainSettingListSaga";
import {getDomainSettingListReducer} from "../reducers/setting/DomainSettingListReducer";

import {getIpBlockListSaga} from "./setting/IpBlockListSaga";
import {getIpBlockListReducer} from "../reducers/setting/IpBlockListReducer";

import {getEtcSettingListSaga} from "./setting/EtcSettingListSaga";
import {getEtcSettingListReducer} from "../reducers/setting/EtcSettingListReducer";

import {getGroupSettingListSaga} from "./setting/GroupSettingListSaga";
import {getGroupSettingListReducer} from "../reducers/setting/GroupSettingListReducer";

import {getDepositListSaga} from "./deposit/DepositListSaga";
import {getDepositList} from "../reducers/deposit/DepositListReducer";

import {getWithdrawListSaga} from "./deposit/WithdrawListSaga";
import {getWithdrawList} from "../reducers/deposit/WithdrawListReducer";

import {getDepositWithdrawLogSaga} from "./deposit/DepositWithdrawLogSaga";
import {getDepositWithdrawLog} from "../reducers/deposit/DepositWithdrawLogReducer";

import {getDepositWithdrawCombineSaga} from "./deposit/DepositWithdrawCombineSaga";
import {getDepositWithdrawCombine} from "../reducers/deposit/DepositWithdrawCombineReducer";

import {getMoneyListSaga} from "./deposit/MoneyListSaga";
import {getMoneyList} from "../reducers/deposit/MoneyListReducer";

import {getMoneyStatSaga} from "./deposit/MoneyStatSaga";
import {getMoneyStat} from "../reducers/deposit/MoneyStatReducer";

import {getPointListSaga} from "./deposit/PointListSaga";
import {getPointList} from "../reducers/deposit/PointListReducer";

import {getPointStatSaga} from "./deposit/PointStatSaga";
import {getPointStat} from "../reducers/deposit/PointStatReducer";

import {getCouponListSaga} from "./deposit/CouponListSaga";
import {getCouponList} from "../reducers/deposit/CouponListReducer";
import {getCustomers} from "../reducers/board/CustomerReducer";
import {getCustomerSaga} from "./board/CustomerSaga";
import {getCustomerDetail} from "../reducers/board/CustomerDetailReducer";
import {getCustomerDetailSaga} from "./board/CustomerDetailSaga";
import {getNoticesSaga} from "./board/NoticeSaga";
import {getNotices} from "../reducers/board/NoticeReducer";
import {getEvents} from "../reducers/board/EventReducer";
import {getEventsSaga} from "./board/EventSaga";
import {getETCs} from "../reducers/board/ETCReducer";
import {getETCsSaga} from "./board/ETCSaga";
import {getAdmins} from "../reducers/monitoring/AdminReducer";
import {getAdminSaga} from "./monitoring/AdminSaga";
import {getActives} from "../reducers/monitoring/ActiveReducer";
import {getActiveSaga} from "./monitoring/ActiveSaga";
import {getActiveMembers} from "../reducers/monitoring/ActiveMemberReducer";
import {getActiveMemberSaga} from "./monitoring/ActiveMemberSaga";
import {getLoginFails} from "../reducers/monitoring/LoginFailReducer";
import {getLoginFailSaga} from "./monitoring/LoginFailSaga";
import {getAccounts} from "../reducers/monitoring/AccountReducer";
import {getAccountSaga} from "./monitoring/AccountSaga";
import {getChecks} from "../reducers/board/CheckReducer";
import {getCheckSaga} from "./board/CheckSaga";
import {getSameBrowsers} from "../reducers/monitoring/SameBrowserReducer";
import {getSameBrowserSaga} from "./monitoring/SameBrowserSaga";
import {getOverlaps} from "../reducers/monitoring/OverlapReducer";
import {getOverlapSaga} from "./monitoring/OverlapSaga";

import {getMemberInformationSimpleModalReducer} from "../reducers/member/MemberInformationSimpleModalReducer"
import {getMemberInformationSimpleModalSaga} from "./member/MemberInformationSimpleModalSaga"
import {getMoneyInfo} from "../reducers/moneyInfo";
import {getMoneyInfoSaga} from "./moneyInfo";
import {getAlerts} from "../reducers/alerts";
import {getAlertsSaga} from "./alerts";
import {getLeftAlerts} from "../reducers/leftAlerts";
import {getLeftAlertsSaga} from "./leftAlerts";
import {getGameKinds, getLeagues, getNations, getTeams} from "../reducers/game/gameSetting";
import {getGameKindsSaga, getLeaguesSaga, getNationsSaga, getTeamsSaga} from "./game/gameSetting";
import {getWaitFixture, getWaitFixtureDetail} from "../reducers/game/manage/parsingWait";
import {getWaitFixtureDetailSaga, getWaitFixtureSaga} from "./game/manage/parsingWait";
import {getActiveFixture, getActiveFixtureDetail} from "../reducers/game/manage/activeMatch";
import {getActiveFixtureDetailSaga, getActiveFixtureSaga} from "./game/manage/activeMatch";
import {getCloseFixture, getCloseFixtureDetail} from "../reducers/game/manage/closeMatch";
import {getCloseFixtureDetailSaga, getCloseFixtureSaga} from "./game/manage/closeMatch";
import {getStopFixture, getStopFixtureDetail} from "../reducers/game/manage/stopMatch";
import {getStopFixtureDetailSaga, getStopFixtureSaga} from "./game/manage/stopMatch";
import {getStopMatchBy} from "../reducers/game/manage/stopMatchBy";
import {getStopMatchBySaga} from "./game/manage/stopMatchBy";
import {getResultFixture, getResultFixtureDetail} from "../reducers/game/manage/resultMatch";
import {getResultFixtureDetailSaga, getResultFixtureSaga} from "./game/manage/resultMatch";
import {getCancelFixture, getCancelFixtureDetail} from "../reducers/game/manage/cancelMatch";
import {getCancelFixtureDetailSaga, getCancelFixtureSaga} from "./game/manage/cancelMatch";
import {getCancelMatchBy} from "../reducers/game/manage/cancelMatchBy";
import {getCancelMatchBySaga} from "./game/manage/cancelMatchBy";
import {getFixture} from "../reducers/game/manage/fixture";
import {getFixtureSaga} from "./game/manage/fixture";

import {getMiniGames} from "../reducers/game/manage/miniGame";
import {getMiniGamesSaga} from "./game/manage/miniGame";

import {getWithdrawInfoSaga} from "./partner/WithdrawInfoSaga";
import {getWithdrawInfo} from "../reducers/partner/WithdrawInfoReducer";
import {getRollingInfoSaga} from "./partner/RollingInfoSaga";
import {getRollingInfo} from "../reducers/partner/RollingInfoReducer";
import {getLosingInfoSaga} from "./partner/LosingInfoSaga";
import {getLosingInfo} from "../reducers/partner/LosingInfoReducer";
import {getMemberInfoSaga} from "./partner/MemberInfoSaga";
import {getMemberInfo} from "../reducers/partner/MemberInfoReducer";
import {getInplayFixture, getInplayFixtureDetail} from "../reducers/game/manage/inplayMatch";
import {getInplayFixtureDetailSaga, getInplayFixtureSaga} from "./game/manage/inplayMatch";
import {getInplayResult, getInplayResultDetail} from "../reducers/game/manage/inplayResult";
import {getInplayResultDetailSaga, getInplayResultSaga} from "./game/manage/inplayResult";

import {getAdjustmentSaga} from "./partner/AdjustmentSaga";
import {getAdjustment} from "../reducers/partner/AdjustmentReducer";

import {getCodeSettingListSaga} from "./setting/CodeSettingListSaga";
import {getCodeSettingListReducer} from "../reducers/setting/CodeSettingListReducer";

import {getLvlSettingSaga} from "./setting/LvlSettingListSaga";
import {getLvlSetting} from "../reducers/setting/LvlSettingListReducer";

import {getLvlSettingSingleSaga} from "./setting/LvlSettingSingleSaga";
import {getLvlSettingSingle, getLvlSettingSingleReducer} from "../reducers/setting/LvlSettingSingleReducer";

import {getDwpSettingSaga} from "./setting/DwpSettingListSaga";
import {getDwpSetting} from "../reducers/setting/DwpSettingListReducer";

import {getAcctSettingSaga} from "./setting/AcctSettingListSaga";
import {getAcctSetting} from "../reducers/setting/AcctSettingListReducer";

import {getRecommenderSettingSaga} from "./setting/RecommenderSettingListSaga";
import {getRecommenderSetting} from "../reducers/setting/RecommenderSettingListReducer";

import {getAttendanceSettingSaga} from "./setting/AttendanceSettingListSaga";
import {getAttendanceSetting} from "../reducers/setting/AttendanceListReducer";

import {getRouletteSettingSaga} from "./setting/RouletteSettingListSaga";
import {getRouletteSetting} from "../reducers/setting/RouletteListReducer";

import {getRegSettingSaga} from "./setting/RegSettingListSaga";
import {getRegSetting} from "../reducers/setting/RegSettingListReducer";


export function* watcherSaga() {

    yield takeLatest(getBlackMemberReducer.type, getBlackMemberSaga)

    //user-member
    yield takeLatest(getMemberUser.type, getMemberSaga)
    //user-memberUpdate
    yield takeLatest(getMemberUpdate.type, getMemberUpdateSaga)

    yield takeLatest(getLotteryReducer.type, getLotterySaga)
    yield takeLatest(getMemberStateReducer.type, getMemberStateSaga)
    yield takeLatest(getMessageReducer.type, getMessageSaga)
    yield takeLatest(getMessagePopReducer.type, getMessagePopSaga)
    yield takeLatest(getMessageTemplateReducer.type, getMessageTemplateSaga)
    yield takeLatest(getOrgListReducer.type, getOrgListSaga)
    yield takeLatest(getCompanySubListReducer.type, getCompanySubListSaga)
    yield takeLatest(getCompanySubSingleReducer.type, getCompanySubSingleSaga)
    yield takeLatest(getDistributorListReducer.type, getDistributorListSaga)
    yield takeLatest(getDistributorSingleReducer.type, getDistributorSingleSaga)
    yield takeLatest(getPartnerListReducer.type, getPartnerListSaga)
    yield takeLatest(getPartnerSingleReducer.type, getPartnerSingleSaga)
    yield takeLatest(getRecommendKeyListReducer.type, getRecommendKeyListSaga)
    yield takeLatest(getOrgAuthListReducer.type, getOrgAuthListSaga)
    yield takeLatest(getSiteSetting.type, getSiteSettingSingleSaga)
    yield takeLatest(getLevelSetting.type, getLevelSettingSingleSaga)
    yield takeLatest(getBettingSetting.type, getBettingSettingSingleSaga)
    yield takeLatest(getTagSetting.type, getTagSettingSaga)
    yield takeLatest(getRegBankSetting.type, getRegBankSettingSaga)
    yield takeLatest(getDangerAccountSettingListReducer.type, getDangerAccountSettingListSaga)
    yield takeLatest(getDomainSettingListReducer.type, getDomainSettingListSaga)
    yield takeLatest(getIpBlockListReducer.type, getIpBlockListSaga)
    yield takeLatest(getEtcSettingListReducer.type, getEtcSettingListSaga)
    yield takeLatest(getGroupSettingListReducer.type, getGroupSettingListSaga)
    yield takeLatest(getDepositList.type, getDepositListSaga)
    yield takeLatest(getWithdrawList.type, getWithdrawListSaga)
    yield takeLatest(getDepositWithdrawLog.type, getDepositWithdrawLogSaga)
    yield takeLatest(getDepositWithdrawCombine.type, getDepositWithdrawCombineSaga)
    yield takeLatest(getMoneyList.type, getMoneyListSaga)
    yield takeLatest(getMoneyStat.type, getMoneyStatSaga)
    yield takeLatest(getPointList.type, getPointListSaga)
    yield takeLatest(getPointStat.type, getPointStatSaga)
    yield takeLatest(getCouponList.type, getCouponListSaga)


    //board
    yield takeLatest(getCustomers.type, getCustomerSaga)

    yield takeLatest(getCustomerDetail.type, getCustomerDetailSaga)
    yield takeLatest(getNotices.type, getNoticesSaga)
    yield takeLatest(getEvents.type, getEventsSaga)
    yield takeLatest(getETCs.type, getETCsSaga)
    yield takeLatest(getChecks.type, getCheckSaga)

    //monitoring
    yield takeLatest(getAdmins.type, getAdminSaga)
    yield takeLatest(getActives.type, getActiveSaga)
    yield takeLatest(getActiveMembers.type, getActiveMemberSaga)
    yield takeLatest(getLoginFails.type, getLoginFailSaga)
    yield takeLatest(getAccounts.type, getAccountSaga)
    yield takeLatest(getSameBrowsers.type, getSameBrowserSaga)
    yield takeLatest(getOverlaps.type, getOverlapSaga)

    //member
    yield takeLatest(getMemberInformationSimpleModalReducer.type, getMemberInformationSimpleModalSaga)

    //top
    yield takeLatest(getMoneyInfo.type, getMoneyInfoSaga)
    yield takeLatest(getAlerts.type, getAlertsSaga)

    //game
    yield takeLatest(getGameKinds.type, getGameKindsSaga)
    yield takeLatest(getNations.type, getNationsSaga)
    yield takeLatest(getLeagues.type, getLeaguesSaga)
    yield takeLatest(getTeams.type, getTeamsSaga)

    yield takeLatest(getWaitFixtureDetail.type, getWaitFixtureDetailSaga)
    yield takeLatest(getWaitFixture.type, getWaitFixtureSaga)

    yield takeLatest(getActiveFixture.type, getActiveFixtureSaga)
    yield takeLatest(getActiveFixtureDetail.type, getActiveFixtureDetailSaga)

    yield takeLatest(getInplayFixture.type, getInplayFixtureSaga)
    yield takeLatest(getInplayFixtureDetail.type, getInplayFixtureDetailSaga)

    yield takeLatest(getInplayResult.type, getInplayResultSaga)
    yield takeLatest(getInplayResultDetail.type, getInplayResultDetailSaga)

    yield takeLatest(getCloseFixture.type, getCloseFixtureSaga)
    yield takeLatest(getCloseFixtureDetail.type, getCloseFixtureDetailSaga)

    yield takeLatest(getStopFixtureDetail.type, getStopFixtureDetailSaga)
    yield takeLatest(getStopFixture.type, getStopFixtureSaga)

    yield takeLatest(getStopMatchBy.type, getStopMatchBySaga)

    yield takeLatest(getResultFixtureDetail.type, getResultFixtureDetailSaga)
    yield takeLatest(getResultFixture.type, getResultFixtureSaga)

    yield takeLatest(getCancelFixtureDetail.type, getCancelFixtureDetailSaga)
    yield takeLatest(getCancelFixture.type, getCancelFixtureSaga)

    yield takeLatest(getCancelMatchBy.type, getCancelMatchBySaga)

    yield takeLatest(getMiniGames.type, getMiniGamesSaga)

    yield takeLatest(getFixture.type, getFixtureSaga)
    yield takeLatest(getWithdrawInfo.type, getWithdrawInfoSaga)
    yield takeLatest(getRollingInfo.type, getRollingInfoSaga)
    yield takeLatest(getLosingInfo.type, getLosingInfoSaga)
    yield takeLatest(getMemberInfo.type, getMemberInfoSaga)

    yield takeLatest(getAdjustment.type, getAdjustmentSaga)
    yield takeLatest(getLeftAlerts.type, getLeftAlertsSaga)

    yield takeLatest(getCodeSettingListReducer.type, getCodeSettingListSaga)

    yield takeLatest(getLvlSetting.type, getLvlSettingSaga)
    yield takeLatest(getLvlSettingSingleReducer.type, getLvlSettingSingleSaga)

    yield takeLatest(getDwpSetting.type, getDwpSettingSaga)
    yield takeLatest(getAcctSetting.type, getAcctSettingSaga)
    yield takeLatest(getRecommenderSetting.type, getRecommenderSettingSaga)

    yield takeLatest(getAttendanceSetting.type, getAttendanceSettingSaga)
    yield takeLatest(getRouletteSetting.type, getRouletteSettingSaga)

    yield takeLatest(getRegSetting.type, getRegSettingSaga)

}

