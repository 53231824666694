import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"


export const regBankSettingSlice = createSlice({
    name: 'regBankSetting',
    initialState: {
        isLoading: false,
        error: null,
        regBankSetting: [],
        countList: {},
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
        userGbCd: "",
        searchGbCd: ""
    },
    reducers: {
        getRegBankSetting(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userGbCd = action.payload.userGbCd;
            state.searchGbCd = action.payload.searchGbCd;

        },
        setRegBankSetting(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.regBankSetting = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList;;

        },
        failedGeRegBankSetting(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { getRegBankSetting, setRegBankSetting, failedGeRegBankSetting } = regBankSettingSlice.actions;

export default regBankSettingSlice.reducer;