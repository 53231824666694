import {useParams} from "react-router-dom";
import MemberDetailInfoContainer from "../../containers/user/detail/MemberDetailInfoContainer";


const MemberInfo = () => {
    const param = useParams()

    return (
        <MemberDetailInfoContainer userId={param.userId} />
    )
}
export default MemberInfo