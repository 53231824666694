import {createSlice} from "@reduxjs/toolkit"
import moment from "moment";

export const loginFailSlice = createSlice({
    name: 'loginFail',
    initialState: {
        isLoading: false,
        error: null,
        loginFails: [],
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loginType: -1,
        searchGb: "id",
        searchQuery: "",
        page: 1,
        pageSize: 10,
    },
    reducers: {
        getLoginFails(state, action) {
            state.isLoading = true;
            state.startDate = action.payload.startDate !== undefined? action.payload.startDate : moment().format('YYYY-MM-DD');
            state.endDate = action.payload.endDate !== undefined?  action.payload.endDate : moment().add(1, "day").format('YYYY-MM-DD');
            state.loginType = action.payload.loginType? action.payload.loginType : 0;
            state.searchGb = action.payload.searchGb? action.payload.searchGb : "id";
            state.searchQuery = action.payload.searchQuery? action.payload.searchQuery : "";
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
        },
        failedGetLoginFails(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setLoginFails(state, action) {
            state.isLoading = false;
            state.loginFails = action.payload.result;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalCount = action.payload.totalCount;
        },
    }
});

export const { getLoginFails, failedGetLoginFails, setLoginFails } = loginFailSlice.actions;

export default loginFailSlice.reducer;