import {call, put, take,} from "redux-saga/effects";
import {fetchRollingInfo} from "../../../lib/api";
import {failedGetRollingInfo, setRollingInfo} from "../../reducers/partner/RollingInfoReducer";

export function* getRollingInfoSaga(action) {
    try{
        const res = yield call(
            fetchRollingInfo,action.payload.input
        )
        yield put(setRollingInfo(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetRollingInfo(error))
    }
}