import React from 'react';
import StopContainer from "../../../containers/game/manage/StopContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const Stop = () => {
    return (
        <div className="common">
            <font color={"blue"} size="3">파싱중지</font>
            <StopContainer />
        </div>
    );
};

export default Stop;