import React from 'react';
import DwpSettingContainer from "../../containers/setting/DwpSettingContainer";


const DwpSetting = () => {
    return (
        <div>
            <DwpSettingContainer />
        </div>
    );
};

export default DwpSetting;