import React, {useEffect, useRef,useState} from 'react';
import {useDispatch, useSelector} from "react-redux"
import MessageTemplateModal from "../../components/modal/user/MessageTemplateModal"
import MessageSendRecModal from "../../components/modal/user/MessageSendRecModal";
import OrgSelect from "../../components/org/OrgSelect";
import OrgSelect2 from "../../components/org/OrgSelect";
import Editor from "../../components/common/Editor";
import {setPopNo,setInputTemplate,setIsOpen,setIsOpen2,setIsOpen4,setSelectOrgId,setSelectExceptOrgId,setUserColorNoListAll,setExceptUserColorNoListAll,setPntGdCdListAll,setRcptMethCdListAll,setUserGbCdListAll,setMessageTemplateList,setRegId,setUpdId,setAutoDeleteDay,setTitle,setUserIdList,setUserGbCdList,setUserColorNoList,setSiteCdList,setPntGdCdList,setExceptUserIdList,setOrgIdList,setRcptMethCdList,setExceptOrgIdList,setExceptUserColorNoList,setDomainList,setCont,setSendIp,setSendName,setSendDt,setPayPoint1,setPayPointTime,setPayPoint2,setPayPoint,statsSlice,setPayCouponAmt,setPayCouponName,setSendId,setNowCnncYn,setMessageReducer,failedGetMessageReducer,getMessageReducer} from "../../redux/reducers/user/MessageReducer";
import axios from "axios";
import {insertAdminLog} from "../../lib/api";

const Message = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.MessageReducer)
    const userInfoId = useSelector((state) => state.userInfo.id)
    const userInfoIp = useSelector((state) => state.userInfo.ip)

    const getList = () => {
        if(initialState.totalList !== null ) {
            dispatch(getMessageReducer({
                input:{
                    gb : "init",
                    page: 1,
                    pageSize: 20,
                }
            }))
            dispatch(setSendId(userInfoId))
            dispatch(setRegId(userInfoId))
            dispatch(setUpdId(userInfoId))
            dispatch(setSendIp(userInfoIp))
        }

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "쪽지관리"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }
    useEffect(getList, [dispatch])

    const reload = () => {
        dispatch(getMessageReducer({
            input:{
                gb : "init",
                page: 1,
                pageSize: 20,
            }
        }))
    }
    const deleteData = (gb,no) => {
        axios.post('/admin/user/Message/deleteMessage',{
            gb : "no",
            no : no,
        }).
        then((res) => {
            alert(res.data.message);
            reload();
        })
    }

    //발송자이름
    const changeSendName = (e) =>{
        dispatch(setSendName(e.target.value))
    }

    //지급포인트
    const changePayPoint = (e) =>{
        dispatch(setPayPoint(e.target.value))
    }

    //지급쿠폰명
    const changePayCouponName = (e) =>{
        dispatch(setPayCouponName(e.target.value))
    }

    const changeAutoDeleteDay = (e) =>{
        dispatch(setAutoDeleteDay(e.target.value))
    }

    const changeCont =(cont)=>{
        dispatch(setCont(cont));
    }

    const changeDomainList = (e) =>{
        dispatch(setDomainList(e.target.value))
    }

    const changeSendId = (e) =>{
        dispatch(setSendId(e.target.value))
    }

    const changeExceptOrgIdList = (e) =>{
        dispatch(setExceptOrgIdList(e.target.value))
    }

    const addOrgIdList = () =>{
        console.log("##addOrgIdList##",initialState.selectOrgId)
        if(initialState.orgIdText === ''){//첫번째일경우 한칸 띄기 하자 읺음
            dispatch(setOrgIdList(initialState.selectOrgId))
        }else{
            dispatch(setOrgIdList(initialState.orgIdText +'\n'+initialState.selectOrgId))
        }
    }

    const addExceptOrgIdList = () =>{
        if(initialState.exceptOrgIdText === ''){//첫번째일경우 한칸 띄기 하자 읺음
            dispatch(setExceptOrgIdList(initialState.selectExceptOrgId))
        }else{
            dispatch(setExceptOrgIdList(initialState.exceptOrgIdText +'\n'+initialState.selectExceptOrgId))
        }
    }

    const changeExceptUserIdList = (e) =>{
        dispatch(setExceptUserIdList(e.target.value))
    }

    const changeNowCnncYn = (e) =>{
        dispatch(setNowCnncYn(e.target.value))
    }

    const changeOrgIdList= (e) =>{
        dispatch(setOrgIdList(e.target.value))
    }

    const changePayCouponAmt= (e) =>{
        dispatch(setPayCouponAmt(e.target.value))
    }

    const changePayPoint1= (e) =>{
        dispatch(setPayPoint1(e.target.value))
    }
    const changePayPoint2= (e) =>{
        dispatch(setPayPoint2(e.target.value))
    }

    const changePayPointTime= (e) =>{
        dispatch(setPayPointTime(e.target.value))
    }

    const changePntGdCdListAll= (e) =>{
        dispatch(setPntGdCdListAll(e.target.checked))
    }

    const changePntGdCdList= (e) =>{
        dispatch(setPntGdCdList({val :e.target.value,gb :e.target.checked}))
    }

    const changeRcptMethCdListAll= (e) =>{
        dispatch(setRcptMethCdListAll(e.target.checked))
    }

    const changeRcptMethCdList= (e) =>{
        dispatch(setRcptMethCdList({val :e.target.value,gb :e.target.checked}))
    }

    const changeRegId= (e) =>{
        dispatch(setRegId(e.target.value))
    }

    const changeSendDt= (e) =>{
        dispatch(setSendDt(e.target.value))
    }

    const changeSendIp= (e) =>{
        dispatch(setSendIp(e.target.value))
    }

    const changeSiteCdList= (e) =>{
        dispatch(setSiteCdList(e.target.value))
    }

    const changeTitle= (e) =>{
        dispatch(setTitle(e.target.value))
    }

    const changeUpdId= (e) =>{
        dispatch(setUpdId(e.target.value))
    }

    const changeUserColorNoListAll = (e) =>{
        dispatch(setUserColorNoListAll(e.target.checked))
    }
    const changeUserColorNoList= (e) =>{
        dispatch(setUserColorNoList({val :e.target.value,gb :e.target.checked}))
    }

    const changeExceptUserColorNoListAll = (e) =>{
        dispatch(setExceptUserColorNoListAll(e.target.checked))
    }
    const changeExceptUserColorNoList= (e) =>{
        dispatch(setExceptUserColorNoList({val :e.target.value,gb :e.target.checked}))
    }

    const changeUserGbCdListAll= (e) =>{
        dispatch(setUserGbCdListAll(e.target.checked))
    }
    const changeUserGbCdList= (e) =>{
        dispatch(setUserGbCdList({val :e.target.value,gb :e.target.checked}))
    }

    const changeUserIdList= (e) =>{
        dispatch(setUserIdList(e.target.value))
    }

    const handleClickOpenInsert = () => {
        dispatch(setIsOpen(true))
        dispatch(setInputTemplate({ nm : 'gb' , val :'등록' }));
        dispatch(setInputTemplate({ nm : 'no' , val :'' }));
        dispatch(setInputTemplate({ nm : 'template' , val :'' }));
        dispatch(setInputTemplate({ nm : 'messageTitle' , val :'' }));
        dispatch(setInputTemplate({ nm : 'messageCont' , val :'' }));
    }

    const handleClickTemplet = (messageTitle,messageCont) => {
        console.log("##messageCont##",messageCont)
        dispatch(setTitle(messageTitle))
        dispatch(setCont(messageCont))
    }
    const handleClickOpenUpdate = (no,template,messageTitle,messageCont) => {
        dispatch(setIsOpen(true))
        dispatch(setInputTemplate({ nm : 'gb' , val :'수정' }));
        dispatch(setInputTemplate({ nm : 'no' , val :no }));
        dispatch(setInputTemplate({ nm : 'template' , val : template }));
        dispatch(setInputTemplate({ nm : 'messageTitle' , val :messageTitle }));
        dispatch(setInputTemplate({ nm : 'messageCont' , val :messageCont }));
    }

    const handleClickOpen2 = (no) => {
        dispatch(setIsOpen2(true))
        dispatch(setPopNo(no))
    }

    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        //reload()
        window.location.reload();
    }

    const handleClickClose2 = () => {
        dispatch(setIsOpen2(false))
        reload()
    }

    const handleClickClose4 = () => {
        dispatch(setIsOpen4(false))
    }

    const setParentParam = (id) =>{
        dispatch(setSelectOrgId(id))
    }
    const setParentParam2 = (id) =>{
        dispatch(setSelectExceptOrgId(id))
    }

    const repeatCharacter = (char, count) => {
        return Array.from({ length: count }, () => char).join('');
    };

    const spaces = repeatCharacter('\u00A0', 87);

    const openUserCom = (userNo) => {
        dispatch(setIsOpen4(true))
    }
    const messageSend = () => {
        axios.post('/admin/user/Message/sendMessage', initialState.inputSend)
            .then((res) => {
                alert(res.data.message)
                window.location.reload();
            })
    }

    const deleteTemplate = (no)=>{
        axios.post('/admin/user/MessageTemplate/deleteMessagTemplate', {
            no : no,
        }).then((res) => {
            if(res.data.code === 'suc') {
                alert(res.data.message)
            }else{
                alert(res.data.message)
            }
            reload()
        })
    }

    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="5">쪽지관리</font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"}  >
                        <tr>
                            <td align={"right"}>
                                <button onClick={messageSend} style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" >쪽지발송</button>
                            </td>
                        </tr>
                    </table>
                </div>
                <br></br>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>

                    <table className="table table-bordered table-sm text-center align-middle">
                        <tbody>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>발송자이름</td>
                            <td width={"1%"}></td>
                            <td width={"84%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input value={initialState.inputSend.send_name} onChange={(e)=>changeSendName(e)} className="form-check-input sm-input" type="text" placeholder="관리자"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>지급 포인트</td>
                            <td width={"1%"}></td>
                            <td width={"84%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input onChange={(e)=>changePayPoint(e)}  className="form-check-input sm-input" type="text" placeholder="0"/>
                                </div>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    *단, 보유머니+포인트가
                                    <input onChange={(e)=>changePayPoint1(e)} className="form-check-input sm-input" type="text" placeholder="0"/>
                                    원 미만인 회원에게만 지급(0일 경우 무조건 지급)
                                </div>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    *단,
                                    <input onChange={(e)=>changePayPointTime(e)} className="form-check-input sm-input" type="text" placeholder="0"/>
                                    시간 이내 충전이
                                    <input onChange={(e)=>changePayPoint2(e)} className="form-check-input sm-input" type="text" placeholder="0"/>
                                    원 이상인 경우 지급(0시간 또는 0원으로 설정시 이 조건은 무시됨)
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>지급 쿠폰명</td>
                            <td width={"1%"}></td>
                            <td width={"84%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input onChange={(e)=>changePayCouponName(e)} className="form-check-input sm-input" type="text" placeholder=""/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>지급 쿠폰 금액</td>
                            <td width={"1%"}></td>
                            <td width={"84%"} align={"left"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input onChange={(e)=>changePayCouponAmt(e)} className="form-check-input sm-input" type="text" placeholder="0"/>
                                </div>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    * 금액이 0 보다 크며, 쿠폰명이 있을 경우 발송됩니다
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>입금방식</td>
                            <td width={"1%"}></td>
                            <td width={"84%"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input
                                        type="checkbox"
                                        name="flexcmmn1All"
                                        id="flexcmmn1All"
                                        //value={initialState.rcptMethCdListAll}
                                        style={{ verticalAlign: 'middle', marginLeft: '8px' }}
                                        onChange={(e)=>changeRcptMethCdListAll(e)}
                                        checked={initialState.rcptMethCdListAll}
                                    />
                                    <label
                                        htmlFor="flexcmmn1All"
                                        style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                    >
                                        전체
                                    </label>
                                    {initialState.cmCd0001.map(item => (
                                        <React.Fragment key={item.cmmn_cd_dtl_id}>
                                            <input
                                                type="checkbox"
                                                name="flexcmmn1"
                                                id={`flexcmmn1${item.cmmn_cd_dtl_id}`}
                                                value={item.cmmn_cd_dtl_id}
                                                style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                                onChange={(e)=>changeRcptMethCdList(e)}
                                                checked={initialState.inputSend.rcptMethCdList.includes(item.cmmn_cd_dtl_id)}
                                            />
                                            <label
                                                htmlFor={`flexcmmn1${item.cmmn_cd_dtl_id}`}
                                                style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                            >
                                                {item.cmmn_cd_dtl_nm}
                                            </label>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    * 체크가 없으면 전체 입금방식 대상 / 체크하면 입금방식 회원들만 대상
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>회원등급</td>
                            <td width={"1%"}></td>
                            <td width={"84%"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input
                                        type="checkbox"
                                        name="flexcmmn2All"
                                        id="flexcmmn2All"
                                        style={{ verticalAlign: 'middle', marginLeft: '8px' }}
                                        onChange={(e)=>changeUserGbCdListAll(e)}
                                        checked={initialState.userGbCdListAll}
                                    />
                                    <label
                                        htmlFor="flexcmmn2All"
                                        style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                    >
                                        전체
                                    </label>
                                    {initialState.cmCd0008.map(item => (
                                        <React.Fragment key={item.cmmn_cd_dtl_id}>
                                            <input
                                                type="checkbox"
                                                name="flexcmmn2"
                                                id={`flexcmmn2${item.cmmn_cd_dtl_id}`}
                                                value={item.cmmn_cd_dtl_id}
                                                style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                                onChange={(e)=>changeUserGbCdList(e)}
                                                checked={initialState.inputSend.userGbCdList.includes(item.cmmn_cd_dtl_id)}
                                            />
                                            <label
                                                htmlFor={`flexcmmn2${item.cmmn_cd_dtl_id}`}
                                                style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                            >
                                                {item.cmmn_cd_dtl_nm}
                                            </label>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>현재접속회원</td>
                            <td width={"1%"}></td>
                            <td width={"84%"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    {initialState.nowCnncYnCombo.map(item => (
                                        <React.Fragment key={item.cd}>
                                            <input
                                                type="radio"
                                                name="nowCnncYn"  // 동일한 name으로 설정
                                                id={`flexcmmn3${item.cd}`}
                                                value={item.cd}
                                                style={{ verticalAlign: 'middle', marginLeft: '8px' }}
                                                onChange={(e) => changeNowCnncYn(e)}
                                                checked={initialState.inputSend.nowCnncYn === item.cd}
                                            />
                                            <label
                                                htmlFor={`flexcmmn3${item.cd}`}
                                                style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                            >
                                                {item.nm}
                                            </label>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>포인트등급</td>
                            <td width={"1%"}></td>
                            <td width={"84%"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input
                                        type="checkbox"
                                        name="flexcmmn4All"
                                        id="flexcmmn4All"
                                        style={{ verticalAlign: 'middle', marginLeft: '8px' }}
                                        onChange={(e)=>changePntGdCdListAll(e)}
                                        checked={initialState.pntGdCdListAll}
                                    />
                                    <label
                                        htmlFor="flexcmmn4All"
                                        style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                    >
                                        전체
                                    </label>
                                    {initialState.cmCd0004.map(item => (
                                        <React.Fragment key={item.cmmn_cd_dtl_id}>
                                            <input
                                                type="checkbox"
                                                name="flexcmmn4"
                                                id={`flexcmmn4${item.cmmn_cd_dtl_id}`}
                                                value={item.cmmn_cd_dtl_id}
                                                style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                                onChange={(e)=>changePntGdCdList(e)}
                                                checked={initialState.inputSend.pntGdCdList.includes(item.cmmn_cd_dtl_id)}
                                            />
                                            <label
                                                htmlFor={`flexcmmn4${item.cmmn_cd_dtl_id}`}
                                                style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                            >
                                                {item.cmmn_cd_dtl_nm}
                                            </label>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>관심회원 선택<br></br>(선택한 관심회원에게만 발송)</td>
                            <td width={"1%"}></td>
                            <td width={"84%"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input
                                        type="checkbox"
                                        name="flexColor1All"
                                        id="flexColor1All"
                                        style={{ verticalAlign: 'middle', marginLeft: '8px' }}
                                        onChange={(e)=>changeUserColorNoListAll(e)}
                                        checked={initialState.userColorNoListAll}
                                    />
                                    <label
                                        htmlFor="flexColor1All"
                                        style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                    >
                                        전체
                                    </label>
                                    {initialState.userColorList.map(item => (
                                        <React.Fragment key={item.no}>
                                            <td style={{backgroundColor:item.back_ground_color}} align={"center"} height={"20px"} >
                                                <input
                                                    type="checkbox"
                                                    name="flexColor1"
                                                    id={`flexColor1${item.no}`}
                                                    value={item.no}
                                                    style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                                    onChange={(e)=>changeUserColorNoList(e)}
                                                    checked={initialState.inputSend.userColorNoList.includes(parseInt(item.no))}
                                                />
                                                <label
                                                    htmlFor={`flexColor1${item.no}`}
                                                    style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                                ><font color={item.font_color}>{item.no}</font>
                                                </label>
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>
                                <font color="red">관심회원 제외<br></br>(선택한 관심회원은 제외)</font>
                            </td>
                            <td width={"1%"}></td>
                            <td width={"84%"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input
                                        type="checkbox"
                                        name="flexColor2All"
                                        id="flexColor2All"
                                        style={{ verticalAlign: 'middle', marginLeft: '8px' }}
                                        onChange={(e)=>changeExceptUserColorNoListAll(e)}
                                        checked={initialState.exceptUserColorNoListAll}

                                    />
                                    <label
                                        htmlFor="flexColor2All"
                                        style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                    >
                                        전체
                                    </label>
                                    {initialState.userColorList.map(item => (
                                        <React.Fragment key={item.no}>
                                            <td style={{backgroundColor:item.back_ground_color}} align={"center"} height={"20px"} >
                                                <input
                                                    type="checkbox"
                                                    name="flexColor2"
                                                    id={`flexColor2${item.no}`}
                                                    value={item.no}
                                                    style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                                    onChange={(e)=>changeExceptUserColorNoList(e)}
                                                    checked={initialState.inputSend.exceptUserColorNoList.includes(parseInt(item.no))}
                                                />
                                                <label
                                                    htmlFor={`flexColor2${item.no}`}
                                                    style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                                ><font color={item.font_color}>{item.no}</font>
                                                </label>
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} >
                                부본사/총판/가맹점<br></br>고른 곳만 보내기<br></br>(상부를 선택하면, 하부 포함)
                            </td>
                            <td width={"1%"}></td>
                            <td width={"100%"} align={"left"}>
                                <div style={{zIndex : 999}}>
                                    <OrgSelect setChildParam = {setParentParam} id = {1}/>
                                </div>
                                {spaces}
                                <button
                                    onClick={addOrgIdList}
                                    style={{
                                        backgroundColor: "#CECECE",
                                        border: "1px solid black",
                                        fontSize: "9px",
                                        height: "20px",
                                        width: "fit-content",
                                        top: "0", // 상단에 위치 (상단에서 0px)
                                    }}
                                >
                                    추가
                                </button>
                                <br></br>
                                <textarea value={initialState.orgIdText}  onChange={(e)=>changeOrgIdList(e)} name="textarea_name" cols="200" rows="5" defaultValue="골라 제외할 대상 소속의 아이디를 입력하세요(구분은 엔터)"></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} >
                                <font color="red">부본사/총판/가맹점<br></br>고른 곳만 보내기<br></br>(상부를 선택하면, 하부 포함)</font>
                            </td>
                            <td width={"1%"}></td>
                            <td width={"100%"} align={"left"}>
                                <div style={{zIndex : 998}}>
                                    <OrgSelect2 setChildParam = {setParentParam2} id = {0}/>
                                </div>
                                {spaces}
                                <button onClick={addExceptOrgIdList} style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "20px", width: "fit-content"}}>추가</button>
                                <br></br>
                                <textarea value={initialState.exceptOrgIdText} onChange={(e)=> changeExceptOrgIdList(e)} name="textarea_name" cols="200" rows="5" defaultValue="골라 보낼 대상 소속의 아이디를 입력하세요(구분은 엔터)"></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>사이트 코드 기능미구현</td>
                            <td width={"1%"}></td>
                            <td width={"84%"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio1"
                                           id="inlineRadio1"/> Tbet
                                    &nbsp;&nbsp;&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio1"
                                           id="inlineRadio1"/> Tbet
                                    &nbsp;&nbsp;&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio1"
                                           id="inlineRadio1"/> 신규
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} >
                                특정 회원들에게만 전송(아이디 입력)
                            </td>
                            <td width={"1%"}></td>
                            <td width={"84%"} align={"left"}>
                                * 회원 아이디를 콤마 혹은 엔터로 구분하여 입력합니다.
                                <br></br>
                                * 여기 아이디를 넣으시면 위에 정의된 조건과 여기 입력된 아이디의 교집합에 대해서만 전송됩니다.
                                <br></br>
                                * 즉, 여기에 입력하신 아이디라 하더라도 위에 지정된 조건에 포함되지 않으면 전송되지 않습니다.
                                <br></br>
                                * 입력한 아이디는 올림차순으로 자동 정렬 됩니다.
                                <br></br>
                                * 존재하지 않는 아이디는 쪽지발송시 자동으로 제외됩니다.
                                <br></br>
                                <textarea onChange={(e)=>changeUserIdList(e)} name="textarea_name" cols="200" rows="5" defaultValue=""/>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} >
                                <font color="red">특정 회원들만 제외(아이디 입력)</font>
                            </td>
                            <td width={"1%"}></td>
                            <td width={"84%"} align={"left"}>

                                * 회원 아이디를 콤마 혹은 엔터로 구분하여 입력합니다.
                                <br></br>
                                * 여기 아이디를 넣으시면 다른 모든 조건에 부합하더라도  <font color="red">무조건 제외</font>됩니다.
                                <br></br>
                                * 입력한 아이디는 올림차순으로 자동 정렬 됩니다.
                                <br></br>
                                * 존재하지 않는 아이디는 아무 영향을 미치지 않습니다.
                                <br></br>
                                <textarea onChange={(e)=>changeExceptUserIdList(e)} name="textarea_name" cols="200" rows="5" defaultValue=""></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} >
                                특정 도메인 접속회원들에게만 전송
                                <br></br>
                                (도메인 입력)
                            </td>
                            <td width={"1%"}></td>
                            <td width={"84%"} align={"left"}>
                                * 도메인을 콤마 혹은 엔터로 구분하여 입력합니다.
                                <br></br>
                                <textarea onChange={(e)=>changeDomainList(e)} name="textarea_name" cols="200" rows="5" defaultValue=""></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} >
                                쪽지템플릿
                            </td>
                            <td width={"1%"}></td>
                            <td width={"84%"} align={"left"} style={{ display: "flex", alignItems: "center" }}>
                                <button onClick={handleClickOpenInsert} style={{ backgroundColor: "lightgray" }} type="submit">
                                    <font color="green">템플릿추가</font>
                                </button>&nbsp;&nbsp;
                                {initialState.isOpen && <MessageTemplateModal open={initialState.isOpen} onClose={handleClickClose}
                                                                              param = {initialState.inputTemplate}
                                />}

                                {initialState.messageTemplateList && initialState.messageTemplateList.length > 0 ? (
                                    initialState.messageTemplateList.map((List, index) => (
                                        <div key={index} style={{ display: "flex", marginRight: "8px" }}>
                                            <button onClick={()=>handleClickTemplet(List.message_title,List.message_cont)} style={{ backgroundColor: "lightgray" }} type="submit">
                                                <font color="black">{List.template}</font>
                                            </button>
                                            <button onClick={()=>handleClickOpenUpdate(List.no,List.template,List.message_title,List.message_cont)} style={{ backgroundColor: "lightgray" }} type="submit">
                                                <font color="green">수정</font>
                                            </button>
                                            <button onClick={()=>deleteTemplate(List.no)} style={{ backgroundColor: "lightgray" }} type="submit">
                                                <font color="red">삭제</font>
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <>
                                        {/* 거짓일 때의 내용을 제공해야 함 */}
                                    </>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>쪽지제목</td>
                            <td width={"1%"}></td>
                            <td width={"84%"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input value={initialState.inputSend.title} onChange={(e)=>changeTitle(e)} style={{
                                        width: "1400px"
                                    }} type="text" className="form-control" id="search_edit" placeholder="<font color=#f6ff00><strong> 거래 불가 계좌 안내" onBlur={(e) => {
                                    }}  />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>쪽지내용</td>
                            <td width={"1%"}></td>
                            <td width={"84%"} align={"left"}>
                                <Editor cont={initialState.inputSend.cont} setCont={changeCont} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"} height={"50px"}>
                                미확인 쪽지
                                <br></br>
                                자동삭제설정
                            </td>
                            <td width={"1%"}></td>
                            <td width={"84%"}>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <input onChange={(e)=>changeAutoDeleteDay(e)} className="form-check-input sm-input" type="text" placeholder="0"/>
                                    일
                                </div>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <font color="red"><span style={{backgroundColor:"yellow"}}>
                                        * 0으로 설정하면 자동삭제하지 않음
                                    </span></font>
                                </div>
                                <div className="container-fluid p-0 mx-1" style={{
                                    display: "flex"
                                }}>
                                    <font color="red"><span style={{backgroundColor:"yellow"}}>
                                        * 발송 시점으로 부터 계산됩니다. 만약 자동 삭제를 1일로 설정하고, 10일 23시 30분에 발송했다면 11일 23시 30분에 삭제됩니다.
                                    </span></font>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"}>
                        <tbody>
                        <tr>
                            <td align={"right"}>
                                <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>읽지않은 쪽지 모두삭제</button>
                                <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>쪽지 모두삭제</button>
                                <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>3일 이전 삭제</button>
                                <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>7일 이전 삭제</button>
                                <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>30일 이전 삭제</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <th style={{width: "2%"}}>번호</th>
                            <th style={{width: "*%"}}>내용</th>
                            <th style={{width: "7%"}}>지급 포인트</th>
                            <th style={{width: "7%"}}>보낸 사람</th>
                            <th style={{width: "7%"}}>받는 사람</th>
                            <th style={{width: "7%"}}>보낸 날짜</th>
                            <th style={{width: "5%"}}>보낸 수</th>
                            <th style={{width: "7%"}}>읽은 수</th>
                            <th style={{width: "7%"}}>읽은 시각<br></br>(최초 시각)</th>
                            <th style={{width: "7%"}}>미확인 쪽지<br></br>자동삭제설정</th>
                            <th style={{width: "5%"}}>삭제</th>
                        </tr>
                        </thead>
                        {initialState.totalList && initialState.totalList.length > 0 ? initialState.gridList.map((List, index) => (
                                <tbody key={List.no}>
                                <tr>
                                    <td align={"center"}>{List.no}</td>
                                    <td align={"left"}>
                                        {List.title}
                                        <br></br>
                                        {List.cont}
                                        <br></br><br></br><br></br><br></br>
                                    </td>
                                    <td align={"center"}><font color="blue">{List.pay_point}</font></td>
                                    <td align={"center"}>
                                        {List.send_name}<br></br>
                                        {List.send_id}<br></br>
                                        <font color="blue">{List.send_ip}</font>
                                    </td>
                                    <td onClick={()=>openUserCom(List.receive_id)} align={"center"}>{List.receiveNm} {List.sendCnt>1? "외" + (parseInt(List.sendCnt)-1) +"명":""}</td>

                                    <td align={"center"}>{List.send_dt}</td>
                                    <td align={"center"}>{List.sendCnt}</td>
                                    <td align={"center"}>
                                        {List.readCnt} ({List.readPer})<br></br>
                                        <button onClick={()=>handleClickOpen2(List.no)} className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "9px", height: "20px", width: "fit-content"}}>쪽지 조작</button>
                                    </td>
                                    <td align={"center"}>{List.minDt === null ? '-' : List.minDt}</td>
                                    <td align={"center"}>{List.sendCnt-List.readCnt?List.sendCnt-List.readCnt:0}</td>
                                    <td align={"center"}>
                                        <button onClick={()=>deleteData("no",List.no)} style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}}>삭제</button>
                                    </td>
                                </tr>
                                </tbody>
                            )) :
                            <tbody>
                            <tr>
                                <td colSpan= "11">조회된 데이터가 없습니다.</td>
                            </tr>
                            </tbody>
                        }
                    </table>
                    {initialState.isOpen2 && <MessageSendRecModal open={initialState.isOpen2} onClose={handleClickClose2} no ={initialState.popNo}/>}
                </div>
            </div>
        </div>
    )
}
export default Message;