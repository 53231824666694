import React from 'react';

const AdminList = ({state, onRefresh}) => {

    return (
        <div className="common">
            <table className="table table-bordered table-sm text-center align-middle">
                <thead>
                <tr>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>순서</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>위치</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이피</td>
                    <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>활동시각</td>
                </tr>
                </thead>
                <tbody>
                {state?.admins && state.admins.map((item, index) => (
                    <tr>
                        <td>{item.no}</td>
                        <td>{item.id}</td>
                        <td>{item.nick}</td>
                        <td>{item.menu_id}</td>
                        <td>{item.use_ip}</td>
                        <td>{item.createdAt}</td>
                    </tr>
                ))}

                </tbody>
            </table>
        </div>
    )

}


export default AdminList;