import {call, put, take,} from "redux-saga/effects";
import {
    failedGetCancelFixture, failedGetCancelFixtureDetail,
    getCancelFixtureDetail,
    setCancelFixture, setCurrentFixture
} from "../../../reducers/game/manage/cancelMatch";
import {fetchCancelFixture, fetchFixtureDetail} from "../../../../lib/api";

export function* getCancelFixtureSaga(action) {
    try {
        const res = yield call(fetchCancelFixture,
            action.payload
        );
        yield put(setCancelFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getCancelFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetCancelFixture(error));
    }
}
export function* getCancelFixtureDetailSaga(action) {
    try {
        const res = yield call(fetchFixtureDetail, action.payload, 8)
        yield put(setCurrentFixture(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetCancelFixtureDetail(error))
    }
}


