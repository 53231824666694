import React from 'react';

const ActiveList = ({state, onRefresh}) => {

    return <div className="common">
        <table className="table table-bordered table-sm text-center align-middle">
            <thead>
            <tr>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>접속일시</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>구분</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이피</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이피국가</td>
                <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>페이지</td>
            </tr>
            </thead>
            <tbody>
            {state?.actives && state.actives.map((item, index) => (
                <tr>
                    <td >{item.createdAt}</td>
                    <td>{item.admin_type === 0? "관리자" : "총판"}</td>
                    <td><span>{item.admin_type === 0? item.admin_nick : item.partner_nick}</span></td>
                    <td><span>{item.id}</span></td>
                    <td><span style={{ color: "#1F68EE" }}>{item.use_ip}</span></td>
                    <td>{item.use_country}</td>
                    <td>{item.menu_id}</td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>

}


export default ActiveList;