import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination2";
import {getDepositList, setSelectedNos,setSelectedNos3} from "../../redux/reducers/deposit/DepositListReducer";
import DepositList from "../../components/deposit/DepositList";
import {NumericFormat} from "react-number-format";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import OrgSelect from "../../components/org/OrgSelect";
import {setSelectOrgId} from "../../redux/reducers/user/MessageReducer";
import InterestUser from "../../components/common/InterestUserFull";
import {setSelectedNos4} from "../../redux/reducers/deposit/DepositListReducer";
import {insertAdminLog} from "../../lib/api";
import PointGrade from "../../components/common/PointGrade";
import {getDepositWithdrawLog} from "../../redux/reducers/deposit/DepositWithdrawLogReducer";

const DepositListContainer = ({userId}) => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfoId = useSelector((state) => state.userInfo.id)
    const userInfoIp = useSelector((state) => state.userInfo.ip)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.depositList)
    const userState = useSelector((state) => state.userInfoCombine)

    //const [startDate, setStartDate] = useState(moment().format('YYYY-MM')+'-01');
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const [dateType, setDateType] = useState(0);
    const [userGbCd, setUserGbCd] = useState("");

    const [date7, setDate7] = useState('#3fadf7')
    const [date1, setDate1] = useState('#3fadf7')
    const [date0, setDate0] = useState('red')


    /*조회조건 start*/
    const [searchCd01, setSearchCd01] = useState(0); //정렬
    const [searchCd02, setSearchCd02] = useState(); //상태
    const [searchCd03, setSearchCd03] = useState(); //구분
    const [searchCd04, setSearchCd04] = useState(); //회원구분
    const [searchCd05, setSearchCd05] = useState(); //처리
    const [searchCd06, setSearchCd06] = useState(); //현재입금방식
    const [searchCd07, setSearchCd07] = useState(); //총판/가맹점
    const [searchCd08, setSearchCd08] = useState(); //충전신청/완료처리 금액(전체 보정/미보정)
    const [searchCd09, setSearchCd09] = useState(); //첫충정만보기
    const [searchCd10, setSearchCd10] = useState(); //가입후첫충전만보기
    const [searchCd11, setSearchCd11] = useState(); //관리자강제신청여부
    const [searchCd12, setSearchCd12] = useState(); //마킹여부
    //////////////////////////////////////////////////////////////////////////



    const [searchCd13, setSearchCd13] = useState(); //전체P레벨
    const [searchCd14, setSearchCd14] = useState(); //회원그룹
    const [searchCd15, setSearchCd15] = useState(); //관심회원
    const [searchCd16, setSearchCd16] = useState(); ///

    const [keyCd, setKeyCd] = useState("");         //셀렉트박스별 검색 종류
    const [valueCd, setValueCd] = useState("");     //검색 값
    /*조회조건 end*/

    const [searchQuery, setSearchQuery] = useState("");

    const [userGbList, setUserGbList] = useState([]);   //회원그룹DB맵핑



    const getCmmnCd = () => {
        if(changeFieldList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0028',
            }).then((res) => {
                setChangeFieldList(res.data.cmmnCdList);
            })
        }

        if(userGbList.length === 0){
            axios.post('/admin/member/getGroupSetting', {
                cmmnCdId : '0000',
            }).then((res) => {
                setUserGbList(res.data.cmmnCdList);
            })
        }

    }
    useEffect(getCmmnCd, [])

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        setSearchQuery(userId)
        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "충전내역"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getDepositList({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const changeQuery = (value) => {
        setValueCd(value)
        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: value,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const onChangePG = (selectedPGNos) => {
        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd03,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: selectedPGNos
        }))
    }


    const exportExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {


            let jsonObject = { // JSON 객체를 생성합니다.
                고유번호: initialState.totalList[i].no? initialState.totalList[i].no :'',
                사이트코드: initialState.totalList[i].site_cd? initialState.totalList[i].site_cd :'',
                소속: initialState.totalList[i].store_cd? initialState.totalList[i].store_cd :'',
                가맹점명: initialState.totalList[i].mrch_cd? initialState.totalList[i].mrch_cd :'',
                도메인: initialState.totalList[i].domain? initialState.totalList[i].domain :'',
                그룹: initialState.totalList[i].user_group_cd? initialState.totalList[i].user_group_cd :'',
                아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                회원등급: initialState.totalList[i].user_gb_cd? initialState.totalList[i].user_gb_cd :'',
                포인트등급: initialState.totalList[i].pnt_gd_cd? initialState.totalList[i].pnt_gd_cd :'',
                전체베팅차단: initialState.totalList[i].bet_block_cd? initialState.totalList[i].bet_block_cd :'',
                기준일충전: initialState.totalList[i].standard_deposit_amt? initialState.totalList[i].standard_deposit+" / "+initialState.totalList[i].standard_deposit_amt :'',
                기준일환전: initialState.totalList[i].standard_withdraw_amt? initialState.totalList[i].standard_withdraw+" / "+initialState.totalList[i].standard_withdraw_amt :'',
                가입입금자명: initialState.totalList[i].reg_deposit_nm? initialState.totalList[i].reg_deposit_nm :'',
                입금자명: initialState.totalList[i].deposit_nm? initialState.totalList[i].deposit_nm :'',
                전용계좌: initialState.totalList[i].exclusive_account_cd? initialState.totalList[i].exclusive_account_cd+"/"+initialState.totalList[i].exclusive_account_no+"/"+initialState.totalList[i].exclusive_account_nm :'',
                마지막충전: initialState.totalList[i].last_deposit? initialState.totalList[i].last_deposit+"분전" :'',
                당일첫충전: initialState.totalList[i].first_deposit? (initialState.totalList[i].first_deposit === 1 ? '[당일 첫 충전]' : '-') :'',
                충전금액: initialState.totalList[i].deposit_amt? initialState.totalList[i].deposit_amt :'',
                현재입금방식: initialState.totalList[i].rcpt_meth_cd? initialState.totalList[i].rcpt_meth_cd :'',
                코인: initialState.totalList[i].coin? initialState.totalList[i].coin :'',
                분류: initialState.totalList[i].game_type_cd? initialState.totalList[i].game_type_cd :'',
                IP: initialState.totalList[i].use_ip? initialState.totalList[i].use_ip :'',
                신청일: initialState.totalList[i].request_dt? initialState.totalList[i].request_dt :'',
                처리일: initialState.totalList[i].complete_dt? initialState.totalList[i].complete_dt :'',
                포인트지급: initialState.totalList[i].result_point? initialState.totalList[i].result_point :'',
                상태: initialState.totalList[i].deposit_state_cd? initialState.totalList[i].deposit_state_cd :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '충전내역');
        XLSX.writeFile(workBook, '충전내역.xlsx');

    }



    const updateState = (state, depositNos) => {
        if (window.confirm("변경 하시겠습니까?"))
        {
            if(depositNos.length > 0) {
                axios.post('/admin/depositList/change_state', {
                    adminNo: userInfoId,
                    depositNos: depositNos,
                    state: state,
                    reg_ip: userInfoIp
                }).then((res) => {
                    alert(res.data.isUpdated? "변경완료" : "변경실패")
                    //getAllList()
                    //window.location.reload();
                    onRefresh();
                    //window.location.reload();
                })
            }
            else
            {
                alert("항목을 선택해주세요");
                return;
            }
        }
    }


    const updateStateMulti = (state, depositNos) => {

        //alert("depositNos :: "+depositNos);
        //alert("arr : "+JSON.stringify(arr));

        if(depositNos.length < 1) {
            alert("항목을 선택해주세요");
            return;
        }

        let arr = depositNos.sort();
        for(let i=0;i<arr.length;i++){
            //alert("depositNos ::: "+arr[i]);
            if (window.confirm("완료에 대한 최종 확인입니다.\n정말 "+arr[i]+"번 항목을 완료 처리하시겠습니까?")) {
                if(depositNos.length > 0) {
                    axios.post('/admin/depositList/change_state', {
                        adminNo: userInfoId,
                        depositNos: arr[i],
                        state: state,
                        reg_ip: userInfoIp
                    }).then((res) => {
                    })

                    continue;
                }
                else
                {
                    alert("변경실패")
                    continue;
                }
            }
        }

        window.location.reload();

    }

    const updateMarking = (depositNo, markingYn) => {

        //alert("markingYn >>>>>>>>>>> "+markingYn);

        let markingCd = 0;
        switch (markingYn) {
            case 0 : markingCd = 1; break;
            case 1 : markingCd = 2; break;
            case 2 : markingCd = 0; break;
        }

        //alert("markingCd >>>>>>>>>>> "+markingCd);

        axios.post('/admin/depositList/change_marking', {
            adminNo: userInfoId,
            depositNo: depositNo,
            markingCd: markingCd,
            reg_ip: userInfoIp
        }).then((res) => {
            //alert(res.data.isUpdated? "변경완료" : "변경실패")
            //getAllList()
            //window.location.reload();
            onRefresh();
            //window.location.reload();
        })
    }



    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: e.currentTarget.value,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: e.currentTarget.value,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const add7Date = () => {
        setStartDate(moment().add(-7, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate7('red')
        setDate1('#3fadf7')
        setDate0('#3fadf7')

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-7, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }
    const add1Date = () => {
        setStartDate(moment().add(-1, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate7('#3fadf7')
        setDate1('red')
        setDate0('#3fadf7')

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-1, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }
    const add0Date = () => {
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('red')

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    //공통정렬처리
    const dateTypeChangeHandler = (type) => {
        setDateType(type)
        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: type,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))

    }


    const search01ChangeHandler = (type) => {
        setSearchCd01(type)

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: type,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const search02ChangeHandler = (type) => {
        setSearchCd02(type)

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: type,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const search03ChangeHandler = (type) => {
        setSearchCd03(type)

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: type,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const search04ChangeHandler = (type) => {
        setSearchCd04(type)

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: type,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const search05ChangeHandler = (type) => {
        setSearchCd05(type)

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: type,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const search06ChangeHandler = (type) => {
        setSearchCd06(type)

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: type,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const search08ChangeHandler = (type) => {
        setSearchCd08(type)

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: type,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }


    const search11ChangeHandler = (type) => {
        setSearchCd11(type)

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: type,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const search12ChangeHandler = (type) => {
        setSearchCd12(type)

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: type,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }


    const setParentParam = (id) =>{
        setSearchCd07(id)
        dispatch(setSelectOrgId(id))
        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: id,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))
    }

    const onCheckedColor = (selectedNos) => {

        //alert("selectedNos :: "+JSON.stringify(selectedNos));
        dispatch(setSelectedNos([selectedNos]))
        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4
        }))

        /*
        const fixInput = {...input}
        fixInput.interestUser = selectedNos
        dispatch(getActiveMembers(fixInput))
         */
    }


    const checkHandler3 = (checked, no) => {
        if(checked) {
            dispatch(setSelectedNos3([...initialState.selectedNos3, no]))
            dispatch(getDepositList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userId ? userId : userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: [...initialState.selectedNos3, no],
                selectedNos4: initialState.selectedNos4
            }))
        }
        else {
            dispatch(setSelectedNos3(initialState.selectedNos3.filter((id) => id !== no)))
            dispatch(getDepositList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userId ? userId : userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: initialState.selectedNos3.filter((id) => id !== no),
                selectedNos4: initialState.selectedNos4
            }))

        }
    }



    const checkHandler9 = (checked) => {
        if(checked) {
            setSearchCd09('Y')
            dispatch(getDepositList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: 'Y',
                searchCd10: initialState.searchCd10,
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userId ? userId : userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4
            }))
        }
        else {
            setSearchCd09('N')
            dispatch(getDepositList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: 'N',
                searchCd10: initialState.searchCd10,
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userId ? userId : userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4
            }))

        }
    }

    const checkHandler10 = (checked) => {
        if(checked) {
            setSearchCd10('Y')
            dispatch(getDepositList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: 'Y',
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userId ? userId : userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4
            }))
        }
        else {
            setSearchCd10('N')
            dispatch(getDepositList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: 'N',
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userId ? userId : userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4
            }))
        }
    }


    const checkHandler4 = (checked, no) => {
        if(checked) {
            dispatch(setSelectedNos4([...initialState.selectedNos4, no]))
            dispatch(getDepositList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userId ? userId : userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: [...initialState.selectedNos4, no],
            }))
        }
        else {
            dispatch(setSelectedNos4(initialState.selectedNos4.filter((id) => id !== no)))
            dispatch(getDepositList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userId ? userId : userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4.filter((id) => id !== no),
            }))
        }
    }

    const allCheckHandler4 = (e) => {
        const checks = [];
        checks.push('01','02','03','04','05','06','07','08','09','10','11','12','13')
        dispatch(setSelectedNos4(checks))

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: checks,
        }))
    }

    const allDisableCheckHandler4 = (e) => {
        const checks = [];
        dispatch(setSelectedNos4(checks))

        dispatch(getDepositList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId ? userId : userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: checks,
        }))
    }


    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <div className="commonNew">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">충전내역</font>
                    &nbsp;
                    <select
                        className="form-select sm-select"
                        defaultValue={30}
                        style={{
                            width: "70px"
                        }}
                        value={initialState.pageSize === "999"? "모두" : initialState.pageSize}
                        onChange={(e) => {
                            dispatch(getDepositList({
                                page: initialState.page,
                                pageSize: parseInt(e.target.value),
                                order: initialState.order,
                                query: searchQuery,
                                startDate: startDate ? startDate : initialState.startDate,
                                endDate: endDate ? endDate : initialState.endDate,
                                userGbCd: initialState.userGbCd,
                                searchGbCd: initialState.searchGbCd,
                                searchCd01: initialState.searchCd01,
                                searchCd02: initialState.searchCd02,
                                searchCd03: initialState.searchCd03,
                                searchCd04: initialState.searchCd04,
                                searchCd05: initialState.searchCd05,
                                searchCd06: initialState.searchCd06,
                                searchCd07: initialState.searchCd07,
                                searchCd08: initialState.searchCd08,
                                searchCd09: initialState.searchCd09,
                                searchCd10: initialState.searchCd10,
                                searchCd11: initialState.searchCd11,
                                searchCd12: initialState.searchCd12,
                                searchCd13: initialState.searchCd13,
                                searchCd14: initialState.searchCd14,
                                searchCd15: initialState.searchCd15,
                                searchCd16: initialState.searchCd16,
                                keyCd: initialState.keyCd,
                                valueCd: initialState.valueCd,
                                userId: userId ? userId : userState.userId,
                                selectedNos: initialState.selectedNos,
                                selectedNos3: initialState.selectedNos3,
                                selectedNos4: initialState.selectedNos4
                            }))
                        }}
                    >
                        <option value={30}>30개</option>
                        <option value={60}>60개</option>
                        <option value={120}>120개</option>
                        <option value={240}>240개</option>
                        <option value={360}>360개</option>
                        <option value={500}>500개</option>
                        <option value={1000}>1000개</option>
                        <option value={5000}>5000개</option>
                        <option value={10000}>10000개</option>
                    </select>
                    <table   width={"88%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                                &nbsp;
                                <button style={{
                                    width: "120px", backgroundColor :"gray"
                                }} type="submit" className="btn btn-primary" onClick={onClickReset}>새로고침(자동보류)</button>
                            </td>
                        </tr>
                    </table>

                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray" , width:"460px"
                    }}>
                        <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "10px"}} onClick={exportExcel}>엑셀 다운로드</button>
                        <button style={{ backgroundColor: date7, border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add7Date}>일주일</button>
                        <button style={{ backgroundColor: date1, border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add1Date}>어제</button>
                        <button style={{ backgroundColor: date0, border: "none", color: "white" ,fontSize: "9px", height: "20px", width: "fit-content"}} onClick={add0Date}>오늘</button>
                        &nbsp;&nbsp;
                        <input className="form-control sm-date" type="date" value={startDate} onChange={startDateHandler} style={{width:"110px"}}/>
                        &nbsp;~&nbsp;
                        <input className="form-control sm-date" type="date" value={endDate} onChange={endDateHandler} style={{width:"110px"}}/>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"300px"
                    }}>
                        <ArrowsExpand />정렬 :
                        <input className="form-check-input"  type="radio" name="inlineRadio01" id="inlineRadio01" value={dateType} style={{ cursor: "pointer" }}
                               onChange={() => dateTypeChangeHandler(1)} defaultChecked={true} /> 신청일 기준
                        <input className="form-check-input"  type="radio" name="inlineRadio01" id="inlineRadio01" value={dateType} style={{ cursor: "pointer" }}
                               onChange={() => dateTypeChangeHandler(2)} /> 처리일 기준
                        <input className="form-check-input"  type="radio" name="inlineRadio01" id="inlineRadio01" value={dateType} style={{ cursor: "pointer" }}
                               onChange={() => dateTypeChangeHandler(3)} /> 금액 큰 순
                        <input className="form-check-input"  type="radio" name="inlineRadio01" id="inlineRadio01" value={dateType} style={{ cursor: "pointer" }}
                               onChange={() => dateTypeChangeHandler(4)} /> 금액 적은 순
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"210px"
                    }}>
                        <ArrowsExpand />상태 :
                        <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio01" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler(0)} defaultChecked={true}/> 전체
                        <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio01" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler(9)} /> 대기
                        <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio01" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler(2)} /> 완료
                        <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio01" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler(1)} /> 보류
                        <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio01" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler(3)} /> 취소
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"380px"
                    }}>
                        <ArrowsExpand />구분 :
                        <input className="form-check-input"  type="radio" name="inlineRadio03" id="inlineRadio03" value={searchCd02} style={{ cursor: "pointer" }}
                               onChange={() => search02ChangeHandler("")} defaultChecked={true}/> 전체
                        <input className="form-check-input"  type="radio" name="inlineRadio03" id="inlineRadio03" value={searchCd02} style={{ cursor: "pointer" }}
                               onChange={() => search02ChangeHandler("01")} /> 스포츠
                        <input className="form-check-input"  type="radio" name="inlineRadio03" id="inlineRadio03" value={searchCd02} style={{ cursor: "pointer" }}
                               onChange={() => search02ChangeHandler("05")} /> 스포츠다폴더
                        <input className="form-check-input"  type="radio" name="inlineRadio03" id="inlineRadio03" value={searchCd02} style={{ cursor: "pointer" }}
                               onChange={() => search02ChangeHandler("04")} /> 미니게임
                        <input className="form-check-input"  type="radio" name="inlineRadio03" id="inlineRadio03" value={searchCd02} style={{ cursor: "pointer" }}
                               onChange={() => search02ChangeHandler("02")} /> 카지노
                        <input className="form-check-input"  type="radio" name="inlineRadio03" id="inlineRadio03" value={searchCd02} style={{ cursor: "pointer" }}
                               onChange={() => search02ChangeHandler("03")} /> 슬롯
                        <input className="form-check-input"  type="radio" name="inlineRadio03" id="inlineRadio03" value={searchCd02} style={{ cursor: "pointer" }}
                               onChange={() => search02ChangeHandler("06")} /> 보너스미지급
                    </div>
                </div>
            </div>

            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"450px", height:"23px"
                }}>
                    <ArrowsExpand />현재입금방식 :
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("")} defaultChecked={true}/> 전체&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("01")} /> 현금&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("02")} /> 가상&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("03")} /> 가상2&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio05" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("99")} />코인(전체)&nbsp;&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("04")} /> 코인[A]&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("05")} /> 코인[B]&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("06")} /> 코인[C]
                </div>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"200px"
                }}>
                    <input className="form-check-input"  type="radio" name="inlineRadio3" id="inlineRadio3" value={searchCd03} style={{ cursor: "pointer" }}
                           onChange={() => search03ChangeHandler("")} defaultChecked={true}/> 전체
                    <input className="form-check-input"  type="radio" name="inlineRadio3" id="inlineRadio3" value={searchCd03} style={{ cursor: "pointer" }}
                           onChange={() => search03ChangeHandler("01")} /> 일반회원
                    <input className="form-check-input"  type="radio" name="inlineRadio3" id="inlineRadio3" value={searchCd03} style={{ cursor: "pointer" }}
                           onChange={() => search03ChangeHandler("05")} /> 가라회원
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"280px"
                }}>
                    [  충전 신청/완료 처리 금액 :
                    <input className="form-check-input"  type="radio" name="inlineRadio8" id="inlineRadio8" value={searchCd08} style={{ cursor: "pointer" }}
                           onChange={() => search08ChangeHandler("")} defaultChecked={true}/> 전체&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio8" id="inlineRadio8" value={searchCd08} style={{ cursor: "pointer" }}
                           onChange={() => search08ChangeHandler('Y')}/> 보정&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio8" id="inlineRadio8" value={searchCd08} style={{ cursor: "pointer" }}
                           onChange={() => search08ChangeHandler('N')}/> 미보정 ]
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"330px"
                }}>
                    [ 관리자강제신청여부 :
                    <input className="form-check-input"  type="radio" name="inlineRadio11" id="inlineRadio11" value={searchCd11} style={{ cursor: "pointer" }}
                           onChange={() => search11ChangeHandler("")} defaultChecked={true}/> 전체&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio11" id="inlineRadio11" value={searchCd11} style={{ cursor: "pointer" }}
                           onChange={() => search11ChangeHandler('Y')}/> 유저신청&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio11" id="inlineRadio11" value={searchCd11} style={{ cursor: "pointer" }}
                           onChange={() => search11ChangeHandler('N')}/> 관리자강제신청 ]
                </div>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"200px"
                }}>
                    [ 마킹여부 :&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio12" id="inlineRadio12" value={searchCd12} style={{ cursor: "pointer" }}
                           onChange={() => search12ChangeHandler("")} defaultChecked={true} /> 전체&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio12" id="inlineRadio12" value={searchCd12} style={{ cursor: "pointer" }}
                           onChange={() => search12ChangeHandler('Y')}/> 마킹&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio12" id="inlineRadio12" value={searchCd12} style={{ cursor: "pointer" }}
                           onChange={() => search12ChangeHandler('N')}/> 마킹없음 ]
                </div>
            </div>

            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"380px", height:"23px"
                }}>
                    검색
                    <div style={{zIndex : 999}}>
                        <OrgSelect setChildParam = {setParentParam} id = {1}/>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"110px"
                }}>
                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" /> 하위 포함 검색
                    &nbsp;
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"390px"
                }}>

                    <select
                        className="form-select sm-select"
                        defaultValue={999}
                        style={{
                            width: "150px"
                        }}
                        value={initialState.searchGb === ""? "0" : initialState.searchGb}
                        onChange={e =>
                            dispatch(getDepositList({
                                page: initialState.page,
                                pageSize: initialState.pageSize,
                                order: initialState.order,
                                query: searchQuery,
                                startDate: startDate ? startDate : initialState.startDate,
                                endDate: endDate ? endDate : initialState.endDate,
                                userGbCd: initialState.userGbCd,
                                searchGbCd: initialState.searchGbCd,
                                searchCd01: initialState.searchCd01,
                                searchCd02: initialState.searchCd02,
                                searchCd03: initialState.searchCd03,
                                searchCd04: initialState.searchCd04,
                                searchCd05: initialState.searchCd05,
                                searchCd06: initialState.searchCd06,
                                searchCd07: initialState.searchCd07,
                                searchCd08: initialState.searchCd08,
                                searchCd09: initialState.searchCd09,
                                searchCd10: initialState.searchCd10,
                                searchCd11: initialState.searchCd11,
                                searchCd12: initialState.searchCd12,
                                searchCd13: initialState.searchCd13,
                                searchCd14: initialState.searchCd14,
                                searchCd15: initialState.searchCd15,
                                searchCd16: initialState.searchCd16,
                                keyCd: e.target.value,
                                valueCd: initialState.valueCd,
                                userId: userId ? userId : userState.userId,
                                selectedNos: initialState.selectedNos,
                                selectedNos3: initialState.selectedNos3,
                                selectedNos4: initialState.selectedNos4
                            }))
                        }
                    >
                        <option value={"01"}>닉네임 또는 아이디</option>
                        <option value={"02"}>코인사명</option>
                        <option value={"03"}>코인아이디</option>
                        <option value={"04"}>기준일 충전금액(~이상)</option>
                        <option value={"05"}>기준일 충전금액(~미만)</option>
                        <option value={"06"}>닉네임</option>
                        <option value={"07"}>아이디</option>
                        <option value={"08"}>이름</option>
                        <option value={"09"}>가입입금자명</option>
                        <option value={"10"}>입금자명</option>
                        <option value={"11"}>금액</option>
                        <option value={"12"}>가맹점명</option>
                        <option value={"13"}>IP</option>
                        <option value={"14"}>충전번호</option>
                        <option value={"15"}>정산코드 조회</option>
                    </select>
                    &nbsp;
                    <input style={{
                        width: "170px"
                    }} type="text" value={userId} className="form-control" id="search_edit" placeholder="검색조건을 입력하세요." onBlur={(e) => {
                        changeQuery(e.target.value)
                    }} onKeyDown={inputPress} />
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"700px"
                }}>
                    <PointGrade onChangePG={onChangePG}/>
                </div>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"900px", height:"23px"
                }}>
                    <ArrowsExpand />회원그룹 :
                    {userGbList.map(item => (
                        <React.Fragment key={item.no}>
                            &nbsp;<input className="form-check-input" type="checkbox" name="inlineRadioGrp" id={'inlineRadioGrp'+item.no}
                                         style={{cursor:"pointer"}}
                                         checked={initialState.selectedNos3.filter((no) => no === item.no).length > 0}
                                         onChange={(e) => {checkHandler3(e.currentTarget.checked, item.no) }}/>
                            <label htmlFor={'inlineRadioGrp' + item.no} style={{cursor: "pointer"}}>
                                <font  style={{backgroundColor: "lightgray"  }} color={ "yellow"}>
                                    {item.no}
                                </font>&nbsp;{item.group_nm}&nbsp;&nbsp;
                            </label>
                        </React.Fragment>
                    ))}
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"230px"
                }}>
                    <input className="form-check-input" type="checkbox" name="inlineRadio2" id="inlineRadio1"
                           checked={ searchCd09 === 'Y' ? true : false  }
                           onChange={(e) => {checkHandler9(e.currentTarget.checked) }}
                    /> 첫충전만 보기
                    &nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio2" id="inlineRadio1"
                           checked={ searchCd10 === 'Y' ? true : false  }
                           onChange={(e) => {checkHandler10(e.currentTarget.checked) }}
                    /> 가입 후 첫충전만 보기
                </div>

            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray"
            }}>
                <div style={{display: "flex", paddingRight: "5px", paddingLeft: "5px"}}>
                    <InterestUser onCheckedColor={onCheckedColor} />
                </div>
            </div>
            <br></br>

        </div>
        <DepositList depositList={initialState.depositList} onRefresh={onRefresh} updateState={updateState} updateStateMulti={updateStateMulti} initialState={initialState} updateMarking={updateMarking}/>
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange} />
    </>





}
export default DepositListContainer