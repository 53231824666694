import React, {useEffect, useState} from "react";
import MarketList from "../../../components/game/setting/MarketList";
import axios from "axios";
import Pagination from "../../../components/common/Pagination";
import {getLeagues} from "../../../redux/reducers/game/gameSetting";


const MarketContainer = () => {
    const [useCode, setUseCode] = useState(999)
    const [searchQuery, setSearchQuery] = useState("")
    const [markets, setMarkets] = useState([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [totalCount, setTotalCount] = useState(0)

    useEffect(() => {
        getMarketList(input)
    }, [])

    const input = {
        useCode: useCode,
        query: searchQuery,
        page: page,
        pageSize: pageSize,
    }
    const getMarketList = (bodyData) => {
        axios.post('/admin/game/setting/getMarketList', bodyData)
            .then((res) => {
                const total = res.data.totalCount
                if((page -1) * pageSize > total) {
                    setPage(1)
                }
                setMarkets(res.data.result)
                setTotalCount(total)
            })
    }
    const getMarketsFromMatchServer = (bodyData) => {
        axios.post('/admin/game/setting/getMarketsFromMatchServer', bodyData)
            .then((res) => {
                if(res.data.inserted) {
                    alert("완료")
                    onRefresh()
                }
                else {
                    alert('등록실패')
                }
            })
    }

    const onRefresh = () => {
        getMarketList(input)
    }

    const changeUseCode = (e) => {
        setUseCode(e.currentTarget.value)
        const fixInput = {...input}
        fixInput.useCode = e.currentTarget.value
        getMarketList(fixInput)
    }
    const changeQuery = (e) => {
        setSearchQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.currentTarget.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.query = query
        getMarketList(fixInput)
    }
    const handlePageChange = (page) => {
        setPage(page)
        const fixInput = {...input}
        fixInput.page = page
        getMarketList(fixInput)
    }

    return <div className="common">
        <div className="d-flex justify-content-end" >
            <select className="form-select" style={{width: "150px"}} onChange={changeUseCode} value={useCode}>
                <option value={999}>사용여부전체</option>
                <option value={1}>사용</option>
                <option value={0}>미사용</option>
            </select>
            &nbsp;
            <input style={{
                width: "100px"
            }} type="text" className="form-control" id="search_edit" placeholder="마켓명" value={searchQuery} onChange={changeQuery} onKeyDown={inputPress} />
            &nbsp;
            <button style={{
                width: "80px", borderTopRightRadius: "5px" ,borderBottomRightRadius: "5px"
            }} type="submit" className="btn btn-primary" onClick={(e) => {
                onSearch(searchQuery)
            }}>검색</button>
            &nbsp;
            <button className="btn btn-success" onClick={(e) => {
                getMarketsFromMatchServer()
            }}>가져오기</button>
        </div>
        <MarketList markets={markets} onRefresh={onRefresh} setMarkets={setMarkets}/>
        <Pagination totalCount={totalCount} pageSize={pageSize} currentPage={page} onPageChange={handlePageChange}/>
    </div>
}

export default MarketContainer