import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination2";
import TagSetting from "../../components/setting/TagSetting";
import {NumericFormat} from "react-number-format";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import {getTagSetting} from "../../redux/reducers/setting/TagSettingListReducer";
import {insertAdminLog} from "../../lib/api";

const TagSettingContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfoId = useSelector((state) => state.userInfo.id)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.tagSetting)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM')+'-01');
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [userGbCd, setUserGbCd] = useState("");



    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getTagSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfoId,
            logContent: "태그설정"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getTagSetting({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getTagSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getTagSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getTagSetting({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd
        }))
    }


    const exportExcel = ()=>{

        //alert('initialState.totalList <>>>> '+initialState.totalList);

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {


            let jsonObject = { // JSON 객체를 생성합니다.
                no: initialState.totalList[i].no? initialState.totalList[i].no :'',
                소속: initialState.totalList[i].store_cd? initialState.totalList[i].store_cd :'',
                아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                변경필드명: initialState.totalList[i].change_field? initialState.totalList[i].change_field :'',
                기존값: initialState.totalList[i].asis_value? initialState.totalList[i].asis_value :'',
                수정값: initialState.totalList[i].tobe_value? initialState.totalList[i].tobe_value :'',
                수정아이디: initialState.totalList[i].upd_id? initialState.totalList[i].upd_id :'',
                수정아이피: initialState.totalList[i].update_ip? initialState.totalList[i].update_ip :'',
                수정시각: initialState.totalList[i].updatedAt? initialState.totalList[i].updatedAt :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '회원정보 수정내역');
        XLSX.writeFile(workBook, '회원정보 수정내역.xlsx');
    }

    const userGbCdChangeHandler = (e) => {
        alert('userGbCd000 >>>>>>>>>>>> '+e.currentTarget.value);
        setUserGbCd(e.currentTarget.value);

        alert('userGbCd >>>>>>>>>>>> '+userGbCd);
        onRefresh();
    }





    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const add7Date = () => {
        setStartDate(moment().add(-7, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add1Date = () => {
        setStartDate(moment().add(-1, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add0Date = () => {
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }


    const deleteState = (no) => {
        //alert("no :: "+no)
        if (window.confirm("삭제 하시겠습니까?"))
        {
            axios.post('/admin/setting/deleteTagSetting', {
                adminNo: userInfoId,
                no: no
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }

    const insertState = (noArr,etcArr,tagNmArr,colorNmArr,insertNoArr,insertEtcArr,insertTagNmArr,insertColorNmArr) => {
        // alert("noArr:::"+JSON.stringify(noArr));
        // alert("etcArr:::"+JSON.stringify(etcArr));
        // alert("tagNmArr:::"+JSON.stringify(tagNmArr));
        // alert("colorNmArr:::"+JSON.stringify(colorNmArr));
        //
        // alert("insertNoArr:::"+JSON.stringify(insertNoArr));
        // alert("insertEtcArr:::"+JSON.stringify(insertEtcArr));
        // alert("insertTagNmArr:::"+JSON.stringify(insertTagNmArr));
        // alert("insertColorNmArr:::"+JSON.stringify(insertColorNmArr));

        if (window.confirm("저장 하시겠습니까?"))
        {
            axios.post('/admin/setting/saveTagSettingList', {
                adminNo: userInfoId,
                noArr: noArr,
                etcArr: etcArr,
                tagNmArr: tagNmArr,
                colorNmArr: colorNmArr,
                insertNoArr: insertNoArr,
                insertEtcArr: insertEtcArr,
                insertTagNmArr: insertTagNmArr,
                insertColorNmArr: insertColorNmArr
            }).then((res) => {
                alert(res.data.isSaved? "저장완료" : "저장실패")
                //getAllList()
                //window.location.reload();
                //onRefresh();
                window.location.reload();
            })
        }
    }


    const updateState = (e) => {
        alert("수정:::");
    }


    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">태그설정</font>
                </div>

            </div>
        </div>
        <TagSetting tagSetting={initialState.tagSetting} onRefresh={onRefresh} initialState={initialState} deleteState={deleteState} insertState={insertState} updateState={updateState}/>
    </>





}
export default TagSettingContainer